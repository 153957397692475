import { TeamsService } from './../../../services/teams/teams.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RCSeasonTeamMembersVM } from '@app/client/pages/leagues/league-page/league-page-season/season-teams-page/season-teams-page.component';
import { LeaguesService } from '@app/shared/services/leagues/leagues.service';
import { RCLeagueSeason } from '@rcenter/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'rc-move-player-to-season-modal',
	templateUrl: './move-player-to-season-modal.component.html',
	styleUrls: ['./move-player-to-season-modal.component.scss'],
	exportAs: 'modal',
})
export class MovePlayerToSeasonModalComponent implements OnInit {
	@ViewChild('modal', { static: true }) public modal: ModalDirective;

	@Output() onSubmit = new EventEmitter();
	player: RCSeasonTeamMembersVM;
	currentSeason: RCLeagueSeason;
	playerEditForm: FormGroup;
	loading: boolean;
	leaguesSelections;
	toLeagueId;
	seasonsSelections;
	toSeasonId;
	subscribers = [];
	constructor(
		private leaguesService: LeaguesService,
		private toastr: ToastrService,
		private fb: FormBuilder,
		private leagueService: LeaguesService,
		private teamService: TeamsService
	) {}

	// getDivisionsBySeason

	ngOnInit() {
		this.playerEditForm = this.fb.group({
			memberId: ['', Validators.required],
			fromSeasonId: ['', Validators.required],
			toSeasonId: ['', Validators.required],
			toLeagueId: ['', Validators.required],
		});
	}

	openMovePlayerModal(season: RCLeagueSeason, player: RCSeasonTeamMembersVM) {
		this.playerEditForm.reset();

		this.player = player;
		this.currentSeason = season;

		this.toLeagueId = this.leagueService.currentLeagueObject.id;

		this.playerEditForm.get('memberId').setValue(this.player.userId);
		this.playerEditForm.get('fromSeasonId').setValue(this.currentSeason.id);

		this.leagueService.getLeagues().subscribe(leagues => {
			this.leaguesSelections = leagues.data;
			this.playerEditForm.get('toLeagueId').setValue(this.leagueService.currentLeagueObject.id);
			this.leagueSelected(this.leagueService.currentLeagueObject.id);
		});

		this.modal.show();
	}

	leagueSelected(leagueId) {
		this.seasonsSelections = undefined;
		setTimeout(() => {
			let league = this.leaguesSelections.find(l => l.id == leagueId);
			this.seasonsSelections = league.leagueSeasons.filter(s => s.id != this.currentSeason.id);
			if (this.seasonsSelections.length == 1) {
				this.toSeasonId = this.seasonsSelections[0].id;
			} else {
				this.toSeasonId = undefined;
			}
			this.playerEditForm.get('toSeasonId').setValue(this.toSeasonId);
		}, 0);
	}

	submit(data) {
		if (this.loading) return;
		this.loading = true;
		this.teamService.moveTeamOrMember(parseInt(localStorage.getItem('selected_org_id')), data).subscribe(
			res => {
				this.loading = false;
				this.toastr.success('Player moved Successfully');
				this.reset(true);
				this.onSubmit.emit();
			},
			err => {
				this.loading = false;
				this.toastr.error(err.message);
			}
		);
	}

	reset(closeModal?: boolean) {
		this.playerEditForm.reset();

		if (closeModal) {
			this.modal.hide();
		}
	}
}
