import * as  React from 'react';

export const BnDollar = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83301 7.08329C5.83301 5.24649 7.28093 3.75114 9.17069 3.40763L9.16731 3.37118L9.16634 2.49996C9.16634 2.03972 9.53944 1.66663 9.99967 1.66663C10.4599 1.66663 10.833 2.03972 10.833 2.49996V3.33329L10.8295 3.40778C12.7188 3.75158 14.1663 5.24676 14.1663 7.08329C14.1663 7.54353 13.7932 7.91663 13.333 7.91663C12.9056 7.91663 12.5534 7.59493 12.5053 7.18048L12.4997 7.08329C12.4997 5.95929 11.4033 4.99996 9.99967 4.99996C8.59605 4.99996 7.49967 5.95929 7.49967 7.08329C7.49967 8.16567 8.51634 9.09534 9.84502 9.16272L9.99967 9.16663C12.2779 9.16663 14.1663 10.819 14.1663 12.9166C14.1663 14.7518 12.721 16.2461 10.8337 16.5914L10.833 17.5C10.833 17.9602 10.4599 18.3333 9.99967 18.3333C9.53944 18.3333 9.16634 17.9602 9.16634 17.5L9.16652 16.5915C7.27881 16.2466 5.83301 14.7521 5.83301 12.9166C5.83301 12.4564 6.2061 12.0833 6.66634 12.0833C7.12658 12.0833 7.49967 12.4564 7.49967 12.9166C7.49967 14.0406 8.59605 15 9.99967 15C11.4033 15 12.4997 14.0406 12.4997 12.9166C12.4997 11.8343 11.483 10.9046 10.1543 10.8372L9.99967 10.8333C7.7214 10.8333 5.83301 9.18095 5.83301 7.08329Z"
      fill="currentColor"
    />
  </svg>
);
