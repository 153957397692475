/* @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import { SimpleResourceDto } from '@bondsports/types';
import { BnIcon, Button, ETypography, ETypographyColor, Icons, ModalWindow, Typography } from '@bondsports/utils';
import { paddingBottomCss } from '../../../../../styles/utils';
import { colors_v2 } from '../../../../../styles/theme';
import { capitalize } from 'lodash';
import { scrolleContainerCss } from '../../../../settings/styles';

const resourcesModalContainerCss = css`
	min-width: 500px;
	max-width: 500px;
	min-height: 257px;
	max-height: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 36px;
`;

const titleCss = css`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	gap: 4px;
`;

const listCss = css`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
	justify-content: start;
`;

const rowCss = css`
	display: flex;
	flex-direction: row;
	max-width: 70%;
	min-width: 70%;
	justify-content: space-between;
`;

export const ResourcesModal: React.FC<{
	resources: SimpleResourceDto[];
	isShowing: boolean;
	toggle: () => void;
	labels: Record<string, string>;
}> = ({ labels, resources, isShowing, toggle }) => {
	return (
		<ModalWindow isShowing={isShowing} toggle={toggle}>
			<div datatype="title" data-testid="resource-modal-title" css={resourcesModalContainerCss}>
				<div css={titleCss}>
					<BnIcon icon={Icons.court} color={colors_v2.tx_text_primary} />
					<Typography type={ETypography.h3} color={ETypographyColor.primary}>
						{labels.sessionResources}
					</Typography>
				</div>
				<div datatype="list" data-testid="resources-list" css={[listCss, scrolleContainerCss]}>
					{resources?.map((resource, index) => (
						<div key={`resource-${index}`} datatype="row" data-testid="resource-row" data-index={index} css={rowCss}>
							<Typography type={ETypography.body2} color={ETypographyColor.secondary}>
								{capitalize(resource.name)}
							</Typography>
						</div>
					))}
				</div>
				<div css={paddingBottomCss(20)}>
					<Button data-aid="button-ResourcesModal-close" theme="primary" sizer="M" onClick={toggle}>
						{labels.close}
					</Button>
				</div>
			</div>
		</ModalWindow>
	);
};
