import * as  React from 'react';

export const BnExcel = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8118 4.27154H11.041V2.70904C11.041 2.55383 10.9723 2.40695 10.8525 2.30799C10.7337 2.20904 10.5743 2.16633 10.4243 2.19758L2.09102 3.76008C1.84414 3.80591 1.66602 4.02049 1.66602 4.27154V15.7299C1.66602 15.9799 1.84414 16.1955 2.09102 16.2413L10.4243 17.8038C10.4556 17.8101 10.4879 17.8132 10.5202 17.8132C10.641 17.8132 10.7587 17.7715 10.8525 17.6934C10.9723 17.5945 11.041 17.4465 11.041 17.2924V15.7299H17.8118C18.0993 15.7299 18.3327 15.4965 18.3327 15.209V4.79237C18.3327 4.50487 18.0993 4.27154 17.8118 4.27154ZM8.82852 11.7413C9.0181 11.957 8.99622 12.2861 8.77956 12.4757C8.6806 12.5622 8.55872 12.6049 8.43685 12.6049C8.29206 12.6049 8.14831 12.5445 8.04518 12.4267L6.5306 10.6965L5.20247 12.4049C5.09935 12.5361 4.94518 12.6049 4.79102 12.6049C4.67956 12.6049 4.56706 12.5695 4.47122 12.4955C4.24414 12.3184 4.20352 11.9913 4.37956 11.7642L5.83164 9.89758L4.39935 8.26008C4.20977 8.04445 4.23164 7.71529 4.44831 7.5257C4.66393 7.33612 4.99206 7.35695 5.18372 7.57466L6.48372 9.06008L8.02643 7.07674C8.20352 6.8507 8.5306 6.80904 8.75768 6.98612C8.98477 7.16216 9.02539 7.48924 8.84831 7.71737L7.18164 9.85904L8.82852 11.7413ZM17.291 14.6882H11.041V13.6465H12.6035C12.891 13.6465 13.1243 13.4132 13.1243 13.1257C13.1243 12.8382 12.891 12.6049 12.6035 12.6049H11.041V11.5632H12.6035C12.891 11.5632 13.1243 11.3299 13.1243 11.0424C13.1243 10.7549 12.891 10.5215 12.6035 10.5215H11.041V9.47987H12.6035C12.891 9.47987 13.1243 9.24654 13.1243 8.95904C13.1243 8.67154 12.891 8.4382 12.6035 8.4382H11.041V7.39654H12.6035C12.891 7.39654 13.1243 7.1632 13.1243 6.8757C13.1243 6.5882 12.891 6.35487 12.6035 6.35487H11.041V5.3132H17.291V14.6882Z"
      fill="currentColor"
    />
  </svg>
);
