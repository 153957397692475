import { ITaxType } from 'app/react/components/customers/singleInvoice';
import { Membership } from 'app/react/types/membership';
import { Space } from 'app/react/types/packege';
import { EPaymentMethod } from 'app/react/types/payment';
import { Product } from 'app/react/types/product';
import { Program } from 'app/react/types/program';
import { User } from './user';
import { SlotDto } from './NewReservation';
import { EReservationStatus } from './reservation';
import {
	Customer,
	EntryStatusEnum,
	FuturePaymentStatusEnum,
	Invoice,
	LeagueSeason,
	Payment,
	ProductsUsers,
	RequestStatusEnum,
} from '@bondsports/types';

export interface ILineItem {
	totalPaid: number;
	id: number;
	invoiceId: number;
	invoice: IInvoice;
	lineItemsHistory?: any[];
	type?: LineItemsStatusEnum;
	userId?: number;
	quantity?: number;
	productId: number;
	paymentProcessorId?: string;
	productType: ProductTypesEnum;
	displayUnitPrice?: number;
	displayTotalPrice?: number;
	productUserId?: number;
	ordinal?: number;
	entitlementGroupId?: number;
	price?: number;
	originalPrice?: number;
	paidAmount?: number;
	currency: CurrencyEnum;
	paymentStatus: PaymentStatusEnum;
	productSubType?: ProductSubTypesEnum;
	product: Product;
	organizationId: number;
	previousPurchaseLineId?: number;
	previousPurchaseProducUserId?: number;
	discountDescription?: string;
	productUser?: ProductsUsers;
	resourceRedeemedForDiscount?: number;
	isRefunded: boolean;
	purchasedResources: IPurchasedResource[];
	refundedItem: ILineItem;
	unitPrice?: number;
	tax: { isTaxInclusive?: boolean; taxPrecent?: number; price?: number; paidAmount?: number };
	taxLineItem: { price?: number; paidAmount?: number };
	isTaxInclusive?: boolean;
	taxPrecent?: number;
	displayTotalPaid?: number;
	displayFullPrice?: number;
	discounts?: any[];
	customerFirstName?: string;
	customerLastName?: string;
	img?: string;
	isVoided?: boolean;
	displayQuantity?: number;
	displayTotalQuantity?: number;
	displayTotalPriceWithTax?: number;
	displayTotalPaidWithTax?: number;
	displayFullPriceWithVoid?: number;
	displayFullQuantityWithVoid?: number;
	parentLineItemId?: number;
}

export interface ILineItemHistory {
	id: number;
	invoiceId: number;
	paymentId: number;
	lineItemId: number;
	paidAmount: number;
	unitPaidAmount: number;
	currency: CurrencyEnum;
	payment: IPayment;
	lineItem: ILineItem;
}

export interface IPurchaseRes {
	status: FuturePaymentStatusEnum;
	invoice: Invoice;
	payment?: Payment;
	customer?: Customer;
	productUsers?: ProductsUsers[];
}

export interface IInvoice {
	id: number;
	invoiceId?: string;
	price?: number;
	status?: InvoiceStatusEnum;
	sentForClientDate?: Date;
	payingUserId?: number;
	invoiceToPayments: IInvoiceToPayment[];
	lineItems: ILineItem[];
	lineItemHistory: ILineItemHistory[];
	paymentStatus: PaymentStatusEnum;
	paymentMethodId?: string;
	paymentType?: EPaymentMethod;
	paidAmount: number;
	currency: CurrencyEnum;
	mainPaymentId: number;
	isScheduled: boolean;
	isRefunded: boolean;
	importedId?: string;
	creatingUserId: number;
	shiftId: number;
	platform: PlatformsEnum;
}

export interface IPayment {
	id: number;
	price: number;
	paymentProcessorId: string;
	invoiceToPayments: IInvoiceToPayment[];
	paymentStatus: PaymentStatusEnum;
	bondFee?: number;
	stripeFee?: number;
	currency: CurrencyEnum;
	payingUserId?: number;
	paymentMethodId?: string;
	paymentType?: EPaymentMethod;
	fundLeft: number;
	notes?: string;
	isRefunded: boolean;
	lineItemHistory: ILineItemHistory[];
	receiptUrl?: string;
	paymentProcessorTransactionId?: string;
	creatingUserId: number;
	shiftId: number;
	platform: PlatformsEnum;
	ccLast4: string;
	ccBrand: string;
	createdAt: Date;
	lineItems: ILineItem[];
	paidAmount: number;
	isScheduled?: boolean;
	invoiceId: string;
	mainPaymentId: number;
	slots?: SlotDto[];
}

export interface IInvoiceToPayment {
	updatedAt?: Date;
	id: number;
	invoiceId: number;
	paymentId: number;
	invoice: IInvoice;
	payment: IPayment;
	paidAmount?: number;
	currency: CurrencyEnum;
}

export interface IPurchasedResource {
	id: number;
	productUserId: number;
	resourceId: number;
	resourceType: ResourceNameTypeEnum;
	status: PurchasedResourceStatusEnum;
	productUser: ProductsUsers;
	lineItem: ILineItem;
	eventAttendee: IEventAttendee;
	seasonAttendee: ISeasonAttendee;
	leagueAttendee: any;
	membership: Membership;
	program: Program;
	programSeason: any;
	leagueSeason: LeagueSeason;
	space: Space;
	product: Product;
	event: any;
}

export interface ISeasonAttendee {
	id: number;
	status: RequestStatusEnum;
	paymentStatus: PaymentStatusEnum;
	paymentId?: number;
	productId?: number;
	attendeeId: number;
	attendee: User;
	seasonId: number;
	season: any;
	productUserId?: number;
	answerTitleIds?: number[];
	deletedAt?: Date;
	purchasedResource: IPurchasedResource;
}

export interface IEventAttendee {
	id: number;
	status?: RequestStatusEnum;
	hasPaid?: boolean;
	paymentId?: number;
	attendeeId: number;
	eventId?: number;
	productUserId?: number;
	answerTitleIds?: number[];
	entryStatus?: EntryStatusEnum;
	addonProductUserIds?: number[];
	deletedAt?: Date;
	attendee: User;
	event: any;
	purchasedResource: IPurchasedResource;
}

export interface IDisplayLineItem {
	item: IPaymentItem;
	total: number;
	quantity: number;
	price: number;
	tax: string;
}

export interface IAppendixMultiCell {
	text: string;
	tooltipContent?: string;
	moreCount?: number;
}

export interface AppendixItem {
	userId: number;
	description: string;
	addonCount: IAppendixMultiCell;
	startDate: Date;
	daysTimes: AppendixAddonItemDaysTimes;
	addons: AppendixAddonItem[];
	approvalStatus?: EReservationStatus;
	space?: IAppendixMultiCell;
	slots?: SlotDto[];
}

export interface AppendixAddonItem {
	description: string;
	addonCount: string;
	startDate: Date;
	subTitles: string[];
	daysTimes: AppendixAddonItemDaysTimes;
}

export interface AppendixAddonItemDaysTimes {
	dateRange: string;
	timeRange: string[];
}

export interface IPaymentDetailValues {
	totalTax: { taxesExcl: ITaxType; taxesInc: ITaxType };
	itemRows: {
		item: IPaymentItem;
		total: number;
		quantity: number;
		price: number;
		priceToRefund: any;
		tax: string;
		id: any;
	}[];
	originalPrice: number;
	creditCardCounter: number;
	paymentRows: { date: Date; id: number; method: EPaymentMethod; amount: number }[];
}

interface IPaymentItem {
	img: string;
	name: string;
	secondDescription: null;
	description: string;
	productType: ProductTypesEnum;
}

export enum LineItemsStatusEnum {
	USER_PRODUCT = 'UserProduct',
	RENTAL_PRODUCT = 'RentalProduct',
}

export enum ProductTypesEnum {
	RESERVATION = 'reservation',
	REGISTRATION = 'registration',
	MEMBERSHIP = 'membership',
	GOODS = 'goods',
	PACKAGE = 'package',
	//financial data
	REFUND_COMPENSATION = 'refund',
	CASH_OVER_SHORT = 'cash_over_short',
	PETTY_CASH = 'petty_cash',
	LEAGUE_REGISTRATION = 'league_registration',
	TAX = 'tax',
}

export enum CurrencyEnum {
	USD = 'USD',
}

export enum PaymentStatusEnum {
	NOT_PAID = 'not_paid',
	PARTIAL_PAYMENT = 'partial',
	FULLY_PAID = 'paid',
	REFUNDED = 'refunded',
	VOID = 'void',
	SCHEDULED = 'scheduled',
	DISCOUNT = 'discount',
	REVERT_DISCOUNT = 'revert_discount',
}

export enum ProductSubTypesEnum {
	SEASON_INDIVIDUAL = 'season_individual',
	SEASON_TEAM = 'season_team',
	SEASON_PER_PLAYER = 'season_per_player',
}

export enum InvoiceStatusEnum {
	ACTIVE = 'active',
	WAITING_ADMIN = 'waitingAdmin',
	WAITING_CLIENT = 'waitingClient',
	CANCELED = 'canceled',
}

export enum PlatformsEnum {
	CONSUMER = 'consumer',
	BO = 'backoffice',
	MOBILE = 'mobile',
	CRON = 'cron',
	IMPORT = 'import',
}

export enum ResourceNameTypeEnum {
	EVENT = 'event',
	VENUE = 'venue',
	TEAM = 'team',
	LEAGUE = 'league',
	USER = 'user',
	ORGANIZATION = 'organization',
	APP = 'app',
	FEED = 'feed',
	MATCH = 'match',
	ROUND = 'round',
	PORTAL = 'portal',
	SEASON = 'season',
	TOURNAMENT = 'tournament',
	MEMBERSHIP = 'membership',
	DIVISION = 'division',
	GAMESLOT = 'gameslot',
	SPACE = 'space',
	RESERVATION = 'reservation',
	INVOICE = 'invoice',
	CUSTOMER = 'customer',
	PACKAGE = 'package',
	FACILITY = 'facility',
	PROGRAM = 'program',
	PROGRAM_SEASON = 'program_season',
	PRODUCT = 'product',
	GROUP = 'group',
	VARIANT = 'variant',
}

export enum PurchasedResourceStatusEnum {
	ACTIVE = 1,
	MOVED = 2,
	CANCELED = 3,
}

export type VoidActionType = 'all' | 'items';
