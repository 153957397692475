/* @jsx jsx */

import React, { useMemo } from 'react';
import { jsx, css } from '@emotion/react';
import { SimpleResourceDto } from '@bondsports/types';
import { capitalize } from 'lodash';
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import { fulWidthCss } from '@app/react/styles/utils';
import { EllipsisWithViewMoreContainer } from '@app/react/components/shared/EllipsisWithViewMoreContainer';

export const ResourcesList: React.FC<{
	resources: SimpleResourceDto[];
	maxWidth?: number;
	setResourceToView: (resources: SimpleResourceDto[]) => void;
	labels: Record<string, any>;
}> = ({ resources, setResourceToView, labels }) => {
	const resourcesNames = useMemo(() => resources.map(resource => capitalize(resource.name)).join(', '), [resources]);

	if (!resources?.length) {
		return (
			<div>
				<Typography type={ETypography.body2} color={ETypographyColor.secondary}>
					{labels.noResources}
				</Typography>
			</div>
		);
	}

	return (
		<div datatype="list" data-testid="spaces" css={fulWidthCss}>
			<EllipsisWithViewMoreContainer text={resourcesNames} onClick={() => setResourceToView(resources)} />
		</div>
	);
};
