import { css } from '@emotion/react';
import { oldThemeColors as colors } from '../../../../../styles/theme';

export const defaultModalLayoutCss = css`
width: 100%;
height: 100%;
.modal-layout-header {
	height: 2rem;
}
.modal-layout-content {
	display: flex;
	flex-direction: column;
	background: white;
	gap: 24px;
}
.modal-layout-footer {
	height: 2rem;
}
`;

export const ChargeButtonCss = css`
background: linear-gradient(270deg, ${colors.brandSecondary} 0%, ${colors.brandSecondary} 100%);
color: ${colors.white};
border: 1px solid ${colors.brandSecondary};
&:hover {
	box-shadow: 0px 2px 16px 8px rgba(247, 181, 0, 0.2);
	border: 1px solid ${colors.brandSecondary};
}
&:disabled {
	box-shadow: none;
	border: none;
}
	}
`;
export const headerCss = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
	align-items: center;
`;

export const footerWithButtonsCss = css`
	margin: 1rem 0;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: end;
	gap: 6px;
	width: 100%;

	button {
		margin-top: 0.5rem;
	}
`;

export const SecondaryButtonCss = css`
	border: 1px solid transparent;
	&:hover {
		box-shadow: none;
		text-decoration: underline;
		border: 1px solid transparent;
	}
`;
