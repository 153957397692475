/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/react';
import { Tag } from '../../shared/Tag';
import { CustomerTypeEnum, EStatusColorMapper } from '../../../types/customers';
import { TranslationEn } from '@assets/i18n/en';
import { CustomerFamilyStatusEnum } from '@bondsports/types';

interface IProps {
	entityType: CustomerTypeEnum;
	familyStatus?: CustomerFamilyStatusEnum;
}

export const CustomerTag = ({ entityType, familyStatus }: IProps) => {
	const labels = TranslationEn.customers.tags;

	const accountType = entityType === CustomerTypeEnum.USER ? familyStatus : entityType;

	return <Tag title={labels[accountType]} color={EStatusColorMapper[accountType]} />;
};
