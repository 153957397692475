/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactNode, Fragment, useMemo } from "react";
import { usePermissions } from "../hooks/usePermissions";
import { css, jsx } from "@emotion/react";

const containerCss = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export interface IBnAuthWrapper {
  permission?: string;
  withoutPermission?: string;
  children: ReactNode;
}
export const BnAuthWrapper = ({
  permission = "",
  withoutPermission = "",
  children,
}: IBnAuthWrapper) => {
  const { permissions: userPermissions } = usePermissions();

  const isAllowed = useMemo(() => {
    const array = permission.split(",");
    let response = true;
    array.forEach((requiredPermission) => {
      if (!userPermissions.includes(requiredPermission) && requiredPermission) {
        response = false;
      }
    });
    const secondArray = withoutPermission.split(",");
    secondArray.forEach((requiredPermission) => {
      if (userPermissions.includes(requiredPermission)) {
        response = false;
      }
    });
    return response;
  }, [permission, userPermissions, withoutPermission]);

  return <Fragment>{isAllowed && children}</Fragment>;
};
