/** @jsx jsx */
import React, { Fragment, useEffect, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { ErrorResponse, ISdkManagedPaymentIntent, IPaymentIntent } from '@stripe/terminal-js';
import { Button, getIcon } from '@bondsports/utils';

import { organizationApi } from '../../../lib/api/organizationApi';
import { colors } from 'app/react/styles/theme';
import { paymentApi } from 'app/react/lib/api/paymentApi';
import { useStation } from 'app/react/hooks/useStation';
import { useLayout } from 'app/react/hooks/useLayout';
import { useOrganization } from 'app/react/hooks/useOrganization';
import { NotificationApi } from 'app/react/lib/api/notificationsApi';
import { Mixpanel, MixpanelEvents } from 'app/react/lib/mixpanel';
import { BnTERMINAL } from '../../../types/station';
import { EPaymentMethod } from 'app/react/types/payment';
import { TranslationEn } from '@assets/i18n/en';
import { PlatformsEnum } from '@bondsports/types';

const errMessage = css`
	color: ${colors.dangerRed};
	font-weight: 500;
	font-size: 1.4rem;
	font-family: Montserrat;
	line-height: 1.7rem;
	margin-top: 0.5rem;
`;

const messageLabels = css`
	${errMessage};
	width: 100%;
	text-align: center;
	color: ${colors.formInputBg};
`;

// we need amount, currency, customer
interface Props {
	amount: number;
	currency: 'usd';
	userId: number;
	handleSwipeReady: (val: string) => void;
	triggerPay: boolean;
	handleError: (message: string) => void;
	paymentAction: (val: string, val2: string) => void;
}

export const SwipeButton = ({
	amount,
	currency,
	userId,
	handleSwipeReady,
	triggerPay,
	paymentAction,
	handleError,
}: Props) => {
	const {
		isTerminalSet,
		setSwipeAllowed,
		isTerminalLoading: isLoading,
		isSwipeAllowed,
		clearTerminalDisplay,
		initiateTerminal,
	} = useStation();

	const { disabledScreenToggle } = useLayout();
	const { organizationId } = useOrganization();
	const [isCreatingPaymentIntent, setCreatingPaymentIntent] = useState(false);
	const [isPaymentIntentReady, setPaymentIntentReady] = useState(false);
	const [clientSecret, setClientSecret] = useState('');
	const labels = TranslationEn.terminal;

	useEffect(() => {
		return () => {
			clearTerminalDisplay();
		};
	}, []);

	useEffect(() => {
		if (clientSecret) {
			setPaymentIntentReady(true);
			handleSwipeReady(clientSecret);
		}
	}, [clientSecret]);

	useEffect(() => {
		if (triggerPay) {
			collectPaymentMethod(clientSecret);
		}
	}, [triggerPay]);

	const processPayment = (paymentInt: ISdkManagedPaymentIntent) => {
		window[BnTERMINAL]?.processPayment(paymentInt).then(result => {
			if ((result as ErrorResponse).error) {
				// Placeholder for handling result.error
				console.log('error in process payments ', (result as ErrorResponse).error);
				handleError((result as ErrorResponse).error.message);
				NotificationApi.TerminalError({ ...result, organizationId });
				disabledScreenToggle(false);
			} else if ((result as { paymentIntent: IPaymentIntent }).paymentIntent) {
				const theData = (result as { paymentIntent: IPaymentIntent }).paymentIntent.charges.data;
				if (theData && theData.length > 0) {
					if (theData[0].payment_method_details.card_present) {
						//const card = theData[0].payment_method_details.card_present;
						// on terminal we need to pass the intent since we're NOT really dealing
						// with the actual card
						paymentAction(paymentInt.id, EPaymentMethod.CARD_ON_TERMINAL);
					}
				}
			}
		});
	};

	const collectPaymentMethod = (clientSecret: string) => {
		window[BnTERMINAL]?.setSimulatorConfiguration({
			testCardNumber: '4242424242424242',
		});
		// clientSecret is the client_secret from the PaymentIntent you created in Step 1.
		window[BnTERMINAL]?.collectPaymentMethod(clientSecret).then(result => {
			if ((result as ErrorResponse).error) {
				// Placeholder for handling result.error
				disabledScreenToggle(false);
				console.log((result as ErrorResponse).error);
				handleError((result as ErrorResponse).error.message);
				NotificationApi.TerminalError({ ...result, organizationId });
			} else {
				disabledScreenToggle(true);
				processPayment((result as { paymentIntent: ISdkManagedPaymentIntent }).paymentIntent);
			}
		});
	};

	const handleSwiping = async () => {
		Mixpanel.track(MixpanelEvents.PICK_TERMINAL, {});
		const customerId = userId ?? (await organizationApi.getAnonymousCustomer(organizationId));

		setCreatingPaymentIntent(true);
		const intentObj = {
			amount: amount * 100,
			customer: customerId,
			currency,
			payment_method_types: ['card_present'],
			capture_method: 'manual',
			organizationId,
			platform: PlatformsEnum.BO,
		};
		// create payment intent
		paymentApi.createPaymentIntent(intentObj).then(res => {
			if (res.err) {
				const errorMessage: string = Array.isArray(res.err) ? res.err[0] : res.err;
				handleError(errorMessage);
				setSwipeAllowed(false);
			} else {
				setClientSecret(res.client_secret);
			}
		});
	};

	useEffect(() => {
		if (!isSwipeAllowed && isTerminalSet) {
			initiateTerminal();
		}
	}, []);

	if (isLoading) {
		return <Fragment></Fragment>;
	}

	return (
		<Fragment>
			{isTerminalSet ? (
				isSwipeAllowed ? (
					isCreatingPaymentIntent ? (
						isPaymentIntentReady ? (
							<div css={messageLabels}>{labels.swipe.callToAction}</div>
						) : (
							<div css={messageLabels}>{TranslationEn.isLoading}</div>
						)
					) : (
						<Button data-aid="button-swipeButton" theme="basic" sizer="S" onClick={handleSwiping}>
							{getIcon('credit_card', 'r')}
							{TranslationEn.payments.swipeCard}
						</Button>
					)
				) : (
					<label css={errMessage}>{labels.errors.failedToConnect}</label>
				)
			) : undefined}
		</Fragment>
	);
};
