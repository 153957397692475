/** @jsx jsx */
import { useContext, useEffect } from 'react';
import { jsx, css } from '@emotion/react';

import { EMERGENCY_CONTACT_NAME, EMERGENCY_CONTACT_PHONE, IToggleProps } from '../familyAccount/formBody/types';
import { FormContext } from '../../../components/shared/form';
import { FormSection } from './utils/FormSection';
import { useToggle } from '../../../hooks/useToggle';
import { ICustomer } from '../../../types/customers';
import { TranslationEn } from '@assets/i18n/en';

interface IProps {
	formvalues?: { emergencyContactName?: string; emergencyContactPhone?: string };
	withToggle?: IToggleProps;
	customerState?: ICustomer;
}

export const EmergencyContact = ({ formvalues, withToggle = null, customerState }: IProps) => {
	const [isActive, setIsActive] = useToggle();
	const { values: contextValues, form } = useContext(FormContext);
	const values = contextValues || formvalues;
	const labels = TranslationEn.customers.editFamilyAccount.emergencyContact;
	const { title, phoneNo, contactName } = labels;

	const emergencyContactInputs = [
		{ name: EMERGENCY_CONTACT_NAME, label: `${contactName} ${values.emergencyContactPhone ? '*' : ''}` },
		{ name: EMERGENCY_CONTACT_PHONE, label: `${phoneNo} ${values.emergencyContactName ? '*' : ''}` },
	];

	useEffect(() => {
		const { onSelect } = form?.mutators ?? {};
		//temp solution until we refactor create/edit individual account
		if (!onSelect || !withToggle) {
			return;
		}
		if (customerState && isActive) {
			const { name, phoneNumber } = customerState.emergencyContacts[0] ?? {};
			onSelect(EMERGENCY_CONTACT_NAME, name);
			onSelect(EMERGENCY_CONTACT_PHONE, phoneNumber);
		} else {
			onSelect(EMERGENCY_CONTACT_NAME, '');
			onSelect(EMERGENCY_CONTACT_PHONE, '');
		}
	}, [customerState, isActive]);

	return (
		<FormSection
			detailsInputs={emergencyContactInputs}
			title={title}
			isWithToggle={!!withToggle}
			toggleLabel={withToggle?.toggleLabel}
			isActive={isActive}
			setIsActive={setIsActive}
		/>
	);
};
