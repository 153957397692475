import React from "react";

export const Program_Camps = (props: any) => (
  <svg
    data-aid="svgProgram_Camps"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.6667 19.5557V45.7076L59.9644 58.7645C60.4 58.9868 60.5689 59.529 60.3466 59.9645C60.1866 60.2668 59.8755 60.4445 59.5555 60.4445C59.4222 60.4445 59.28 60.409 59.1466 60.3468L32 46.329L4.85331 60.3468C4.40886 60.569 3.88442 60.4001 3.65331 59.9645C3.43108 59.529 3.59997 58.9957 4.03553 58.7645L29.3333 45.7076V4.44455C29.3333 3.95566 29.7333 3.55566 30.2222 3.55566H33.7778C34.2667 3.55566 34.6667 3.95566 34.6667 4.44455V7.11122H43.5555C44.0444 7.11122 44.4444 7.51122 44.4444 8.00011V18.6668H44.4431C44.4431 18.8974 44.3502 19.1255 44.1778 19.2979L41.2533 22.2223H52.96L48.2578 17.5201C47.9111 17.1734 47.9111 16.6134 48.2578 16.2668L52.9689 11.5557H46.2222V9.77789H55.1111C55.4667 9.77789 55.7955 9.99122 55.9289 10.329C56.0711 10.6579 55.9911 11.0401 55.7333 11.2979L50.1422 16.889L55.7333 22.4801C55.9911 22.7379 56.0622 23.1201 55.9289 23.449C55.7955 23.7868 55.4667 24.0001 55.1111 24.0001H39.1111C38.7555 24.0001 38.4267 23.7868 38.2933 23.449C38.2462 23.3401 38.2235 23.2254 38.2235 23.1112H38.2222V19.5557H34.6667ZM40 19.5557V20.969L41.4133 19.5557H40ZM34.6667 17.7779V8.889H42.6666V17.7779H34.6667ZM32.8889 5.33344V44.7901L32.4089 44.5423C32.1511 44.409 31.8489 44.409 31.5911 44.5423L31.1111 44.7901V5.33344H32.8889Z"
      fill="currentColor"
    />
  </svg>
);
