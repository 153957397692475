/** @jsxRuntime classic*/
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { IColors, ITypography } from '../../types/theme';

export const containerCss = (colors: IColors, typography: ITypography) => css`
	padding: 6px 10px;
	background: ${colors.bg_background_fields};
	border-radius: 2px;
	display: flex;
	flex-wrap: wrap;
	input {
		color: ${colors.tx_text_teriaty};
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0px;
		text-align: left;

		background: transparent;
		border: none;
		margin: 2px;

		outline: none;
	}
`;

export const labelTagCss = (
	colors: IColors,
	typography: ITypography,
	size: 'S' | 'XS' = 'S',
	isDisabled?: boolean
) => css`
	background: ${isDisabled ? colors.tx_text_disabled : colors.bg_text_teriaty};
	border-radius: 2px;
	padding: 3px 8px;
	font-family: ${typography.fontPrimary};
	color: ${colors.tx_white};
	margin: 2px;
	display: flex;
	align-items: center;
	font-size: ${size === 'XS' ? 12 : 14}px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0px;
	text-align: left;
	text-transform: capitalize;
	width: fit-content;
	button {
		border: none;
		background: none;
		padding: 0;
		cursor: ${!isDisabled && 'pointer'};
		display: flex;
		align-items: center;
		margin-left: 4px;
		svg {
			height: 12px;
			width: 12px;
			color: ${colors.tx_white};
		}
	}
`;

export const marginBottom = css`
	margin-bottom: 8px;
`;

export const marginTop = css`
	margin-top: 4px;
`;
