import { min } from 'lodash';
import { roundPriceCents } from '@bondsports/utils';

export function getLowestProductPrice(prices: number[]) {
	return min(prices);
}

export function calculatePriceWithPercentageAdjustment(price: number, amount: number): number {
	return roundPriceCents(price * ((100 + amount) / 100));
}

/**
 * Calculates the price including tax for a given price, tax percentage, and quantity.
 * @param price The price of a single item.
 * @param taxPrecent The tax percentage to apply (optional, defaults to 0).
 * @param quantity The quantity of items to calculate the price for (optional, defaults to 1).
 * @returns The price including tax.
 */
export function calculatePriceWithTax(price: number, taxPrecent?: number, quantity = 1): number {
	const totalPrice: number = roundPriceCents(price * quantity);
	return roundPriceCents(((100 + (taxPrecent ?? 0)) / 100) * totalPrice);
}

/**
 * Calculates the total price of an item including tax.
 * @param price The price of the item.
 * @param isTaxInclusive Whether the price already includes tax.
 * @param taxPrecent The tax percentage to apply, if `isTaxInclusive` is `false`.
 * @param quantity The quantity of items being purchased.
 * @returns The total price, including tax.
 */
export function calculateTotalPriceWithTax(
	price: number,
	isTaxInclusive: boolean,
	taxPrecent?: number,
	quantity = 1
): number {
	return isTaxInclusive ? roundPriceCents(price * quantity) : calculatePriceWithTax(price, taxPrecent, quantity);
}
