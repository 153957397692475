import React from 'react';

export const Apperal = (props: any) => (
  <svg
    data-aid="svgApperal"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7368 5.4197C21.8483 5.4714 21.9339 5.56635 21.9751 5.6818C22.0149 5.79861 22.007 5.92469 21.9512 6.03494L20.2952 9.30178C20.2167 9.45683 20.0597 9.54988 19.8932 9.54988C19.8503 9.54988 19.8067 9.54366 19.7636 9.53073L17.9238 8.98581V19.9471C17.9238 20.196 17.7213 20.3991 17.4724 20.3991H6.52768C6.27809 20.3991 6.07499 20.196 6.07499 19.9471V8.98567L4.23645 9.5306C4.1934 9.54352 4.14975 9.54975 4.10671 9.54975C3.93996 9.54975 3.78234 9.45669 3.70485 9.30165L2.0495 6.0348C1.99238 5.92462 1.98507 5.79855 2.02561 5.68174C2.0648 5.56628 2.15101 5.47133 2.26322 5.41963L6.07493 3.65945C6.88731 3.38468 7.71363 3.16765 8.54794 3.00902C8.57731 3.00326 8.60654 3.00049 8.63537 3.00049C8.84178 3.00049 9.0264 3.14308 9.07297 3.3522C9.37757 4.6936 10.565 5.69778 11.9999 5.69778C13.4349 5.69778 14.6223 4.69353 14.927 3.3522C14.973 3.14308 15.158 3.00049 15.3645 3.00049C15.3933 3.00049 15.4226 3.00326 15.452 3.00902C16.2863 3.16765 17.1126 3.38468 17.9237 3.65945L21.7368 5.4197ZM13.8835 3.31787C13.6147 4.13045 12.8664 4.68282 12.0001 4.68282C11.1337 4.68282 10.3855 4.13039 10.1168 3.31787C10.6296 3.45904 11.2833 3.54425 12.0001 3.54425C12.717 3.54425 13.3707 3.45904 13.8835 3.31787Z"
      fill="currentColor"
    />
  </svg>
);
