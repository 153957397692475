/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import { OneOpenNoteProps } from '../../types';
import { Column, Row } from '../../../../atoms/layoutPrimitives';
import { Typography } from '../../../../atoms';
import { ETypography, ETypographyColor, Icons } from '../../../../types';
import { Tooltip } from '../../../../components/Tooltip';
import { BnIcon } from '../../../../components/icons_v2';
import { hyperlinkCss } from '../../styles';
import { OpenNote } from '../open-note';

export const OneOpenNote: FC<OneOpenNoteProps> = ({ value, labels, onChange, onClick }) => (
	<Column gap="0.5rem" fullWidth align="flex-start">
		<OpenNote labels={labels} openNote={labels.openNote} value={value} onChange={onChange} />
		<Row gap="0.5rem">
			<div css={hyperlinkCss} onClick={onClick} data-aid="index-one-open-note">
				<Typography color={ETypographyColor.primary} type={ETypography.captionLink}>
					{labels.closedNote}
				</Typography>
			</div>
			<Tooltip isTopLayer content={labels.tooltip}>
				<BnIcon icon={Icons.info} />
			</Tooltip>
		</Row>
	</Column>
);
