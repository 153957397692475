import { useState } from 'react';

export const useGridDimensions = (avgCellWidth: number) => {
	const [dimensions, setDimensions] = useState({
		columns: Math.floor(window.innerWidth / avgCellWidth) || 1,
		width: 0,
		height: 0,
	});

	const handleResize = (width: number, height: number) => {
		const columns = Math.floor(width / avgCellWidth) || 1;
		setDimensions({
			columns,
			width,
			height,
		});
	};

	const setNewParentDimensions = (width: number, height: number) => {
		if (dimensions.width !== width || dimensions.height !== height) handleResize(width, height);
	};

	return { dimensions, setNewParentDimensions };
};
