/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import dayjs from 'dayjs';
import { Fragment, useCallback, useState } from 'react';
import { ContainerWithIcon } from '../../components/containerWithIcon';
import { useColors } from '../../hooks/useColors';
import { EBrandingColorsOptions, ETypography, ETypographyColor, IColors, Icons } from '../../types/theme';
import utc from 'dayjs/plugin/utc';
import { useTimeFormats } from '../../hooks/useTimeFormats';
import { Button } from '../../components/Button';
import { BnIcon } from '../../components/icons_v2';
import { Avatar, Typography } from '../../atoms';
import { Tag } from '../../components/Tag';
import { IOption, sportsIcons } from '../../types';
import { genericIconCss, maintenanceIconCss } from './style';
import { ITimeSlot, PaymentStatusEnum, ReservationStatusEnum } from './types';
import { Loader } from '../../components/Loader';
import { Popup } from '../../components/Popup';
import { SelectOptions } from '../../components/Options';
import { Pricify } from '../../lib/price';

dayjs.extend(utc);

const closeButton = css`
	position: absolute;
	top: 16px;
	right: 16px;
`;

const slotHeader = (colors: IColors) => css`
	display: flex;
	padding: 36px 40px 24px 40px;
	gap: 8px;
	border-bottom: 1px solid ${colors.bg_border_seperator};

	.left {
		color: ${colors.tx_text_primary};
		svg {
			width: 24px;
			height: 24px;
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;

const dividerCss = (colors: IColors) => css`
	border-bottom: 1px solid ${colors.bg_border_seperator};
`;

const bodyCss = css`
	padding: 24px 40px;
	display: flex;
	flex-direction: column;
	.line {
		display: flex;
		align-items: center;
		div {
			margin-right: 4px;
		}
	}
`;

const footerCss = (colors: IColors) => css`
	border-top: 1px solid ${colors.bg_border_seperator};

	display: flex;
	padding: 16px 40px;
	justify-content: space-between;
`;

const flexCol = css`
	display: flex;
	flex-direction: column;
`;

const gap = (num: number) => css`
	display: flex;
	gap: ${num}px;
`;

const titleCss = css`
	display: flex;
	${gap(8)};
`;

const privateComponent = (colors: IColors) => css`
	display: flex;
	align-items: center;
	${gap(4)};
	color: ${colors.tx_text_primary};
`;

const fourGap = css`
	display: flex;
	${gap(4)};
`;

const reservationDetails = css`
	display: flex;
	${gap(8)};
	position: relative;
`;

const cornerIconCss = (colors: IColors) => css`
	position: absolute;
	transform: translateX(-100%);
	left: -12px;

	svg {
		width: 16px;
		height: 16px;
		color: ${colors.tx_text_secondary};
	}
`;

const loaderContainerCss = css`
	display: flex;
	justify-content: center;
	padding: 5rem 0;
`;

const reservationNumberCss = css`
	padding-left: 0px !important;
`;

const conflictCss = (colors: IColors) => css`
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 4px;
	div {
		color: ${colors.tx_error};
	}
	svg {
		color: ${colors.tx_error};
		height: 10px;
		width: 10px;
	}
`;

export const SlotBody = ({
	state,
	toggle,
	isEditable,
	triggerEvent,
	expandedData,
	moreOptions,
	handleActions,
	goToReservation,
	goToConflicts,
}: {
	state: any;
	toggle: () => void;
	isEditable: boolean;
	triggerEvent: (v: any) => void;
	expandedData?: any;
	moreOptions?: IOption[];
	handleActions?: (v: string, state: any) => void;
	goToReservation?: (reservationId: number) => void;
	goToConflicts?: (val: ITimeSlot) => void;
}) => {
	const { colors } = useColors();
	const { generateDuration } = useTimeFormats();
	const diff = dayjs
		.utc(expandedData?.endTime, 'HH:mm:ss')
		.diff(dayjs.utc(expandedData?.startTime, 'HH:mm:ss'), 'minutes');

	const isExternal = expandedData?.slotType === 'external' || expandedData?.slotType === 'rental';
	const isMaintenance = expandedData?.slotType === 'maintenance';

	const onReservationNumberClick = () => {
		if (goToReservation) goToReservation(expandedData.reservation.id);
	};

	const Header = useCallback(() => {
		return (
			<div data-aid="popupBody-header" css={slotHeader(colors)}>
				<div className="left">
					{isMaintenance ? (
						<BnIcon icon={Icons.maintenance} css={maintenanceIconCss} className="sub" />
					) : (
						expandedData?.sportIds?.[0] && (
							<BnIcon icon={sportsIcons[expandedData?.sportIds?.[0]]} css={maintenanceIconCss} className="sub" />
						)
					)}
				</div>
				<div className="right">
					<div css={titleCss}>
						<Typography color={ETypographyColor.primary} type={ETypography.h4}>
							{expandedData?.title}
						</Typography>
						{expandedData?.isPrivate && (
							<div css={privateComponent(colors)}>
								<BnIcon className="main" icon={Icons.lock_filled} css={genericIconCss(12)} />
								<Typography color={ETypographyColor.primary} type={ETypography.caption}>
									Private
								</Typography>
							</div>
						)}
						{expandedData?.paymentStatus === 'purchase_order' && isExternal && (
							<div css={privateComponent(colors)}>
								<Tag type="outline" color="yellow" title="HOLD" />
							</div>
						)}
					</div>
					{expandedData?.reservation && (
						<div css={reservationDetails}>
							{expandedData?.slotType === 'maintenance' && expandedData?.parentSlotId && (
								<div css={cornerIconCss(colors)}>
									<BnIcon icon={Icons.corner_down_right} />
								</div>
							)}
							<div css={fourGap}>
								<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
									Reservation
								</Typography>
								<Button data-aid="button-popupBody-res" sizer="XS" theme="basic" onClick={onReservationNumberClick} css={reservationNumberCss}>
									<Typography color={ETypographyColor.primary} type={ETypography.captionLink}>
										#{expandedData.reservation.id}
									</Typography>
								</Button>
							</div>
							{expandedData.slotType === 'external' && (
								<div css={fourGap}>
									{expandedData.approvalStatus === ReservationStatusEnum.APPROVED ? (
										<Tag title="Approved" color="green" />
									) : (
										<Tag title={expandedData.approvalStatus} color="yellow" />
									)}
									{expandedData.paymentStatus === PaymentStatusEnum.FULLY_PAID && <Tag title="Paid" color="green" />}
									{expandedData.paymentStatus === PaymentStatusEnum.NOT_PAID && <Tag title="Unpaid" color="red" />}
									{expandedData.paymentStatus === PaymentStatusEnum.PARTIAL_PAYMENT && (
										<Tag title="Partialy paid" color="yellow" />
									)}
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}, [expandedData]);

	const frequencyMapper = {
		daily: 'Daily',
		weekly: 'Weekly',
		monthly: 'Monthly',
	};

	// missing the repeat on days
	const TimeFrame = useCallback(() => {
		return (
			<div data-aid="popupBody-timeFrame">
			<ContainerWithIcon icon={Icons.clock}>
				<div css={[gap(4), flexCol]}>
					<div className="line">
						<Typography color={ETypographyColor.primary} type={ETypography.body2}>
							{dayjs.utc(expandedData?.startDate).format('ddd, MMM DD')}
						</Typography>
						<Typography color={ETypographyColor.primary} type={ETypography.body2}>
							{dayjs.utc(expandedData?.startTime, 'HH:mm:ss').format('hh:mma')}
						</Typography>
						<Typography color={ETypographyColor.primary} type={ETypography.body2}>
							-
						</Typography>
						<Typography color={ETypographyColor.primary} type={ETypography.body2}>
							{dayjs.utc(expandedData?.endTime, 'HH:mm:ss').format('hh:mma')}
						</Typography>
						<Typography color={ETypographyColor.teriaty} type={ETypography.overlineAccented}>
							{`( ${generateDuration(diff)} )`}
						</Typography>
					</div>
					{expandedData?.series?.frequency !== 'none' && (
						<div className="line">
							<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
								{frequencyMapper[expandedData?.series?.frequency]}
							</Typography>
							<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
								{expandedData?.slotIndexInSeries || 1}/{expandedData?.totalSlotsInSeries}
							</Typography>
						</div>
					)}
				</div>
			</ContainerWithIcon>
			</div>
		);
	}, [expandedData]);

	const Customer = useCallback(() => {
		return (
			<div data-aid="popupBody-customer" className="line" css={gap(10)}>
				<Avatar img={expandedData?.reservation?.customer?.mainMedia || ''} />
				<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
					{expandedData?.reservation?.customer?.name}
				</Typography>
			</div>
		);
	}, [expandedData]);

	const Location = useCallback(() => {
		const displayPrice = expandedData?.totalPrice || expandedData?.productMetadata?.price || 0;
		const handleGoToConflicts = () => {
			const timeSlot: ITimeSlot = {
				resourceId: expandedData.spaceId,
				startTime: state.overallStartTime ?? expandedData.event.startTime,
				endTime: state.overallEndTime ?? expandedData.event.endTime,
				startDate: dayjs(expandedData.event.startDateString).format('YYYY-MM-DD'),
				endDate: dayjs(expandedData.event.endDateString).format('YYYY-MM-DD'),
				relevantSlotId: expandedData.id,
			};
			goToConflicts && goToConflicts(timeSlot);
		};
		return (
			<div data-aid="popupBody-location">			
			<Fragment>
				{expandedData?.resource?.name && (
					<ContainerWithIcon icon={Icons.location}>
						<div className="line" css={gap(12)}>
							<Typography color={ETypographyColor.primary} type={ETypography.body2}>
								{expandedData?.resource?.name}
							</Typography>
							{expandedData?.price?.unitPrice && (
								<Typography color={ETypographyColor.teriaty} type={ETypography.overlineAccented}>
									{Pricify(displayPrice)}
								</Typography>
							)}
							{expandedData?.conflictCount > 0 && (
								<div css={conflictCss(colors)} onClick={handleGoToConflicts}>
									<BnIcon icon={Icons.conflict} />
									<Typography type={ETypography.captionLink}>{`View conflicts`}</Typography>
								</div>
							)}
						</div>
					</ContainerWithIcon>
				)}
			</Fragment>
			</div>
		);
	}, [expandedData]);

	const Notes = useCallback(() => {
		return (
			<div data-aid="popupBody-notes">
			<Fragment>
				{expandedData?.privateNotes && (
					<ContainerWithIcon icon={Icons.note}>
						<Typography color={ETypographyColor.primary} type={ETypography.body2}>
							{expandedData?.privateNotes}
						</Typography>
					</ContainerWithIcon>
				)}
				{expandedData?.publicNotes && (
					<ContainerWithIcon icon={Icons.note_filled}>
						<Typography color={ETypographyColor.primary} type={ETypography.body2}>
							{expandedData?.publicNotes}
						</Typography>
					</ContainerWithIcon>
				)}
			</Fragment>
			</div>
		);
	}, [expandedData]);

	const Addons = useCallback(() => {
		return (
			<div data-aid="popupBody-addons">
			<Fragment>
				{expandedData?.addons?.length > 0 && (
					<ContainerWithIcon icon={Icons.add_ons}>
						<Typography color={ETypographyColor.teriaty} type={ETypography.overlineAccented}>
							Add-Ons
						</Typography>
						{expandedData?.addons?.map((addon: any, index: number) => {
							return (
								<div className="line" css={gap(12)} key={index}>
									<Typography color={ETypographyColor.primary} type={ETypography.body2}>
										{addon?.product?.name}
									</Typography>
									{!(expandedData?.paymentStatus === 'purchase_order' && isExternal) && (
										<Typography color={ETypographyColor.teriaty} type={ETypography.overlineAccented}>
											{addon?.totalPrice ? Pricify(addon.totalPrice) : ''}
										</Typography>
									)}
								</div>
							);
						})}
					</ContainerWithIcon>
				)}
			</Fragment>
			</div>
		);
	}, [expandedData]);

	const handleMoreMenuActions = (v: string) => {
		if (handleActions) {
			handleActions(v, state);
		}
	};

	return (
		<div data-aid="popupBody-more" style={{ minWidth: '500px', position: 'relative' }}>
			<div css={closeButton}>
				<Button data-aid="button-popupBody-toggle" sizer="S" theme="basic" onClick={toggle}>
					<BnIcon icon={Icons.close} />
				</Button>
			</div>
			{expandedData ? (
				<Fragment>
					<Header />
					<div data-aid="popupBody-body" css={bodyCss}>
						{isExternal && <Customer />}
						<TimeFrame />
						<Location />
						<Notes />
						<Addons />
					</div>
					<div data-aid="popupBody-footer" css={footerCss(colors)}>
						<div>
							{moreOptions && handleActions && <MoreMenu options={moreOptions} handleActions={handleMoreMenuActions} />}
						</div>
						{isEditable && (
							<Button data-aid="button-popupBody-edit" sizer="S" theme="primary" onClick={() => triggerEvent(state)}>
								edit
							</Button>
						)}
					</div>
				</Fragment>
			) : (
				<div css={loaderContainerCss}>
					<Loader width={50} margin="40px" color={EBrandingColorsOptions.bg_system} />
				</div>
			)}
		</div>
	);
};

const MoreMenu = ({ options, handleActions }: { options: IOption[]; handleActions: (v: string) => void }) => {
	const [isOpen, setOpen] = useState<boolean>(false);

	return (
		<Popup
			disableScroll
			placement="bottom-start"
			padding={0}
			trigger={isOpen}
			onOpenChange={v => setOpen(v)}
			body={<SelectOptions options={options} onSelect={v => handleActions(v as string)} value={''} />}
		>
			<Button data-aid="button-popupBody-drop" sizer="S" theme="basic">
				More options <BnIcon icon={isOpen ? Icons.dropdown_active : Icons.dropdown} />
			</Button>
		</Popup>
	);
};

// export const SlotBody = ({
// 	state,
// 	toggle,
// 	isEditable,
// 	triggerEvent,
// }: {
// 	state: any;
// 	toggle: () => void;
// 	isEditable: boolean;
// 	triggerEvent: (v: any) => void;
// }) => {
// 	const { generateDuration } = useTimeFormats();
// 	const { colors } = useColors();

// 	const diff = dayjs.utc(state.endTime).diff(dayjs.utc(state.startTime), 'minutes');

// 	const reservationStatusMapper = {
// 		active: 'green',
// 	};

// 	const isProgram = state?.reservation?.creatorType === 'program_season';

// 	console.log({ state });

// 	return (

// 			<div css={dividerCss(colors)} />
// 			<div css={bodyCss}>

// 				{state?.publicNotes && (
// 					<ContainerWithIcon icon={Icons.note_filled}>
// 						<Typography color={ETypographyColor.primary} type={ETypography.body2}>
// 							{state?.publicNotes}
// 						</Typography>
// 					</ContainerWithIcon>
// 				)}
// 				{/* <ContainerWithIcon icon={Icons.add_ons}>
//           <Typography
//             color={ETypographyColor.teriaty}
//             type={ETypography.overlineAccented}
//           >
//             Add-Ons
//           </Typography>
//           <div className="line">
//             <Typography
//               color={ETypographyColor.primary}
//               type={ETypography.body2}
//             >
//               Filed Sixth 2
//             </Typography>
//             <Typography
//               color={ETypographyColor.teriaty}
//               type={ETypography.overlineAccented}
//             >
//               $120/Hr
//             </Typography>
//           </div>
//         </ContainerWithIcon> */}
// 			</div>
// 			<div css={dividerCss(colors)} />
// 			{isEditable && (
// 				<div css={footerCss}>
// 					<Button sizer="S" theme="primary" onClick={() => triggerEvent(state)}>
// 						edit
// 					</Button>
// 				</div>
// 			)}
// 		</div>
// 	);
// };
