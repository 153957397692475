/** @jsx jsx */
import React, { ChangeEvent } from 'react';
import { jsx, css } from '@emotion/react';
import { MembershipTypeEnum } from '@bondsports/types';

import { CustomCheckbox } from '../../../shared/Checkbox';
import { useMembershipTab } from '../../../../hooks/memberships/useMembershipTab';
import { colors } from '../../../../styles/theme';
import { flex, checkboxCss, CheckedIconCss, iconCss, flexCol } from '../../../../styles/utils';
import {
	MembershipStatusFilterEnum,
	PaymentStatusFilterEnum,
	TMembershipFilterValue,
} from '../../../../types/membership';
import { TranslationEn } from '@assets/i18n/en';

const filterContainer = css`
	${flexCol};
	width: 200px;
	margin-top: 1rem;
	padding: 12px 0 8px 11px;
	font-family: Montserrat;
	font-size: 1.4rem;
	line-height: 130%;
	color: ${colors.brandPrimary};
	label {
		font-family: Montserrat;
		font-style: normal;
		font-weight: 500;
		font-size: 1.2rem;
		line-height: 1.5rem;
		color: ${colors.lightText};
	}
	hr {
		color: ${colors.lightText};
		width: 100%;
	}
`;

type TFilterSectionEnum =
	| typeof MembershipStatusFilterEnum
	| typeof PaymentStatusFilterEnum
	| typeof MembershipTypeEnum;

interface IFilterSectionProps {
	filterSectionEnum: TFilterSectionEnum;
	labels: { title: string } & { [key: string]: string };
	filters: string[];
	setFilters: (val: string[]) => void;
	onFilterChange: (ev: ChangeEvent<HTMLInputElement>, filters: string[], setFilters: (arr: any[]) => void) => void;
}

interface FilterProps {
	statusFilter: string[];
	paymentFilter: string[];
	renewalFilter: string[];
	setStatus: (val: TMembershipFilterValue[]) => void;
	setPayment: (val: TMembershipFilterValue[]) => void;
	setRenewal: (val: TMembershipFilterValue[]) => void;
}

const FilterFieldSection = ({
	filterSectionEnum,
	labels,
	filters,
	setFilters,
	onFilterChange,
}: IFilterSectionProps) => {
	return (
		<section>
			<label>{labels.title}</label>
			{Object.values(filterSectionEnum).map(value => {
				return (
					<div key={value} css={flex}>
						<CustomCheckbox
							css={checkboxCss}
							checkedIcon={<span id="checked" css={CheckedIconCss} />}
							icon={<span css={iconCss} />}
							checked={filters.includes(value)}
							onChange={ev => onFilterChange(ev, filters, setFilters)}
							name={value}
						/>
						<div>{labels[value]}</div>
					</div>
				);
			})}
		</section>
	);
};

export const MembershipFilter = ({
	statusFilter,
	paymentFilter,
	renewalFilter,
	setStatus,
	setPayment,
	setRenewal,
}: FilterProps) => {
	const { onFilterChange } = useMembershipTab();
	const labels = TranslationEn.customers.membershipTab.filterOptions;

	return (
		<div css={filterContainer}>
			<FilterFieldSection
				labels={labels.membershipStatus}
				filterSectionEnum={MembershipStatusFilterEnum}
				filters={statusFilter}
				setFilters={setStatus}
				onFilterChange={onFilterChange}
			/>
			<hr />
			<FilterFieldSection
				labels={labels.paymentStatus}
				filterSectionEnum={PaymentStatusFilterEnum}
				filters={paymentFilter}
				setFilters={setPayment}
				onFilterChange={onFilterChange}
			/>
			<hr />
			<FilterFieldSection
				labels={labels.renewalType}
				filterSectionEnum={MembershipTypeEnum}
				filters={renewalFilter}
				setFilters={setRenewal}
				onFilterChange={onFilterChange}
			/>
		</div>
	);
};
