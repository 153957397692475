import { ERoutePaths as EPath } from '../types/navigation';

const NEW_FAMILY_ACCOUNT = 'new/family';
const DASHBOARD = 'dashboard';

const getInvoicePaymentPath = (customerId: number) => `${EPath.CUSTOMER}/${customerId}/${EPath.PAYMENTS}`;
const getCustomerPagePath = (customerId: number) => `/${EPath.CUSTOMER}/${customerId}/${EPath.OVERVIEW}`;
const getFamilyPagePath = ({
	familyId,
	isLeadingSlash = true,
}: {
	familyId: number | string;
	isLeadingSlash?: boolean;
}) => `${isLeadingSlash ? '/' : ''}${EPath.FAMILY}/${familyId}/${EPath.OVERVIEW}`;

export { getInvoicePaymentPath, getCustomerPagePath, getFamilyPagePath, NEW_FAMILY_ACCOUNT };

export const getMembershipByIdPath = (id: number) => `${DASHBOARD}/${id}`;
export const getInvoiceByIdPath = (id: number, param?: string) =>
	`${EPath.INVOICES}/${id}/${EPath.DETAILS}${param ? '?' : ''}${param || ''}`;
