/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useEffect, useRef, Fragment } from 'react';
import { useColors } from '../../hooks/useColors';
import { IOption } from '../../types';
import { containerCss, marginBottom, marginTop } from './style';
import { jsx } from '@emotion/react';
import { useTypography } from '../../hooks/useTypography';
import { ETypography, ETypographyColor } from '../../types/theme';
import { Typography } from '../../atoms/Typography';
import { Popup } from '../../components/Popup';
import { SelectOptions } from '../../components/Options';
import { LabelTag } from '../LabelTag';

export interface ITagsProps {
	options: IOption[];
	label?: string;
	bottomInfo?: string;
	selected: string[];
	handleChange: (value: string) => void;
	editCallback?: (value: string) => void;
	infiniteScroll?: boolean;
	handleLoadNextPage?: () => void;
	isLoading?: boolean;
	isFinalPage?: boolean;
}

export const TagsInput = ({
	options,
	selected,
	handleChange,
	label = '',
	bottomInfo = '',
	editCallback,
	infiniteScroll = false,
	handleLoadNextPage,
	isLoading,
	isFinalPage,
}: ITagsProps) => {
	const [searchPhrase, setSearchPhrase] = useState<string>('');
	const [isOpen, setOpen] = useState<boolean>(false);
	const { colors } = useColors();
	const { typography } = useTypography();
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (searchPhrase) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [searchPhrase]);

	const handleAddition = (v: string) => {
		setSearchPhrase('');
		setOpen(false);
		handleChange(v);
		inputRef?.current?.focus();
	};

	return (
		<Fragment>
			{label && (
				<div css={marginBottom} data-aid="index-tags">
					<Typography color={ETypographyColor.secondary} type={ETypography.captionAccented}>
						{label}
					</Typography>
				</div>
			)}
			<Popup
				fullWidth
				body={
					<SelectOptions
						options={options.filter(opt => opt.label.toLowerCase().includes(searchPhrase.toLowerCase()))}
						value={selected}
						isMultiple
						onSelect={handleAddition}
						autoFocus={false}
						infiniteScroll={infiniteScroll}
						handleLoadNextPage={handleLoadNextPage}
						isLoading={isLoading}
						isFinalPage={isFinalPage}
						originalOptions={options}
					/>
				}
				disableAutoTrigger={!infiniteScroll || Boolean(searchPhrase)}
				trigger={isOpen}
				isTopLayer
			>
				<div css={containerCss(colors, typography)}>
					{selected.map((tag, index) => {
						const state = options.find(opt => String(opt.value) === String(tag));
						if (state) {
							return <LabelTag key={index} state={state} handleRemove={handleChange} />;
						}
						return <Fragment />;
					})}
					<input
						ref={inputRef}
						value={searchPhrase}
						onChange={e => {
							if (editCallback) {
								editCallback(e.target.value);
							}
							setSearchPhrase(e.target.value);
						}}
					/>
				</div>
			</Popup>
			{bottomInfo && (
				<div css={marginTop}>
					<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
						{bottomInfo}
					</Typography>
				</div>
			)}
		</Fragment>
	);
};
