import React from 'react';

export const Tag = (props: any) => (
  <svg
    data-aid="svgTag"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.582 20.4686C10.937 20.8216 11.401 20.9986 11.866 20.9986C12.332 20.9986 12.797 20.8216 13.151 20.4676L20.468 13.1516C20.811 12.8086 21 12.3526 21 11.8666C21 11.3806 20.811 10.9246 20.468 10.5816L14.051 4.16461C13.886 3.99961 13.669 3.89761 13.437 3.8766L4.093 3.0046C3.796 2.9746 3.504 3.0816 3.293 3.2926C3.082 3.5036 2.977 3.7966 3.004 4.0926L3.876 13.4366C3.898 13.6686 4 13.8866 4.165 14.0506L10.582 20.4686ZM5.834 12.8916L11.866 18.9246L18.924 11.8666L12.892 5.83361L5.107 5.1076L5.834 12.8916ZM10.5605 8.4395C11.1465 9.0245 11.1465 9.9755 10.5605 10.5605C9.9745 11.1465 9.0255 11.1465 8.4395 10.5605C7.8535 9.9755 7.8535 9.0245 8.4395 8.4395C9.0255 7.8535 9.9745 7.8535 10.5605 8.4395Z"
      fill="currentColor"
    />
  </svg>
);
