import React from 'react';

export const Vertical = (props: any) => (
  <svg
    data-aid="svgVertical"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.341 4.32V19.68C3.341 20.7404 4.2259 21.6 5.31747 21.6H9.27041C10.362 21.6 11.2469 20.7404 11.2469 19.68V4.32C11.2469 3.25961 10.362 2.4 9.27041 2.4H5.31747C4.2259 2.4 3.341 3.25961 3.341 4.32ZM5.091 19.68V4.32C5.091 4.28951 5.10112 4.25466 5.13927 4.2176C5.17832 4.17967 5.23805 4.15 5.31747 4.15H9.27041C9.34984 4.15 9.40957 4.17967 9.44861 4.2176C9.48676 4.25466 9.49688 4.28951 9.49688 4.32V19.68C9.49688 19.7105 9.48676 19.7453 9.44861 19.7824C9.40957 19.8203 9.34984 19.85 9.27041 19.85H5.31747C5.23804 19.85 5.17832 19.8203 5.13927 19.7824C5.10112 19.7453 5.091 19.7105 5.091 19.68Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7528 4.31999V19.68C12.7528 20.7404 13.6377 21.6 14.7292 21.6H18.6822C19.7737 21.6 20.6586 20.7404 20.6586 19.68V4.31999C20.6586 3.25961 19.7737 2.39999 18.6822 2.39999H14.7292C13.6377 2.39999 12.7528 3.25961 12.7528 4.31999ZM14.5028 19.68V4.31999C14.5028 4.2895 14.5129 4.25465 14.551 4.21759C14.5901 4.17966 14.6498 4.14999 14.7292 4.14999H18.6822C18.7616 4.14999 18.8213 4.17966 18.8604 4.21759C18.8985 4.25465 18.9086 4.2895 18.9086 4.31999V19.68C18.9086 19.7105 18.8985 19.7453 18.8604 19.7824C18.8213 19.8203 18.7616 19.85 18.6822 19.85H14.7292C14.6498 19.85 14.5901 19.8203 14.551 19.7824C14.5129 19.7453 14.5028 19.7105 14.5028 19.68Z"
      fill="currentColor"
    />
  </svg>
);
