import React from 'react';

export const Snowboarding = (props: any) => (
  <svg
    data-aid="svgSnowboarding"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.5926 6.11901C12.4283 7.69496 11.0024 9.30693 9.83816 10.8939C8.6739 12.4808 7.48935 14.0338 6.21962 15.5367L6.06344 15.7218L12.3837 17.7951L19.1025 9.12482C19.4332 8.69756 19.7628 8.28231 20.0792 7.83304L13.6626 6.02095L13.5926 6.11901ZM12.9973 14.407C12.9395 14.6071 12.7681 14.7572 12.5592 14.7862C12.5348 14.7892 12.5095 14.7912 12.4841 14.7912C12.4344 14.7912 12.3857 14.7842 12.3391 14.7712L9.95986 14.1088C9.75601 14.0328 9.61301 13.8407 9.61301 13.6165C9.61301 13.3264 9.85134 13.0912 10.1454 13.0912C10.1809 13.0912 10.2144 13.0942 10.2479 13.1012L12.6362 13.7636C12.8583 13.8287 13.0176 14.0288 13.0176 14.2649C13.0176 14.315 13.0105 14.364 12.9973 14.41V14.407ZM16.2852 10.1324C16.2213 10.3546 16.0175 10.5147 15.7751 10.5147C15.7233 10.5147 15.6737 10.5077 15.627 10.4936L13.2397 9.83125C12.9912 9.78322 12.8066 9.57109 12.8066 9.31494C12.8066 9.02476 13.0449 8.78962 13.339 8.78962C13.406 8.78962 13.4699 8.80163 13.5287 8.82364L15.916 9.48504C16.1422 9.54708 16.3065 9.7492 16.3065 9.98934C16.3065 10.0404 16.2994 10.0884 16.2852 10.1354V10.1324ZM3.09397 18.4404L2.95401 18.5385C2.56457 18.7746 2.25424 19.1059 2.05242 19.4991C1.64168 20.6778 3.75419 21.4363 4.57363 21.6814C5.93464 22.0806 7.70537 22.1897 9.0319 21.5043C9.82294 21.0941 10.3899 20.3806 10.9335 19.6702L11.4801 18.9648L5.02899 16.8725C4.44382 17.4148 3.81199 17.9291 3.14873 18.4024L3.09397 18.4404ZM21.7596 3.47541C21.2729 2.89406 20.1431 2.70294 19.2912 2.43878C19.1421 2.38975 18.9514 2.34072 18.7567 2.3017L18.1076 2.14961C15.9242 1.6473 15.415 2.57086 15.415 2.57086C15.0621 2.9611 14.9911 3.47841 14.8461 3.97471C14.7406 4.2909 14.6179 4.56206 14.4698 4.81722L20.9128 6.6123C21.1968 6.16904 21.4737 5.65272 21.711 5.1154L21.7698 4.98932C22.1085 4.2869 22.0456 3.81561 21.7617 3.47541H21.7596Z"
      fill="currentColor"
    />
  </svg>
);
