import React from 'react';

export const ProfileOutline = (props: any) => (
	<svg 
		data-aid="svgProfileFileOutline" 
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 7.5C16 9.706 14.206 11.5 12 11.5C9.794 11.5 8 9.706 8 7.5C8 5.294 9.794 3.5 12 3.5C14.206 3.5 16 5.294 16 7.5ZM12 5.5C13.103 5.5 14 6.397 14 7.5C14 8.603 13.103 9.5 12 9.5C10.897 9.5 10 8.603 10 7.5C10 6.397 10.897 5.5 12 5.5ZM19 19.5C19 20.052 18.553 20.5 18 20.5C17.447 20.5 17 20.052 17 19.5C17 16.743 14.757 14.5 12 14.5C9.243 14.5 7 16.743 7 19.5C7 20.052 6.553 20.5 6 20.5C5.447 20.5 5 20.052 5 19.5C5 15.64 8.141 12.5 12 12.5C15.859 12.5 19 15.64 19 19.5Z"
			fill="currentColor"
		/>
	</svg>
);
