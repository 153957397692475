import { IPayingUser } from '../types/payment';
import { capitalize } from 'lodash';

export function isEvenNuber(number: number) {
	return number % 2 === 0;
}
export function isEmptyObject(obj: any) {
	return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function getBrowserName() {
	if (navigator.userAgent.includes('Edge') && navigator.appVersion.includes('Edge')) {
		return 'Edge';
	} else if (navigator.userAgent.includes('Opera') || navigator.userAgent.includes('OPR')) {
		return 'Opera';
	} else if (navigator.userAgent.includes('Chrome')) {
		return 'Chrome';
	} else if (navigator.userAgent.includes('Safari')) {
		return 'Safari';
	} else if (navigator.userAgent.includes('Firefox')) {
		return 'Firefox';
	}
	return 'unknown';
}

export function deepClone(object: any) {
	return JSON.parse(JSON.stringify(object));
}

export const handlePolling = async ({
	currentPage,
	itemsPerPage,
	array,
	apiCallHook,
	isNextPage = true,
	limit = 10,
}: {
	currentPage: number;
	itemsPerPage: number;
	array: any[];
	apiCallHook: (currentPage: number, itemsPerPage: number) => Promise<any>;
	isNextPage?: boolean;
	limit?: number;
}) => {
	if (isNextPage && limit >= currentPage) {
		const response = await apiCallHook(currentPage, itemsPerPage);
		return handlePolling({
			currentPage: currentPage + 1,
			itemsPerPage,
			array: [...array, ...response.data],
			apiCallHook,
			isNextPage: currentPage < response.meta.totalPages,
		});
	}
	return array;
};

export const getFullName = (
	user?: { firstName?: string; lastName?: string },
	lastNameFirst = true,
	withComma = true
) => {
	if (!user) return '';
	const separation = `${user.firstName && user.lastName && withComma ? ', ' : ''} `;
	const fullName = lastNameFirst
		? `${user.lastName || ''}${separation}${user.firstName || ''}`
		: `${user.firstName || ''}${separation}${user.lastName || ''}`;

	return fullName.trim();
};

export function isLetter(char) {
	const charCode = char.charCodeAt(0);
	return (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122);
}

export const isEmptyString = (val: string) => val === '';

export const EMPTY_ARRAY = [];

export const deepFindFirst = (obj: any, key: string): any => {
	if (typeof obj === 'object' && obj != null) {
		// make sure obj is an object and not null
		if (obj.hasOwnProperty(key)) return obj[key]; // if obj has the key as a property, return its value

		for (const i in obj) {
			// otherwise, loop over each property of obj
			if (obj.hasOwnProperty(i)) {
				// only continue if this property belongs to our object
				const found = deepFindFirst(obj[i], key);
				if (found) return found; // if the key is found somewhere in obj's properties, return its value
			}
		}
	}

	return null;
};

export const generateUUID = () => {
	let d = new Date().getTime(); //Timestamp
	let d2 = performance && performance.now && performance.now() * 1000; //Time in microseconds since page-load or 0 if unsupported
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		let r = Math.random(); //random number between 0 and 1
		if (d > 0) {
			//Use timestamp until depleted
			r = (d + r) % 16 | 0;
			d = Math.floor(d / 16);
		} else {
			//Use microseconds since page-load if supported
			r = (d2 + r) % 16 | 0;
			d2 = Math.floor(d2 / 16);
		}
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
};

export function concatFullName(firstName: string, lastName: string): string {
	return [firstName, lastName].join(' ').trim();
}

export const capitalizeSentence = (...strings: string[]): string => {
	return strings
		.map(string => capitalize(string))
		.join(' ')
		.trim();
};

export function capitalizedFullName(firstName: string, lastName: string): string {
	return capitalizeSentence(firstName, lastName);
}

export const cleanObjectOfNulls = (obj: object) => {
	Object.keys(obj).forEach(key => {
		if (obj[key] && typeof obj[key] === 'object') cleanObjectOfNulls(obj[key]);
		else if (obj[key] == null) delete obj[key];
	});
	return obj;
};

export function mergeArraysByIds<T extends { id: number }>(array1: T[], array2: T[]): T[] {
	const uniqueItemsMap = new Map(array1.map(item => [item.id, item]));
	array2.forEach(item => uniqueItemsMap.set(item.id, item));
	return Array.from(uniqueItemsMap.values());
}
