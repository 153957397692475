import React from 'react';

export const Basketball = (props: any) => (
  <svg
    data-aid="svgBasketball"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.6337 4.51788L14.2781 8.87349C12.5637 6.92749 11.7927 4.43668 11.9635 2.00007C14.3483 1.99127 16.7349 2.83107 18.6337 4.51788Z"
      fill="currentColor"
    />
    <path
      d="M13.4271 9.72433L12.0001 11.1513L5.36656 4.51791C6.92555 3.13331 8.81255 2.3209 10.7583 2.0789C10.6089 4.79851 11.4971 7.56472 13.4271 9.72433Z"
      fill="currentColor"
    />
    <path
      d="M8.87344 14.2779L4.51766 18.6335C2.83126 16.7347 1.99147 14.3481 2.00007 11.9633C4.43646 11.7927 6.92745 12.5635 8.87344 14.2779Z"
      fill="currentColor"
    />
    <path
      d="M11.1516 11.9999L4.51767 5.36628C3.13328 6.92549 2.32108 8.81249 2.07908 10.7579C4.79887 10.6089 7.56506 11.4967 9.72466 13.4269L11.1516 11.9999Z"
      fill="currentColor"
    />
    <path
      d="M19.4821 5.36648L15.1264 9.72209C17.0726 11.4363 19.5633 12.2073 21.9999 12.0365C22.0085 9.65169 21.1687 7.26529 19.4821 5.36648Z"
      fill="currentColor"
    />
    <path
      d="M12.8484 11.9999L14.2755 10.5729C16.4351 12.5031 19.2013 13.3911 21.9209 13.2419C21.6787 15.1873 20.8667 17.0743 19.4821 18.6333L12.8484 11.9999Z"
      fill="currentColor"
    />
    <path
      d="M5.36656 19.4821C7.26555 21.1689 9.65194 22.0085 12.0369 21.9999C12.2075 19.5633 11.4365 17.0725 9.72234 15.1265L5.36656 19.4821Z"
      fill="currentColor"
    />
    <path
      d="M10.5728 14.2757L12 12.8487L18.6336 19.4823C17.0746 20.8669 15.1874 21.6789 13.2422 21.9211C13.391 19.2015 12.503 16.4351 10.5728 14.2757Z"
      fill="currentColor"
    />
  </svg>
);
