/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { DiscountFooterFooterProps } from '../types';
import { Row } from '../../../atoms/layoutPrimitives';
import { Button } from '../../../components/Button';
import { i18n } from '../../../lib/i18n';

export const DiscountFooter = ({ isValid, onSubmit, onCancel }: DiscountFooterFooterProps) => {
	const labels = i18n.addDiscount.footer;
	return (
		<Row gap="1rem" justify="flex-end" fullWidth>
			<Button data-aid="button-discountFooter-cancel" theme="basic" sizer="S" onClick={onCancel}>
				{labels.cancel}
			</Button>
			<Button data-aid="button-discountFooter-confirm" theme="primary" sizer="S" onClick={onSubmit} disabled={!isValid}>
				{labels.confirm}
			</Button>
		</Row>
	);
};
