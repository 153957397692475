import React from 'react';

export const CheckP = (props: any) => (
  <svg
    data-aid="svgCheckP"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.7951 11.2025C17.4447 11.0785 16.8734 10.8703 16.5871 10.6459C16.5698 10.6322 16.5374 10.5506 16.5601 10.4293C16.5711 10.3711 16.6215 10.1768 16.8101 10.1199C17.1014 10.0317 17.3778 10.0896 17.5592 10.224C17.8456 10.4848 18.2891 10.4649 18.5509 10.1792C18.8124 9.89375 18.7938 9.45097 18.5101 9.18828C18.5101 9.18828 18.1843 8.89822 17.7184 8.76097V8.70312C17.7184 8.31481 17.4036 8 17.0153 8C16.627 8 16.3122 8.31481 16.3122 8.70312V8.80484C15.0483 9.2645 14.7512 10.9933 15.7194 11.7525C16.1605 12.0984 16.7908 12.3388 17.3259 12.5282C17.6745 12.6515 17.7099 12.9014 17.6783 13.0894C17.6431 13.2986 17.4628 13.6535 17.0106 13.6565C16.5417 13.6595 16.4305 13.6423 16.0883 13.4183C15.7633 13.2057 15.3276 13.2965 15.1149 13.6215C14.9022 13.9465 14.9932 14.3823 15.3182 14.5949C15.6919 14.8396 15.988 14.9608 16.3121 15.0176V15.0852C16.3121 15.4735 16.6269 15.7883 17.0153 15.7883C17.4036 15.7883 17.7184 15.4735 17.7184 15.0852V14.9381C19.422 14.3224 19.648 11.8579 17.7951 11.2025Z"
      fill="currentColor"
    />
    <path
      d="M5 10.65C5 10.1806 5.38056 9.8 5.85 9.8H12.15C12.6194 9.8 13 10.1806 13 10.65C13 11.1194 12.6194 11.5 12.15 11.5H5.85C5.38056 11.5 5 11.1194 5 10.65Z"
      fill="currentColor"
    />
    <path
      d="M5 13.65C5 13.1806 5.38056 12.8 5.85 12.8H12.15C12.6194 12.8 13 13.1806 13 13.65C13 14.1194 12.6194 14.5 12.15 14.5H5.85C5.38056 14.5 5 14.1194 5 13.65Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5C2.34315 5 1 6.34315 1 8V16C1 17.6569 2.34315 19 4 19H20C21.6569 19 23 17.6569 23 16V8C23 6.34315 21.6569 5 20 5H4ZM20 7H4C3.44772 7 3 7.44772 3 8V16C3 16.5523 3.44772 17 4 17H20C20.5523 17 21 16.5523 21 16V8C21 7.44772 20.5523 7 20 7Z"
      fill="currentColor"
    />
  </svg>
);
