import * as  React from 'react';

export const BnTrash = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66596 2.5C6.62175 2.5 6.57936 2.51756 6.54811 2.54882C6.51685 2.58007 6.49929 2.62246 6.49929 2.66667V4.16668H9.49929V2.66667C9.49929 2.62246 9.48173 2.58007 9.45047 2.54882C9.41922 2.51756 9.37683 2.5 9.33262 2.5H6.66596ZM5.49929 2.66667V4.16668H3.33983H3.32647H2.66602C2.38987 4.16668 2.16602 4.39054 2.16602 4.66668C2.16602 4.94282 2.38987 5.16668 2.66602 5.16668H2.87253L3.4994 12.6891C3.50524 13.1672 3.69771 13.6245 4.03623 13.963C4.38005 14.3068 4.84636 14.5 5.33259 14.5H10.6659C11.1522 14.5 11.6185 14.3068 11.9623 13.963C12.3008 13.6245 12.4933 13.1672 12.4991 12.6891L13.126 5.16668H13.3327C13.6088 5.16668 13.8327 4.94282 13.8327 4.66668C13.8327 4.39054 13.6088 4.16668 13.3327 4.16668H12.672H12.6587H10.4993V2.66667C10.4993 2.35725 10.3764 2.0605 10.1576 1.84171C9.93879 1.62292 9.64204 1.5 9.33262 1.5H6.66596C6.35654 1.5 6.05979 1.62292 5.841 1.84171C5.62221 2.0605 5.49929 2.35725 5.49929 2.66667ZM7.16602 7.33332C7.16602 7.05718 6.94216 6.83332 6.66602 6.83332C6.38987 6.83332 6.16602 7.05718 6.16602 7.33332V11.3333C6.16602 11.6095 6.38987 11.8333 6.66602 11.8333C6.94216 11.8333 7.16602 11.6095 7.16602 11.3333V7.33332ZM9.33256 6.83332C9.60871 6.83332 9.83256 7.05718 9.83256 7.33332V11.3333C9.83256 11.6095 9.60871 11.8333 9.33256 11.8333C9.05642 11.8333 8.83256 11.6095 8.83256 11.3333V7.33332C8.83256 7.05718 9.05642 6.83332 9.33256 6.83332Z"
      fill="currentColor"
    />
  </svg>
);
