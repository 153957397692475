/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { flexRowCss, justifyContentCss } from '../../../styles/utils';
import { useColors } from '../../../hooks/useColors';
import { Button } from '../../../components/Button';
import { BnIcon } from '../../../components/icons_v2';
import { Icons } from '../../../types';
import { NotesPopupLabels } from '../../../types/notes';
import { Pagination, PaginationProps } from '../../../molecules/Pagination';

const footerCss = (color: string) => css`
	border-top: 1px solid ${color};
	padding: 16px 50px 20px 50px;
`;

type FooterProps = {
	onAdd: () => void;
	labels: NotesPopupLabels['list']['buttons'];
} & PaginationProps;

export const NotesListFooter: React.FC<FooterProps> = ({ onAdd, labels, ...rest }) => {
	const { colors } = useColors();
	return (
		<div
			css={[flexRowCss, justifyContentCss('space-between'), footerCss(colors.bg_border_seperator as string)]}
			data-aid="Footer-NotesList"
		>
			<Button data-aid="button-NotesListFooter-add" theme={'primary'} sizer={'M'} disabled={!rest.pages} onClick={onAdd}>
				<BnIcon icon={Icons.plus} color={colors.tx_white} />
				{labels.addNewNote}
			</Button>
			{!!rest.pages && <Pagination {...rest} />}
		</div>
	);
};
