import React from "react";

export const Filed = (props: any) => (
  <svg
    data-aid="SvgFiled"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 14.9C13.3254 14.9 14.4 13.8255 14.4 12.5C14.4 11.1745 13.3254 10.1 12 10.1C10.6745 10.1 9.59995 11.1745 9.59995 12.5C9.59995 13.8255 10.6745 14.9 12 14.9ZM12 16.5C14.2091 16.5 16 14.7091 16 12.5C16 10.2908 14.2091 8.49998 12 8.49998C9.79081 8.49998 7.99995 10.2908 7.99995 12.5C7.99995 14.7091 9.79081 16.5 12 16.5Z"
      fill="currentColor"
    />
    <path
      d="M14.2965 23.4C14.3638 23.1787 14.4 22.944 14.4 22.7008C14.4 21.3753 13.3254 20.3008 12 20.3008C10.6745 20.3008 9.59995 21.3753 9.59995 22.7008C9.59995 22.944 9.63613 23.1787 9.7034 23.4H8.06086C8.02083 23.1729 7.99995 22.9393 7.99995 22.7008C7.99995 20.4916 9.79081 18.7008 12 18.7008C14.2091 18.7008 16 20.4916 16 22.7008C16 22.9393 15.9791 23.1729 15.939 23.4H14.2965Z"
      fill="currentColor"
    />
    <path
      d="M9.7034 1.59998C9.63613 1.82121 9.59995 2.05598 9.59995 2.29919C9.59995 3.62468 10.6745 4.69919 12 4.69919C13.3254 4.69919 14.4 3.62468 14.4 2.29919C14.4 2.05598 14.3638 1.82121 14.2965 1.59998H15.939C15.9791 1.82702 16 2.06067 16 2.29919C16 4.50833 14.2091 6.29919 12 6.29919C9.79081 6.29919 7.99995 4.50833 7.99995 2.29919C7.99995 2.06067 8.02083 1.82702 8.06086 1.59998H9.7034Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.09995 4.49998C3.09995 2.89835 4.39833 1.59998 5.99995 1.59998H18C19.6016 1.59998 20.9 2.89835 20.9 4.49998V20.5C20.9 22.1016 19.6016 23.4 18 23.4H5.99995C4.39833 23.4 3.09995 22.1016 3.09995 20.5V4.49998ZM5.99995 3.39998C5.39244 3.39998 4.89995 3.89246 4.89995 4.49998V20.5C4.89995 21.1075 5.39244 21.6 5.99995 21.6H18C18.6075 21.6 19.1 21.1075 19.1 20.5V4.49998C19.1 3.89246 18.6075 3.39998 18 3.39998H5.99995Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.69995 12.5C1.69995 12.0581 2.05812 11.7 2.49995 11.7H21.5C21.9418 11.7 22.2999 12.0581 22.2999 12.5C22.2999 12.9418 21.9418 13.3 21.5 13.3H2.49995C2.05812 13.3 1.69995 12.9418 1.69995 12.5Z"
      fill="currentColor"
    />
  </svg>
);
