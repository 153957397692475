import * as  React from 'react';

export const BnInfo = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2C4.6865 2 2 4.6865 2 8C2 11.3135 4.6865 14 8 14C11.3135 14 14 11.3135 14 8C14 4.6865 11.3135 2 8 2ZM8.75004 11C8.75004 11.414 8.41403 11.75 8.00006 11.75C7.58608 11.75 7.25007 11.4139 7.25007 11V7.24996C7.25007 6.83596 7.58608 6.49997 8.00006 6.49997C8.41403 6.49997 8.75004 6.83598 8.75004 7.24996V11ZM7.25007 5.01201C7.25007 5.42599 7.58605 5.762 8.00006 5.762C8.41406 5.762 8.75004 5.42599 8.75004 5.01201C8.75004 4.59803 8.41403 4.26202 8.00006 4.26202C7.58608 4.26202 7.25007 4.59803 7.25007 5.01201Z"
      fill="currentColor"
    />
  </svg>
);
