import { TranslationEn } from '@assets/i18n/en';

const labels = TranslationEn.memberships;
export const getRenwalTitle = (duration: number): string => {
	const durationMap = {
		12: labels.renewalType.annually,
		3: labels.renewalType.quarterly,
		1: labels.renewalType.monthly,
		default: labels.renewalType.default,
	};
	return durationMap[duration] ?? durationMap.default;
};
