import React from "react";

export const PaidParking = (props: any) => (
  <svg
    data-aid="SvgPaidParking"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99967 5.41659C5.40137 5.41659 4.91634 5.90161 4.91634 6.49992V18.4999C4.91634 19.0982 5.40137 19.5833 5.99967 19.5833H11.9997C12.5059 19.5833 12.9163 19.9937 12.9163 20.4999C12.9163 21.0062 12.5059 21.4166 11.9997 21.4166H5.99967C4.38884 21.4166 3.08301 20.1108 3.08301 18.4999V6.49992C3.08301 4.88909 4.38884 3.58325 5.99967 3.58325H17.9997C19.6105 3.58325 20.9163 4.88909 20.9163 6.49992V12.4999C20.9163 13.0062 20.5059 13.4166 19.9997 13.4166C19.4934 13.4166 19.083 13.0062 19.083 12.4999V6.49992C19.083 5.90161 18.598 5.41659 17.9997 5.41659H5.99967Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.08301 8.49996C8.08301 7.9937 8.49342 7.58329 8.99968 7.58329H12.9997C13.7732 7.58329 14.5151 7.89058 15.0621 8.43756C15.6091 8.98454 15.9163 9.72641 15.9163 10.5C15.9163 11.2735 15.6091 12.0154 15.0621 12.5624C14.5151 13.1093 13.7732 13.4166 12.9997 13.4166H9.91635V16.5C9.91635 17.0062 9.50594 17.4166 8.99968 17.4166C8.49342 17.4166 8.08301 17.0062 8.08301 16.5V8.49996ZM9.91635 11.5833H12.9997C13.287 11.5833 13.5625 11.4692 13.7657 11.266C13.9689 11.0628 14.083 10.7873 14.083 10.5C14.083 10.2126 13.9689 9.93709 13.7657 9.73393C13.5625 9.53076 13.287 9.41663 12.9997 9.41663H9.91635V11.5833Z"
      fill="currentColor"
    />
    <path
      d="M17.1361 17.4003H17.7743C18.2971 17.4003 18.7882 17.5993 19.1571 17.9606C19.5312 18.3269 19.7371 18.8185 19.7371 19.3448V19.5158C19.7371 20.4977 19.023 21.2857 18.0649 21.4196V21.8229C18.0649 22.2076 17.7531 22.5194 17.3684 22.5194C16.9837 22.5194 16.6719 22.2076 16.6719 21.8229V21.4382C15.7291 21.4057 14.9997 20.6688 14.9997 19.7335C14.9997 19.3488 15.3115 19.037 15.6962 19.037C16.0809 19.037 16.3927 19.3488 16.3927 19.7335C16.3927 19.9392 16.5658 20.0469 16.7371 20.0469H17.7743C18.0992 20.0469 18.3442 19.8185 18.3442 19.5158V19.3448C18.3442 19.0355 18.0939 18.7932 17.7743 18.7932H17.1361C16.0558 18.7932 15.1771 17.9028 15.1771 16.8082V16.5541C15.1771 16.0278 15.3825 15.5369 15.7553 15.1721C16.0115 14.9215 16.3272 14.7495 16.6719 14.6682V14.1964C16.6719 13.8117 16.9837 13.4999 17.3684 13.4999C17.7531 13.4999 18.0649 13.8117 18.0649 14.1964V14.6442C18.877 14.7935 19.4934 15.4901 19.4934 16.3248C19.4934 16.7094 19.1816 17.0213 18.7969 17.0213C18.4123 17.0213 18.1004 16.7094 18.1004 16.3248C18.1004 16.126 17.9169 16.0073 17.7396 16.0073H17.1361C16.8134 16.0073 16.57 16.2425 16.57 16.5543V16.8082C16.57 17.1291 16.8292 17.4003 17.1361 17.4003Z"
      fill="currentColor"
    />
  </svg>
);
