import { NotesPopupStepEnum, NotesPopupLabels } from '@bondsports/utils';
import { TranslationEn } from '../../../../assets/i18n/en';

export const NOTE_POPUP_LABELS: NotesPopupLabels = {
	titles: {
		[NotesPopupStepEnum.ADD_NOTE]: TranslationEn.notes.addNote.title,
		[NotesPopupStepEnum.EDIT_NOTE]: TranslationEn.notes.editNote.title,
		[NotesPopupStepEnum.DELETE_NOTE]: TranslationEn.notes.archiveNote.title,
		[NotesPopupStepEnum.LIST]: TranslationEn.customers.customerPage.customerAccountNotes,
	},
	form: TranslationEn.notes.addNote,
	list: {
		buttons: {
			goBack: TranslationEn.buttons.goBack,
			addNewNote: TranslationEn.notes.notesList.buttons.addNewNote,
		},
		errors: {
			noData: TranslationEn.notes.notesList.noData,
			tryLater: TranslationEn.errors.tryLater,
		},
		loading: {
			description: TranslationEn.notes.notesList.loading.description,
			title: TranslationEn.notes.notesList.loading.title,
		},
		notedBy: TranslationEn.notes.notesList.note.notedBy,
		dateAndTime: TranslationEn.notes.notesList.note.dateAndTime,
	},
	archive: {
		description: TranslationEn.notes.archiveNote.description,
		buttons: {
			cancel: TranslationEn.buttons.cancel,
			confirm: TranslationEn.buttons.confirm,
		},
	},
};
