/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { FormPart } from 'app/react/components/customers/utils/formPart';
import { FrameContainer } from 'app/react/components/customers/utils/FrameContainer';
import { formatAgeRange } from 'app/react/lib/age';
import { TranslationEn } from 'assets/i18n/en';
import React from 'react';
import { ReactSVG } from 'react-svg';

const genderIconCss = css`
	svg {
		width: 30px;
		height: 30px;
	}
`;

interface ExtraDetailsProps {
	maxParticipants?: string;
	minAge?: string;
	maxAge?: string;
	gender?: string;
	ageCalcDate?: string;
}

const ExtraDetails = ({ maxParticipants, minAge, maxAge, gender, ageCalcDate }: ExtraDetailsProps) => {
	const GenderImage = () => {
		switch (Number(gender)) {
			case 1:
				return (
					<Grid container>
						<ReactSVG css={genderIconCss} src="assets/media/gender/male_idle.svg" />
						<ReactSVG css={genderIconCss} src="assets/media/gender/female_idle.svg" />
					</Grid>
				);
			case 2:
				return <ReactSVG css={genderIconCss} src="assets/media/gender/male_idle.svg" />;
			case 3:
				return <ReactSVG css={genderIconCss} src="assets/media/gender/female_idle.svg" />;
			default:
				return null;
		}
	};

	return (
		<FrameContainer title={''} margin="0 0 2rem 0">
			<Grid container direction="row" alignItems="flex-start">
				<Grid item lg={4}>
					<FormPart
						label={TranslationEn.season.dashboard.extraDetails.maxMembers}
						value={maxParticipants}
						width={100}
						margin={0}
					/>
				</Grid>

				<Grid item lg={4}>
					<FormPart
						label={TranslationEn.season.dashboard.extraDetails.age}
						value={formatAgeRange(minAge, maxAge, ageCalcDate)}
						width={100}
						margin={0}
					/>
				</Grid>

				<Grid item lg={4}>
					<FormPart
						label={TranslationEn.season.dashboard.extraDetails.gender}
						value={<GenderImage /> || null}
						width={90}
						margin={0}
					/>
				</Grid>
			</Grid>
		</FrameContainer>
	);
};

export default ExtraDetails;
