import React, { Fragment, useState } from 'react';
import { Field as FinalField } from 'react-final-form';
import { Toggle, Props } from './index';

export interface IFormProps extends Props {
  name: string;
  onClear?: (val: string) => void;
  required?: boolean;
}

export const FormToggle = ({
  onClear = (val: string) => {},
  required = false,
  ...props
}: IFormProps) => {
  const clear = () => onClear(props.name);

  return (
    <div data-aid="toggleFormToggle">
      <FinalField
        type="checkbox"
        name={props.name}
        validate={(val) => {
          if (required) {
            if (!val) {
              let errors = {};
              errors[props.name] = 'required';
              return errors;
            }
          }
          return undefined;
        }}
        displayEmpty
      >
        {({ input, meta }) => {
          return <Toggle {...input} {...props} />;
        }}
      </FinalField>
    </div>
  );
};
