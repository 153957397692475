import { environment } from '../../../../environments/environment';

const GOOGLE_API_KEY = environment.GOOGLE_API_KEY;

export type TGoogleTimezoneResponse = {
	dstOffset: number;
	rawOffset: number;
	status: string;
	timeZoneId: string;
	timeZoneName: string;
};

const getTimezoneByGeoCoordinates = async (
	coordinates: number[],
	options?: Partial<RequestInit>
): Promise<TGoogleTimezoneResponse> => {
	const location: string = encodeURIComponent(coordinates.join(','));
	const timestamp: number = Date.now() / 1000;

	return await fetch(
		`https://maps.googleapis.com/maps/api/timezone/json?location=${location}&timestamp=${timestamp}&key=${GOOGLE_API_KEY}`,
		{
			method: 'GET',
			signal: options?.signal,
		}
	).then(res => res.json());
};

export const googleMapsApi = {
	getTimezoneByGeoCoordinates,
};
