import React from "react";

export const LockerRoom = (props: any) => (
  <svg
    data-aid="SvgLockerRoom"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.8066 2.5C19.3662 2.5 4.62207 2.5 4.18164 2.5C3.75016 2.5 3.40039 2.84977 3.40039 3.28125V21.7188C3.40039 22.1502 3.75016 22.5 4.18164 22.5C4.61312 22.5 4.96289 22.1502 4.96289 21.7188V20.9375H11.2129V21.7188C11.2129 22.1502 11.5627 22.5 11.9941 22.5C12.4256 22.5 12.7754 22.1502 12.7754 21.7188V20.9375H19.0254V21.7188C19.0254 22.1502 19.3752 22.5 19.8066 22.5C20.2381 22.5 20.5879 22.1502 20.5879 21.7188C20.5879 21.5511 20.5879 3.44937 20.5879 3.28125C20.5879 2.84977 20.2381 2.5 19.8066 2.5ZM19.0254 5.625H12.7754V4.0625H19.0254V5.625ZM11.2129 4.0625V5.625H4.96289V4.0625H11.2129ZM4.96289 7.1875H11.2129V19.375H4.96289V7.1875ZM12.7754 7.1875H19.0254V19.375H12.7754V7.1875Z"
      fill="currentColor"
    />
    <path
      d="M8.86914 11.7188C9.30061 11.7188 9.65039 11.369 9.65039 10.9375C9.65039 10.506 9.30061 10.1562 8.86914 10.1562C8.43767 10.1562 8.08789 10.506 8.08789 10.9375C8.08789 11.369 8.43767 11.7188 8.86914 11.7188Z"
      fill="currentColor"
    />
    <path
      d="M16.6816 11.7188C17.1131 11.7188 17.4629 11.369 17.4629 10.9375C17.4629 10.506 17.1131 10.1562 16.6816 10.1562C16.2502 10.1562 15.9004 10.506 15.9004 10.9375C15.9004 11.369 16.2502 11.7188 16.6816 11.7188Z"
      fill="currentColor"
    />
    <path
      d="M8.86914 16.4062H7.30664C6.87516 16.4062 6.52539 16.756 6.52539 17.1875C6.52539 17.619 6.87516 17.9688 7.30664 17.9688H8.86914C9.30062 17.9688 9.65039 17.619 9.65039 17.1875C9.65039 16.756 9.30062 16.4062 8.86914 16.4062Z"
      fill="currentColor"
    />
    <path
      d="M16.6816 16.4062H15.1191C14.6877 16.4062 14.3379 16.756 14.3379 17.1875C14.3379 17.619 14.6877 17.9688 15.1191 17.9688H16.6816C17.1131 17.9688 17.4629 17.619 17.4629 17.1875C17.4629 16.756 17.1131 16.4062 16.6816 16.4062Z"
      fill="currentColor"
    />
  </svg>
);
