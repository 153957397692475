import React from 'react';

export const Bowling = (props: any) => (
  <svg
    data-aid="svgBowling"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 22C17.523 22 22 17.523 22 12C22 6.47699 17.523 2 12 2C6.47699 2 2 6.47699 2 12C2 17.523 6.47699 22 12 22ZM14.2227 7.69421C13.5319 7.69421 12.9728 7.13409 12.9728 6.44433C12.9728 5.75458 13.5329 5.19446 14.2227 5.19446C14.9134 5.19446 15.4725 5.75458 15.4725 6.44433C15.4725 7.13409 14.9124 7.69421 14.2227 7.69421ZM17.6942 10.194C17.0035 10.194 16.4443 9.63384 16.4443 8.94409C16.4443 8.25433 17.0045 7.69421 17.6942 7.69421C18.385 7.69421 18.9441 8.25433 18.9441 8.94409C18.9441 9.63384 18.384 10.194 17.6942 10.194ZM13.9446 11.7219C13.2538 11.7219 12.6947 11.1618 12.6947 10.472C12.6947 9.78229 13.2548 9.22217 13.9446 9.22217C14.6353 9.22217 15.1945 9.78229 15.1945 10.472C15.1945 11.1618 14.6343 11.7219 13.9446 11.7219Z"
      fill="currentColor"
    />
  </svg>
);
