import React from 'react';

export const Wallet = (props: any) => (
  <svg
    data-aid="svgWallet"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00016 3.08337C5.22661 3.08337 4.48475 3.39066 3.93777 3.93765C3.39079 4.48463 3.0835 5.22649 3.0835 6.00004V18C3.0835 18.7736 3.39079 19.5155 3.93777 20.0624C4.48475 20.6094 5.22661 20.9167 6.00016 20.9167H18.0002C18.5085 20.9167 18.996 20.7148 19.3555 20.3553C19.7149 19.9959 19.9168 19.5084 19.9168 19V16.9167H20.0002C20.5064 16.9167 20.9168 16.5063 20.9168 16V12C20.9168 11.4938 20.5064 11.0834 20.0002 11.0834H19.9168V9.00004C19.9168 8.49171 19.7149 8.0042 19.3555 7.64475C18.996 7.28531 18.5085 7.08337 18.0002 7.08337H17.9168V5.00004C17.9168 4.49171 17.7149 4.0042 17.3554 3.64475C16.996 3.28531 16.5085 3.08337 16.0002 3.08337H6.00016ZM18.9983 12.9167C18.9989 12.9167 18.9995 12.9167 19.0002 12.9167C19.0008 12.9167 19.0014 12.9167 19.0021 12.9167H19.0835V15.0834H16.0002C15.7128 15.0834 15.4373 14.9692 15.2341 14.7661C15.031 14.5629 14.9168 14.2874 14.9168 14C14.9168 13.7127 15.031 13.4372 15.2341 13.234C15.4373 13.0308 15.7128 12.9167 16.0002 12.9167H18.9983ZM18.0835 11.0834V9.00004C18.0835 8.97794 18.0747 8.95674 18.0591 8.94112C18.0435 8.92549 18.0223 8.91671 18.0002 8.91671H6.00016C5.62543 8.91671 5.25814 8.84459 4.91683 8.70805V18C4.91683 18.2874 5.03097 18.5629 5.23413 18.7661C5.43729 18.9692 5.71285 19.0834 6.00016 19.0834H18.0002C18.0223 19.0834 18.0435 19.0746 18.0591 19.059C18.0747 19.0433 18.0835 19.0221 18.0835 19V16.9167H16.0002C15.2266 16.9167 14.4847 16.6094 13.9378 16.0624C13.3908 15.5155 13.0835 14.7736 13.0835 14C13.0835 13.2265 13.3908 12.4846 13.9378 11.9376C14.4847 11.3907 15.2266 11.0834 16.0002 11.0834H18.0835ZM5.23413 6.76607C5.03097 6.56291 4.91683 6.28736 4.91683 6.00004C4.91683 5.71272 5.03097 5.43717 5.23413 5.23401C5.43729 5.03084 5.71284 4.91671 6.00016 4.91671H16.0002C16.0223 4.91671 16.0435 4.92549 16.0591 4.94112C16.0747 4.95674 16.0835 4.97794 16.0835 5.00004V7.08337H6.00016C5.71284 7.08337 5.43729 6.96924 5.23413 6.76607Z"
      fill="currentColor"
    />
  </svg>
);
