import React from 'react';

export const Other = (props: any) => (
  <svg
    data-aid="svgOther"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2C6.48702 2 2 6.48702 2 12C2 17.513 6.48702 22 12 22C17.513 22 22 17.513 22 12C22 6.48702 17.513 2 12 2ZM11.1667 3.85547V20.1445C10.4689 19.8392 9.78162 19.1567 9.19564 18.0824C8.37614 16.5799 7.83333 14.4085 7.83333 12C7.83333 9.59152 8.37614 7.42005 9.19564 5.91764C9.78162 4.84334 10.4689 4.16082 11.1667 3.85547ZM12.8333 3.85547C13.5311 4.16082 14.2184 4.84334 14.8044 5.91764C15.6239 7.42005 16.1667 9.59152 16.1667 12C16.1667 14.4085 15.6239 16.5799 14.8044 18.0824C14.2184 19.1567 13.5311 19.8392 12.8333 20.1445V3.85547ZM7.98796 4.69206C7.90112 4.83231 7.81284 4.97105 7.73242 5.11849C6.74227 6.93376 6.16667 9.34587 6.16667 12C6.16667 14.6541 6.74227 17.0662 7.73242 18.8815C7.81284 19.0289 7.90112 19.1677 7.98796 19.3079C5.41006 17.8931 3.66667 15.156 3.66667 12C3.66667 8.84401 5.41006 6.10687 7.98796 4.69206ZM16.012 4.69206C18.5899 6.10687 20.3333 8.84401 20.3333 12C20.3333 15.156 18.5899 17.8931 16.012 19.3079C16.0989 19.1677 16.1872 19.0289 16.2676 18.8815C17.2577 17.0662 17.8333 14.6541 17.8333 12C17.8333 9.34587 17.2577 6.93376 16.2676 5.11849C16.1872 4.97105 16.0989 4.83231 16.012 4.69206Z"
      fill="currentColor"
    />
  </svg>
);
