import { i18n } from '../../lib/i18n';
import { ResourceGlDto } from '../../types/entities/product';

export const convertGlCodesToOptions = (glCodes: ResourceGlDto[]) => {
	const { other } = i18n.glCodes;
	return glCodes.map(glCode => {
		return {
			value: glCode.glId,
			label: glCode.code,
			key: glCode.label ?? other,
		};
	});
};
