/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useContext } from 'react';
import { NotesList, NotesListProps } from '../NotesList/NotesList';
import { jsx } from '@emotion/react';
import { NotesPopupStepEnum } from '../../../types/notes';
import { NotesPopupContext } from '../../../store/notes';
import { NoteDto } from '../../../types/entities/notes';

type NotesListStepProps = {
	setNoteToEdit: (note: NoteDto) => void;
	setNoteToArchive: (note: NoteDto) => void;
} & Omit<
	NotesListProps,
	'onEdit' | 'timezone' | 'entityType' | 'entityId' | 'NoteComponent' | 'onArchive' | 'notesToDisplay'
>;

export const NotesListStep: React.FC<NotesListStepProps> = ({
	setNoteToEdit,
	setNoteToArchive,
	changeStep,
	getPaginatedNotes,
	page,
	setPage,
	onError,
	labels,
	setTotalNotes,
}) => {
	const { timezone, entityId, entityType, NoteComponent, notesToDisplay } = useContext(NotesPopupContext);

	const onEditHandler = (note: NoteDto) => {
		setNoteToEdit(note);
		changeStep(NotesPopupStepEnum.EDIT_NOTE);
	};

	const onArchiveHandler = (note: NoteDto) => {
		setNoteToArchive(note);
		changeStep(NotesPopupStepEnum.DELETE_NOTE);
	};

	return (
		<NotesList
			onError={onError}
			getPaginatedNotes={getPaginatedNotes}
			changeStep={changeStep}
			entityId={entityId!}
			entityType={entityType!}
			timezone={timezone!}
			onEdit={onEditHandler}
			onArchive={onArchiveHandler}
			page={page}
			setPage={setPage}
			labels={labels}
			notesToDisplay={notesToDisplay}
			NoteComponent={NoteComponent}
			setTotalNotes={setTotalNotes}
		/>
	);
};
