import React from "react";

export const Studio = (props: any) => (
  <svg
    data-aid="SvgStudio"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.7059 0.5C11.957 0.5 11.2389 0.75934 10.7093 1.22097C10.1798 1.6826 9.88235 2.3087 9.88235 2.96154C9.88235 3.61438 10.1798 4.24048 10.7093 4.70211C11.2389 5.16374 11.957 5.42308 12.7059 5.42308C14.2729 5.42308 15.5294 4.32769 15.5294 2.96154C15.5294 2.3087 15.2319 1.6826 14.7024 1.22097C14.1729 0.75934 13.4547 0.5 12.7059 0.5ZM1.23077 6.65385C0.551035 6.65385 0 7.20488 0 7.88462C0 8.56435 0.551034 9.11539 1.23077 9.11539H8.47059V16.5L2.4436 21.7543C1.84434 22.2767 1.8411 23.207 2.43669 23.7336C2.93249 24.1719 3.67661 24.1743 4.17514 23.739L12.2758 16.6664C12.5862 16.3954 13.0311 16.3425 13.3964 16.5333L18.3529 19.1215V22.4729C18.3529 23.2525 18.985 23.8846 19.7647 23.8846C20.5444 23.8846 21.1765 23.2525 21.1765 22.4729V18.4323C21.1765 17.9892 20.9224 17.5954 20.4706 17.3738L15.5294 14.7769V9.11539H22.7692C23.449 9.11539 24 8.56435 24 7.88462C24 7.20488 23.449 6.65385 22.7692 6.65385H1.23077Z"
      fill="currentColor"
    />
  </svg>
);
