import React from 'react';

export const Badminton = (props: any) => (
  <svg
    data-aid="svgBadminton"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.7151 11.2783L8.21563 17.4695L7.94791 17.2087L16.7702 8.80986L19.4473 9.64682L19.7151 11.2783ZM6.9725 16.2464L15.7833 7.83948L14.8598 5.178L13.1767 5.03043L6.70167 15.9937L6.9725 16.2464ZM12.0083 4.30163L10.7589 2L4.80274 14.1439L5.67646 14.995L12.0083 4.30163ZM3.08332 16.0989C2.41507 16.7235 2 17.6009 2 18.5723C2 20.4656 3.57518 22 5.51873 22C6.51593 22 7.41662 21.5957 8.05686 20.9467L8.0579 20.9457L8.93162 20.0946L3.95912 15.2457L3.08332 16.0989ZM20.2256 12.558L9.24499 18.4571L10.0575 19.2486L22 13.3666L20.5172 12.7419C20.4051 12.6924 20.3096 12.6307 20.2246 12.557L20.2256 12.558Z"
      fill="currentColor"
    />
  </svg>
);
