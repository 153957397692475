import { css } from '@emotion/react';
import { flexWrapCss } from '../../../styles/utils';

export const containerCss = (isShowMore: boolean, initMaxHeight: number, finalMaxHeight: number) => css`
	${flexWrapCss};
	max-height: ${isShowMore ? initMaxHeight : finalMaxHeight}px;
	overflow: hidden;
	transition: max-height 0.5s ease-in-out;
	margin-left: -2px;
`;

export const fontSizeCss = css`
	font-size: 12px;
`;

export const noneTextTransformCss = css`
	text-transform: none;
`;
