import React from 'react';

export const CollapseAll = (props: any) => (
  <svg
    data-aid="svgCollapseAll"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 19.8508L12 14L18 19.8508L16.8215 21L12 16.2984L7.17851 21L6 19.8508Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4.1492L12 10L6 4.1492L7.17851 3L12 7.70159L16.8215 3L18 4.1492Z"
      fill="currentColor"
    />
  </svg>
);
