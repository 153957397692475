import React from 'react';

export const BnEllipseMid = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="1.5" cy="2" r="1.5" fill="currentColor" />
  </svg>
);
