/** @jsxRuntime classic */
/** @jsx jsx */

import { FC, Fragment } from 'react';
import { jsx, Theme } from '@emotion/react';

import { DraggableSlot } from './DraggableSlot';
import { MoreSlotsButton } from '../MoreSlotsButton';
import { MoreSlotsList } from '../MoreSlotsList';
import { IEventComponentProps, IEventsGroup } from '../../types';
import { collisionGroupContainer } from '../styles';

const MAX_COUNT = 4;

interface ISlotGroupProps {
	group: IEventsGroup;
	withModal: boolean;
	index: number;
	id: string;
	EventComponent: FC<IEventComponentProps>;
	handleToggleModal: (index: number) => void;
	colors: Theme;
	modals: boolean[];
}

export const SlotGroups = ({
	group,
	withModal,
	index,
	id,
	EventComponent,
	handleToggleModal,
	colors,
	modals,
}: ISlotGroupProps) => {
	const moreItemsCount = withModal ? group.groups!.slice(3).reduce((acc, events) => acc + events.length, 0) : 0;

	return (
		<div
			id={`group-${index}`}
			key={index}
			css={collisionGroupContainer(group.top, group.height)}
			data-aid="SlotGroup-daily"
		>
			{group.groups?.map((groupItems, index) => {
				return (
					<Fragment key={index}>
						{groupItems.map(event => {
							return (
								<DraggableSlot
									key={event.id}
									slot={event}
									parentId={id}
									EventComponent={EventComponent}
									groupTop={group.top}
									groupCount={withModal ? MAX_COUNT : group.groups?.length}
									groupIndex={index}
								/>
							);
						})}
					</Fragment>
				);
			})}
			{withModal && (
				<MoreSlotsButton
					colors={colors}
					handleToggleModal={() => handleToggleModal(index)}
					moreItemsCount={moreItemsCount}
				/>
			)}
			{withModal && modals[index] && (
				<MoreSlotsList
					colors={colors}
					EventComponent={EventComponent}
					group={group}
					handleToggleModal={handleToggleModal}
					index={index}
				/>
			)}
		</div>
	);
};
