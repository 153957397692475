/** @jsxRuntime classic */
/** @jsx jsx */

import React, { FC } from 'react';
import { css, jsx } from '@emotion/react';
import { flexCol } from '../appendix/lib';

const footerCss = (gap = 12, padding = '16px') => css`
	${flexCol};
	padding: ${padding};
	gap: ${gap}px;
`;

interface Props {
	children: React.ReactNode;
	gap?: number;
	padding?: string;
	className?: string;
}
export const Summary: FC<Props> = ({ children, gap = 12, padding, className }) => {
	return (
		<div css={footerCss(gap, padding)} className={className} data-aid="index-summary">
			{children}
		</div>
	);
};
