import React from 'react';

export const Diners = (props: any) => (
	<svg data-aid="SvgDiners" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.7548 11.629C15.7514 9.49183 14.4155 7.6691 12.5331 6.94725V16.3104C14.4155 15.5877 15.7514 13.7666 15.7548 11.629ZM8.94208 16.3086V6.94819C7.06135 7.67229 5.7275 9.4924 5.72247 11.6291C5.7275 13.7651 7.06135 15.5851 8.94208 16.3086ZM10.7386 3.71493C6.36789 3.7166 2.82689 7.25835 2.82614 11.629C2.82689 15.9991 6.36789 19.5403 10.7386 19.541C15.1092 19.5403 18.651 15.9991 18.6521 11.629C18.651 7.25835 15.1092 3.7166 10.7386 3.71493ZM10.7191 20.2879C5.93643 20.3107 2 16.436 2 11.7193C2 6.5645 5.93643 2.99907 10.7191 3H12.9604C17.6866 2.99907 22 6.56282 22 11.7193C22 16.4343 17.6866 20.2879 12.9604 20.2879H10.7191Z"
			fill="#0069AA"
		/>
	</svg>
);
