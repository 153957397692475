import * as  React from 'react';

export const BnCreditCard = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.91665 8.50001C3.91665 7.34942 4.84939 6.41668 5.99998 6.41668H18C19.1506 6.41668 20.0833 7.34942 20.0833 8.50001V9.58334H3.91665V8.50001ZM2.08331 10.5V8.50001C2.08331 6.3369 3.83686 4.58334 5.99998 4.58334H18C20.1631 4.58334 21.9166 6.3369 21.9166 8.50001V10.5V16.5C21.9166 18.6631 20.1631 20.4167 18 20.4167H5.99998C3.83686 20.4167 2.08331 18.6631 2.08331 16.5V10.5ZM20.0833 11.4167V16.5C20.0833 17.6506 19.1506 18.5833 18 18.5833H5.99998C4.84939 18.5833 3.91665 17.6506 3.91665 16.5V11.4167H20.0833ZM6.08331 15.5C6.08331 14.9937 6.49372 14.5833 6.99998 14.5833H7.00998C7.51624 14.5833 7.92665 14.9937 7.92665 15.5C7.92665 16.0063 7.51624 16.4167 7.00998 16.4167H6.99998C6.49372 16.4167 6.08331 16.0063 6.08331 15.5ZM11 14.5833C10.4937 14.5833 10.0833 14.9937 10.0833 15.5C10.0833 16.0063 10.4937 16.4167 11 16.4167H13C13.5062 16.4167 13.9166 16.0063 13.9166 15.5C13.9166 14.9937 13.5062 14.5833 13 14.5833H11Z"
      fill="currentColor"
    />
  </svg>
);
