import React from "react";

export const Facebook = (props: any) => (
  <svg
    data-aid="SvgFacebook"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 22H13.5C14.053 22 14.5 21.553 14.5 21V14.8H16.5C16.949 14.8 17.344 14.5 17.464 14.067L18.464 10.468C18.547 10.167 18.485 9.844 18.296 9.595C18.107 9.347 17.812 9.2 17.5 9.2H14.5V7.6H17.5C18.053 7.6 18.5 7.152 18.5 6.6V3C18.5 2.447 18.053 2 17.5 2H14.5C11.191 2 8.5 4.468 8.5 7.5V9.2H6.5C5.947 9.2 5.5 9.647 5.5 10.2V13.8C5.5 14.353 5.947 14.8 6.5 14.8H8.5V21C8.5 21.553 8.947 22 9.5 22ZM12.5 20H10.5V13.8C10.5 13.247 10.053 12.8 9.5 12.8H7.5V11.2H9.5C10.053 11.2 10.5 10.753 10.5 10.2V7.5C10.5 5.57 12.294 4 14.5 4H16.5V5.6H14.5C13.397 5.6 12.5 6.452 12.5 7.5V10.2C12.5 10.753 12.947 11.2 13.5 11.2H16.185L15.74 12.8H13.5C12.947 12.8 12.5 13.247 12.5 13.8V20Z"
      fill="currentColor"
    />
  </svg>
);
