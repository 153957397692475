import { useTheme } from "@emotion/react";
import React, { FC, Fragment, useEffect, useState } from "react";
import { capitalize } from "../../functions";
import { getIcon } from "../../icons";
import { Tooltip } from "../Tooltip";
import { PickerContainer } from "./style";
import { PickerItem } from "./PickerItem";
import cx from "classnames";
import { IPickerProps, EPickerTheme, Icons } from "../../types";
import { Input } from "../Input";
import { BnIcon } from "../icons_v2";

export const Picker: FC<
  IPickerProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  label,
  info,
  sizer = "S",
  options = [],
  theme = EPickerTheme.FULL,
  withCustom = false,
  isMultiple = true,
  selected = [],
  setSelected,
  ...rest
}) => {
  const colors = useTheme();
  const newClassName = rest.className;
  delete rest.className;
  const [value, setValue] = useState<string>("");
  const [isCustomSelected, setCustomSelected] = useState<boolean>(false);
  const [isFirstRun, setFirstRun] = useState<boolean>(true);
  //   const [selected, setSelected] = useState<any[]>([]);

  const handlePress = (val: string | number) => {
    setCustomSelected(false);
    updateValue(val);
  };

  const updateValue = (val: string | number) => {
    if (isMultiple) {
      if ((selected as (string | number)[]).includes(val)) {
        setSelected(
          (selected as (string | number)[]).filter((item) => item !== val)
        );
      } else {
        setSelected([...(selected as (string | number)[]), val]);
      }
    } else {
      // handle case of single
      if (selected === val) {
        setSelected("");
      } else {
        setSelected(val);
      }
    }
  };

  useEffect(() => {
    if (!isMultiple) {
      let index = options.findIndex((opt) => opt.value === selected);
      if (index === -1 && selected) {
        setCustomSelected(true);
        setValue(selected as string);
      }
    }
  }, [options]);

  useEffect(() => {
    if (isCustomSelected && !isMultiple) {
      updateValue(value);
    }
  }, [isCustomSelected]);

  useEffect(() => {
    if (!isFirstRun) {
      if (value !== selected) {
        updateValue(value);
      }
    }
    setFirstRun(false);
  }, [value]);

  const clear = () => {
    setValue("");
  };

  return (
    <div data-aid="pickerIndex">
    <Fragment>
      <PickerContainer
        style={{ ...rest.style }}
        className={cx(
          {
            M: sizer === "M",
            S: sizer === "S",
          },
          newClassName
        )}
        theme={{ ...colors }}
      >
        {label && (
          <div className="label" data-aid="picker" data-label={label}>
            <div>
              {capitalize(label)}
              {rest.required && " *"}
            </div>
            {info && <Tooltip content={info}>{getIcon("info", "l")}</Tooltip>}
          </div>
        )}
        <div className="wrapper">
          {options.map((opt, index) => {
            return (
              <PickerItem
                data-value={opt.value}
                tabIndex={index}
                key={index}
                isOutline={theme === EPickerTheme.OUTLINE}
                {...opt}
                handleSelect={handlePress}
                isActive={
                  isMultiple
                    ? (selected as (string | number)[]).includes(opt.value)
                    : selected === opt.value
                }
              />
            );
          })}
          {withCustom && !isMultiple && (
            <PickerItem
              label={
                <Fragment>
                  <BnIcon icon={Icons.edit_outline} />
                  Custom
                </Fragment>
              }
              value="custom"
              isOutline={theme === EPickerTheme.OUTLINE}
              tabIndex={options.length + 1}
              isActive={isCustomSelected}
              handleSelect={() => setCustomSelected(!isCustomSelected)}
            />
          )}
        </div>
      </PickerContainer>
      {!isMultiple && isCustomSelected && (
        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          sizer="S"
          clear={clear}
        />
      )}
    </Fragment>
    </div>
  );
};
