import React, { Fragment, useState } from "react";
import { Field as FinalField } from "react-final-form";
import { Radio, IRadioProps } from "./index";

export interface IFormProps extends IRadioProps {
  name: string;
  onClear?: (val: string) => void;
  required?: boolean;
  label?: string;
}

export const FormRadio = ({
  onClear = (val: string) => {},
  required = false,
  value,
  ...props
}: IFormProps) => {
  const clear = () => onClear(props.name);

  return (
    <FinalField
      type="radio"
      value={value}
      name={props.name}
      validate={(val) => {
        if (required) {
          if (!val) {
            let errors = {};
            errors[props.name] = "required";
            return errors;
          }
        }
        return undefined;
      }}
      displayEmpty
    >
      {({ input, meta }) => {
        return (
          <>
            <Radio
              {...props}
              {...input}
              error={meta.error && meta.error[props.name]}
            />
          </>
        );
      }}
    </FinalField>
  );
};
