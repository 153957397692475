/** @jsx jsx */
import React, { Fragment, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { TranslationEn } from '@assets/i18n/en';
import { RadioModalBody } from '../../shared/RadioModal/RadioModalBody';

enum RadiosEnum {
	NEW_FAMILY_ACCOUNT = 'newFamilyAccount',
	EXISTING_FAMILY_ACCOUNT = 'existingFamilyAccount',
}

interface IProps {
	handleBack?: () => void;
	goToCreateFamilyAccount: (val: string | number) => void;
}

export const CustomerAccountModalBody = ({ handleBack, goToCreateFamilyAccount }: IProps) => {
	const labels = TranslationEn.customers.customerHeader;

	const radios = [
		{
			value: RadiosEnum.NEW_FAMILY_ACCOUNT,
			label: labels.customerAccountModal.radios.newFamilyAccount,
		},

		{
			value: RadiosEnum.EXISTING_FAMILY_ACCOUNT,
			label: labels.customerAccountModal.radios.existingFamilyAccount,
			disabled: true,
		},
	];

	return (
		<RadioModalBody
			radios={radios}
			labels={labels.customerAccountModal}
			handleActionButton={goToCreateFamilyAccount}
			handleBack={handleBack}
			defaultValue={RadiosEnum.NEW_FAMILY_ACCOUNT}
		/>
	);
};
