/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { i18n } from '../../../lib/i18n';
import { Column } from '../../../atoms/layoutPrimitives';
import { ETypography, ETypographyColor } from '../../../types';
import { Typography } from '../../../atoms';
import { TDiscountApplicant } from '../types';
import { Pricify } from '../../../lib/price';
import { Fragment, ReactNode } from 'react';

interface Props {
	totalAmount: number;
	type: TDiscountApplicant;
	invoiceSubtotalBalance?: number;
	tabs?: ReactNode;
}

export const DiscountHeader = ({ totalAmount, invoiceSubtotalBalance, type, tabs }: Props) => {
	const labels = i18n.addDiscount.header;
	const isInvoice = type === 'invoice';

	return (
		<Column fullWidth gap={'1rem'}>
			<Typography type={ETypography.h6Accented} color={ETypographyColor.primary}>
				{labels.title}
			</Typography>
			<Typography type={ETypography.body2Accented} color={ETypographyColor.secondary}>
				{isInvoice
					? labels.invoiceAmount(Pricify(totalAmount, undefined, undefined, true))
					: labels.lineItemAmount(Pricify(totalAmount, undefined, undefined, true))}
			</Typography>
			{invoiceSubtotalBalance ? (
				<Typography type={ETypography.body2Accented} color={ETypographyColor.secondary}>
					{labels.invoiceSubtotalBalance(Pricify(invoiceSubtotalBalance, undefined, undefined, true))}
				</Typography>
			) : (
				<Fragment />
			)}
			{tabs}
		</Column>
	);
};
