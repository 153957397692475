import { useState } from 'react';
import { TabsEnum } from '../organisms/discount-modal/types';

export const useMultiTabForm = (initialTab?: TabsEnum) => {
	const [currentTab, setCurrentTab] = useState(initialTab);

	const handleTabChange = (tabId: TabsEnum) => {
		setCurrentTab(tabId);
	};

	const resetCurrentTab = () => {
		setCurrentTab(initialTab);
	};

	return {
		currentTab,
		handleTabChange,
		resetCurrentTab,
	};
};
