/** @jsx jsx */
import { EDocType, useCustomers } from 'app/react/components/customers/hooks/useCustomers';
import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { jsx, css } from '@emotion/react';
import { HeaderContainer, SideContainer, BackButton, HeaderTitle } from '../../shared/Header';
import { Button, Segment, ModalWindow, useModal } from '@bondsports/utils';
import { MiddleWhiteContainer, SectionContainer } from '../../shared/Containers';
import { RCOrganization } from '@rcenter/core';
import { useLocation, useParams, Redirect, useHistory } from 'react-router-dom';
import { flex, flexCol } from '../../../styles/utils';
import { colors } from '../../../styles/theme';
import { TranslationEn } from 'assets/i18n/en';
import { Table } from '../../shared/NewTable';
import { Message } from '../utils/Message';
import { customersApi } from '../../../lib/api/customersApi';
import { ICustomer } from '../../../types/customers';
import { useErrorModalDepricated } from '../../shared/ErrorDepricated';
import { IInvoice, detailsTableContainer, ITaxType } from '../singleInvoice';
import { membershipApi } from '../../../lib/api/membershipApi';
import { LineItemColumn } from '../singleInvoice/LineItemColumn';
import { SummaryLine } from '../singleInvoice/SummaryLine';
import { Notes } from '../singleInvoice/Notes';
import { Input, Pricify } from '@bondsports/utils';
import { usePayments } from '../../payments';
import { Icons } from 'app/react/styles/theme';
import { useOrganization } from 'app/react/hooks/useOrganization';
import { ProductTypesEnum } from '@app/shared/services/programs/programs.service';
import { AlignType } from '../../shared/NewTable/types';
import { EPaymentMethod } from '../../../types/payment';
import { NO_SUBJECT } from '../../../types/helpers';

interface Props {
	organization: RCOrganization;
}

const container = css`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const linkToInvoiceCss = css`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 1.2rem;
	line-height: 1.5rem;
	text-decoration-line: underline;
	cursor: pointer;
	margin-top: 5px;
	margin-right: 2px;
	color: ${colors.formInputBg};
`;

const totalPaid = css`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 1.2rem;
	line-height: 1.5rem;
	margin-top: 5px;
	margin-right: 2px;
	margin-left: 5px;
	color: ${colors.lightText};
`;

const subTitle = css`
	${flex};
	align-items: center;
`;

const centerAlignmetn = css`
	${flex};
	justify-content: center;
`;

const classicButtonCss = css`
	font-weight: bold;
	text-transform: uppercase;
`;

const buttonContainer = css`
	${flex};
	justify-content: flex-end;
	margin-top: 25px;
`;
//TBD: CLEANUP NEEDED this component seems to be NOT in use. if it is, there is a lot of cleanup needed inside the component
export const RefundDeprecated = ({ organization }: Props) => {
	const [isRedirect, setRedirect] = useState(false);
	const { pathname } = useLocation();
	const { customerId, invoiceId } = useParams<{ customerId: string; invoiceId: string }>();
	const [loading, setLoading] = useState(true);
	const [customerState, setCustomerState] = useState<ICustomer>();
	const [invoiceState, setInvoiceState] = useState<IInvoice>();
	const { ErrorModalDepricated, ErrorToggle } = useErrorModalDepricated();
	const [discounts, setDiscounts] = useState([]);
	const [totalDiscount, setTotalDiscount] = useState<number>(0);
	const [itemsRows, setItemsRows] = useState([]);
	const [paymentsRows, setPaymentsRows] = useState([]);
	const [amountToRefund, setAmount] = useState(0);
	const [maxAmountToRefund, setMaxAmount] = useState(0);
	const [notes, setNotes] = useState<string>('');
	const [refundManual, setManual] = useState<boolean>(false);
	const { setOrganizationId } = useOrganization();
	const [error, setError] = useState<string>('');
	const [totalTax, setTotalTax] = useState<{ taxesExcl: ITaxType; taxesInc: ITaxType }>({
		taxesExcl: {},
		taxesInc: {},
	});
	const history = useHistory();
	const labels = TranslationEn.payments;
	const { isShowing: isRefundShowing, toggle: RefundToggle } = useModal();
	const { Refund: RefundComponent } = usePayments();
	const [originalPrice, setOriginalPrice] = useState(0);
	const [refundPrice, setRefundPrice] = useState(0);
	const [selectedItems, setSelectedItems] = useState([]);
	const { expandLineItemData, handleTaxesOnLineItem } = useCustomers();

	const newLink = pathname.split('/');
	newLink.pop();
	const redirectTo = newLink.join('/');

	const fetchData = () => {
		customersApi
			.getInvoiceById_DEPRICATED({
				customerId: Number(customerId) || 1,
				invoiceId: Number(invoiceId),
				extended: true,
				organizationId: organization.id,
			})
			.then(data => {
				if (data.statusCode) {
					// alert("Oops, looks like something went wrong");
				} else {
					customersApi
						.getCustomerByUserId(organization.id, data.payingUserId)
						.then(res => {
							if (res.data) {
								setCustomerState(res.data);
								setLoading(false);
							} else {
								ErrorToggle();
							}
						})
						.catch(e => {
							ErrorToggle();
						});
					setInvoiceState(data);
					// setLoading(false);
					// handle items rows
					let entitlementGroups = '';
					const entitlementObj = {};
					let totalDiscountAmount = 0;
					const discounts = [];
					let calculatedOriginalPrice = 0;
					const taxesInc = {};
					const taxesExcl = {};
					let refundSum = 0;
					const lineItems = {};
					data.lineItems
						.sort((a, b) => {
							return a.id - b.id;
						})
						.forEach(line => {
							if (line.productType === ProductTypesEnum.TAX) {
								if (lineItems[line.previousPurchaseLineId]) {
									lineItems[line.previousPurchaseLineId].tax = line;
								}
							} else {
								lineItems[line.id] = line;
							}
						});

					const items = Object.keys(lineItems)
						.filter(itemId => {
							const item = lineItems[itemId];
							if (item.paymentStatus === 'refunded') {
								refundSum += item.unitPrice;
							}
							return !item.isRefunded && item.paymentStatus !== 'refunded';
						})
						.filter(itemId => {
							const item = lineItems[itemId];
							return item.price >= 0;
						})
						.map(itemId => {
							const item = lineItems[itemId];
							const entitlementId = item.entitlementGroupId;
							if (entitlementId && entitlementId !== -1) {
								if (entitlementObj[entitlementId]) {
									entitlementObj[entitlementId].amount =
										entitlementObj[entitlementId].amount - (item.price - item.originalPrice);
								} else {
									if (entitlementGroups === '') {
										entitlementGroups = `${entitlementId}`;
									} else {
										entitlementGroups += `,${entitlementId}`;
									}
									entitlementObj[entitlementId] = {
										amount: item.price - item.originalPrice,
										percentage: Number(100 - (item.price / item.originalPrice) * 100).toFixed(2),
									};
								}
								totalDiscountAmount += item.price - item.originalPrice;
							}
							if (entitlementId === -1) {
								// entitlementObj[`${item.id}-1`] = {
								//   amount: item.price - item.originalPrice,
								//   percentage: +(100 - (item.price / item.originalPrice) * 100).toFixed(2),
								// };
								totalDiscountAmount += item.price - item.originalPrice;
								const DiscountObj = {
									createdAt: '',
									id: `${item.id}-1`,
									name: item.discountDescription,
									organizationId: organization.id,
									updatedAt: '',
									amount: item.price - item.originalPrice,
									percentage: Number(100 - (item.price / item.originalPrice) * 100).toFixed(2),
								};
								discounts.push(DiscountObj);
							}

							membershipApi.getEntitlementGroupsByOrganiztionId(organization.id, entitlementGroups).then(response => {
								const relevantEntitlement = entitlementGroups.split(',');
								response.map(entitlement => {
									if (relevantEntitlement.includes(String(entitlement.id))) {
										discounts.push({ ...entitlement, ...entitlementObj[entitlement.id] });
									}
								});
								setDiscounts(discounts);
								setTotalDiscount(totalDiscountAmount);
							});

							handleTaxesOnLineItem(item, totalTax, EDocType.PAYMENT);
							const obj = expandLineItemData(item);

							calculatedOriginalPrice += item.unitPrice;

							const priceToRefund = item.displayTotalPrice + (!item.tax?.isTaxInclusive ? item.tax?.price ?? 0 : 0);
							// if (item.tax && !item.tax.isTaxInclusive) {
							// 	priceToRefund = (item.unitPrice + item.tax.price) * item.quantity;
							// }

							return { ...obj, id: item.id, priceToRefund };
						});
					setTotalTax({ taxesExcl, taxesInc });
					setItemsRows(items);
					setRefundPrice(refundSum);
					setOriginalPrice(calculatedOriginalPrice);
					// handle payments rows
					let creditCardCounter = 0;
					const payments = data.invoiceToPayments.map(invoice => {
						if (
							[EPaymentMethod.CARD, EPaymentMethod.CARD_ON_TERMINAL].includes(invoice?.invoice?.paymentType) &&
							invoice.paidAmount > 0
						) {
							creditCardCounter++;
						}
						const paymentObj = {
							date: invoice.updatedAt,
							id: invoice.invoice.id,
							method: invoice.invoice.paymentType,
							amount: invoice.invoice.price,
						};
						return paymentObj;
					});
					if (creditCardCounter > 1) {
						setError(labels.errors.noSupportMultipleCard.refund);
						ErrorToggle();
					}
					setPaymentsRows(payments);
				}
			})
			.catch(e => {
				ErrorToggle();
			});
	};

	const ItemsColumns = [
		{
			id: 'item',
			label: ` ${TranslationEn.customers.paymentsInvoices.purchasedItems} (${itemsRows.length})`,
			type: 'custom',
			component: state => {
				return <LineItemColumn {...state} />;
			},
			styling: { align: AlignType.LEFT },
		},
		{
			id: 'quantity',
			label: TranslationEn.customers.paymentsInvoices.quantity,
			type: 'string',
		},
		{
			id: 'price',
			label: TranslationEn.memberships.dashboard.unitPrice,
			type: 'currency',
		},
		{
			id: 'tax',
			label: TranslationEn.customers.paymentsInvoices.tax,
			type: 'string',
		},
		{
			id: 'total',
			label: TranslationEn.customers.paymentsInvoices.total,
			type: 'currency',
			styling: { align: AlignType.RIGHT },
		},
	];

	const handleRedirection = () => {
		history.push(redirectTo);
	};

	const handleSelectItem = (val: string | number) => {
		let newArr = [...selectedItems];
		if (newArr.includes(val)) {
			newArr = newArr.filter(id => id !== val);
		} else {
			newArr = [...newArr, val];
		}
		setSelectedItems(newArr);
		let price = 0;
		itemsRows.forEach(item => {
			if (newArr.includes(item.id)) {
				price += item.priceToRefund;
			}
		});
		setAmount(price);
	};

	useEffect(() => {
		setOrganizationId(organization.id);
	}, [organization]);

	// callback to successfull refund
	const handleCallback = () => {
		handleRedirection();
	};

	// initial fetching
	useEffect(() => {
		if (!isRefundShowing) {
			fetchData();
		}
	}, [isRefundShowing]);

	useEffect(() => {
		if (invoiceState && invoiceState.isRefunded) {
			//handleRedirection();
		}
	}, [invoiceState]);

	// reset selection when toggle the refund method
	useEffect(() => {
		setSelectedItems([]);
		if (refundManual) {
			let newAmount = 0;
			invoiceState.invoiceToPayments.map(invoiceToPayment => {
				newAmount += invoiceToPayment?.paidAmount;
			});
			setAmount(newAmount);
			setMaxAmount(newAmount);
		} else {
			setAmount(0);
			setMaxAmount(0);
		}
	}, [refundManual]);

	if (loading) {
		return (
			<Fragment>
				<ErrorModalDepricated message={error} />
				<div>{TranslationEn.isLoading}</div>
			</Fragment>
		);
	}

	return (
		<Fragment>
			<ErrorModalDepricated message={error} isDismissable={false} goBack />
			<div data-aid="customersRefund" css={container}>
				<HeaderContainer>
					<SideContainer>
						<BackButton to={redirectTo} />
						<div>
							<div css={flex}>
								<HeaderTitle>{TranslationEn.customers.paymentsInvoices.issueRefund}</HeaderTitle>
							</div>
							<div css={subTitle}>
								<div css={linkToInvoiceCss} onClick={() => setRedirect(true)}>
									{isRedirect && <Redirect to={redirectTo} />}
									{TranslationEn.customers.paymentsInvoices.invoiceNum} {invoiceId}
								</div>
								<div css={totalPaid}>
									{labels.totalPaid} {Pricify(invoiceState.price)}
								</div>
							</div>
						</div>
					</SideContainer>
					<SideContainer>
						<Button
							data-aid="button-customersRefund-cancel"
							sizer="S"
							theme="basic"
							css={classicButtonCss}
							onClick={handleRedirection}
						>
							{TranslationEn.memberships.selectPage.cancel}
						</Button>
						<Button
							data-aid="button-customersRefund-refund"
							sizer="S"
							theme="primary"
							disabled={amountToRefund === 0}
							onClick={RefundToggle}
						>
							{labels.refunds.refund} {Pricify(amountToRefund)}
						</Button>
					</SideContainer>
				</HeaderContainer>
				<MiddleWhiteContainer>
					<Message type="note">{labels.refunds.defaultMessage}</Message>
					<div data-aid="customerRefund-message" css={centerAlignmetn}>
						<Segment
							label1={labels.refunds.refundByItems}
							label2={labels.refunds.manual}
							isPressed={refundManual}
							setPressed={setManual}
						/>
					</div>
					{refundManual ? (
						<div style={{ marginBottom: '25px' }}>
							<Input
								data-aid="customerRefund-input-number"
								type="number"
								leftIcon={Icons.dollar}
								decimalScale={2}
								step={0.01}
								sizer="M"
								label={labels.refunds.inputLabel}
								helper={`${Pricify(amountToRefund)} ${labels.refunds.inputHelper}`}
								value={amountToRefund}
								onChange={e => setAmount(Number(e.target.value))}
								rightButton={amountToRefund !== maxAmountToRefund ? Pricify(maxAmountToRefund) : ''}
								onRightButtonClick={() => {
									if (amountToRefund !== maxAmountToRefund) {
										setAmount(maxAmountToRefund);
									}
								}}
							/>
						</div>
					) : (
						<Fragment>
							<SectionContainer label={TranslationEn.memberships.dashboard.details}>
								<div data-aid="customerRefund-table" css={detailsTableContainer}>
									<Table
										rows={itemsRows}
										columns={ItemsColumns}
										pagination={false}
										subject={NO_SUBJECT}
										isHoverRow={false}
										isSelectRow
										selectedArr={selectedItems}
										onSelect={handleSelectItem}
									/>
								</div>
								<div
									data-aid="customerRefund-summary"
									css={flexCol}
									style={{ padding: '1rem', background: '#FAFCFD', borderRadius: '3px' }}
								>
									<SummaryLine label={TranslationEn.payments.items.subTotal} value={Pricify(invoiceState.price)} />
									{refundPrice < 0 && (
										<SummaryLine label={TranslationEn.payments.items.refunded} value={Pricify(refundPrice)} />
									)}
									{Object.keys(totalTax.taxesExcl).map(percentage => {
										return (
											<SummaryLine
												label={`${TranslationEn.payments.items.taxExc} (${percentage}%)`}
												value={Pricify(totalTax.taxesExcl[percentage])}
											/>
										);
									})}
									{Object.keys(totalTax.taxesInc).map(percentage => {
										return (
											<SummaryLine
												label={`${TranslationEn.payments.items.taxInc} (${percentage}%)`}
												value={Pricify(totalTax.taxesInc[percentage])}
											/>
										);
									})}
									{discounts.length > 0 && (
										<React.Fragment>
											<SummaryLine
												label={TranslationEn.customers.paymentsInvoices.discount}
												value={Pricify(totalDiscount)}
											/>
											{discounts.map((discount, index) => {
												return (
													<SummaryLine
														sub
														label={`${discount.name} (${discount.percentage}%)`}
														value={Pricify(discount.amount)}
														key={index}
													/>
												);
											})}
										</React.Fragment>
									)}
									<SummaryLine
										label={TranslationEn.customers.paymentsInvoices.total}
										weight={500}
										value={Pricify(invoiceState.price)}
									/>
								</div>
							</SectionContainer>
						</Fragment>
					)}
					<div>
						<Notes isComingSoon={false} value={notes} setValue={setNotes} />
						<div css={buttonContainer}>
							<Button
								data-aid="button-customersRefund-page"
								sizer="S"
								theme="basic"
								css={classicButtonCss}
								onClick={handleRedirection}
							>
								{TranslationEn.memberships.selectPage.cancel}
							</Button>
							<Button
								data-aid="button-customersRefund-primary"
								sizer="S"
								theme="primary"
								disabled={amountToRefund === 0}
								onClick={RefundToggle}
							>
								{labels.refunds.refund} {Pricify(amountToRefund)}
							</Button>
						</div>
					</div>
				</MiddleWhiteContainer>
			</div>

			{/*<RefundComponent
				customerId={Number(customerId)}
				invoiceId={Number(invoiceId)}
				totalAmount={amountToRefund}
				toggle={RefundToggle}
				lineItemId={selectedItems}
				onSuccess={handleCallback}
				isRefundShowing={isRefundShowing}
			/> */}
		</Fragment>
	);
};
