/** @jsxRuntime classic */
/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/react';
import { ETypography, ETypographyColor } from '../../../types';
import { CHARACHERS } from '../../../lib/constants';
import { Typography } from '../../../atoms';
import { customerDetailLineCss, detailsCss } from '../styles';

interface Props {
	title: string;
	data: string;
}

export const CustomerDetailLine: FC<Props> = (props: Props) => {
	const { title, data } = props;
	return (
		<div css={customerDetailLineCss} data-aid="CustomerDetailLine">
			<Typography color={ETypographyColor.primary} type={ETypography.body1}>
				{title}
				{CHARACHERS.COLON}
			</Typography>
			<div css={detailsCss(data)}>
				<Typography color={ETypographyColor.secondary} type={ETypography.body1}>
					{data}
				</Typography>
			</div>
		</div>
	);
};
