/** @jsxRuntime classic */
/** @jsx jsx */

import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { jsx } from '@emotion/react';
import { spaceColumnContainer } from '../../styles';
import { IDailyViewProps } from '../../types';
import { DayToDrop } from './DayToDrop';
// import { useMiddlewareContext } from "../../hooks/useMiddlewareContext";
import { CurrentTimeLine } from './CurrentTimeLine';
import { spaceWidth } from '../styles';

export const DailyViewVertical: FC<IDailyViewProps> = ({
	spaces,
	EventComponent,
	isModeChanged,
	onAddNewSlotClick,
	hourSize,
}) => {
	// const { options } = useMiddlewareContext();
	// const [isLoading, setLoading] = useState(true);

	const [updates, setUpdates] = useState(false);

	useEffect(() => {
		setUpdates(true);
	}, [hourSize]);

	const columnsCount = useMemo(() => {
		return spaces.reduce((acc, { children }) => acc + (children.length || 1), 0);
	}, [spaces]);

	useEffect(() => {
		if (updates) setUpdates(false);
	}, [updates]);

	if (updates) return null;

	return (
		<Fragment>
			<Fragment>
				{spaces.map((space, index) => {
					const hiddenEvents = false;

					if (space.children.length === 0) {
						return (
							<div key={space.id} css={spaceColumnContainer(columnsCount, 1)} data-aid="DailyViewVertical-daily">
								<DayToDrop
									id={String(space.id)}
									EventComponent={EventComponent}
									withoutChildren
									isModeChanged={isModeChanged}
									hiddenEvents={hiddenEvents}
									onAddNewSlotClick={onAddNewSlotClick}
								/>
							</div>
						);
					} else {
						return (
							<div key={space.id} css={spaceColumnContainer(columnsCount, space.children.length || 1)}>
								<Fragment>
									{space.children.map(item => {
										return (
											<DayToDrop
												key={item.id}
												id={String(item.id)}
												EventComponent={EventComponent}
												isModeChanged={isModeChanged}
												hiddenEvents={hiddenEvents}
												onAddNewSlotClick={onAddNewSlotClick}
											/>
										);
									})}
								</Fragment>
							</div>
						);
					}
				})}
			</Fragment>
			<CurrentTimeLine width={columnsCount * spaceWidth} />
		</Fragment>
	);
};
