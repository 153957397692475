/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { ChangeEvent, FC, Fragment, useState } from 'react';
import { i18n } from '../../../../lib/i18n';
import { ECollapsedNotes } from '../../types';
import { DefaultNotesLine } from '../default-notes-line';
import { TwoOpenNotes } from '../two-open-notes';
import { OneOpenNote } from '../one-open-note';

interface Props {
	type: ECollapsedNotes;
	isPublicFirst: boolean;
	onOpen: (isPublic: boolean) => void;
	onChange: (publicNote?: string, privateNote?: string) => void;
}

export const CollapsedNotesLine: FC<Props> = ({ type, isPublicFirst, onOpen, onChange }) => {
	const [publicNote, setPublicNote] = useState<string | undefined>();
	const [privateNote, setPrivateNote] = useState<string | undefined>();
	const labels = i18n.addDiscount.content.custom.notes;

	const handleOpenPublic = () => {
		onOpen(true);
	};

	const handleOpenPrivate = () => {
		onOpen(false);
	};

	const handlePublicNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const note = e.target?.value;
		setPublicNote(note);
		handleOnChange(true, note);
	};

	const handlePrivateNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const note = e.target?.value;
		setPrivateNote(note);
		handleOnChange(false, note);
	};

	const handleBothNoteChange = (e: ChangeEvent<HTMLTextAreaElement>, type: ECollapsedNotes) => {
		const isPublic = type === ECollapsedNotes.PUBLIC;
		if (isPublic) {
			handlePublicNoteChange(e);
		} else {
			handlePrivateNoteChange(e);
		}
	};

	const handleOnChange = (isPublic: boolean, note: string) => {
		if (isPublic) {
			onChange(note, privateNote);
		} else {
			onChange(publicNote, note);
		}
	};

	return (
		<Fragment>
			{type === ECollapsedNotes.NONE ? (
				<DefaultNotesLine onOpenPublic={handleOpenPublic} labels={labels} onOpenPrivate={handleOpenPrivate} />
			) : type === ECollapsedNotes.PUBLIC ? (
				<OneOpenNote
					labels={labels[ECollapsedNotes.PUBLIC]}
					onChange={handlePublicNoteChange}
					value={publicNote}
					onClick={handleOpenPrivate}
				/>
			) : type === ECollapsedNotes.PRIVATE ? (
				<OneOpenNote
					labels={labels[ECollapsedNotes.PRIVATE]}
					onChange={handlePrivateNoteChange}
					value={privateNote}
					onClick={handleOpenPublic}
				/>
			) : (
				<TwoOpenNotes
					publicValue={publicNote}
					privateValue={privateNote}
					isPublicFirst={isPublicFirst}
					labels={labels.both}
					onChange={handleBothNoteChange}
				/>
			)}
		</Fragment>
	);
};
