import { TagColorsMapper } from '../../../components/Tag/style';
import { Icons } from '../../../types';
import { MembershipStatusEnum } from './types';

export const iconMapper = {
	[MembershipStatusEnum.ACTIVE]: Icons.indications_approved,
	[MembershipStatusEnum.ACTIVE_CANCELLED]: Icons.indications_approved,
	[MembershipStatusEnum.CANCELLED]: Icons.indications_cancelled,
	[MembershipStatusEnum.EXPIRED]: Icons.indications_cancelled,
	[MembershipStatusEnum.PAUSED]: Icons.indications_paused,
	[MembershipStatusEnum.PENDING]: Icons.indications_question_mark,
};

export const membershipStatusColorMapper = {
	[MembershipStatusEnum.ACTIVE]: TagColorsMapper.green.primary,
	[MembershipStatusEnum.ACTIVE_CANCELLED]: TagColorsMapper.green.primary,
	[MembershipStatusEnum.CANCELLED]: TagColorsMapper.red.primary,
	[MembershipStatusEnum.EXPIRED]: TagColorsMapper.disabledGray.primary,
	[MembershipStatusEnum.PAUSED]: TagColorsMapper.blue.primary,
	[MembershipStatusEnum.PENDING]: TagColorsMapper.yellow.primary,
};
