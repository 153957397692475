import { environment } from '../../../../environments/environment';
import { network } from '../network';
import { DiscountTypeEnum } from '@bondsports/types';

const v4Api = `${environment.CS_URLS.API_ROOT_V4}`;

const getOrgDiscounts = async (organizationId: number, type?: DiscountTypeEnum) => {
	const response = await network.get(`${v4Api}/discounts/organization/${organizationId}?type=${type}`);
	return response;
};

export const discountsApi = { getOrgDiscounts };
