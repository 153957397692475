import * as  React from 'react';

export const BnRightAction = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99992 14.1667C10.9204 14.1667 11.6666 14.9129 11.6666 15.8333C11.6666 16.7538 10.9204 17.5 9.99992 17.5C9.07944 17.5 8.33325 16.7538 8.33325 15.8333C8.33325 14.9129 9.07944 14.1667 9.99992 14.1667ZM9.99992 8.33333C10.9204 8.33333 11.6666 9.07952 11.6666 10C11.6666 10.9205 10.9204 11.6667 9.99992 11.6667C9.07944 11.6667 8.33325 10.9205 8.33325 10C8.33325 9.07952 9.07944 8.33333 9.99992 8.33333ZM9.99992 2.5C10.9204 2.5 11.6666 3.24619 11.6666 4.16667C11.6666 5.08714 10.9204 5.83333 9.99992 5.83333C9.07944 5.83333 8.33325 5.08714 8.33325 4.16667C8.33325 3.24619 9.07944 2.5 9.99992 2.5Z"
      fill="currentColor"
    />
  </svg>
);
