/** @jsxRuntime classic */
/* * @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import React, { Fragment, ReactNode } from "react";
import { Typography } from "../../atoms";
import { Button } from "../../components/Button";
import { Checkbox } from "../../components/Checkbox";
import { BnIcon } from "../../components/icons_v2";
import { useColors } from "../../hooks/useColors";
import { useTypography } from "../../hooks/useTypography";
import { ETypography, ETypographyColor, Icons } from "../../types/theme";
import { SegmentCardContainer } from "./style";

export interface ISegmentCardProps {
  withCta?: boolean;
  withCheckbox?: boolean;
  disabled?: boolean;
  body?: ReactNode;
  title?: string;
  leftInfo?: string;
  handleBasicButton?: () => void;
  handleMainButton?: () => void;
  isCheckBoxDisabled?: boolean;
  checked?: boolean;
  setChecked?: (val: boolean) => void;
  ctaText?: string;
}

export const SegmentCard = ({
  withCta = false,
  withCheckbox = false,
  disabled = false,
  checked = false,
  isCheckBoxDisabled = false,
  setChecked,
  body,
  title,
  leftInfo,
  handleBasicButton,
  handleMainButton,
  ctaText = "EDIT SEGMENT",
}: ISegmentCardProps) => {
  const { colors } = useColors();
  const { typography } = useTypography();
  return (
    <div data-aid="segmentCard" css={SegmentCardContainer(colors, typography, disabled)}>
      <div className="disabled" />
      <div className="header">
        <div className="title">
          {withCheckbox && (
            <Checkbox
              checked={checked}
              isDisabled={isCheckBoxDisabled || disabled}
              setChecked={setChecked}
            />
          )}
          <Typography
            color={ETypographyColor.secondary}
            type={ETypography.caption}
          >
            {title}
          </Typography>
        </div>
        <Typography
          color={ETypographyColor.secondary}
          type={ETypography.caption}
        >
          {leftInfo}
        </Typography>
      </div>
      <div className="divider" />
      <div className="body">{body}</div>
      {withCta && (
        <Fragment>
          <div className="divider" />
          <div className="footer">
            <Button
              data-aid="button-segmentCard-basic"
              theme="basic"
              sizer="XS"
              type="button"
              onClick={handleBasicButton}
            >
              <BnIcon className="icon" icon={Icons.delete_outline} />
              Delete
            </Button>
            <Button
              data-aid="button-segmentCard-main"
              theme="secondary"
              sizer="XS"
              type="button"
              onClick={handleMainButton}
            >
              {ctaText}
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};
