/** @jsx jsx */
/** @jsxRuntime classic */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { ETypography, ETypographyColor, IColors, Icons } from '../../../types';
import { Typography } from '../../../atoms/Typography';
import { ColorEllipse } from './ColorEllipse';

const wrapperCss = css`
	display: flex;
	align-items: center;
	gap: 6px;
	text-transform: capitalize;
`;

interface Props {
	colorId: string;
	name: string;
}

export const ColorDetails = ({ colorId, name }: Props) => {
	return (
		<div css={wrapperCss} data-aid="ColorDetails-ColorPicker">
			<ColorEllipse colorId={colorId} size="s" />
			<Typography type={ETypography.body2} color={ETypographyColor.primary}>
				{name}
			</Typography>
		</div>
	);
};
