import { AddNoteDto, EditNoteDto, NoteDto } from '../entities/notes';
import { GenericResponseDto, PaginationResultDto } from '../dtos';
import { ResourceNameTypeEnum } from '../enums';

type TGetNotesAction = (
	page?: number,
	itemsPerPage?: number,
	alertsOnly?: boolean,
	signal?: AbortSignal
) => Promise<PaginationResultDto<NoteDto>>;

type TAddNoteAction = (dto: AddNoteDto, signal?: AbortSignal) => Promise<NoteDto>;
type TEditNoteAction = (noteId: number, dto: EditNoteDto, signal?: AbortSignal) => Promise<NoteDto>;
type TArchiveNoteAction = (noteId: number, signal?: AbortSignal) => Promise<GenericResponseDto>;

export type TNotesPopupActions = {
	getNotes?: TGetNotesAction;
	addNote?: TAddNoteAction;
	editNote?: TEditNoteAction;
	archiveNote?: TArchiveNoteAction;
};

export enum NotesPopupStepEnum {
	LIST = 'list',
	ADD_NOTE = 'add_note',
	EDIT_NOTE = 'edit_note',
	DELETE_NOTE = 'delete_note',
}

export type NotesPopupLabels = {
	form: {
		titleInputPlaceHolder: string;
		contentInputPlaceHolder: string;
		pinToTop: string;
		pinToTopAndDisplay: string;
		pinToTopAndDisplayTooltip: string;
		pinToTopAndDisplayTooltipMaxLimit: string;
		buttons: {
			cancel: string;
			save: string;
		};
	};
	list: {
		notedBy: string;
		dateAndTime: (date: Date, timezone: string) => string;
		buttons: {
			goBack: string;
			addNewNote: string;
		};
		errors: {
			tryLater: string;
			noData: (entityType: ResourceNameTypeEnum, entityId: number) => string;
		};
		loading: {
			description: string;
			title: string;
		};
	};
	archive: {
		description: string;
		buttons: {
			cancel: string;
			confirm: string;
		};
	};
	titles: {
		[NotesPopupStepEnum.LIST]: string;
		[NotesPopupStepEnum.ADD_NOTE]: string;
		[NotesPopupStepEnum.EDIT_NOTE]: string;
		[NotesPopupStepEnum.DELETE_NOTE]: string;
	};
};
