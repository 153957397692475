import { IStation } from '../../types/station';
import { network } from '../network';
import { v4Api } from './apiv4';

const getStations = async (organizationId: number, facilityId: number) => {
	const response = await network.get(`${v4Api}/payment/${organizationId}/${facilityId}/stations`);
	return response;
};

const getStation = async (organizationId: number, facilityId: number, stationId: number) => {
	const response = await network.get(`${v4Api}/payment/${organizationId}/${facilityId}/stations/${stationId}`);
	return response;
};

const updateStation = async (organizationId: number, data: IStation) => {
	const response = await network.put(`${v4Api}/payment/${organizationId}/${data.facilityId}/stations/${data.id}`, {
		...data,
	});
	return response;
};

const validateStation = async (terminalToken: string) => {
	const response = await network.post(`${v4Api}/payment/validate-station`, {
		terminalToken,
	});
	return response;
};

export const stationApi = {
	getStations,
	getStation,
	updateStation,
	validateStation,
};
