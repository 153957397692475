import { BaseNoteDto } from '@bondsports/types';
import { isEqual } from 'lodash';
import { isEmptyObject } from '../../../lib/utils';

export function isValid<T extends BaseNoteDto>(
	values: Partial<T>,
	initialValues: Partial<T>,
	errors?: Record<string, string>
) {
	if (errors && !isEmptyObject(errors)) {
		return false;
	}

	const isOriginallySetToAlertAndNotPinned: boolean = initialValues.isAlert && values.isAlert && !values.isPinned;
	const isContentAndTitleSimilar: boolean =
		values.content === initialValues.content && values.title === initialValues.title;

	return !isEqual(values, initialValues) && !(isContentAndTitleSimilar && isOriginallySetToAlertAndNotPinned);
}
