import React from 'react';
import { css } from '@emotion/react';
import { Icons } from '../../types';
import { FrameContainer } from '../../components/FrameContainer';
import { Button } from '../../components/Button';
import { BnIcon } from '../../components/icons_v2';
import { SizeEnum } from '../../lib/constants';

const fullWidthButton = css`
  justify-content: space-between !important;
  width: 100%;
`;

export interface Props { title: string; buttons: { title: string, icon: Icons, onClick: () => void, sizer?: SizeEnum }[] }

export const ButtonList = ({ title, buttons }: Props) => {
  return (
    <FrameContainer title={title} data-aid={`${title}-ButtonList`}>
      {buttons.map((button, index) => (
        <Button
          data-aid={`button-${button.title}`}
          key={index}
          sizer={button.sizer || SizeEnum.LARGE}
          theme="basic"
          css={fullWidthButton}
          onClick={button.onClick}
        >
          <div>
            {button.title}
          </div>
          <BnIcon icon={button.icon} />
        </Button>
      ))}
    </FrameContainer>
  );
}