/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/react';
import { ComponentType } from 'react';

interface IStyledTableRowWrapperProps {
	key?: string | number;
	isChecked?: boolean;
	serializedCss?: SerializedStyles | SerializedStyles[];
}

export const StyledTableRowWrapper: ComponentType<IStyledTableRowWrapperProps> = ({
	children,
	key,
	isChecked = false,
	serializedCss = null,
}) => {
	return (
		<tr role="checkbox" aria-checked={isChecked} tabIndex={-1} key={key} css={serializedCss}>
			{children}
		</tr>
	);
};
