/** @jsxRuntime classic*/
/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/react';
import React, { FC, CSSProperties } from 'react';
import { colCss } from '../styles';

export interface ColumnProps {
	styles?: SerializedStyles;
	gap?: CSSProperties['gap'];
	align?: CSSProperties['alignItems'];
	fullWidth?: boolean;
	justify?: CSSProperties['justifyContent'];
	className?: string;
	children: React.ReactNode | React.ReactNode[];
}

export const Column: FC<ColumnProps> = ({
	gap = '8px',
	align = 'center',
	fullWidth = false,
	styles,
	className,
	children,
}) => {
	return (
		<div
			className={`column-primitive ${className}`}
			css={colCss(gap, align, fullWidth, styles)}
			data-aid="column-components"
		>
			{children}
		</div>
	);
};
