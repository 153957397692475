import React from 'react';

export const Bank = (props: any) => (
  <svg
    data-aid="svgBank"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6339 2.15411C11.8644 2.0553 12.1254 2.0553 12.356 2.15411L19.3548 5.1536C19.82 5.353 20.0356 5.89179 19.8362 6.35704C19.6368 6.82229 19.098 7.03781 18.6327 6.83842L11.9949 3.99365L5.35714 6.83842C4.89189 7.03781 4.35309 6.82229 4.1537 6.35704C3.95431 5.89179 4.16983 5.353 4.63508 5.1536L11.6339 2.15411ZM2.99653 9.07883C2.49035 9.07883 2.08002 9.48916 2.08002 9.99534C2.08002 10.5015 2.49035 10.9119 2.99653 10.9119H3.07985V20.077H2.99653C2.49035 20.077 2.08002 20.4873 2.08002 20.9935C2.08002 21.4997 2.49035 21.91 2.99653 21.91H3.99369L3.99636 21.91L3.99903 21.91H19.991L19.9937 21.91L19.9963 21.91H20.9935C21.4997 21.91 21.91 21.4997 21.91 20.9935C21.91 20.4873 21.4997 20.077 20.9935 20.077H20.9102V10.9119H20.9935C21.4997 10.9119 21.91 10.5015 21.91 9.99534C21.91 9.48916 21.4997 9.07883 20.9935 9.07883H19.9937H3.99636H2.99653ZM19.0772 10.9119V20.077H4.91287V10.9119H19.0772ZM8.9122 13.9947C8.9122 13.4885 8.50186 13.0782 7.99569 13.0782C7.48951 13.0782 7.07917 13.4885 7.07917 13.9947V16.9942C7.07917 17.5003 7.48951 17.9107 7.99569 17.9107C8.50186 17.9107 8.9122 17.5003 8.9122 16.9942V13.9947ZM11.995 13.0782C12.5012 13.0782 12.9115 13.4885 12.9115 13.9947V16.9942C12.9115 17.5003 12.5012 17.9107 11.995 17.9107C11.4888 17.9107 11.0785 17.5003 11.0785 16.9942V13.9947C11.0785 13.4885 11.4888 13.0782 11.995 13.0782ZM16.9109 13.9947C16.9109 13.4885 16.5005 13.0782 15.9944 13.0782C15.4882 13.0782 15.0778 13.4885 15.0778 13.9947V16.9942C15.0778 17.5003 15.4882 17.9107 15.9944 17.9107C16.5005 17.9107 16.9109 17.5003 16.9109 16.9942V13.9947Z"
      fill="currentColor"
    />
  </svg>
);
