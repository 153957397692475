import React, { useRef, ReactNode } from 'react';
import styled from '@emotion/styled';
import { useInfiniteScroll, FetchDataCallback } from '../../hooks/useInfiniteScroll';

const ScrollContainer = styled.div`
	height: 100%;
	width: 100%;
	overflow-y: auto;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
`;

export interface LayoutWithInfiniteScrollProps<T, F> {
	children: (items: T[], refetch: (filters: F) => void, isLoading?: boolean, hasMore?: boolean) => ReactNode;
	initialItemsPerPage: number;
	fetchDataCallback: FetchDataCallback<T, F>;
}

export const LayoutWithInfiniteScroll = <T extends unknown, F>({
	children,
	initialItemsPerPage,
	fetchDataCallback,
}: LayoutWithInfiniteScrollProps<T, F>) => {
	const scrollRef = useRef<HTMLDivElement>(null);
	const {
		items = [],
		refetch,
		hasMore,
		isLoading,
	} = useInfiniteScroll({
		fetchCallback: fetchDataCallback,
		scrollElementRef: scrollRef,
		initialItemsPerPage,
	});

	return (
		<ScrollContainer ref={scrollRef}>
			<Wrapper>{children(items, refetch, isLoading, hasMore)}</Wrapper>
		</ScrollContainer>
	);
};
