/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { FormInput } from '@bondsports/utils';
import { IFormInputWraper } from '../../familyAccount/formBody/types';
import { FormFieldWrapper } from './FormFieldWrapper';

export const FormInputWraper = ({ label, name, isRequired, type, helper, helperContent }: IFormInputWraper) => {
	return (
		<FormFieldWrapper>
			<FormInput
				name={name}
				sizer="S"
				label={label}
				hideError
				disableTrailing
				required={isRequired}
				type={type}
				helper={helper}
				helperContent={helperContent}
			/>
		</FormFieldWrapper>
	);
};
