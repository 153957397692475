import React, { useCallback, useState } from 'react';
import { AddonRelationType, Product, ProductPackageChild } from 'bondsports-utils/types/entities/product';
import { SelectedAddonInterface } from 'bondsports-utils/types/entities/addon';
import { PaginationMetaDto } from '@bondsports/types';
import { userApi } from '@app/react/lib/api/userApi';

export const useAddons = () => {
	const defaultItemPerPage = 10;
	const [addons, setAddons] = useState([]);
	const [addonsMeta, setAddonsMeta] = useState<PaginationMetaDto>({
		currentPage: 1,
		totalItems: 10,
		totalPages: 1,
		itemsPerPage: 10,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const toSelectedAddon = ({
		product,
		relationType,
	}: {
		product: Product;
		relationType: AddonRelationType;
	}): SelectedAddonInterface => {
		return {
			id: product.id,
			name: product.name,
			price: product && product.currPrice && product.currPrice.price,
			relationType,
			type: 'addon',
			package: product,
		};
	};

	function mapProductToAddon(child: ProductPackageChild) {
		return toSelectedAddon({
			product: child.product,
			relationType: child.relationType,
		});
	}

	const getUserProgramAddons = useCallback(
		async (
			isSegment = false,
			organizationId: number,
			userId: number,
			eventOrSegmentId: number,
			productUserId: number,
			page: number
		) => {
			const request = isSegment ? userApi.getUserSegmentAddons : userApi.getUserEventAddons;

			try {
				setIsLoading(true);
				const response = await request(
					organizationId,
					userId,
					eventOrSegmentId,
					productUserId,
					defaultItemPerPage,
					page
				);
				if (response.err) {
					throw new Error(response.err);
				} else {
					setError(null);
					setAddons(prevAddons => [...prevAddons, ...response.data]);
					setAddonsMeta(response.meta);
				}
			} catch (error) {
				setAddons([]);
				setError(error.message ?? error.toString());
			} finally {
				setIsLoading(false);
			}
		},
		[setAddons, setAddonsMeta, setError, setIsLoading]
	);
	return { mapProductToAddon, toSelectedAddon, getUserProgramAddons, addons, addonsMeta, isLoading, error };
};
