// import { Customer, Invoice, Organization } from '@bondsports/types';

import { TagColorsMapper } from '../../../components/Tag/style';
import { Icons } from "../../../types";

export interface IInvoiceTemplateState {
	invoice: /*Invoice*/ any & { customer: /*Customer*/ any };
	entitlementGroups: any[];
	organization: /*Organization*/ any;
	scheduledPayments?: any[];
}

export interface IInvoiceTemplateLabels {
	header: {
		print: 'Invoice no.';
		['payment-print']: 'Receipt no.';
	};
	billingInfo: {
		title: string;
		purchaseDate: string;
		customer: string;
	};
	details: {
		title: string;
		summary: {
			subtotal: string;
			taxRate: string;
			membershipDiscount: string;
			total: string;
			totalDue: string;
			tax: string;
			taxInc: string;
			fees?: string;
		};
		table: {
			columns: {
				item: string;
				quantity: string;
				price: string;
				tax: string;
				total: string;
			};
		};
	};
	landingPage: {
		title: string;
	};
	checkoutCard: {
		title: string;
		invoiceNum: string;
		memo: string;
		download: string;
		pay: string;
		paidOn: string;
	};
	paymentForm: {
		title: string;
	};
	paymentMethods: {
		title: string;
		description: string;
		newCreditCard: string;
		newACH: string;
		saved: string;
		pay: string;
		'gift-card': string;
		check: string;
		balance: string;
	};
	paymentsSection: {
		title: string;
		table: {
			columns: {
				customerName: string;
				id: string;
				amount: string;
				total: string;
				method: string;
				date: string;
				fees: string;
				status: string;
			};
		};
		summary: {
			amountDue: string;
			fees: string;
			amountPaid: string;
		};
	};
	scheduledSection: {
		title: string;
		table: {
			columns: {
				status: string;
				amount: string;
				date: string;
				fees: string;
				method: string;
				total: string
			};
		};
	};
	notes: {
		title: string;
	};
	payments: {
		paid: string;
		partial: string;
		not_paid: string;
	};
	appendix: {
		title: string;
		scheduleTab: {
			columns: {
				description: string;
				addons: string;
				dayTime: string;
				resource: string;
				event: string;
			};
			info: {
				title: string;
				schedule: string;
				participant: string;
				program: string;
				products: string;
				sessions: string;
				more: string;
			};
		};
	};
}

export enum EInvoiceVariant {
	BACKOFFICE = 'backoffice',
	BACKOFFICE_PREVIEW = 'backoffice-preview',
	CONSUMER = 'consumer',
	PRINT = 'print',
	PAYMENT = 'payment',
	PAYMENT_PRINT = 'payment-print',
}

export interface IResponse {
	appendixData: any;
	discounts: any;
	totalDiscountAmount: any;
	totalTax: any;
	originalPrice: any;
	items: any;
	payments: any;
}

export interface ITaxType {
	[x: number]: number;
}

export interface AppendixAddonItemDaysTimes {
	dateRange: string;
	timeRange: string[];
}

export interface AppendixAddonItem {
	description: string;
	addonCount: string;
	startDate: Date;
	subTitles: string[];
	daysTimes: AppendixAddonItemDaysTimes;
}

export enum PaymentStatusEnum {
	FULLY_PAID = 'paid',
	NOT_PAID = 'not_paid',
	PARTIAL_PAYMENT = 'partial',
	REFUNDED = 'refunded',
	VOID = 'void',
	REVERT = 'revert',
	PENDING = 'pending',
}

export const paymentStatusColorMapper = {
	[PaymentStatusEnum.FULLY_PAID]: TagColorsMapper.green.primary,
	[PaymentStatusEnum.NOT_PAID]: TagColorsMapper.red.primary,
	[PaymentStatusEnum.PARTIAL_PAYMENT]: TagColorsMapper.yellow.primary,
	[PaymentStatusEnum.REFUNDED]: TagColorsMapper.turquoise.primary,
	[PaymentStatusEnum.VOID]: TagColorsMapper.gray.primary,
};

export const SUBLINE_ICON_TEXT = `{{${Icons.corner_down_right}}}`;

export interface ISingleInvoice {
	payments: {
		paymentProcessorTransactionId: any;
		note: any;
		notes: any;
		paymentProcessorId: string;
		bondFee: number;
		subPaymentMethodType: any;
		ccLast4: any;
		platform: string;
		paymentType: string;
		organizationId: number;
		createdAt: string;
		payingUser: { firstName: string; lastName: string; id: number };
		installmentId: any;
		reasonId: any;
		price: number;
		paymentMethodId: string;
		ccBrand: any;
		currency: string;
		id: number;
		paymentStatus: string;
		updatedAt: string;
		shiftId: any;
		isRefunded: boolean;
		fundLeft: number;
		stripeFee: number;
		creatingUserId: number;
		parentPaymentId: any;
		paymentFees: any[];
		payingUserId: number;
		receiptUrl: any;
		discountId: any;
		totalFeesAmount: number;
		status: string;
	}[];
	discountAmount: number;
	taxes: {
		organizationId: number;
		price: number;
		isTaxInclusive: boolean;
		invoiceId: number;
		currency: string;
		taxPrecent: number;
		paidAmount: number;
	}[];
	isScheduled: boolean;
	importedId: any;
	subtotalBalance: number;
	platform: string;
	paymentType: string;
	lineItems: {
		discountOn?: any;
		type?: string;
		productSubType?: string;
		organizationId?: number;
		discountPercentage?: any;
		createdAt?: string;
		productUserId?: number;
		isPartiallyVoided?: boolean;
		parentLineItemId?: any;
		price?: number;
		currency?: string;
		id?: number;
		taxPrecent?: any;
		paymentStatus?: string;
		productType?: string;
		updatedAt?: string;
		unitPrice?: number;
		quantity?: number;
		productId?: number;
		isRefunded?: boolean;
		isVoided?: boolean;
		userId?: number;
		entitlementGroupId?: any;
		isEdit?: boolean;
		invoiceId?: number;
		wasReverted?: boolean;
		discountId?: any;
		paidAmount?: number;
		isPartiallyRefund?: boolean;
		ordinal?: number;
		product: any;
	}[];
	createdAt: string;
	discounts: {
		discountOn: string;
		unitPrice: any;
		reason: string;
		quantity: any;
		lineItemsIds: any;
		discount: {
			organizationId: number;
			name: string;
			fixValue: any;
			percentageValue: any;
			id: number;
			type: string;
		};
		discountAmount: number;
		organizationId: number;
		discountPercentage: number;
		reasonId: number;
		paymentId: number;
		price: number;
		invoiceId: number;
		currency: string;
		id: any;
		discountId: number;
	}[];
	discountSubtotal: number;
	price: number;
	paymentMethodId: string;
	isPublic: boolean;
	currency: string;
	id: number;
	paymentsFees: {
		organizationId: number;
		feeAmount: number;
		parentPaymentFeeId: any;
		children: any[];
		paymentId: number;
		fee: {
			isBondFee: boolean;
			max: any;
			glCode: any;
			fixValue: any;
			subPaymentMethodType: string;
			platform: string;
			organizationId: number;
			createdAt: string;
			deletedAt: any;
			min: number;
			name: string;
			percentageValue: number;
			id: number;
			paymentMethodType: string;
			updatedAt: string;
			status: string;
		};
		totalFeeAmount: number;
		invoiceId: number;
		id: number;
		feeId: number;
	}[];
	sentForClientDate: any;
	paymentStatus: string;
	updatedAt: string;
	shiftId: any;
	isRefunded: boolean;
	isVoided: boolean;
	hasFailedPayments: boolean;
	invoiceNotes: any[];
	subtotal: number;
	creatingUserId: number;
	invoiceId: string;
	payingUserId: number;
	paidAmount: number;
	totalFeesAmount: number;
	status: string;
	customer: any;
}

export interface ISinglePayment {
	id: number;
	createdAt: string;
	updatedAt: string;
	organizationId: number;
	price: number;
	paymentProcessorId: string;
	paymentStatus: string;
	bondFee: number;
	stripeFee: number;
	currency: string;
	payingUserId: number;
	installmentId: any;
	paymentMethodId: string;
	paymentType: string;
	subPaymentMethodType: string;
	status: string;
	fundLeft: number;
	notes: any;
	isRefunded: boolean;
	receiptUrl: any;
	paymentProcessorTransactionId: string;
	creatingUserId: number;
	shiftId: any;
	platform: string;
	ccLast4: string;
	ccBrand: string;
	parentPaymentId: any;
	reasonId: any;
	note: any;
	discountId: any;
	payingUser: {
		lastName: string;
		lastLogin: string;
		gender: number;
		lastInteractionDay: string;
		profilePictureId: any;
		about: any;
		allowMultiSignHack: any;
		passwordResetToken: any;
		passwordResetExpires: any;
		notificationSettings: {
			feed: { active: boolean };
			teams: { active: boolean };
			leagues: { active: boolean };
			venues: { active: boolean };
			users: { active: boolean };
			events: { active: boolean };
		};
		addressId: number;
		createdAt: string;
		password: string;
		merchantId: any;
		id: number;
		createAsId: any;
		email: string;
		updatedAt: string;
		height: any;
		createAsType: any;
		loginToken: string;
		weight: any;
		birthDate: string;
		firstName: string;
		deletedAt: any;
		phoneNumber: string;
		pushNotifications: boolean;
		currentAddressId: any;
		motto: any;
		privateProfile: any;
		status: string;
	};
	lineItemHistory: {
		createdAt: string;
		lineItemId: number;
		paymentId: number;
		invoiceId: number;
		currency: string;
		id: number;
		paidAmount: number;
		updatedAt: string;
	}[];
	paymentFees: {
		organizationId: number;
		feeAmount: number;
		parentPaymentFeeId: any;
		paymentId: number;
		fee: {
			isBondFee: boolean;
			max: any;
			glCode: any;
			fixValue: any;
			subPaymentMethodType: string;
			platform: string;
			organizationId: number;
			min: number;
			name: string;
			percentageValue: number;
			id: number;
			paymentMethodType: string;
			status: string;
		};
		totalFeeAmount: number;
		invoiceId: number;
		id: number;
		feeId: number;
	}[];
	paymentNotes: any[];
	invoices: ISingleInvoice[];
	totalFeesAmount: number;
	customer: any;
}
