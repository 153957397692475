/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { useContext } from 'react';
import { FormContext } from '../../form';
import { FormColorPicker } from '../../FormControls/FormColorPicker';
import { useOrganization } from '@app/react/hooks/useOrganization';

const selectCss = css`
	margin-top: 20px;
`;

interface Props {
	presetName?: string;
	placeholder?: string;
	label: string;
	required?: boolean;
	formProp?: any;
	isMarginTop?: boolean;
}

export const ColorPickerBlock = ({ presetName, required, label, formProp, isMarginTop = false }: Props) => {
	const { form } = useContext(FormContext);
	const { colorCodes } = useOrganization();
	return (
		<div css={isMarginTop && selectCss}>
			<FormColorPicker
				placeholder=""
				name={presetName ? `${presetName}.colorCodeId` : 'colorCodeId'}
				onClear={formProp ? formProp.mutators.clear : form.mutators.clear}
				onSelecting={formProp ? formProp.mutators.onSelect : form.mutators.onSelect}
				required={required}
				label={label}
				colorsCodes={colorCodes}
			/>
		</div>
	);
};
