import React from "react";

export const Program_Classes = (props: any) => (
  <svg
    data-aid="svgProgram_Classes"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.0013 17.3738H30.0262L6.9998 53.2356L40.4592 53.1696C47.6353 53.1696 52.8586 46.4607 52.3035 39.6661C51.7751 33.1685 46.2175 28.1831 39.5631 28.652C34.0054 29.0416 28.9293 33.7496 29.3641 39.4878C29.752 44.5326 34.1994 48.4681 39.2956 48.2172C43.5557 48.0059 46.5118 45.2193 46.5185 40.9933C46.5185 37.6653 44.4653 34.7533 40.9542 34.4496C38.2188 34.2118 35.3698 36.0938 35.4768 39.0058C35.5637 41.4225 37.6102 42.9148 40.0178 42.8224L40.0847 44.6845C36.6137 44.8099 33.7179 42.512 33.5841 39.0652C33.4303 35.024 37.2357 32.2507 41.1147 32.5809C45.6089 32.9704 48.4044 36.6946 48.4044 40.9867C48.4044 46.2098 44.6525 49.8151 39.3825 50.0793C33.2698 50.383 27.9395 45.6684 27.4781 39.6264C27.2841 37.1437 27.9663 34.6014 29.5647 32.3299L39.0013 17.3738ZM34.4402 28.1303L42.8669 14.7787C43.6962 13.5109 44.6191 12.5732 45.9634 11.8271C47.1873 11.147 48.5984 10.7574 50.1099 10.7574C52.4439 10.7574 54.5506 11.6884 56.0821 13.2005C58.2891 15.3796 59.0315 18.4171 58.2423 21.3621L53.8083 37.1635C52.1831 30.7518 46.1908 26.3144 39.436 26.7899C37.7105 26.9087 36.0118 27.3577 34.4402 28.1303ZM53.942 43.6478C52.6579 49.9472 47.1338 55.0383 40.4592 55.0383L5.27433 55.1043L3.55554 55.1109L4.47847 53.6714L28.702 15.9409L28.9829 15.5051H29.5045H40.185L41.2618 13.7948C42.3252 12.2167 43.3417 11.1404 45.0338 10.1961C46.5318 9.3641 48.264 8.88867 50.1032 8.88867C52.9589 8.88867 55.5404 10.031 57.413 11.8799C60.048 14.4816 61.078 18.3312 60.0547 21.8639L53.942 43.6478Z"
      fill="currentColor"
    />
  </svg>
);
