import mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';

/**
 * debug mode will be in RC, not in STG or PROD
 */
mixpanel.init(environment.MIXPANEL_TOKEN, { debug: !environment.production });

const actions = {
	identify: (id: string) => {
		mixpanel.identify(id);
	},
	alias: (id: string) => {
		mixpanel.alias(id);
	},
	track: (name: string, props: unknown) => {
		mixpanel.track(name, props);
	},
	register: (props: unknown) => {
		mixpanel.register(props);
	},
	set_people: (user: any) => {
		mixpanel.people.set_once(user);
	},
};

export const Mixpanel = actions;

export enum MixpanelEvents {
	CLEAR_CART = 'Clear Cart',
	START_ADD_CUSTOMER = 'Start Add Customer',
	ADD_CUSTOMER_TO_CART = 'Add Customer To Cart',
	REMOVE_CUSTOMER = 'Remove Customer',
	ADD_PRODUCT = 'Add Product',
	HIDE_CART = 'Hide Cart',
	SHOW_CART = 'Show Cart',
	OPEN_CART_OPTIONS = 'Open Cart Options',
	DONE_WITH_CART = 'Done with Cart',
	START_CHECKOUT = 'Start Checkout',
	PICK_PAYMENT_METHOD = 'Pick Payment Method',
	START_ADD_NEW_CARD = 'Start Add New Card',
	PICK_ONLINE_CC = 'Pick Online CC',
	PICK_TERMINAL = 'Pick Terminal',
	BACK_TO_PAYMENT_METHOD = 'Back to Payment Method',
	CANCEL_CHECKOUT = 'Cancel Checkout',
	PAYMENT_ERROR = 'Payment Error',
	//----------------------------------------------------------------
	PICK_CASH_AMOUNT_RECEIVED = 'Pick Cash Amount Received',
	COMPLETE_CHECKOUT = 'Complete Checkout',
	PURCHASE_COMPLETE = 'Purchase Complete',
	SKIP_RECEIPT = 'Skip Receipt',
	SEND_RECEIPT = 'Send Receipt',
	PRINT_RECEIPT = 'Print Receipt',
}
export enum MixpanelModules {
	SETTINGS = 'Settings',
	CALENDAR = 'Calendar',
	RENTALS = 'Rentals',
	PROGRAMS = 'Programs',
	CUSTOMERS = 'Customers',
	MEMBERSHIPS = 'Memberships',
	COMMERCE = 'Commerce',
	NOTIFICATIONS = 'Notifications',
}

export enum MixpanelShiftType {
	ONLINE = 'Online',
	STATION = 'Station',
}

export const mixpanelNull = 'n/a';
