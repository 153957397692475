/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { ReactNode } from 'react';

const ellipsis = (justify: string) => css`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	justify-content: ${justify ?? 'start'};
	text-align: start;
`;

export const EllipsisContainer = ({ children, justify }: { children: ReactNode; justify?: string }) => {
	return <div css={ellipsis(justify)}>{children}</div>;
};
