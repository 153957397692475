export enum EPublishingStatusColorMapper {
	DRAFT = 'red',
	PUBLISHED = 'green',
}

export enum PublishingStatusEnum {
	DRAFT = 1,
	PUBLISHED = 2,
	CLOSED = 3,
	CANCELLED = 4,
	ARCHIVE = 5,
	UNPUBLISHED = 6,
}

export enum CardsDisplayEnum {
	MAXS_CARDS_TO_DISPLAY = 3,
	CARDS_TO_DISPLAY_IF_MORE_THEN_MAX = 2,
}

export enum EPrintDocumentType {
	INVOICE = 'invoice',
	RECEIPT = 'receipt',
}

export enum EStorageKeys {
	BASE_STORE = 'baseStore',
	BN_TERMINAL = 'bn_terminal',
	CUSTOMER_STORE = 'customerStore',
	EVENT_DATE_OBJECT = 'eventDateObject',
	FACILITYID = 'facilityId',
	FORMATTED_PATH = 'formattedPath',
	MEMBERSHIP_STORE = 'membershipStore',
	TIME_FILTER = 'timeFilter',
	CUSTOMER_DATA = 'customerData',
	SCHEDULE_TIME_FILTER = 'scheduleTimeFilter',
}

export const ICON_BTN_HORIZONTAL = 'icon-btn-horizontal';

export enum BOdocumentsEnum {
	INVOICE = 'invoice',
	PAYMENT = 'payment',
}

//todo: rename more genric
export enum CustomPaymentPlanAllocationEnum {
	FIXED = 'fixed',
	PERCENT = 'percent',
}

export enum NoTableRecordsReasonEnum {
	ERROR = 'error',
	EMPTY = 'emptyArray',
}

export enum ButtonTypeEnum {
	Submit = 'submit',
	Button = 'button',
}
