/** @jsxRuntime classic */
/** @jsx jsx */

import { FC } from 'react';
import { jsx } from '@emotion/react';
import { useMiddlewareContext } from '../../hooks/useMiddlewareContext';
import { ECalendarView, IDraggableProps } from '../../types';
import { oneHourHeight, oneHourWidth } from '../../utils/timeUtils';
import { calculateNewDate } from '../../utils';
import dayjs from 'dayjs';

export enum EAxis {
	ALL,
	VERTICAL,
	HORIZONTAL,
}

export const Draggable: FC<IDraggableProps> = ({ item, delta, Component }) => {
	const { options } = useMiddlewareContext();
	const { view = ECalendarView.VERTICAL } = options || {};

	let startDate = item?.startDate || '';
	let endDate = item?.endDate || '';
	let startTime = item?.startTime || '';
	let endTime = item?.endTime || '';

	if (item) {
		const size = view === ECalendarView.VERTICAL ? oneHourHeight : oneHourWidth;

		const minutesDifference = Math.round(Number(delta) * (60 / size));

		startDate = calculateNewDate(dayjs.utc(item.startTime, 'HH:mm:ss').format(), minutesDifference);

		endDate = calculateNewDate(dayjs.utc(item.endTime, 'HH:mm:ss').format(), minutesDifference);

		endTime = dayjs.utc(endDate).format('HH:mm:ss');
		startTime = dayjs.utc(startDate).format('HH:mm:ss');
	}

	return (
		<div style={{ cursor: 'grabbing' }}>
			{item && <Component event={{ ...item, startDate, endDate, startTime, endTime }} />}
		</div>
	);
};
