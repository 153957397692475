import React from 'react';

export const Hide = (props: any) => (
  <svg
    data-aid="svgHide"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0291 4.26732C17.3545 3.94188 17.3545 3.41424 17.0291 3.0888C16.7037 2.76337 16.176 2.76337 15.8506 3.0888L13.7923 5.14705C12.655 4.53062 11.3122 4.12109 9.77463 4.16921C5.16796 4.28587 2.4888 8.34421 1.77713 9.58504C1.62963 9.84171 1.62963 10.1575 1.77713 10.4142C2.07863 10.94 3.14413 12.6536 4.92477 14.0146L2.88695 16.0524C2.56152 16.3779 2.56152 16.9055 2.88695 17.2309C3.21239 17.5564 3.74003 17.5564 4.06547 17.2309L6.36558 14.9308C7.41499 15.4705 8.63523 15.8325 10.0205 15.8325C10.0888 15.8325 10.1571 15.8317 10.2255 15.83C14.8313 15.7125 17.5113 11.655 18.223 10.4142C18.3696 10.1575 18.3696 9.84171 18.223 9.58504C17.9297 9.07392 16.9048 7.43156 15.2089 6.08754L17.0291 4.26732ZM12.5399 6.3995C11.7345 6.03535 10.8242 5.80945 9.81713 5.83504C6.48546 5.92004 4.33213 8.65837 3.47713 9.99587C3.90387 10.6456 4.80283 11.8649 6.12148 12.8179L7.47714 11.4622C7.22673 11.032 7.08321 10.5322 7.08321 9.99954C7.08321 8.39121 8.39155 7.08287 9.99988 7.08287C10.5326 7.08287 11.0323 7.22639 11.4626 7.4768L12.5399 6.3995ZM12.606 8.69045L14.0138 7.2826C15.257 8.21954 16.1108 9.37711 16.5221 10.0034C15.668 11.3409 13.5138 14.0792 10.183 14.1642C9.24286 14.186 8.38838 13.9915 7.62523 13.6712L8.69078 12.6056C9.08466 12.8043 9.52943 12.9162 9.99988 12.9162C11.6082 12.9162 12.9165 11.6079 12.9165 9.99954C12.9165 9.5291 12.8046 9.08432 12.606 8.69045ZM10.1773 8.7621C10.1193 8.75382 10.0601 8.74954 9.99988 8.74954C9.31071 8.74954 8.74988 9.31037 8.74988 9.99954C8.74988 10.0598 8.75416 10.119 8.76243 10.177L10.1773 8.7621ZM11.249 10.0474L10.0478 11.2486C10.6989 11.224 11.2243 10.6986 11.249 10.0474Z"
      fill="currentColor"
    />
  </svg>
);
