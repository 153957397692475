/* @jsx jsx */

import React, { Fragment, ReactElement } from 'react';
import { jsx, css } from '@emotion/react';
import { fullWidthCss, paddingBottomCss, paddingTopCss } from '../../../../styles/utils';
import { colors_v2 } from '../../../../styles/theme';
import { separatorCss } from '../styles';

type ActivityViewLayoutProps = {
	Header?: ReactElement;
	MainContent: ReactElement;
	Footer?: ReactElement;
};

const containerCss = css`
	border-radius: 4px;
	border: 1px solid ${colors_v2.bg_border_seperator};
	background: ${colors_v2.bg_white};
	overflow-y: scroll;
	box-shadow: 0px 2px 22px 8px rgba(21, 93, 148, 0.07);
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: start;
	width: 100%;
	height: 100%;
	padding-top: 18px;
	padding-left: 20px;
	padding-right: 20px;
`;

const headerCss = css`
	${separatorCss()};
	${fullWidthCss};
	${paddingBottomCss(18)};
`;

const mainContentCss = css`
	${fullWidthCss};
`;

const footerCss = css`
	${separatorCss('top')};
	${fullWidthCss};
	${paddingTopCss(18)};
`;

export const ActivityViewLayout: React.FC<ActivityViewLayoutProps> = ({ Header, MainContent, Footer }) => {
	return (
		<div data-aid="ActivityViewLayout" css={containerCss}>
			{Header ? <div data-aid="ActivityViewLayout-header" css={headerCss}>{Header}</div> : <Fragment />}
			{MainContent ? <div data-aid="ActivityViewLayout-content" css={mainContentCss}>{MainContent}</div> : <Fragment />}
			{Footer ? <div data-aid="ActivityViewLayout-footer" css={footerCss}>{Footer}</div> : <Fragment />}
		</div>
	);
};
