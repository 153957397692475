import React from 'react';

export const Favorite = (props: any) => (
	<svg data-aid="SvgFavorite" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M11.641 3.58971C11.7876 3.29172 12.2124 3.29172 12.359 3.58971L14.6285 8.20522C14.8611 8.67826 15.3118 9.00637 15.8334 9.08245L20.9143 9.82346C21.242 9.87124 21.373 10.2736 21.1363 10.5052L17.4551 14.1067C17.0797 14.474 16.9084 15.0022 16.9969 15.5199L17.8653 20.6015C17.9211 20.9285 17.5777 21.1775 17.2843 21.0227L12.7467 18.6284C12.2794 18.3818 11.7206 18.3818 11.2533 18.6284L6.7157 21.0227C6.42234 21.1775 6.07887 20.9285 6.13475 20.6015L7.00313 15.5199C7.0916 15.0022 6.92035 14.474 6.54491 14.1067L2.86367 10.5052C2.627 10.2736 2.75804 9.87124 3.08567 9.82346L8.16659 9.08245C8.68821 9.00637 9.13888 8.67826 9.37149 8.20522L11.641 3.58971Z"
			stroke="#0D4774"
			stroke-width="1.2"
			stroke-linejoin="round"
		/>
	</svg>
);
