import { css } from '@emotion/react';
import { colors_v2 } from '../../../styles/theme';
import { CSSProperties } from 'react';

export const separatorCss = (where: 'top' | 'bottom' | 'right' | 'left' = 'bottom') => css`
	border-${where}: solid 1px;
	border-color: ${colors_v2.bg_border_seperator};
`;

export const iconCss = (color: string) => css`
	color: ${color};
	height: 24px;
	width: 24px;
`;

export const cardCss = (backgroundColor: string) => css`
	border: 1px solid ${colors_v2.bg_border_primary};
	border-radius: 4px;
	padding: 16px;
	margin: 0;
	background: ${backgroundColor};
`;

export const widthCss = (howMuch: CSSProperties['width']) => css`
	width: ${howMuch};
`;

export const textAlignCss = (alignment: CSSProperties['textAlign']) => css`
	text-align: ${alignment};
`;
