import React from "react";

export const Visa = (props: any) => (
    <svg data-aid="SvgVisa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M10.6666 15.379H9.04651L10.0599 9.11328H11.6799L10.6666 15.379Z" fill="#00579F"/>
        <path
            d="M16.5397 9.26676C16.2201 9.13998 15.7133 9 15.0866 9C13.4866 9 12.36 9.85317 12.3531 11.0729C12.3398 11.9729 13.1597 12.4727 13.773 12.7728C14.3998 13.0794 14.6128 13.2796 14.6128 13.5529C14.6065 13.9726 14.1064 14.1661 13.6399 14.1661C12.9931 14.1661 12.6465 14.0664 12.1198 13.8328L11.9065 13.7327L11.6797 15.1393C12.0597 15.3124 12.7598 15.466 13.4866 15.4727C15.1866 15.4727 16.2933 14.6328 16.3064 13.3329C16.3129 12.6196 15.8799 12.0731 14.9465 11.6265C14.3798 11.3398 14.0328 11.1465 14.0328 10.8531C14.0395 10.5865 14.3263 10.3133 14.966 10.3133C15.4927 10.2999 15.8797 10.4266 16.1728 10.5532L16.3194 10.6198L16.5397 9.26676Z"
            fill="#00579F"/>
        <path
            d="M18.6931 13.1593C18.8266 12.7993 19.34 11.4062 19.34 11.4062C19.3332 11.4196 19.4731 11.0396 19.5531 10.8063L19.6663 11.3462C19.6663 11.3462 19.9731 12.846 20.0398 13.1593C19.7866 13.1593 19.0131 13.1593 18.6931 13.1593ZM20.693 9.11328H19.4398C19.0534 9.11328 18.7598 9.22651 18.593 9.63315L16.1865 15.379H17.8865C17.8865 15.379 18.1663 14.6056 18.2265 14.4391C18.413 14.4391 20.0667 14.4391 20.3066 14.4391C20.3531 14.659 20.4999 15.379 20.4999 15.379H22L20.693 9.11328Z"
            fill="#00579F"/>
        <path
            d="M7.69326 9.11328L6.10662 13.3859L5.93324 12.5194C5.6399 11.5195 4.71993 10.4332 3.6933 9.893L5.1466 15.3724H6.85985L9.40641 9.11328H7.69326Z"
            fill="#00579F"/>
        <path
            d="M4.6333 9.11328H2.02667L2 9.23988C4.03334 9.75984 5.37997 11.0132 5.93325 12.5197L5.3666 9.63998C5.27331 9.23979 4.98661 9.12648 4.6333 9.11328Z"
            fill="#FAA61A"/>
    </svg>

);

