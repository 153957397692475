export const arrayTo2d = <TCell extends any>(rowCount: number, colCount: number, data: TCell[]) => {
	// Convert data to 2d array based on row and column count
	const data2d: TCell[][] = [];
	for (let i = 0; i < rowCount; i++) {
		const row: TCell[] = [];
		for (let j = 0; j < colCount; j++) {
			const dataIndex = i * colCount + j;
			if (dataIndex < data.length) {
				row.push(data[dataIndex]);
			}
		}
		data2d.push(row);
	}
	return data2d;
};
