import React, {
  FC,
  Fragment,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

export interface ISkeletonProps {
  isLoading: boolean;
  minHeight?: number;
  minWidth?: number;
  breakToLines?: boolean;
  children?: ReactNode;
}

export const SkeletonWrapper = ({
  isLoading,
  minHeight = 30,
  minWidth = 100,
  children,
  breakToLines = false,
}: ISkeletonProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [lineHeight, setLineHeight] = useState<number>(0);
  const [linesCount, setLinesCount] = useState<number>(1);

  function getLineHeight(element: HTMLElement): number {
    if (element) {
      const oldHtml = element?.innerHTML;
      element.innerHTML = "A";
      const lineHeight = element.offsetHeight;
      setLineHeight(lineHeight - 5);
      element.innerHTML = oldHtml;
      return lineHeight;
    }
    return 25;
  }

  function countLines(element: HTMLElement): number {
    var divHeight = element?.offsetHeight || 30;
    var lineHeight = getLineHeight(element);
    var lines = divHeight / lineHeight;
    return lines;
  }

  useEffect(() => {
    if (breakToLines && isLoading) {
      const child = containerRef?.current?.children[0] as HTMLElement;
      setLinesCount(countLines(child));
    }
  }, [children]);

  if (!(children as any)?.props?.children) {
    return <></>;
  }
  return (
    <Fragment>
      {isLoading ? (
        breakToLines ? (
          <div style={{ position: "relative" }}>
            <div
              ref={containerRef}
              style={{
                visibility: "hidden",
                minWidth,
              }}
            >
              {children}
            </div>
            <div
              style={{
                position: "absolute",
                top: "0",
                width: "100%",
              }}
            >
              {Array.from({ length: linesCount }, (_, i) => i + 1).map(
                (lineNumber) => {
                  return (
                    <div
                      key={lineNumber}
                      className="skeleton"
                      style={{
                        height: `${lineHeight}px`,
                        minWidth,
                        width: lineNumber === linesCount ? `60%` : "100%",
                        marginBottom: "5px",
                      }}
                    />
                  );
                }
              )}
            </div>
          </div>
        ) : (
          <div className="skeleton">
            <div
              ref={containerRef}
              style={{
                visibility: "hidden",
                minHeight,
                minWidth,
                display: "flex",
                color: "transparent",
              }}
            >
              {children}
            </div>
          </div>
        )
      ) : (
        children
      )}
    </Fragment>
  );
};
