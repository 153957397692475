/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { Drawer as VaulDrawer } from 'vaul';
import { useWindowSize } from '../../hooks/useWindowSize';
import { jsx, useTheme, css } from '@emotion/react';
import { IColors, Icons } from '../../types';
import { BnIcon } from '../icons_v2';

type Direction = 'top' | 'bottom' | 'left' | 'right';

const headerButtonCss = (colors: IColors, rightMargin: string) => css`
	position: absolute;
	right: 10px;
	top: 10px;
	width: 20px;
	height: 20px;
	z-index: 2;
	border-radius: 200px;
	display: flex;
	padding: 0.25rem;
	outline: none;
	border: 0;
	background: transparent;
	cursor: pointer;
	svg {
		color: ${colors.tx_text_secondary};
		height: 20px;
		width: 20px;
	}

	&:focus {
		outline: none;
	}
	&:hover {
		outline: none;
		svg {
			color: ${colors.tx_text_primary};
		}
	}
`;

const drawerStyles = (direction: Direction, width?: string, height?: string) => css`
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	position: fixed;
	z-index: 11112;
	overflow-y: hidden;
	box-sizing: border-box;

	${direction === 'top' &&
	css`
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		top: 0;
		left: 0;
		right: 0;
		${height ? `height: ${height};` : 'height: 70vh;'}
	`}

	${direction === 'bottom' &&
	css`
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		bottom: 0;
		left: 0;
		right: 0;
		${height ? `height: ${height};` : 'height: 70vh;'}
	`}

    ${direction === 'left' &&
	css`
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		top: 0;
		bottom: 0;
		left: 0;
		${width ? `width: ${width}rem;` : 'width: 70vw;'}
	`}

    ${direction === 'right' &&
	css`
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		top: 0;
		bottom: 0;
		right: 0;
		${width ? `width: ${width}rem;` : 'width: 70vw;'}
	`}
`;

const drawerBodyStyles = css`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: start;
	overflow: hidden;
	box-sizing: border-box;
`;

const drawerOverlayStyle = css`
	position: fixed;
	inset: 0;
	background: rgba(0, 0, 0, 0.4);
`;

export interface IDrawerProps {
	direction: Direction;
	children: React.ReactNode;
	onClose: () => void;
	open: boolean;
	isDismissable?: boolean;
	showCloseButton?: boolean;
	height?: string;
	width?: string;
}

export const Drawer = ({
	open,
	onClose,
	direction,
	children,
	isDismissable = true,
	showCloseButton = true,
	height,
	width,
}: IDrawerProps) => {
	const { isMobile } = useWindowSize();
	const colors = useTheme();

	return (
		<VaulDrawer.Root modal fixed dismissible={isDismissable} open={open} direction={direction} onClose={onClose}>
			<VaulDrawer.Portal>
				<VaulDrawer.Content css={drawerStyles(direction, width, height)} data-aid="content-Drawer">
					{showCloseButton && isDismissable && !isMobile && (
						<button data-aid="button-Drawer-close" onClick={onClose} css={headerButtonCss(colors, '5px')}>
							<BnIcon icon={Icons.close} />
						</button>
					)}
					<div css={drawerBodyStyles} data-aid="body-Drawer">
						{children}
					</div>
				</VaulDrawer.Content>
				<VaulDrawer.Overlay
					onClick={isDismissable ? onClose : undefined}
					css={drawerOverlayStyle}
					data-aid="overlay-Drawer"
				/>
			</VaulDrawer.Portal>
		</VaulDrawer.Root>
	);
};
