import React from 'react';

export const Indications_paused = (props: any) => (
	<svg data-aid="SvgIndications_paused" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
			fill="currentColor"
		/>
		<path
			d="M5 4.75C5 4.33579 5.33579 4 5.75 4C6.16421 4 6.5 4.33579 6.5 4.75V11.25C6.5 11.6642 6.16421 12 5.75 12C5.33579 12 5 11.6642 5 11.25V4.75Z"
			fill="white"
		/>
		<path
			d="M9.5 4.75C9.5 4.33579 9.83579 4 10.25 4C10.6642 4 11 4.33579 11 4.75V11.25C11 11.6642 10.6642 12 10.25 12C9.83579 12 9.5 11.6642 9.5 11.25V4.75Z"
			fill="white"
		/>
	</svg>
);
