import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PermissionsService {
	permissions: BehaviorSubject<string[]> = new BehaviorSubject([]);

	constructor(private http: HttpClient) {}

  async getMyPermissions(organizationId: number) {
    const currentPermissions = this.permissions.value;
    this.http
      .get<any>(`${environment.CS_URLS.API_ROOT_V4}/roles/organization/${organizationId}/user/my`).pipe(
      map(res => res)).subscribe((response: { name: string, permissions: { name: string }[] } [] ) => {
      currentPermissions.length = 0; // clear the permissions to reset
      response.forEach((role) => {
        role.permissions.forEach((permission: { name: string }) => {
          if (!currentPermissions.includes(permission.name)) {
            currentPermissions.push(permission.name);
          }
        });
      });
      this.permissions.next(currentPermissions);
    });
  }

	isAllowed(permission = '', withoutPermission = '') {
		const currentPermissions = this.permissions.value;
		const array = permission.split(',');
		let response = true;
		array.forEach(requiredPermission => {
			if (!currentPermissions.includes(requiredPermission) && requiredPermission) {
				response = false;
			}
		});

		const secondArray = withoutPermission.split(',');
		secondArray.forEach(requiredPermission => {
			if (currentPermissions.includes(requiredPermission)) {
				response = false;
			}
		});
		return response;
	}
}
