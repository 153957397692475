/** @jsxRuntime classic */
/** @jsx jsx */
import React, { FC, useState, useRef, useEffect, ChangeEvent, FocusEvent, MutableRefObject, Fragment } from 'react';

import cx from 'classnames';
import { jsx, Theme, useTheme } from '@emotion/react';
import { StyledInputContainer, DeleteButtonStyle, InputContainer, RightButton, ArrowsButton } from './style';
import { getIcon, BnRemove, BnArrowUp, BnArrowDown } from '../../icons';
import { BnIcon } from '../icons_v2';
import { capitalize } from '../../functions';
import { Tooltip } from '../Tooltip';
import { ErrorMessage } from '../ErrorMessage';
import { InputProps } from '../../types';
import Cleave from 'cleave.js/react';
import NumberFormat from 'react-number-format';
import { Typography } from '../../atoms';

const useClickOutside = (
	ref: React.MutableRefObject<HTMLDivElement>,
	callback: (val: number) => void,
	innerValue: string
) => {
	const handleClick = (e: Event) => {
		if (ref) {
			if (ref.current && !ref.current.contains(e.target as Node)) {
				callback(Number(innerValue));
			}
		}
	};
	React.useEffect(() => {
		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	});
};

export const Input: FC<InputProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
	insideLeftLabel = '',
	leftIcon = '',
	rightIcon = '',
	children,
	sizer = 'S',
	label = '',
	info = '',
	helper = '',
	helperContent = '',
	rightButton = '',
	format = 'default',
	onRightButtonClick = () => {},
	isVisited = false,
	withArrows = false,
	error = undefined,
	hideError = false,
	noLabel = false,
	disableTrailing = false,
	decimalScale = 0,
	allowNegative = true,
	step = 1,
	max,
	isStaticPlaceholder = false,

	setChange = () => {},
	clear = () => {},
	clickOutside = val => {},
	displayErrorBeforeVisited = false,
	grayedOut,
	isMobile = false,
	...rest
}) => {
	const [isFocus, setFocus] = useState<boolean>(false);
	// const [innerValue, setInnerValue] = useState<string>('');
	const [isHover, setHover] = useState<boolean>(false);
	const [visited, setVisited] = useState<boolean>(false);

	const inputRef = useRef(null);
	const inputContainerRef = useRef<HTMLDivElement>(null);
	const colors = useTheme();
	// const { colors: ThemeC } = useColors();
	const newClassName = rest.className;
	delete rest.className;

	const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
		setFocus(true);
		if (rest.onFocus) {
			rest.onFocus(e);
		}
	};

	const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
		setFocus(false);
		setVisited(true);
		if (rest.onBlur) {
			rest.onBlur(e);
		}
	};

	useClickOutside(inputContainerRef as MutableRefObject<HTMLDivElement>, clickOutside, String(rest.value));

	useEffect(() => {
		setVisited(isVisited);
	}, [isVisited]);

	const disabled = rest.disabled;

	const handleChangeUsingArrows = (v: number, newValue: number) => {
		let e = {
			target: {
				value: v ? newValue : 1,
			},
		};

		// setInnerValue(String(v + 1));
		if (rest.onChange) {
			rest.onChange(e as unknown as ChangeEvent<HTMLInputElement>);
		}
	};

	enum ECalculationType {
		ADD = 'add',
		SUBSTRACT = 'substract',
	}

	const handleCalculationOfDiff = (value: number, max: number, type: ECalculationType) => {
		if (type === ECalculationType.ADD) {
			if (max - value > 1) {
				return 1;
			} else {
				return max - value;
			}
		} else {
			if (max === value) {
				return value % 1 || 1;
			} else {
				return 1;
			}
		}
	};

	const handleArrowClick = (e: any, type: ECalculationType) => {
		const isAdd = type === ECalculationType.ADD;
		e.preventDefault();
		e.stopPropagation();
		let v = (rest.value as number) || 0;
		if (max) {
			if (isAdd ? v < max : v <= max) {
				handleChangeUsingArrows(
					v,
					+v + (isAdd ? handleCalculationOfDiff(v, max, type) : -1 * handleCalculationOfDiff(v, max, type))
				);
			}
		} else {
			handleChangeUsingArrows(v, +v + (isAdd ? 1 : -1));
		}
	};

	return (
		<InputContainer
			className={cx(
				{
					M: sizer === 'M',
					S: sizer === 'S',
					disabled: grayedOut && disabled,
				},
				newClassName
			)}
			theme={{ ...colors }}
			ref={inputContainerRef}
			style={{ ...rest.style }}
			{...rest}
		>
			{(label || noLabel) && (
				<div className="label" data-aid="index-Input">
					<div>
						{capitalize(label)}
						{rest.required && !noLabel && ' *'}
					</div>
					{info && (
						<Tooltip content={info} isMobile={isMobile}>
							{getIcon('info', 'l')}
						</Tooltip>
					)}
				</div>
			)}
			<StyledInputContainer
				disableTrailingClearButtonPadding={disableTrailing || !isHover}
				theme={{ ...colors }}
				className={cx({
					focus: isFocus,
					error: error && (visited || displayErrorBeforeVisited),
					hover: isHover,
					M: sizer === 'M',
					S: sizer === 'S',
					disabled,
				})}
				grayedOut={true}
				onClick={() => {
					if (inputRef.current) {
						//@ts-ignore
						inputRef.current.focus();
					}
				}}
				onMouseEnter={() => {
					setHover(true);
				}}
				onMouseLeave={() => {
					setHover(false);
				}}
			>
				<div style={{ position: 'relative', width: '100%', display: 'flex' }}>
					{insideLeftLabel && (
						<Typography color="secondary" type="body2" className="insideLeftLabel">
							{insideLeftLabel}
						</Typography>
					)}
					{leftIcon && (
						<BnIcon icon={leftIcon} style={{ paddingRight: '4px', height: '20px', width: '20px', flex: '1 0 20px' }} />
					)}
					{format === 'time' ? (
						<Cleave
							options={{
								time: true,
								timePattern: ['h', 'm'],
								timeFormat: '12',
							}}
							{...rest}
							disabled={disabled}
							onFocus={handleFocus}
							onChange={e => {
								if (rest.onChange) {
									rest.onChange(e);
								}
							}}
							onBlur={handleBlur}
						/>
					) : (
						<Fragment>
							{rest.type !== 'number' ? (
								<input
									ref={inputRef}
									disabled={disabled}
									{...rest}
									value={rest.value}
									onChange={e => {
										if (rest.onChange) {
											rest.onChange(e);
										}
									}}
									onFocus={handleFocus}
									onBlur={handleBlur}
								/>
							) : (
								<Fragment>
									{isStaticPlaceholder && (
										<span className="static">
											<span style={{ opacity: 0 }}>{rest.value || rest?.placeholder}</span>
											<span>{`/ ${max}`}</span>
										</span>
									)}
									<NumberFormat
										step={step}
										decimalScale={decimalScale}
										getInputRef={inputRef}
										value={rest.value === '' ? '' : Number(rest.value)}
										type="text"
										placeholder={rest?.placeholder}
										displayType="input"
										onChange={(e: any) => {
											if (rest.onChange) {
												rest.onChange(e);
											}
										}}
										isAllowed={({ floatValue }) => {
											if (max && floatValue) {
												return floatValue <= max;
											} else {
												return true;
											}
										}}
										allowNegative={allowNegative}
										onFocus={handleFocus}
										onBlur={handleBlur}
										disabled={disabled}
									/>
								</Fragment>
							)}
						</Fragment>
					)}
					{!disableTrailing && isHover && !disabled && rest.value && (
						<DeleteButton
							onClick={() => {
								if (inputRef && inputRef.current) {
									// @ts-ignore: Object is possibly 'null'.
									inputRef.current.focus();
								}
								if (clear) {
									clear();
								}
							}}
							theme={{ ...colors }}
						/>
					)}
					{rightIcon && <BnIcon icon={rightIcon} style={{ paddingLeft: '4px' }} />}
				</div>
				{withArrows && rest.type === 'number' && (
					<ArrowsButton theme={{ ...colors }}>
						<button
							data-aid="button-CompInput-add"
							disabled={disabled}
							onClick={e => {
								handleArrowClick(e, ECalculationType.ADD);
							}}
						>
							<BnArrowUp />
						</button>
						<button
							data-aid="button-CompInput-subtract"
							disabled={disabled}
							onClick={e => {
								handleArrowClick(e, ECalculationType.SUBSTRACT);
							}}
						>
							<BnArrowDown />
						</button>
					</ArrowsButton>
				)}
				{rightButton && (
					<RightButton theme={{ ...colors }}>
						<button onClick={onRightButtonClick}>{rightButton}</button>
					</RightButton>
				)}
			</StyledInputContainer>

			{helper && (
				<div className="helper">
					{helperContent && <Tooltip content={helperContent}>{getIcon('info', 'r')}</Tooltip>}
					<div>{capitalize(helper)}</div>
				</div>
			)}
			{!hideError && (
				<div className="error" style={{ minHeight: '15px' }}>
					{(displayErrorBeforeVisited || visited) && <ErrorMessage error={error || ''} />}
				</div>
			)}
		</InputContainer>
	);
};

interface DeleteButtonProps {
	theme: Theme;
	onClick: () => void;
}
const DeleteButton = ({ theme, onClick }: DeleteButtonProps) => {
	return (
		<DeleteButtonStyle type="button" tabIndex={-1} theme={theme} onClick={onClick}>
			<BnRemove />
		</DeleteButtonStyle>
	);
};
