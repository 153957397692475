/** @jsxRuntime classic*/
/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import { IColors, Icons } from '../../types/theme';
import { BnIcon } from '../../components/icons_v2';
import { useTheme } from '@emotion/react';
import { AvatarVariant } from './types';
import { css, jsx } from '@emotion/react';
import Picture from '../../components/Picture';

const imageCss = (color?: string, isStatusVariant?: boolean) => css`
	height: ${isStatusVariant ? 100 : 24}px;
	width: ${isStatusVariant ? 100 : 24}px;
	border-radius: 100px;
	box-sizing: border-box;
	border: ${isStatusVariant && color && `4px solid ${color}`};
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ImgPlaceholder = styled.div<any>(
	({ color, theme: colors, isStatusVariant }: { color?: string; theme: IColors; isStatusVariant?: boolean }) => `
    height: ${isStatusVariant ? 100 : 24}px;
    width: ${isStatusVariant ? 100 : 24}px;
    border-radius: 100px;
    box-sizing: border-box;
    border:${isStatusVariant ? 4 : 1}px solid ${(isStatusVariant && color) || colors.tx_border_primary};
    background:${colors.bg_white};
    display:flex;
    align-items:center;
    justify-content: center;
   
    svg{
        height: ${isStatusVariant ? 50 : 14}px;
        width: ${isStatusVariant ? 50 : 14}px;
        color:${isStatusVariant ? colors.bg_text_teriaty : colors.tx_border_primary};
       
    }
    `
);

const Check = styled.div<any>(
	({ theme: colors }: { theme: IColors }) => `
  height: 14px;
  width: 14px;
  background: ${colors.b_green};
  border: 1px solid ${colors.bg_white};
  box-shadow: 0px 2px 22px 8px rgba(21, 93, 148, 0.0749563);
  border-radius: 100px;
  position:absolute;
  bottom: 0;
    display: flex;
    align-items: center;
    right: 0;
    box-sizing: border-box;
    transform: translate(50%, 15%);
    svg{
        color:${colors.t_green} !important;
        height: 14px;
        width: 14px;
        
    }
  `
);

const Icon = styled.div<any>(
	({ color }: { color?: string }) => `
  height: 32px;
  width: 32px;
  border-radius: 100px;
  position:absolute;
  bottom: 0;
    display: flex;
    align-items: center;
    right: 18px;
    top:61px;
    box-sizing: border-box;
    transform: translate(50%, 15%); 
    background:white;
    svg{
        color:${color} !important;
        height: 32px;
        width: 32px; 
    }
  `
);

export const Avatar = ({
	img,
	imgStyle,
	checked,
	variant,
	icon,
	color,
}: {
	img?: string;
	imgStyle?: any;
	checked?: boolean;
	variant?: AvatarVariant;
	icon?: Icons;
	color?: string;
}) => {
	const theme = useTheme();
	const isStatus = variant === AvatarVariant.STATUS;
	const isStatusVariant = Boolean(isStatus && icon && color);
	return (
		<div
			style={{
				width: 'min-content',
				position: 'relative',
			}}
			data-aid="index-Avatar"
		>
			{img ? (
				<Picture path={img} alt="image profile" css={imageCss(color, isStatusVariant)} />
			) : (
				<ImgPlaceholder theme={theme} color={color} isStatusVariant={isStatusVariant}>
					<BnIcon icon={Icons.profile_filled} />
				</ImgPlaceholder>
			)}
			{!isStatus && checked && (
				<Check theme={theme}>
					<BnIcon theme={theme} icon={Icons.check} />
				</Check>
			)}
			{isStatusVariant && (
				<Icon color={color}>
					<BnIcon icon={icon} />
				</Icon>
			)}
		</div>
	);
};
