import React from 'react';
import { Typography } from '../../atoms';
import { ETypography, ETypographyColor } from '../../types';
import { CenteredContainer } from '../../molecules/layouts/CenteredContainer';

export const NoOptionsComponent = ({ noOptionsMessage }: { noOptionsMessage: string }) => {
	return (
		<CenteredContainer>
			<div style={{ padding: '8px' }}>
				<Typography color={ETypographyColor.secondary} type={ETypography.captionAccented}>
					{noOptionsMessage}
				</Typography>
			</div>
		</CenteredContainer>
	);
};
