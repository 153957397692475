import { IParticipant } from 'app/react/types/sessionParticipant';
import { atom } from 'recoil';
import { MoveParticipantsRequestParams } from '../lib/api/programsApi';
import { IEvent } from '../types/events';
import { DateRange } from '../components/shared/DeleteEventModal/types';

const scheduleDateRangeState = atom<DateRange>({
	key: 'scheduleDateRangeState',
	default: {
		startDate: '',
		endDate: '',
	},
});

// should add session interface
const sessionState = atom<any>({
	key: 'sessionState',
	default: {},
});

const segments = atom<any>({
	key: 'segments',
	default: [],
});

const products = atom<any[]>({
	key: 'products',
	default: [],
});

const events = atom<IEvent[]>({
	key: 'events',
	default: [],
});

const participants = atom<IParticipant[]>({
	key: 'participants',
	default: [],
});

const participantsLoading = atom<boolean>({
	key: 'participantsLoading',
	default: false,
});

const isSessionHasSegments = atom<boolean>({
	key: 'isSessionHasSegments',
	default: false,
});

const isFetchingSegments = atom<boolean>({
	key: 'isFetchingSegments',
	default: true,
});

const isFetchingProducts = atom<boolean>({
	key: 'isFetchingProducts',
	default: true,
});

const isFetchingEvents = atom<boolean>({
	key: 'isFetchingEvents',
	default: true,
});

const isMovingParticipants = atom<boolean>({
	key: 'isMovingParticipants',
	default: true,
});

const moveParticipantRequest = atom<MoveParticipantsRequestParams>({
	key: 'moveParticipantRequest',
	default: {
		invoiceId: 0,
		userId: 0,
		productsToAdd: [],
		resourcesToRemove: [],
	},
});
const isUpdatingEvents = atom<boolean>({
	key: 'isUpdatingEvents',
	default: true,
});

const isErrorUpdatingEvent = atom<boolean>({
	key: 'isErrorUpdatingEvent',
	default: false,
});

const spaces = atom<any[]>({
	key: 'spaces',
	default: [],
});

const isFetchingSpaces = atom<boolean>({
	key: 'isFetchingSpaces',
	default: true,
});

const isEditingSession = atom<boolean>({
	key: 'isEditingSession',
	default: true,
});

const editSessionError = atom<string>({
	key: 'editSessionError',
	default: '',
});

const moveParticipantsError = atom<string>({
	key: 'isMoveParticipantsError',
	default: '',
});

const isFetchingData = atom<boolean>({
	key: 'isFetchingData',
	default: false,
});

const isAddingEvents = atom<boolean>({
	key: 'isAddingEvents',
	default: true,
});

const addEventsError = atom<boolean>({
	key: 'addEventsError',
	default: false,
});

const isDeletingEvents = atom<boolean>({
	key: 'isDeletingEvents',
	default: true,
});

const deletingEventsError = atom<boolean>({
	key: 'deletingEventsError',
	default: false,
});

const totalParticipantsAmount = atom<number>({
	key: 'totalParticipantsAmount',
	default: 0,
});

const totalParticipantsWithoutPunchPass = atom<number>({
	key: 'totalParticipantsWithoutPunchPass',
	default: null,
});

const isFetchingParticipants = atom<boolean>({
	key: 'isFetchingParticipants',
	default: false,
});

const participantsPage = atom<number>({
	key: 'participantsPage',
	default: 1,
});

export const sessionStore = {
	sessionState,
	products,
	segments,
	participants,
	isSessionHasSegments,
	isFetchingProducts,
	isFetchingSegments,
	participantsLoading,
	isMovingParticipants,
	moveParticipantRequest,
	spaces,
	isFetchingSpaces,
	isEditingSession,
	isFetchingData,
	isUpdatingEvents,
	isErrorUpdatingEvent,
	editSessionError,
	moveParticipantsError,
	isAddingEvents,
	addEventsError,
	deletingEventsError,
	isDeletingEvents,
	isFetchingParticipants,
	totalParticipantsAmount,
	totalParticipantsWithoutPunchPass,
	participantsPage,
	events,
	isFetchingEvents,
	scheduleDateRangeState,
};
