/** @jsx jsx */
import React, { useCallback } from 'react';
import { jsx, css } from '@emotion/react';
import { BnIcon, Button, Icons, TSize, useColors } from '@bondsports/utils';
import { PaginationResultDto, ResourceNameTypeEnum, NoteDto } from '@bondsports/types';
import { isUndefined } from 'lodash';

export interface INoteButtonProps {
	onClick: (hasNotes: boolean) => void;
	size?: TSize;
	padding?: number;
	count?: number;
}

export const NoteButton: React.FC<INoteButtonProps> = ({ onClick, size = 'M', padding, count }) => {
	const { colors } = useColors();

	const onClicked = useCallback(() => onClick(!!count), [count]);

	return (
		<div>
			<Button
				data-aid="button-NoteButton"
				theme={'basic'}
				sizer={size}
				onClick={onClicked}
				style={{ padding: padding ?? 0 }}
				disabled={isUndefined(count)}
			>
				<BnIcon icon={count ? Icons.note_filled : Icons.note} color={colors.tx_text_primary} />
			</Button>
		</div>
	);
};
