/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor } from '../../../types';
import { SkeletonWrapper } from '../../../components/skeletonWrapper';
import { EInvoiceVariant } from '../../../organisms/invoiceTemplate/types';
import { fullWidthCss, printFont } from '../../../styles/utils';

const summaryLineCss = css`
	display: flex;
	justify-content: space-between;
	${fullWidthCss};
	@media print and (max-width: 10cm) {
		${printFont}
	}
`;

const FONT_CONSTANTS = {
	[EInvoiceVariant.PRINT]: ETypography.body2,
	[EInvoiceVariant.CONSUMER]: ETypography.body2,
	[EInvoiceVariant.BACKOFFICE]: ETypography.body2,
	[EInvoiceVariant.PAYMENT]: ETypography.body2,
	[EInvoiceVariant.PAYMENT_PRINT]: ETypography.body2,
};

const FONT_MAIN_CONSTANTS = {
	[EInvoiceVariant.PRINT]: ETypography.body1Accented,
	[EInvoiceVariant.CONSUMER]: ETypography.body1Accented,
	[EInvoiceVariant.BACKOFFICE]: ETypography.body1Accented,
	[EInvoiceVariant.PAYMENT]: ETypography.body1Accented,
	[EInvoiceVariant.PAYMENT_PRINT]: ETypography.body1Accented,
};

const FONT_SUBLINE_CONSTANTS = {
	[EInvoiceVariant.PRINT]: ETypography.caption,
	[EInvoiceVariant.CONSUMER]: ETypography.caption,
	[EInvoiceVariant.BACKOFFICE]: ETypography.caption,
	[EInvoiceVariant.PAYMENT]: ETypography.caption,
	[EInvoiceVariant.PAYMENT_PRINT]: ETypography.caption,
};

export const SummaryLine = ({
	label,
	value,
	isMain = false,
	isLoading = false,
	variant = EInvoiceVariant.CONSUMER,
	isSubline = false,
}: {
	label?: string;
	value?: string | React.ReactNode;
	isMain?: boolean;
	isLoading?: boolean;
	variant?: EInvoiceVariant;
	isSubline?: boolean;
}) => {
	const font = isSubline
		? FONT_SUBLINE_CONSTANTS[variant]
		: isMain
		? FONT_MAIN_CONSTANTS[variant]
		: FONT_CONSTANTS[variant];
	const color = isSubline ? ETypographyColor.secondary : ETypographyColor.primary;
	return (
		<div css={summaryLineCss} data-aid="summaryLine-summary">
			<Typography type={font} color={color}>
				{label}
			</Typography>
			<SkeletonWrapper isLoading={isLoading} minHeight={10}>
				{typeof value === 'string' ? (
					<Typography type={font} color={color}>
						{value}
					</Typography>
				) : (
					<Fragment>{value}</Fragment>
				)}
			</SkeletonWrapper>
		</div>
	);
};
