import React from 'react';

export const Flag = (props: any) => (
  <svg
    data-aid="svgFlag"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.96954 5C3.96954 4.43046 4.43125 3.96875 5.00079 3.96875H19.0008C19.4179 3.96875 19.7939 4.22001 19.9535 4.60536C20.1132 4.99071 20.0249 5.43427 19.73 5.7292L15.9592 9.5L19.73 13.2708C20.0249 13.5657 20.1132 14.0093 19.9535 14.3946C19.7939 14.78 19.4179 15.0313 19.0008 15.0313H6.03204V21C6.03204 21.5695 5.57034 22.0313 5.00079 22.0313C4.43125 22.0313 3.96954 21.5695 3.96954 21V5ZM6.03204 12.9688H16.5111L13.7716 10.2292C13.3689 9.82648 13.3689 9.17353 13.7716 8.7708L16.5111 6.03125H6.03204V12.9688Z"
      fill="currentColor"
    />
  </svg>
);
