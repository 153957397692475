import dayjs from 'dayjs';

// Constants for date formats
export const YEAR_MONTH_DAY_FORMAT = 'YYYY-MM-DD';
export const YEAR_MONTH_FORMAT = 'YYYY-MM';
export const FULL_MONTH_NAME_FORMAT = 'MMMM';
export const YEAR_FORMAT = 'YYYY';
export const DAY_OF_MONTH_FORMAT = 'D';

// Get the week days array
export const weekDays = [1, 2, 3, 4, 5, 6, 0].map((v, index) => dayjs().day(v).format('dd').slice(0, 1));
