/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { Tag } from '../../components/Tag';
import { i18n } from '../../lib/i18n';
import { jsx } from '@emotion/react';

export const PublicTag = ({}) => {
	return <Tag title={i18n.public} color="blue" />;
};
