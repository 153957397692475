/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useCallback, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { alignItemsCss, flexColCss, gapCss } from '../../styles/utils';
import { DomPortal } from '../../components/DomPortal';
import { Toast, ToastProps } from '../../molecules/Toast';

type ToastData = Omit<ToastProps, 'onDismiss' | 'useAnimation' | 'delay'>;

type ToastContainerProps = {
	toastsData?: ToastData[];
	useAnimation?: boolean;
	delay?: number;
	delayEach?: number;
	width?: number;
};

const toastsCss = css`
	position: absolute;
	width: 100vw;
	overflow: hidden;
	padding-bottom: 50px;
	bottom: 0;
`;

export const ToastContainer: React.FC<ToastContainerProps> = ({
	toastsData,
	useAnimation,
	delay = 0,
	delayEach = 0,
	width,
}) => {
	const [toasts, setToasts] = useState<ToastData[]>();

	useEffect(() => {
		setToasts(toastsData);
	}, [toastsData]);

	const onDismiss = useCallback(
		(index: number) => {
			if (useAnimation) {
				return setToasts(toasts => toasts!.map((toast, i) => (i === index ? { ...toast, deleted: true } : toast)));
			}

			setToasts(toasts => toasts!.filter((_, i) => i !== index));
		},
		[useAnimation]
	);

	return (
		<DomPortal selector="body">
			<div css={[toastsCss, flexColCss, alignItemsCss('center'), gapCss(12)]} data-aid="index-ToastContainer">
				{toasts?.map((toast, index) => {
					// Delay each toast by set amount and multiply it by the index to achieve a staggered affect.
					const delayAnimation: number = delay + (delayEach || 0) * index;
					return (
						<Toast
							key={index}
							content={toast.content}
							toastColor={toast.toastColor}
							textColor={toast.textColor}
							onDismiss={() => onDismiss(index)}
							useAnimation={useAnimation}
							delay={delayAnimation}
							deleted={toast.deleted}
							width={width}
						/>
					);
				})}
			</div>
		</DomPortal>
	);
};
