import React from "react";

export const Wifi = (props: any) => (
  <svg
    data-aid="SvgWifi"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9994 4.50018C15.6474 4.50018 19.1004 5.89518 21.7244 8.42918C22.1214 8.81218 22.1324 9.44618 21.7494 9.84318C21.5534 10.0462 21.2914 10.1482 21.0304 10.1482C20.7804 10.1482 20.5294 10.0552 20.3354 9.86718C18.0854 7.69618 15.1254 6.50018 11.9994 6.50018C8.87338 6.50018 5.91338 7.69618 3.66338 9.86718C3.26838 10.2522 2.63438 10.2412 2.24938 9.84318C1.86738 9.44618 1.87738 8.81218 2.27538 8.42918C4.89938 5.89518 8.35238 4.50018 11.9994 4.50018Z"
      fill="currentColor"
    />
    <path
      d="M11.9998 9.49998C9.57178 9.49998 7.21178 10.501 5.52478 12.248C5.14278 12.646 5.15278 13.279 5.55078 13.662C5.94678 14.046 6.58178 14.035 6.96478 13.638C8.27578 12.279 10.1108 11.5 11.9998 11.5C13.8888 11.5 15.7238 12.279 17.0348 13.638C17.2308 13.841 17.4928 13.943 17.7548 13.943C18.0048 13.943 18.2558 13.85 18.4488 13.662C18.8468 13.279 18.8568 12.646 18.4748 12.248C16.7878 10.501 14.4278 9.49998 11.9998 9.49998Z"
      fill="currentColor"
    />
    <path
      d="M8.52678 15.903C9.46378 14.999 10.6968 14.5 11.9998 14.5C13.3028 14.5 14.5358 14.999 15.4728 15.903C15.8698 16.287 15.8808 16.92 15.4978 17.317C15.3018 17.521 15.0398 17.623 14.7778 17.623C14.5278 17.623 14.2768 17.529 14.0838 17.342C12.9588 16.256 11.0408 16.256 9.91578 17.342C9.52078 17.726 8.88678 17.715 8.50178 17.317C8.11878 16.92 8.12978 16.287 8.52678 15.903Z"
      fill="currentColor"
    />
    <path
      d="M10.9998 19.5C10.9998 18.948 11.4468 18.5 11.9998 18.5C12.5528 18.5 12.9998 18.948 12.9998 19.5C12.9998 20.052 12.5528 20.5 11.9998 20.5C11.4468 20.5 10.9998 20.052 10.9998 19.5Z"
      fill="currentColor"
    />
  </svg>
);
