/* @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import { ActivityCategoriesView } from '../views/ActivityCategoriesView';

export const ActivityCategoriesSection: React.FC<React.ComponentProps<typeof ActivityCategoriesView>> = ({
	onClick,
	selectedActivity,
	activities,
	mainActivity,
}) => {
	return (
		<ActivityCategoriesView
			onClick={onClick}
			selectedActivity={selectedActivity}
			activities={activities}
			mainActivity={mainActivity}
		/>
	);
};
