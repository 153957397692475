import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'rc-league-filters',
	templateUrl: './league-filters.component.html',
	styleUrls: ['./league-filters.component.scss'],
})
export class LeagueFiltersComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
