import * as  React from 'react';

export const BnProfiles = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8334 5.83333C10.8334 7.67167 9.33842 9.16667 7.50008 9.16667C5.66175 9.16667 4.16675 7.67167 4.16675 5.83333C4.16675 3.995 5.66175 2.5 7.50008 2.5C9.33842 2.5 10.8334 3.995 10.8334 5.83333ZM7.50008 4.16667C8.41925 4.16667 9.16675 4.91417 9.16675 5.83333C9.16675 6.7525 8.41925 7.5 7.50008 7.5C6.58091 7.5 5.83341 6.7525 5.83341 5.83333C5.83341 4.91417 6.58091 4.16667 7.50008 4.16667ZM16.6667 8.33333C16.6667 9.71167 15.5451 10.8333 14.1667 10.8333C12.7884 10.8333 11.6667 9.71167 11.6667 8.33333C11.6667 6.955 12.7884 5.83333 14.1667 5.83333C15.5451 5.83333 16.6667 6.955 16.6667 8.33333ZM14.1667 7.5C14.6267 7.5 15.0001 7.87417 15.0001 8.33333C15.0001 8.7925 14.6267 9.16667 14.1667 9.16667C13.7067 9.16667 13.3334 8.7925 13.3334 8.33333C13.3334 7.87417 13.7067 7.5 14.1667 7.5ZM18.3334 15.8333C18.3334 16.2933 17.9609 16.6667 17.5001 16.6667C17.0392 16.6667 16.6667 16.2933 16.6667 15.8333C16.6667 14.455 15.5451 13.3333 14.1667 13.3333C13.5976 13.3333 13.0567 13.5283 12.6201 13.8742C13.0734 14.7042 13.3334 15.6558 13.3334 16.6667C13.3334 17.1267 12.9609 17.5 12.5001 17.5C12.0392 17.5 11.6667 17.1267 11.6667 16.6667C11.6667 14.3692 9.79758 12.5 7.50008 12.5C5.20258 12.5 3.33341 14.3692 3.33341 16.6667C3.33341 17.1267 2.96091 17.5 2.50008 17.5C2.03925 17.5 1.66675 17.1267 1.66675 16.6667C1.66675 13.45 4.28425 10.8333 7.50008 10.8333C9.10592 10.8333 10.5609 11.4858 11.6167 12.5383C12.3409 11.9783 13.2326 11.6667 14.1667 11.6667C16.4642 11.6667 18.3334 13.5358 18.3334 15.8333Z"
      fill="currentColor"
    />
  </svg>
);
