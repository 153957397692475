/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { FormPart } from 'app/react/components/customers/utils/formPart';
import { FrameContainer } from 'app/react/components/customers/utils/FrameContainer';
import { colors } from 'app/react/styles/theme';
import { TranslationEn } from 'assets/i18n/en';
import { Button, getIcon } from '@bondsports/utils';

import { useComingSoonPopUp } from 'app/react/components/shared/ComingSoon';
import { Fragment } from 'react';
import { flexRowCss, spaceBetweenCss } from '@app/react/styles/utils';

const basicIconCss = css`
	color: ${colors.formInputBg};
`;

const checkedIconImgCss = css`
	display: flex;
	align-items: center;
	svg {
		width: 20px;
		height: 20px;
		color: #24c875;
	}
`;

const participantsProfilesCss = css`
	display: flex;
	svg {
		width: 20px;
		height: 20px;
		color: ${colors.brandPrimary};
	}
`;

interface ParticipantsProps {
	totalAmount?: number;
}

const Participants = ({ totalAmount }: ParticipantsProps) => {
	const { ComingSoonToggle, ComingSoonModal } = useComingSoonPopUp();

	// preserve behaviour of not rendering this component until we have all the data
	if (!Number.isInteger(totalAmount)) {
		return null;
	}

	return (
		<Fragment>
			<FrameContainer
				width={65}
				margin="0 1rem 2rem 1rem"
				title={TranslationEn.season.dashboard.participants.title}
				button={
					<Grid container justify="flex-end" alignItems="center" css={basicIconCss}>
						<Button
							data-aid="button-seasonDashboardParticipants-toggle"
							sizer="S"
							theme="basic"
							onClick={ComingSoonToggle}
						>
							{getIcon('view')}
						</Button>
						<Button
							data-aid="button-seasonDashboardParticipants-coming"
							sizer="S"
							theme="basic"
							onClick={ComingSoonToggle}
						>
							{getIcon('boxed_card')}
						</Button>
					</Grid>
				}
			>
				<div>
					<div css={participantsProfilesCss}>
						{getIcon('profiles')}
						<Grid item lg={10}>
							<FormPart
								label={''}
								value={`${totalAmount} ${TranslationEn.season.dashboard.participants.register}`}
								width={100}
								margin={0}
							/>
						</Grid>
					</div>

					<div css={[flexRowCss, spaceBetweenCss]}>
						<div css={checkedIconImgCss}>
							{getIcon('whitecheck')}
							<FormPart
								label={''}
								value={TranslationEn.season.dashboard.participants.checkedIn}
								width={100}
								margin={0}
							/>
						</div>

						<Button
							data-aid="button-seasonDashboardParticipants-soon"
							sizer="S"
							theme="secondary"
							onClick={ComingSoonToggle}
						>
							{TranslationEn.season.dashboard.participants.checkInButton}
						</Button>
					</div>
				</div>
			</FrameContainer>

			<ComingSoonModal />
		</Fragment>
	);
};

export default Participants;
