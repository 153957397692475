/** @jsx jsx */

import React, { FC, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { colors } from '../../../styles/theme';
import { PopoverComp } from '../Popover';
import { ReactSVG } from 'react-svg';
import { flexCol } from '@app/react/styles/utils';
const moreButtonContainer = (isDisabled: boolean) => css`
	color: ${isDisabled ? colors.disabled : colors.brandPrimary};
	background: white;
	border: none;
	padding: 1rem 2rem;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 1.4rem;
	text-align: left;
	line-height: 130%;
	flex-grow: 1;
	&:hover {
		background: ${isDisabled ? colors.white : colors.formControlBg};
	}
`;
const iconMoreCss = css`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	div {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	svg {
		width: 24px;
		height: 24px;
	}
`;
interface IAction {
	label: string;
	action: () => void;
	isDisabled?: boolean;
}
interface Props {
	actions?: IAction[];
}
export const MoreMenu: FC<Props> = ({ actions }) => {
	const [isMenuOpen, setMenuOpen] = useState(false);
	const Body = () => (
		<div css={flexCol}>
			{actions.map((action, index) => {
				return (
					<button
						data-aid="button-MoreMenu-action"
						key={index}
						onClick={action.action}
						className="no-print"
						css={moreButtonContainer(action.isDisabled)}
						disabled={action.isDisabled}
					>
						{action.label}
					</button>
				);
			})}
		</div>
	);
	return (
		<PopoverComp
			content={<Body />}
			open={isMenuOpen}
			placement="bottom"
			onClose={() => setMenuOpen(false)}
			arrow={false}
		>
			<button
				data-aid="button-MoreMenu-set"
				style={{
					margin: '0 5px',
					background: 'transparent',
					border: 'none',
				}}
				onClick={() => setMenuOpen(true)}
			>
				<ReactSVG css={iconMoreCss} src="assets/media/icons/customers/more.svg" />
			</button>
		</PopoverComp>
	);
};
