/** @jsx jsx */

import React, { useEffect } from 'react';
import { usePayments } from '../components/payments';
import { BnProvider } from '@bondsports/utils';
import { Charge } from '../components/payments/Charge';
import { useModal } from '@bondsports/utils';
import { Modal } from '../components/shared/Modal';
import { jsx } from '@emotion/react';

export interface ISelectedPaymentDetail {
	token: string;
	type: string;
	isNewCard: boolean;
	amount: number;
}

interface Props {
	totalAmount: number;
	userId: number;
	organizationId: number;
	invoiceId?: number;
	isAmountEditable?: boolean;
	handleCharge?: (value: ISelectedPaymentDetail) => void;
	onModalShowingChange?: (isVisible: boolean) => void;
}

// Example of Charge Modal - Yossi
export const ChargingModalExample = ({
	handleCharge,
	onModalShowingChange,
	totalAmount,
	userId,
	organizationId,
}: Props) => {
	const { Charge } = usePayments();
	const { isShowing, toggle } = useModal();
	const localToggle = () => {
		const isShowing = toggle();
		if (onModalShowingChange) onModalShowingChange(isShowing);
	};

	useEffect(() => {
		localToggle();
	}, []);

	return (
		<BnProvider>
			<Modal isShowing={isShowing} toggle={localToggle}>
				<Charge
					toggle={localToggle}
					totalAmount={totalAmount}
					userId={userId}
					organizationId={organizationId}
					alternativeHandleCharge={(val: ISelectedPaymentDetail) =>
						Promise.resolve(handleCharge ? handleCharge(val) : console.log(val))
					}
					isAmountEditable={true}
					invoiceId={123}
					isScheduled={false}
					showFeeWarning={true /*showFeeWarning*/}
					paymentMethods={[]}
					onSelectPaymentMethod={() => ({})}
				/>
			</Modal>
		</BnProvider>
	);
};
