/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/react';
import { useModal } from '@bondsports/utils';
import { ComingSoon } from './ComingSoon';

export const useComingSoonPopUp = () => {
	const { toggle: ComingSoonToggle, isShowing: isComingSoonShowing } = useModal();

	const ComingSoonModal = () => {
		return <ComingSoon isShowing={isComingSoonShowing} toggle={ComingSoonToggle} />;
	};

	return { ComingSoonToggle, isComingSoonShowing, ComingSoonModal };
};
