/** @jsx jsx */
/** @jsxRuntime classic */
import React, { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { ETypography, ETypographyColor, Radio, Typography } from '@bondsports/utils';
import { Divider } from '@app/react/layouts/Divider';
import { IRadioGroup } from './types';

interface Props {
	radioGroups: IRadioGroup[];
	value: string | number;
	setValue: React.Dispatch<React.SetStateAction<string | number>>;
}

export const RadioGroups = ({ radioGroups, value, setValue }: Props) => {
	return (
		<Fragment>
			{radioGroups.map((arg, index: number) => {
				return (
					<Fragment key={index}>
						<Typography type={ETypography.body2Accented} color={ETypographyColor.primary}>
							{arg.group}
						</Typography>
						{arg.radios.map(arg => {
							return <Radio key={arg.value} {...arg} checked={value === arg.value} setChecked={setValue} />;
						})}
						{index < radioGroups.length - 1 && <Divider />}
					</Fragment>
				);
			})}
		</Fragment>
	);
};
