import { useCallback, useEffect, useState } from 'react';
import { CacheKey, useCache } from './useCache';

// ─────────────────────────────────────────────────────────────────────────────

export const useAsyncData = <T>({ fetch, cacheKey }: UseAsyncDataParams<T>, deps: any[] = []): UseAsyncData<T> => {
	const cache = useCache();
	const [data, setData] = useState<T | null>(cacheKey ? cache.get({ key: cacheKey, parcelable: true }) : null);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);

	const fetchAsync = useCallback(async () => {
		setLoading(true);
		setError(false);

		try {
			const data = await fetch();
			setData(data);
			cache.set(cacheKey, JSON.stringify(data));
		} catch (e) {
			setError(true);
		} finally {
			setLoading(false);
		}
	}, deps);

	useEffect(() => {
		if (!cacheKey) {
			fetchAsync();
			return;
		}

		const cachedData = cache.get({ key: cacheKey, parcelable: true });

		if (cachedData) {
			setData(cachedData);
		} else {
			fetchAsync();
		}
	}, deps);

	return { data, loading, error, refetch: fetchAsync };
};

// ─── Types ───────────────────────────────────────────────────────────────────

interface UseAsyncDataParams<T> {
	fetch: () => Promise<T>;
	cacheKey?: CacheKey;
}

interface UseAsyncData<T> {
	data: T | null;
	loading: boolean;
	error: boolean;
	refetch: () => Promise<void>;
}
