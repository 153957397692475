/** @jsxRuntime classic */
/* * @jsx jsx */
import React, { Fragment, LegacyRef, useMemo } from 'react';
import { StyledSelectOption } from './style';
import { useTheme, jsx } from '@emotion/react';
import { capitalize } from '../../functions';
import { Typography } from '../../atoms';
import { ETypography, ETypographyColor } from '../../types/theme';
import { Checkbox } from '../Checkbox';
import { ISelectOptions, IGroup } from './types';
import { IOption } from '../../types';
import { SelectOption } from '../Select_v2/SelectOption';
import { GroupsOptions } from './GroupsOptions';
import { flexColumn, paddingCss } from '../../styles/utils';
import { i18n } from '../../lib/i18n';
import { Loader } from '../Loader';
import { SizeEnum } from '../../lib/constants';

export const SelectOptions = ({
	options,
	onSelect,
	value = [],
	autoFocus = true,
	isMultiple = false,
	enableSelectAll = false,
	selectAllLabel = '',
	handleSelectAll,
	handleRemoveAll,
	size = SizeEnum.MEDIUM,
	lastOptionElementRef,
	isGroups = false,
	isLoading = false,
}: ISelectOptions) => {
	const colors = useTheme();

	const selectedValuesArray = useMemo(() => {
		return Array.isArray(value) ? value : value ? [value] : [];
	}, [value]);

	const isEmptyOptions = options.length === 0 || (isGroups && Object.keys(options).length === 0);
	const isAllowSelectAll = !isGroups && options.length > 0 && isMultiple && enableSelectAll;
	const labels = i18n.components.select;
	return (
		<div css={flexColumn}>
			{isEmptyOptions && (
				<div css={paddingCss(12)}>
					<Typography color={ETypographyColor.primary} type={ETypography.captionAccented}>
						{i18n.noResults}
					</Typography>
				</div>
			)}
			{isAllowSelectAll && (
				<StyledSelectOption
					theme={{ ...colors }}
					size={size}
					onClick={() => {
						if (value.length === options.length) {
							if (handleRemoveAll) {
								handleRemoveAll();
							}
						} else {
							if (handleSelectAll) {
								handleSelectAll();
							}
						}
					}}
					tabIndex={0}
					autoFocus={false}
				>
					<Typography color={ETypographyColor.primary} type={size === 'M' ? ETypography.body2 : ETypography.cta1}>
						<span>
							{isMultiple && <Checkbox width={16} setChecked={() => {}} checked={value.length === options.length} />}

							{capitalize(selectAllLabel || labels.selectAll)}
						</span>
					</Typography>
				</StyledSelectOption>
			)}
			{isGroups ? (
				<GroupsOptions
					groups={options as IGroup[]}
					onSelect={onSelect}
					isMultiple={isMultiple}
					selectedValuesArray={selectedValuesArray}
					autoFocus={autoFocus}
				/>
			) : (
				<Fragment>
					{(options as IOption[]).map((opt, index) => {
						const optionVal = String(opt.value);

						return (
							<SelectOption
								key={opt.value}
								lastOptionElementRef={
									lastOptionElementRef && options.length - 1 === index ? lastOptionElementRef : undefined
								}
								colors={colors}
								index={index}
								size={size}
								onSelect={onSelect}
								opt={opt as IOption}
								autoFocus={autoFocus}
								isMultiple={isMultiple}
								selectedValuesArray={selectedValuesArray}
								value={value}
								optionVal={optionVal}
							/>
						);
					})}
					{isLoading && <Loader />}
				</Fragment>
			)}
		</div>
	);
};
