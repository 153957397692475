/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/react';
import { Typography } from '../../../atoms/Typography';
import { ETypography, ETypographyColor } from '../../../types';
import { centerTextCss, flexRowCss, justifyContentCss, marginTopCss } from '../../../styles/utils';

export const PopupHeader: React.FC<{ title: string; marginTop?: number }> = ({ title, marginTop = 16 }) => {
	return (
		<div css={[flexRowCss, justifyContentCss('center'), centerTextCss, marginTopCss(marginTop)]}>
			<Typography color={ETypographyColor.primary} type={ETypography.h3}>
				{title}
			</Typography>
		</div>
	);
};
