/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/react';
import { TranslationEn } from 'assets/i18n/en';
import { colors_v2 as colors } from '../../../styles/theme';
import { BnIcon, ETypography, Typography, Pricify, Icons } from '@bondsports/utils';
import { EPaymentMethod } from 'app/react/types/payment';
import { flexRowCss } from 'app/react/styles/utils';
import { PaymentMethodTypeEnum } from '@bondsports/types';

type TPaymentMethodTemporal = PaymentMethodTypeEnum.ACH | PaymentMethodTypeEnum.CARD;

interface Props {
	method: string;
	handleClick?: (method: TPaymentMethodTemporal | string) => void;
	active?: boolean;
	isFirst?: boolean;
	alternativeLabel?: string;
	customerBalance?: number;
	disableBalance?: boolean;
	showAch?: boolean;
}

const container = (isActive: boolean, isFirst: boolean, disabled = false) => css`
	background: ${disabled ? colors.bg_background_primary : colors.bg_background_secondary};
	border: 1px solid ${isActive ? colors.bg_text_primary : colors.bg_border_primary};
	display: flex;
	padding: 2rem;
	flex-direction: column;
	justify-content: center;
	width: 150px;
	box-sizing: border-box;
	border-radius: 3px;
	text-align: center;
	font-weight: 500;
	font-size: 1.4rem;
	line-height: 1.7rem;
	text-align: center;
	${!disabled && `cursor: pointer`};
	color: ${!disabled ? colors.tx_text_primary : colors.tx_text_disabled};
	&:hover {
		${!disabled && `background: ${colors.bg_background_primary};`}
	}
`;

const iconContainer = (balance = false, isDisabled = false) => css`
	${flexRowCss};
	justify-content: center;
	padding-bottom: 10px;

	// if balanceIcon, we color the icon and balance green, or disabled
	${isDisabled && `color: ${colors.tx_text_disabled};`};
	${!isDisabled && balance && `color: ${colors.t_green};`}

	.body-accented {
		${isDisabled && `color: ${colors.tx_text_disabled};`};
		${!isDisabled && balance && `color: ${colors.t_green};`}
	}
`;

const ICONS: Partial<{ [method in PaymentMethodTypeEnum]: string }> = {
	card: Icons.credit_card,
	check: Icons.check_p,
	other: Icons.dollar,
	cash: Icons.cash,
	'gift-card': Icons.check_p,
	us_bank_account: Icons.bank,
};

export const PaymentMethodBox = ({
	method,
	handleClick,
	active,
	isFirst = false,
	alternativeLabel = '',
	customerBalance,
	showAch = true,
	disableBalance = false,
}: Props) => {
	const paymentMethod = method === PaymentMethodTypeEnum.CARD_ON_TERMINAL ? PaymentMethodTypeEnum.CARD : method;
	const isCreditBalance = method === PaymentMethodTypeEnum.BALANCE && !isNaN(customerBalance);
	const disabledBalance = isCreditBalance && (disableBalance || customerBalance === 0);
	const disableACH = method === PaymentMethodTypeEnum.ACH && !showAch;
	const disabled = disabledBalance || disableACH;

	const handleClickPayment = (selectedMethod: string) => {
		if (!disabled) handleClick(selectedMethod);
	};

	return (
		<div
			data-active={active}
			data-aid="PaymentMethodBox"
			css={container(active, isFirst, disabled)}
			onClick={() => handleClickPayment(method)}
		>
			<div data-disable={disableBalance} css={iconContainer(paymentMethod === EPaymentMethod.BALANCE, disabledBalance)}>
				{!isCreditBalance && <BnIcon icon={ICONS[paymentMethod]} />}
				{isCreditBalance && (
					<Typography type={ETypography.body1Accented}>{Pricify(customerBalance ?? 0, undefined)}</Typography>
				)}
			</div>
			<div>{alternativeLabel || TranslationEn.payments.methods[paymentMethod]}</div>
		</div>
	);
};
