/** @jsx jsx */
import * as React from 'react';
import { jsx } from '@emotion/react';
import { containerCss } from './styles';
import { Avatar, AvatarVariant } from '@bondsports/utils';
import { flexCss, marginRightCss } from '@app/react/styles/utils';

export const Card = ({ color, icon, profilePictureUrl, children }: { color; icon; profilePictureUrl; children }) => {
	return (
		<div css={containerCss(color.background)}>
			<div css={flexCss}>
				<div css={marginRightCss(28)}>
					<Avatar img={profilePictureUrl} icon={icon} color={color.primary} variant={AvatarVariant.STATUS} />
				</div>
				{children}
			</div>
		</div>
	);
};
