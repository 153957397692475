/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useState } from 'react';
import { Popup } from '../Popup';
import { SelectOptions } from '../Options';
import { Button } from '../Button';
import { BnIcon } from '../icons_v2';
import { Icons, IOption } from '../../types';

export const MoreMenu = ({ options, handleActions }: { options: IOption[]; handleActions: (v: string) => void }) => {
	const [isOpen, setOpen] = useState<boolean>(false);

	const handleOnActions = (v: string) => {
		setOpen(false);
		handleActions(v);
	};

	return (
		<Popup
			placement="bottom-start"
			padding={0}
			trigger={isOpen}
			onOpenChange={v => setOpen(v)}
			body={<SelectOptions options={options} onSelect={handleOnActions} value={''} />}
		>
			<Button data-aid="button-moreMenu-more" sizer="S" theme="basic">
				<BnIcon icon={Icons.more} />
			</Button>
		</Popup>
	);
};
