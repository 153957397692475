/** @jsx jsx */

import React, { Fragment, useEffect, useState } from 'react';
import { jsx, css } from '@emotion/react';
import * as dayjs from 'dayjs';
import { ReactSVG } from 'react-svg';
import { Stripe } from 'stripe';
import { NO_VALUE_INDICATION } from '../../../utils/ constValues';
import { EditButton } from '../../../../shared/EditButton';
import { EmergencyContacts } from '../../../sections/emergencyContacts';
import { useCart } from '../../../../../hooks/useCart';
import { FormPart } from '../../../utils/formPart';
import { FrameContainer } from '../../../utils/FrameContainer';
import { MiddleWhiteContainer } from '../../../../shared/Containers';
import { ETypography, ETypographyColor, ModalWindow, Typography, useModal } from '@bondsports/utils';
import { PaymentMethodOption, PaymentMethodTypeEnum } from '@bondsports/types';
import { PaymentMethods } from './../PaymentMethods';
import { useToggle } from '@app/react/hooks/useToggle';
import { ColorSet } from '@app/react/components/shared/ColorSet';
import { EditColorModal } from '@app/react/components/shared/EditColorModal.tsx';
import { customersApi } from '@app/react/lib/api/customersApi';
import { EditCustomerDetailsForm } from '../../../../../forms/customers/editCustomerDetails';
import { Charge } from '../../../../payments/Charge';
import { flex, fullWidthCss, imageContainerCss } from '../../../../../styles/utils';
import { EChargeInnerStep } from '../../../../../types/payment';
import { EDateTimeFormats, EDurations } from '../../../../../types/times';
import { TCustomer } from '../../../../../types/customers';
import { getEmergencyContactDetails } from '../../../lib';
import { LinkedAccountsBlock } from '../../LinkedAccountsBlock';
import { TranslationEn } from '@assets/i18n/en';
import { useCustomer } from '../../hooks/useCustomer';
import { useQuery } from '@app/react/hooks/useQuery';
import { useScroll } from '@app/react/hooks/useScroll';
import { ScrollToSectionEnum, UrlParamEnum } from './../Overview/utils';
import { WaiverAndDocuments } from './WaiverAndDocuments';

const paymentMethodEmptyCss = css`
	${flex};
	justify-content: center;
	div {
		texrt-align: center;
		margin-top: 1rem;
		width: 245px;
	}
`;

const iconCss = css`
	height: 60px;
	width: 60px;
	margin: 5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
const overviewContainer = css`
	display: flex;
	margin: 2rem 0 0 0;
	height: 100%;
`;
const leftContainer = css`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: -1rem 3rem 2rem 0;
`;

const mainContainer = css`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 0rem;
	margin-bottom: 1rem;
	flex-grow: 1;
	width: 600px;
`;

const detailsPartCss = css`
	display: flex;
	flex-wrap: wrap;
`;

const mainCon = css`
	display: flex;
	flex-direction: column;
	padding-right: 1rem;
	padding: 1rem 0;
	margin-bottom: 3rem;
	margin-top: -2rem;
`;

const imgCss = css`
	width: 224px;
	height: 224px;
	border-radius: 300px;
`;

interface Props {
	customerState: TCustomer;
	organizationId: number;
	fetchData: () => void;
	orgQuestionnaireId: number;
}

export const CustomerDetails = ({ customerState, organizationId, fetchData, orgQuestionnaireId }: Props) => {
	const { getPaymentMethods } = useCart();
	const { isShowing, toggle } = useModal();
	const [isEditColorModalShowing, editColorModalToggle] = useToggle();
	const [skippedFirstRun, setSkippedFirstRun] = useState<boolean>(false);
	const [paymentMethods, setPaymentMethods] = useState<Stripe.PaymentMethod[]>(undefined);
	const [paymentMethodOptions, setPaymentMethodOptions] = useState<PaymentMethodOption[]>(undefined);
	const [profileEditMode, toggleEditMode] = useToggle();
	const { connectedAccounts, isLoadingLinkedAccounts } = useCustomer();
	const { getQueryValue } = useQuery();
	const { ref: waiverRef, scrollRef } = useScroll();

	const emergencyContact = getEmergencyContactDetails(customerState.emergencyContacts);

	const fetchPaymentMethods = async () => {
		const results = await getPaymentMethods(organizationId, customerState.entityId);
		setPaymentMethodOptions(results);

		const filteredResults = results
			.filter(paymentMethod =>
				[PaymentMethodTypeEnum.ACH, PaymentMethodTypeEnum.CARD].includes(paymentMethod.paymentMethodType)
			)
			.flatMap(fpm => fpm.options);

		if (filteredResults.length > 0) {
			setPaymentMethods(filteredResults);
		} else {
			setPaymentMethods([]);
		}
	};

	useEffect(() => {
		if (!profileEditMode && skippedFirstRun) {
			fetchData();
		}
		setSkippedFirstRun(true);

		if (!paymentMethods) fetchPaymentMethods();
	}, [profileEditMode]);

	const handleToggleClose = () => {
		if (isShowing) {
			fetchPaymentMethods().then();
		}
		toggle();
	};

	useEffect(() => {
		const isScrollToWaiver = getQueryValue(UrlParamEnum.SCROLL_TO_SECTION) === ScrollToSectionEnum.WAIVER;
		if (isScrollToWaiver) {
			scrollRef();
		}
	}, []);

	return (
		<Fragment>
			<MiddleWhiteContainer
				style={{ marginBottom: '7rem', marginTop: '-1rem', display: 'flex' }}
				margin="0"
				height="unset"
				isTransparent={true}
				fullWidth
			>
				<div data-aid="customerDetailsMapper" css={overviewContainer}>
					<div data-aid="customerDetailsMapper-left" css={leftContainer}>
						<div css={imageContainerCss}>
							{customerState.profilePicture && customerState.profilePicture.url ? (
								<img css={imgCss} src={customerState.profilePicture.url} />
							) : (
								<ReactSVG src="assets/media/icons/customers/image_placeholder.svg" css={iconCss} />
							)}
						</div>
						<LinkedAccountsBlock isLoading={isLoadingLinkedAccounts} linkedAccounts={connectedAccounts} />
					</div>

					<div data-aid="customerDetailsMapper-main" css={mainCon}>
						<div css={mainContainer}>
							{profileEditMode ? (
								<EditCustomerDetailsForm
									toggle={toggleEditMode}
									state={customerState}
									organizationId={organizationId}
									customerId={customerState.id}
									editColorModalToggle={editColorModalToggle}
								/>
							) : (
								<FrameContainer
									title={TranslationEn.customers.paymentsInvoices.customerDetails}
									button={<EditButton onEdit={toggleEditMode} />}
								>
									{/* line 1 */}
									<div data-aid="customerDetailsMapper-details1" css={detailsPartCss}>
										<FormPart
											label={TranslationEn.customers.listColumns.firstName}
											value={customerState.firstName || NO_VALUE_INDICATION}
										/>
										<FormPart
											label={TranslationEn.customers.listColumns.lastName}
											value={customerState.lastName || customerState.name}
										/>
									</div>
									{/* line 2 */}
									<div data-aid="customerDetailsMapper-details2" css={detailsPartCss}>
										<FormPart
											label={TranslationEn.customers.customerPage.gender}
											value={customerState.gender ? TranslationEn.gender[customerState.gender] : NO_VALUE_INDICATION}
										/>
										<FormPart
											label={TranslationEn.customers.customerPage.dateOfBirth}
											value={
												(customerState.birthDate &&
													dayjs(customerState.birthDate).isValid() &&
													dayjs(customerState.birthDate).format(EDateTimeFormats.DAY_FORMAT)) ||
												NO_VALUE_INDICATION
											}
										/>
										<FormPart
											label={TranslationEn.customers.customerPage.age}
											value={
												(customerState.birthDate &&
													dayjs().diff(dayjs(customerState.birthDate), EDurations.YEARS, false)) ||
												NO_VALUE_INDICATION
											}
										/>
									</div>
									{/* line 3 */}
									<div data-aid="customerDetailsMapper-details3" css={detailsPartCss}>
										<FormPart
											label={TranslationEn.customers.customerPage.phoneNumber}
											value={customerState.phoneNumber || NO_VALUE_INDICATION}
										/>
										<FormPart
											label={TranslationEn.customers.customerPage.email}
											value={customerState.email || NO_VALUE_INDICATION}
										/>
									</div>
									{/* line 4 */}
									<div data-aid="customerDetailsMapper-details4" css={detailsPartCss}>
										<FormPart
											label={TranslationEn.customers.customerPage.address}
											value={
												customerState.address
													? `${customerState.address.street || ''}, ${customerState.address.streetNum || ''} `
													: NO_VALUE_INDICATION
											}
										/>
										<FormPart
											label={TranslationEn.customers.customerPage.city}
											value={
												customerState.address
													? `${customerState.address.city || ''}, ${customerState.address.state || ''} `
													: NO_VALUE_INDICATION
											}
										/>
										<FormPart
											label={TranslationEn.customers.customerPage.zip}
											value={(customerState.address && customerState.address.zip) || NO_VALUE_INDICATION}
										/>
										<ColorSet colorCodeId={customerState.colorCodeId} />
									</div>
								</FrameContainer>
							)}

							{/* Emergency contact */}
							{!profileEditMode && (
								<EmergencyContacts
									onEdit={toggleEditMode}
									name={emergencyContact?.name}
									phone={emergencyContact?.phoneNumber}
								/>
							)}

							{/* Payment methods */}
							<FrameContainer
								title={TranslationEn.customers.customerPage.paymentMethods}
								button={<EditButton onEdit={toggle} />}
							>
								<div>
									{!customerState.isAnonymous && paymentMethods && paymentMethods.length > 0 ? (
										<PaymentMethods paymentMethods={paymentMethods} />
									) : (
										<div data-aid="customerDetailsMapper-methods" css={paymentMethodEmptyCss}>
											<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
												{TranslationEn.customers.customerPage.noPaymentMethods}
											</Typography>
										</div>
									)}
								</div>
							</FrameContainer>

							<div ref={waiverRef} css={fullWidthCss}>
								<WaiverAndDocuments
									organizationId={organizationId}
									userId={customerState.entityId}
									orgQuestionnaireId={orgQuestionnaireId}
								/>
							</div>
						</div>
					</div>
				</div>
			</MiddleWhiteContainer>

			<ModalWindow isShowing={isShowing} toggle={handleToggleClose}>
				<Charge
					organizationId={organizationId}
					toggle={handleToggleClose}
					userId={customerState.entityId}
					totalAmount={0}
					customer={customerState}
					paymentMethods={paymentMethodOptions}
					isScheduled={false}
					initialStep={EChargeInnerStep.NEW_CARD}
					showFeeWarning={false}
				/>
			</ModalWindow>
			<EditColorModal
				isShowing={isEditColorModalShowing}
				toggle={editColorModalToggle}
				entityId={customerState.id}
				organizationId={customerState.organizationId}
				labels={TranslationEn.customers.detailsPage.editColorModal}
				updateEndPoint={customersApi.updateEventsColors}
			/>
		</Fragment>
	);
};
