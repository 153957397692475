/** @jsxRuntime classic */
/** @jsx jsx */

import React, { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import {
	bottomMarginCss,
	flexRowCss,
	iconCss,
	leftMarginCss,
	rightBorderCss,
	rightMarginCss,
	secondRowIndentCss,
	flexGrowCss,
	flexColCss,
} from './style';
import { ETypography, ETypographyColor, Icons } from '../../types';
import { Typography } from '../../atoms';
import { BnIcon } from '../../components/icons_v2';
import { useColors } from '../../hooks/useColors';
import { DetailsProps } from './index';
import { ConflictsIndication } from '../conflicts/ConflictsIndication';

const detailColCss = css`
	min-width: 350px;
	max-width: 392px;
	align-items: flex-start;
	align-self: stretch;
`;

const containerCss = css`
	gap: 16px 32px;
	flex-wrap: wrap;
`;

const alightSelfCss = css`
	align-self: flex-start;
`;

const alignSelfStretchCss = css`
	align-self: stretch;
`;

export const AttendanceDetails = ({
	dateTimeString,
	duration,
	location,
	attendance,
	notes,
	conflicts,
}: DetailsProps) => {
	const { colors } = useColors();

	const labels = {
		attendance: 'Attendance',
		checkedIn: 'Checked in',
		missingPayments: 'Missing payments:',
		publicNotes: 'Public notes',
		privateNotes: 'Private notes',
		viewConflict: 'View conflict',
	};

	return (
		<div css={[containerCss, flexRowCss]} data-aid="Details-attendancePopup">
			<div data-aid="DetailsAttendancePopup" css={[detailColCss, flexGrowCss]}>
				<div css={[flexRowCss, bottomMarginCss(18)]}>
					<div data-aid="DetailsAttendancePopup-icon" css={iconCss(String(colors.tx_text_teriaty), 20)}>
						<BnIcon icon={Icons.clock} />
					</div>
					<span data-aid="DetailsAttendancePopup-dateTime" css={rightMarginCss(12)}>
						<Typography color={ETypographyColor.primary} type={ETypography.body2}>
							{dateTimeString}
						</Typography>
					</span>
					<Typography color={ETypographyColor.teriaty} type={ETypography.overlineAccented}>
						{duration}
					</Typography>
				</div>
				<div data-aid="DetailsAttendancePopup-location" css={[flexRowCss, bottomMarginCss(18)]}>
					<div css={iconCss(String(colors.tx_text_teriaty), 20)}>
						<BnIcon icon={Icons.location} />
					</div>
					<Typography color={ETypographyColor.primary} type={ETypography.body2}>
						{location}
					</Typography>
					{conflicts && <ConflictsIndication caption={labels.viewConflict} OnTriggerConflicts={conflicts?.callback} />}
				</div>
				<div css={flexRowCss}>
					<div data-aid="DetailsAttendancePopup-profile" css={iconCss(String(colors.tx_text_teriaty), 20)}>
						<BnIcon icon={Icons.profiles} />
					</div>
					<div data-aid="DetailsAttendancePopup-attendance" css={flexRowCss}>
						<Typography color={ETypographyColor.primary} type={ETypography.body2}>
							{attendance.total} {labels.attendance}
						</Typography>
					</div>
				</div>
				<div css={[flexRowCss, secondRowIndentCss(6)]}>
					<span data-aid="DetailsAttendancePopup-checkin" css={[rightMarginCss(12), rightBorderCss]}>
						<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
							{labels.checkedIn} {attendance.checkedIn}/{attendance.total}
						</Typography>
					</span>
					<span data-aid="DetailsAttendancePopup-missing" css={leftMarginCss(12)}>
						<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
							{labels.missingPayments} {attendance.missingPayments}
						</Typography>
					</span>
				</div>
			</div>
			{(notes?.public || notes?.private) && (
				<div data-aid="DetailsAttendancePopup-notes" css={[detailColCss, flexRowCss, flexGrowCss]}>
					<div css={[flexColCss, alightSelfCss]}>
						<div data-aid="DetailsAttendancePopup-notesIcone" css={iconCss(String(colors.tx_text_teriaty), 20)}>
							<BnIcon icon={Icons.note} />
						</div>
					</div>
					<div data-aid="DetailsAttendancePopup-public" css={[alignSelfStretchCss, flexColCss]}>
						{notes?.public && (
							<Fragment>
								<div data-aid="DetailsAttendancePopup-publicLabel" css={bottomMarginCss(4)}>
									<Typography color={ETypographyColor.primary} type={ETypography.body2}>
										{labels.publicNotes}
									</Typography>
								</div>
								<div data-aid="DetailsAttendancePopup-publicNotes" css={bottomMarginCss(14)}>
									<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
										{notes.public}
									</Typography>
								</div>
							</Fragment>
						)}
						{notes?.private && (
							<Fragment>
								<div data-aid="DetailsAttendancePopup-privateLabel" css={bottomMarginCss(4)}>
									<Typography color={ETypographyColor.primary} type={ETypography.body2}>
										{labels.privateNotes}
									</Typography>
								</div>
								<div data-aid="DetailsAttendancePopup-privateNotes" css={bottomMarginCss(14)}>
									<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
										{notes.private}
									</Typography>
								</div>
							</Fragment>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
