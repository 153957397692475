import { useProgram } from 'app/react/hooks/useProgram';
import { useSession } from 'app/react/hooks/useSession';
import { TranslationEn } from 'assets/i18n/en';
import { Button } from '@bondsports/utils';
import { Icons } from 'app/react/styles/theme';
import React, { Fragment, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { WidgetBody } from './widgetBody';

export const ProductsWidget = () => {
	const { sessionState, sessionId } = useSession();
	const { programState } = useProgram();
	const history = useHistory();
	const labels = {
		addProducts: 'Add product and prices',
		addProductLabel: 'Add product',
		productExist: 'Go to Products tab to view products',
	};
	const steps = TranslationEn.activities.steps;

	const { title, isAddProduct } = useMemo(() => {
		let title = labels.productExist;
		let isAddProduct = false;
		if (sessionState.products && sessionState.products.length === 0) {
			title = labels.addProducts;
			isAddProduct = true;
		}
		return { title, isAddProduct };
	}, [sessionState]);

	const handleRedirectionToNewProduct = () => {
		history.push(`/program/${steps.type[programState.type]}/${programState.id}/sessions/${sessionId}/pricing`);
	};

	return (
		<WidgetBody icon={Icons.activity_w} title={title}>
			{isAddProduct ? (
				<Button data-aid="button-productsCallToAction-add" sizer="S" theme="primary" onClick={handleRedirectionToNewProduct}>
					{labels.addProductLabel}
				</Button>
			) : (
				<Fragment />
			)}
		</WidgetBody>
	);
};
