import React from 'react';
import { useRecoilState } from 'recoil';
import { layoutStore } from '../stores/layoutStore';

import { useQuery } from './useQuery';

export const useLayout = () => {
	const [isLeftWindow, setLeftWindow] = useRecoilState(layoutStore.isLeftWindow);
	const [isRightWindow, setRightWindow] = useRecoilState(layoutStore.isRightWindow);
	const [isNavigation, setNavigation] = useRecoilState(layoutStore.isNavigation);
	const [isLeftExpanded, setLeftExpanded] = useRecoilState(layoutStore.isLeftExpanded);
	const [isSubLeftExpanded, setSubLeftExpanded] = useRecoilState(layoutStore.isSubLeftExpanded);
	const [showCart, setShowCart] = useRecoilState(layoutStore.showCart);
	const [isCart, setCart] = useRecoilState(layoutStore.isCart);
	const [isDisableScreenShown, setDisableScreenShow] = useRecoilState(layoutStore.isDisableScreenShown);
	const [leftWindowContent, setLeftWindowContent] = useRecoilState(layoutStore.leftRenderContent);
	const [isLeftWindowCollapsable, setLeftWindowCollapsable] = useRecoilState(layoutStore.isLeftWindowCollapsable);

	const { setQuery } = useQuery();

	const transitionTime = 600;

	const leftWindowToggle = () => {
		setLeftWindow(!isLeftWindow);
	};

	const cartToggle = (val: boolean) => {
		if (val && isLeftWindow) {
			setLeftWindow(false);
			// setCart(true);
		}
		setCart(val);
	};

	const showCartToggle = () => {
		setShowCart(!showCart);
	};

	const leftWindowCollapsableToggle = () => setLeftWindowCollapsable(!isLeftWindowCollapsable);

	const disabledScreenToggle = (v: boolean) => {
		setDisableScreenShow(v);
	};

	const openSubLeft = () => {
		setQuery('sl', 'true');
	};

	const closeSubLeft = () => {
		setQuery('sl', 'false');
	};

	const collapseLeftIfExpanded = () => {
		if (isLeftExpanded) {
			setLeftExpanded(false);
		}
	};

	const openLeftWindowOnExpandedMode = () => {
		leftWindowToggle();
		setLeftExpanded(true);
	};

	return {
		isLeftWindow,
		setLeftWindow,
		openSubLeft,
		closeSubLeft,
		isCart,
		leftWindowToggle,
		showCart,
		showCartToggle,
		setShowCart,
		cartToggle,
		leftWindowContent,
		setLeftWindowContent,
		isLeftWindowCollapsable,
		isRightWindow,
		setRightWindow,
		leftWindowCollapsableToggle,
		isDisableScreenShown,
		isLeftExpanded,
		setLeftExpanded,
		isSubLeftExpanded,
		setSubLeftExpanded,
		disabledScreenToggle,
		transitionTime,
		collapseLeftIfExpanded,
		openLeftWindowOnExpandedMode,
	};
};
