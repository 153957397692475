/** @jsx jsx */

import React from 'react';
import { AngularAutoComplete } from 'bondsports-utils';
import { jsx } from '@emotion/react';
import { RecoilRoot } from 'recoil';
import { BnProvider } from '@bondsports/utils';

interface IAngularProps {
	checked?: any;
	typed?: (val: any) => void;
	list: { id: any; text: string }[];
	clear?: () => void;
	onSelect?: any;
	placeholder?: string;
	label?: string;
}

export const AutoComplete = (props: IAngularProps) => {
	return (
		<RecoilRoot>
			<BnProvider>
				<AngularAutoComplete {...props} />
			</BnProvider>
		</RecoilRoot>
	);
};
