import * as yup from 'yup';
import { ResourceNameTypeEnum } from '@bondsports/types';

export const MIN_LENGTH = 1;
export const MAX_TITLE_LENGTH = 60;
export const MAX_CONTENT_LENGTH = 120;

export const SCHEMA = yup.object().shape({
	entityId: yup.number(),
	entityType: yup.string().oneOf(Object.values(ResourceNameTypeEnum)),
	creatorId: yup.number(),
	creatorName: yup.string(),
	title: yup.string().required().min(MIN_LENGTH).max(MAX_TITLE_LENGTH),
	content: yup.string().max(MAX_CONTENT_LENGTH),
	isPinned: yup.boolean().default(false),
	isAlert: yup.boolean().default(false),
	isPublic: yup.boolean().default(false).notRequired(),
});
