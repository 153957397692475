import * as  React from 'react';

export const BsVollyball = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1631 3.80134C9.92903 2.468 6.63826 3.65949 5.44678 4.1985C5.16309 3.34744 5.04961 2.55311 5.02125 2.04247C5.92904 1.58857 6.92195 1.33325 7.99996 1.33325C10.0992 1.33325 11.9716 2.29779 13.1631 3.80134Z"
      fill="#4A90E2"
    />
    <path
      d="M5.84401 5.04956C7.06386 4.51056 10.8936 3.12049 14.156 5.36162C14.4113 5.98573 14.5816 6.60985 14.6667 7.2907C11.9433 5.90063 8.87946 7.03538 8.00003 7.43254C6.95039 6.75169 6.26954 5.90063 5.84401 5.04956Z"
      fill="#4A90E2"
    />
    <path
      d="M11.6028 7.57439C10.1844 7.60276 8.96453 8.05666 8.42553 8.28361C8.539 10.8368 6.5532 13.1063 5.44682 14.1843C6.0993 14.4396 6.78015 14.6098 7.51773 14.6666C11.461 11.9432 11.6596 8.6524 11.6028 7.57439Z"
      fill="#4A90E2"
    />
    <path
      d="M7.48939 8.22687C7.54613 9.36162 7.09223 10.4396 6.52486 11.3758C2.89367 9.24815 2.46814 5.75879 2.46814 4.28361C2.92204 3.60276 3.48941 3.03538 4.14189 2.58148C4.3121 4.05666 4.96458 6.58148 7.48939 8.22687Z"
      fill="#4A90E2"
    />
    <path
      d="M6.01417 12.1418C3.03545 10.3829 2.01418 7.7446 1.70213 5.81552C1.47518 6.49638 1.33333 7.23396 1.33333 7.99992C1.33333 10.4113 2.63829 12.5389 4.56736 13.702C4.93616 13.39 5.47516 12.851 6.01417 12.1418Z"
      fill="#4A90E2"
    />
    <path
      d="M9.13473 14.5815C12.312 11.8581 12.5957 8.85099 12.539 7.60276C13.2482 7.71623 13.9858 7.94318 14.6666 8.36872C14.4964 11.5177 12.1702 14.0708 9.13473 14.5815Z"
      fill="#4A90E2"
    />
  </svg>
);
