/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Modal as ModalWindow } from '../../components/Modal/modal';
import { ThemeColors as colors } from '../../styles/theme';
import { Typography } from '../../atoms';
import { ETypographyColor, ETypography } from '../../types';
import { Button } from '../../components/Button';
import { useRefundPopup } from './hooks/useRefundPopup';
import { IPaymentMethod } from '../../molecules/PaymentMethodPicker';
import { RefundSteps } from './steps';
import { ArchiveSlotInvoiceActionEnum } from '../../molecules/ActionSelect';
import { roundPriceCents } from '../../lib/price';

const container = (customWidth = 430) => css`
	display: flex;
	width: ${customWidth}px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 18px 24px 8px 24px;
	h4 {
		margin: 8px 0 8px 0;
	}
	text-align: center;
	transition: width 0.5s;
	max-height: 87vh;
`;

const lineCss = (step?: number, marginTopBottom = false) => css`
	display: flex;
	${step === 0 && 'justify-content: flex-end;'}
	${step === 1 && 'justify-content: center;'}
	${marginTopBottom && `margin-top: 8px; margin-bottom: 8px`};
	button:first-child {
		margin-right: 8px;
	}
`;

const subtitleCss = css`
	margin-bottom: 8px;
`;

const hrCss = css`
	border-top: 1px solid ${colors.bg_border_seperator};
	margin: 16px 0 16px -3.5rem;
	width: calc(100% + 7rem - 1px); // -1px to not trigger overflow on the modal itself
`;

const sectionsCss = (grow = false) => css`
	display: flex;
	flex-direction: column;
	width: 99%;
	align-items: center;
	${grow ? `flex-grow: 1; padding-right: 32px; margin-left: 32px;` : `flex-grow: 0`};
	overflow-y: ${grow ? 'auto' : 'none'};
`;

const buttonContainer = css`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-grow: 0;
`;

export enum RefundTypeEnum {
	LEAVE_BALANCE = 'leave_balance',
	REDUCE_BALANCE = 'reduce_balance',
}

interface RefundWrapperProps {
	isShowing: boolean;
	toggle: () => void;
	isModal?: boolean;
	children: ReactNode;
}

const RefundWrapper = ({ isModal = true, isShowing, toggle, children }: RefundWrapperProps) => {
	return (
		<div className="no-print" data-aid="index-refundPopup">
			{isModal ? (
				<ModalWindow toggle={toggle} isShowing={isShowing} isDismissable maxHeight="90%" minHeight="fit-content">
					{children}
				</ModalWindow>
			) : (
				<Fragment>{children}</Fragment>
			)}
		</div>
	);
};

export interface RefundPopupProps {
	isShowing?: boolean;
	refundAmount?: number;
	usedPaymentMethods: IPaymentMethod[];
	lineItemCount?: number;
	refundResponse?: IPaymentMethod[];
	goToResourceType?: 'program' | 'membership';
	sendRefund: (val: IPaymentMethod[], action: RefundTypeEnum) => void;
	printReceiptAction: () => void;
	sendReceiptAction: (type: 'email' | 'sms', toAddress: string) => void;
	goToResource?: () => void;
	toggle: () => void;
	disableActions?: boolean;
	toggleOnSubActions?: boolean;
	isModal?: boolean;
	isPublicInvoice?: boolean;
	invoiceOwner?: string;
}

export const RefundPopup = ({
	refundAmount,
	usedPaymentMethods,
	isShowing = true,
	lineItemCount,
	refundResponse,
	goToResourceType,
	sendRefund,
	printReceiptAction,
	sendReceiptAction,
	goToResource,
	toggle,
	disableActions = false,
	toggleOnSubActions = true,
	isModal = true,
	isPublicInvoice = false,
	invoiceOwner = '',
}: RefundPopupProps) => {
	const [sendReceipt, setSendReceipt] = useState<{ type: 'email' | 'sms'; toAddress: string }>();
	const [summarySubtitle, setSummarySubtitle] = useState<string | undefined>();
	const {
		step,
		loading,
		labels,
		selectedAction,
		selectedMethods,
		amountToRefund,
		init,
		setStep,
		setLoading,
		setSelectedMethods,
		handleSelectAction,
	} = useRefundPopup();

	const stepLabels = useMemo(() => {
		return labels[step ?? 0];
	}, [step]);

	const mainButtonActive = useMemo(() => {
		if (selectedMethods.length > 0 && (selectedAction || disableActions) && amountToRefund) {
			const refundedAmount = roundPriceCents(selectedMethods.reduce((a, b) => a + (b.refundAmount ?? 0), 0));
			return refundedAmount === amountToRefund;
		}

		if (refundResponse && refundResponse?.length > 0) return true;

		return false;
	}, [selectedMethods, selectedAction, amountToRefund, disableActions]);

	const handleOnChangeSendReceipt = (type: 'email' | 'sms', toAddress: string) => {
		setSendReceipt({ type, toAddress });
	};

	const handleAction = (action: ArchiveSlotInvoiceActionEnum) => handleSelectAction(action);

	const handleMethods = (methods: IPaymentMethod[]) => setSelectedMethods(methods);

	const handleSecondaryButton = (calledFromTertiary = false) => {
		if (toggleOnSubActions) {
			toggle();
		}
		if (step === 1 && !calledFromTertiary) handlePrint();
	};

	const handlePrint = () => printReceiptAction();

	const handleGoToResource = () => {
		if (toggleOnSubActions) {
			toggle();
		}
		if (goToResource) goToResource();
	};

	const mainButtonAction = () => {
		// Validate
		switch (step) {
			case 1:
				if (sendReceipt) sendReceiptAction(sendReceipt.type, sendReceipt.toAddress);
				break;
			default:
				if (amountToRefund && selectedMethods && (selectedAction || disableActions)) {
					const totalRefunded = selectedMethods.reduce((sum, method) => sum + (method.refundAmount ?? 0), 0);
					if (amountToRefund === totalRefunded) {
						sendRefund(
							selectedMethods,
							disableActions
								? RefundTypeEnum.REDUCE_BALANCE
								: selectedAction !== ArchiveSlotInvoiceActionEnum.REFUND
								? RefundTypeEnum.REDUCE_BALANCE
								: RefundTypeEnum.LEAVE_BALANCE
						);
						setLoading(true);
					}
				}
				break;
		}
	};

	const handleInit = useCallback(() => {
		if (
			((isModal && isShowing) || !isModal) &&
			refundAmount &&
			(!amountToRefund || amountToRefund === 0 || amountToRefund !== refundAmount)
		) {
			init(refundAmount);
		}
	}, [isShowing, refundAmount, isModal]);

	useEffect(() => {
		handleInit();
	}, [isShowing, refundAmount, isModal]);

	useEffect(() => {
		if (refundResponse && refundResponse.length > 0) {
			if (step !== 1) {
				setLoading(false);
				setStep(1);
				let subtitle;
				//	Only success, no failures
				const successes = refundResponse?.filter(response => response.refundSuccess);
				const failures = refundResponse?.filter(response => !response.refundSuccess);
				//	Single success
				if (successes?.length === 1 && failures?.length === 0) subtitle = labels[1].subtitle;
				//	Multiple success
				if (successes?.length > 1 && failures?.length === 0) subtitle = labels[1].subtitleMulti;
				//	Success and failures
				if (successes?.length > 0 && failures?.length > 0) subtitle = labels[1].subtitleMultiMixed;
				//	Only failures
				if (successes?.length === 0 && failures?.length > 0) subtitle = labels[1].subtitleFail;
				if (subtitle) setSummarySubtitle(subtitle);
			}
		}
	}, [refundResponse]);

	// The "send receipt" button is disabled when step 1 (the completion step) is reached and no email address has been filled in.
	const disableSendReceiptButton = step === 1 && !Boolean(sendReceipt?.toAddress);

	return (
		<RefundWrapper isModal={isModal} isShowing={isShowing} toggle={toggle}>
			<div css={container(550)}>
				{/* Title section */}
				{stepLabels.title && (
					<div css={sectionsCss(false)}>
						{stepLabels.title && (
							<Typography color={ETypographyColor.primary} type={ETypography.h4}>
								{stepLabels.title}
							</Typography>
						)}
						{stepLabels.subtitle && (
							<Typography css={subtitleCss} color={ETypographyColor.secondary} type={ETypography.body2}>
								{summarySubtitle}
							</Typography>
						)}
						<hr css={hrCss} />
					</div>
				)}

				{/* Body section */}
				<div css={sectionsCss(true)}>
					<RefundSteps
						step={step}
						amountToRefund={refundAmount ?? 0}
						usedPaymentMethods={usedPaymentMethods}
						lineItemCount={lineItemCount}
						refundResponse={refundResponse}
						sendReceiptAction={handleOnChangeSendReceipt}
						goToResource={goToResource ? handleGoToResource : undefined}
						handleSelectedMethods={handleMethods}
						handleSelectAction={handleAction}
						goToResourceType={goToResourceType}
						disableActions={disableActions}
						isPublicInvoice={isPublicInvoice}
						invoiceOwner={invoiceOwner}
					/>
				</div>

				{/* Button section */}
				<div css={buttonContainer}>
					<hr css={hrCss} />
					<div css={lineCss(step, true)}>
						<Button data-aid="button-refundPopup-sec" sizer="S" theme="secondary" disabled={loading} onClick={() => handleSecondaryButton(false)}>
							{stepLabels.secondaryButton}
						</Button>
						<Button
							isProcessing={loading}
							sizer="S"
							theme="primary"
							onClick={mainButtonAction}
							disabled={!mainButtonActive || disableSendReceiptButton}
						>
							{stepLabels.mainButton}
						</Button>
					</div>
					{step === 1 && (
						<div css={lineCss(step)}>
							<Button data-aid="button-refundPopup-tert" sizer="S" theme="basic" onClick={() => handleSecondaryButton(true)}>
								{stepLabels.tertiaryButton}
							</Button>
						</div>
					)}
				</div>
			</div>
		</RefundWrapper>
	);
};
