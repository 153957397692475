import React from 'react';

export const Classes = (props: any) => (
  <svg
    data-aid="svgClasses"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.53125 13.2358C8.73762 12.4464 7.60754 11.9528 6.35094 11.9528C3.94766 11.9528 2 13.7607 2 16V7.04657C2 5.11265 3 3 5.76562 3C8.53125 3 9.53125 5.11265 9.53125 7.04657V13.2358Z"
      fill="currentColor"
    />
    <path
      d="M16 9.49998C19.1541 7.92293 21.0859 9.62766 21.5 10C21.9141 10.3724 22 10.6347 22 10.9583V18.5C22 18.8236 21.7377 19.0859 21.4141 19.0859C21.4141 19.0859 19.0202 18.8281 14.5101 20.414C10 22 7.02086 21 6 20.414C4.97914 19.8281 3.17188 18.4248 3.17188 15.9785C3.17188 14.2227 4.5923 12.7995 6.35156 12.7995C8.07535 12.7995 9.47879 14.1684 9.5298 15.8825C9.5625 16.9813 8.69023 17.9124 7.59102 17.9036C6.53711 17.8951 5.6823 17.0351 5.6823 15.9792C5.6823 15.5377 6.10867 15.1964 6.57316 15.3453C6.84668 15.4331 7.0193 15.6952 7.02086 15.9824C7.02262 16.3135 7.29906 16.5796 7.63395 16.5645C7.95039 16.5502 8.19574 16.2774 8.19262 15.9607C8.18266 14.954 7.36059 14.138 6.35156 14.138C5.33477 14.138 4.51043 14.9606 4.51043 15.9792C4.51043 17.6967 5.91605 19.092 7.63734 19.0754C9.34195 19.0588 10.7031 17.6443 10.7031 15.9397V10.3724C10.7031 10.3724 12.8459 11.077 16 9.49998Z"
      fill="currentColor"
    />
  </svg>
);
