import React from 'react';

export const Discount = (props: any) => (
  <svg
    data-aid="svgDiscount"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5853 14.6493C13.1254 14.1766 13.1801 13.3555 12.7073 12.8153C12.2346 12.2752 11.4135 12.2205 10.8733 12.6933C10.3332 13.166 10.2785 13.9872 10.7513 14.5273C11.224 15.0674 12.0451 15.1221 12.5853 14.6493ZM11.9267 13.8968C12.0512 13.7878 12.0638 13.5985 11.9548 13.4739C11.8458 13.3494 11.6565 13.3368 11.5319 13.4458C11.4074 13.5548 11.3948 13.7441 11.5038 13.8687C11.6128 13.9932 11.8021 14.0058 11.9267 13.8968Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2093 13.1814C18.6821 13.7215 18.6275 14.5427 18.0873 15.0154C17.5472 15.4882 16.7261 15.4335 16.2533 14.8934C15.7806 14.3532 15.8352 13.5321 16.3753 13.0594C16.9155 12.5866 17.7366 12.6412 18.2093 13.1814ZM17.4568 13.84C17.5659 13.9646 17.5533 14.1539 17.4287 14.2629C17.3042 14.3719 17.1148 14.3593 17.0058 14.2348C16.8968 14.1102 16.9094 13.9209 17.0339 13.8119C17.1585 13.7028 17.3478 13.7154 17.4568 13.84Z"
      fill="currentColor"
    />
    <path
      d="M15.0718 9.72339C15.4851 9.75089 15.7978 10.1082 15.7703 10.5215L15.3174 17.3287C15.2899 17.742 14.9326 18.0547 14.5193 18.0272C14.106 17.9997 13.7932 17.6424 13.8207 17.2291L14.2737 10.4219C14.3012 10.0086 14.6585 9.69589 15.0718 9.72339Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5345 3.7461C11.4714 3.44189 11.3846 3.14159 11.2741 2.8526C10.963 2.03831 10.4326 1.23554 9.62471 0.730614C8.78273 0.204394 7.74864 0.071584 6.61671 0.433389C5.20001 0.886221 4.39561 2.18484 4.24655 3.49793C4.09498 4.83308 4.59158 6.31865 5.90003 7.29999C5.92362 7.31768 5.94774 7.33417 5.97231 7.34948L5.32693 11.5339C5.20431 12.3289 5.40612 13.1399 5.88705 13.7847L11.7338 21.6241C12.7243 22.9522 14.604 23.2259 15.9321 22.2354L22.345 17.4526C23.6731 16.462 23.9468 14.5823 22.9563 13.2542L17.1095 5.41481C16.6286 4.76997 15.9089 4.34529 15.1119 4.23612L11.5345 3.7461ZM9.35811 3.44799C9.15379 2.96741 8.87294 2.61925 8.56473 2.42663C8.26583 2.23982 7.84961 2.13899 7.22564 2.33844C6.72118 2.49968 6.315 3.0081 6.23378 3.72353C6.19409 4.07321 6.23886 4.44096 6.38171 4.78615C6.64553 3.8347 7.57801 3.20415 8.57995 3.3414L9.35811 3.44799ZM11.1194 7.17464C11.3755 6.74697 11.5253 6.25704 11.5999 5.77374L14.8404 6.21762C15.1061 6.25401 15.346 6.39557 15.5063 6.61052L21.3531 14.4499C21.6832 14.8926 21.592 15.5192 21.1493 15.8493L14.7364 20.6321C14.2937 20.9623 13.6672 20.8711 13.337 20.4284L7.49026 12.589C7.32995 12.3741 7.26268 12.1038 7.30356 11.8387L8.30853 5.32289L9.61818 5.50228C9.55194 5.90266 9.42005 6.15858 9.30318 6.28228C8.86017 6.51666 8.58408 7.00617 8.64622 7.5338C8.72695 8.21942 9.34821 8.70977 10.0338 8.62903C10.7194 8.5483 11.2098 7.92704 11.1291 7.24142C11.1264 7.21895 11.1232 7.19668 11.1194 7.17464Z"
      fill="currentColor"
    />
    <path
      d="M5.01164 7.6408C5.48615 7.92339 5.64173 8.53715 5.35915 9.01166L4.29294 10.802C4.00891 11.2789 3.93587 11.8525 4.09132 12.3854L6.07339 19.1805C6.39454 20.2815 7.57386 20.8875 8.65597 20.5075L9.45988 20.2253C9.98097 20.0423 10.5517 20.3164 10.7347 20.8375C10.9177 21.3586 10.6436 21.9294 10.1225 22.1123L9.31856 22.3946C7.15434 23.1545 4.7957 21.9426 4.1534 19.7406L2.17133 12.9454C1.86044 11.8796 2.00651 10.7325 2.57458 9.77864L3.64078 7.98831C3.92337 7.5138 4.53713 7.35822 5.01164 7.6408Z"
      fill="currentColor"
    />
  </svg>
);
