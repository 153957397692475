/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import React, { ReactNode } from "react";
import { useColors } from "../../hooks/useColors";
import { IColors, Icons } from "../../types/theme";
import { BnIcon } from "../icons_v2";

const containerWithIconCss = (colors: IColors, maxWidth: string) => css`
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  max-width: ${maxWidth};
  .icon-container {
    svg {
      color: ${colors.tx_text_teriaty};
    }
  }
  .children-container {
    margin-left: 16px;
    .line {
      margin-top: 4px;
    }
  }
`;

export const ContainerWithIcon = ({
  icon,
  children,
  maxWidth = "350px",
}: {
  icon: Icons;
  children: ReactNode;
  maxWidth?: string;
}) => {
  const { colors } = useColors();
  return (
    <div css={containerWithIconCss(colors, maxWidth)}>
      <div className="icon-container">
        <BnIcon icon={icon} />
      </div>
      <div className="children-container">{children}</div>
    </div>
  );
};
