/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { Fragment, useContext } from 'react';
import {
	bottomMarginCss,
	containerCss,
	detailsMarginCss,
	dividerCss,
	flexColCss,
	layoutContainer,
	dataListContainer,
	headerCss,
	footerCss,
} from './style';
import { useColors } from '../../hooks/useColors';
import { ModalContext } from '../../components/Modal/container';

interface LayoutRegularProps {
	header: React.ReactNode;
	details: React.ReactNode;
	dataList: React.ReactNode;
	footer: React.ReactNode;
}

export const Layout = ({ header, details, dataList, footer }: LayoutRegularProps) => {
	const { isMaximized, isInTransition } = useContext(ModalContext);
	const { colors } = useColors();

	return (
		<div css={[flexColCss, containerCss]} data-aid="Layout-attendancePopup">
			{/* Header */}
			<div css={headerCss}>{header}</div>
			<div css={[dividerCss(colors), bottomMarginCss(24)]} />
			<div css={layoutContainer((isInTransition && !isMaximized) || isMaximized)}>
				{/*	Data list */}
				<div css={dataListContainer((isInTransition && !isMaximized) || isMaximized)}>{dataList}</div>
				{/* Details */}
				<div css={detailsMarginCss((isInTransition && !isMaximized) || isMaximized)}>{details}</div>
			</div>

			{footer && (
				<Fragment>
					<div css={dividerCss(colors)} />
					<div css={footerCss}>{footer}</div>
				</Fragment>
			)}
		</div>
	);
};
