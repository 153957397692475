/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { LabelTag } from '../../../molecules/LabelTag';
import { marginTopCss } from '../../../styles/utils';
import { IOption } from '..';
import { Fragment, useEffect, useMemo } from 'react';
import { ShowMoreButton } from '../../ShowMoreButton';
import { useSelectedTags } from './useSelectedTags';
import { containerCss } from './styles';
import { GroupTypeLabel } from './GroupTypeLabel';
import { MoreOptionsTooltip } from '../../../molecules/MoreOptionsTooltip';

const INIT_MAX_HEIGHT = 88;
const FINAL_MAX_HEIGHT = 1000; // 1000px to give it an infinite height and animation

export const SelectedTags = ({
	selectedValues,
	handleRemove,
	isWithShowMoreButton = true,
}: {
	selectedValues: IOption[];
	handleRemove?: (val: string) => void;
	isWithShowMoreButton?: boolean;
}) => {
	const {
		isShowMore,
		setIsShowMore,
		hiddenValues,
		handleSetHiddenValues,
		render,
		rerender,
		expandableDivRef,
		toggleShowMore,
	} = useSelectedTags();

	const isTagsOutOfDisplaybounds = useMemo(() => {
		return expandableDivRef.current && expandableDivRef.current.scrollHeight > INIT_MAX_HEIGHT;
	}, [expandableDivRef?.current?.scrollHeight]);

	useEffect(() => {
		if (!isWithShowMoreButton) {
			handleSetHiddenValues(selectedValues);
		}
	}, [selectedValues]);

	/*A workaround to rerender the component each time the selectedValues
	 in order to update the expandableDivRef.current.scrollHeight*/
	useEffect(() => {
		rerender(!render);
	}, [selectedValues]);

	return (
		<Fragment>
			<div
				css={containerCss(isShowMore, INIT_MAX_HEIGHT, FINAL_MAX_HEIGHT)}
				ref={expandableDivRef}
				data-aid="index-SelectedTags"
				data-show={isShowMore}
			>
				{selectedValues?.map(selectedValue => {
					const label = selectedValue.key
						? GroupTypeLabel(selectedValue.key, selectedValue.label)
						: selectedValue.label;
					return (
						<LabelTag
							key={selectedValue.value}
							state={{ label, value: selectedValue.value }}
							handleRemove={handleRemove}
							size="S"
						/>
					);
				})}
			</div>
			{isTagsOutOfDisplaybounds && (
				<Fragment>
					{isWithShowMoreButton ? (
						<div css={marginTopCss(8)}>
							<ShowMoreButton handleShowMore={toggleShowMore} />
						</div>
					) : (
						<MoreOptionsTooltip moreItems={hiddenValues} />
					)}
				</Fragment>
			)}
		</Fragment>
	);
};
