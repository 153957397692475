/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';

import { flexCss, gapCss, boldlinkCss } from '@app/react/styles/utils';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';

export const Footer = ({
	isExistingData,
	viewAllPath,
	viewAll,
	noDataText,
	noAlerts,
	alertsText,
}: {
	isExistingData: boolean;
	viewAllPath: string;
	viewAll: string;
	noDataText: string;
	noAlerts: string;
	alertsText?: string;
}) => {
	const text = alertsText || noAlerts;
	return (
		<Fragment>
			{isExistingData ? (
				<div css={[flexCss, gapCss(8)]}>
					<Typography type={ETypography.body2Accented} color={ETypographyColor.primary}>
						{text}
					</Typography>
					<Link to={viewAllPath}>
						<div css={boldlinkCss}>
							<Typography type={ETypography.body2Link} color={ETypographyColor.primary}>
								{viewAll}
							</Typography>
						</div>
					</Link>
				</div>
			) : (
				<Typography type={ETypography.body2Accented} color={ETypographyColor.primary}>
					{noDataText}
				</Typography>
			)}
		</Fragment>
	);
};
