/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactNode } from 'react';
import { jsx, css } from '@emotion/react';
import { mobileOnly } from '../../../styles/utils';

const container = css`
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	background: white;
	padding: 24px 20px;
	gap: 24px;

	${mobileOnly} {
		padding: 0;
	}
`;

export const Body = ({ children }: { children: ReactNode }) => {
	return (
		<div css={container} className="body-section" data-aid="index-body">
			{children}
		</div>
	);
};
