import React, { useEffect, ReactNode, useRef, useState, FC } from 'react';
import { createPortal } from 'react-dom';

interface IProps {
	children: ReactNode;
	selector: string;
}

/**
 *
 * @param selector a css selector to hook onto as portal
 * @param children ReactNode to insert in the hooked element
 * @returns ReactPortal
 */
export const DomPortal: FC<IProps> = ({ children, selector }) => {
	const ref = useRef<any>();
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		ref.current = document.querySelector(selector);
		setMounted(true);
	}, [selector]);

	return mounted ? createPortal(children, ref.current) : null;
};
