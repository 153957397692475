import { TranslationEn } from '@assets/i18n/en';
import { useCallback, useEffect, useRef } from 'react';
import { activitiesApi } from '../lib/api/activitesApi';
import { SportItem } from '../types/sports';
import { useAsyncData } from './useAsyncData';
import { useErrorModal } from './useErrorModal';

interface UseSports {
	sports: SportItem[];
	getSportById: (id: number) => SportItem | undefined;
	loading: boolean;
	error: boolean;
}

export const useSports = (): UseSports => {
	const { data, loading, error } = useAsyncData<SportItem[]>(
		{
			cacheKey: 'sports',
			fetch: activitiesApi.getActivities,
		},
		[]
	);

	// TODO: we have 1 org that this map is init but it is not set with the data that comes from useAsyncData<SportItem[]>(line: 16). connected to bug BOND-8243
	const sportsHashmap = useRef(new Map<number, SportItem>());

	const { setErrorModal } = useErrorModal();

	useEffect(() => {
		if (!error) return;

		setErrorModal({
			title: TranslationEn.sportsErrorModalBody.title,
			message: TranslationEn.sportsErrorModalBody.message,
			isDismissable: true,
		});
	}, [error]);

	useEffect(() => {
		if (!data) return;

		data.forEach(sport => {
			sportsHashmap.current.set(sport.id, sport);
		});
	}, [data]);

	const getSportById = useCallback(
		(id: number): SportItem => {
			return sportsHashmap.current.get(id);
		},
		[sportsHashmap]
	);

	return {
		sports: data || [],
		loading,
		error,
		getSportById,
	};
};
