import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { StyledSegment } from './style';

export interface ISegment {
  label1: string;
  label2: string;
  isPressed: boolean;
  setPressed: (val: boolean) => void;
}

export const Segment = ({
  label1,
  label2,
  isPressed = false,
  setPressed,
}: ISegment) => {
  const colors = useTheme();
  // const [isPressed, setPressed] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(100);
  const [changeLabel, setChangeLabel] = useState<boolean>(false);
  const ref = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setChangeLabel(isPressed);
    }, 40);
  }, [isPressed]);
  useEffect(() => {
    if (ref.current) {
      // @ts-ignore: Object is possibly 'null'.
      setWidth(ref.current.offsetWidth);
    }
  }, [ref.current]);

  return (
    <StyledSegment theme={{ ...colors }} isPressed={isPressed} width={width}>
      <div data-aid="segment">
        <span className="background">
          <span ref={ref} className="label" onClick={() => setPressed(false)}>
            {label1}
          </span>
          <span className="label" onClick={() => setPressed(true)}>
            {label2}
          </span>
          <span className="toggle" onClick={() => setPressed(!isPressed)}>
            {changeLabel ? label2 : label1}
          </span>
        </span>
      </div>
    </StyledSegment>
  );
};
