import { Component, EventEmitter, Output } from '@angular/core';

enum RadioOptions {
	CustomerSearch = 'customerSearch',
	CustomerInvite = 'customerInvite',
}

@Component({
	selector: 'rc-team-player-select-invite-type',
	templateUrl: './select-type.component.html',
})
export class SelectInvitationTypeComponent {
	@Output() hide = new EventEmitter<void>();
	@Output() submit = new EventEmitter<RadioOptions>();

	selected: RadioOptions = RadioOptions.CustomerInvite;

	selectType(type) {
		this.selected = type;
	}
}
