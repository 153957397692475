/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useState, useEffect, Fragment } from "react";
import { css, jsx } from "@emotion/react";
import { useTheme } from "@emotion/react";
import { IAddon, IPackage } from "../../types/entities/addon";
import { ResourcesToProduct } from "../../types/entities/resources-to-products";
import { AddonContainerCss, footerCss, headerCss, mainCss } from "./style";
import { Icons } from "../../types/theme";
import { useTypography } from "../../hooks/useTypography";
import { Pricify } from "../../lib/price";
import { DateAddon } from "./dateAddon";
import { Button } from "../../components/Button";
import { Checkbox } from "../../components/Checkbox";
import { BnIcon } from "../../components/icons_v2";

const dateOptions = [
  {
    title: "some title",
    subTitle: "some sub title",
  },
];
export const Addon = ({
  addonItem,
  events,
  handleAddToCart,
  handleRemoveFromCart,
}: IAddon) => {
  const colors = useTheme();
  const { typography } = useTypography();
  const [checked, setChecked] = useState<boolean>(false);
  const [selected, setSelected] = useState<number[]>([]);
  const [isAddedToCart, setAddedToCart] = useState<boolean>(false);
  const [isFirst, setFirst] = useState<boolean>(true);
  const [clearTrigger, setClearTrigger] = useState<boolean>(false);
  const [eventToWorkWith, setEvents] = useState<ResourcesToProduct>({
    quantity: 0,
    resourceType: "",
    resources: [],
  });

  useEffect(() => {
    if (!isFirst) {
      if (checked) {
        clickAddToCart();
      } else {
        clickRemoveFromCart();
      }
    }
    setFirst(false);
  }, [checked]);
  const clickAddToCart = () => {
    setAddedToCart(true);
    handleAddToCart(selected, addonItem, eventToWorkWith);
  };

  const clickRemoveFromCart = () => {
    setAddedToCart(false);
    setSelected([]);
    setClearTrigger(!clearTrigger);
    handleRemoveFromCart(addonItem);
  };

  useEffect(() => {
    setEvents(events);
  }, [events]);

  return (
    <div data-aid="addon" css={AddonContainerCss(colors)}>
      {(addonItem?.package as IPackage)?.timePeriod === "event" ? (
        <Fragment>
          <div css={headerCss(colors, typography)}>
            <div className="icon_container">
              <BnIcon icon={Icons.pos_equipment} />
            </div>
            <div className="titles">
              <h1>{addonItem.name}</h1>
              <h2>Add product to cart now!</h2>
            </div>
          </div>
          <div css={mainCss}>
            <DateAddon
              events={eventToWorkWith}
              label="Please select the dates you prefer:"
              options={dateOptions}
              selected={selected}
              setSelected={setSelected}
              clearTrigger={clearTrigger}
            />
          </div>
          <div css={footerCss(colors, typography)}>
            <div className="price_container">
              <div>Price Per Unit</div>
              {addonItem.price ? Pricify(addonItem.price) : "N/A"}
            </div>
            <Button
              data-aid="button-addon-on"
              theme={isAddedToCart ? "secondary" : "primary"}
              sizer="M"
              disabled={selected?.length === 0 || !addonItem.price}
              onClick={
                addonItem.price
                  ? isAddedToCart
                    ? clickRemoveFromCart
                    : clickAddToCart
                  : undefined
              }
            >
              {addonItem.price
                ? isAddedToCart
                  ? "Remove"
                  : "Add To Cart"
                : "invalid price"}
            </Button>
          </div>
        </Fragment>
      ) : (
        <div css={headerCss(colors, typography)}>
          <div className="checkbox">
            <Checkbox checked={checked} setChecked={(val) => setChecked(val)} />
          </div>
          <div className="icon_container">
            <BnIcon icon={Icons.pos_equipment} />
          </div>
          <div className="titles">
            <h1>{addonItem.name}</h1>
            <h2>Add product to cart now!</h2>
          </div>
        </div>
      )}
    </div>
  );
};
