/** @jsx jsx */
/** @jsxRuntime classic */
import React, { FC, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import { DomPortal } from '@bondsports/utils';

import { Container } from './container';
import { Background } from './background';

interface ModalProps {
	isShowing: boolean;
	toggle: () => void;
	padding?: number;
	paddingX?: number;
	showButton?: boolean;
	isDismissable?: boolean;
	maxWidth?: number;
}

export const Modal: FC<ModalProps> = ({
	showButton = true,
	isShowing,
	toggle,
	children,
	padding = 1,
	paddingX,
	isDismissable = true,
	maxWidth,
}) => {
	useEffect(() => {
		const body = document.querySelector('body');
		body.style.overflowY = isShowing ? 'hidden' : 'auto';
	}, [isShowing]);

	return isShowing ? (
		<div data-aid="sharedModal">
		<DomPortal selector="body">
			<Background onClick={() => (isDismissable ? toggle() : undefined)} />
			<Container showButton={showButton} padding={padding} paddingX={paddingX} toggle={toggle} maxWidth={maxWidth}>
				{children}
			</Container>
		</DomPortal>
		</div>
	) : null;
};
