import React, { useRef } from 'react';
import { useRecoilState } from 'recoil';
import * as dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { Slot, SlotTypeEnum, TimeSlotDto } from '@bondsports/types';

import { useQuery } from './useQuery';
import { organizationApi } from '../lib/api/organizationApi';
import { calendarStore, ECalendarMode } from '../stores/calendarStore';
import { useOrganization } from './useOrganization';
import { facilityApi } from '../lib/api/facilityApi';
import { useNotification } from './useNotification';
import { ENotificationType } from '../stores/baseStore';
import { bookingV2Api } from '../lib/api/bookingv2';
import { useNavigation } from './useNavigation';
import { SlotDto } from '../types/NewReservation';
import { TranslationEn } from '../../../assets/i18n/en';
import { eventDispatcher, EventsDispatcher } from './useEventDispatcher';
import { ERoutePaths } from '../types/navigation';
import { localStorage } from '../lib/storage';
import { EStorageKeys } from '../types/enums';
import { IEventDates } from '../types/program';
import { EDurations, EDateTimeFormats } from '../types/times';
import { useColorCode } from './useColorCode';
import {
	bundleDraftSlotForSpaces,
	bundleEventToSpaces,
	IBundledSlot,
	ISlotEventsToSpaces,
} from '../components/calendar/calendatUtils/bundleSlots';

const listToTree = (arr = []) => {
	let map = {},
		node,
		res = [],
		i;
	for (i = 0; i < arr.length; i += 1) {
		map[arr[i].id] = i;
		arr[i].children = [];
	}
	for (i = 0; i < arr.length; i += 1) {
		node = arr[i];
		if (node.parentSpaceId !== null) {
			if (arr[map[node.parentSpaceId]]) {
				arr[map[node.parentSpaceId]].children.push(node);
			}
		} else {
			res.push(node);
		}
	}

	return res;
};

export function toTree(data) {
	const arr = data.map(x => {
		const newX = { ...x };
		newX.children = [];
		return newX;
	});

	return listToTree(arr);
}

export interface ITimeSlot extends TimeSlotDto {
	relevantSlotId?: number;
	isStep4?: boolean;
	isFullpage?: boolean;
}

export interface IReservations {
	name: string;
}

export interface ISlotsEvents {
	[eventId: number]: Slot;
}

export const useCalendar = () => {
	const [resources, setResources] = useRecoilState(calendarStore.resources);
	const [slots, setSlots] = useRecoilState(calendarStore.slots);
	const [draftSlots, setDraftSlots] = useRecoilState(calendarStore.draftSlots);
	const [facilities, setFacilities] = useRecoilState(calendarStore.facilities);
	const [selectedFacility, setSelectedFacility] = useRecoilState(calendarStore.selectedFacility);
	const [CalendarScrollTo, setCalendarScrollTo] = useRecoilState(calendarStore.scrollTo);
	const prevFacility = useRef<number>(null);
	const prevMode = useRef<ECalendarMode>(null);
	const [selectedDate, setSelectedDate] = useRecoilState(calendarStore.selectedDate);
	const { organizationId } = useOrganization();
	const [spaces, setSpaces] = useRecoilState(calendarStore.spaces);
	const [showSpaces, setShowSpaces] = useRecoilState(calendarStore.showSpaces);
	const [mode, setMode] = useRecoilState(calendarStore.mode);
	const [view, setView] = useRecoilState(calendarStore.view);
	const [isFilters, setFilters] = useRecoilState(calendarStore.isFilters);
	const [filters, setFiltersValues] = useRecoilState(calendarStore.filters);
	const [groups, setGroups] = useRecoilState(calendarStore.groups);
	const [filterResources] = useRecoilState(calendarStore.filterResources);
	const [dateRange, setDateRange] = useRecoilState(calendarStore.dateRange);
	const [selectedMonthlyResource, setSelectedMonthlyResource] = useRecoilState(calendarStore.selectedMonthlyResource);
	const [isLoading, setLoading] = useRecoilState(calendarStore.isLoading);
	const [isFirstRender, setFirstRender] = useRecoilState(calendarStore.isFirstRender);
	const [groupedResources, setGroupedResources] = useRecoilState(calendarStore.groupedResources);

	const { ngNavigate } = useNavigation();
	const { getQueryString, setMultipleQuery, resetQuery } = useQuery();
	const { setPopupNotification } = useNotification();
	const { getDefaultMaintenanceColor } = useColorCode();
	const defaultMaintColors = getDefaultMaintenanceColor();
	const labels = TranslationEn.calendar;
	const { YYYY_MM_DD } = EDateTimeFormats;

	const selectResourceGroup = (mainGroupId: number, childrenIds: number[]) => {
		setShowSpaces({ ...showSpaces, [mainGroupId]: childrenIds });
	};

	type SlotUpdateInput = {
		endDate: string;
		endTime: string;
		overId: string;
		parentId: string;
		slotId: string;
		startDate: string;
		startTime: string;
	};

	//extract-logic?
	const updateSlot = (data: SlotUpdateInput) => {
		// find slot by id
		const rawSlot = slots.events[data.slotId];

		bookingV2Api
			.updatePartialSingleSlot(rawSlot.reservation.id, organizationId, selectedFacility, rawSlot.id, {
				...rawSlot,
				startTime: dayjs(data.startTime, EDateTimeFormats.H24_WITH_MINUTES).format(EDateTimeFormats.H24_WITH_SECONDS),
				endTime: dayjs(data.endTime, EDateTimeFormats.H24_WITH_MINUTES).format(EDateTimeFormats.H24_WITH_SECONDS),
				spaceId: data.overId,
			})
			.then(response => {
				// fix for the jump of the calendar - basically we update here the state with the current change,
				// and than fetch the api again to validate the change
				const { events, eventsToSpaces } = slots;
				const newEvents = cloneDeep(events);
				const newEventsToSpaces = cloneDeep(eventsToSpaces);
				newEvents[rawSlot.id] = {
					...newEvents[rawSlot.id],
					startDate: rawSlot.startDate,
					endDate: rawSlot.endDate,
					startTime: dayjs(data.startTime, EDateTimeFormats.H24_WITH_MINUTES).format(EDateTimeFormats.H24_WITH_SECONDS),
					endTime: dayjs(data.endTime, EDateTimeFormats.H24_WITH_MINUTES).format(EDateTimeFormats.H24_WITH_SECONDS),
					spaceId: data.overId,
				};

				if (rawSlot.spaceId === data.overId) {
					// stay on the same resource
					newEventsToSpaces[rawSlot.spaceId] = newEventsToSpaces[rawSlot.spaceId].map(ev => {
						if (ev.id === rawSlot.id) {
							return {
								...ev,
								startDate: rawSlot.startDate,
								endDate: rawSlot.endDate,
								startTime: dayjs(data.startTime, EDateTimeFormats.H24_WITH_MINUTES).format(
									EDateTimeFormats.H24_WITH_SECONDS
								),
								endTime: dayjs(data.endTime, EDateTimeFormats.H24_WITH_MINUTES).format(
									EDateTimeFormats.H24_WITH_SECONDS
								),
							};
						}
						return ev;
					});
				} else {
					// move to another resource
					newEventsToSpaces[rawSlot.spaceId] = newEventsToSpaces[rawSlot.spaceId].filter(ev => {
						if (ev.id === rawSlot.id) {
							return false;
						}
						return true;
					});
					newEventsToSpaces[data.overId].push({
						...rawSlot,
						startTime: dayjs(data.startTime, EDateTimeFormats.H24_WITH_MINUTES).format(
							EDateTimeFormats.H24_WITH_SECONDS
						),
						endTime: dayjs(data.endTime, EDateTimeFormats.H24_WITH_MINUTES).format(EDateTimeFormats.H24_WITH_SECONDS),
						spaceId: data.overId,
					});
				}
				const bundleEventWithMaintenance = bundleEventToSpaces(newEventsToSpaces, defaultMaintColors);
				setSlots({
					events: newEvents,
					eventsToSpaces: bundleEventWithMaintenance,
				});

				if (!response.err) {
					setPopupNotification(labels.notifications.eventUpdated, ENotificationType.success);
					fetchSpacesByDay();
					eventDispatcher<EventsDispatcher.CALENDAR_SLOT_UPDATED>(EventsDispatcher.CALENDAR_SLOT_UPDATED, {
						slotId: rawSlot.id,
					});
				} else {
					handleUpdateSlotError();
				}
			})
			.catch(() => {
				handleUpdateSlotError();
			});
	};

	const fetchGroups = () => {
		facilityApi.getGroupsByFacilityId(organizationId, selectedFacility).then(res => {
			if (res.data) {
				const groupsTree = {};
				const groupsParents = [];
				res.data.forEach(group => {
					if (groupsTree[group.parentSlotId]) {
						groupsTree[group.parentSlotId] = [
							...groupsTree[group.parentSlotId],
							{
								name: group.name,
								childrenSlotIds: [...group.childrenSlotIds],
							},
						];
					} else {
						groupsParents.push(group.parentSlotId);
						groupsTree[group.parentSlotId] = [
							{
								name: group.name,
								childrenSlotIds: [...group.childrenSlotIds],
							},
						];
					}
				});

				Object.keys(groupsTree).forEach(key => {
					groupsTree[key] = [{ name: 'main', childrenSlotIds: [Number(key)] }, ...groupsTree[key]];
				});

				setGroupedResources(groupsParents);
				setGroups(groupsTree);
			}
		});
	};

	const fetchFacilities = () => {
		organizationApi.getFacilities(organizationId).then(res => {
			setFacilities(res.data);
		});
	};

	const removeDateFromStorge = () => localStorage.removeItem(EStorageKeys.EVENT_DATE_OBJECT);

	const handleSelectDate = (v: string) => {
		removeDateFromStorge();
		setSelectedDate(v);
	};

	const nextDay = () => {
		handleSelectDate(dayjs(selectedDate, YYYY_MM_DD).add(1, EDurations.DAY).format(YYYY_MM_DD));
	};

	const previousDay = () => {
		handleSelectDate(dayjs(selectedDate, YYYY_MM_DD).subtract(1, EDurations.DAY).format(YYYY_MM_DD));
	};

	const nextWeek = () => {
		handleSelectDate(dayjs(selectedDate, YYYY_MM_DD).add(1, EDurations.WEEK).format(YYYY_MM_DD));
	};

	const previousWeek = () => {
		handleSelectDate(dayjs(selectedDate, YYYY_MM_DD).subtract(1, EDurations.WEEK).format(YYYY_MM_DD));
	};

	const nextMonth = () => {
		handleSelectDate(dayjs(selectedDate, YYYY_MM_DD).add(1, EDurations.MONTH).format(YYYY_MM_DD));
	};

	const previousMonth = () => {
		handleSelectDate(dayjs(selectedDate, YYYY_MM_DD).subtract(1, EDurations.MONTH).format(YYYY_MM_DD));
	};

	const handleNextDateButton = () => {
		if (mode === ECalendarMode.DAILY) {
			nextDay();
		}
		if (mode === ECalendarMode.MONTHLY) {
			nextMonth();
		}
		if (mode === ECalendarMode.WEEKLY) {
			nextWeek();
		}
	};

	const handlePreviousDateButton = () => {
		if (mode === ECalendarMode.DAILY) {
			previousDay();
		}
		if (mode === ECalendarMode.MONTHLY) {
			previousMonth();
		}
		if (mode === ECalendarMode.WEEKLY) {
			previousWeek();
		}
	};

	const selectFacility = (facility: any) => {
		setSelectedFacility(facility.id);
	};

	const fetchSpacesByDay = () => {
		setLoading(true);
		const facilityFromSchedule = +localStorage.getItem(EStorageKeys.FACILITYID);
		const dateFromSchedule = localStorage.getItem(EStorageKeys.EVENT_DATE_OBJECT) as IEventDates;
		const startDateFromSchedule = dateFromSchedule?.formattedStartDate;
		const date = startDateFromSchedule ?? selectedDate;
		const facilityId = facilityFromSchedule || selectedFacility;

		facilityApi.getSpacesByDay(organizationId, facilityId, date, mode).then(res => {
			// setShowSpaces(toTree(res));
			if (res) {
				const newSpaces = toTree(res);

				// check if groups exist
				if (isFirstRender || prevFacility.current !== selectedFacility || prevMode.current === ECalendarMode.MONTHLY) {
					setFirstRender(false);
					prevFacility.current = selectedFacility;
					// initiate all spaces to be close at the first run
					const showData = {};
					newSpaces.forEach(space => {
						showData[space.id] = [space.id];
					});

					setShowSpaces(showData);
				}
				prevMode.current = mode;
				setSpaces(newSpaces);
				let newSlots = [];

				const eventsToSpaces = {} as ISlotEventsToSpaces;
				const events = {} as ISlotsEvents;

				setResources(res);
				res.forEach(resource => {
					newSlots = [...newSlots, ...resource.slots];

					if (eventsToSpaces[resource.id]) {
						eventsToSpaces[resource.id] = [...eventsToSpaces[resource.id], ...(resource.slots as IBundledSlot[])];
					} else {
						eventsToSpaces[resource.id] = [...resource.slots];
					}
				});

				newSlots.forEach(event => {
					events[event.id] = event;
				});
				const bundleEventWithMaintenance = bundleEventToSpaces(eventsToSpaces, defaultMaintColors);
				setSlots({ events, eventsToSpaces: bundleEventWithMaintenance });
				setLoading(false);
			}
		});
	};

	const handleUpdateSlotError = () => {
		setPopupNotification(labels.errors.eventUpdate, ENotificationType.warning);
		fetchSpacesByDay();
	};
	const handleDateRangeChange = (newDateRange: { startDate: string; endDate: string }) => {
		setDateRange(newDateRange);
	};

	const updateDraftSlots = (newSlots: SlotDto[]) => {
		const sortedSlots = [...newSlots].sort((a, b) => dayjs(a.startDate).valueOf() - dayjs(b.startDate).valueOf());
		const firstSlot = sortedSlots[0];
		setSelectedDate(firstSlot.startDate);
		// handle scroll to calendar
		setCalendarScrollTo({
			startTime: firstSlot.startTime,
			endTime: firstSlot.endTime,
			resourceId: firstSlot.spaceId,
			trigger: dayjs().valueOf(),
		});

		const events = {};
		const eventsToSpacesByDate = {}; //will be:{ date: { spaceId: [slot]}} e.g. { 2023-04-11: { 1284: [{...},{...}] }

		const maintenanceSlots = newSlots
			.filter(newSlot => newSlot.maintenanceSlots)
			.flatMap(newSlot => newSlot.maintenanceSlots);
		const allSlots = [...newSlots, ...maintenanceSlots];

		allSlots.forEach((slot, index) => {
			const { startDate, spaceId } = slot;
			const id = +`${dayjs().valueOf()}${index} `;
			if (!events[startDate]) {
				events[startDate] = {};
			}

			events[startDate][id] = { ...slot, id, isDraft: true };
			if (!eventsToSpacesByDate[startDate]) {
				eventsToSpacesByDate[startDate] = {};
			}

			if (eventsToSpacesByDate[startDate][spaceId]) {
				eventsToSpacesByDate[startDate][spaceId] = [
					...eventsToSpacesByDate[startDate][spaceId],
					{ ...slot, id, isDraft: true },
				];
			} else {
				eventsToSpacesByDate[startDate][spaceId] = [{ ...slot, id, isDraft: true }];
			}
		});
		const isMaintenanceReservation = allSlots.every(slot => slot.slotType === SlotTypeEnum.MAINTENANCE);
		// if it is a maintenance reservation, all maints, no need to bundle anything.
		const draftSlotToSpaces = isMaintenanceReservation
			? eventsToSpacesByDate
			: bundleDraftSlotForSpaces(eventsToSpacesByDate);

		setDraftSlots({ events, eventsToSpaces: draftSlotToSpaces });
	};

	const handleCloseConflicts = (isFromSchedule = false) => {
		if (isFromSchedule) {
			const path = localStorage.getItem(EStorageKeys.FORMATTED_PATH) as string;
			ngNavigate(ERoutePaths.ACTIVITIES, path);
		} else {
			resetQuery();
		}
	};

	/**
	 *
	 * @param resourceId - the id of the relevant resource
	 * @param startTime - HH:mm format
	 * @param endTime - HH:mm format
	 * @param startDate - YYYY-MM-DD format
	 * @param endDate - YYYY-MM-DD format
	 */
	const handleNavigateToConflicts = ({
		resourceId,
		startTime,
		endTime,
		startDate,
		endDate,
		relevantSlotId,
		isStep4 = false,
		isFullpage = false,
	}: ITimeSlot) => {
		if (window.location.href.includes('calendar')) {
			setMultipleQuery({
				conflicts: 'true',
				r: String(resourceId),
				st: startTime,
				et: endTime,
				sd: startDate,
				ed: endDate,
				rsid: relevantSlotId + '',
				cinline: isStep4 ? 'false' : 'true',
				fullpage: String(isFullpage),
			});
		} else {
			const { formattedStartDate, formattedEndDate } = localStorage.getItem(
				EStorageKeys.EVENT_DATE_OBJECT
			) as IEventDates;
			ngNavigate(
				'calendar',
				`?${getQueryString({
					conflicts: 'true',
					cinline: 'true',
					r: String(resourceId),
					st: startTime,
					et: endTime,
					sd: formattedStartDate,
					ed: formattedEndDate,
					rsid: relevantSlotId + '',
					fullpage: String(isFullpage),
				})}`
			);
		}
	};

	const resetDraftSlots = () => {
		setDraftSlots({ events: {}, eventsToSpaces: {} });
	};

	return {
		resources,
		setSlots,
		slots,
		facilities,
		fetchFacilities,
		selectedFacility,
		selectFacility,
		handleSelectDate,
		fetchSpacesByDay,
		groupedResources,
		selectedDate,
		spaces,
		mode,
		isFilters,
		setFilters,
		filters,
		setFiltersValues,
		fetchGroups,
		groups,
		filterResources,
		handleCloseConflicts,
		selectResourceGroup,
		showSpaces,
		view,
		setMode,
		setView,
		dateRange,
		handleDateRangeChange,
		CalendarScrollTo,
		setCalendarScrollTo,
		handleNextDateButton,
		handlePreviousDateButton,
		selectedMonthlyResource,
		setSelectedMonthlyResource,
		isLoading,
		updateDraftSlots,
		setShowSpaces,
		updateSlot,
		draftSlots,
		setDraftSlots,
		handleNavigateToConflicts,
		resetDraftSlots,
	};
};
