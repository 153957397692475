import * as  React from 'react';

export const BnList = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.18 9.1678H7.48918C7.03524 9.1678 6.66504 9.51863 6.66504 9.9478V10.0545C6.66504 10.4836 7.03524 10.8345 7.48918 10.8345H17.18C17.634 10.8345 18.005 10.4836 18.005 10.0545V9.9478C18.005 9.51863 17.634 9.1678 17.18 9.1678Z"
      fill="currentColor"
    />
    <path
      d="M17.18 14.3358H7.48918C7.03524 14.3358 6.66504 14.6866 6.66504 15.1158V15.2224C6.66504 15.6516 7.03524 16.0024 7.48918 16.0024H17.18C17.634 16.0024 18.005 15.6516 18.005 15.2224V15.1158C18.005 14.6866 17.634 14.3358 17.18 14.3358Z"
      fill="currentColor"
    />
    <path
      d="M17.18 3.99983H7.48918C7.03524 3.99983 6.66504 4.35067 6.66504 4.77983V4.8865C6.66504 5.31567 7.03524 5.6665 7.48918 5.6665H17.18C17.634 5.6665 18.005 5.31567 18.005 4.8865V4.77983C18.005 4.35067 17.634 3.99983 17.18 3.99983Z"
      fill="currentColor"
    />
    <path
      d="M3.24707 3.57983C2.55707 3.57983 1.99707 4.13983 1.99707 4.82983C1.99707 5.51983 2.55707 6.07983 3.24707 6.07983C3.93707 6.07983 4.49707 5.51983 4.49707 4.82983C4.49707 4.13983 3.93707 3.57983 3.24707 3.57983Z"
      fill="currentColor"
    />
    <path
      d="M3.24707 13.9198C2.55707 13.9198 1.99707 14.4798 1.99707 15.1698C1.99707 15.8598 2.55707 16.4198 3.24707 16.4198C3.93707 16.4198 4.49707 15.8598 4.49707 15.1698C4.49707 14.4798 3.93707 13.9198 3.24707 13.9198Z"
      fill="currentColor"
    />
    <path
      d="M3.24707 8.49975C2.55707 8.49975 1.99707 9.05975 1.99707 9.74975C1.99707 10.4398 2.55707 10.9998 3.24707 10.9998C3.93707 10.9998 4.49707 10.4398 4.49707 9.74975C4.49707 9.05975 3.93707 8.49975 3.24707 8.49975Z"
      fill="currentColor"
    />
  </svg>
);
