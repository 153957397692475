import { FeeDto, PaymentMethodOption, PaymentStatusEnum } from '@bondsports/types';
import { PaymentMethodTypeEnum } from '@bondsports/utils';
import { Stripe } from 'stripe';

export function convertToPaymentStatus(paymentStatus: PaymentStatusEnum, isVoided: boolean, isRefunded: boolean) {
	if (isRefunded) {
		return PaymentStatusEnum.REFUNDED;
	}

	if (isVoided) {
		return PaymentStatusEnum.VOID;
	}

	return paymentStatus;
}

/**
 * This function is used to find the fee associated with the selected Stripe payment method.
 *
 * @param {PaymentMethodOption[]} paymentMethods - An array of available payment methods.
 * @param {Stripe.PaymentMethod} selectedPaymentMethod - The payment method selected by the user.
 *
 * @returns {FeeDto | undefined} - Returns the fee associated with the selected payment method. If no fee is found, it
 *     returns undefined.
 */
export const findFeeSelectedStripePaymentMethod = (
	paymentMethods: PaymentMethodOption[],
	selectedPaymentMethod: Stripe.PaymentMethod
): FeeDto | undefined => {
	const relevantMethods = paymentMethods.filter(pm => pm.paymentMethodType === selectedPaymentMethod.type);
	if (selectedPaymentMethod.type !== PaymentMethodTypeEnum.CARD) return relevantMethods?.[0].fee;

	if (relevantMethods.length === 1) return relevantMethods?.[0]?.fee;

	return relevantMethods.find(pm => pm.subPaymentMethodType === selectedPaymentMethod?.card?.funding)?.fee;
};
