import React from 'react';

export const BillAccount = (props: any) => (
  <svg
    data-aid="svgBillAccount"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00016 3.91671C6.71284 3.91671 6.43729 4.03084 6.23413 4.23401C6.03097 4.43717 5.91683 4.71272 5.91683 5.00004V11.0834H18.0835V8.91297C18.056 8.91545 18.0283 8.91671 18.0002 8.91671H14.0002C13.4918 8.91671 13.0043 8.71477 12.6449 8.35533C12.2854 7.99589 12.0835 7.50837 12.0835 7.00004V3.91671H7.00016ZM19.9168 11.0834V8.00004C19.9168 7.75693 19.8203 7.52377 19.6483 7.35186L14.6483 2.35186C14.4764 2.17995 14.2433 2.08337 14.0002 2.08337H13.0002H7.00016C6.22661 2.08337 5.48475 2.39066 4.93777 2.93765C4.39079 3.48463 4.0835 4.22649 4.0835 5.00004V11.0834H3.00016C2.4939 11.0834 2.0835 11.4938 2.0835 12C2.0835 12.5063 2.4939 12.9167 3.00016 12.9167H5.00016H19.0002H21.0002C21.5064 12.9167 21.9168 12.5063 21.9168 12C21.9168 11.4938 21.5064 11.0834 21.0002 11.0834H19.9168ZM13.9168 7.00004V4.21307L16.7871 7.08337H14.0002C13.9781 7.08337 13.9569 7.0746 13.9412 7.05897C13.9256 7.04334 13.9168 7.02214 13.9168 7.00004ZM6.00016 15.0834C6.50642 15.0834 6.91683 15.4938 6.91683 16V18C6.91683 18.5063 6.50642 18.9167 6.00016 18.9167C5.4939 18.9167 5.0835 18.5063 5.0835 18V16C5.0835 15.4938 5.4939 15.0834 6.00016 15.0834ZM10.9168 16C10.9168 15.4938 10.5064 15.0834 10.0002 15.0834C9.4939 15.0834 9.0835 15.4938 9.0835 16V22C9.0835 22.5063 9.4939 22.9167 10.0002 22.9167C10.5064 22.9167 10.9168 22.5063 10.9168 22V16ZM14.0002 15.0834C14.5064 15.0834 14.9168 15.4938 14.9168 16V18C14.9168 18.5063 14.5064 18.9167 14.0002 18.9167C13.4939 18.9167 13.0835 18.5063 13.0835 18V16C13.0835 15.4938 13.4939 15.0834 14.0002 15.0834ZM18.9168 16C18.9168 15.4938 18.5064 15.0834 18.0002 15.0834C17.4939 15.0834 17.0835 15.4938 17.0835 16V20C17.0835 20.5063 17.4939 20.9167 18.0002 20.9167C18.5064 20.9167 18.9168 20.5063 18.9168 20V16Z"
      fill="currentColor"
    />
  </svg>
);
