import React from 'react';

export const Primary = () => (
	<svg data-aid="SvgPrimary" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.99999 9.16667C11.8383 9.16667 13.3333 7.67167 13.3333 5.83333C13.3333 3.995 11.8383 2.5 9.99999 2.5C8.16166 2.5 6.66666 3.995 6.66666 5.83333C6.66666 7.67167 8.16166 9.16667 9.99999 9.16667Z"
			fill="url(#paint0_linear_12790_7388)"
		/>
		<path
			d="M15 17.4999C15.4608 17.4999 15.8333 17.1266 15.8333 16.6666C15.8333 13.4499 13.2158 10.8332 9.99999 10.8332C6.78416 10.8332 4.16666 13.4499 4.16666 16.6666C4.16666 17.1266 4.53916 17.4999 4.99999 17.4999H15Z"
			fill="url(#paint1_linear_12790_7388)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_12790_7388"
				x1="15.8333"
				y1="2.5"
				x2="4.16666"
				y2="2.5"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#F7C500" />
				<stop offset="1" stop-color="#F7B500" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_12790_7388"
				x1="15.8333"
				y1="2.5"
				x2="4.16666"
				y2="2.5"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#F7C500" />
				<stop offset="1" stop-color="#F7B500" />
			</linearGradient>
		</defs>
	</svg>
);
