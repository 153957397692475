/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, PropsWithChildren } from 'react';
import { css, jsx } from '@emotion/react';
import { ETypography, ETypographyColor, IColors, Icons } from '../../types';
import { TGroup } from './types';
import { separatorCss } from '../../styles/utils';
import { StyledSelectOption } from '../Options_v2/style';
import { Typography } from '../../atoms';
import { BnIcon } from '../icons_v2';
import { capitalize } from '../../functions';
import { CustomCheckbox } from './CustomCheckbox';
import { SizeEnum } from '../../lib/constants';

type SelectAllOptionProps<T, U> = {
	theme: IColors;
	handleRemoveAll: () => void;
	handleSelectAll: () => void;
	selectAllLabel?: string;
	searchValue?: string;
	isChecked: boolean;
	isIndeterminate: boolean;
	children?: React.ReactNode;
};

const selectAllSeparatorCss = (colors: IColors) => css`
	${separatorCss('bottom', colors.bg_border_seperator!, 1)};
	margin: 0 10px;
`;

export const SelectAllOptions = <T, U>({
	theme,
	handleRemoveAll,
	handleSelectAll,
	selectAllLabel = '',
	searchValue,
	isChecked,
	isIndeterminate,
	children,
}: SelectAllOptionProps<T, U>) => {
	const handleOnChange = (): void => {
		if (isChecked || isIndeterminate) {
			handleRemoveAll?.();
			return;
		}

		handleSelectAll?.();
	};

	const isDisabled: boolean = Boolean(searchValue);

	return (
		<Fragment>
			<StyledSelectOption theme={theme} size={SizeEnum.SMALL} onClick={() => {}} tabIndex={0} autoFocus={false}>
				<Typography
					color={isDisabled ? ETypographyColor.disabled : ETypographyColor.secondary}
					type={ETypography.body2}
				>
					<span>
						<CustomCheckbox
							indeterminateIcon={
								<BnIcon
									icon={Icons.minus}
									color={isDisabled ? theme.bg_text_disabled : theme.tx_text_primary}
									style={{ margin: 0 }}
								/>
							}
							checked={isChecked}
							onChange={handleOnChange}
							isIndeterminate={isIndeterminate}
							colors={theme}
							disabled={isDisabled}
						/>
						{children ? children : capitalize(selectAllLabel!)}
					</span>
				</Typography>
			</StyledSelectOption>
			<span css={selectAllSeparatorCss(theme)} />
		</Fragment>
	);
};
