import * as  React from 'react';

export const BnFilters = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.48746 5.83333H2.49996C2.03913 5.83333 1.66663 5.46083 1.66663 5C1.66663 4.53917 2.03913 4.16667 2.49996 4.16667H3.48746C3.83246 3.19917 4.74829 2.5 5.83329 2.5C6.91829 2.5 7.83413 3.19917 8.17913 4.16667H17.5C17.9608 4.16667 18.3333 4.53917 18.3333 5C18.3333 5.46083 17.9608 5.83333 17.5 5.83333H8.17913C7.83413 6.80083 6.91829 7.5 5.83329 7.5C4.74829 7.5 3.83246 6.80083 3.48746 5.83333ZM5.83329 4.16667C6.29329 4.16667 6.66663 4.54 6.66663 5C6.66663 5.46 6.29329 5.83333 5.83329 5.83333C5.37329 5.83333 4.99996 5.46 4.99996 5C4.99996 4.54 5.37329 4.16667 5.83329 4.16667ZM13.4875 9.16667C13.8325 8.19917 14.7483 7.5 15.8333 7.5C17.2116 7.5 18.3333 8.62167 18.3333 10C18.3333 11.3783 17.2116 12.5 15.8333 12.5C14.7483 12.5 13.8325 11.8008 13.4875 10.8333H2.49996C2.03913 10.8333 1.66663 10.4608 1.66663 10C1.66663 9.53917 2.03913 9.16667 2.49996 9.16667H13.4875ZM15.8333 10.8333C15.3733 10.8333 15 10.46 15 10C15 9.54 15.3733 9.16667 15.8333 9.16667C16.2933 9.16667 16.6666 9.54 16.6666 10C16.6666 10.46 16.2933 10.8333 15.8333 10.8333ZM11.5125 14.1667H17.5C17.9608 14.1667 18.3333 14.5392 18.3333 15C18.3333 15.4608 17.9608 15.8333 17.5 15.8333H11.5125C11.1675 16.8008 10.2516 17.5 9.16663 17.5C8.08163 17.5 7.16579 16.8008 6.82079 15.8333H2.49996C2.03913 15.8333 1.66663 15.4608 1.66663 15C1.66663 14.5392 2.03913 14.1667 2.49996 14.1667H6.82079C7.16579 13.1992 8.08163 12.5 9.16663 12.5C10.2516 12.5 11.1675 13.1992 11.5125 14.1667ZM8.33329 15C8.33329 15.46 8.70663 15.8333 9.16663 15.8333C9.62663 15.8333 9.99996 15.46 9.99996 15C9.99996 14.54 9.62663 14.1667 9.16663 14.1667C8.70663 14.1667 8.33329 14.54 8.33329 15Z"
      fill="currentColor"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="2"
      width="18"
      height="16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.48746 5.83333H2.49996C2.03913 5.83333 1.66663 5.46083 1.66663 5C1.66663 4.53917 2.03913 4.16667 2.49996 4.16667H3.48746C3.83246 3.19917 4.74829 2.5 5.83329 2.5C6.91829 2.5 7.83413 3.19917 8.17913 4.16667H17.5C17.9608 4.16667 18.3333 4.53917 18.3333 5C18.3333 5.46083 17.9608 5.83333 17.5 5.83333H8.17913C7.83413 6.80083 6.91829 7.5 5.83329 7.5C4.74829 7.5 3.83246 6.80083 3.48746 5.83333ZM5.83329 4.16667C6.29329 4.16667 6.66663 4.54 6.66663 5C6.66663 5.46 6.29329 5.83333 5.83329 5.83333C5.37329 5.83333 4.99996 5.46 4.99996 5C4.99996 4.54 5.37329 4.16667 5.83329 4.16667ZM13.4875 9.16667C13.8325 8.19917 14.7483 7.5 15.8333 7.5C17.2116 7.5 18.3333 8.62167 18.3333 10C18.3333 11.3783 17.2116 12.5 15.8333 12.5C14.7483 12.5 13.8325 11.8008 13.4875 10.8333H2.49996C2.03913 10.8333 1.66663 10.4608 1.66663 10C1.66663 9.53917 2.03913 9.16667 2.49996 9.16667H13.4875ZM15.8333 10.8333C15.3733 10.8333 15 10.46 15 10C15 9.54 15.3733 9.16667 15.8333 9.16667C16.2933 9.16667 16.6666 9.54 16.6666 10C16.6666 10.46 16.2933 10.8333 15.8333 10.8333ZM11.5125 14.1667H17.5C17.9608 14.1667 18.3333 14.5392 18.3333 15C18.3333 15.4608 17.9608 15.8333 17.5 15.8333H11.5125C11.1675 16.8008 10.2516 17.5 9.16663 17.5C8.08163 17.5 7.16579 16.8008 6.82079 15.8333H2.49996C2.03913 15.8333 1.66663 15.4608 1.66663 15C1.66663 14.5392 2.03913 14.1667 2.49996 14.1667H6.82079C7.16579 13.1992 8.08163 12.5 9.16663 12.5C10.2516 12.5 11.1675 13.1992 11.5125 14.1667ZM8.33329 15C8.33329 15.46 8.70663 15.8333 9.16663 15.8333C9.62663 15.8333 9.99996 15.46 9.99996 15C9.99996 14.54 9.62663 14.1667 9.16663 14.1667C8.70663 14.1667 8.33329 14.54 8.33329 15Z"
        fill="currentColor"
      />
    </mask>
    <g mask="url(#mask0)"></g>
  </svg>
);
