import dayjs from 'dayjs';

export const getMonthName = (month: number): string => {
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	return months[month - 1];
};

export const getDateStringWithYear = (date: Date): string =>
	`${getMonthName(date.getMonth() + 1)} ${date.getDate()}, ${date.getFullYear()}`;

const padTo2Digits = (n: number) => (n > 9 ? String(n) : `0${n}`);

const time24ToAmPm = (hour: number, minutes: number): string => {
	const amOrPm = hour >= 12 ? 'PM' : 'AM';

	if (hour > 12) {
		hour = hour - 12;
	}
	if (hour === 0) {
		hour = 12;
	}

	return `${hour}:${padTo2Digits(minutes)}${amOrPm}`;
};

export const getTime24ToAmPmDisplay = (time: string): string => {
	const [hour, minutes] = time.split(':');
	return time24ToAmPm(Number(hour), Number(minutes));
};

export const getHours = (startTime: string, endTime: string) =>
	dayjs(startTime).format('hh:mm a') + ' - ' + dayjs(endTime).format('hh:mm a');

export const getTimeRangeDisplay = (startTime: string, endTime: string) =>
	`${getTime24ToAmPmDisplay(startTime)} - ${getTime24ToAmPmDisplay(endTime)}`;

export const apiDateStringToDate = (date: string): Date => {
	if (date.endsWith('Z')) {
		return dayjs(date.slice(0, -1)).toDate();
	}

	return date.includes('T') ? dayjs(date).toDate() : dayjs(`${date}T23:59:59`).toDate();
};

export const getDateString = (date: Date): string => `${getMonthName(date.getMonth() + 1)} ${date.getDate()}`;

export const getMonthDayRange = (startDate: string, endDate?: string) => {
	const start = apiDateStringToDate(startDate);
	if (!endDate) {
		return getDateString(start);
	}
	const end = apiDateStringToDate(endDate || '');
	const startString = `${getDateString(start)}`;
	const endString = start.getMonth() === end.getMonth() ? end.getDate() : `${getDateString(end)}`;
	const endStringYear = end.getFullYear();

	return `${startString} - ${endString}, ${endStringYear}`;
};

export enum EDateFormats {
	YYYY_MM_DD = 'YYYY-MM-DD', // ISO 8601 format, the standard format acrooss the BO.
	SLASHED_DATE = 'YYYY/MM/DD',
	DAY_FORMAT = 'MMM DD, YYYY',
	DAY_OF_WEEK_FORMAT = 'ddd',
	DAY_FORMAT_WITH_DAY_OF_WEEK = 'ddd, MMM DD, YYYY',
	MMMM_YYYY = 'MMMM YYYY',
	MMM_DD = 'MMM DD',
	ddd_MMM_DD = 'ddd, MMM DD',
	MM_DD_YY_slashed = 'MM/DD/YY',
	MMM_d_yyyy = 'MMM d yyyy',
	MMM_D_YYYY = 'MMM D, YYYY',
	FULL_MONTH = 'MMMM',
}

export enum EHoursFormat {
	H24_WITH_SECONDS = 'HH:mm:ss', // 14:24:19
	H24_WITH_MINUTES = 'HH:mm', // 14:24
	H12_WITH_MINUTES = 'hh:mm', // 02:24
	H12_ampm = 'hh:mm a', // 02:24 pm
	H12_AMPM_UPPERCASE = 'hh:mm A', //02:24 PM
}

export enum DurationUnitTypesEnum {
	MINUTES = 'minutes',
	HOURS = 'hours',
}
