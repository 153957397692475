import { DateTimeFormats, formatDateTime } from '@bondsports/date-time';
import { AnswerDto } from '@bondsports/types';
import { RCCustomQuestionType, RCQuestionTypesEnum } from '../../types/entities/coreEntites/Questions';
import { getAddressString } from './address';

const getAnswerText = (answer: AnswerDto, metadata?: any) => {
	const value = answer.answerValue?.value ?? answer.answerValue;

	if (Array.isArray(value)) {
		return value.map(v => getAnswerText({ ...answer, answerValue: v }, metadata)).join(', ');
	}

	if (answer?.questionType === RCQuestionTypesEnum.BIRTH_DATE || metadata?.customType === RCCustomQuestionType.DATE) {
		return formatDateTime(value, DateTimeFormats.DAY_FORMAT);
	}

	if (answer?.questionType === RCQuestionTypesEnum.ADDRESS || answer?.answerValue?.city) {
		return getAddressString(answer.answerValue);
	}

	return value;
};

export { getAnswerText };
