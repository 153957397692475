import React from 'react';
import { BnRemove } from './BnRemove';
import { BnDollar } from './BnDollar';
import { BnInfo } from './BnInfo';
import { BnArrowDown } from './BnArrowDown';
import { BnArrowUp } from './BnArrowUp';
import { BnClose } from './BnClose';
import { BnWhiteCheck } from './BnWhiteCheck';
import { BnBack } from './BnBack';
import { BnCalendar } from './BnCalendar';
import { BnForward } from './BnForward';
import { BnCourt } from './BnCourt';
import { BnActivity } from './BnActivity';
import { BnTag } from './BnTag';
import { BnProfiles } from './BnProfiles';
import { BnEditOutline } from './BnEditOutline';
import { BnArrowLeft } from './BnArrowLeft';
import { BnCash } from './BnCash';
import { BnTickets } from './BnTickets';
import { BnReports } from './BnReports';
import { BnClock } from './BnClock';
import { BnSettingsOutline } from './BnSettingsOutline';
import { BnBell } from './BnBell';
import { BnMore } from './BnMore';
import { BnCollapse } from './BnCollapse';
import { BnSupport } from './BnSupport';
import { Logo } from './Logo';
import { BnRightAction } from './BnRightAction';
import { BnPlus } from './BnPlus';
import { BnSquareAndPlus } from './BnSquareAndPlus';
import { BnEllipseMid } from './BnEllipseMid';
import { BnExcel } from './BnExcel';
import { BnCreditCard } from './BnCreditCard';
import { SmallLogo } from './SmallLogo';
import { BnLocation } from './BnLocation';
import { BnWhistle } from './BnWhistle';
import { BnMountain } from './BnMountain';
import { BnProfile } from './BnProfile';
import { BnBackpack } from './BnBackpack';
import { BnSearch } from './BnSearch';
import { BnNoteFilled } from './BnNoteFilled';
import { BnConflict } from './BnConflict';
import { BnBoxedCard } from './BnBoxedCard';
import { BnView } from './BnView';
import { BnTrash } from './BnTrash';
import { BnMinus } from './BnMinus';
import { BnExpandAll } from './BnExpandAll';
import { BnFilters } from './BnFilters';
import { BsTennis } from './sports/BsTennis';
import { BsVollyball } from './sports/BsVollyball';
import { BnVertical } from './BnVertical';
import { BnHorizontal } from './BnHorizontal';
import { BnList } from './BnList';
import { BnDrag } from './BnDrag';
import { BnWhistleOutline } from './BnWhistleOutline';
import styled from '@emotion/styled';
import { BnPinned } from './BnPinned';
import { BnWarning } from './BnWarning';

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 4px;
	padding-left: 4px;
`;

function IconnifyNew(icon: string, props: any) {
	switch (icon) {
		case 'warning':
			return <BnWarning {...props} />;
		case 'pinned':
			return <BnPinned {...props} />;
		case 'remove':
			return <BnRemove {...props} />;
		case 'minus':
			return <BnMinus {...props} />;
		case 'profile_filled':
			return <BnProfile {...props} />;
		case 'trash':
			return <BnTrash {...props} />;
		case 'dollar':
			return <BnDollar {...props} />;
		case 'info':
			return <BnInfo {...props} />;
		case 'arrow_down':
			return <BnArrowDown {...props} />;
		case 'edit_outline':
			return <BnEditOutline {...props} />;
		case 'credit_card':
			return <BnCreditCard {...props} />;
		case 'view':
			return <BnView {...props} />;
		case 'more':
			return <BnMore {...props} />;
		case 'search':
			return <BnSearch {...props} />;
		case 'arrow_up':
			return <BnArrowUp {...props} />;
		case 'note_filled':
			return <BnNoteFilled {...props} />;
		case 'conflict':
			return <BnConflict {...props} />;
		case 'close':
			return <BnClose {...props} />;
		case 'whistle':
			return <BnWhistle {...props} />;
		case 'whitecheck':
			return <BnWhiteCheck {...props} />;
		case 'arrow_left':
			return <BnArrowLeft {...props} />;
		case 'mountain':
			return <BnMountain {...props} />;
		case 'back':
			return <BnBack {...props} />;
		case 'forward':
			return <BnForward {...props} />;
		case 'calendar':
			return <BnCalendar {...props} />;
		case 'court':
			return <BnCourt {...props} />;
		case 'backpack':
			return <BnBackpack {...props} />;
		case 'activity':
			return <BnActivity {...props} />;
		case 'location':
			return <BnLocation {...props} />;
		case 'tag':
			return <BnTag {...props} />;
		case 'profiles':
			return <BnProfiles {...props} />;
		case 'tickets':
			return <BnTickets {...props} />;
		case 'cash':
			return <BnCash {...props} />;
		case 'reports':
			return <BnReports {...props} />;
		case 'clock':
			return <BnClock {...props} />;
		case 'settings_outline':
			return <BnSettingsOutline {...props} />;
		case 'bell':
			return <BnBell {...props} />;
		case 'collapse':
			return <BnCollapse {...props} />;
		case 'support':
			return <BnSupport {...props} />;
		case 'plus':
			return <BnPlus {...props} />;
		case 'right-action':
			return <BnRightAction {...props} />;
		case 'ellipse_mid':
			return <BnEllipseMid {...props} />;
		case 'excel':
			return <BnExcel {...props} />;
		case 'squareAndPlus':
			return <BnSquareAndPlus {...props} />;
		case 'boxed_card':
			return <BnBoxedCard {...props} />;
		case 'sport_tennis':
			return <BsTennis {...props} />;
		case 'sport_vollyball':
			return <BsVollyball {...props} />;
		case 'filters':
			return <BnFilters {...props} />;
		case 'expand_all':
			return <BnExpandAll {...props} />;
		case 'vertical':
			return <BnVertical {...props} />;
		case 'horizontal':
			return <BnHorizontal {...props} />;
		case 'list':
			return <BnList {...props} />;
		case 'drag':
			return <BnDrag {...props} />;
		case 'whistle_outline':
			return <BnWhistleOutline {...props} />;
		default:
			return <></>;
	}
}

export const BnIcon = ({ icon, ...props }: { icon: string; props: any }) => {
	return IconnifyNew(icon, props);
};

export const getIcon = (name: string, paddingTo?: string) => {
	function Iconnify() {
		switch (name) {
			case 'remove':
				return <BnRemove />;
			case 'minus':
				return <BnMinus />;
			case 'profile_filled':
				return <BnProfile />;
			case 'trash':
				return <BnTrash />;
			case 'dollar':
				return <BnDollar />;
			case 'info':
				return <BnInfo />;
			case 'arrow_down':
				return <BnArrowDown />;
			case 'edit_outline':
				return <BnEditOutline />;
			case 'credit_card':
				return <BnCreditCard />;
			case 'view':
				return <BnView />;
			case 'more':
				return <BnMore />;
			case 'search':
				return <BnSearch />;
			case 'arrow_up':
				return <BnArrowUp />;
			case 'note_filled':
				return <BnNoteFilled />;
			case 'conflict':
				return <BnConflict />;
			case 'close':
				return <BnClose />;
			case 'whistle':
				return <BnWhistle />;
			case 'whitecheck':
				return <BnWhiteCheck />;
			case 'arrow_left':
				return <BnArrowLeft />;
			case 'mountain':
				return <BnMountain />;
			case 'back':
				return <BnBack />;
			case 'forward':
				return <BnForward />;
			case 'calendar':
				return <BnCalendar />;
			case 'court':
				return <BnCourt />;
			case 'backpack':
				return <BnBackpack />;
			case 'activity':
				return <BnActivity />;
			case 'location':
				return <BnLocation />;
			case 'tag':
				return <BnTag />;
			case 'profiles':
				return <BnProfiles />;
			case 'tickets':
				return <BnTickets />;
			case 'cash':
				return <BnCash />;
			case 'reports':
				return <BnReports />;
			case 'clock':
				return <BnClock />;
			case 'settings_outline':
				return <BnSettingsOutline />;
			case 'bell':
				return <BnBell />;
			case 'collapse':
				return <BnCollapse />;
			case 'support':
				return <BnSupport />;
			case 'plus':
				return <BnPlus />;
			case 'right-action':
				return <BnRightAction />;
			case 'ellipse_mid':
				return <BnEllipseMid />;
			case 'excel':
				return <BnExcel />;
			case 'squareAndPlus':
				return <BnSquareAndPlus />;
			case 'boxed_card':
				return <BnBoxedCard />;
			case 'sport_tennis':
				return <BsTennis />;
			case 'sport_vollyball':
				return <BsVollyball />;
			case 'filters':
				return <BnFilters />;
			case 'expand_all':
				return <BnExpandAll />;
			case 'vertical':
				return <BnVertical />;
			case 'horizontal':
				return <BnHorizontal />;
			case 'list':
				return <BnList />;
			case 'drag':
				return <BnDrag />;
			case 'whistle_outline':
				return <BnWhistleOutline />;
			default:
				return <></>;
		}
	}
	return (
		<IconContainer
			className="icon_container"
			style={{
				paddingRight: paddingTo === 'r' ? '4px' : '0',
				paddingLeft: paddingTo === 'l' ? '4px' : '0',
			}}
		>
			{Iconnify()}
		</IconContainer>
	);
};

export {
	BnRemove,
	BnDollar,
	BnInfo,
	BnBackpack,
	BnArrowDown,
	BnTag,
	BnArrowUp,
	BnClose,
	BnActivity,
	BnMinus,
	BnProfiles,
	BnArrowLeft,
	BnBack,
	BnWhiteCheck,
	BnWhistle,
	BnMountain,
	BnBoxedCard,
	BnCreditCard,
	BnForward,
	BnEditOutline,
	BnLocation,
	BnSquareAndPlus,
	BnCourt,
	BnSettingsOutline,
	BnMore,
	BnNoteFilled,
	BnReports,
	BnProfile,
	BnCalendar,
	BnView,
	BnCash,
	BnTickets,
	Logo,
	SmallLogo,
	BnSearch,
	BnExcel,
	BnPlus,
	BnSupport,
	BnCollapse,
	BnTrash,
	BnConflict,
	BnRightAction,
};
