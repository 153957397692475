import { RCAddress } from './address';
import { Product } from './product';
import { User } from './user';

export interface BlockDated {
	id: number;
	createdAt: string;
	updatedAt: string;
	entityType: string;
	entityId: number;
	name: string;
	startDate: string;
	endDate: string;
}

export interface ActivityTime {
	id: number;
	createdAt: string;
	updatedAt: string;
	parentType: string;
	parentId: number;
	dayOfWeek: string;
	open: string;
	close: string;
}

export interface Season {
	id: number;
	programId: number;
	name: string;
	description?: string;
	longDescription?: string;
	startDate: string;
	endDate: string;
	registrationStartDate?: string;
	registrationEndDate?: string;
	questionnaires: number[] | null;
	seasonType?: string;
	parentSeasonId?: string;
	maxParticipants?: string;
	maxMaleParticipants?: string;
	maxFemaleParticipants?: string;
	maxWaitlist?: string;
	maxMaleWaitlist?: string;
	maxFemaleWaitlist: string;
	organizationId: number;
	facilityId?: string;
	facilityName?: string;
	addressId?: string;
	address: RCAddress;
	sport?: number;
	minAge?: string;
	maxAge?: string;
	gender?: string;
	level?: string[];
	blockedDated?: BlockDated[];
	activityTimes?: ActivityTime[];
	products?: Product[];
	mainMedia?: {
		url: string;
	};
	ageCalcDate?: string;
}

export interface SeasonAttendeeInfo {
	user: User;
	paymentAmount: number;
	registrationDate: Date;
}

export enum ProgramSeasonTypesEnum {
	ROUND_ROBIN = 1,
	BRACKETS = 2,
	CAMP_CLINIC_CLASS = 3,
}

export enum PublishingStatusEnum {
	DRAFT = 1,
	PUBLISHED = 2,
	CLOSED = 3,
	CANCELLED = 4,
	ARCHIVE = 5,
	UNPUBLISHED = 6,
}
