/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, ReactNode, useContext } from 'react';
import { css, jsx } from '@emotion/react';
import { PaymentContext } from '../context';
import { ETypography, ETypographyColor, IColors } from '../../../types';
import { Avatar, Typography } from '../../../atoms';
import { useColors } from '../../../hooks/useColors';
import dayjs from 'dayjs';
import { Pricify } from '../../../lib/price';
import { SectionContainer } from '../../../molecules/invoicing/sectionContainer';
import { SkeletonWrapper } from '../../../components/skeletonWrapper';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { mobileOnly, printFont } from '../../../styles/utils';

const borderCss = (colors: IColors) => css`
	border: 1px solid ${colors.bg_border_seperator};
	border-radius: 5px;
	display: flex;
	padding: 20px 16px;
	justify-content: space-between;
`;

const leftSideCss = css`
	display: flex;
	gap: 4rem;
`;

const datesContainerCss = css`
	display: flex;
	gap: 4px;
	flex-direction: column;
`;

const customerCss = css`
	display: flex;
	gap: 8px;
`;

const moreInfoCss = css`
	${customerCss};
	${mobileOnly} {
		flex-direction: column;
	}
`;

const priceCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 2rem;
`;

const printRowCss = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-bottom: black 1px dashed;
	padding: 1rem 0 1rem 0;
`;

const printContainerCss = css`
	@media print and (max-width: 10cm) {
		display: flex;
		${printFont}
	}
	display: none;
	width: 100%;
	flex-direction: column;
`;

const bottomBorderCss = css`
	border-bottom: black 1px dashed;
`;

const notMobileCss = css`
	@media print and (max-width: 10cm) {
		display: none;
	}
`;

export const BillingInfo = () => {
	const { isMobile } = useWindowSize();
	const { state, labels, isLoading } = useContext(PaymentContext);
	const { colors } = useColors();
	const customerName = `${state.payingUser?.firstName || ''} ${state.payingUser?.lastName || ''}`;

	const primaryColor = isMobile ? ETypographyColor.black : ETypographyColor.primary;
	const secondaryColor = isMobile ? ETypographyColor.black : ETypographyColor.secondary;

	return (
		<Fragment>
			<div css={printContainerCss} data-aid="index-billingInfo">
				{dayjs(state?.createdAt).isValid() && (
					<div>
						<div css={[bottomBorderCss, printRowCss]}>
							<Typography color={primaryColor} type={ETypography.caption}>
								{dayjs(state?.createdAt).format('M/D/YYYY')}
							</Typography>
							<Typography color={primaryColor} type={ETypography.caption}>
								{dayjs(state?.createdAt).format('hh:mmA')}
							</Typography>
						</div>
						<div css={[bottomBorderCss, printRowCss]}>
							<Typography color={secondaryColor} type={ETypography.caption}>
								{customerName}
							</Typography>
						</div>
					</div>
				)}
			</div>
			<div css={notMobileCss}>
				<SectionContainer title={labels.billingInfo.title}>
					<div css={borderCss(colors)}>
						<div css={leftSideCss}>
							<SubContainer title={labels.billingInfo.purchaseDate} color={primaryColor}>
								{dayjs(state?.createdAt).isValid() && (
									<div css={datesContainerCss}>
										<SkeletonWrapper isLoading={isLoading} minHeight={10}>
											<Typography color={primaryColor} type={ETypography.body2}>
												{dayjs(state?.createdAt).format('MMM DD, YYYY')}
											</Typography>
										</SkeletonWrapper>
										<SkeletonWrapper isLoading={isLoading} minHeight={10}>
											<Typography color={secondaryColor} type={ETypography.caption}>
												{dayjs(state?.createdAt).format('hh:mmA')}
											</Typography>
										</SkeletonWrapper>
									</div>
								)}
							</SubContainer>
							<SubContainer title={labels.billingInfo.customer} color={primaryColor}>
								<div css={customerCss}>
									<Avatar img={state?.customer?.profilePicture} imgStyle={{ height: '36px', width: '36px' }} />
									<div css={datesContainerCss}>
										<SkeletonWrapper isLoading={isLoading} minHeight={10}>
											<Typography color={primaryColor} type={ETypography.body2}>
												{customerName}
											</Typography>
										</SkeletonWrapper>
										<div css={moreInfoCss}>
											<SkeletonWrapper isLoading={isLoading} minHeight={10}>
												<Typography color={secondaryColor} type={ETypography.caption}>
													{state?.customerContactLine}
												</Typography>
											</SkeletonWrapper>
										</div>
									</div>
								</div>
							</SubContainer>
						</div>
						{!isMobile && (
							<div css={priceCss}>
								<SkeletonWrapper isLoading={isLoading} minHeight={10}>
									<Typography type={ETypography.body1Accented} color={primaryColor}>
										{Pricify(state?.price)}
									</Typography>
								</SkeletonWrapper>
							</div>
						)}
					</div>
				</SectionContainer>
			</div>
		</Fragment>
	);
};

const subContainerCss = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
	min-width: 100px;
`;

const SubContainer = ({ title, children, color }: { title: string; children: ReactNode; color: ETypographyColor }) => {
	return (
		<div css={subContainerCss}>
			<Typography type={ETypography.captionAccented} color={color}>
				{title}
			</Typography>
			{children}
		</div>
	);
};
