(function (global, factory) {
	typeof exports === 'object' && typeof module !== 'undefined'
		? factory(exports)
		: typeof define === 'function' && define.amd
		? define(['exports'], factory)
		: factory((global['@rcenter/core'] = global['@rcenter/core'] || {}));
})(this, function (exports) {
	'use strict';

	var EventStatus = {};
	EventStatus.OPEN = 1;
	EventStatus.DRAFT = 2;
	EventStatus.FULL = 3;
	EventStatus.CANCELLED = 4;
	EventStatus.CLOSED = 5;
	EventStatus.DELETED = 6;
	EventStatus[EventStatus.OPEN] = 'OPEN';
	EventStatus[EventStatus.DRAFT] = 'DRAFT';
	EventStatus[EventStatus.FULL] = 'FULL';
	EventStatus[EventStatus.CANCELLED] = 'CANCELLED';
	EventStatus[EventStatus.CLOSED] = 'CLOSED';
	EventStatus[EventStatus.DELETED] = 'DELETED';
	var RCEventAttendeeStatus = {};
	RCEventAttendeeStatus.PENDING = 1;
	RCEventAttendeeStatus.ACCEPTED = 2;
	RCEventAttendeeStatus.DECLINED = 3;
	RCEventAttendeeStatus.REJECTED = 4;
	RCEventAttendeeStatus[RCEventAttendeeStatus.PENDING] = 'PENDING';
	RCEventAttendeeStatus[RCEventAttendeeStatus.ACCEPTED] = 'ACCEPTED';
	RCEventAttendeeStatus[RCEventAttendeeStatus.DECLINED] = 'DECLINED';
	RCEventAttendeeStatus[RCEventAttendeeStatus.REJECTED] = 'REJECTED';
	var RCEventType = {};
	RCEventType.PICK_UP = 2;
	RCEventType.LEAGUE_MATCH = 3;
	RCEventType.FRIENDLY_MATCH = 4;
	RCEventType.PRACTICE = 5;
	RCEventType.GENERAL = 6;
	RCEventType[RCEventType.PICK_UP] = 'PICK_UP';
	RCEventType[RCEventType.LEAGUE_MATCH] = 'LEAGUE_MATCH';
	RCEventType[RCEventType.FRIENDLY_MATCH] = 'FRIENDLY_MATCH';
	RCEventType[RCEventType.PRACTICE] = 'PRACTICE';
	RCEventType[RCEventType.GENERAL] = 'GENERAL';
	var RCEventJoinEnum = {};
	RCEventJoinEnum.CAN_JOIN = 0;
	RCEventJoinEnum.CANNOT_ALREADY_ATTENDEE = 1;
	RCEventJoinEnum.CANNOT_JOIN_FULL = 2;
	RCEventJoinEnum.CANNOT_JOIN_INACTIVE = 3;
	RCEventJoinEnum.CANNOT_JOIN_NO_NEW = 4;
	RCEventJoinEnum[RCEventJoinEnum.CAN_JOIN] = 'CAN_JOIN';
	RCEventJoinEnum[RCEventJoinEnum.CANNOT_ALREADY_ATTENDEE] = 'CANNOT_ALREADY_ATTENDEE';
	RCEventJoinEnum[RCEventJoinEnum.CANNOT_JOIN_FULL] = 'CANNOT_JOIN_FULL';
	RCEventJoinEnum[RCEventJoinEnum.CANNOT_JOIN_INACTIVE] = 'CANNOT_JOIN_INACTIVE';
	RCEventJoinEnum[RCEventJoinEnum.CANNOT_JOIN_NO_NEW] = 'CANNOT_JOIN_NO_NEW';

	var RCGenderEnum = {};
	RCGenderEnum.OTHER = 1;
	RCGenderEnum.MALE = 2;
	RCGenderEnum.FEMALE = 3;
	RCGenderEnum[RCGenderEnum.OTHER] = 'OTHER';
	RCGenderEnum[RCGenderEnum.MALE] = 'MALE';
	RCGenderEnum[RCGenderEnum.FEMALE] = 'FEMALE';

	var RCMembershipCanJoin = {};
	RCMembershipCanJoin.CAN_JOIN = 0;
	RCMembershipCanJoin.CANNOT_ALREADY_MEMBER = 1;
	RCMembershipCanJoin[RCMembershipCanJoin.CAN_JOIN] = 'CAN_JOIN';
	RCMembershipCanJoin[RCMembershipCanJoin.CANNOT_ALREADY_MEMBER] = 'CANNOT_ALREADY_MEMBER';

	var RCLinkedAccountStatus = {};
	RCLinkedAccountStatus.NOT_ACTIVE = 0;
	RCLinkedAccountStatus.PENDING = 1;
	RCLinkedAccountStatus.ACTIVE = 2;
	RCLinkedAccountStatus[RCLinkedAccountStatus.NOT_ACTIVE] = 'NOT_ACTIVE';
	RCLinkedAccountStatus[RCLinkedAccountStatus.PENDING] = 'PENDING';
	RCLinkedAccountStatus[RCLinkedAccountStatus.ACTIVE] = 'ACTIVE';
	var RCOfflinePaymentSetting = {};
	RCOfflinePaymentSetting.DISABLED = 1;
	RCOfflinePaymentSetting.ALLOW_CASH = 2;
	RCOfflinePaymentSetting.REDIRECT = 3;
	RCOfflinePaymentSetting.INFO_ONLY = 4;
	RCOfflinePaymentSetting[RCOfflinePaymentSetting.DISABLED] = 'DISABLED';
	RCOfflinePaymentSetting[RCOfflinePaymentSetting.ALLOW_CASH] = 'ALLOW_CASH';
	RCOfflinePaymentSetting[RCOfflinePaymentSetting.REDIRECT] = 'REDIRECT';
	RCOfflinePaymentSetting[RCOfflinePaymentSetting.INFO_ONLY] = 'INFO_ONLY';
	var RCPaymentSettingStatus = {};
	RCPaymentSettingStatus.ENABLED = 1;
	RCPaymentSettingStatus.DISABLED_REDIRECT = 2;
	RCPaymentSettingStatus.DISABLED_INFO_ONLY = 3;
	RCPaymentSettingStatus.DISABLED_EMAIL = 4;
	RCPaymentSettingStatus[RCPaymentSettingStatus.ENABLED] = 'ENABLED';
	RCPaymentSettingStatus[RCPaymentSettingStatus.DISABLED_REDIRECT] = 'DISABLED_REDIRECT';
	RCPaymentSettingStatus[RCPaymentSettingStatus.DISABLED_INFO_ONLY] = 'DISABLED_INFO_ONLY';
	RCPaymentSettingStatus[RCPaymentSettingStatus.DISABLED_EMAIL] = 'DISABLED_EMAIL';

	var RCLeagueDetailTypeEnum = {};
	RCLeagueDetailTypeEnum.OTHER = 1;
	RCLeagueDetailTypeEnum.MINAGE = 2;
	RCLeagueDetailTypeEnum.MAXAGE = 3;
	RCLeagueDetailTypeEnum.GENDER = 4;
	RCLeagueDetailTypeEnum.LEVELOFPLAY = 5;
	RCLeagueDetailTypeEnum.GAMESSEASON = 6;
	RCLeagueDetailTypeEnum.MINWEEK = 7;
	RCLeagueDetailTypeEnum.SURFACE = 8;
	RCLeagueDetailTypeEnum.FORMAT = 9;
	RCLeagueDetailTypeEnum.PLAYERSPERTEAM = 10;
	RCLeagueDetailTypeEnum.MATCHLENGTH = 12;
	RCLeagueDetailTypeEnum[RCLeagueDetailTypeEnum.OTHER] = 'OTHER';
	RCLeagueDetailTypeEnum[RCLeagueDetailTypeEnum.MINAGE] = 'MINAGE';
	RCLeagueDetailTypeEnum[RCLeagueDetailTypeEnum.MAXAGE] = 'MAXAGE';
	RCLeagueDetailTypeEnum[RCLeagueDetailTypeEnum.GENDER] = 'GENDER';
	RCLeagueDetailTypeEnum[RCLeagueDetailTypeEnum.LEVELOFPLAY] = 'LEVELOFPLAY';
	RCLeagueDetailTypeEnum[RCLeagueDetailTypeEnum.GAMESSEASON] = 'GAMESSEASON';
	RCLeagueDetailTypeEnum[RCLeagueDetailTypeEnum.MINWEEK] = 'MINWEEK';
	RCLeagueDetailTypeEnum[RCLeagueDetailTypeEnum.SURFACE] = 'SURFACE';
	RCLeagueDetailTypeEnum[RCLeagueDetailTypeEnum.FORMAT] = 'FORMAT';
	RCLeagueDetailTypeEnum[RCLeagueDetailTypeEnum.PLAYERSPERTEAM] = 'PLAYERSPERTEAM';
	RCLeagueDetailTypeEnum[RCLeagueDetailTypeEnum.MATCHLENGTH] = 'MATCHLENGTH';
	var RCLeagueSeasonStatusEnum = {};
	RCLeagueSeasonStatusEnum.UNPUBLISHED = 1;
	RCLeagueSeasonStatusEnum.PUBLISHED = 2;
	RCLeagueSeasonStatusEnum.CLOSED = 3;
	RCLeagueSeasonStatusEnum.CANCELLED = 4;
	RCLeagueSeasonStatusEnum[RCLeagueSeasonStatusEnum.UNPUBLISHED] = 'UNPUBLISHED';
	RCLeagueSeasonStatusEnum[RCLeagueSeasonStatusEnum.PUBLISHED] = 'PUBLISHED';
	RCLeagueSeasonStatusEnum[RCLeagueSeasonStatusEnum.CLOSED] = 'CLOSED';
	RCLeagueSeasonStatusEnum[RCLeagueSeasonStatusEnum.CANCELLED] = 'CANCELLED';
	var RCLeagueSeasonRegistrationStatusEnum = {};
	RCLeagueSeasonRegistrationStatusEnum.UNAVAILABLE = 1;
	RCLeagueSeasonRegistrationStatusEnum.OPEN = 2;
	RCLeagueSeasonRegistrationStatusEnum.FULLYBOOKED = 3;
	RCLeagueSeasonRegistrationStatusEnum.ENDED = 4;
	RCLeagueSeasonRegistrationStatusEnum[RCLeagueSeasonRegistrationStatusEnum.UNAVAILABLE] = 'UNAVAILABLE';
	RCLeagueSeasonRegistrationStatusEnum[RCLeagueSeasonRegistrationStatusEnum.OPEN] = 'OPEN';
	RCLeagueSeasonRegistrationStatusEnum[RCLeagueSeasonRegistrationStatusEnum.FULLYBOOKED] = 'FULLYBOOKED';
	RCLeagueSeasonRegistrationStatusEnum[RCLeagueSeasonRegistrationStatusEnum.ENDED] = 'ENDED';
	var RCLeagueBookingStateEnum = {};
	RCLeagueBookingStateEnum.BOOK_BY_EMAIL = 1;
	RCLeagueBookingStateEnum.BOOK_DIRECTLY = 2;
	RCLeagueBookingStateEnum.NO_BOOKING = 3;
	RCLeagueBookingStateEnum.REDIRECT = 4;
	RCLeagueBookingStateEnum[RCLeagueBookingStateEnum.BOOK_BY_EMAIL] = 'BOOK_BY_EMAIL';
	RCLeagueBookingStateEnum[RCLeagueBookingStateEnum.BOOK_DIRECTLY] = 'BOOK_DIRECTLY';
	RCLeagueBookingStateEnum[RCLeagueBookingStateEnum.NO_BOOKING] = 'NO_BOOKING';
	RCLeagueBookingStateEnum[RCLeagueBookingStateEnum.REDIRECT] = 'REDIRECT';
	var RCSeasonPoolStatusEnum = {};
	RCSeasonPoolStatusEnum.IN_POOL = 5;
	RCSeasonPoolStatusEnum.ASSIGNED = 1;
	RCSeasonPoolStatusEnum.QUIT = 2;
	RCSeasonPoolStatusEnum.SUSPENDED = 3;
	RCSeasonPoolStatusEnum.INACTIVE = 4;
	RCSeasonPoolStatusEnum[RCSeasonPoolStatusEnum.IN_POOL] = 'IN_POOL';
	RCSeasonPoolStatusEnum[RCSeasonPoolStatusEnum.ASSIGNED] = 'ASSIGNED';
	RCSeasonPoolStatusEnum[RCSeasonPoolStatusEnum.QUIT] = 'QUIT';
	RCSeasonPoolStatusEnum[RCSeasonPoolStatusEnum.SUSPENDED] = 'SUSPENDED';
	RCSeasonPoolStatusEnum[RCSeasonPoolStatusEnum.INACTIVE] = 'INACTIVE';
	var RCPaymentStatus = {};
	RCPaymentStatus.SENT_TO_CLIENT = 1;
	RCPaymentStatus.SENT_FOR_PAYMENT = 2;
	RCPaymentStatus.ACCEPTED = 3;
	RCPaymentStatus.REJECTED = 4;
	RCPaymentStatus.CANCELLED = 5;
	RCPaymentStatus.FRAUD = 6;
	RCPaymentStatus.NOT_RELEVANT = 7;
	RCPaymentStatus.PENDING = 8;
	RCPaymentStatus[RCPaymentStatus.SENT_TO_CLIENT] = 'SENT_TO_CLIENT';
	RCPaymentStatus[RCPaymentStatus.SENT_FOR_PAYMENT] = 'SENT_FOR_PAYMENT';
	RCPaymentStatus[RCPaymentStatus.ACCEPTED] = 'ACCEPTED';
	RCPaymentStatus[RCPaymentStatus.REJECTED] = 'REJECTED';
	RCPaymentStatus[RCPaymentStatus.CANCELLED] = 'CANCELLED';
	RCPaymentStatus[RCPaymentStatus.FRAUD] = 'FRAUD';
	RCPaymentStatus[RCPaymentStatus.NOT_RELEVANT] = 'NOT_RELEVANT';
	RCPaymentStatus[RCPaymentStatus.PENDING] = 'PENDING';
	var RCEventMatchStatusEnum = {};
	RCEventMatchStatusEnum.PENDING = 1;
	RCEventMatchStatusEnum.ACTIVE = 2;
	RCEventMatchStatusEnum.CANCELLED = 3;
	RCEventMatchStatusEnum[RCEventMatchStatusEnum.PENDING] = 'PENDING';
	RCEventMatchStatusEnum[RCEventMatchStatusEnum.ACTIVE] = 'ACTIVE';
	RCEventMatchStatusEnum[RCEventMatchStatusEnum.CANCELLED] = 'CANCELLED';
	var RCCanJoinSeasonEnum = {};
	RCCanJoinSeasonEnum.CAN_JOIN = 0;
	RCCanJoinSeasonEnum.CANNOT_ALREADY_IN_SEASON = 1;
	RCCanJoinSeasonEnum.CANNOT_MISSING_ORGANIZATION_SUBSCRIPTION = 2;
	RCCanJoinSeasonEnum.CANNOT_SEASON_STATUS = 3;
	RCCanJoinSeasonEnum[RCCanJoinSeasonEnum.CAN_JOIN] = 'CAN_JOIN';
	RCCanJoinSeasonEnum[RCCanJoinSeasonEnum.CANNOT_ALREADY_IN_SEASON] = 'CANNOT_ALREADY_IN_SEASON';
	RCCanJoinSeasonEnum[RCCanJoinSeasonEnum.CANNOT_MISSING_ORGANIZATION_SUBSCRIPTION] =
		'CANNOT_MISSING_ORGANIZATION_SUBSCRIPTION';
	RCCanJoinSeasonEnum[RCCanJoinSeasonEnum.CANNOT_SEASON_STATUS] = 'CANNOT_SEASON_STATUS';
	var RCSeasonCanJoinStatus = {};
	RCSeasonCanJoinStatus.REGISTRATION_OPEN_NOW_PLAYING = 'registration-open-now-playing';
	RCSeasonCanJoinStatus.REGISTRATION_OPEN = 'registration-open';
	RCSeasonCanJoinStatus.NOW_PLAYING = 'now-playing';
	RCSeasonCanJoinStatus.REGISTRATION_ENDED = 'registration-ended';
	RCSeasonCanJoinStatus.ENDED = 'ended';
	RCSeasonCanJoinStatus[RCSeasonCanJoinStatus.REGISTRATION_OPEN_NOW_PLAYING] = 'REGISTRATION_OPEN_NOW_PLAYING';
	RCSeasonCanJoinStatus[RCSeasonCanJoinStatus.REGISTRATION_OPEN] = 'REGISTRATION_OPEN';
	RCSeasonCanJoinStatus[RCSeasonCanJoinStatus.NOW_PLAYING] = 'NOW_PLAYING';
	RCSeasonCanJoinStatus[RCSeasonCanJoinStatus.REGISTRATION_ENDED] = 'REGISTRATION_ENDED';
	RCSeasonCanJoinStatus[RCSeasonCanJoinStatus.ENDED] = 'ENDED';
	var RCSeasonScheduleStatusEnum = {};
	RCSeasonScheduleStatusEnum.DRAFT = 'draft';
	RCSeasonScheduleStatusEnum.PUBLISHED = 'published';
	RCSeasonScheduleStatusEnum[RCSeasonScheduleStatusEnum.DRAFT] = 'DRAFT';
	RCSeasonScheduleStatusEnum[RCSeasonScheduleStatusEnum.PUBLISHED] = 'PUBLISHED';
	var RCSeasonRosterStatusEnum = {};
	RCSeasonRosterStatusEnum.DRAFT = 'draft';
	RCSeasonRosterStatusEnum.PUBLISHED = 'published';
	RCSeasonRosterStatusEnum[RCSeasonRosterStatusEnum.DRAFT] = 'DRAFT';
	RCSeasonRosterStatusEnum[RCSeasonRosterStatusEnum.PUBLISHED] = 'PUBLISHED';

	var RCTeamMemberRoleEnum = {};
	RCTeamMemberRoleEnum.ADMIN = 1;
	RCTeamMemberRoleEnum[RCTeamMemberRoleEnum.ADMIN] = 'ADMIN';
	var RCTeamMemberStatusEnum = {};
	RCTeamMemberStatusEnum.PENDING = 1;
	RCTeamMemberStatusEnum.ACTIVE = 2;
	RCTeamMemberStatusEnum.DECLINED = 3;
	RCTeamMemberStatusEnum.SUSPENDED = 4;
	RCTeamMemberStatusEnum.INACTIVE = 5;
	RCTeamMemberStatusEnum.INVITED = 6;
	RCTeamMemberStatusEnum[RCTeamMemberStatusEnum.PENDING] = 'PENDING';
	RCTeamMemberStatusEnum[RCTeamMemberStatusEnum.ACTIVE] = 'ACTIVE';
	RCTeamMemberStatusEnum[RCTeamMemberStatusEnum.DECLINED] = 'DECLINED';
	RCTeamMemberStatusEnum[RCTeamMemberStatusEnum.SUSPENDED] = 'SUSPENDED';
	RCTeamMemberStatusEnum[RCTeamMemberStatusEnum.INACTIVE] = 'INACTIVE';
	RCTeamMemberStatusEnum[RCTeamMemberStatusEnum.INVITED] = 'INVITED';
	var RCTeamJoinEnum = {};
	RCTeamJoinEnum.CAN_JOIN = 0;
	RCTeamJoinEnum.CANNOT_ALREADY_MEMBER = 1;
	RCTeamJoinEnum.CANNOT_JOIN_FULL = 2;
	RCTeamJoinEnum.CANNOT_JOIN_INACTIVE = 3;
	RCTeamJoinEnum.CANNOT_JOIN_NOT_RECRUITING = 4;
	RCTeamJoinEnum.CLOSED_CAN_REQUEST_TO_JOIN = 5;
	RCTeamJoinEnum[RCTeamJoinEnum.CAN_JOIN] = 'CAN_JOIN';
	RCTeamJoinEnum[RCTeamJoinEnum.CANNOT_ALREADY_MEMBER] = 'CANNOT_ALREADY_MEMBER';
	RCTeamJoinEnum[RCTeamJoinEnum.CANNOT_JOIN_FULL] = 'CANNOT_JOIN_FULL';
	RCTeamJoinEnum[RCTeamJoinEnum.CANNOT_JOIN_INACTIVE] = 'CANNOT_JOIN_INACTIVE';
	RCTeamJoinEnum[RCTeamJoinEnum.CANNOT_JOIN_NOT_RECRUITING] = 'CANNOT_JOIN_NOT_RECRUITING';
	RCTeamJoinEnum[RCTeamJoinEnum.CLOSED_CAN_REQUEST_TO_JOIN] = 'CLOSED_CAN_REQUEST_TO_JOIN';
	var RCTeamPrivacySettingEnum = {};
	RCTeamPrivacySettingEnum.OPEN = 1;
	RCTeamPrivacySettingEnum.CLOSED = 2;
	RCTeamPrivacySettingEnum.CLOSED_CONTROLLED = 3;
	RCTeamPrivacySettingEnum.PRIVATE = 4;
	RCTeamPrivacySettingEnum[RCTeamPrivacySettingEnum.OPEN] = 'OPEN';
	RCTeamPrivacySettingEnum[RCTeamPrivacySettingEnum.CLOSED] = 'CLOSED';
	RCTeamPrivacySettingEnum[RCTeamPrivacySettingEnum.CLOSED_CONTROLLED] = 'CLOSED_CONTROLLED';
	RCTeamPrivacySettingEnum[RCTeamPrivacySettingEnum.PRIVATE] = 'PRIVATE';
	var RCTeamInviteEnum = {};
	RCTeamInviteEnum.CAN_INVITE = 0;
	RCTeamInviteEnum.CAN_INVITE_WITH_APPROVAL = 1;
	RCTeamInviteEnum.CANNOT_INVITE = 2;
	RCTeamInviteEnum[RCTeamInviteEnum.CAN_INVITE] = 'CAN_INVITE';
	RCTeamInviteEnum[RCTeamInviteEnum.CAN_INVITE_WITH_APPROVAL] = 'CAN_INVITE_WITH_APPROVAL';
	RCTeamInviteEnum[RCTeamInviteEnum.CANNOT_INVITE] = 'CANNOT_INVITE';

	var RCFeedContentTypeEnum = {};
	RCFeedContentTypeEnum.BASIC = 1;
	RCFeedContentTypeEnum.GALLERY = 2;
	RCFeedContentTypeEnum.VIDEO = 3;
	RCFeedContentTypeEnum.VENUE = 4;
	RCFeedContentTypeEnum.EVENT = 5;
	RCFeedContentTypeEnum.LEAGUE = 6;
	RCFeedContentTypeEnum.SCORES = 7;
	RCFeedContentTypeEnum.PROMOTEDEVENT = 8;
	RCFeedContentTypeEnum.CHECKIN = 9;
	RCFeedContentTypeEnum.SHARE = 10;
	RCFeedContentTypeEnum.JOINEVENT = 11;
	RCFeedContentTypeEnum.TEAM = 15;
	RCFeedContentTypeEnum.EMBED = 16;
	RCFeedContentTypeEnum.ORGANIZATION = 17;
	RCFeedContentTypeEnum.MATCH_RESULTS = 18;
	RCFeedContentTypeEnum.LEAGUE_STANDINGS = 19;
	RCFeedContentTypeEnum.ROUND_STANDINGS = 20;
	RCFeedContentTypeEnum.TEAM_LOOKING_FOR_PLAYERS = 21;
	RCFeedContentTypeEnum.PLAYER_LOOKING_FOR_TEAM = 22;
	RCFeedContentTypeEnum.TEAM_LOOKING_FOR_FRIENDLY_MATCH = 23;
	RCFeedContentTypeEnum.LEVEL_UP_SHARE = 24;
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.BASIC] = 'BASIC';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.GALLERY] = 'GALLERY';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.VIDEO] = 'VIDEO';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.VENUE] = 'VENUE';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.EVENT] = 'EVENT';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.LEAGUE] = 'LEAGUE';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.SCORES] = 'SCORES';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.PROMOTEDEVENT] = 'PROMOTEDEVENT';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.CHECKIN] = 'CHECKIN';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.SHARE] = 'SHARE';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.JOINEVENT] = 'JOINEVENT';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.TEAM] = 'TEAM';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.EMBED] = 'EMBED';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.ORGANIZATION] = 'ORGANIZATION';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.MATCH_RESULTS] = 'MATCH_RESULTS';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.LEAGUE_STANDINGS] = 'LEAGUE_STANDINGS';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.ROUND_STANDINGS] = 'ROUND_STANDINGS';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.TEAM_LOOKING_FOR_PLAYERS] = 'TEAM_LOOKING_FOR_PLAYERS';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.PLAYER_LOOKING_FOR_TEAM] = 'PLAYER_LOOKING_FOR_TEAM';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.TEAM_LOOKING_FOR_FRIENDLY_MATCH] = 'TEAM_LOOKING_FOR_FRIENDLY_MATCH';
	RCFeedContentTypeEnum[RCFeedContentTypeEnum.LEVEL_UP_SHARE] = 'LEVEL_UP_SHARE';
	var RCPostTargetTypeEnum = {};
	RCPostTargetTypeEnum.USER = 0;
	RCPostTargetTypeEnum.FOLLOWERS = 1;
	RCPostTargetTypeEnum.PUBLIC = 2;
	RCPostTargetTypeEnum.EVENT = 3;
	RCPostTargetTypeEnum.VENUE = 4;
	RCPostTargetTypeEnum.TEAM = 5;
	RCPostTargetTypeEnum.LEAGUE = 6;
	RCPostTargetTypeEnum.GEO = 7;
	RCPostTargetTypeEnum[RCPostTargetTypeEnum.USER] = 'USER';
	RCPostTargetTypeEnum[RCPostTargetTypeEnum.FOLLOWERS] = 'FOLLOWERS';
	RCPostTargetTypeEnum[RCPostTargetTypeEnum.PUBLIC] = 'PUBLIC';
	RCPostTargetTypeEnum[RCPostTargetTypeEnum.EVENT] = 'EVENT';
	RCPostTargetTypeEnum[RCPostTargetTypeEnum.VENUE] = 'VENUE';
	RCPostTargetTypeEnum[RCPostTargetTypeEnum.TEAM] = 'TEAM';
	RCPostTargetTypeEnum[RCPostTargetTypeEnum.LEAGUE] = 'LEAGUE';
	RCPostTargetTypeEnum[RCPostTargetTypeEnum.GEO] = 'GEO';

	var RCSharedDaysEnum = {};
	RCSharedDaysEnum.SUNDAY = 1;
	RCSharedDaysEnum.MONDAY = 2;
	RCSharedDaysEnum.TUESDAY = 3;
	RCSharedDaysEnum.WEDNESDAY = 4;
	RCSharedDaysEnum.THURSDAY = 5;
	RCSharedDaysEnum.FRIDAY = 6;
	RCSharedDaysEnum.SATURDAY = 7;
	RCSharedDaysEnum[RCSharedDaysEnum.SUNDAY] = 'SUNDAY';
	RCSharedDaysEnum[RCSharedDaysEnum.MONDAY] = 'MONDAY';
	RCSharedDaysEnum[RCSharedDaysEnum.TUESDAY] = 'TUESDAY';
	RCSharedDaysEnum[RCSharedDaysEnum.WEDNESDAY] = 'WEDNESDAY';
	RCSharedDaysEnum[RCSharedDaysEnum.THURSDAY] = 'THURSDAY';
	RCSharedDaysEnum[RCSharedDaysEnum.FRIDAY] = 'FRIDAY';
	RCSharedDaysEnum[RCSharedDaysEnum.SATURDAY] = 'SATURDAY';
	var RCLevelOfPlayEnum = {};
	RCLevelOfPlayEnum.BEGINNER = 1;
	RCLevelOfPlayEnum.INTERMEDIATE = 2;
	RCLevelOfPlayEnum.ADVANCED = 3;
	RCLevelOfPlayEnum.SEMI_PRO = 4;
	RCLevelOfPlayEnum.SPECTATOR = 5;
	RCLevelOfPlayEnum[RCLevelOfPlayEnum.BEGINNER] = 'BEGINNER';
	RCLevelOfPlayEnum[RCLevelOfPlayEnum.INTERMEDIATE] = 'INTERMEDIATE';
	RCLevelOfPlayEnum[RCLevelOfPlayEnum.ADVANCED] = 'ADVANCED';
	RCLevelOfPlayEnum[RCLevelOfPlayEnum.SEMI_PRO] = 'SEMI_PRO';
	RCLevelOfPlayEnum[RCLevelOfPlayEnum.SPECTATOR] = 'SPECTATOR';
	var RCInviteOrigin = {};
	RCInviteOrigin.OTHER = 1;
	RCInviteOrigin.LEAGUEINVITE = 2;
	RCInviteOrigin[RCInviteOrigin.OTHER] = 'OTHER';
	RCInviteOrigin[RCInviteOrigin.LEAGUEINVITE] = 'LEAGUEINVITE';
	var RCAmenitiesEnum = {};
	RCAmenitiesEnum.HEAT = 1;
	RCAmenitiesEnum.AC = 2;
	RCAmenitiesEnum.WIFI = 3;
	RCAmenitiesEnum.RESTROOMS = 4;
	RCAmenitiesEnum.DRINKING_FOUNTAIN = 5;
	RCAmenitiesEnum.PARKING = 6;
	RCAmenitiesEnum.CONCESSIONS = 7;
	RCAmenitiesEnum.SHELTER = 8;
	RCAmenitiesEnum.PORTABLE_RESTROOMS = 9;
	RCAmenitiesEnum.LIGHTS = 10;
	RCAmenitiesEnum.LOCKER_ROOM = 11;
	RCAmenitiesEnum.PAID_PARKING = 12;
	RCAmenitiesEnum.ACCESSIBLE = 13;
	RCAmenitiesEnum[RCAmenitiesEnum.HEAT] = 'HEAT';
	RCAmenitiesEnum[RCAmenitiesEnum.AC] = 'AC';
	RCAmenitiesEnum[RCAmenitiesEnum.WIFI] = 'WIFI';
	RCAmenitiesEnum[RCAmenitiesEnum.RESTROOMS] = 'RESTROOMS';
	RCAmenitiesEnum[RCAmenitiesEnum.DRINKING_FOUNTAIN] = 'DRINKING_FOUNTAIN';
	RCAmenitiesEnum[RCAmenitiesEnum.PARKING] = 'PARKING';
	RCAmenitiesEnum[RCAmenitiesEnum.CONCESSIONS] = 'CONCESSIONS';
	RCAmenitiesEnum[RCAmenitiesEnum.SHELTER] = 'SHELTER';
	RCAmenitiesEnum[RCAmenitiesEnum.PORTABLE_RESTROOMS] = 'PORTABLE_RESTROOMS';
	RCAmenitiesEnum[RCAmenitiesEnum.LIGHTS] = 'LIGHTS';
	RCAmenitiesEnum[RCAmenitiesEnum.LOCKER_ROOM] = 'LOCKER_ROOM';
	RCAmenitiesEnum[RCAmenitiesEnum.PAID_PARKING] = 'PAID_PARKING';
	RCAmenitiesEnum[RCAmenitiesEnum.ACCESSIBLE] = 'ACCESSIBLE';

	var RCCustomQuestionType = {};
	RCCustomQuestionType.TEXT = 'text';
	RCCustomQuestionType.YES_NO = 'yesNo';
	RCCustomQuestionType.MULTIPLE_CHOICE = 'multipleChoices';
	RCCustomQuestionType.NUMERIC = 'numeric';
	RCCustomQuestionType.DATE = 'date';
	RCCustomQuestionType.SINGLE_CHOICE = 'singleChoice';
	RCCustomQuestionType.FILE_UPLOAD = 'fileUpload';
	RCCustomQuestionType[RCCustomQuestionType.TEXT] = 'TEXT';
	RCCustomQuestionType[RCCustomQuestionType.YES_NO] = 'YES_NO';
	RCCustomQuestionType[RCCustomQuestionType.MULTIPLE_CHOICE] = 'MULTIPLE_CHOICE';
	RCCustomQuestionType[RCCustomQuestionType.NUMERIC] = 'NUMERIC';
	RCCustomQuestionType[RCCustomQuestionType.DATE] = 'DATE';
	RCCustomQuestionType[RCCustomQuestionType.SINGLE_CHOICE] = 'SINGLE_CHOICE';
	RCCustomQuestionType[RCCustomQuestionType.FILE_UPLOAD] = 'FILE_UPLOAD';
	var RCQuestionTypesEnum = {};
	RCQuestionTypesEnum.OTHER = 'other';
	RCQuestionTypesEnum.T_SHIRT_SIZE = 'tshirtSize';
	RCQuestionTypesEnum.PHONE_NUMBER = 'phoneNumber';
	RCQuestionTypesEnum.EMAIL_ADDRESS = 'emailAddress';
	RCQuestionTypesEnum.ADDRESS = 'address';
	RCQuestionTypesEnum.BIRTH_DATE = 'birthDate';
	RCQuestionTypesEnum.PREFERRED_TEAM = 'preferredTeam';
	RCQuestionTypesEnum.WAIVER = 'waiver';
	RCQuestionTypesEnum.CUSTOM_WAIVER = 'customWaiver';
	RCQuestionTypesEnum[RCQuestionTypesEnum.OTHER] = 'OTHER';
	RCQuestionTypesEnum[RCQuestionTypesEnum.T_SHIRT_SIZE] = 'T_SHIRT_SIZE';
	RCQuestionTypesEnum[RCQuestionTypesEnum.PHONE_NUMBER] = 'PHONE_NUMBER';
	RCQuestionTypesEnum[RCQuestionTypesEnum.EMAIL_ADDRESS] = 'EMAIL_ADDRESS';
	RCQuestionTypesEnum[RCQuestionTypesEnum.ADDRESS] = 'ADDRESS';
	RCQuestionTypesEnum[RCQuestionTypesEnum.BIRTH_DATE] = 'BIRTH_DATE';
	RCQuestionTypesEnum[RCQuestionTypesEnum.PREFERRED_TEAM] = 'PREFERRED_TEAM';
	RCQuestionTypesEnum[RCQuestionTypesEnum.WAIVER] = 'WAIVER';
	RCQuestionTypesEnum[RCQuestionTypesEnum.CUSTOM_WAIVER] = 'CUSTOM_WAIVER';

	exports.EventStatus = EventStatus;
	exports.RCEventAttendeeStatus = RCEventAttendeeStatus;
	exports.RCEventType = RCEventType;
	exports.RCEventJoinEnum = RCEventJoinEnum;
	exports.RCGenderEnum = RCGenderEnum;
	exports.RCMembershipCanJoin = RCMembershipCanJoin;
	exports.RCLinkedAccountStatus = RCLinkedAccountStatus;
	exports.RCOfflinePaymentSetting = RCOfflinePaymentSetting;
	exports.RCPaymentSettingStatus = RCPaymentSettingStatus;
	exports.RCLeagueDetailTypeEnum = RCLeagueDetailTypeEnum;
	exports.RCLeagueSeasonStatusEnum = RCLeagueSeasonStatusEnum;
	exports.RCLeagueSeasonRegistrationStatusEnum = RCLeagueSeasonRegistrationStatusEnum;
	exports.RCLeagueBookingStateEnum = RCLeagueBookingStateEnum;
	exports.RCSeasonPoolStatusEnum = RCSeasonPoolStatusEnum;
	exports.RCPaymentStatus = RCPaymentStatus;
	exports.RCEventMatchStatusEnum = RCEventMatchStatusEnum;
	exports.RCCanJoinSeasonEnum = RCCanJoinSeasonEnum;
	exports.RCSeasonCanJoinStatus = RCSeasonCanJoinStatus;
	exports.RCSeasonScheduleStatusEnum = RCSeasonScheduleStatusEnum;
	exports.RCSeasonRosterStatusEnum = RCSeasonRosterStatusEnum;
	exports.RCTeamMemberRoleEnum = RCTeamMemberRoleEnum;
	exports.RCTeamMemberStatusEnum = RCTeamMemberStatusEnum;
	exports.RCTeamJoinEnum = RCTeamJoinEnum;
	exports.RCTeamPrivacySettingEnum = RCTeamPrivacySettingEnum;
	exports.RCTeamInviteEnum = RCTeamInviteEnum;
	exports.RCFeedContentTypeEnum = RCFeedContentTypeEnum;
	exports.RCPostTargetTypeEnum = RCPostTargetTypeEnum;
	exports.RCSharedDaysEnum = RCSharedDaysEnum;
	exports.RCLevelOfPlayEnum = RCLevelOfPlayEnum;
	exports.RCInviteOrigin = RCInviteOrigin;
	exports.RCAmenitiesEnum = RCAmenitiesEnum;
	exports.RCCustomQuestionType = RCCustomQuestionType;
	exports.RCQuestionTypesEnum = RCQuestionTypesEnum;

	Object.defineProperty(exports, '__esModule', { value: true });
});
