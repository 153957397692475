/** @jsxRuntime classic*/
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { FC } from 'react';
import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';
import { centerCss } from '../styles';

interface CenterProps {
	styles?: SerializedStyles;
	children: React.ReactNode;
}

export const Center: FC<CenterProps> = ({ styles, children }) => {
	return (
		<div className="center-primitive" css={centerCss(styles)} data-aid="center-components">
			{children}
		</div>
	);
};
