/** @jsxRuntime classic */
/** @jsx jsx */

import { FC, useEffect, useMemo, useRef, useState, MutableRefObject } from 'react';
import { jsx } from '@emotion/react';
import { useDraggable } from '@dnd-kit/core';
import dayjs from 'dayjs';
import { calculateMarginFromMinutes, calculateMinutesFromHeight } from '../../utils/timeUtils';
import { useMiddlewareContext } from '../../hooks/useMiddlewareContext';
import { useDebouncedValue } from '../../hooks/useDebouncedValue';
import { useResize } from '../../hooks/useResize';
import { IDraggableDayToDropProps } from '../../types';
import { draggableContainer, draggableContent, draggableHandler, draggableResizeHandler } from '../styles';
import { DndIcon } from '../../utils/DndIcon';
import { getMinutesDifferenceByStep } from '../../utils';
import { ResizeIcon } from '../../utils/ResizeIcon';

export const Draggable: FC<IDraggableDayToDropProps> = ({
	children,
	id,
	state,
	parentId,
	groupTop,
	groupCount,
	groupIndex,
	isEditable,
}) => {
	const elementId = `draggable-${id}`;

	const { updateDatesRange, getTopAndHeight, options } = useMiddlewareContext();

	const splittedStartTime = (state.overallStartTime ?? state.startTime).split(':');
	const splittedEndTime = (state.overallEndTime ?? state.endTime).split(':');

	const { top, height } = useMemo(() => {
		return getTopAndHeight(
			dayjs(dayjs.utc(state.startDate).format('DD/MM/YYYY'), 'DD/MM/YYYY')
				.hour(+splittedStartTime[0])
				.minute(+splittedStartTime[1])
				.toDate()
				.getTime(),
			dayjs(dayjs.utc(state.endDate).format('DD/MM/YYYY'), 'DD/MM/YYYY')
				.hour(+splittedEndTime[0])
				.minute(+splittedEndTime[1])
				.toDate()
				.getTime()
		);
	}, [splittedStartTime, splittedEndTime]);

	const { isDragging, setNodeRef, listeners } = useDraggable({
		id: String(id),
		data: {
			parentId: parentId,
			event: state,
		},
	});

	const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
	const { initResize, size, cursor } = useResize(ref as MutableRefObject<HTMLDivElement>, {
		axis: 'vertical',
	});
	const sizeH = useDebouncedValue(size.height, 200);

	const [sizeHeight, setSizeHeight] = useState(size.height);

	useEffect(() => {
		setSizeHeight(sizeH);
	}, [sizeH]);

	const handleResize = () => {
		const draggableElement = ref?.current;
		if (!isDragging && draggableElement && !Number.isNaN(sizeHeight)) {
			const draggableHeight = draggableElement.offsetHeight;
			if (draggableHeight !== sizeHeight) return;

			const splittedStartTime = (state.overallStartTime ?? state.startTime).split(':');

			const startDate = dayjs
				.utc(state.startDate)
				.hour(+splittedStartTime[0])
				.minute(+splittedStartTime[1])
				.format();

			// const startDate = dayjs.utc(state?.startTime, "HH:mm:ss").format();
			let minutes = Math.floor(calculateMinutesFromHeight(draggableHeight));

			minutes = getMinutesDifferenceByStep(startDate, minutes, options.dndStepLength, true);

			const endDate = dayjs
				.utc(startDate)
				.add(minutes, 'minute')
				.set('milliseconds', 0)
				.set('seconds', 0)
				.toISOString();

			const onChangeData = {
				type: 'SLOT_RESIZE',
				data: {
					startDate,
					endDate,
					startTime: dayjs.utc(startDate).format('HH:mm'),
					endTime: dayjs.utc(endDate).format('HH:mm'),
					overId: parentId, // maybe should be targetId
					parentId,
					slotId: String(state.id),
				},
			};

			draggableElement.removeAttribute('style');
			setSizeHeight(calculateMarginFromMinutes(minutes));

			updateDatesRange(state.id, startDate, endDate, onChangeData);
		}
	};

	useEffect(() => {
		handleResize();
	}, [sizeHeight]);

	return (
		<div
			id={elementId}
			css={draggableContainer(top - (groupTop || 0), groupCount, groupIndex)}
			ref={setNodeRef}
			data-aid="Draggable-daily"
		>
			<div css={draggableContent(!Number.isNaN(sizeHeight) && sizeHeight !== Infinity ? sizeHeight : height)} ref={ref}>
				{children}
				{options.dragAndDrop && isEditable && (
					<div {...listeners} css={draggableHandler}>
						<DndIcon />
					</div>
				)}
				{options.resize && isEditable && (
					<div css={draggableResizeHandler(cursor)} onMouseDown={initResize}>
						<ResizeIcon />
					</div>
				)}
			</div>
		</div>
	);
};
