import React from "react";

export const Rollerderby = (props: any) => (
  <svg
    data-aid="svgRollerderby"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.8461 15.625H9.05781C8.75547 11.8719 5.75312 8.86953 2 8.56719V2.625C2 2.2798 2.2798 2 2.625 2H10.75C11.0952 2 11.375 2.2798 11.375 2.625V3.5832H9.875C9.51945 3.5832 9.23348 3.88016 9.25074 4.23906C9.26691 4.57496 9.55644 4.8332 9.89273 4.8332H11.375V6.4168H9.89273C9.55644 6.4168 9.26691 6.675 9.25074 7.0109C9.23344 7.36984 9.51945 7.6668 9.875 7.6668H11.375C11.375 8.08645 11.4482 8.47387 11.634 8.86992L10.2617 9.69336C9.96562 9.8707 9.86992 10.2547 10.0473 10.5508C10.2246 10.8462 10.6076 10.9428 10.9051 10.7652L12.4156 9.85859C12.6344 10.0328 12.8801 10.1742 13.1457 10.2754V12C13.1457 12.3555 13.4427 12.6415 13.8016 12.6243C14.1375 12.6081 14.3957 12.3186 14.3957 11.9823V10.4621C17.2473 10.5949 19.5629 12.8145 19.8461 15.625Z"
      fill="currentColor"
    />
    <path
      d="M2 15.625V9.82227C5.06367 10.1172 7.50781 12.5613 7.80273 15.625H2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 16.6H2C2 17.1114 2.23993 17.5667 2.61336 17.8596C2.23132 18.2987 2 18.8723 2 19.5C2 20.8807 3.11929 22 4.5 22C5.88071 22 7 20.8807 7 19.5C7 19.0238 6.86688 18.5788 6.63582 18.2H15.142C14.9109 18.5788 14.7778 19.0238 14.7778 19.5C14.7778 20.8807 15.8971 22 17.2778 22C18.6585 22 19.7778 20.8807 19.7778 19.5C19.7778 18.7907 19.4824 18.1503 19.0078 17.6953C19.3759 17.4497 19.691 17.1219 19.9239 16.7285L20 16.6ZM5.5 19.5C5.5 20.0523 5.05228 20.5 4.5 20.5C3.94772 20.5 3.5 20.0523 3.5 19.5C3.5 18.9477 3.94772 18.5 4.5 18.5C5.05228 18.5 5.5 18.9477 5.5 19.5ZM17.2778 20.5C17.8301 20.5 18.2778 20.0523 18.2778 19.5C18.2778 18.9477 17.8301 18.5 17.2778 18.5C16.7255 18.5 16.2778 18.9477 16.2778 19.5C16.2778 20.0523 16.7255 20.5 17.2778 20.5Z"
      fill="currentColor"
    />
  </svg>
);
