/** @jsx jsx */
import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { css, jsx } from '@emotion/react';
import { CustomSelect } from '../../customSelect';
import { ItemsPerPageCalculator, Table } from '../../../shared/NewTable';
import { Input } from '../../../shared/Input';
import { checkboxCss, CheckedIconCss, filterContainer, flex, iconCss } from '../../../../styles/utils';
import { CustomersService } from 'app/shared/services/customers/customers.service';
import * as queryString from 'querystring';
import { CustomCheckbox } from '../../../shared/Checkbox';
import { EPaymentStatusInvoiceFilter } from '../../../../types/payment';
import { TranslationEn } from 'assets/i18n/en';
import { useComingSoonPopUp } from '../../../shared/ComingSoon';
import { ProductTypesEnum } from 'app/shared/services/programs/programs.service';
import { AlignType } from 'app/react/components/shared/NewTable/types';
import { PaymentStatusEnum } from 'app/react/types/orders';
import { customersApi } from '@app/react/lib/api/customersApi';
import { useDebounce } from '@app/react/hooks/useDebouncedSearch';
import { getPaymentMethodLabelByValue } from '@app/react/components/payments/utils/paymentMethods';
import { filterPaymentMethods } from '@app/react/components/shared/ChooseInvoice/utils';
import { getInvoiceStatus } from '@app/react/components/customers/utils/invoice.utils';
import { PageContext } from '@app/react/pages/customers/PageContext';
import { SortEnum, UrlParamEnum } from './Overview/utils';
import { useQuery } from '@app/react/hooks/useQuery';
import { OrderByEnum } from '@bondsports/types';
import { useOrganizationTimezone } from '@app/react/hooks/useOrganizationTimezone';

const containerCss = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
`;

const filterContainerCss = css`
	display: flex;
	align-items: center;
	margin: 5px 2rem;
`;

const tableContainerCss = css`
	height: 500px;
	flex-grow: 1;
	margin: 1rem 2rem 2rem 2rem;
`;

interface FilterProps {
	statusFilter: string[];
	methodFilter: string[];
	setStatus: (val: string[]) => void;
	setMethod: (val: string[]) => void;
}

const InvoiceFilters = ({ statusFilter, methodFilter, setStatus, setMethod }: FilterProps) => {
	const onFilterChange = (e: any) => {
		if (e.target.checked) {
			setStatus([...statusFilter, e.target.name]);
		} else {
			setStatus(statusFilter.filter(status => status != e.target.name));
		}
	};

	return (
		<div css={filterContainer}>
			<label>Status</label>
			{Object.values(EPaymentStatusInvoiceFilter).map((value, index) => {
				return (
					<div css={flex} key={index}>
						<CustomCheckbox
							css={checkboxCss}
							checkedIcon={<span id="checked" css={CheckedIconCss} />}
							icon={<span css={iconCss} />}
							checked={statusFilter.includes(value)}
							onChange={onFilterChange}
							name={value}
						/>
						<div>{TranslationEn.customers.filterLabels[value]}</div>
					</div>
				);
			})}
			<label>Payment Methods</label>
			{filterPaymentMethods.map((method, index) => {
				return (
					<div css={flex} key={index}>
						<CustomCheckbox
							css={checkboxCss}
							checkedIcon={<span id="checked" css={CheckedIconCss} />}
							icon={<span css={iconCss} />}
							checked={methodFilter.includes(method.value)}
							onChange={e => {
								if (e.target.checked) {
									setMethod([...methodFilter, method.value]);
								} else {
									setMethod(methodFilter.filter(method => method != e.target.name));
								}
							}}
							name={method.value}
						/>
						<div>{method.label}</div>
					</div>
				);
			})}
		</div>
	);
};

interface Props {
	customerState: any;
	customersService: CustomersService;
}

//REFACTOR REQUIRED TBD: duplicate code with Payments
export const Invoices = ({ customerState, customersService }: Props) => {
	const [isLoading, setLoading] = useState(true);
	const [paymentStatusFilter, setPaymentStatusFilter] = useState([]);
	const [methodFilter, setMethodFilter] = useState([]);
	const [searchFilter, setSearchFilter] = useState('');
	const [tablePage, setPage] = useState(0);
	const [invoices, setInvoices] = useState([]);
	const [meta, setMeta] = useState({});
	const tableContainerRef = useRef(null);
	const history = useHistory();
	const location = useLocation();
	const { ComingSoonModal } = useComingSoonPopUp();
	const debouncedValue = useDebounce(searchFilter, 300);
	const { isMemberAdded } = useContext(PageContext);
	const { getQueryValue } = useQuery();
	const { timezone } = useOrganizationTimezone();

	const { itemsPerPage } = ItemsPerPageCalculator(tableContainerRef);
	const [skipFirstFetch, setSkipFirstFetch] = useState<boolean>(true);

	const nonLineItems = [
		PaymentStatusEnum.REFUNDED,
		PaymentStatusEnum.VOID,
		PaymentStatusEnum.DISCOUNT,
		PaymentStatusEnum.REVERT_DISCOUNT,
	];

	const handleRedirection = (id: number) => {
		history.push(`${location.pathname}/${id}/details`);
	};

	const handleStatusFilterChange = (val: string[]) => {
		setPaymentStatusFilter(val);
	};

	const handleMethodFilterChange = (val: string[]) => {
		setMethodFilter(val);
	};

	const isSortByAlerts = useMemo(() => {
		return getQueryValue(UrlParamEnum.SORT_BY) === SortEnum.PAYMENT_ALERTS;
	}, []);

	useEffect(() => {
		const parsed = queryString.parse(location.search.replace('?', ''));
		if (parsed.page) {
			setPage(Number(parsed.page) - 1);
		}
		return () => setPage(0);
	}, [location]);

	useEffect(() => {
		if (!isMemberAdded) {
			history.push(`${location.pathname}?page=1`);
		}
	}, [methodFilter, debouncedValue, paymentStatusFilter]);

	useEffect(() => {
		if (!skipFirstFetch) {
			setLoading(true);
			customersApi
				.getInvoicesByCustomerId({
					customerId: Number(customerState.id),
					page: tablePage + 1,
					itemsPerPage,
					paymentStatusFilter,
					methodFilter,
					searchFilter: debouncedValue,
					...(isSortByAlerts && {
						orderByProperty: ['paymentStatus', 'id'],
						sortOrder: [OrderByEnum.DESC, OrderByEnum.ASC],
					}),
				})
				.then(data => {
					const newInvoices = data?.data.map(invoice => {
						invoice.itemsCount = invoice.itemsAmount ? `${invoice.itemsAmount} ${invoice.itemsAmount === 1 ? 'Item' : 'Items'}` : '';
						invoice.paymentType = getPaymentMethodLabelByValue(invoice.paymentType);
						invoice.paymentStatus = getInvoiceStatus(invoice.paymentStatus, invoice.isScheduled);

						return invoice;
					});
					setInvoices(newInvoices);
					setMeta(data.meta);
					setLoading(false);
				});
		}
		setSkipFirstFetch(false);
	}, [paymentStatusFilter, methodFilter, debouncedValue, tablePage, itemsPerPage, isSortByAlerts, timezone]);

	const tableLabels = TranslationEn.customers.paymentsInvoices;
	const columns = [
		{
			id: 'createdAt',
			label: tableLabels.createdOn,
			type: 'datetime',
			styling: { align: AlignType.LEFT },
			action: row => handleRedirection(row.id),
		},
		{
			id: 'id',
			label: tableLabels.invoiceNum,
			type: 'number',
			action: row => handleRedirection(row.id),
		},
		{
			id: 'itemsCount',
			label: tableLabels.items,
			type: 'string',
			action: row => handleRedirection(row.id),
		},
		{
			id: 'price',
			label: tableLabels.total,
			type: 'currency',
			action: row => handleRedirection(row.id),
		},
		{
			id: 'paymentType',
			label: tableLabels.paymentMethod,
			type: 'string',
			action: row => handleRedirection(row.id),
		},
		{
			id: 'paymentStatus',
			label: tableLabels.status,
			type: 'status',
			action: row => handleRedirection(row.id),
		},
		{
			id: 'forward',
			label: '',
			type: 'icon',
			action: row => handleRedirection(row.id),
		},
	];

	return (
		<React.Fragment>
			<div data-aid="customerInvoices" css={containerCss}>
				<div css={filterContainerCss}>
					<CustomSelect
						title="Filter"
						content={
							<InvoiceFilters
								statusFilter={paymentStatusFilter}
								methodFilter={methodFilter}
								setStatus={handleStatusFilterChange}
								setMethod={handleMethodFilterChange}
							/>
						}
					/>
					<Input
						data-aid="customerInvoices-input-search"
						search
						placeholder="Search by invoice no."
						// onFocus={() => ComingSoonToggle()}
						value={searchFilter}
						onChange={e => setSearchFilter(e.target.value)}
					/>
				</div>
				<div data-aid="customerInvoices-table" ref={tableContainerRef} css={tableContainerCss}>
					<Table
						page={tablePage}
						ssr
						meta={meta}
						rows={invoices}
						columns={columns}
						isLoading={isLoading}
						subject={TranslationEn.customers.tables.invoices}
						timezone={timezone}
					/>
				</div>
			</div>
			<ComingSoonModal />
		</React.Fragment>
	);
};
