import React from "react";

export const Shelters = (props: any) => (
  <svg
    data-aid="SvgShelters"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.17978 3.58674C4.33506 3.27619 4.65247 3.08002 4.99967 3.08002H18.9997C19.3469 3.08002 19.6643 3.27619 19.8196 3.58674L21.8196 7.58674C21.8832 7.71402 21.9163 7.85438 21.9163 7.99668V8.99668C21.9163 10.0354 21.5037 11.0317 20.7692 11.7662C20.0347 12.5007 19.0384 12.9134 17.9997 12.9134C16.9609 12.9134 15.9647 12.5007 15.2302 11.7662C15.1494 11.6854 15.0725 11.6015 14.9997 11.5147C14.9268 11.6015 14.8499 11.6854 14.7692 11.7662C14.0347 12.5007 13.0384 12.9134 11.9997 12.9134C10.9609 12.9134 9.96469 12.5007 9.23017 11.7662C9.14941 11.6854 9.07254 11.6015 8.99967 11.5147C8.92681 11.6015 8.84994 11.6854 8.76918 11.7662C8.03466 12.5007 7.03844 12.9134 5.99967 12.9134C4.96091 12.9134 3.96469 12.5007 3.23017 11.7662C2.49566 11.0317 2.08301 10.0354 2.08301 8.99668V7.99668C2.08301 7.85438 2.11614 7.71402 2.17978 7.58674L4.17978 3.58674ZM9.91634 8.99668C9.91634 9.54922 10.1358 10.0791 10.5265 10.4698C10.9172 10.8605 11.4471 11.08 11.9997 11.08C12.5522 11.08 13.0821 10.8605 13.4728 10.4698C13.8635 10.0791 14.083 9.54922 14.083 8.99668V8.91335H9.91634V8.99668ZM8.08301 8.91335H3.91634V8.99668C3.91634 9.54922 4.13583 10.0791 4.52654 10.4698C4.91724 10.8605 5.44714 11.08 5.99967 11.08C6.55221 11.08 7.08211 10.8605 7.47281 10.4698C7.86351 10.0791 8.08301 9.54922 8.08301 8.99668V8.91335ZM4.48287 7.08002H19.5165L18.4331 4.91335H5.56621L4.48287 7.08002ZM20.083 8.91335H15.9163V8.99668C15.9163 9.54922 16.1358 10.0791 16.5265 10.4698C16.9172 10.8605 17.4471 11.08 17.9997 11.08C18.5522 11.08 19.0821 10.8605 19.4728 10.4698C19.8635 10.0791 20.083 9.54922 20.083 8.99668V8.91335Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99967 10.93C4.50594 10.93 4.91634 11.3404 4.91634 11.8467V20.9967C4.91634 21.503 4.50594 21.9134 3.99967 21.9134C3.49341 21.9134 3.08301 21.503 3.08301 20.9967V11.8467C3.08301 11.3404 3.49341 10.93 3.99967 10.93Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9997 10.93C20.5059 10.93 20.9163 11.3404 20.9163 11.8467V20.9967C20.9163 21.503 20.5059 21.9134 19.9997 21.9134C19.4934 21.9134 19.083 21.503 19.083 20.9967V11.8467C19.083 11.3404 19.4934 10.93 19.9997 10.93Z"
      fill="currentColor"
    />
  </svg>
);
