import React from "react";

export const PaymentStatusPartially = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
      fill="#F7B500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 6.25C5.5 5.14792 6.36876 4.25071 7.50261 4.0446L7.50058 4.02273L7.5 3.5C7.5 3.22386 7.72386 3 8 3C8.27614 3 8.5 3.22386 8.5 3.5V4L8.49789 4.04469C9.6315 4.25097 10.5 5.14808 10.5 6.25C10.5 6.52614 10.2761 6.75 10 6.75C9.74358 6.75 9.53225 6.55698 9.50336 6.30831L9.5 6.25C9.5 5.5756 8.84217 5 8 5C7.15783 5 6.5 5.5756 6.5 6.25C6.5 6.89943 7.11 7.45723 7.90721 7.49766L8 7.5C9.36696 7.5 10.5 8.49141 10.5 9.75C10.5 10.8511 9.63277 11.7477 8.50039 11.9549L8.5 12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5L7.50011 11.9549C6.36748 11.748 5.5 10.8513 5.5 9.75C5.5 9.47386 5.72386 9.25 6 9.25C6.27614 9.25 6.5 9.47386 6.5 9.75C6.5 10.4244 7.15783 11 8 11C8.84217 11 9.5 10.4244 9.5 9.75C9.5 9.10058 8.89 8.54277 8.09279 8.50235L8 8.5C6.63304 8.5 5.5 7.50859 5.5 6.25Z"
      fill="white"
    />
  </svg>
);

export const PaymentStatusPaid = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
      fill="#24C875"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 6.25C5.5 5.14792 6.36876 4.25071 7.50261 4.0446L7.50058 4.02273L7.5 3.5C7.5 3.22386 7.72386 3 8 3C8.27614 3 8.5 3.22386 8.5 3.5V4L8.49789 4.04469C9.6315 4.25097 10.5 5.14808 10.5 6.25C10.5 6.52614 10.2761 6.75 10 6.75C9.74358 6.75 9.53225 6.55698 9.50336 6.30831L9.5 6.25C9.5 5.5756 8.84217 5 8 5C7.15783 5 6.5 5.5756 6.5 6.25C6.5 6.89943 7.11 7.45723 7.90721 7.49766L8 7.5C9.36696 7.5 10.5 8.49141 10.5 9.75C10.5 10.8511 9.63277 11.7477 8.50039 11.9549L8.5 12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5L7.50011 11.9549C6.36748 11.748 5.5 10.8513 5.5 9.75C5.5 9.47386 5.72386 9.25 6 9.25C6.27614 9.25 6.5 9.47386 6.5 9.75C6.5 10.4244 7.15783 11 8 11C8.84217 11 9.5 10.4244 9.5 9.75C9.5 9.10058 8.89 8.54277 8.09279 8.50235L8 8.5C6.63304 8.5 5.5 7.50859 5.5 6.25Z"
      fill="white"
    />
  </svg>
);

export const PaymentStatusUnpaid = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
      fill="#E02020"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 6.25C5.5 5.14792 6.36876 4.25071 7.50261 4.0446L7.50058 4.02273L7.5 3.5C7.5 3.22386 7.72386 3 8 3C8.27614 3 8.5 3.22386 8.5 3.5V4L8.49789 4.04469C9.6315 4.25097 10.5 5.14808 10.5 6.25C10.5 6.52614 10.2761 6.75 10 6.75C9.74358 6.75 9.53225 6.55698 9.50336 6.30831L9.5 6.25C9.5 5.5756 8.84217 5 8 5C7.15783 5 6.5 5.5756 6.5 6.25C6.5 6.89943 7.11 7.45723 7.90721 7.49766L8 7.5C9.36696 7.5 10.5 8.49141 10.5 9.75C10.5 10.8511 9.63277 11.7477 8.50039 11.9549L8.5 12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5L7.50011 11.9549C6.36748 11.748 5.5 10.8513 5.5 9.75C5.5 9.47386 5.72386 9.25 6 9.25C6.27614 9.25 6.5 9.47386 6.5 9.75C6.5 10.4244 7.15783 11 8 11C8.84217 11 9.5 10.4244 9.5 9.75C9.5 9.10058 8.89 8.54277 8.09279 8.50235L8 8.5C6.63304 8.5 5.5 7.50859 5.5 6.25Z"
      fill="white"
    />
  </svg>
);
