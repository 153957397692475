import * as  React from 'react';

export const BnCourt = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0001 12C11.1047 12 12.0001 11.1046 12.0001 10C12.0001 8.89544 11.1047 8 10.0001 8C8.89551 8 8.00008 8.89544 8.00008 10C8.00008 11.1046 8.89551 12 10.0001 12ZM10.0001 13.3333C11.841 13.3333 13.3334 11.841 13.3334 10C13.3334 8.15906 11.841 6.66667 10.0001 6.66667C8.15913 6.66667 6.66675 8.15906 6.66675 10C6.66675 11.841 8.15913 13.3333 10.0001 13.3333Z"
      fill="currentColor"
    />
    <path
      d="M11.9139 19.0833C11.9699 18.899 12.0001 18.7033 12.0001 18.5007C12.0001 17.3961 11.1047 16.5007 10.0001 16.5007C8.89551 16.5007 8.00008 17.3961 8.00008 18.5007C8.00008 18.7033 8.03023 18.899 8.08629 19.0833H6.7175C6.68415 18.8941 6.66675 18.6994 6.66675 18.5007C6.66675 16.6597 8.15913 15.1673 10.0001 15.1673C11.841 15.1673 13.3334 16.6597 13.3334 18.5007C13.3334 18.6994 13.316 18.8941 13.2827 19.0833H11.9139Z"
      fill="currentColor"
    />
    <path
      d="M8.08629 0.916672C8.03023 1.10103 8.00008 1.29667 8.00008 1.49935C8.00008 2.60392 8.89551 3.49935 10.0001 3.49935C11.1047 3.49935 12.0001 2.60392 12.0001 1.49935C12.0001 1.29667 11.9699 1.10103 11.9139 0.916672H13.2827C13.316 1.10588 13.3334 1.30058 13.3334 1.49935C13.3334 3.3403 11.841 4.83269 10.0001 4.83269C8.15913 4.83269 6.66675 3.3403 6.66675 1.49935C6.66675 1.30058 6.68415 1.10588 6.7175 0.916672H8.08629Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.58341 3.33334C2.58341 1.99865 3.66539 0.916672 5.00008 0.916672H15.0001C16.3348 0.916672 17.4167 1.99865 17.4167 3.33334V16.6667C17.4167 18.0014 16.3348 19.0833 15.0001 19.0833H5.00008C3.66539 19.0833 2.58341 18.0014 2.58341 16.6667V3.33334ZM5.00008 2.41667C4.49382 2.41667 4.08341 2.82708 4.08341 3.33334V16.6667C4.08341 17.1729 4.49382 17.5833 5.00008 17.5833H15.0001C15.5063 17.5833 15.9167 17.1729 15.9167 16.6667V3.33334C15.9167 2.82708 15.5063 2.41667 15.0001 2.41667H5.00008Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.41675 10C1.41675 9.63181 1.71522 9.33334 2.08341 9.33334H17.9167C18.2849 9.33334 18.5834 9.63181 18.5834 10C18.5834 10.3682 18.2849 10.6667 17.9167 10.6667H2.08341C1.71522 10.6667 1.41675 10.3682 1.41675 10Z"
      fill="currentColor"
    />
  </svg>
);
