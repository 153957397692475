import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IColors } from '../../types/theme';
import { TSizeEnum } from '../../lib/constants';

export const StyledSelectOption = styled.button<any>(
	({ theme: colors, size, isDisabled }: { theme: IColors; size: TSizeEnum; isDisabled?: boolean }) => `
    border:none ;
    background:none;

    div{
      display:flex;
      justify-content:space-between;
      cursor:${isDisabled ? 'auto' : 'pointer'};
      margin:4px;
      padding:${size === 'M' ? '12px' : '8px 16px'};
      &:hover{
        background:${!isDisabled && colors.bg_background_primary};
      }
      svg{
        width:16px;
        height:16px;
      }

      span{
        display:flex;
        align-items:center;
        text-align:left;
        svg{
          margin-right:8px;
        }
      }
    }

	.additional-info{
		color:${colors.tx_text_secondary};
		font-size: 12px;
        font-weight: 400;
		margin-left:4px;
	}

  `
);

export const StyledCustomerSelectOption = styled.button<any>(
	({ theme: colors }: { theme: IColors }) => `
    border:none ;
    background:none;
    padding:0;
    position: relative;
    
  `
);

export const customerOptionsContainerCss = (colors: IColors) => css`
	padding: 12px;
	cursor: pointer;
	display: flex;
	align-items: center;

	&:hover {
		background: ${colors.bg_background_primary};
	}
	.thumbil {
		width: 44px;
		min-width: 44px;
		height: 44px;
		background: rgba(13, 71, 116, 0.04);
		border-radius: 100px;
		margin-right: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			color: ${colors.tx_text_teriaty};
		}
	}

	.body {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		.caption-accented {
			text-align: start;
		}
		.body-2 {
			margin-bottom: 4px;
		}
	}
`;

export const checkedCss = (colors: IColors) => css`
	position: absolute;
	right: 12px;
	top: 50%;
	transform: translateY(-50%);
	svg {
		color: ${colors.tx_text_primary};
	}
`;
