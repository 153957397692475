/** @jsxRuntime classic */
/** @jsx jsx */
import { useContext, useMemo } from 'react';
import { jsx, css } from '@emotion/react';
import { ETypography, ETypographyColor, IColors, Icons } from '../../../types';
import { Typography } from '../../../atoms/Typography';
import { BnIcon } from '../../../components/icons_v2';
import { SkeletonWrapper } from '../../../components/skeletonWrapper';
import { PaymentContext } from '../context';

const flex = css`
	display: flex;
`;

const textCss = css`
	display: flex;
	flex-direction: column;
	justify-content: center !important;
	gap: 2px;
	.body1,
	.caption {
		align-items: left !important;
	}
`;

const containerCss = (colors: IColors) => css`
	${flex};
	height: 48px;
	width: 48px;
	padding: 12px;
	justify-content: center !important;
	align-items: center;
	border-radius: 2px;
	margin: 10px;
	background: ${colors.bg_background_primary};
`;

const iconCss = css`
	height: 24px !important;
	width: 24px !important;
`;
export const ResourceThumbnail = ({
	resourceType = 'membership',
}: {
	resourceType: 'membership' | 'goods' | 'programSeason' | 'leagueSeason' | 'space' | 'registration';
}) => {
	const { icon } = useMemo(() => {
		let iconAsset;
		switch (resourceType) {
			case 'membership':
				iconAsset = Icons.membership;
				break;
			case 'programSeason':
			case 'leagueSeason':
			case 'registration':
				iconAsset = Icons.whistle;
				break;
			case 'space':
				iconAsset = (Icons as any).court;
				break;
			case 'goods':
			default:
				iconAsset = Icons.pos_equipment;
				break;
		}
		return { icon: iconAsset };
	}, [resourceType]);

	return (
		<div css={containerCss} data-aid="lineItemColumn-details">
			<BnIcon css={iconCss} icon={icon} />
		</div>
	);
};

// TBD - add types
export const LineItemColumn = ({ name, description, secondDescription, img, productType }: any) => {
	const { isLoading } = useContext(PaymentContext);
	return (
		<div css={flex}>
			{img ? (
				<img src={img} />
			) : (
				//  Resource thumbnail
				<ResourceThumbnail resourceType={productType} />
			)}
			<div css={textCss}>
				<SkeletonWrapper isLoading={isLoading} minHeight={6} minWidth={80}>
					<Typography type={ETypography.body1} color={ETypographyColor.primary}>
						{name}
					</Typography>
				</SkeletonWrapper>
				<SkeletonWrapper isLoading={isLoading} minHeight={6} minWidth={80}>
					<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
						{description}
					</Typography>
				</SkeletonWrapper>
				<SkeletonWrapper isLoading={isLoading} minHeight={6} minWidth={80}>
					<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
						{secondDescription}
					</Typography>
				</SkeletonWrapper>
			</div>
		</div>
	);
};
