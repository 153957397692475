import styled from '@emotion/styled';
import { IColors } from '../../types/theme';

export const StyledError = styled.span<any>(
	({ theme: colors }: { theme: IColors }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    margin-top: 4px;
    color: ${colors.tx_error};
`
);
