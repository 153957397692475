import React, { useState, useEffect, useCallback } from "react";
import { useTheme } from "@emotion/react";
import { StyledSegment } from "./style";
import { IOption } from "../Select";
// import { ECalendarMode } from '../../../SACalendar/types';

export const MultipleSegment = ({
  options,
  selected,
  setSelected,
}: {
  options: IOption[];
  selected: IOption;
  setSelected: (v: IOption) => void;
}) => {
  const colors = useTheme();
  const [width, setWidth] = useState<number>(100);

  useEffect(() => {
    const item = document.getElementById(String(selected.value));
    if (item) {
      setWidth(item.offsetLeft);
    }
  }, [selected]);

  return (
    <StyledSegment
      theme={{ ...colors }}
      isPressed={selected.value !== options[0].value}
      width={width}
    >
      <div>
        <span className="background">
          {options.map((item) => (
            <span
              key={item.value}
              id={String(item.value)}
              className="label"
              onClick={() => setSelected(item)}
            >
              {item.label}
            </span>
          ))}
          <span className="toggle">{selected.label}</span>
        </span>
      </div>
    </StyledSegment>
  );
};
