/* @jsx jsx */

import React, { useMemo } from 'react';
import { css, jsx } from '@emotion/react';
import {
	BnIcon,
	Button,
	CHARACHERS,
	ETypography,
	ETypographyColor,
	Icons,
	ModalWindow,
	Typography,
} from '@bondsports/utils';
import { BasicActivityTimesDto } from '@bondsports/types';
import { flexRowCss, gapCss, paddingBottomCss } from '../../../../../styles/utils';
import { formatTime } from '../../../../../lib/dates';
import { ETimeFormats } from '../../../../../types/times';
import { formatActivityTimes, FormattedActivityTimes } from '../../utils';
import { colors_v2 } from '../../../../../styles/theme';

type ScheduleModalProps = {
	isShowing: boolean;
	toggle: () => void;
	activityTimes: BasicActivityTimesDto[];
	labels: {
		title: string;
		close: string;
	};
};

const scheduleModalContainerCss = css`
	min-width: 500px;
	max-width: 500px;
	min-height: 300px;
	max-height: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 36px;
	gap: 20px;
`;

const titleCss = css`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
`;

const listCss = css`
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
	justify-content: start;
	overflow-y: auto;
`;

const rowCss = css`
	display: flex;
	flex-direction: row;
	min-width: 70%;
	justify-content: space-between;
`;

export const ScheduleModal: React.FC<ScheduleModalProps> = ({ isShowing, toggle, labels, activityTimes }) => {
	const formattedActivityTimes: FormattedActivityTimes[] = useMemo(
		() => formatActivityTimes(activityTimes),
		[activityTimes]
	);

	return (
		<ModalWindow isShowing={isShowing} toggle={toggle}>
			<div data-aid="ScheduleModal" datatype="title" data-testid="schedule-title" css={scheduleModalContainerCss}>
				<div css={titleCss}>
					<BnIcon icon={Icons.clock} color={colors_v2.tx_text_primary} />
					<Typography type={ETypography.h3} color={ETypographyColor.primary}>
						{labels.title}
					</Typography>
				</div>
				<div datatype="list" data-testid="schedule-list" css={listCss}>
					{formattedActivityTimes.map((activityTime, index) => (
						<div key={`schedule-${index}`} datatype="row" data-testid="schedule-row" data-index={index} css={rowCss}>
							<Typography type={ETypography.body2} color={ETypographyColor.secondary}>
								{activityTime.openingDays}
							</Typography>
							<div css={[flexRowCss, gapCss(4)]}>
								<Typography type={ETypography.body2} color={ETypographyColor.secondary}>
									{CHARACHERS.DASH}
								</Typography>
								<Typography type={ETypography.body2} color={ETypographyColor.secondary}>
									{`${formatTime(activityTime.open, ETimeFormats.H12_AMPM_UPPERCASE)} - ${formatTime(
										activityTime.close,
										ETimeFormats.H12_AMPM_UPPERCASE
									)}`}
								</Typography>
							</div>
						</div>
					))}
				</div>
				<div css={paddingBottomCss(20)}>
					<Button data-aid="button-ScheduleModal-close" theme="primary" sizer="M" onClick={toggle}>
						{labels.close}
					</Button>
				</div>
			</div>
		</ModalWindow>
	);
};
