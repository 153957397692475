import { ValuesOf } from '../types';

export enum CurrencySymbolEnum {
	USD = '$',
	EUR = '€',
}

export enum ProductPaymentPlanTypeEnum {
	NO_PLAN = 'no_plan',
	ROLLING = 'rolling',
	PAYMENT_DATE = 'payment_date',
	CUSTOM = 'custom',
}

export enum ProductPaymentStatusEnum {
	INACTIVE = 'inactive',
	ACTIVE = 'active',
}

export const CARRIAGE_RETURNS_ALL_PLATFORMS = ['\n', '\r', '\n\r'];

export enum SizeEnum {
	EXTRA_SMALL = 'XS',
	SMALL = 'S',
	MEDIUM = 'M',
	LARGE = 'L',
}

export type TSizeEnum = ValuesOf<typeof SizeEnum>;

export const CHARACHERS = {
	DASH: '-',
	DOT: '•',
	COLON: ':',
	FORWARD_SLASH: '/',
	OPEN_PARENTHESES: '(',
	CLOSE_PARENTHESES: ')',
};
export const N_A = 'n/a';
