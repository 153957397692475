/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Typography } from '../../../atoms';
import { BnIcon } from '../../../components/icons_v2';
import { ETypography, ETypographyColor, IColors, Icons } from '../../../types';

const paymentMethodButtonCss = (colors: IColors) => css`
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: 18px 8px;
	background: ${colors.bg_background_secondary};
	border: 1px solid ${colors.bg_border_primary};
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	cursor: pointer;
	svg {
		color: ${colors.tx_text_teriaty};
	}

	&:hover {
		border: 1px solid ${colors.tx_text_primary};
		svg {
			color: ${colors.tx_text_primary};
		}
	}
	width: 154px;
	height: 80px;
`;

interface Props {
	icon: Icons;
	label: string;
	onClick: () => void;
}
export const AddPaymentMethodBtton = ({ icon, label, onClick }: Props) => {
	return (
		<button data-aid="addPaymentMethodBtton" css={paymentMethodButtonCss} onClick={onClick}>
			<BnIcon icon={icon} />
			<Typography type={ETypography.body2Accented} color={ETypographyColor.primary}>
				{label}
			</Typography>
		</button>
	);
};
