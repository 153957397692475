import { rcEnums } from '../rc.enums';

export enum RCCustomQuestionType {
    TEXT = 'text',
    YES_NO = 'yesNo',
    MULTIPLE_CHOICE = 'multipleChoices',
    NUMERIC = 'numeric',
    DATE = 'date',
    SINGLE_CHOICE = 'singleChoice',
    FILE_UPLOAD = 'fileUpload',
}

export enum RCQuestionTypesEnum {
    OTHER = 'other',
    T_SHIRT_SIZE = 'tshirtSize',
    PHONE_NUMBER = 'phoneNumber',
    EMAIL_ADDRESS = 'emailAddress',
    ADDRESS = 'address',
    BIRTH_DATE = 'birthDate',
    PREFERRED_TEAM = 'preferredTeam',
    WAIVER = 'waiver',
    CUSTOM_WAIVER = 'customWaiver'
}

export interface RCQuestionObject {
    id?: number;
    creatorId?: number;
    creatorType?: rcEnums.shared.ResourceNameTypeEnum;
    questionType: RCQuestionTypesEnum;
    ordinal: number;
    pageOrdinal: number;
    isActive: boolean;
    isMandatory: boolean;
    metaData?: {
        customType?: RCCustomQuestionType;
        text?: string;
        fromDate?: Date;
        toDate?: Date;
        numericFrom?: number;
        numericTo?: number;
        maxLength?: number;
        selectOptions?: {
            text: string;
        }[];
        dateType?: 'range' | 'singleDate';
    };
    question: string;
    toDelete?: boolean;
    questionnaireId?: number;
}
