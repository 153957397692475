import React from 'react';

export const Sort = (props: any) => (
  <svg
    data-aid="svgSort"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 17L6 9H18L12 17Z"
      fill="currentColor"
    />
  </svg>
);
