/* @jsx jsx */

import React, { useEffect, useRef, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { EllipsisContainer } from '../EllipsisContainer';
import {
	alignItemsCss,
	flexGrowCss,
	flexRowCss,
	fullWidthCss,
	gapCss,
	justifyContentCss,
} from '@app/react/styles/utils';
import { Button, ETypography, ETypographyColor, Tooltip, Typography } from '@bondsports/utils';
import { TranslationEn } from '@assets/i18n/en';
import { useWindowSize } from '@app/react/hooks/useWindowResize';

const LABLES = TranslationEn.general.buttons;

type EllipsisWithViewMoreContainerProps = {
	text?: string;
	safetyGap?: number;
	onClick: () => void;
	label?: string;
	color?: ETypographyColor;
	type?: ETypography;
	showOnlyLink?: boolean;
};

const maxWidthCss = (width: number) => css`
	max-width: ${width}px;
`;

const maxContentWidth = css`
	width: max-content;
`;

const SAFETY_OFFSET = 60;

export const EllipsisWithViewMoreContainer: React.FC<EllipsisWithViewMoreContainerProps> = ({
	text,
	onClick,
	safetyGap = SAFETY_OFFSET,
	label,
	color,
	type,
	showOnlyLink = false,
}) => {
	const [isTruncated, setIsTruncated] = useState(false);
	const [maxWidth, setMaxWidth] = useState(100);
	const resourcesTextRef: React.MutableRefObject<HTMLSpanElement> = useRef();
	const containerRef: React.MutableRefObject<HTMLDivElement> = useRef();

	const { width: windowWidth } = useWindowSize();

	useEffect(() => {
		const divElement = containerRef.current;

		if (divElement) {
			setMaxWidth(divElement.offsetWidth);
		}
	}, [windowWidth]);

	useEffect(() => {
		if (resourcesTextRef.current) {
			setIsTruncated(resourcesTextRef.current.offsetWidth > maxWidth);
		}
	}, [maxWidth]);

	return (
		<div ref={containerRef} css={[flexRowCss, gapCss(8), justifyContentCss('space-between')]}>
			{!showOnlyLink && text && (
				<Typography type={type ?? ETypography.body2} color={color ?? ETypographyColor.primary}>
					<span css={maxWidthCss(maxWidth - safetyGap)}>
						<EllipsisContainer>
							<span ref={resourcesTextRef}>{text}</span>
						</EllipsisContainer>
					</span>
				</Typography>
			)}
			<span css={[maxContentWidth, justifyContentCss('end')]}>
				<Button
					data-aid="button-EllipsisWithViewMoreContainer-on"
					sizer="XS"
					theme="basicLine"
					onClick={onClick}
					disabled={!showOnlyLink && !isTruncated}
				>
					<Typography
						type={ETypography.captionLink}
						color={!(!showOnlyLink && !isTruncated) ? ETypographyColor.secondary : ETypographyColor.disabled}
					>
						{label ?? LABLES.viewAll}
					</Typography>
				</Button>
			</span>
		</div>
	);
};
