import React from 'react';

export const Surfing = (props: any) => (
  <svg
    data-aid="svgSurfing"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.7903 8.43574L13.3647 3.73642C13.5979 3.6119 13.8281 3.49232 14.0632 3.38459C14.5314 3.16223 14.9869 2.97643 15.4275 2.83016L16.5754 6.6499L14.7903 8.43574ZM12.3808 4.30765C12.1565 4.44898 11.9343 4.58536 11.715 4.74349C10.7053 5.4511 9.82017 6.19924 9.00615 7.01754C8.25041 7.77161 7.53418 8.56224 6.85945 9.38647C4.95677 11.7623 3.34749 14.406 2.09879 17.2434C2.00395 17.4845 2 17.5013 2 17.5191C2 17.5823 2.04742 17.6347 2.10867 17.6426H2.14522C3.04322 17.8018 3.83057 18.1951 4.46578 18.7564L13.8893 9.32025L12.3808 4.30765ZM5.24622 19.5402C5.80438 20.1707 6.19756 20.9593 6.35167 21.831L6.35563 21.8923C6.36057 21.9328 6.38329 21.9664 6.41589 21.9862C6.43268 21.9951 6.45145 22 6.4722 22C6.49294 22 6.51171 21.9951 6.52851 21.9862C9.59394 20.651 12.2385 19.043 14.6292 17.1327C15.0106 16.8214 15.4591 16.43 15.9036 16.0248L14.24 10.5319L5.24622 19.5402ZM21.1523 2.06325C20.7779 2.02273 20.3442 2 19.9046 2C18.6865 2 17.5099 2.17592 16.3976 2.50403L17.4665 5.7466L21.1523 2.06325ZM17.8409 6.95429L19.3879 12.1033C19.8078 11.4905 20.2158 10.7849 20.5704 10.0486C22.1412 6.71513 22.072 4.03291 21.9337 2.85981L17.8409 6.95429ZM15.1538 9.64244L16.8174 15.1689L16.9952 14.997C17.5416 14.4495 18.0572 13.8703 18.5383 13.2635L16.9399 7.84573L15.1538 9.64244Z"
      fill="currentColor"
    />
  </svg>
);
