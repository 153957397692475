import React from 'react';

export const ProfileFilled = (props: any) => (
  <svg
    data-aid="svgProfileFilled"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 11C14.206 11 16 9.206 16 7C16 4.794 14.206 3 12 3C9.794 3 8 4.794 8 7C8 9.206 9.794 11 12 11Z"
      fill="currentColor"
    />
    <path
      d="M18 20.9999C18.553 20.9999 19 20.5519 19 19.9999C19 16.1399 15.859 12.9999 12 12.9999C8.141 12.9999 5 16.1399 5 19.9999C5 20.5519 5.447 20.9999 6 20.9999H18Z"
      fill="currentColor"
    />
  </svg>
);
