import React from 'react';

export const Cash = (props: any) => (
  <svg
    data-aid="svgCash"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.2831 13.3021C13.9328 13.1781 13.3615 12.97 13.0752 12.7455C13.0578 12.7318 13.0254 12.6502 13.0482 12.529C13.0592 12.4707 13.1095 12.2764 13.2981 12.2195C13.5894 12.1313 13.8659 12.1892 14.0473 12.3236C14.3336 12.5844 14.7771 12.5645 15.039 12.2788C15.3005 11.9934 15.2819 11.5506 14.9981 11.2879C14.9981 11.2879 14.6723 10.9978 14.2065 10.8606V10.8027C14.2065 10.4144 13.8917 10.0996 13.5033 10.0996C13.115 10.0996 12.8002 10.4144 12.8002 10.8027V10.9045C11.5364 11.3641 11.2392 13.093 12.2074 13.8521C12.6485 14.198 13.2789 14.4385 13.8139 14.6278C14.1625 14.7511 14.1979 15.001 14.1663 15.189C14.1311 15.3982 13.9509 15.7531 13.4986 15.7561C13.0298 15.7591 12.9185 15.7419 12.5763 15.5179C12.2514 15.3053 11.8157 15.3962 11.6029 15.7211C11.3903 16.0461 11.4813 16.4819 11.8062 16.6945C12.18 16.9392 12.476 17.0604 12.8002 17.1172V17.1848C12.8002 17.5731 13.115 17.8879 13.5033 17.8879C13.8916 17.8879 14.2064 17.5731 14.2064 17.1848V17.0377C15.91 16.422 16.136 13.9575 14.2831 13.3021Z"
      fill="currentColor"
    />
    <path
      d="M9.13975 14.4313C8.74868 14.5045 8.3913 14.2571 8.31288 13.8791C8.24871 13.5382 8.42754 13.1898 8.79803 13.0691C9.19324 12.9515 9.60536 13.1893 9.69194 13.6044C9.76455 13.9923 9.52117 14.3517 9.13975 14.4313Z"
      fill="currentColor"
    />
    <path
      d="M18.1397 14.4313C17.7487 14.5045 17.3913 14.2571 17.3129 13.8791C17.2485 13.5372 17.4288 13.1894 17.798 13.0691C18.1932 12.9515 18.6054 13.1893 18.6919 13.6044C18.7647 13.9931 18.5205 14.3519 18.1397 14.4313Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.93777 4.93826C3.48475 4.39127 4.22661 4.08398 5.00016 4.08398H16.0002C16.7737 4.08398 17.5156 4.39128 18.0626 4.93826C18.6095 5.48524 18.9168 6.2271 18.9168 7.00065V8.08398H19.0002C20.611 8.08398 21.9168 9.38982 21.9168 11.0006V17.0006C21.9168 18.6115 20.611 19.9173 19.0002 19.9173H8.00016C6.38933 19.9173 5.08349 18.6115 5.08349 17.0006V15.9173H5.00016C4.22661 15.9173 3.48475 15.61 2.93777 15.063C2.39079 14.5161 2.0835 13.7742 2.0835 13.0007V7.00065C2.0835 6.2271 2.39079 5.48524 2.93777 4.93826ZM18.0002 9.91732L17.9983 9.91732H8.00016C7.40185 9.91732 6.91683 10.4023 6.91683 11.0006L6.91683 15.0007L6.91683 17.0006C6.91683 17.599 7.40185 18.084 8.00016 18.084H19.0002C19.5985 18.084 20.0835 17.599 20.0835 17.0006V11.0006C20.0835 10.4023 19.5985 9.91732 19.0002 9.91732H18.0021L18.0002 9.91732ZM17.0835 7.00065V8.08398H8.00016C6.38933 8.08398 5.08349 9.38982 5.08349 11.0006V14.084H5.00016C4.71284 14.084 4.43729 13.9698 4.23413 13.7667C4.03097 13.5635 3.91683 13.288 3.91683 13.0007V7.00065C3.91683 6.71333 4.03097 6.43778 4.23413 6.23462C4.43729 6.03145 4.71284 5.91732 5.00016 5.91732H16.0002C16.2875 5.91732 16.563 6.03145 16.7662 6.23462C16.9694 6.43778 17.0835 6.71333 17.0835 7.00065Z"
      fill="currentColor"
    />
  </svg>
);
