import React from 'react';

export const ClubTeams = (props: any) => (
  <svg
    data-aid="svgClubTeams"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.9728 12.5815C19.9065 12.5616 19.4949 12.3724 19.7538 11.5757H19.7471C20.4176 10.8787 20.9288 9.75015 20.9288 8.64152C20.9288 6.93543 19.8135 6.04255 18.5157 6.04255C17.2179 6.04255 16.1092 6.93543 16.1092 8.64152C16.1092 9.75347 16.6171 10.8887 17.2909 11.5857C17.3871 11.8479 17.3838 12.0504 17.334 12.1998C18.0278 12.7972 19.0335 14.032 18.6883 16.382C18.353 15.3132 17.8485 14.344 17.2212 13.6104C16.6038 12.7707 15.14 12.0106 13.7957 11.5591C13.7161 11.5326 13.2082 11.3002 13.5269 10.3211H13.5202C14.3467 9.46138 14.974 8.07061 14.974 6.70308C14.974 4.60199 13.5999 3.5 12 3.5C10.4001 3.5 9.03259 4.60199 9.03259 6.70308C9.03259 8.07725 9.65993 9.47133 10.4864 10.331C10.8117 11.194 10.2342 11.516 10.1113 11.5591C9.24502 11.8778 8.30567 12.3325 7.59867 12.8503C7.55884 12.8769 7.52565 12.9101 7.48914 12.9366C7.31986 13.0694 7.17049 13.2022 7.0344 13.3383C6.95806 13.4146 6.88171 13.4943 6.80869 13.5772V13.5806C6.16476 14.3141 5.65359 15.2999 5.31171 16.382C4.96651 14.032 5.96892 12.7972 6.66596 12.2031C6.61617 12.0537 6.61285 11.8512 6.70911 11.5857C7.38292 10.8887 7.89077 9.75679 7.89077 8.64152C7.89077 6.93874 6.78214 6.04255 5.48431 6.04255C4.18648 6.04255 3.07121 6.93874 3.07121 8.64152C3.07121 9.75347 3.58238 10.8787 4.25287 11.5791H4.24623C4.50513 12.3757 4.09354 12.5616 4.02716 12.5848C2.6032 13.0628 1 13.9656 1 14.8917C1 15.134 1 16.8567 1 17.099C1 18.3238 5.08268 20.3983 12.0166 20.3983C18.9505 20.3983 23 18.3238 23 17.099C23 16.86 23 15.134 23 14.8917C23 13.9623 21.4001 13.0628 19.9728 12.5815Z"
      fill="currentColor"
    />
  </svg>
);
