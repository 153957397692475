/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { MoreTooltip } from '../MoreTooltip';
import { css, jsx } from '@emotion/react';
import { fitContentCss } from '../../styles/utils';
import { Typography } from '../../atoms';
import { ETypography, ETypographyColor } from '../../types';
import { GroupTypeLabel } from '../../components/Select_v2/SelectedTags/GroupTypeLabel';
import { IOption } from '../../components/Select_v2';

const containerCss = css`
	${fitContentCss};
	display: flex;
	flex-direction: column;
`;
const marginCss = css`
	margin: 2px;
`;

export const MoreOptionsTooltip = ({ moreItems }: { moreItems: IOption[] }) => {
	return (
		<MoreTooltip
			moreItemsCounter={moreItems.length}
			content={
				<div css={containerCss} data-aid="index-MoreOptionsTooltip">
					{moreItems.map(item => {
						const label = item.key ? GroupTypeLabel(item.key, item.label, false) : item.label;

						return (
							<div css={marginCss}>
								<Typography type={ETypography.overline} color={ETypographyColor.secondary}>
									{label}
								</Typography>
							</div>
						);
					})}
				</div>
			}
		/>
	);
};
