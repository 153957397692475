import { useOrganizationTimezone } from '@app/react/hooks/useOrganizationTimezone';
import { useJWTPayload } from '@app/react/hooks/useJWTPayload';
import { useCustomerNotes } from '@app/react/hooks/useCustomerNotes';
import { NotesPopupStepEnum, TNotesPopupActions } from '@bondsports/utils';
import { useCallback, useMemo, useState } from 'react';
import { concatFullName } from '@app/react/lib/utils';

export const useCustomerNotesPopup = (customerId: number) => {
	const [isShowing, setIsShowing] = useState(false);
	const [step, setStep] = useState<NotesPopupStepEnum>();

	const toggle = useCallback(() => {
		setIsShowing(prev => !prev);
	}, []);

	const { timezone } = useOrganizationTimezone();
	const { id: userId, firstName, lastName } = useJWTPayload();
	const { getPaginatedNotes, addCustomerNote, editCustomerNote, archiveCustomerNote } = useCustomerNotes(customerId);

	const notesPopupActions: TNotesPopupActions = useMemo(
		(): TNotesPopupActions => ({
			/* eslint-disable @typescript-eslint/ban-ts-comment */
			// @ts-ignore
			getNotes: getPaginatedNotes,
			// @ts-ignore
			addNote: addCustomerNote,
			// @ts-ignore
			editNote: editCustomerNote,
			archiveNote: archiveCustomerNote,
			/* eslint-enable @typescript-eslint/ban-ts-comment */
		}),
		[getPaginatedNotes, addCustomerNote, editCustomerNote]
	);

	return {
		toggle,
		isShowing,
		timezone,
		creatorId: userId,
		creatorName: concatFullName(firstName, lastName),
		notesPopupActions,
		step,
		setStep,
	};
};
