/* @jsx jsx */
/* @jsxRuntime classic */

import React, { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import { Pagination, PaginationProps } from '../../molecules/Pagination';
import {
	alignItemsCss,
	flexColCss,
	flexGrowCss,
	flexRowCss,
	fullHeightCss,
	gapCss,
	justifyContentCss,
	marginTopCss,
	minHeightCss,
} from '../../styles/utils';
import { LoadingWithSpinner } from '../../molecules/Loading';
import { SuspenseWrapper } from '../../components/SuspenseWrapper';
import { EBrandingColorsOptions } from '../../types';

type PaginatedListProps = {
	children: React.FC<{ data: any; index: number }>;
	data: any[];
	isLoading: boolean;
	labels: {
		loading: {
			title: string;
			description: string;
		};
	};
	gap?: number;
} & PaginationProps;

const containerCss = css`
	display: grid;
	grid-template-rows: 1fr;
	min-height: inherit;
	flex-grow: 1;
`;

const gridRowCss = (row: number) => css`
	grid-row: ${row};
`;

export const PaginatedList: React.FC<PaginatedListProps> = ({
	data,
	pages,
	currentPage,
	setPage,
	children,
	isLoading,
	labels,
	gap,
	maxPagesView,
}) => {
	return (
		<div css={[containerCss]} data-aid="index-PaginatedList">
			<div css={[gridRowCss(1), minHeightCss('inherit')]}>
					<SuspenseWrapper width={30} loading={isLoading}>
						<div css={[flexGrowCss(1), flexColCss, justifyContentCss('start'), gapCss(gap ?? 16)]}>
							{data?.map((item, index) => (
								<Fragment key={index}>{children({ data: item, index })}</Fragment>
							))}
						</div>
					</SuspenseWrapper>
			</div>
			<div css={gridRowCss(2)}>
				<Footer setPage={setPage} currentPage={currentPage} pages={pages} maxPagesView={maxPagesView} />
			</div>
		</div>
	);
};

type FooterProps = {} & PaginationProps;

const Footer: React.FC<FooterProps> = ({ setPage, currentPage, pages, maxPagesView }) => {
	return (
		<div css={[flexRowCss, marginTopCss(16), justifyContentCss('center'), alignItemsCss('center')]}>
			{pages > 1 && (
				<Pagination setPage={setPage} currentPage={currentPage} pages={pages} maxPagesView={maxPagesView} />
			)}
		</div>
	);
};
