import { colors } from 'app/react/styles/theme';
import { css } from '@emotion/react';
import { flex, flexCol, spaceBetweenCss } from 'app/react/styles/utils';

export const imgCss = css`
	width: 100%;
	height: 172px;
	border-radius: 2px 2px 0 0;
`;

export const iconContainerCss = css`
	width: 20px;
	height: 20px;
	color: ${colors.lightText};
	margin-right: 10px;
`;

export const sportItemCss = css`
	display: flex;
	width: 33%;
	padding-top: 12px;
`;
export const scrolleContainerCss = css`
	flex-grow: 1;
	overflow-y: scroll;
`;

export const mainCss = css`
	margin: 2rem;
	margin-top: 0;
	overflow-y: scroll;
	overflow-x: hidden;
`;
export const containerCss = css`
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
`;

export const frameCss = css`
	margin: 0 1rem 2rem 1rem;
	padding: 2rem 0 0 2rem;
`;

export const propertiesMainCss = css`
	${flexCol};
	justify-content: center;
	align-items: center;
`;
export const propertiesCss = css`
	display: flex;
	padding-top: 4px;
`;

export const headerCss = css`
	${spaceBetweenCss};
	padding-bottom: 2rem;
`;
export const createBtnCss = css`
	padding: 0px 4px !important;
`;
export const iconCss = css`
	width: 16px;
	height: 16px;
	color: ${colors.lightText};
`;
export const spaceContainerCss = css`
	${spaceBetweenCss};
	width: 100%;
`;

export const tagCss = css`
	${flex};
	span {
		background: ${colors.statusGreen};
		margin-right: 4px;
		height: 4px;
		width: 4px;
		border-radius: 10px;
	}
`;

export const modalFooterCss = css`
	padding: 16px 40px;
	border-top: 1px solid ${colors.borderSeperator};
	display: flex;
	justify-content: flex-end;
`;
export const modalBtnCss = css`
	margin-right: 16px;
`;
export const tableContainerCss = css`
	height: 500px;
	flex-grow: 1;
	margin: 0 2rem 2rem 2rem;
	text-transform: capitalize;
`;

export const locationIconCss = css`
	color: ${colors.lightText};
	width: 20px;
	height: 20px;
	margin-right: 4px;
`;
export const seperatorCss = css`
	border-right: 1px solid ${colors.borderSeperator};
	height: 36px;
	margin-right: 18px;
	margin-left: 16px;
`;
export const avatarCss = css`
	border-radius: 50%;
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const flexColContainer = css`
	${flexCol};
	align-items: center;
`;

export const modalHeaderCss = css`
	text-align: center;
	padding-top: 36px;
	padding-bottom: 24px;
	border-bottom: 1px solid ${colors.borderSeperator};
	${flexColContainer};
`;

export const flexCenterCss = css`
	${flex};
	justify-content: center;
`;

export const searchFilterCss = css`
	width: 280px;
`;

export const paddingBottomCss = (padding: number) => css`
	padding-bottom: ${padding}px;
`;
