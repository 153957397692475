import React from 'react';

export const CornerDownRight = (props: any) => (
  <svg
    data-aid="SvgCornerDownRight"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.7808 12.3755L15.7808 7.37549C15.4358 6.94349 14.8058 6.87449 14.3758 7.21949C13.9438 7.56349 13.8738 8.19349 14.2188 8.62449L16.9198 12.0005H7.99979C7.44879 12.0005 6.99979 11.5515 6.99979 11.0005V6.00049C6.99979 5.44749 6.55179 5.00049 5.99979 5.00049C5.44779 5.00049 4.99979 5.44749 4.99979 6.00049V11.0005C4.99979 12.6545 6.34579 14.0005 7.99979 14.0005H16.9198L14.2188 17.3755C13.8738 17.8065 13.9438 18.4365 14.3758 18.7805C14.5598 18.9285 14.7808 19.0005 14.9998 19.0005C15.2928 19.0005 15.5838 18.8715 15.7808 18.6245L19.7808 13.6245C20.0728 13.2595 20.0728 12.7405 19.7808 12.3755Z"
      fill="currentColor"
    />
  </svg>
);
