import React from 'react';

export const Indications_approved = (props: any) => (
	<svg data-aid="svg-approved" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.5895 5.23151C10.9122 4.92283 11.4353 4.92283 11.758 5.23151C12.0807 5.54023 12.0807 6.04075 11.758 6.34947L7.20724 10.7685C7.04588 10.9228 6.83442 11 6.62297 11C6.41151 11 6.20001 10.9228 6.0387 10.7685L4.24201 9.04993C3.91933 8.74121 3.91933 8.24069 4.24201 7.93197C4.56465 7.62325 5.08783 7.62325 5.41047 7.93197L6.62289 9.09149L10.5895 5.23151Z"
			fill="currentColor"
		/>
	</svg>
);
