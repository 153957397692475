import * as  React from 'react';

export const BnBack = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.84769 10.0076L5.84465 10.0107L6.78052 10.9465L6.78357 10.9435L12.3188 16.4788L13.2547 15.5429L7.71945 10.0076L13.2623 4.46473L12.3265 3.52885L6.78357 9.07174L6.77291 9.06108L5.83703 9.99695L5.84769 10.0076Z"
      fill="currentColor"
    />
  </svg>
);
