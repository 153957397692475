/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { NotesPopupLabels } from '../../../types/notes';
import { ETypography, ETypographyColor } from '../../../types';
import { Typography } from '../../../atoms';
import { Button } from '../../../components/Button';
import {
	alignItemsCss,
	flexColCss,
	flexRowCss,
	gapCss,
	justifyContentCss,
	marginBottomCss,
	marginTopCss,
} from '../../../styles/utils';

export type ArchiveNoteProps = {
	onCancel: () => void;
	onClickedArchive: () => void;
	labels: NotesPopupLabels['archive'];
};

export const ArchiveNote: React.FC<ArchiveNoteProps> = ({ onClickedArchive, onCancel, labels }) => {
	return (
		<div
			css={[flexColCss, marginTopCss(24), marginBottomCss(32), gapCss(24), alignItemsCss('center')]}
			data-aid="index-ArchiveNote"
		>
			<Typography type={ETypography.body2} color={ETypographyColor.secondary}>
				{labels.description}
			</Typography>
			<div css={[flexRowCss, justifyContentCss('center'), gapCss(12)]}>
				<Button data-aid="button-ArchiveNote-cancel" theme={'secondary'} sizer={'M'} onClick={onCancel}>
					{labels.buttons.cancel}
				</Button>
				<Button data-aid="button-ArchiveNote-confirm" theme={'primary'} sizer={'M'} onClick={onClickedArchive}>
					{labels.buttons.confirm}
				</Button>
			</div>
		</div>
	);
};
