/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { LoadingWithSpinner } from '../Loading';
import { Typography } from '../../atoms';
import { ETypography, ETypographyColor, IColors } from '../../types';
import { useColors } from '../../hooks/useColors';

const containerCss = (colors: IColors) => css`
	display: flex;
	flex-direction: column;
	width: 440px;

	header {
		padding: 36px 40px 24px 40px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		align-items: center;
		border-bottom: 1px solid ${colors.bg_border_seperator};

		.h4 {
			text-align: center;
		}
	}
`;
const wrapperCss = css`
	height: 235px;
	padding-top: 70px;
`;

interface Props {
	labels: { title: string; loading: { title: string; description: string } };
}

export const LoadingModalBody = ({ labels }: Props) => {
	const { colors } = useColors();
	return (
		<div css={containerCss(colors)} data-aid="LoadingModaBody-Modals">
			<header>
				<Typography type={ETypography.h4} color={ETypographyColor.primary}>
					{labels.title}
				</Typography>
			</header>

			<div css={wrapperCss}>
				<LoadingWithSpinner labels={labels.loading} />
			</div>
		</div>
	);
};
