import React from 'react';

export const Bell = (props: any) => (
  <svg
    data-aid="svgBell"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7196 13.404L20.5206 15.208C20.9896 15.677 21.1276 16.377 20.8726 16.991C20.6186 17.604 20.0266 18 19.3626 18H15.9996V18.341C15.9996 20.359 14.2056 22 11.9996 22C9.7936 22 7.9996 20.359 7.9996 18.341V18H4.6366C3.9726 18 3.3796 17.604 3.1246 16.99C2.8706 16.377 3.0096 15.678 3.4786 15.208L5.2796 13.404L5.2806 8.72703C5.2806 6.79303 6.1186 4.94903 7.5826 3.66703C9.0376 2.39103 10.9776 1.80403 12.8996 2.06003C16.2176 2.49903 18.7196 5.45603 18.7196 8.93703V13.404ZM5.5146 16.0001L6.6946 14.8181C7.0726 14.4401 7.2806 13.9381 7.2806 13.4041V8.72708C7.2806 7.37008 7.8706 6.07308 8.9006 5.17108C9.9386 4.26108 11.2606 3.86108 12.6376 4.04208C14.9646 4.35108 16.7196 6.45508 16.7196 8.93708V13.4041C16.7196 13.9381 16.9276 14.4401 17.3046 14.8171L18.4856 16.0001H5.5146ZM13.9996 18.341C13.9996 19.24 13.0836 20 11.9996 20C10.9156 20 9.99962 19.24 9.99962 18.341V18H13.9996V18.341Z"
      fill="currentColor"
    />
  </svg>
);
