import React from 'react';

export const Fitness = (props: any) => (
  <svg
    data-aid="svgFitness"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.7688 8H16.8845C16.4635 8 16.1266 8.26667 16.1266 8.6V11H7.87392V8.6C7.87392 8.26667 7.53707 8 7.11601 8H6.23179C5.81073 8 5.47389 8.26667 5.47389 8.6V15.4C5.47389 15.7333 5.81073 16 6.23179 16H7.11601C7.53707 16 7.87392 15.7333 7.87392 15.4V13H16.1266V15.4C16.1266 15.7333 16.4635 16 16.8845 16H17.7688C18.1898 16 18.5267 15.7333 18.5267 15.4V8.6C18.5267 8.26667 18.1898 8 17.7688 8Z"
      fill="currentColor"
    />
    <path
      d="M3.64212 9.35H2.7579C2.33685 9.35 2 9.61667 2 9.95V14.0333C2 14.3667 2.33685 14.6333 2.7579 14.6333H3.64212C4.06318 14.6333 4.40003 14.3667 4.40003 14.0333V9.96667C4.40003 9.63334 4.06318 9.35 3.64212 9.35Z"
      fill="currentColor"
    />
    <path
      d="M21.2421 9.35H20.3579C19.9368 9.35 19.6 9.61667 19.6 9.95V14.0333C19.6 14.3667 19.9368 14.6333 20.3579 14.6333H21.2421C21.6632 14.6333 22 14.3667 22 14.0333V9.96667C22 9.63334 21.6632 9.35 21.2421 9.35Z"
      fill="currentColor"
    />
  </svg>
);
