/** @jsxRuntime classic */
/** @jsx jsx */

import { FC, useMemo } from 'react';
import { jsx, useTheme } from '@emotion/react';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { headerComponentContainer, headerContainer, headerContent } from '../../styles';
import { IMonthlyHeaderContainerProps } from '../../types';
import { monthlyDatesHeaderContainer } from '../styles';
import { useMiddlewareContext } from '../../hooks/useMiddlewareContext';

dayjs.extend(isoWeek);

export const MonthlyHeaderContainer: FC<IMonthlyHeaderContainerProps> = ({
	width,
	headerRef,
	space,
	ResourceGroupHeader,
}) => {
	const colors = useTheme();
	const { options } = useMiddlewareContext();
	const { date } = options;
	const dayShift = options?.isSundayFirstDay ? 1 : 0;

	const dates = useMemo(() => {
		let result = [] as dayjs.Dayjs[];
		for (let i = 1; i < 8; i++) {
			result.push(dayjs(date).isoWeekday(i - dayShift));
		}
		return result;
	}, [date, dayShift]);

	return (
		<div css={headerContainer(width)} data-aid="MonthlyHeaderContainer-monthly">
			<div ref={headerRef} css={headerContent()}>
				<div css={headerComponentContainer(1, 1, true)}>
					<ResourceGroupHeader state={space} />
					<div css={monthlyDatesHeaderContainer(colors)}>
						{dates.map(date => (
							<div key={date.toString()}>{date.format('ddd')}</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
