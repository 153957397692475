/* @jsx jsx */

import React, { Fragment, useContext } from 'react';
import { css, jsx } from '@emotion/react';
import { ActivityViewLayout } from '../layouts/ActivityViewLayout';
import { ETypography, ETypographyColor, PaginatedList, Typography } from '@bondsports/utils';
import { CustomerActivitiesContext } from '../customerActivitiesContext';
import {
	alignItemsCss,
	colorCss,
	flexCol,
	flexGrowCss,
	fullHeightCss,
	fullWidthCss,
	justifyContentCss,
	marginBottomCss,
} from '../../../../styles/utils';
import { PaginationResultDto } from '@bondsports/types';
import { colors_v2 } from '../../../../styles/theme';
import { TCustomerActivitiesLabels } from '../types/types';
import { ActivitiesEnum } from '../types/enums';
import { useActivityView } from '../hooks/useActivityView';

type ActivityViewProps<T> = {
	getData: (page: number, options: { signal: AbortSignal }) => Promise<PaginationResultDto<T>>;
	children: React.FC<{ data: T; index: number }>;
};

const minHeightCss = css`
	min-height: 35vh;
`;

const errorCss = css`
	${flexCol};
	${justifyContentCss('center')};
	${alignItemsCss('center')};
	${fullWidthCss};
	${fullHeightCss};
	${flexGrowCss(1)};
`;

const listCss = css`
	display: flex;
	${fullWidthCss};
	${marginBottomCss(20)};
	${minHeightCss};
	${flexGrowCss(1)};
`;

export const ActivityView = <T,>({ getData, children }: ActivityViewProps<T>): React.ReactElement => {
	const { labels, selectedActivity } = useContext(CustomerActivitiesContext);

	const { data, setPage, currentPage, totalPages, isLoading, error } = useActivityView(getData);

	return (
		<ActivityViewLayout
			Header={
				<Fragment>
					<Typography
						datatype="title"
						data-testid="activity-title"
						type={ETypography.body1Accented}
						color={ETypographyColor.primary}
					>
						{labels.activities[selectedActivity].title}
					</Typography>
				</Fragment>
			}
			MainContent={
				<div datatype="paginated-list" data-testid="punch-pass-list" css={listCss}>
					{!error ? (
						<List
							data={data}
							isLoading={isLoading}
							labels={labels}
							setPage={setPage}
							currentPage={currentPage}
							totalPages={totalPages}
							selectedActivity={selectedActivity}
						>
							{children}
						</List>
					) : (
						<div data-aid="activityVire-errors" css={errorCss}>
							<Typography type={ETypography.body2}>
								<span css={colorCss(colors_v2.bg_error)}>{labels.activities[selectedActivity].error}</span>
							</Typography>
						</div>
					)}
				</div>
			}
		/>
	);
};

type PunchPassPaginatedListProps = {
	children: React.FC<{ data: any; index: number }>;
	data?: any[];
	isLoading: boolean;
	labels: TCustomerActivitiesLabels;
	selectedActivity: ActivitiesEnum;
	setPage: (page: number) => void;
	totalPages: number;
	currentPage: number;
};

const List: React.FC<PunchPassPaginatedListProps> = ({
	children,
	data,
	isLoading,
	labels,
	totalPages,
	setPage,
	currentPage,
	selectedActivity,
}) => {
	if (!isLoading && data && !data.length) {
		return (
			<div data-aid="ActivityView-labels" css={[flexCol, justifyContentCss('center'), alignItemsCss('center'), fullWidthCss, flexGrowCss(1)]}>
				<Typography type={ETypography.body2} color={ETypographyColor.primary}>
					{labels.activities[selectedActivity].noData}
				</Typography>
			</div>
		);
	}

	return (
		<PaginatedList
			data={data}
			isLoading={isLoading}
			labels={labels.util}
			pages={totalPages}
			setPage={setPage}
			currentPage={currentPage}
			maxPagesView={5}
		>
			{children}
		</PaginatedList>
	);
};
