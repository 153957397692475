import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LeaguesService } from '@app/shared/services/leagues/leagues.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

declare let window;

@Injectable()
export class PaymentsService {
	constructor(private http: HttpClient, private leaguesService: LeaguesService) {}

	getAuthLink(
		userId,
		stripeReturn
	): Observable<{
		missingBank: boolean;
		expire: Date;
		url: string;
		id: string;
		status: boolean;
	}> {
		const arr = window.location.href.split('/');
		const baseUrl = arr[0] + '//' + arr[2];
		const redirectUrl = baseUrl + '/client/general/settings/billing?stripeReturn=true';
		return this.http
			.get<any>(
				`${environment.CS_URLS.API_ROOT_V4}/payment/organization/${
					this.leaguesService.currentOrganization.id
				}/account?callbackUrl=${encodeURIComponent(redirectUrl)}&userId=${userId}
				${stripeReturn === 'true' ? '&stripeReturn=true' : ''}`
			)
			.pipe(map(response => response));
	}

	createTestAccount(userId): Observable<{
		url: string;
		id: string;
		status: boolean;
		updateLink: string;
	}> {
		return this.http
			.get<any>(
				`${environment.CS_URLS.API_ROOT_V4}/payment/organization/${this.leaguesService.currentOrganization.id}/create-test-account?userId=${userId}`
			)
			.pipe(map(response => response));
	}

	/**
	 * After the proccess of authorization finished on braintree side
	 */
	authorizeAccount(code: string, merchantId: number, state: string) {
		return this.http
			.get<any>(
				`${environment.CS_URLS.API_ROOT}/payments/connect-url-callback?provider=stripe&code=${code}&state=${state}&merchantId=${merchantId}`
			)
			.pipe(map(response => response));
	}

	switchToStripePendingStatus(organizationId: number) {
		return this.http
			.post<any>(`${environment.CS_URLS.API_ROOT}/payments/connect-url/pending`, {
				creatorType: 'organization',
				creatorId: organizationId,
			})
			.pipe(map(response => response));
	}

	getAccountStatus(organizationId: number) {
		return this.http
			.get<any>(`${environment.CS_URLS.API_ROOT_V4}/payment/organization/${organizationId}/account/check-stripe-status`)
			.pipe(map(response => response));
	}

	updateBankDetails(organizationId: number, data) {
		return this.http
			.post<any>(
				`${environment.CS_URLS.API_ROOT_V4}/payment/organization/${organizationId}/account/update-bank-details`,
				data
			)
			.pipe(map(response => response));
	}

	getInvoiceDetailsByPaymentId(paymentId: number) {
		return this.http
			.get<any>(`${environment.CS_URLS.API_ROOT_V4}/payment/${paymentId}/invoice`)
			.pipe(map(response => response));
	}
}
