import React from "react";

export const Twitter = (props: any) => (
  <svg
    data-aid="SvgTwitter"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.43263 19.9973C8.45863 19.9993 8.48663 20.0003 8.51263 20.0003C15.3596 20.0003 19.5076 14.8333 19.6756 9.72831C19.9976 9.31731 20.6356 8.45931 20.9586 7.12831C21.0866 6.60531 20.9116 6.06831 20.5006 5.72731C20.0886 5.38331 19.5236 5.30931 19.0266 5.53231C18.6476 5.70231 18.3336 5.52831 18.2496 5.43231C17.4256 4.54731 16.3076 4.03931 15.1026 4.00231C13.8986 3.96631 12.7516 4.40231 11.8746 5.23531C11.0136 6.05331 10.5176 7.17631 10.4656 8.34131C8.66563 8.20831 7.13063 7.33331 5.59463 5.59231C5.23463 5.18331 4.68963 5.02331 4.16363 5.18331C3.64563 5.34031 3.26263 5.78931 3.18863 6.32731C2.93763 8.16531 2.83563 12.5053 6.71263 15.4043C6.08463 15.8133 5.21763 16.1193 4.17263 16.2913C3.56863 16.3893 3.10263 16.8653 3.01463 17.4723C2.92763 18.0723 3.23463 18.6503 3.77763 18.9143C5.22163 19.6133 6.82963 19.9863 8.43263 19.9973ZM8.51263 17.9973C8.53663 17.9973 8.56263 17.9983 8.58663 18.0003C14.2816 17.9633 17.6806 13.6123 17.6806 9.40631C17.6806 9.02431 17.8766 8.77731 18.0506 8.55831L18.0565 8.5509C18.2146 8.35074 18.4563 8.04487 18.6716 7.60631C17.8786 7.59831 17.1706 7.24631 16.7586 6.76431C16.3276 6.30331 15.7086 6.02131 15.0406 6.00131C14.3966 5.97531 13.7396 6.22331 13.2516 6.68631C12.6016 7.30331 12.3236 8.23431 12.5256 9.11431L12.7866 10.2533L11.6206 10.3353C8.60463 10.5613 6.51463 9.27031 5.07963 7.93431C5.08563 9.95431 5.75663 12.6493 8.80663 14.3873L9.76463 14.9333L9.12763 15.8343C8.46463 16.7733 7.46663 17.3723 6.45063 17.7553C7.12163 17.9153 7.81163 17.9973 8.49963 17.9973H8.51263Z"
      fill="currentColor"
    />
  </svg>
);
