/** @jsx jsx */
import React, { useState, createContext, ReactNode } from 'react';
import { jsx, css } from '@emotion/react';

interface PagContextObj {
	isAddingMember: boolean;
	setIsAddingMember: (v: boolean) => void;
	isMemberAdded: boolean;
	setIsMemberAdded: (v: boolean) => void;
	isCancelClicked: boolean;
	setIsCncelClicked: (v: boolean) => void;
	isFormTouched: boolean;
	setIsFormTouched: (v: boolean) => void;
}

export const PageContext = createContext<PagContextObj>({
	isAddingMember: false,
	setIsAddingMember: (v: boolean) => {},
	isMemberAdded: false,
	setIsMemberAdded: (v: boolean) => {},
	isCancelClicked: false,
	setIsCncelClicked: (v: boolean) => {},
	isFormTouched: false,
	setIsFormTouched: (v: boolean) => {},
});

interface Props {
	children: ReactNode;
}

export const PageContextProvider = ({ children }: Props) => {
	const [isAddingMember, setIsAddingMember] = useState(false);
	const [isMemberAdded, setIsMemberAdded] = useState(false);
	const [isCancelClicked, setIsCncelClicked] = useState(false);
	const [isFormTouched, setIsFormTouched] = useState(false);
	return (
		<PageContext.Provider
			value={{
				isAddingMember,
				setIsAddingMember,
				isMemberAdded,
				setIsMemberAdded,
				isCancelClicked,
				setIsCncelClicked,
				isFormTouched,
				setIsFormTouched,
			}}
		>
			{children}
		</PageContext.Provider>
	);
};
