import styled from "@emotion/styled";
import { IColors } from "../../types/theme";

export const StyledTimeInput = styled.div<any>(
  ({ theme: colors }: { theme: IColors }) => `
    display:flex;
    width:100%;
    div:first-of-type{
      min-width: 40px !important;
    }
    `
);
