import React from 'react';

export const Edit_filled = (props: any) => (
  <svg
    data-aid="svgEdit_filled"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.69722 20.9987H3.00006V16.3015L13.5332 5.76841L18.2301 10.4654L19.4756 9.21986L14.7787 4.52289L15.3301 3.97147C15.953 3.34859 16.7978 2.99866 17.6787 2.99866C18.5596 2.99866 19.4044 3.34859 20.0272 3.97147C20.6501 4.59435 21.0001 5.43916 21.0001 6.32005C21.0001 7.20093 20.6501 8.04574 20.0272 8.66862L7.69722 20.9987Z"
      fill="currentColor"
    />
  </svg>
);
