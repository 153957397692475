import React from 'react';

export const EditOutline = (props: any) => (
  <svg
    data-aid="svgEditOutLine"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3065 3.62115L20.3748 5.69313C21.2088 6.53074 21.2088 7.89002 20.3748 8.72396L9.90104 19.2124C9.80919 19.3043 9.69898 19.3704 9.57407 19.4035L3.95328 20.9868C3.79163 21.0236 3.40957 20.9905 3.21853 20.7958C3.03117 20.6011 2.95402 20.3219 3.0275 20.0611L4.6072 14.4366C4.64026 14.3117 4.70639 14.2015 4.79823 14.1096L15.2794 3.62115C16.4439 2.45291 17.9024 3.21704 18.3065 3.62115ZM18.1231 8.85256L19.3134 7.66227C19.5632 7.41246 19.5632 7.00468 19.3134 6.75486L17.2451 4.68288C17.0026 4.44042 16.5801 4.44042 16.3377 4.68288L15.1474 5.87317L18.1231 8.85256ZM6.39258 14.6386L9.36829 17.618L17.0611 9.91423L14.0854 6.93484L6.39258 14.6386ZM5.70599 16.0751L7.93226 18.305L4.83164 19.1794L5.70599 16.0751Z"
      fill="currentColor"
    />
  </svg>
);
