/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import { useColors } from '../hooks/useColors';
import { IOption, Icons } from '../types';
import { useTypography } from '../hooks/useTypography';
import { labelTagCss } from './tags/style';
import { BnIcon } from '../components/icons_v2';
import { ReactNode } from 'react';

export const LabelTag = ({
	state,
	handleRemove,
	size = 'S',
	disabled,
}: {
	state: Omit<IOption, 'label'> & { label: string | ReactNode };
	handleRemove?: (value: string) => void;
	size?: 'S' | 'XS';
	disabled?: boolean;
}) => {
	const { colors } = useColors();
	const { typography } = useTypography();
	return (
		<div css={labelTagCss(colors, typography, size, disabled)} data-aid="LabelTag">
			{state.icon && <BnIcon icon={state.icon} />}
			{state.label}
			{handleRemove && (
				<button
					data-aid="button-LabelTag-close"
					type="button"
					disabled={disabled}
					onClick={() => handleRemove(String(state.value))}
				>
					<BnIcon icon={Icons.close} />
				</button>
			)}
		</div>
	);
};
