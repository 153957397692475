import { createContext, Dispatch, SetStateAction } from 'react';

const initialPayment = {
	id: 139023,
	paymentStatus: 'paid',
};

const labels: { [key: string]: any } = {
	header: {
		title: 'Payment no.',
	},
};

export const PaymentContext = createContext<{
	state: any;
	labels: any;
	isLoading: boolean;
	setLoading: (v: boolean) => void;
}>({
	state: initialPayment,
	labels,
	isLoading: true,
	setLoading: () => {},
});
