import { LineItems, PaymentStatusEnum } from '@bondsports/types';

export const getLineItemPaymentStatus = (lineItem: Partial<LineItems>) => {
	const { isRefunded, isVoided, paymentStatus } = lineItem;
	if (isRefunded) {
		return PaymentStatusEnum.REFUNDED;
	} else if (isVoided) {
		return PaymentStatusEnum.VOID;
	}
	return paymentStatus;
};
