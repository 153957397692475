import _ from 'lodash';
import React, { Fragment } from 'react';
import { Field as FinalField, useForm } from 'react-final-form';
import { ColorPicker, IColorCode } from '@bondsports/utils';
import { IFormProps } from './FormSelect';

export const FormColorPicker = ({
	...props
}: IFormProps & React.SelectHTMLAttributes<HTMLSelectElement> & { colorsCodes: IColorCode[] }) => {
	const form = useForm();

	const onSelect = (val: string) => {
		props.onSelecting(props.name, val);
	};

	return (
		<FinalField
			name={props.name}
			subscription={{ touched: true }}
			validate={val => {
				let res = undefined;
				if (props.required) {
					if (!val) {
						const errors = {};
						errors[props.name] = 'required';
						res = errors;
					}
				}
				return res;
			}}
			displayEmpty
		>
			{({ input }) => {
				const value = _.get(form.getState().values, props.name);

				const newProps = { ...props };

				return (
					<Fragment>
						<ColorPicker
							{...input}
							{...(newProps as any)}
							value={value ? String(value) : undefined}
							onSelect={onSelect}
							colorsCodes={props.colorsCodes}
							size={'s'}
						/>
					</Fragment>
				);
			}}
		</FinalField>
	);
};
