import React from 'react';

export const Football = (props: any) => (
  <svg
    data-aid="svgFootball"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.75265 21.8573C5.92759 22.2296 3.56179 21.8328 2.86422 21.1352C2.18404 20.455 1.79025 18.1874 2.11653 15.4545C2.75784 16.7361 3.62623 17.9553 4.71554 19.0446C5.93066 20.2597 7.30739 21.2017 8.75265 21.8573ZM21.9369 8.02977C22.1589 5.52998 21.7722 3.5007 21.136 2.8645C20.5152 2.24365 18.5718 1.86111 16.16 2.04726C17.2892 2.663 18.3631 3.45774 19.3328 4.4284C20.4252 5.52078 21.2956 6.74408 21.9369 8.02977ZM11.4775 21.2743C13.6571 20.6228 15.8818 19.4864 17.685 17.6842C19.6765 15.6928 20.8548 13.1868 21.4644 10.7955C20.9519 8.89099 19.8953 7.02024 18.3181 5.44304C16.8933 4.01825 15.2282 3.01793 13.5149 2.46049C11.0305 3.04554 8.39261 4.23815 6.31627 6.31551C4.42403 8.20774 3.26619 10.5653 2.63204 12.8483C3.16084 14.6935 4.20106 16.5008 5.73121 18.0309C7.41683 19.7166 9.43896 20.8079 11.4785 21.2753L11.4775 21.2743ZM9.85526 13.4006L11.276 11.9799L9.29168 9.99564C9.01142 9.71538 9.01142 9.26125 9.29168 8.98099C9.57193 8.70074 10.0261 8.70074 10.3063 8.98099L12.2906 10.9653L13.5927 9.66322L11.6084 7.67894C11.3281 7.39868 11.3281 6.94455 11.6084 6.6643C11.8886 6.38404 12.3428 6.38404 12.623 6.6643L14.6073 8.64858L15.9329 7.32299C16.2132 7.04274 16.6673 7.04274 16.9476 7.32299C17.2278 7.60325 17.2278 8.05738 16.9476 8.33764L15.622 9.66322L17.4958 11.537C17.776 11.8173 17.776 12.2714 17.4958 12.5517C17.2155 12.8319 16.7614 12.8319 16.4811 12.5517L14.6073 10.6779L13.3053 11.9799L15.1791 13.8537C15.4593 14.134 15.4593 14.5881 15.1791 14.8684C14.8988 15.1486 14.4447 15.1486 14.1644 14.8684L12.2906 12.9946L10.8699 14.4153L12.7437 16.2891C13.024 16.5693 13.024 17.0235 12.7437 17.3037C12.4635 17.584 12.0093 17.584 11.7291 17.3037L9.85526 15.4299L8.62377 16.6614C8.34351 16.9416 7.88938 16.9416 7.60912 16.6614C7.32887 16.3811 7.32887 15.927 7.60912 15.6467L8.84061 14.4153L6.85632 12.431C6.57606 12.1507 6.57606 11.6966 6.85632 11.4163C7.13657 11.1361 7.59071 11.1361 7.87097 11.4163L9.85526 13.4006Z"
      fill="currentColor"
    />
  </svg>
);
