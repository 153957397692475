import React from 'react';

export const PunchPass = (props: any) => {
	return (
		<svg data-aid="SvgPunchPass" fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="currentColor" {...props}>
			<g fill="currentColor">
				<path d="m22.3135 13.002c.2638 0 .4776-.2239.4776-.5 0-.2762-.2138-.5-.4776-.5s-.4776.2238-.4776.5c0 .2761.2138.5.4776.5z" />
				<path d="m22.7911 14.502c0 .2761-.2138.5-.4776.5s-.4776-.2239-.4776-.5c0-.2762.2138-.5.4776-.5s.4776.2238.4776.5z" />
				<path d="m22.3135 17.002c.2638 0 .4776-.2239.4776-.5 0-.2762-.2138-.5-.4776-.5s-.4776.2238-.4776.5c0 .2761.2138.5.4776.5z" />
				<path d="m22.7911 18.502c0 .2761-.2138.5-.4776.5s-.4776-.2239-.4776-.5c0-.2762.2138-.5.4776-.5s.4776.2238.4776.5z" />
				<path d="m22.3135 21.002c.2638 0 .4776-.2239.4776-.5 0-.2762-.2138-.5-.4776-.5s-.4776.2238-.4776.5c0 .2761.2138.5.4776.5z" />
				<path d="m22.7911 22.502c0 .2761-.2138.5-.4776.5s-.4776-.2239-.4776-.5c0-.2762.2138-.5.4776-.5s.4776.2238.4776.5z" />
				<path d="m22.3135 25.002c.2638 0 .4776-.2239.4776-.5 0-.2762-.2138-.5-.4776-.5s-.4776.2238-.4776.5c0 .2761.2138.5.4776.5z" />
				<path d="m10 23.5c0 .2761.2239.5.5.5s.5-.2239.5-.5v-5c0-.2761-.2239-.5-.5-.5s-.5.2239-.5.5z" />
				<path d="m12 23.5c0 .2761.2239.5.5.5s.5-.2239.5-.5v-10c0-.2761-.2239-.5-.5-.5s-.5.2239-.5.5z" />
				<path
					clipRule="evenodd"
					d="m27.3444 7.23731c-.1393-.81824-.8875-1.36195-1.6684-1.21238l-20.54542 3.93494c-.27135.05193-.51082.18083-.7016.36123.34536-.2032.74388-.3191 1.16826-.3191h21.01476c1.3188 0 2.388 1.1193 2.388 2.5v3.5c-1.3189 0-2.388 1.1193-2.388 2.5s1.0691 2.5 2.388 2.5v3.5c0 1.3807-1.0692 2.5-2.388 2.5h-21.01476c-1.31888 0-2.38804-1.1193-2.38804-2.5v-3.5c1.31888 0 2.38804-1.1193 2.38804-2.5s-1.06916-2.5-2.38804-2.5v-3.1201l-.00267-.0144-.1691-.9936c-.2306-1.3548.62814-2.65004 1.92125-2.8977l20.54542-3.93494c1.3015-.24929 2.5486.6569 2.7807 2.02064l.2302 1.35238c.0463.27186-.1267.53151-.3864.57995s-.5077-.13267-.554-.40453zm-23.17999 14.61969v2.645c0 .8284.6415 1.5 1.43283 1.5h21.01476c.7913 0 1.4328-.6716 1.4328-1.5v-2.645c-1.381-.4302-2.3881-1.7695-2.3881-3.355s1.0071-2.9248 2.3881-3.3551v-2.6449c0-.8284-.6415-1.5-1.4328-1.5h-21.01476c-.79133 0-1.43283.6716-1.43283 1.5v2.6449c1.381.4303 2.38804 1.7696 2.38804 3.3551s-1.00704 2.9248-2.38804 3.355z"
					fillRule="evenodd"
				/>
			</g>
		</svg>
	);
};
