export enum MorMenuEnum {
	DELETE = 'delete',
	EDIT = 'edit',
}

export enum SortCulumnsEnum {
	CODE = 'code',
	NAME = 'name',
	ACTIVE = 'active',
	FAVORITE = 'favorite',
}
