/** @jsx jsx */
import { Capitalize } from 'app/react/lib/form';
import React from 'react';
import { jsx, css } from '@emotion/react';
import { cellCss } from '../styles';
import { PaymentMethodMapper } from '@bondsports/utils';

export const PaymentMethod = ({ row, index, column, isLast }) => {
	return (
		<td
			className="TableCell-root TableCell-body"
			css={cellCss({ isAction: column.action })}
			key={column.id}
			onClick={() => {
				if (column.action) {
					column.action(row);
				}
			}}
		>
			<PaymentMethodMapper noIcons state={row} />
		</td>
	);
};
