import React from 'react';

export const Attach = (props: any) => (
  <svg
    data-aid="svgAttach"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.86154 19.125C6.06354 20.334 7.63754 21 9.29354 21C10.8315 21 12.2625 20.412 13.3225 19.346L20.7095 11.916C21.0985 11.524 21.0965 10.892 20.7055 10.502C20.3135 10.112 19.6805 10.115 19.2915 10.506L11.9045 17.936C11.2225 18.622 10.2945 19 9.29354 19C8.17354 19 7.10354 18.544 6.28054 17.715C4.66454 16.09 4.56654 13.544 6.06054 12.041L13.4465 4.611C14.2785 3.775 15.8175 3.847 16.7245 4.759C17.6725 5.713 17.7375 7.198 16.8705 8.071L9.47554 15.5C9.34554 15.631 9.18754 15.658 9.07754 15.658C8.88654 15.658 8.69254 15.574 8.54654 15.427C8.26554 15.145 8.23254 14.718 8.47254 14.476L15.2965 7.62C15.6865 7.229 15.6845 6.596 15.2935 6.206C14.9015 5.816 14.2695 5.818 13.8795 6.21L7.05454 13.065C6.04154 14.085 6.07454 15.776 7.12754 16.837C7.65354 17.366 8.34654 17.658 9.07754 17.658C9.76954 17.658 10.4145 17.393 10.8935 16.91L18.2885 9.481C19.9295 7.831 19.8635 5.08 18.1425 3.349C17.2775 2.479 16.1435 2 14.9505 2C13.8355 2 12.7975 2.427 12.0285 3.201L4.64254 10.631C2.37554 12.912 2.47354 16.722 4.86154 19.125Z"
      fill="currentColor"
    />
  </svg>
);
