/** @jsxRuntime classic */
/** @jsx jsx */
import { FC, Fragment } from 'react';
import { jsx } from '@emotion/react';
import { QuestionDto } from '@bondsports/types';
import { ETypography, ETypographyColor, Icons } from '../../../types';
import { CHARACHERS } from '../../../lib/constants';
import { sanitize } from '../../../lib/form';
import { i18n } from '../../../lib/i18n';
import { Typography } from '../../../atoms';
import { BnIcon } from '../../../components/icons_v2';
import { answerCss } from '../styles';

interface Props {
	question: QuestionDto;
	answer: any;
}
export const FormQuestion: FC<Props> = (props: Props) => {
	const { question, answer } = props;

	const labels = i18n.applicationTemplate;
	const isColumn = Boolean(question.metadata?.text);

	return (
		<div css={answerCss(isColumn)}>
			<Typography color={ETypographyColor.primary} type={ETypography.body1}>
				{question.question}:
			</Typography>

			{isColumn && (
				<Fragment>
					{question.metadata.text.includes('&nbsp;') || question.metadata.text.includes('<p>') ? (
						<div className="textData" dangerouslySetInnerHTML={{ __html: sanitize(question.metadata.text) }} />
					) : (
						<div className="textData">{question.metadata.text}</div>
					)}
				</Fragment>
			)}
			<span className="answer">
				{isColumn && labels.customerAgreementColumn}
				{answer === true ? (
					<BnIcon icon={Icons.check} />
				) : answer === false || answer === null ? (
					CHARACHERS.DASH
				) : (
					answer
				)}
			</span>
		</div>
	);
};
