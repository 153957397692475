import React from 'react';

export const Remove = (props: any) => (
  <svg
    data-aid="svgRmove"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 7.0372 7.0372 3 12 3C16.9628 3 21 7.0372 21 12C21 16.9628 16.9628 21 12 21C7.0372 21 3 16.9628 3 12ZM15.3144 15.3143C15.6076 15.021 15.6076 14.547 15.3144 14.2537L13.0606 12L15.3144 9.7463C15.6076 9.45296 15.6076 8.97904 15.3144 8.68571C15.0211 8.39251 14.5472 8.39251 14.2538 8.68571L12.0001 10.9396L9.74641 8.68571C9.45307 8.39251 8.97915 8.39251 8.68582 8.68571C8.39262 8.97904 8.39262 9.45296 8.68582 9.7463L10.9397 12L8.68582 14.2537C8.39262 14.547 8.39262 15.021 8.68582 15.3143C8.83207 15.4606 9.02406 15.534 9.21618 15.534C9.40817 15.534 9.60015 15.4606 9.74641 15.3143L12.0001 13.0605L14.2538 15.3143C14.4001 15.4606 14.5921 15.534 14.7841 15.534C14.9762 15.534 15.1682 15.4606 15.3144 15.3143Z"
      fill="currentColor"
    />
  </svg>
);
