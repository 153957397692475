import React from 'react';

export const View = (props: any) => (
  <svg
    data-aid="svgView"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7297 5.00359C17.7057 4.81659 21.2297 10.3906 21.8677 11.5026C22.0437 11.8106 22.0437 12.1896 21.8677 12.4976C21.0137 13.9866 17.7977 18.8556 12.2707 18.9966C12.1887 18.9986 12.1067 18.9996 12.0247 18.9996C6.1617 18.9996 2.7617 13.5946 2.1327 12.4976C1.9557 12.1896 1.9557 11.8106 2.1327 11.5026C2.9867 10.0136 6.2017 5.14359 11.7297 5.00359ZM12.2197 16.9976C7.9137 17.0976 5.1047 13.4146 4.1727 11.9956C5.1987 10.3906 7.7827 7.10459 11.7807 7.00259C16.0697 6.89359 18.8947 10.5856 19.8267 12.0046C18.8017 13.6096 16.2167 16.8956 12.2197 16.9976ZM8.5 12C8.5 10.07 10.07 8.49999 12 8.49999C13.93 8.49999 15.5 10.07 15.5 12C15.5 13.93 13.93 15.5 12 15.5C10.07 15.5 8.5 13.93 8.5 12ZM10.5 12C10.5 12.827 11.173 13.5 12 13.5C12.827 13.5 13.5 12.827 13.5 12C13.5 11.173 12.827 10.5 12 10.5C11.173 10.5 10.5 11.173 10.5 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7297 5.00359C17.7057 4.81659 21.2297 10.3906 21.8677 11.5026C22.0437 11.8106 22.0437 12.1896 21.8677 12.4976C21.0137 13.9866 17.7977 18.8556 12.2707 18.9966C12.1887 18.9986 12.1067 18.9996 12.0247 18.9996C6.1617 18.9996 2.7617 13.5946 2.1327 12.4976C1.9557 12.1896 1.9557 11.8106 2.1327 11.5026C2.9867 10.0136 6.2017 5.14359 11.7297 5.00359ZM12.2197 16.9976C7.9137 17.0976 5.1047 13.4146 4.1727 11.9956C5.1987 10.3906 7.7827 7.10459 11.7807 7.00259C16.0697 6.89359 18.8947 10.5856 19.8267 12.0046C18.8017 13.6096 16.2167 16.8956 12.2197 16.9976ZM8.5 12C8.5 10.07 10.07 8.49999 12 8.49999C13.93 8.49999 15.5 10.07 15.5 12C15.5 13.93 13.93 15.5 12 15.5C10.07 15.5 8.5 13.93 8.5 12ZM10.5 12C10.5 12.827 11.173 13.5 12 13.5C12.827 13.5 13.5 12.827 13.5 12C13.5 11.173 12.827 10.5 12 10.5C11.173 10.5 10.5 11.173 10.5 12Z"
      fill="currentColor"
    />
  </svg>
);
