import { useState } from 'react';
import { IPromoCodeState, TCustomState } from '../types';

export const useNotes = (
	initialState: { publicNote?: string; privateNote?: string },
	onChange: ({ publicNote, privateNote }: TCustomState | IPromoCodeState) => void
) => {
	const [publicNote, setPublicNote] = useState<string | undefined>(initialState?.publicNote || '');
	const [privateNote, setPrivateNote] = useState<string | undefined>(initialState?.privateNote || '');

	const handleNotesChange = (publicText?: string, privateText?: string) => {
		if (publicText !== publicNote) setPublicNote(publicText);
		if (privateText !== privateNote) setPrivateNote(privateText);
		onChange({ publicNote: publicText, privateNote: privateText });
	};

	return { publicNote, privateNote, handleNotesChange };
};
