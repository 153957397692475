/** @jsx jsx */

import React, { FC, ReactNode } from 'react';
import { jsx, css } from '@emotion/react';
import * as dayjs from 'dayjs';
import { BnIcon, ETypography, ETypographyColor, Icons, Typography } from '@bondsports/utils';
import { colors } from '../../../styles/theme';
import { EDateTimeFormats } from '../../../types/times';
import { TranslationEn } from '@assets/i18n/en';
import { flex, marginRightCss } from '../../../styles/utils';

const clockIconCss = (unsetHeight: boolean) => css`
	${unsetHeight ? null : 'height: 30px;'};
	display: flex;
	align-items: center;
`;

const dateCss = css`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: ${colors.lightText};
	display: flex;
	align-items: center;
	gap: 2px;
	svg {
		width: 16px;
		height: 16px;
	}
`;

interface Props {
	unsetHeight?: boolean;
	children?: ReactNode;
}

export const ClockLabel: FC<Props> = ({ unsetHeight = false, children }: Props) => {
	return (
		<div css={dateCss}>
			<div css={clockIconCss(unsetHeight)}>
				<BnIcon icon={Icons.clock} />
			</div>
			{children}
		</div>
	);
};

interface ILastActivityProps {
	time: string;
}

export const LastActivity = ({ time }: ILastActivityProps) => {
	const labels = TranslationEn.customers.customerHeader.lastActivity;
	return (
		<ClockLabel>
			{labels} {dayjs(time).format(EDateTimeFormats.DAY_FORMAT)}
		</ClockLabel>
	);
};

export const AccountActivity = ({ id, time }: { id: number; time: string }) => {
	return (
		<div css={flex}>
			<Typography css={marginRightCss(8)} type={ETypography.caption} color={ETypographyColor.secondary}>
				{`#${id}`}
			</Typography>
			<LastActivity time={time} />
		</div>
	);
};
