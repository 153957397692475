/** @jsx jsx */
import React, { forwardRef } from 'react';
import { jsx, css } from '@emotion/react';
const iconBtnCss = css`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: transparent;
	border: none;
	vertical-align: middle;
	cursor: pointer;
	padding: 0;
	margin-left: 10px;
	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}
`;
export const IconButton = forwardRef(({ onClick, children, ...rest }: any, ref) => (
	<button
		data-aid="button-IconButton"
		ref={ref}
		css={iconBtnCss}
		onClick={e => {
			e.stopPropagation();
			onClick && onClick(e);
		}}
		{...rest}
	>
		{children}
	</button>
));
export const KeyboardArrowUpIcon = () => (
	<svg data-aid="arrowUp" width="20px" height="20px" fill="#A6BCCC" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
		<path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path>
	</svg>
);
export const KeyboardArrowDownIcon = () => (
	<svg data-aid="arrowDown" width="20px" height="20px" fill="#A6BCCC" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
		<path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
	</svg>
);
