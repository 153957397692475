/** @jsxRuntime classic*/
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import React, { useState } from 'react';
import { ETypography, ETypographyColor, Icons } from '../../types/theme';
import { Typography } from '../../atoms/Typography';
import { Radio } from '../../components/Radio';
import { Input } from '../../components/Input';

const selectContainerCss = css`
	width: 100%;
`;

const rowCss = css`
	display: flex;
	align-items: center;

	.S {
		margin: 0 !important;
	}
`;

const marginTopCss = css`
	margin-top: 2rem;
`;

const titleCss = css`
	margin-bottom: 4px;
`;

const paddingRight = css`
	padding-right: 8px;
`;

export interface SendToInputProps {
	sendReceiptAction: (type: 'email' | 'sms', toAddress: string) => void;
}

export const SendToInput = ({ sendReceiptAction }: SendToInputProps) => {
	const [type, setType] = useState<'email' | 'sms'>('email');
	const [address, setAddress] = useState<string>();

	const labels = {
		title: 'Send receipt to',
		email: 'Email',
		sms: 'Phone',
		emailPlaceholder: 'you@email.com',
		phonePlaceholder: '305-270-9414',
	};

	const handleAddressChange = (val: any) => {
		const parsedVal = val.currentTarget.value;
		if (parsedVal !== undefined) {
			setAddress(parsedVal);
			if (type) sendReceiptAction(type, parsedVal);
		}
	};

	return (
		<div css={selectContainerCss} data-aid="index-SendToInput">
			<div css={rowCss}>
				<div css={{ ...paddingRight }}>
					<Typography css={titleCss} type={ETypography.captionAccented} color={ETypographyColor.secondary}>
						{labels.title}
					</Typography>
				</div>
				<Radio label={labels.email} value="email" setChecked={setType} checked={type === 'email'} />
				<Radio label={labels.sms} value="sms" setChecked={setType} checked={type === 'sms'} />
			</div>
			<div css={{ ...rowCss, ...marginTopCss}}>
				<Input
					value={address}
					sizer="S"
					onChange={handleAddressChange}
					placeholder={type === 'email' ? labels.emailPlaceholder : labels.phonePlaceholder}
				/>
			</div>
		</div>
	);
};
