import * as  React from 'react';

export const BnHorizontal = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4 2.77881H3.6C2.71634 2.77881 2 3.51622 2 4.42587V7.71999C2 8.62963 2.71634 9.36704 3.6 9.36704H16.4C17.2837 9.36704 18 8.62963 18 7.71999V4.42587C18 3.51622 17.2837 2.77881 16.4 2.77881ZM3.6 4.23714H16.4C16.4254 4.23714 16.4545 4.24557 16.4853 4.27737C16.5169 4.3099 16.5417 4.35968 16.5417 4.42587V7.71999C16.5417 7.78618 16.5169 7.83595 16.4853 7.86849C16.4545 7.90028 16.4254 7.90871 16.4 7.90871H3.6C3.57459 7.90871 3.54555 7.90028 3.51466 7.86849C3.48305 7.83595 3.45833 7.78618 3.45833 7.71999V4.42587C3.45833 4.35968 3.48305 4.3099 3.51466 4.27737C3.54555 4.24557 3.57459 4.23714 3.6 4.23714Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4 10.6232H3.6C2.71634 10.6232 2 11.3606 2 12.2702V15.5644C2 16.474 2.71634 17.2114 3.6 17.2114H16.4C17.2837 17.2114 18 16.474 18 15.5644V12.2702C18 11.3606 17.2837 10.6232 16.4 10.6232ZM3.6 12.0815H16.4C16.4254 12.0815 16.4545 12.09 16.4853 12.1217C16.5169 12.1543 16.5417 12.2041 16.5417 12.2702V15.5644C16.5417 15.6306 16.5169 15.6803 16.4853 15.7129C16.4545 15.7447 16.4254 15.7531 16.4 15.7531H3.6C3.57459 15.7531 3.54555 15.7447 3.51466 15.7129C3.48305 15.6803 3.45833 15.6306 3.45833 15.5644V12.2702C3.45833 12.2041 3.48305 12.1543 3.51466 12.1217C3.54555 12.09 3.57459 12.0815 3.6 12.0815Z"
      fill="currentColor"
    />
  </svg>
);
