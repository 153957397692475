import { PaginationResultDto } from '@bondsports/types';
import { useMemo, useState } from 'react';
import { useFetch } from '@bondsports/utils';

export const useActivityView = (
	getData: (page: number, options: { signal: AbortSignal }) => Promise<PaginationResultDto<any>>
) => {
	const [currentPage, setPage] = useState<number>(1);
	const [error, setError] = useState<Error>();

	const { isLoading, data, isFirstFetch } = useFetch(options => getData(currentPage, options), [getData, currentPage], {
		onError: setError,
		onSuccess: () => setError(null),
	});

	const totalPages: number = useMemo(() => data?.meta?.totalPages || 0, [data]);

	return {
		setPage,
		currentPage,
		isLoading: isLoading || isFirstFetch,
		totalPages,
		data: data?.data,
		error,
	};
};
