import React from "react";

export const Pickleball = (props: any) => (
  <svg
    data-aid="SvgPickleball"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.0604 5.99322C5.01996 5.99322 5.82724 5.35672 6.05841 4.48021L6.05657 4.49087C6.09877 4.33264 6.12079 4.16551 6.12079 3.99483C6.12079 3.07031 5.4713 2.29158 4.59063 2.06578C4.42184 2.02311 4.24387 2 4.0604 2C2.92287 2 2 2.8943 2 3.99661C2 5.09893 2.92287 5.99322 4.0604 5.99322Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9205 15.5289C11.0312 15.5453 11.1437 15.5552 11.2574 15.5583L14.5834 15.65C15.4849 15.6749 16.3767 15.2991 17.0092 14.6279L21.1483 10.2354C22.3036 9.00939 22.2807 7.16651 21.0972 6.11925L17.5256 2.95886C16.342 1.9116 14.4461 2.05653 13.2907 3.28257L9.15172 7.67501C8.51918 8.34628 8.21381 9.24095 8.32107 10.1087L8.71677 13.3102C8.73031 13.4197 8.75064 13.5273 8.77751 13.6327L4.29411 18.3906C3.8996 18.0415 3.2676 18.0898 2.8825 18.4985C2.4974 18.9072 2.50502 19.5215 2.89953 19.8705L5.04249 21.7668C5.437 22.1159 6.069 22.0676 6.4541 21.6589C6.8392 21.2502 6.83158 20.6359 6.43707 20.2868L10.9205 15.5289ZM16.8283 3.69884L20.3999 6.85923C21.1889 7.55741 21.2042 8.78599 20.4339 9.60335L16.2949 13.9958C15.8732 14.4433 15.2787 14.6938 14.6777 14.6772L11.3517 14.5855C10.9357 14.574 10.5457 14.4234 10.2466 14.1588C9.94756 13.8942 9.75748 13.5315 9.70798 13.131L9.31227 9.92954C9.24077 9.35105 9.44435 8.7546 9.86604 8.30709L14.0051 3.91465C14.7753 3.09729 16.0393 3.00067 16.8283 3.69884ZM5.72275 19.6547L5.00843 19.0227L9.19216 14.5828L9.90648 15.2148L5.72275 19.6547Z"
      fill="currentColor"
    />
  </svg>
);
