/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';

import { Overview } from './Overview';
import { PageBodyMapper, IBodyProps } from '../../PageBodyMapper';

type TCustomerMapperProps = Omit<IBodyProps, 'OverviewComponent' | 'isFamily'>;

export const CustomerPageBodyMapper = ({
	type,
	customerState,
	customersService,
	organizationId,
	fetchData,
	currencySymbol,
	orgQuestionnaireId,
}: TCustomerMapperProps) => {
	return (
		<PageBodyMapper
			type={type}
			customerState={customerState}
			customersService={customersService}
			currencySymbol={currencySymbol}
			organizationId={organizationId}
			fetchData={fetchData}
			OverviewComponent={Overview}
			isFamily={false}
			orgQuestionnaireId={orgQuestionnaireId}
		/>
	);
};
