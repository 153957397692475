import React from 'react';

export const WindSurfing = (props: any) => (
  <svg
    data-aid="svgWindSurfing"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.02948 20.8452L20.3995 19.5187C20.4006 19.5187 20.4006 19.5187 20.4018 19.5187C20.4243 19.5187 20.4435 19.5343 20.4435 19.5547C20.4435 19.5645 20.4389 19.5732 20.4322 19.579C17.2292 21.8993 10.9855 22.9554 6.01821 20.9113C6.00582 20.9055 5.99794 20.8938 5.99794 20.8802C5.99794 20.8627 6.01258 20.8491 6.03061 20.8452H6.02948ZM15.1213 5.04378C14.6278 4.74037 14.0533 4.42627 13.4595 4.1394L6.74714 7.29598C7.73068 8.72646 8.66126 10.3962 9.42736 12.1359L18.6318 7.95433C17.6438 6.82142 16.4834 5.85869 15.1709 5.07295L15.1213 5.04378ZM21 13.7784C21 13.6442 21 13.3057 20.9685 13.1725C20.7634 11.5514 20.1731 10.0811 19.2763 8.78578L9.90956 13.3796C10.0774 13.8464 10.2261 14.3161 10.3613 14.7965C10.6396 15.8011 10.7376 16.8435 10.9246 17.8841C13.6792 15.4714 16.6963 13.4837 21 13.8279V13.7784ZM3.30644 2H3L6.13088 6.38965L12.0479 3.52383C8.93616 2.22464 4.24604 2.0282 3.30644 2.00097V2Z"
      fill="currentColor"
    />
  </svg>
);
