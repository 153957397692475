import { jsx, css, useTheme, Theme } from '@emotion/react';
import { IColors } from '../../types';

export const style = (colors: IColors) => css`
	.SelectTrigger {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		padding: 0 15px;
		font-size: 13px;
		line-height: 1;
		height: 35px;
		gap: 5px;
		background-color: white;
		color: ${colors.tx_text_primary};
	}

	.SelectTrigger:hover {
		background-color: mediumpurple;
	}

	.SelectTrigger:focus {
		box-shadow: 0 0 0 2px black;
	}

	.SelectTrigger[data-placeholder] {
		color: purple;
	}

	.SelectIcon {
		color: blue;
	}

	.SelectContent {
		overflow: hidden;
		background-color: white;
		border-radius: 6px;
	}

	.SelectViewport {
		padding: 5px;
	}

	.SelectItem {
		font-size: 13px;
		line-height: 1;
		color: rebeccapurple;
		border-radius: 3px;
		display: flex;
		align-items: center;
		height: 25px;
		padding: 0 35px 0 25px;
		position: relative;
		user-select: none;
	}

	.SelectItem[data-disabled] {
		color: dimgray;
		pointer-events: none;
	}

	.SelectItem[data-highlighted] {
		outline: none;
		background-color: black;
		color: ${colors.tx_text_primary};
	}

	.SelectLabel {
		padding: 0 25px;
		font-size: 12px;
		line-height: 25px;
		color: brown;
	}

	.SelectSeparator {
		height: 1px;
		background-color: darkblue;
		margin: 5px;
	}

	.SelectItemIndicator {
		position: absolute;
		left: 0;
		width: 25px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}

	.SelectScrollButton {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 25px;
		background-color: white;
		color: green;
		cursor: default;
	}
`;

export const showCss = (show: boolean) => css`
	visibility: ${show ? 'visible' : 'hidden'};
`;
