import { css } from '@emotion/react';
import { colors } from 'app/react/styles/theme';
import { flex } from 'app/react/styles/utils';

export const containerCss = css`
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 20px 8px 20px 16px;
	background: ${colors.lightGray};
	border: 1px solid ${colors.brandPrimary};
	box-sizing: border-box;
	border-radius: 5px;
	width: 327px;
`;

export const headerCss = css`
	margin-bottom: 20px;
`;
export const overviewContainer = css`
	display: flex;
	margin: 2rem 0 0 0;
	height: 100%;
`;
export const leftContainer = css`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 0 0 2rem 0;
	padding: 3rem 2rem 2rem 2rem;
	min-width: 280px;
`;
export const mainContainer = css`
	display: flex;
	align-items: center;
	flex-direction: column;
	flex-grow: 1;
	max-width: 100%;
`;
export const mainCon = css`
	display: flex;
	flex-direction: column;
	padding-right: 1rem;
	padding: 1rem 0;
	margin-bottom: 6rem;
	margin-top: -2rem;
`;
export const detailsPartCss = css`
	display: flex;
	flex-wrap: wrap;
`;

export const inputCss = css`
	.MuiFormLabel-root {
		color: ${colors.formInputBg};
	}
`;
export const formLine = css`
	${flex};
	justify-content: space-between;
	width: 100%;
	align-items: flex-start;
	position: relative;
`;

export const formLineCenter = css`
	${formLine};
	align-items: center;
	margin-top: 16px;
	div {
		margin-top: 0;
	}
`;

export const marginLine = (margin?: number) => css`
	${formLine};
	margin: ${margin || 8}px 0;
`;

export const RightSide = css`
	margin-right: 10px;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Quarter = css`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Half = css`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const marginRight = css`
	margin-right: 10px;
`;

export const marginTop = css`
	margin-top: 0;
`;

export const selectAction = css`
	${Quarter};
	${marginRight};
	margin-top: 0;
	width: 150px;
`;

export const QuarterWithMargin = css`
	${Quarter};
	${marginRight};
`;

export const HalfWithMargin = css`
	${Quarter};
	${marginRight};
`;

export const Halfi = (skipMargins?: boolean) => css`
	margin-right: ${skipMargins ? '0' : '10px'};
	display: flex;
	flex-direction: row;
	width: 100%;
`;

export const inputNoteCss = css`
	${flex}
	align-items: flex-start;
	padding-top: 6px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 130%;
	color: ${colors.lightText};
`;

export const notesLabel = css`
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 130%;
	color: ${colors.formInputBg};
`;
export const multipleInputCss = css`
	margin-top: 6px;
	&:first-of-type {
		margin-top: 0;
	}
`;
