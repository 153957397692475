/** @jsxRuntime classic */
/** @jsx jsx */

import { FC, Fragment } from 'react';
import { jsx } from '@emotion/react';
import dayjs from 'dayjs';
import { HourBox } from './daily/hourBox';
import { WeekBox } from './weekly/WeekBox';
import { useMiddlewareContext } from '../hooks/useMiddlewareContext';
import { renderHoursSeparator } from '../styles';
import { ECalendarMode, ECalendarView, ITimeLineProps } from '../types';
import { HorizontalHourBox } from './daily/horizontal/HorizontalHourBox';

export const TimeLine: FC<ITimeLineProps> = ({ width }) => {
	const { options, day: days } = useMiddlewareContext();
	const { mode = ECalendarMode.DAILY, view = ECalendarView.VERTICAL, date } = options || {};
	const horizontal = view === ECalendarView.HORIZONTAL;

	let timeLineList = horizontal ? [] : [<div key={-1} css={renderHoursSeparator(horizontal)} />];

	for (let day = 0; day < days; day++) {
		if (mode === ECalendarMode.DAILY) {
			for (let i = 0; i < 24; i++) {
				timeLineList.push(
					horizontal ? (
						<HorizontalHourBox
							key={`${i}_${day}`}
							width={width}
							date={dayjs(`${dayjs(date).format('MM/DD/YYYY')} ${i}:00`, 'MM/DD/YYYY HH:mm').add(day, 'day')}
						/>
					) : (
						<HourBox
							key={`${i}_${day}`}
							width={width}
							date={dayjs(`${dayjs(date).format('MM/DD/YYYY')} ${i}:00`, 'MM/DD/YYYY HH:mm').add(day, 'day')}
						/>
					)
				);
			}
		}
		if (mode === ECalendarMode.WEEKLY) {
			timeLineList.push(<WeekBox key={day} weekNumber={day} width={width} horizontal={horizontal} />);
		}
	}

	return <Fragment>{timeLineList}</Fragment>;
};
