/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React from 'react';
import { BnIcon } from '../../../components/icons_v2';
import { IPaymentMethod } from '../../../molecules/PaymentMethodPicker';
import { ETypography, ETypographyColor, Icons } from '../../../types/theme';
import { flexColCss, flexRowCss, hrCss, underlineCss } from '../style';
import { ThemeColors as colors } from '../../../styles/theme';
import { Typography } from '../../../atoms';
import { useRefundPopup } from '../hooks/useRefundPopup';
import { EPaymentIcons, EPaymentMethodLabel } from '../../../atoms/PaymentMethod';
import { Pricify } from '../../../lib/price';
import { SendToInput } from '../../../molecules/SendToInput';
import { ArchiveSlotInvoiceActionEnum } from '../../../molecules/ActionSelect';

const containerCss = css`
	margin-bottom: 20px;
	width: 100%;
	padding: 8px 0 8px 0;
	${flexColCss}
`;

const lineItemCss = css`
	margin-bottom: 20px;
	margin-top: 1rem;
	align-items: center;
	color: ${colors.tx_text_secondary};
	justify-content: flex-start;
	svg {
		width: 16px;
		height: auto;
		margin-right: 8px;
	}
`;

const refundSummaryContainerCss = css`
	width: 100%;
	${flexColCss};
`;

const summaryBodyCss = css`
	width: 100%;
	background: ${colors.bg_background_primary};
	padding: 8px 0 8px 0;
	margin: 4px 0 26px 0;
	gap: 4px;
	${flexColCss};
`;

const rowCss = css`
	${flexRowCss};
	justify-content: space-between;
	height: 28px;
	padding: 0 8px 0 8px;
	color: ${colors.tx_text_secondary};
	svg {
		margin: 0 8px 0 8px;
		width: 20px;
		height: auto;
	}
`;

const midDotCss = css`
	margin: 0 8px 0 8px;
`;

const statusIconCss = (error = false) => css`
	color: ${!error ? colors.t_green : colors.bg_error};
	svg {
		height: 20px;
		width: 20px;
	}
`;

interface Props {
	lineItemCount?: number;
	refundResponse?: IPaymentMethod[];
	action?: ArchiveSlotInvoiceActionEnum;
	goToResourceType?: 'program' | 'membership';
	onChangeSendReceipt: (type: 'email' | 'sms', toAddress: string) => void;
	goToResource?: () => void;
}

const SummaryRow = ({ rec }: { rec: IPaymentMethod }) => {
	return (
		<div css={rowCss} data-aid="SummaryStep-steps">
			<div css={flexRowCss}>
				<BnIcon icon={rec.icon ?? EPaymentIcons[rec.type]} />
				<Typography color={ETypographyColor.primary} type={ETypography.body2}>
					{rec.primaryText ?? EPaymentMethodLabel[rec.type]}
				</Typography>
				<span css={midDotCss}> &#8226; </span>
				<Typography color={ETypographyColor.primary} type={ETypography.body2}>
					{Pricify(rec.refundAmount)}
				</Typography>
			</div>
			<div css={statusIconCss(!rec.refundSuccess)}>
				<BnIcon icon={rec.refundSuccess ? Icons.indications_approved : Icons.indications_cancelled} />
			</div>
		</div>
	);
};

export const SummaryStep = ({
	lineItemCount,
	refundResponse,
	action,
	goToResourceType,
	onChangeSendReceipt,
	goToResource,
}: Props) => {
	const { labels } = useRefundPopup();
	const stepLabels = labels[1];
	const successful = refundResponse?.filter(r => r.refundSuccess);
	const failed = refundResponse?.filter(r => !r.refundSuccess);

	return (
		<div css={containerCss}>
			{lineItemCount && lineItemCount > 0 && (
				<div css={[flexRowCss, lineItemCss]}>
					<BnIcon icon={Icons.activity} />
					<Typography color={ETypographyColor.primary} type={ETypography.body2}>
						{lineItemCount} {lineItemCount === 1 ? stepLabels.lineItemCount : stepLabels.lineItemsCount}
					</Typography>
				</div>
			)}

			<div css={refundSummaryContainerCss}>
				{successful && successful.length > 0 && (
					<div>
						<Typography color={ETypographyColor.secondary} type={ETypography.captionCTA}>
							{stepLabels.summaryTitle}
						</Typography>
						<div css={summaryBodyCss}>
							{successful.map(rec => (
								<SummaryRow rec={rec} />
							))}
						</div>
					</div>
				)}

				{failed && failed.length > 0 && (
					<div>
						<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
							{stepLabels.failedPayment}
						</Typography>
						<div css={summaryBodyCss}>
							{failed.map(rec => (
								<SummaryRow rec={rec} />
							))}
						</div>
					</div>
				)}
			</div>

			{action && action === ArchiveSlotInvoiceActionEnum.RETURN && goToResourceType && (
				<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
					<span>
						{stepLabels[goToResourceType].resourceNotice}{' '}
						<span onClick={goToResource} css={underlineCss}>
							{stepLabels[goToResourceType].resourceNoticeLink}
						</span>
					</span>
				</Typography>
			)}
			<hr css={hrCss(colors)} />

			<SendToInput sendReceiptAction={onChangeSendReceipt} />
		</div>
	);
};
