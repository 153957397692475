/** @jsxRuntime classic */
/** @jsx jsx */

import { FC, Fragment } from 'react';
import { jsx } from '@emotion/react';
import { useMiddlewareContext } from '../hooks/useMiddlewareContext';
import { TimeLine } from './Timeline';
import {
	headerComponentContainerSpace,
	renderHoursContainer,
	renderHoursSeparator,
	renderSpacesHorizontalView,
} from '../styles';
import { ECalendarMode, ECalendarView, IAsideContainerProps } from '../types';
import { asideContainer, spaceHeight, timeBoxWidth } from './styles';

export const AsideContainer: FC<IAsideContainerProps> = ({
	width,
	verticalSpacesRef,
	spaces,
	ResourceGroupHeader,
	bodyContainerHeight,
}) => {
	const { options } = useMiddlewareContext();
	const { mode, view = ECalendarView.VERTICAL } = options || {};
	const horizontal = view === ECalendarView.HORIZONTAL;

	if (mode === ECalendarMode.MONTHLY) return null;

	return (
		<div ref={verticalSpacesRef} css={asideContainer(mode, horizontal)} data-aid="AsideContainer-atoms">
			<div css={horizontal ? renderSpacesHorizontalView(bodyContainerHeight) : renderHoursContainer}>
				{horizontal ? (
					<Fragment>
						<div css={renderHoursSeparator(horizontal)} />
						{spaces.map(space => (
							<div
								key={space.id}
								id={`resource_${space.id}`}
								css={headerComponentContainerSpace(
									spaceHeight * (space.children.length || 1),
									space.children.length || 1
								)}
							>
								<ResourceGroupHeader state={space} />
							</div>
						))}
					</Fragment>
				) : (
					<TimeLine width={width + timeBoxWidth} />
				)}
			</div>
		</div>
	);
};
