/* @jsx jsx */

import React, { Fragment, useMemo } from 'react';
import { jsx, css } from '@emotion/react';
import { useDynamicList } from '@app/react/components/customers/activities/hooks/useDynamicList';
import { isEarlier } from '@app/react/lib/dates';
import {
	alignItemsCss,
	flexRowCss,
	fullWidthCss,
	heightPxCss,
	justifyContentCss,
	marginTopCss,
	verticalPaddingCss,
} from '@app/react/styles/utils';
import {
	Checkbox,
	DynamicPaginatedList,
	ETypography,
	ETypographyColor,
	Typography,
} from '@bondsports/utils';
import { FetchFunc, MustHaveStartAndEndDates } from '@app/react/components/customers/activities/types/types';
import { colors_v2 } from '@app/react/styles/theme';
import { separatorCss } from '@app/react/components/customers/activities/styles';

const DISABLE_SELECTION = true;

const selectAllCss = (labelColor: string) => css`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	> label {
		padding-bottom: 20px;
		height: 0px;

		> div {
			color: ${labelColor};
			padding-left: 10px;
		}
	}
`;

export type DynamicListProps<T extends MustHaveStartAndEndDates> = {
	fetcher: FetchFunc<T>;
	sessionStartDate: Date;
	sessionEndDate: Date;
	children: React.FC<{ data: T; isLastItem: boolean; index: number }>;
	labels: {
		previous: string;
		next: string;
		selectAll: string;
		loading: {
			title: string;
			description: string;
		};
		noData: string;
		eventsError: string;
	};
	NoDataComponent?: React.FC;
};

export const DynamicList = <T extends MustHaveStartAndEndDates>({
	sessionEndDate,
	sessionStartDate,
	fetcher,
	labels,
	children,
	NoDataComponent,
}: DynamicListProps<T>) => {
	const { previousPage, currentPage, nextPage, isLoading, onPrevious, onNext, error } = useDynamicList<T>(
		sessionStartDate,
		sessionEndDate,
		fetcher
	);

	const isDisabled: boolean = useMemo(() => isEarlier(new Date(sessionEndDate), new Date()), [sessionEndDate]);

	const isSelectAllDisabled: boolean = useMemo(
		() => isDisabled || !currentPage || currentPage.every(event => isEarlier(event.startDate, new Date())),
		[currentPage, isDisabled]
	);

	return (
		<div
			datatype="dynamic-paginted-list"
			data-testid="dynamic-paginated-events"
			css={[verticalPaddingCss(28), separatorCss('top')]}
		>
				<DynamicPaginatedList
					ErrorComponent={() => <Prompt label={labels.eventsError} color={ETypographyColor.red} />}
					NoDataComponent={() => (NoDataComponent ? <NoDataComponent /> : <Prompt label={labels.noData} />)}
					Header={() => <ListHeader isSelectAllDisabled={isSelectAllDisabled} labels={labels} />}
					Footer={() => <ListFooter isLoading={isLoading} />}
					hasPrevious={!!previousPage?.length}
					currentPage={currentPage}
					hasNext={!!nextPage?.length}
					onPrevious={onPrevious}
					onNext={onNext}
					labels={labels}
					isLoading={isLoading}
					hasError={!!error}
				>
					{children}
				</DynamicPaginatedList>
		</div>
	);
};

const ListHeader = ({ labels, isSelectAllDisabled }) => {
	return (
		<div
			datatype="select"
			data-testid="select-all-events"
			css={selectAllCss(isSelectAllDisabled ? colors_v2.tx_text_secondary : colors_v2.tx_text_primary)}
		>
			<Checkbox isDisabled={DISABLE_SELECTION || isSelectAllDisabled} sizer="S" label={labels.selectAll} />
		</div>
	);
};

const ListFooter = ({ isLoading }) => {
	return <div css={[separatorCss('top'), heightPxCss(20), fullWidthCss, isLoading ? marginTopCss(28) : null]}></div>;
};

const promptCss = css`
	${heightPxCss(140)};
	${fullWidthCss};
	${flexRowCss};
	${justifyContentCss('center')};
	${alignItemsCss('center')};
	${separatorCss('bottom')};
	${separatorCss('top')};
`;

export const Prompt: React.FC<{ label: string; color?: ETypographyColor }> = ({ label, color }) => {
	return (
		<div css={promptCss}>
			<Typography type={ETypography.body2} color={color ?? ETypographyColor.secondary}>
				{label}
			</Typography>
		</div>
	);
};
