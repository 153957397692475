/** @jsx jsx */

import React, { useState, useRef, ReactElement } from 'react';
import { jsx, css } from '@emotion/react';
import { colors } from '../../../styles/theme';
import { BnIcon, Icons, Popup } from '@bondsports/utils';
const customSelectContainer = css`
	position: relative;
	height: 100%;
	margin-right: 1rem;
`;
const customSelectBody = (isDisabled = false) => css`
	display: flex;
	height: 100%;
	background: rgba(13, 71, 116, 0.04);
	align-items: center;
	border-radius: 2px;
	span {
		border-radius: 2px 0px 0px 2px;
		display: flex;
		align-items: center;
		text-align: left;
		padding-left: 10px;
		min-width: 200px;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 1.4rem;
		line-height: 130%;
		color: ${isDisabled ? colors.disabled : colors.brandPrimary};
		padding: 10px;
		${isDisabled && 'user-select: none;'}
	}
	button {
		${isDisabled && 'cursor: default;'}
		border: 0;
		background: #a6bccc;
		border-radius: 0px 2px 2px 0px;
		height: 42px;
		display: flex;
		align-items: center;
		svg {
			color: ${colors.white};
		}
		div {
			margin: auto;
			align-items: center;
			justify-content: center;
		}
	}
`;
interface CustomSelectProps {
	title: string;
	content?: ReactElement;
	isDisabled?: boolean;
}
export const CustomSelect = ({ title, content, isDisabled }: CustomSelectProps) => {
	const [isOpen, setOpen] = useState<boolean>(false);
	const ref = useRef(null);
	return (
		<Popup isTopLayer body={content} placement="bottom-center" onOpenChange={setOpen} trigger={isOpen} unsetWidth>
			<div ref={ref} css={customSelectContainer}>
				<div css={customSelectBody(isDisabled)}>
					<span>{title}</span>
					<button data-aid="button-customSelect-open">
						{isOpen ? <BnIcon icon={Icons.dropdown_active} /> : <BnIcon icon={Icons.dropdown} />}
					</button>
				</div>
			</div>
		</Popup>
	);
};
export const DisabledCustomSelect = ({ title }: { title: string }) => {
	return (
		<div css={customSelectContainer}>
			<div css={customSelectBody(true)}>
				<span>{title}</span>
				<button disabled data-aid="button-customSelect-drop">
					{<BnIcon icon={Icons.dropdown} />}
				</button>
			</div>
		</div>
	);
};
