import React from 'react';

export const ExclamationMark = (props: any) => (
  <svg
    data-aid="svgExclamationMark"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 16.9997C12.6904 16.9997 13.25 17.5594 13.25 18.2497C13.25 18.9401 12.6904 19.4997 12 19.4997C11.3096 19.4997 10.75 18.9401 10.75 18.2497C10.75 17.5594 11.3096 16.9997 12 16.9997ZM12 4.5C12.6904 4.5 13.25 5.05964 13.25 5.75V13.25C13.25 13.9404 12.6904 14.5 12 14.5C11.3096 14.5 10.75 13.9404 10.75 13.25V5.75C10.75 5.05964 11.3096 4.5 12 4.5Z"
      fill="currentColor"
    />
  </svg>
);
