/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { Tooltip, Props as TooltipProps } from '../../components/Tooltip';
import { Icons } from '../../types';
import { BnIcon } from '../../components/icons_v2';
import { colorCss, wrapperCss } from './style';

interface Props extends Omit<TooltipProps, 'content'> {
	icon: Icons;
	text: string;
	color: string;
	size?: number;
}
export const IconWithTooltip = ({
	icon,
	text,
	color,
	size = 24,
	placement,
	isTopLayer,
	delayLeave,
	delayEnter,
}: Props) => {
	return (
		<div css={wrapperCss(size)} data-aid="index-IconWithTooltip">
			<Tooltip
				content={text}
				placement={placement}
				isTopLayer={isTopLayer}
				delayEnter={delayEnter}
				delayLeave={delayLeave}
			>
				<BnIcon icon={icon} css={color && colorCss(color)} />
			</Tooltip>
		</div>
	);
};
