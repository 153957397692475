/** @jsx jsx */
import React from 'react';
import { Field as FinalField } from 'react-final-form';
import { FormControl, FormLabel, Input, MenuItem, Select } from '@material-ui/core';
import { Button as MuiButton } from '@material-ui/core';
import { jsx, css } from '@emotion/react';
import { colors } from 'app/react/styles/theme';
import { setOpacity } from 'app/react/styles/utils';
import { required } from 'app/react/lib/form';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const fullWidthControlCss = css`
	width: 100%;
`;

const metaErrorCss = css`
	font-size: 1.2rem;
	color: ${colors.dangerRed};
`;

const labelCss = css`
	// color: ${colors.brandPrimary};
	// font-size: 1.2rem;
	font-family: Montserrat;
	font-weight: 500;
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: ${colors.formInputBg};
`;

const downArrowCss = css`
	background-color: ${setOpacity(colors.brandPrimary, 0.37)};
	top: 0;
	height: 100%;
	color: ${colors.white};
	width: 30px;
	border-radius: 2px;
`;

const userInputBgCss = css`
	background-color: ${colors.formControlBg};
	.MuiSelect-icon {
		${downArrowCss}
	}
	&.MuiInput-formControl {
		margin-top: 0;
	}
	.MuiSelect-root {
		padding: 9px 24px 9px 12px;
	}
`;

export interface MenuOptions {
	text: string;
	value: number | string;
}

interface Props {
	controlName: string;
	require: boolean;
	menuOptions: MenuOptions[];
	text?: string;
	disabled?: boolean;
}

function SelectTextInput({ controlName, require, menuOptions, text, disabled }: Props) {
	return (
		<FinalField name={controlName} validate={require ? required : null} displayEmpty>
			{({ input, meta }) => (
				<FormControl css={fullWidthControlCss}>
					{text && (
						<FormLabel css={labelCss}>
							{text} {require ? '*' : ''}
						</FormLabel>
					)}
					<Select
						disabled={disabled}
						labelId={controlName}
						id={controlName}
						displayEmpty
						disableUnderline
						IconComponent={ExpandMoreIcon}
						css={userInputBgCss}
						{...input}
					>
						{menuOptions.map((value, key) => (
							<MenuItem key={key} value={value.value}>
								{value.text}
							</MenuItem>
						))}
					</Select>
					{meta.error && meta.touched && <div css={metaErrorCss}>{meta.error}</div>}
				</FormControl>
			)}
		</FinalField>
	);
}

const fileLabelCss = css`
	${labelCss}
	${userInputBgCss}
  padding: 0;
	.MuiSvgIcon-root {
		${downArrowCss}
	}
	justify-content: flex-end;
`;
export function SelectFileInput({ controlName, require, menuOptions, text }: Props) {
	return (
		<FinalField name={controlName} validate={require ? required : null} displayEmpty>
			{({ input, meta }) => (
				<FormControl css={fullWidthControlCss}>
					{text && (
						<FormLabel css={labelCss}>
							{text} {require ? '*' : ''}
						</FormLabel>
					)}

					<Input hidden id={controlName} multiple type="file" {...input} />
					<MuiButton
						htmlFor={controlName}
						css={fileLabelCss}
						fullWidth
						disableFocusRipple
						disableElevation
						variant="contained"
						component="label"
					>
						<ExpandMoreIcon />
					</MuiButton>
					{meta.error && meta.touched && <div css={metaErrorCss}>{meta.error}</div>}
				</FormControl>
			)}
		</FinalField>
	);
}
export default SelectTextInput;
