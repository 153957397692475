import styled from '@emotion/styled';
import { ETypographyColor, IColors, TypographyColorsMap } from '../../types';

export const StyledH1 = styled.h1<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;

  white-space: break-spaces;
  display:flex;
  align-items:center;

  margin:0;
  color:${colors[color] || colors['default']};
  `
);

export const StyledH2 = styled.h2<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;

    white-space: break-spaces;
    display:flex;
    align-items:center;

    margin:0;
    color:${colors[color] || colors['default']};`
);

export const StyledH3 = styled.h3<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    white-space: break-spaces;
    display:flex;
    align-items:center;

    margin:0;
    color:${colors[color] || colors['default']};`
);

export const StyledH4 = styled.h4<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;

    white-space: break-spaces;
    display:flex;
    align-items:center;

    line-height: 24px;
    margin:0;
    color:${colors[color] || colors['default']};`
);

export const StyledBodyAccented = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;

    white-space: break-spaces;
    display:flex;
    align-items:center;

    font-size: 16px;
    line-height: 20px;
    color:${colors[color] || colors['default']};
    `
);

export const StyledBody1 = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 16px;

white-space: break-spaces;
display:flex;
align-items:center;


line-height: 20px;
color:${colors[color] || colors['default']};`
);

export const StyledBody1Link = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 16px;

white-space: break-spaces;
display:flex;
align-items:center;


line-height: 20px;
text-decoration-line: underline;
color:${colors[color] || colors['default']};`
);

export const StyledCTA = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 16px;

white-space: break-spaces;
display:flex;
align-items:center;

line-height: 20px;
text-transform: uppercase;
color:${colors[color] || colors['default']};`
);

export const StyledBody2Accented = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;

    display:flex;
    align-items:center;

    white-space: break-spaces;
    font-size: 14px;
    line-height: 17px;
    color:${colors[color] || colors['default']};
`
);

export const StyledBody2 = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;

    display:flex;
    align-items:center;
    white-space: break-spaces;

    font-size: 14px;
    line-height: 130%;
    color:${colors[color] || colors['default']};
`
);

export const StyledBody2Link = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
font-family: Montserrat;
font-style: normal;
font-weight: normal;

white-space: break-spaces;
display:flex;
align-items:center;


font-size: 14px;
line-height: 17px;
text-decoration-line: underline;
color:${colors[color] || colors['default']};
`
);

export const StyledCTA2 = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;

    white-space: break-spaces;
    display:flex;
    align-items:center;


    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color:${colors[color] || colors['default']};
`
);

export const StyledCaptionAccented = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;

    display:flex;
    align-items:center;
    white-space: break-spaces;

    font-size: 12px;
    line-height: 15px;
    color:${colors[color] || colors['default']};
`
);

export const StyledCaption = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;

    display:flex;
    align-items:center;
    white-space: break-spaces;


    font-size: 12px;
    line-height: 15px;
    color:${colors[color] || colors['default']};
`
);

export const StyledCaptionLink = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: 500;

display:flex;
align-items:center;
white-space: break-spaces;

font-size: 12px;
line-height: 15px;
text-decoration-line: underline;
color:${colors[color] || colors['default']};

    `
);

export const StyledCaptionCTA = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 12px;

display:flex;
align-items:center;PopUpButton
white-space: break-spaces;

line-height: 15px;
text-transform: uppercase;
color:${colors[color] || colors['default']};
`
);

export const StyledOverlinedAccented = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: 600;
font-size: 10px;

display:flex;
align-items:center;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
display: inline-block;

line-height: 12px;
text-transform: capitalize;
color:${colors[color] || colors['default']};`
);

export const StyledOverline = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: normal;

display:flex;
align-items:center;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
display: inline-block;

font-size: 10px;
line-height: 12px;
color:${colors[color] || colors['default']};
`
);

export const StyledOverlineLink = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 10px;

display:flex;
align-items:center;
white-space: break-spaces;

line-height: 12px;
text-decoration-line: underline;
color:${colors[color] || colors['default']};
`
);

export const StyledH6Accented = styled.h6<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    display:flex;
    align-items:center;
    font-size: 20px;
		line-height: 24px;
		margin:0;
		color:${colors[color] || colors['default']};
  `
);

export const StyledH6 = styled.h6<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;  
    display:flex;
    align-items:center;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-decoration-line: underline;
    margin:0;
		color:${colors[color] || colors['default']};
  `
);
