import React from 'react';

export const Replace = (props: any) => (
  <svg
    data-aid="svgReplace"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.68724 3.578C9.04823 3.21701 9.04823 2.63173 8.68724 2.27074C8.32625 1.90975 7.74097 1.90975 7.37999 2.27074L4.35478 5.29595C3.99379 5.65694 3.99379 6.24222 4.35478 6.60321L7.37999 9.62842C7.74097 9.98941 8.32625 9.98941 8.68724 9.62842C9.04823 9.26743 9.04823 8.68215 8.68724 8.32116L7.24003 6.87395H19.0588C19.3486 6.87395 19.6264 6.98905 19.8313 7.19392C20.0362 7.39879 20.1513 7.67666 20.1513 7.96639V10.9916C20.1513 11.5021 20.5651 11.916 21.0756 11.916C21.5861 11.916 22 11.5021 22 10.9916V7.96639C22 7.18634 21.6901 6.43824 21.1385 5.88666C20.587 5.33508 19.8389 5.02521 19.0588 5.02521H7.24003L8.68724 3.578ZM3.84874 13.0084C3.84874 12.4979 3.43489 12.084 2.92437 12.084C2.41385 12.084 2 12.4979 2 13.0084V16.0336C2 16.8137 2.30987 17.5618 2.86145 18.1133C3.41303 18.6649 4.16113 18.9748 4.94118 18.9748H15.76L14.3128 20.422C13.9518 20.783 13.9518 21.3683 14.3128 21.7293C14.6737 22.0902 15.259 22.0902 15.62 21.7293L18.6452 18.704C19.0062 18.3431 19.0062 17.7578 18.6452 17.3968L15.62 14.3716C15.259 14.0106 14.6737 14.0106 14.3128 14.3716C13.9518 14.7326 13.9518 15.3179 14.3128 15.6788L15.76 17.1261H4.94118C4.65144 17.1261 4.37358 17.011 4.16871 16.8061C3.96384 16.6012 3.84874 16.3233 3.84874 16.0336V13.0084Z"
      fill="currentColor"
    />
  </svg>
);
