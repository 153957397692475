import * as dayjs from 'dayjs';
import * as React from 'react';
import { TranslationEn } from '../../../assets/i18n/en';
import { bondsportsDays } from '../lib/dates';
import { Capitalize } from '../lib/form';
import { FrequencyEnum } from '../types/reservation';

const durationLabels = TranslationEn.duration;

export const useTimeFormats = () => {
	const generateDelta = (diff: number, withSpace?: boolean) => {
		const string =
			Math.floor(diff / 60) >= 1
				? `${Math.floor(diff / 60)}${withSpace ? ' ' : ''}${durationLabels.h} ${
						diff % 60 > 0 ? `${diff % 60}${withSpace ? ' ' : ''}${durationLabels.min}` : ''
				  }`
				: `${diff % 60 > 0 ? `${diff % 60}${withSpace ? ' ' : ''}${durationLabels.min}` : ''}`;
		return string;
	};

	const generateDurationDays = (durationDays: number) => {
		const daysString = durationDays > 1 ? durationLabels.days : durationLabels.day;
		return `${durationDays} ${daysString}`;
	};

	const generateTimeRange = (startTime: string, endTime: string, is12Hours = true) => {
		const hourFormat = is12Hours ? 'hh:mma' : 'HH:mm';
		return `${dayjs(startTime, 'YYYY/MM/DD HH:mm:ss').format(hourFormat)} - ${dayjs(
			endTime,
			'YYYY/MM/DD HH:mm:ss'
		).format(hourFormat)}`;
	};

	/**
	 * The function will return a ranged string if the start date and end date are different
	 * @param startDate Reservation start date
	 * @param endDate Reservation end date
	 * @returns String that displays the reservation's date range
	 */
	const generateDurationRangedDisplay = (startDate: string, endDate: string, format?: string) => {
		if (dayjs(startDate).isSame(dayjs(endDate))) {
			return dayjs(startDate, 'YYYY-MM-DD').format(format || 'ddd, MMM, DD, YYYY');
		}
		return `${dayjs(startDate, 'YYYY-MM-DD').format('MMM, DD')} - ${dayjs(endDate, 'YYYY-MM-DD').format(
			'MMM, DD, YYYY'
		)}`;
	};

	/**
	 * The function parses the reservation repeat data and returns a string
	 * @param frequency Reservation frequency
	 * @param repeatOn Days reservation repeats on
	 * @param repeatEvery Reservation will appear every 1 day/1 week/1 month
	 * @returns String that displays the reservation's frequency and occuarnces
	 */
	const generateFrequencyDurationDisplay = (frequency: string, repeatOn?: number[], repeatEvery?: number) => {
		if (frequency !== 'none') {
			if (frequency === 'weekly') {
				return `${FrequencyEnum[frequency]} on ${repeatOn.map(day => bondsportsDays[day])}`;
			} else if (frequency === 'daily' && repeatEvery) {
				if (repeatEvery < 1) {
					return `${Capitalize(frequency)} every ${repeatEvery} days`;
				}
			}
			return `${Capitalize(frequency)}`;
		}
		return '';
	};

	/**
	 * The function gets a time with format 'HH:mm:ss', and returns the time formated to 'hh:mm a'
	 * @param dateTime Reservation frequency
	 * @returns String that displays time formated to 'hh:mm a'
	 */
	//ToDo: remove and replace all places with function in date.js file
	function formatTimeToAmPm(dateTime: string) {
		return dayjs(dateTime, 'HH:mm:ss').format('hh:mm a');
	}

	return {
		generateDelta,
		generateTimeRange,
		generateDurationDays,
		generateDurationRangedDisplay,
		generateFrequencyDurationDisplay,
		formatTimeToAmPm,
	};
};
