import { Dispatch, Reducer } from 'react';
import {
	EDiscountStateActionsType,
	TCustomState,
	TCustomStateActions,
	IPromoCodeState,
	TPromoCodeStateActions,
} from '../types';

export const discountModalReducer = (state: TCustomState, action: TCustomStateActions) => {
	switch (action.type) {
		case EDiscountStateActionsType.SET_STATE:
			return { ...state, ...action.payload };
		case EDiscountStateActionsType.RESET:
			return {};
		default:
			return state;
	}
};

export const promoCodeReducer: Reducer<Partial<IPromoCodeState>, TPromoCodeStateActions> = (
	state: Partial<IPromoCodeState>,
	action: TPromoCodeStateActions
) => {
	switch (action.type) {
		case EDiscountStateActionsType.SET_STATE:
			return { ...state, ...action.payload };
		case EDiscountStateActionsType.RESET:
			return {};
		default:
			return state;
	}
};
