import React from 'react';

export const Dance = (props: any) => (
  <svg
    data-aid="svgDance"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.78947 2C6.90029 2 6.1491 2.45209 5.49712 3.08594C4.84515 3.71978 4.26172 4.58166 3.74959 5.66992C2.72532 7.84645 2 10.9335 2 14.8418C2 18.4152 3.04378 20.3525 3.73931 21.2187C4.14014 21.7184 4.7677 22 5.40872 22H10.1682C10.8082 22 11.4367 21.719 11.8376 21.2207V21.2187C12.5348 20.3516 13.5789 18.4149 13.5789 14.8418C13.5789 10.9335 12.8536 7.84645 11.8294 5.66992C11.3172 4.58166 10.7338 3.71978 10.0818 3.08594C9.42985 2.45209 8.67866 2 7.78947 2ZM16.2105 2C14.9684 2 13.673 2.9793 12.6414 4.7793C13.0941 5.7193 13.4731 6.76016 13.7784 7.91016L19.2101 12.3301L18.8215 12.75C18.6742 12.91 18.4729 13 18.2623 13H14.5678C14.61 13.61 14.6316 14.2294 14.6316 14.8594V15H18.2623C18.8413 15 19.4108 14.82 19.8845 14.5C19.8845 14.62 19.8947 14.7198 19.8947 14.8398C19.8947 18.0298 18.9787 19.52 18.5892 20H13.8318C13.8213 19.99 13.8113 19.9807 13.8113 19.9707C13.5481 20.7107 13.2309 21.2991 12.9046 21.7891C13.1888 21.9291 13.5055 22 13.8318 22H18.5892C19.2524 22 19.8627 21.7107 20.2627 21.2207C20.9049 20.4107 22 18.5098 22 14.8398C22 6.90984 19 2 16.2105 2ZM7.78947 4C7.84766 4 8.16308 4.08206 8.57689 4.48437C8.88242 4.78141 9.22421 5.22937 9.55757 5.81445L4.59046 9.85938C4.87108 8.51605 5.24976 7.38331 5.67188 6.48633C6.10711 5.56147 6.58824 4.88668 7.00206 4.48437C7.41587 4.08207 7.73129 4 7.78947 4ZM16.2105 4C16.8421 4 18.5569 5.85938 19.4095 9.85938L14.4527 5.83008C15.1475 4.60008 15.8526 4 16.2105 4ZM10.4108 7.75391C10.7982 8.87317 11.1136 10.2279 11.2969 11.8027L10.4046 12.7441C10.234 12.924 10.0391 13 9.84745 13H5.73355C5.54016 13 5.34542 12.9248 5.1764 12.7461H5.17434L4.78577 12.334L10.4108 7.75391ZM4.11349 14.498C4.58564 14.8137 5.14641 15 5.73355 15H9.84745C10.4339 15 10.994 14.8146 11.4655 14.5C11.4669 14.6155 11.4737 14.7246 11.4737 14.8418C11.4737 18.0478 10.5772 19.4903 10.1682 20H5.41077H5.40872C4.99994 19.4896 4.10526 18.046 4.10526 14.8418C4.10526 14.7239 4.11207 14.6142 4.11349 14.498Z"
      fill="currentColor"
    />
  </svg>
);
