import * as  React from 'react';

export const BnSquareAndPlus = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.65989 3.66111V8.34111H8.33989V3.66111H3.65989ZM1.13989 3.60111C1.13989 2.24249 2.24127 1.14111 3.59989 1.14111H8.39989C9.75851 1.14111 10.8599 2.24249 10.8599 3.60111V8.40111C10.8599 9.75973 9.75851 10.8611 8.39989 10.8611H3.59989C2.24127 10.8611 1.13989 9.75973 1.13989 8.40111V3.60111Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.65989 15.6591V20.3391H8.33989V15.6591H3.65989ZM1.13989 15.5991C1.13989 14.2404 2.24127 13.1391 3.59989 13.1391H8.39989C9.75851 13.1391 10.8599 14.2404 10.8599 15.5991V20.3991C10.8599 21.7577 9.75851 22.8591 8.39989 22.8591H3.59989C2.24127 22.8591 1.13989 21.7577 1.13989 20.3991V15.5991Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6601 15.6591V20.3391H20.3401V15.6591H15.6601ZM13.1401 15.5991C13.1401 14.2404 14.2415 13.1391 15.6001 13.1391H20.4001C21.7588 13.1391 22.8601 14.2404 22.8601 15.5991V20.3991C22.8601 21.7577 21.7588 22.8591 20.4001 22.8591H15.6001C14.2415 22.8591 13.1401 21.7577 13.1401 20.3991V15.5991Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1401 6.00111C13.1401 5.30523 13.7043 4.74111 14.4001 4.74111H21.6001C22.296 4.74111 22.8601 5.30523 22.8601 6.00111C22.8601 6.69699 22.296 7.26111 21.6001 7.26111H14.4001C13.7043 7.26111 13.1401 6.69699 13.1401 6.00111Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0001 1.14111C18.696 1.14111 19.2601 1.70523 19.2601 2.40111V9.60111C19.2601 10.297 18.696 10.8611 18.0001 10.8611C17.3043 10.8611 16.7401 10.297 16.7401 9.60111V2.40111C16.7401 1.70523 17.3043 1.14111 18.0001 1.14111Z"
      fill="currentColor"
    />
  </svg>
);
