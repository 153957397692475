import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'rc-breadcrumbs',
	templateUrl: './breadcrumbs.component.html',
	styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
