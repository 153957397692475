import * as  React from 'react';

export const BnForward = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1523 10.0076L14.1554 10.0107L13.2195 10.9465L13.2164 10.9435L7.68116 16.4788L6.74528 15.5429L12.2806 10.0076L6.73766 4.46473L7.67354 3.52885L13.2164 9.07174L13.2271 9.06108L14.163 9.99695L14.1523 10.0076Z"
      fill="currentColor"
    />
  </svg>
);
