import React from 'react';
import { ETypography, ETypographyColor } from '../../types/theme';
import { Typography } from '../../atoms/Typography';

interface Props {
  color: ETypographyColor,
  type: ETypography
}

export const Dash: React.FC<Props> = (props) => {

  return (
    <Typography
      color={props.color}
      type={props.type}
    >
      -
    </Typography>
  );
};