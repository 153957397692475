import { DateTimeFormats, formatDateTime } from '@bondsports/date-time';
import { AddonTimePeriodEnum } from '@bondsports/types';
import dayjs from 'dayjs';
import { AddonsModalLabels } from './types';

export function getTimePeriodLabel(
	labels: AddonsModalLabels,
	timePeriod: AddonTimePeriodEnum,
	date: string,
	isSegment?: boolean
): string {
	if (timePeriod === AddonTimePeriodEnum.FULL) {
		return labels.addonTypes.perPurchase;
	} else if (timePeriod === AddonTimePeriodEnum.SESSION) {
		return labels.addonTypes.perSegment;
	} else if (isSegment) {
		return formatDateTime(dayjs(date), DateTimeFormats.DAY_FORMAT);
	} else {
		return labels.addonTypes.perEvent;
	}
}
