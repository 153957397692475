/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { Button } from '../../../components/Button';
import { flexRowCss, gapCss, justifyContentCss, marginTopCss, widthCss } from '../../../styles/utils';
import { NotesPopupLabels } from '../../../types/notes';

type FooterProps = {
	labels: NotesPopupLabels['form'];
	disabled: boolean;
	onCancel: () => void;
};

export const FormNoteFooter: React.FC<FooterProps> = ({ disabled, onCancel, labels }) => {
	return (
		<div
			css={[flexRowCss, justifyContentCss('end'), gapCss(20), marginTopCss(28), widthCss(100)]}
			data-aid="Footer-NoteForm"
		>
			<Button data-aid="button-NoteFormFooter-cancel" type="button" theme={'secondary'} sizer={'L'} onClick={onCancel}>
				{labels.buttons.cancel}
			</Button>
			<Button data-aid="button-NoteFormFooter-save" theme={'primary'} sizer={'L'} disabled={disabled} type="submit">
				{labels.buttons.save}
			</Button>
		</div>
	);
};
