export enum ECollapsedNotes {
	PUBLIC = 'public',
	PRIVATE = 'private',
	BOTH = 'both',
	NONE = 'none',
}

export interface DefaultNotesLineProps {
	onOpenPublic: () => void;
	onOpenPrivate: () => void;
	labels: {
		initialPublic: string;
		initialPrivateNote: string;
		publicTooltip: string;
		privateTooltip: string;
	};
}

export interface OneOpenNoteProps {
	value: string | undefined;
	labels: {
		openNote: string;
		closedNote: string;
		tooltip: string;
	};
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onClick: () => void;
}

export interface TwoOpenNoteProps {
	publicValue?: string;
	privateValue?: string;
	isPublicFirst?: boolean;
	labels: {
		public: string;
		private: string;
		publicTooltip: string;
		privateTooltip: string;
	};
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>, type: ECollapsedNotes) => void;
}

export interface OpenNoteProps {
	openNote: string;
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	value?: string;
	labels: {
		tooltip: string;
	};
}

export interface AddPublicPrivateNotesProps {
	isPublicNoteShowing?: boolean;
	isPrivateNoteShowing?: boolean;
	onChange: (publicNote?: string, privateNote?: string) => void;
}
