import * as Cookies from 'js-cookie';
import jwtDecode from "jwt-decode";
import {USER_DETAILS_COOKIE_NAME, VerificationResult} from './types';

const SECONDS_TO_MILISECONDS_MULTIPLIER = 1000;

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class CookiesWorker {
    public static hasBondUserDetails(): boolean {
        return CookiesWorker.getBondUserDetails() !== false;
    }

    public static getBondUserDetails(): boolean | VerificationResult {
        const token = CookiesWorker.getBondUserDetailsCookie();
        console.log('JWT token', token);

        if (!token) {
            return false;
        }

        try {
            const decodedToken: any = jwtDecode(token);
            console.log('JWT decoded token', decodedToken);

            if (!CookiesWorker.validateUserDetails(decodedToken)) {
                return false;
            }

            return decodedToken;
        } catch (e) {
            console.info('Error while decoding token', e);
            return false;
        }
    }

    private static validateUserDetails(userDetails: VerificationResult): boolean {
        const nowDate = new Date();
        const tokenExpirationDate = new Date(userDetails.exp * SECONDS_TO_MILISECONDS_MULTIPLIER);

        return tokenExpirationDate >= nowDate;
    }

    private static getBondUserDetailsCookie(): string | undefined {
        return Cookies.get(USER_DETAILS_COOKIE_NAME);
    }
}
