import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import EventRegistrationModal from 'app/react/pages/UserRegistration/EventRegistrationModal';
import React from 'react';
import ReactDOM from 'react-dom';

@Component({
	selector: 'rc-customer-attendees-wrapper',
	template: `<div #reactCustomerAttendees></div>`,
	encapsulation: ViewEncapsulation.Emulated,
})
export class CustomerAttendeesComponent implements OnDestroy, AfterViewInit {
	@ViewChild('reactCustomerAttendees', { static: true }) containerRef: ElementRef;

	@Input() organizationId: number;
	@Input() seasonId: number;
	@Input() eventId: number;
	@Input() startDate: Date | string;
	@Input() endDate: Date | string;
	@Input() spaceName: string;
	@Input() eventName: string;
	@Input() programName: string;
	@Input() seasonName: string;
	@Input() punchCardSeason: boolean = false;
	@Output() close = new EventEmitter<boolean>();
	startTime: string;
	endTime: string;

	constructor() {}

	ngOnDestroy() {
		ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
	}

	ngAfterViewInit() {
		this.render();
	}

	private render() {
		if (!this.containerRef) return;

		ReactDOM.render(
			React.createElement(EventRegistrationModal, {
				organizationId: this.organizationId,
				eventId: this.eventId,
				seasonId: this.seasonId,
				startDate: this.startDate,
				endDate: this.endDate,
				spaceName: this.spaceName,
				eventName: this.eventName,
				programName: this.programName,
				seasonName: this.seasonName,
				punchCardSeason: this.punchCardSeason,
				close: refresh => this.close.emit(refresh),
			}),
			this.containerRef.nativeElement
		);
	}
}
