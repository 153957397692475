import React from "react";

export const PortableRestrooms = (props: any) => (
  <svg
    data-aid="SvgPortableRestrooms"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.3023 4.70677H18.1959C16.6923 1.09948 7.34671 1.09601 5.83954 4.70677H4.70567C4.38229 4.70677 4.12012 4.96894 4.12012 5.29232C4.12012 5.6157 4.38229 5.87787 4.70567 5.87787H5.64256V21.4144C5.64256 21.7378 5.90473 22 6.22811 22H17.7798C18.1032 22 18.3654 21.7378 18.3654 21.4144V5.87787H19.3022C20.0781 5.84918 20.0779 4.73534 19.3023 4.70677ZM14.8553 12.4032C14.8322 12.3944 14.1765 12.3967 14.1315 12.397C13.8081 12.397 13.5459 12.6591 13.5459 12.9825C13.5459 13.3059 13.8081 13.5681 14.1315 13.5681C14.1779 13.5676 14.8309 13.5727 14.8553 13.5618V20.8289H9.15264V6.02789C10.6447 5.35154 13.3624 5.35134 14.8553 6.02785V12.4032ZM17.1943 20.8289H16.0264V5.72169C16.0264 5.59899 15.9878 5.47942 15.9162 5.3798C14.6654 4.01339 9.34638 4.01148 8.09173 5.37976C8.0201 5.47942 7.98157 5.59899 7.98157 5.72169V20.8289H6.8137V5.51175C7.40461 2.42092 16.5178 2.43052 17.197 5.43375C17.189 5.45998 17.197 20.7915 17.1943 20.8289Z"
      fill="currentColor"
    />
  </svg>
);
