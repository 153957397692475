/** @jsx jsx */

import { PaymentMethodTypeEnum } from '@bondsports/types';
import React, { useEffect, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { Stripe } from 'stripe';
import { flexCol, flexRowCss } from '../../../styles/utils';
import { Icons, colors, colors_v2 } from '../../../styles/theme';
import { Mixpanel, MixpanelEvents } from 'app/react/lib/mixpanel';
import { FlowLayout } from '../../shared/ModalBody/FlowLayout';
import { ICustomer } from 'app/react/types/customers';
import {
	ETypography,
	ETypographyColor,
	PaymentMethodMapper,
	Pricify,
	SendToInput,
	Typography,
} from '@bondsports/utils';
import { Profile } from 'app/react/components/shared/Profile';
import { TranslationEn } from '@assets/i18n/en';

const descriptionContainerCss = css`
	width: 100%;
	padding: 3rem 4.5rem;
	border-bottom: 1px solid ${colors_v2.bg_border_seperator};
	${flexCol};
	align-items: center;
	gap: 12px;
`;

const customerContainerCss = css`
	${flexRowCss};
	justify-content: center;
	gap: 8px;
`;

const sendInputCss = css`
	margin: 24px 40px 0 40px;
`;

const containerCss = css`
	header {
		padding: 2rem 2rem 2.5rem 2rem !important;
	}
`;

const sumRowCss = css`
	${flexRowCss};
	align-items: center;
	justify-content: space-between;
	width: 100%;
	.body-2 {
		font-size: 16px;
	}
`;

interface CustProfProps {
	name: string;
	profilePicture?: any;
	removeCustomer?: () => void;
	status?: string;
	width: number;
	height: number;
	balance?: number;
}

interface Props {
	amount: number;
	actuallyPaid: number;
	feeAmount?: number;
	customer?: ICustomer;
	paymentMethod?: Stripe.PaymentMethod;
	documentId?: string;
	paymentMethodType: PaymentMethodTypeEnum;
	toggle: () => void;
	handlePrint?: () => void;
	handleSend?: (contactMethod: string, sendAddress: string) => void;
	handleClose?: (closeReceipt?: boolean) => void;
}

const CustomerSummary = ({ name, profilePicture, width, height, balance }: CustProfProps) => {
	return (
		<div data-aid="CustomerSummary" css={customerContainerCss}>
			<Profile name={name} profilePicture={profilePicture} width={width} height={height} />
			<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
				{name}
			</Typography>
		</div>
	);
};

export const Completed = ({
	amount,
	customer,
	actuallyPaid = 0,
	feeAmount,
	toggle,
	handlePrint,
	handleSend,
	handleClose,
	paymentMethod,
	documentId = '',
	paymentMethodType,
}: Props) => {
	const [contactMethod, setContactMethod] = useState('');
	const [sendAddress, setSendAddress] = useState('');

	const paymentMethodState: {
		paymentType: PaymentMethodTypeEnum;
		ccBrand?: string;
		ccLast4?: string;
		paymentMethodId?: string;
	} = {
		paymentType: paymentMethodType,
		ccBrand: paymentMethod?.card?.brand ?? '',
		ccLast4: paymentMethod?.card?.last4 ?? '',
		paymentMethodId: documentId,
	};

	const labels = TranslationEn.payments.completed;

	const printAction = () => {
		toggle();
		setTimeout(() => {
			handlePrint();
		}, 200);
	};

	const sendAction = () => {
		if (handleClose) {
			handleClose(true);
		} else {
			toggle();
		}
		handleSend(contactMethod, sendAddress);
	};

	const handleSendToChange = (type: 'email' | 'sms', toAddress: string) => {
		setContactMethod(type);
		setSendAddress(toAddress);
	};

	useEffect(() => {
		handleMixpanel(MixpanelEvents.PURCHASE_COMPLETE);
	}, []);

	const handleMixpanel = (mixpanelEvent: MixpanelEvents) => {
		Mixpanel.track(mixpanelEvent, {
			actuallyPaid,
			amount,
		});
	};

	return (
		<div data-aid="completed" css={containerCss}>
			<FlowLayout
				iconColor={colors.statusGreen}
				icon={Icons.check}
				title={labels.purchaseCompleted}
				actionButtonText={labels.send}
				subButtonText={labels.print}
				thirdButtonText={labels.skip}
				actionButtonDisabled={!sendAddress}
				handleActionButton={sendAction}
				handleSubButton={printAction}
				handleThirdButton={toggle}
				hideSeparator
				width={'400px'}
				descriptionComponent={
					(customer?.firstName || customer?.lastName) && (
						<CustomerSummary
							name={`${customer.firstName} ${customer.lastName}`}
							profilePicture={customer.profilePicture}
							width={28}
							height={28}
							balance={customer?.storedCredit}
						/>
					)
				}
			>
				<div data-aid="completed-desc">
					<div css={descriptionContainerCss}>
						<div data-aid="completed-paymentMethod" css={sumRowCss}>
							<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
								{labels.paymentMethod}
							</Typography>
							<PaymentMethodMapper state={paymentMethodState as any} />
						</div>
						<div css={sumRowCss}>
							<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
								{labels.subtotal}
							</Typography>
							<Typography data-aid="completed-subtotal-amount" color={ETypographyColor.primary} type={ETypography.body2}>
								{Pricify(amount)}
							</Typography>
						</div>
						{feeAmount > 0 && (
							<div css={sumRowCss}>
								<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
									{labels.fees}
								</Typography>
								<Typography data-aid="completed-fee-amount" color={ETypographyColor.primary} type={ETypography.body2}>
									{Pricify(feeAmount)}
								</Typography>
							</div>
						)}
						<div css={sumRowCss}>
							<Typography color={ETypographyColor.primary} type={ETypography.body1Accented}>
								{labels.total}
							</Typography>
							<Typography data-aid="completed-total-amount" color={ETypographyColor.primary} type={ETypography.body1Accented}>
								{Pricify(amount + (feeAmount ?? 0))}
							</Typography>
						</div>
					</div>
					<div css={sendInputCss}>
						<SendToInput sendReceiptAction={handleSendToChange} />
					</div>
				</div>
			</FlowLayout>
		</div>
	);
};
