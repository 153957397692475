/** @jsx jsx */
import React, { ChangeEvent } from 'react';
import { jsx, css } from '@emotion/react';
import { ModalWindow, Icons, Textarea } from '@bondsports/utils';
import { CancellationStatusEnum } from '@bondsports/types';
import { ModalBody } from '../../shared/ModalBody';
import { IMembershipRow } from '../../../types/membership';
import { widthPxCss } from '../../../styles/utils';
import { TranslationEn } from '@assets/i18n/en';

const cancellationReasonContainer = css`
	${widthPxCss(450)}
`;

interface Props {
	isModalShown: boolean;
	isProcessing: boolean;
	cancelledUser: IMembershipRow;
	cancellationReason: string;
	handleCancel: (isImmediate: boolean) => Promise<void>;
	setCancellationReason: (v: string) => void;
	cancelToggle: () => void;
}

export const CancelMembershipModal = ({
	isModalShown,
	isProcessing,
	cancelledUser,
	cancellationReason,
	handleCancel,
	setCancellationReason,
	cancelToggle,
}: Props) => {
	const { isAutoRenew, cancellationStatus } = cancelledUser ?? {};
	const isImmediate = !isAutoRenew || cancellationStatus === CancellationStatusEnum.AUTO_RENEWAL;
	const labels = TranslationEn.memberships.members.cancelMembership.cancelMembershipModal;

	let _description = labels.fixedDescription;
	let mainButtonLabel = labels.fixedMainButton;
	let secondaryButtonLabel = labels.fixedSecondButton;
	if (isImmediate) {
		_description = labels.description;
		mainButtonLabel = labels.mainButton;
		secondaryButtonLabel = labels.cancel;
	}

	const handleMainAction = () => handleCancel(isImmediate);
	const handleSecondaryAction = () => {
		isImmediate ? cancelToggle() : handleCancel(true);
	};

	return (
		<ModalWindow toggle={cancelToggle} isShowing={isModalShown}>
			<ModalBody
				customIsProcessing={isProcessing}
				customWidth={450}
				title={labels.title}
				description={_description}
				mainButton={mainButtonLabel}
				secondButton={secondaryButtonLabel}
				mainAction={handleMainAction}
				secondAction={handleSecondaryAction}
				icon={Icons.profile_filled}
				children={
					<div css={cancellationReasonContainer}>
						<Textarea
							charCounter
							placeholder={labels.placeholder}
							sizer="M"
							value={cancellationReason}
							onChange={({ target }: ChangeEvent<HTMLTextAreaElement>) => setCancellationReason(target.value)}
							clear={() => setCancellationReason('')}
						/>
					</div>
				}
			/>
		</ModalWindow>
	);
};
