/** @jsx jsx */
/** @jsxRuntime classic */
import * as React from 'react';
import { jsx, css, useTheme } from '@emotion/react';
import { SelectItem } from '@radix-ui/react-select';
import * as Select from '@radix-ui/react-select';
import styled from '@emotion/styled';
import { IColors, Icons } from '../../../types';
import { BnIcon } from '../../icons_v2';
import { IColorCode } from './types';
import { ColorEllipse } from './ColorEllipse';

const containerCss = css`
	display: grid;
	grid-template-columns: repeat(auto-fill, 24px);
	gap: 8px;
	padding: 12px 0px 12px 12px;
`;

interface Props {
	colorsCodes: IColorCode[];
}

const StyledItemIndicator = styled(Select.ItemIndicator)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const checkIconCss = css`
	color: white !important;
	width: 20px;
	height: 20px;
`;

export const ColorPalette = ({ colorsCodes }: Props) => {
	return (
		<div css={containerCss} data-aid="ColorPalette-ColorPicker">
			{colorsCodes.map((colorsCode, index) => {
				return (
					<SelectItem key={index} value={String(colorsCode.id)}>
						<Select.ItemText>
							<ColorEllipse key={index} colorId={colorsCode.baseValue} size="m">
								<StyledItemIndicator>
									<BnIcon icon={Icons.check} css={checkIconCss} />
								</StyledItemIndicator>
							</ColorEllipse>
						</Select.ItemText>
					</SelectItem>
				);
			})}
		</div>
	);
};
