/** @jsx jsx */
import { BnIcon, ETypography, ETypographyColor, Icons, Typography } from '@bondsports/utils';
import { css, jsx } from '@emotion/react';
import React, { FC, Fragment, ReactNode } from 'react';
import { TranslationEn } from '../../../../../../assets/i18n/en';
import { colors_v2 } from '../../../../styles/theme';
import { flexCss, gapCss, svgCss } from '../../../../styles/utils';

const containerCss = css`
	${flexCss};
	align-items: center;
`;

const spaceBetweenCss = css`
	justify-content: space-between;
`;

const iconContainerCss = css`
	${flexCss};
	${svgCss('20px', null, colors_v2.tx_error)};
	align-self: center;
`;

const blankDivCss = css`
	width: 10px;
`;

interface Props {
	showFeeWarning: boolean;
	ChargeButton?: ReactNode;
}

export const Footer: FC<Props> = ({ showFeeWarning, ChargeButton }) => {
	return (
		<div css={[containerCss, spaceBetweenCss]}>
			{showFeeWarning ? <FeeGeneralWarning /> : <div css={blankDivCss} />}
			{ChargeButton ? ChargeButton : <Fragment />}
		</div>
	);
};

export const FeeGeneralWarning = () => {
	const label: string = TranslationEn.customers.paymentsInvoices.feeWarning;

	return (
		<div css={[containerCss, gapCss(10)]}>
			<div css={iconContainerCss}>
				<BnIcon icon={Icons.conflict} />
			</div>
			<Typography type={ETypography.body2} color={ETypographyColor.primary}>
				{label}
			</Typography>
		</div>
	);
};
