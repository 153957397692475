/** @jsx jsx */
import React from 'react';
import { FormPart } from 'app/react/components/customers/utils/formPart';
import { TranslationEn } from 'assets/i18n/en';
import { ColorDetails } from '@bondsports/utils';
import { useColorCode } from '@app/react/hooks/useColorCode';
import { jsx } from '@emotion/react';

export const ColorSet = ({ colorCodeId }: { colorCodeId?: number }) => {
	const { getColorById } = useColorCode();

	const colorCode = getColorById(colorCodeId);

	return (
		<FormPart
			width={40}
			label={TranslationEn.activities.program.info.basicInfo.colorSet}
			value={colorCode ? <ColorDetails colorId={colorCode.baseValue} name={colorCode.name} /> : '-'}
			labelMargin={12}
		/>
	);
};
