/* @jsx jsx */

import React, { useCallback, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { SportItem } from '../../../../../types/sports';
import { OrderByEnum, PaymentStatusEnum, SimpleResourceDto, PunchPassEventDto } from '@bondsports/types';
import { FetchFunc, SimpleProgramWithSportDto } from '../../types/types';
import { convertToPaymentStatus } from '../../../../../lib/payment';
import {
	BnIcon,
	Button,
	ETypography,
	ETypographyColor,
	ExpandableSection,
	Icons,
	IconWithTooltip,
	Pricify,
	Typography,
} from '@bondsports/utils';
import {
	flexCol,
	flexRowCss,
	gapCss,
	paddingBottomCss,
	paddingLeftCss,
	paddingRightCss,
	paddingTopCss,
	sidePaddingCss,
} from '../../../../../styles/utils';
import { separatorCss } from '../../styles';
import { colors_v2 } from '../../../../../styles/theme';
import { formatDate, setTimezone } from '../../../../../lib/dates';
import { EDateTimeFormats } from '../../../../../types/times';
import { capitalize } from 'lodash';
import { capitalizedFullName } from '../../../../../lib/utils';
import { PunchPassDetailsCardProps } from './types';
import { TranslationEn } from '../../../../../../../assets/i18n/en';
import { PunchPassEventsDynamicList } from './PunchPassEventsDynamicList';
import { punchPassApi } from '../../../../../lib/api/punchPassApi';
import { ResourcesList } from '../utils/ResourcesList';
import { RemovedFromActivityPrompt } from '../utils/RemovedFromActivityPrompt';

const SHOW_PUNCH_PASS_PRICE = false;

const PAYMENT_STATUS_TO_COLOR_MAPPER = TranslationEn.paymentStatusToColorMapper;
const PAYMENT_STATUS_TO_LABEL_MAPPER = TranslationEn.paymentStatusToLabelMapper;

const SHOW_PUNCH_PASS_EVENTS_LIST = true;

const flexRowWithGapCss = (gap: number) => css`
	${flexRowCss};
	${gapCss(gap)};
`;

type PunchPassExpendableSectionProps = Pick<
	PunchPassDetailsCardProps,
	'data' | 'displayUserDetails' | 'labels' | 'onViewInvoice' | 'onViewPayment' | 'timezone'
> & {
	getSportById: (id: number) => SportItem;
	toggle: () => void;
	setResourcesView: (resources: SimpleResourceDto[]) => void;
};

export const PunchPassExpendableSection: React.FC<PunchPassExpendableSectionProps> = ({
	displayUserDetails,
	data,
	labels,
	getSportById,
	onViewPayment,
	onViewInvoice,
	timezone,
	setResourcesView,
	toggle,
}) => {
	const { financialInfo, program, facility, user, resources, session, organizationId, totalPasses, id } = data;
	const sport: SportItem = getSportById((program as SimpleProgramWithSportDto)?.sport);
	const paymentStatus: PaymentStatusEnum = convertToPaymentStatus(
		financialInfo.paymentStatus,
		financialInfo.isVoided,
		financialInfo.isRefunded
	);

	const [latestDeletedEventDate, setLatestDeletedEventDate] = useState<Date>();

	const setResourcesToView = useCallback(
		(resources: SimpleResourceDto[]) => {
			setResourcesView(resources);
			toggle();
		},
		[setResourcesView, toggle]
	);

	const fetchEvents: FetchFunc<PunchPassEventDto> = useCallback(
		async (itemsPerPage: number, startDate: Date, endDate: Date, order: OrderByEnum, options) => {
			const events: PunchPassEventDto[] = await punchPassApi.getPunchPassEvents(
				organizationId,
				user.id,
				id,
				itemsPerPage,
				startDate,
				endDate,
				order,
				true,
				true,
				options
			);

			if (events[0]?.deletedAt) {
				setLatestDeletedEventDate(events[0].deletedAt);

				return [];
			}

			return events;
		},
		[organizationId, user.id, id]
	);

	const RemovedFromSessionComponent = useCallback(
		() => (
			<RemovedFromActivityPrompt latestDeletedEventDate={latestDeletedEventDate} timezone={timezone} labels={labels} />
		),
		[labels.removedFromSession, latestDeletedEventDate, timezone]
	);

	return (
		<ExpandableSection titleOnShow={labels.defaultOnHide} titleOnHide={labels.defaultOnExpended}>
			<div data-aid="PunchPassExpendableSection" css={[flexCol, gapCss(20), paddingTopCss(28), paddingBottomCss(16)]}>
				{displayUserDetails && (
					<div css={flexRowWithGapCss(8)}>
						<BnIcon icon={Icons.profile_outline} color={colors_v2.bg_text_primary} width={16} height={16} />
						<Typography
							datatype="label"
							data-testid="user-name"
							type={ETypography.body2}
							color={ETypographyColor.primary}
						>
							{capitalizedFullName(user.firstName, user.lastName)}
						</Typography>
					</div>
				)}
				<div css={[flexRowCss]}>
					<div css={[flexRowWithGapCss(8), separatorCss('right'), paddingRightCss(16)]}>
						<BnIcon icon={Icons.other_payment} color={colors_v2.bg_text_primary} width={16} height={16} />
						<Typography
							datatype="date"
							data-testid="purchse-date"
							type={ETypography.body2}
							color={ETypographyColor.primary}
						>
							{formatDate(setTimezone(financialInfo.purchasedOn, timezone), EDateTimeFormats.DAY_FORMAT)}
						</Typography>
					</div>
					<div css={[flexRowWithGapCss(8), separatorCss('right'), sidePaddingCss(16)]}>
						<span
							datatype="tooltip"
							data-testid="payment-status"
							data-status={PAYMENT_STATUS_TO_LABEL_MAPPER[paymentStatus]}
						>
							<IconWithTooltip
								icon={Icons.indications_payment}
								text={PAYMENT_STATUS_TO_LABEL_MAPPER[paymentStatus]}
								color={PAYMENT_STATUS_TO_COLOR_MAPPER[paymentStatus]}
								size={16}
							/>
						</span>
						{SHOW_PUNCH_PASS_PRICE && (
							<Typography type={ETypography.body2} color={ETypographyColor.primary}>
								{Pricify(financialInfo.price)}
							</Typography>
						)}
					</div>
					<div css={[flexRowWithGapCss(16), paddingLeftCss(16)]}>
						<Button
							data-aid="button-PunchPassExpendableSection-invoice"
							datatype="button"
							data-testid="invoice-link"
							theme="basicLine"
							sizer="S"
							onClick={() => onViewInvoice(financialInfo.invoiceId)}
						>
							<Typography type={ETypography.captionLink} color={ETypographyColor.secondary}>
								{labels.viewInvoice}
							</Typography>
						</Button>
						<Button
							data-aid="button-PunchPassExpendableSection-link"
							theme="basicLine"
							sizer="S"
							disabled={!financialInfo.paymentId}
							onClick={() => onViewPayment(financialInfo.paymentId)}
							datatype="button"
							data-testid="payment-link"
						>
							<Typography
								type={ETypography.captionLink}
								color={financialInfo.paymentId ? ETypographyColor.secondary : ETypographyColor.disabled}
							>
								{labels.viewReceipt}
							</Typography>
						</Button>
					</div>
				</div>
				<div css={flexRowWithGapCss(8)}>
					<BnIcon icon={Icons.location} color={colors_v2.bg_text_primary} width={16} height={16} />
					<Typography
						datatype="label"
						data-testid="facility-name"
						type={ETypography.body2}
						color={ETypographyColor.primary}
					>
						{capitalize(facility?.name ?? '')}
					</Typography>
				</div>
				<div css={flexRowWithGapCss(8)}>
					<BnIcon icon={Icons.whistle} color={colors_v2.bg_text_primary} width={16} height={16} />
					<Typography
						datatype="label"
						data-testid="activity-type"
						type={ETypography.body2}
						color={ETypographyColor.primary}
					>
						{capitalize(sport?.label)}
					</Typography>
				</div>
				<div css={flexRowWithGapCss(8)}>
					<BnIcon
						icon={Icons.court}
						color={resources?.length ? colors_v2.bg_text_primary : colors_v2.bg_text_secondary}
						width={16}
						height={16}
					/>
					<ResourcesList resources={resources} setResourceToView={setResourcesToView} labels={labels} />
				</div>
				{SHOW_PUNCH_PASS_EVENTS_LIST && (
					<PunchPassEventsDynamicList
						sessionStartDate={session.startDate}
						sessionEndDate={session.endDate}
						labels={labels as any}
						totalPasses={totalPasses}
						fetcher={fetchEvents}
						NoDataComponent={latestDeletedEventDate ? RemovedFromSessionComponent : null}
					/>
				)}
			</div>
		</ExpandableSection>
	);
};
