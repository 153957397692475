import { EInvoiceVariant, PaymentStatusEnum } from '../../organisms/invoiceTemplate/types';

export enum EInvoicingTabs {
	DETAILS = 'details',
	SCHEDULE = 'schedule',
}

export enum ResourceNameTypeEnum {
	EVENT = 'event',
	VENUE = 'venue',
	TEAM = 'team',
	LEAGUE = 'league',
	USER = 'user',
	ORGANIZATION = 'organization',
	APP = 'app',
	FEED = 'feed',
	MATCH = 'match',
	ROUND = 'round',
	PORTAL = 'portal',
	SEASON = 'season',
	TOURNAMENT = 'tournament',
	MEMBERSHIP = 'membership',
	DIVISION = 'division',
	GAMESLOT = 'gameslot',
	SPACE = 'space',
	RESERVATION = 'reservation',
	ORDER = 'order',
	INVOICE = 'invoice',
	CUSTOMER = 'customer',
	PACKAGE = 'package',
	FACILITY = 'facility',
	PROGRAM = 'program',
	PROGRAM_SEASON = 'program_season',
	PRODUCT = 'product',
	GROUP = 'group',
	VARIANT = 'variant',
	SLOT = 'slot',
}

export enum EInvoiceAction {
	VIEW = 'view',
	DOWNLOAD = 'download',
	REMOVE_DISCOUNT = 'remove_discount',
	ADD_DISCOUNT = 'add_discount',
}

export const BACKOFFICE_VARIANTS = [EInvoiceVariant.BACKOFFICE, EInvoiceVariant.BACKOFFICE_PREVIEW];
export const ALLOWED_DISCOUNT_INVOICE = [PaymentStatusEnum.NOT_PAID, PaymentStatusEnum.PARTIAL_PAYMENT];
export const ALLOWED_DISCOUNT_LINE_ITEM = [PaymentStatusEnum.NOT_PAID, PaymentStatusEnum.PARTIAL_PAYMENT];

export const PAYMENT_VARIANTS = [EInvoiceVariant.PAYMENT, EInvoiceVariant.PAYMENT_PRINT];

export enum DiscountTypeEnum {
	ENTITLEMENT_GROUP = 'entitlement_group',
	MANUAL = 'manual',
	FIXED = 'fixed',
}

export enum DiscountOnTypeEnum {
	LINE_ITEM = 'line_item',
	INVOICE = 'invoice',
	ENTITLEMENT_GROUP = 'entitlement_group',
}
