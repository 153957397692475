import React, { ForwardedRef, memo, ReactNode, useEffect, useMemo } from 'react';
import * as Select from '@radix-ui/react-select';
import styled from '@emotion/styled';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { css, jsx, SerializedStyles, useTheme } from '@emotion/react';
import { BnIcon } from '../icons_v2';
import { ETypography, ETypographyColor, IColors, Icons, IOption } from '../../types';
import { TComponentSize } from './types';
import { Typography } from '../../atoms';
import { showCss } from './styles';

interface SelectRadixProps {
	options?: IOption[];
	value?: string;
	disabled?: boolean;
	size: TComponentSize;
	onSelect: (val: string) => void;
	placeholder?: string;
	label?: string;
	labelAddition?: ReactNode;
	controlLabel?: string; // used for accessibility
	errorMessage?: string;
	required?: boolean;
	isTouched?: boolean;
	onBlur?: () => void;
	key?: string;
	customItems?: ReactNode;
	customValue?: ReactNode;
	isDisableScrollbars?: boolean;
}

export const SelectRadix = ({
	options,
	value,
	onSelect,
	placeholder,
	disabled,
	size,
	label,
	labelAddition,
	controlLabel,
	errorMessage,
	required = false,
	isTouched = false,
	onBlur,
	key,
	customItems,
	customValue,
	isDisableScrollbars,
}: SelectRadixProps) => {
	const handleOnOpenChanged = (open: boolean) => {
		switch (open) {
			case true:
				break;
			case false:
				if (onBlur && !isTouched) {
					onBlur();
				}
				break;
		}
	};

	const { val, error } = useMemo(() => {
		const val: string | undefined = value && value.length > 0 ? value : undefined;
		const error: boolean = Boolean(isTouched && errorMessage);

		return { val, error };
	}, [errorMessage, isTouched, value, key]);

	return (
		<div key={key} style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
			<Typography css={showCss(Boolean(label))} type={ETypography.captionAccented} color={ETypographyColor.secondary}>
				{label ?? 'no label'}
				{required ? ' *' : ''} {labelAddition && labelAddition}
			</Typography>
			<SelectRoot defaultValue={val} onValueChange={onSelect} required={required} onOpenChange={handleOnOpenChanged}>
				<SelectTrigger
					className="select-trigger"
					disabled={disabled}
					aria-label={controlLabel ?? 'Select control'}
					size={size}
					error={error}
				>
					<div className="spacing-value" data-aid="select">
						<div style={{ flexGrow: 1 }}>
							<Select.Value placeholder={placeholder}>{customValue}</Select.Value>
						</div>
						<SelectIcon size={size} disabled={disabled}>
							<BnIcon icon={Icons.dropdown} />
						</SelectIcon>
					</div>
				</SelectTrigger>
				<StyledPortal>
					<SelectContent position="popper" sideOffset={5}>
						{!isDisableScrollbars && (
							<ScrollUp>
								<ChevronUpIcon />
							</ScrollUp>
						)}
						<Select.Viewport>
							{customItems
								? customItems
								: options?.map((o: IOption, idx: number) => (
										<SelectItem key={idx} value={String(o.value ?? '')} disabled={disabled}>
											{o.label}
										</SelectItem>
								  ))}
						</Select.Viewport>
						{!isDisableScrollbars && (
							<ScrollDown>
								<ChevronDownIcon />
							</ScrollDown>
						)}
					</SelectContent>
				</StyledPortal>
			</SelectRoot>
			<Typography css={showCss(error)} type={ETypography.caption} style={{ color: '#e02020' }}>
				{errorMessage ?? 'no error'}
			</Typography>
		</div>
	);
};

const SelectRoot = (props: any) => {
	const StyleSelectRoot = styled(Select.Root)`
		width: 100%;
	`;

	return <StyleSelectRoot {...props}>{props.children}</StyleSelectRoot>;
};

const selectFontCss = (colors: IColors, disabled = false) => css`
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 130%;
	color: ${disabled ? colors.tx_text_disabled : colors.tx_text_primary};
`;

const SelectTrigger = (props: any) => {
	const { size, children, error, disabled } = props;
	const colors: IColors = useTheme();
	const showError = error && !disabled;

	const StyledSelectTrigger = styled(Select.SelectTrigger)`
		all: unset;
		${selectFontCss(colors, disabled)};
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 2px;
		justify-self: flex-end;
		padding: 0 0 0 12px;
		height: ${size === 's' ? '34px' : '38px'};
		gap: 5px;
		background-color: ${colors.tx_background_fields};
		border: 1px solid ${showError ? colors.tx_error : 'transparent'};
		border-right: 0;

		.select-trigger {
			border-left: 1px solid ${showError ? colors.tx_error : 'transparent'};
		}

		.spacing-value {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		svg {
			transition: transform 0.5s;
		}

		${!disabled &&
		`&:hover {
			border: 1px solid ${error ? colors.tx_error : colors.tx_border_primary};
			border-right: 0;
			.select-trigger {
				border-left: 1px solid ${error ? colors.tx_error : colors.tx_border_primary};
			}
		};
		`}

		&[data-state='open'] {
			svg {
				transform: rotate(-180deg);
			}
		}

		&[data-placeholder] {
			color: ${disabled ? colors.tx_text_disabled : colors.tx_text_teriaty};
		}
	`;

	return <StyledSelectTrigger {...props}>{children}</StyledSelectTrigger>;
};

const SelectIcon = (props: any) => {
	const { children, size, disabled } = props;
	const colors: IColors = useTheme();

	const StyledSelectIcon = styled(Select.SelectIcon)`
		display: flex;
		height: ${size === 's' ? '36px' : '40px'};
		width: ${size === 's' ? '36px' : '40px'};
		background-color: ${disabled ? colors.bg_text_disabled : colors.bg_text_secondary};
		justify-content: center;
		color: white;
		align-items: center;
		border-radius: 0 2px 2px 0;

		svg {
			height: auto;
			width: ${size === 's' ? '20px' : '24px'};
		}
	`;
	return (
		<StyledSelectIcon className="select-trigger" {...props}>
			{children}
		</StyledSelectIcon>
	);
};

const SelectContent = (props: any) => {
	const { children, size } = props;
	const StyledSelectContent = styled(Select.Content)`
		justify-self: flex-start;
		overflow: hidden;
		background-color: white;
		border-radius: 2px;
		box-shadow: 0 2px 22px rgba(61, 64, 67, 0.0749563);
		width: var(--radix-select-trigger-width);
		max-height: var(--radix-select-content-available-height);
	`;
	return (
		<StyledSelectContent className="styled-select-content" {...props}>
			{children}
		</StyledSelectContent>
	);
};

const SelectItem = React.forwardRef(
	(
		{ ...props }: { key: number; value: string; children: ReactNode; disabled?: boolean },
		forwardedRef: ForwardedRef<HTMLDivElement>
	) => {
		return (
			<Item key={props.key} value={props.value} ref={forwardedRef} disabled={props.disabled}>
				<Select.ItemText>{props.children}</Select.ItemText>
				<StyledItemIndicator>
					<CheckIcon />
				</StyledItemIndicator>
			</Item>
		);
	}
);

const Item = (props: any) => {
	const { children, key, value, ref, size, disabled } = props;
	const colors: IColors = useTheme();

	const StyledItem = styled(Select.Item)`
		${selectFontCss(colors, disabled)};
		display: flex;
		align-items: center;
		user-select: none;
		height: ${size === 's' ? '36px' : '40px'};
		width: 100%;
		gap: 8px;
		padding-left: 12px;
		justify-content: space-between;

		&[data-disabled] {
			pointer-events: none;
		}

		&[data-highlighted] {
			outline: none;
			background-color: ${colors.tx_background_fields};
		}
	`;

	return (
		<StyledItem key={key} value={value} ref={ref}>
			{children}
		</StyledItem>
	);
};

const StyledItemIndicator = styled(Select.ItemIndicator)`
	width: 24px;
	align-items: center;
	justify-content: center;
	padding-right: 12px;
`;

const StyledPortal = styled(Select.Portal)`
	z-index: 1000004;
`;

const scrollButtonStyles = (colors: IColors) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: 25,
	backgroundColor: 'white',
	color: colors.tx_text_primary,
	cursor: 'default',
});

const ScrollUp = (props: any) => {
	const { children } = props;
	const colors: IColors = useTheme();

	const StyledScrollUp = styled(Select.ScrollUpButton)`
		${scrollButtonStyles(colors)};
	`;

	return <StyledScrollUp {...props}>{children}</StyledScrollUp>;
};

const ScrollDown = (props: any) => {
	const { children } = props;
	const colors: IColors = useTheme();

	const StyledScrollDown = styled(Select.ScrollDownButton)`
		${scrollButtonStyles(colors)};
	`;

	return <StyledScrollDown {...props}>{children}</StyledScrollDown>;
};

const SelectScrollDownButton = styled(Select.ScrollDownButton)`
	${scrollButtonStyles}
`;

export default memo(SelectRadix);
