/** @jsxRuntime classic */
/** @jsx jsx */

import { useState, Fragment, useMemo, useCallback, useEffect, ReactNode } from 'react';
import { css, jsx } from '@emotion/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { SlotBody } from './popupBody';
import { Popup } from '../../components/Popup';
import { IOption } from '../../types';
import { ColorCode, ITimeSlot } from './types';
import { useSlot } from '../../hooks/useSlot';
import { Maintenance, MaintenanceWeekly } from './MaintenanceSlot';
import { EventSlot } from './EventSlot';
import { InternalWeekly } from './EventSlot/InternalWeekly';
import { ExternalWeekly } from './EventSlot/ExternalWeekly';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

enum SlotTypeEnum {
	EXTERNAL = 'external',
	INTERNAL = 'internal',
	MAINTENANCE = 'maintenance',
	CUSTOM = 'custom',
}

export enum ECalendarMode {
	DAILY = 'daily',
	WEEKLY = 'weekly',
	MONTHLY = 'monthly',
}

export const Slot = (props: {
	event: any;
	triggerEvent: (v: any) => void;
	mode: ECalendarMode;
	isDragging?: boolean;
	isConflict?: boolean;
	conflictCount?: number;
	activeId?: number;
	fetchExpandModeSlot?: (slotId: number, reservationId: number, slotType: string) => any;
	setActivePopup: (id: number) => void;
	Popup?: {
		Component: ReactNode;
		toggle: () => void;
		isOpen: boolean;
	};
	moreOptions?: IOption[];
	onContextMenu?: any;
	handleActions?: (v: string, state: any) => void;
	goToReservation?: (reservationId: number) => void;
	goToConflicts?: (val: ITimeSlot) => void;
	colorCode?: ColorCode;
}) => {
	const [isShowing, setShowing] = useState<boolean>(false);
	const [expandedData, setExpandedData] = useState<any>(null);
	const { isMaintenance, isDaily, isInternal } = useMemo(() => {
		const { event, mode } = props;
		return {
			isMaintenance: event.slotType === SlotTypeEnum.MAINTENANCE,
			isDaily: mode === ECalendarMode.DAILY,
			isInternal: event?.slotType === 'internal',
		};
	}, [props]);

	const { isEditable, startTime, endTime, isDraft } = useSlot({ state: props.event });

	useEffect(() => {
		if (isShowing) {
			(async () => {
				if (props.fetchExpandModeSlot) {
					const data = await props.fetchExpandModeSlot(
						props.event.id,
						props.event.reservation.id,
						props.event.slotType
					);

					setExpandedData({ ...data, conflictCount: props.conflictCount ?? 0 });
				}
			})();
		}
	}, [isShowing]);

	const toggle = () => setShowing(!isShowing);

	const SlotView = useCallback(() => {
		if (isMaintenance) {
			return isDaily ? (
				<Maintenance
					slot={props.event}
					isDragging={props.isDragging}
					isConflict={props.isConflict}
					colorCode={props.colorCode}
				/>
			) : (
				<MaintenanceWeekly state={props.event} isConflict={props.isConflict} colorCode={props.colorCode} />
			);
		}
		if (isInternal) {
			return isDaily ? (
				<EventSlot isInternal={true} {...props} />
			) : (
				<InternalWeekly state={props.event} isConflict={props.isConflict} colorCode={props.colorCode} />
			);
		}

		return isDaily ? <EventSlot isInternal={false} {...props} /> : <ExternalWeekly {...props} />;
	}, [props]);

	const isProgram = props?.event?.reservation?.creatorType === 'program_season';

	if (isDraft) {
		return (
			<span style={{ cursor: 'default' }}>
				<SlotView />
			</span>
		);
	}

	return (
		<Fragment>
			{isProgram ? (
				<Fragment>
					<span onClick={props.Popup?.toggle} onContextMenu={props.onContextMenu}>
						<SlotView />
					</span>
					{props.Popup?.isOpen && props.Popup?.Component}
				</Fragment>
			) : (
				<Popup
					disableMaxHeight
					disableAutoTrigger
					trigger={isShowing}
					onOpenChange={setShowing}
					padding={0}
					limitPlacements={false}
					body={
						<SlotBody
							toggle={toggle}
							triggerEvent={props.triggerEvent}
							state={{ ...props.event, startTime, endTime }}
							isEditable={isEditable}
							expandedData={expandedData}
							handleActions={props.handleActions}
							moreOptions={props.moreOptions}
							goToReservation={props.goToReservation}
							goToConflicts={props.goToConflicts}
						/>
					}
				>
					<span onClick={toggle} onContextMenu={props.onContextMenu}>
						<SlotView />
					</span>
				</Popup>
			)}
		</Fragment>
	);
};
