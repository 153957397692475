/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { ChangeEvent, FC, Fragment } from 'react';
import { ECollapsedNotes, OpenNoteProps, TwoOpenNoteProps } from '../../types';
import { Column } from '../../../../atoms/layoutPrimitives';
import { OpenNote } from '../open-note';
import { i18n } from '../../../../lib/i18n';
export const TwoOpenNotes: FC<TwoOpenNoteProps> = ({ isPublicFirst, publicValue, privateValue, labels, onChange }) => {
	const handlePublicOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		onChange(e, ECollapsedNotes.PUBLIC);
	};

	const handlePrivateOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		onChange(e, ECollapsedNotes.PRIVATE);
	};

	const publicNoteProps = {
		openNote: labels.public,
		onChange: handlePublicOnChange,
		value: publicValue,
		labels: {
			tooltip: labels.publicTooltip,
		},
	};

	const privateNoteProps = {
		openNote: labels.private,
		onChange: handlePrivateOnChange,
		value: privateValue,
		labels: {
			tooltip: labels.privateTooltip,
		},
	};

	const props: OpenNoteProps[] = isPublicFirst
		? [publicNoteProps, privateNoteProps]
		: [privateNoteProps, publicNoteProps];

	return (
		<Column gap="0.5rem" fullWidth>
			{props.map((noteProps, index) => (
				<Fragment key={index}>
					<OpenNote {...noteProps} />
				</Fragment>
			))}
		</Column>
	);
};
