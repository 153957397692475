/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import {css,jsx} from '@emotion/react'
import { IColumn } from './types'
import { useColors } from '../../hooks/useColors';
import { ETypography, ETypographyColor, IColors } from '../../types';
import { Typography } from '../../atoms';
import { printOnly } from '../../styles/utils';

export const headerTdCss = (colors:IColors,isRadio: boolean) => css`
	border-bottom: none;
	padding: 6px;
	background: ${colors.bg_background_primary};
    ${printOnly} {
        border-bottom:1px solid ${colors.bg_border_seperator};
    }
`;


export const Header = ({columns}:{columns:IColumn[]}) => {

    const {colors} = useColors()

    return (
        <tr>
            {columns.map(headCell => {
                return (
                    <th
                        className={`TableCell-root ${headCell.id}-head`}
                        css={headerTdCss(colors,false)}
                        id="cell"
                        key={headCell.id}
                        align={
                            headCell?.styling?.align ? (headCell?.styling?.align === 'right' ? 'right' : 'left') : 'center'
                        }
                    >
                        <Typography type={ETypography.captionAccented} color={ETypographyColor.primary}>
                            {headCell.label}
                        </Typography>
                    </th>
                );
            })}
        </tr>
    )
}