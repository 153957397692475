/** @jsx jsx */

import { ETypography } from 'app/react/styles/theme';
import { useState } from 'react';
import { jsx } from '@emotion/react';
import { CheckboxButton } from '../../utils';
import { TranslationEn } from 'assets/i18n/en';
import { cashContainerCss, changeCss, ContainerCss, middleCss, topPartCss, wrapContainerCss } from './styles';
import { BnIcon, ETypographyColor, Icons, Pricify, Typography } from '@bondsports/utils';

export const Amount = ({ type, optionalAmounts, selectedCard, handleAmountChoose, children, showChange, change }) => (
	<div data-aid="amount" css={ContainerCss(false)}>
		<div css={topPartCss}>
			<div data-aid="amount-choose" css={middleCss}>{TranslationEn.payments.chooseAmount[type]}</div>
		</div>
		<div css={wrapContainerCss(false)}>
			<SelectCashAmount
				optionalAmounts={optionalAmounts}
				selectedCard={selectedCard}
				handleChosenAmount={handleAmountChoose}
			/>
		</div>
		{children}
		{showChange && (
			<div data-aid="amount-change" css={changeCss}>
				<Typography color={ETypographyColor.primary} type={ETypography.body1Accented}>
					{`${TranslationEn.payments.changeDue} ${Pricify(change)}`}
				</Typography>
			</div>
		)}
	</div>
);

const SelectCashAmount = ({ optionalAmounts, selectedCard, handleChosenAmount }) => {
	const [chosen, setChosen] = useState<number | string>(0);

	const handleAmountChoose = (val: string | number) => {
		setChosen(val);
		handleChosenAmount(val);
	};

	return (
		<div data-aid="amount-select" css={cashContainerCss}>
			{optionalAmounts.map((option, index) => {
				return (
					<CheckboxButton
						key={index}
						onClick={() => {
							handleAmountChoose(option);
						}}
						active={chosen === option}
					>
						<BnIcon icon={Icons.dollar} />
						{option}
					</CheckboxButton>
				);
			})}

			<CheckboxButton
				onClick={() => {
					handleAmountChoose('custom');
				}}
				active={selectedCard === 'remove-custom'}
			>
				<BnIcon icon={Icons.edit_outline} />
				{TranslationEn.payments.custom}
			</CheckboxButton>
		</div>
	);
};
