import React from 'react';

export const Note = (props: any) => (
  <svg
    data-aid="svgNote"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.38202 5.02247C5.75623 5.02247 5.15607 5.27107 4.71357 5.71357C4.27107 6.15607 4.02247 6.75623 4.02247 7.38202V14.1236C4.02247 14.7494 4.27107 15.3496 4.71357 15.7921C5.15607 16.2346 5.75623 16.4832 6.38202 16.4832H8.62921C8.89741 16.4832 9.15462 16.5897 9.34427 16.7793L12 19.4351L14.6557 16.7793C14.8454 16.5897 15.1026 16.4832 15.3708 16.4832H17.618C18.2438 16.4832 18.8439 16.2346 19.2864 15.7921C19.7289 15.3496 19.9775 14.7494 19.9775 14.1236V7.38202C19.9775 6.75623 19.7289 6.15607 19.2864 5.71357C18.8439 5.27107 18.2438 5.02247 17.618 5.02247H6.38202ZM3.28346 4.28347C4.10525 3.46168 5.21984 3 6.38202 3H17.618C18.7802 3 19.8947 3.46168 20.7165 4.28347C21.5383 5.10525 22 6.21984 22 7.38202V14.1236C22 15.2858 21.5383 16.4004 20.7165 17.2222C19.8947 18.0439 18.7802 18.5056 17.618 18.5056H15.7897L12.7151 21.5802C12.5254 21.7699 12.2682 21.8764 12 21.8764C11.7318 21.8764 11.4746 21.7699 11.2849 21.5802L8.21035 18.5056H6.38202C5.21984 18.5056 4.10525 18.0439 3.28346 17.2222C2.46168 16.4004 2 15.2858 2 14.1236V7.38202C2 6.21984 2.46168 5.10525 3.28346 4.28347ZM6.47618 8.5046C6.47618 7.93576 6.9373 7.47463 7.50614 7.47463H16.4949C17.0637 7.47463 17.5249 7.93576 17.5249 8.5046C17.5249 9.07343 17.0637 9.53456 16.4949 9.53456H7.50614C6.9373 9.53456 6.47618 9.07343 6.47618 8.5046ZM7.50538 11.9704C6.93654 11.9704 6.47541 12.4315 6.47541 13.0004C6.47541 13.5692 6.93654 14.0303 7.50538 14.0303H14.247C14.8158 14.0303 15.2769 13.5692 15.2769 13.0004C15.2769 12.4315 14.8158 11.9704 14.247 11.9704H7.50538Z"
      fill="currentColor"
    />
  </svg>
);
