import React from "react";

export const Restrooms = (props: any) => (
  <svg
    data-aid="SvgRestrooms"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99967 4.41665C6.40136 4.41665 5.91634 4.90167 5.91634 5.49998C5.91634 6.09829 6.40136 6.58331 6.99967 6.58331C7.59798 6.58331 8.083 6.09829 8.083 5.49998C8.083 4.90167 7.59798 4.41665 6.99967 4.41665ZM4.083 5.49998C4.083 3.88915 5.38884 2.58331 6.99967 2.58331C8.6105 2.58331 9.91634 3.88915 9.91634 5.49998C9.91634 7.11081 8.6105 8.41665 6.99967 8.41665C5.38884 8.41665 4.083 7.11081 4.083 5.49998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99967 12.4167C4.97757 12.4167 4.95638 12.4254 4.94075 12.4411C4.92512 12.4567 4.91634 12.4779 4.91634 12.5V16.1203L5.64786 16.8518C5.81976 17.0237 5.91634 17.2569 5.91634 17.5V22.5C5.91634 23.0063 5.50594 23.4167 4.99967 23.4167C4.49341 23.4167 4.08301 23.0063 4.08301 22.5V17.8797L3.35149 17.1482C3.17958 16.9763 3.08301 16.7431 3.08301 16.5V12.5C3.08301 11.9917 3.28494 11.5042 3.64439 11.1447C4.00383 10.7853 4.49134 10.5833 4.99967 10.5833H8.99967C9.50801 10.5833 9.99552 10.7853 10.355 11.1447C10.7144 11.5042 10.9163 11.9917 10.9163 12.5V16.5C10.9163 16.7431 10.8198 16.9763 10.6479 17.1482L9.91634 17.8797V22.5C9.91634 23.0063 9.50594 23.4167 8.99967 23.4167C8.49341 23.4167 8.08301 23.0063 8.08301 22.5V17.5C8.08301 17.2569 8.17958 17.0237 8.35149 16.8518L9.08301 16.1203V12.5C9.08301 12.4779 9.07423 12.4567 9.0586 12.4411C9.04297 12.4254 9.02178 12.4167 8.99967 12.4167H4.99967Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9997 4.41665C16.4014 4.41665 15.9163 4.90167 15.9163 5.49998C15.9163 6.09829 16.4014 6.58331 16.9997 6.58331C17.598 6.58331 18.083 6.09829 18.083 5.49998C18.083 4.90167 17.598 4.41665 16.9997 4.41665ZM14.083 5.49998C14.083 3.88915 15.3888 2.58331 16.9997 2.58331C18.6105 2.58331 19.9163 3.88915 19.9163 5.49998C19.9163 7.11081 18.6105 8.41665 16.9997 8.41665C15.3888 8.41665 14.083 7.11081 14.083 5.49998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9997 12.4167C15.9776 12.4167 15.9564 12.4254 15.9408 12.4411C15.9251 12.4567 15.9163 12.4779 15.9163 12.5C15.9163 12.5985 15.9005 12.6964 15.8693 12.7899L14.2715 17.5833H14.9997C15.5059 17.5833 15.9163 17.9937 15.9163 18.5V22.5C15.9163 23.0063 15.5059 23.4167 14.9997 23.4167C14.4934 23.4167 14.083 23.0063 14.083 22.5V19.4167H12.9997C12.705 19.4167 12.4283 19.275 12.256 19.036C12.0837 18.7969 12.0369 18.4897 12.13 18.2101L14.0907 12.3281C14.1308 11.8828 14.3257 11.4634 14.6444 11.1447C15.0038 10.7853 15.4913 10.5833 15.9997 10.5833H17.9997C18.508 10.5833 18.9955 10.7853 19.355 11.1447C19.6737 11.4634 19.8685 11.8828 19.9086 12.3281L21.8693 18.2101C21.9625 18.4897 21.9156 18.7969 21.7433 19.036C21.571 19.275 21.2943 19.4167 20.9997 19.4167H19.9163V22.5C19.9163 23.0063 19.5059 23.4167 18.9997 23.4167C18.4934 23.4167 18.083 23.0063 18.083 22.5V18.5C18.083 17.9937 18.4934 17.5833 18.9997 17.5833H19.7279L18.1301 12.7899C18.0989 12.6964 18.083 12.5985 18.083 12.5C18.083 12.4779 18.0742 12.4567 18.0586 12.4411C18.043 12.4254 18.0218 12.4167 17.9997 12.4167H15.9997Z"
      fill="currentColor"
    />
  </svg>
);
