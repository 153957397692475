import React from 'react';

export const Browse = (props: any) => (
  <svg
    data-aid="svgBrowse"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.6273 19.5525H14.3948H13.5243H13.3363V15.2166H14.7544C15.114 15.2166 15.3265 14.8079 15.114 14.5137L12.3555 10.6968C12.1798 10.4516 11.8161 10.4516 11.6404 10.6968L8.8819 14.5137C8.66939 14.8079 8.87781 15.2166 9.24152 15.2166H10.6596V19.5525H10.4716H9.60114H5.8537C3.70821 19.434 2 17.4275 2 15.2534C2 13.7536 2.81324 12.4459 4.0188 11.7389C3.90846 11.4405 3.85125 11.1218 3.85125 10.7867C3.85125 9.25419 5.0895 8.01594 6.62199 8.01594C6.953 8.01594 7.27176 8.07315 7.57009 8.18349C8.45689 6.30364 10.3694 5 12.5926 5C15.4696 5.00409 17.8398 7.20678 18.1095 10.0143C20.3204 10.3944 22 12.4418 22 14.7589C22 17.2354 20.0711 19.3809 17.6273 19.5525Z"
      fill="currentColor"
    />
  </svg>
);
