import React from "react";

export const Program_Tournaments = (props: any) => (
  <svg
    data-aid="svgProgram_Tournaments"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M37.3333 8.88867H35.5556V17.7776H37.3333V8.88867Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3334 38.222C21.3334 36.8817 21.4904 35.5598 21.797 34.2788L4.16002 28.3998C3.80446 28.2753 3.55557 27.9376 3.55557 27.5553V22.222C3.55557 21.7331 3.95557 21.3331 4.44446 21.3331H35.5556V23.1109H5.33335V26.9153L22.3029 32.5719C22.7676 31.2635 23.3955 30.0121 24.1778 28.8442L25.6534 29.8309C23.9911 32.3109 23.1111 35.2176 23.1111 38.222C23.1111 46.5509 29.8934 53.3331 38.2222 53.3331C46.5511 53.3331 53.3333 46.5509 53.3333 38.222C53.3333 30.1864 47.0311 23.5998 39.1111 23.1376V24.918C46.0496 25.377 51.5556 31.1696 51.5556 38.222C51.5556 45.5731 45.5733 51.5553 38.2222 51.5553C30.8711 51.5553 24.8889 45.5731 24.8889 38.222H26.6667C26.6667 44.5953 31.8489 49.7776 38.2222 49.7776C44.5956 49.7776 49.7778 44.5953 49.7778 38.222C49.7778 31.8487 44.5956 26.6665 38.2222 26.6665H33.7778V24.8887H37.3333V21.3331H38.2222C46.5423 21.3331 53.4725 27.3742 54.8588 35.3022C55.4343 34.9014 56.134 34.6665 56.8889 34.6665C58.8533 34.6665 60.4445 36.2576 60.4445 38.222C60.4445 40.1864 58.8533 41.7776 56.8889 41.7776C56.134 41.7776 55.4343 41.5426 54.8588 41.1418C53.4725 49.0698 46.5423 55.1109 38.2222 55.1109C28.9067 55.1109 21.3334 47.5376 21.3334 38.222ZM56.8889 36.4442C55.9111 36.4442 55.1111 37.2442 55.1111 38.222C55.1111 39.1998 55.9111 39.9998 56.8889 39.9998C57.8667 39.9998 58.6667 39.1998 58.6667 38.222C58.6667 37.2442 57.8667 36.4442 56.8889 36.4442Z"
      fill="currentColor"
    />
    <path
      d="M48.1508 12.837L49.6301 13.8231L46.0751 19.1559L44.5958 18.1698L48.1508 12.837Z"
      fill="currentColor"
    />
    <path
      d="M28.2984 18.1776L24.7434 12.8454L23.2643 13.8315L26.8193 19.1637L28.2984 18.1776Z"
      fill="currentColor"
    />
  </svg>
);
