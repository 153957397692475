/** @jsxRuntime classic */
/** @jsx jsx */
import React, { FC, ReactNode, useState } from 'react';
import { useLayer, useHover, Arrow } from 'react-laag';
import { StyledTooltip, bodyCss, closeButton } from './style';
import { jsx, useTheme } from '@emotion/react';
import { Icons, TPlacements } from '../../types/theme';
import { Button } from '../Button';
import { BnIcon } from '../icons_v2';

export interface Props {
	content: string | ReactNode;
	delayEnter?: number;
	delayLeave?: number;
	placement?: TPlacements;
	isTopLayer?: boolean;
	isMobile?: boolean;
}

export const Tooltip: FC<Props> = ({
	children,
	content,
	delayEnter = 10,
	delayLeave = 300,
	placement = 'top-center',
	isTopLayer = false,
	isMobile = false,
}) => {
	const colors = useTheme();
	const [isOver, hoverProps] = useHover({ delayEnter, delayLeave });
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
		isOpen: isMobile ? isOpen : isOver,
		auto: true,
		triggerOffset: 8,
		placement,
	});

	return (
		<div data-aid="index-Tooltip">
			{!isMobile ? (
				<div>
					<div {...triggerProps} {...hoverProps} className="utils_tooltip">
						{children}
					</div>
					{isOver &&
						renderLayer(
							<StyledTooltip
								theme={{ ...colors }}
								className="tooltip"
								{...layerProps}
								isTopLayer={isTopLayer}
								{...hoverProps}
							>
								{content}
								<Arrow {...arrowProps} />
							</StyledTooltip>
						)}
				</div>
			) : (
				<div>
					<div {...triggerProps} onClick={() => setIsOpen(true)} className="utils_tooltip">
						{children}
					</div>
					{isOpen &&
						renderLayer(
							<StyledTooltip theme={{ ...colors }} className="tooltip" {...layerProps} isTopLayer={isTopLayer}>
								<div css={closeButton}>
									<Button
										data-aid="button-tooltip-set"
										sizer="S"
										theme="basic"
										onClick={() => {
											setIsOpen(false);
										}}
									>
										<BnIcon icon={Icons.close} />
									</Button>
								</div>
								<div css={bodyCss}>{content}</div>
								<Arrow {...arrowProps} />
							</StyledTooltip>
						)}
				</div>
			)}
		</div>
	);
};
