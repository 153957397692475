import { promoCodesApi } from '@app/react/lib/api/promoCodesApi';
import { IBasicError } from '@app/react/types/errors';
import { GetOrganizationPromoCodesDto, PaginationMetaDto, PaginationResultDto, PromoCodeDto } from '@bondsports/types';
import { IOption } from '@bondsports/utils';

export const fetchOrgPromoCodes = async <T>(
	organizationId: number,
	queryData: GetOrganizationPromoCodesDto,
	mapOptionsCallback?: (data: PromoCodeDto[]) => T[]
): Promise<PaginationResultDto<T>> => {
	try {
		const response = await promoCodesApi.getOrgPromoCodes({
			organizationId,
			queryData,
		});
		if ((response as IBasicError)?.err) {
			return { data: [], meta: {} as PaginationMetaDto };
		} else {
			return {
				data: mapOptionsCallback
					? mapOptionsCallback((response as PaginationResultDto<PromoCodeDto>).data)
					: (response as PaginationResultDto<T>).data,
				meta: (response as PaginationResultDto<T>).meta,
			};
		}
	} catch (error) {
		return { data: [], meta: {} as PaginationMetaDto };
	}
};

const mapOptions = (data: PromoCodeDto[]): IOption[] => {
	return data.map(data => {
		return {
			label: data.discount.name,
			value: data.discountId,
			additionalInfo: data.code,
			isDisabled: !data.discount.isValid || data.discount.uses === data.discount.maxUses,
		};
	});
};

export const fetchPromoCodes = async (
	organizationId: number,
	queryData: GetOrganizationPromoCodesDto
): Promise<PaginationResultDto<IOption>> => {
	return fetchOrgPromoCodes(organizationId, queryData, mapOptions);
};
