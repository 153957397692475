import React, { Fragment, forwardRef, useEffect } from 'react';
import { useModal } from '../../hooks/useModal';
import { Modal as ModalWindow } from './modal';

export interface IModal {
	body: React.ReactNode;
	children?: React.ReactNode;
	callback?: () => void;
	padding?: number;
	minWidth?: string;
	minHeight?: string;
	maxWidth?: string;
	maxHeight?: string;
	isFullSize?: boolean;
	isFullWidth?: boolean;
	isFullHeight?: boolean;
	isMaximizable?: boolean;
	maximizedCallback?: (val: boolean) => void;
}
export const Modal = forwardRef<HTMLDivElement, IModal>(
	(
		{
			children,
			body,
			minWidth,
			minHeight,
			maxWidth,
			maxHeight,
			padding = 1,
			isFullSize = false,
			isFullHeight = false,
			isFullWidth = false,
			isMaximizable = false,
			callback = () => {},
			maximizedCallback = () => {},
		},
		ref
	) => {
		const { isShowing, toggle } = useModal();

		useEffect(() => {
			callback();
		}, [isShowing]);

		return (
			<Fragment>
				<div data-aid="modal" onClick={toggle}>{children}</div>
				<ModalWindow
					padding={padding}
					ref={ref}
					minWidth={minWidth}
					minHeight={minHeight}
					maxWidth={maxWidth}
					maxHeight={maxHeight}
					isFullSize={isFullSize}
					isFullWidth={isFullWidth}
					isFullHeight={isFullHeight}
					isShowing={isShowing}
					toggle={toggle}
					isMaximizable={isMaximizable}
				>
					{body}
				</ModalWindow>
			</Fragment>
		);
	}
);
