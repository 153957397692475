import * as  React from 'react';

export const BnConflict = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 2.33333C20.4434 2.33333 25.6667 7.55667 25.6667 14C25.6667 20.4433 20.4434 25.6667 14 25.6667C7.55672 25.6667 2.33337 20.4433 2.33337 14C2.33337 7.55667 7.55672 2.33333 14 2.33333ZM14.0001 4.66654C8.84549 4.66654 4.66681 8.84521 4.66681 13.9999C4.66681 19.1545 8.84549 23.3332 14.0001 23.3332C19.1548 23.3332 23.3335 19.1545 23.3335 13.9999C23.3335 8.84521 19.1548 4.66654 14.0001 4.66654ZM14 18.6665C14.6444 18.6665 15.1667 19.1889 15.1667 19.8332C15.1667 20.4775 14.6444 20.9999 14 20.9999C13.3557 20.9999 12.8334 20.4775 12.8334 19.8332C12.8334 19.1889 13.3557 18.6665 14 18.6665ZM14 7.00012C14.6444 7.00012 15.1667 7.52245 15.1667 8.16678V15.1668C15.1667 15.8111 14.6444 16.3335 14 16.3335C13.3557 16.3335 12.8334 15.8111 12.8334 15.1668V8.16678C12.8334 7.52245 13.3557 7.00012 14 7.00012Z"
      fill="currentColor"
    />
  </svg>
);
