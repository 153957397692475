/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from '@emotion/react';
import { N_A } from '../../../lib/constants';

export const containerCss = css`
	font-size: 14px;
	max-width: 800px;
	min-height: 100%;
	height: fit-content !important;
	overflow-y: auto;
	background: white;
	display: flex;
	flex-direction: column;
	padding: 20px;

	.flex {
		display: flex;
	}

	.questions-container {
		margin-top: 8px;
		margin-bottom: 8px;
		padding: 10px;
		border-radius: 8px;
		border: 1px solid;
	}

	.flex-col {
		flex-direction: column;
	}

	.mt-8 {
		margin-top: 8px;
	}

	.customer-details-container {
		border-radius: 8px;
		border: 1px solid;
		padding: 10px;
		margin-top: 8px;
	}
`;

export const answerCss = (isColumn: boolean) => css`
	margin: 4px 0;

	display: flex;
	align-items: center;
	min-height: 30px;
	flex-direction: ${isColumn ? 'column' : 'row'};
	${isColumn
		? `
    margin-top:8px;
      align-items: baseline;
      .textData {
       border-top: 1px solid;
      }
      `
		: ''}
	.answer {
		display: flex;
		align-items: center;
		margin-left: 16px;
	}
`;

export const sectionCss = css`
	margin: 20px 0;
	.header {
		text-align: center;
		font-variant-numeric: tabular-nums;
	}
	.divider {
		margin: 0 5px;
	}
	h4 {
		font-size: 20px;
		line-height: 24px;
	}
`;
export const detailsCss = (data: string) => css`
	div {
		color: unset;
		font-weight: ${data === N_A ? 'bold' : 'unset'};
	}
`;
export const customerDetailLineCss = css`
	display: flex;
	margin-top: 8px;
	margin-bottom: 8px;
	gap: 16px;
`;
