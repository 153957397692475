import * as  React from 'react';

export const BnSupport = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99999 1.66675C5.39762 1.66675 1.66666 5.39771 1.66666 10.0001C1.66666 14.6025 5.39762 18.3334 9.99999 18.3334C14.6024 18.3334 18.3333 14.6025 18.3333 10.0001C18.3333 5.39771 14.6024 1.66675 9.99999 1.66675ZM2.94871 10.0001C2.94871 8.67269 3.31549 7.43097 3.95323 6.37073L6.18656 8.60405C6.02715 9.03935 5.94016 9.50956 5.94016 10.0001C5.94016 10.4906 6.02715 10.9608 6.18655 11.3961L3.95323 13.6294C3.31549 12.5692 2.94871 11.3275 2.94871 10.0001ZM8.60404 6.18662L6.3707 3.95328C7.43093 3.31556 8.67263 2.9488 9.99999 2.9488C11.3274 2.9488 12.5691 3.31559 13.6294 3.95335L11.3961 6.18667C10.9608 6.02725 10.4905 5.94025 9.99999 5.94025C9.5095 5.94025 9.03932 6.02724 8.60404 6.18662ZM13.8135 8.60418C13.9728 9.03945 14.0598 9.50961 14.0598 10.0001C14.0598 10.4906 13.9728 10.9607 13.8135 11.396L16.0468 13.6293C16.6845 12.5691 17.0513 11.3274 17.0513 10.0001C17.0513 8.67274 16.6845 7.43105 16.0468 6.37084L13.8135 8.60418ZM6.3707 16.0469C7.43092 16.6846 8.67263 17.0514 9.99999 17.0514C11.3274 17.0514 12.5691 16.6846 13.6294 16.0468L11.3961 13.8135C10.9608 13.9729 10.4905 14.0599 9.99999 14.0599C9.50949 14.0599 9.03931 13.9729 8.60403 13.8135L6.3707 16.0469ZM7.22221 10.0001C7.22221 8.46596 8.46587 7.2223 9.99999 7.2223C11.5341 7.2223 12.7778 8.46596 12.7778 10.0001C12.7778 11.5342 11.5341 12.7779 9.99999 12.7779C8.46587 12.7779 7.22221 11.5342 7.22221 10.0001Z"
      fill="currentColor"
    />
  </svg>
);
