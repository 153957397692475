/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { SectionContainer } from '../../../molecules/invoicing';
import { PaymentContext } from '../context';
import { Table } from '../../../molecules/table';
import { expandLineItemData } from '../../../molecules/invoicing/details/lib';
import { SummaryLine } from './summaryLine';
import { Pricify } from '../../../lib/price';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { LineItemColumn } from './lineItemColumn';
import { AlignType, IColumn } from '../../../molecules/table/types';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor } from '../../../types';
import { capitalize } from '../../../functions';
import { handleTaxesOnLineItem, sortAndGroupLineItems } from '../../invoiceTemplate/lib';
import { printFont } from '../../../styles/utils';
import { cloneDeep } from 'lodash';
export const Details = () => {
	return <div data-aid="index-details">details</div>;
};
const footerCss = css`
	display: flex;
	padding: 16px;
	flex-direction: column;
	gap: 12px;

	@media print {
		break-inside: avoid;
	}

	@media print and (max-width: 10cm) {
		display: initial;
		padding: initial;
		flex-direction: initial;
		gap: initial;
		${printFont};
	}
`;

const printContainerCss = css`
	@media print and (max-width: 10cm) {
		display: flex;
		${printFont}
	}
	display: none;
	width: 100%;
	flex-direction: column;
`;

const bottomBorderCss = css`
	border-bottom: black 1px dashed;
`;

const printRowCss = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-bottom: 0.5rem;
`;

const bottomPaddingCss = css`
	padding-bottom: 8px;
	margin-bottom: 8px;
`;

const notMobileCss = css`
	@media print and (max-width: 10cm) {
		display: none;
	}
`;

export const PrintableDetails = () => {
	const { isMobile } = useWindowSize();
	const [itemsRows, setItemsRows] = useState<any[]>([]);
	const [originalPrice, setOriginalPrice] = useState<number>(0);
	const [total, setTotal] = useState(0);
	const [feesAmount, setFeesAmount] = useState(0);
	const [totalTax, setTotalTax] = useState<{ taxesExcl: any; taxesInc: any }>({
		taxesExcl: {},
		taxesInc: {},
	});

	const { labels, isLoading, state } = useContext(PaymentContext);

	const primaryColor = isMobile ? ETypographyColor.black : ETypographyColor.primary;
	const secondaryColor = isMobile ? ETypographyColor.black : ETypographyColor.secondary;

	const ItemsColumns: IColumn[] = [
		{
			id: 'item',
			label: ` ${labels.details.table.columns.item} (${itemsRows.length})`,
			type: 'custom',
			component: (state: any) => {
				return <LineItemColumn {...state} />;
			},
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: 'quantity',
			label: labels.details.table.columns.quantity,
			type: 'string',
		},
		{
			id: 'price',
			label: labels.details.table.columns.price,
			type: 'currency',
		},
		{
			id: 'tax',
			label: labels.details.table.columns.tax,
			type: 'string',
		},
		{
			id: 'total',
			label: labels.details.table.columns.total,
			type: 'currency',
			styling: {
				align: AlignType.RIGHT,
			},
		},
	];

	useEffect(() => {
		if (state && !itemsRows.length) {
			let calculatedOriginalPrice = 0;
			let totalTax = {
				taxesExcl: {},
				taxesInc: {},
			};
			const sortedLineItems: any[] = [];
			sortAndGroupLineItems(
				cloneDeep({
					invoice: state,
				} as any),
				sortedLineItems,
				true
			);
			const items = Object.keys(sortedLineItems).map(key => {
				const lineHistory = sortedLineItems[key];

				const item = { ...lineHistory };

				return expandLineItemData({ ...item, displayTotalPrice: lineHistory.paidAmount }, [] , true, false);
			});

			const taxLineItems = state.lineItemHistory
				.filter((hli: any) => hli.lineItem.productType === 'tax')
				.map((hli: any) => ({
					isTaxInclusive: hli.lineItem.isTaxInclusive,
					price: hli.paidAmount,
					quantity: 1,
					taxPrecent: hli.lineItem.taxPrecent,
				}));

			totalTax = handleTaxesOnLineItem(taxLineItems, totalTax);

			const totalIncludedTax: number = Object.values(totalTax.taxesInc).reduce(
				(sum: number, tax: number) => sum + tax,
				0
			) as number;
			const totalExcludedTax: number = Object.values(totalTax.taxesExcl).reduce(
				(sum: number, tax: number) => sum + tax,
				0
			) as number;

			const productLineItems = state.lineItemHistory.filter((hli: any) => hli.lineItem.productType !== 'tax');

			productLineItems.forEach((hli: any) => {
				calculatedOriginalPrice += hli.paidAmount;
			});

			const total = calculatedOriginalPrice + totalIncludedTax + totalExcludedTax + (state.totalFeesAmount ?? 0);

			setTotal(total);
			setOriginalPrice(calculatedOriginalPrice);
			setFeesAmount(state.totalFeesAmount ?? 0);
			setTotalTax(totalTax);
			setItemsRows(items as any[]);
		}
	}, []);

	const FooterComponent = useCallback(() => {
		return (
			<div css={footerCss}>
				<SummaryLine label={labels?.details?.summary?.subtotal} value={Pricify(originalPrice)} isLoading={isLoading} />
				{Object.keys(totalTax.taxesExcl).length > 0 &&
					Object.keys(totalTax.taxesExcl).map((te: any, idx: number) => (
						<SummaryLine
							key={`taxExc-${idx}`}
							label={`${labels?.details?.summary?.tax} (${te}%)`}
							value={Pricify(totalTax.taxesExcl[te])}
							isLoading={isLoading}
						/>
					))}
				{Object.keys(totalTax.taxesInc).length > 0 &&
					Object.keys(totalTax.taxesInc).map((ti: any, idx: number) => (
						<SummaryLine
							key={`taxInc-${idx}`}
							label={`${labels?.details?.summary?.taxInc} (${ti}%)`}
							value={Pricify(totalTax.taxesInc[ti])}
							isLoading={isLoading}
						/>
					))}
				{feesAmount > 0 && (
					<SummaryLine label={labels?.details?.summary?.fees} value={Pricify(feesAmount)} isLoading={isLoading} />
				)}
				<SummaryLine label={labels.details?.summary?.total} value={Pricify(total)} isLoading={isLoading} />
			</div>
		);
	}, [isLoading, labels, state]);

	return (
		<Fragment>
			<div css={printContainerCss}>
				{itemsRows?.map((row: any, idx: number) => (
					<div css={[bottomPaddingCss, bottomBorderCss]} key={`printRow-${idx}`}>
						<div css={printRowCss}>
							<Typography color={primaryColor} type={ETypography.body2}>
								{capitalize(row.item.name)}
							</Typography>
							<Typography color={secondaryColor} type={ETypography.body2}>
								{Pricify(row.total)}
							</Typography>
						</div>
						{Boolean(row.item.description) && (
							<div css={printRowCss}>
								<Typography color={secondaryColor} type={ETypography.caption}>
									{row.item.description}
								</Typography>
							</div>
						)}
						<div css={printRowCss}>
							<Typography color={secondaryColor} type={ETypography.caption}>
								{`Quantity x${row.quantity}`}
							</Typography>
						</div>
					</div>
				))}
				<FooterComponent />
			</div>
			<div css={notMobileCss}>
				<SectionContainer title={labels.details.title}>
					<Table<any>
						columns={ItemsColumns}
						rows={itemsRows as any[]}
						pagination={false}
						isLoading={isLoading}
						footer={<FooterComponent />}
						isHover={false}
						maxHeight={-1}
					/>
				</SectionContainer>
			</div>
		</Fragment>
	);
};
