import React from "react";

export const Membership = (props: any) => (
  <svg
    data-aid="svgMembership"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.33329 6.66602C7.70148 6.66602 7.99996 6.36754 7.99996 5.99935C7.99996 5.63116 7.70148 5.33268 7.33329 5.33268H3.33329C2.9651 5.33268 2.66663 5.63116 2.66663 5.99935C2.66663 6.36754 2.9651 6.66602 3.33329 6.66602H7.33329Z"
      fill="currentColor"
    />
    <path
      d="M5.33329 7.99935C5.33329 8.36754 5.03482 8.66602 4.66663 8.66602H3.33329C2.9651 8.66602 2.66663 8.36754 2.66663 7.99935C2.66663 7.63116 2.9651 7.33268 3.33329 7.33268H4.66663C5.03482 7.33268 5.33329 7.63116 5.33329 7.99935Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666626 11.3327V4.66602C0.666626 3.56145 1.56206 2.66602 2.66663 2.66602H13.3333C14.4379 2.66602 15.3333 3.56145 15.3333 4.66602V11.3327C15.3333 12.4373 14.4379 13.3327 13.3333 13.3327H2.66663C1.56206 13.3327 0.666626 12.4373 0.666626 11.3327ZM2.66663 3.99935H13.3333C13.7015 3.99935 14 4.29783 14 4.66602V10.666H1.99996V4.66602C1.99996 4.29783 2.29844 3.99935 2.66663 3.99935Z"
      fill="currentColor"
    />
  </svg>
);
