import React from 'react';

export const Plus = (props: any) => (
  <svg
    data-aid="svgPlus"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.375 5C13.375 4.24061 12.7594 3.625 12 3.625C11.2406 3.625 10.625 4.24061 10.625 5V10.625H5C4.24061 10.625 3.625 11.2406 3.625 12C3.625 12.7594 4.24061 13.375 5 13.375H10.625V19C10.625 19.7594 11.2406 20.375 12 20.375C12.7594 20.375 13.375 19.7594 13.375 19V13.375H19C19.7594 13.375 20.375 12.7594 20.375 12C20.375 11.2406 19.7594 10.625 19 10.625H13.375V5Z"
      fill="currentColor"
    />
  </svg>
);
