/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { ModalBody } from '@app/react/components/shared/ModalBody';
import { ETypography, ETypographyColor, Icons, Typography } from '@bondsports/utils';
import { colors } from '@app/react/styles/theme';
import { centeredColumn, justifyCenterCss, marginTop } from '@app/react/styles/utils';

interface ILabels {
	title: string;
	description: string;
	mainButton: string;
	descriptionSecondLine?: string;
}
export const InfoModalBody = ({
	morInfo,
	mainAction,
	labels,
	isProcessing,
	handleBack,
	customWidth,
}: {
	morInfo?: string;
	mainAction: () => void;
	labels: ILabels;
	isProcessing?: boolean;
	handleBack?: () => void;
	customWidth?: number;
}) => {
	return (
		<ModalBody
			title={labels.title}
			mainButton={labels.mainButton}
			mainAction={mainAction}
			iconColor={colors.dangerRed}
			icon={Icons.conflict}
			iconWidth={20}
			iconHeight={20}
			handleBack={handleBack}
			customWidth={customWidth || 440}
			customIsProcessing={isProcessing}
			compact
		>
			<div css={[marginTop(18), centeredColumn]}>
				<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
					{labels.description}
				</Typography>
				<div css={marginTop(8)}>
					{labels.descriptionSecondLine && (
						<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
							{labels.descriptionSecondLine}
						</Typography>
					)}
				</div>
				{morInfo && (
					<div css={[marginTop(8), justifyCenterCss]}>
						<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
							{morInfo}
						</Typography>
					</div>
				)}
			</div>
		</ModalBody>
	);
};
