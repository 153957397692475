/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { ETypographyColor, Icons } from '../../types';
import { Tooltip } from '../../components/Tooltip';
import { tooltipWithButtonCss } from '../../organisms/invoiceTemplate/styles';
import { BnIcon } from '../../components/icons_v2';

interface TooltipWithButtonProps {
	children: React.ReactNode;
	tooltipContent: React.ReactNode;
	showButton?: boolean;
	icon: Icons;
}
export const TooltipWithButton = ({
	icon,
	tooltipContent,
	showButton = true,
	children,
}: React.PropsWithChildren<TooltipWithButtonProps>) => {
	return (
		<div css={tooltipWithButtonCss}>
			<Tooltip content={tooltipContent}>
				{children}
				{showButton && <BnIcon icon={icon} color={ETypographyColor.primary} />}
			</Tooltip>
		</div>
	);
};
