import React from "react";

export const Passes = (props: any) => (
  <svg
    data-aid="SvgPasses"
    width="26"
    height="23"
    viewBox="0 0 26 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.530339 6.79003C0.410902 6.0883 0.650517 5.4197 1.09875 4.97539L1.6755 5.74267C1.06732 6.08481 0.664414 6.7539 0.664414 7.50199V7.57778L0.530339 6.79003ZM25.5 10.5288V10.5465C25.4381 10.5576 25.3768 10.5708 25.3164 10.586L25.5 10.5288ZM25.5 16.4752L25.3164 16.418C25.3768 16.4331 25.4381 16.4463 25.5 16.4575V16.4752ZM0.709199 16.4752V16.4575C0.771156 16.4463 0.83239 16.4331 0.89284 16.418L0.709199 16.4752ZM0.709199 10.5288L0.892822 10.586C0.832378 10.5708 0.77115 10.5576 0.709199 10.5465V10.5288Z"
      fill="#7C9CB4"
      stroke="#7C9CB4"
    />
  </svg>
);
