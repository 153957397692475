/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { footerWithButtonsCss } from './styles';
import { Fragment } from 'react';
import { CTAButton } from '../../../CTAButton';
import { SecondaryButtonCss, ChargeButtonCss } from './styles';
import { Loader as SpinningLoader } from '../../../../components/Loader';

interface FooterWithButtonsProps {
	labels: {
		cancel: string;
		next: string;
	};
	isLoading: boolean;
	onCancel: () => void;
	onNext: () => void;
	nextDisabled?: boolean;
}

export const ModalFooterWithTwoButtons = ({
	labels,
	nextDisabled,
	isLoading,
	onCancel,
	onNext,
}: FooterWithButtonsProps) => (
	<div css={footerWithButtonsCss} data-aid="modal-layout-footer-with-buttons">
		<CTAButton css={SecondaryButtonCss} onClick={onCancel}>
			{labels.cancel}
		</CTAButton>
		<CTAButton css={ChargeButtonCss} disabled={nextDisabled} onClick={onNext}>
			{isLoading ? <SpinningLoader /> : <Fragment>{labels.next}</Fragment>}
		</CTAButton>
	</div>
);
