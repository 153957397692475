import { Product } from './product';
import { EPaymentMethod, EPaymentStatus } from './payment';
import { Answers } from './sessionParticipant';
import { BaseInvoiceDto, SegmentDto } from './NewReservation';
import { ISlot } from '@bondsports/utils';
import {
	CustomerFamilyStatusEnum,
	CustomerTypeEnum,
	Invoice,
	PaymentMethodTypeEnum,
	ReservationTypeEnum,
	Series,
} from '@bondsports/types';
import { Reservation } from 'app/react/types/calendar';

export const ReservationStatusColorsMapper = {
	Planned: 'gray',
	Approved: 'green',
	'Awaiting Admin': 'yellow',
	Rejected: 'red',
	Canceled: 'red',
};

export const PaymentStatusColorsMapper = {
	paid: 'green',
	not_paid: 'red',
	partial: 'yellow',
	void: 'gray',
	purchase_order: 'purple',
	quote: 'pink',
};

export enum EReservationStatus {
	PLANNED = 'Planned',
	APPROVED = 'Approved',
	AWAITING_ADMIN = 'Awaiting Admin',
	AWAITING_CUSTOMER = 'Awaiting Customer',
	REJECTED = 'Rejected',
	DELETED = 'Deleted',
	CANCELED = 'Canceled',
}

export enum PaymentStatusDisplayStrings {
	not_paid = 'Unpaid',
	paid = 'Paid',
	partial = 'Partial',
	purchase_order = 'Order',
	refunded = 'Refunded',
	void = 'Void',
	quote = 'Quote',
}

export enum FrequencyEnum {
	daily = 'Daily',
	weekly = 'Weekly',
	monthly = 'Monthly',
}

export interface ReservationData {
	id: number;
	reservationName: string;
	customerId: number;
	customerFirstName: string;
	customerLastName: string;
	spaces: {
		spaceId: number;
		spaceName: string;
		reservationId: number;
	}[];
	startDate: string;
	endDate: string;
	price: number;
	status: string;
	paymentStatus: string;
	//hasConflicts: boolean, // V3
	frequencies: {
		frequency?: string;
		repeatEvery?: number;
		repeatOn?: number[];
		reservationId: number;
		repeatEndDate?: string;
	}[];
	reservationType: ReservationTypeEnum;
}

export interface ISingleReservation {
	id: number;
	name: string;
	description: string;
	updatedAt: string;
	privateNotes: string;
	publicNotes: string;
	facilityId: number;
	approvalStatus: EReservationStatus; // enum
	paymentStatus: EPaymentStatus;
	sports: number;
	customerId: number;
	customer?: ReservationCustomer;
	invoices?: ReservationInvoice[]; // including 'lineItemsCount' field
	payments?: ReservationPayments[];
	addons?: IReservationAddons[];
	series: Series[]; // including 'slots' relation
	answers?: Answers[];
	reservationType: ReservationTypeEnum;
	segments: SegmentDto[];
}

// can replace ISingleReservation, is more accurate
export interface ISingleReservationResponse {
	id: number;
	name: string;
	organizationId: number;
	description: string;
	updatedAt: Date; //string?
	privateNotes: string;
	publicNotes: string;
	approvalStatus: string; // enum
	paymentStatus: EPaymentStatus; // PaymentStatusEnum;
	reservationType: ReservationTypeEnum;
	sports: number | number[];
	addonsProductUserIds: number[];
	facilityId?: number;
	customerId: number;
	customer?: ReservationCustomer; //verify if correct type
	invoices?: Invoice[]; // including 'lineItemsCount' field
	payments?: any[]; //TODO: check correct type -> IPartialPayment[];
	addons?: IReservationAddons[]; //IProduct[];
	answers?: Answers[];
	series: Series[]; // including 'slots' relation
	segments?: SegmentDto[]; // including 'series' and 'series.slots' relation
}

export interface ReservationCustomer {
	id: number;
	createdAt: string;
	updatedAt: string;
	organizationId: number;
	name: string;
	entityId: number;
	addressId: number;
	entityType: string;
	customerId: number;
	email: string;
	color: string;
	phoneNumber: string;
	mainMediaId: number;
	creatorId: number;
	creatorType: string;
	userCreatorId: number;
	ownerId: number;
	vetted: boolean;
	firstName: string;
	lastName: string;
	waiverSignedDate: string;
	familyStatus: CustomerFamilyStatusEnum;
	balance: number;
	importedId: number;
	mainMedia: string;
	storedCredit?: number;
	customerType: CustomerTypeEnum;
	linkedAccounts?: number[];
}

export interface ReservationInvoice {
	id: number;
	createdAt: string;
	price: string;
	paymentStatus: string;
	paidAmount: string;
	lineItemsCount: string;
	payingUserId: number;
}

export interface ReservationPayments {
	id: string;
	createdAt: string;
	price: string;
	paymentStatus: string;
	paymentType: PaymentMethodTypeEnum;
	ccLast4?: string;
	ccBrand?: string;
}

export enum AddonParentTypeEnum {
	RESERVATION = 'reservation',
	SLOT = 'slot',
}

export enum ProductPackageLevelEnum {
	HOUR = 'hour',
	SLOT = 'slot',
	RESERVATION = 'reservation',
}

export enum ReservationStatusEnum {
	PLANNED = 'Planned',
	APPROVED = 'Approved',
	AWAITING_ADMIN = 'Awaiting Admin',
	AWAITING_CUSTOMER = 'Awaiting Customer',
	REJECTED = 'Rejected',
	CANCELED = 'Canceled',
}

export enum PaymentStatusEnum {
	NOT_PAID = 'not_paid',
	PARTIAL_PAYMENT = 'partial',
	FULLY_PAID = 'paid',
	REFUNDED = 'refunded',
	VOID = 'void',
}

export interface IReservationAddons {
	addonsPurchases?: IReservationAddons[];
	id: number;
	parentId: number;
	parentType: AddonParentTypeEnum;
	organizationId: number;
	level?: ProductPackageLevelEnum;
	productId: number;
	product: Product;
	originalProduct?: Product;
	quantity: number;
	approvalStatus: ReservationStatusEnum;
	paymentStatus: PaymentStatusEnum;
	unitPrice: number;
	totalPrice: number; // tax calculated inside
	resourceId?: number; // for the future (locker..)
	invoiceId?: number;
	productUserId?: number;
	isScheduled?: boolean;
}

export interface MaintenanceDto {
	title: string;
	durationValue: number;
	maintenanceDurationdurationType: string;
	maintenanceTiming: number;

	// init by server
	id?: number; // need for update

	// for v3
	isResourcesBlocked?: boolean; // for v3
	//   tasks:ITask[] // for V3
}

export const DurationTimingStrings = {
	1: 'Before',
	2: 'After',
	3: 'At the beggining of the event',
	4: 'At the end of the event',
};

export enum EventActionsEnum {
	DUPLICATE = 'duplicate',
	DELETE = 'delete',
}

export interface IAddon {
	id: number;
	name: string;
	qty: number;
	price?: number;
	addonId?: number;
	totalPrice?: number;
}

export interface IDeleteFromResv {
	panelAddons?: IAddon[];
	panelSlotEvent?: ISlot;
	totalPrice?: number;
	isScheduled: boolean;
}
export enum ReservationActionsEnum {
	ADD_SEGMENT = 'addSegment',
}

export interface UpdateReservationDto {
	isInvoiced: boolean; // if this is added as invoice or purchase_invoice
	addToInvoiceId?: number; // when invoiced and missing - create new invoice
	basicInfoData?: BaseInvoiceDto;
}

export interface AddSegmentToReservationDto extends UpdateReservationDto {
	segments: SegmentDto[];
}

//duplicated -exists also in newReservation types
export enum FinancialStepEnum {
	VOID = 'void',
	REFUND = 'refund',
	NONE = 'none',
	REFUND_AND_VOID = 'refund-and-void',
	APPEND = 'append', // add to existing enum
}

export interface SlotsLineItemData {
	// change from existing SlotsLineItemData
	id: number;
	slotIds: number[];
	amount: number;
	amountPaid: number;
	quantity: number;
	canVoid: boolean;
	canRefund: boolean;
	isEdit: boolean; // add to existing interface
}
export interface EditSlotsRefundMetadata {
	lineItems: SlotsLineItemData[];
	refundAmount: number;
}

export interface IPaymentMethodToFundLeft {
	paymentType: EPaymentMethod;
	paymentMethodId: string;
	fundLeft: number;
	ccLast4?: string;
	ccBrand?: string;
}

export interface ValidateEditSlotsResult {
	paymentMethods?: IPaymentMethodToFundLeft[];
	refundMetadata?: EditSlotsRefundMetadata;
	nextFinancialStep: FinancialStepEnum;
	updatedInvoicePrice?: number;
	originalInvoicePrice?: number;
	priceChange?: number;
}

export interface IReservationDetails {
	readonly id?: number;
	name: string;
	privateNotes: string;
	publicNotes: string;
}

export interface IRemoveAddonResp {
	nextFinancialStep: FinancialStepEnum;
	paymentMethods: IPaymentMethodToFundLeft[];
	lineItems: SlotsLineItemData[];
	invoice?: Invoice;
}

export interface IAddAddon {
	addons: {
		productId: number;
		price?: number;
		quantity?: number;
		totalPrice?: number; // add
	}[];
}

export interface ISelectedAddon {
	productId: number;
	price: number;
	quantity: number;
	totalPrice: number;
	name: string;
}

export interface IInvoiceAddon {
	slotsIds: number[];
	addonsIds: number[];
	basicInfoData?: BaseInvoiceDto;
	addToInvoiceId?: number;
}

export interface IUpdateReservationResult {
	reservation: Reservation;
	invoice?: Invoice;
	debug?: any;
}

export enum KeywordsEnum {
	ERR = 'err',
	TRUE = 'true',
	FALSE = 'false',
}
