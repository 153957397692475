/** @jsx jsx */

import React, { CSSProperties, FC } from 'react';
import { jsx, css } from '@emotion/react';

const scrollWrapper = (height: string, margin: string, width?: number, fullWidth = false) => css`
	min-height: ${height};
	width: ${fullWidth ? '100%' : '80%'};
	${!fullWidth && `max-width: ${width ? `${width}px` : '800px'}`};
	margin: ${margin};
	padding-top: ${fullWidth ? 0 : '2rem'};
	flex-grow: ${width ? 0 : 1};
	overflow-y: scroll;
`;
const bodyCss = (isTransparent: boolean, bodyPadding: number) => css`
	padding: ${bodyPadding}rem;
	margin-bottom: 2rem;
	background: ${isTransparent ? 'transparent' : '#fff'};
	box-shadow: ${isTransparent ? 'unset' : '0px 2px 22px rgba(21, 93, 148, 0.0749563)'};
`;

export const MiddleWhiteContainer: FC<{
	isTransparent?: boolean;
	height?: string;
	margin?: string;
	width?: number;
	fullWidth?: boolean;
	bodyPadding?: number;
	style?: CSSProperties;
}> = ({
	children,
	margin = '0 auto',
	isTransparent = false,
	height = '200px',
	fullWidth = false,
	width,
	bodyPadding = 2,
	style,
}) => {
	return (
		<div className="scroller" css={scrollWrapper(height, margin, width, fullWidth)} style={style}>
			<div css={bodyCss(isTransparent, bodyPadding)}>{children}</div>
		</div>
	);
};
