/* @jsx jsx */

import React, { useCallback, useContext } from 'react';
import { jsx } from '@emotion/react';
import { CustomerActivitiesContext } from '../customerActivitiesContext';
import { PunchPassDetailsCard } from '../components/PunchPassDetailsCard';
import { ActivityView } from './ActivityView';
import { ProgramTypesEnum, SimpleSessionDto } from '@bondsports/types';
import { ActivitiesEnum } from '../types/enums';
import { customersApi } from '../../../../lib/api/customersApi';
import { useNavigation } from '../../../../hooks/useNavigation';
import { SimpleProgramWithSportDto } from '../types/types';

const PUNCH_PASSES_PER_PAGE = 2;

export const PunchPassView: React.FC = () => {
	const { customer, displayUserDetails, labels, timezone } = useContext(CustomerActivitiesContext);
	const { ngNavigate, pushNavigation } = useNavigation();

	const onViewInvoice = (invoiceId: number) => pushNavigation(`/customer/${customer.id}/invoices/${invoiceId}/details`);

	const onViewPayment = (paymentId: number) => pushNavigation(`/customer/${customer.id}/payments/${paymentId}`);

	const onNavigateToSession = (session: SimpleSessionDto, program: SimpleProgramWithSportDto): void => {
		const programTypeName: string = ProgramTypesEnum[program.type].toLowerCase();
		ngNavigate('activities', `program/${programTypeName}/${program.id}/sessions/${session.id}`);
	};

	const getData = useCallback(
		(page, options) =>
			customersApi.getCustomersPunchPasses(customer.id, customer.organizationId, PUNCH_PASSES_PER_PAGE, page, {
				signal: options?.signal,
			}),
		[customer]
	);

	return (
		<ActivityView getData={getData}>
			{({ data, index }) => (
				<PunchPassDetailsCard
					labels={labels.activities[ActivitiesEnum.PUNCH_PASS].card}
					data={data}
					displayUserDetails={displayUserDetails}
					onViewInvoice={onViewInvoice}
					onViewPayment={onViewPayment}
					timezone={timezone}
					onNavigateToSession={onNavigateToSession}
					index={index}
				/>
			)}
		</ActivityView>
	);
};
