import React, { useState, useEffect } from 'react';

export const useDebounce = <T,>(searchFilter: T, delay: number) => {
	const [debouncedValue, setDebouncedValue] = useState(searchFilter);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(searchFilter);
		}, delay);

		// Cancel the timeout if value changes (also on delay change or unmount)
		// This is how we prevent debounced value from updating if value is changed
		// within the delay period. Timeout gets cleared and restarted.
		return () => {
			clearTimeout(handler);
		};
	}, [searchFilter, delay]);

	return debouncedValue;
};
