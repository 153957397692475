/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { TranslationEn } from '@assets/i18n/en';
import { Icons, ModalWindow } from '@bondsports/utils';
import { ModalBody } from '@app/react/components/shared/ModalBody';
import { colors } from '@app/react/styles/theme';

interface Props {
	toggle: () => void;
	isShowing: boolean;
	message?: string;
	title?: string;
}
export const FailureModal = ({ toggle, isShowing, title, message }: Props) => {
	const labels = TranslationEn.singleReservationDisplay.resources.actions.deleteEvent.failureModal;

	return (
		<ModalWindow isShowing={isShowing} toggle={toggle} padding={0}>
			<ModalBody
				icon={Icons.conflict}
				iconColor={colors.dangerRed}
				title={title ?? (message ? labels.failToRefund : labels.title)}
				description={message ?? labels.description}
				mainAction={toggle}
				mainButton={labels.buttonText}
				customWidth={520}
			/>
		</ModalWindow>
	);
};
