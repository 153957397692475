import React from 'react';

export const Frisbee = (props: any) => (
  <svg
    data-aid="svgFrisbee"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.3969 8.5798C5.2069 10.4688 1.49661 13.3644 2.10951 15.0484C2.72219 16.7317 7.42593 16.5655 12.616 14.6765C17.806 12.7875 21.516 9.89129 20.9034 8.20796C20.2905 6.52403 15.587 6.69078 10.3969 8.5798ZM21.216 9.28656C21.3053 9.39095 21.3753 9.50459 21.4207 9.62923C22.0334 11.3127 18.3231 14.2083 13.1331 16.0973C7.94306 17.9864 3.23955 18.1531 2.62681 16.4696C2.5815 16.3451 2.56263 16.2127 2.56338 16.0757C3.67307 17.382 8.05944 17.1119 12.8657 15.3626C17.6719 13.6133 21.2063 11.0004 21.216 9.28656Z"
      fill="currentColor"
    />
  </svg>
);
