/** @jsxRuntime classic*/
/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { Typography } from "../../atoms";
import { capitalize } from "../../functions";
import { useColors } from "../../hooks/useColors";
import { ETypography, ETypographyColor } from "../../types";
import { Checkbox } from "../Checkbox";
import { BnIcon } from "../icons_v2";
import {
  checkedCss,
  customerOptionsContainerCss,
  StyledCustomerSelectOption,
} from "./style";
import { TCustomerSelectOptions } from "./types";

export const CustomerOptions = ({
  options,
  onSelect,
  value = [],
  autoFocus = true,
  isMultiple = false,
}: TCustomerSelectOptions) => {
  const { colors } = useColors();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {options.length === 0 && <div>No Results</div>}
      {options.map((opt, index) => {
        const val = String(opt.value);

        return (
          <StyledCustomerSelectOption
            theme={{ ...colors }}
            key={index}
            onClick={() => onSelect(opt.value)}
            tabIndex={0}
            autoFocus={autoFocus ? index === 0 : false}
          >
            <div css={customerOptionsContainerCss(colors)} id="customerSelect">
              <div className="thumbil">
                {opt.icon && <BnIcon icon={opt.icon} />}
              </div>
              <div className="body">
                <Typography
                  color={ETypographyColor.primary}
                  type={ETypography.body2}
                >
                  {capitalize(opt.label)}
                </Typography>
                <Typography
                  color={ETypographyColor.secondary}
                  type={ETypography.captionAccented}
                >
                  {capitalize(opt.description)}
                </Typography>
              </div>
            </div>

            {!isMultiple && String(value) === String(val) && (
              <span css={checkedCss}>
                <BnIcon icon="check" />
              </span>
            )}
          </StyledCustomerSelectOption>
        );
      })}
    </div>
  );
};
