import styled from '@emotion/styled';
import { IColors } from '../../types/theme';
import { css } from '@emotion/react';

export const StyledTooltip = styled.div<any>(
	({ theme: colors, isTopLayer }: { theme: IColors; isTopLayer: boolean }) => `
      box-shadow: 0px 2px 22px 8px rgba(21, 93, 148, 0.0749563);
      border-radius: 2px;
      padding: 8px 12px;
      font-family: Montserrat;
      background:${colors.bg_white};
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: ${colors.tx_text_secondary};
      max-width:320px;
      word-break:break-word;
      opacity:1 !important;
      z-index: ${isTopLayer ? 1000003 : 'unset'};
      white-space: pre-line;
    `
);

export const bodyCss = css`
	padding: 16px 16px;
`;

export const closeButton = css`
	position: absolute;
	top: 0px;
	right: 0px;
`;
