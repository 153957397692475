import * as  React from 'react';

export const BnWhiteCheck = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.75799 3.23151C9.43531 2.92283 8.91217 2.92283 8.58949 3.23151L4.62289 7.09149L3.41047 5.93197C3.08783 5.62325 2.56465 5.62325 2.24201 5.93197C1.91933 6.24069 1.91933 6.74121 2.24201 7.04993L4.0387 8.76845C4.20001 8.92283 4.41151 9 4.62297 9C4.83442 9 5.04588 8.92283 5.20724 8.76845L9.75799 4.34947C10.0807 4.04075 10.0807 3.54023 9.75799 3.23151Z"
      fill="currentColor"
    />
  </svg>
);
