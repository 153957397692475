/** @jsx jsx */
import { FlowLayout } from '@app/react/components/shared/ModalBody/FlowLayout';
import {
	BnIcon,
	Button,
	Center,
	Column,
	ETypography,
	ETypographyColor,
	Icons,
	ModalWindow,
	Typography,
} from '@bondsports/utils';
import { css, jsx } from '@emotion/react';
import React, { FC } from 'react';
import { TranslationEn } from '../../../../../../assets/i18n/en';
import { colors_v2 } from '../../../../styles/theme';

const iconCss = css`
	color: ${colors_v2.tx_text_primary};
	height: 28px;
	svg {
		width: auto;
		height: 28px;
	}
`;

const containerCss = css`
	margin-top: 2rem;
	text-align: center;
`;

const contextTextCss = css`
	line-height: 1.3;
`;
interface Props {
	isOpen: boolean;
	isLoading?: boolean;
	onClose: (isConfirm: boolean) => void;
}

export const DeleteDiscount: FC<Props> = ({ isOpen, onClose, isLoading }) => {
	const labels = TranslationEn.customers.paymentsInvoices.discounts;

	const handleApprove = () => {
		onClose(true);
	};

	return (
		<ModalWindow
			isShowing={isOpen}
			toggle={() => onClose(false)}
			maxHeight="280px"
			minHeight="280px"
			minWidth="520px"
			maxWidth="520px"
		>
			<Center styles={containerCss}>
				<Column fullWidth gap="32px" justify="center">
					<Column align="center" justify="center">
						<div css={iconCss}>
							<BnIcon icon={Icons.delete_outline} />
						</div>
						<Typography color={ETypographyColor.primary} type={ETypography.h4}>
							{labels.title}
						</Typography>
						<div css={contextTextCss}>
							<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
								{labels.content}
							</Typography>
						</div>
					</Column>
					<Column justify="center">
						<Button data-aid="button-deleteDiscountModal-main" theme="primary" sizer="S" onClick={handleApprove} isProcessing={isLoading}>
							{labels.mainButton}
						</Button>
						<Button data-aid="button-deleteDiscountModal-main" theme="basic" sizer="S" disabled={isLoading} onClick={() => onClose(false)}>
							{labels.subButton}
						</Button>
					</Column>
				</Column>
			</Center>
		</ModalWindow>
	);
};
