import React from "react";

export const FacebookFill = (props: any) => (
  <svg
    data-aid="SvgFacebookFill"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.96189 21.6V12.8425H7.01396V9.42794H9.96189V6.91126C9.96189 3.99098 11.7455 2.3998 14.3517 2.3998C15.6001 2.3998 16.6716 2.49269 16.9856 2.5336V5.58657L15.1777 5.58768C13.7612 5.58768 13.487 6.25997 13.487 7.24962V9.42794H16.8662L16.4261 12.8425H13.487V21.6H9.96189Z"
      fill="currentColor"
    />
  </svg>
);
