import { useRecoilState } from 'recoil';
import { baseStore, IErrorModal } from '../stores/baseStore';

export const useErrorModal = () => {
	const [ErrorModalStore, setErrorModalStore] = useRecoilState(baseStore.ErrorModalAtom);

	const isShowing = ErrorModalStore.isShowing;
	const message = ErrorModalStore.message;
	const title = ErrorModalStore.title;
	const isDismissable = ErrorModalStore.isDismissable;
	const goBack = ErrorModalStore.goBack;
	const approve = ErrorModalStore.approve;
	const isNewUi = ErrorModalStore.isNewUi;
	const tryAgainText = ErrorModalStore.tryAgainText;

	const toggle = () => {
		setErrorModalStore({ ...ErrorModalStore, isShowing: !isShowing });
	};

	const setErrorModal = (props: Omit<IErrorModal, 'isShowing'>) => {
		setErrorModalStore({ isShowing: true, ...props });
	};

	const checkForErrorResponse = (response: any, title?: string) => {
		const error = response?.err;
		if (error) {
			setErrorModal({ message: error, title });
			return true;
		}
		return false;
	};

	return {
		setErrorModal,
		isShowing,
		toggle,
		message,
		title,
		isDismissable,
		goBack,
		approve,
		isNewUi,
		tryAgainText,
		checkForErrorResponse,
	};
};
