import { CurrencySymbolEnum } from './constants';
import { round } from 'lodash';

/**
 * Remove commas in a price string and format it
 * with commas to separate every three digits in the integer part.
 *
 * @param price - The price string to be formatted
 * @returns The price string formatted with commas
 * separating every three digits in the integer part
 */
export const formatPriceWithCommas = (price: string) => {
	const priceWithoutCommas = price.replace(/,/g, ''); //for formInput price parsing
	return priceWithoutCommas.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Format a price with currency symbol and commas.
 *
 * @param price - The price to be formatted
 * @param currencySymbol - The currency symbol used to prefix the price
 * @param disableCurrencySymbol - If true, the output won't contain the currency symbol
 * @param fullPrice - If true, the output will always contain two decimal places
 * @returns The formatted price string
 */
export const Pricify = (
	price?: number,
	currencySymbol = CurrencySymbolEnum.USD,
	disableCurrencySymbol = false,
	fullPrice = false
) => {
	const currencySymbolDisplay = disableCurrencySymbol ? '' : currencySymbol;
	const errorString = `${currencySymbolDisplay}0${fullPrice ? '.00' : ''}`;

	if (price == null) return errorString;

	const pr = Number(price);
	const newPrice = roundPriceCents(Math.abs(pr));

	if (newPrice) {
		const decimalPrice = fullPrice ? newPrice.toFixed(2) : newPrice.toFixed(2).replace('.00', '');
		return pr > 0
			? `${currencySymbolDisplay}${formatPriceWithCommas(decimalPrice)}`
			: `-${currencySymbolDisplay}${formatPriceWithCommas(decimalPrice)}`;
	} else {
		return errorString;
	}
};

/**
 * Round the price to the nearest hundredths place (cents).
 *
 * @param price - The price to be rounded
 * @returns The price rounded to the nearest hundredths place
 */
export function roundPriceCents(price: number): number {
	let roundedPrice = round(price, 2);
	let roundedPriceWithTwoDecimals = Number.parseFloat(roundedPrice.toFixed(2));
	return roundedPriceWithTwoDecimals;
}

/**
 * Format a transaction fee with a percentage and/or a fixed value.
 *
 * @param percentVal - The percentage value of the transaction fee
 * @param fixedVal - The fixed value of the transaction fee
 * @returns The formatted transaction fee
 */
export const formatTransactionFee = (percentVal?: string, fixedVal?: string): string => {
	const percent = Number(percentVal) > 0 ? `${roundPriceCents(Number(percentVal) * 100)}%` : '';
	const addition = Number(percentVal) > 0 && Number(fixedVal) > 0 ? ` + ` : '';
	const fixed = Number(fixedVal) > 0 ? `${Pricify(Number(fixedVal))} fixed` : ``;
	return `${percent}${addition}${fixed}`;
};
