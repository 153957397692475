/** @jsx jsx */

import { useState, useEffect } from 'react';
import { jsx } from '@emotion/react';
import { Input } from '@bondsports/utils';
import { Icons } from 'app/react/styles/theme';
import { Amount } from './CashOrDocument/Amount';
import { isDocumentType, Document } from './CashOrDocument/Document';

export const CashOrDocument = ({ selectedCard, setSelectedCard, totalAmount, type, handleDocumentNumber }) => {
	const [optionalAmounts, setOptionalAmounts] = useState<string[]>([]);
	const [amount, updateAmount] = useState<string | number>(totalAmount);
	const [showChange, setShowChange] = useState<boolean>(false);

	const RoundTo = (totalAmount, array, roundTarget) => {
		if (totalAmount % roundTarget !== 0) {
			const newValue = Math.ceil(totalAmount / roundTarget) * roundTarget;
			if (!array.includes(newValue)) {
				array.push(newValue);
			}
		}
		return array;
	};

	useEffect(() => {
		const roundArray = [1, 5, 10, 50, 100];
		let newOptions = [];

		// exact price
		newOptions.push(totalAmount);

		// rounded prices
		if (type === 'cash') {
			roundArray.forEach(round => {
				newOptions = [...RoundTo(totalAmount, newOptions, round)];
			});
		}

		setOptionalAmounts(newOptions);
	}, [totalAmount]);

	const handleAmountChoose = val => {
		updateAmount(val);
		setSelectedCard(`remove-${val}`);
		if (Number(val) <= totalAmount) {
			setShowChange(false);
		} else {
			setShowChange(true);
		}
	};

	const handleChangeCustomInput = e => {
		setShowChange(true);
		updateAmount(e.target.value);
		if (Number(e.target.value) > totalAmount) {
			setShowChange(true);
		} else {
			setShowChange(false);
		}
	};

	const handleClear = () => {
		updateAmount(0);
	};

	return isDocumentType(type) ? (
		<Document type={type} handleDocumentNumberChange={handleDocumentNumber} />
	) : (
		<Amount
			type={type}
			optionalAmounts={optionalAmounts}
			handleAmountChoose={handleAmountChoose}
			showChange={showChange}
			change={Number(amount) - totalAmount}
			selectedCard={selectedCard}
		>
			{selectedCard === 'remove-custom' && (
				<div>
					<Input
					    data-aid="CashOrDocument-input-number"
						type="number"
						decimalScale={2}
						step={0.01}
						value={amount || ''}
						leftIcon={Icons.dollar}
						onChange={handleChangeCustomInput}
						clear={handleClear}
						placeholder={String(totalAmount)}
						sizer="S"
						label="Custom"
					/>
				</div>
			)}
		</Amount>
	);
};
