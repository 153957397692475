import React from "react";

export const Shelter = (props: any) => (
  <svg
    data-aid="SvgShelter"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 1.71519C0 1.04406 0.54406 0.5 1.21519 0.5H22.7848C23.4559 0.5 24 1.04406 24 1.71519V23.2848C24 23.9559 23.4559 24.5 22.7848 24.5C22.1137 24.5 21.5696 23.9559 21.5696 23.2848V2.93038H2.43038V23.2848C2.43038 23.9559 1.88632 24.5 1.21519 24.5C0.54406 24.5 0 23.9559 0 23.2848V1.71519Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.257 14.2804H17.0397V17.8312C17.0397 19.8446 15.4076 21.4768 13.3942 21.4768H10.6059C8.5925 21.4768 6.96032 19.8446 6.96032 17.8312V14.2804H7.74314V12.2459C7.74314 9.89486 9.64902 7.98897 12.0001 7.98897C14.3511 7.98897 16.257 9.89486 16.257 12.2459V14.2804ZM12.0001 10.4194C10.9913 10.4194 10.1735 11.2371 10.1735 12.2459V14.2804H13.8266V12.2459C13.8266 11.2371 13.0088 10.4194 12.0001 10.4194ZM13.0729 17.3021C13.0729 17.7896 12.7478 18.2011 12.3026 18.3317V19.4095H11.6975V18.3317C11.2523 18.2011 10.9271 17.7896 10.9271 17.3021C10.9271 16.7095 11.4075 16.2292 12 16.2292C12.5926 16.2292 13.0729 16.7095 13.0729 17.3021Z"
      fill="currentColor"
    />
  </svg>
);
