/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { FC } from 'react';
import { ErrorRowProps } from '../types';
import { BnIcon } from '../../../components/icons_v2';
import { ETypography, ETypographyColor, Icons } from '../../../types';
import { Typography } from '../../../atoms';
import { Pricify, roundPriceCents } from '../../../lib/price';
import { Row } from '../../../atoms/layoutPrimitives';

export const ErrorRow: FC<ErrorRowProps> = ({ labels, percentage, amount, styles }) => (
	<Row justify="flex-start" fullWidth styles={styles}>
		<BnIcon icon={Icons.conflict} />
		<Typography type={ETypography.captionAccented} color={ETypographyColor.red}>
			{labels.discountError(Pricify(amount, undefined, undefined, true), String(roundPriceCents(percentage)))}
		</Typography>
	</Row>
);
