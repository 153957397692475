import React from 'react';
import { IColors, ITypography } from '../../types/theme';
import { css, jsx } from '@emotion/react';

export const SegmentCardContainer = (colors: IColors, typography: ITypography, disabled: boolean) => css`
	display: flex;
	flex-direction: column;
	max-width: 400px;
	height: 100%;
	border: 1px solid ${colors.bg_border_primary};
	background: ${colors.bg_background_secondary};
	box-sizing: border-box;
	border-radius: 5px;
	position: relative;

	.disabled {
		pointer-events: none;
		position: absolute;
		height: 100%;
		width: 100%;
		background: ${colors.bg_background_fields};
		display: ${disabled ? 'block' : 'none'};
	}

	.title {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 12px 0;
		padding: 2.5px 16px;
	}

	.divider {
		width: 100%;
		height: 1px;
		background: ${colors.bg_border_seperator};
	}

	.body {
		padding: 12px 16px;

		.line {
			margin-bottom: 16px;
			padding: 2.5px;
			display: flex;
			align-items: flex-end;
			.line > svg {
				height: 20px;
				width: 20px;
				margin-right: 10px;
				color: ${colors.tx_text_teriaty};
			}
		}

		& div:last-child {
			margin-bottom: 0;
		}

		.body-2 {
			margin-left: 8px;
		}
		.caption-accented {
			margin: 0 2px;
			margin-bottom: 1px;
		}
	}

	.footer {
		display: flex;
		justify-content: flex-end;
		padding: 12px 16px;
		button {
			margin-left: 8px;
		}
		.icon {
			height: 16px;
			width: 16px;
			margin-right: 4px;
		}
	}
`;
