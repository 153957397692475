import { css } from '@emotion/react';
import { ThemeColors as colors_v2 } from '../../../../../styles/theme';

export const modalLayoutContainerCss = css`
	width: 100%;
	height: fit-content;
	transition: all 0.3s ease-in-out;

	.modal-layout-separator {
		width: 100%;
		height: 1px;
		background-color: ${colors_v2.bg_border_primary};
	}
`;

export const headerRowCss = (iswithTabs: boolean, headerSeparator: boolean) => css`
	justify-content: center;
	padding: ${`2.5rem 4rem ${iswithTabs ? 0 : '1.5rem'} 4rem`};
	flex-grow: 0;
	border-bottom: ${headerSeparator ? `1px solid ${colors_v2.bg_border_primary}` : 'none'};
`;

export const contentRowCss = (minHeight?: string) => css`
	padding: 2rem 4rem 2.5rem;
	flex-wrap: wrap;
	overflow-y: auto;
	flex-grow: 1;
	flex-shrink: 1;
	justify-content: center;
	min-height: ${minHeight || 'auto'};
`;

export const footerRowCss = css`
	padding: 2rem 4rem;
	flex-grow: 0;
`;

export const layoutSizeCss = css`
	max-height: 90vh;
	flex-shrink: 1;
	flex-grow: 1;
`;
