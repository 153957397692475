/* @jsx jsx */

import React, { Fragment, useContext } from 'react';
import { css, jsx } from '@emotion/react';
import { BnIcon, Button, ETypography, ETypographyColor, Icons, Typography } from '@bondsports/utils';
import { colors_v2 } from '../../../../styles/theme';
import { flexCol, fullWidthCss, gapCss, paddingBottomCss } from '../../../../styles/utils';
import { ActivitiesEnum } from '../types/enums';
import { separatorCss } from '../styles';
import { ActivityViewLayout } from '../layouts/ActivityViewLayout';
import { CustomerActivitiesContext } from '../customerActivitiesContext';

export type Activity = {
	name: string;
	icon: Icons;
	id: ActivitiesEnum;
};

export type ActivitiesTabCategoriesProps = {
	onClick: (activity: ActivitiesEnum) => void;
	selectedActivity: ActivitiesEnum;
	activities: Activity[];
	mainActivity: Activity;
};

const headerCss = css`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	padding-top: 2px;
`;

const mainActivityButtonCss = css`
	width: 100%;
	padding-bottom: 20px;
	${separatorCss()}
`;

const secondaryActivityButtonsCss = css`
	${flexCol};
	${gapCss(12)};
	${fullWidthCss};
	${paddingBottomCss(20)};
`;

export const ActivityCategoriesView: React.FC<ActivitiesTabCategoriesProps> = ({
	onClick,
	selectedActivity,
	activities,
	mainActivity,
}) => {
	const { labels } = useContext(CustomerActivitiesContext);

	return (
		<ActivityViewLayout
			Header={
				<section datatype="title" data-testid="activities-selector-title" css={headerCss}>
					<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
						{labels.activitiesBar.title}
					</Typography>
				</section>
			}
			MainContent={
				<Fragment>
					<section css={mainActivityButtonCss}>
						<ActivityButton
							testId={`${mainActivity.id}-button`}
							onClick={onClick}
							name={mainActivity.name}
							icon={mainActivity.icon}
							id={mainActivity.id}
							selected={selectedActivity === mainActivity.id}
						/>
					</section>
					<section css={secondaryActivityButtonsCss}>
						{activities.map(activity => (
							<ActivityButton
								testId={`${activity.id}-button`}
								onClick={onClick}
								name={activity.name}
								icon={activity.icon}
								id={activity.id}
								key={activity.id}
								selected={selectedActivity === activity.id}
							/>
						))}
					</section>
				</Fragment>
			}
		/>
	);
};

type ActivityButtonProps = {
	onClick: (activity: ActivitiesEnum) => void;
	disabled?: boolean;
	selected?: boolean;
	testId: string;
} & Activity;

const activityButtonCss = css`
	display: flex;
	flex-direction: row;
	padding-left: 6px;
	gap: 8px;
	align-items: center;
	justify-content: start;
`;

const ActivityButton: React.FC<ActivityButtonProps> = ({
	name: content,
	icon,
	onClick,
	id,
	disabled = false,
	selected = false,
	testId,
}) => {
	return (
		<Button
			data-aid="button-ActivityCategoriesView-click"
			datatype="button"
			data-testid={testId}
			disabled={disabled}
			sizer="S"
			theme="secondary"
			onClick={() => onClick(id)}
			style={{
				width: '100%',
				display: 'block',
				justifyContent: 'start',
				border: selected ? `1px solid ${colors_v2.bg_text_primary}` : undefined,
				textTransform: 'none',
			}}
		>
			<div data-aid="ActivityCategoriesView-buttons" css={activityButtonCss}>
				<BnIcon
					color={!disabled ? colors_v2.bg_text_primary : colors_v2.tx_text_disabled}
					width={24}
					height={24}
					icon={icon}
				/>
				<Typography type={ETypography.body2} color={!disabled ? ETypographyColor.primary : ETypographyColor.disabled}>
					{content}
				</Typography>
			</div>
		</Button>
	);
};
