import React, { createContext } from 'react';
import { NotesPopupProps } from '../templates/notes/NotesPopup';
import { NoteFormProps } from '../organisms/notes/NoteForm';
import { NoteProps } from '../molecules/notes/NotesList/Note';
import { ArchiveNoteProps } from '../molecules/notes/ArchiveNote';
import { AddNoteDto, EditNoteDto } from '../types/entities/notes';

export type NotesPopupContext = Partial<
	Pick<
		NotesPopupProps,
		'timezone' | 'entityId' | 'entityType' | 'creatorId' | 'creatorName' | 'schema' | 'actions' | 'labels'
	>
> & {
	FormComponent?: React.FC<NoteFormProps>;
	NoteComponent?: React.FC<NoteProps>;
	ArchiveComponent?: React.FC<ArchiveNoteProps>;
	isFormValid: (
		values: Partial<EditNoteDto> | AddNoteDto,
		initialValues: Partial<EditNoteDto | AddNoteDto>,
		errors: any
	) => boolean;
	notesToDisplay: number;
	maxAlerts: number;
};

export const NotesPopupContext = createContext<NotesPopupContext>({} as NotesPopupContext);
