/** @jsxRuntime classic */
/** @jsx jsx */
import dayjs from 'dayjs';
import React, { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { SectionContainer } from '../sectionContainer';
import { Table } from '../../table';
import { AlignType, IColumn } from '../../table/types';
import { useDetectPrint } from '../../../hooks/useIsPrint';
import { EInvoiceVariant, IInvoiceTemplateLabels } from '../../../organisms/invoiceTemplate/types';
import { EInvoiceAction, EInvoicingTabs } from '../types';
import { SkeletonWrapper } from '../../../components/skeletonWrapper';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor } from '../../../types';
import { EDateFormats } from '../../../lib/timeUtils';
import { oldThemeColors as colors, ThemeColors as colors_v2 } from '../../../styles/theme';

const HIDE_SCHEDULED_PAYMENTS_VARIANTS = [EInvoiceVariant.PAYMENT_PRINT, EInvoiceVariant.PAYMENT];

export const ScheduledPayments = ({
	labels,
	additional,
	isLoading,
	activeTab,
	variant,
	state,
}: {
	labels: IInvoiceTemplateLabels;
	additional: any;
	isLoading: boolean;
	activeTab: string;
	additionalInfoRow?: string;
	state: any;
	variant: EInvoiceVariant;
	onAction: (action: EInvoiceAction, value: any) => void;
}) => {
	const isPrint = useDetectPrint();
	const rowsToDisplay = additional.scheduledPayments;
	const tableLabels = labels.scheduledSection.table.columns;

	const columns = [
		{
			id: 'plannedDate',
			label: tableLabels.date,
			type: 'custom',
			styling: { align: AlignType.LEFT },
			component: (state: string) => {
				return (
					<SkeletonWrapper isLoading={isLoading} minHeight={6} minWidth={50}>
						<Typography type={ETypography.body2} color={ETypographyColor.primary}>
							{dayjs.utc(state, EDateFormats.YYYY_MM_DD).format(EDateFormats.MMM_D_YYYY)}
						</Typography>
					</SkeletonWrapper>
				);
			},
		},
		{
			id: 'price',
			label: tableLabels.amount,
			type: 'currency',
			styling: { align: AlignType.LEFT },
		},
		{
			id: 'fee',
			type: isPrint ? 'feeNoIcon' : 'fee',
			label: tableLabels.fees,
			styling: { align: AlignType.LEFT },
		},
		{
			id: 'totalPrice',
			label: tableLabels.total,
			type: 'currency',
			styling: { align: AlignType.LEFT },
		},
		{
			id: 'method',
			label: tableLabels.method,
			type: 'paymentMethodWithIcon',
			styling: { align: AlignType.LEFT },
		},
		{
			id: 'status',
			label: tableLabels.status,
			type: isPrint ? 'status' : 'statusWithIcon',
			styling: { align: AlignType.LEFT },
		},
	];

	return (
		<Fragment>
			{activeTab === EInvoicingTabs.SCHEDULE && !HIDE_SCHEDULED_PAYMENTS_VARIANTS.includes(variant) && !isPrint ? (
				<Fragment />
			) : (
				<Fragment>
					{rowsToDisplay?.length > 0 && (
						<SectionContainer title={labels.scheduledSection.title}>
							<Table<any>
								columns={columns as IColumn[]}
								rows={rowsToDisplay}
								pagination={false}
								isLoading={isLoading}
								isHover={false}
							/>
						</SectionContainer>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};
