import React from 'react';

export const MaintenanceFilled = (props: any) => (
  <svg
    data-aid="svgMaintenaceFilled"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0408 11.5871C13.2712 11.4693 13.5279 11.407 13.7942 11.407C14.2355 11.407 14.6504 11.5783 14.9632 11.8895L15.6365 11.2162C17.3027 11.8003 19.2297 11.4264 20.5617 10.0945C21.6825 8.97373 22.1245 7.43173 21.8885 5.97824C21.8625 5.81817 21.7492 5.68615 21.5949 5.63634C21.4407 5.58646 21.2713 5.62699 21.1567 5.74169L20.157 6.74139C19.3429 7.5555 18.0228 7.5555 17.2087 6.74139C16.3945 5.92727 16.3945 4.60723 17.2087 3.79311L18.2084 2.79341C18.3231 2.67872 18.364 2.50937 18.314 2.35504C18.2642 2.20063 18.1319 2.08758 17.9718 2.06158C16.5184 1.82564 14.9763 2.26763 13.8556 3.38838C12.5039 4.74014 12.1401 6.70461 12.7618 8.3875L11.3015 9.84775L13.0408 11.5871ZM10.3796 14.2333L8.64759 12.5012L2.59118 18.5576C1.80367 19.3451 1.80367 20.6219 2.59118 21.4094C3.37869 22.1969 4.65547 22.1969 5.44298 21.4094L10.664 16.1885C10.1374 15.6591 10.0425 14.8598 10.3796 14.2333ZM13.7926 12.4304C13.9549 12.4304 14.1173 12.4924 14.2412 12.6162L20.1825 18.5575C20.97 19.345 20.97 20.6218 20.1825 21.4093C19.395 22.1968 18.1183 22.1968 17.3307 21.4093L11.3894 15.4679C11.1416 15.2202 11.1416 14.8186 11.3894 14.5709L11.7764 14.1839L5.33603 7.74353L5.035 8.04462C4.76052 8.31918 4.3059 8.28479 4.0757 7.97223L2.14056 5.34457C1.95464 5.09212 1.98104 4.74162 2.20279 4.51988L3.31848 3.40412C3.54023 3.18237 3.89066 3.15597 4.14317 3.34189L6.77084 5.27709C7.08346 5.5073 7.11751 5.96219 6.84323 6.2364L6.5422 6.53743L12.9825 12.9778L13.3441 12.6162C13.4679 12.4924 13.6303 12.4304 13.7926 12.4304Z"
      fill="currentColor"
    />
  </svg>
);
