/** @jsxRuntime classic*/
/** @jsx jsx */
import React, { ReactNode } from 'react';

import { css, jsx } from '@emotion/react';
import { Icons } from '../../types/theme';
import { TagColorsMapper } from '../../components/Tag/style';
import { BnIcon } from '../../components/icons_v2';

const container = (size: ENotificationSize, fullWidth: boolean) => css`
	display: flex;
	padding: 8px 12px 8px 8px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	align-items: flex-start;
	${size === ENotificationSize.XS &&
	`
    font-size: 12px;
    line-height:15px;
    svg{
      height: 16px;
      width: 16px;
      min-width: 16px;

    }
    `}
	${size === ENotificationSize.S &&
	`
    font-size: 14px;
    line-height:17px;
    svg{
      height: 20px;
      width: 20px;
      min-width: 20px;
    }
    `}
  ${size === ENotificationSize.M &&
	`
    font-size: 16px;
    line-height:19px;
    svg{
      height: 24px;
      width: 24px;
      min-width: 24px;
    }
    `}

		${!fullWidth &&
	` 
	  text-transform: capitalize;
	  width: fit-content;
		`}

	svg {
		margin-right: 8px;
	}

	&.warning {
		background: ${TagColorsMapper.red.background};
		color: ${TagColorsMapper.red.primary};
	}
	&.action_confirmed {
		background: ${TagColorsMapper.darkGray.background};
		color: ${TagColorsMapper.darkGray.primary};
	}
	&.info {
		background: ${TagColorsMapper.blue.background};
		color: ${TagColorsMapper.blue.primary};
	}
	&.success {
		background: ${TagColorsMapper.green.background};
		color: ${TagColorsMapper.green.primary};
	}
`;

export enum ENotificationType {
	warning = 'warning',
	action_confirmed = 'action_confirmed',
	info = 'info',
	success = 'success',
}

export enum ENotificationSize {
	XS = 'XS',
	S = 'S',
	M = 'M',
}

export interface INotificationProps {
	type: ENotificationType;
	text: ReactNode | string;
	size?: ENotificationSize;
	fullWidth?: boolean;
}

export const NotificationMessage = ({
	type,
	text,
	size = ENotificationSize.XS,
	fullWidth = false,
}: INotificationProps) => {
	const iconMapper = {
		warning: Icons.conflict,
		action_confirmed: Icons.check,
		info: Icons.info,
		success: Icons.check,
	};

	return (
		<div css={container(size, fullWidth)} className={type} data-aid="index-Notification">
			<BnIcon icon={iconMapper[type]} />
			{text}
		</div>
	);
};
