import React from 'react';

export const Filters = (props: any) => (
  <svg
    data-aid="svgFilters"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.185 7H3C2.447 7 2 6.553 2 6C2 5.447 2.447 5 3 5H4.185C4.599 3.839 5.698 3 7 3C8.302 3 9.401 3.839 9.815 5H21C21.553 5 22 5.447 22 6C22 6.553 21.553 7 21 7H9.815C9.401 8.161 8.302 9 7 9C5.698 9 4.599 8.161 4.185 7ZM7 5C7.552 5 8 5.448 8 6C8 6.552 7.552 7 7 7C6.448 7 6 6.552 6 6C6 5.448 6.448 5 7 5ZM16.185 11C16.599 9.839 17.698 9 19 9C20.654 9 22 10.346 22 12C22 13.654 20.654 15 19 15C17.698 15 16.599 14.161 16.185 13H3C2.447 13 2 12.553 2 12C2 11.447 2.447 11 3 11H16.185ZM19 13C18.448 13 18 12.552 18 12C18 11.448 18.448 11 19 11C19.552 11 20 11.448 20 12C20 12.552 19.552 13 19 13ZM13.815 17H21C21.553 17 22 17.447 22 18C22 18.553 21.553 19 21 19H13.815C13.401 20.161 12.302 21 11 21C9.698 21 8.599 20.161 8.185 19H3C2.447 19 2 18.553 2 18C2 17.447 2.447 17 3 17H8.185C8.599 15.839 9.698 15 11 15C12.302 15 13.401 15.839 13.815 17ZM10 18C10 18.552 10.448 19 11 19C11.552 19 12 18.552 12 18C12 17.448 11.552 17 11 17C10.448 17 10 17.448 10 18Z"
      fill="currentColor"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="3"
      width="20"
      height="18"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.185 7H3C2.447 7 2 6.553 2 6C2 5.447 2.447 5 3 5H4.185C4.599 3.839 5.698 3 7 3C8.302 3 9.401 3.839 9.815 5H21C21.553 5 22 5.447 22 6C22 6.553 21.553 7 21 7H9.815C9.401 8.161 8.302 9 7 9C5.698 9 4.599 8.161 4.185 7ZM7 5C7.552 5 8 5.448 8 6C8 6.552 7.552 7 7 7C6.448 7 6 6.552 6 6C6 5.448 6.448 5 7 5ZM16.185 11C16.599 9.839 17.698 9 19 9C20.654 9 22 10.346 22 12C22 13.654 20.654 15 19 15C17.698 15 16.599 14.161 16.185 13H3C2.447 13 2 12.553 2 12C2 11.447 2.447 11 3 11H16.185ZM19 13C18.448 13 18 12.552 18 12C18 11.448 18.448 11 19 11C19.552 11 20 11.448 20 12C20 12.552 19.552 13 19 13ZM13.815 17H21C21.553 17 22 17.447 22 18C22 18.553 21.553 19 21 19H13.815C13.401 20.161 12.302 21 11 21C9.698 21 8.599 20.161 8.185 19H3C2.447 19 2 18.553 2 18C2 17.447 2.447 17 3 17H8.185C8.599 15.839 9.698 15 11 15C12.302 15 13.401 15.839 13.815 17ZM10 18C10 18.552 10.448 19 11 19C11.552 19 12 18.552 12 18C12 17.448 11.552 17 11 17C10.448 17 10 17.448 10 18Z"
        fill="currentColor"
      />
    </mask>
    <g mask="url(#mask0)"></g>
  </svg>
);
