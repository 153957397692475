import React from 'react';

export const Drink = (props: any) => (
  <svg
    data-aid="svgDrink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3554 3.18563C18.2485 3.0675 18.0977 3 17.938 3H5.56299C5.40436 3 5.25249 3.0675 5.14561 3.18563C5.03986 3.30263 4.98699 3.46012 5.00274 3.61875L6.69024 20.4937C6.71949 20.7817 6.96136 21 7.25049 21H16.2505C16.5396 21 16.7815 20.7817 16.8096 20.4937L18.4971 3.61875C18.514 3.46012 18.4622 3.30263 18.3554 3.18563ZM16.858 8.71275C15.4281 9.17513 13.5674 9.38888 12.1071 8.19075C10.2767 6.68887 8.03349 6.94762 6.51249 7.39988L6.18511 4.125H17.3159L16.858 8.71275Z"
      fill="currentColor"
    />
  </svg>
);
