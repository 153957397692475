import React from 'react';

export const Running = (props: any) => (
  <svg
    data-aid="svgRunning"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.3275 3.35515C10.6978 3.10653 10.8625 2.80386 10.7926 2.52771L10.7886 2.52378C10.7178 2.2555 10.4902 2.05012 10.2077 2.00491C10.1818 2.00197 10.1548 2 10.1278 2C10.025 2 9.92722 2.0226 9.84338 2.06191C8.71447 2.56407 6.92179 3.7502 5.53735 4.89801C5.73094 6.3677 6.78342 7.69106 11.9768 14.221L13.1782 15.7304C15.437 18.5743 19.1012 19.788 22.7215 20.6764C22.7375 20.6804 22.7533 20.6845 22.769 20.6887C22.8495 20.7098 22.9265 20.7301 23 20.7235C22.9311 20.4749 22.7784 20.2607 22.5708 20.1103C20.2142 18.3414 19.0942 16.9077 18.3037 14.673L16.9841 14.5158C16.7057 14.4814 16.4911 14.2475 16.4911 13.9645C16.4911 13.6569 16.7446 13.4083 17.056 13.4083C17.08 13.4083 17.1039 13.4092 17.1249 13.4122L17.9374 13.5085C17.8535 13.2084 17.7714 12.894 17.6908 12.5651L16.3853 12.4079C16.1068 12.3735 15.8922 12.1396 15.8922 11.8566C15.8922 11.549 16.1457 11.3003 16.4571 11.3003C16.4811 11.3003 16.505 11.3013 16.526 11.3043L17.4223 11.4114C17.2407 10.5731 17.053 9.6435 16.8454 8.5979C16.548 8.68143 16.1816 8.93988 16.004 9.6779C15.9561 9.85675 15.8193 9.99924 15.6586 10.0543C15.3582 10.1683 15.0318 10.2302 14.6914 10.2302C14.5726 10.2302 14.4548 10.2233 14.354 10.2095C12.2978 9.95011 10.2127 7.57785 9.7226 5.6085C9.38023 4.23763 9.95717 3.60378 10.3275 3.35515Z"
      fill="currentColor"
    />
    <path
      d="M4.01318 6.35929C4.12198 7.25847 4.28035 8.08787 5.78756 10.0543L5.88839 10.1398C6.33756 10.7245 6.88355 11.4281 7.55031 12.2555L8.92876 13.9822C9.43675 14.6235 9.99045 15.3184 10.6012 16.0849L10.609 16.0947C10.8755 16.4292 11.1529 16.7773 11.4421 17.1406C12.9164 18.994 14.9865 20.1585 17.2503 20.9859C18.0768 21.2886 18.9312 21.548 19.7916 21.7799C20.4094 21.946 20.6236 21.9698 21.1666 21.9952C21.4002 22.0061 21.7653 21.9952 21.7653 21.9952C21.7653 21.9952 22.3193 21.9234 22.5448 21.8016L22.444 21.7799C18.6251 20.8474 14.7533 19.5453 12.2789 16.434L11.0741 14.958L11.0294 14.9018C6.44726 9.13937 5.00085 7.32039 4.5402 5.80799C4.33858 5.99274 4.1619 6.1824 4.01318 6.35929Z"
      fill="currentColor"
    />
    <path
      d="M5.58333 20.1774C5.07707 20.1774 4.66666 20.5843 4.66666 21.0863C4.66666 21.5882 5.07707 21.9952 5.58333 21.9952H12C12.5062 21.9952 12.9166 21.5882 12.9166 21.0863C12.9166 20.5843 12.5062 20.1774 12 20.1774H5.58333Z"
      fill="currentColor"
    />
    <path
      d="M2.83333 15.6331C2.83333 15.1311 3.24374 14.7242 3.75 14.7242H7.41666C7.92292 14.7242 8.33332 15.1311 8.33332 15.6331C8.33332 16.135 7.92292 16.5419 7.41666 16.5419H3.75C3.24374 16.5419 2.83333 16.135 2.83333 15.6331Z"
      fill="currentColor"
    />
    <path
      d="M1.91667 9.27097C1.41041 9.27097 1 9.67788 1 10.1798C1 10.6818 1.4104 11.0887 1.91667 11.0887H3.75C4.25626 11.0887 4.66666 10.6818 4.66666 10.1798C4.66666 9.67788 4.25626 9.27097 3.75 9.27097H1.91667Z"
      fill="currentColor"
    />
  </svg>
);
