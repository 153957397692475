import { useEffect, useState } from 'react';

const DEFAULT_Z_INDEX_MODAL = 1000;

export const useModal = () => {
	const [isShowing, setIsShowing] = useState(false);
	const [zIndex, setZIndex] = useState<number>(DEFAULT_Z_INDEX_MODAL);

	useEffect(() => {
		const otherModalsCount = Array.from(document.querySelectorAll('.UtilsDialog-root, .MuiDialog-root')).length;
		if (otherModalsCount > 1) {
			setZIndex((prevState: number) => prevState + otherModalsCount);
		}
	}, []);

	const toggle = () => {
		setIsShowing(!isShowing);
		return !isShowing;
	};

	return {
		isShowing,
		toggle,
		zIndex,
	};
};
