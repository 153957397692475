import React from 'react';

export const Email = (props: any) => (
  <svg
    data-aid="svgEmail"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99998 4.08333C3.83686 4.08333 2.08331 5.83688 2.08331 8V16C2.08331 18.1631 3.83686 19.9167 5.99998 19.9167H18C20.1631 19.9167 21.9166 18.1631 21.9166 16V8C21.9166 5.83688 20.1631 4.08333 18 4.08333H5.99998ZM4.00853 7.38622C4.27052 6.5352 5.06302 5.91667 5.99998 5.91667H18C18.937 5.91667 19.7295 6.53522 19.9914 7.38627L12 11.382L4.00853 7.38622ZM3.91665 9.57634V16C3.91665 17.1506 4.84939 18.0833 5.99998 18.0833H18C19.1506 18.0833 20.0833 17.1506 20.0833 16V9.5764L12 13.618L3.91665 9.57634Z"
      fill="currentColor"
    />
  </svg>
);
