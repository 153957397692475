/* @jsx jsx */
/* @jsxRuntime classic */

import React from 'react';
import { css, jsx } from '@emotion/react';
import { useColors } from '../../hooks/useColors';

const dotCss = (color: string, size: number) => css`
	height: ${size}px;
	width: ${size}px;
	min-width: 2px;
	display: block;
	border-radius: 50%;
	background: ${color};
`;
interface Props {
	color?: string;
	size?: number;
}
export const Dot = ({ color, size = 4 }: Props) => {
	const { colors } = useColors();

	return <span css={dotCss(color || colors.bg_text_teriaty!, size)} />;
};
