import React from "react";

export const BattingCage = (props: any) => (
  <svg
    data-aid="SvgBattingCage"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.0821 1.5C1.487 1.5 1.00457 1.98543 1.00457 2.58423C1.00457 3.18303 1.487 3.66845 2.0821 3.66845H21.9225C22.5176 3.66845 23 3.18303 23 2.58423C23 1.98543 22.5176 1.5 21.9225 1.5H2.0821Z"
      fill="currentColor"
    />
    <path
      d="M16.6685 4.62606C16.1902 4.75503 15.7893 5.06894 15.5374 5.50785L13.0894 9.77245C12.6101 10.6079 11.992 12.1633 11.3366 13.8127L11.331 13.8268C11.0926 14.4278 10.9047 14.9426 10.7365 15.4036C10.4052 16.3112 10.1501 17.01 9.73611 17.7476L8.48792 19.923L9.87614 20.7294L11.1253 18.5525C11.5667 17.7653 12.0802 17.1419 12.7546 16.3231C13.0643 15.9471 13.408 15.5299 13.7942 15.0367C14.8463 13.6914 15.8391 12.4217 16.3045 11.6124L18.704 7.42861C19.1811 6.59723 19.0166 5.57048 18.3123 4.98721C17.8402 4.604 17.2398 4.47203 16.6685 4.62606Z"
      fill="currentColor"
    />
    <path
      d="M7.52803 20.351C7.37485 20.3923 7.23806 20.4901 7.15534 20.6343C6.99082 20.921 7.0941 21.3089 7.37907 21.4745L9.05862 22.4502C9.34359 22.6157 9.72905 22.5118 9.89358 22.2251C10.059 21.9367 9.95574 21.5489 9.66952 21.3836L7.99155 20.4088C7.84827 20.3256 7.68121 20.3097 7.52803 20.351Z"
      fill="currentColor"
    />
    <path
      d="M1.00457 7.54211C1.00457 6.94331 1.487 6.45788 2.0821 6.45788H11.4085C12.0036 6.45788 12.4861 6.94331 12.4861 7.54211C12.4861 8.14091 12.0036 8.62633 11.4085 8.62633H2.0821C1.487 8.62633 1.00457 8.14091 1.00457 7.54211Z"
      fill="currentColor"
    />
    <path
      d="M20.2279 7.54211C20.2279 6.94331 20.7103 6.45788 21.3054 6.45788H21.9225C22.5176 6.45788 23 6.94331 23 7.54211C23 8.14091 22.5176 8.62633 21.9225 8.62633H21.3054C20.7103 8.62633 20.2279 8.14091 20.2279 7.54211Z"
      fill="currentColor"
    />
    <path
      d="M2.0821 11.4158C1.487 11.4158 1.00457 11.9012 1.00457 12.5C1.00457 13.0988 1.487 13.5842 2.0821 13.5842H9.58758C10.1827 13.5842 10.6651 13.0988 10.6651 12.5C10.6651 11.9012 10.1827 11.4158 9.58758 11.4158H2.0821Z"
      fill="currentColor"
    />
    <path
      d="M18.1895 11.4158C17.5944 11.4158 17.1119 11.9012 17.1119 12.5C17.1119 13.0988 17.5944 13.5842 18.1895 13.5842H21.9225C22.5176 13.5842 23 13.0988 23 12.5C23 11.9012 22.5176 11.4158 21.9225 11.4158H18.1895Z"
      fill="currentColor"
    />
    <path
      d="M1 17.4579C1 16.8591 1.48242 16.3737 2.07753 16.3737H6.08279C6.67789 16.3737 7.16032 16.8591 7.16032 17.4579C7.16032 18.0567 6.67789 18.5421 6.08279 18.5421H2.07753C1.48242 18.5421 1 18.0567 1 17.4579Z"
      fill="currentColor"
    />
    <path
      d="M13.0246 17.4579C13.0246 16.8591 13.507 16.3737 14.1021 16.3737H21.9179C22.513 16.3737 22.9954 16.8591 22.9954 17.4579C22.9954 18.0567 22.513 18.5421 21.9179 18.5421H14.1021C13.507 18.5421 13.0246 18.0567 13.0246 17.4579Z"
      fill="currentColor"
    />
    <path
      d="M2.07753 21.3315C1.48242 21.3315 1 21.817 1 22.4158C1 23.0146 1.48242 23.5 2.07753 23.5H4.90039C5.49549 23.5 5.97792 23.0146 5.97792 22.4158C5.97792 21.817 5.49549 21.3315 4.90039 21.3315H2.07753Z"
      fill="currentColor"
    />
    <path
      d="M12.7725 21.3315C12.1774 21.3315 11.695 21.817 11.695 22.4158C11.695 23.0146 12.1774 23.5 12.7725 23.5H21.9179C22.513 23.5 22.9954 23.0146 22.9954 22.4158C22.9954 21.817 22.513 21.3315 21.9179 21.3315H12.7725Z"
      fill="currentColor"
    />
  </svg>
);
