/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { Checkbox } from '../../components/Checkbox';
import { Radio } from '../../components/Radio';
import { ETypography, ETypographyColor, IColors } from '../../types';
import { Typography } from '../../atoms';

const dividerCss = (colors: IColors) => css`
	width: 100%;
	height: 1px;
	background: ${colors.bg_border_seperator};
	margin-bottom: 20px;
`;

const filterItemCss = css`
	display: flex;
	flex-direction: column;
	margin-bottom: 12px;
`;

const filterLabelCss = (inputType: InputTypeEnum) => css`
	margin-bottom: ${inputType === InputTypeEnum.Radio ? '8px' : '16px'};
`;

export enum InputTypeEnum {
	Radio = 'radio',
	Checkbox = 'checkbox',
	Select = 'select',
}

export interface IFilterOption {
	id: string;
	value: string;
	label: string;
}

export interface IFilterType {
	id: string;
	inputType: InputTypeEnum;
	options: IFilterOption[];
	label: string;
}

export interface IFilterItemProps {
	filter: IFilterType;
	selections: { [key: string]: string[] };
	onSelectionChange: (filterLabel: string, newValue: string[]) => void;
}

export interface IFilterMenuProps {
	filters: IFilterType[];
	onFilterChange: (filters: { [key: string]: string[] }) => void;
	currentSelections?: { [key: string]: string[] };
}

const FilterItem = ({ filter, selections, onSelectionChange }: IFilterItemProps) => {
	const handleChange = (value: string, isChecked: boolean) => {
		let newSelections = selections[filter.label] || [];

		if (filter.inputType === InputTypeEnum.Checkbox) {
			newSelections = isChecked ? [...newSelections, value] : newSelections.filter(v => v !== value);
		} else if (filter.inputType === InputTypeEnum.Radio) {
			newSelections = [value];
		}

		onSelectionChange(filter.label, newSelections);
	};

	const currentSelections = selections[filter.label] || [];

	return (
		<div css={filterItemCss}>
			<Typography
				css={filterLabelCss(filter.inputType)}
				color={ETypographyColor.secondary}
				type={ETypography.overlineAccented}
			>
				{filter.label}
			</Typography>
			{filter.options.map((option, index) => {
				const isChecked = currentSelections.includes(option.value);
				if (filter.inputType === InputTypeEnum.Checkbox) {
					return (
						<div
							css={css`
								margin-left: 10px;
							`}
							key={option.id}
						>
							<Checkbox
								width={15}
								label={option.label}
								checked={isChecked}
								setChecked={e => handleChange(option.value, !selections[filter.label]?.includes(option.value))}
							/>
						</div>
					);
				}
				if (filter.inputType === InputTypeEnum.Radio) {
					const isDefault = currentSelections.length < 1 && index === 0;
					return (
						<div
							css={css`
								margin-bottom: 4px;
							`}
							key={option.id}
						>
							<Radio
								width={15}
								label={option.label}
								value={option.value}
								checked={isChecked || isDefault}
								setChecked={() => handleChange(option.value, true)}
							/>
						</div>
					);
				}
				return null;
			})}
		</div>
	);
};

export const FilterMenu = ({ filters, onFilterChange, currentSelections }: IFilterMenuProps) => {
	const [selections, setSelections] = useState<{ [key: string]: string[] }>({});

	const handleSelectionChange = (filterLabel: string, newValue: string[]) => {
		const updatedSelections = { ...selections, [filterLabel]: newValue };
		setSelections(updatedSelections);
		onFilterChange(updatedSelections);
	};

	useEffect(() => {
		if (currentSelections) {
			setSelections(currentSelections);
		}
	}, [currentSelections]);

	return (
		<div>
			{filters.map((filter, index) => (
				<div key={filter.id}>
					<FilterItem
						key={filter.inputType + filter.label + index}
						filter={filter}
						selections={selections}
						onSelectionChange={handleSelectionChange}
					/>
					{index !== filters.length - 1 && <div css={dividerCss} />}
				</div>
			))}
		</div>
	);
};
