/** @jsx jsx */

import React, { useEffect } from 'react';
import { EDateFormats, ETypography, ETypographyColor, SuspenseWrapper, Typography } from '@bondsports/utils';
import { jsx, css } from '@emotion/react';
import { ItemsPerPageCalculator, Table } from '../../shared/NewTable';
import { ColumnType } from '../../shared/NewTable/HeaderRow';
import { AlignType } from '../../shared/NewTable/types';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

const tableCss = css`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-height: 350px;
`;

const columns = [
	{
		id: 'originalPlannedDate' || 'plannedDate',
		label: 'Date',
		type: 'custom',
		styling: { align: AlignType.LEFT },
		component: (state: string) => {
			return (
				<Typography type={ETypography.body2} color={ETypographyColor.primary}>
					{dayjs.utc(state, EDateFormats.YYYY_MM_DD).format(EDateFormats.MMM_D_YYYY)}
				</Typography>
			);
		},
	},
	{
		id: 'price',
		label: 'Amount',
		type: 'currency',
		styling: { align: AlignType.LEFT },
	},
	{
		id: 'method',
		label: 'Method',
		type: 'paymentMethodWithIcon',
		styling: { align: AlignType.LEFT },
	},
	{
		id: 'status',
		label: 'Status',
		type: 'status',
		styling: { align: AlignType.LEFT },
	},
];

interface ISelectScheduleInstallmentsProps {
	subject: string;
	isLoading: boolean;
	scheduledPayments: any[];
	meta: any;
	selectedIds: string[];
	setSelectedIds: (selectedIds: string[]) => void;
	isAllSelected: boolean;
	setIsAllSelected: (isAllSelected: boolean) => void;
	handleSelectedInstallmentPrice: (id: string, add?: boolean) => void;
	page: number;
	tableRef?: React.RefObject<HTMLDivElement>;
	handleNext: () => void;
}
const SelectScheduleInstallments = ({
	subject,
	isLoading,
	scheduledPayments,
	handleSelectedInstallmentPrice,
	meta,
	tableRef,
	selectedIds,
	setSelectedIds,
	isAllSelected,
	setIsAllSelected,
	page,
	handleNext,
}: ISelectScheduleInstallmentsProps) => {
	const { maxHeight } = ItemsPerPageCalculator(tableRef, true);
	useEffect(() => {
		if (selectedIds.length === meta.totalItems) {
			setIsAllSelected(!isAllSelected);
			setSelectedIds([]);
		}
	}, [selectedIds]);
	const handleSelect = (id: string) => {
		const isSelected = selectedIds.some((selectedId: any) => selectedId === id);
		if (isSelected) {
			handleSelectedInstallmentPrice(id)
			setSelectedIds(selectedIds.filter((selectedId: any) => selectedId !== id));
		} else {
			handleSelectedInstallmentPrice(id, true);
			setSelectedIds([...selectedIds, id]);
		}
	};
	const handleOnSelectAll = () => {
		if (!!selectedIds.length) {
			setSelectedIds([]);
		}
		setIsAllSelected(!isAllSelected);
	};
	const isSelectIndeterminate = (): boolean => {
		if (
			!isAllSelected && meta.totalItems === selectedIds.length
		) {
			return true;
		}
		if (isAllSelected && isEmpty(selectedIds)) {
			return false;
		}
		return selectedIds.length > 0;
	};

	return (
		<div css={tableCss}>
			<SuspenseWrapper loading={isLoading} width={30}>
				<Table
					isSelectRow
					isVirtualized
					meta={meta}
					columns={columns as ColumnType[]}
					rows={scheduledPayments}
					maxHeight={maxHeight}
					customEmptySpace
					onSelect={handleSelect}
					onSelectAll={handleOnSelectAll}
					isAllSelected={isAllSelected}
					selectedArr={selectedIds}
					isLoading={isLoading}
					pagination
					ssr
					removeShadows
					footerOrder={['pagination', 'labels', 'footer']}
					subject={subject}
					isSelectIndeterminate={isSelectIndeterminate()}
					page={page - 1}
				/>
			</SuspenseWrapper>
		</div>
	);
};

export default SelectScheduleInstallments;
