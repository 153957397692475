/** @jsxRuntime classic */
/** @jsx jsx */

import React, { ReactNode } from 'react';
import { css, jsx } from '@emotion/react';
import { Modal as ModalWindow } from '../../components/Modal/modal';
import { Layout } from './Layout';
import { AttendanceHeader } from './Header';
import { AttendanceDetails } from './Details';
import { useWindowSize } from '../../hooks/useWindowSize';

export interface IConflictsMeta {
	hasConflicts: boolean;
	callback: () => void;
}

export interface AttendancePopupProps {
	isShowing: boolean;
	toggle: () => void;
	header: HeaderProps;
	details: DetailsProps;
	attendees: ReactNode;
	callback?: () => void;
	children?: ReactNode;
	footer?: ReactNode;
	conflicts?: IConflictsMeta;
}

export interface HeaderProps {
	sportIcon: string;
	title: string;
	subTitle: {
		firstText: string;
		secondText: string;
	};
	publicUrl: string;
	navigateToSeasonDashboard: () => void;
}

export interface DetailsProps {
	dateTimeString: string;
	duration: string;
	location: string;
	attendance: {
		total: number;
		checkedIn: number;
		missingPayments: number;
	};
	notes?: { public?: string; private?: string };
	conflicts?: IConflictsMeta;
}

export const AttendancePopup = ({
	isShowing,
	toggle,
	header,
	details,
	attendees,
	footer,
	conflicts,
}: AttendancePopupProps) => {
	const { width, height } = useWindowSize();

	return (
		<ModalWindow
			minHeight={`${(height ?? 600) * 0.9}px`}
			minWidth={`${width && width < 880 ? width * 0.9 : 880}px`}
			isDismissable
			isMaximizable
			toggle={toggle}
			isShowing={isShowing}
		>
			<Layout
				header={
					<AttendanceHeader
						sportIcon={header.sportIcon || ''}
						title={header.title || ''}
						subTitle={header.subTitle || { firstText: '', secondText: '' }}
						publicUrl={header.publicUrl || ''}
						navigateToSeasonDashboard={header.navigateToSeasonDashboard}
					/>
				}
				details={
					<AttendanceDetails
						dateTimeString={details.dateTimeString || ''}
						duration={details.duration || ''}
						location={details.location || ''}
						attendance={
							details.attendance || {
								total: 0,
								checkedIn: 0,
								missingPayments: 0,
							}
						}
						notes={details.notes}
						conflicts={conflicts}
					/>
				}
				dataList={attendees}
				footer={footer}
			/>
		</ModalWindow>
	);
};
