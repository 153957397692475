import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimeList, TimeService } from '@app/shared/services/utils/time.service';
import { TranslationEn } from '../../../../assets/i18n/en';

export interface DaysOfWeek {
	index: number;
	active: boolean;
	startTime: TimeBreakdown;
	finishTime: TimeBreakdown;
}

export interface TimeBreakdown {
	hour: number;
	minute: number;
	pm: boolean;
}

@Component({
	selector: 'rc-week-days-hours-select',
	templateUrl: './week-days-hours-select.component.html',
	styleUrls: ['./week-days-hours-select.component.scss'],
})
export class WeekDaysHoursSelectComponent implements OnInit {
	@Input() weekData: DaysOfWeek[][];
	@Input() labelString?: string = '';
	@Input() disabled?: boolean = false;
	@Output() onChange = new EventEmitter<DaysOfWeek[][]>();

	daysOfWeekNames: string[] = TranslationEn.daysOfWeek;

	startTimeValue: string;
	finishTimeValue: string;
	dangerousUpdateCss: boolean = false;

	constructor(timeService: TimeService) {
		// this.timeList = timeService.getTimeSelectList();
	}

	ngOnInit() {
		if (this.disabled) this.dangerousUpdateCss = true;
		if (this.weekData && !(this.weekData.length > 0)) {
			this.weekData.push(this.createNewTimeObj());
		}
		this.onChange.emit(this.weekData);
	}

	onChangeHandle = () => {
		this.onChange.emit(this.weekData);
	};

	createNewTimeObj = (): DaysOfWeek[] => {
		const weekObject: DaysOfWeek[] = [];
		for (let i = 0; i < 7; i++) {
			weekObject.push({
				index: i + 2, //  Hurray to stupidity
				active: false,
				startTime: { hour: 6, minute: 0, pm: true },
				finishTime: { hour: 9, minute: 0, pm: true },
			});
		}
		return weekObject;
	};

	addNewWeekObj = () => {
		this.weekData.push(this.createNewTimeObj());
	};

	formatMinutes = (minutes: number) => (minutes <= 9 ? `0${minutes}` : minutes);
}
