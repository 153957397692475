/** @jsxRuntime classic */
/** @jsx jsx */

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { css, jsx, useTheme } from '@emotion/react';
import { flexCss, fullWidthCss, gapCss, justifyContentCss } from '../../styles/utils';
import { Typography } from '../../atoms';
import { ETypography, ETypographyColor } from '../../types';
import { i18n } from '../../lib/i18n';
import cx from 'classnames';
import { BnArrowDown, BnArrowUp } from '../../icons';
import { Popup } from '../Popup';
import { StyledSelect, StyledValue } from '../Select_v2/style';
import { InputContainer } from '../Input/style';
import { SearchInput } from '../Select_v2/SearchInput';
import { LabelTag } from '../../molecules/LabelTag';
import { TFetchFunc, TGroup, TSelectedEvent } from './types';
import { usePaginatedSelect } from './usePaginatedSelect';
import { SelectOptions } from './SelectOptions';
import { SizeEnum } from '../../lib/constants';
import { find, isEqual } from 'lodash';
import { NoOptionsComponent } from './NoOptionsComponent';

type PaginatedSelectProps<T, U> = {
	disabled?: boolean;
	insideLeftLabel?: string;
	sizer?: SizeEnum;
	isDisplayCounter?: boolean;
	placeholder?: string;
	fetcher?: TFetchFunc<T, U>;
	initialGroups: TGroup<T, U>[];
	onSelected: (selected: TSelectedEvent<T, U>[]) => void;
	labels: {
		selectAll: string;
		loading: string;
		end: string;
		error: string;
		noOptionsMessage?: string;
	};
	isGroups?: boolean;
	usePagination?: boolean;
	onBlur?: () => void;
	maxHeight?: number;
	useSearch?: boolean;
	handleLoadMoreGroups?: () => void;
	totalItemsCount?: number;
	onAllGroupsSelectionChange: (selected: boolean) => void;
};

export const PaginatedSelect = <T, U>({
	disabled,
	insideLeftLabel,
	sizer,
	isDisplayCounter,
	placeholder,
	fetcher,
	initialGroups,
	onSelected,
	labels,
	isGroups,
	usePagination = true,
	onBlur,
	useSearch = true,
	maxHeight,
	handleLoadMoreGroups,
	totalItemsCount = 0,
	onAllGroupsSelectionChange,
}: PaginatedSelectProps<T, U>) => {
	const colors = useTheme();
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	const {
		selectedCount,
		selectedValues,
		searchValue,
		selectedGroup,
		isLoading,
		isFinalPage,
		groups,
		hasError,
		setSearchValue,
		onSelect,
		nextPage,
		onRemove,
		onSelectGroup,
		selectAll,
		removeAll,
		deslectAllGroups,
		selectAllGroups,
	} = usePaginatedSelect(
		initialGroups,
		usePagination,
		totalItemsCount,
		onAllGroupsSelectionChange,
		fetcher,
		onSelected
	);

	useEffect(() => {
		if (!isGroups && groups?.length) {
			onSelectGroup(groups[0]);
		}
	}, [isGroups, groups]);

	const handleSetOpen = (val: boolean) => {
		setIsOpen(val);

		if (!val && isOpen) {
			if (isGroups) {
				onSelectGroup();
			}

			onBlur?.();
		}
	};

	const handleOnSelect = (values: U[]) => {
		if (values.every(val => find(selectedGroup?.selected, selected => isEqual(val, selected)))) {
			onRemove(values);
			return;
		}

		onSelect(values);
	};

	const canShowSelectedCount: boolean =
		(selectedValues.length > 0 || (!!groups?.length && groups.some(group => group.allSelected))) &&
		Boolean(selectedCount);

	return (
		<Fragment>
			<InputContainer
				className={cx({
					M: sizer === SizeEnum.MEDIUM,
					S: sizer === SizeEnum.SMALL,
				})}
				theme={{ ...colors }}
				disabled={disabled}
			>
				<div data-aid="GroupPaginatedSelect-select-container" className="select-container">
					<Popup
						isTopLayer
						fullWidth
						disableAutoTrigger
						maxHeight={maxHeight}
						body={
							disabled || !groups ? undefined : (
								<SelectOptions
									handleRemoveAll={removeAll}
									handleSelectAll={selectAll}
									handleLoadNextPage={nextPage}
									groups={groups}
									onSelect={handleOnSelect}
									selectAllLabel={labels.selectAll}
									value={selectedValues}
									isMultiple
									enableSelectAll
									size={SizeEnum.SMALL}
									isLoading={isLoading}
									isFinalPage={isFinalPage}
									selectedGroup={selectedGroup}
									onSelectGroup={onSelectGroup}
									isGroups={isGroups}
									searchValue={searchValue}
									usePagination={usePagination}
									LoadingComponent={
										usePagination && (
											<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
												{labels.loading}
											</Typography>
										)
									}
									EndOptionComponent={
										usePagination &&
										hasError && (
											<Typography color={ETypographyColor.red} type={ETypography.caption}>
												{labels.error}
											</Typography>
										)
									}
									NoOptionsComponent={
										labels.noOptionsMessage ? (
											<NoOptionsComponent noOptionsMessage={labels.noOptionsMessage} />
										) : (
											<Fragment />
										)
									}
									handleLoadMoreGroups={handleLoadMoreGroups}
									deslectAllGroups={deslectAllGroups}
									selectAllGroups={selectAllGroups}
									labels={labels}
								/>
							)
						}
						onOpenChange={handleSetOpen}
						padding="0"
						borderRadius={0}
						trigger={isOpen}
					>
						<StyledSelect
							className={cx({
								placeholder: !String(searchValue),
								disabled: disabled,
							})}
							theme={{ ...colors }}
							tabIndex={0}
							ref={ref}
							onClick={() => {
								if (ref.current === document.activeElement) {
									handleSetOpen(!isOpen);
								}
							}}
						>
							{insideLeftLabel && (
								<Typography color="secondary" type="body2" className="insideLeftLabel">
									{insideLeftLabel}
								</Typography>
							)}
							<div css={[flexCss, fullWidthCss, justifyContentCss('start'), gapCss(8)]}>
								{canShowSelectedCount && (
									<Fragment>
										<StyledValue
											className={cx(isDisplayCounter ? 'tagValue' : 'value')}
											style={{ overflow: 'inherit', width: 'auto', padding: '9px 0px 9px 12px' }}
											theme={{ ...colors }}
										>
											<LabelTag
												state={{
													label: `${selectedCount} ${i18n.selcted}`,
													value: selectedValues.length,
												}}
												size="XS"
											/>
										</StyledValue>
									</Fragment>
								)}
								<SearchInput
									handleOpenPopup={handleSetOpen}
									onType={setSearchValue}
									placeholder={placeholder ?? ''}
									disabled={!useSearch || disabled}
								/>
							</div>
							<div data-aid="PaginatedGroupSelect-button" className="button">
								{isOpen ? <BnArrowUp /> : <BnArrowDown />}
							</div>
						</StyledSelect>
					</Popup>
				</div>
			</InputContainer>
		</Fragment>
	);
};
