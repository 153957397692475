import { useRef } from 'react';

export function useScroll() {
	const ref = useRef<HTMLDivElement | null>(null);

	const scrollRef = () => {
		if (ref?.current) {
			ref.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return { ref, scrollRef };
}
