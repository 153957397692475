import React from 'react';

export const CornerRightDown = (props: any) => (
  <svg
    data-aid="SvgCornerRightDown"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.7808 14.3755C18.4358 13.9435 17.8058 13.8745 17.3758 14.2195L13.9998 16.9195V8.00049C13.9998 6.34549 12.6548 5.00049 10.9998 5.00049H5.9998C5.4478 5.00049 4.9998 5.44749 4.9998 6.00049C4.9998 6.55249 5.4478 7.00049 5.9998 7.00049H10.9998C11.5518 7.00049 11.9998 7.44849 11.9998 8.00049V16.9195L8.6248 14.2195C8.1948 13.8745 7.5648 13.9435 7.2188 14.3755C6.8738 14.8065 6.9438 15.4365 7.3758 15.7805L12.3758 19.7805C12.5578 19.9265 12.7788 20.0005 12.9998 20.0005C13.2208 20.0005 13.4418 19.9265 13.6248 19.7805L18.6248 15.7805C19.0558 15.4365 19.1258 14.8065 18.7808 14.3755Z"
      fill="currentColor"
    />
  </svg>
);
