/** @jsxRuntime classic */
/** @jsx jsx */

import { Fragment } from 'react';
import { jsx } from '@emotion/react';
import { spaceColumnContainer, spaceRowContainer } from '../../styles';
import { ECalendarView, IWeeklyViewProps } from '../../types';
import { WeekToDrop } from './WeekToDrop';
import { useMiddlewareContext } from '../../hooks/useMiddlewareContext';

export const WeeklyView = ({ spaces, EventComponent, isLoading }: IWeeklyViewProps) => {
	const { options } = useMiddlewareContext();
	const { view = ECalendarView.VERTICAL } = options || {};
	const horizontal = view === ECalendarView.HORIZONTAL;

	return (
		<Fragment>
			{spaces.map(space => {
				const columnsCount = spaces.reduce((acc, { children }) => acc + (children.length || 1), 0);

				if (space.children.length === 0) {
					return (
						<div
							key={space.id}
							css={horizontal ? spaceRowContainer(spaces.length) : spaceColumnContainer(columnsCount, 1)}
							data-aid="WeeklyView-weekly"
						>
							<WeekToDrop spaceId={String(space.id)} EventComponent={EventComponent} isLoading={isLoading} />
						</div>
					);
				} else {
					return (
						<div
							key={space.id}
							css={
								horizontal
									? spaceRowContainer(spaces.length)
									: spaceColumnContainer(columnsCount, space.children.length || 1)
							}
						>
							{space.children.map(item => {
								return (
									<WeekToDrop
										key={item.id}
										spaceId={String(item.id)}
										EventComponent={EventComponent}
										isLoading={isLoading}
									/>
								);
							})}
						</div>
					);
				}
			})}
		</Fragment>
	);
};
