import React from 'react';

export const Vollyball = (props: any) => (
  <svg
    data-aid="svgVollyball"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.7446 5.70213C14.8935 3.70213 9.95739 5.48936 8.17016 6.29787C7.74463 5.02128 7.57442 3.82979 7.53187 3.06383C8.89357 2.38298 10.3829 2 11.9999 2C15.1489 2 17.9574 3.44681 19.7446 5.70213Z"
      fill="currentColor"
    />
    <path
      d="M8.76601 7.57447C10.5958 6.76596 16.3404 4.68085 21.234 8.04255C21.617 8.97872 21.8723 9.91489 22 10.9362C17.9149 8.85106 13.3192 10.5532 12 11.1489C10.4256 10.1277 9.40431 8.85106 8.76601 7.57447Z"
      fill="currentColor"
    />
    <path
      d="M17.4042 11.3617C15.2766 11.4043 13.4468 12.0851 12.6383 12.4255C12.8085 16.2553 9.82979 19.6596 8.17023 21.2766C9.14895 21.6596 10.1702 21.9149 11.2766 22C17.1915 17.9149 17.4893 12.9787 17.4042 11.3617Z"
      fill="currentColor"
    />
    <path
      d="M11.2341 12.3404C11.3192 14.0426 10.6383 15.6596 9.78729 17.0638C4.3405 13.8723 3.70221 8.6383 3.70221 6.42554C4.38305 5.40426 5.23412 4.5532 6.21283 3.87235C6.46815 6.08511 7.44687 9.87235 11.2341 12.3404Z"
      fill="currentColor"
    />
    <path
      d="M9.02125 18.2128C4.55318 15.5745 3.02127 11.617 2.55319 8.72341C2.21277 9.74468 2 10.8511 2 12C2 15.617 3.95744 18.8085 6.85104 20.5532C7.40423 20.0851 8.21274 19.2766 9.02125 18.2128Z"
      fill="currentColor"
    />
    <path
      d="M13.7021 21.8723C18.468 17.7872 18.8936 13.2766 18.8085 11.4043C19.8723 11.5745 20.9787 11.9149 21.9999 12.5532C21.7446 17.2766 18.2553 21.1064 13.7021 21.8723Z"
      fill="currentColor"
    />
  </svg>
);
