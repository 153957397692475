import React from 'react';

export const PublicNotesFilled = (props: any) => (
	<svg data-aid="svgPublicNotesFilled" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M9.43045 7.93436C9.84075 7.59009 10.3212 7.32668 10.8472 7.16862C10.5385 7.52837 10.2749 7.94041 10.0649 8.39123C9.83844 8.25964 9.62602 8.10642 9.43045 7.93436Z"
			fill="currentColor"
		/>
		<path
			d="M8.75006 8.66756C9.04379 8.92871 9.36863 9.15583 9.71831 9.34265C9.57611 9.86718 9.5 10.4249 9.5 11C9.5 11.534 9.56563 12.0531 9.68887 12.5445C9.32344 12.7311 8.98404 12.9613 8.6776 13.2282C8.24968 12.5914 8 11.8249 8 11C8 10.1296 8.278 9.32414 8.75006 8.66756Z"
			fill="currentColor"
		/>
		<path
			d="M10.5 11C10.5 11.4112 10.5452 11.8064 10.629 12.1792C11.0528 12.0624 11.4992 12 11.96 12C12.4482 12 12.9201 12.07 13.3662 12.2006C13.453 11.8215 13.5 11.4191 13.5 11C13.5 10.5674 13.4499 10.1525 13.3576 9.76276C12.9471 9.87178 12.5159 9.9299 12.0712 9.9299C11.5781 9.9299 11.1015 9.85842 10.6514 9.72532C10.5533 10.1259 10.5 10.5535 10.5 11Z"
			fill="currentColor"
		/>
		<path
			d="M9.3343 13.9823C9.54385 13.7998 9.77249 13.6388 10.0168 13.5027C10.2342 13.9951 10.5145 14.4436 10.8472 14.8314C10.2795 14.6608 9.76494 14.3675 9.3343 13.9823Z"
			fill="currentColor"
		/>
		<path
			d="M14.6257 14.0176C14.2033 14.3855 13.703 14.6661 13.1528 14.8314C13.4773 14.4532 13.752 14.0172 13.967 13.5391C14.2029 13.6762 14.4235 13.8367 14.6257 14.0176Z"
			fill="currentColor"
		/>
		<path
			d="M12 14.5751C12.4239 14.2085 12.7848 13.722 13.0463 13.1492C12.7009 13.052 12.3366 13 11.96 13C11.6092 13 11.269 13.0451 10.9449 13.1298C11.2071 13.7111 11.5714 14.2044 12 14.5751Z"
			fill="currentColor"
		/>
		<path
			d="M15.2924 13.2723C14.9926 13.0042 14.6602 12.7716 14.3018 12.5813C14.431 12.079 14.5 11.5474 14.5 11C14.5 10.4485 14.43 9.91295 14.2989 9.40735C14.6611 9.22679 14.9983 9.00362 15.3039 8.74447C15.7431 9.38659 16 10.1633 16 11C16 11.844 15.7386 12.6269 15.2924 13.2723Z"
			fill="currentColor"
		/>
		<path
			d="M14.6408 7.99557C14.4322 8.17061 14.2056 8.3247 13.9642 8.45467C13.7496 7.97902 13.4759 7.54518 13.1528 7.16862C13.7096 7.33592 14.2153 7.62126 14.6408 7.99557Z"
			fill="currentColor"
		/>
		<path
			d="M12.0712 8.9299C12.4016 8.9299 12.7226 8.88991 13.0296 8.81447C12.7695 8.25736 12.4149 7.78368 12 7.42493C11.5934 7.77649 11.2448 8.23843 10.9862 8.78108C11.3312 8.87806 11.6951 8.9299 12.0712 8.9299Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.38202 3C5.21984 3 4.10525 3.46168 3.28346 4.28347C2.46168 5.10525 2 6.21984 2 7.38202V14.1236C2 15.2858 2.46168 16.4004 3.28346 17.2222C4.10525 18.0439 5.21984 18.5056 6.38202 18.5056H8.21035L11.2849 21.5802C11.4746 21.7699 11.7318 21.8764 12 21.8764C12.2682 21.8764 12.5254 21.7699 12.7151 21.5802L15.7897 18.5056H17.618C18.7802 18.5056 19.8947 18.0439 20.7165 17.2222C21.5383 16.4004 22 15.2858 22 14.1236V7.38202C22 6.21984 21.5383 5.10525 20.7165 4.28347C19.8947 3.46168 18.7802 3 17.618 3H6.38202ZM12 6C11.8791 6 11.7592 6.00429 11.6404 6.01273L11.1511 6.07177C8.79401 6.47488 7 8.52793 7 11C7 13.4721 8.79401 15.5251 11.1511 15.9282L11.6404 15.9873C11.7592 15.9957 11.8791 16 12 16C12.1209 16 12.2408 15.9957 12.3596 15.9873L12.8489 15.9282C15.206 15.5251 17 13.4721 17 11C17 8.52793 15.206 6.47488 12.8489 6.07177L12.3596 6.01273C12.2408 6.00429 12.1209 6 12 6Z"
			fill="currentColor"
		/>
	</svg>
);
