/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, ReactNode } from 'react';
import { css, jsx } from '@emotion/react';
import {
	StyledH1,
	StyledH2,
	StyledH3,
	StyledH4,
	StyledH6,
	StyledH6Accented,
	StyledBodyAccented,
	StyledBody1,
	StyledBody1Link,
	StyledCTA,
	StyledBody2Accented,
	StyledBody2,
	StyledBody2Link,
	StyledCTA2,
	StyledCaptionAccented,
	StyledCaption,
	StyledCaptionLink,
	StyledCaptionCTA,
	StyledOverlinedAccented,
	StyledOverline,
	StyledOverlineLink,
} from './styles';
import {
	StyledH1_B2C,
	StyledH2_B2C,
	StyledH3_B2C,
	StyledH4_B2C,
	StyledH5_B2C,
	StyledH6Accented_B2C,
	StyledH6_B2C,
	StyledBodyAccented_B2C,
	StyledBody1_B2C,
	StyledBody1Link_B2C,
	StyledCTA_B2C,
	StyledBody2Accented_B2C,
	StyledBody2_B2C,
	StyledBody2Link_B2C,
	StyledCTA2_B2C,
	StyledCaptionAccented_B2C,
	StyledCaption_B2C,
	StyledCaptionLink_B2C,
	StyledCaptionCTA_B2C,
	StyledOverlinedAccented_B2C,
	StyledOverline_B2C,
	StyledOverlineLink_B2C,
} from './b2c_styles';
import { ETypography, ETypographyColor, IColors } from '../../types/theme';
import { useColors } from '../../hooks/useColors';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Store } from '../../store';
import { memoize } from 'lodash';
import { TypographyColorsMap } from '../../types';

const createTypographyColorsMap = memoize((colors: IColors) => {
	return {
		[ETypographyColor.primary]: colors.tx_text_primary,
		[ETypographyColor.secondary]: colors.tx_text_secondary,
		[ETypographyColor.teriaty]: colors.tx_text_teriaty,
		[ETypographyColor.white]: colors.tx_white,
		[ETypographyColor.black]: colors.black,
		[ETypographyColor.red]: colors.tx_error,
		[ETypographyColor.disabled]: colors.tx_text_disabled,
		default: colors.tx_text_disabled,
	} as TypographyColorsMap;
});

export const H1 = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledH1 className="h1" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledH1>
	);
};

export const H2 = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledH2 className="h2" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledH2>
	);
};

export const H3 = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledH3 className="h3" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledH3>
	);
};

export const H4 = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledH4 className="h4" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledH4>
	);
};

export const Body1Accented = ({
	color,
	children,
	...props
}: {
	color: ETypographyColor;
	children: ReactNode | string;
}) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledBodyAccented className="body-accented" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledBodyAccented>
	);
};

export const Body1 = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledBody1 className="body-1" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledBody1>
	);
};

export const Body1Link = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledBody1Link className="body-1-link" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledBody1Link>
	);
};

export const CTA1 = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledCTA className="cta" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledCTA>
	);
};

export const Body2Accented = ({
	color,
	children,
	...props
}: {
	color: ETypographyColor;
	children: ReactNode | string;
}) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledBody2Accented className="body-2-accented" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledBody2Accented>
	);
};

export const Body2 = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledBody2 className="body-2" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledBody2>
	);
};

export const Body2Link = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledBody2Link className="body-2-link" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledBody2Link>
	);
};

export const CTA2 = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledCTA2 className="cta-2" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledCTA2>
	);
};

export const CaptionAccented = ({
	color,
	children,
	...props
}: {
	color: ETypographyColor;
	children: ReactNode | string;
}) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledCaptionAccented className="caption-accented" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledCaptionAccented>
	);
};

export const Caption = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledCaption className="caption" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledCaption>
	);
};

export const CaptionLink = ({
	color,
	children,
	...props
}: {
	color: ETypographyColor;
	children: ReactNode | string;
}) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledCaptionLink className="caption-link" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledCaptionLink>
	);
};

export const CaptionCTA = ({
	color,
	children,
	...props
}: {
	color: ETypographyColor;
	children: ReactNode | string;
}) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledCaptionCTA className="caption-cta" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledCaptionCTA>
	);
};

export const OverlineAccented = ({
	color,
	children,
	...props
}: {
	color: ETypographyColor;
	children: ReactNode | string;
}) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledOverlinedAccented className="overline-accented" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledOverlinedAccented>
	);
};

export const Overline = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledOverline className="overline" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledOverline>
	);
};

export const OverlineLink = ({
	color,
	children,
	...props
}: {
	color: ETypographyColor;
	children: ReactNode | string;
}) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledOverlineLink className="overline-link" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledOverlineLink>
	);
};

const H6Accented = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledH6Accented className="h6-accented" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledH6Accented>
	);
};

const H6 = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledH6 className="h6" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledH6>
	);
};

const H1_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledH1_B2C className="h1" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledH1_B2C>
	);
};
const H2_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledH2_B2C className="h2" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledH2_B2C>
	);
};
const H3_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledH3_B2C className="h3" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledH3_B2C>
	);
};
const H4_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledH4_B2C className="h4" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledH4_B2C>
	);
};
const H5_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledH5_B2C className="h5" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledH5_B2C>
	);
};
const H6Accented_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledH6Accented_B2C className="h6-accented" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledH6Accented_B2C>
	);
};
const H6_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledH6_B2C className="h6" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledH6_B2C>
	);
};
const BodyAccented_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledBodyAccented_B2C className="body-accented" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledBodyAccented_B2C>
	);
};
const Body1_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledBody1_B2C className="body-1" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledBody1_B2C>
	);
};
const Body1Link_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledBody1Link_B2C className="body-1-link" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledBody1Link_B2C>
	);
};
const CTA_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledCTA_B2C className="cta" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledCTA_B2C>
	);
};
const Body2Accented_B2C = ({
	color,
	children,
	...props
}: {
	color: ETypographyColor;
	children: ReactNode | string;
}) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledBody2Accented_B2C className="body-2-accented" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledBody2Accented_B2C>
	);
};
const Body2_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledBody2_B2C className="body-2" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledBody2_B2C>
	);
};
const Body2Link_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledBody2Link_B2C className="body-2-link" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledBody2Link_B2C>
	);
};
const CTA2_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledCTA2_B2C className="cta-2" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledCTA2_B2C>
	);
};
const CaptionAccented_B2C = ({
	color,
	children,
	...props
}: {
	color: ETypographyColor;
	children: ReactNode | string;
}) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledCaptionAccented_B2C className="caption-accented" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledCaptionAccented_B2C>
	);
};
const Caption_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledCaption_B2C className="caption" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledCaption_B2C>
	);
};
const CaptionLink_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledCaptionLink_B2C className="caption-link" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledCaptionLink_B2C>
	);
};
const CaptionCTA_B2C = ({ color, children, ...props }: { color: ETypographyColor; children: ReactNode | string }) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledCaptionCTA_B2C className="caption-cta" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledCaptionCTA_B2C>
	);
};

export const OverlineAccented_B2C = ({
	color,
	children,
	...props
}: {
	color: ETypographyColor;
	children: ReactNode | string;
}) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledOverlinedAccented_B2C className="overline-accented" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledOverlinedAccented_B2C>
	);
};

export const Overline_B2C = ({
	color,
	children,
	...props
}: {
	color: ETypographyColor;
	children: ReactNode | string;
}) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledOverline_B2C className="overline" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledOverline_B2C>
	);
};

export const OverlineLink_B2C = ({
	color,
	children,
	...props
}: {
	color: ETypographyColor;
	children: ReactNode | string;
}) => {
	const { colors } = useColors();

	const colorsMap: TypographyColorsMap = createTypographyColorsMap(colors);

	return (
		<StyledOverlineLink_B2C className="overline-link" {...props} color={color} theme={colorsMap}>
			{children}
		</StyledOverlineLink_B2C>
	);
};

export const Typography = ({
	type,
	color,
	children,
	...props
}: {
	type: ETypography;
	color: ETypographyColor;
	children: ReactNode | string;
} & any) => {
	const isWhiteLabeled = useRecoilValue(Store.isWhiteLabeld);
	if (isWhiteLabeled) {
		switch (type) {
			case 'h1':
				return (
					<H1_B2C color={color} {...props}>
						{children}
					</H1_B2C>
				);
			case 'h2':
				return (
					<H2_B2C color={color} {...props}>
						{children}
					</H2_B2C>
				);
			case 'h3':
				return (
					<H3_B2C color={color} {...props}>
						{children}
					</H3_B2C>
				);
			case 'h4':
				return (
					<H4_B2C color={color} {...props}>
						{children}
					</H4_B2C>
				);
			case 'Body1Accented':
				return (
					<BodyAccented_B2C color={color} {...props}>
						{children}
					</BodyAccented_B2C>
				);
			case 'body1':
				return (
					<Body1_B2C color={color} {...props}>
						{children}
					</Body1_B2C>
				);
			case 'body1Link':
				return (
					<Body1Link_B2C color={color} {...props}>
						{children}
					</Body1Link_B2C>
				);
			case 'cta1':
				return (
					<CTA_B2C color={color} {...props}>
						{children}
					</CTA_B2C>
				);
			case 'body2Accented':
				return (
					<Body2Accented_B2C color={color} {...props}>
						{children}
					</Body2Accented_B2C>
				);
			case 'body2':
				return (
					<Body2_B2C color={color} {...props}>
						{children}
					</Body2_B2C>
				);
			case 'body2Link':
				return (
					<Body2Link_B2C color={color} {...props}>
						{children}
					</Body2Link_B2C>
				);
			case 'cta2':
				return (
					<CTA2_B2C color={color} {...props}>
						{children}
					</CTA2_B2C>
				);
			case 'captionAccented':
				return (
					<CaptionAccented_B2C color={color} {...props}>
						{children}
					</CaptionAccented_B2C>
				);
			case 'caption':
				return (
					<Caption_B2C color={color} {...props}>
						{children}
					</Caption_B2C>
				);
			case 'captionLink':
				return (
					<CaptionLink_B2C color={color} {...props}>
						{children}
					</CaptionLink_B2C>
				);
			case 'captionCTA':
				return (
					<CaptionCTA_B2C color={color} {...props}>
						{children}
					</CaptionCTA_B2C>
				);
			case 'h5':
				return (
					<H5_B2C color={color} {...props}>
						{children}
					</H5_B2C>
				);
			case 'h6':
				return (
					<H6_B2C color={color} {...props}>
						{children}
					</H6_B2C>
				);
			case 'h6Accented':
				return (
					<H6Accented_B2C color={color} {...props}>
						{children}
					</H6Accented_B2C>
				);
			case 'overlineAccented':
				return (
					<OverlineAccented_B2C color={color} {...props}>
						{children}
					</OverlineAccented_B2C>
				);
			case 'overline':
				return (
					<Overline_B2C color={color} {...props}>
						{children}
					</Overline_B2C>
				);
			case 'overlineLink':
				return (
					<OverlineLink_B2C color={color} {...props}>
						{children}
					</OverlineLink_B2C>
				);
			default:
				return <Fragment />;
		}
	} else {
		switch (type) {
			case 'h1':
				return (
					<H1 color={color} {...props}>
						{children}
					</H1>
				);
			case 'h2':
				return (
					<H2 color={color} {...props}>
						{children}
					</H2>
				);
			case 'h3':
				return (
					<H3 color={color} {...props}>
						{children}
					</H3>
				);
			case 'h4':
				return (
					<H4 color={color} {...props}>
						{children}
					</H4>
				);
			case 'h6':
				return (
					<H6 color={color} {...props}>
						{children}
					</H6>
				);
			case 'h6Accented':
				return (
					<H6Accented color={color} {...props}>
						{children}
					</H6Accented>
				);
			case 'Body1Accented':
				return (
					<Body1Accented color={color} {...props}>
						{children}
					</Body1Accented>
				);
			case 'body1':
				return (
					<Body1 color={color} {...props}>
						{children}
					</Body1>
				);
			case 'body1Link':
				return (
					<Body1Link color={color} {...props}>
						{children}
					</Body1Link>
				);
			case 'cta1':
				return (
					<CTA1 color={color} {...props}>
						{children}
					</CTA1>
				);
			case 'body2Accented':
				return (
					<Body2Accented color={color} {...props}>
						{children}
					</Body2Accented>
				);
			case 'body2':
				return (
					<Body2 color={color} {...props}>
						{children}
					</Body2>
				);
			case 'body2Link':
				return (
					<Body2Link color={color} {...props}>
						{children}
					</Body2Link>
				);
			case 'cta2':
				return (
					<CTA2 color={color} {...props}>
						{children}
					</CTA2>
				);
			case 'captionAccented':
				return (
					<CaptionAccented color={color} {...props}>
						{children}
					</CaptionAccented>
				);
			case 'caption':
				return (
					<Caption color={color} {...props}>
						{children}
					</Caption>
				);
			case 'captionLink':
				return (
					<CaptionLink color={color} {...props}>
						{children}
					</CaptionLink>
				);
			case 'captionCTA':
				return (
					<CaptionCTA color={color} {...props}>
						{children}
					</CaptionCTA>
				);
			case 'overlineAccented':
				return (
					<OverlineAccented color={color} {...props}>
						{children}
					</OverlineAccented>
				);
			case 'overline':
				return (
					<Overline color={color} {...props}>
						{children}
					</Overline>
				);
			case 'overlineLink':
				return (
					<OverlineLink color={color} {...props}>
						{children}
					</OverlineLink>
				);
			default:
				return <Fragment />;
		}
	}
};
