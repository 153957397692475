import { useErrorModal } from '@app/react/hooks/useErrorModal';
import { customerStore } from '@app/react/stores/customerStore';
import { ICustomer, ICustomerResponse } from '@app/react/types/customers';
import { EStorageKeys } from '@app/react/types/enums';
import { useRecoilState } from 'recoil';
import { localStorage } from '@app/react/lib/storage';
import { customersApi } from '@app/react/lib/api/customersApi';
import { IErrorArr } from '@app/react/types/errors';
import { useNavigation } from '@app/react/hooks/useNavigation';
import {
	getCustomerPagePath,
	getFamilyPagePath,
	getInvoiceByIdPath,
	getMembershipByIdPath,
	NEW_FAMILY_ACCOUNT,
} from '@app/react/lib/paths';
import { useHistory } from 'react-router';
import { ERoutePaths } from '../../../../types/navigation';
import { getConnectedMembers } from '../lib';
import { ENotificationType } from '@bondsports/utils';
import { useNotification } from '@app/react/hooks/useNotification';
import { CustomerFamilyWithMembersDto } from '@bondsports/types';

export const useCustomer = () => {
	const [customerState, setCustomerState] = useRecoilState(customerStore.customerState);
	const [isLoadingCustomer, setIsLoadingCustomer] = useRecoilState(customerStore.isLoadingCustomer);
	const [isLoadingLinkedAccounts, setisLoadingLinkedAccounts] = useRecoilState(customerStore.isLoadingLinkedAccountsr);
	const [connectedAccounts, setConnectedAccounts] = useRecoilState(customerStore.connectedAccounts);
	const { setErrorModal } = useErrorModal();
	const { ngNavigate, pushNavigation } = useNavigation();
	const history = useHistory();
	const { setToastNotification } = useNotification();
	const { CUSTOMER_DATA } = EStorageKeys;

	const isLoadingCustomerToggle = () => {
		setIsLoadingCustomer(state => !state);
	};

	const handleLoaderTrigger = (isDisableTriggerLoader: boolean) => {
		if (!isDisableTriggerLoader) {
			isLoadingCustomerToggle();
		}
	};

	const handleError = (errorMessage: string, errCallback?: (err: string) => void) => {
		if (errCallback) {
			errCallback(errorMessage);
		} else {
			setErrorModal({ message: errorMessage });
		}
		return;
	};

	const saveData = (data: ICustomer) => {
		setCustomerState(data);
		localStorage.setItem(CUSTOMER_DATA, data);
	};

	const handleResponse = (response: ICustomerResponse | IErrorArr, errCallback?: (err: string) => void) => {
		if ((response as IErrorArr)?.err) {
			handleError(String((response as IErrorArr)?.err), errCallback);
		}
		saveData((response as ICustomerResponse).data);
	};

	const fetchCustomerData = async ({
		organizationId,
		customerId,
		errCallback,
		isDisableTriggerLoader = false,
	}: {
		organizationId: number;
		customerId: number;
		errCallback?: (err: string) => void;
		isDisableTriggerLoader?: boolean;
	}) => {
		handleLoaderTrigger(isDisableTriggerLoader);
		const response = await customersApi.getCustomerById(organizationId, customerId);
		handleResponse(response, errCallback);
		handleLoaderTrigger(isDisableTriggerLoader);
	};

	const getCustomerData = async (organizationId: number, customerId: number) => {
		if (!customerState || customerState?.id !== customerId) {
			const customerData = localStorage.getItem(CUSTOMER_DATA) as ICustomer;
			if (customerData?.id === customerId) {
				setCustomerState(customerData);
			} else {
				fetchCustomerData({ organizationId, customerId });
			}
		}
	};

	const handleGoToCustomerPage = (customerId: number, additionalData?: string) => {
		const path = getCustomerPagePath(customerId);
		history.push(`${additionalData || ''}${path}`);
	};

	const handleGoToFamilyPage = (familyId: number) => {
		const path = getFamilyPagePath({ familyId, isLeadingSlash: false });
		ngNavigate(ERoutePaths.CUSTOMERS, path);
	};

	const goToCreateFamilyAccount = (customerId: number) => {
		history.push(`/${ERoutePaths.CUSTOMER}/${customerId}/${NEW_FAMILY_ACCOUNT}`);
	};

	const updateCustomerByProperty = <K extends keyof ICustomer>(property: K, value: ICustomer[K]) => {
		const updatedCustomer = { ...customerState };
		updatedCustomer[property] = value;

		saveData(updatedCustomer);
	};

	const navigateToMembershipById = (id: number) => ngNavigate(ERoutePaths.MEMBERSHIPS, getMembershipByIdPath(id));
	const navigateInvoiceById = (id: number, param?: string) => pushNavigation(getInvoiceByIdPath(id, param));

	const generateLinkedAccounts = async (customerState: ICustomer) => {
		setisLoadingLinkedAccounts(true);
		const linkedMembers = await getConnectedMembers(customerState, (eror: string) => {
			setToastNotification(eror, ENotificationType.warning);
		});
		setisLoadingLinkedAccounts(false);
		setConnectedAccounts(linkedMembers);
	};

	const resetConnectedAccounts = () => setConnectedAccounts([]);

	const resetCustomerState = () => setCustomerState(null);

	const getCustomerFamiliesWithMembers = async (
		organizationId: number,
		customerId: number,
		handleSucces: () => void,
		handleError: () => void
	): Promise<CustomerFamilyWithMembersDto[] | []> => {
		try {
			const res = await customersApi.getCustomerFamiliesWithMembers(organizationId, customerId);

			if ((res as { err: string[] })?.err) {
				handleError();
				return [];
			} else {
				handleSucces();
				return res as CustomerFamilyWithMembersDto[];
			}
		} catch {
			handleError();
		}
	};
	return {
		fetchCustomerData,
		getCustomerData,
		customerState,
		isLoadingCustomer,
		handleGoToCustomerPage,
		handleGoToFamilyPage,
		goToCreateFamilyAccount,
		updateCustomerByProperty,
		navigateToMembershipById,
		navigateInvoiceById,
		isLoadingLinkedAccounts,
		connectedAccounts,
		generateLinkedAccounts,
		resetConnectedAccounts,
		resetCustomerState,
		getCustomerFamiliesWithMembers,
	};
};
