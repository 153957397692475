import React from "react";
export const Hockey = (props: any) => (
  <svg
    data-aid="svgHockey"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 10C2 7.24285 6.48605 5 12 5C17.5139 5 22 7.24289 22 10C22 12.7572 17.5139 15 12 15C6.4857 15 2 12.7572 2 10ZM5.57145 12.5004C5.69965 12.5004 5.8232 12.4311 5.8875 12.31C5.97965 12.1354 5.91285 11.9193 5.73855 11.8272C4.60031 11.2254 3.92031 10.4747 3.91961 9.81895C3.91855 9.10215 4.71426 8.52786 5.38211 8.1725C5.55641 8.07965 5.62211 7.86325 5.52961 7.6893C5.43711 7.51536 5.22035 7.4493 5.04641 7.5418C3.8232 8.19286 3.20391 8.9593 3.2057 9.82036C3.20715 10.7729 3.9882 11.7096 5.405 12.4589C5.45824 12.4872 5.515 12.5004 5.57145 12.5004ZM6.88785 6.80356C6.7007 6.86571 6.59891 7.0675 6.66035 7.255C6.71 7.405 6.84965 7.50039 6.99961 7.50032C7.03676 7.50032 7.07426 7.49426 7.11141 7.48211C7.53641 7.34176 8.00566 7.24176 8.50246 7.13641C8.69531 7.09535 8.81852 6.90606 8.77746 6.71285C8.73641 6.51965 8.54746 6.39641 8.35391 6.43785L8.35103 6.43846C7.83604 6.54789 7.34914 6.65134 6.88785 6.80356ZM7.2568 13.1997C8.88215 13.6761 10.5221 13.9283 12 13.9286C12.1971 13.9286 12.3571 13.7686 12.3571 13.5715C12.3571 13.3743 12.1971 13.2143 12 13.2143C10.5893 13.214 9.01855 12.9718 7.45785 12.5143C7.2693 12.459 7.07 12.5668 7.01465 12.7565C6.9593 12.9461 7.0675 13.1443 7.2568 13.1997Z"
      fill="currentColor"
    />
    <path
      d="M21.68 12.4889C21.7796 12.3753 21.8743 12.2582 21.9632 12.1371C21.9685 12.1301 21.9741 12.1233 21.9798 12.1166C21.9869 12.1082 21.9939 12.0998 22 12.0911V14.6429C22 17.4 17.5139 19.6429 12 19.6429C6.48605 19.6429 2 17.4 2 14.6429V12.0911C2.00572 12.0989 2.01206 12.1065 2.0184 12.1141C2.02474 12.1217 2.03107 12.1293 2.0368 12.1371C3.2182 13.7418 5.36145 14.6632 7.245 15.1561C9.07395 15.6346 10.9836 15.745 12.8725 15.6671C14.4836 15.6007 16.0993 15.3964 17.6332 14.8989C19.0861 14.4275 20.6493 13.6625 21.68 12.4889Z"
      fill="currentColor"
    />
  </svg>
);
