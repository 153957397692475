/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { ReactNode } from 'react';
import { flexCss, gapCss } from '@app/react/styles/utils';
import { Icons, BnIcon, Typography, ETypography, ETypographyColor } from '@bondsports/utils';
import { colors } from '@app/react/styles/theme';
import { nowrapCss, zeroMinWidthCss } from '@app/react/styles/ellipsisStyles';

export const iconCss = css`
	min-width: 20px;
	min-height: 20px;
	color: ${colors.brandPrimary};
`;

export const Detail = ({ icon, detail }: { icon: Icons; detail: string | ReactNode }) => {
	return (
		<div css={[flexCss, gapCss(6), nowrapCss, zeroMinWidthCss]}>
			<BnIcon icon={icon} css={iconCss} />
			{typeof detail === 'string' ? (
				<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
					{detail}
				</Typography>
			) : (
				detail
			)}
		</div>
	);
};
