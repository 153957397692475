import React from "react";

export const Parking = (props: any) => (
  <svg
    data-aid="SvgParking"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99967 5.41665C5.40137 5.41665 4.91634 5.90167 4.91634 6.49998V18.5C4.91634 19.0983 5.40137 19.5833 5.99967 19.5833H17.9997C18.598 19.5833 19.083 19.0983 19.083 18.5V6.49998C19.083 5.90167 18.598 5.41665 17.9997 5.41665H5.99967ZM3.08301 6.49998C3.08301 4.88915 4.38884 3.58331 5.99967 3.58331H17.9997C19.6105 3.58331 20.9163 4.88915 20.9163 6.49998V18.5C20.9163 20.1108 19.6105 21.4166 17.9997 21.4166H5.99967C4.38884 21.4166 3.08301 20.1108 3.08301 18.5V6.49998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.08301 8.50002C8.08301 7.99376 8.49342 7.58335 8.99968 7.58335H12.9997C13.7732 7.58335 14.5151 7.89064 15.0621 8.43762C15.6091 8.98461 15.9163 9.72647 15.9163 10.5C15.9163 11.2736 15.6091 12.0154 15.0621 12.5624C14.5151 13.1094 13.7732 13.4167 12.9997 13.4167H9.91635V16.5C9.91635 17.0063 9.50594 17.4167 8.99968 17.4167C8.49342 17.4167 8.08301 17.0063 8.08301 16.5V8.50002ZM9.91635 11.5834H12.9997C13.287 11.5834 13.5625 11.4692 13.7657 11.2661C13.9689 11.0629 14.083 10.7873 14.083 10.5C14.083 10.2127 13.9689 9.93715 13.7657 9.73399C13.5625 9.53082 13.287 9.41669 12.9997 9.41669H9.91635V11.5834Z"
      fill="currentColor"
    />
  </svg>
);
