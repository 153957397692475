import React from "react";

export const Lights = (props: any) => (
  <svg
    data-aid="SvgLights"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9997 2.58337C12.5059 2.58337 12.9163 2.99378 12.9163 3.50004V4.50004C12.9163 5.0063 12.5059 5.41671 11.9997 5.41671C11.4934 5.41671 11.083 5.0063 11.083 4.50004V3.50004C11.083 2.99378 11.4934 2.58337 11.9997 2.58337ZM4.95149 5.45186C5.30947 5.09388 5.88988 5.09388 6.24786 5.45186L6.94786 6.15186C7.30584 6.50984 7.30584 7.09024 6.94786 7.44822C6.58988 7.8062 6.00947 7.8062 5.65149 7.44822L4.95149 6.74822C4.59351 6.39024 4.59351 5.80984 4.95149 5.45186ZM19.0479 5.45186C19.4058 5.80984 19.4058 6.39024 19.0479 6.74822L18.3479 7.44822C17.9899 7.8062 17.4095 7.8062 17.0515 7.44822C16.6935 7.09024 16.6935 6.50984 17.0515 6.15186L17.7515 5.45186C18.1095 5.09388 18.6899 5.09388 19.0479 5.45186ZM2.08301 12.5C2.08301 11.9938 2.49341 11.5834 2.99967 11.5834H3.99967C4.50594 11.5834 4.91634 11.9938 4.91634 12.5C4.91634 13.0063 4.50594 13.4167 3.99967 13.4167H2.99967C2.49341 13.4167 2.08301 13.0063 2.08301 12.5ZM19.083 12.5C19.083 11.9938 19.4934 11.5834 19.9997 11.5834H20.9997C21.5059 11.5834 21.9163 11.9938 21.9163 12.5C21.9163 13.0063 21.5059 13.4167 20.9997 13.4167H19.9997C19.4934 13.4167 19.083 13.0063 19.083 12.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9997 8.41669C11.1427 8.41669 10.3074 8.68634 9.61211 9.18745C8.91686 9.68856 8.3969 10.3957 8.12588 11.2088C7.85487 12.0218 7.84654 12.8995 8.10208 13.7175C8.35624 14.5312 8.85862 15.2448 9.53863 15.7584C9.5591 15.7733 9.57909 15.7892 9.59854 15.806C9.61896 15.8236 9.63844 15.8419 9.65695 15.8609C10.1435 16.3463 10.51 16.9386 10.7274 17.5908C10.938 18.2226 11.0029 18.8936 10.9177 19.5535C10.9309 19.8213 11.0431 20.0755 11.2336 20.2661C11.4368 20.4692 11.7124 20.5834 11.9997 20.5834C12.287 20.5834 12.5625 20.4692 12.7657 20.2661C12.9562 20.0755 13.0685 19.8213 13.0817 19.5535C12.9964 18.8936 13.0613 18.2226 13.2719 17.5908C13.4911 16.9331 13.8621 16.3363 14.3548 15.8486C14.3845 15.8191 14.4162 15.7918 14.4497 15.7667C15.1353 15.2525 15.6417 14.5356 15.8973 13.7175C16.1528 12.8995 16.1445 12.0218 15.8735 11.2088C15.6025 10.3957 15.0825 9.68856 14.3872 9.18745C13.692 8.68634 12.8567 8.41669 11.9997 8.41669ZM8.39377 17.1909C7.42869 16.4491 6.71542 15.4272 6.35214 14.2642C5.98187 13.0789 5.99394 11.8071 6.38663 10.629C6.77932 9.45093 7.53273 8.42627 8.54014 7.70017C9.54754 6.97408 10.7579 6.58335 11.9997 6.58335C13.2415 6.58335 14.4518 6.97408 15.4592 7.70017C16.4666 8.42627 17.22 9.45093 17.6127 10.629C18.0054 11.8071 18.0175 13.0789 17.6472 14.2642C17.2839 15.4272 16.5707 16.4491 15.6056 17.1909C15.3368 17.4687 15.1336 17.8034 15.0112 18.1705C14.8829 18.5552 14.8471 18.9647 14.9065 19.3658C14.913 19.4102 14.9163 19.4551 14.9163 19.5C14.9163 20.2736 14.609 21.0154 14.0621 21.5624C13.5151 22.1094 12.7732 22.4167 11.9997 22.4167C11.2261 22.4167 10.4843 22.1094 9.93728 21.5624C9.3903 21.0154 9.08301 20.2736 9.08301 19.5C9.08301 19.4551 9.08631 19.4102 9.09289 19.3658C9.15227 18.9647 9.1164 18.5552 8.98818 18.1705C8.8658 17.8034 8.66257 17.4687 8.39377 17.1909Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.78301 17.5001C8.78301 16.9938 9.19341 16.5834 9.69968 16.5834H14.2997C14.8059 16.5834 15.2163 16.9938 15.2163 17.5001C15.2163 18.0063 14.8059 18.4167 14.2997 18.4167H9.69968C9.19341 18.4167 8.78301 18.0063 8.78301 17.5001Z"
      fill="currentColor"
    />
  </svg>
);
