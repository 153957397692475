/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { colors } from '../../../styles/theme';

const errorMessageCss = css`
	font-weight: 500;
	color: ${colors.dangerRed};
`;

export const TableRowsErorr = ({ message }: { message: string }) => {
	return <p css={errorMessageCss}>{message}</p>;
};
