/* @jsx jsx */

import React, { Fragment, useMemo } from 'react';
import { jsx, css } from '@emotion/react';
import { ProgramEventDto } from '@bondsports/types';
import { CHARACHERS, Checkbox, ETypography, ETypographyColor, Typography, useModal } from '@bondsports/utils';
import {
	flexCol,
	flexGrowCss,
	flexRowCss,
	fullWidthCss,
	gapCss,
	justifyContentCss,
	paddingBottomCss,
	pointerCss,
} from '../../../../../styles/utils';
import { EllipsisWithTooltipContainer } from '../../../../shared/EllipsisWithTooltipContainer';
import { separatorCss, textAlignCss } from '../../styles';
import { FormattedHeader, formatDate, getDateAndTimeHeader } from '../../../../../lib/dates';
import { EDateTimeFormats } from '../../../../../types/times';
import { EllipsisWithViewMoreContainer } from '../../../../shared/EllipsisWithViewMoreContainer';
import { AddonsModal } from '../utils/AddonsModal';

type EventRowProps = {
	data: ProgramEventDto & {
		id: number;
		organizationId: number;
		userId: number;
		productUserId: number;
		isSegment?: boolean;
	};
	isSelected: boolean;
	labels: Record<string, any>;
	isLastItem: boolean;
	index: number;
	isDisabled: boolean;
	timezone: string;
};

const eventRowContainerCss = (isLast: boolean) => css`
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 12px;
	padding-top: 20px;
	${separatorCss('top')};
	${isLast ? paddingBottomCss(20) : paddingBottomCss(28)};
`;

export const ProgramEventRow: React.FC<EventRowProps> = ({
	data,
	index,
	isDisabled,
	timezone,
	labels,
	isLastItem,
	isSelected,
}) => {
	const { isShowing: isShowingAddonsModal, toggle: toggleAddonsModal } = useModal();

	const textColor: ETypographyColor = useMemo(
		() => (Date.parse(data?.endDate?.toString()) < Date.now() ? ETypographyColor.secondary : ETypographyColor.primary),
		[data.endDate]
	);

	const eventHeaderText: string = useMemo(() => {
		return getEventHeader(data);
	}, [data]);

	return (
		<div
			css={eventRowContainerCss(isLastItem)}
			data-index={index}
			datatype="row"
			data-testid="event"
			key={`program-event-row-${index}`}
		>
			<Checkbox isDisabled={isDisabled} />
			<div datatype="content" data-testid="event-name" css={[flexCol, flexGrowCss(1), gapCss(8), fullWidthCss]}>
				<div css={[flexRowCss, justifyContentCss('space-between'), fullWidthCss, gapCss(16)]}>
					<span css={flexGrowCss(0.7)}>
						<EllipsisWithTooltipContainer
							color={textColor}
							type={ETypography.body2Accented}
							text={eventHeaderText}
							placement="top-end"
						/>
					</span>
				</div>
				<div
					datatype="date"
					data-testid="event-purchse-data"
					css={[flexRowCss, justifyContentCss('space-between'), fullWidthCss, gapCss(16)]}
				>
					<div css={flexGrowCss(0.97)}>
						<Typography type={ETypography.body2} color={ETypographyColor.secondary}>
							{labels.registeredOn} {formatDate(data.registeredOn, EDateTimeFormats.DAY_FORMAT)}
						</Typography>
					</div>
					<Fragment>
						<div
							datatype="content"
							data-testid="event-addons"
							css={[pointerCss, flexGrowCss(0.03), justifyContentCss('end'), textAlignCss('start')]}
						>
							<EllipsisWithViewMoreContainer
								type={ETypography.body2}
								color={ETypographyColor.secondary}
								onClick={toggleAddonsModal}
								label={labels.viewAddons}
								safetyGap={100}
								showOnlyLink={!!data.addonsCount}
							/>
						</div>
					</Fragment>
				</div>
			</div>
			{isShowingAddonsModal && (
				<AddonsModal
					isShowing={isShowingAddonsModal}
					toggle={toggleAddonsModal}
					data={data}
					labels={{
						title: labels.addonsTitle,
						close: labels.close,
						addonTypes: labels.addonTypes,
						error: labels.addonError,
					}}
				/>
			)}
		</div>
	);
};

function getEventHeader(data: ProgramEventDto) {
	const formattedHeader: FormattedHeader = getDateAndTimeHeader(
		data.startDate,
		data.endDate,
		data.startTime,
		data.endTime
	);
	// Build the desired string
	const formattedDateTimeString = `${formattedHeader.dayOfWeek} ${CHARACHERS.DOT} ${formattedHeader.fullDate} ${CHARACHERS.DOT} ${formattedHeader.formattedStartTime} ${CHARACHERS.DASH} ${formattedHeader.formattedEndTime}`;
	return formattedDateTimeString;
}
