/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { flexCss } from '../../../styles/utils';
import React, { FC, Fragment } from 'react';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor, IColors } from '../../../types';
import { capitalize } from '../../../functions';
import { useColors } from '../../../hooks/useColors';

const paymentContainerCss = (colors: IColors) => css`
	${flexCss};
	padding: 1rem;
	justify-content: flex-start;
	.marginY {
		margin: 10px 0;
	}
	flex-wrap: wrap;
	border: 1px solid ${colors.bg_border_primary};
	border-radius: 4px;
`;

const participantColCss = css`
	min-width: 230px;
	align-self: flex-start;
	margin: 8px;
	height: 100%;
`;

const cellMarginCss = (bottomMargin?: number) => css`
	margin: 4px 0 ${bottomMargin ?? '10'}px 0;
`;

const labelCss = css`
	text-transform: uppercase;
`;

interface Props {
	lineItem: any /* LineItem */;
	labels: any;
}

const ProgramParticipantDetails: FC<Props> = ({ lineItem, labels }) => {
	const scheduleInfoLabels = labels.attendeeInfo;
	const lastName = capitalize(lineItem?.customerLastName ?? '');
	const firstName = capitalize(lineItem?.customerFirstName ?? '');
	const name = !!lastName && !!firstName ? `${lastName}, ${firstName}` : lastName || firstName || '';
	const productName = capitalize(lineItem.product?.name ?? '');

	const purchasedResource = lineItem.purchasedResources?.[0];
	const sessionName = purchasedResource?.programSeason
		? capitalize(purchasedResource?.programSeason?.parentSession?.name)
		: capitalize(purchasedResource?.event?.parentSession?.name);
	const programName = capitalize(
		purchasedResource?.programSeason?.program?.name ?? purchasedResource?.event?.parentSession?.program?.name ?? ''
	);

	return (
		<div css={[flexCss]} data-aid="ProgramParticipantDetails-appendix">
			<div css={participantColCss}>
				<Typography color={ETypographyColor.secondary} type={ETypography.overlineAccented}>
					<span css={labelCss}>{scheduleInfoLabels.participant}</span>
				</Typography>
				<div css={cellMarginCss(24)}>
					<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
						{name}
					</Typography>
				</div>
				<Typography color={ETypographyColor.secondary} type={ETypography.overlineAccented}>
					<span css={labelCss}>{scheduleInfoLabels.products}</span>
				</Typography>
				<div css={cellMarginCss()}>
					<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
						{productName}
					</Typography>
				</div>
			</div>
			<div css={participantColCss}>
				<Typography color={ETypographyColor.secondary} type={ETypography.overlineAccented}>
					<span css={labelCss}>{scheduleInfoLabels.program}</span>
				</Typography>
				{programName && (
					<div css={cellMarginCss(24)}>
						<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
							{programName}
						</Typography>
					</div>
				)}
				{sessionName && (
					<Fragment>
						<Typography color={ETypographyColor.secondary} type={ETypography.overlineAccented}>
							<span css={labelCss}>{scheduleInfoLabels.sessions}</span>
						</Typography>
						<div css={cellMarginCss()}>
							<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
								{sessionName}
							</Typography>
						</div>
					</Fragment>
				)}
			</div>
		</div>
	);
};

export const ProgramParticipantInfo: FC<Props> = ({ lineItem, labels }) => {
	const { colors } = useColors();
	return (
		<div css={paymentContainerCss(colors)}>
			<ProgramParticipantDetails lineItem={lineItem} labels={labels} />
		</div>
	);
};
