import { useCallback } from 'react';
import { EStorageKeys } from '../types/enums';

export type CacheKey = 'sports' | EStorageKeys | string;
export type CacheGetParams = {
	key: CacheKey;
	parcelable: boolean;
};

export const useCache = () => {
	const set = useCallback((key: CacheKey, value: string | object) => {
		const parcelableValue = typeof value === 'object' ? JSON.stringify(value) : value;

		// eslint-disable-next-line no-undef
		sessionStorage.setItem(key, parcelableValue);
	}, []);

	const get = useCallback((params: CacheGetParams) => {
		const { key, parcelable } = params;

		// eslint-disable-next-line no-undef
		const value = sessionStorage.getItem(key);

		if (parcelable && value) {
			return JSON.parse(value);
		}

		return value;
	}, []);

	return { get, set };
};
