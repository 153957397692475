import { Directive, HostListener, Input } from '@angular/core';
import { FileUploader } from '@app/shared/directives/file-uploader/file-uploader.class';

@Directive({
	selector: '[rcFileUploader]',
})
export class FileUploaderDirective {
	@Input() uploader: FileUploader;
	constructor() {}

	@HostListener('drop', ['$event'])
	public onDrop(event: any): boolean {
		const transfer = this._getTransfer(event);
		if (!transfer) {
			return;
		}

		this._preventAndStop(event);
		this.uploader.addToQueue(transfer.files);
	}

	@HostListener('dragleave', ['$event'])
	public onDragLeave(event: any): any {
		if ((this as any).element) {
			if (event.currentTarget === (this as any).element[0]) {
				return;
			}
		}

		this._preventAndStop(event);
	}

	@HostListener('dragover', ['$event'])
	public onDragOver(event: any): void {
		const transfer = this._getTransfer(event);
		if (!this._haveFiles(transfer.types)) {
			return;
		}

		transfer.dropEffect = 'copy';
		this._preventAndStop(event);
	}

	protected _getTransfer(event: any): any {
		return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer; // jQuery fix;
	}

	protected _preventAndStop(event: any): any {
		event.preventDefault();
		event.stopPropagation();
	}

	protected _haveFiles(types: any): any {
		if (!types) {
			return false;
		}

		if (types.indexOf) {
			return types.indexOf('Files') !== -1;
		} else if (types.contains) {
			return types.contains('Files');
		} else {
			return false;
		}
	}
}
