/** @jsx jsx */

import React, { Fragment, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import { colors_v2 } from '../../../styles/theme';
import { TranslationEn } from 'assets/i18n/en';
import {
	Button,
	Typography,
	Textarea,
	BnIcon,
	ETypographyColor,
	ETypography,
	Icons,
	SuspenseWrapper,
} from '@bondsports/utils';
import { SavedNote } from './SavedNote';
import { IRawNote, EDocumentType } from '../../../types/notes';
import { useNotes } from '../../../hooks/useNotes';
import { svgIcon, flexRowCss } from '../../../styles/utils';

const notesContainerCss = css`
	padding: 12px;
	padding-bottom: 0px;
`;

const notesContainerWithMargin = css`
	${notesContainerCss}
	margin-bottom: 12px;
`;

const noteEditorContainerCss = css`
	color: ${colors_v2.bg_text_secondary};
	.S {
		margin-top: 0 !important;
	}
`;

const notesHeaderActiveCss = css`
	display: flex;
	justify-content: space-between;
	button {
		padding: 0px;
		color: ${colors_v2.tx_text_primary} !important;

		&:hover {
			text-decoration: underline;
		}
		&:disabled {
			color: ${colors_v2.tx_text_teriaty} !important;
			text-decoration: none;
		}
	}

	svg {
		${svgIcon(16)}
	}
`;

const iconTitleContainer = css`
	${flexRowCss}
	gap: 4px;
`;

interface Props {
	publicNotes: IRawNote[];
	invoiceId: number;
	documentType: EDocumentType;
	setMoreNotesButton?: React.Dispatch<React.SetStateAction<boolean>>; // If no function sent, this is a print
}

export const PublicNotes = ({ publicNotes, invoiceId, documentType, setMoreNotesButton }: Props) => {
	const {
		isLoadingNotes,
		errorMessage,
		ErrorModalDepricated,
		changeInputHandler,
		createAndSaveNoteHandler,
		saveEditedNoteHandler,
		isEditing,
		isFirstNote,
		isShowMore,
		noteText,
		setIsLoadingNotes,
		createDeleteNoteModal,
		toggleDeleteNoteModal,
		setNoteForDelete,
		noteToDelete,
	} = useNotes({ isPublic: true });

	const deleteNoteModal = createDeleteNoteModal(noteToDelete);

	useEffect(() => {
		if (setMoreNotesButton) setMoreNotesButton(publicNotes.length > 1);
	}, [publicNotes, setMoreNotesButton]);

	useEffect(() => {
		if (publicNotes) {
			setIsLoadingNotes(false);
		}
	}, [publicNotes]);

	return (
		<Fragment>
			<SuspenseWrapper loading={isLoadingNotes}>
				<div data-aid="PublicNotes" css={publicNotes[0] ? notesContainerCss : notesContainerWithMargin}>
					{/* Hide when no setMoreNotesButton (aka print) */}
					{setMoreNotesButton && (
						<div data-aid="PublicNotes-edit" css={noteEditorContainerCss}>
							<div css={notesHeaderActiveCss}>
								<div data-aid="PublicNotes-title" css={iconTitleContainer}>
									<BnIcon icon={Icons.note} />

									<Typography
										data-aid="PublicNotes-note"
										color={ETypographyColor.secondary}
										type={ETypography.captionAccented}
									>
										{TranslationEn.components.note}
									</Typography>
								</div>

								<Button
									data-aid="button-PublicNotes"
									sizer="XS"
									theme="basic"
									disabled={!isEditing}
									onClick={createAndSaveNoteHandler.bind(null, documentType, invoiceId)}
								>
									{TranslationEn.season.dashboard.scheduledPopover.add}
								</Button>
							</div>
							<Textarea sizer="M" charCounter charLimit={120} value={noteText} onChange={changeInputHandler} />
						</div>
					)}

					{isFirstNote && setMoreNotesButton && (
						<SavedNote
							key={publicNotes[0].id}
							onSaveEdit={saveEditedNoteHandler}
							note={publicNotes[0]}
							invoiceId={invoiceId}
							documentType={documentType}
							toggleDeleteModal={toggleDeleteNoteModal}
							setNoteForDelete={setNoteForDelete}
						/>
					)}

					{/* No setMoreNotesButton means this is a print */}
					{(isShowMore || !setMoreNotesButton) &&
						publicNotes.map(note => (
							<SavedNote
								key={note.id}
								onSaveEdit={saveEditedNoteHandler}
								note={note}
								invoiceId={invoiceId}
								documentType={documentType}
								toggleDeleteModal={toggleDeleteNoteModal}
								setNoteForDelete={setNoteForDelete}
								isPrinting={!setMoreNotesButton}
							/>
						))}
				</div>
				{deleteNoteModal}
			</SuspenseWrapper>
			<ErrorModalDepricated message={errorMessage} />
		</Fragment>
	);
};
