import React from "react";

export const Diamond = (props: any) => (
  <svg
    data-aid="SvgDiamond"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.61091L3.67587 19.6439C3.46477 19.8299 3.27902 20.0487 3.12751 20.298C2.31335 21.6377 2.79651 23.3508 4.20668 24.1243C5.61685 24.8977 7.42003 24.4387 8.23419 23.099C8.3857 22.8497 8.49228 22.5875 8.55632 22.3208L22.2749 18.8286C25.8262 12.985 23.7187 5.51274 17.5676 2.13891C11.4166 -1.23493 3.5513 0.767255 0 6.61091ZM4.13751 13.2901L5.71897 18.8973C6.20766 18.9028 6.70165 19.0241 7.15503 19.2728C7.60841 19.5215 7.96596 19.8673 8.21539 20.2666L13.8598 18.8297L11.7507 11.3521L4.13751 13.2901ZM3.57409 11.2925L12.2387 9.08684C12.5175 9.01586 12.8146 9.05301 13.0646 9.19014C13.3146 9.32726 13.497 9.55312 13.5717 9.81802L15.9625 18.2945L20.7931 17.0648C23.1584 12.3343 21.3363 6.59402 16.4792 3.92993C11.6221 1.26584 5.47839 2.63683 2.34877 6.94809L3.57409 11.2925Z"
      fill="currentColor"
    />
  </svg>
);
