import React from 'react';

export const Indications_cancelled = (props: any) => (
	<svg data-aid="SvgIndications_cancelled" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 8C0 3.58862 3.58862 0 8 0C12.4114 0 16 3.58862 16 8C16 12.4114 12.4114 16 8 16C3.58862 16 0 12.4114 0 8ZM10.9461 10.9461C11.2068 10.6853 11.2068 10.264 10.9461 10.0033L8.94273 8.00001L10.9461 5.99671C11.2068 5.73597 11.2068 5.3147 10.9461 5.05396C10.6854 4.79334 10.2641 4.79334 10.0034 5.05396L8.0001 7.05738L5.99681 5.05396C5.73607 4.79334 5.3148 4.79334 5.05406 5.05396C4.79344 5.3147 4.79344 5.73597 5.05406 5.99671L7.05748 8.00001L5.05406 10.0033C4.79344 10.264 4.79344 10.6853 5.05406 10.9461C5.18406 11.0761 5.35472 11.1414 5.52549 11.1414C5.69615 11.1414 5.8668 11.0761 5.99681 10.9461L8.0001 8.94263L10.0034 10.9461C10.1334 11.0761 10.3041 11.1414 10.4747 11.1414C10.6455 11.1414 10.8161 11.0761 10.9461 10.9461Z"
			fill="currentColor"
		/>
	</svg>
);
