import React from 'react';

export const TriangleDown = (props: any) => {
	return (
		<svg data-aid="SvgTriangleDown" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M9.1129 20.0024C10.3961 22.2249 13.6039 22.2249 14.8871 20.0024L23.5484 5.0006C24.8315 2.77812 23.2276 3.60965e-06 20.6613 3.60965e-06H3.33871C0.772401 3.60965e-06 -0.831546 2.77811 0.451608 5.0006L9.1129 20.0024Z"
				fill="currentColor"
			/>
		</svg>
	);
};
