/** @jsx jsx */

import React, { useEffect, Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { flexCol, svgIcon, flexRowCss } from '../../../styles/utils';
import { colors } from '../../../styles/theme';
import { TranslationEn } from 'assets/i18n/en';
import {
	Button,
	Typography,
	Textarea,
	BnIcon,
	ETypographyColor,
	ETypography,
	Icons,
	SpinningLoader,
	EBrandingColorsOptions,
	SuspenseWrapper,
} from '@bondsports/utils';
import { SavedNote } from './SavedNote';
import { IRawNote, EDocumentType } from '../../../types/notes';
import { useNotes } from '../../../hooks/useNotes';

const notesContainerCss = css`
	padding: 12px;
	margin: 0px 7px;
	width: 356px;
	textarea {
		width: 100%;
		height: 60px;
	}
	svg {
		${svgIcon(16)}
	}
	hr {
		border-color: ${colors.borderPrimary};
		margin-bottom: 0px;
	}
`;
const noteEditorContainerCss = css`
	${flexCol};
	color: ${colors.formInputBg};
	.S {
		margin-top: 0 !important;
	}
`;

const notesHeaderCss = css`
	display: flex;
	justify-content: space-between;
	button {
		padding-right: 0px !important;
	}
`;

const iconTitleContainer = css`
	${flexRowCss}
	gap: 4px;
`;

const buttonGroup = css`
	display: flex;
	align-items: center;
	align-self: flex-end;
	margin-top: 24px;
	button {
		padding: 10px 12px !important;
		font-size: 14px !important;
	}
`;

interface Props {
	privateNotes: IRawNote[];
	invoiceId: number;
	changeNoteIconHandler: React.Dispatch<React.SetStateAction<boolean>>;
	documentType: EDocumentType;
	toggleDeleteModal: () => void;
	setNoteForDelete?: (noteId: number, documentType: EDocumentType, invoiceId: number) => void;
	onClosePopup: () => void;
}

export const PrivateNotes = ({
	privateNotes,
	changeNoteIconHandler,
	invoiceId,
	documentType,
	toggleDeleteModal,
	setNoteForDelete,
	onClosePopup,
}: Props) => {
	const {
		isLoadingNotes,
		errorMessage,
		ErrorModalDepricated,
		changeInputHandler,
		cancelHandler,
		createAndSaveNoteHandler,
		saveEditedNoteHandler,
		isEditing,
		noteText,
		setIsLoadingNotes,
	} = useNotes({ isPublic: false });

	const labels = TranslationEn.customers.paymentsInvoices.invoiceNotes;

	useEffect(() => {
		changeNoteIconHandler(!!privateNotes[0]);
		if (privateNotes) {
			setIsLoadingNotes(false);
		}
	}, [privateNotes]);

	return (
		<div data-aid="PrivateNotes" css={notesContainerCss}>
			<div css={noteEditorContainerCss}>
				<div css={notesHeaderCss}>
					<div css={iconTitleContainer}>
						<BnIcon icon={Icons.note} />

						<Typography color={ETypographyColor.secondary} type={ETypography.captionAccented}>
							{labels.addNote}
						</Typography>
					</div>

					<Button
						data-aid="button-PrivateNotes-close"
						sizer="XS"
						theme="basic"
						isActive={isEditing}
						onClick={onClosePopup}
					>
						<BnIcon icon={Icons.close} />
					</Button>
				</div>
				<Textarea sizer="M" charCounter charLimit={120} value={noteText} onChange={changeInputHandler} />

				<div css={buttonGroup}>
					<Button data-aid="button-PrivateNotes-cancel" sizer="XS" theme="basic" onClick={cancelHandler}>
						<Typography color={ETypographyColor.secondary} type={ETypography.cta2}>
							{TranslationEn.buttons.cancel}
						</Typography>
					</Button>
					<Button
						data-aid="button-PrivateNotes-add"
						sizer="XS"
						theme="primary"
						disabled={!isEditing}
						onClick={createAndSaveNoteHandler.bind(null, documentType, invoiceId)}
					>
						{labels.add}
					</Button>
				</div>
			</div>
				<SuspenseWrapper color={EBrandingColorsOptions.tx_text_secondary} loading={isLoadingNotes}> 
				<Fragment>
					{privateNotes[0] &&
						privateNotes.map(note => {
							return (
								<SavedNote
								key={note.id}
								onSaveEdit={saveEditedNoteHandler}
								note={note}
								invoiceId={invoiceId}
								documentType={documentType}
								toggleDeleteModal={toggleDeleteModal}
								setNoteForDelete={setNoteForDelete}
								/>
								);
							})}
					{privateNotes[0] && <hr />}
				</Fragment>
			</SuspenseWrapper>
			<ErrorModalDepricated message={errorMessage} />
		</div>
	);
};
