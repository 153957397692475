import React, { useState, useEffect, useCallback } from 'react';
import { paymentApi } from '../lib/api/paymentApi';
import { IRawNote, IFilterObject, EDocumentType } from '../types/notes';
import * as dayjs from 'dayjs';
import { useOrganization } from './useOrganization';
import { useErrorModalDepricated } from '../components/shared/ErrorDepricated';
import { baseStore, ENotificationType } from '../stores/baseStore';
import { useRecoilState } from 'recoil';
import { useNotification } from './useNotification';
import { TranslationEn } from '../../../assets/i18n/en';
import { notesStore } from '../stores/notesStore';
import { Icons, ModalWindow } from '@bondsports/utils';
import { colors_v2 } from '../styles/theme';
import { ModalBody } from '../components/shared/ModalBody';
import { useToggle } from './useToggle';

enum ECrudOps {
	GET_INVOICE_NOTES = 'getInvoiceNotes',
	CREATE_INVOICE_NOTE = 'createInvoiceNote',
	UPDATE_INVOICE_NOTE = 'updateInvoiceNote',
	DELETE_INVOICE_NOTE = 'deleteInvoiceNote',
	GET_PAYMENT_NOTES = 'getPaymentNotes',
	CREATE_PAYMENT_NOTE = 'createPaymentNote',
	UPDATE_PAYMENT_NOTE = 'updatePaymentNote',
	DELETE_PAYMENT_NOTE = 'deletePaymentNote',
}

interface IDeleteNoteData {
	noteId: number;
	documentType: EDocumentType;
	invoiceId: number;
}

interface Props {
	isPublic?: boolean;
	organizationId?: number;
	invoiceId?: number;
	documentType?: EDocumentType;
}
/* This saves code duplication between private and public notes components and share notes logic across the app  */
/* It export logic to process notes on that been fetched from other components, as well abl to fetch   */
/* notes from the db by its own for different crud ops  */
/* The privacyFilter is used to select private/ public notes. if no filer supplied, all notes are fetched  */
export const useNotes = ({ isPublic }: Props) => {
	const { organizationId } = useOrganization();
	const [publicNotes, setPublicNotes] = useRecoilState<IRawNote[]>(notesStore.publicSavedNotes);
	const [privateNotes, setPrivateNotes] = useRecoilState<IRawNote[]>(notesStore.privateSavedNotes);
	const [hasSavedNotes, setHasSavedNotes] = useState<boolean>(false);
	const [isMoreNotes, setIsMoreNotes] = useState<boolean>(false);
	const [isShowMore, setIsShowMore] = useRecoilState<boolean>(baseStore.isOpen);
	const isFirstNote = publicNotes[0] && !isShowMore;
	const [isLoadingNotes, setIsLoadingNotes] = useState<boolean>(true);
	const [noteText, setNoteText] = useState<string>('');
	const isEditing = !!noteText.trim();
	const [errorMessage, setErrorMessage] = useState<string>('');
	const { ErrorModalDepricated, ErrorToggle } = useErrorModalDepricated();
	const { setPopupNotification } = useNotification();
	const [noteToDelete, setNoteToDelete] = useState<IDeleteNoteData | null>(null);
	const [isDeleteModalShown, toggleDeleteNoteModal] = useToggle();
	const FILTER: IFilterObject = { isPublic };
	const labels = TranslationEn.customers.paymentsInvoices.invoiceNotes;

	const fetchAndFormatNotes = async (documentType: EDocumentType, invoiceId: number): Promise<void> => {
		try {
			const rawNotes = await fetchNotesFromDb(documentType, invoiceId);
			sortAndFormatNotes(rawNotes, isPublic);
			setIsLoadingNotes(false);
		} catch (err) {
			setErrorMessage(String(err));
			ErrorToggle();
		}
	};

	const fetchNotesFromDb = async (documentType: EDocumentType, invoiceId: number) => {
		const getNotes = documentType === EDocumentType.INVOICE ? ECrudOps.GET_INVOICE_NOTES : ECrudOps.GET_PAYMENT_NOTES;
		const response = await paymentApi[getNotes](organizationId, invoiceId, FILTER);

		if (Array.isArray(response)) {
			return response;
		} else {
			setPopupNotification(labels.fetchFailed, ENotificationType.warning);
			return [];
		}
	};
	/** @jsx jsx  this function gets notes from the DB and, sort them by last-edit-first,foramt the edit-date for display, split it to public/private and save to Recoil store*/
	const sortAndFormatNotes = (rawNotes?: IRawNote[], isPublic?: boolean): void => {
		if (rawNotes?.length) {
			const notes = [...rawNotes];
			const sortedNotes = sortNotesByDate(notes);
			const formattedNotes = formatDbNotesDateAndName(sortedNotes);

			//when calling this function from outside the hook, isPublick is undefined
			if (isPublic || isPublic === undefined) {
				const publics = formattedNotes.filter(note => note.isPublic);
				setPublicNotes(publics);
			}
			if (!isPublic) {
				const privates = formattedNotes.filter(note => !note.isPublic);
				setPrivateNotes(privates);
			}
		} else {
			setPublicNotes([]);
			setPrivateNotes([]);
		}
		setIsLoadingNotes(false);
	};

	const sortNotesByDate = (notes: IRawNote[]): IRawNote[] => {
		const sortedNotes = notes.sort((a, b) => {
			return Date.parse(b.updatedAt.toString()) - Date.parse(a.updatedAt.toString());
		});
		return sortedNotes;
	};

	const formatDbNotesDateAndName = (rawNotes: IRawNote[]): IRawNote[] => {
		return rawNotes.map(note => {
			return {
				...note,
				createdBy: `${note.user.firstName} ${note.user.lastName}`,
				updatedAt: dayjs(note.updatedAt).format(labels.timeFormat),
			} as IRawNote;
		});
	};

	const toggleShowMoreHandler = () => {
		setIsShowMore(prevState => !prevState);
	};

	const changeInputHandler = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
		setNoteText(ev.target.value);
	};

	const cancelHandler = () => {
		setNoteText('');
	};

	const createAndSaveNoteHandler = async (documentType: EDocumentType, invoiceId: number) => {
		const createNote =
			documentType === EDocumentType.INVOICE ? ECrudOps.CREATE_INVOICE_NOTE : ECrudOps.CREATE_PAYMENT_NOTE;
		const newNote = {
			content: noteText,
			isPublic,
		};
		try {
			await paymentApi[createNote](organizationId, invoiceId, newNote);
			setNoteText('');
			fetchAndFormatNotes(documentType, invoiceId);
		} catch (err) {
			setErrorMessage(String(err));
			ErrorToggle();
		}
	};

	const saveEditedNoteHandler = async (
		note: IRawNote,
		newContent: string,
		documentType: EDocumentType,
		invoiceId: number
	) => {
		const updateNote =
			documentType === EDocumentType.INVOICE ? ECrudOps.UPDATE_INVOICE_NOTE : ECrudOps.UPDATE_PAYMENT_NOTE;
		const NoteEditObject = {
			id: note.id,
			content: newContent,
			isPublic: note.isPublic,
		};

		try {
			await paymentApi[updateNote](organizationId, invoiceId, NoteEditObject);
			fetchAndFormatNotes(documentType, invoiceId);
		} catch (err) {
			setErrorMessage(String(err));
			ErrorToggle();
		}
	};

	const deleteNoteHandler = async (noteId: number, documentType: EDocumentType, invoiceId: number) => {
		const deleteNote =
			documentType === EDocumentType.INVOICE ? ECrudOps.DELETE_INVOICE_NOTE : ECrudOps.DELETE_PAYMENT_NOTE;
		try {
			toggleDeleteNoteModal();
			await paymentApi[deleteNote](organizationId, invoiceId, noteId);
			fetchAndFormatNotes(documentType, invoiceId);
			setPopupNotification(labels.deleteConfirmation, ENotificationType.action_confirmed);
		} catch (err) {
			setErrorMessage(String(err));
			ErrorToggle();
		}
	};

	const setNoteForDelete = (noteId: number, documentType: EDocumentType, invoiceId: number) => {
		setNoteToDelete({ noteId, documentType, invoiceId });
	};

	const createDeleteNoteModal = (noteToDelete: IDeleteNoteData, reopenPopup?: () => void) => {
		if (!noteToDelete) return;
		const { noteId, documentType, invoiceId } = noteToDelete;

		const onDeleteNote = () => {
			deleteNoteHandler(noteId, documentType, invoiceId);
			reopenPopup && reopenPopup();
		};

		const onCancel = () => {
			toggleDeleteNoteModal();
			reopenPopup && reopenPopup();
		};

		return (
			<ModalWindow toggle={toggleDeleteNoteModal} isShowing={isDeleteModalShown}>
				<ModalBody
					iconColor={colors_v2.bg_text_primary}
					icon={Icons.delete_outline}
					title={labels.deleteNoteModal.title}
					description={labels.deleteNoteModal.description}
					mainAction={onDeleteNote}
					mainButton={labels.deleteNoteModal.mainButton}
					subButton={labels.deleteNoteModal.cancelButton}
					subAction={onCancel}
				/>
			</ModalWindow>
		);
	};

	useEffect(() => {
		if (!isPublic) {
			setHasSavedNotes(!!privateNotes[0]);
		}
	}, [isPublic, privateNotes]);

	return {
		publicNotes,
		privateNotes,
		sortAndFormatNotes,
		hasSavedNotes,
		setHasSavedNotes,
		isLoadingNotes,
		errorMessage,
		ErrorModalDepricated,
		changeInputHandler,
		cancelHandler,
		createAndSaveNoteHandler,
		saveEditedNoteHandler,
		isEditing,
		noteText,
		toggleShowMoreHandler,
		isFirstNote,
		isMoreNotes,
		setIsMoreNotes,
		isShowMore,
		setIsShowMore,
		setIsLoadingNotes,
		createDeleteNoteModal,
		toggleDeleteNoteModal,
		isDeleteModalShown,
		setNoteForDelete,
		noteToDelete,
	};
};
