import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';

export enum AddonTimePeriodEnum {
	FULL = 'full',
	SESSION = 'session',
	EVENT = 'event',
}

export type AddOnItem = {
	index: number;
	name: string;
	price: number;
	active: boolean;
	editing: boolean;
	new: boolean;
	timePeriod?: string;
	id?: number;
};

@Component({
	selector: 'rc-add-ons-management',
	templateUrl: './add-ons-management.component.html',
	styleUrls: ['./add-ons-management.component.scss'],
})
export class AddOnsManagementComponent implements OnInit {
	@Input() labelString: string;
	@Input() addOns: AddOnItem[];
	@Input() addonSelection: { id: number; name: string }[] = [];
	@Input() disabled: boolean = false;
	@Output() onAddOnChange = new EventEmitter<AddOnItem[]>();

	addOnToEdit: AddOnItem = {
		index: -1,
		name: '',
		price: null,
		active: false,
		editing: false,
		new: false,
		timePeriod: AddonTimePeriodEnum.FULL,
		id: null,
	};

	nameEditField: string;
	newRecord: boolean;
	selectedProduct: { id: number; name: string };

	constructor(public datePipe: DatePipe) {}

	ngOnInit() {}

	addAddOnBtn = () => {
		this.selectedProduct = { id: null, name: null };
		this.newRecord = true;
		this.addOnToEdit.index = this.addOns.length;
		this.addOnToEdit.editing = true;
		this.addOnToEdit.active = true;
		this.addOnToEdit.new = true;
		this.addOnToEdit.timePeriod = AddonTimePeriodEnum.FULL;
		this.addOnToEdit.id = -1;
		this.addOns.push({ ...this.addOnToEdit });
	};

	editAddOn = (index: number) => {
		this.addOns[index].editing = true;
		this.addOnToEdit = { ...this.addOns[index] };
		this.selectedProduct = { name: this.addOnToEdit.name, id: this.addOnToEdit.id };
		this.addOnToEdit.editing = true;
	};

	doneEditing = () => {
		if (this.selectedProduct) {
			// Prepare AddOnItem to add to array
			this.addOnToEdit.editing = false;
			this.addOnToEdit.active = true;

			const indexToRemove = this.addOns.findIndex(a => a.index === this.addOnToEdit.index);

			// Remove temp obj and add final to constraints
			this.addOns.splice(indexToRemove, 1, { ...this.addOnToEdit });

			// Send to @Output()
			this.onAddOnChange.emit(this.addOns);

			// Reset variables for next edit
			this.resetAddOnDefault();
		}
	};

	cancelEdit = (index: number) => {
		this.addOns[this.addOns.findIndex(c => c.index === index)].editing = false;
		if (this.newRecord) this.addOns.splice(index, 1);
		this.resetAddOnDefault();
	};

	deleteAddOn = (index: number) => {
		this.addOns.splice(index, 1);
		this.onAddOnChange.emit(this.addOns);
		this.resetAddOnDefault();
	};

	resetAddOnDefault = () => {
		this.addOnToEdit.index = -1;
		this.addOnToEdit.name = '';
		this.addOnToEdit.price = null;
		this.addOnToEdit.editing = false;
		this.addOnToEdit.active = false;
		this.addOnToEdit.timePeriod = AddonTimePeriodEnum.FULL;
		this.nameEditField = '';
		this.newRecord = false;
		this.addOnToEdit.id = null;
	};

	priceChanged = (newPrice: number) => {
		this.addOnToEdit.price = newPrice;
	};

	onNameChange = (newNameId: number) => {
		this.addOnToEdit.id = newNameId;
		this.addOnToEdit.name = this.addonSelection.find(addon => addon.id === newNameId).name;
	};

	onTimePeriodChange = (newTimePeriod: string) => {
		this.addOnToEdit.timePeriod = newTimePeriod;
	};

	onActiveChange = () => {
		this.onAddOnChange.emit(this.addOns);
	};
}
