/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext } from 'react';
import { jsx } from '@emotion/react';
import { ArchiveNote } from '../../../molecules/notes/ArchiveNote';
import { NotesPopupContext } from '../../../store/notes';
import { NoteDto } from '../../../types/entities/notes';

export type ArchiveNoteStepProps = {
	note: NoteDto;
	onArchived: (note: NoteDto) => void;
	onCancel: () => void;
};

export const ArchiveNoteStep: React.FC<ArchiveNoteStepProps> = ({ note, onArchived, onCancel }) => {
	const { labels, ArchiveComponent } = useContext(NotesPopupContext);

	const onArchivedNoteHandler = () => {
		onArchived(note);
	};

	if (ArchiveComponent) {
		return <ArchiveComponent onClickedArchive={onArchivedNoteHandler} onCancel={onCancel} labels={labels!.archive} />;
	}

	return <ArchiveNote onClickedArchive={onArchivedNoteHandler} onCancel={onCancel} labels={labels!.archive} />;
};
