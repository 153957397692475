import React from 'react';

export const Indications_rejected = (props: any) => (
	<svg data-aid="SvgIndications_rejected" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM4.9 7.2C4.40294 7.2 4 7.60294 4 8.1C4 8.59706 4.40294 9 4.9 9H11.1C11.5971 9 12 8.59706 12 8.1C12 7.60294 11.5971 7.2 11.1 7.2H4.9Z"
			fill="currentColor"
		/>
	</svg>
);
