import React from 'react';

export const Addons = (props: any) => (
  <svg
    data-aid="svgAddons"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.04999 5.04999V8.94994H8.94994V5.04999H5.04999ZM2.95001 4.99999C2.95001 3.86782 3.86782 2.95001 4.99999 2.95001H8.99994C10.1321 2.95001 11.0499 3.86782 11.0499 4.99999V8.99994C11.0499 10.1321 10.1321 11.0499 8.99994 11.0499H4.99999C3.86782 11.0499 2.95001 10.1321 2.95001 8.99994V4.99999Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.04999 15.0482V18.9481H8.94994V15.0482H5.04999ZM2.95001 14.9982C2.95001 13.866 3.86782 12.9482 4.99999 12.9482H8.99994C10.1321 12.9482 11.0499 13.866 11.0499 14.9982V18.9981C11.0499 20.1303 10.1321 21.0481 8.99994 21.0481H4.99999C3.86782 21.0481 2.95001 20.1303 2.95001 18.9981V14.9982Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0501 15.0482V18.9481H18.95V15.0482H15.0501ZM12.9501 14.9982C12.9501 13.866 13.8679 12.9482 15.0001 12.9482H19C20.1322 12.9482 21.05 13.866 21.05 14.9982V18.9981C21.05 20.1303 20.1322 21.0481 19 21.0481H15.0001C13.8679 21.0481 12.9501 20.1303 12.9501 18.9981V14.9982Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9501 6.99996C12.9501 6.42007 13.4202 5.94998 14.0001 5.94998H20C20.5799 5.94998 21.05 6.42007 21.05 6.99996C21.05 7.57986 20.5799 8.04995 20 8.04995H14.0001C13.4202 8.04995 12.9501 7.57986 12.9501 6.99996Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0001 2.95001C17.58 2.95001 18.05 3.42011 18.05 4V9.99993C18.05 10.5798 17.58 11.0499 17.0001 11.0499C16.4202 11.0499 15.9501 10.5798 15.9501 9.99993V4C15.9501 3.42011 16.4202 2.95001 17.0001 2.95001Z"
      fill="currentColor"
    />
  </svg>
);
