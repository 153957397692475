/** @jsx jsx */
/** @jsxRuntime classic */
import React, { Fragment, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { IRadioProps, Radio } from '@bondsports/utils';
import { FlowLayout } from '../ModalBody/FlowLayout';
import { RadioGroups } from './RadioGroups';
import { ILabels, IRadioGroup } from './types';

const containerCss = (isGroups: boolean) => css`
	display: flex;
	flex-direction: column;
	padding: 24px 40px 0 40px;
	padding-bottom: ${isGroups ? '18px' : '0'};
	gap: 20px;
	label {
		margin: 0;
	}
	max-height: ${isGroups ? '450px' : 'none'};
	overflow: ${isGroups ? 'scroll' : 'none'};
`;

interface Props {
	labels: ILabels;
	handleActionButton: (val: string | number) => void;
	radios?: IRadioProps[];
	radioGroups?: IRadioGroup[];
	handleBack?: () => void;
	defaultValue?: string | number;
	hideSeparator?: boolean;
	handleSubButton?: () => void;
}

export const RadioModalBody = ({
	labels,
	handleActionButton,
	radios,
	radioGroups,
	handleBack,
	defaultValue,
	hideSeparator = true,
}: Props) => {
	const [value, setValue] = useState<string | number>(defaultValue);

	const handleRadioAction = () => {
		handleActionButton(value);
	};

	return (
		<FlowLayout
			handleBack={handleBack}
			title={labels.title}
			description={labels.description}
			actionButtonText={labels.actionButtonText}
			handleActionButton={handleRadioAction}
			subButtonText={labels.subButtonText}
			handleSubButton={handleBack}
			width={'440px'}
			hideSeparator={hideSeparator}
			footerPaddingBottom={'32px'}
			actionButtonsSize="M"
			actionButtonDisabled={!value}
		>
			<div css={containerCss(Boolean(radioGroups))}>
				{radioGroups ? (
					<RadioGroups radioGroups={radioGroups} value={value} setValue={setValue} />
				) : (
					<Fragment>
						{radios?.map(arg => {
							return <Radio key={arg.value} {...arg} checked={value === arg.value} setChecked={setValue} />;
						})}
					</Fragment>
				)}
			</div>
		</FlowLayout>
	);
};
