// function that will serve the entire utils

export const capitalize = (s: string) => {
	if (typeof s !== 'string') {
		return s;
	}
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatPhoneNumber = (input: string): string => {
	// Extract digits from the string.
	let digits = input.replace(/\D/g, '');

	// Pad with zeros or trim to 10 digits.
	if (digits.length > 10) {
		digits = digits.substring(0, 10);
	} else {
		while (digits.length < 10) {
			digits = '0' + digits;
		}
	}

	const areaCode = digits.substring(0, 3);
	const prefix = digits.substring(3, 6);
	const lineNumber = digits.substring(6, 10);
	return `(${areaCode}) ${prefix}-${lineNumber}`;
};
