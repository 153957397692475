import { useTheme } from '@emotion/react';
import React from 'react';
import { StyleCheckLabel } from './style';

interface Props {
  label: string;
  handleClick: () => void;
  active: boolean;
}

export const CheckLabel = ({ label, handleClick, active }: Props) => {
  const colors = useTheme();
  return (
    <StyleCheckLabel
      onClick={handleClick}
      isActive={active}
      theme={{ ...colors }}
    >
      {label}
    </StyleCheckLabel>
  );
};
