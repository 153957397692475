/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { flexCss, flexDirectionColumnCss, marginLeftCss } from 'app/react/styles/utils';
import {
	BnProvider,
	Input,
	ModalWindow,
	Select,
	Typography,
	ETypography,
	ETypographyColor,
	IOption,
} from '@bondsports/utils';
import { TranslationEn } from 'assets/i18n/en';
import { CustomModalBody } from 'app/react/components/shared/CustomModalBody';
import GoogleAddress from 'app/react/components/shared/GoogleMap/Address';
import { useToggle } from 'app/react/hooks/useToggle';
import { RecoilRoot } from 'recoil';

const containerCss = css`
	width: 400px;
`;

interface UpdateBankDetailsModalProps {
	currencies: IOption[];
	visible?: boolean;
	//  send the modal data to the angular
	onSubmit?: (obj: BankDetailsData) => boolean;
}

interface BankDetailsData {
	owner: string;
	routing_number: string;
	address: string;
	account_number: string;
	currency: string;
}

const UpdateBankDetailsModal = ({ currencies, visible, onSubmit }: UpdateBankDetailsModalProps) => {
	const labels = TranslationEn.bank.modal;
	const [isShowing, toggle] = useToggle();
	const [currency, selectCurrency] = useState<string>();
	const [owner, setOwner] = useState<string>();
	const [accNumber, setAccNumber] = useState<string>();
	const [routeNumber, setRouteNumber] = useState<string>();
	const [address, setAddress] = useState<string>();

	const submit = () => {
		const success = onSubmit({
			owner,
			routing_number: routeNumber,
			address,
			account_number: accNumber,
			currency,
		});
		if (success) {
			toggle();
		}
	};

	const handleOnClose = () => {
		toggle();
	};

	useEffect(() => {
		if (visible) {
			toggle();
		}
	}, [visible]);

	return (
		<RecoilRoot>
			<BnProvider>
				<ModalWindow toggle={handleOnClose} isShowing={isShowing} padding={0}>
					<CustomModalBody
						title={labels.title}
						htmlSubTitle={
							<span>
								{labels.subTitlePart1} <a href="mailto:support@bondsports.co">support@bondsports.co</a>{' '}
								{labels.subTitlePart2}
							</span>
						}
						main={
							<div css={containerCss}>
								<div css={[flexCss, flexDirectionColumnCss]}>
									<div css={[marginLeftCss(16)]}>
										<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
											{labels.owner}
										</Typography>
									</div>
									<div css={[marginLeftCss(16)]}>
										<Input
											data-aid="UpdateBankDetailsModal-input-owner"
											sizer="S"
											value={owner || ''}
											onChange={event => {
												setOwner(event.target.value);
											}}
										/>
									</div>
								</div>
								<div css={[flexCss, flexDirectionColumnCss]}>
									<div css={[marginLeftCss(16)]}>
										<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
											{labels.accNumber}
										</Typography>
									</div>
									<div css={[marginLeftCss(16)]}>
										<Input
											data-aid="UpdateBankDetailsModal-input-account"
											sizer="M"
											value={accNumber || ''}
											onChange={event => {
												setAccNumber(event.target.value);
											}}
										/>
									</div>
								</div>
								<div css={[flexCss, flexDirectionColumnCss]}>
									<div css={[marginLeftCss(16)]}>
										<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
											{labels.routeNumber}
										</Typography>
									</div>
									<div css={[marginLeftCss(16)]}>
										<Input
											data-aid="UpdateBankDetailsModal-input-route"
											sizer="M"
											value={routeNumber || ''}
											onChange={event => {
												setRouteNumber(event.target.value);
											}}
										/>
									</div>
								</div>
								<div css={[flexCss, flexDirectionColumnCss]}>
									<div css={[marginLeftCss(16)]}>
										<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
											{labels.currency}
										</Typography>
									</div>
									<div data-aid="UpdateBankDetailsModal-select-currency" css={[marginLeftCss(16)]}>
										<Select
											// throwing strange issue that IOption[] is different from IOption[]
											options={currencies}
											isTopLayer
											value={String(currency)}
											onSelecte={(val: string) => {
												selectCurrency(val);
											}}
										/>
									</div>
								</div>
								<div css={[flexCss, flexDirectionColumnCss]}>
									<div css={[marginLeftCss(16)]}>
										<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
											{labels.address}
										</Typography>
									</div>
									<div data-aid="UpdateBankDetailsModal-input-address" css={[marginLeftCss(16)]}>
										<GoogleAddress
											onChange={data => {
												setAddress(data.value.description);
											}}
										/>
									</div>
								</div>
							</div>
						}
						mainAction={submit}
						mainButton={labels.mainButton}
						subButton={labels.closeButton}
						subAction={toggle}
					/>
				</ModalWindow>
			</BnProvider>
		</RecoilRoot>
	);
};

export default UpdateBankDetailsModal;
