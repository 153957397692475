import { Fragment } from 'react';
import { EBrandingColorsOptions, ETypography, ETypographyColor } from '../../types/theme';
// import './index.scss';
import { css } from '@emotion/react';
import { Loader as SpinningLoader } from '../Loader';
import React from 'react';
import { Typography } from '../../atoms';
import { LoadingWithSpinner } from '../../molecules/Loading/LoadingWithSpinner';

export const gapCss = (gap: number) => css`
	gap: ${gap}px;
`;

export const flexCss = css`
	display: flex;
`;

export const fitContentCss = css`
	width: fit-content;
`;

export const flexColCss = css`
	${flexCss};
	flex-direction: column;
`;

export const flexRowCss = css`
	${flexCss};
	flex-direction: row;
`;

export const alignItemsCss = (how: string) => css`
	align-items: ${how};
`;

export const justifyContentCss = (how: string) => css`
	justify-content: ${how};
`;

export const flexGrowCss = (howMuch: number) => css`
	flex-grow: ${howMuch};
`;

export const fullHeightCss = css`
	height: 100%;
`;

export const SuspenseWrapper = ({
	color = EBrandingColorsOptions.tx_white,
	width = 50,
	margin = '0 30px',
	loading,
	labels = { title: '', description: '' },
	children,
}: {
	color?: EBrandingColorsOptions;
	width?: number;
	margin?: string;
	children?: React.ReactNode;
	loading?: boolean;
	labels?: { title?: string; description?: string };
	contentOnEmpty?: string;
} & React.HTMLAttributes<HTMLDivElement>) => {
	if (labels.title || labels.description) {
		return <Fragment>{loading ? <LoadingWithSpinner labels={labels} /> : children}</Fragment>;
	}

	return (
		<Fragment>
			{loading ? <SpinningLoader width={width} margin={margin} /> : children}
		</Fragment>
	);
};
