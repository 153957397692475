/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { BnIcon, Button, Icons, SizeEnum } from '@bondsports/utils';
import { useToggle } from '../../hooks/useToggle';
import { colors_v2 } from '../../styles/theme';
import { svgIcon, flexCol } from '../../styles/utils';

const sortButtonCss = css`
	${flexCol}
	svg {
		${svgIcon(14)}
	}
`;

const sortIconCss = (isActive: boolean) => css`
	color: ${isActive ? colors_v2.bg_text_primary : colors_v2.bg_text_secondary};
`;

interface Props {
	sortFunction: (isAscending: boolean) => void;
	idSelector: string;
	isInitialAscending?: boolean;
	isCurrentSort?: boolean;
	isDisabled?: boolean;
}

export const SortButton = ({
	sortFunction,
	idSelector,
	isInitialAscending = false,
	isCurrentSort,
	isDisabled,
}: Props) => {
	const [isAscending, toggleAscending] = useToggle(isInitialAscending);

	const sortHandler = () => {
		sortFunction(isAscending);
		toggleAscending();
	};

	return (
		<Button
			data-aid="button-SortButton-basic"
			theme="basic"
			sizer={SizeEnum.SMALL}
			disabled={isDisabled}
			onClick={sortHandler}
			id={idSelector}
		>
			<div css={sortButtonCss}>
					<BnIcon icon={isAscending ? Icons.arrow_top : Icons.arrow_bottom} css={sortIconCss(isCurrentSort)} />
			</div>
		</Button>
	);
};
