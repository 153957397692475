/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { BnIcon } from '../../../components/icons_v2';
import { ETypography, ETypographyColor, IColors, Icons } from '../../../types';
import { Typography } from '../../../atoms';
import dayjs from 'dayjs';
import { useColors } from '../../../hooks/useColors';
import { formatDateTimeTz } from '../utils/date-formatting.util';

const containerCss = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
	height: 84px;
`;

const headerCss = (colors: IColors) => css`
	display: flex;
	align-items: center;
	gap: 4px;
	svg {
		height: 16px;
		width: 16px;
		color: ${colors.tx_text_teriaty};
	}
`;

export const Note = ({ state, organizationTimeZone }: { state: any; organizationTimeZone: string }) => {
	const { colors } = useColors();
	const { formatDate } = formatDateTimeTz(organizationTimeZone);
	return (
		<div css={containerCss} data-aid="noteCard-notes">
			<div css={headerCss(colors)}>
				<BnIcon icon={Icons.note_filled} />
				<Typography color={ETypographyColor.secondary} type={ETypography.captionAccented}>
					Note
				</Typography>
				<Typography color={ETypographyColor.teriaty} type={ETypography.caption}>
					{formatDate(state.updatedAt)}
				</Typography>
			</div>
			<div>
				<Typography color={ETypographyColor.primary} type={ETypography.body2}>
					{state.content}
				</Typography>
			</div>
		</div>
	);
};

const borderCss = (colors: IColors) => css`
	height: 1px;
	width: 100%;
	background: ${colors.bg_border_seperator};
	margin-bottom: 20px;
`;

export const NoteBorder = () => {
	const { colors } = useColors();

	return <div css={borderCss(colors)} />;
};
