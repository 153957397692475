import * as  React from 'react';

export const BnExpandAll = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10.0994L8 13.9999L4 10.0994L4.78567 9.33325L8 12.4676L11.2143 9.33325L12 10.0994Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5.90061L8 2.00008L12 5.90061L11.2143 6.66675L8 3.53235L4.78567 6.66675L4 5.90061Z"
      fill="currentColor"
    />
  </svg>
);
