import React from 'react';

export const Percent = (props: any) => (
  <svg
    data-aid="svgPercent"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.12974 17.3799C4.74061 17.1508 4.62439 16.671 4.87016 16.3083L13.9802 2.86211C14.2259 2.49937 14.7406 2.39103 15.1297 2.62013C15.5189 2.84923 15.6351 3.32901 15.3893 3.69175L6.2793 17.1379C6.03354 17.5006 5.51886 17.609 5.12974 17.3799Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83431 10.0264C7.67526 10.0264 9.16764 8.63521 9.16764 6.91909C9.16764 5.20297 7.67526 3.81178 5.83431 3.81178C3.99336 3.81178 2.50098 5.20297 2.50098 6.91909C2.50098 8.63521 3.99336 10.0264 5.83431 10.0264ZM5.83431 8.47275C6.75478 8.47275 7.50098 7.77715 7.50098 6.91909C7.50098 6.06103 6.75478 5.36544 5.83431 5.36544C4.91384 5.36544 4.16764 6.06103 4.16764 6.91909C4.16764 7.77715 4.91384 8.47275 5.83431 8.47275Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.501 13.9105C17.501 15.6267 16.0086 17.0179 14.1676 17.0179C12.3267 17.0179 10.8343 15.6267 10.8343 13.9105C10.8343 12.1944 12.3267 10.8032 14.1676 10.8032C16.0086 10.8032 17.501 12.1944 17.501 13.9105ZM15.8343 13.9105C15.8343 14.7686 15.0881 15.4642 14.1676 15.4642C13.2472 15.4642 12.501 14.7686 12.501 13.9105C12.501 13.0525 13.2472 12.3569 14.1676 12.3569C15.0881 12.3569 15.8343 13.0525 15.8343 13.9105Z"
      fill="currentColor"
    />
  </svg>
);
