import * as  React from 'react';

export const BnVertical = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.78418 3.6V16.4C2.78418 17.2837 3.52159 18 4.43124 18H7.72535C8.635 18 9.37241 17.2837 9.37241 16.4V3.6C9.37241 2.71635 8.635 2.00001 7.72535 2.00001H4.43124C3.52159 2.00001 2.78418 2.71635 2.78418 3.6ZM4.24251 16.4V3.6C4.24251 3.57459 4.25095 3.54555 4.28274 3.51467C4.31527 3.48306 4.36505 3.45834 4.43124 3.45834H7.72535C7.79154 3.45834 7.84132 3.48306 7.87385 3.51467C7.90565 3.54555 7.91408 3.57459 7.91408 3.6V16.4C7.91408 16.4254 7.90565 16.4545 7.87385 16.4853C7.84132 16.5169 7.79154 16.5417 7.72535 16.5417H4.43124C4.36505 16.5417 4.31527 16.5169 4.28274 16.4853C4.25095 16.4545 4.24251 16.4254 4.24251 16.4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6273 3.6V16.4C10.6273 17.2837 11.3647 18 12.2744 18H15.5685C16.4781 18 17.2155 17.2837 17.2155 16.4V3.6C17.2155 2.71634 16.4781 2 15.5685 2H12.2744C11.3647 2 10.6273 2.71634 10.6273 3.6ZM12.0856 16.4V3.6C12.0856 3.57459 12.0941 3.54555 12.1259 3.51466C12.1584 3.48305 12.2082 3.45833 12.2744 3.45833H15.5685C15.6347 3.45833 15.6844 3.48305 15.717 3.51466C15.7488 3.54555 15.7572 3.57459 15.7572 3.6V16.4C15.7572 16.4254 15.7488 16.4545 15.717 16.4853C15.6844 16.5169 15.6347 16.5417 15.5685 16.5417H12.2744C12.2082 16.5417 12.1584 16.5169 12.1259 16.4853C12.0941 16.4545 12.0856 16.4254 12.0856 16.4Z"
      fill="currentColor"
    />
  </svg>
);
