import { useRef, useState } from 'react';
import { getHiddenValues } from './utils';
import { IOption } from '..';

export const useSelectedTags = () => {
	const [isShowMore, setIsShowMore] = useState(true);
	const [hiddenValues, setHiddenValues] = useState<IOption[]>([]);
	const [render, rerender] = useState(false);
	const expandableDivRef = useRef<HTMLInputElement>(null);

	const handleSetHiddenValues = (selectedValues: IOption[]) => {
		if (expandableDivRef.current) {
			const hiddenValues = getHiddenValues(expandableDivRef.current, selectedValues);
			setHiddenValues(hiddenValues);
		}
	};

	const toggleShowMore = () => setIsShowMore(prevState => !prevState);

	return {
		isShowMore,
		setIsShowMore,
		hiddenValues,
		render,
		rerender,
		expandableDivRef,
		handleSetHiddenValues,
		toggleShowMore,
	};
};
