import { ReactNode, Fragment } from 'react';
import { atom, AtomEffect, DefaultValue, selector } from 'recoil';

const isNavigation = atom<boolean>({
	key: 'isNavigation',
	default: false,
});

const isRightWindow = atom<boolean>({
	key: 'isRightWindow',
	default: false,
});

const isLeftWindow = atom<boolean>({
	key: 'isLeftWindow',
	default: false,
});

const showCart = atom<boolean>({
	key: 'showCart',
	default: false,
});

const isCart = atom<boolean>({
	key: 'isCart',
	default: false,
});

const leftRenderContent = atom<ReactNode>({
	key: 'leftRenderContent',
	default: Fragment,
});

const isLeftWindowCollapsable = atom<boolean>({
	key: 'isLeftWindowCollapsable',
	default: false,
});

const isLeftExpanded = atom<boolean>({
	key: 'isLeftExpanded',
	default: false,
});

const isSubLeftExpanded = atom<boolean>({
	key: 'isSubLeftExpanded',
	default: false,
});

const isDisableScreenShown = atom<boolean>({
	key: 'isDisableScreenShown',
	default: false,
});

export const layoutStore = {
	isNavigation,
	isRightWindow,
	isLeftWindow,
	isSubLeftExpanded,
	showCart,
	isCart,
	leftRenderContent,
	isLeftExpanded,
	isLeftWindowCollapsable,
	isDisableScreenShown,
};
