import { TranslationEn } from '@assets/i18n/en';
import { PaymentMethodTypeEnum } from '@bondsports/types';

export const filterPaymentMethods = Object.values(PaymentMethodTypeEnum)
	.filter(p => p !== PaymentMethodTypeEnum.VOID)
	.map(value => {
		return {
			label: TranslationEn.filterPaymentMethods[value],
			value,
		};
	});
