import React, { Fragment, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { ModalWindow } from '@bondsports/utils';
import { StepFlowContext } from './context';

export const StepsWrapper = ({
	steps,
	onClose,
	isModal = false,
	modalProps = [],
	dismissible = true,
	padding,
}: {
	steps: any[];
	onClose: (closeOthers?: boolean) => void;
	isModal?: boolean;
	modalProps?: any;
	dismissible?: boolean;
	padding?: number;
}) => {
	const nestedProps = useContext(StepFlowContext);
	const [activeStep, setActiveStep] = useState(0);
	const [isShowing, setShowing] = useState(true);
	const [stateProps, setStateProps] = useState({});
	const [selectedPath, setSelectedPath] = useState<string>();

	const handleStateProps = v => {
		setStateProps(v);
	};
	const toggle = () => setShowing(prev => !prev);

	const nextStep = (optionalPath?: string) => {
		if (optionalPath) {
			setSelectedPath(optionalPath);
		} else {
			setSelectedPath(null);
		}
		if (!isNextStepAvailable) {
			if (nestedProps?.isNextStepAvailable) {
				nestedProps.nextStep();
			} else {
				onClose(true);
			}
		} else {
			setActiveStep(prev => prev + 1);
		}
	};
	const previousStep = () => {
		if (!isPreviousStepAvailable) {
			if (nestedProps?.isPreviousStepAvailable) {
				nestedProps.previousStep();
			} else {
				onClose(false);
			}
		} else {
			setActiveStep(prev => prev - 1);
		}
	};

	const isNextStepAvailable = activeStep + 1 !== steps.length;
	const isPreviousStepAvailable = activeStep !== 0;

	const relevantProps = modalProps?.[activeStep] ?? stateProps;

	const DummaryWrapper = useCallback(
		({ children, ...props }: { children: ReactNode } & any) => {
			return <Fragment>{children}</Fragment>;
		},
		[activeStep]
	);

	const Wrapper = isModal ? ModalWindow : DummaryWrapper;

	useEffect(() => {
		return () => {
			setActiveStep(0);
			setShowing(false);
			setStateProps({});
			setSelectedPath('');
		};
	}, []);

	return (
		<StepFlowContext.Provider
			value={{
				stepsCount: steps.length,
				nextStep,
				previousStep,
				isNextStepAvailable,
				isPreviousStepAvailable,
				activeStep,
				handleStateProps,
				onClose: () => {
					onClose(true);
					toggle();
				},
			}}
		>
			<Wrapper isShowing={isShowing} toggle={onClose} padding={padding} {...relevantProps}>
				{selectedPath ? steps[activeStep][selectedPath] : steps[activeStep]}
			</Wrapper>
		</StepFlowContext.Provider>
	);
};
