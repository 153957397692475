/** @jsx jsx */
/** @jsxRuntime classic */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { ColorPalette } from './ColorPalette';
import SelectRadix from '..';
import { useEffect, useState } from 'react';
import { ColorDetails } from './ColorDetails';
import { TComponentSize } from '../types';
import { IColorCode } from './types';

interface Props {
	value: string;
	colorsCodes: IColorCode[];
	size: TComponentSize;
	onSelect: (value: string) => void;
}

export const ColorPicker = (props: Props) => {
	const [selectedColor, setSelectedColor] = useState<IColorCode>();

	const handleSelect = (value: string) => {
		props.onSelect(value ?? undefined);
	};

	useEffect(() => {
		const selectedColor = props.colorsCodes.find(color => color.id === +props.value);
		setSelectedColor(selectedColor);
	}, [props.value]);

	return (
		<SelectRadix
			{...props}
			value={props.value}
			onSelect={handleSelect}
			controlLabel="Color picker"
			customItems={<ColorPalette colorsCodes={props.colorsCodes} />}
			isDisableScrollbars
			customValue={<ColorDetails colorId={selectedColor?.baseValue || ''} name={selectedColor?.name || ''} />}
		/>
	);
};
