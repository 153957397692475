import React from 'react';

export const Pilatis = (props: any) => (
  <svg
    data-aid="svgPilatis"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5448 18.3858C7.71994 18.3858 4.34438 16.9044 2.36418 14.6617C2.1336 13.8616 2.00099 12.9427 2 11.9931C2 11.8792 2 11.7683 2 11.6564C4.28105 13.7992 7.70609 15.1696 11.5359 15.1696C15.8753 15.1696 19.6912 13.413 21.9139 10.7642C21.9654 11.1296 21.996 11.5554 22 11.9881C22 12.2545 22 12.5129 21.9664 12.7684C20.4631 16.0311 16.3602 18.3858 11.5438 18.3858H11.5448ZM11.5448 13.7824C15.9565 13.7824 19.7704 11.8119 21.5448 8.97396C21.1974 7.8986 20.7145 6.96386 20.1059 6.12714C18.6145 8.72344 15.3449 10.4999 11.5448 10.4999C8.09797 10.4999 5.07274 9.03832 3.4191 6.8589C2.88768 7.73126 2.47402 8.74423 2.23652 9.82256C4.16922 12.2228 7.62692 13.7774 11.5438 13.7774L11.5448 13.7824ZM11.5448 4.41905C13.2113 4.41905 14.6195 3.58531 15.0252 2.47331C14.1178 2.17328 13.0737 2 11.9891 2C10.6195 2 9.3142 2.27626 8.12568 2.77532C8.71351 3.71799 10.0168 4.41311 11.5448 4.41311V4.41905ZM11.5448 19.775C11.5012 19.775 11.4498 19.776 11.3993 19.776C8.55319 19.776 5.89609 18.9611 3.65067 17.552C5.53389 20.2652 8.56606 22 12.003 22C15.9495 22 19.3617 19.7126 20.9886 16.3905C18.7343 18.4303 15.337 19.775 11.5448 19.775ZM11.5448 9.11556C15.0727 9.11556 18.0722 7.40054 19.1648 5.02703C18.3602 4.19824 17.4161 3.50906 16.3711 2.99614C15.6873 4.61016 13.7892 5.80731 11.5448 5.80731C9.48342 5.80731 7.71697 4.79533 6.95596 3.36152C5.90797 3.98336 5.01831 4.74879 4.28105 5.64294C5.55863 7.69759 8.33647 9.10962 11.5458 9.10962L11.5448 9.11556Z"
      fill="currentColor"
    />
  </svg>
);
