import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProgramsService } from '@app/shared/services/programs/programs.service';
import SeasonDashboard from 'app/react/pages/seasonDashboard/SeasonDashboard';
import React from 'react';
import ReactDOM from 'react-dom';

@Component({
	selector: 'rc-season-dashboard-wrapper',
	template: `<div #reactSeasonDashboard></div>`,
	encapsulation: ViewEncapsulation.Emulated,
})
export class SeasonDashboardComponent implements OnDestroy, AfterViewInit, OnInit {
	@ViewChild('reactSeasonDashboard', { static: true }) containerRef: ElementRef;
	organizationId: number;
	seasonId: number;
	constructor(private activeRoute: ActivatedRoute, private programService: ProgramsService) {}
	ngOnInit() {
		this.organizationId = this.programService.getOrgId();
	}
	ngOnDestroy() {
		ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
	}
	ngAfterViewInit() {
		this.activeRoute.parent.params.subscribe(params => {
			this.seasonId = params.seasonId;
			this.render();
		});
	}
	private render() {
		if (!this.containerRef) return;
		ReactDOM.render(
			React.createElement(SeasonDashboard, {
				organizationId: this.organizationId,
				seasonId: this.seasonId,
			}),
			this.containerRef.nativeElement
		);
	}
}
