/** @jsxRuntime classic */
/** @jsx jsx */
import { colors } from '../../../styles/theme';
import { flex } from '../../../styles/utils';
import React, { useMemo } from 'react';
import { jsx, css } from '@emotion/react';
import { BnIcon, Icons } from '@bondsports/utils';

const containerCss = css`
	${flex};
	height: 48px;
	width: 48px;
	padding: 12px;
	justify-content: center;
	border-radius: 2px;
	margin: 10px;
	background: ${colors.formControlBg};
`;

const iconCss = css`
	height: 24px !important;
	width: 24px !important;
`;

export const ResourceThumbnail = ({
	resourceType = 'membership',
}: {
	resourceType: 'membership' | 'goods' | 'programSeason' | 'leagueSeason' | 'space' | 'registration';
}) => {
	const { icon } = useMemo(() => {
		let iconAsset;
		switch (resourceType) {
			case 'membership':
				iconAsset = Icons.membership;
				break;
			case 'programSeason':
			case 'leagueSeason':
			case 'registration':
				iconAsset = Icons.whistle;
				break;
			case 'space':
				iconAsset = (Icons as any).court;
				break;
			case 'goods':
			default:
				iconAsset = Icons.pos_equipment;
				break;
		}
		return { icon: iconAsset };
	}, [resourceType]);

	return (
		<div css={containerCss}>
			<BnIcon css={iconCss} icon={icon} />
		</div>
	);
};
