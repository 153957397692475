import { customersApi } from '@app/react/lib/api/customersApi';
import { ICustomer, ICustomerResponse, ILinkedAccount } from '@app/react/types/customers';
import { IErrorArr } from '@app/react/types/errors';

//REFACTOR-REQUIRED: this is a temp solution until a dedicated api would be ready.
//with the new api, all this data would already be part of the linkedAccounts array
export const getConnectedMembers = async (customerState: ICustomer, handleError?: (message: string) => void) => {
	if (customerState.linkedAccounts[0]) {
		const res = await customersApi.getCustomerById(customerState.organizationId, customerState.linkedAccounts[0]);
		if (!res || (res as IErrorArr).err) {
			handleError(String((res as IErrorArr).err));
			return [];
		}
		const linkedMembers: ILinkedAccount[] = [];
		const data = (res as ICustomerResponse).data;
		if (data) {
			const familyData = {
				customerId: data.id,
				customerName: data.name,
				isFamily: true,
			};
			linkedMembers.push(familyData);

			data.members?.forEach(member => {
				if (member.id !== customerState.id) {
					const { id, firstName, lastName, familyStatus } = member;
					const memberData = {
						customerId: id,
						customerName: `${firstName} ${lastName}`,
						familyStatus: familyStatus,
						isFamily: false,
					};
					linkedMembers.push(memberData);
				}
			});
		}
		return linkedMembers;
	}
	return [];
};
