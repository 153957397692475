/** @jsxRuntime classic */
/** @jsx jsx */
import { FC, Fragment } from 'react';
import { OpenNoteProps } from '../../types';
import { Row } from '../../../../atoms/layoutPrimitives';
import { Typography } from '../../../../atoms';
import { ETypography, ETypographyColor, Icons } from '../../../../types';
import { Tooltip } from '../../../../components/Tooltip';
import { BnIcon } from '../../../../components/icons_v2';
import { Textarea } from '../../../../components/Input/Textarea';
import { jsx } from '@emotion/react';

export const OpenNote: FC<OpenNoteProps> = ({ openNote, onChange, value, labels }) => (
	<Fragment>
		<Row gap="0.5rem" fullWidth>
			<Typography color={ETypographyColor.secondary} type={ETypography.captionAccented}>
				{openNote}
			</Typography>
			<Tooltip isTopLayer content={labels.tooltip}>
				<BnIcon icon={Icons.info} />
			</Tooltip>
		</Row>
		<Textarea sizer="S" charLimit={120} charCounter onChange={onChange} value={value} style={{ marginTop: 0 }} />
	</Fragment>
);
