/** @jsxRuntime classic */
/** @jsx jsx */

import { FC } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { jsx } from '@emotion/react';
import { IWeeklyDraggableProps } from '../../types';
import { useMiddlewareContext } from '../../hooks/useMiddlewareContext';

export const WeeklyDraggable: FC<IWeeklyDraggableProps> = ({ id, state, parentId, children }) => {
	const elementId = `draggable-${id}`;

	const { options } = useMiddlewareContext();

	const { setNodeRef, listeners, attributes } = useDraggable({
		id: String(id),
		data: {
			parentId: parentId,
			event: state,
			weeklyView: true,
		},
	});

	if (options.dragAndDrop) {
		return (
			<div {...attributes} {...listeners} id={elementId} ref={setNodeRef} css={{ cursor: 'grab' }}>
				{children}
			</div>
		);
	} else {
		return <div id={elementId}>{children}</div>;
	}
};
