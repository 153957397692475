/* @jsx jsx */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { textAlignCss } from 'app/react/components/customers/activities/styles';
import { jsx, css } from '@emotion/react';
import { useWindowSize } from '../../../hooks/useWindowResize';
import { flexRowCss, fullWidthCss, gapCss, justifyContentCss } from '../../../styles/utils';
import { ETypography, ETypographyColor, Tooltip, TPlacements, Typography } from '@bondsports/utils';
import { EllipsisContainer } from '../EllipsisContainer';

const maxWidthCss = (width: number) => css`
	width: ${width}px;
`;

type EllipsisWithTooltipContainerProps = {
	text: string;
	color?: ETypographyColor;
	type?: ETypography;
	disableTooltip?: boolean;
	placement?: TPlacements;
	gap?: number;
};

export const EllipsisWithTooltipContainer: React.FC<EllipsisWithTooltipContainerProps> = ({
	text,
	color,
	type,
	disableTooltip,
	placement,
	gap,
}) => {
	const [isTruncated, setIsTruncated] = useState(false);
	const [maxWidth, setMaxWidth] = useState(100);
	const resourcesTextRef: React.MutableRefObject<HTMLSpanElement> = useRef();
	const containerRef: React.MutableRefObject<HTMLDivElement> = useRef();

	const { width: windowWidth } = useWindowSize();

	useEffect(() => {
		const divElement = containerRef.current;

		if (divElement) {
			setMaxWidth(divElement.offsetWidth - (gap || 0));
		}
	}, [gap, windowWidth]);

	useEffect(() => {
		if (resourcesTextRef.current) {
			setIsTruncated(resourcesTextRef.current.offsetWidth > maxWidth);
		}
	}, [maxWidth]);

	const TextComponent = useCallback(() => {
		return (
			<div
				ref={containerRef}
				css={[flexRowCss, gapCss(8), fullWidthCss, justifyContentCss('space-between'), textAlignCss('end')]}
			>
				<Typography type={type ?? ETypography.body2} color={color ?? ETypographyColor.primary}>
					<span css={maxWidthCss(maxWidth)}>
						<EllipsisContainer justify="end">
							<span ref={resourcesTextRef}>{text}</span>
						</EllipsisContainer>
					</span>
				</Typography>
			</div>
		);
	}, [type, color, maxWidth, text]);

	return (
		<div ref={containerRef} css={[flexRowCss, gapCss(8), fullWidthCss, justifyContentCss('space-between')]}>
			{isTruncated && !disableTooltip ? (
				<Tooltip content={text} placement={placement}>
					<TextComponent />
				</Tooltip>
			) : (
				<TextComponent />
			)}
		</div>
	);
};
