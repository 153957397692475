/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { headerCss } from './styles';
import { Typography } from '../../../../atoms';
import { ETypographyColor, ETypography } from '../../../../types';

interface headerWithLabelsProps {
	labels: {
		title: string;
		subtitle?: string;
	};
}

export const ModalHeaderWithPrimaryAndSecondaryLabels = ({ labels }: headerWithLabelsProps) => {
	return (
		<div css={headerCss} data-aid="modal-layout-header-with-labels">
			<Typography color={ETypographyColor.primary} type={ETypography.h1}>
				{labels.title}
			</Typography>
			{labels.subtitle ? (
				<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
					{labels.subtitle}
				</Typography>
			) : null}
		</div>
	);
};
