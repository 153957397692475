/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { IColumn } from './types';
import { ETypography, ETypographyColor, IColors, Icons } from '../../types';
import { useColors } from '../../hooks/useColors';
import { Typography } from '../../atoms';
import { SkeletonWrapper } from '../../components/skeletonWrapper';
import { Pricify } from '../../lib/price';
import { Button } from '../../components/Button';
import { BnIcon } from '../../components/icons_v2';
import { PaymentMethodMapper } from '../invoicing/payments/PaymentMethodMapper';
import { Status, StatusWithIcon } from './cellTypes/Status';
import { FeesMapper } from '../invoicing/scheduledPayments/feesMapper';

export const rowCss = (
	isHoverRow: boolean,
	isSubRow: boolean,
	subHeaderBottomBorder: boolean,
	colors: IColors,
	borderBottom = true
) => css`
	td {
		padding: 6px !important;
		border-bottom: ${(isSubRow && subHeaderBottomBorder) || !borderBottom
			? '0 !important'
			: `1px solid ${colors.bg_border_primary} !important`};
	}

	td:first-child {
		padding-left: 16px !important;
	}

	td:last-child {
		padding-right: 16px !important;
	}

	&:hover {
		td {
			background-color: ${isHoverRow ? colors.bg_background_secondary : 'transparent'};
		}
	}

	tr {
		background: ${isSubRow ? colors.bg_background_secondary : colors.bg_white};
	}
`;

const subRowCss = (colors: IColors) => css`
	background: ${colors.bg_background_fields};
`;

const dateWithRangeWrapper = css`
	display: flex;
	flex-direction: column;
`;

const pageBreakCss = css`
	break-inside: avoid !important;
`;

const customCss = css`
	> div {
		display: flex;
		align-items: center;
	}
`;

const iconHoverCss = css`
	:hover {
		cursor: pointer;
	}
`;

const ExpandCollapseButton = ({
	row,
	expandableKey,
	setIsExpanded,
	isExpanded,
	expandOn,
	column,
}: {
	row: any;
	expandableKey: string;
	setIsExpanded: (v: boolean) => void;
	isExpanded: boolean;
	expandOn: string[];
	column: IColumn;
}) => {
	return (
		<Fragment>
			{expandableKey &&
				setIsExpanded &&
				row[expandableKey] &&
				row[expandableKey].length > 0 &&
				expandOn?.find(e => e === column.id) && (
					<Button data-aid="button-tableRow-expand" sizer="S" theme="basic" onClick={() => setIsExpanded(!isExpanded)}>
						{isExpanded ? <BnIcon icon={Icons.dropdown_active} /> : <BnIcon icon={Icons.dropdown} />}
					</Button>
				)}
		</Fragment>
	);
};

export function Row<T>({
	columns,
	state,
	isHoverRow,
	isLoading = false,
	expandable,
	expandableKey,
	expandOn,
	isExpand = false,
	subRows = [],
	subRowsColumns,
}: {
	columns: IColumn[];
	state: T & { id?: number };
	isHoverRow: boolean;
	isLoading?: boolean;
	isExpand?: boolean;
	expandOn: string[];
	expandableKey: string;
	expandable: boolean;
	subRows?: T[];
	subRowsColumns?: IColumn[];
}) {
	const { colors } = useColors();
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	useEffect(() => {
		setIsExpanded(isExpand);
	}, [isExpand]);

	return (
		<Fragment>
			<tr
				tabIndex={-1}
				key={state.id}
				css={[pageBreakCss, rowCss(isHoverRow, false, false, colors, !Boolean(subRows?.length))]}
			>
				{columns.map((column, index) => {
					return (
						<CellMapper
							expandableKey={expandableKey}
							setIsExpanded={(v: boolean) => setIsExpanded(v)}
							isExpanded={isExpanded}
							expandOn={expandOn}
							key={index}
							isLoading={isLoading}
							column={column}
							state={state}
						/>
					);
				})}
			</tr>
			{/* add expandable functionality */}
			{subRowsColumns &&
				subRows?.map((subRow: T, sindex: any) => {
					return (
						<tr
							tabIndex={-1}
							key={sindex}
							css={[pageBreakCss, rowCss(false, false, false, colors, sindex === subRows.length - 1)]}
						>
							{subRowsColumns.map((column, index) => {
								return <CellMapper key={index} isLoading={isLoading} column={column} state={subRow} />;
							})}
						</tr>
					);
				})}
			{/* add expandable functionality */}
			{isExpanded &&
				expandable &&
				state[expandableKey] &&
				state[expandableKey].map((subRow: T, sindex: any) => {
					return (
						<tr
							tabIndex={-1}
							key={state.id}
							css={[pageBreakCss, rowCss(isHoverRow, false, false, colors), subRowCss(colors)]}
						>
							{columns.map((column, index) => {
								return <CellMapper key={sindex} isLoading={isLoading} column={column} state={subRow} />;
							})}
						</tr>
					);
				})}
		</Fragment>
	);
}

const BaseTdWrapper = ({
	isLoading,
	column,
	children,
	row,
	expandableKey,
	expandOn,
	isExpanded,
	setIsExpanded,
}: {
	isLoading: boolean;
	column: IColumn;
	children: ReactNode;
	expandableKey: string;
	setIsExpanded: (v: boolean) => void;
	isExpanded: boolean;
	expandOn: string[];
	row: any;
}) => {
	return (
		<td align={column?.styling?.align ? (column?.styling?.align === 'right' ? 'right' : 'left') : 'center'}>
			<SkeletonWrapper isLoading={isLoading} minHeight={6} minWidth={50}>
				<Fragment>
					<Typography type={ETypography.body2} color={ETypographyColor.primary}>
						{isLoading ? '-' : children}
					</Typography>
					<ExpandCollapseButton {...{ row, expandableKey, expandOn, isExpanded, setIsExpanded, column }} />
				</Fragment>
			</SkeletonWrapper>
		</td>
	);
};

const DateWithTimeRange = ({ row, column, isLast }: { row: any; column: any; isLast?: boolean }) => {
	return (
		<div css={dateWithRangeWrapper} data-aid="row-table">
			<Typography color={ETypographyColor.primary} type={ETypography.body2}>
				{row.daysTimes?.dateRange}
			</Typography>
			<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
				{row.daysTimes?.timeRange}
			</Typography>
		</div>
	);
};

const CellMapper = ({
	column,
	state,
	isLoading = false,
	expandableKey = '',
	setIsExpanded = () => {},
	isExpanded = false,
	expandOn = [],
}: {
	column: IColumn;
	state: any;
	isLoading?: boolean;
	expandableKey?: string;
	setIsExpanded?: (v: boolean) => void;
	isExpanded?: boolean;
	expandOn?: string[];
}) => {
	const expandProps = {
		row: state,
		expandableKey,
		setIsExpanded,
		isExpanded,
		expandOn,
	};

	if (column.type === 'icon') {
		return (
			<BaseTdWrapper isLoading={isLoading} column={column} {...expandProps}>
				<BnIcon css={iconHoverCss} icon={column.id} />
			</BaseTdWrapper>
		);
	}

	if (column.type === 'string') {
		return (
			<BaseTdWrapper isLoading={isLoading} column={column} {...expandProps}>
				{state?.[column.id]}
			</BaseTdWrapper>
		);
	}
	if (column.type === 'currency') {
		return (
			<BaseTdWrapper isLoading={isLoading} column={column} {...expandProps}>
				{Pricify(state?.[column.id], undefined, undefined, true)}
			</BaseTdWrapper>
		);
	}
	if (column.type === 'dateWithTimeRange') {
		return (
			<BaseTdWrapper isLoading={isLoading} column={column} {...expandProps}>
				<DateWithTimeRange row={state} column={column} isLast={false} />
			</BaseTdWrapper>
		);
	}
	if (column.type === 'custom') {
		return (
			<td
				align={column?.styling?.align ? column?.styling?.align : 'left'}
				className={`TableCell-root TableCell-body ${column.id}`}
				key={column.id}
				css={customCss}
				onClick={() => {
					if (column.action) {
						column.action(state);
					}
				}}
				// css={[
				//     cellCss(column.action, isLast),
				//     // (row[expandableKey] && row[expandableKey].length > 0 && expandOn.find(e => e === column.id))
				// ?? flex, ]}
			>
				<div>
					{(column as { component: (a: unknown, b?: unknown) => JSX.Element }).component(state[column.id])}
					<ExpandCollapseButton {...expandProps} column={column} />
				</div>
			</td>
		);
	}

	if (column.type === 'paymentMethod' || column.type === 'paymentMethodWithIcon') {
		return (
			<BaseTdWrapper isLoading={isLoading} column={column} {...expandProps}>
				<PaymentMethodMapper state={state} />
			</BaseTdWrapper>
		);
	}

	if (column.type === 'fee') {
		return (
			<BaseTdWrapper isLoading={isLoading} column={column} {...expandProps}>
				<FeesMapper state={state} />
			</BaseTdWrapper>
		);
	}

	if (column.type === 'feeNoIcon') {
		return (
			<BaseTdWrapper isLoading={isLoading} column={column} {...expandProps}>
				<FeesMapper state={state} noIcons />
			</BaseTdWrapper>
		);
	}

	if (column.type === 'status') {
		return (
			<BaseTdWrapper isLoading={isLoading} column={column} {...expandProps}>
				<Status column={column} row={state} />
			</BaseTdWrapper>
		);
	}

	if (column.type === 'statusWithIcon') {
		return (
			<BaseTdWrapper isLoading={isLoading} column={column} {...expandProps}>
				<StatusWithIcon column={column} row={state} />
			</BaseTdWrapper>
		);
	}
	return <Fragment />;
};
