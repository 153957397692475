import * as  React from 'react';

export const BnView = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7297 5.50359C17.7057 5.31659 21.2297 10.8906 21.8677 12.0026C22.0437 12.3106 22.0437 12.6896 21.8677 12.9976C21.0137 14.4866 17.7977 19.3556 12.2707 19.4966C12.1887 19.4986 12.1067 19.4996 12.0247 19.4996C6.16169 19.4996 2.76169 14.0946 2.13269 12.9976C1.95569 12.6896 1.95569 12.3106 2.13269 12.0026C2.98669 10.5136 6.20169 5.64359 11.7297 5.50359ZM12.2197 17.4976C7.91369 17.5976 5.10469 13.9146 4.17269 12.4956C5.19869 10.8906 7.78269 7.60459 11.7807 7.50259C16.0697 7.39359 18.8947 11.0856 19.8267 12.5046C18.8017 14.1096 16.2167 17.3956 12.2197 17.4976ZM8.49999 12.5C8.49999 10.57 10.07 8.99999 12 8.99999C13.93 8.99999 15.5 10.57 15.5 12.5C15.5 14.43 13.93 16 12 16C10.07 16 8.49999 14.43 8.49999 12.5ZM10.5 12.5C10.5 13.327 11.173 14 12 14C12.827 14 13.5 13.327 13.5 12.5C13.5 11.673 12.827 11 12 11C11.173 11 10.5 11.673 10.5 12.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7297 5.50359C17.7057 5.31659 21.2297 10.8906 21.8677 12.0026C22.0437 12.3106 22.0437 12.6896 21.8677 12.9976C21.0137 14.4866 17.7977 19.3556 12.2707 19.4966C12.1887 19.4986 12.1067 19.4996 12.0247 19.4996C6.16169 19.4996 2.76169 14.0946 2.13269 12.9976C1.95569 12.6896 1.95569 12.3106 2.13269 12.0026C2.98669 10.5136 6.20169 5.64359 11.7297 5.50359ZM12.2197 17.4976C7.91369 17.5976 5.10469 13.9146 4.17269 12.4956C5.19869 10.8906 7.78269 7.60459 11.7807 7.50259C16.0697 7.39359 18.8947 11.0856 19.8267 12.5046C18.8017 14.1096 16.2167 17.3956 12.2197 17.4976ZM8.49999 12.5C8.49999 10.57 10.07 8.99999 12 8.99999C13.93 8.99999 15.5 10.57 15.5 12.5C15.5 14.43 13.93 16 12 16C10.07 16 8.49999 14.43 8.49999 12.5ZM10.5 12.5C10.5 13.327 11.173 14 12 14C12.827 14 13.5 13.327 13.5 12.5C13.5 11.673 12.827 11 12 11C11.173 11 10.5 11.673 10.5 12.5Z"
      fill="currentColor"
    />
  </svg>
);
