import React, { ComponentType, Fragment, memo, useEffect, useRef } from 'react';
import { GridChildComponentProps } from 'react-window';

export type DecoratedCellProps<T> = T & {
	rowIndex: number;
	columnIndex: number;
};
export function createCell<TInnerProps>(
	CellComponent: ComponentType<TInnerProps>,
	onSetSize: (rowIndex: number, columnIndex: number, size: { height: number; width: number }) => void
): ComponentType<GridChildComponentProps<TInnerProps[]>> {
	return memo(({ data, columnIndex, rowIndex, style }) => {
		const ref = useRef<HTMLDivElement | null>(null);
		const isShowCell = Boolean(data[rowIndex][columnIndex]);

		useEffect(() => {
			if (ref?.current) {
				onSetSize(rowIndex, columnIndex, getElementSizeWithMargins(ref.current) ?? { height: 100, width: 100 });
			}
		}, [ref]);

		const props: DecoratedCellProps<TInnerProps> = {
			...data[rowIndex][columnIndex],
			rowIndex: rowIndex,
			columnIndex: columnIndex,
		};

		return (
			<div style={style} data-testid={`infinite-grid-cell-${rowIndex}-${columnIndex}`}>
				<div ref={ref} style={{ display: 'inline-block' }}>
					{isShowCell ? <CellComponent {...(props as DecoratedCellProps<TInnerProps>)} /> : <Fragment />}
				</div>
			</div>
		);
	});
}

const getElementSizeWithMargins = (node: HTMLDivElement): { height: number; width: number } => {
	const { offsetHeight, offsetWidth } = node;
	const styles = window.getComputedStyle(node);

	return {
		height: Math.ceil(offsetHeight + parseFloat(styles['margin-top']) + parseFloat(styles['margin-bottom'])),
		width: Math.ceil(offsetWidth + parseFloat(styles['margin-left']) + parseFloat(styles['margin-right'])),
	};
};
