import React from 'react';

export const Amex = (props: any) => (
	<svg data-aid="SvgAmex" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path fillRule="evenodd" clipRule="evenodd" d="M4.00439 4V20H19.9997V12.7204V4H4.00439Z" fill="#006FCF" />
		<mask id="mask0_612_33380" maskUnits="userSpaceOnUse" x="4" y="8" width="16" height="8">
			<path d="M4 8.96265V15.516H20V8.96265H4Z" fill="white" />
		</mask>
		<g mask="url(#mask0_612_33380)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.0396 11.7581H19.2406V8.96216H17.9588V9.40928L17.6735 8.96216H16.556V9.52754L16.3056 8.96216H14.4583C14.3696 8.96216 14.2829 8.9687 14.199 8.98179C14.1276 8.99299 14.0581 9.00899 13.9911 9.02994C13.9384 9.04638 13.8872 9.06587 13.8379 9.08841C13.7801 9.11503 13.7247 9.14572 13.6722 9.18077V9.08463V8.96216H7.6432L7.47903 9.44972L7.31195 8.96216H5.90915V9.5335L5.65599 8.96216H4.51159L3.99988 10.1581V11.5092V11.7581H4.81041L4.9612 11.3568H5.24665L5.4002 11.7581H11.6365V11.3514L11.9489 11.7581H13.6722V11.6686V11.5547C13.7167 11.5835 13.7629 11.6087 13.8099 11.6306C13.857 11.6525 13.9051 11.6711 13.9538 11.6868C14.014 11.7064 14.0752 11.7213 14.1362 11.7323C14.2356 11.7504 14.3347 11.7581 14.4287 11.7581H15.46L15.6108 11.3568H15.8963L16.0497 11.7581H17.7785V11.3432L18.0396 11.7581ZM19.9998 15.3435V12.7201H19.8598H10.0745L9.82134 13.0783L9.57108 12.7201H6.71677V15.5157H9.56018L9.80796 15.1521L10.0637 15.5157H11.8436V14.9125H11.8248C12.0462 14.9125 12.2473 14.8725 12.4254 14.8063V15.5157H13.6666V15.1092L13.979 15.5157H19.3644C19.5194 15.5157 19.6646 15.4894 19.7949 15.4421C19.8682 15.4155 19.9368 15.3823 19.9998 15.3435Z"
				fill="white"
			/>
			<mask id="mask1_612_33380" maskUnits="userSpaceOnUse" x="7" y="13" width="14" height="3">
				<path d="M20.0112 15.0606V13.1755H7.15601V15.0606H20.0112Z" fill="white" />
			</mask>
			<g mask="url(#mask1_612_33380)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M19.3756 14.6837H18.4089V15.0608H19.362C19.7688 15.0608 20.0111 14.813 20.0111 14.4602C20.0111 14.1236 19.793 13.9271 19.4268 13.9271H18.9906C18.8827 13.9271 18.8075 13.857 18.8075 13.7467C18.8075 13.6335 18.8827 13.5582 18.9906 13.5582H19.809L19.9762 13.1758H19.004C18.5972 13.1758 18.355 13.4236 18.355 13.7628C18.355 14.1102 18.584 14.3094 18.9422 14.3094H19.3756C19.4832 14.3094 19.5613 14.3794 19.5613 14.4926C19.5613 14.6082 19.4832 14.6837 19.3756 14.6837ZM17.5823 14.6837H16.6157V15.0608H17.5687C17.9755 15.0608 18.2179 14.813 18.2179 14.4602C18.2179 14.1236 17.9997 13.9271 17.6334 13.9271H17.1972C17.0895 13.9271 17.0141 13.857 17.0141 13.7467C17.0141 13.6335 17.0895 13.5582 17.1972 13.5582H18.0157L18.1828 13.1758H17.2106C16.804 13.1758 16.5616 13.4236 16.5616 13.7628C16.5616 14.1102 16.7906 14.3094 17.1488 14.3094H17.5823C17.6899 14.3094 17.7679 14.3794 17.7679 14.4926C17.7679 14.6082 17.6899 14.6837 17.5823 14.6837ZM16.341 13.5582V13.1758H14.8331V15.0608H16.341V14.681H15.2855V14.3014H16.3167V13.9244H15.2855V13.5582H16.341ZM13.8152 13.5608C13.9767 13.5608 14.063 13.6658 14.063 13.7952C14.063 13.9244 13.9767 14.0266 13.8152 14.0266H13.2847V13.5608H13.8152ZM13.2847 14.3821H13.4975L14.0602 15.0608H14.623L13.9956 14.366C14.3161 14.3067 14.5072 14.0752 14.5072 13.7736C14.5072 13.4208 14.2568 13.1758 13.8556 13.1758H12.8323V15.0608H13.2847V14.3821ZM12.1322 13.8085C12.1322 13.9353 12.0515 14.0401 11.8925 14.0401H11.3513V13.5771H11.8925C12.0515 13.5771 12.1322 13.6793 12.1322 13.8085ZM10.8989 13.1758V15.0608H11.3513V14.4385H11.9194C12.3315 14.4385 12.5872 14.1694 12.5872 13.8059C12.5872 13.4317 12.3369 13.1758 11.9356 13.1758H10.8989ZM10.2338 15.0608H10.7993L10.0426 14.1102L10.7939 13.1758H10.2447L9.78144 13.7736L9.32106 13.1758H8.75555L9.50951 14.1182L8.75817 15.0608H9.30754L9.77068 14.4548L10.2338 15.0608ZM8.66394 13.5582V13.1758H7.15601V15.0608H8.66394V14.681H7.60853V14.3014H8.6398V13.9244H7.60853V13.5582H8.66394Z"
					fill="#006FCF"
				/>
			</g>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.4122 10.174L17.4069 10.0178L17.5011 10.174L18.2226 11.3022H18.7506V9.41724H18.309V10.5078L18.3144 10.6479L18.2309 10.5078L17.5252 9.41724H16.9708V11.3022H17.4122V10.174ZM15.6082 10.1202L15.7455 9.76739L15.8826 10.1202L16.0499 10.5375H15.4439L15.6082 10.1202ZM16.3567 11.3022H16.8655L16.0606 9.41724H15.4653L14.6577 11.3022H15.1396L15.2985 10.8983H16.1951L16.3567 11.3022ZM14.383 11.3022H14.4073L14.593 10.901H14.4557C14.146 10.901 13.9629 10.7072 13.9629 10.3733V10.3464C13.9629 10.0475 14.1271 9.82396 14.4503 9.82396H14.9404V9.41724H14.4127C13.831 9.41724 13.5133 9.80782 13.5133 10.3437V10.3759C13.5133 10.9361 13.8392 11.2996 14.383 11.3022ZM12.7755 11.3022H13.2278V10.4726V9.41724H12.7755V10.4619V11.3022ZM11.8249 9.80244C11.9864 9.80244 12.0727 9.90742 12.0727 10.0367C12.0727 10.166 11.9864 10.2682 11.8249 10.2682H11.2944V9.80244H11.8249ZM11.2944 10.6237H11.5072L12.0701 11.3022H12.6328L12.0054 10.6074C12.3258 10.5483 12.5171 10.3166 12.5171 10.0152C12.5171 9.6624 12.2665 9.41724 11.8653 9.41724H10.8421V11.3022H11.2944V10.6237ZM10.5109 9.79967V9.41724H9.00301V11.3022H10.5109V10.9172H9.45539V10.5429H10.4867V10.166H9.45539V9.79967H10.5109ZM6.76002 10.0152L6.74911 9.50623L7.27158 11.3022H7.68092L8.20324 9.51699L8.19248 10.0124V11.3022H8.63134V9.41724H7.83433L7.47618 10.7529L7.11541 9.41724H6.32102V11.3022H6.76002V10.0152ZM4.9585 10.1202L5.09591 9.76739L5.23318 10.1202L5.40012 10.5375H4.79433L4.9585 10.1202ZM5.70708 11.3022H6.21603L5.41088 9.41724H4.81585L4.00793 11.3022H4.48998L4.64891 10.8983H5.54553L5.70708 11.3022Z"
				fill="#006FCF"
			/>
		</g>
	</svg>
);
