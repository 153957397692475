/** @jsx jsx */

import React, { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { AccountLink } from './AccountLink';
import { ILinkedAccount } from '../../../../types/customers';

interface Props {
	linkedAccounts: ILinkedAccount[];
}

export const LikedAccountsList = ({ linkedAccounts }: Props) => {
	return (
		<Fragment>
			{linkedAccounts.map(account => {
				return <AccountLink key={account.customerId} details={account} />;
			})}
		</Fragment>
	);
};
