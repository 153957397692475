import { ReactNode } from 'react';

export type TCell =
	| 'custom'
	| 'currency'
	| 'string'
	| 'dateWithTimeRange'
	| 'icon'
	| 'paymentMethod'
	| 'status'
	| 'statusWithIcon'
	| 'paymentMethodWithIcon'
	| 'fee'
	| 'feeNoIcon'
	| 'date';

export interface IColumn {
	id: string;
	type: TCell;
	label: string;
	numeric?: boolean;
	disablePadding?: boolean;
	styling?: StylingType;
	component?: (a: unknown, b?: unknown) => JSX.Element;
	action?: (a: unknown, b?: unknown) => void;
}

export enum AlignType {
	LEFT = 'left',
	CENTER = 'center',
	RIGHT = 'right',
}

export interface StylingType {
	align?: AlignType;
}

export interface ITableProps<T> {
	columns: IColumn[];
	rows: (T & { id?: number })[];
	pagination?: boolean;
	maxHeight?: number;
	bottomBorder?: boolean;
	removeRowSeparator?: boolean;
	isLoading?: boolean;
	footer?: ReactNode;
	isHover?: boolean;
	isExpand?: boolean;
	expandOn?: string[];
	expandableKey?: string;
	expandable?: boolean;
	additionalInfoRow?: string;
	subRowsColumns?: IColumn[];
}

export interface IInvoiceRowSubRow {
	text: string;
	price: string;
	total: string;
}
