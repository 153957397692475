/** @jsxRuntime classic */
/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/react';
import { AnswerDto, QuestionDto } from '@bondsports/types';
import { getAnswerText } from '../../../lib/formats/answer';
import { FormQuestion } from './FormQuestion';
import { ETypography, ETypographyColor } from '../../../types';
import { Typography } from '../../../atoms';
import { i18n } from '../../../lib/i18n';
import { sectionCss } from '../styles';

interface Props {
	answers: AnswerDto[];
	questions: QuestionDto[];
}

export const TemplateForm: FC<Props> = (props: Props) => {
	const { answers, questions } = props;

	const labels = i18n.applicationTemplate;

	const metadataMap = questions.reduce((acc, question) => {
		acc[question.id] = question.metadata;
		return acc;
	}, {});

	const answersMap = answers.reduce((acc, answer) => {
		acc[answer.questionId] = getAnswerText(answer, metadataMap[answer.questionId]);
		return acc;
	}, {});

	return (
		<div css={sectionCss}>
			<Typography color={ETypographyColor.primary} type={ETypography.h4}>
				{labels.formTitle}
			</Typography>
			<div className="questions-container">
				{questions
					.filter(({ id }) => answersMap[id])
					.map((question: QuestionDto) => (
						<FormQuestion question={question} answer={answersMap[question.id]} key={question.id} />
					))}
			</div>
		</div>
	);
};
