import { useState } from 'react';

interface PaginationState {
	currentPage: number;
	totalPages: number;
}

interface PaginationActions {
	goToPage: (page: number) => void;
	nextPage: () => void;
	previousPage: () => void;
}

const usePagination = (totalPages: number, initialPage: number = 1) => {
	const [currentPage, setCurrentPage] = useState(initialPage);

	const goToPage = (page: number) => {
		if (page >= 1 && page <= totalPages) {
			setCurrentPage(page);
		}
	};

	const goToNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	const goToPreviousPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	const resetPage = () => {
		setCurrentPage(initialPage);
	};

	return {
		currentPage,
		goToPage,
		nextPage: currentPage + 1,
		goToNextPage,
		goToPreviousPage,
		isHasMore: totalPages > currentPage,
		resetPage,
	};
};

export default usePagination;
