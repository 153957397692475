/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { FormInputWraper } from './FormInputWrapper';
import { IFormInputWraper } from '../../familyAccount/formBody/types';
import { containerCss } from './styles';

export const FormSectionInputs = ({ detailsInputs }: { detailsInputs: IFormInputWraper[] }) => {
	return (
		<div css={containerCss}>
			{detailsInputs.map(details => {
				return <FormInputWraper key={details.name} {...details} />;
			})}
		</div>
	);
};
