import styled from "@emotion/styled";
import { IColors } from "../../types/theme";
// .label{
//     font-family: Montserrat;
//     font-style: normal;
//     font-weight: 500;
//     color: ${colors.tx_text_secondary};
//     margin-bottom:8px;
//     padding:0px;
//     display: flex;
//     align-items: center;
//   }
export const PickerContainer = styled.div<any>(
  ({ theme: colors }: { theme: IColors }) => `
  
  margin: 16px 0 1px 0;

    .label{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      color: ${colors.tx_text_secondary};
      margin-bottom:8px;
      padding:0px;
      display: flex;
      align-items: center;   
    }
    .wrapper{
        display:flex;
        flex-wrap: wrap;
    }
    &.M{
        .label{
          font-size: 14px;
          line-height: 17px;
        }
        .helper{
          font-size: 14px;
          line-height: 17px;
        }
        svg{
          width: 20px;
          height: 20px;
        }
      }
      &.S{
        .label{
          font-size: 12px;
          line-height: 15px;
        }
        .helper{
          font-size: 12px;
          line-height: 15px;
        }
      }
    `
);

export const PickerItemComp = styled.div<any>(
  ({ theme: colors, isOutline }: { theme: IColors; isOutline: boolean }) => `
    padding: 3px 8px;
    margin-right: 4px;
    margin-bottom: 6px;
    background: ${isOutline ? colors.bg_white : colors.bg_background_primary};
    color: ${colors.tx_text_teriaty};
    border: 1px solid ${isOutline ? colors.bg_border_primary : "transparent"};
    border-radius: 2px;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 130%;
    cursor: pointer;
    font-family: Montserrat;

    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2rem;
    svg{
      margin-right:4px;
      height:20px;
      width:20px;
    }
  &.active {
    
    background: ${isOutline ? colors.bg_white : colors.bg_text_primary};
    color: ${isOutline ? colors.bg_text_primary : colors.tx_white};
    border: 1px solid ${isOutline ? colors.bg_text_primary : "transparent"};
  }
`
);
