import React from 'react';

export const Intermediate = (props: any) => (
  <svg
    data-aid="svgIntermediate"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.7158 15.2963C15.1088 14.5839 15.7632 14.2603 16.5485 14.2603C17.3338 14.2603 17.792 13.8715 17.792 13.0303C17.792 12.2535 18.1851 11.6062 18.8394 11.2175C19.5596 10.8287 19.6906 10.2466 19.2976 9.53425C18.9045 8.82187 18.9045 8.1102 19.2976 7.39783C19.7558 6.7506 19.6247 6.16781 18.9045 5.77905C18.1843 5.39028 17.8572 4.74306 17.8572 3.96624C17.8572 3.18942 17.4641 2.73622 16.6136 2.73622C15.8283 2.73622 15.174 2.34746 14.7809 1.70023C14.3879 0.987855 13.7994 0.858268 13.0792 1.24703C12.359 1.63579 11.6395 1.63579 10.9193 1.24703C10.1991 0.793828 9.67581 0.987855 9.21763 1.63579C8.8246 2.34817 8.17027 2.67178 7.38492 2.73622C6.59957 2.73622 6.14139 3.12498 6.14139 3.96624C6.14139 4.74306 5.74836 5.39028 5.09402 5.77905C4.37382 6.16781 4.24281 6.74989 4.63584 7.46227C5.02887 8.17464 5.02887 8.88631 4.63584 9.59869C4.24281 10.3111 4.37382 10.8287 5.09402 11.2819C5.81422 11.6707 6.14139 12.3179 6.14139 13.0947C6.14139 13.8715 6.53442 14.3247 7.38492 14.3247C8.17027 14.3247 8.8246 14.7135 9.21763 15.3607C9.61067 16.0731 10.1991 16.2027 10.9193 15.8139C11.6395 15.4252 12.359 15.4252 13.0792 15.8139C13.7336 16.1375 14.3228 16.0079 14.7158 15.2963ZM11.9667 12.9007C9.47966 12.9007 7.51593 10.8939 7.51593 8.49826C7.51593 6.03822 9.5448 4.09583 11.9667 4.09583C14.4538 4.09583 16.4175 6.10266 16.4175 8.49826C16.4826 10.8939 14.4538 12.9007 11.9667 12.9007ZM7.31977 14.9075C6.46856 14.9075 6.33826 14.5188 6.33826 14.5188L3 21.0576L5.68393 20.734L7.18948 22.9356C7.18948 22.9356 10.2657 16.5263 10.2657 16.5907C8.62916 16.5263 9.28421 14.972 7.32049 14.9075H7.31977ZM17.5959 14.7779C15.174 15.0371 15.6321 15.6199 15.1088 16.0731C14.4545 16.7203 13.6691 16.5907 13.6691 16.5907L17.4656 23L18.3819 20.6044L21 20.928L17.5966 14.7772L17.5959 14.7779ZM12.9489 7.5267L12.0326 5.64946L11.1162 7.5267L9.02148 7.85032L10.527 9.33951L10.134 11.3463L12.0319 10.3755L13.8646 11.3463L13.5374 9.27436L15.043 7.78517L12.9482 7.526L12.9489 7.5267Z"
      fill="currentColor"
    />
  </svg>
);
