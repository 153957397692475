import { timeApi } from '../../../lib/api/timeApi';
import { OrganizationAddress, RCAddress } from '../../../types/address';
import { geocodeByAddress, geocodeByLatLng } from 'react-google-places-autocomplete';
import { orgLocationToString } from '../../settings/organization/utils';

const getPartOfAddress = (address_components: google.maps.GeocoderAddressComponent[], type: string) => {
	return address_components.find(address => address.types.includes(type))?.long_name;
};

export function getServerFormatAddress(address: { value: { description: string } }) {
	return geocodeByAddress(address.value.description).then(results => {
		const geocodeAddressData = results[0];
		return {
			city: getPartOfAddress(geocodeAddressData.address_components, 'locality'),
			street: getPartOfAddress(geocodeAddressData.address_components, 'route'),
			streetNum: getPartOfAddress(geocodeAddressData.address_components, 'street_number'),
			zip: getPartOfAddress(geocodeAddressData.address_components, 'postal_code'),
			state: getPartOfAddress(geocodeAddressData.address_components, 'administrative_area_level_1'),
			country: getPartOfAddress(geocodeAddressData.address_components, 'country'),
			geo: [geocodeAddressData.geometry.location.lng(), geocodeAddressData.geometry.location.lat()],
		} as RCAddress;
	});
}

export function getTimezone(address: string) {
	return geocodeByAddress(address).then(results => {
		const geocodeAddressData = results[0];
		const lat = geocodeAddressData.geometry.location.lat();
		const lon = geocodeAddressData.geometry.location.lng();
		return timeApi.getTimezoneByLocation(lat, lon).then(response => {
			if (response && response.status === 'OK') {
				return response.timeZoneId;
			} else {
				return null;
			}
		});
	});
}

export const getLocationByLatLng = (lat: number, lng: number, address: RCAddress | OrganizationAddress) => {
	return geocodeByLatLng({
		lat: lat,
		lng: lng,
	}).then(results => {
		const location = {
			label: orgLocationToString(address),
			value: { description: results[0].formatted_address, place_id: results[0].place_id },
		};
		return location;
	});
};
