import styled from '@emotion/styled';
import { ETypographyColor } from '../../types/theme';
import { setOpacity } from '../../styles/utils';
import { TypographyColorsMap } from '../../types';

export const StyledH1_B2C = styled.h1<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 80px;

  display:flex;
  align-items:center;

  margin:0;
  color:${colors[color] || colors['default']};
  `
);

export const StyledH2_B2C = styled.h2<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;

    display:flex;
    align-items:center;

    margin:0;
    color:${colors[color] || colors['default']};
    `
);

export const StyledH3_B2C = styled.h3<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;

    display:flex;
    align-items:center;

    margin:0;
    color:${colors[color] || colors['default']};
    `
);

export const StyledH4_B2C = styled.h4<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;

    display:flex;
    align-items:center;

    margin:0;
    color:${colors[color] || colors['default']};
    `
);

export const StyledH5_B2C = styled.h5<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
      font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
font-size: 24px;
line-height: 32px;
  
  display:flex;
  align-items:center;
  margin:0;
  text-transform: capitalize;
  color:${colors[color] || colors['default']};
      `
);

export const StyledH6Accented_B2C = styled.h6<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
      font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  
  display:flex;
  align-items:center;
  
  
  font-size: 20px;
line-height: 24px;
margin:0;
  color:${colors[color] || colors['default']};
  `
);

export const StyledH6_B2C = styled.h6<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
      font-family: Montserrat;
  font-style: normal;  
  display:flex;
  align-items:center;
  
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-decoration-line: underline;
  margin:0;
  color:${colors[color] || colors['default']};
  `
);

export const StyledBodyAccented_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;

    display:flex;
    align-items:center;
    
    font-size: 16px;
    line-height: 20px;
    color:${colors[color] || colors['default']};
    `
);

export const StyledBody1_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;

display:flex;
align-items:center;


color:${colors[color] || colors['default']};
    `
);

export const StyledBody1Link_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 16px;

display:flex;
align-items:center;


line-height: 20px;
text-decoration-line: underline;
color:${colors[color] || colors['default']};
    `
);

export const StyledCTA_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 16px;

display:flex;
align-items:center;

line-height: 20px;
text-transform: uppercase;
color:${colors[color] || colors['default']};
    `
);

export const StyledBody2Accented_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;

    display:flex;
    align-items:center;


    font-size: 14px;
    line-height: 20px;
    color:${colors[color] || colors['default']};
`
);

export const StyledBody2_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;

    display:flex;
    align-items:center;


    font-size: 14px;
    line-height: 20px;
    color:${colors[color] || colors['default']};
`
);

export const StyledBody2Link_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
font-family: Montserrat;
font-style: normal;
font-weight: normal;

display:flex;
align-items:center;


font-size: 14px;
line-height: 20px;
text-decoration-line: underline;
color:${colors[color] || colors['default']};
`
);

export const StyledCTA2_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;

    display:flex;
    align-items:center;


    font-size: 14px;
line-height: 20px;
    text-transform: uppercase;
    color:${colors[color] || colors['default']};
`
);

export const StyledCaptionAccented_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;

    display:flex;
    align-items:center;


    font-size: 12px;
line-height: 18px;
    color:${colors[color] || colors['default']};
`
);

export const StyledCaption_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;

    display:flex;
    align-items:center;
    white-space: break-spaces;

    font-size: 12px;
line-height: 18px;
    color:${colors[color] || colors['default']};
`
);

export const StyledCaptionLink_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: 500;

display:flex;
align-items:center;


font-size: 12px;
line-height: 18px;
text-decoration-line: underline;
color:${colors[color] || colors['default']};

    `
);

export const StyledCaptionCTA_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
font-family: Montserrat;
font-style: normal;
font-weight: bold;


display:flex;
align-items:center;


font-size: 12px;
line-height: 18px;
text-transform: uppercase;
color:${colors[color] || colors['default']};
`
);

export const StyledOverlinedAccented_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: 600;
font-size: 10px;

display:flex;
align-items:center;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
display: inline-block;

line-height: 12px;
text-transform: capitalize;
color:${colors[color] || colors['default']};`
);

export const StyledOverline_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: normal;

display:flex;
align-items:center;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
display: inline-block;

font-size: 10px;
line-height: 12px;
color:${colors[color] || colors['default']};
`
);

export const StyledOverlineLink_B2C = styled.div<any>(
	({ theme: colors, color }: { theme: TypographyColorsMap; color: ETypographyColor }) => `
    font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 10px;

display:flex;
align-items:center;
white-space: break-spaces;

line-height: 12px;
text-decoration-line: underline;
color:${colors[color] || colors['default']};
`
);
