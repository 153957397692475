/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react";
import { css, jsx, useTheme } from "@emotion/react";
import { IColors, Icons, ITypography } from "../../types/theme";
import dayjs from "dayjs";
import { BnIcon } from "../../components/icons_v2";
import { useTypography } from "../../hooks/useTypography";
import { useColors } from "../../hooks/useColors";

const DayOfWeek = (day: number) => {
  switch (day) {
    case 2:
      return "Mon";
    case 3:
      return "Tue";
    case 4:
      return "Wed";
    case 5:
      return "Thu";
    case 6:
      return "Fri";
    case 7:
      return "Sat";
    case 8:
      return "Sun";
    default:
      return "";
  }
};

interface IDayCheck {
  disabled?: boolean;
  chosen?: boolean;
  onClick: () => void;
  isTinyView?: boolean;
  addon: {
    id: number;
    date: string;
    dayOfWeek: number;
  };
}

const DayCheckboxCss = (
  colors: IColors,
  typography: ITypography,
  disabled: boolean,
  isTinyView: boolean
) => css`
  background: ${colors.bg_background_secondary};
  border: 1px solid
    ${disabled ? colors.bg_background_primary : colors.bg_border_primary};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  cursor: ${disabled ? "default" : "pointer"};
  font-family: ${typography.fontPrimary};
  font-style: normal;
  font-weight: 500;
  margin: 12px 6px;
  margin-left: 0;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-align: center;
  &:hover {
    background: ${disabled ? "unset" : colors.bg_background_primary};
  }
  .checked {
    min-width: 60px;
    min-height: 28px;
    svg {
      color: ${colors.t_green};
      height: 2rem;
      width: 2rem;
      margin: 4px 20px;
    }
  }
  .date {
    color: ${disabled ? colors.tx_text_disabled : colors.tx_text_primary};
  }
  .weekDay {
    color: ${disabled ? colors.tx_text_disabled : colors.tx_text_secondary};
  }
  ${isTinyView &&
  `.checked{
      min-width: 42px;
      svg {
        margin: 4px 0;
      }
    }`}
  @media (max-width: 767px) {
    .checked {
      min-width: ${isTinyView ? "28px" : "50px"};
      svg {
        margin: 4px 0;
      }
    }
  }
`;
export const DayCheckBox = ({
  disabled = false,
  chosen = false,
  onClick,
  addon,
  isTinyView = false,
}: IDayCheck) => {
  //   const isMobile = useMediaQuery(mobileOnly);
  const isMobile = false;
  // const { colors } = useColors();
  const colors = useTheme();
  const { typography } = useTypography();

  return (
    <div
      css={DayCheckboxCss(colors, typography, disabled, isTinyView)}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      <div className="checked">{chosen && <BnIcon icon={Icons.check} />}</div>
      <div className="date">
        {isMobile
          ? dayjs(addon.date).format("DD")
          : dayjs(addon.date).format("MMM DD")}
      </div>
      <div className="weekDay">
        {isMobile ? DayOfWeek(addon.dayOfWeek)[0] : DayOfWeek(addon.dayOfWeek)}
      </div>
    </div>
  );
};
