import React from 'react';

export const Indications_payment = (props: any) => (
	<svg data-aid="SvgIndications_payment" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.50261 4.0446C6.36876 4.25071 5.5 5.14792 5.5 6.25C5.5 7.50859 6.63304 8.5 8 8.5L8.09279 8.50235C8.89 8.54277 9.5 9.10058 9.5 9.75C9.5 10.4244 8.84217 11 8 11C7.15783 11 6.5 10.4244 6.5 9.75C6.5 9.47386 6.27614 9.25 6 9.25C5.72386 9.25 5.5 9.47386 5.5 9.75C5.5 10.8513 6.36748 11.748 7.50011 11.9549L7.5 12.5C7.5 12.7761 7.72386 13 8 13C8.27614 13 8.5 12.7761 8.5 12.5L8.50039 11.9549C9.63277 11.7477 10.5 10.8511 10.5 9.75C10.5 8.49141 9.36696 7.5 8 7.5L7.90721 7.49766C7.11 7.45723 6.5 6.89943 6.5 6.25C6.5 5.5756 7.15783 5 8 5C8.84217 5 9.5 5.5756 9.5 6.25L9.50336 6.30831C9.53225 6.55698 9.74358 6.75 10 6.75C10.2761 6.75 10.5 6.52614 10.5 6.25C10.5 5.14808 9.6315 4.25097 8.49789 4.04469L8.5 4V3.5C8.5 3.22386 8.27614 3 8 3C7.72386 3 7.5 3.22386 7.5 3.5L7.50058 4.02273L7.50261 4.0446Z"
			fill="currentColor"
		/>
	</svg>
);
