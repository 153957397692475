import React, { ReactNode } from 'react';
import { useRecoilState } from 'recoil';
import { baseStore, ENotificationType, EPopupNotificationType } from '../stores/baseStore';

export const useNotification = () => {
	const [notificationStore, setNotificationStore] = useRecoilState(baseStore.NotificationAtom);
	const [notificationType, setNotificationType] = useRecoilState(baseStore.notificationType);
	const isShowing = notificationStore.isShowing;
	const notification = notificationStore.notification;
	const toastType = notificationStore.toastType;

	const toggle = () => {
		setNotificationStore({ ...notificationStore, isShowing: !isShowing });
	};

	const setNotification = (val: ReactNode) => {
		setNotificationStore({ isShowing: true, notification: val });
	};

	const setInlineNotification = (val: ReactNode) => {
		setNotificationType(EPopupNotificationType.INLINE);
		setNotificationStore({ isShowing: true, notification: val });
	};

	const setPopupNotification = (val: string, type: ENotificationType) => {
		setNotificationType(type);
		setNotificationStore({ isShowing: true, notification: val });
	};

	const setTerminalNotification = (status: ReactNode) => {
		setNotificationType(EPopupNotificationType.TERMINAL);
		setNotificationStore({ isShowing: true, notification: status });
	};

	const setToastNotification = (val: string, type: ENotificationType) => {
		setNotificationType(EPopupNotificationType.TOAST);
		setNotificationStore({ isShowing: true, notification: val, toastType: type });
	};

	return {
		isShowing,
		toggle,
		notification,
		setNotification,
		notificationType,
		setNotificationType,
		setInlineNotification,
		setPopupNotification,
		setTerminalNotification,
		setToastNotification,
		toastType,
	};
};
