import React from 'react';

export const Forward = (props: any) => (
  <svg
    data-aid="svgForward"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9835 12.0091L16.9872 12.0128L15.8642 13.1358L15.8605 13.1322L9.21817 19.7745L8.09512 18.6515L14.7374 12.0091L8.08598 5.35767L9.20903 4.23462L15.8605 10.8861L15.8733 10.8733L16.9963 11.9963L16.9835 12.0091Z"
      fill="currentColor"
    />
  </svg>
);
