import { IOption } from '../../../components/Select_v2';

/**
 * Maximum percentage allowed for discounts
 */
const MAX_PERCENTAGE = 100;

/**
 * Options for the discount type input
 */
const discountTypeOptions: IOption[] = [
	{
		label: '%',
		value: 'percent',
	},
	{
		label: '$',
		value: 'amount',
	},
];

export { MAX_PERCENTAGE, discountTypeOptions };
