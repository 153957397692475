/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { BnIcon, Button, Icons } from '@bondsports/utils';

interface IProps {
	onEdit: () => void;
}

export const EditButton = ({ onEdit }: IProps) => {
	return (
		<Button data-aid="button-EditButton" sizer="XS" theme="basic" onClick={onEdit}>
			<BnIcon icon={Icons.edit_outline} />
		</Button>
	);
};
