import React from 'react';

export const Birthday = (props: any) => (
	<svg data-aid="SvgBirthday" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M11.9506 4.97284C12.4757 4.97284 12.9013 4.54722 12.9013 4.02219C12.9013 3.49716 12.4757 2.22919 11.9506 2.22919C11.4256 2.22919 11 3.49716 11 4.02219C11 4.54722 11.4256 4.97284 11.9506 4.97284Z"
			fill="currentColor"
		/>
		<path
			d="M10.0863 4.77432C10.0863 5.29935 9.66072 5.72498 9.13569 5.72498C8.61066 5.72498 8.18504 5.29935 8.18504 4.77432C8.18504 4.24929 8.61066 2.98132 9.13569 2.98132C9.66072 2.98132 10.0863 4.24929 10.0863 4.77432Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 5.53886C11.4477 5.53886 11 5.98657 11 6.53886V9.03849H10.185V7.12418C10.185 6.5719 9.73732 6.12418 9.18504 6.12418C8.63275 6.12418 8.18504 6.5719 8.18504 7.12418V9.03849H8.1074C6.45055 9.03849 5.1074 10.3816 5.1074 12.0385V14.4014H5.0676C3.41075 14.4014 2.0676 15.7446 2.0676 17.4014V21.767H2.24339V21.7691H19.9324V21.7708H21.9324V17.4014C21.9324 15.7446 20.5893 14.4014 18.9324 14.4014H18.7338V12.0385C18.7338 10.4088 17.4343 9.08257 15.8149 9.03957V7.1049C15.8149 6.55261 15.3672 6.1049 14.8149 6.1049C14.2626 6.1049 13.8149 6.55261 13.8149 7.1049V9.03849H13V6.53886C13 5.98657 12.5523 5.53886 12 5.53886ZM19.9324 19.7691V17.4014C19.9324 16.8491 19.4847 16.4014 18.9324 16.4014H5.0676C4.51532 16.4014 4.0676 16.8491 4.0676 17.4014V19.7691H19.9324ZM16.7338 14.4014V12.0385C16.7338 11.4862 16.2861 11.0385 15.7338 11.0385H8.1074C7.55511 11.0385 7.1074 11.4862 7.1074 12.0385V14.4014H16.7338Z"
			fill="currentColor"
		/>
		<path
			d="M15.9877 4.75504C15.9877 5.28007 15.5621 5.70569 15.0371 5.70569C14.512 5.70569 14.0864 5.28007 14.0864 4.75504C14.0864 4.23001 14.512 2.96204 15.0371 2.96204C15.5621 2.96204 15.9877 4.23001 15.9877 4.75504Z"
			fill="currentColor"
		/>
	</svg>
);
