import React from "react";

export const ThrowingAxe = (props: any) => (
  <svg
    data-aid="SvgThrowingAxe"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 16C9.79086 16 8 14.2091 8 12C8 11.1474 8.26674 10.3571 8.72133 9.70804C8.24754 8.87894 8.0179 8.41578 7.91578 7.60461C6.73727 8.70019 6 10.264 6 12C6 15.3137 8.68629 18 12 18C13.9022 18 15.5977 17.1148 16.6969 15.7338L15.5627 13.8205C14.9002 15.1144 13.5535 16 12 16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4906 17.2856C21.4472 15.7521 22 13.9406 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C14.7923 22 17.3173 20.8555 19.1315 19.0101L19.9746 20.6373C20.2287 21.1276 20.8322 21.3192 21.3225 21.0651C21.8129 20.8111 22.0045 20.2076 21.7504 19.7172L20.4906 17.2856ZM19.3687 15.1203C19.7752 14.1615 20 13.107 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C14.4724 20 16.6829 18.8784 18.1503 17.1163L14.093 9.28529C13.7937 9.6343 13.5134 10.013 13.2223 10.4168C13.3229 10.4946 13.4159 10.5818 13.5 10.6771C13.8112 11.0297 14 11.4928 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 11.6357 10.0974 11.2942 10.2676 11C10.324 10.9024 10.3885 10.81 10.4601 10.7238C9.38178 9.34747 8.65659 7.51859 8.71797 5.92801C9.13445 5.93395 9.52593 5.94571 9.89694 5.95687C10.8195 5.9846 11.6154 6.00852 12.3543 5.92952L12.3242 5.87147C12.0701 5.38109 12.2617 4.7776 12.7521 4.52353C13.2424 4.26946 13.8459 4.46102 14.1 4.9514L14.3152 5.36668C14.5861 5.22804 14.8581 5.05964 15.1354 4.85556L16.5155 7.51926C16.1977 7.64539 15.9115 7.78679 15.6497 7.94234L19.3687 15.1203ZM11.5281 11.8332C11.51 11.8844 11.5 11.9401 11.5 12C11.5 12.2761 11.7239 12.5 12 12.5C12.2761 12.5 12.5 12.2761 12.5 12C12.5 11.8722 12.454 11.7587 12.3754 11.6697C12.3635 11.6562 12.3509 11.6433 12.3378 11.6312C12.2106 11.7991 12.078 11.9699 11.9382 12.1433C11.7993 12.0481 11.6624 11.9445 11.5281 11.8332Z"
      fill="currentColor"
    />
  </svg>
);
