import { AgmCoreModule } from '@agm/core';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared.module';
import { SharedPagesModule } from '@app/shared/shared.pages.module';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { CustomFormsModule } from 'ng2-validation';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/takeLast';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import pkg from '../../package.json';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { APP_PROVIDERS } from './shared/services/main';
import {ApiAuthHeadersInterceptor} from "@app/apiAuthHeaders.interceptor";

// Add the following import statement
registerLocaleData(en);

export function jwtOptionsFactory() {
	return {
		skipWhenExpired: false,
		tokenGetter: () => {
			return localStorage.getItem('id_token');
		},
		whitelistedDomains: [environment.CS_URLS.API_DOMAIN.replace('http://', '').replace('https://', '')],
		blacklistedRoutes: [],
	};
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		ToastrModule.forRoot(),
		TooltipModule.forRoot(),
		CustomFormsModule,
		BrowserAnimationsModule,
		BrowserModule,
		HttpClientModule,
		SharedModule,
		SharedPagesModule,
		RouterModule.forRoot(ROUTES),
		AgmCoreModule.forRoot({
			apiKey: environment.GOOGLE_API_KEY,
			libraries: ['places'],
		}),
		JwtModule.forRoot({
			jwtOptionsProvider: {
				provide: JWT_OPTIONS,
				useFactory: jwtOptionsFactory,
			},
		}),
	],
	providers: [
		...APP_PROVIDERS,
		{ provide: NZ_I18N, useValue: en_US },
		{ provide: HTTP_INTERCEPTORS, useClass: ApiAuthHeadersInterceptor, multi: true }
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private meta: Meta) {
		this.meta.addTag({ name: 'version', content: pkg.version });
	}
}
