import { TranslationEn } from '@assets/i18n/en';
import { Icons } from '@bondsports/utils';
import { NoTableRecordsReasonEnum } from './enums';

export interface ObjectStringValues {
	[index: string]: string;
}

export interface CustomError extends Error {
	err?: unknown;
	meta?: Record<string, unknown>;
}

export interface SelectOption {
	value: string;
	label: string;
}

export interface INoTableRecords {
	message: string;
	type: NoTableRecordsReasonEnum;
}

/**
 * as of task BOND-7227 we turn the Table 'subject' prop into required properties instead of optional.
 * where a table has no pagination, i.e. pagination={false}, we'll pass this value.
 * when there is no pagination, the subject is not rendered anyway.
 */
export const NO_SUBJECT = '';
