/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Radio } from '../../../components/Radio';
import { ETypography, ETypographyColor, IColors, Icons } from '../../../types';
import { BnIcon } from '../../../components/icons_v2';
import { Typography } from '../../../atoms';
import dayjs from 'dayjs';
import { Fragment, useMemo } from 'react';
import { PaymentMethod, PaymentMethodTypeEnum } from '../../../types/entities/payment';
import { useColors } from '../../../hooks/useColors';
import { oldThemeColors as brandColors } from '../../../styles/theme';

const paymentMethodCss = (colors: IColors, isChecked: boolean) => css`
	padding: 20px 16px;
	display: flex;
	background: ${colors.bg_background_secondary};
	border: 1px solid ${isChecked ? brandColors.brandPrimary : colors.bg_border_primary};
	align-items: center;
	gap: 12px;
	justify-content: start;
	border-radius: 3px;
	cursor: pointer;
	> label {
		padding-left: 30px !important;
		margin: 0 !important;
	}

	.checkmark {
		transform: translateY(-50%);
	}
`;

const cardIconCss = css`
	width: 32px;
	height: 32px;
`;

const bankIconCss = (colors: IColors) => css`
	color: ${colors.tx_text_teriaty};
`;

interface Props {
	state: PaymentMethod;
	isChecked?: boolean;
	handleSelect: (v: PaymentMethod) => void;
}

export const PaymentMethodRadio = (props: Props) => {
	const { colors } = useColors();
	const Method = useMemo(() => {
		const methodsMapper = {
			[PaymentMethodTypeEnum.CARD]: <CreditCard {...props} />,
			[PaymentMethodTypeEnum.ACH]: <ACH {...props} />,
		};
		return methodsMapper[props.state.type];
	}, [props.state.type, props.isChecked]);

	return (
		<div data-aid="paymentMethodRadio" css={paymentMethodCss(colors, Boolean(props.isChecked))} onClick={() => props.handleSelect(props.state)}>
			<Radio value={props.state?.id} checked={props.isChecked} setChecked={() => {}} />
			{Method}
		</div>
	);
};

const CreditCard = ({ state, isChecked }: Props) => {
	return (
		<Fragment>
			{state?.card && <BnIcon icon={Icons[state.card.brand]} css={cardIconCss} />}
			<Typography type={ETypography.body1} color={isChecked ? ETypographyColor.primary : ETypographyColor.disabled}>
				**** **** **** {state?.card?.last4}
			</Typography>
			<Typography
				type={ETypography.body2Accented}
				color={isChecked ? ETypographyColor.primary : ETypographyColor.disabled}
			>
				{state?.card?.exp_month.length === 1 ? `0${state?.card?.exp_month}` : state?.card?.exp_month}/
				{dayjs(`01-01-${state?.card?.exp_year}`, 'MM-DD-YYYY').format('YY')}
			</Typography>
		</Fragment>
	);
};

const ACH = ({ state, isChecked }: Props) => {
	const { colors } = useColors();
	const accountLast4Chars = (state as any)?.us_bank_account?.last4;
	return (
		<Fragment>
			<BnIcon icon={Icons.bank} css={bankIconCss(colors)} />
			<Typography type={ETypography.body1} color={isChecked ? ETypographyColor.primary : ETypographyColor.disabled}>
				{accountLast4Chars}
			</Typography>
		</Fragment>
	);
};
