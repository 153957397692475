import { AlertsStatusEnum } from '@app/react/types/customers';
import { Icons, TagColorsMapper } from '@bondsports/utils';

export const alertStatusMapper = {
	[AlertsStatusEnum.GOOD_STANGING]: { color: TagColorsMapper.green, icon: Icons.indications_approved },
	[AlertsStatusEnum.ISSUES]: { color: TagColorsMapper.red, icon: Icons.indications_alert },
};

export enum DetailsEnum {
	BIRTHDATE = 'birthDate',
	PHONENUMBER = 'phoneNumber',
	EMAIL = 'email',
}
