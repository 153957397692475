import * as  React from 'react';

export const BnTag = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.81823 17.0572C9.11406 17.3513 9.50073 17.4988 9.88823 17.4988C10.2766 17.4988 10.6641 17.3513 10.9591 17.0563L17.0566 10.9597C17.3424 10.6738 17.4999 10.2938 17.4999 9.88884C17.4999 9.48384 17.3424 9.10384 17.0566 8.818L11.7091 3.4705C11.5716 3.333 11.3907 3.248 11.1974 3.2305L3.41073 2.50384C3.16323 2.47884 2.9199 2.568 2.74406 2.74384C2.56823 2.91967 2.48073 3.16384 2.50323 3.4105L3.2299 11.1972C3.24823 11.3905 3.33323 11.5722 3.47073 11.7088L8.81823 17.0572ZM4.86156 10.743L9.88823 15.7705L15.7699 9.88884L10.7432 4.86134L4.25573 4.25634L4.86156 10.743ZM8.80031 7.03292C9.28865 7.52042 9.28865 8.31292 8.80031 8.80042C8.31198 9.28875 7.52115 9.28875 7.03281 8.80042C6.54448 8.31292 6.54448 7.52042 7.03281 7.03292C7.52115 6.54459 8.31198 6.54459 8.80031 7.03292Z"
      fill="currentColor"
    />
  </svg>
);
