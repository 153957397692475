import React from 'react';

export const Support = (props: any) => (
  <svg
    data-aid="svgSupport"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM3.53846 12C3.53846 10.4071 3.9786 8.91706 4.74389 7.64477L7.42388 10.3248C7.2326 10.8471 7.12821 11.4114 7.12821 12C7.12821 12.5886 7.2326 13.1529 7.42388 13.6752L4.74388 16.3552C3.9786 15.0829 3.53846 13.5929 3.53846 12ZM10.3249 7.42384L7.64485 4.74384C8.91712 3.97858 10.4072 3.53846 12 3.53846C13.5929 3.53846 15.083 3.97861 16.3553 4.74392L13.6753 7.4239C13.1529 7.23261 12.5887 7.12821 12 7.12821C11.4114 7.12821 10.8472 7.23259 10.3249 7.42384ZM16.5762 10.3249C16.7674 10.8472 16.8718 11.4114 16.8718 12C16.8718 12.5886 16.7674 13.1528 16.5762 13.6751L19.2562 16.3551C20.0214 15.0828 20.4615 13.5928 20.4615 12C20.4615 10.4072 20.0214 8.91716 19.2562 7.64491L16.5762 10.3249ZM7.64485 19.2562C8.91712 20.0214 10.4072 20.4615 12 20.4615C13.5929 20.4615 15.083 20.0214 16.3553 19.2561L13.6753 16.5761C13.1529 16.7674 12.5887 16.8718 12 16.8718C11.4114 16.8718 10.8472 16.7674 10.3249 16.5762L7.64485 19.2562ZM8.66667 12C8.66667 10.1591 10.1591 8.66667 12 8.66667C13.841 8.66667 15.3333 10.1591 15.3333 12C15.3333 13.841 13.841 15.3333 12 15.3333C10.1591 15.3333 8.66667 13.841 8.66667 12Z"
      fill="currentColor"
    />
  </svg>
);
