import { IOption } from '..';

/**
 * Finds the index of the first child element that is hidden (scrolled out of view) in the container.
 * @param container - The container element to check for hidden children.
 * @returns The index of the first hidden child, or -1 if all children are visible.
 */
const getFirstHiddenChildIndex = (container: HTMLElement): number => {
	const { top: containerTop, height: containerHeight } = container.getBoundingClientRect();
	for (let i = 0; i < container.children.length; i++) {
		const child = container.children[i] as HTMLElement;
		const { top: childTop, height: childHeight } = child.getBoundingClientRect();
		const childOffset = childTop - containerTop + childHeight * 0.3;
		if (childOffset >= containerHeight) {
			return i;
		}
	}
	return -1; // All children are visible
};
/**
 * Gets the hidden values from the selectedValues array based on the first hidden child index in the container.
 * @param container - The container element containing the children.
 * @param selectedValues - The array of selected values.
 * @returns An array of hidden values from the selectedValues array.
 */
export const getHiddenValues = (container: HTMLElement, selectedValues: IOption[]): IOption[] => {
	const firstHiddenChildIndex = getFirstHiddenChildIndex(container);
	if (firstHiddenChildIndex === -1) {
		return []; // All children are visible, return an empty array
	} else {
		return selectedValues.slice(firstHiddenChildIndex);
	}
};
