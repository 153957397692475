import { useCallback } from 'react';
import { customersApi } from '@app/react/lib/api/customersApi';
import { useOrganization } from '@app/react/hooks/useOrganization';
import { AddNoteDto, EditNoteDto } from '@bondsports/types';

export const useCustomerNotes = (customerId: number) => {
	const { organizationId } = useOrganization();

	const getPaginatedNotes = useCallback(
		(page?: number, itemsPerPage?: number, alertsOnly?: boolean, signal?: AbortSignal) => {
			return customersApi.getCustomerNotes(organizationId, customerId, alertsOnly, itemsPerPage, page, { signal });
		},
		[customerId, organizationId]
	);

	const addCustomerNote = useCallback(
		(dto: AddNoteDto, signal?: AbortSignal) => {
			dto = structuredClone(dto);
			dto.isPinned = dto.isPinned || dto.isAlert;
			return customersApi.addCustomerNote(organizationId, customerId, dto, { signal });
		},
		[customerId, organizationId]
	);

	const editCustomerNote = useCallback(
		(noteId: number, dto: EditNoteDto, signal?: AbortSignal) => {
			dto = structuredClone(dto);
			dto.isPinned = dto.isPinned || dto.isAlert;
			return customersApi.editCustomerNote(organizationId, noteId, dto, { signal });
		},
		[customerId, organizationId]
	);

	const archiveCustomerNote = useCallback(
		(noteId: number, signal?: AbortSignal) => {
			return customersApi.archiveCustomerNote(organizationId, noteId, { signal });
		},
		[customerId, organizationId]
	);

	return {
		getPaginatedNotes,
		addCustomerNote,
		editCustomerNote,
		archiveCustomerNote,
	};
};
