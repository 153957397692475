/** @jsx jsx */
import { DateWithTimeRange } from 'app/react/components/shared/NewTable/Cell/DateWithTimeRange';
import { TimeRange } from './TimeRange';
import { PaymentMethod } from 'app/react/components/shared/NewTable/Cell/PaymentMethod';
import React, { useRef, Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { IconButton, KeyboardArrowDownIcon, KeyboardArrowUpIcon } from '../IconButton';
import { Status } from './Status';
import { DefaultCell } from './Default';
import { Age } from './Age';
import { Balance } from './Balance';
import { DateTime } from './DateTime';
import { Date } from './Date';
import { BooleanCell } from './Boolean';
import { Currency } from './Currency';
import { Description } from './Description';
import { cellCss, expandableButtonCss } from '../styles';
import { BnIcon, PaymentMethodMapper, StatusWithIcon } from '@bondsports/utils';
import { flex } from 'app/react/styles/utils';
import { LongString } from './LongString';
import { Invoice } from './Invoice';

interface CellMapperProps {
	type: string;
	column: any;
	row: any;
	index: number;
	expandOn: string[];
	expandableKey: string;
	isLast?: boolean;
	isExpanded: boolean;
	setIsExpanded: any;
	source?: 'participants';
	timezone?: string;
}

export const CellMapper = ({
	type,
	column,
	row,
	index,
	expandOn,
	isLast = false,
	expandableKey = '',
	isExpanded = false,
	setIsExpanded = () => {},
	source,
	timezone,
}: CellMapperProps) => {
	const cellProps = {
		row,
		column,
		index,
	};

	const handelIconClick = () => {
		if (column.id === 'email' && row[column.id]) {
			column.action(row);
		}
		if (column.id !== 'email') {
			column.action(row);
		}
	};
	const ref = useRef(null);

	const handleClick = e => {
		const btn = ref.current;
		if (btn && !btn.contains(e.target)) {
			if (column.action) {
				column.action(row);
			}
		}
		if (!btn) {
			if (column.action) {
				column.action(row);
			}
		}
	};

	if (source && source === 'participants') {
		switch (type) {
			case 'date':
				return <Date row={row} isLast={isLast} column={column} index={index} source="participants" longDate />;
			case 'time':
				const isSegment = Boolean(row.activityTimes);
				const tempRow = {
					startTime: `2999-01-01 ${isSegment ? row.activityTimes[0].open : row.startTime}`,
					endTime: `2999-01-01 ${isSegment ? row.activityTimes[0].close : row.endTime}`,
				};
				return <TimeRange row={tempRow} isLast={isLast} index={index} />;
			case 'space':
				// return <Description row={row} isLast={isLast} column={column} index={index} />;
				return <Fragment />;
			case 'custom':
				return (
					<td
						className={`TableCell-root TableCell-body ${column.id}`}
						key={column.id}
						align={column?.styling?.align ? (column?.styling?.align === 'right' ? 'right' : 'left') : 'center'}
						onClick={() => {
							if (column.action) {
								column.action(row);
							}
						}}
						css={[cellCss({ isAction: column.action, isLast })]}
					>
						{column.component(row[column.id], row)}
						{row[expandableKey] && row[expandableKey].length > 0 && expandOn.find(e => e === column.id) && (
							<IconButton ref={ref} css={expandableButtonCss} onClick={() => setIsExpanded(!isExpanded)}>
								{isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</IconButton>
						)}
					</td>
				);
			default:
				return (
					<DefaultCell
						isLast={isLast}
						expandableKey={expandableKey}
						key={index}
						setIsExpanded={setIsExpanded}
						isExpanded={isExpanded}
						type={column.type}
						column={column}
						row={row}
						index={index}
						expandOn={expandOn}
					/>
				);
		}
	} else {
		switch (type) {
			case 'icon':
				return (
					<td
						className="TableCell-root TableCell-body"
						key={column.id}
						onClick={handelIconClick}
						css={cellCss({ isAction: column.action, isLast, isIcon: true })}
					>
						<BnIcon icon={column.id} />
					</td>
				);
			case 'status':
				return <Status row={row} isLast={isLast} column={column} index={index} />;
			case 'statusWithIcon':
				return <td><StatusWithIcon row={row} column={column} /></td>;
			case 'balance':
				return <Balance row={row} isLast={isLast} column={column} index={index} />;
			case 'age':
				return <Age row={row} isLast={isLast} column={column} index={index} />;
			case 'boolean':
				return <BooleanCell isLast={isLast} expandableKey={expandableKey} row={row} column={column} index={index} />;
			case 'datetime':
				return <DateTime row={row} isLast={isLast} column={column} timezone={timezone} />;
			case 'date':
				return <Date row={row} isLast={isLast} column={column} index={index} />;
			case 'currency':
				return <Currency row={row} isLast={isLast} column={column} index={index} />;
			case 'description':
				return <Description row={row} isLast={isLast} column={column} />;
			case 'invoice':
				return <Invoice row={row} column={column} />;
			case 'longString':
				return (
					<LongString
						isLast={isLast}
						expandableKey={expandableKey}
						setIsExpanded={setIsExpanded}
						isExpanded={isExpanded}
						type={column.type}
						column={column}
						row={row}
						index={index}
						expandOn={expandOn}
					/>
				);
			case 'custom':
				return (
					<td
						className={`TableCell-root TableCell-body ${column.id}`}
						key={column.id}
						onClick={() => {
							if (column.action) {
								column.action(row);
							}
						}}
						css={cellCss({ isAction: column.action, isLast, isDisabled: row[column.id]?.isDisabled })}
					>
						<div
							css={
								row[expandableKey] && row[expandableKey].length > 0 && expandOn.find(e => e === column.id) ? flex : ''
							}
						>
							<Fragment>{column.component(row[column.id], row)}</Fragment>
							{row[expandableKey] && row[expandableKey].length > 0 && expandOn.find(e => e === column.id) ? (
								<IconButton ref={ref} css={expandableButtonCss} onClick={() => setIsExpanded(!isExpanded)}>
									{isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
							) : (
								<Fragment />
							)}
						</div>
					</td>
				);
			case 'paymentMethodWithIcon':
				return <td className={`TableCell-root TableCell-body ${column.id}`}><PaymentMethodMapper state={row} /></td>;
			case 'paymentMethod':
				return <PaymentMethod row={row} isLast={isLast} column={column} index={index} />;
			case 'dateWithTimeRange':
				return <DateWithTimeRange row={row} isLast={isLast} column={column} index={index} />;
			case 'addonCount':
				return (
					<td
						className="TableCell-root TableCell-body"
						key={column.id}
						onClick={() => {
							if (column.action) {
								column.action(row);
							}
						}}
						css={[cellCss({ isAction: column.action, isLast }), flex]}
					>
						{column.component(row[column.id])}
						{row[expandableKey] && row[expandableKey].length > 0 && expandOn.find(e => e === column.id) ? (
							<IconButton ref={ref} css={expandableButtonCss} onClick={() => setIsExpanded(!isExpanded)}>
								{isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</IconButton>
						) : (
							<Fragment />
						)}
					</td>
				);
			default:
				return (
					<DefaultCell
						isLast={isLast}
						expandableKey={expandableKey}
						setIsExpanded={setIsExpanded}
						isExpanded={isExpanded}
						type={column.type}
						column={column}
						row={row}
						index={index}
						expandOn={expandOn}
					/>
				);
		}
	}
};
