/** @jsxRuntime classic */
/** @jsx jsx */

import { FC, memo, useState } from 'react';
import { jsx } from '@emotion/react';
import dayjs from 'dayjs';
import useInterval from '../../hooks/useInterval';
import { hourBoxLine2 } from '../styles';
import { useMiddlewareContext } from '../../hooks/useMiddlewareContext';

export const CurrentTimeLine: FC<{ width?: number }> = memo(({ width }) => {
	const { getTopAndHeight, options } = useMiddlewareContext();

	const nowHours = Number(dayjs().format('HH'));
	const utcHours = Number(dayjs().format('HH'));
	const diff = nowHours - utcHours;

	const [marginTop, setMarginTop] = useState(
		getTopAndHeight(
			dayjs()
				.hour(nowHours + diff)
				.toDate()
				.getTime(),
			0
		).top
	);

	useInterval(() => {
		const now = dayjs()
			.hour(nowHours + diff)
			.toDate()
			.getTime();
		setMarginTop(getTopAndHeight(now, 0).top);
	}, 1000);

	if (dayjs(options?.date).format('DD/MM/YYYY') !== dayjs().format('DD/MM/YYYY')) {
		return null;
	}

	return <div css={hourBoxLine2(width, marginTop)} data-aid="CurrentTimeLine-daily" />;
});
