import * as  React from 'react';

export const BnArrowLeft = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5892 3.57741C10.9146 3.90284 10.9146 4.43048 10.5892 4.75592L6.17843 9.16666H15.8333C16.2935 9.16666 16.6666 9.53976 16.6666 9.99999C16.6666 10.4602 16.2935 10.8333 15.8333 10.8333H6.17843L10.5892 15.2441C10.9146 15.5695 10.9146 16.0971 10.5892 16.4226C10.2637 16.748 9.7361 16.748 9.41066 16.4226L3.57733 10.5892C3.25189 10.2638 3.25189 9.73618 3.57733 9.41074L9.41066 3.57741C9.7361 3.25197 10.2637 3.25197 10.5892 3.57741Z"
      fill="currentColor"
    />
  </svg>
);
