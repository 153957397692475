import { css } from '@emotion/react';

export const sizeCss = (size: number) => css`
	width: ${size}px;
	height: ${size}px;
`;

export const wrapperCss = (size: number) => css`
	${sizeCss(size)};
	svg {
		${sizeCss(size)};
	}
`;

export const colorCss = (color: string) => css`
	color: ${color} !important;
`;
