import { Subcategory } from 'app/react/types/product';
import { atom, AtomEffect, DefaultValue, selector } from 'recoil';
import { IProductInCart, ISelectedItems } from '../types/checkout';
import { SlotData } from '../types/Booking';
import { IStation } from '../types/station';
import { ICustomer } from '../types/customers';
import { Terminal } from '@stripe/terminal-js';
import { roundPriceCents } from 'app/react/lib/pricing';
import { PaymentMethodTypeEnum } from '@bondsports/types';

const connectedStation = atom<IStation | null>({
	key: 'connectedStation',
	default: null,
});

const isCartOpen = atom<boolean>({
	key: 'isCartOpen',
	default: true,
});

const isCartInline = atom<boolean>({
	key: 'isCartInline',
	default: true,
});

const isRentalCart = atom<boolean>({
	key: 'isRentalCart',
	default: false,
});

const cart = JSON.parse(localStorage.getItem('bn_cart')) || {
	selectedItems: {},
	selectedCustomer: {},
	itemsForPurchase: [],
	originalItems: [],
	organizationId: '',
};

const originalItems = atom<SlotData[]>({
	key: 'originalItems',
	default: [],
});

const selectedCustomer = atom<ICustomer>({
	key: 'selectedCustomer',
	default: cart.selectedCustomer,
});

const selectedItems = atom<ISelectedItems>({
	key: 'selectedItems',
	default: cart.selectedItems,
});

const itemsForPurchase = atom<any>({
	key: 'itemsForPurchase',
	default: cart.itemsForPurchase,
});

const sumLine = selector({
	key: 'sumLine',
	get: ({ get }) => {
		const selectedItemsObj = get(selectedItems);

		let total = 0;
		let subTotal = 0;
		let tax = 0;
		let taxPrecentage = 0;

		Object.keys(selectedItemsObj).forEach(key => {
			const item = selectedItemsObj[key];
			const taxPrice = roundPriceCents(item.product.taxPrice);
			const price = roundPriceCents(item.product.price);
			const withoutTaxPrice = roundPriceCents(price - taxPrice);

			total = total + roundPriceCents(item.quantity * price);
			subTotal = subTotal + roundPriceCents(item.quantity * withoutTaxPrice);
			tax = tax + roundPriceCents(item.quantity * taxPrice);

			if (item && item.product && item.product.subProducts) {
				if (item.product.subProducts.length > 0) {
					item.product.subProducts.forEach(sub => {
						const quantity = sub.quantity ?? 1;
						const taxSubPrice = sub.product.taxPrice;
						const priceSub = sub.product.price;
						const withoutTaxPriceSub = priceSub - taxSubPrice;

						if (priceSub) {
							total = total + roundPriceCents(quantity * priceSub);
						}
						if (withoutTaxPriceSub) {
							subTotal = subTotal + roundPriceCents(quantity * withoutTaxPriceSub);
						}
						if (taxSubPrice) {
							tax = tax + roundPriceCents(quantity * taxSubPrice);
						}
					});
				}
			}
		});

		taxPrecentage = roundPriceCents((tax / total) * 100);
		return {
			total: roundPriceCents(total),
			subTotal: roundPriceCents(subTotal),
			tax: roundPriceCents(tax),
			taxPrecentage,
		};
	},
});

const closeCartAfterPurchase = atom<boolean>({
	key: 'closeCartAfterPurchase',
	default: false,
});

const cartError = atom<{ message: string; timedate: number }>({
	key: 'cartError',
	default: { message: '', timedate: 0 },
});

interface IPayment {
	method: PaymentMethodTypeEnum;
	amount: number;
}

const payments = atom<IPayment[]>({
	key: 'payments',
	default: [],
});

const invoiceId = atom<number | null>({
	key: 'invoiceId',
	default: null,
});

const productSubcategories = atom<Subcategory[]>({
	key: 'productSubcategories',
	default: [],
});

const paidAmount = selector({
	key: 'paidAmount',
	get: ({ get }) => {
		const paymentArray = get(payments);
		let total = 0;
		paymentArray.forEach(payment => {
			total += Number(payment.amount);
		});
		return total;
	},
});

const checkoutState = selector({
	key: 'checkoutState',
	get: ({ get }) => {
		const selectedItemsObj = get(selectedItems);
		const selectedCustomerObj = get(selectedCustomer);
		const rawItemsObj = get(itemsForPurchase);
		const originalItemsArray = get(originalItems);
		const paymentArray = get(payments);
		const invoiceIdNumber = get(invoiceId);

		return {
			organizationId: 0,
			selectedItems: selectedItemsObj,
			selectedCustomer: selectedCustomerObj,
			itemsForPurchase: rawItemsObj,
			originalItems: originalItemsArray,
			payments: paymentArray,
			invoiceId: invoiceIdNumber,
		};
	},
	set: ({ set }, newValue: any) => {
		set(selectedItems, newValue.selectedItems);
		set(selectedCustomer, newValue.selectedCustomer);
		set(itemsForPurchase, newValue.itemsForPurchase);
		set(originalItems, newValue.originalItems);
		set(payments, newValue.payments);
		set(invoiceId, newValue.invoiceId);
	},
});

const isTerminalSet = atom<boolean>({
	key: 'isTerminalSet',
	default: false,
});

const isTerminalLoading = atom<boolean>({
	key: 'isTerminalLoading',
	default: false,
});

const sTerminal = atom<Terminal | null>({
	key: 'sTerminal',
	default: null,
});

const isSwipeAllowed = atom<boolean>({
	key: 'isSwipeAllowed',
	default: false,
});

const terminalStatus = atom<string>({
	key: 'terminalStatus',
	default: '',
});

const anonymousId = atom<number>({
	key: 'anonymousId',
	default: 0,
});

const additionalData = atom<{ [key: string]: number | string }>({
	key: 'additionalData',
	default: {},
});

const anonymousCustomer = atom<ICustomer>({
	key: 'anonymousCustomer',
	default: undefined,
});

export const cartStore = {
	isCartOpen,
	isRentalCart,
	isCartInline,
	anonymousId,
	closeCartAfterPurchase,
	cartError,
	checkoutState,
	selectedItems,
	itemsForPurchase,
	originalItems,
	selectedCustomer,
	payments,
	paidAmount,
	connectedStation,
	invoiceId,
	additionalData,
	productSubcategories,
	isTerminalSet,
	isTerminalLoading,
	sTerminal,
	terminalStatus,
	isSwipeAllowed,
	sumLine,
	anonymousCustomer,
};
