/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, useState } from 'react';
import { css, jsx, useTheme } from '@emotion/react';
import { ETypography, ETypographyColor, IColors, IOption, Icons } from '../../../types';
import { SelectOption } from '../../Select_v2/SelectOption';
import { IGroup, ISelectOption } from '../types';
import { BnIcon } from '../../icons_v2';
import { Typography } from '../../../atoms';
import { capitalize } from '../../../functions';

const containerCss = css`
	display: flex;
	align-items: center;
	padding: 8px;
	padding-left: 16px;
`;

const iconCss = (colors: IColors) => css`
	width: 20px;
	height: 20px;
	color: ${colors.bg_text_primary};
`;

export const Group = ({
	label,
	options,
	onSelect,
	value = [],
	autoFocus = true,
	isMultiple = false,
	selectedValuesArray,
}: IGroup & ISelectOption) => {
	const colors = useTheme();
	const [isOpen, setIsOpen] = useState(true);

	const toggle = () => {
		setIsOpen(prevState => !prevState);
	};
	return (
		<Fragment>
			<div css={containerCss} data-aid="Group-GroupsOptions">
				<Typography type={ETypography.captionAccented} color={ETypographyColor.primary}>
					{capitalize(label)}
				</Typography>
				<BnIcon icon={isOpen ? Icons.dropdown_active : Icons.dropdown} onClick={toggle} css={iconCss} />
			</div>

			{isOpen &&
				options?.map((opt, index) => {
					const optionVal = String(opt.value);
					return (
						<SelectOption
							colors={colors}
							index={index}
							size="S"
							onSelect={onSelect}
							opt={opt as IOption}
							autoFocus={autoFocus}
							isMultiple={isMultiple}
							selectedValuesArray={selectedValuesArray}
							value={value}
							optionVal={optionVal}
						/>
					);
				})}
		</Fragment>
	);
};
