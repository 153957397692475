import { RCAddress, RCGenderEnum } from '@rcenter/core';
import { atom } from 'recoil';
import { ICustomer, ILinkedAccount } from '../types/customers';
import { EStorageKeys } from '../types/enums';
import { CustomerTypeEnum } from '../types/membership';
import { MediaUpload } from './../types/media';
import { localStorageEffect } from './baseStore';

interface CustomerEditStore {
	id: number; // populated if it's in edit mode and not create
	firstName: string;
	lastName: string;
	customerType: CustomerTypeEnum;
	gender: RCGenderEnum;
	birthDate?: Date;
	email: string;
	phoneNumber?: string;
	sendLink: boolean;
	pinToTop: boolean;
	notes?: string[];
	picture?: MediaUpload;
	address: RCAddress;
	emergencyContactName: string;
	emergencyContactPhone: string;
}

const editCreate = atom<CustomerEditStore | null>({
	key: 'customerEditStore',
	default: null,
	effects_UNSTABLE: [localStorageEffect(EStorageKeys.CUSTOMER_STORE)],
});

const customerState = atom<ICustomer | null>({
	key: 'customerState',
	default: null,
});

const isLoadingCustomer = atom<boolean>({
	key: 'isLoadingCustomer',
	default: false,
});

const isLoadingLinkedAccountsr = atom<boolean>({
	key: 'isLoadingLinkedAccountsr',
	default: false,
});

const connectedAccounts = atom<ILinkedAccount[]>({
	key: 'connectedAccounts',
	default: [],
});

export const customerStore = {
	editCreate,
	customerState,
	isLoadingCustomer,
	isLoadingLinkedAccountsr,
	connectedAccounts,
};
