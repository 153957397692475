import React, { useMemo } from 'react';
import { Theme } from '@emotion/react';
import { capitalize } from '../../functions';
import { Typography } from '../../atoms';
import { ETypography, ETypographyColor } from '../../types/theme';
import { Checkbox } from '../Checkbox';
import { BnIcon } from '../icons_v2';
import { IOption } from '../../types';
import { StyledSelectOption } from '../Options_v2/style';
import { ISelectOptions } from '../Options_v2/types';
import { CHARACHERS } from '../../lib/constants';
import { getAdditionalInfo } from './utils';

interface OptionProps {
	colors: Theme;
	index: number;
	opt: IOption;
	selectedValuesArray: string[] | number[] | Date[];
	value: string | string[] | number[] | Date[];
	optionVal: string;
	size: 'S' | 'M';
	lastOptionElementRef?: (node: Element) => void;
}

export const SelectOption = ({
	colors,
	index,
	size,
	onSelect,
	opt,
	autoFocus,
	isMultiple,
	selectedValuesArray,
	value,
	optionVal,
	lastOptionElementRef,
}: Omit<ISelectOptions, 'size' | 'options'> & OptionProps) => {
	const additionalInfo = useMemo(() => getAdditionalInfo(opt.additionalInfo || ''), [opt.additionalInfo]);
	const onClick = () => {
		if (!opt.isDisabled) onSelect(opt.value);
	};
	return (
		<StyledSelectOption
			theme={{ ...colors }}
			key={index}
			onClick={onClick}
			tabIndex={0}
			size={size}
			autoFocus={autoFocus ? index === 0 : false}
			isDisabled={opt.isDisabled}
			ref={lastOptionElementRef}
		>
			<Typography
				color={opt.isDisabled ? ETypographyColor.secondary : ETypographyColor.primary}
				type={ETypography.body2}
			>
				<span data-aid="Select_v2-span">
					{isMultiple && (
						<Checkbox
							data-aid="Select_v2-checkbox"
							width={16}
							functionDisable
							setChecked={() => {}}
							checked={(selectedValuesArray as string[]).map(v => String(v)).includes(String(optionVal))}
						/>
					)}
					{opt.icon && <BnIcon icon={opt.icon} />}
					{capitalize(opt.label)}
					{opt.additionalInfo && <span className="additional-info">{additionalInfo}</span>}
				</span>

				{!isMultiple && String(value) === String(optionVal) && <BnIcon icon="check" />}
			</Typography>
		</StyledSelectOption>
	);
};
