/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { SectionContainer } from '../sectionContainer';
import { Table } from '../../table';
import { Typography } from '../../../atoms';
import { AlignType, IColumn } from '../../table/types';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { ShowMoreButton } from '../../../components/ShowMoreButton';
import { EInvoiceVariant, IInvoiceTemplateLabels, PaymentStatusEnum } from '../../../organisms/invoiceTemplate/types';
import { Summary } from '../summary';
import { BACKOFFICE_VARIANTS, EInvoiceAction, EInvoicingTabs } from '../types';
import { SummaryLine } from '../summary/summaryLine';
import { Pricify, roundPriceCents } from '../../../lib/price';
import { ETypography, ETypographyColor, Icons } from '../../../types';
import { SkeletonWrapper } from '../../../components/skeletonWrapper';
import { BnIcon } from '../../../components/icons_v2';
import { flexRowCss, iconCss } from '../../../organisms/refundPopup/style';
import { oldThemeColors as colors, ThemeColors as colors_v2 } from '../../../styles/theme';
import { gapCss } from '../../../styles/utils';
import { Tooltip } from '../../../components/Tooltip';
import { Column, Row } from '../../../atoms/layoutPrimitives';
import { PaymentMethodMapper } from './PaymentMethodMapper';
import { useDetectPrint } from '../../../hooks/useIsPrint';
import { hrCss } from '../../../organisms/invoiceTemplate/styles';
import { formatDateTimeTz } from '../utils/date-formatting.util';
import { TooltipWithButton } from '../../tooltip-with-button';

const feeInfoIconCss = css`
	color: ${colors_v2.bg_text_primary};
	margin-right: 6px;
`;

const PAYMENT_VARIANTS = [EInvoiceVariant.PAYMENT, EInvoiceVariant.PAYMENT_PRINT];

export const Payments = ({
	labels,
	additional,
	isLoading,
	activeTab,
	additionalInfoRow,
	state,
	variant,
	onAction,
	organizationTimeZone = 'utc',
}: {
	labels: IInvoiceTemplateLabels;
	additional: any;
	isLoading: boolean;
	activeTab: string;
	additionalInfoRow?: string;
	state: any;
	variant: EInvoiceVariant;
	onAction: (action: EInvoiceAction, value: any) => void;
	organizationTimeZone?: string;
}) => {
	const [rowsToDisplay, setRowsToDisplay] = useState([]);
	const [showFeesCol, setShowFeesCol] = useState(false);
	const { isMobile } = useWindowSize();
	const isPrint = useDetectPrint();
	const { formatDate, formatTime } = formatDateTimeTz(organizationTimeZone);
	const invoiceState = state.invoice;

	const isBackOffice = BACKOFFICE_VARIANTS.includes(variant);

	const payments = additional?.payments?.map(setMethodLabel(labels)) ?? [];

	const DEFAULT_MAX_ROWS = 8;
	const isAllRowsMoreThenDefaultMax = payments?.length > DEFAULT_MAX_ROWS;
	const isDisplayShowMoreButton = isAllRowsMoreThenDefaultMax && !isPrint;

	const tableLabels = labels.paymentsSection.table.columns;

	const styling = { align: AlignType.LEFT };
	const columns: IColumn[] = [
		{
			id: 'id',
			type: 'string',
			label: tableLabels.id,
			styling,
		},
		{
			id: 'customerName',
			type: 'string',
			label: tableLabels.customerName,
			styling,
		},
		{
			id: 'date',
			type: 'custom',
			label: tableLabels.date,
			component: (state: string, row: any) => {
				return (
					<SkeletonWrapper isLoading={isLoading} minHeight={6} minWidth={50}>
						<Typography type={ETypography.body2} color={ETypographyColor.primary}>
							{formatDate(state)}
						</Typography>
					</SkeletonWrapper>
				);
			},
			styling,
		},
		{
			id: 'priceWithoutFee',
			type: 'currency',
			label: tableLabels.amount,
			styling,
		},
		...(showFeesCol
			? ([
					{
						id: 'paymentFees',
						type: 'custom',
						label: tableLabels.fees,
						styling,
						component: (state: number) => {
							const fee = state?.[0];
							const percent =
								Number(fee?.fee?.percentageValue ?? 0) > 0
									? `${roundPriceCents(Number(fee?.fee?.percentageValue ?? 0) * 100)}%`
									: '';
							const addition =
								Number(fee?.fee?.percentageValue ?? 0) > 0 && Number(fee?.fee?.fixValue ?? 0) > 0 ? ` + ` : '';
							const fixed =
								Number(fee?.fee?.fixValue ?? 0) > 0
									? `${Pricify(Number(fee?.fee?.fixValue ?? 0), undefined, undefined, true)} fixed`
									: ``;
							const tooltipContent = fee ? `${fee?.fee?.name ?? ''} - ${percent}${addition}${fixed}` : '';

							const totalFeeAmount = (state as unknown as [])?.reduce(
								(acc, current: any) => acc + current.feeAmount,
								0
							);

							return fee ? (
								<TooltipWithButton showButton={!isPrint} icon={Icons.info} tooltipContent={tooltipContent}>
									<Typography type={ETypography.body2} color={ETypographyColor.primary}>
										{Pricify(totalFeeAmount, undefined, undefined, true)}
									</Typography>
								</TooltipWithButton>
							) : (
								<BnIcon icon={'minus'} />
							);
						},
					},
			  ] as IColumn[])
			: []),
		{
			id: 'amount',
			type: 'currency',
			label: tableLabels.total,

			styling,
		},
		{
			id: 'method',
			type: 'paymentMethod',
			label: tableLabels.method,
			styling,
		},

		{
			id: 'status',
			type: 'status',
			label: tableLabels.status,

			styling,
		},
		...(isBackOffice
			? ([
					{
						id: 'view',
						type: 'custom',
						label: '',
						styling: { align: AlignType.RIGHT },
						component: (state: number) => (
							<div data-aid="invoicePayment" css={[flexRowCss, gapCss(10)]}>
								<div
									css={iconCss(colors.brandTertiary, 20, '0', colors.brandPrimary)}
									onClick={() => onAction(EInvoiceAction.VIEW, state)}
								>
									<BnIcon icon={Icons.view} />
								</div>
								<div
									css={iconCss(colors.brandTertiary, 20, '0', colors.brandPrimary)}
									onClick={() => onAction(EInvoiceAction.DOWNLOAD, state)}
								>
									<BnIcon icon={Icons.download} />
								</div>
							</div>
						),
					},
			  ] as IColumn[])
			: []),
	];

	const filterColumns = columns.filter(col => {
		if (isMobile) {
			if (col.id === 'method') {
				return false;
			}
		}
		if (!showFeesCol) {
			if (col.id === 'feesAmount') {
				return false;
			}
		}
		if (!invoiceState?.isPublic) {
			if (col.id === 'customerName') {
				return false;
			}
		}
		return true;
	});

	const getRowsToDisplay = (isShowMore: boolean, payments: any) => {
		const isDisplayAll = isPrint || !isShowMore;
		return isDisplayAll ? payments : payments?.slice(0, DEFAULT_MAX_ROWS);
	};

	const handleShowMoreRows = useCallback(
		(isShowMore: boolean) => {
			const formattedPayments = payments?.map((p: any) => ({ ...p, view: p.id, download: p.id }));
			const rowsToDisplay = getRowsToDisplay(isShowMore, formattedPayments);
			setRowsToDisplay(rowsToDisplay);
			setShowFeesCol(formattedPayments.some((p: any) => p?.feesAmount && p?.feesAmount > 0));
		},
		[payments, isAllRowsMoreThenDefaultMax]
	);

	useEffect(() => {
		handleShowMoreRows(true);
	}, [invoiceState]);

	const SummaryComponent = useCallback(
		() => (
			<Summary gap={variant === EInvoiceVariant.BACKOFFICE ? 8 : 12} className="no-print">
				{invoiceState?.totalFeesAmount > 0 ? (
					<SummaryLine
						label={labels?.paymentsSection?.summary?.fees}
						value={Pricify(invoiceState?.totalFeesAmount, undefined, undefined, true)}
						isLoading={isLoading}
						variant={variant}
					/>
				) : (
					<Fragment />
				)}
				<SummaryLine
					label={labels?.paymentsSection?.summary?.amountPaid}
					value={Pricify(invoiceState?.paidAmount, undefined, undefined, true)}
					isLoading={isLoading}
					variant={variant}
					isMain={invoiceState?.paymentStatus === PaymentStatusEnum.FULLY_PAID}
				/>
				{invoiceState?.paymentStatus === PaymentStatusEnum.NOT_PAID ||
				invoiceState?.paymentStatus === PaymentStatusEnum.PARTIAL_PAYMENT ? (
					<SummaryLine
						label={labels?.paymentsSection?.summary?.amountDue}
						value={Pricify(invoiceState?.meta?.totalDue, undefined, undefined, true)}
						isLoading={isLoading}
						variant={variant}
						isMain
					/>
				) : (
					<Fragment />
				)}
			</Summary>
		),
		[invoiceState, isLoading, labels, variant]
	);

	return (
		<Fragment>
			{activeTab === EInvoicingTabs.SCHEDULE && !isPrint ? (
				<Fragment />
			) : (
				<Fragment>
					<div className="no-print">
						{payments?.length > 0 && (
							<SectionContainer title={labels.paymentsSection.title}>
								<Table<any>
									columns={filterColumns}
									rows={rowsToDisplay}
									pagination={false}
									isLoading={isLoading}
									isHover={false}
									additionalInfoRow={additionalInfoRow}
									footer={!PAYMENT_VARIANTS.includes(variant) && <SummaryComponent />}
									maxHeight={-1}
								/>
								{isDisplayShowMoreButton && <ShowMoreButton handleShowMore={handleShowMoreRows} />}
							</SectionContainer>
						)}
					</div>
					{Boolean(payments[0]) && (
						<Column fullWidth className="print">
							<Row fullWidth justify="space-between">
								<Typography color={ETypographyColor.primary} type={ETypography.body2}>
									Payment
								</Typography>
								<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
									{Pricify(payments[0].amount - payments[0].feesAmount, undefined, undefined, true)}
								</Typography>
							</Row>
							{payments?.[0]?.feesAmount > 0 && (
								<Row fullWidth justify="space-between">
									<Typography color={ETypographyColor.primary} type={ETypography.body2}>
										{`Fees`}
									</Typography>
									<Typography color={ETypographyColor.primary} type={ETypography.body2}>
										{Pricify(payments?.[0]?.feesAmount, undefined, undefined, true)}
									</Typography>
								</Row>
							)}
							<hr css={hrCss} />
							{Boolean(payments?.[0]) && (
								<Row fullWidth justify="space-between">
									<Column align="start" gap="0">
										<Typography color={ETypographyColor.primary} type={ETypography.body2}>
											Total paid
										</Typography>
										<PaymentMethodMapper state={payments?.[0]} noIcons />
									</Column>
									<Typography color={ETypographyColor.primary} type={ETypography.body2}>
										{Pricify(payments?.[0]?.amount, undefined, undefined, true)}
									</Typography>
								</Row>
							)}
							<Row
								fullWidth
								justify="center"
								styles={css`
									margin-top: 5rem;
								`}
							>
								<Typography color={ETypographyColor.primary} type={ETypography.body1Accented}>
									THANK YOU
								</Typography>
							</Row>
						</Column>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

const setMethodLabel = (labels: any) => (payment: any) =>
	payment && {
		...payment,
		method: labels?.paymentMethods?.[payment.method] ?? payment.method,
	};
