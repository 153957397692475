import React from "react";

export const ReservationStatusApproved = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
      fill="#24C875"
    />
    <path
      d="M11.758 5.23151C11.4353 4.92283 10.9122 4.92283 10.5895 5.23151L6.62289 9.09149L5.41047 7.93197C5.08783 7.62325 4.56465 7.62325 4.24201 7.93197C3.91933 8.24069 3.91933 8.74121 4.24201 9.04993L6.0387 10.7685C6.20001 10.9228 6.41151 11 6.62297 11C6.83442 11 7.04588 10.9228 7.20724 10.7685L11.758 6.34947C12.0807 6.04075 12.0807 5.54023 11.758 5.23151Z"
      fill="white"
    />
  </svg>
);
