import { ICustomer, ICustomerListCulumns, ICustomerListRow } from '@app/react/types/customers';
import { CustomerTypeEnum } from '@bondsports/types';

export const generateMoreMenuProps = (customer: ICustomer) => {
	return {
		customerId: customer.id,
		entityType: customer.entityType,
		isLinkedMember: customer.linkedAccounts?.length > 0,
	};
};

export const generateAccountTypeProps = (customer: ICustomer) => {
	return {
		entityType: customer.entityType,
		familyStatus: customer.familyStatus,
	};
};

const isNameContainingSearchFilter = (member: ICustomerListRow, searchFilter: string) => {
	const fullName = `${member.firstName} ${member.lastName}`;
	return fullName.includes(searchFilter);
};

const isMemberInCustomersList = (customersArr: ICustomerListCulumns[], member: ICustomerListRow) => {
	return customersArr.map(customer => customer.id).includes(member.id);
};

export const getExpandedFamilyRowsIds = (customersArr: ICustomerListCulumns[], searchFilter: string) => {
	const familiesWithChildInCustomerList = customersArr.filter(
		customer =>
			customer.accountType.entityType === CustomerTypeEnum.FAMILY &&
			customer.members.find(
				member => isMemberInCustomersList(customersArr, member) || isNameContainingSearchFilter(member, searchFilter)
			)
	);

	return familiesWithChildInCustomerList.map(family => family.id);
};
