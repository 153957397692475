/* @jsx jsx */
/* @jsxRuntime classic */

import React, { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { flexGrowCss, flexRowCss, gapCss, paddingBottomCss } from '../../styles/utils';
import { useColors } from '../../hooks/useColors';
import { Button } from '../../components/Button';
import { Icons } from '../../types';
import { IconWithTooltip } from '../../molecules/IconWithTooltip';
import { LoadingWithSpinner } from '../../molecules/Loading';
import { SuspenseWrapper } from '../../components/SuspenseWrapper';

type ChildrenProps<T> = {
	data: T;
	index: number;
	isLastItem: boolean;
};

const alignedFlexRowCss = (justifyHow = 'center', alignHow = 'center') => css`
	display: flex;
	flex-direction: row;
	justify-content: ${justifyHow};
	align-items: ${alignHow};
`;

const loadingContainerCss = (borderColor: string) => css`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	padding: 28px 0 28px 0;
	border-top: 1px solid ${borderColor};
	border-bottom: 1px solid ${borderColor};
`;

const containerCss = css`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
`;

type DynamicPaginatedListProps<T> = {
	children: (props: ChildrenProps<T>) => React.ReactNode;
	displayButtons?: 'top' | 'bottom';
	currentPage: T[];
	hasPrevious: boolean;
	hasNext: boolean;
	onNext: () => void;
	onPrevious: () => void;
	Header?: React.FC;
	Footer?: React.FC;
	labels: {
		next: string;
		previous: string;
		loading: {
			title: string;
			description: string;
		};
	};
	isLoading?: boolean;
	hasError?: boolean;
	NoDataComponent?: React.FC;
	LoadingComponent?: React.FC;
	ErrorComponent?: React.FC;
};

export const DynamicPaginatedList = <T,>({
	children,
	displayButtons = 'top',
	currentPage,
	onNext,
	onPrevious,
	Header,
	Footer,
	hasNext,
	hasPrevious,
	labels,
	isLoading,
	hasError,
	NoDataComponent,
	LoadingComponent,
	ErrorComponent,
}: DynamicPaginatedListProps<T>) => {
	const hasNoData: boolean = !isLoading && currentPage && !currentPage?.length;

	if (hasError) {
		return ErrorComponent ? <ErrorComponent /> : <EmptyComponent />;
	}

	if (hasNoData) {
		return NoDataComponent ? <NoDataComponent /> : <EmptyComponent />;
	}

	return (
		<div css={containerCss} data-aid="index-DynamicPaginatedList">
				<SuspenseWrapper loading={isLoading} width={30}>
					<div css={[alignedFlexRowCss('space-between'), paddingBottomCss(20)]}>
						{Header ? <Header /> : <EmptyComponent />}
						{displayButtons === 'top' && (
							<Buttons
								onNext={onNext}
								onPrevious={onPrevious}
								hasNext={hasNext}
								hasPrevious={hasPrevious}
								labels={labels}
							/>
						)}
					</div>
					<div css={containerCss}>
						{currentPage?.map((data, index) => {
							const isLastItem: boolean = index === currentPage.length - 1;
							return children({ data, index, isLastItem });
						})}
					</div>
					<div css={alignedFlexRowCss('space-between')}>
						{Footer ? <Footer /> : <EmptyComponent />}
						{displayButtons === 'bottom' && (
							<Buttons
								onNext={onNext}
								onPrevious={onPrevious}
								hasNext={hasNext}
								hasPrevious={hasPrevious}
								labels={labels}
							/>
						)}
					</div>
				</SuspenseWrapper>
		</div>
	);
};

const EmptyComponent = () => <div css={flexGrowCss(1)} />;

type LoadingProps<T> = Pick<DynamicPaginatedListProps<T>, 'LoadingComponent' | 'labels'>;

const Loading = <T,>({ LoadingComponent, labels }: LoadingProps<T>) => {
	const { colors } = useColors();

	if (LoadingComponent) {
		return <LoadingComponent />;
	}

	return (
		<div css={loadingContainerCss(colors.bg_border_seperator!)}>
			<LoadingWithSpinner labels={labels.loading} />
		</div>
	);
};

type ButtonsProps<T> = Pick<
	DynamicPaginatedListProps<T>,
	'onNext' | 'onPrevious' | 'hasNext' | 'hasPrevious' | 'labels'
>;

const Buttons = <T,>({ onNext, onPrevious, hasPrevious, hasNext, labels }: ButtonsProps<T>) => {
	const { colors } = useColors();

	return (
		<div css={[flexRowCss, gapCss(20)]}>
			<Button
				data-aid="button-DynamicPaginatedList-prev"
				datatype="button"
				data-testid="previous-button"
				theme="basic"
				sizer="S"
				onClick={onPrevious}
				disabled={!hasPrevious}
				style={{ padding: 0 }}
			>
				<IconWithTooltip
					icon={Icons.previous}
					color={hasPrevious ? colors.tx_system! : colors.tx_text_disabled!}
					text={labels.previous}
					isTopLayer
					placement="top-center"
				/>
			</Button>
			<Button
				data-aid="button-DynamicPaginatedList-next"
				datatype="button"
				data-testid="next-button"
				theme="basic"
				sizer="S"
				onClick={onNext}
				disabled={!hasNext}
				style={{ padding: 0 }}
			>
				<IconWithTooltip
					icon={Icons.next}
					color={hasNext ? colors.tx_system! : colors.tx_text_disabled!}
					text={labels.next}
					isTopLayer
					placement="top-center"
				/>
			</Button>
		</div>
	);
};
