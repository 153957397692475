/** @jsx jsx */

import React, { FC, ReactNode } from 'react';
import { jsx, css } from '@emotion/react';
import { colors } from '../../../styles/theme';
import { flex } from 'app/react/styles/utils';

const container = css`
	display: flex;
	flex-direction: column;
	label {
		font-family: Montserrat;
		font-style: normal;
		font-weight: 500;
		font-size: 1.2rem;
		line-height: 1.5rem;
		text-transform: uppercase;
		color: ${colors.brandPrimary};
	}
	.section-body {
		position: relative;
		border-radius: 4px;
		margin: 1rem 0 2rem 0;
		border: 1px solid ${colors.borderPrimary};
	}
	.footer {
		margin-bottom: 2rem;
	}
`;

const labelRowContainerCss = css`
	${flex};
	justify-content: space-between;
	align-items: center;
`;

interface Props {
	label?: string;
	labelRowComponent?: ReactNode;
	footer?: ReactNode;
}

export const SectionContainer: FC<Props> = ({ label, labelRowComponent, children, footer }) => {
	return (
		<div data-aid="SectionContainer" className="not-mobile" css={container}>
			<div css={labelRowContainerCss}>
				{label && <label>{label}</label>}
				{labelRowComponent && labelRowComponent}
			</div>
			<div data-aid="SectionContainer-body" className="section-body">{children}</div>
			{footer && <div data-aid="SectionContainer-footer" className="footer">{footer}</div>}
		</div>
	);
};
