import { DEFAULT_COLOR_NAME, DEFAULT_MAINTENANCE_COLOR_NAME } from '../mocks/colorCodes';
import { useOrganization } from './useOrganization';

export const useColorCode = () => {
	const { colorCodes } = useOrganization();

	const getColorById = (colorCodeId: number) => {
		return colorCodes.find(colorCode => colorCode.id === colorCodeId);
	};

	const getColorCodeIdByName = (colorCodeName: string): number | undefined => {
		return colorCodes.find(colorCode => colorCode.name === colorCodeName)?.id;
	};

	const getDefaultColorCodeId = () => {
		return getColorCodeIdByName(DEFAULT_COLOR_NAME);
	};

	const getDefaultMaintenanceColorId = () => {
		return getColorCodeIdByName(DEFAULT_MAINTENANCE_COLOR_NAME);
	};

	const getDefaultMaintenanceColor = () => {
		return getColorById(getDefaultMaintenanceColorId());
	};

	return { getColorById, getColorCodeIdByName, getDefaultColorCodeId, getDefaultMaintenanceColor };
};
