import React from "react";

export const Program_Clinics = (props: any) => (
  <svg
    data-aid="svgProgram_Clinics"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.2055 32.6922C28.4001 32.8225 28.6163 32.8876 28.8325 32.8876C29.0595 32.8876 29.2865 32.8116 29.4595 32.703L32.0108 30.8136L34.5512 32.6162C34.9296 32.8768 35.4377 32.8768 35.8161 32.6053C36.1836 32.323 36.3458 31.8452 36.2052 31.4L35.2107 28.2291L37.816 26.1225C38.1727 25.8401 38.3133 25.3515 38.1619 24.9171C38.0106 24.4828 37.5998 24.1896 37.1457 24.1896H33.9999L33.027 21.1925C32.8864 20.7472 32.4648 20.4432 32 20.4432C31.5351 20.4432 31.1135 20.7472 30.973 21.1925L30 24.1896H26.8542C26.4001 24.1896 25.9893 24.4828 25.838 24.9171C25.6866 25.3515 25.8272 25.8293 26.1839 26.1225L28.7892 28.2074L27.7947 31.4868C27.665 31.932 27.8271 32.4207 28.2055 32.6922Z"
      fill="currentColor"
    />
    <path
      d="M36.4444 14.221H34.6666V15.9988H36.4444V14.221Z"
      fill="currentColor"
    />
    <path
      d="M27.5555 14.221H29.3333V15.9988H27.5555V14.221Z"
      fill="currentColor"
    />
    <path
      d="M32.8888 14.221H31.1111V15.9988H32.8888V14.221Z"
      fill="currentColor"
    />
    <path
      d="M34.6666 41.7765H36.4444V43.5543H34.6666V41.7765Z"
      fill="currentColor"
    />
    <path
      d="M29.3333 41.7765H27.5555V43.5543H29.3333V41.7765Z"
      fill="currentColor"
    />
    <path
      d="M31.1111 43.5543V41.7765H32.8888V43.5543H31.1111Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.3333 4.44321C53.3333 4.1321 53.1733 3.84766 52.9066 3.68766C52.6488 3.52766 52.32 3.51877 52.0444 3.6521L43.8933 7.7321H20.0977L11.9466 3.6521C11.6711 3.50988 11.3422 3.52766 11.0844 3.68766C10.8266 3.84766 10.6666 4.1321 10.6666 4.44321V44.4432C10.6666 44.7276 10.8 44.9943 11.0311 45.1543L31.4755 60.2654C31.6266 60.381 31.8133 60.4432 32 60.4432C32.1866 60.4432 32.3733 60.381 32.5244 60.2654L52.9688 45.1543C53.2 44.9943 53.3333 44.7276 53.3333 44.4432V4.44321ZM12.4444 43.9988V5.88321L14.2222 6.77002V42.6654C14.2222 42.9499 14.3555 43.2165 14.5866 43.3765L31.4755 55.821C31.6266 55.9365 31.8133 55.9988 32 55.9988C32.1866 55.9988 32.3733 55.9365 32.5244 55.821L49.4133 43.3765C49.6444 43.2165 49.7777 42.9499 49.7777 42.6654V6.77314L51.5555 5.88321V43.9988L32 58.4521L12.4444 43.9988ZM48 7.66307L43.9555 9.68766L20.0533 9.67877L16 7.65684V42.221L32 54.0077L48 42.221V7.66307Z"
      fill="currentColor"
    />
  </svg>
);
