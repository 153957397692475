import React from 'react';

export const Image = (props: any) => (
  <svg
    data-aid="svgImage"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.80718 8.62C9.08357 8.62 10.1183 7.58578 10.1183 6.31C10.1183 5.03422 9.08357 4 7.80718 4C6.53079 4 5.49608 5.03422 5.49608 6.31C5.49608 7.58578 6.53079 8.62 7.80718 8.62ZM21.7734 18.3605C22.2865 19.017 21.8188 19.9764 20.9855 19.9764H12.8798L12.8797 19.9765L12.8797 19.9767H2.99183C2.14715 19.9767 1.68295 18.9942 2.2193 18.3417L6.1051 13.6141C6.50513 13.1274 7.25012 13.1274 7.65016 13.6141L9.24742 15.5574C9.65381 16.0518 10.4136 16.0425 10.8078 15.5383L14.3996 10.9434C14.8 10.4313 15.575 10.4313 15.9753 10.9434L21.7734 18.3605Z"
      fill="currentColor"
    />
  </svg>
);
