/* @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import { CustomerActivities } from '../../activities';
import { useWindowSize } from '../../../../hooks/useWindowResize';

export const ActivitiesTab: React.FC<React.ComponentProps<typeof CustomerActivities>> = ({
	customerState,
	displayUserDetails,
}) => {
	useWindowSize();

	return <CustomerActivities customerState={customerState} displayUserDetails={displayUserDetails} />;
};
