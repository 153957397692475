import { MediaInterface } from './media';
import { Product, ProductPackage } from './product';

export enum CustomerTypeEnum {
  USER = 'user',
  FAMILY = 'family',
  ORGANIZATION = 'organization',
}
export enum MembershipTypeEnum {
  FIXED = 'fix_membership',
  ROLLING = 'rolling_membership',
}

export interface Membership {
  id: number;
  organizationId: number;
  name: string;
  description?: string;
  longDescription?: string;
  mainMedia?: MediaInterface;
  customerType: CustomerTypeEnum[];
  activity: number;
  facilityId: number;
  questionnaires: number[];
  minAgeYears: number;
  maxAgeYears: number;
  gender: number;
  tags: string[];
  maxMembers?: number;
  maxMaleMembers?: number;
  maxFemaleMembers?: number;
  membershipType: MembershipTypeEnum;
  startDate: string;
  endDate: string;
  registrationStartDate: string;
  registrationEndDate: string;
  durationMonths?: number;
  package: {
    parentProduct: Product; // the membership product will include currPrice and prices[] inside, as in all products
    children: {
      product: Product;
      relationType: ProductPackage;
    }[];
  };
}
