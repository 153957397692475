/** @jsxRuntime classic */
/** @jsx jsx */

import React, { FC, useState, useRef, useEffect } from 'react';
import { ETypography, ETypographyColor, Icons } from '../../types/theme';
import cx from 'classnames';
import { jsx, Theme, useTheme } from '@emotion/react';
import { StyledInputContainer, DeleteButtonStyle, InputContainer, charCounterCss } from './style';
import { getIcon, BnRemove } from '../../icons';
import { capitalize } from '../../functions';
import { Tooltip } from '../Tooltip';
import { ErrorMessage } from '../ErrorMessage';
import { Button } from '../Button';
import { Typography } from '../../atoms';
import { CARRIAGE_RETURNS_ALL_PLATFORMS } from '../../lib/constants';
import { filterCharacters } from '../../lib/utils';

export type TTextareaProps = { charCounter?: boolean; charLimit?: number };

export interface InputProps {
	placeholder?: string;
	label?: string;
	info?: string;
	helper?: string;
	helperContent?: string;
	sizer: 'M' | 'S';
	leftIcon?: Icons;
	rightIcon?: Icons;
	error?: string | undefined;
	setChange?: (val: string | number) => void;
	disableTrailing?: boolean;
	clear?: () => void;
	hideError?: boolean;
	breakLine?: boolean;
	noCarriageReturn?: boolean;
	// colors?: IColors;
}

export const Textarea: FC<InputProps & React.TextareaHTMLAttributes<HTMLTextAreaElement> & TTextareaProps> = ({
	leftIcon = '',
	rightIcon = '',
	children,
	sizer = 'S',
	label = '',
	info = '',
	helper = '',
	helperContent = '',
	hideError = false,
	error = undefined,
	disableTrailing = true,
	setChange = () => {},
	breakLine = false,
	clear = () => {},
	charCounter = false,
	charLimit = 100,
	...rest
}) => {
	const [isFocus, setFocus] = useState<boolean>(false);
	const [innerValue, setInnerValue] = useState<string>('');
	const [isHover, setHover] = useState<boolean>(false);
	const [visited, setVisited] = useState<boolean>(false);
	const inputRef = useRef(null);
	const colors = useTheme();

	const valueSetter = (e: any) => {
		setInnerValue(e.target.value);
		if (rest.onChange) {
			rest.onChange(e);
		}
	};

	const handleChange = (e: any) => {
		if (rest.noCarriageReturn && e.target?.value) {
			e.target.value = filterCharacters(e.target.value, ...CARRIAGE_RETURNS_ALL_PLATFORMS);
		}

		if (charCounter) {
			if (e.target.value.length <= charLimit) {
				valueSetter(e);
			}
		} else {
			valueSetter(e);
		}
	};

	useEffect(() => {
		if (typeof rest.value === 'string') {
			setInnerValue(String(rest.value));
		}
	}, [rest.value]);

	const addBreakLine = () => {
		handleChange({
			target: {
				value: `${rest?.value || innerValue}
___________________________________
`,
			},
		});
		if (inputRef.current) {
			//@ts-ignore
			inputRef.current.focus();
		}
	};

	return (
		<InputContainer
			className={cx({
				M: sizer === 'M',
				S: sizer === 'S',
			})}
			theme={{ ...colors }}
			style={{ ...rest.style }}
		>
			{label && (
				<div className="label" data-aid="Textarea-Input">
					<div>
						{capitalize(label)}
						{rest.required && ' *'}
					</div>
					{info && <Tooltip content={info}>{getIcon('info', 'l')}</Tooltip>}
				</div>
			)}
			{breakLine && (
				<div className="breakLine">
					<Button data-aid="button-CompTextarea-add" type="button" theme="basic" sizer="XS" onClick={addBreakLine}>
						<Typography color={ETypographyColor.secondary} type={ETypography.overline}>
							Add Divider
						</Typography>
					</Button>
				</div>
			)}

			<StyledInputContainer
				theme={{ ...colors }}
				className={cx({
					focus: isFocus,
					error: error && visited,
					hover: isHover,
					M: sizer === 'M',
					S: sizer === 'S',
				})}
				onClick={() => {
					if (inputRef.current) {
						//@ts-ignore
						inputRef.current.focus();
					}
				}}
				onMouseEnter={() => {
					setHover(true);
				}}
				onMouseLeave={() => {
					setHover(false);
				}}
			>
				{leftIcon && getIcon(leftIcon, 'r')}
				<textarea
					data-aid="CompTextarea-input"
					rows={4}
					ref={inputRef}
					{...rest}
					value={typeof rest.value === 'string' ? rest.value : innerValue}
					onChange={handleChange}
					onFocus={e => {
						setFocus(true);

						if (rest.onFocus) {
							rest.onFocus(e);
						}
					}}
					onBlur={e => {
						setFocus(false);
						setVisited(true);
						if (rest.onBlur) {
							rest.onBlur(e);
						}
					}}
				/>
				{charCounter && (
					<span css={charCounterCss}>
						<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
							{innerValue.length} / {charLimit}
						</Typography>
					</span>
				)}
				{!disableTrailing && innerValue.length > 0 && isHover && (
					<DeleteButton
						onClick={() => {
							if (inputRef && inputRef.current) {
								// @ts-ignore: Object is possibly 'null'.
								inputRef.current.focus();
							}
							setInnerValue('');
							if (clear) {
								clear();
							}
						}}
						theme={{ ...colors }}
					/>
				)}
				{rightIcon && getIcon(rightIcon, 'l')}
			</StyledInputContainer>
			{!hideError && (
				<div className="error" style={{ minHeight: '15px' }}>
					{visited && <ErrorMessage error={error || ''} />}
				</div>
			)}
			{helper && (
				<div className="helper">
					{helperContent && <Tooltip content={helperContent}>{getIcon('info', 'r')}</Tooltip>}
					<div>{capitalize(helper)}</div>
				</div>
			)}
		</InputContainer>
	);
};

interface DeleteButtonProps {
	theme: Theme;
	onClick: () => void;
}

const DeleteButton = ({ theme, onClick }: DeleteButtonProps) => {
	return (
		<DeleteButtonStyle theme={theme} onClick={onClick}>
			<BnRemove />
		</DeleteButtonStyle>
	);
};

const BreakLine = () => (
	<svg width="24" height="24" focusable="false" fill="currentColor">
		<path d="M4 11h16v2H4z" fillRule="inherit"></path>
	</svg>
);
