import React from 'react';

export const Cricket = (props: any) => (
  <svg
    data-aid="svgCricket"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.7631 2.0005C17.3154 2.01067 17.0832 2.18531 17.0832 2.18531L6.56416 12.7484C6.30305 13.0095 6.30305 13.4436 6.56416 13.7047L10.296 17.4365C10.5571 17.6976 10.9912 17.6976 11.2523 17.4365L21.8137 6.91751C21.8137 6.91751 22.7258 5.70182 20.5115 3.48747C19.267 2.24296 18.3378 1.98694 17.7631 2.0005ZM7.01347 2.01237C6.83883 2.01237 6.67098 2.03272 6.50651 2.06663C6.20471 2.46677 6.03685 3.69263 6.97108 4.58956C7.86293 5.44749 8.90397 5.4068 9.26512 5.18808C9.3499 4.94053 9.40076 4.67603 9.40076 4.39966C9.40076 3.08394 8.32919 2.01237 7.01347 2.01237ZM5.53837 2.52611C4.98393 2.96356 4.62618 3.64007 4.62618 4.39966C4.62618 5.71538 5.69775 6.78695 7.01347 6.78695C7.70355 6.78695 8.3258 6.49024 8.76325 6.02058C8.64286 6.02566 8.52418 6.02905 8.40719 6.02905C7.77306 6.02905 7.07451 5.79338 6.41834 5.16434C5.59941 4.37762 5.40442 3.37387 5.53837 2.52611ZM6.36578 14.9V15.3781C6.36578 15.8563 6.14876 16.2903 5.84526 16.5938L2.19481 20.2426C1.93539 20.5037 1.93539 20.9801 2.19481 21.2413L2.75941 21.8042C3.02052 22.0653 3.49696 22.0653 3.75807 21.8042L7.40683 18.1554C7.75441 17.8095 8.18847 17.6349 8.6666 17.6349H9.14304L6.36578 14.9Z"
      fill="currentColor"
    />
  </svg>
);
