/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, ReactNode } from 'react';
import { jsx, css } from '@emotion/react';
import { ETypography, ETypographyColor, IColors } from '../../../types';
import { Avatar, Typography } from '../../../atoms';
import { useColors } from '../../../hooks/useColors';
import dayjs from 'dayjs';
import { Pricify } from '../../../lib/price';
import { SectionContainer } from '../sectionContainer';
import { SkeletonWrapper } from '../../../components/skeletonWrapper';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { mobileOnly } from '../../../styles/utils';
import { useDetectPrint } from '../../../hooks/useIsPrint';
import { EInvoiceVariant } from '../../../organisms/invoiceTemplate/types';
import { PAYMENT_VARIANTS } from '../types';
import { Column, Row } from '../../../atoms/layoutPrimitives';
import { hrCss } from '../../../organisms/invoiceTemplate/styles';
import { formatDateTimeTz } from '../utils/date-formatting.util';

const containerCss = (colors: IColors) => css`
	border: 1px solid ${colors.bg_border_seperator};
	border-radius: 5px;
	display: flex;
	padding: 20px 16px;

	justify-content: space-between;
`;

const leftSideCss = css`
	display: flex;
	gap: 16px;
`;

const datesContainerCss = css`
	display: flex;
	gap: 4px;
	flex-direction: column;
`;

const totalsContainerCss = css`
	${datesContainerCss};
	align-items: flex-end;
`;

const customerCss = css`
	display: flex;
	gap: 8px;
`;

const moreInfoCss = css`
	${customerCss};
	${mobileOnly} {
		flex-direction: column;
	}
`;

const titleRightAlignCss = css`
	.caption-accented {
		align-self: flex-end;
	}
`;

export const BillingInfo = ({
	state,
	labels,
	isLoading,
	activeTab,
	variant,
	organizationTimeZone = 'utc',
	isShowTransactionFee = false,
}: {
	state: any; // Invoice & { customer: Customer }
	labels: any;
	isLoading: boolean;
	activeTab: string;
	variant: EInvoiceVariant;
	organizationTimeZone?: string;
	isShowTransactionFee: boolean;
}) => {
	const { isMobile } = useWindowSize();
	const { colors } = useColors();
	const isPrint = useDetectPrint();
	const invoiceState = state.invoice;
	const transactionFeeTotal = invoiceState.payments?.[0]?.totalFeesAmount ?? 0;
	const paymentVariant = PAYMENT_VARIANTS.includes(variant);

	if (activeTab === 'schedule' && !isPrint) {
		return <Fragment />;
	}

	const { formatDate, formatTime } = formatDateTimeTz(organizationTimeZone);

	return (
		<Fragment>
			<SectionContainer title={labels.billingInfo.title} className="no-print">
				<div css={containerCss(colors)} data-aid="index-billingInfo">
					<div css={leftSideCss}>
						<SubContainer title={labels.billingInfo.purchaseDate}>
							{dayjs(invoiceState.createdAt).isValid() && (
								<div css={datesContainerCss}>
									<SkeletonWrapper isLoading={isLoading} minHeight={10}>
										<Typography color={ETypographyColor.primary} type={ETypography.body2}>
											{formatDate(invoiceState.createdAt)}
										</Typography>
									</SkeletonWrapper>
									<SkeletonWrapper isLoading={isLoading} minHeight={10}>
										<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
											{formatTime(invoiceState.createdAt)}
										</Typography>
									</SkeletonWrapper>
								</div>
							)}
						</SubContainer>
						<SubContainer title={labels.billingInfo.customer}>
							<div css={customerCss}>
								<Avatar img={invoiceState.customer?.profilePicture} imgStyle={{ height: '36px', width: '36px' }} />
								<div css={datesContainerCss}>
									<SkeletonWrapper isLoading={isLoading} minHeight={10}>
										<Typography color={ETypographyColor.primary} type={ETypography.body2}>
											{invoiceState.customer?.firstName} {invoiceState.customer?.lastName}
										</Typography>
									</SkeletonWrapper>
									<div css={moreInfoCss}>
										<SkeletonWrapper isLoading={isLoading} minHeight={10}>
											<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
												{invoiceState.customer?.email}
											</Typography>
										</SkeletonWrapper>
										<SkeletonWrapper isLoading={isLoading} minHeight={10}>
											<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
												{invoiceState.customer?.phoneNumber}
											</Typography>
										</SkeletonWrapper>
									</div>
								</div>
							</div>
						</SubContainer>
					</div>
					{!isMobile && (
						<div css={titleRightAlignCss}>
							<SubContainer title={labels.billingInfo.total(paymentVariant)}>
								<div css={totalsContainerCss}>
									<SkeletonWrapper isLoading={isLoading} minHeight={10}>
										<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
											{Pricify(
												paymentVariant ? invoiceState.payments?.[0]?.price ?? 0 : invoiceState.price,
												undefined,
												undefined,
												true
											)}
										</Typography>
									</SkeletonWrapper>
									{isShowTransactionFee && (
										<SkeletonWrapper isLoading={isLoading} minHeight={10}>
											<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
												{labels.billingInfo.transactionFee(paymentVariant ? transactionFeeTotal : undefined)}
											</Typography>
										</SkeletonWrapper>
									)}
								</div>
							</SubContainer>
						</div>
					)}
				</div>
			</SectionContainer>
			<Column className="print">
				<Row fullWidth justify="space-between">
					<Typography color={ETypographyColor.primary} type={ETypography.caption}>
						{formatDate(invoiceState.createdAt)}
					</Typography>
					<Typography color={ETypographyColor.primary} type={ETypography.caption}>
						{formatTime(invoiceState.createdAt)}
					</Typography>
				</Row>
				<hr css={hrCss} />
				<Row fullWidth>
					<Row fullWidth justify="space-between">
						<Typography color={ETypographyColor.primary} type={ETypography.body2}>
							{invoiceState.customer?.firstName ?? 'placeholder'} {invoiceState.customer?.lastName ?? 'placeholder'}
						</Typography>
					</Row>
				</Row>
				<hr css={hrCss} />
			</Column>
		</Fragment>
	);
};

const subContainerCss = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
	min-width: 100px;
`;

const SubContainer = ({ title, children }: { title: string; children: ReactNode }) => {
	return (
		<div css={subContainerCss}>
			<Typography type={ETypography.captionAccented} color={ETypographyColor.primary}>
				{title}
			</Typography>
			{children}
		</div>
	);
};
