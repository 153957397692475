import * as  React from 'react';

export const BnMinus = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.625 12C3.625 11.2406 4.24061 10.625 5 10.625H19C19.7594 10.625 20.375 11.2406 20.375 12C20.375 12.7594 19.7594 13.375 19 13.375H5C4.24061 13.375 3.625 12.7594 3.625 12Z"
      fill="currentColor"
    />
  </svg>
);
