import React from 'react';


export const Excel = (props: any) => (
  <svg
    data-aid="svgExcel"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.375 5.12548H13.25V3.25048C13.25 3.06423 13.1675 2.88798 13.0238 2.76923C12.8813 2.65048 12.69 2.59923 12.51 2.63673L2.51 4.51173C2.21375 4.56673 2 4.82423 2 5.12548V18.8755C2 19.1755 2.21375 19.4342 2.51 19.4892L12.51 21.3642C12.5475 21.3717 12.5863 21.3755 12.625 21.3755C12.77 21.3755 12.9113 21.3255 13.0238 21.2317C13.1675 21.113 13.25 20.9355 13.25 20.7505V18.8755H21.375C21.72 18.8755 22 18.5955 22 18.2505V5.75048C22 5.40548 21.72 5.12548 21.375 5.12548ZM10.595 14.0892C10.8225 14.348 10.7963 14.743 10.5363 14.9705C10.4175 15.0742 10.2712 15.1255 10.125 15.1255C9.95125 15.1255 9.77875 15.053 9.655 14.9117L7.8375 12.8355L6.24375 14.8855C6.12 15.043 5.935 15.1255 5.75 15.1255C5.61625 15.1255 5.48125 15.083 5.36625 14.9942C5.09375 14.7817 5.045 14.3892 5.25625 14.1167L6.99875 11.8767L5.28 9.91173C5.0525 9.65298 5.07875 9.25798 5.33875 9.03048C5.5975 8.80298 5.99125 8.82798 6.22125 9.08923L7.78125 10.8717L9.6325 8.49173C9.845 8.22048 10.2375 8.17048 10.51 8.38298C10.7825 8.59423 10.8313 8.98673 10.6187 9.26048L8.61875 11.8305L10.595 14.0892ZM20.75 17.6255H13.25V16.3755H15.125C15.47 16.3755 15.75 16.0955 15.75 15.7505C15.75 15.4055 15.47 15.1255 15.125 15.1255H13.25V13.8755H15.125C15.47 13.8755 15.75 13.5955 15.75 13.2505C15.75 12.9055 15.47 12.6255 15.125 12.6255H13.25V11.3755H15.125C15.47 11.3755 15.75 11.0955 15.75 10.7505C15.75 10.4055 15.47 10.1255 15.125 10.1255H13.25V8.87548H15.125C15.47 8.87548 15.75 8.59548 15.75 8.25048C15.75 7.90548 15.47 7.62548 15.125 7.62548H13.25V6.37548H20.75V17.6255Z"
      fill="currentColor"
    />
  </svg>
);
