import React from 'react';

export const Home = (props: any) => (
  <svg
    data-aid="svgHome"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7152 2.28881L20.4242 10.1728C20.7902 10.5488 21.0002 11.0728 21.0002 11.6118V19.9878C21.0002 21.0908 20.1522 21.9878 19.1112 21.9878H15.0002H9.0002H4.8882C3.8472 21.9878 3.0002 21.0908 3.0002 19.9878V11.6118C3.0002 11.0728 3.2102 10.5488 3.5752 10.1738L11.2852 2.28881C11.6622 1.90381 12.3382 1.90381 12.7152 2.28881ZM18.9902 19.9878H16.0002V12.9878C16.0002 12.4348 15.5522 11.9878 15.0002 11.9878H9.0002C8.4472 11.9878 8.0002 12.4348 8.0002 12.9878V19.9878H5.0002L5.0062 11.5708L11.9982 4.41981L19.0002 11.6118L18.9902 19.9878ZM10.0002 19.9878H14.0002V13.9878H10.0002V19.9878Z"
      fill="currentColor"
    />
  </svg>
);
