import { userApi } from '@app/react/lib/api/userApi';
import { IErrorArr } from '@app/react/types/errors';
import { QuestionnaireDto } from '@bondsports/types';
import { useState } from 'react';

export const useQuestionnaire = () => {
	const [questionnaires, setQuestionnaires] = useState<QuestionnaireDto[]>([]);
	const [isErrorInFetchingQuestionnaires, setIsErrorInFetchingQuestionnaires] = useState(false);
	const [isLoadingQuestionnaires, setIsLoadingQuestionnaires] = useState(true);

	const fetchQuestionnaires = async (organizationId: number, userId: number) => {
		const response = await userApi.getUserQuestionnaires(organizationId, userId);
		if ((response as IErrorArr)?.err) {
			setIsErrorInFetchingQuestionnaires(true);
		} else {
			setQuestionnaires(response as QuestionnaireDto[]);
		}
		setIsLoadingQuestionnaires(false);
	};

	return { questionnaires, fetchQuestionnaires, isErrorInFetchingQuestionnaires, isLoadingQuestionnaires };
};
