import { css } from '@emotion/react';
import { flex, fullWidthCss } from '../../../styles/utils';
import { ThemeColors } from '../../../styles/theme';

export const svgColorCss = css`
	svg {
		height: 16px;
		width: auto;
		color: ${ThemeColors.tx_text_secondary};
	}
`;

export const containerCss = css`
	${flex};
	${fullWidthCss};
	${svgColorCss};
	margin-top: -15px; // Compensate for bad select component
`;

export const hyperlinkCss = css`
	cursor: pointer;
`;

export const alignCenterRowCss = css`
	align-items: center;
	.utils_tooltip {
		display: flex;
		align-items: center;
	}
`;
