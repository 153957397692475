/** @jsxRuntime classic */
/** @jsx jsx */

import { FC } from 'react';
import { jsx, Theme } from '@emotion/react';

import { calculateModalPosition } from './../utils';
import { SlotInModal } from './../../organisms/bo_calendar_slot/SlotInModal';
import { IEventComponentProps, IEventsGroup } from './../types';
import { moreModal } from './styles';
import { BnIcon } from '../../components/icons_v2';
import { Icons } from '../../types';

interface IProps {
	colors: Theme;
	EventComponent: FC<IEventComponentProps>;
	group: IEventsGroup;
	handleToggleModal: (index: number) => void;
	index: number;
}

export const MoreSlotsList = ({ colors, EventComponent, group, handleToggleModal, index }: IProps) => {
	const box: HTMLElement | null = document.querySelector(`#group-${index}`);
	const container = box?.parentElement;
	const boxW = container?.parentElement?.parentElement;
	const containerW = boxW?.parentElement;
	return (
		<div
			css={moreModal(colors, calculateModalPosition(box, container, boxW, containerW))}
			data-aid="MoreSlotsList-atoms"
		>
			<div className="more-modal-header">
				<div onClick={() => handleToggleModal(index)}>
					<BnIcon icon={Icons.close} />
				</div>
			</div>
			<div className="events-list">
				{group.items.map(slot => {
					return <SlotInModal key={slot.id} slot={slot} SlotWrapper={EventComponent} />;
				})}
			</div>
		</div>
	);
};
