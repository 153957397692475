import React from 'react';

export const Court = (props: any) => (
  <svg
    data-aid="svgCourt"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 14.4C13.3255 14.4 14.4 13.3255 14.4 12C14.4 10.6745 13.3255 9.60001 12 9.60001C10.6745 9.60001 9.60001 10.6745 9.60001 12C9.60001 13.3255 10.6745 14.4 12 14.4ZM12 16C14.2092 16 16 14.2091 16 12C16 9.79087 14.2092 8.00001 12 8.00001C9.79087 8.00001 8.00001 9.79087 8.00001 12C8.00001 14.2091 9.79087 16 12 16Z"
      fill="currentColor"
    />
    <path
      d="M14.2966 22.9C14.3638 22.6788 14.4 22.444 14.4 22.2008C14.4 20.8753 13.3255 19.8008 12 19.8008C10.6745 19.8008 9.60001 20.8753 9.60001 22.2008C9.60001 22.444 9.63619 22.6788 9.70346 22.9H8.06092C8.02089 22.673 8.00001 22.4393 8.00001 22.2008C8.00001 19.9916 9.79087 18.2008 12 18.2008C14.2092 18.2008 16 19.9916 16 22.2008C16 22.4393 15.9791 22.673 15.9391 22.9H14.2966Z"
      fill="currentColor"
    />
    <path
      d="M9.70346 1.10001C9.63619 1.32124 9.60001 1.55601 9.60001 1.79922C9.60001 3.12471 10.6745 4.19922 12 4.19922C13.3255 4.19922 14.4 3.12471 14.4 1.79922C14.4 1.55601 14.3638 1.32124 14.2966 1.10001H15.9391C15.9791 1.32705 16 1.5607 16 1.79922C16 4.00836 14.2092 5.79922 12 5.79922C9.79087 5.79922 8.00001 4.00836 8.00001 1.79922C8.00001 1.5607 8.02089 1.32705 8.06092 1.10001H9.70346Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.10001 4.00001C3.10001 2.39838 4.39839 1.10001 6.00001 1.10001H18C19.6016 1.10001 20.9 2.39838 20.9 4.00001V20C20.9 21.6016 19.6016 22.9 18 22.9H6.00001C4.39839 22.9 3.10001 21.6016 3.10001 20V4.00001ZM6.00001 2.90001C5.3925 2.90001 4.90001 3.39249 4.90001 4.00001V20C4.90001 20.6075 5.3925 21.1 6.00001 21.1H18C18.6075 21.1 19.1 20.6075 19.1 20V4.00001C19.1 3.39249 18.6075 2.90001 18 2.90001H6.00001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.70001 12C1.70001 11.5582 2.05818 11.2 2.50001 11.2H21.5C21.9418 11.2 22.3 11.5582 22.3 12C22.3 12.4418 21.9418 12.8 21.5 12.8H2.50001C2.05818 12.8 1.70001 12.4418 1.70001 12Z"
      fill="currentColor"
    />
  </svg>
);
