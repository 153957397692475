/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import { reasonSelectCss } from '../../../molecules/discount-amount-input/styles';
import { IOption, Select_v2 } from '../../../components/Select_v2';
import { i18n } from '../../../lib/i18n';

interface Props {
	options: IOption[];
	value?: string;
	onChange: (val: string) => void;
}

export const DiscountReasonSelect: FC<Props> = ({ options, value, onChange }) => {
	const labels = i18n.addDiscount.content.custom.reasonSelect;

	const handleReasonChange = (val: string) => {
		onChange(val);
	};

	return (
		<Select_v2
			value={value}
			onSelecte={handleReasonChange}
			options={options}
			isGroups={false}
			label={labels.label}
			placeholder={labels.placeholder}
			css={reasonSelectCss}
		/>
	);
};
