import React from "react";

export const GolfSimulator = (props: any) => (
  <svg
    data-aid="SvgGolfSimulator"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.04527 1.5C1.9157 1.5 1 2.5085 1 3.75255V18.7577C1 20.0017 1.9157 21.0102 3.04527 21.0102H7.81264C7.70898 20.2576 7.77272 19.4825 8.00386 18.7577H3.04527V3.75255H20.9547V18.7577H15.9961C16.2273 19.4825 16.291 20.2576 16.1874 21.0102H20.9547C22.0843 21.0102 23 20.0017 23 18.7577V3.75255C23 2.5085 22.0843 1.5 20.9547 1.5H3.04527Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.5C13.6012 23.5 14.8993 22.0704 14.8993 20.3069C14.8993 18.5433 13.6012 17.1137 12 17.1137C10.3987 17.1137 9.10067 18.5433 9.10067 20.3069C9.10067 22.0704 10.3987 23.5 12 23.5ZM12.3596 22.4494C12.4258 22.3765 12.4258 22.2583 12.3596 22.1854C12.2934 22.1124 12.1861 22.1124 12.1199 22.1854C12.0537 22.2583 12.0537 22.3765 12.1199 22.4494C12.1861 22.5223 12.2934 22.5223 12.3596 22.4494ZM13.0677 22.3741C13.1339 22.447 13.1339 22.5653 13.0677 22.6382C13.0015 22.7111 12.8941 22.7111 12.8279 22.6382C12.7617 22.5653 12.7617 22.447 12.8279 22.3741C12.8941 22.3012 13.0015 22.3012 13.0677 22.3741ZM12.9045 21.8655C12.9707 21.7925 12.9707 21.6743 12.9045 21.6014C12.8383 21.5285 12.7309 21.5285 12.6647 21.6014C12.5985 21.6743 12.5985 21.7925 12.6647 21.8655C12.7309 21.9384 12.8383 21.9384 12.9045 21.8655ZM13.6125 21.7791C13.6787 21.852 13.6787 21.9702 13.6125 22.0431C13.5463 22.116 13.439 22.116 13.3728 22.0431C13.3066 21.9702 13.3066 21.852 13.3728 21.7791C13.439 21.7061 13.5463 21.7061 13.6125 21.7791ZM13.4298 21.2815C13.496 21.2086 13.496 21.0904 13.4298 21.0175C13.3636 20.9445 13.2563 20.9445 13.1901 21.0175C13.1239 21.0904 13.1239 21.2086 13.1901 21.2815C13.2563 21.3544 13.3636 21.3544 13.4298 21.2815ZM14.1399 21.2059C14.2061 21.2788 14.2061 21.397 14.1399 21.4699C14.0736 21.5428 13.9663 21.5428 13.9001 21.4699C13.8339 21.397 13.8339 21.2788 13.9001 21.2059C13.9663 21.1329 14.0736 21.1329 14.1399 21.2059ZM13.9698 20.7029C14.036 20.63 14.036 20.5118 13.9698 20.4389C13.9036 20.366 13.7963 20.366 13.73 20.4389C13.6638 20.5118 13.6638 20.63 13.73 20.7029C13.7963 20.7758 13.9036 20.7758 13.9698 20.7029Z"
      fill="currentColor"
    />
    <path
      d="M11.7251 7.16927C11.7251 6.85826 11.4962 6.60614 11.2138 6.60614C10.9314 6.60614 10.7025 6.85826 10.7025 7.16927V13.0056H11.7251V7.16927Z"
      fill="currentColor"
    />
    <path
      d="M11.962 6.75286C12.1304 6.78002 12.2524 6.8043 12.3574 6.82522C12.6631 6.88607 12.8254 6.91837 13.5689 6.90996C13.8043 6.90729 14.0064 6.87521 14.2235 6.84072C14.4889 6.79858 14.7768 6.75286 15.1758 6.75286V9.19358C15.1758 9.19358 14.2588 9.01962 13.5689 9.19358C12.879 9.36754 11.962 9.19358 11.962 9.19358V6.75286Z"
      fill="currentColor"
    />
    <path
      d="M14.3516 14.4855C14.3516 14.9571 13.2819 15.3392 11.9621 15.3392C10.6423 15.3392 9.57248 14.9571 9.57248 14.4855C9.57248 14.014 10.6423 13.6317 11.9621 13.6317C13.2819 13.6317 14.3516 14.014 14.3516 14.4855Z"
      fill="currentColor"
    />
  </svg>
);
