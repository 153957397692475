import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import * as moment from 'moment-timezone';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import dayjs from 'dayjs';

export declare type TimeList = {
	timeValue: string;
	display: string;
};

@Injectable()
export class TimeService {
	constructor(private http: HttpClient) {}

	// convert a possibly wrongly created date/time to the same hour - but in a diff timezone
	// e.g. if the client is in the east coast (ET) creating an event @ 1/1/2020 11:00
	// in pacific time PT is 3 hours before ET
	// the wrongly created date is:
	// Wed Jan 01 2020 10:00:00 GMT-0500 (Eastern Standard Time)
	// when we really should be @
	// Wed Jan 01 2020 10:00:00 GMT-0800 (Pacific Standard Time)
	dateTimeInTimeZone(date: Date | string, timezone?: string): Date {
		if (!timezone) timezone = moment.tz.guess();
		let m = moment(date);
		return moment.tz(m.format('YYYY-MM-DDTHH:mm:ss'), timezone).toDate();
	}

	// hack #2
	// because the backoffice assumes everything happens in LOCAL TIME
	// getting the time:
	// Wed Jan 01 2020 10:00:00 GMT-0800 (Pacific Standard Time)
	// is being translated into 13:00:00 Eastern Time (assuming ran on ET)
	// so we trick the backoffice into wrong local time:
	// Wed Jan 01 2020 10:00:00 GMT-0500 (Eastern Standard Time)
	// which will later be converted back using #dateTimeInTimeZone
	dateFromTimeZone(date: Date | string, timezone?: string): Date {
		if (!timezone) timezone = moment.tz.guess();
		let m = moment(date);
		return new Date(moment.tz(m, timezone).format('YYYY-MM-DDTHH:mm:ss'));
	}

	// convert a local date / time to the same hour in UTC - just cause the server expects it
	// to be in that format :(
	// so "2021-02-03T19:00:00-05:00" will be converted to "2021-02-03T19:00:00Z" (although it's 5 hours off)
	localTimeToSameDateTimeInUTC(date: Date | string): string {
		return moment(date).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
	}

	convertTimeZone(date: Date | string, timezone?: string, format?: string): string {
		if (!timezone) timezone = moment.tz.guess();

		if (format) {
			return moment(date).tz(timezone).format(format);
		} else {
			return moment(date).tz(timezone).format();
		}
	}

	getTimezoneByLocation(lat: number, lon: number) {
		return this.http
			.get<any>(
				`https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lon}&timestamp=${moment()
					.add('1', 'hour')
					.format('X')}&key=${environment.GOOGLE_API_KEY}`
			)
			.pipe(
				map(response => response),
				map((response: any) => {
					if (response && response.status === 'OK') {
						return response.timeZoneId;
					} else {
						return null;
					}
				})
			);
	}

	replaceTimeZone(time: any, newTimeZone: string, oldTimeZone?: string) {
		/*
    if (oldTimeZone) {
      moment.tz.setDefault(oldTimeZone);
    }
    */

		// const current = moment(time);
		// const newDate = current.clone();
		// newDate.tz(newTimeZone);
		// newDate.add(current.utcOffset() - newDate.utcOffset(), 'minutes');
		//

		return dayjs(time).tz(newTimeZone, true);
	}

	prepareTZForDisplay(time: any, newTimeZone: string) {
		return dayjs(time).tz(newTimeZone).tz(dayjs.tz.guess(), true).toString()
	}

	switchTimeZone(date, newTimeZone = moment.tz.guess(), format = '') {
		return moment.tz(date, 'YYYY-MM-DDTHH:mm:ss', newTimeZone).format(format);
	}

	/*
	 *
	 * Time functions for new time-related components
	 *
	 * */
	getTimeSelectList = (): TimeList[] => {
		return [
			{ timeValue: '00:00', display: '12:00 AM' },
			{ timeValue: '00:05', display: '12:05 AM' },
			{ timeValue: '00:10', display: '12:10 AM' },
			{ timeValue: '00:15', display: '12:15 AM' },
			{ timeValue: '00:20', display: '12:20 AM' },
			{ timeValue: '00:25', display: '12:25 AM' },
			{ timeValue: '00:30', display: '12:30 AM' },
			{ timeValue: '00:35', display: '12:35 AM' },
			{ timeValue: '00:40', display: '12:40 AM' },
			{ timeValue: '00:45', display: '12:45 AM' },
			{ timeValue: '00:50', display: '12:50 AM' },
			{ timeValue: '00:55', display: '12:55 AM' },

			{ timeValue: '01:00', display: '1:00 AM' },
			{ timeValue: '01:05', display: '1:05 AM' },
			{ timeValue: '01:10', display: '1:10 AM' },
			{ timeValue: '01:15', display: '1:15 AM' },
			{ timeValue: '01:20', display: '1:20 AM' },
			{ timeValue: '01:25', display: '1:25 AM' },
			{ timeValue: '01:30', display: '1:30 AM' },
			{ timeValue: '01:35', display: '1:35 AM' },
			{ timeValue: '01:40', display: '1:40 AM' },
			{ timeValue: '01:45', display: '1:45 AM' },
			{ timeValue: '01:50', display: '1:50 AM' },
			{ timeValue: '01:55', display: '1:55 AM' },

			{ timeValue: '02:00', display: '2:00 AM' },
			{ timeValue: '02:05', display: '2:05 AM' },
			{ timeValue: '02:10', display: '2:10 AM' },
			{ timeValue: '02:15', display: '2:15 AM' },
			{ timeValue: '02:20', display: '2:20 AM' },
			{ timeValue: '02:25', display: '2:25 AM' },
			{ timeValue: '02:30', display: '2:30 AM' },
			{ timeValue: '02:35', display: '2:35 AM' },
			{ timeValue: '02:40', display: '2:40 AM' },
			{ timeValue: '02:45', display: '2:45 AM' },
			{ timeValue: '02:50', display: '2:50 AM' },
			{ timeValue: '02:55', display: '2:55 AM' },

			{ timeValue: '03:00', display: '3:00 AM' },
			{ timeValue: '03:05', display: '3:05 AM' },
			{ timeValue: '03:10', display: '3:10 AM' },
			{ timeValue: '03:15', display: '3:15 AM' },
			{ timeValue: '03:20', display: '3:20 AM' },
			{ timeValue: '03:25', display: '3:25 AM' },
			{ timeValue: '03:30', display: '3:30 AM' },
			{ timeValue: '03:35', display: '3:35 AM' },
			{ timeValue: '03:40', display: '3:40 AM' },
			{ timeValue: '03:45', display: '3:45 AM' },
			{ timeValue: '03:50', display: '3:50 AM' },
			{ timeValue: '03:55', display: '3:55 AM' },

			{ timeValue: '04:00', display: '4:00 AM' },
			{ timeValue: '04:05', display: '4:05 AM' },
			{ timeValue: '04:10', display: '4:10 AM' },
			{ timeValue: '04:15', display: '4:15 AM' },
			{ timeValue: '04:20', display: '4:20 AM' },
			{ timeValue: '04:25', display: '4:25 AM' },
			{ timeValue: '04:30', display: '4:30 AM' },
			{ timeValue: '04:35', display: '4:35 AM' },
			{ timeValue: '04:40', display: '4:40 AM' },
			{ timeValue: '04:45', display: '4:45 AM' },
			{ timeValue: '04:50', display: '4:50 AM' },
			{ timeValue: '04:55', display: '4:55 AM' },

			{ timeValue: '05:00', display: '5:00 AM' },
			{ timeValue: '05:05', display: '5:05 AM' },
			{ timeValue: '05:10', display: '5:10 AM' },
			{ timeValue: '05:15', display: '5:15 AM' },
			{ timeValue: '05:20', display: '5:20 AM' },
			{ timeValue: '05:25', display: '5:25 AM' },
			{ timeValue: '05:30', display: '5:30 AM' },
			{ timeValue: '05:35', display: '5:35 AM' },
			{ timeValue: '05:40', display: '5:40 AM' },
			{ timeValue: '05:45', display: '5:45 AM' },
			{ timeValue: '05:50', display: '5:50 AM' },
			{ timeValue: '05:55', display: '5:55 AM' },

			{ timeValue: '06:00', display: '6:00 AM' },
			{ timeValue: '06:05', display: '6:05 AM' },
			{ timeValue: '06:10', display: '6:10 AM' },
			{ timeValue: '06:15', display: '6:15 AM' },
			{ timeValue: '06:20', display: '6:20 AM' },
			{ timeValue: '06:25', display: '6:25 AM' },
			{ timeValue: '06:30', display: '6:30 AM' },
			{ timeValue: '06:35', display: '6:35 AM' },
			{ timeValue: '06:40', display: '6:40 AM' },
			{ timeValue: '06:45', display: '6:45 AM' },
			{ timeValue: '06:50', display: '6:50 AM' },
			{ timeValue: '06:55', display: '6:55 AM' },

			{ timeValue: '07:00', display: '7:00 AM' },
			{ timeValue: '07:05', display: '7:05 AM' },
			{ timeValue: '07:10', display: '7:10 AM' },
			{ timeValue: '07:15', display: '7:15 AM' },
			{ timeValue: '07:20', display: '7:20 AM' },
			{ timeValue: '07:25', display: '7:25 AM' },
			{ timeValue: '07:30', display: '7:30 AM' },
			{ timeValue: '07:35', display: '7:35 AM' },
			{ timeValue: '07:40', display: '7:40 AM' },
			{ timeValue: '07:45', display: '7:45 AM' },
			{ timeValue: '07:50', display: '7:50 AM' },
			{ timeValue: '07:55', display: '7:55 AM' },

			{ timeValue: '08:00', display: '8:00 AM' },
			{ timeValue: '08:05', display: '8:05 AM' },
			{ timeValue: '08:10', display: '8:10 AM' },
			{ timeValue: '08:15', display: '8:15 AM' },
			{ timeValue: '08:20', display: '8:20 AM' },
			{ timeValue: '08:25', display: '8:25 AM' },
			{ timeValue: '08:30', display: '8:30 AM' },
			{ timeValue: '08:35', display: '8:35 AM' },
			{ timeValue: '08:40', display: '8:40 AM' },
			{ timeValue: '08:45', display: '8:45 AM' },
			{ timeValue: '08:50', display: '8:50 AM' },
			{ timeValue: '08:55', display: '8:55 AM' },

			{ timeValue: '09:00', display: '9:00 AM' },
			{ timeValue: '09:05', display: '9:05 AM' },
			{ timeValue: '09:10', display: '9:10 AM' },
			{ timeValue: '09:15', display: '9:15 AM' },
			{ timeValue: '09:20', display: '9:20 AM' },
			{ timeValue: '09:25', display: '9:25 AM' },
			{ timeValue: '09:30', display: '9:30 AM' },
			{ timeValue: '09:35', display: '9:35 AM' },
			{ timeValue: '09:40', display: '9:40 AM' },
			{ timeValue: '09:45', display: '9:45 AM' },
			{ timeValue: '09:50', display: '9:50 AM' },
			{ timeValue: '09:55', display: '9:55 AM' },

			{ timeValue: '10:00', display: '10:00 AM' },
			{ timeValue: '10:05', display: '10:05 AM' },
			{ timeValue: '10:10', display: '10:10 AM' },
			{ timeValue: '10:15', display: '10:15 AM' },
			{ timeValue: '10:20', display: '10:20 AM' },
			{ timeValue: '10:25', display: '10:25 AM' },
			{ timeValue: '10:30', display: '10:30 AM' },
			{ timeValue: '10:35', display: '10:35 AM' },
			{ timeValue: '10:40', display: '10:40 AM' },
			{ timeValue: '10:45', display: '10:45 AM' },
			{ timeValue: '10:50', display: '10:50 AM' },
			{ timeValue: '10:55', display: '10:55 AM' },

			{ timeValue: '11:00', display: '11:00 AM' },
			{ timeValue: '11:05', display: '11:05 AM' },
			{ timeValue: '11:10', display: '11:10 AM' },
			{ timeValue: '11:15', display: '11:15 AM' },
			{ timeValue: '11:20', display: '11:20 AM' },
			{ timeValue: '11:25', display: '11:25 AM' },
			{ timeValue: '11:30', display: '11:30 AM' },
			{ timeValue: '11:35', display: '11:35 AM' },
			{ timeValue: '11:40', display: '11:40 AM' },
			{ timeValue: '11:45', display: '11:45 AM' },
			{ timeValue: '11:50', display: '11:50 AM' },
			{ timeValue: '11:55', display: '11:55 AM' },

			{ timeValue: '12:00', display: '12:00 PM' },
			{ timeValue: '12:05', display: '12:05 PM' },
			{ timeValue: '12:10', display: '12:10 PM' },
			{ timeValue: '12:15', display: '12:15 PM' },
			{ timeValue: '12:20', display: '12:20 PM' },
			{ timeValue: '12:25', display: '12:25 PM' },
			{ timeValue: '12:30', display: '12:30 PM' },
			{ timeValue: '12:35', display: '12:35 PM' },
			{ timeValue: '12:40', display: '12:40 PM' },
			{ timeValue: '12:45', display: '12:45 PM' },
			{ timeValue: '12:50', display: '12:50 PM' },
			{ timeValue: '12:55', display: '12:55 PM' },

			{ timeValue: '13:00', display: '1:00 PM' },
			{ timeValue: '13:05', display: '1:05 PM' },
			{ timeValue: '13:10', display: '1:10 PM' },
			{ timeValue: '13:15', display: '1:15 PM' },
			{ timeValue: '13:20', display: '1:20 PM' },
			{ timeValue: '13:25', display: '1:25 PM' },
			{ timeValue: '13:30', display: '1:30 PM' },
			{ timeValue: '13:35', display: '1:35 PM' },
			{ timeValue: '13:40', display: '1:40 PM' },
			{ timeValue: '13:45', display: '1:45 PM' },
			{ timeValue: '13:50', display: '1:50 PM' },
			{ timeValue: '13:55', display: '1:55 PM' },

			{ timeValue: '14:00', display: '2:00 PM' },
			{ timeValue: '14:05', display: '2:05 PM' },
			{ timeValue: '14:10', display: '2:10 PM' },
			{ timeValue: '14:15', display: '2:15 PM' },
			{ timeValue: '14:20', display: '2:20 PM' },
			{ timeValue: '14:25', display: '2:25 PM' },
			{ timeValue: '14:30', display: '2:30 PM' },
			{ timeValue: '14:35', display: '2:35 PM' },
			{ timeValue: '14:40', display: '2:40 PM' },
			{ timeValue: '14:45', display: '2:45 PM' },
			{ timeValue: '14:50', display: '2:50 PM' },
			{ timeValue: '14:55', display: '2:55 PM' },

			{ timeValue: '15:00', display: '3:00 PM' },
			{ timeValue: '15:05', display: '3:05 PM' },
			{ timeValue: '15:10', display: '3:10 PM' },
			{ timeValue: '15:15', display: '3:15 PM' },
			{ timeValue: '15:20', display: '3:20 PM' },
			{ timeValue: '15:25', display: '3:25 PM' },
			{ timeValue: '15:30', display: '3:30 PM' },
			{ timeValue: '15:35', display: '3:35 PM' },
			{ timeValue: '15:40', display: '3:40 PM' },
			{ timeValue: '15:45', display: '3:45 PM' },
			{ timeValue: '15:50', display: '3:50 PM' },
			{ timeValue: '15:55', display: '3:55 PM' },

			{ timeValue: '16:00', display: '4:00 PM' },
			{ timeValue: '16:05', display: '4:05 PM' },
			{ timeValue: '16:10', display: '4:10 PM' },
			{ timeValue: '16:15', display: '4:15 PM' },
			{ timeValue: '16:20', display: '4:20 PM' },
			{ timeValue: '16:25', display: '4:25 PM' },
			{ timeValue: '16:30', display: '4:30 PM' },
			{ timeValue: '16:35', display: '4:35 PM' },
			{ timeValue: '16:40', display: '4:40 PM' },
			{ timeValue: '16:45', display: '4:45 PM' },
			{ timeValue: '16:50', display: '4:50 PM' },
			{ timeValue: '16:55', display: '4:55 PM' },

			{ timeValue: '17:00', display: '5:00 PM' },
			{ timeValue: '17:05', display: '5:05 PM' },
			{ timeValue: '17:10', display: '5:10 PM' },
			{ timeValue: '17:15', display: '5:15 PM' },
			{ timeValue: '17:20', display: '5:20 PM' },
			{ timeValue: '17:25', display: '5:25 PM' },
			{ timeValue: '17:30', display: '5:30 PM' },
			{ timeValue: '17:35', display: '5:35 PM' },
			{ timeValue: '17:40', display: '5:40 PM' },
			{ timeValue: '17:45', display: '5:45 PM' },
			{ timeValue: '17:50', display: '5:50 PM' },
			{ timeValue: '17:55', display: '5:55 PM' },

			{ timeValue: '18:00', display: '6:00 PM' },
			{ timeValue: '18:05', display: '6:05 PM' },
			{ timeValue: '18:10', display: '6:10 PM' },
			{ timeValue: '18:15', display: '6:15 PM' },
			{ timeValue: '18:20', display: '6:20 PM' },
			{ timeValue: '18:25', display: '6:25 PM' },
			{ timeValue: '18:30', display: '6:30 PM' },
			{ timeValue: '18:35', display: '6:35 PM' },
			{ timeValue: '18:40', display: '6:40 PM' },
			{ timeValue: '18:45', display: '6:45 PM' },
			{ timeValue: '18:50', display: '6:50 PM' },
			{ timeValue: '18:55', display: '6:55 PM' },

			{ timeValue: '19:00', display: '7:00 PM' },
			{ timeValue: '19:05', display: '7:05 PM' },
			{ timeValue: '19:10', display: '7:10 PM' },
			{ timeValue: '19:15', display: '7:15 PM' },
			{ timeValue: '19:20', display: '7:20 PM' },
			{ timeValue: '19:25', display: '7:25 PM' },
			{ timeValue: '19:30', display: '7:30 PM' },
			{ timeValue: '19:35', display: '7:35 PM' },
			{ timeValue: '19:40', display: '7:40 PM' },
			{ timeValue: '19:45', display: '7:45 PM' },
			{ timeValue: '19:50', display: '7:50 PM' },
			{ timeValue: '19:55', display: '7:55 PM' },

			{ timeValue: '20:00', display: '8:00 PM' },
			{ timeValue: '20:05', display: '8:05 PM' },
			{ timeValue: '20:10', display: '8:10 PM' },
			{ timeValue: '20:15', display: '8:15 PM' },
			{ timeValue: '20:20', display: '8:20 PM' },
			{ timeValue: '20:25', display: '8:25 PM' },
			{ timeValue: '20:30', display: '8:30 PM' },
			{ timeValue: '20:35', display: '8:35 PM' },
			{ timeValue: '20:40', display: '8:40 PM' },
			{ timeValue: '20:45', display: '8:45 PM' },
			{ timeValue: '20:50', display: '8:50 PM' },
			{ timeValue: '20:55', display: '8:55 PM' },

			{ timeValue: '21:00', display: '9:00 PM' },
			{ timeValue: '21:05', display: '9:05 PM' },
			{ timeValue: '21:10', display: '9:10 PM' },
			{ timeValue: '21:15', display: '9:15 PM' },
			{ timeValue: '21:20', display: '9:20 PM' },
			{ timeValue: '21:25', display: '9:25 PM' },
			{ timeValue: '21:30', display: '9:30 PM' },
			{ timeValue: '21:35', display: '9:35 PM' },
			{ timeValue: '21:40', display: '9:40 PM' },
			{ timeValue: '21:45', display: '9:45 PM' },
			{ timeValue: '21:50', display: '9:50 PM' },
			{ timeValue: '21:55', display: '9:55 PM' },

			{ timeValue: '22:00', display: '10:00 PM' },
			{ timeValue: '22:05', display: '10:05 PM' },
			{ timeValue: '22:10', display: '10:10 PM' },
			{ timeValue: '22:15', display: '10:15 PM' },
			{ timeValue: '22:20', display: '10:20 PM' },
			{ timeValue: '22:25', display: '10:25 PM' },
			{ timeValue: '22:30', display: '10:30 PM' },
			{ timeValue: '22:35', display: '10:35 PM' },
			{ timeValue: '22:40', display: '10:40 PM' },
			{ timeValue: '22:45', display: '10:45 PM' },
			{ timeValue: '22:50', display: '10:50 PM' },
			{ timeValue: '22:55', display: '10:55 PM' },

			{ timeValue: '23:00', display: '11:00 PM' },
			{ timeValue: '23:05', display: '11:05 PM' },
			{ timeValue: '23:10', display: '11:10 PM' },
			{ timeValue: '23:15', display: '11:15 PM' },
			{ timeValue: '23:20', display: '11:20 PM' },
			{ timeValue: '23:25', display: '11:25 PM' },
			{ timeValue: '23:30', display: '11:30 PM' },
			{ timeValue: '23:35', display: '11:35 PM' },
			{ timeValue: '23:40', display: '11:40 PM' },
			{ timeValue: '23:45', display: '11:45 PM' },
			{ timeValue: '23:50', display: '11:50 PM' },
			{ timeValue: '23:55', display: '11:55 PM' },
		];
	};
}
