import { atom } from 'recoil';
import { Facility } from '../types/facility';
import { Resource } from '../types/resource';

const facilityState = atom<Facility>({
	key: 'facilityState',
	default: {},
});

const facilityId = atom<number | undefined>({
	key: 'facilityId',
	default: undefined,
});

const isFacilityFetching = atom<boolean>({
	key: 'isFacilityFetching',
	default: true,
});

const facilityResources = atom<Resource[]>({
	key: 'facilityResources',
	default: [],
});

const facilityLocation = atom<any>({
	key: 'facilityLocation',
	default: undefined,
});

const isRefreshFacilitiesNeeded = atom<boolean>({
	key: 'isRefreshFacilitiesNeeded',
	default: true,
});

export const facilityStore = {
	facilityState,
	isFacilityFetching,
	facilityResources,
	facilityId,
	facilityLocation,
	isRefreshFacilitiesNeeded,
};
