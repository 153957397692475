import { QuestionnaireDto } from '@bondsports/types';
import { EDateTimeFormats } from '../../../../../../types/times';
import { formatDate, getEndOfYearFromGivenDate } from '@app/react/lib/dates';

export const getSubRows = (
	questionnaire: QuestionnaireDto,
	orgQuestionnaireId: number,
	labels: { generalWaiver: string; form: string }
) => {
	return questionnaire.answerTitle.flatMap(answerTitle => {
		const signedDate = answerTitle.createdAt;
		return {
			id: questionnaire.id,
			name: questionnaire.title,
			dateCompleted: formatDate(signedDate, EDateTimeFormats.DAY_FORMAT),
			type: questionnaire.id === orgQuestionnaireId ? labels.generalWaiver : labels.form,
			download: { formId: questionnaire.id, answerTitleId: answerTitle.id },
			validUntil: getEndOfYearFromGivenDate(signedDate as Date, 1, EDateTimeFormats.DAY_FORMAT),
		};
	});
};
