/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment, useContext } from 'react';
import { jsx, css } from '@emotion/react';
import { SectionContainer } from '../../../molecules/invoicing/sectionContainer';
import { PaymentContext } from '../context';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor, IColors } from '../../../types';
import { capitalize } from '../../../functions';
import { Pricify } from '../../../lib/price';
import { justifyCenterCss } from '../../refundPopup/style';
import { useColors } from '../../../hooks/useColors';
import { PaymentMethodMapper } from '../../../molecules/invoicing/payments/PaymentMethodMapper';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { printFont } from '../../../styles/utils';

const bottomBorderCss = css`
	border-bottom: black 1px dashed;
`;

const printRowCss = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 1rem 0 1rem 0;
`;

const bottomPaddingCss = css`
	margin: 2rem 0 2rem 0;
`;

const methodContainerCss = (colors: IColors) => css`
	display: flex;
	justify-content: space-between;
	padding: 1rem;
	color: ${colors.tx_text_primary};
	align-items: center;
	border: 1px solid ${colors.tx_border_seperator};
	border-radius: 5px;
`;

const printContainerCss = css`
	@media print and (max-width: 10cm) {
		display: flex;
		font-weight: bolder;
		${printFont};
	}
	display: none;
	width: 100%;
	flex-direction: column;
`;

const notMobileCss = css`
	@media print and (max-width: 10cm) {
		display: none;
	}
`;

export const Payments = () => {
	const { colors } = useColors();
	const { isMobile } = useWindowSize();
	const { state, labels, isLoading } = useContext(PaymentContext);

	const primaryColor = isMobile ? ETypographyColor.black : ETypographyColor.primary;

	return (
		<Fragment>
			<div css={printContainerCss} data-aid="index-payments">
				<div css={[bottomBorderCss, printRowCss]}>
					<PaymentMethodMapper state={state} noIcons />
					<Typography type={ETypography.body2Accented} color={primaryColor}>
						{Pricify(state?.price)}
					</Typography>
				</div>
				<div css={[printRowCss, justifyCenterCss, bottomPaddingCss]}>
					<Typography type={ETypography.body1Accented} color={primaryColor}>
						{`THANK YOU`}
					</Typography>
				</div>
			</div>
			<div css={notMobileCss}>
				<SectionContainer title={labels.paymentsSection.title}>
					<div css={methodContainerCss(colors)}>
						<PaymentMethodMapper state={state} />
						<Typography color={primaryColor} type={ETypography.body2}>
							{Pricify(state.price)}
						</Typography>
					</div>
				</SectionContainer>
			</div>
		</Fragment>
	);
};
