import React from 'react';

export const Printer = (props: any) => (
	<svg data-aid="SvgPrinter" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M18 11C18.5523 11 19 10.5523 19 10C19 9.44772 18.5523 9 18 9C17.4477 9 17 9.44772 17 10C17 10.5523 17.4477 11 18 11Z"
			fill="currentColor"
		/>
		<path
			d="M16 10C16 10.5523 15.5523 11 15 11C14.4477 11 14 10.5523 14 10C14 9.44772 14.4477 9 15 9C15.5523 9 16 9.44772 16 10Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9 2C7.34315 2 6 3.34315 6 5V6H5C3.34315 6 2 7.34315 2 9V16C2 17.6569 3.34315 19 5 19H6V20C6 21.1046 6.89543 22 8 22H16C17.1046 22 18 21.1046 18 20V19H19C20.6569 19 22 17.6569 22 16V9C22 7.34315 20.6569 6 19 6H18V5C18 3.34315 16.6569 2 15 2H9ZM6 16V17H5C4.44772 17 4 16.5523 4 16V9C4 8.44772 4.44772 8 5 8H19C19.5523 8 20 8.44771 20 9V16C20 16.5523 19.5523 17 19 17H18V16C18.5523 16 19 15.5523 19 15C19 14.4477 18.5523 14 18 14H16.0018L16 14H8H6C5.44772 14 5 14.4477 5 15C5 15.5523 5.44772 16 6 16ZM8 16V20H16V16H8ZM16 6V5C16 4.44772 15.5523 4 15 4H9C8.44772 4 8 4.44772 8 5V6H16Z"
			fill="currentColor"
		/>
	</svg>
);
