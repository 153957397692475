/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import { Typography } from '../../../atoms';
import { Tag } from '../../../components/Tag';
import { ETypography, ETypographyColor, IColors } from '../../../types';
import { useColors } from '../../../hooks/useColors';
import { Tabs } from '../../tabs';
import { SkeletonWrapper } from '../../../components/skeletonWrapper';
import { flexCss, gapCss, printFont, printOnly } from '../../../styles/utils';
import { EInvoiceVariant } from '../../../organisms/invoiceTemplate/types';
import { EInvoicingTabs } from '../types';
import { PublicTag } from '../../PublicTag';

const headerContainerCss = (colors: IColors) => css`
	display: flex;
	gap: 8px;
	flex-direction: column;
	padding: 13px 23px 0 23px;
	background: ${colors.bg_white};
	box-shadow: 0 2px 22px rgb(61 64 67 / 7%);
	z-index: 1;

	${printOnly} {
		padding: 13px 23px;
	}

	@media print and (max-width: 10cm) {
		${printFont}
	}
`;

const leftSide = css`
	display: flex;
	gap: 16px;
	align-items: center;
`;

const PaymentColorObj = {
	paid: 'green',
	partial: 'yellow',
	not_paid: 'red',
	pending: 'blue',
	refund: 'blue',
	void: 'gray',
	scheduled: 'blue',
};

const HIDE_HEADER_VARIANTS = [
	EInvoiceVariant.BACKOFFICE,
	EInvoiceVariant.PRINT,
	EInvoiceVariant.BACKOFFICE_PREVIEW,
	EInvoiceVariant.PAYMENT,
	EInvoiceVariant.PAYMENT_PRINT,
];

export const Header = ({
	state,
	labels,
	isLoading,
	activeTab,
	setActiveTab,
	additional,
	variant,
}: {
	state: any;
	labels: any;
	isLoading: boolean;
	activeTab: EInvoicingTabs;
	setActiveTab?: (v: EInvoicingTabs) => void;
	additional: any;
	variant: EInvoiceVariant;
}) => {
	const { colors } = useColors();
	const invoiceState = state.invoice;
	const hideHeader = HIDE_HEADER_VARIANTS.includes(variant);

	const handleTabSelect = (v: string) => {
		setActiveTab && setActiveTab(EInvoicingTabs[v.toUpperCase()]);
	};

	const tabs =
		additional.appendix.length > 0
			? [
					{ title: 'Details', link: 'details' },
					{ title: 'Schedule', link: 'schedule' },
			  ]
			: [{ title: 'Details', link: 'details' }];

	return (
		<Fragment>
			{hideHeader ? (
				<Fragment />
			) : (
				<div css={headerContainerCss(colors)} data-aid="index-header">
					<div css={leftSide}>
						<SkeletonWrapper isLoading={isLoading}>
							<Typography type={ETypography.h6Accented} color={ETypographyColor.primary}>
								{labels.header.title} {invoiceState.id}
							</Typography>
						</SkeletonWrapper>
						<div css={[flexCss, gapCss(4)]}>
							{invoiceState.paymentStatus && (
								<Tag
									title={labels.payments[invoiceState.paymentStatus]}
									color={PaymentColorObj[invoiceState.paymentStatus]}
								/>
							)}
							{invoiceState.isPublic && <PublicTag />}
						</div>
					</div>
					<Tabs tabs={tabs} currentTab={activeTab} hideMargins handleTabSelect={handleTabSelect} />
				</div>
			)}
		</Fragment>
	);
};
