/** @jsxRuntime classic */
/** @jsx jsx */

import { ResourceThumbnail } from './ResourceThumbnail';
import React from 'react';
import { jsx, css } from '@emotion/react';
import { flex, flexCol } from '../../../styles/utils';
import { colors } from '../../../styles/theme';

const textCss = css`
	${flexCol};
	div {
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		display: flex;
		align-items: center;
	}

	.item-name {
		color: ${colors.brandPrimary};
		font-size: 1.4rem;
		line-height: 130%;
	}
	.item-description {
		color: ${colors.formInputBg};
		font-size: 1.2rem;
		line-height: 1.5rem;
	}
`;

export const LineItemColumn = ({ name, description, secondDescription, img, productType }) => (
	<div css={flex}>
		{img ? (
			<img src={img} />
		) : (
			//  Resource thumbnail
			<ResourceThumbnail resourceType={productType} />
		)}
		<div css={textCss}>
			<div className="item-name">{name}</div>
			{description && <span className="item-description">{description}</span>}
			{secondDescription && <span className="item-description">{secondDescription}</span>}
		</div>
	</div>
);
