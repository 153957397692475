/** @jsx jsx */

import { useReceipt } from '@app/react/hooks/useReceipt';
import { useState, Fragment, useRef, useEffect, useCallback } from 'react';
import { jsx, css } from '@emotion/react';
import { usePurchase } from '../../../hooks/purchase/usePurchase';
import { useSteps } from '@app/react/forms/FormTemplate/useSteps';
import { colors } from '../../../styles/theme';
import { TranslationEn } from '@assets/i18n/en';
import { ModalFooterWithTwoButtons, ModalHeaderWithPrimaryAndSecondaryLabels } from '@bondsports/utils';
import { customersApi } from '@app/react/lib/api/customersApi';
import { paymentApi } from '@app/react/lib/api/paymentApi';
import { FuturePaymentStatusEnum, PaymentMethodOption, PaymentMethodTypeEnum } from '@bondsports/types';
import { useLayout } from 'app/react/hooks/useLayout';
import { useStation } from 'app/react/hooks/useStation';
import { useToggle } from '@app/react/hooks/useToggle';
import { useErrorModalDepricated } from '../../shared/ErrorDepricated';
import { IPurchaseRes, PlatformsEnum } from '@app/react/types/orders';
import { useCart } from 'app/react/hooks/useCart';
import * as queryString from 'querystring';
import { useLocation } from 'react-router-dom';
import { Charge, FlowType } from '.';
import { Stripe } from 'stripe';
import { EStripeCardFundingType, TChargeScheduled, TPrintDetails } from '@app/react/hooks/purchase/types';
import { roundPriceCents } from '../../../lib/pricing';
import { ModalLayout } from '@bondsports/utils';
import SelectScheduleInstallments from '../Schedule/selectScheduleInstallments';

const labels = TranslationEn.customers.chargeInstallments;

const container = css`
	padding: 2rem;
	display: flex;
	align-items: center;
	flex-direction: column;

	svg {
		path {
			fill: ${colors.brandPrimary};
		}
	}

	h1 {
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 2rem;
		line-height: 2.4rem;
		text-align: center;
		color: ${colors.brandPrimary};
	}

	h2 {
		max-width: 400px;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 1.4rem;
		line-height: 130%;
		text-align: center;
		color: ${colors.formInputBg};
	}
`;

const selectPaymentMethodsContainer = css`
	position: relative;
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

enum ScheduledPaymentsStep {
	SELECT_INSTALLMENTS,
	SELECT_PAYMENTS,
}

interface IProps {
	totalAmount: number; // Display -> with fees
	totalToCharge: number; // To api -> without fees
	toggle: () => void;
	userId: number;
	invoiceId: number;
	organizationId: number;
}

export const Scheduled = ({ userId, toggle, invoiceId, organizationId, totalToCharge }: IProps) => {
	const { currentStep, nextStep, prevStep } = useSteps(0, 2);
	const [scheduledPayments, setScheduledPayments] = useState<any[]>([]);
	const [meta, setMeta] = useState<any>({});
	const { disabledScreenToggle } = useLayout();
	const { shiftId } = useStation();
	const { ErrorModalDepricated, ErrorToggle } = useErrorModalDepricated();
	const [isShowingDoneConfirmation, DoneConfirmationToggle] = useToggle();
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>({});
	const [selectInstallmentsPrice, setSelectInstallmentsPrice] = useState<number>(0);
	const [finalPrice, setFinalPrice] = useState<number>(0);
	const [feeAmount, setFeeAmount] = useState<number>(0);
	const tableRef = useRef<HTMLDivElement>(null);
	const [selectedIds, setSelectedIds] = useState<any>([]);
	const [isSelectAll, setIsSelectAll] = useState(false);
	const [page, setPage] = useState<number>(1);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const { selectedCustomer: customer, setSelectedCustomer: setCustomer, setSelectedCustomer } = useCart();
	const [paymentMethods, setPaymentMethods] = useState<
		| PaymentMethodOption[]
		| {
				err: string[];
		  }
	>([]);
	const [printDetails, setPrintDetails] = useState<TPrintDetails>({
		customerId: 0,
		paymentId: 0,
	});
	const location = useLocation();
	const { handleSendReceipt: sendReceipt, printReceipt } = useReceipt();
	const handlePriceOnSelect = (id: string, add?: boolean) => {
		if (add) {
			setSelectInstallmentsPrice(selectInstallmentsPrice + scheduledPayments.find(p => p.id === id).price);
		} else {
			setSelectInstallmentsPrice(selectInstallmentsPrice - scheduledPayments.find(p => p.id === id).price);
		}
	};
	const handlePrintReceipt = () => {
		printReceipt(String(printDetails.paymentId), printDetails.customerId);
	};
	const handleSendReceipt = (contactMethod: 'email' | 'sms', sendAddress: string) => {
		sendReceipt([printDetails.paymentId], contactMethod, sendAddress);
	};

	const getFinalPrice = async () => {
		const futurePrice = isSelectAll ? totalToCharge - selectInstallmentsPrice : selectInstallmentsPrice;
		if (futurePrice !== 0) {
			await paymentApi
				.getRecalcFee(
					organizationId,
					(selectedPaymentMethod?.type as PaymentMethodTypeEnum) || PaymentMethodTypeEnum.CARD,
					roundPriceCents(futurePrice),
					selectedPaymentMethod.card?.funding as EStripeCardFundingType
				)
				.then(res => {
					setFinalPrice(res.totalPrice);
					setFeeAmount(res.feeAmount);
				});
		} else {
			setFinalPrice(futurePrice);
		}
	};

	useEffect(() => {
		getFinalPrice();
	}, [selectedPaymentMethod, selectedIds, organizationId, isSelectAll]);

	const handleBack = () => {
		prevStep();
	};

	const getPaymentMethods = useCallback(async () => {
		const customerById = await customersApi.getCustomerByUserId(organizationId, userId);
		setCustomer(customerById.data);
		if (customerById.data) {
			const paymentMethods = await paymentApi.getCustomerPaymentMethods(
				organizationId,
				Number(customerById.data.entityId)
			);
			setPaymentMethods(paymentMethods);
			setSelectedPaymentMethod(paymentMethods[0]);
		}
	}, [organizationId, userId, invoiceId, page]);

	const getScheduledPayments = useCallback(async () => {
		setIsLoading(true);
		const futurePayments = await paymentApi.getFutureScheduledPayments(
			Number(organizationId),
			Number(invoiceId),
			Number(userId),
			Number(page)
		);
		setScheduledPayments(futurePayments.data);
		setMeta(futurePayments.meta);
		setIsLoading(false);
	}, [organizationId, invoiceId, userId, page]);

	useEffect(() => {
		getPaymentMethods();
		getScheduledPayments();
	}, [userId, organizationId, invoiceId, page]);

	const { handleChargeScheduled } = usePurchase();

	const handleCharge = async ({ token, type }) => {
		setIsLoading(true);
		const payload: TChargeScheduled = {
			query: { userId, invoiceId, organizationId },
			body: {
				paymentMethodData: { token, type },
				platform: PlatformsEnum.BO,
				shiftId,
				...(isSelectAll && selectedIds?.length && { excludedIds: selectedIds }),
				...(!isSelectAll && { includedIds: selectedIds }),
			},
		};
		try {
			const response = await handleChargeScheduled(payload);
			const errorCheck = (response as any).err;
			if (errorCheck) {
				throw new Error(errorCheck);
			}
			switch (response.status) {
				case FuturePaymentStatusEnum.FAILED:
					handlePurchaseError(response);
					break;
				default: {
					const customerRes = response.customer;
					if (customerRes && Object.keys(customer).length) {
						setSelectedCustomer({
							...customer,
							storedCredit: customerRes.storedCredit,
						});
					}
					const payingUserId = response.payment?.payingUserId;
					const paymentId = response.payment?.id;
					setPrintDetails({ customerId: payingUserId, paymentId });
					break;
				}
			}
		} catch (err) {
			handlePayError(err, false);
			nextStep();
			ErrorToggle();
		} finally {
			setIsLoading(false);
		}
	};
	const handlePayError = (err: string, toDisableScreenToggle = true) => {
		if (toDisableScreenToggle) {
			disabledScreenToggle(false);
		}
		setError(String(err));
		ErrorToggle();
		handleErrorCleanup();
	};
	const handleErrorCleanup = () => {
		prevStep();

		if (isShowingDoneConfirmation) {
			DoneConfirmationToggle();
		}
	};
	const handlePurchaseError = (response: IPurchaseRes) => {
		handleErrorCleanup();
	};

	useEffect(() => {
		const parsed = queryString.parse(location.search.replace('?', ''));
		if (parsed.page) {
			setPage(Number(parsed.page));
		}
	}, [location]);

	const handleSelectPaymentMethod = (paymentMethod?: Stripe.PaymentMethod) => {
		if (paymentMethod) {
			setSelectedPaymentMethod(paymentMethod);
		}
	};
	const alternativeModalCss = css`
		width: 100%;
		height: 100%;
		.modal-layout-header {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 1rem;
			align-items: center;
		}
		.modal-layout-footer {
			margin: 1rem 0;
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: end;
			gap: 6px;
			width: 100%;

			button {
				margin-top: 0.5rem;
			}
		}
		.modal-layout-content {
			min-width: 67.5rem;
			display: flex;
			height: 100%;
			margin-top: 2rem;
			margin-bottom: 2rem;
			border-bottom: 1px solid ${colors.borderSeperator};
			border-top: 1px solid ${colors.borderSeperator};
		}
	`;
	const noInstallmentsSelected =
		(!isSelectAll && !selectedIds.length) || (isSelectAll && selectedIds.length === meta.totalItems);
	return (
		<div data-aid="ScheduledPopup" css={container}>
			{currentStep === ScheduledPaymentsStep.SELECT_INSTALLMENTS ? (
				<ModalLayout data-aid="select-installments-modal" alternativeCss={alternativeModalCss}>
					<Fragment>
						<ModalLayout.Header data-aid="select-installments-modal-header">
							<ModalHeaderWithPrimaryAndSecondaryLabels
								labels={{ title: labels.title, subtitle: labels.subTitleFirstLine }}
							/>
						</ModalLayout.Header>
						<ModalLayout.Content data-aid="select-installments-modal-content">
							<SelectScheduleInstallments
								subject={labels.subject}
								isLoading={isLoading}
								scheduledPayments={scheduledPayments}
								meta={meta}
								handleSelectedInstallmentPrice={(id: string, add = false) => handlePriceOnSelect(id, add)}
								tableRef={tableRef}
								selectedIds={selectedIds}
								setSelectedIds={setSelectedIds}
								isAllSelected={isSelectAll}
								setIsAllSelected={setIsSelectAll}
								page={page}
								handleNext={nextStep}
							/>
						</ModalLayout.Content>
						<ModalLayout.Footer data-aid="select-installments-modal-footer">
							<ModalFooterWithTwoButtons
								isLoading={isLoading}
								labels={{
									cancel: currentStep === ScheduledPaymentsStep.SELECT_INSTALLMENTS ? labels.cancel : labels.back,
									next: labels.next,
								}}
								onNext={nextStep}
								onCancel={toggle}
								nextDisabled={noInstallmentsSelected}
							/>
						</ModalLayout.Footer>
					</Fragment>
				</ModalLayout>
			) : (
				<div css={selectPaymentMethodsContainer}>
					<Charge
						toggle={handleBack}
						totalAmount={finalPrice}
						originalPrice={finalPrice}
						userId={customer?.entityId}
						customer={customer}
						organizationId={organizationId}
						isAmountEditable={false}
						alternativeHandleCharge={handleCharge}
						handleClose={toggle}
						invoiceId={invoiceId}
						isScheduled={false}
						initialLabels={labels}
						showFeeWarning={true}
						isProcessing={isLoading}
						feeAmount={feeAmount}
						paymentMethods={paymentMethods as PaymentMethodOption[]}
						onSelectPaymentMethod={method => handleSelectPaymentMethod(method)}
						alternativeHandlePrintReceipt={handlePrintReceipt}
						alternativeHandleSendReceipt={handleSendReceipt}
						flowType={FlowType.SCHEDULED}
					/>
				</div>
			)}
			<ErrorModalDepricated message={error} />
		</div>
	);
};
