/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { ColorCode, ISlot } from '../types';
import { GenericContainer } from '../GenericContainer';
import { useSlot } from '../../../hooks/useSlot';

//TODO: check if theres a way to avoid inline style without !important..
/** inline css is rewuired here to enforce the style, otherwise it get override */
const containerChildInlineCss = {
	display: 'flex',
	overflowX: 'hidden' as any,
	width: 'max-content',
	alignItems: 'end',
	justifyContent: 'space-between',
	minWidth: '100%',
};

dayjs.extend(customParseFormat);
dayjs.extend(utc);

interface IProps {
	state: ISlot;
	colorCode?: ColorCode;
	isConflict?: boolean;
}

export const InternalWeekly = ({ state, colorCode, isConflict = false }: IProps) => {
	const { Name, SpacerX, DiffTime, StartTime, IconsContainer, isDraft } = useSlot({
		state,
		isConflict,
	});

	return (
		<GenericContainer isDraft={isDraft} colorCode={colorCode}>
			<div style={containerChildInlineCss} data-aid="InternalWeekly-EventSlot">
				<Name size="S" />
				<SpacerX width={4} />
				<span style={{ display: 'flex' }}>
					<StartTime />
					<SpacerX width={4} />
					<DiffTime />
				</span>
				<IconsContainer hidePaymentIcons />
			</div>
		</GenericContainer>
	);
};
