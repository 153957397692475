/* @jsx jsx */

import React, { Fragment, useCallback, useContext } from 'react';
import { ActivitiesEnum } from '../types/enums';
import { PunchPassView } from '../views/PunchPassView';
import { css, jsx } from '@emotion/react';
import { CustomerActivitiesContext } from '../customerActivitiesContext';
import { ProgramView } from '../views/ProgramView';

const activitySectionCss = css`
	min-height: 100%;
`;

export const ActivitySection: React.FC = () => {
	const { selectedActivity } = useContext(CustomerActivitiesContext);

	const ActivityView = useCallback(() => {
		switch (selectedActivity) {
			case ActivitiesEnum.PUNCH_PASS:
				return <PunchPassView />;
			case ActivitiesEnum.CLASS:
			case ActivitiesEnum.CLINIC:
			case ActivitiesEnum.CAMP:
			case ActivitiesEnum.CLUB_TEAM:
			case ActivitiesEnum.PRIVATE_LESSON:
				return <ProgramView />;
			default:
				return <Fragment />;
		}
	}, [selectedActivity]);

	return (
		<div data-aid="activitiesSection" css={activitySectionCss}>
			<ActivityView />
		</div>
	);
};
