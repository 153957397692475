import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { RCPaymentStatus } from '@rcenter/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

type PlayerShortDetails = {
	readonly name: string;
	readonly paymentStatus: RCPaymentStatus;
};

export type OpenInvoicePayload = {
	readonly invoiceId: number;
	readonly customerId: number;
};

@Component({
	selector: 'rc-remove-user-from-team-modal',
	templateUrl: './remove-user-from-team-modal.component.html',
	styleUrls: ['./remove-user-from-team-modal.component.scss'],
	exportAs: 'modal',
})
export class RemoveUserFromTeamModalComponent implements AfterViewInit {
	@Input() player: PlayerShortDetails;
	@Input() invoiceId?: number;
	@Input() customerId: number;
	@Output() close: EventEmitter<void> = new EventEmitter<void>();
	@Output() navigateInvoice: EventEmitter<OpenInvoicePayload> = new EventEmitter<OpenInvoicePayload>();

	@ViewChild('modal', { static: false }) public modal: ModalDirective;

	ngAfterViewInit(): void {
		this.modal.onHidden.subscribe(() => {
			this.close.emit();
		});
	}

	navigateToInvoice(event) {
		event.preventDefault();
		this.navigateInvoice.emit({
			invoiceId: this.invoiceId,
			customerId: this.customerId,
		});
		this.hideModal();
	}

	hasPayment() {
		return typeof this.invoiceId === 'number';
	}

	showModal() {
		this.modal.show();
	}

	hideModal() {
		this.modal.hide();
	}
}
