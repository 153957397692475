/** @jsxRuntime classic */
/** @jsx jsx */

import { FC, memo, useState } from 'react';
import { jsx } from '@emotion/react';
import dayjs from 'dayjs';
import useInterval from '../../../hooks/useInterval';
import { hourBoxLineHorizontal2 } from '../../styles';
import { useMiddlewareContext } from '../../../hooks/useMiddlewareContext';

export const HorizontalCurrentTimeLine: FC = memo(() => {
	const { getTopAndHeight, options } = useMiddlewareContext();

	const nowHours = Number(dayjs().format('HH'));
	const utcHours = Number(dayjs().format('HH'));
	const diff = nowHours - utcHours;

	const [marginLeft, setMarginLeft] = useState(
		getTopAndHeight(
			dayjs()
				.hour(nowHours + diff)
				.toDate()
				.getTime(),
			0,
			true
		).top
	);

	useInterval(() => {
		const now = dayjs()
			.hour(nowHours + diff)
			.toDate()
			.getTime();
		setMarginLeft(getTopAndHeight(now, 0, true).top);
	}, 1000);

	if (dayjs(options?.date).format('DD/MM/YYYY') !== dayjs().format('DD/MM/YYYY')) {
		return null;
	}

	return <div css={hourBoxLineHorizontal2(marginLeft)} />;
});
