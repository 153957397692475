/* @jsx jsx */
import React, { Fragment, useMemo, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { colors_v2 } from '../../../../../styles/theme';
import { ActivityDetailsCardProps } from '../../types/types';
import { cardCss, separatorCss } from '../../styles';
import { ProgramInfoDto, ProgramTypesEnum, SimpleResourceDto, SimpleSessionDto } from '@bondsports/types';
import {
	flexCol,
	fullWidthCss,
	gapCss,
	paddingBottomCss,
	pointerCss,
	verticalPaddingCss,
} from '../../../../../styles/utils';
import {
	ETypography,
	ETypographyColor,
	Icons,
	IconWithTooltip,
	TagColorsMapper,
	Typography,
	useModal,
} from '@bondsports/utils';
import { capitalize } from 'lodash';
import { formatDateRange, isEarlier } from '../../../../../lib/dates';
import { EDateTimeFormats } from '../../../../../types/times';
import { ProgramExpendableSection } from './ProgramExpendableSection';
import { useSports } from '../../../../../hooks/useSports';
import { ResourcesModal } from '../utils/ResourcesModal';

export type ProgramDetailsCardProps = ActivityDetailsCardProps<ProgramInfoDto> & { programType: ProgramTypesEnum };

export const ProgramDetailsCard: React.FC<ProgramDetailsCardProps> = ({
	index,
	displayUserDetails,
	data,
	onViewInvoice,
	onViewPayment,
	labels,
	timezone,
	programType,
	onNavigateToSession,
}) => {
	const { getSportById } = useSports();
	const { toggle: toggleResourcesModal, isShowing: isShowingResourcesModal } = useModal();

	const [resourcesToView, setResourcesToView] = useState<SimpleResourceDto[]>();

	const expired: boolean = useMemo(() => isEarlier(data.session.endDate, new Date()), [data.session.endDate]);

	return (
		<div
			datatype="card"
			data-testid="program-details"
			data-index={index}
			css={cardCss(!expired ? colors_v2.bg_white : colors_v2.tx_background_primary)}
		>
			<ProgramBasicInfo
				title={data.name}
				session={data.session}
				onNavigateToSession={() =>
					onNavigateToSession(data.session, { id: data.id, name: data.name, type: programType, sport: data.sport })
				}
			/>
			<ProductRegistrationInfo expired={expired} labels={labels} session={data.session} timezone={timezone} />
			<ProgramExpendableSection
				data={data}
				displayUserDetails={displayUserDetails}
				onViewInvoice={onViewInvoice}
				labels={labels}
				timezone={timezone}
				toggle={toggleResourcesModal}
				onViewPayment={onViewPayment}
				setResourcesView={setResourcesToView}
				getSportById={getSportById}
			/>
			<ResourcesModal
				resources={resourcesToView}
				isShowing={isShowingResourcesModal}
				toggle={toggleResourcesModal}
				labels={labels}
			/>
		</div>
	);
};

const ProgramBasicInfo: React.FC<{ title: string; session: SimpleSessionDto; onNavigateToSession: () => void }> = ({
	title,
	session,
	onNavigateToSession,
}) => {
	return (
		<div
			datatype="content"
			data-testid="program-basic-info"
			css={[flexCol, fullWidthCss, gapCss(4), separatorCss('bottom'), paddingBottomCss(16)]}
		>
			<Typography
				datatype="title"
				data-testid="program-name"
				type={ETypography.body1Accented}
				color={ETypographyColor.primary}
			>
				{capitalize(title)}
			</Typography>
			<span datatype="link" data-testid="session-link" onClick={onNavigateToSession} css={pointerCss}>
				<Typography type={ETypography.body2Link} color={ETypographyColor.primary}>
					{capitalize(session.name)}
				</Typography>
			</span>
		</div>
	);
};

const sectionFlexCss = css`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

type ProgramRegistrationInfoProps = Pick<ActivityDetailsCardProps<ProgramInfoDto>, 'labels' | 'timezone'> & {
	expired: boolean;
	session: SimpleSessionDto;
};

const ProductRegistrationInfo: React.FC<ProgramRegistrationInfoProps> = ({ labels, timezone, expired, session }) => {
	return (
		<Fragment>
			<div css={[sectionFlexCss, verticalPaddingCss(16)]}>
				<span datatype="status-icon" data-testid="dates-status" data-status={!expired ? 'active' : 'expired'}>
					<IconWithTooltip
						icon={!expired ? Icons.indications_approved : Icons.indications_cancelled}
						color={!expired ? TagColorsMapper.green.primary : TagColorsMapper.liteGray.primary}
						size={16}
						text={!expired ? labels.active : labels.expired}
					/>
				</span>
				<Typography
					datatype="date"
					data-testid="program-session-dates"
					type={ETypography.body2}
					color={ETypographyColor.primary}
				>
					{formatDateRange(session.startDate, session.endDate, EDateTimeFormats.DAY_FORMAT, timezone)}
				</Typography>
			</div>
		</Fragment>
	);
};
