/* @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import { paddingBottomCss, verticalPaddingCss } from '../../../../../styles/utils';
import { ETypography, ETypographyColor, Icons, IconWithTooltip, TagColorsMapper, Typography } from '@bondsports/utils';
import { formatDateRange } from '../../../../../lib/dates';
import { EDateTimeFormats } from '../../../../../types/times';
import { IconWrapper } from '../../../../shared/IconWrapper';
import { PunchPassDetailsCardProps } from './types';
import { SimpleSessionDto } from '@bondsports/types';

const sectionFlexCss = css`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

type PunchPassValidityInfoProps = Pick<PunchPassDetailsCardProps, 'labels' | 'timezone'> & {
	expired: boolean;
	isValidPass: boolean;
	session: SimpleSessionDto;
	totalPasses: number;
	passesLeft: number;
};

export const PunchPassValidityInfo: React.FC<PunchPassValidityInfoProps> = ({
	labels,
	timezone,
	isValidPass,
	expired,
	session,
	totalPasses,
	passesLeft,
}) => {
	return (
		<div>
			<div css={[sectionFlexCss, verticalPaddingCss(16)]}>
				<span datatype="status-icon" data-testid="dates-status" data-status={!expired ? 'active' : 'expired'}>
					<IconWithTooltip
						icon={!expired ? Icons.indications_approved : Icons.indications_cancelled}
						color={!expired ? TagColorsMapper.green.primary : TagColorsMapper.liteGray.primary}
						size={16}
						text={!expired ? labels.active : labels.expired}
					/>
				</span>
				<Typography
					datatype="date"
					data-testid="punch-pass-dates"
					type={ETypography.body2}
					color={ETypographyColor.primary}
				>
					{formatDateRange(session.startDate, session.endDate, EDateTimeFormats.DAY_FORMAT, timezone)}
				</Typography>
			</div>
			<div css={[sectionFlexCss, paddingBottomCss(16)]}>
				<span datatype="status-icon" data-testid="punches-left-status" data-status={isValidPass ? 'valid' : 'warning'}>
					<IconWrapper
						icon={isValidPass ? Icons.indications_approved : Icons.indications_alert}
						color={isValidPass ? TagColorsMapper.green.primary : TagColorsMapper.red.primary}
						size={16}
					/>
				</span>
				<Typography
					datatype="counter"
					data-testid="punches-left"
					type={ETypography.body2}
					color={ETypographyColor.primary}
				>
					{labels.punchesCounter(totalPasses, passesLeft)}
				</Typography>
			</div>
		</div>
	);
};
