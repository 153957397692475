import React from "react";

export const ReservationStatusPending = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
      fill="#F7B500"
    />
    <path
      d="M7.96875 3.25C9.60572 3.25 10.9375 4.58178 10.9375 6.21875C10.9375 7.65206 9.91566 8.85203 8.5625 9.12694V9.78125C8.5625 10.1096 8.29709 10.375 7.96875 10.375C7.64041 10.375 7.375 10.1096 7.375 9.78125L7.375 8.59375C7.375 8.26541 7.64041 8 7.96875 8C8.95081 8 9.75 7.20081 9.75 6.21875C9.75 5.23669 8.95081 4.4375 7.96875 4.4375C6.98669 4.4375 6.1875 5.23669 6.1875 6.21875C6.1875 6.54709 5.92209 6.8125 5.59375 6.8125C5.26541 6.8125 5 6.54709 5 6.21875C5 4.58178 6.33178 3.25 7.96875 3.25Z"
      fill="white"
    />
    <path
      d="M7.375 12.1563C7.375 11.8279 7.64041 11.5625 7.96875 11.5625C8.2971 11.5625 8.5625 11.8279 8.5625 12.1563C8.5625 12.4846 8.2971 12.75 7.96875 12.75C7.64041 12.75 7.375 12.4846 7.375 12.1563Z"
      fill="white"
    />
  </svg>
);
