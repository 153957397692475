/** @jsx jsx */
import { FrameContainer } from 'app/react/components/customers/utils/FrameContainer';
import { TranslationEn } from 'assets/i18n/en';
import React, { Fragment, useEffect, useState } from 'react';
import { ResourceItem } from '../../components/seasonDashboard/schedulePopover';
import { jsx, css } from '@emotion/react';

const sessionsContainer = css`
	display: flex;
	flex-wrap: wrap;
`;

export const SeasonSessions = ({ sessions = [] }) => {
	return (
		<Fragment>
			{sessions.length !== 0 ? (
				<FrameContainer
					margin="0 1rem 2rem 1rem"
					width={-1}
					title={TranslationEn.season.dashboard.seasonSchedule.editSession.title}
				>
					<div css={sessionsContainer}>
						{sessions.map((session, index) => (
							<ResourceItem
								key={index}
								resource={session}
								checked={false}
								handleSelection={() => {}}
								checkable={false}
							/>
						))}
					</div>
				</FrameContainer>
			) : undefined}
		</Fragment>
	);
};
