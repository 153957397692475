/** @jsxRuntime classic */
/** @jsx jsx */

import React, { Fragment, Key, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { jsx, css, Theme, useTheme } from '@emotion/react';
import { ETypography, ETypographyColor, IColors, Icons } from '../../types';
import { TGroup, TOption } from './types';
import { Typography } from '../../atoms';
import { capitalize } from '../../functions';
import { BnIcon } from '../icons_v2';
import { flexCss, fullWidthCss } from '../../styles/utils';
import { SelectAllOptions } from './SelectAllOptions';
import { SelectOption } from './SelectOption';
import { SizeEnum } from '../../lib/constants';
import { flexCol } from '../../molecules/invoicing/appendix/lib';
import { hasMorePages } from '../../lib/pagination';

const containerCss = css`
	display: flex;
	align-items: center;
	padding: 8px;
	padding-left: 16px;
`;

const iconCss = (colors: IColors) => css`
	width: 20px;
	height: 20px;
	color: ${colors.bg_text_primary};
`;

type GroupProps<T, U> = {
	label: string;
	options: TOption<U>[];
	onSelect: (val: any) => void;
	group: TGroup<T, U>;
	value?: U[];
	isMultiple?: boolean;
	selectedValuesArray: U[];
	isFinalPage?: boolean;
	isLoading?: boolean;
	handleLoadNextPage?: () => void;
	enableSelectAll?: boolean;
	handleSelectAll?: () => void;
	handleRemoveAll?: () => void;
	selectAllLabel?: string;
	size?: SizeEnum;
	selectedGroup?: TGroup<T, U>;
	onSelectGroup: (group?: TGroup<T, U>) => void;
	isGroups?: boolean;
	searchValue?: string;
	usePagination: boolean;
	LoadingComponent: React.ReactNode;
	EndOptionComponent: React.ReactNode;
	isLastGroup?: boolean;
	NoOptionsComponent: React.ReactNode;
};

export const Group = <T, U>({
	label,
	options,
	onSelect,
	group,
	value = [],
	isMultiple = false,
	selectedValuesArray,
	isFinalPage,
	isLoading,
	handleLoadNextPage,
	enableSelectAll,
	handleSelectAll,
	handleRemoveAll,
	selectAllLabel,
	size,
	selectedGroup,
	onSelectGroup,
	isGroups,
	searchValue,
	usePagination,
	LoadingComponent,
	EndOptionComponent,
	isLastGroup,
	NoOptionsComponent,
}: GroupProps<T, U>) => {
	const theme: Theme = useTheme();
	const isOpen: boolean = !isGroups || selectedGroup?.id === group.id;
	const isAllowSelectAll: boolean = Boolean(options.length > 0 && isMultiple && enableSelectAll);

	const setIsOpen = (val: boolean): void => onSelectGroup(val ? group : undefined);

	const observer = useRef<IntersectionObserver | null>(null);
	const lastOptionElementRef = useCallback(
		(node: Element): void => {
			if (isLoading) {
				return;
			}

			if (observer?.current?.disconnect) {
				observer?.current?.disconnect();
			}

			observer.current = new IntersectionObserver(entries => {
				const [entry] = entries;
				if (entry.isIntersecting && !isFinalPage && hasMorePages(group.meta!)) {
					handleLoadNextPage?.();
				}
			});

			if (node) {
				observer?.current?.observe(node);
			}
		},
		[isLoading, isFinalPage]
	);

	const toggle = () => setIsOpen(!isOpen);

	const groupSelectAll = () => handleSelectAll?.();

	const groupRemoveAll = () => handleRemoveAll?.();

	const isIndeterminate = Boolean(
		(selectedGroup?.selected?.length && !selectedGroup?.allSelected) ||
			(selectedGroup?.allSelected && selectedGroup?.excluded?.length)
	);

	const optionsComponent = useMemo(() => {
		return !options?.length && !isLoading
			? NoOptionsComponent
			: options.map((option: TOption<U>, index: number) => (
					<SelectOption
						key={option.value as Key}
						colors={theme}
						index={index}
						size={size ?? SizeEnum.SMALL}
						onSelect={val => onSelect([val])}
						option={option}
						isMultiple={isMultiple}
						selectedValuesArray={selectedValuesArray}
						value={value}
						lastOptionElementRef={index === options.length - 1 && usePagination ? lastOptionElementRef : null}
					/>
			  ));
	}, [
		options,
		isLoading,
		NoOptionsComponent,
		theme,
		size,
		onSelect,
		isMultiple,
		selectedValuesArray,
		value,
		usePagination,
		lastOptionElementRef,
	]);

	return (
		<Fragment>
			{isGroups && (
				<div css={containerCss} data-aid="Group-GroupsOptions">
					<Typography type={ETypography.captionAccented} color={ETypographyColor.primary}>
						{capitalize(label)}
					</Typography>
					<BnIcon icon={isOpen ? Icons.dropdown_active : Icons.dropdown} onClick={toggle} css={iconCss(theme)} />
				</div>
			)}

			{isOpen && (
				<div css={flexCol}>
					{isAllowSelectAll && (
						<SelectAllOptions
							handleSelectAll={groupSelectAll}
							handleRemoveAll={groupRemoveAll}
							selectAllLabel={selectAllLabel!}
							theme={theme}
							isIndeterminate={isIndeterminate}
							isChecked={Boolean(selectedGroup?.allSelected) && !isIndeterminate}
							searchValue={searchValue}
						/>
					)}
					{optionsComponent}
					<EndOfOptions
						padding={isLastGroup ? '0px 0px 8px 0px' : ''}
						isLoading={isLoading!}
						LoadingComponent={LoadingComponent}
						EndComponent={EndOptionComponent}
					/>
				</div>
			)}
		</Fragment>
	);
};

const endOfOptionsCss = (padding?: string) => css`
	${flexCss};
	${fullWidthCss};
	justify-content: center;
	${padding && `padding: ${padding}`};
`;

type EndOfOptionsProps = {
	isLoading: boolean;
	LoadingComponent: React.ReactNode;
	EndComponent: React.ReactNode;
	padding?: string;
};

const EndOfOptions = ({ isLoading, LoadingComponent, EndComponent, padding }: EndOfOptionsProps) => {
	return <span css={endOfOptionsCss(padding)}>{isLoading ? LoadingComponent : EndComponent}</span>;
};
