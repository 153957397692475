import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeaguesService } from '@app/shared/services/leagues/leagues.service';
import { ToastrService } from 'ngx-toastr';
import { RCSeasonTeam, RCUserInvite } from '@rcenter/core';

@Component({
	selector: 'rc-team-player-invite-customer',
	templateUrl: './invite-customer.component.html',
})
export class InviteCustomerComponent implements OnInit {
	@Input() inviteForm: FormGroup;
	@Input() currentTeamId: number;
	@Input() teams: RCSeasonTeam[];
	@Input() loading: boolean;
	@Output() hide = new EventEmitter<void>();
	@Output() submit = new EventEmitter<FormGroup>();

	constructor(private fb: FormBuilder, private leaguesService: LeaguesService) {}

	ngOnInit() {
		this.inviteForm = this.fb.group({
			firstName: '',
			lastName: '',
			asCaptain: false,
			phoneNumber: [''],
			email: ['', Validators.compose([])],
		});
	}

	/**
	 * After the user has been invite we want to update the UI
	 * with the newly created invitation received from the server.
	 * @param invitees
	 */
	addInviteeToTeam(invitees: RCUserInvite[]) {
		if (!this.teams || !this.teams.length) return;

		this.teams.forEach(seasonTeam => {
			// we want to update only the currently select team
			if (seasonTeam.team && seasonTeam.team.id === this.currentTeamId) {
				seasonTeam.userInvites = seasonTeam.userInvites.concat(invitees);
			}
		});

		// we want to notify the other components about the changes made to the teams
		// after event is broadcasted they will recalculate the relevant ViewModels
		this.leaguesService.setCurrentTeams(this.teams);
	}
}
