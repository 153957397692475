import * as  React from 'react';

export const SmallLogo = () => (
  <svg
    width="10"
    height="24"
    viewBox="0 0 10 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 21.0512C0 21.25 0.159545 21.4289 0.378919 21.4289H5.32481C7.69804 21.4289 9.6724 19.8187 9.6724 17.5923C9.6724 15.7635 8.05701 14.7895 7.00003 14.3521C7.93735 13.8552 9.31343 12.742 9.31343 11.2113C9.31343 9.12408 7.37895 7.51392 5.02566 7.51392H0.378919C0.159545 7.51392 0 7.69282 0 7.89161V21.0512ZM3.11112 13.1594V10.3764H4.66668C5.42452 10.3764 6.0627 10.933 6.0627 11.7282C6.0627 12.5233 5.42452 13.1594 4.66668 13.1594H3.11112ZM3.11112 18.5863V15.7436H4.72651C5.50429 15.7436 6.36185 16.34 6.36185 17.1351C6.36185 17.9501 5.70373 18.5863 4.92594 18.5863H3.11112Z"
      fill="white"
    />
  </svg>
);
