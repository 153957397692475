import React from 'react';

export const ResizeIcon = (props: any) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.51 3.07996L3.08002 19.51C3.17002 19.85 3.35002 20.16 3.59002 20.41C3.84002 20.65 4.15002 20.83 4.49002 20.92L20.93 4.48996C20.74 3.79996 20.2 3.25996 19.51 3.07996ZM19 21C19.55 21 20.05 20.78 20.41 20.41C20.78 20.05 21 19.55 21 19V17L17 21H19ZM12.12 21H9.29002L21 9.28996V12.12L12.12 21Z"
			fill="currentColor"
			fillOpacity="0.54"
		/>
	</svg>
);
