import { useRecoilState } from 'recoil';
import { Store } from '../store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const usePermissions = () => {
	const [permissions, setPermissions] = useRecoilState(Store.permissions);
	const [currentTab, setCurrentTab] = useRecoilState(Store.currentTab);
	const [isPermissionFetched, setPermissionFetched] = useRecoilState(Store.isPermissionFetched);

	const getPermissions = async (fetchPermissios: () => Promise<{ data: any[] }>, origin: string) => {
		const lastRefreshedTimestamp = localStorage.getItem(`lastRefreshedTimestamp-${origin}`) || 0;

		const newTime = lastRefreshedTimestamp ? Number(lastRefreshedTimestamp) : 0;
		if (
			!localStorage.getItem(`lastRefreshedTimestamp-${origin}`) ||
			dayjs().utc().diff(dayjs(newTime).utc(), 'minutes') > 10
		) {
			localStorage.setItem(`lastRefreshedTimestamp-${origin}`, String(dayjs().utc().valueOf()));
			fetchPermissios().then(({ data }) => {
				let permissions: string[] = [];
				setPermissionFetched(true);
				// console.log({ data });
				data.forEach(role => {
					role.permissions.forEach((permission: { name: string }) => {
						if (!permissions.includes(permission.name)) {
							permissions.push(permission.name);
						}
					});
				});
				// console.log({ calculated: permissions });
				setPermissions(permissions);
			});
		}
	};

	const checkIsAllowed = ({
		permission = '',
		withoutPermission = '',
	}: {
		permission: string;
		withoutPermission: string;
	}) => {
		const userPermissions = permissions;
		const array = permission.split(',');
		let response = true;
		array.forEach(requiredPermission => {
			if (!userPermissions.includes(requiredPermission) && requiredPermission) {
				response = false;
			}
		});
		const secondArray = withoutPermission.split(',');
		secondArray.forEach(requiredPermission => {
			if (userPermissions.includes(requiredPermission)) {
				response = false;
			}
		});
		return response;
	};

	return {
		permissions,
		setPermissions,
		currentTab,
		getPermissions,
		setCurrentTab,
		checkIsAllowed,
	};
};
