import React from 'react';

export const Void = (props: any) => (
	<svg data-aid="SvgVoid" width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clip-path="url(#clip0_5387_35008)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.9905 11L21 8C21 7.77815 20.8996 7.46463 20.7376 7.30775L16.0255 2.74501C15.8635 2.58813 15.6437 2.5 15.4146 2.5H8.81769C8.08868 2.5 7.38954 2.78042 6.87405 3.27956C6.35857 3.77871 6 4.2941 6 5V10.5V12L6.07326 12.1617C6.76231 11.8378 7.51278 11.6229 8.30242 11.5392L8 11V10.5V8V7V5.5C8 5.47079 7.99961 5.43727 7.99918 5.40051C7.99578 5.10737 7.98999 4.60819 8.16012 4.44345C8.3944 4.2166 8.54692 4.17301 8.81769 4.17301H13.6083V6.9867C13.6083 7.45058 13.7986 7.89546 14.1374 8.22347C14.4761 8.55148 14.9356 8.73575 15.4146 8.73575H19V21H16.0255H15.5885C15.384 21.5346 15.1159 22.0377 14.7934 22.5H20.9905V13V11ZM15.3361 6.9867V4.44345L18.0411 7.06275H15.4146C15.3938 7.06275 15.3738 7.05474 15.3591 7.04047C15.3444 7.02621 15.3361 7.00687 15.3361 6.9867Z"
				fill="currentColor"
			/>
			<circle cx="10" cy="17.5" r="6" stroke="#A6BCCC" stroke-width="2" />
			<path
				d="M10.4601 17.4992L12.417 15.5422C12.5444 15.4149 12.5444 15.2091 12.417 15.0818C12.2898 14.9545 12.0839 14.9545 11.9566 15.0818L9.99966 17.0388L8.04273 15.0818C7.91538 14.9545 7.70963 14.9545 7.58233 15.0818C7.45498 15.2091 7.45498 15.4149 7.58233 15.5422L9.53926 17.4992L7.58233 19.4562C7.45498 19.5835 7.45498 19.7893 7.58233 19.9166C7.64577 19.9801 7.72918 20.012 7.81253 20.012C7.89589 20.012 7.97924 19.9801 8.04273 19.9166L9.99966 17.9596L11.9566 19.9166C12.0201 19.9801 12.1035 20.012 12.1868 20.012C12.2702 20.012 12.3535 19.9801 12.417 19.9166C12.5444 19.7893 12.5444 19.5835 12.417 19.4562L10.4601 17.4992Z"
				fill="currentColor"
				stroke="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_5387_35008">
				<rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
			</clipPath>
		</defs>
	</svg>
);
