export interface IBasicError {
	err: string;
}

export interface IErrorObj {
	err: { message: string };
}

export interface IErrorArr {
	err: string[];
}

//temp until added to types
export interface ISuccess {
	data: 'success';
}

export const isErrArr = (obj: unknown): obj is IErrorArr => Array.isArray((obj as IErrorArr).err);
