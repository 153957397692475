import { PaymentMethodOption, PaymentMethodTypeEnum } from '@bondsports/types';
import { getXLastChars, Icons } from '@bondsports/utils';
import { Stripe } from 'stripe';

export const splitPaymentMethods = (paymentMethods: PaymentMethodOption[]) => {
	const creditCards: Stripe.PaymentMethod[] = [].concat(
		...paymentMethods
			.filter(paymentMethod => paymentMethod.paymentMethodType === PaymentMethodTypeEnum.CARD)
			.map(pm => pm.options ?? [])
	);

	const ACHMethods: Stripe.PaymentMethod[] = [].concat(
		...paymentMethods
			.filter(paymentMethod => paymentMethod.paymentMethodType === PaymentMethodTypeEnum.ACH)
			.map(pm => pm.options)
	);
	return { creditCards, ACHMethods };
};

export const mapPaymentMethod = (paymentMethod: Stripe.PaymentMethod) => {
	const ICON_SIZE = 24;
	switch (paymentMethod?.type as PaymentMethodTypeEnum) {
		case PaymentMethodTypeEnum.CARD:
			return {
				icon: paymentMethod.card.brand as Icons,
				label: paymentMethod.card.last4 || '',
			};
		case PaymentMethodTypeEnum.ACH:
			return {
				icon: Icons.bank,
				label: (paymentMethod?.us_bank_account as any)?.last4,
				iconSize: ICON_SIZE,
			};
		default:
			return {
				icon: undefined,
				label: '',
			};
	}
};
