import React from 'react';

export const Dashboard = (props: any) => (
  <svg
    data-aid="svgDashboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 3H19C20.103 3 21 3.897 21 5V9C21 10.103 20.103 11 19 11H15C13.897 11 13 10.103 13 9V5C13 3.897 13.897 3 15 3ZM15 9V5H19L19.001 9H15ZM5 13H9C10.103 13 11 13.897 11 15V19C11 20.103 10.103 21 9 21H5C3.897 21 3 20.103 3 19V15C3 13.897 3.897 13 5 13ZM15 13H19C20.103 13 21 13.897 21 15V19C21 20.103 20.103 21 19 21H15C13.897 21 13 20.103 13 19V15C13 13.897 13.897 13 15 13ZM15 15V19H19.001L19 15H15ZM5 19V15H9L9.001 19H5ZM5 9V5H9L9.001 9H5ZM5 3H9C10.103 3 11 3.897 11 5V9C11 10.103 10.103 11 9 11H5C3.897 11 3 10.103 3 9V5C3 3.897 3.897 3 5 3Z"
      fill="currentColor"
    />
  </svg>
);
