/** @jsx jsx */
import { Typography } from '@bondsports/utils';
import { css, jsx } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { FormPart } from 'app/react/components/customers/utils/formPart';
import { FrameContainer } from 'app/react/components/customers/utils/FrameContainer';
import { getDatesRangeWithYears } from 'app/react/lib/dates';
import { colors } from 'app/react/styles/theme';
import { TranslationEn } from 'assets/i18n/en';
import { BnIcon, Button, getIcon, Tag } from '@bondsports/utils';
import { useSession } from 'app/react/hooks/useSession';
import React, { useMemo, Fragment } from 'react';
import { flex } from 'app/react/styles/utils';
import * as moment from 'moment';
import { ETypography, ETypographyColor, Icons } from 'app/react/styles/theme';
import { useHistory } from 'react-router-dom';
import { useProgram } from 'app/react/hooks/useProgram';

const basicIconCss = css`
	color: ${colors.formInputBg};
`;

const marginLeft = css`
	margin-left: 4rem;
`;

const flexWithMarginTop = css`
	${flex};
	margin-top: 16px;
`;

const withIcon = css`
	${flexWithMarginTop};
	svg {
		margin-right: 8px;
		height: 16px;
		width: 16px;
		color: ${colors.formInputBg};
	}
`;

const steps = TranslationEn.activities.steps;

const RegistrationDate = ({ isDashboard = true }: { isDashboard?: boolean }) => {
	const { sessionState, sessionId } = useSession();
	const { programState } = useProgram();
	const history = useHistory();

	const labels = TranslationEn.activities.dashboard.registrationDates;

	const handleEdit = () =>
		history.push(
			`/program/${steps.type[programState.type]}/${programState.id}/sessions/${sessionId}/edit_registration/edit`
		);

	return (
		<FrameContainer
			margin="0 0rem 2rem 0"
			title={TranslationEn.season.dashboard.registrationDateTitle}
			button={
				isDashboard && (
					<Button data-aid="button-RegistrationDate-edit" theme="secondary" sizer="S" onClick={handleEdit}>
						{labels.edit}
					</Button>
				)
			}
		>
			<RegistrationDatesBody />
		</FrameContainer>
	);
};

export default RegistrationDate;

export const RegistrationDatesBody = () => {
	const { sessionState } = useSession();

	const labels = TranslationEn.activities.dashboard.registrationDates;

	const openExpression = useMemo(() => {
		let label = '';
		if (sessionState.registrationConstraints) {
			if (sessionState.registrationConstraints.openNumMinutes) {
				label = `${labels.openLimit} ${sessionState.registrationConstraints.openNumMinutes / 60} ${labels.hours}`;
			}
			if (sessionState.registrationConstraints.openNumDays) {
				let type;
				let num;
				if (sessionState.registrationConstraints.openNumDays % 30 === 0) {
					type = 'months';
					num = sessionState.registrationConstraints.openNumDays / 30;
				} else {
					if (sessionState.registrationConstraints.openNumDays % 7 === 0) {
						type = 'weeks';
						num = sessionState.registrationConstraints.openNumDays / 7;
					} else {
						type = 'days';
						num = sessionState.registrationConstraints.openNumDays;
					}
				}

				label = `${labels.openLimit} ${num} ${labels[type]}`;
			}
		}
		return label;
	}, [sessionState]);

	const closeExpressopn = useMemo(() => {
		let label = '';
		if (sessionState.registrationConstraints) {
			if (sessionState.registrationConstraints.closeNumMinutes) {
				label = `${labels.closeLimit} ${sessionState.registrationConstraints.closeNumMinutes / 60} ${labels.hours}`;
			}
			if (sessionState.registrationConstraints.closeNumDays) {
				let type;
				let num;
				if (sessionState.registrationConstraints.closeNumDays % 30 === 0) {
					type = 'months';
					num = sessionState.registrationConstraints.closeNumDays / 30;
				} else {
					if (sessionState.registrationConstraints.closeNumDays % 7 === 0) {
						type = 'weeks';
						num = sessionState.registrationConstraints.closeNumDays / 7;
					} else {
						type = 'days';
						num = sessionState.registrationConstraints.closeNumDays;
					}
				}

				label = `${labels.closeLimit} ${num} ${labels[type]}`;
			}
		}
		return label;
	}, [sessionState]);

	return (
		<Fragment>
			<div css={flex}>
				<div>
					<Typography color={ETypographyColor.secondary} type={ETypography.captionCTA}>
						{labels.startDate}
					</Typography>
					<Typography color={ETypographyColor.primary} type={ETypography.body2}>
						{moment(sessionState.registrationStartDate).format('MMM DD, YYYY')}
					</Typography>
				</div>
				<div css={marginLeft}>
					<Typography color={ETypographyColor.secondary} type={ETypography.captionCTA}>
						{labels.endDate}
					</Typography>
					<Typography color={ETypographyColor.primary} type={ETypography.body2}>
						{moment(sessionState.registrationEndDate).format('MMM DD, YYYY')}
					</Typography>
				</div>
			</div>
			{openExpression && (
				<div css={withIcon}>
					<BnIcon icon={Icons.conflict} />
					<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
						{openExpression}
					</Typography>
				</div>
			)}
			{closeExpressopn && (
				<div css={withIcon}>
					<BnIcon icon={Icons.conflict} />
					<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
						{closeExpressopn}
					</Typography>
				</div>
			)}
			{sessionState.earlyRegistrationStartDate && (
				<div css={flexWithMarginTop}>
					<Tag title={labels.earlyBird} color="green" />
					<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
						{moment(sessionState.earlyRegistrationStartDate).format('MMM DD')} -
						{moment(sessionState.earlyRegistrationEndDate).format('MMM DD, YYYY')}
					</Typography>
				</div>
			)}
			{sessionState.lateRegistrationStartDate && (
				<div css={flexWithMarginTop}>
					<Tag title={labels.lateFee} color="red" />
					<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
						{moment(sessionState.lateRegistrationStartDate).format('MMM DD')} -
						{moment(sessionState.lateRegistrationEndDate).format('MMM DD, YYYY')}
					</Typography>
				</div>
			)}
		</Fragment>
	);
};
