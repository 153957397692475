import * as  React from 'react';

export const BnBoxedCard = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.91665 8.50016C3.91665 7.34957 4.84939 6.41683 5.99998 6.41683H18C19.1506 6.41683 20.0833 7.34957 20.0833 8.50016V9.5835H3.91665V8.50016ZM2.08331 10.5002V8.50016C2.08331 6.33705 3.83686 4.5835 5.99998 4.5835H18C20.1631 4.5835 21.9166 6.33705 21.9166 8.50016V10.5002V16.5002C21.9166 18.6633 20.1631 20.4168 18 20.4168H5.99998C3.83686 20.4168 2.08331 18.6633 2.08331 16.5002V10.5002ZM20.0833 11.4168V16.5002C20.0833 17.6508 19.1506 18.5835 18 18.5835H5.99998C4.84939 18.5835 3.91665 17.6508 3.91665 16.5002V11.4168H20.0833ZM6.08331 15.5002C6.08331 14.9939 6.49372 14.5835 6.99998 14.5835H7.00998C7.51624 14.5835 7.92665 14.9939 7.92665 15.5002C7.92665 16.0064 7.51624 16.4168 7.00998 16.4168H6.99998C6.49372 16.4168 6.08331 16.0064 6.08331 15.5002ZM11 14.5835C10.4937 14.5835 10.0833 14.9939 10.0833 15.5002C10.0833 16.0064 10.4937 16.4168 11 16.4168H13C13.5062 16.4168 13.9166 16.0064 13.9166 15.5002C13.9166 14.9939 13.5062 14.5835 13 14.5835H11Z"
      fill="currentColor"
    />
    <path
      d="M1.19922 4.1002V2.70019C1.19922 2.14791 1.64693 1.7002 2.19922 1.7002H3.59922"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.4008 1.7002L21.8008 1.7002C22.3531 1.7002 22.8008 2.14791 22.8008 2.7002L22.8008 4.1002"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.8008 20.8998L22.8008 22.2998C22.8008 22.8521 22.3531 23.2998 21.8008 23.2998L20.4008 23.2998"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.59922 23.2998L2.19922 23.2998C1.64693 23.2998 1.19922 22.8521 1.19922 22.2998L1.19922 20.8998"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
