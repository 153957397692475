import React from "react";

export const Rink = (props: any) => (
  <svg
    data-aid="SvgRink"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.45694 6.35295C3.45694 5.01285 4.55694 3.92648 5.91387 3.92648H18.0861C19.4431 3.92648 20.5431 5.01285 20.5431 6.35295V20.8496H23V6.35295C23 3.67274 20.8 1.5 18.0861 1.5H5.91387C3.20002 1.5 1 3.67274 1 6.35295V20.8496H3.45694V6.35295Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 16.0978C9.17764 16.0978 6.8814 17.2316 6.8814 18.6254C6.8814 20.0192 9.17745 21.153 12 21.153C14.8224 21.153 17.1186 20.0192 17.1186 18.6254C17.1186 17.2316 14.8224 16.0978 12 16.0978ZM8.87126 19.7931C8.83835 19.8543 8.7751 19.8894 8.70948 19.8894C8.68059 19.8894 8.65154 19.8827 8.62428 19.8684C7.89908 19.4896 7.49929 19.0161 7.49855 18.5346C7.49763 18.0993 7.81462 17.7118 8.44073 17.3827C8.52977 17.336 8.64072 17.3694 8.68807 17.4573C8.73541 17.5452 8.70178 17.6546 8.61257 17.7016C8.27072 17.8812 7.86343 18.1715 7.86397 18.5339C7.86433 18.8653 8.2124 19.2448 8.79502 19.549C8.88423 19.5956 8.91843 19.7049 8.87126 19.7931ZM9.26685 17.2377C9.2354 17.143 9.28751 17.0409 9.3833 17.0095C9.61942 16.9326 9.86864 16.8803 10.1322 16.825L10.1337 16.8247C10.2328 16.8037 10.3295 16.866 10.3505 16.9637C10.3715 17.0613 10.3085 17.157 10.2098 17.1778C9.95546 17.231 9.71527 17.2816 9.49773 17.3525C9.47871 17.3587 9.45952 17.3618 9.4405 17.3618C9.36374 17.3618 9.29226 17.3136 9.26685 17.2377ZM12 20.6114C11.2436 20.6112 10.4041 20.4837 9.57215 20.2429C9.47525 20.2149 9.41987 20.1147 9.4482 20.0188C9.47653 19.9229 9.57855 19.8684 9.67506 19.8964C10.4739 20.1277 11.2779 20.2501 12 20.2503C12.1009 20.2503 12.1828 20.3311 12.1828 20.4308C12.1828 20.5305 12.1009 20.6114 12 20.6114Z"
      fill="currentColor"
    />
    <path
      d="M17.0998 19.7057C17.0543 19.7669 17.0058 19.8261 16.9548 19.8836C16.4273 20.4768 15.6271 20.8635 14.8834 21.1019C14.0983 21.3534 13.2712 21.4566 12.4466 21.4902C11.4797 21.5296 10.5023 21.4738 9.56611 21.2319C8.60199 20.9827 7.50495 20.5169 6.90023 19.7057C6.8973 19.7018 6.89406 19.6979 6.89081 19.6941C6.88757 19.6903 6.88433 19.6864 6.8814 19.6824V20.9724C6.8814 22.3662 9.17764 23.5 12 23.5C14.8224 23.5 17.1186 22.3662 17.1186 20.9724V19.6824C17.1155 19.6868 17.1119 19.6911 17.1083 19.6953L17.1036 19.701L17.0998 19.7057Z"
      fill="currentColor"
    />
  </svg>
);
