export enum EDiscountUnit {
	PERCENT = 'percent',
	AMOUNT = 'amount',
}

export type TDiscountApplicant = 'invoice' | 'lineItem';

export interface DiscountAmountInputProps {
	value?: number; // dollar value
	type?: EDiscountUnit;
	amountPreDiscount: number;
	onChange: (type: EDiscountUnit, value?: number) => void;
	discountApplicant: TDiscountApplicant;
}
