/** @jsx jsx */

import { VoidActionType } from 'app/react/types/orders';
import { TranslationEn } from '@assets/i18n/en';
import { colors } from 'app/react/styles/theme';
import React from 'react';
import { jsx, css } from '@emotion/react';
import { BnIcon, Icons } from '@bondsports/utils';

interface Props {
	voidType: VoidActionType;
	handleClick?: (voidType: VoidActionType) => void;
	active?: boolean;
	isFirst?: boolean;
}

const container = (isActive: boolean, isFirst: boolean) => css`
	background: ${colors.lightGray};
	border: 1px solid ${isActive ? colors.brandPrimary : colors.borderPrimary};
	margin-left: ${isFirst ? '0' : '16px'};
	margin-bottom: 16px;
	display: flex;
	padding: 2rem;
	flex-direction: column;
	justify-content: center;
	width: 150px;
	box-sizing: border-box;
	border-radius: 3px;
	text-align: center;
	font-weight: 500;
	font-size: 1.4rem;
	line-height: 1.7rem;
	text-align: center;
	align-items: center;

	cursor: pointer;
	color: ${colors.brandPrimary};
	&:hover {
		background: ${colors.formControlBg};
	}
`;

const iconCss = css`
	margin-bottom: 8px;
`;

export const VoidMethodBox = ({ voidType, handleClick, active, isFirst = false }: Props) => {
	const voidLabels = TranslationEn.customers.paymentsInvoices.voidModal.buttons;
	const [icon, label] = voidType === 'all' ? [Icons.conflict, voidLabels.all] : [Icons.list, voidLabels.items];

	return (
		<div data-aid="voidMethodBox" css={container(active, isFirst)} onClick={() => handleClick(voidType)}>
			<div data-aid="voidMethodBox-button" css={iconCss}>
				<BnIcon icon={icon} />
			</div>
			<div data-aid="voidMethodBox-label">{label}</div>
		</div>
	);
};
