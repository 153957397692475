export enum MaintenanceTimingEnum {
	BEFORE = 1,
	AFTER = 2,
	AT_THE_BEGINING = 3,
	AT_THE_END = 4,
}

export enum ReservationStatusEnum {
	PLANNED = 'Planned',
	APPROVED = 'Approved',
	AWAITING_ADMIN = 'Awaiting Admin',
	AWAITING_CUSTOMER = 'Awaiting Customer',
	REJECTED = 'Rejected',
	CANCELLED = 'Cancelled',
}

export enum PaymentStatusEnum {
	NOT_PAID = 'not_paid',
	PARTIAL_PAYMENT = 'partial',
	FULLY_PAID = 'paid',
	REFUNDED = 'refunded',
	VOID = 'void',
}

export enum SlotTypeEnum {
	EXTERNAL = 'external',
	INTERNAL = 'internal',
	MAINTENANCE = 'maintenance',
	CUSTOM = 'custom',
}

export interface ITimeSlot {
	resourceId: number;
	startTime: string;
	endTime: string;
	startDate: string;
	endDate: string;
	relevantSlotId?: string;
}

export interface ColorCode {
	id: number;
	name: string;
	description?: string;
	lightValue: string;
	baseValue: string;
	darkValue: string;
}

export enum ColorCodeEnum {
	GRAY = 'gray',
	BLUE = 'blue',
}

export const colorCodeMap = {
	gray: {
		id: 1,
		name: 'neutral',
		lightValue: '#E2E7EB',
		baseValue: '#9B9B9B',
		darkValue: '#4E4E4E',
	},
	blue: {
		id: 6656,
		name: 'blue',
		lightValue: '#EDF4FC',
		baseValue: '#4A90E2',
		darkValue: '#254871',
	},
};

export interface IReservations {
	creatorType: string;
	name: string;
}

export interface ISlot {
	id: number;
	isReverted: boolean;
	reservationId: number;
	title?: string;
	creatorId?: number;
	creatorType?: string;
	userCreatorId?: number;
	startDate: string;
	endDate: string;
	startTime: string;
	endTime: string;
	timezone?: string;
	spaceId?: number;
	deletedAt?: Date;
	publicNotes?: string;
	privateNotes?: string;
	slotType: SlotTypeEnum;
	productsUserId?: number;
	addonsProductUserIds?: number[] | null;
	segmentId: number;
	eventId: number;
	event: Event;
	sportIds: number[];
	parentSlotId: number | null;
	parent?: ISlot;
	children?: ISlot[];
	maintenanceDurationdurationType: string;
	maintenanceTiming: number;
	durationValue: number;
	seriesId?: number;
	invoiceId?: number;
	approvalStatus?: ReservationStatusEnum;
	displayName?: string;
	internalName?: string;
	activityTypes?: number[] | null;
	hourlyRate: number;
	totalPrice: number;
	isConsumerVisible: boolean;
	isMonitorVisible: boolean;
	isPrivate: boolean;
	occurrence?: number;
	maintenance?: ISlot[];
	productMetadata: {
		productId: number;
		price: number;
	};
	slotDurationType: string;
	previousProductUsersIds?: number[];
	conflictsCount?: number;
	conflicts?: ISlot[];
	isConnectedMaintenance?: boolean;
	connectedMaints: IMaintSlot[];
	overallStartTime?: string;
	overallEndTime?: string;
	colorCode: ColorCode;
	name: string;
	reservation: IReservations;
	isDraft?: boolean;
}
export interface IMaintSlot extends ISlot {
	maintenanceTiming: MaintenanceTimingEnum;
}

export type TPxHeightSetter = (px: number, isAfter: boolean) => void;

export type TMaintAtEndHeight = null | { height: number };
