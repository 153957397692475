/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/react';
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import { FrameContainer } from '../../utils/FrameContainer';
import { SkeletonList } from '../../../shared/SkeletonsList';
import { centerText } from '../../../../styles/utils';
import { ILinkedAccount } from '../../../../types/customers';
import { TranslationEn } from '@assets/i18n/en';
import { LikedAccountsList } from './LikedAccountsList';

const linksContainerCss = css`
	max-width: 188px;
	min-height: 148px;
`;

interface ILinksProps {
	linkedAccounts: ILinkedAccount[];
}

interface IContentProps extends ILinksProps {
	labels: { noOtherMembers: string; noLinkedAccounts: string };
}

interface IProps extends ILinksProps {
	isLoading: boolean;
}

const LinkedAccontsText = ({ label }: { label: string }) => {
	return (
		<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
			<p css={centerText}> {label}</p>
		</Typography>
	);
};

const AccountLinks = ({ linkedAccounts, labels }: IContentProps) => {
	return linkedAccounts?.length ? (
		<LikedAccountsList linkedAccounts={linkedAccounts} />
	) : (
		<LinkedAccontsText label={labels.noLinkedAccounts} />
	);
};

export const LinkedAccountsBlock = ({ isLoading, linkedAccounts }: IProps) => {
	const labels = TranslationEn.customers.customerPage.linkedAccountsBlock;
	const { title, noLinkedAccounts, noOtherMembers } = labels;

	return (
		<FrameContainer title={title} margin={'16px 0'}>
			<div css={linksContainerCss}>
				{isLoading ? (
					<SkeletonList isLoading={isLoading} skeletonLength={3} />
				) : (
					<AccountLinks linkedAccounts={linkedAccounts} labels={{ noLinkedAccounts, noOtherMembers }} />
				)}
			</div>
		</FrameContainer>
	);
};
