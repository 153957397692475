/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { TranslationEn } from '@assets/i18n/en';
import { Button, ButtonProps } from '@bondsports/utils';
import { useContext } from 'react';
import { PaymentMethodsContext } from '../../payments/Charge/PaymentMethodsProvider';

interface Props extends ButtonProps {
	onClickCallback: () => void;
	isDisabled?: boolean;
	isProcessing?: boolean;
	isDisableFetchPaymentMethods?: boolean;
	alternativeText?: string;
}
export const ChargeButtonWrapper = ({
	onClickCallback,
	isDisabled,
	isProcessing,
	isDisableFetchPaymentMethods = false,
	alternativeText,
	...buttonProps
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
	const { setPaymentMethodsIfUserExists, fetchPaymentsLoader } = useContext(PaymentMethodsContext);

	const handleClick = async () => {
		if (!isDisableFetchPaymentMethods) {
			await setPaymentMethodsIfUserExists();
		}
		onClickCallback();
	};

	return (
		<Button
			data-aid="button-ChargeButtonWrapper-charge"
			onClick={handleClick}
			disabled={isDisabled}
			isProcessing={isProcessing}
			{...buttonProps}
		>
			{alternativeText || TranslationEn.customers.paymentsInvoices.charge}
		</Button>
	);
};
