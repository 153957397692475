/* @jsx jsx */

import React, { useCallback, useContext } from 'react';
import { jsx } from '@emotion/react';
import { ActivityView } from './ActivityView';
import { ProgramDetailsCard } from '../components/ProgramDetailsCard';
import { CustomerActivitiesContext } from '../customerActivitiesContext';
import { customersApi } from '../../../../lib/api/customersApi';
import { TranslationEn } from '../../../../../../assets/i18n/en';
import { ProgramTypesEnum, SimpleSessionDto } from '@bondsports/types';
import { SimpleProgramWithSportDto } from '../types/types';
import { useNavigation } from '../../../../hooks/useNavigation';

const PROGRAMS_PER_PAGE = 2;
const ACTIVITIES_ENUM_TO_PROGRAM_TYPES = TranslationEn.customers.activitiesTab.activitiesEnumToProgramTypeEnum;

export const ProgramView: React.FC = () => {
	const { timezone, labels, displayUserDetails, selectedActivity, customer } = useContext(CustomerActivitiesContext);
	const { ngNavigate, pushNavigation } = useNavigation();

	const getData = useCallback((page: number, options: { signal: AbortSignal }) => {
		return customersApi.getCustomersPrograms(
			customer.id,
			customer.organizationId,
			PROGRAMS_PER_PAGE,
			page,
			ACTIVITIES_ENUM_TO_PROGRAM_TYPES[selectedActivity],
			options
		);
	}, []);

	const onViewInvoice = useCallback(
		(invoiceId: number) => pushNavigation(`/customer/${customer.id}/invoices/${invoiceId}/details`),
		[]
	);

	const onViewPayment = useCallback(
		(paymentId: number) => pushNavigation(`/customer/${customer.id}/payments/${paymentId}`),
		[]
	);

	const onNavigateToSession = useCallback((session: SimpleSessionDto, program: SimpleProgramWithSportDto): void => {
		const programTypeName: string = ProgramTypesEnum[program.type].toLowerCase();
		ngNavigate('activities', `program/${programTypeName}/${program.id}/sessions/${session.id}`);
	}, []);

	return (
		<ActivityView getData={getData}>
			{({ data, index }) => (
				<ProgramDetailsCard
					data={data}
					displayUserDetails={displayUserDetails}
					onViewInvoice={onViewInvoice}
					onViewPayment={onViewPayment}
					labels={labels.activities[selectedActivity].card}
					timezone={timezone}
					onNavigateToSession={onNavigateToSession}
					index={index}
					programType={ACTIVITIES_ENUM_TO_PROGRAM_TYPES[selectedActivity]}
				/>
			)}
		</ActivityView>
	);
};
