import React, { Fragment } from 'react';
import { StyledError } from './style';
import { useTheme } from '@emotion/react';

export const ErrorMessage = ({ error }: { error: string }) => {
  const colors = useTheme();
  return (
    <Fragment>
      {error && <StyledError theme={{ ...colors }}>{error}</StyledError>}
    </Fragment>
  );
};
