import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { network } from '../network';

const GOOGLE_API_DOMAIN = 'https://maps.googleapis.com';

async function getTimezoneByLocation(lat: number, lon: number) {
	const url = `${GOOGLE_API_DOMAIN}/maps/api/timezone/json?location=${lat},${lon}&timestamp=${moment()
		.add('1', 'hour')
		.format('X')}&key=${environment.GOOGLE_API_KEY}`;
	const response = await fetch(url, { method: 'Get' });
	const data = await response.text();
	const timezoneResponse = JSON.parse(data);

	if (timezoneResponse) {
		return timezoneResponse;
	}
	return null;
}

export const timeApi = {
	getTimezoneByLocation,
};
