/* @jsx jsx */

import React, { CSSProperties, Fragment, useMemo } from 'react';
import { css, jsx } from '@emotion/react';
import { ProgramSegmentDto } from '@bondsports/types';
import { formatActivityTimes, FormattedActivityTimes, isPastSegment } from '../../utils';
import { bondsportsDays, formatDate, formatTime } from '../../../../../lib/dates';
import { EDateTimeFormats, ETimeFormats } from '../../../../../types/times';
import { CHARACHERS, Checkbox, ETypography, ETypographyColor, Typography, useModal } from '@bondsports/utils';
import {
	flexCol,
	flexGrowCss,
	flexRowCss,
	fullWidthCss,
	gapCss,
	justifyContentCss,
	paddingBottomCss,
	pointerCss,
} from '../../../../../styles/utils';
import { EllipsisWithTooltipContainer } from '../../../../shared/EllipsisWithTooltipContainer';
import { EllipsisWithViewMoreContainer } from '../../../../shared/EllipsisWithViewMoreContainer';
import { AddonsModal } from '../utils/AddonsModal';
import { ScheduleModal } from '../utils/ScheduleModal';
import { separatorCss } from '../../styles';

const segmentContainerCss = (isLast: boolean) => css`
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 12px;
	padding-top: 20px;
	${separatorCss('top')};
	${isLast ? paddingBottomCss(20) : paddingBottomCss(28)};
`;

const widthCss = (howMuch: CSSProperties['width']) => css`
	width: ${howMuch};
`;

const textAlignCss = (alignment: CSSProperties['textAlign']) => css`
	text-align: ${alignment};
`;

const segmentDateTimeContainerCss = css`
	display: flex;
	text-align: start;
	justify-content: end;
`;

type SegmentRowProps = {
	data: ProgramSegmentDto & {
		id: number;
		organizationId: number;
		userId: number;
		productUserId: number;
		isSegment?: boolean;
	};
	isSelected: boolean;
	labels: Record<string, any>;
	isLastItem: boolean;
	index: number;
	isDisabled: boolean;
	timezone: string;
};

export const ProgramSegmentRow: React.FC<SegmentRowProps> = ({
	data,
	isLastItem,
	isSelected,
	index,
	labels,
	isDisabled,
	timezone,
}) => {
	const scheduleText = useMemo(() => {
		const formattedActivityTimes: FormattedActivityTimes[] = formatActivityTimes(data.activityTimes, bondsportsDays);

		return formattedActivityTimes
			.map(
				activityTime =>
					`${activityTime.openingDays} ${CHARACHERS.DOT} ${formatTime(
						activityTime.open,
						ETimeFormats.H12_AMPM_UPPERCASE
					)} - ${formatTime(activityTime.close, ETimeFormats.H12_AMPM_UPPERCASE)}`
			)
			.join(', ');
	}, [data.activityTimes]);

	const textColor: ETypographyColor = useMemo(
		() => (isPastSegment(data?.endDate, data?.activityTimes) ? ETypographyColor.secondary : ETypographyColor.primary),
		[data.endDate]
	);

	const { isShowing: isShowingAddonsModal, toggle: toggleAddonsModal } = useModal();
	const { isShowing: isShowingScheduleModal, toggle: toggleScheduleModal } = useModal();

	return (
		<div css={segmentContainerCss(isLastItem)} data-index={index} datatype="row" data-testid="segment">
			<Checkbox isDisabled={isDisabled} />
			<div datatype="content" css={[flexCol, flexGrowCss(1), gapCss(8), fullWidthCss]}>
				<div css={[flexRowCss, justifyContentCss('space-between'), fullWidthCss, gapCss(16)]}>
					<span datatype="title" data-testid="segment-name" css={flexGrowCss(0.7)}>
						<EllipsisWithTooltipContainer
							color={textColor}
							type={ETypography.body2Accented}
							text={data.name}
							placement="top-end"
						/>
					</span>
					<div
						datatype="title"
						data-testid="segment-schedule"
						css={[
							pointerCss,
							segmentDateTimeContainerCss,
							flexGrowCss(0.3),
							justifyContentCss('end'),
							textAlignCss('start'),
						]}
						onClick={toggleScheduleModal}
					>
						<EllipsisWithTooltipContainer
							color={textColor}
							type={ETypography.body2Accented}
							text={scheduleText}
							disableTooltip
							gap={8}
						/>
					</div>
				</div>
				<div
					datatype="date"
					data-testid="segment-purchse-date"
					css={[flexRowCss, justifyContentCss('space-between'), fullWidthCss, gapCss(16)]}
				>
					<div css={flexGrowCss(0.97)}>
						<Typography type={ETypography.body2} color={ETypographyColor.secondary}>
							{labels.registeredOn} {formatDate(data.registeredOn, EDateTimeFormats.DAY_FORMAT, timezone)}
						</Typography>
					</div>
					<Fragment>
						<div
							datatype="content"
							data-testid="segment-addons"
							css={[pointerCss, flexGrowCss(0.03), justifyContentCss('end'), textAlignCss('start')]}
						>
							<EllipsisWithViewMoreContainer
								type={ETypography.body2}
								color={ETypographyColor.secondary}
								onClick={toggleAddonsModal}
								label={labels.viewAddons}
								safetyGap={100}
								showOnlyLink={!!data.addonsCount}
							/>
						</div>
					</Fragment>
				</div>
			</div>
			{isShowingAddonsModal && (
				<AddonsModal
					isShowing={isShowingAddonsModal}
					toggle={toggleAddonsModal}
					data={data}
					labels={{
						title: labels.addonsTitle,
						close: labels.close,
						addonTypes: labels.addonTypes,
						error: labels.addonError,
					}}
				/>
			)}

			<ScheduleModal
				isShowing={isShowingScheduleModal}
				labels={{ title: labels.scheduleTitle, close: labels.close }}
				toggle={toggleScheduleModal}
				activityTimes={data.activityTimes}
			/>
		</div>
	);
};
