import React from 'react';

export const Ticket = (props: any) => (
  <svg
    data-aid="svgTicket"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.0391 18.4062C18.5137 18.4062 18.8984 18.0215 18.8984 17.5468C18.8984 17.0722 18.5137 16.6875 18.0391 16.6875C17.5644 16.6875 17.1797 17.0722 17.1797 17.5468C17.1797 18.0215 17.5644 18.4062 18.0391 18.4062Z"
      fill="currentColor"
    />
    <path
      d="M22.3116 13.2749C22.7122 13.1936 23 12.8415 23 12.4328V10.2413C23 8.34582 21.4579 6.80376 19.5625 6.80376H4.4375C4.1888 6.80376 3.94641 6.83096 3.71257 6.88136C3.82249 6.83079 3.93919 6.791 4.0617 6.76354L17.4993 3.75246C18.1972 3.60156 18.9123 3.96434 19.2003 4.61596C19.3921 5.05012 19.8995 5.24636 20.3337 5.05463C20.7678 4.86282 20.9642 4.35536 20.7723 3.92125C20.1555 2.52553 18.6242 1.74844 17.1317 2.0735L17.1266 2.07462L3.68589 5.08639C2.92659 5.25654 2.24055 5.68477 1.75419 6.29217C1.26782 6.89958 1 7.66262 1 8.44074V12.4328C1 12.8415 1.28785 13.1936 1.6884 13.275C2.28541 13.3962 2.71875 13.9275 2.71875 14.5382C2.71875 15.1489 2.28541 15.6802 1.6884 15.8014C1.28785 15.8827 1 16.2348 1 16.6436V18.8351C1 20.7305 2.54206 22.2726 4.4375 22.2726H19.5625C21.4579 22.2726 23 20.7305 23 18.8351V16.6436C23 16.2348 22.7121 15.8827 22.3116 15.8014C21.7146 15.6802 21.2812 15.1489 21.2812 14.5382C21.2812 13.9275 21.7146 13.3962 22.3116 13.2749ZM21.2812 11.8204C20.254 12.3097 19.5625 13.3607 19.5625 14.5382C19.5625 15.7157 20.254 16.7667 21.2812 17.2559V18.8351C21.2812 19.7828 20.5102 20.5538 19.5625 20.5538H18.8984C18.8984 20.0792 18.5137 19.6944 18.0391 19.6944C17.5644 19.6944 17.1797 20.0792 17.1797 20.5538H4.4375C3.48978 20.5538 2.71875 19.7828 2.71875 18.8351V17.2559C3.746 16.7667 4.4375 15.7157 4.4375 14.5382C4.4375 13.3607 3.746 12.3097 2.71875 11.8204V10.2413C2.71875 9.29358 3.48978 8.52255 4.4375 8.52255H17.1797C17.1797 8.99719 17.5644 9.38193 18.0391 9.38193C18.5137 9.38193 18.8984 8.99719 18.8984 8.52255H19.5625C20.5102 8.52255 21.2812 9.29358 21.2812 10.2413V11.8204Z"
      fill="currentColor"
    />
    <path
      d="M18.0391 12.3906C18.5137 12.3906 18.8984 12.0058 18.8984 11.5312C18.8984 11.0566 18.5137 10.6718 18.0391 10.6718C17.5644 10.6718 17.1797 11.0566 17.1797 11.5312C17.1797 12.0058 17.5644 12.3906 18.0391 12.3906Z"
      fill="currentColor"
    />
    <path
      d="M18.0391 15.3984C18.5137 15.3984 18.8984 15.0137 18.8984 14.539C18.8984 14.0644 18.5137 13.6797 18.0391 13.6797C17.5644 13.6797 17.1797 14.0644 17.1797 14.539C17.1797 15.0137 17.5644 15.3984 18.0391 15.3984Z"
      fill="currentColor"
    />
    <path
      d="M15.2468 12.7309L12.7025 12.3422L11.5583 9.90449C11.4426 9.65766 11.1945 9.50001 10.9218 9.50001C10.6492 9.50001 10.4013 9.65766 10.2854 9.9043L9.14113 12.3422L6.59687 12.7309C6.33631 12.7706 6.11988 12.9528 6.03639 13.2028C5.95271 13.4527 6.01588 13.7283 6.1999 13.9171L8.06135 15.825L7.62062 18.5275C7.57722 18.7931 7.68947 19.0603 7.90956 19.2154C8.12965 19.3705 8.41896 19.3862 8.65462 19.256L10.9218 18.0027L13.189 19.256C13.4265 19.3873 13.7158 19.3692 13.9341 19.2154C14.1542 19.0603 14.2664 18.7931 14.223 18.5275L13.7823 15.825L15.6438 13.9171C15.8278 13.7285 15.891 13.4527 15.8073 13.2028C15.7238 12.9528 15.5073 12.7708 15.2468 12.7309ZM12.528 15.097C12.372 15.2567 12.3014 15.481 12.3372 15.701L12.6022 17.3248L11.262 16.584C11.1562 16.5256 11.039 16.4963 10.9218 16.4963C10.8046 16.4963 10.6875 16.5256 10.5816 16.584L9.24147 17.3248L9.50642 15.701C9.54231 15.481 9.47163 15.2567 9.31563 15.097L8.16188 13.9145L9.72432 13.6757C9.95613 13.6402 10.155 13.4917 10.2546 13.2795L10.9218 11.858L11.5891 13.2793C11.6887 13.4915 11.8875 13.6402 12.1193 13.6755L13.6818 13.9143L12.528 15.097Z"
      fill="currentColor"
    />
  </svg>
);
