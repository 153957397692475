/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { ETypography, ETypographyColor, Icons } from '../../types';
import { alignItemsCss, flexRowCss, gapCss, justifyContentCss, widthPxCss } from '../../styles/utils';
import { BnIcon } from '../../components/icons_v2';
import { Typography } from '../../atoms/Typography';
import { Button } from '../../components/Button';

export type ToastProps = {
	content: string;
	toastColor: string;
	textColor: ETypographyColor;
	onDismiss: () => void;
	useAnimation?: boolean;
	delay?: number;
	deleted?: boolean;
	width?: number;
};

const toastCss = (options: {
	backgroundColor: string;
	color: string;
	useAnimation?: boolean;
	delay?: number;
	fadeOut?: boolean;
}) => css`
	background-color: ${options.backgroundColor};
	color: ${options.color};
	padding: 20px 24px 20px 24px;
	border-radius: 8px;
	${options.useAnimation ? (options.fadeOut ? fadeOutAnimation : fadeInAnimationCss(options.delay as number)) : ''}
`;

const fadeInAnimationCss = (delay: number) => css`
	transform: translateY(100vh);
	animation: appearAnimation 1s ease-out forwards;
	animation-delay: ${delay}s;

	@keyframes appearAnimation {
		to {
			transform: translateY(0);
		}
	}
`;

const fadeOutAnimation = css`
	animation: disappearRight 0.5s ease-out forwards;

	@keyframes disappearRight {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(100vw);
			display: none;
		}
	}
`;

export const Toast: React.FC<ToastProps> = ({
	useAnimation,
	delay,
	content,
	toastColor,
	textColor,
	onDismiss,
	deleted,
	width,
}) => {
	return (
		<div
			css={[
				toastCss({
					backgroundColor: toastColor,
					color: textColor,
					useAnimation,
					delay,
					fadeOut: deleted,
				}),
				widthPxCss(width || 650),
				flexRowCss,
				justifyContentCss('space-between'),
				gapCss(20),
			]}
			data-aid="index-Toast"
		>
			<div css={[flexRowCss, gapCss(8), justifyContentCss('center'), alignItemsCss('center')]}>
				<BnIcon icon={Icons.conflict} color={textColor} />
				<Typography type={ETypography.body1Accented} color={textColor}>
					{content}
				</Typography>
			</div>
			<Button data-aid="button-Toast-close" theme="basic" sizer="S" onClick={onDismiss}>
				<BnIcon icon={Icons.close} color={textColor} />
			</Button>
		</div>
	);
};
