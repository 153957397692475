/** @jsxRuntime classic */
/** @jsx jsx */
import { useMemo } from 'react';
import { css, jsx } from '@emotion/react';
import { Fee } from '@bondsports/types';
import { ETypography, ETypographyColor, Icons } from '../../../types';
import { TooltipWithButton } from '../../tooltip-with-button';
import { BnIcon } from '../../../components/icons_v2';
import { Typography } from '../../../atoms';
import { Pricify, roundPriceCents } from '../../../lib/price';

interface IFees {
	fee: Fee;
	feeAmount?: number;
}

export const FeesMapper = ({ state }: { state: IFees; noIcons?: boolean }) => {
	const feeAmount = state?.feeAmount ?? 0;
	const fee = state.fee;
	const percent =
		Number(fee?.percentageValue ?? 0) > 0 ? `${roundPriceCents(Number(fee.percentageValue ?? 0) * 100)}%` : '';
	const addition = Number(fee?.percentageValue ?? 0) > 0 && Number(fee?.fixValue ?? 0) > 0 ? ` + ` : '';
	const fixed =
		Number(fee?.fixValue ?? 0) > 0 ? `${Pricify(Number(fee?.fixValue ?? 0), undefined, undefined, true)} fixed` : ``;
	const tooltipContent = fee ? `${fee?.name ?? ''} - ${percent}${addition}${fixed}` : '';
	return feeAmount ? (
		<TooltipWithButton icon={Icons.info} tooltipContent={tooltipContent}>
			<Typography type={ETypography.body2} color={ETypographyColor.primary}>
				{Pricify(feeAmount, undefined, undefined, true)}
			</Typography>
		</TooltipWithButton>
	) : (
		<BnIcon icon={'minus'} />
	);
};
