/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { BnIcon, Button, Icons } from '@bondsports/utils';
import { TranslationEn } from '../../../../../assets/i18n/en';

interface IProps {
	handleBack: () => void;
}

const backBtnCss = css`
	position: absolute;
	top: 10px;
	left: 10px;
	button {
		display: flex;
		gap: 4px;
		padding: 0px !important;
	}
`;

export const BackButton = ({ handleBack }: IProps) => {
	return (
		<div css={backBtnCss}>
			<Button data-aid="button-BackButton-left" theme="basic" sizer="S" onClick={handleBack}>
				<BnIcon icon={Icons.arrow_left} />
				{TranslationEn.general.buttons.back}
			</Button>
		</div>
	);
};
