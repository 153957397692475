import React from 'react';

export const TriangleUp = (props: any) => {
	return (
		<svg data-aid="SvgTriangleUp" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M9.1129 1.66687C10.3961 -0.55562 13.6039 -0.555624 14.8871 1.66686L23.5484 16.6687C24.8315 18.8912 23.2276 21.6693 20.6613 21.6693H3.33871C0.772401 21.6693 -0.831546 18.8912 0.451608 16.6687L9.1129 1.66687Z"
				fill="currentColor"
			/>
		</svg>
	);
};
