/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from "react";
import { css, jsx, useTheme } from "@emotion/react";

import { BnIcon } from "../icons_v2";
import {
  dividerCss,
  stepCss,
  stepContainerCss,
  collapsedDividerCss,
} from "./style";

export const Steps = ({
  arr,
  activeStep,
  handlePrevious,
  isCollapsed = false,
}: {
  arr: string[];
  activeStep: number;
  handlePrevious: (val: number) => void;
  isCollapsed?: boolean;
}) => {
  return (
    <div css={stepContainerCss}>
      {arr.map((step, index) => {
        if (index === 0) {
          return (
            <Step
              handleClick={handlePrevious}
              key={index}
              index={index}
              isActive={index === activeStep}
              isCollapsed={isCollapsed}
              passed={index < activeStep}
              label={step}
            />
          );
        } else {
          return (
            <Fragment key={index}>
              {isCollapsed ? (
                index - 1 === activeStep ? (
                  <Divider />
                ) : (
                  <CollapsedDivider />
                )
              ) : (
                <Divider />
              )}
              <Step
                handleClick={handlePrevious}
                key={index}
                index={index}
                isActive={index === activeStep}
                isCollapsed={isCollapsed}
                passed={index < activeStep}
                label={step}
              />
            </Fragment>
          );
        }
      })}
    </div>
  );
};

interface IStepProps {
  isActive: boolean;
  index: number;
  label: string;
  passed: boolean;
  handleClick: (val: number) => void;
  isCollapsed: boolean;
}

const Step = ({
  isActive,
  index,
  label,
  passed,
  handleClick,
  isCollapsed,
}: IStepProps) => {
  const colors = useTheme();
  return (
    <div
      onClick={() => {
        if (passed) {
          handleClick(index);
        }
      }}
      css={stepCss(colors, isActive, passed)}
    >
      <div className="step">
        {passed ? <BnIcon icon="check" props={{}} /> : index + 1}
      </div>
      {(!isCollapsed || isActive) && <div className="step-label">{label}</div>}
      <div className="step-border" />
    </div>
  );
};

const Divider = () => {
  const colors = useTheme();
  return <div css={dividerCss(colors)} />;
};

const CollapsedDivider = () => {
  return <div css={collapsedDividerCss} />;
};
