import { useState, ChangeEvent } from 'react';
import { ENotificationType } from '@bondsports/utils';
import {
	MembershipFilterByPropertyEnum as FiltePropEnum,
	MembershipOrderByPropertyEnum as OrderPropEnum,
	OrderByEnum,
	MembershipStatusEnum,
	IPaginationData,
	IExpandedMembershipMemberData,
} from '@bondsports/types';

import { useNotification } from '../useNotification';
import { TMembershipFilterValue as TFilterValue } from '../../types/membership';
import { IErrorArr, isErrArr } from '../../types/errors';
import { TranslationEn } from '@assets/i18n/en';
import { NoTableRecordsReasonEnum } from '@app/react/types/enums';
import { INoTableRecords } from '@app/react/types/helpers';

const defaultSort = [OrderPropEnum.MEMBERSHIP_STATUS, OrderPropEnum.STARTDATE];
const defaultSortOrder = [OrderByEnum.DESC, OrderByEnum.DESC];

export const useMembershipTab = () => {
	const [sortProperties, setSortProperties] = useState<OrderPropEnum[]>(defaultSort);
	const [sortOrder, setSortOrder] = useState<OrderByEnum[]>(defaultSortOrder);
	const [statusFilter, setStatusFilter] = useState<TFilterValue[]>([]);
	const [paymentFilter, setPaymentFilter] = useState<TFilterValue[]>([]);
	const [renewalFilter, setRenewalFilter] = useState<TFilterValue[]>([]);
	const [selectedFilters, setSelectedFilters] = useState<FiltePropEnum[]>();
	const [filters, setFilters] = useState<TFilterValue[]>([]);
	const [noMemberships, setNoMemberships] = useState<INoTableRecords | null>(null);
	const { setPopupNotification } = useNotification();
	const labels = TranslationEn.customers.membershipTab;

	const handleUpdateFilters = () => {
		const filterValus = [...statusFilter, ...paymentFilter, ...renewalFilter];
		const activeFilters: FiltePropEnum[] = [];
		statusFilter.length && activeFilters.push(FiltePropEnum.MEMBERSHIP_STATUS);
		paymentFilter.length && activeFilters.push(FiltePropEnum.PAYMENT_STATUS);
		renewalFilter.length && activeFilters.push(FiltePropEnum.RENEWAL_TYPE);
		setSelectedFilters(activeFilters);
		setFilters(filterValus);
	};

	const handleFilterForActiveStatus = () => {
		if (filters.includes(MembershipStatusEnum.ACTIVE)) {
			filters.push(MembershipStatusEnum.ACTIVE_CANCELLED);
		}
	};

	const onFilterChange = (
		{ target }: ChangeEvent<HTMLInputElement>,
		filters: string[],
		setFilters: (arr: any[]) => void
	) => {
		if (target.checked) {
			setFilters([...filters, target.name]);
		} else {
			setFilters(filters.filter(value => value != target.name));
		}
	};

	const handleNoMembershipsError = (
		res: IErrorArr | IPaginationData<IExpandedMembershipMemberData>,
		searchFilter: string
	) => {
		if (isErrArr(res) || !res.data) {
			setNoMemberships({ message: labels.errorLoadingMemberships, type: NoTableRecordsReasonEnum.ERROR });
			return;
		}
		if (!res.data.length) {
			//check if there's no memberships because of filters or search results:
			if (selectedFilters.length || searchFilter) {
				const message = searchFilter ? labels.noMatchSearch : labels.noMatchFilter;
				setPopupNotification(message, ENotificationType.warning);
				return;
			}
			setNoMemberships({ message: labels.noMemberships, type: NoTableRecordsReasonEnum.EMPTY });
			return;
		}

		return res;
	};

	const handleSortColumn = (column: string, isAsc: boolean) => {
		const propertyName = OrderPropEnum[column];
		const sortOrder = isAsc ? OrderByEnum.ASC : OrderByEnum.DESC;
		const { STARTDATE, ENDDATE } = OrderPropEnum;

		if (propertyName === STARTDATE || propertyName === ENDDATE) {
			_setSortParameters([propertyName], [sortOrder]);
		} else {
			/*if not sorting by date, the startDate will be a second sort parameter */
			const sortByProperties = [propertyName, STARTDATE];
			const sortOrders = [sortOrder, OrderByEnum.DESC];
			_setSortParameters(sortByProperties, sortOrders);
		}
	};

	const _setSortParameters = (props: OrderPropEnum[], orders: OrderByEnum[]) => {
		setSortProperties(props);
		setSortOrder(orders);
	};

	return {
		sortProperties,
		sortOrder,
		handleSortColumn,
		handleUpdateFilters,
		selectedFilters,
		filters,
		statusFilter,
		paymentFilter,
		renewalFilter,
		setStatusFilter,
		setPaymentFilter,
		setRenewalFilter,
		onFilterChange,
		handleFilterForActiveStatus,
		handleNoMembershipsError,
		noMemberships,
	};
};
