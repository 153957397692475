/** @jsxRuntime classic */
/** @jsx jsx */

import React, { FC, Fragment, useState, useEffect } from "react";
import { css, jsx, useTheme } from "@emotion/react";
import { IColors } from "../../types/theme";
import {
  BnCollapse,
  BnSupport,
  getIcon,
  Logo,
  SmallLogo,
  BnRightAction,
} from "../../icons";
import { setOpacity } from "../../styles/utils";
import { Popup } from "../Popup";

const containerCss = css`
  display: flex;
  height: 100%;
  z-index: 10001;
`;

const navCss = (colors: IColors, isWide: boolean) => css`
  background: ${colors.bg_text_primary};
  display: flex;
  flex-direction: column;
  .tab {
    background-position: center;

    padding: 6px;
    color: ${colors.tx_text_teriaty};
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    min-width: ${isWide ? "200px" : "0px"};
    margin-bottom: 4px;
    cursor: pointer;
    border-radius: 5px;
    // transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    // transition-duration: 600ms;

    transition: all 600ms, background 0.3s;
    svg {
      width: 24px;
    }
    .label {
      margin-left: 12px;
    }
    &:hover {
      background: ${setOpacity(colors.bg_white || "", 0.1)};
      color: ${colors.tx_white};
    }
  }
  .active_tab {
    background: ${colors.bg_background_primary};
    color: ${colors.tx_text_primary};
    &:hover {
      color: ${colors.tx_text_primary};
      background: ${colors.bg_background_primary}
        radial-gradient(
          circle,
          transparent 1%,
          ${colors.bg_background_primary} 1%
        )
        center/15000%;
    }
  }

  .tab:active {
    background-color: ${colors.bg_background_primary};
    background-size: 100%;
    transition: background 0s;
  }
`;

const bottomSeperator = (colors: IColors) => css`
  padding: 12px 14px;
  position: relative;
  border-bottom: 1px solid ${setOpacity(colors.bg_border_seperator || "", 0.1)};
`;

const labelCss = (colors: IColors) => css`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  min-height: 12px;
  text-transform: uppercase;
  padding: 6px;
  margin-bottom: 6px;
  color: ${colors.tx_text_teriaty};
`;

const bottomContainer = (colors: IColors) => css`
  padding: 12px 14px;
  justify-content: space-between;
  display: flex;
  position: relative;
  align-items: center;
  .logo {
    margin-left: 6px;
  }
  .small-logo {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 2px 0;
    justify-content: center;
  }
  .button {
    border: none;
    background: none;
    cursor: pointer;
    svg {
      color: ${colors.bg_text_secondary};
    }
  }
`;

const collapsableButtonCss = (colors: IColors) => `
  position:absolute;
  top:0;
  right:0;
  z-index:10001;
  background:${colors.bg_text_primary};
  color:${colors.bg_text_teriaty};
  border: 1px solid ${setOpacity(colors.bg_border_seperator || "", 0.1)};
  transform:translate(50%,-50%);
  height: 24px;
  cursor:pointer;
  
  border-radius: 100px;
  width: 24px;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;

  outline:none;
`;

const userSettingsCss = (colors: IColors) => css`
  margin-top: 16px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .userInfo {
    display: flex;
    align-items: center;
    img {
      width: 32px;
      height: 32px;
      border-radius: 100px;
    }
    label {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      text-transform: capitalize;
      color: ${colors.tx_text_secondary};
      margin-left: 12px;
    }
  }
  button {
    cursor: pointer;
    border: none;
    background: none;
    color: ${colors.tx_text_secondary};
    &:hover {
      color: ${colors.tx_white};
    }
  }
`;

export interface Props {
  active: string;
  setActive: (val: string) => void;
  isWide: boolean;
  setWide: (val: boolean) => void;
  handleCart: () => void;
  user: {
    img: string;
    fullname: string;
  };
}

const Tabs = [
  {
    label: "calendar",
    icon: "calendar",
    action: "",
  },
  {
    label: "rentals",
    icon: "court",
    action: "",
  },
  {
    label: "activites",
    icon: "activity",
    action: "",
  },
  {
    label: "commerce",
    icon: "tag",
    action: "",
  },
  {
    label: "customers",
    icon: "profiles",
    action: "",
  },
  {
    label: "membership",
    icon: "tickets",
    action: "",
  },
  {
    label: "transactions",
    icon: "cash",
    action: "",
  },
];

const managementTabs = [
  {
    label: "reports",
    icon: "reports",
    action: "",
  },
  {
    label: "employees",
    icon: "clock",
    action: "",
  },
  {
    label: "settings",
    icon: "settings_outline",
    action: "",
  },
];

export const Navigation: FC<Props> = ({
  children,
  active,
  setActive,
  isWide,
  setWide,
  user,
  handleCart,
}) => {
  const colors = useTheme();
  // const [isWide, setWide] = useState<boolean>(true);
  const [showText, setShowText] = useState<boolean>(isWide);

  useEffect(() => {
    if (isWide) {
      setTimeout(() => {
        setShowText(isWide);
      }, 200);
    } else {
      setTimeout(() => {
        setShowText(isWide);
      }, 30);
    }
  }, [isWide]);
  return (
    <div css={containerCss}>
      <div css={navCss(colors, isWide)}>
        <button data-aid="button-Navigation-cart" onClick={handleCart}>cart</button>
        <div css={bottomSeperator(colors)}>
          {Tabs.map((tab, index) => {
            return (
              <div
                key={index}
                className={
                  active === tab.label ? "active_tab tab ripple" : "tab ripple"
                }
                onClick={() => setActive(tab.label)}
              >
                {getIcon(tab.icon, "")}
                {showText && <div className="label">{tab.label}</div>}
              </div>
            );
          })}
        </div>
        <div
          css={bottomSeperator(colors)}
          style={{ flexGrow: 1, minHeight: "300px" }}
        >
          <div css={labelCss(colors)}>{showText && "Managment"}</div>
          {managementTabs.map((tab, index) => {
            return (
              <div
                key={index}
                className={
                  active === tab.label ? "active_tab tab ripple" : "tab ripple"
                }
                onClick={() => setActive(tab.label)}
              >
                {getIcon(tab.icon, "")}
                {showText && <div className="label">{tab.label}</div>}
              </div>
            );
          })}
          <div style={{ bottom: "12px", position: "absolute" }}>
            <div
              className={
                active === "notifications"
                  ? "active_tab tab ripple"
                  : "tab ripple"
              }
            >
              {getIcon("bell", "")}
              {showText && <div className="label">Notifications</div>}
            </div>
            <div css={userSettingsCss(colors)}>
              <div className="userInfo">
                <img src={user.img} />
                {showText && <label>{user.fullname}</label>}
              </div>
              <div>
                {showText && (
                  <Popup
                    borderRadius={2}
                    arrow
                    body={<div>asaf</div>}
                    placement="top-start"
                  >
                    <button data-aid="button-Navigation-right">
                      <BnRightAction />
                    </button>
                  </Popup>
                )}
              </div>
            </div>
          </div>
        </div>

        <div css={bottomContainer(colors)}>
          <button data-aid="button-Navigation-cl" css={collapsableButtonCss} onClick={() => setWide(!isWide)}>
            <BnCollapse />
          </button>
          {showText ? (
            <Fragment>
              <div className="logo">
                <Logo />
              </div>
              <button data-aid="button-Navigation-sup" className="button">
                <BnSupport />
              </button>
            </Fragment>
          ) : (
            <div className="small-logo">
              <SmallLogo />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
