/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useContext } from 'react';
import { ModalBody } from '@app/react/components/shared/ModalBody';
import { StepFlowContext } from '@app/react/components/shared/StepFlow/context';
import { colors } from '@app/react/styles/theme';
import { Icons } from '@bondsports/utils';
import { TranslationEn } from '@assets/i18n/en';

export const ConfirmationModal = ({ handleSendRequest }) => {
	const { previousStep } = useContext(StepFlowContext);
	const { title, description, descriptionSecondLine, mainButton, subButton } =
		TranslationEn.customers.paymentsInvoices.paymentRequestModal.confirmationModal;

	return (
		<ModalBody
			icon={Icons.conflict}
			iconColor={colors.dangerRed}
			title={title}
			description={description}
			descriptionSecondLine={descriptionSecondLine}
			mainAction={handleSendRequest}
			mainButton={mainButton}
			subButton={subButton}
			subAction={previousStep}
		/>
	);
};
