import { GetOrganizationPromoCodesDto, PaginationResultDto } from '@bondsports/types';
import { IPromoCodeState } from '.';
import { IOption } from '../../../types';

export enum StateEnum {
	LOADING = 'loading',
	ERROR = 'error',
	APPLIED = 'applied',
	NO_CODES = 'noCodes',
	CODES = 'codes',
}

export interface PromoCodeSelectProps {
	onChange: (promoCodeState: Partial<IPromoCodeState>) => void;
	initialState: IPromoCodeState;
	fetchData: (query: GetOrganizationPromoCodesDto) => Promise<PaginationResultDto<IOption>>;
}
