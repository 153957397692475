import React from "react";

export const LatePickup = (props: any) => (
  <svg
    data-aid="SvgLatePick"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM7.16667 4C7.16667 3.44772 7.61438 3 8.16667 3C8.71895 3 9.16667 3.44772 9.16667 4L9.16667 7.53162L10.8069 8.89844C11.2311 9.25201 11.2885 9.88257 10.9349 10.3069C10.5813 10.7311 9.95076 10.7885 9.52648 10.4349L7.52648 8.76822C7.29849 8.57823 7.16667 8.29678 7.16667 8V4Z"
      fill="currentColor"
    />
  </svg>
);
