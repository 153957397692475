/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { Icons } from '../../../types';
import { css, jsx } from '@emotion/react';
import { PaymentStatusEnum } from '../../invoicing'; //TO DO use types
import { paymentStatusColorMapper } from '../../../organisms/invoiceTemplate/types';
import { i18n } from '../../../lib/i18n';
import { IconWithTooltip } from '../../IconWithTooltip';

export interface Props {
	paymentStatus: PaymentStatusEnum;
	size?: number;
}
export const PaymentStatusIndication = ({ paymentStatus, size = 16 }: Props) => {
	const paymentStatusTooltipMapper = i18n.paymentStatusTooltipMapper;

	return (
		<IconWithTooltip
			icon={Icons.indications_payment}
			text={paymentStatusTooltipMapper[paymentStatus]}
			color={paymentStatusColorMapper[paymentStatus]}
			size={size}
		/>
	);
};
