import { IOption } from '.';
import { CHARACHERS } from '../../lib/constants';
import { IGroup } from '../Options_v2/types';

const filterSelectedOptions = (options: IOption[], values: string & readonly string[], isOrderBySelection = false) => {
	return isOrderBySelection
		? values.map(v => options?.find(option => String(option?.value) === String(v))).filter(opt => opt)
		: options.filter(option => values.map(v => String(v)).includes(String(option.value)));
};

const generateFlatArray = (options: IGroup[]): IOption[] => {
	return options.reduce((result, group) => {
		return result.concat(
			group.options
				?.map(optio => {
					return { ...optio, key: group.label } as never;
				})
				.filter(opt => Boolean(opt))
		);
	}, []);
};

const getAllOptions = (options: IGroup[], prevOPtions: IOption[]): IOption[] => {
	const flatArray = generateFlatArray(options);
	const uniquePrevOptions: IOption[] = [];

	prevOPtions.forEach(op => {
		if (!flatArray.map(t => t.value).includes(op.value)) {
			uniquePrevOptions.push(op);
		}
	});
	return [...uniquePrevOptions, ...flatArray];
};

export function getLabels({
	prevSelectedValues,
	options,
	values,
	isGroups,
	isOrderBySelection,
}: {
	prevSelectedValues: IOption[];
	options: IOption[] | IGroup[];
	values: string & readonly string[];
	isGroups: boolean;
	isOrderBySelection: boolean;
}) {
	const flatOptions = !isGroups ? [...options] : getAllOptions(options as IGroup[], prevSelectedValues);

	return filterSelectedOptions(flatOptions as IOption[], values, isOrderBySelection);
}

export const getAdditionalInfo = (text: string) =>
	`${CHARACHERS.OPEN_PARENTHESES}${text}${CHARACHERS.CLOSE_PARENTHESES}`;
