/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { ThreeRowModalLayoutProps } from './types';
import { Column, Row } from '../../../../atoms/layoutPrimitives';
import { contentRowCss, footerRowCss, headerRowCss, layoutSizeCss, modalLayoutContainerCss } from './styles';

export const ThreeRowModalLayout = ({
	header,
	content,
	footer,
	headerSeparator = true,
	footerSeparator,
	style,
	iswithTabs = false,
	contentMinHeight,
}: ThreeRowModalLayoutProps) => {
	return (
		<div id="modal-layout-container" css={[modalLayoutContainerCss, style]} data-aid="index-three-row-layout">
			<Column fullWidth styles={layoutSizeCss}>
				<Row fullWidth styles={headerRowCss(iswithTabs, headerSeparator)}>
					{header}
				</Row>

				<Row fullWidth styles={contentRowCss(contentMinHeight)}>
					{content}
				</Row>
				{footerSeparator && <div className="modal-layout-separator" />}
				<Row fullWidth styles={footerRowCss}>
					{footer}
				</Row>
			</Column>
		</div>
	);
};
