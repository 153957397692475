import React, { Fragment, useCallback, useRef, useState } from 'react';
import { StyledSelectOption } from './style';
import { Theme, useTheme } from '@emotion/react';
import { capitalize } from '../../functions';

import { Typography } from '../../atoms';
import { ETypography, ETypographyColor, Icons } from '../../types/theme';
import { Checkbox } from '../Checkbox';
import { BnIcon } from '../icons_v2';
import { ISelectOptions } from './types';
import { IOption } from '../../types';

export const SelectOptions = ({
	options,
	onSelect,
	value = [],
	autoFocus = true,
	isMultiple = false,
	enableSelectAll = false,
	selectAllLabel = '',
	handleSelectAll,
	handleRemoveAll,
	size = 'M',
	infiniteScroll = false,
	handleLoadNextPage,
	isLoading,
	isFinalPage,
	originalOptions,
}: ISelectOptions) => {
	const colors = useTheme();

	const newValue = Array.isArray(value) ? value : value ? [value] : [];

	const observer = useRef<IntersectionObserver | null>(null);
	const lastOptionElementRef = useCallback(
		node => {
			if (isLoading) {
				return;
			}
			if (observer.current && observer.current?.disconnect) {
				observer?.current?.disconnect();
			}
			observer.current = new IntersectionObserver(entries => {
				const [entry] = entries; //There is only one entry because the ref is on the last div
				if (entry.isIntersecting && isFinalPage) {
					if (handleLoadNextPage) {
						handleLoadNextPage();
					}
				}
			});
			if (node) {
				observer?.current?.observe(node);
			}
		},
		[isLoading, isFinalPage]
	);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
			}}
			data-aid="SelectOptions-Options"
		>
			{options.length === 0 && <div>No Results</div>}
			{options.length > 0 && isMultiple && enableSelectAll && (
				<StyledSelectOption
					theme={{ ...colors }}
					size={size}
					onClick={() => {
						if (value.length === options.length) {
							if (handleRemoveAll) {
								handleRemoveAll();
							}
						} else {
							if (handleSelectAll) {
								handleSelectAll();
							}
						}
					}}
					tabIndex={0}
					autoFocus={false}
				>
					<Typography color={ETypographyColor.primary} type={size === 'M' ? ETypography.body2 : ETypography.cta1}>
						<span>
							{isMultiple && <Checkbox width={16} setChecked={() => {}} checked={value.length === options.length} />}

							{capitalize(selectAllLabel || 'Select All')}
						</span>
					</Typography>
				</StyledSelectOption>
			)}
			{options.map((opt, index) => {
				const val = String(opt.value);

				return infiniteScroll && originalOptions?.length === index + 1 ? (
					<div ref={lastOptionElementRef}>
						<SelectOption
							colors={colors}
							index={index}
							size={size}
							onSelect={onSelect}
							opt={opt as IOption}
							autoFocus={autoFocus}
							isMultiple={isMultiple}
							newValue={newValue}
							value={value}
							val={val}
						/>
					</div>
				) : (
					<SelectOption
						colors={colors}
						index={index}
						size={size}
						onSelect={onSelect}
						opt={opt as IOption}
						autoFocus={autoFocus}
						isMultiple={isMultiple}
						newValue={newValue}
						value={value}
						val={val}
					/>
				);
			})}
		</div>
	);
};

interface OptionProps {
	colors: Theme;
	index: number;
	opt: IOption;
	newValue: string[] | number[] | Date[];
	value: string | string[] | number[] | Date[];
	val: string;
	size: 'S' | 'M';
}

const SelectOption = ({
	colors,
	index,
	size,
	onSelect,
	opt,
	autoFocus,
	isMultiple,
	newValue,
	value,
	val,
}: Omit<ISelectOptions, 'size' | 'options'> & OptionProps) => {
	return (
		<StyledSelectOption
			theme={{ ...colors }}
			key={index}
			onClick={() => onSelect(opt.value)}
			tabIndex={0}
			size={size}
			autoFocus={autoFocus ? index === 0 : false}
			isDisabled={opt.isDisabled}
		>
			<Typography
				color={opt.isDisabled ? ETypographyColor.secondary : ETypographyColor.primary}
				type={ETypography.body2}
			>
				<span>
					{isMultiple && (
						<Checkbox
							width={16}
							functionDisable
							setChecked={() => {}}
							checked={(newValue as string[]).map(v => String(v)).includes(String(val))}
						/>
					)}
					{opt.icon && <BnIcon icon={opt.icon} />}
					{capitalize(opt.label)}
				</span>

				{!isMultiple && String(value) === String(val) && <BnIcon icon="check" />}
			</Typography>
		</StyledSelectOption>
	);
};
