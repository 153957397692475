import { getFamilyPagePath } from '../../../lib/paths';
import { ERoutePaths } from '../../../types/navigation';
import { TranslationEn } from '@assets/i18n/en';

//TODO: export this interface form @bondsports/utils - Breadcrumbs (need to add export)
interface Step {
	label: string;
	to: string;
}

const customerLabels = TranslationEn.customers;
const CUSTOMERS = customerLabels.allCustomers;
const CUSTOMER_PROFILE = customerLabels.customerPage.customerProfile;
const FAMILY_PROFILE = customerLabels.familyPage.familyProfile;

const getFamilyPath = (url: string) => {
	//the goal: '/family/256614/customer/256613/overview' => '/family/256614/overview'
	const pathBlocks = url.split('/');
	const familyIdIndex = pathBlocks.findIndex(el => el === ERoutePaths.FAMILY) + 1;
	const familyId = pathBlocks[familyIdIndex];
	const familyPagePath = getFamilyPagePath({ familyId });

	return familyPagePath;
};

export const setBreadCrumbsPaths = (location: Location, setBreadCrumbs: (v: Step[]) => void) => {
	const newBreadCrumbSteps = [{ label: CUSTOMERS, to: '/' }];
	const { pathname } = location;

	if (pathname.includes(ERoutePaths.FAMILY)) {
		const familyPath = getFamilyPath(pathname);
		newBreadCrumbSteps.push({ label: FAMILY_PROFILE, to: familyPath });
	}
	if (pathname.includes(ERoutePaths.CUSTOMER)) {
		newBreadCrumbSteps.push({ label: CUSTOMER_PROFILE, to: pathname });
	}
	setBreadCrumbs(newBreadCrumbSteps);
};
