import { useTheme } from '@emotion/react';
import React from 'react';
import { EBrandingColorsOptions } from '../../types/theme';
// import './index.scss';
import { StyledLoader } from './style';

export const Loader = ({
	color = EBrandingColorsOptions.tx_text_primary,
	width = 20,
	margin = '0 30px',
}: {
	color?: EBrandingColorsOptions;
	width?: number;
	margin?: string;
}) => {
const colors = useTheme();
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
			}}
		>
			<StyledLoader color={colors[color]} width={width} margin={margin}>
				<div data-aid="loader" className="sk-fading-circle">
					<div className="sk-circle1 sk-circle"></div>
					<div className="sk-circle2 sk-circle"></div>
					<div className="sk-circle3 sk-circle"></div>
					<div className="sk-circle4 sk-circle"></div>
					<div className="sk-circle5 sk-circle"></div>
					<div className="sk-circle6 sk-circle"></div>
					<div className="sk-circle7 sk-circle"></div>
					<div className="sk-circle8 sk-circle"></div>
					<div className="sk-circle9 sk-circle"></div>
					<div className="sk-circle10 sk-circle"></div>
					<div className="sk-circle11 sk-circle"></div>
					<div className="sk-circle12 sk-circle"></div>
				</div>
			</StyledLoader>
		</div>
	);
};
