import dayjs from "dayjs";
import React from "react";

interface IActivityTime {
  dayOfWeek: number;
  open: string;
  close: string;
}

interface ICalculatedActivityTime {
  open: string;
  close: string;
  days: string;
  duration: string;
}

const shortDaysMapper = {
  2: "mon",
  3: "tue",
  4: "wed",
  5: "thu",
  6: "fri",
  7: "sat",
  8: "sun",
};

/**
 * @description the main hook to handle all time formatting we need in our projects
 */
export const useTimeFormats = () => {
  /**
   *
   * @param diff -> calculate it with : dayjs(endTime).diff(dayjs(startTime),"minutes")
   * @description calcualtes the duration of the time and generate to display format string
   * @example result: 1h 40min
   * @returns string
   */
  const generateDuration = (diff: number) => {
    let string =
      Math.floor(diff / 60) >= 1
        ? `${Math.floor(diff / 60)}h${diff % 60 > 0 ? ` ${diff % 60}min` : ""}`
        : `${diff % 60 > 0 ? `${diff % 60}min` : ""}`;
    return string;
  };

  /**
   * @param activityTimesArray
   * @description calculates all the data we need to display days, hours and duration
   * @example result = [{
          open: “18:00:00”,
          close: “19:00:00",
          days: “mon - wed , fri” ,
          duration: “1h”,
    }]
   * @returns ICalculatedActivityTime[]
   */
  const generateDaysOfActivity = (
    activityTimesArray: IActivityTime[]
  ): ICalculatedActivityTime[] => {
    const daysByHours = {};
    activityTimesArray.forEach((at) => {
      if (daysByHours[`${at.open}-${at.close}`]) {
        daysByHours[`${at.open}-${at.close}`] = [
          ...daysByHours[`${at.open}-${at.close}`],
          at.dayOfWeek,
        ];
      } else {
        daysByHours[`${at.open}-${at.close}`] = [at.dayOfWeek];
      }
    });

    let activityTimeOrganized = {};

    Object.keys(daysByHours).forEach((activityHour) => {
      let str = "";
      daysByHours[activityHour]
        .sort((a: number, b: number) => a - b)
        .forEach((dayOfWeek: number, index: number) => {
          if (str.includes(`${dayOfWeek - 2},${dayOfWeek - 1}`)) {
            str = str.replace(`,${dayOfWeek - 1}`, `-${dayOfWeek}`);
          } else {
            if (str.includes(`-${dayOfWeek - 1}`)) {
              str = str.replace(`${dayOfWeek - 1}`, `${dayOfWeek}`);
            } else {
              if (index !== 0) {
                str += ",";
              }
              str += dayOfWeek;
            }
          }
        });

      Object.keys(shortDaysMapper).forEach((dayOfWeek) => {
        str = str.replace(dayOfWeek, ` ${shortDaysMapper[dayOfWeek]} `);
      });
      activityTimeOrganized[activityHour] = str.trim();
    });

    const arr = Object.keys(activityTimeOrganized).map((hours) => {
      const time = hours.split("-");
      const diff = dayjs(time[1], "HH:mm:ss").diff(
        dayjs(time[0], "HH:mm:ss"),
        "minutes"
      );
      return {
        open: time[0],
        close: time[1],
        days: activityTimeOrganized[hours],
        duration: generateDuration(diff),
      };
    });

    return arr;
  };
  return { generateDuration, generateDaysOfActivity };
};
