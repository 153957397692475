import * as  React from 'react';

export const BnReports = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 3.33341V4.16675C16.3783 4.16675 17.5 5.28841 17.5 6.66675V15.8334C17.5 17.2117 16.3783 18.3334 15 18.3334H5C3.62167 18.3334 2.5 17.2117 2.5 15.8334V6.66675C2.5 5.28841 3.62167 4.16675 5 4.16675V3.33341C5 2.41425 5.7475 1.66675 6.66667 1.66675H13.3333C14.2525 1.66675 15 2.41425 15 3.33341ZM6.66667 3.33341L13.3333 3.33591V6.66675H6.66667V3.33341ZM15.8333 15.8334C15.8333 16.2934 15.4592 16.6667 15 16.6667H5C4.54083 16.6667 4.16667 16.2934 4.16667 15.8334V6.66675C4.16667 6.20675 4.54083 5.83342 5 5.83342V6.66675C5 7.58592 5.7475 8.33342 6.66667 8.33342H13.3333C14.2525 8.33342 15 7.58592 15 6.66675V5.83342C15.4592 5.83342 15.8333 6.20675 15.8333 6.66675V15.8334Z"
      fill="currentColor"
    />
  </svg>
);
