import React from 'react';

export const CorporateEvents = (props: any) => (
	<svg data-aid="SvgCorporateEvents" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M4.07796 2.18927C2.97339 2.18927 2.07796 3.0847 2.07796 4.18927V16.2603C2.07796 17.3648 2.97339 18.2603 4.07796 18.2603H5.02232C5.5746 18.2603 6.02232 17.8125 6.02232 17.2603C6.02232 16.708 5.5746 16.2603 5.02232 16.2603H4.07796V4.18927L19.1321 4.18927V8.96635C19.1321 9.51863 19.5798 9.96635 20.1321 9.96635C20.6844 9.96635 21.1321 9.51863 21.1321 8.96635V4.18927C21.1321 3.0847 20.2367 2.18927 19.1321 2.18927H4.07796Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.4604 13.7634C13.2357 13.7634 14.6794 12.3197 14.6794 10.5444C14.6794 8.76915 13.2357 7.32544 11.4604 7.32544C9.68516 7.32544 8.24145 8.76915 8.24145 10.5444C8.24145 12.3197 9.68516 13.7634 11.4604 13.7634ZM13.0699 10.5444C13.0699 9.65678 12.348 8.93492 11.4604 8.93492C10.5728 8.93492 9.85093 9.65678 9.85093 10.5444C9.85093 11.432 10.5728 12.1539 11.4604 12.1539C12.348 12.1539 13.0699 11.432 13.0699 10.5444Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.8983 15.3729C19.2294 15.3729 20.3126 14.2897 20.3126 12.9586C20.3126 11.6276 19.2294 10.5444 17.8983 10.5444C16.5673 10.5444 15.4841 11.6276 15.4841 12.9586C15.4841 14.2897 16.5673 15.3729 17.8983 15.3729ZM18.7031 12.9586C18.7031 12.5152 18.3426 12.1539 17.8983 12.1539C17.4541 12.1539 17.0936 12.5152 17.0936 12.9586C17.0936 13.402 17.4541 13.7634 17.8983 13.7634C18.3426 13.7634 18.7031 13.402 18.7031 12.9586Z"
			fill="currentColor"
		/>
		<path
			d="M21.1173 21.006C21.5623 21.006 21.9221 20.6455 21.9221 20.2013C21.9221 17.9826 20.117 16.1776 17.8983 16.1776C16.9962 16.1776 16.1352 16.4786 15.4358 17.0194C14.4162 16.003 13.0112 15.3729 11.4604 15.3729C8.35492 15.3729 5.82722 17.8997 5.82722 21.006C5.82722 21.4503 6.18694 21.8108 6.63197 21.8108C7.07699 21.8108 7.43671 21.4503 7.43671 21.006C7.43671 18.7874 9.24174 16.9823 11.4604 16.9823C13.6791 16.9823 15.4841 18.7874 15.4841 21.006C15.4841 21.4503 15.8438 21.8108 16.2889 21.8108C16.7339 21.8108 17.0936 21.4503 17.0936 21.006C17.0936 20.0299 16.8425 19.1109 16.4047 18.3094C16.8264 17.9754 17.3487 17.7871 17.8983 17.7871C19.2294 17.7871 20.3126 18.8703 20.3126 20.2013C20.3126 20.6455 20.6723 21.006 21.1173 21.006Z"
			fill="currentColor"
		/>
	</svg>
);
