import { environment } from 'environments/environment';
import { network } from '../network';
import { IErrorArr } from '@app/react/types/errors';
import { OrderByEnum, ProgramEventDto, ProgramSegmentDto, QuestionnaireDto } from '@bondsports/types';
import { getEpochTime } from '@app/react/lib/dates';

const v4APIUrl = `${environment.CS_URLS.API_ROOT_V4}`;

async function getUserQuestionnaires(organizationId: number, userId: number): Promise<QuestionnaireDto[] | IErrorArr> {
	const response = await network.get(`${v4APIUrl}/questionnaires/organization/${organizationId}/user/${userId}`);
	return response;
}

async function getUserProgramSegments(
	organizationId: number,
	userId: number,
	programId: number,
	productUserId: number,
	itemsPerPage: number,
	startDate: Date,
	endDate: Date,
	orderBy: OrderByEnum
): Promise<ProgramSegmentDto[]> {
	const query = `productUserId=${productUserId}&itemsPerPage=${itemsPerPage}&startDate=${getEpochTime(
		startDate
	)}&endDate=${getEpochTime(endDate)}&orderBy=${orderBy}&returnLastDeletedEvent=true`;

	return await network.get(
		`${v4APIUrl}/user-activities/organization/${organizationId}/user/${userId}/program/${programId}/segments?${query}`
	);
}

async function getUserProgramEvents(
	organizationId: number,
	userId: number,
	programId: number,
	productUserId: number,
	itemsPerPage: number,
	startDate: Date,
	endDate: Date,
	orderBy: OrderByEnum
): Promise<ProgramEventDto[]> {
	const query = `productUserId=${productUserId}&itemsPerPage=${itemsPerPage}&startDate=${getEpochTime(
		startDate
	)}&endDate=${getEpochTime(endDate)}&orderBy=${orderBy}&returnLastDeletedEvent=true`;

	return await network.get(
		`${v4APIUrl}/user-activities/organization/${organizationId}/user/${userId}/program/${programId}/events?${query}`
	);
}

async function getUserEventAddons(
	organizationId: number,
	userId: number,
	eventId: number,
	productUserId: number,
	itemsPerPage: number,
	page: number
) {
	const query = `productUserId=${productUserId}&itemsPerPage=${itemsPerPage}&page=${page || 1}`;

	return await network.get(
		`${v4APIUrl}/user-activities/organization/${organizationId}/user/${userId}/events/${eventId}/addons?${query}`
	);
}

async function getUserSegmentAddons(
	organizationId: number,
	userId: number,
	segmentId: number,
	productUserId: number,
	itemsPerPage: number,
	page: number
) {
	const query = `productUserId=${productUserId}&itemsPerPage=${itemsPerPage}&page=${page || 1}`;

	return await network.get(
		`${v4APIUrl}/user-activities/organization/${organizationId}/user/${userId}/segments/${segmentId}/addons?${query}`
	);
}

export const userApi = {
	getUserQuestionnaires,
	getUserProgramEvents,
	getUserProgramSegments,
	getUserEventAddons,
	getUserSegmentAddons,
};
