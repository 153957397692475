/** @jsx jsx */
import { Typography } from '@bondsports/utils';
import { Button, Checkbox, Input } from '@bondsports/utils';
import React, { useState, Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import { ETypography, ETypographyColor, Icons } from 'app/react/styles/theme';
import { colors } from 'app/react/styles/theme';
import { useStation } from 'app/react/hooks/useStation';
import { organizationApi } from 'app/react/lib/api/organizationApi';
import { useOrganization } from 'app/react/hooks/useOrganization';
import { useErrorModalDepricated } from '../../shared/ErrorDepricated';
import { TranslationEn } from '@assets/i18n/en';
import { ParseError } from 'app/react/lib/network';

const containerCss = css`
	display: flex;
	flex-direction: column;
`;

const headerCss = css`
	padding: 36px 40px 24px 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	border-bottom: 1px solid ${colors.borderSeperator};
	.h4 {
		margin-bottom: 8px;
	}
`;

const buttonContainerCss = css`
	display: flex;
	justify-content: flex-end;
	padding: 24px 40px 32px 40px;
`;

const bodyCss = css`
	padding: 24px 40px 0 40px;
	label {
		display: flex;
	}
	.error {
		margin-bottom: 6px;
	}
`;

export const CloseRegistration = ({
	toggle,
	handleError,
}: {
	toggle: () => void;
	handleError: (m: string) => void;
}) => {
	const [amount, setAmount] = useState<string>('');
	const [isChecked, setChecked] = useState<boolean>(false);
	const { connectedStation } = useStation();
	const [isProcessing, setProcessing] = useState<boolean>(false);
	const { organizationId } = useOrganization();
	const [err, setErr] = useState<string>('');
	const { ErrorToggle, ErrorModalDepricated } = useErrorModalDepricated();

	const labels = TranslationEn.facilityController;

	const handleOpenShift = () => {
		setProcessing(true);
		organizationApi
			.closeShift(organizationId, connectedStation?.currentOpenShift?.id, {
				closingCashAmount: Number(amount),
			})
			.then(res => {
				setProcessing(false);
				if (res.data) {
					toggle();
				} else {
					// handle error
					ErrorToggle();
					setErr(String(res.err));
				}
			})
			.catch(err => {
				const error = ParseError(err);
				toggle();
				handleError(error?.message);
			});
	};

	return (
		<Fragment>
			<div data-aid="closeRegistration" css={containerCss}>
				<div css={headerCss}>
					<Typography type={ETypography.h4} color={ETypographyColor.primary}>
						{labels.close.title}
					</Typography>
					<Typography type={ETypography.captionAccented} color={ETypographyColor.secondary}>
						{connectedStation.name} - {`${labels.shiftId}: ${connectedStation?.currentOpenShift?.id}`}
					</Typography>
				</div>
				<div css={bodyCss}>
					<Input
						data-aid="closeRegistration-input-amount"
						value={amount}
						onChange={e => setAmount(e.target.value)}
						sizer="S"
						label={labels.close.inputLabel}
						leftIcon={Icons.dollar}
						clear={() => setAmount('')}
					/>
					<Checkbox checked={isChecked} setChecked={v => setChecked(v)} label={labels.close.checkbox} />
				</div>
				<div css={buttonContainerCss}>
					<Button data-aid="button-closeRegistration-cancel" theme="basic" sizer="S" onClick={toggle}>
						{labels.cancel}
					</Button>
					<Button
						data-aid="button-closeRegistration-close"
						theme="primary"
						sizer="S"
						disabled={!isChecked || !amount}
						isProcessing={isProcessing}
						onClick={handleOpenShift}
					>
						{labels.closeRegister}
					</Button>
				</div>
			</div>
			<ErrorModalDepricated message={err} />
		</Fragment>
	);
};
