/** @jsx jsx */
import { mapPaymentMethod } from '@app/react/components/payments/lib';
import { colors, colors_v2 } from '@app/react/styles/theme';
import { flex } from '@app/react/styles/utils';
import { PaymentDetailAndIcon, Icons } from '@bondsports/utils';
import { jsx, css } from '@emotion/react';
import { memo } from 'react';
import { Stripe } from 'stripe';

const paymentMethodContainerCss = () => css`
	display: flex;
	border: 1px solid ${colors_v2.bg_border_primary};
	border-radius: 2px;
	margin: 5px;
	height: 36px;
	width: 110px;
	justify-content: center;
	svg {
		color: ${colors.brandPrimary};
	}
`;

const wrapContainerCss = css`
	${flex};
	width: 100%;
	flex-wrap: wrap;
`;

interface Props {
	paymentMethods: Stripe.PaymentMethod[];
}

export const PaymentMethods = ({ paymentMethods }: Props) => {
	const newPaymentMethods = paymentMethods
		.map(paymentMethod => {
			return mapPaymentMethod(paymentMethod);
		})
		.filter(paymentMethod => paymentMethod.label && paymentMethod.icon);

	return (
		<div css={wrapContainerCss}>
			{newPaymentMethods.map((paymentMethod, index) => {
				return (
					<PaymentMethodBox
						key={index}
						label={paymentMethod.label}
						icon={paymentMethod.icon}
						iconSize={paymentMethod.iconSize}
					/>
				);
			})}
		</div>
	);
};

const PaymentMethodBox = memo(({ icon, label, iconSize }: { icon: Icons; label: string; iconSize?: number }) => {
	return (
		<div css={paymentMethodContainerCss}>
			<PaymentDetailAndIcon text={label} icon={icon} iconSize={iconSize} />
		</div>
	);
});
