import React from "react";

export const EarlyDropoff = (props: any) => (
  <svg
    data-aid="SvgEarlyDropoff"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8.66667 4C8.66667 3.63181 8.36819 3.33333 8 3.33333C7.63181 3.33333 7.33333 3.63181 7.33333 4V7.68775L5.57321 9.15452C5.29036 9.39023 5.25214 9.81061 5.48785 10.0935C5.72356 10.3763 6.14394 10.4145 6.42679 10.1788L8.42679 8.51215C8.57878 8.38549 8.66667 8.19785 8.66667 8V4Z"
      fill="currentColor"
    />
  </svg>
);
