import React, { ReactNode, useState } from 'react';
import { useTheme } from '@emotion/react';
import { capitalize } from '../../functions';
import { StyledCheckbox } from './style';

import { ErrorMessage } from '../ErrorMessage';
import { ETypography, ETypographyColor } from '../../types/theme';
import { BnIcon } from '../icons_v2';

export interface Props {
	checked?: boolean;
	setChecked?: (val: boolean) => void;
	icon?: string;
	label?: string;
	width?: number;
	error?: string;
	isDisabled?: boolean;
	sizer?: 'M' | 'S';
	functionDisable?:boolean
}
export const Checkbox = ({
	checked,
	setChecked,
	label = '',
	sizer = 'S',
	icon = '',
	width = 20,
	error,
	isDisabled = false,
	functionDisable= false,
	...props
}: Props) => {
	const colors = useTheme();
	const [visited, setVisited] = useState<boolean>(false);

	const functionalityDisabled = isDisabled ? isDisabled : functionDisable

	return (
		<>
			<StyledCheckbox data-testid="styled-checkbox" theme={{ ...colors }} disabled={isDisabled} width={width}>
				{(label || icon) && (
					<div className={`${sizer}`} data-aid="index-Checkbox">
						{icon && <BnIcon icon={icon} />}
						{capitalize(label)}
					</div>
				)}
				<input
					data-testid="checkbox-input"
					type="checkbox"
					checked={checked}
					disabled={functionalityDisabled}
					onChange={e => {
						if (!functionalityDisabled) {
							//@ts-ignore
							if (setChecked) {
								//@ts-ignore
								setChecked(e.target.checked);
							}
						}
					}}
					{...props}
					onBlur={() => setVisited(true)}
				/>
				<span className={`checkmark ${visited && error ? 'error' : ''}`}></span>
			</StyledCheckbox>
			{visited && <ErrorMessage error={error || ''} />}
		</>
	);
};
