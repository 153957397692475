/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/react';
import { flexRowCss, gapCss, paddingBottomCss, paddingTopCss } from '../../../styles/utils';
import { useColors } from '../../../hooks/useColors';
import { Checkbox } from '../../../components/Checkbox';
import { Typography } from '../../../atoms/Typography';
import { ETypography, ETypographyColor, Icons } from '../../../types';
import { Tooltip } from '../../../components/Tooltip';
import { BnIcon } from '../../../components/icons_v2';

type CheckBoxContainerProps = {
	onChecked: (val: boolean) => void;
	withToolTip?: boolean;
	toolTipContent?: React.ReactNode;
	label: string;
	isChecked: boolean;
	disabled?: boolean;
};

export const CheckBoxContainer: React.FC<CheckBoxContainerProps> = ({
	onChecked,
	toolTipContent,
	withToolTip,
	label,
	isChecked,
	disabled,
}) => {
	const { colors } = useColors();

	return (
		<div css={[flexRowCss, gapCss(8)]} data-aid="CheckBoxContainer-NoteForm">
			<Checkbox isDisabled={disabled} checked={isChecked} sizer="M" width={25} setChecked={onChecked} />
			<div css={[flexRowCss, gapCss(8), paddingBottomCss(8)]}>
				<Typography type={ETypography.body1} color={ETypographyColor.secondary}>
					{label}
				</Typography>
				{withToolTip && (
					<Tooltip isTopLayer placement={'right-end'} content={toolTipContent}>
						<BnIcon icon={Icons.info} color={colors.bg_text_secondary} css={paddingTopCss(5)} />
					</Tooltip>
				)}
			</div>
		</div>
	);
};
