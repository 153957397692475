import React from 'react';

export const Next: React.FC<any> = props => {
	return (
		<svg data-aid="SvgNext" xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M10.7478 10.3306L10.7509 10.3337L9.81498 11.2696L9.81193 11.2665L4.27666 16.8018L3.34078 15.8659L8.87605 10.3306L3.33316 4.78775L4.26904 3.85187L9.81193 9.39476L9.82259 9.38409L10.7585 10.32L10.7478 10.3306Z"
				fill="currentColor"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M16.5811 10.3306L16.5842 10.3337L15.6483 11.2696L15.6453 11.2665L10.11 16.8018L9.17411 15.8659L14.7094 10.3306L9.16649 4.78774L10.1024 3.85187L15.6453 9.39476L15.6559 9.38409L16.5918 10.32L16.5811 10.3306Z"
				fill="currentColor"
			/>
		</svg>
	);
};
