import React from 'react';

export const BnPinned: React.FC<React.SVGProps<any>> = props => {
	return (
		<svg data-aid="SvgBnPInned" width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M14.1006 1.66772L21.8425 9.40966L20.7673 10.4849C19.9276 11.3245 18.7192 11.5706 17.6635 11.2231L14.0246 14.862C14.8346 16.5578 14.5374 18.6503 13.1329 20.0548L12.0576 21.1301L2.38017 11.4527L3.45543 10.3774C4.85997 8.97287 6.95249 8.67563 8.64822 9.48569L12.2871 5.8468C11.9396 4.79102 12.1857 3.58261 13.0253 2.74299L14.1006 1.66772Z"
				fill="currentColor"
			/>
			<path
				d="M7.75651 16.829L6.68124 15.7537L2.07294 20.362L1.84253 21.6677L3.14821 21.4373L7.75651 16.829Z"
				fill="currentColor"
			/>
		</svg>
	);
};
