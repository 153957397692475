import React from "react";

export const Phone = (props: any) => (
  <svg
    data-aid="svgPhone"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.00015 6.6C2.00015 15.092 8.90815 22 17.4002 22C19.9362 22 22.0002 19.937 22.0002 17.4C22.0002 17.142 21.9782 16.886 21.9362 16.639C21.8952 16.388 21.8332 16.144 21.7512 15.908C21.6392 15.583 21.3662 15.339 21.0312 15.262L15.0662 13.893C14.7402 13.821 14.3942 13.912 14.1502 14.146C14.0082 14.282 14.0042 14.286 13.3542 15.527C11.2242 14.557 9.47915 12.818 8.47515 10.644C9.71515 9.995 9.71815 9.992 9.85415 9.85C10.0882 9.606 10.1832 9.262 10.1072 8.934L8.73815 2.97C8.66115 2.634 8.41615 2.361 8.09115 2.248C7.85315 2.166 7.60915 2.104 7.36615 2.065C7.11715 2.022 6.86015 2 6.60015 2C4.06315 2 2.00015 4.063 2.00015 6.6ZM4.00015 6.59999C4.00015 5.16599 5.16615 3.99999 6.60015 3.99999C6.70915 3.99999 6.81715 4.00699 6.92815 4.02099L7.98815 8.64199C7.81515 8.73199 7.62615 8.83099 7.45115 8.92299C6.58915 9.37299 5.90915 9.72899 6.26615 10.514C7.44115 13.86 10.1402 16.56 13.4462 17.72C14.2871 18.0586 14.6173 17.4285 15.0725 16.5602L15.0742 16.557C15.1348 16.4397 15.1993 16.3168 15.2625 16.1963C15.2953 16.1339 15.3278 16.0721 15.3592 16.012L19.9792 17.072C19.9932 17.18 20.0002 17.289 20.0002 17.4C20.0002 18.834 18.8332 20 17.4002 20C10.0112 20 4.00015 13.988 4.00015 6.59999Z"
      fill="currentColor"
    />
  </svg>
);
