import React from 'react';

export const FieldHockey = (props: any) => (
  <svg
    data-aid="svgFieldHockey"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.4338 12.6599L21.4192 3.97252L21.4202 3.97351C21.5211 3.86953 21.5833 3.72593 21.5833 3.56846C21.5833 3.41199 21.5221 3.26938 21.4231 3.16638C21.3241 3.06338 21.1863 3 21.0339 3H21.028C20.8727 3 20.732 3.06338 20.6301 3.16638L12.3797 11.5952L13.4338 12.6599Z"
      fill="currentColor"
    />
    <path
      d="M12.9096 13.2105L11.8827 14.2434L10.8004 13.1481L11.8468 12.1261L12.9096 13.2105Z"
      fill="currentColor"
    />
    <path
      d="M11.2974 14.8862L9.33574 16.9104C9.33574 16.9104 6.77438 19.549 5.93074 20.4222C5.46969 20.8916 4.83392 21.1818 4.13118 21.1818C2.72375 21.1818 1.58325 20.0182 1.58325 18.5821C1.58325 17.8632 1.86959 17.2125 2.33161 16.7421C2.48012 16.5906 2.6859 16.4965 2.91303 16.4965C3.36826 16.4965 3.73613 16.8728 3.73613 17.3363C3.73613 17.568 3.64392 17.778 3.49541 17.9295C3.34302 18.0939 3.24887 18.3157 3.24887 18.5604C3.24887 19.0664 3.65071 19.4774 4.14768 19.4774C4.38645 19.4774 4.60388 19.3824 4.76403 19.2279L10.2074 13.774L11.2974 14.8862Z"
      fill="currentColor"
    />
    <path
      d="M17.1955 19.6459C16.9565 20.581 16.1219 21.26 15.1299 21.26C13.954 21.26 12.9999 20.306 12.9999 19.13C12.9999 17.954 13.954 17 15.1299 17C15.3196 17 15.5036 17.0247 15.6781 17.0702C16.5885 17.3111 17.2599 18.1418 17.2599 19.1281C17.2599 19.3102 17.2372 19.4885 17.1936 19.6573L17.1955 19.6459Z"
      fill="currentColor"
    />
  </svg>
);
