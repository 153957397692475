/** @jsxRuntime classic */
/** @jsx jsx */
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { containerCss } from '../../styles';
import { jsx } from '@emotion/react';
import {
	Appendix,
	BillingInfo,
	Body,
	Details,
	Header,
	Notes,
	Payments,
	ResourceNameTypeEnum,
} from '../../../../molecules/invoicing';
import { EInvoiceVariant, IInvoiceTemplateLabels, IInvoiceTemplateState } from '../../types';
import { useInvoiceTemplate } from '../../hooks/useInvoiceTemplate';
import { i18n } from '../../../../lib/i18n';
import { EInvoiceAction, EInvoicingTabs, PAYMENT_VARIANTS } from '../../../../molecules/invoicing/types';
import { ScheduledPayments } from '../../../../molecules/invoicing/scheduledPayments';
import { useDetectPrint } from '../../../../hooks/useIsPrint';
import { PrintHeader } from '../../../../molecules/invoicing/printHeader/PrintHeader';
import { cloneDeep } from 'lodash';
import { useWindowSize } from '../../../../hooks/useWindowSize';

enum EScheduledChargeStatus {
	FUTURE = 'future',
	SUCCEDEDED = 'succeeded',
	CANCELED = 'canceled',
	PENDING = 'pending',
	FAILED = 'failed',
}

const PROGRAM_PURCHASED_RESOURCE_TYPE = [ResourceNameTypeEnum.EVENT, ResourceNameTypeEnum.PROGRAM_SEASON];
const BACKOFFICE_VARIANTS = [EInvoiceVariant.BACKOFFICE, EInvoiceVariant.BACKOFFICE_PREVIEW];
const PRINT_VARIANTS = [EInvoiceVariant.PRINT, EInvoiceVariant.PAYMENT_PRINT];

interface Props {
	state: IInvoiceTemplateState;
	activeTab: EInvoicingTabs;
	isLoading: boolean;
	setActiveTab?: (v: EInvoicingTabs) => void;
	setLoading: (v: boolean) => void;
	additionalInfoRow?: string; // I think this should be automatic in the component
	variant?: EInvoiceVariant;
	onAction: (action: EInvoiceAction, val?: any) => void;
	children?: ReactNode | ReactNode[];
	key?: string | number;
	organizationTimeZone?: string;
}

export const InvoiceTemplate: FC<Props> = props => {
	const { state, activeTab, variant: originalVariant = EInvoiceVariant.CONSUMER, organizationTimeZone } = props;
	const paymentVariant = PAYMENT_VARIANTS.includes(originalVariant);
	const { rows, appendix, payments, meta, handleProcessData, setShowVoid, showVoid } =
		useInvoiceTemplate(paymentVariant);

	const { isMobile } = useWindowSize();
	const isPrint = PRINT_VARIANTS.includes(originalVariant);
	const isBackoffice = BACKOFFICE_VARIANTS.includes(originalVariant);
	const variant = isBackoffice ? EInvoiceVariant.BACKOFFICE : originalVariant;
	const headerVariant =
		originalVariant === EInvoiceVariant.BACKOFFICE_PREVIEW ? EInvoiceVariant.BACKOFFICE_PREVIEW : variant;
	const isShowNotes = originalVariant === EInvoiceVariant.BACKOFFICE_PREVIEW || !isBackoffice;

	const labels = i18n.invoice;

	const { invoiceState, immutableState } = useMemo(() => {
		const invoiceState = { ...state?.invoice, meta };
		const immutableState = { ...state, invoice: invoiceState };
		return { invoiceState, immutableState };
	}, [state, meta]);

	const additional = useMemo(() => {
		const scheduledPayments = immutableState?.scheduledPayments ?? [];
		for (const sp of scheduledPayments) {
			if (sp.originalPlannedDate) {
				sp.plannedDate = sp.originalPlannedDate;
			}
		}
		return {
			rows,
			appendix,
			payments,
			scheduledPayments: immutableState.scheduledPayments,
		};
	}, [rows, appendix, payments, immutableState]);

	const showAppendix = activeTab === EInvoicingTabs.SCHEDULE;

	const showPrintAppendix = useMemo(() => {
		return (
			!paymentVariant &&
			isPrint &&
			additional.appendix.some((a: any[]) => {
				const isReservation = a?.some((li: any) => li?.slots?.length > 0);
				const isProgram = a.some((li: any) =>
					PROGRAM_PURCHASED_RESOURCE_TYPE.includes(li?.lineItem?.purchasedResources?.[0]?.resourceType)
				);
				return isReservation || isProgram;
			})
		);
	}, [variant, additional?.appendix, immutableState]);

	const handleOnAction = (action: EInvoiceAction, val: any) => {
		props.onAction(action, val);
	};

	const handleAddLineItemDiscount = (lineItemsIds: number[]) => {
		handleOnAction(EInvoiceAction.ADD_DISCOUNT, lineItemsIds);
	};

	useEffect(() => {
		handleProcessData(cloneDeep(immutableState), handleAddLineItemDiscount);
	}, [state, showVoid]);

	const showDetails = activeTab === EInvoicingTabs.DETAILS || isPrint;

	return (
		<div key={props.key ?? '1'} css={containerCss(isMobile && isPrint)} data-aid="InvoiceTemplate-invoice-template">
			<PrintHeader
				organization={state.organization}
				invoiceId={
					PAYMENT_VARIANTS.includes(variant) ? immutableState.invoice.payments?.[0]?.id : immutableState.invoice.id
				}
				isPrint={PRINT_VARIANTS.includes(variant)}
				variant={variant}
			/>
			<Header {...props} labels={labels} state={immutableState} additional={additional} variant={headerVariant} />
			{showDetails && (
				<Body>
					<BillingInfo
						{...props}
						labels={labels}
						state={immutableState}
						variant={variant}
						isShowTransactionFee={invoiceState.totalFeesAmount > 0}
						organizationTimeZone={organizationTimeZone}
					/>
					<Details
						{...props}
						labels={labels}
						state={immutableState}
						additional={additional}
						variant={variant}
						showVoid={showVoid}
						setShowVoid={setShowVoid}
						onRemoveDiscount={handleOnAction}
					/>
					<ScheduledPayments
						{...props}
						labels={labels as any}
						state={immutableState}
						additional={additional}
						variant={variant}
					/>
					<Payments
						{...props}
						labels={labels as any}
						state={immutableState}
						additional={additional}
						// Use original variant here for differences between backoffice types
						variant={originalVariant}
						onAction={handleOnAction}
						organizationTimeZone={organizationTimeZone}
					/>
					{isShowNotes && (
						<Notes
							{...props}
							labels={labels}
							state={immutableState}
							variant={variant}
							organizationTimeZone={organizationTimeZone}
						/>
					)}
					{Array.isArray(props.children)
						? props.children.map((child, i) => <div key={i}>{child}</div>)
						: props.children}
					{showPrintAppendix && (
						<Appendix {...props} labels={labels} state={immutableState} additional={additional} variant={variant} />
					)}
				</Body>
			)}
			{showAppendix && (
				<Body>
					<Appendix {...props} labels={labels} state={immutableState} additional={additional} variant={variant} />
				</Body>
			)}
		</div>
	);
};
