import React from 'react';

export const Dropdown_active = (props: any) => (
  <svg
    data-aid="svgDropdownActive"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 14.8508L12 9L18 14.8508L16.8215 16L12 11.2984L7.17851 16L6 14.8508Z"
      fill="currentColor"
    />
  </svg>
);
