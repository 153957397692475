import React, { Fragment, useState } from 'react';
import { Field as FinalField } from 'react-final-form';
import { Select, ISelect } from './index';
import { ErrorMessage } from '../ErrorMessage';
import { isPropertyAccessOrQualifiedName } from 'typescript';

export interface IFormProps extends ISelect {
	name: string;
	onClear?: (val: string) => void;
	required: boolean;
	onSelecting: (val1: string, val2: string) => void;
}

export const FormSelect = ({ ...props }: IFormProps & React.SelectHTMLAttributes<HTMLSelectElement>) => {
	const onSelect = (val: string) => {
		props.onSelecting(props.name, val);
	};
	return (
		<FinalField
			name={props.name}
			validate={val => {
				if (props.required) {
					if (!val) {
						let errors = {};
						errors[props.name] = 'required';
						return errors;
					}
				}
				return undefined;
			}}
			displayEmpty
		>
			{({ input, meta }) => {
				const newProps = { ...props };
				let clear;
				if (newProps.onClear) {
					//@ts-ignore
					clear = () => props.onClear(props.name);
					delete newProps?.onClear;
				}

				return (
					<div data-select="formSelect">
						<Fragment>
							<Select
								{...input}
								{...newProps}
								error={meta.error && meta.error[props.name]}
								clear={clear}
								onSelecte={onSelect}
							/>
						</Fragment>
					</div>
				);
			}}
		</FinalField>
	);
};
