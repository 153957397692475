import React from 'react';

export const Program_Club_Teams = (props: any) => (
  <svg
    data-aid="svgProgram_Club_Teams"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0)">
      <path
        d="M53.3333 55.1109H51.8927V44.2776C51.8927 40.9337 49.6669 38.7598 44.4591 37.0192L44.3655 36.9831L38.603 34.0942C38.1996 33.9065 36.0459 32.8592 36.0459 31.2776V28.3887H37.4865V31.2776C37.4865 31.747 38.5094 32.4692 39.2225 32.787L44.9705 35.6687C48.8746 36.9759 53.3333 39.1426 53.3333 44.2776V55.1109Z"
        fill="currentColor"
      />
      <path
        d="M12.9961 55.1109H11.5555V44.2776C11.5555 39.1426 16.0142 36.9759 19.9255 35.6614L25.6447 32.7942C26.4875 32.4114 27.4022 31.7037 27.4022 31.2776V28.3887H28.8429V31.2776C28.8429 32.8592 26.6891 33.9065 26.257 34.1014L20.4297 37.0192C15.2219 38.7598 12.9961 40.9337 12.9961 44.2776V55.1109Z"
        fill="currentColor"
      />
      <path
        d="M46.1302 47.1665H44.6896V55.1109H46.1302V47.1665Z"
        fill="currentColor"
      />
      <path
        d="M32.4444 31.2776C28.0721 31.2776 25.2413 27.3053 25.2413 21.1664V18.9998C25.2413 17.6564 26.1633 16.5226 27.4022 16.2048H29.9958V17.7397H30.4733L32.121 16.1109H36.7662C38.3581 16.1109 39.6475 17.4037 39.6475 18.9998V21.1664C39.6475 27.3053 36.8167 31.2776 32.4444 31.2776ZM28.1226 17.5553C27.3302 17.5553 26.6819 18.2053 26.6819 18.9998V21.1664C26.6819 25.3553 28.1946 29.8331 32.4444 29.8331C36.6942 29.8331 38.2068 25.3553 38.2068 21.1664V18.9998C38.2068 18.2053 37.5586 17.5553 36.7662 17.5553H32.6496L31.1111 19.0674L28.8429 18.9998V17.5553H28.1226Z"
        fill="currentColor"
      />
      <path
        d="M32.4444 41.4753L32.1203 41.3165C31.8681 41.1865 25.9616 38.1676 25.9616 33.4442H27.4022C27.4022 36.7303 31.2847 39.1859 32.4444 39.8431C33.6041 39.1787 37.4865 36.7159 37.4865 33.4442H38.9271C38.9271 38.1676 33.0206 41.1864 32.7685 41.3092L32.4444 41.4753Z"
        fill="currentColor"
      />
      <path
        d="M38.9271 24.7776V23.3331C39.7195 23.3331 40.3678 22.6831 40.3678 21.8887V20.4442C40.3678 20.047 40.0436 19.722 39.6475 19.722H38.9271V18.2776H39.6475C40.836 18.2776 41.8084 19.2526 41.8084 20.4442V21.8887C41.8084 23.4848 40.519 24.7776 38.9271 24.7776Z"
        fill="currentColor"
      />
      <path
        d="M25.9616 24.7776C24.3698 24.7776 23.0804 23.4848 23.0804 21.8887V20.4442C23.0804 19.2526 24.0528 18.2776 25.2413 18.2776H25.9616V19.722H25.2413C24.8452 19.722 24.521 20.047 24.521 20.4442V21.8887C24.521 22.6831 25.1693 23.3331 25.9616 23.3331V24.7776Z"
        fill="currentColor"
      />
      <path
        d="M41.0881 18.9998H39.6475V16.1109C39.6475 12.9259 37.0616 10.3331 33.885 10.3331H31.0038C27.8272 10.3331 25.2413 12.9259 25.2413 16.1109H23.8007C23.8007 12.1314 27.0349 8.88867 31.0038 8.88867H33.885C37.8539 8.88867 41.0881 12.1314 41.0881 16.1109V18.9998Z"
        fill="currentColor"
      />
      <path
        d="M20.1992 47.1665H18.7586V55.1109H20.1992V47.1665Z"
        fill="currentColor"
      />
      <path
        d="M42.1306 33.4358L42.1979 33.4095L46.3393 31.3014C46.6291 31.1644 48.177 30.4003 48.177 29.2461V27.1381H47.1416V29.2461C47.1416 29.5887 46.4065 30.1157 45.8941 30.3476L41.4473 32.6717L42.1306 33.4358Z"
        fill="currentColor"
      />
      <path
        d="M64.7424 46.6373H65.7777V38.7322C65.7777 34.9852 62.5734 33.4042 59.7624 32.445L55.6521 30.3528C55.0464 30.0735 54.389 29.5571 54.389 29.2461V27.1381H53.3537V29.2461C53.3537 30.4003 54.9015 31.1644 55.2121 31.3067L59.4 33.4358C63.1428 34.7059 64.7424 36.2922 64.7424 38.7322V46.6373Z"
        fill="currentColor"
      />
      <path
        d="M50.7653 29.2461C53.9076 29.2461 55.942 26.3476 55.942 21.868V20.287C55.942 19.3068 55.2794 18.4794 54.389 18.2475L50.5945 18.179H47.6593C46.5153 18.179 45.5886 19.1223 45.5886 20.287V21.868C45.5886 26.3476 47.6231 29.2461 50.7653 29.2461ZM53.8713 19.233C54.4408 19.233 54.9067 19.7073 54.9067 20.287V21.868C54.9067 24.9247 53.8196 28.1921 50.7653 28.1921C47.7111 28.1921 46.624 24.9247 46.624 21.868V20.287C46.624 19.7073 47.0899 19.233 47.6593 19.233H53.8713Z"
        fill="currentColor"
      />
      <path
        d="M50.7653 36.6874L50.9983 36.5715C51.1795 36.4766 55.4243 34.2738 55.4243 30.8271H54.389C54.389 33.225 51.5988 35.0168 50.7653 35.4964C49.9319 35.0116 47.1416 33.2145 47.1416 30.8271H46.1063C46.1063 34.2738 50.3512 36.4766 50.5324 36.5662L50.7653 36.6874Z"
        fill="currentColor"
      />
      <path
        d="M46.1063 24.5031V23.4491C45.5369 23.4491 45.071 22.9747 45.071 22.395V21.341C45.071 21.0512 45.3039 20.814 45.5886 20.814H46.1063V19.76H45.5886C44.7345 19.76 44.0356 20.4715 44.0356 21.341V22.395C44.0356 23.5597 44.9622 24.5031 46.1063 24.5031Z"
        fill="currentColor"
      />
      <path
        d="M55.4243 24.5031C56.5684 24.5031 57.495 23.5597 57.495 22.395V21.341C57.495 20.4715 56.7962 19.76 55.942 19.76H55.4243V20.814H55.942C56.2267 20.814 56.4597 21.0512 56.4597 21.341V22.395C56.4597 22.9747 55.9938 23.4491 55.4243 23.4491V24.5031Z"
        fill="currentColor"
      />
      <path
        d="M44.5533 20.287H45.5886V18.179C45.5886 15.8549 47.4471 13.9629 49.73 13.9629H51.8007C54.0836 13.9629 55.942 15.8549 55.942 18.179H56.9773C56.9773 15.2752 54.653 12.9089 51.8007 12.9089H49.73C46.8776 12.9089 44.5533 15.2752 44.5533 18.179V20.287Z"
        fill="currentColor"
      />
      <path
        d="M21.8693 33.4358L21.802 33.4095L17.6606 31.3014C17.3707 31.1644 15.8229 30.4003 15.8229 29.2461V27.1381H16.8583V29.2461C16.8583 29.5887 17.5933 30.1157 18.1058 30.3476L22.5526 32.6717L21.8693 33.4358Z"
        fill="currentColor"
      />
      <path
        d="M-0.742494 46.6373H-1.77783V38.7322C-1.77783 34.9852 1.42654 33.4042 4.23748 32.445L8.34778 30.3528C8.95345 30.0735 9.61089 29.5571 9.61089 29.2461V27.1381H10.6462V29.2461C10.6462 30.4003 9.0984 31.1644 8.78779 31.3067L4.59985 33.4358C0.857104 34.7059 -0.742494 36.2922 -0.742494 38.7322V46.6373Z"
        fill="currentColor"
      />
      <path
        d="M13.2346 29.2461C10.0923 29.2461 8.05788 26.3476 8.05788 21.868V20.287C8.05788 19.3068 8.7205 18.4794 9.61089 18.2475L13.4054 18.179H16.3406C17.4846 18.179 18.4113 19.1223 18.4113 20.287V21.868C18.4113 26.3476 16.3768 29.2461 13.2346 29.2461ZM10.1286 19.233C9.55912 19.233 9.09322 19.7073 9.09322 20.287V21.868C9.09322 24.9247 10.1803 28.1921 13.2346 28.1921C16.2888 28.1921 17.3759 24.9247 17.3759 21.868V20.287C17.3759 19.7073 16.91 19.233 16.3406 19.233H10.1286Z"
        fill="currentColor"
      />
      <path
        d="M13.2346 36.6874L13.0016 36.5715C12.8204 36.4766 8.57555 34.2738 8.57555 30.8271H9.61089C9.61089 33.225 12.4011 35.0168 13.2346 35.4964C14.068 35.0116 16.8583 33.2145 16.8583 30.8271H17.8936C17.8936 34.2738 13.6487 36.4766 13.4675 36.5662L13.2346 36.6874Z"
        fill="currentColor"
      />
      <path
        d="M17.8936 24.5031V23.4491C18.463 23.4491 18.9289 22.9747 18.9289 22.395V21.341C18.9289 21.0512 18.696 20.814 18.4113 20.814H17.8936V19.76H18.4113C19.2654 19.76 19.9643 20.4715 19.9643 21.341V22.395C19.9643 23.5597 19.0376 24.5031 17.8936 24.5031Z"
        fill="currentColor"
      />
      <path
        d="M8.57555 24.5031C7.4315 24.5031 6.50487 23.5597 6.50487 22.395V21.341C6.50487 20.4715 7.20373 19.76 8.05788 19.76H8.57555V20.814H8.05788C7.77316 20.814 7.54021 21.0512 7.54021 21.341V22.395C7.54021 22.9747 8.00611 23.4491 8.57555 23.4491V24.5031Z"
        fill="currentColor"
      />
      <path
        d="M19.4466 20.287H18.4113V18.179C18.4113 15.8549 16.5528 13.9629 14.2699 13.9629H12.1992C9.91631 13.9629 8.05788 15.8549 8.05788 18.179H7.02254C7.02254 15.2752 9.34688 12.9089 12.1992 12.9089H14.2699C17.1223 12.9089 19.4466 15.2752 19.4466 18.179V20.287Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
