/* @jsx jsx */
/* @jsxRuntime classic */

import React, { useState } from 'react';
import { jsx, css } from '@emotion/react';
import {
	displayCss,
	flexRowCss,
	justifyContentCss,
	paddingLeftCss,
	paddingRightCss,
	separatorCss,
} from '../../styles/utils';
import { Button } from '../../components/Button';
import { BnIcon } from '../../components/icons_v2';
import { ETypography, ETypographyColor, Icons } from '../../types';
import { Typography } from '../../atoms';
import { useColors } from '../../hooks/useColors';

const containerCss = (borderColor: string, bgColor: string) => css`
	border: 1px solid ${borderColor};
	border-radius: 4px;
	background: ${bgColor};
`;

type ExpandableSectionProps = {
	titleOnShow: string;
	titleOnHide: string;
	onToggle?: (expanded: boolean) => void;
	initiallyExpanded?: boolean;
};

export const ExpandableSection: React.FC<ExpandableSectionProps> = ({
	children,
	titleOnShow,
	titleOnHide,
	onToggle,
	initiallyExpanded = false,
}) => {
	const { colors } = useColors();
	const [isExpanded, setExpanded] = useState(initiallyExpanded);

	const toggle = () => {
		setExpanded(!isExpanded);
		onToggle?.(!isExpanded);
	};

	const buttonOnClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
		e.preventDefault();
		toggle();
	};

	return (
		<div
			css={[
				containerCss(colors.bg_border_seperator!, colors.bg_background_secondary!),
				paddingLeftCss(16),
				paddingRightCss(16),
			]}
			data-aid="index-ExpandableSection"
		>
			<div css={[flexRowCss, justifyContentCss('space-between')]} onClick={toggle}>
				<Typography type={ETypography.body2} color={ETypographyColor.primary}>
					{!isExpanded ? titleOnShow : titleOnHide}
				</Typography>
				<Button data-aid="button-ExpandableSection-drop" theme="basic" sizer="S" onClick={buttonOnClick}>
					{isExpanded ? <BnIcon icon={Icons.dropdown_active} /> : <BnIcon icon={Icons.dropdown} />}
				</Button>
			</div>
			{isExpanded && <div css={[separatorCss('top', colors.bg_border_seperator!)]}>{children}</div>}
		</div>
	);
};
