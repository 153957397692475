import styled from '@emotion/styled';
import { IColors } from '../../types/theme';
import { setOpacity } from '../../styles/utils';
import { css } from '@emotion/react';

export const StyledDateInput = styled.div<any>(
	({ theme: colors }: { theme: IColors }) => `
    display:flex;
    align-items:center;
    width:100%;
  `
);

export const containerStyles = css`
	display: flex;
	justify-content: space-between; /* Adjust as needed */
`;

export const StyledDateRangeInput = styled.div<any>(
	({ theme: colors }: { theme: IColors }) => `
      display: flex;
      border-radius: 8px;
      box-shadow: 0 0 10px ${setOpacity(colors.bg_system || 'black', 0.1)};
      overflow: hidden;
      width: 100%;
    `
);

export const StyledLeftArea = styled.div<any>(
	({ theme: colors }: { theme: IColors }) => `
      width: auto;
      padding: 16px;
      border-right: 1px solid ${colors.bg_border_seperator};
    `
);

export const StyledPredefinedRanges = styled.div<any>(
	({ theme: colors }: { theme: IColors }) => `
      h2 {
        font-size: 16px;
        margin-bottom: 8px;
        color: ${colors.tx_text_primary};

      }
  
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
  
      li {
        cursor: pointer;
        margin-bottom: 8px;
        color: ${colors.tx_text_primary};
  
        .range-info {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            display: flex;
            font-size: 16px;
            justify-content: space-between;
            width: 100%;
            padding: 5px;  
            min-width: 130px;
             &.selected{
              font-weight: bold;
          }
        }
        
      }
    `
);

export const StyledMainArea = styled.div<any>(
	({ theme: colors }: { theme: IColors }) => `
      flex: 1;
      display: flex;
      flex-direction: column;
    `
);

export const StyledMainBody = styled.div<any>(
	({ theme: colors }: { theme: IColors }) => `
      display: flex;
      justify-content: space-between;
      margin: 16px;
    `
);

export const StyledCalendars = styled.div<any>(
	({ theme: colors }: { theme: IColors }) => `
      display: flex;
    `
);

export const StyledMainFooter = styled.div<any>(
	({ theme: colors }: { theme: IColors }) => `
      display: flex;
      justify-content: space-between;
      padding: 16px;
      border-top: 1px solid ${colors.bg_border_seperator};
      align-items: center;
    `
);

export const Right = styled.div<any>`
	display: flex;
	height: 40px;
`;

export const ErrorStyle = styled.span<any>`
	display: flex;
	align-items: center;
`;
