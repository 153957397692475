import React from 'react';
import ReactDOM from 'react-dom';
import { RCOrganization } from '@rcenter/core';
import {
	AfterViewInit,
	Component,
	ElementRef,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
	Output,
	Input,
	EventEmitter,
} from '@angular/core';
import { AutoComplete } from 'app/react/Example/AutoComplete';

@Component({
	selector: 'react-settings-buttons',
	template: `<div #reactRoot></div>`,
	encapsulation: ViewEncapsulation.Emulated,
})
export class ReactSettingsButtonsComponent implements OnDestroy, AfterViewInit {
	@ViewChild('reactRoot') containerRef: ElementRef;

	/*
export interface IAngularProps {
  checked?: any;
  typed?: (val: any) => void;
  list: IOption[];
  clear?: () => void;
  onSelect?: any;
  placeholder?: string;
  label?: string;
}
  */
	@Input() list: { id: any; text: string }[];
	@Input() label: string;
	@Input() placeholder: string;
	@Input() selected: { id: any; text: string };

	@Output() onClear = new EventEmitter<void>();
	@Output() onSelect = new EventEmitter<any>();
	@Output() onType = new EventEmitter<string>();

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		this.render();
	}

	ngAfterViewInit() {
		this.render();
	}

	ngOnDestroy() {
		ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
	}

	open() {
		console.log('openning anyway  - who cares?');
	}

	private render() {
		if (!this.containerRef) return;

		ReactDOM.render(
			React.createElement(AutoComplete, {
				list: this.list,
				label: this.label,
				checked: this.selected,
				placeholder: this.placeholder,
				typed: (val: any) => this.onType.emit(val),
				clear: () => this.onClear.emit(),
				onSelect: obj => this.onSelect.emit(obj),
			}),
			this.containerRef.nativeElement
		);
	}
}
