import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AccessorConverter } from 'typedoc/dist/lib/converter/nodes';

@Component({
	selector: 'rc-range-age-picker',
	templateUrl: './range-age-picker.component.html',
	styleUrls: ['./range-age-picker.component.scss'],
})
export class RangeAgePickerComponent implements OnInit, OnChanges {
	@Input() min = 1;
	@Input() max = 100;
	@Input() minInterval = 1;
	@Input() isMonth: boolean = false;
	@Output() onChange = new EventEmitter<{ min: number; max: number; isMonths: boolean }>();
	minNumber: number;
	maxNumber: number;
	fromNumber: number;
	toNumber: number;
	initLoaded: boolean;

	constructor() {}

	ngOnInit() {
		this.setMinVal(this.min);
		this.setMaxVal(this.max);
		this.initLoaded = true;
	}

	ngOnChanges() {
		this.ngOnInit();
	}

	writeValue(value: [number, number, boolean]): void {
		if (!value) value = [this.minNumber, this.maxNumber, true];
		this.fromNumber = value[0];
		this.toNumber = value[1];
		this.isMonth = value[2];
		this.onChange.emit({ min: this.minNumber, max: this.maxNumber, isMonths: this.isMonth });
	}

	onOptionsSelected(val) {
		let value = val == 'true';

		this.writeValue([this.minNumber, this.maxNumber, value]);
	}

	get minVal() {
		return this.minNumber;
	}

	set minVal(value: number) {
		if (value >= 1 && value <= 100) {
			this.minNumber = value;
		}
	}

	setMaxVal(value: number) {
		if (value >= 1 && value <= 100) {
			this.maxNumber = value;

			this.writeValue([this.minNumber, value, this.isMonth]);
		}
	}

	setMinVal(value: number) {
		if (value >= 1 && value <= 100) {
			this.minNumber = value;
			this.writeValue([value, this.maxNumber, this.isMonth]);
		}
	}

	decrement() {
		this.minVal--;
		this.writeValue([this.minVal, this.maxVal, this.isMonth]);
	}

	increment() {
		this.minVal++;
		this.writeValue([this.minVal, this.maxVal, this.isMonth]);
	}

	get maxVal() {
		return this.maxNumber;
	}

	set maxVal(value) {
		if (value <= 100) {
			this.maxNumber = value;
		}
	}

	decrement_max() {
		this.maxVal--;
		this.writeValue([this.minVal, this.maxVal, this.isMonth]);
	}

	increment_max() {
		this.maxVal++;
		this.writeValue([this.minVal, this.maxVal, this.isMonth]);
	}
}
