import React from "react";

export const Accessible = (props: any) => (
  <svg
    data-aid="SvgAccessible"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0002 5.41659C16.4019 5.41659 15.9169 5.90161 15.9169 6.49992C15.9169 7.09823 16.4019 7.58325 17.0002 7.58325C17.5985 7.58325 18.0835 7.09823 18.0835 6.49992C18.0835 5.90161 17.5985 5.41659 17.0002 5.41659ZM14.0835 6.49992C14.0835 4.88909 15.3894 3.58325 17.0002 3.58325C18.611 3.58325 19.9169 4.88909 19.9169 6.49992C19.9169 8.11075 18.611 9.41659 17.0002 9.41659C15.3894 9.41659 14.0835 8.11075 14.0835 6.49992Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.10308 11.2927C6.99707 10.81 8.00027 10.5658 9.01606 10.5833C9.52225 10.5921 9.92549 11.0095 9.91674 11.5157C9.90798 12.0219 9.49054 12.4251 8.98435 12.4164C8.28332 12.4043 7.59097 12.5729 6.97399 12.9059C6.35701 13.239 5.83619 13.7253 5.46168 14.3181C5.08718 14.9108 4.87162 15.59 4.83575 16.2902C4.79988 16.9905 4.94493 17.6881 5.25691 18.316C5.56889 18.9439 6.03729 19.4809 6.61701 19.8753C7.19673 20.2697 7.86823 20.5081 8.56684 20.5677C9.26544 20.6272 9.96762 20.5059 10.6057 20.2154C11.2439 19.9249 11.7964 19.4749 12.2102 18.9089C12.509 18.5002 13.0825 18.4111 13.4912 18.7098C13.8999 19.0086 13.989 19.5821 13.6902 19.9908C13.0907 20.811 12.29 21.463 11.3654 21.8839C10.4408 22.3049 9.42335 22.4807 8.41109 22.3944C7.39882 22.3081 6.42583 21.9626 5.58583 21.3911C4.74583 20.8197 4.06711 20.0416 3.61506 19.1318C3.16301 18.222 2.95285 17.2111 3.00482 16.1965C3.05679 15.1818 3.36914 14.1977 3.91178 13.3388C4.45443 12.48 5.20909 11.7753 6.10308 11.2927Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.77788 6.61062C10.0457 6.54367 10.3294 6.60096 10.5502 6.76658L14.5502 9.76658C14.926 10.0484 15.0279 10.5687 14.7862 10.9715L12.6192 14.5832H15.0002C15.2787 14.5832 15.542 14.7098 15.716 14.9273L19.716 19.9273C20.0323 20.3226 19.9682 20.8995 19.5728 21.2157C19.1775 21.532 18.6007 21.4679 18.2844 21.0726L14.5596 16.4166H11.0002C10.67 16.4166 10.3652 16.2389 10.2025 15.9516C10.0398 15.6642 10.0443 15.3115 10.2142 15.0283L12.7888 10.7372L9.79925 8.49503L6.22253 9.38921C5.73139 9.512 5.2337 9.21338 5.11091 8.72224C4.98812 8.23109 5.28674 7.7334 5.77788 7.61062L9.77788 6.61062Z"
      fill="currentColor"
    />
  </svg>
);
