/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { ETypography, ETypographyColor, MembershipStatusIndication, Typography } from '@bondsports/utils';
import { MembershipStatusEnum } from '@bondsports/types';
import { flex, gapCss } from '@app/react/styles/utils';
import { TranslationEn } from '@assets/i18n/en';

export const MembershipStatus = ({ membershipStatus }: { membershipStatus: MembershipStatusEnum }) => {
	const { membershipStatusMapper } = TranslationEn.customers.overview.memberships;
	return (
		<div css={[flex, gapCss(12)]}>
			<MembershipStatusIndication membershipStatus={membershipStatus} />
			<Typography type={ETypography.body2} color={ETypographyColor.primary}>
				{membershipStatusMapper[membershipStatus]}
			</Typography>
		</div>
	);
};
