import * as  React from 'react';

export const BnCollapse = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9437 5.99934L12.667 6C13.035 6 13.3337 6.29867 13.3337 6.66734C13.333 7.03534 13.0343 7.33334 12.667 7.33334L9.34968 7.33134C8.98301 7.33134 8.68501 7.03467 8.68301 6.668L8.66701 3.33667C8.66501 2.96867 8.96168 2.66867 9.33035 2.66667H9.33368C9.70035 2.66667 9.99835 2.96267 10.0003 3.33L10.0083 5.04867L12.195 2.862C12.4557 2.60134 12.8777 2.60134 13.1383 2.862C13.3983 3.12267 13.3983 3.544 13.1383 3.80467L10.9437 5.99934Z"
      fill="currentColor"
    />
    <path
      d="M3.33634 8.66715L6.66767 8.68382C7.03434 8.68582 7.33101 8.98315 7.33101 9.34982L7.33367 12.6665C7.33367 13.0345 7.03501 13.3338 6.66701 13.3338C6.29834 13.3338 6.00034 13.0352 6.00034 12.6678L5.99901 10.9445L3.80501 13.1385C3.67434 13.2685 3.50367 13.3338 3.33367 13.3338C3.16301 13.3338 2.99234 13.2685 2.86234 13.1385C2.60167 12.8778 2.60167 12.4558 2.86234 12.1958L5.04834 10.0092L3.33034 10.0005C2.96167 9.99848 2.66501 9.69782 2.66701 9.33048C2.66834 8.96315 2.96701 8.66715 3.33367 8.66715H3.33634Z"
      fill="currentColor"
    />
  </svg>
);
