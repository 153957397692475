/** @jsx jsx */
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import { jsx } from '@emotion/react';
import { useSession } from 'app/react/hooks/useSession';
import { Icons } from '@bondsports/utils';
import moment from 'moment';
import React, { useMemo } from 'react';
import { WidgetBody } from './widgetBody';

export const Countdown = () => {
	const { sessionState } = useSession();
	const labels = {
		startIn: 'Session starts in',
		endIn: 'Session end in',
		ended: 'Session is ended',
		days: 'days',
	};

	const { title, countdown } = useMemo(() => {
		let title = '';
		let countdown = '';
		if (moment().isBefore(moment(sessionState.startDate))) {
			title = labels.startIn;
			countdown = `${moment(sessionState.startDate).diff(moment(), 'days')} ${labels.days}`;
		} else {
			if (moment().isAfter(moment(sessionState.endDate))) {
				title = labels.ended;
				countdown = '';
			} else {
				title = labels.endIn;
				countdown = `${moment(sessionState.endDate).diff(moment(), 'days')} ${labels.days}`;
			}
		}
		return { title, countdown };
	}, [sessionState]);

	return (
		<WidgetBody icon={Icons.clock} title={title}>
			<Typography data-aid="WidgetBody-countdown" color={ETypographyColor.primary} type={ETypography.h2}>
				{countdown}
			</Typography>
		</WidgetBody>
	);
};
