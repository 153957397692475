/** @jsxRuntime classic */
/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/react';
import { RCAddress } from '../../../types/entities/coreEntites/Address';
import { RCOrganization } from '../../../types/entities/coreEntites/Organization';
import { getAddressStreetAndCity, getAddressZipAndState } from '../../../lib/formats/address';
import { sectionCss } from '../styles';

interface Props {
	organization: RCOrganization;
	address?: RCAddress;
}

export const TemplateHeader: FC<Props> = (props: Props) => {
	const { organization, address } = props;
	return (
		<div css={sectionCss}>
			<div className="header">
				<h1 className="title">{organization.name}</h1>
				<div className="address">
					{getAddressStreetAndCity(address)}
					<br />
					{getAddressZipAndState(address)}
				</div>

				<div className="email_phone">
					<span>{organization.phoneNumber} </span>
					{organization.phoneNumber && organization.email && <span className="divider">|</span>}
					<span>{organization.email}</span>
				</div>
			</div>
		</div>
	);
};
