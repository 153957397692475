import * as  React from 'react';

export const BnBackpack = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.58858 4.35365V4C7.58858 3.54588 7.95799 3.17647 8.4121 3.17647H10.2945C10.7486 3.17647 11.118 3.54588 11.118 4V4.35365C11.1293 4.35427 11.148 4.35462 11.1737 4.35511L11.1737 4.35511C11.3312 4.3581 11.7488 4.36602 12.2944 4.47035V4C12.2944 2.89718 11.3973 2 10.2945 2H8.4121C7.30928 2 6.41211 2.89718 6.41211 4V4.47031C6.79219 4.39765 7.18121 4.35804 7.57583 4.35377L7.58858 4.35365ZM7.58855 10.3468V5.53003C4.66396 5.56175 2.29443 7.95026 2.29443 10.8822V16.1371C2.29443 17.0767 2.74075 17.9135 3.43196 18.4483L4.05914 17.8822C6.29549 16.0186 7.58855 13.2579 7.58855 10.3468ZM16.4123 10.8822V11.441C14.5135 11.6304 12.8779 12.7266 11.9456 14.289C11.4064 13.0607 11.1182 11.7205 11.1182 10.3468V5.53003C14.0428 5.56175 16.4123 7.95026 16.4123 10.8822ZM11.336 15.7073C10.4315 14.0901 9.94189 12.2517 9.94189 10.347V5.52954H8.76542V10.347C8.76542 13.609 7.33169 16.678 4.83103 18.771L4.58789 18.9904C4.7904 19.0351 5.00064 19.059 5.2164 19.059H11.3889C11.0558 18.002 11.0209 16.8306 11.336 15.7073ZM12.2944 17.294C12.2944 14.6992 14.4055 12.5881 17.0003 12.5881C19.5951 12.5881 21.7062 14.6992 21.7062 17.294C21.7062 19.8889 19.5951 21.9999 17.0003 21.9999C14.4055 21.9999 12.2944 19.8889 12.2944 17.294Z"
      fill="currentColor"
    />
  </svg>
);
