import { roundPriceCents } from '../../../lib/price';
import { EDiscountUnit } from '../types';

export const calculateDiscount = (
	selectedType: EDiscountUnit,
	value: number,
	amountPreDiscount: number
): { amount: number; percent: number } => {
	let percent = 0;
	let amount = 0;
	switch (selectedType) {
		case EDiscountUnit.PERCENT:
			percent = value;
			amount = roundPriceCents((percent / 100) * amountPreDiscount);
			break;
		case EDiscountUnit.AMOUNT:
		default:
			amount = value;
			percent = roundPriceCents((amount / amountPreDiscount) * 100);
			break;
	}

	return { amount, percent };
};
