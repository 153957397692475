import React, { useState, useEffect } from 'react';

export const ItemsPerPageCalculator = (ref, pagination: boolean = true) => {
	const [itemsPerPage, setItemsPerPage] = useState<number>(0);
	const [maxHeight, setMaxHeight] = useState<number>(500);
	useEffect(() => {
		if (ref.current) {
			let totalHeight = ref.current.clientHeight;
			// padding: 2rem => 20px * 2 = 40
			// header 56px = 56
			// pagination 32px = 32
			let height = pagination ? totalHeight - 138 : totalHeight;
			setMaxHeight(pagination ? totalHeight - 88 : totalHeight);

			const calculatedItems = Math.floor(height / 53);
			setItemsPerPage(calculatedItems);
		}
	}, [ref.current]);
	return { itemsPerPage, maxHeight };
};
