/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { Toggle } from '@bondsports/utils';
import { FrameContainer } from '../../../../components/customers/utils/FrameContainer';
import { IFormInputWraper } from '../../familyAccount/formBody/types';
import { FormSectionInputs } from './FormSectionInputs';

interface IProps {
	detailsInputs: IFormInputWraper[];
	title: string;
	isWithToggle: boolean;
	isActive: boolean;
	setIsActive: () => void;
	toggleLabel: string;
}

export const FormSection = ({
	detailsInputs,
	title,
	isWithToggle = false,
	isActive,
	setIsActive,
	toggleLabel,
}: IProps) => {
	return (
		<FrameContainer title={title}>
			{isWithToggle && <Toggle setPressed={setIsActive} checked={isActive} label={toggleLabel} />}
			<FormSectionInputs detailsInputs={detailsInputs} />
		</FrameContainer>
	);
};
