import { css } from '@emotion/react';

export const fullWidthContainers = css`
	min-width: 0;
	div {
		width: 100%;
	}
`;

export const nowrapCss = css`
	div {
		white-space: nowrap;
	}
`;

export const zeroMinWidthCss = css`
	min-width: 0;
`;
