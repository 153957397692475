import React from 'react';

export const List = (props: any) => (
  <svg
    data-aid="svgList"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.616 11.0015H8.98701C8.44229 11.0015 7.99805 11.4225 7.99805 11.9375V12.0655C7.99805 12.5805 8.44229 13.0015 8.98701 13.0015H20.616C21.1608 13.0015 21.6061 12.5805 21.6061 12.0655V11.9375C21.6061 11.4225 21.1608 11.0015 20.616 11.0015Z"
      fill="currentColor"
    />
    <path
      d="M20.616 17.203H8.98701C8.44229 17.203 7.99805 17.624 7.99805 18.139V18.267C7.99805 18.782 8.44229 19.203 8.98701 19.203H20.616C21.1608 19.203 21.6061 18.782 21.6061 18.267V18.139C21.6061 17.624 21.1608 17.203 20.616 17.203Z"
      fill="currentColor"
    />
    <path
      d="M20.616 4.7999H8.98701C8.44229 4.7999 7.99805 5.2209 7.99805 5.7359V5.8639C7.99805 6.3789 8.44229 6.7999 8.98701 6.7999H20.616C21.1608 6.7999 21.6061 6.3789 21.6061 5.8639V5.7359C21.6061 5.2209 21.1608 4.7999 20.616 4.7999Z"
      fill="currentColor"
    />
    <path
      d="M3.89648 4.2959C3.06848 4.2959 2.39648 4.9679 2.39648 5.7959C2.39648 6.6239 3.06848 7.2959 3.89648 7.2959C4.72448 7.2959 5.39648 6.6239 5.39648 5.7959C5.39648 4.9679 4.72448 4.2959 3.89648 4.2959Z"
      fill="currentColor"
    />
    <path
      d="M3.89648 16.7038C3.06848 16.7038 2.39648 17.3758 2.39648 18.2038C2.39648 19.0318 3.06848 19.7038 3.89648 19.7038C4.72449 19.7038 5.39649 19.0318 5.39649 18.2038C5.39649 17.3758 4.72449 16.7038 3.89648 16.7038Z"
      fill="currentColor"
    />
    <path
      d="M3.89648 10.1998C3.06848 10.1998 2.39648 10.8718 2.39648 11.6998C2.39648 12.5278 3.06848 13.1998 3.89648 13.1998C4.72448 13.1998 5.39648 12.5278 5.39648 11.6998C5.39648 10.8718 4.72448 10.1998 3.89648 10.1998Z"
      fill="currentColor"
    />
  </svg>
);
