/** @jsxRuntime classic */
/** @jsx jsx */

import { FC, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { jsx, useTheme } from '@emotion/react';
import useInterval from '../../hooks/useInterval';
import { calculateMarginFromMinutes } from '../../utils/timeUtils';
import { hourBoxContainer, hourBoxLine } from '../styles';
import { useMiddlewareContext } from '../../hooks/useMiddlewareContext';
import { IHourBoxProps } from '../../types';

export const HourBox: FC<IHourBoxProps> = ({ date, width }) => {
	const colors = useTheme();
	const { options } = useMiddlewareContext();
	const { date: optionDate } = options;
	const today = useMemo(() => dayjs(optionDate), [optionDate]);

	const isSelectedDate = useMemo(
		() =>
			date.format('DD/MM/YYYY') === today.format('DD/MM/YYYY') &&
			today.format('DD/MM/YYYY') === dayjs().format('DD/MM/YYYY'),
		[date, today]
	);

	const [marginTop, setMarginTop] = useState(calculateMarginFromMinutes(Number(dayjs().format('mm'))));
	const [now, setNow] = useState(dayjs());

	useInterval(() => {
		setMarginTop(calculateMarginFromMinutes(Number(dayjs().format('mm'))));
		setNow(dayjs());
	}, 1000);

	return (
		<div css={hourBoxContainer(colors)} data-aid="hourBox-daily">
			<span />
			{isSelectedDate && now.format('hh a') === dayjs(date).format('hh a') && (
				<div css={hourBoxLine(width, marginTop + 6)} />
			)}
			<div className="span" />
			<div>{dayjs(date).format('hh a')}</div>
		</div>
	);
};
