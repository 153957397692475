import { environment } from '../../../../environments/environment';
import { network } from '../network';

const v4APIUrl = `${environment.CS_URLS.API_ROOT_V4}`;

async function getMyPermissions(organizationId: number) {
	const response = await network.get(`${v4APIUrl}/roles/organization/${organizationId}/user/my`);
	return { data: response} ;
}

async function getOrganizationAdmins(orgnanizationId: number) {
	const response = await network.get(`${v4APIUrl}/roles/organization/${orgnanizationId}/admins`);
	return response;
}

async function getRolesByOrganization(organizationId: number) {
	const response = await network.get(`${v4APIUrl}/roles/organization/${organizationId}`);
	return response;
}

async function getPermissionsByOrganization(organizationId: number) {
	const response = await network.get(`${v4APIUrl}/roles/organization/${organizationId}/permissions`);
	return response;
}

async function createRole(organizationId: number, name: string) {
	const response = await network.post(`${v4APIUrl}/roles/organization/${organizationId}`, { name });
	return response;
}

async function updateRole(organizationId: number, roleId: number, name: string) {
	const response = await network.put(`${v4APIUrl}/roles/${roleId}/organization/${organizationId}`, { name });
	return response;
}

async function updatePermissions(organizationId: number, roleId: number, permissions: number[]) {
	const response = await network.post(`${v4APIUrl}/roles/${roleId}/organization/${organizationId}/permissions`, {
		permissionIds: permissions,
	});
	return response;
}

async function deletePermissions(organizationId: number, roleId: number, permissions: number[]) {
	const response = await network.delete(`${v4APIUrl}/roles/${roleId}/organization/${organizationId}/permissions`, {
		permissionIds: permissions,
	});
	return response;
}

async function getRolesByUserId(organizationId: number, userId: number) {
	const response = await network.get(`${v4APIUrl}/roles/organization/${organizationId}/user/${userId}`);
	return response;
}

async function getUsersByRoleId(organizationId: number, roleId: number) {
	const response = await network.get(`${v4APIUrl}/roles/${roleId}/organization/${organizationId}/users`);
	return response;
}

async function addUserToRole(organizationId: number, roleId: number, userId: number) {
	const response = await network.post(`${v4APIUrl}/roles/${roleId}/organization/${organizationId}/user/${userId}`, {});
	return response;
}

async function removeRoleFromUser(organizationId: number, roleId: number, userId: number) {
	const response = await network.delete(
		`${v4APIUrl}/roles/${roleId}/organization/${organizationId}/user/${userId}`,
		{}
	);
	return response;
}

export const authApi = {
	getMyPermissions,
	getOrganizationAdmins,
	getRolesByOrganization,
	getPermissionsByOrganization,
	createRole,
	updateRole,
	updatePermissions,
	deletePermissions,
	getRolesByUserId,
	getUsersByRoleId,
	addUserToRole,
	removeRoleFromUser,
};
