/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { Tag } from '../../../components/Tag';
import { oldThemeColors as colors, ThemeColors as colors_v2 } from '../../../styles/theme';
import { Icons } from '../../../types';
import { TooltipWithButton } from '../../tooltip-with-button';
import { EDateFormats } from '../../../lib/timeUtils';
import dayjs from 'dayjs';
import { i18n } from '../../../lib/i18n';
const labels = {
	individual: 'Individual',
	user: 'Individual',
	organization: 'Organization',
	family: 'Family',
	primary: 'Primary',
	dependent: 'Dependent',
	independent: 'Independent',
	active: 'Active',
	inactive: 'InActive',
	expire: 'Expire',
	pending: 'Pending',
	paid: 'Paid',
	void: 'Voided',
	not_paid: 'Unpaid',
	partial: 'Partially paid',
	charged: 'Charged',
	refunded: 'Refunded',
	future: 'Upcoming',
	succeeded: 'Paid',
	canceled: 'Canceled',
	scheduled: 'Scheduled Payments',
	failed: 'Failed',
	revert: 'revert',
};
enum EStatusColorMapper {
	family = 'purple',
	user = 'yellow',
	organization = 'red',
	primary = 'yellow',
	dependent = 'pink',
	independent = 'blue',
	not_paid = 'red',
	paid = 'green',
	void = 'gray',
	partial = 'yellow',
	future = 'blue',
	succeeded = 'green',
	canceled = 'purple',
	scheduled = 'blue',
	charged = 'green',
	male = 'blue',
	female = 'purple',
	other = 'yellow',
	failed = 'red',
	refunded = 'blue',
	revert = 'gray',
}

export const cellCss = (isAction: boolean, isLast?: boolean, isIcon?: boolean, svgWidth = 20) => css`
	padding: 6px 8px;
	margin-right: 4px;
	cursor: ${isAction ? 'pointer' : 'default'};
	color: ${colors.brandPrimary};
	width: ${isIcon ? '20px' : 'unset'};
	${isLast ? 'padding: 6px 0px;' : ''}
	border-bottom: none !important;

	.TableCell-paddingCheckbox {
		padding: 0 0 0 8px;
	}
	a {
		text-decoration: underline;
		color: ${colors.brandPrimary};
	}
`;

export const Status = ({ column, row }: { column: any; row: any }) => {
	const value = row[column.id];
	const title = labels[value];
	return (
		<div css={cellCss(column.action, false)}>
			<Tag title={title} color={EStatusColorMapper[row[column.id]] || 'blue'} />
		</div>
	);
};
export const StatusWithIcon = ({ column, row }: { column: any; row: any }) => {
	const value = row[column.id];
	const title = labels[value];
	const isFailed = row?.errorMessage && row?.status === 'failed' && row?.errorMessage !== 'UNKNOWN';
	const inFutureAndPaid = row?.status === 'succeeded' && new Date(row?.plannedDate) > new Date(row?.chargedAt);
	const displayTooltip = isFailed || inFutureAndPaid;
	const dateContent = dayjs
		.utc(row?.chargedAt ?? row?.plannedDate, EDateFormats.YYYY_MM_DD)
		.format(EDateFormats.MMM_D_YYYY);
	const tooltipContent = isFailed ? row?.errorMessage : i18n.invoice.toolTips.padInAdvance(dateContent);
	return (
		displayTooltip ? (
			<TooltipWithButton icon={Icons.info} tooltipContent={tooltipContent}>
				<Tag title={title} color={EStatusColorMapper[row[column.id]] || 'blue'} />
			</TooltipWithButton>
		) : (
			<Tag title={title} color={EStatusColorMapper[row[column.id]] || 'blue'} />
		)
	);
};
