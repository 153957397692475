import React from 'react';

export const PaymentOther = (props: any) => (
	<svg
		data-aid="svgPaymentOther"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1 17V7C1 5.34315 2.34315 4 4 4H20C21.6569 4 23 5.34315 23 7V17C23 18.6569 21.6569 20 20 20H4C2.34315 20 1 18.6569 1 17ZM4 6H20C20.5523 6 21 6.44772 21 7V16H3V7C3 6.44772 3.44772 6 4 6Z"
			fill="currentColor"
		/>
		<path
			d="M12.7951 10.2025C12.4447 10.0785 11.8734 9.87034 11.5871 9.64586C11.5698 9.63222 11.5374 9.55061 11.5601 9.42934C11.5711 9.37113 11.6215 9.17678 11.8101 9.11992C12.1014 9.03166 12.3778 9.08964 12.5592 9.22398C12.8456 9.48475 13.2891 9.46492 13.5509 9.17917C13.8124 8.89375 13.7938 8.45097 13.5101 8.18828C13.5101 8.18828 13.1843 7.89822 12.7184 7.76097V7.70312C12.7184 7.31481 12.4036 7 12.0153 7C11.627 7 11.3122 7.31481 11.3122 7.70312V7.80484C10.0483 8.2645 9.75115 9.99334 10.7194 10.7525C11.1605 11.0984 11.7908 11.3388 12.3259 11.5282C12.6745 11.6515 12.7099 11.9014 12.6783 12.0894C12.6431 12.2986 12.4628 12.6535 12.0106 12.6565C11.5417 12.6595 11.4305 12.6423 11.0883 12.4183C10.7633 12.2057 10.3276 12.2965 10.1149 12.6215C9.90223 12.9465 9.99321 13.3823 10.3182 13.5949C10.6919 13.8396 10.988 13.9608 11.3121 14.0176V14.0852C11.3121 14.4735 11.6269 14.7883 12.0153 14.7883C12.4036 14.7883 12.7184 14.4735 12.7184 14.0852V13.9381C14.422 13.3224 14.648 10.8579 12.7951 10.2025Z"
			fill="currentColor"
		/>
	</svg>
);
