import * as  React from 'react';

export const BnCash = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9027 11.0851C11.6107 10.9818 11.1346 10.8083 10.8961 10.6212C10.8816 10.6099 10.8546 10.5418 10.8736 10.4408C10.8827 10.3923 10.9247 10.2303 11.0819 10.1829C11.3246 10.1094 11.555 10.1577 11.7061 10.2697C11.9448 10.487 12.3144 10.4704 12.5326 10.2323C12.7505 9.99447 12.735 9.62548 12.4985 9.40658C12.4985 9.40658 12.227 9.16486 11.8388 9.05048V9.00228C11.8388 8.67869 11.5765 8.41634 11.2529 8.41634C10.9293 8.41634 10.6669 8.67869 10.6669 9.00228V9.08704C9.61373 9.47009 9.36607 10.9108 10.1729 11.5435C10.5405 11.8317 11.0658 12.032 11.5117 12.1898C11.8022 12.2926 11.8317 12.5008 11.8053 12.6575C11.776 12.8319 11.6258 13.1276 11.2489 13.1301C10.8582 13.1326 10.7655 13.1182 10.4804 12.9316C10.2095 12.7544 9.84647 12.8301 9.6692 13.101C9.49197 13.3717 9.56779 13.7349 9.83857 13.9121C10.1501 14.116 10.3968 14.217 10.6669 14.2643V14.3207C10.6669 14.6443 10.9292 14.9066 11.2528 14.9066C11.5764 14.9066 11.8388 14.6443 11.8388 14.3207V14.1981C13.2584 13.685 13.4468 11.6313 11.9027 11.0851Z"
      fill="currentColor"
    />
    <path
      d="M7.61654 12.0261C7.29064 12.0871 6.99283 11.8809 6.92748 11.5659C6.874 11.2818 7.02303 10.9915 7.33178 10.8909C7.66111 10.7929 8.00455 10.9911 8.0767 11.337C8.13721 11.6603 7.93439 11.9598 7.61654 12.0261Z"
      fill="currentColor"
    />
    <path
      d="M15.1165 12.0261C14.7906 12.0871 14.4928 11.8809 14.4275 11.5659C14.3738 11.281 14.5241 10.9912 14.8318 10.8909C15.1611 10.7929 15.5045 10.9911 15.5767 11.337C15.6373 11.6609 15.4338 11.9599 15.1165 12.0261Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.44822 4.11521C2.90404 3.6594 3.52226 3.40332 4.16688 3.40332H13.3336C13.9782 3.40332 14.5964 3.6594 15.0522 4.11521C15.508 4.57103 15.7641 5.18925 15.7641 5.83388V6.73665H15.8336C17.1759 6.73665 18.2641 7.82485 18.2641 9.16721V14.1672C18.2641 15.5096 17.1759 16.5978 15.8336 16.5978H6.66688C5.32452 16.5978 4.23633 15.5096 4.23633 14.1672V13.2644H4.16688C3.52226 13.2644 2.90404 13.0084 2.44822 12.5525C1.9924 12.0967 1.73633 11.4785 1.73633 10.8339V5.83388C1.73633 5.18925 1.9924 4.57103 2.44822 4.11521ZM15.0002 8.26443L14.9986 8.26443H6.66688C6.16829 8.26443 5.7641 8.66862 5.7641 9.16721L5.76411 12.5005L5.7641 14.1672C5.7641 14.6658 6.16829 15.07 6.66688 15.07H15.8336C16.3321 15.07 16.7363 14.6658 16.7363 14.1672V9.16721C16.7363 8.66862 16.3321 8.26443 15.8336 8.26443H15.0018L15.0002 8.26443ZM14.2363 5.83388V6.73665H6.66688C5.32452 6.73665 4.23633 7.82485 4.23633 9.16721V11.7367H4.16688C3.92745 11.7367 3.69783 11.6415 3.52852 11.4722C3.35922 11.3029 3.26411 11.0733 3.26411 10.8339V5.83388C3.26411 5.59444 3.35922 5.36482 3.52852 5.19552C3.69783 5.02621 3.92745 4.9311 4.16688 4.9311H13.3336C13.573 4.9311 13.8026 5.02621 13.9719 5.19552C14.1412 5.36482 14.2363 5.59444 14.2363 5.83388Z"
      fill="currentColor"
    />
  </svg>
);
