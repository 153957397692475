export const removeLastPartOfUrl = (url: string) => {
	const urlArr = url.split('/');
	urlArr.pop();
	return urlArr.join('/');
};

export function arrayToString(array: any[]) {
	return array.join(',');
}

export function buildQueryString(obj: any) {
	let query = '';
	let counter = 0;
	Object.keys(obj).forEach(param => {
		let value = Array.isArray(obj[param]) ? arrayToString(obj[param]) : obj[param];
		if (value) {
			if (counter === 0) {
				query += `?${param}=${value}`;
			} else {
				query += `&${param}=${value}`;
			}
			counter++;
		}
	});
	return query;
}
