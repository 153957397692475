/** @jsx jsx */
import React, { FC } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { jsx, css } from '@emotion/react';

import { CustomersService } from '@app/shared/services/customers/customers.service';
import { Invoices } from '../customerPage/CustomerPageBodyMapper/Invoices';
import { Payments } from '../customerPage/CustomerPageBodyMapper/Payments';
import { CustomerPageTabsEnum, TCustomer } from '../../../types/customers';
import { CurrencySymbolEnum } from '@bondsports/utils';
import { MembershipTab } from './MembershipTab';
import { CustomerDetails } from '../customerPage/CustomerPageBodyMapper/CustomerDetails';
import { ActivitiesTab } from '@app/react/components/customers/customerPage/CustomerPageBodyMapper/ActivitiesTab';

const comingSoonContainer = css`
	display: flex;
	margin-top: 5rem;
	width: 100%;
	text-align: center;
	justify-content: center;
`;

interface IOverviewProps {
	customerState: TCustomer;
	currencySymbol: CurrencySymbolEnum;
	organizationId: number;
	fetchData: () => void;
}

export interface IBodyProps extends IOverviewProps {
	type: string;
	customersService: CustomersService;
	OverviewComponent: FC<IOverviewProps>;
	isFamily: boolean;
	orgQuestionnaireId: number;
}

export const PageBodyMapper = ({
	type,
	customerState,
	customersService,
	organizationId,
	fetchData,
	currencySymbol,
	OverviewComponent,
	isFamily,
	orgQuestionnaireId,
}: IBodyProps) => {
	const location = useLocation();
	switch (type) {
		case CustomerPageTabsEnum.CUSTOMER_DETAILS:
			return (
				<CustomerDetails
					customerState={customerState}
					organizationId={organizationId}
					fetchData={fetchData}
					orgQuestionnaireId={orgQuestionnaireId}
				/>
			);
		case CustomerPageTabsEnum.OVERVIEW:
			return (
				<OverviewComponent
					customerState={customerState}
					currencySymbol={currencySymbol}
					organizationId={organizationId}
					fetchData={fetchData}
				/>
			);
		case CustomerPageTabsEnum.MEMBERSHIP:
			return <MembershipTab customerState={customerState} isFamily={isFamily} />;
		case CustomerPageTabsEnum.INVOICES:
			return <Invoices customerState={customerState} customersService={customersService} />;
		case CustomerPageTabsEnum.PAYMENTS:
			return <Payments customerState={customerState} customersService={customersService} />;
		case CustomerPageTabsEnum.ACTIVITIES:
			return <ActivitiesTab customerState={customerState} displayUserDetails={isFamily} />;
		case undefined:
			return (
				<Redirect
					to={`${location.pathname}${location.pathname[location.pathname.length - 1] === '/' ? '' : '/'}overview`}
				/>
			);
		default:
			return <div css={comingSoonContainer}> Coming Soon </div>;
	}
};
