/* @jsx jsx */

import React, { useCallback, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { ProgramEventDto, ProgramSegmentDto, BasicActivityTimesDto, OrderByEnum } from '@bondsports/types';
import { DynamicList, DynamicListProps } from '../utils/DynamicList';
import { RemovedFromActivityPrompt } from '../utils/RemovedFromActivityPrompt';

type ProgramEventOrSegment<T extends ProgramEventDto | ProgramSegmentDto> = T extends {
	activityTimes: BasicActivityTimesDto[];
}
	? ProgramSegmentDto
	: ProgramEventDto;

type ProgramEventDynamicListProps<T extends ProgramEventDto | ProgramSegmentDto> = DynamicListProps<
	ProgramEventOrSegment<T>
> & {
	children: React.FC<{ data: T; isLastItem: boolean; index: number }>;
	labels: Record<string, any>;
	timezone: string;
};

export const ProgramEventDynamicList = <T extends ProgramEventDto | ProgramSegmentDto>({
	sessionEndDate,
	sessionStartDate,
	labels,
	fetcher,
	children,
	timezone,
}: ProgramEventDynamicListProps<T>) => {
	const [latestDeletedAttendeeDate, setLatestDeletedAttendeeDate] = useState<Date>();

	const RemovedFromSessionComponent = useCallback(
		() => (
			<RemovedFromActivityPrompt
				latestDeletedEventDate={latestDeletedAttendeeDate}
				timezone={timezone}
				labels={labels}
			/>
		),
		[labels.removedFromSession, latestDeletedAttendeeDate, timezone]
	);

	const fetchData = useCallback(
		async (
			itemsPerPage: number,
			startDate: Date,
			endDate: Date,
			orderBy: OrderByEnum,
			options?: { signal: AbortSignal }
		) => {
			const attendees: ProgramEventOrSegment<T>[] = await fetcher(itemsPerPage, startDate, endDate, orderBy, options);

			if (attendees.length === 1 && attendees[0].deletedAt) {
				setLatestDeletedAttendeeDate(attendees[0].deletedAt);

				return [];
			}

			return attendees;
		},
		[fetcher]
	);

	return (
		<DynamicList
			sessionEndDate={sessionEndDate}
			sessionStartDate={sessionStartDate}
			labels={labels}
			fetcher={fetchData}
			NoDataComponent={latestDeletedAttendeeDate ? RemovedFromSessionComponent : null}
		>
			{children}
		</DynamicList>
	);
};
