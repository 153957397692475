import React from 'react';

export const CorenerRightUp = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.6245 8.21921L13.6245 4.21921C13.2595 3.92721 12.7405 3.92721 12.3755 4.21921L7.3755 8.21921C6.9435 8.56421 6.8745 9.19321 7.2195 9.62421C7.5635 10.0552 8.1935 10.1252 8.6245 9.78121L11.9995 7.08021V16.0002C11.9995 16.5512 11.5515 17.0002 10.9995 17.0002H5.9995C5.4475 17.0002 4.9995 17.4482 4.9995 18.0002C4.9995 18.5522 5.4475 19.0002 5.9995 19.0002H10.9995C12.6545 19.0002 13.9995 17.6542 13.9995 16.0002V7.08021L17.3755 9.78121C17.5595 9.92821 17.7805 10.0002 17.9995 10.0002C18.2925 10.0002 18.5835 9.87121 18.7805 9.62421C19.1255 9.19321 19.0565 8.56421 18.6245 8.21921Z"
      fill="currentColor"
    />
  </svg>
);
