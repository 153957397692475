import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const TIME_FORMAT = 'hh:mm a';
export const HOURS_PER_DAY = 24;
export const MINUTES_PER_HOUR = 60;
export const DEFAULT_NEW_SLOT_DURATION = 30;
export const DEFAULT_NEW_SLOT_HOUR_SIZE = 90;

const DEFAULT_HOUR_HEIGHT = 63;
const DEFAULT_HOUR_WIDTH = 82;

export let oneHourHeight = DEFAULT_HOUR_HEIGHT;
export let oneHourWidth = DEFAULT_HOUR_WIDTH;

export const setNewHourHeight = (height = DEFAULT_HOUR_HEIGHT) => {
	oneHourHeight = Math.max(height, DEFAULT_HOUR_HEIGHT);
};

export const setNewHourWidth = (width = DEFAULT_HOUR_WIDTH) => {
	oneHourWidth = Math.max(width, DEFAULT_HOUR_WIDTH);
};

export const calculateMarginFromMinutes = (minutes: number) => {
	return (minutes / 60) * oneHourHeight;
};

export const calculateMarginFromHours = (h: number) => {
	return h * oneHourHeight + 6;
};

export const calculateMinutesFromHeight = (divHeight: number) => {
	return (60 * divHeight) / oneHourHeight;
};

export const calculateMarginLeftFromMinutes = (minutes: number) => {
	return (minutes / 60) * oneHourWidth;
};

export const calculateMarginLeftFromHours = (h: number) => {
	return h * oneHourWidth;
};

export const calculateMinutesFromWidth = (width: number) => {
	return (60 * width) / oneHourWidth;
};

export const getHours = (startTime: string, endTime: string) =>
	dayjs(startTime).format(TIME_FORMAT) + ' - ' + dayjs(endTime).format(TIME_FORMAT);

export const getTimeFromMinutes = (minutes: number): string  => dayjs()
	.minute(0)
	.hour(0)
	.add(minutes, 'minutes')
	.format(TIME_FORMAT);
