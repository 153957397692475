import React from 'react';

export const Dropdown = (props: any) => (
  <svg
    data-aid="svgDropdown"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10.1492L12 16L6 10.1492L7.17851 9L12 13.7016L16.8215 9L18 10.1492Z"
      fill="currentColor"
    />
  </svg>
);
