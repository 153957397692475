/** @jsxRuntime classic */
/** @jsx jsx */

import React, { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import { TGroup, TOption } from './types';
import { GroupsOptions } from './GroupsOptions';
import { Typography } from '../../atoms';
import { ETypography, ETypographyColor } from '../../types';
import { paddingCss } from '../../styles/utils';
import { i18n } from '../../lib/i18n';
import { SizeEnum } from '../../lib/constants';

type SelectOptionsProps<T, U> = {
	groups: TGroup<T, U>[];
	onSelect: (val: any) => void;
	value?: U[];
	isMultiple?: boolean;
	enableSelectAll?: boolean;
	selectAllLabel?: string;
	handleSelectAll?: () => void;
	handleRemoveAll?: () => void;
	size: SizeEnum;
	handleLoadNextPage?: () => void;
	isLoading?: boolean;
	isFinalPage?: boolean;
	originalOptions?: TOption<U>[];
	isGroups?: boolean;
	selectedGroup?: any;
	onSelectGroup: (group: TGroup<T, U>) => void;
	searchValue?: string;
	usePagination: boolean;
	LoadingComponent: React.ReactNode;
	EndOptionComponent: React.ReactNode;
	NoOptionsComponent: React.ReactNode;
	handleLoadMoreGroups?: () => void;
	selectAllGroups: () => void;
	deslectAllGroups: () => void;
	labels: any;
};

const selectOptionsCss = css`
	display: flex;
	flex-direction: column;
`;

export const SelectOptions = <T, U>({
	groups,
	onSelect,
	value = [],
	isMultiple = false,
	enableSelectAll = false,
	selectAllLabel = '',
	handleSelectAll,
	handleRemoveAll,
	size = SizeEnum.MEDIUM,
	handleLoadNextPage,
	isLoading,
	isFinalPage,
	isGroups = false,
	selectedGroup,
	onSelectGroup,
	searchValue,
	usePagination,
	LoadingComponent,
	EndOptionComponent,
	NoOptionsComponent,
	handleLoadMoreGroups,
	selectAllGroups,
	deslectAllGroups,
	labels,
}: SelectOptionsProps<T, U>) => {
	const selectedValuesArray: U[] = Array.isArray(value) ? value : value ? [value] : [];

	const isEmptyOptions: boolean = groups?.length === 0 || (isGroups && Object.keys(groups).length === 0);

	return (
		<div css={selectOptionsCss}>
			{isEmptyOptions ? (
				<div css={paddingCss(12)}>
					<Typography color={ETypographyColor.primary} type={ETypography.captionAccented}>
						{i18n.noResults}
					</Typography>
				</div>
			) : (
				<GroupsOptions
					groups={groups}
					onSelect={onSelect}
					isMultiple={isMultiple}
					selectedValuesArray={selectedValuesArray}
					enableSelectAll={enableSelectAll}
					size={size}
					value={value}
					handleRemoveAll={handleRemoveAll}
					handleSelectAll={handleSelectAll}
					selectAllLabel={selectAllLabel}
					handleLoadNextPage={handleLoadNextPage}
					isLoading={isLoading}
					isFinalPage={isFinalPage}
					selectedGroup={selectedGroup}
					onSelectGroup={onSelectGroup}
					isGroups={isGroups}
					searchValue={searchValue}
					usePagination={usePagination}
					LoadingComponent={LoadingComponent}
					EndOptionComponent={EndOptionComponent}
					handleLoadMoreGroups={handleLoadMoreGroups}
					deslectAllGroups={deslectAllGroups}
					selectAllGroups={selectAllGroups}
					labels={labels}
					NoOptionsComponent={NoOptionsComponent}
				/>
			)}
		</div>
	);
};
