import * as  React from 'react';

export const BnProfile = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00004 7.33333C9.47071 7.33333 10.6667 6.13733 10.6667 4.66667C10.6667 3.196 9.47071 2 8.00004 2C6.52937 2 5.33337 3.196 5.33337 4.66667C5.33337 6.13733 6.52937 7.33333 8.00004 7.33333Z"
      fill="currentColor"
    />
    <path
      d="M12 13.9999C12.3687 13.9999 12.6667 13.7013 12.6667 13.3333C12.6667 10.7599 10.5727 8.66659 8.00004 8.66659C5.42737 8.66659 3.33337 10.7599 3.33337 13.3333C3.33337 13.7013 3.63137 13.9999 4.00004 13.9999H12Z"
      fill="currentColor"
    />
  </svg>
);
