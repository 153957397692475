import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'rc-program-basic-info',
	templateUrl: './program-basic-info.component.html',
	styleUrls: ['./program-basic-info.component.scss'],
})
export class ProgramBasicInfoComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
