/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Typography } from '../../../atoms';
import { BnIcon } from '../../../components/icons_v2';
import { capitalize } from '../../../functions';
import { ETypography, ETypographyColor } from '../../../types';

const iconContainerCss = (size = 24) => css`
	margin-right: 1rem;
	height: ${size}px;
	width: ${size}px;
	svg {
		display: block;
	}
`;

const iconCss = css`
	svg {
		height: 100% !important;
		width: auto !important;
	}
`;

const flexRowCss = css`
	display: flex;
	align-items: center;
`;

interface PaymentDetailProps {
	text: string;
	icon: string;
	iconSize?: number;
}

export const PaymentDetailAndIcon = ({ text, icon, iconSize = 35 }: PaymentDetailProps) => {
	return (
		<div data-aid="paymentDetailsAction" css={flexRowCss}>
			{icon && (
				<div css={[iconContainerCss(iconSize), iconCss]}>
					<BnIcon icon={icon} />
				</div>
			)}
			<Typography color={ETypographyColor.primary} type={ETypography.body2}>
				{capitalize(text)}
			</Typography>
		</div>
	);
};
