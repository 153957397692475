import { Context, createContext } from 'react';
import { ICustomer } from '../../../types/customers';
import { TCustomerActivitiesLabels } from './types/types';
import { ActivitiesEnum } from './types/enums';
import { ProgramTypesEnum } from '@bondsports/types';

type CustomerActivitiesContextProps = {
	labels: TCustomerActivitiesLabels;
	customer: ICustomer;
	displayUserDetails: boolean;
	selectedActivity: ActivitiesEnum;
	timezone: string;
};

export const CustomerActivitiesContext: Context<CustomerActivitiesContextProps> =
	createContext<CustomerActivitiesContextProps>(null);
