/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { truncateString } from '../../../lib/utils';
import { fontSizeCss, noneTextTransformCss } from './styles';
const FIRST_CHARS_COUNT = 8;
const END_CHARS_COUNT = 4;

export const GroupTypeLabel = (key: string, label: string, isCustomFontSize = true) => {
	const keyString = truncateString(key, FIRST_CHARS_COUNT, END_CHARS_COUNT);
	const labelString = truncateString(label, FIRST_CHARS_COUNT, END_CHARS_COUNT);
	return (
		<div data-aid="GroupTypeLabel-SelectedTags">
			<span css={isCustomFontSize && fontSizeCss}>{keyString}</span>
			<span>&nbsp;/&nbsp;</span>
			<span css={noneTextTransformCss}>{labelString}</span>
		</div>
	);
};
