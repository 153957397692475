/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { ReactNode } from 'react';

const wrapperCss = css`
	width: calc(100% / 2 - 8px);
`;
export const FormFieldWrapper = ({ children }: { children: ReactNode }) => {
	return <div css={wrapperCss}>{children}</div>;
};
