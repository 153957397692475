/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import moment from 'moment';
import { cellCss } from '../styles';
import { useTimeFormats } from 'app/react/hooks/useTimeFormats';
import { participantTimeCss } from '../../../../styles/utils';

export const TimeRange = ({ row, index, isLast }) => {
	const { generateDelta } = useTimeFormats();
	const diff = moment(row.endTime).diff(moment(row.startTime), 'minutes');

	return (
		<td key={index} css={cellCss({ isAction: false, isLast })}>
			<span css={participantTimeCss}>
				{`${moment(row.startTime).format('hh:mm A')} - ${moment(row.endTime).format('hh:mm A')} 
          (${generateDelta(diff)})`}
			</span>
		</td>
	);
};
