/** @jsxRuntime classic */
/** @jsx jsx */
import { FC, useMemo } from 'react';
import { useTheme, jsx } from '@emotion/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { weekBoxContainer } from '../styles';
import { useMiddlewareContext } from '../../hooks/useMiddlewareContext';
import { IWeekBoxProps } from '../../types';

dayjs.extend(isoWeek);

export const WeekBox: FC<IWeekBoxProps> = ({ width, horizontal }) => {
	const colors = useTheme();
	const { options } = useMiddlewareContext();
	const { date, isSundayFirstDay } = options;
	const dayShift = isSundayFirstDay ? 1 : 0;

	const today = dayjs(date);

	const dates = useMemo(() => {
		let result = [] as dayjs.Dayjs[];
		for (let i = 1; i < 8; i++) {
			result.push(today.isoWeekday(i - dayShift));
		}
		return result;
	}, [date, dayShift]);

	return (
		<div css={weekBoxContainer(colors, width, horizontal)} data-aid="WeekBox-weekly">
			{dates.map(date => (
				<div className="weekday" key={date.toString()}>
					<div className="name">{date.format('ddd')}</div>
					<div className={classNames('date', date.isSame(dayjs(), 'date') && 'today')}>{date.date()}</div>
				</div>
			))}
		</div>
	);
};
