/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import { SectionContainer } from '../sectionContainer';
import { IColors } from '../../../types';
import { Note, NoteBorder } from './noteCard';
import { useDetectPrint } from '../../../hooks/useIsPrint';
import { EInvoiceVariant } from '../../../organisms/invoiceTemplate/types';
import { EInvoicingTabs } from '../types';

const containerCss = (colors: IColors) => css`
	border: 1px solid ${colors.bg_border_primary};
	border-radius: 5px;
	padding: 12px;

	@media print and (max-width: 10cm) {
		border: none;
		padding: 0;
		display: flex;
		flex-direction: column;
		gap: 2rem;
	}
`;

const NoteMapper = (props: { notes: any; organizationTimeZone: string }) => (
	<div css={containerCss} data-aid="index-notes">
		{props.notes.map((note: any, idx: number) => (
			<Fragment>
				{idx !== 0 && <NoteBorder />}
				<Note state={note} key={idx} organizationTimeZone={props.organizationTimeZone} />
			</Fragment>
		))}
	</div>
);

export const Notes = ({
	labels,
	state,
	activeTab = EInvoicingTabs.DETAILS,
	variant,
	organizationTimeZone = 'utc',
}: {
	labels: any;
	state: any;
	variant: EInvoiceVariant;
	activeTab?: EInvoicingTabs;
	organizationTimeZone?: string;
}) => {
	const isPrint = useDetectPrint();
	const invoiceState = state.invoice;
	const paymentState = state.payment;
	// This component is used in both invoice and payment, so we need to check which one is active
	// and get the notes from there
	const notes = invoiceState?.invoiceNotes ?? paymentState?.paymentNotes ?? [];
	const showNotes = !!notes.length && (activeTab === EInvoicingTabs.DETAILS || isPrint);
	return (
		<Fragment>
			{showNotes ? (
				<Fragment>
					<div className="no-print">
						<SectionContainer title={labels.notes.title}>
							<NoteMapper notes={notes} organizationTimeZone={organizationTimeZone} />
						</SectionContainer>
					</div>
					<div className="print">
						<NoteMapper notes={notes} organizationTimeZone={organizationTimeZone} />
					</div>
				</Fragment>
			) : (
				<Fragment />
			)}
		</Fragment>
	);
};
