import { IErrorArr, ISuccess } from '@app/react/types/errors';
import { Product } from 'app/react/types/product';
import { environment } from '../../../../environments/environment';
import { network } from '../network';

const seasonDashboardAPIUrl = `${environment.CS_URLS.API_ROOT}/programs/season`;
const v1Url = `${environment.CS_URLS.API_ROOT}`;
const v4Url = `${environment.CS_URLS.API_ROOT_V4}`;

const getSeasonById = (seasonId: number): Promise<any> => {
	return network.get(`${seasonDashboardAPIUrl}/${seasonId}?includeResources=false`);
};

const getRegisteredToSeason = (seasonId: number): Promise<any> => {
	return network.get(`${seasonDashboardAPIUrl}/${seasonId}/attendees`);
};

const getProductsBySeason = (organizationId: number, seasonId: number, isAddons?: boolean): Promise<Product[]> => {
	return network.get(
		`${v4Url}/product-pricing/products/organization/${organizationId}/resource/program_season/${seasonId}?isGetEntitlePricing=true&isPublic=true&isGetByChildResources=true&isGetAddOns=${
			isAddons ? 'true' : 'false'
		}`
	);
};

const getParticipantsFromSeason = (seasonId: number): Promise<any> => {
	return network.get(`${v1Url}/programs/attendees/${seasonId}?isFreeAgentsOnly=true`);
};

const updateSessionEventsColors = async (
	sessionId: number,
	organizationId: number,
	data: { futureOnly: boolean }
): Promise<ISuccess | IErrorArr> => {
	const response = await network.put(
		`${v4Url}/programs-seasons/organization/${organizationId}/season/${sessionId}/update-event-colors`,
		data
	);
	return response;
};

export {
	getSeasonById,
	getRegisteredToSeason,
	getProductsBySeason,
	getParticipantsFromSeason,
	updateSessionEventsColors,
};
