/** @jsx jsx */

import React, { useState } from 'react';
import { useComingSoonPopUp } from '../../shared/ComingSoon';
import { jsx, css } from '@emotion/react';
import { flexCol, setOpacity } from '../../../styles/utils';
import { colors } from '../../../styles/theme';
import { TranslationEn } from 'assets/i18n/en';

const notesContainerCss = css`
	${flexCol};
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: ${colors.formInputBg};
	label {
		font-weight: normal;
		b {
			font-weight: 500;
		}
	}
	textarea {
		background: ${setOpacity(colors.brandPrimary, 0.04)};
		font-size: 1.4rem;
		padding: 1rem;
		color: ${colors.brandPrimary};
		border: none;
	}
	div {
		margin-top: 5px;
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
`;

interface Props {
	isComingSoon?: boolean;
	value?: string;
	setValue?: (val: string) => void;
}

export const Notes = ({ isComingSoon = true, value, setValue }: Props) => {
	const { ComingSoonToggle, ComingSoonModal } = useComingSoonPopUp();
	// still in progress
	return (
		<React.Fragment>
			<div css={notesContainerCss}>
				<label>
					<b>{TranslationEn.components.notes}</b> {TranslationEn.components.optional}
				</label>
				<textarea
					onFocus={() => {
						if (isComingSoon) {
							ComingSoonToggle();
						}
					}}
					rows={3}
					maxLength={120}
					value={value}
					onChange={e => setValue(e.target.value)}
				></textarea>
				<div>{`${value.length}/120`}</div>
			</div>
			<ComingSoonModal />
		</React.Fragment>
	);
};
