/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useCallback, useMemo } from 'react';
import { jsx } from '@emotion/react';
import { useColors } from '../../hooks/useColors';
import { flexRowCss, gapCss } from '../../styles/utils';
import { Button } from '../../components/Button';
import { BnIcon } from '../../components/icons_v2';
import { Icons } from '../../types';
import { chunk, clamp } from 'lodash';
import { chunkPages, getChunkIndex } from '../../lib/pagination';

export type PaginationProps = {
	setPage: (page: number) => void;
	currentPage: number;
	pages: number;
	maxPagesView?: number;
};

export const Pagination: React.FC<PaginationProps> = ({ pages, currentPage, setPage, maxPagesView = 0 }) => {
	maxPagesView ||= pages;

	const { colors } = useColors();

	const PageButtons = useCallback(() => {
		const onClick = (page: number) => {
			if (currentPage !== page) {
				setPage(page);
			}
		};

		const pageChunks: number[][] = useMemo(() => chunkPages(pages, maxPagesView), [pages, maxPagesView]);

		const chunkIndex: number = useMemo(
			() => (!pageChunks?.length ? 0 : getChunkIndex(pageChunks, maxPagesView, currentPage)),
			[currentPage, maxPagesView, pageChunks?.length]
		);

		return (
			<div css={flexRowCss} data-aid="index-Pagination">
				{pageChunks?.[chunkIndex].map((_, index) => (
					<PageButton
						chunkIndex={chunkIndex}
						index={index}
						onClick={onClick}
						currentPage={currentPage}
						maxPagesView={maxPagesView}
					/>
				))}
			</div>
		);
	}, [pages, currentPage]);

	const isOnFirstPage: boolean = currentPage === 1;
	const isOnLastPage: boolean = currentPage === pages;

	return (
		<div css={[flexRowCss, gapCss(12)]}>
			<Button
				data-aid="button-Pagination-prev"
				theme="basic"
				sizer="S"
				disabled={isOnFirstPage}
				style={{ padding: 0 }}
				onClick={() => setPage(currentPage - 1)}
				datatype="button"
				data-testid="previous-button"
			>
				<BnIcon icon={Icons.back} color={isOnFirstPage ? colors.tx_text_disabled : colors.bg_text_secondary} />
			</Button>
			<PageButtons />
			<Button
				data-aid="button-Pagination-next"
				theme="basic"
				sizer="S"
				disabled={isOnLastPage}
				style={{ padding: 0 }}
				onClick={() => setPage(currentPage + 1)}
				datatype="button"
				data-testid="next-button"
			>
				<BnIcon icon={Icons.forward} color={isOnLastPage ? colors.tx_text_disabled : colors.bg_text_secondary} />
			</Button>
		</div>
	);
};

type PageButtonProps = {
	chunkIndex: number;
	maxPagesView: number;
	index: number;
	currentPage: number;
	onClick: (page: number) => void;
};

const PageButton: React.FC<PageButtonProps> = ({ currentPage, index, maxPagesView, onClick, chunkIndex }) => {
	const page: number = chunkIndex * maxPagesView + index + 1;
	const isCurrentPage: boolean = currentPage === page;

	return (
		<Button
			datatype="button"
			data-testid="button-pagination"
			data-index={index}
			key={page}
			onClick={() => onClick(page)}
			theme={isCurrentPage ? 'primary' : 'basic'}
			sizer={'S'}
		>
			{page}
		</Button>
	);
};
