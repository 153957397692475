/** @jsx jsx */
import { CalculateFeeResult, PaymentMethodOption, PaymentMethodTypeEnum } from '@bondsports/types';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { jsx } from '@emotion/react';
import { usePayments } from '@app/react/components/payments';
import { ModalWindow, Pricify, roundPriceCents, TSize, useModal } from '@bondsports/utils';
import { Stripe } from 'stripe';
import { EStripeCardFundingType } from '../../../../hooks/purchase/types';
import { paymentApi } from '../../../../lib/api/paymentApi';
import { Charge } from '../../../payments/Charge';
import { PaymentMethodsProvider } from '../../../payments/Charge/PaymentMethodsProvider';
import { ChargeButtonWrapper } from '../../../shared/Cart/ChargeButtonWrapper';
import { useOrganization } from '@app/react/hooks/useOrganization';
import { IPayment, TChargeSteps } from '@app/react/types/payment';
import { IInvoice } from '..';
import { TranslationEn } from '@assets/i18n/en';
import { ICustomer } from '@app/react/types/customers';

interface Props {
	invoiceState?: IInvoice;
	customerState: ICustomer;
	paymentMethodsData: PaymentMethodOption[];
	isPrimaryButton: boolean;
	showFeeWarning?: boolean;
	refetch: () => void;
	originalAmount?: number;
	alternativeHandleCharge?: (value: unknown, errorCallback: () => void) => Promise<void>;
	actuallyPaid?: number;
	initialStep?: TChargeSteps;
	onClickCallback?: () => void;
	buttonSizer?: TSize;
	isDisabled?: boolean;
	isProcessing?: boolean;
	hideAmountOnButton?: boolean;
	alternativeHandlePrintReceipt?: () => void;
	alternativeHandleSendReceipt?: (contactMethod: string, sendAddress: string) => void;
	isShowChargeButton?: boolean;
	isShowing?: boolean;
	scheduledPayments?: IPayment[];
}
export const ChargeFlowWrapper = ({
	invoiceState,
	customerState,
	paymentMethodsData,
	isPrimaryButton,
	showFeeWarning = false,
	refetch,
	originalAmount,
	alternativeHandleCharge,
	actuallyPaid,
	initialStep = '',
	onClickCallback,
	buttonSizer = 'S',
	isDisabled = false,
	isProcessing = false,
	hideAmountOnButton = false,
	alternativeHandlePrintReceipt,
	alternativeHandleSendReceipt,
	isShowChargeButton = true,
	isShowing = false,
	scheduledPayments,
}: Props) => {
	const { isShowing: isChargeShowing, toggle: chargeToggle } = useModal();
	const { Charge } = usePayments();
	const { organizationId } = useOrganization();

	const payingUser = customerState?.userInFamilyAccounts?.find(user => user.isAdmin)?.userId ?? customerState.entityId;

	const originalPrice = roundPriceCents(
		originalAmount >= 0
			? originalAmount
			: invoiceState?.paidAmount
			? Number((invoiceState.price - invoiceState.paidAmount).toFixed(2))
			: invoiceState?.price ?? 0
	);

	const [fees, setFees] = useState<CalculateFeeResult[]>([]);
	const [feeAmount, setFeeAmount] = useState<number>(0); // Total fee sum
	const [totalAmount, setTotalAmount] = useState<number>(originalPrice); // Total sum
	const [amount, setAmount] = useState<number>(totalAmount); // Editable amount to charge
	const [isProcessingFees, setIsProcessingFees] = useState<boolean>(false);

	const invoiceLabels = TranslationEn.customers.paymentsInvoices;

	const setTotalAmountWithRounding = (amount: number) => setTotalAmount(roundPriceCents(amount));

	const handleCalcFees = (amount: number, selectedPaymentMethod: Stripe.PaymentMethod, splitsNumber: number) => {
		const multiPayment = splitsNumber > 1;
		setIsProcessingFees(true);

		if (multiPayment) {
			paymentApi
				.getRecalcFees(
					organizationId,
					selectedPaymentMethod.type as PaymentMethodTypeEnum,
					[amount],
					splitsNumber,
					selectedPaymentMethod.card?.funding as EStripeCardFundingType
				)
				.then(res => {
					const totalPrice = res.reduce((acc, curr) => acc + curr.totalPrice, 0);
					const feeAmount = res.reduce((acc, curr) => acc + curr.feeAmount, 0);

					setTotalAmountWithRounding(totalPrice);
					setFees(res as CalculateFeeResult[]);
					setFeeAmount(feeAmount);
				})
				.finally(() => setIsProcessingFees(false));
		} else {
			paymentApi
				.getRecalcFee(
					organizationId,
					selectedPaymentMethod.type as PaymentMethodTypeEnum,
					amount,
					selectedPaymentMethod.card?.funding as EStripeCardFundingType
				)
				.then(res => {
					setFees([res as CalculateFeeResult]);
					setTotalAmountWithRounding(res.totalPrice);
					setFeeAmount(res.feeAmount);
				})
				.finally(() => setIsProcessingFees(false));
		}
	};

	const handleClose = () => {
		chargeToggle();
		refetch();
	};

	const handleSelectPaymentMethod = (paymentMethod?: Stripe.PaymentMethod, scheduledPaymentCount = 1) => {
		if (paymentMethod && amount) {
			handleCalcFees(amount, paymentMethod, scheduledPaymentCount);
		}
	};

	const handleSetAmount = (val: number) => {
		setAmount(val);
	};

	const handleClickCallback = () => {
		chargeToggle();
		onClickCallback && onClickCallback();
	};

	useEffect(() => {
		if (originalPrice) {
			setTotalAmountWithRounding(originalPrice);
			setAmount(originalPrice);
		}
	}, [originalPrice]);

	useEffect(() => {
		if (isShowing) chargeToggle();
	}, [isShowing]);

	return (
		<PaymentMethodsProvider
			// Not sure if this is what we need, I believe so
			// If family, use admin's entityId, otherwise use customer's entityId
			userId={Number(payingUser)}
			paymentMethodData={paymentMethodsData}
		>
			{isShowChargeButton && (
				<ChargeButtonWrapper
					onClickCallback={handleClickCallback}
					theme={isPrimaryButton ? 'primary' : 'secondary'}
					sizer={buttonSizer}
					isDisableFetchPaymentMethods={invoiceState?.isScheduled ?? false}
					alternativeText={`${invoiceLabels.charge} ${
						!hideAmountOnButton ? Pricify(invoiceState?.futurePrice > 0 ? invoiceState.futurePrice : originalPrice) : ''
					}`}
					isDisabled={isDisabled}
					isProcessing={isProcessing}
				/>
			)}
			<ModalWindow isShowing={isChargeShowing} toggle={handleClose} padding={0}>
				<Charge
					organizationId={organizationId}
					toggle={handleClose}
					userId={customerState.entityId}
					totalAmount={invoiceState?.futurePrice > 0 ? invoiceState.futurePrice : totalAmount}
					initialStep={initialStep}
					customer={customerState}
					invoiceId={invoiceState?.id}
					isScheduled={invoiceState?.isScheduled ?? false}
					showFeeWarning={showFeeWarning}
					paymentMethods={paymentMethodsData}
					feeAmount={feeAmount}
					fees={fees}
					onSelectPaymentMethod={handleSelectPaymentMethod}
					onSetAmount={handleSetAmount}
					originalPrice={originalPrice}
					resetPricing={() => setTotalAmountWithRounding(originalPrice)}
					alternativeHandleCharge={alternativeHandleCharge ? v => alternativeHandleCharge(v, handleClose) : undefined}
					actuallyPaid={actuallyPaid}
					alternativeHandlePrintReceipt={alternativeHandlePrintReceipt}
					alternativeHandleSendReceipt={alternativeHandleSendReceipt}
					scheduledPayments={scheduledPayments}
					isProcessing={isProcessingFees}
				/>
			</ModalWindow>
		</PaymentMethodsProvider>
	);
};
