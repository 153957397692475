import React from "react";

export const Google = (props: any) => (
  <svg
    data-aid="SvgGoogle"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.44725 12.1621C6.44725 11.528 6.55256 10.9201 6.74053 10.3499L3.45048 7.83752C2.80926 9.13943 2.448 10.6064 2.448 12.1621C2.448 13.7165 2.80882 15.1826 3.44914 16.4836L6.73742 13.9663C6.55123 13.3988 6.44725 12.7931 6.44725 12.1621Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2241 6.3936C13.6016 6.3936 14.8458 6.88171 15.8234 7.68042L18.6673 4.84054C16.9343 3.33186 14.7125 2.40002 12.2241 2.40002C8.36082 2.40002 5.04056 4.60936 3.45063 7.8375L6.74068 10.3499C7.49876 8.04872 9.65969 6.3936 12.2241 6.3936Z"
      fill="#EA4335"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2241 17.9305C9.65969 17.9305 7.49876 16.2754 6.74068 13.9742L3.45063 16.4862C5.04056 19.7148 8.36082 21.9241 12.2241 21.9241C14.6085 21.9241 16.885 21.0775 18.5936 19.4911L15.4706 17.0768C14.5894 17.6319 13.4799 17.9305 12.2241 17.9305Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5554 12.1621C21.5554 11.5853 21.4665 10.9641 21.3332 10.3872H12.2238V14.1589H17.4672C17.2051 15.4449 16.4914 16.4335 15.4703 17.0769L18.5932 19.4912C20.388 17.8255 21.5554 15.3441 21.5554 12.1621Z"
      fill="#4285F4"
    />
  </svg>
);
