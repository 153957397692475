/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext } from 'react';
import { jsx } from '@emotion/react';
import { NotesPopupLabels } from '../../../types/notes';
import { NoteForm } from '../../../organisms/notes/NoteForm';
import { NotesPopupContext } from '../../../store/notes';
import { AddNoteDto, EditNoteDto } from '../../../types/entities/notes';

type NoteFormStepProps = {
	onCancel: () => void;
	onSubmit: (dto: EditNoteDto | AddNoteDto) => Promise<void> | void;
	isAlertDisabled: boolean;
	disableAll?: boolean;
	initialValue: EditNoteDto | AddNoteDto;
	labels: NotesPopupLabels['form'];
};

export const NoteFormStep: React.FC<NoteFormStepProps> = ({
	initialValue,
	onCancel,
	onSubmit,
	isAlertDisabled,
	disableAll,
	labels,
}) => {
	const { FormComponent, schema, isFormValid } = useContext(NotesPopupContext);

	if (FormComponent) {
		return (
			<FormComponent
				note={initialValue}
				onCancel={onCancel}
				onSubmit={onSubmit}
				isAlertDisabled={isAlertDisabled}
				disableAll={disableAll}
				labels={labels}
				schema={schema!}
				isValid={isFormValid}
			/>
		);
	}

	return (
		<NoteForm
			note={initialValue}
			onCancel={onCancel}
			onSubmit={onSubmit}
			isAlertDisabled={isAlertDisabled}
			disableAll={disableAll}
			labels={labels}
			schema={schema!}
			isValid={isFormValid}
		/>
	);
};
