/** @jsx jsx */

import React, { FC } from 'react';
import { jsx, css } from '@emotion/react';
import { flex } from '../../../styles/utils';

interface SideContainerProps {
	className?: string;
	children;
}

export const SideContainer: FC<SideContainerProps> = ({ className = '', children }) => {
	return (
		<div className={className} css={flex}>
			{children}
		</div>
	);
};
