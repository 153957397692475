/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { colors } from '@app/react/styles/theme';
import { ReactNode } from 'react';
import { flexEndCss } from '@app/react/styles/utils';

const gridItemCss = css`
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${colors.borderPrimary};
	padding: 20px 0px 12px 0px;
	max-height: 52px;
	min-width: 0;
`;

export const AlertColumn = ({ children, isFlexEnd = false }: { children?: ReactNode; isFlexEnd?: boolean }) => {
	return <div css={[gridItemCss, isFlexEnd && flexEndCss]}>{children}</div>;
};
