/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useColors } from "../../hooks/useColors";
import { printOnly } from "../../styles/utils";
import { IColors } from "../../types/theme";
import { ISingleTab } from "./types";

const TabsCss = (colors: IColors,hideMargine:boolean) => css`
  display: flex;
  ${hideMargine ? "" : `

  margin-left: 1rem;

  margin-top: 1rem;
  
  `}

  ${printOnly}{
    display:none;
  }
  .link {
    padding: 0.7rem 1rem;
    margin-right: 1rem;
    cursor: pointer;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 17px;
    text-align: center;
    color: ${colors.tx_text_secondary};
    position:relative;

    &:hover {
      color: ${colors.tx_text_primary};
    }
  }
  .active {

    &::after{
      content:" ";
      background:${colors.tx_text_primary};
      border-radius: 2px;
      height:2px;
      width:100%;
      position:absolute;
      bottom:0;
      left:0;
    }
  }
`;

interface ITabsProps {
  id?: string;
  tabs: ISingleTab[];
  currentTab: string;
  handleTabSelect?: (v: string) => void;
  hideMargins?:boolean
}

export const Tabs = ({ tabs, currentTab, handleTabSelect,hideMargins = false }: ITabsProps) => {
  const {colors} = useColors()
  return (
    <div css={TabsCss(colors,hideMargins)}>
      {tabs.map((tab, index) => {
        return (
          <Tab
            key={index}
            title={tab.title}
            link={`${tab.link}`}
            isActive={currentTab === tab.link}
            handleTabSelect={handleTabSelect}
          />
        );
      })}
    </div>
  );
};

const Tab = ({ title, link, isActive, handleTabSelect }: ISingleTab) => {
  return (
    <div
      onClick={() => {
        if (handleTabSelect) {
          handleTabSelect(link);
        }
      }}
      className={`link ${isActive ? "active" : ""}`}
    >
      {title}
    </div>
  );
};
