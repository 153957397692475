import { ErrorResponse } from '@stripe/terminal-js';
import { environment } from '../../../../environments/environment';
import { network } from '../network';

const TerminalError = async (data: ErrorResponse & { organizationId: number }) => {
	if (window.location.href.includes('backoffice.bondsports.co')) {
		try {
			//@ts-ignore
			window.hj =
				//@ts-ignore
				window.hj ||
				function () {
					//@ts-ignore
					(hj.q = hj.q || []).push(arguments);
				};
			//@ts-ignore
			hj('event', 'terminal_error');
		} catch (err) {
			console.log({ err });
		}
		if (environment.SLACK_NOTIFICATIONS) {
			return await network.post(environment.SLACK_NOTIFICATIONS, { ...data });
		}
		else {
			console.warn(`No SLACK_NOTIFICATIONS [${environment.SLACK_NOTIFICATIONS}] in environment`);
		}	
	}
};

export const NotificationApi = {
	TerminalError,
};
