/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { BnIcon } from '../../../components/icons_v2';
import { Tag } from '../../../components/Tag';
import { Tooltip } from '../../../components/Tooltip';
import { AlignType, IColumn } from '../../table/types';
import { ETypography, ETypographyColor, IColors, Icons } from '../../../types';
import { Typography } from '../../../atoms';
import { useColors } from '../../../hooks/useColors';
import { TAllowedColors } from '../../../components/Tag/types';

export enum ReservationStatusEnum {
	PLANNED = 'Planned',
	APPROVED = 'Approved',
	AWAITING_ADMIN = 'Awaiting Admin',
	AWAITING_CUSTOMER = 'Awaiting Customer',
	REJECTED = 'Rejected',
	CANCELED = 'Canceled',
}

const minusIconCss = (colors: IColors) => css`
	svg {
		color: ${colors.tx_text_primary};
		width: 20px;
		height: 20px;
	}
`;

const statusCell = css`
	//width: 75px !important;
`;

export const flexCol = css`
	display: flex;
	flex-direction: column;
`;

const SpaceAddonText = ({ text, moreCount, labels }: any) => {
	const { colors } = useColors();

	const schedulesInfoLabels = labels.appendix.scheduleTab.info;

	return (
		<Fragment>
			{!text && (
				<div css={minusIconCss(colors)} data-aid="lib-appendix">
					<BnIcon icon={Icons.minus} />
				</div>
			)}
			{text && (
				<div css={flexCol}>
					<Typography type={ETypography.body2} color={ETypographyColor.primary}>
						{text}
					</Typography>
					{Boolean(moreCount) && (
						<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
							{`${moreCount} ${schedulesInfoLabels.more}`}
						</Typography>
					)}
				</div>
			)}
		</Fragment>
	);
};

const SpaceAddonCell = ({ text, tooltipContent, moreCount = 0, labels }: any) => {
	return moreCount ? (
		<Tooltip content={tooltipContent} placement="top-start">
			<SpaceAddonText text={text} tooltipContent={tooltipContent} moreCount={moreCount} labels={labels} />
		</Tooltip>
	) : (
		<SpaceAddonText text={text} tooltipContent={tooltipContent} moreCount={moreCount} labels={labels} />
	);
};

export const getReservationStatusTag = (reservationStatus: string): { title: string; color: TAllowedColors } => {
	if (reservationStatus) {
		switch (reservationStatus) {
			case ReservationStatusEnum.APPROVED:
				return {
					title: ReservationStatusEnum.APPROVED,
					color: 'green',
				};
			case ReservationStatusEnum.AWAITING_ADMIN:
				return {
					title: ReservationStatusEnum.AWAITING_ADMIN,
					color: 'gray',
				};
			case ReservationStatusEnum.AWAITING_CUSTOMER:
				return {
					title: ReservationStatusEnum.AWAITING_CUSTOMER,
					color: 'gray',
				};
			case ReservationStatusEnum.REJECTED:
				return {
					title: ReservationStatusEnum.REJECTED,
					color: 'red',
				};
			case ReservationStatusEnum.CANCELED:
				return {
					title: ReservationStatusEnum.CANCELED,
					color: 'red',
				};
			default:
				return {
					title: 'Error',
					color: 'gray',
				};
		}
	}
	return {
		title: 'Error',
		color: 'gray',
	};
};

const TagCell = ({ tagData }: { tagData: any }) => {
	return (
		<div css={statusCell}>
			<Tag title={tagData?.title} color={tagData?.color} />
		</div>
	);
};

export const itemsColumns = (isReservation: any, scheduleLabels: any, labels: any) => {
	const columns: IColumn[] = [
		{
			id: 'description',
			label: ` ${isReservation ? scheduleLabels.columns.event : scheduleLabels.columns.description}`,
			styling: { align: AlignType.LEFT },
			type: 'string',
		},
		{
			id: isReservation ? 'space' : 'resource',
			label: ` ${scheduleLabels.columns.resource}`,
			styling: { align: AlignType.LEFT },
			type: 'custom',
			component: (space: any) => (
				<SpaceAddonCell
					text={space?.text || ''}
					tooltipContent={space?.tooltipContent}
					moreCount={space?.moreCount}
					labels={labels}
				/>
			),
		},
		{
			id: 'addonCount',
			label: scheduleLabels.columns.addons,
			styling: { align: AlignType.LEFT },
			type: 'custom',
			component: (addonCount: any) => {
				const text = typeof addonCount === 'string' ? addonCount : addonCount?.text ?? '';
				return (
					<SpaceAddonCell
						text={text}
						tooltipContent={addonCount?.tooltipContent}
						moreCount={addonCount?.moreCount}
						labels={labels}
					/>
				);
			},
		},
		{
			id: 'daysTimes',
			label: scheduleLabels.columns.dayTime,
			type: 'dateWithTimeRange',
			styling: { align: AlignType.LEFT },
		},
		{
			id: 'approvalStatus',
			label: '',
			styling: { align: AlignType.LEFT },
			type: 'custom',
			component: (approvalStatus: any) => {
				if (!approvalStatus) return <div css={statusCell} />;

				const tagData = getReservationStatusTag(approvalStatus);
				return <TagCell tagData={tagData} />;
			},
		},
	];

	return columns.filter((column: IColumn) => {
		if (isReservation) {
			return true;
		}
		return column.id !== 'resource';
	});
};
