import sanitizeHtml from 'sanitize-html';

export const sanitize = (str: string, keepStyles = true) => {
	const allowedTags = ['p', 'span', 'li', 'ul', 'ol', 'strong', 'em', 'a', 'br', 'img'];
	const allowedAttributes = {
		p: ['style'],
		span: ['style'],
		li: ['style'],
		ul: ['style'],
		ol: ['style'],
		strong: ['style'],
		em: ['style'],
		a: ['style', 'href', 'target'],
		img: ['src', 'alt', 'width', 'height', 'style'],
	};

	const allowedStyles = !keepStyles
		? {
				'*': {
					'text-decoration': [/^underline$/],
				},
		  }
		: undefined;

	return sanitizeHtml(str, {
		allowedTags,
		allowedAttributes,
		allowedStyles,
	});
};
