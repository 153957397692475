import React from 'react';
import { useTheme } from '@emotion/react';
import { StyledTag } from './style';
import { TagProps } from './types';

export const Tag = ({ title, color, type = 'basic', padding = '3px 4px', fontWeight = 600 }: TagProps) => {
	const colors = useTheme();
	return (
		<>
			{title && (
				<StyledTag type={type} theme={colors} color={color} padding={padding} fontWeight={fontWeight}>
					{title}
				</StyledTag>
			)}
		</>
	);
};
