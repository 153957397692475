import React, { Fragment, useCallback } from 'react';
import { NoteDto, ResourceNameTypeEnum } from '@bondsports/types';
import { NotesPopupLabels, useFetch, NotesPopup, NotesPopupStepEnum } from '@bondsports/utils';
import { NoteButton } from '../../../components/shared/Notes/NoteButton';
import { useCustomerNotesPopup } from '../../../hooks/useCustomerNotesPopup';
import { ICustomer } from '../../../types/customers';
import { customerStore } from '../../../stores/customerStore';
import { isUndefined } from 'lodash';
import { useRecoilCallback } from 'recoil';
import { SCHEMA } from '../../../components/shared/Notes/schema';
import { isValid } from '../Notes/utils';
import { ArchiveNoteHeader } from '../utils/ArchiveNoteHeader';

const MAX_NOTES_TO_DISPLAY = 3;

export const NotesPopupCustomerWrapper: React.FC<{
	customerState: ICustomer;
	labels: NotesPopupLabels;
}> = ({ labels, customerState }) => {
	const { toggle, timezone, notesPopupActions, isShowing, step, setStep, creatorId, creatorName } =
		useCustomerNotesPopup(customerState.id);

	const updateCustomerNoteCount = useRecoilCallback(
		({ set }) =>
			(count: number) => {
				set(customerStore.customerState, { ...customerState, noteCount: count });
			},
		[customerState]
	);

	useFetch(
		async ({ signal }) => {
			if (customerState && isUndefined(customerState.noteCount)) {
				const result = await notesPopupActions.getNotes(1, 1, false, signal);
				updateCustomerNoteCount(result.meta.totalItems);
			}
		},
		[updateCustomerNoteCount]
	);

	const onNoteButtonClicked = useCallback((hasNotes: boolean) => {
		setStep(hasNotes ? NotesPopupStepEnum.LIST : NotesPopupStepEnum.ADD_NOTE);
		toggle();
	}, []);

	const onNoteCreatedHandler = useCallback(
		(note: NoteDto) => updateCustomerNoteCount((customerState.noteCount || 0) + 1),
		[updateCustomerNoteCount, customerState]
	);

	const onNoteDeletedHandler = useCallback(
		(note: NoteDto) => {
			const value = customerState.noteCount ? customerState.noteCount - 1 : 0;
			updateCustomerNoteCount(value);
			if (!value) {
				toggle();
			}
		},
		[updateCustomerNoteCount, customerState]
	);

	return (
		<Fragment>
			<NotesPopup
				labels={labels}
				step={step}
				isShowing={isShowing}
				toggle={toggle}
				entityId={customerState.id}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				entityType={ResourceNameTypeEnum.CUSTOMER}
				setStep={setStep}
				timezone={timezone}
				actions={notesPopupActions}
				creatorId={creatorId}
				creatorName={creatorName}
				onNoteCreated={onNoteCreatedHandler}
				onNoteArchived={onNoteDeletedHandler}
				schema={SCHEMA}
				isFormValid={isValid}
				HeaderComponent={step === NotesPopupStepEnum.DELETE_NOTE ? ArchiveNoteHeader : null}
				notesToDisplay={MAX_NOTES_TO_DISPLAY}
				maxAlerts={MAX_NOTES_TO_DISPLAY}
			/>
			<NoteButton onClick={onNoteButtonClicked} count={customerState.noteCount} />
		</Fragment>
	);
};
