import React from 'react';

export const Collapse = (props: any) => (
  <svg
    data-aid="svgCollapse"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.4155 8.99887L19.0005 8.99987C19.5525 8.99987 20.0005 9.44787 20.0005 10.0009C19.9995 10.5529 19.5515 10.9999 19.0005 10.9999L14.0245 10.9969C13.4745 10.9969 13.0275 10.5519 13.0245 10.0019L13.0005 5.00487C12.9975 4.45287 13.4425 4.00287 13.9955 3.99987H14.0005C14.5505 3.99987 14.9975 4.44387 15.0005 4.99487L15.0125 7.57287L18.2925 4.29287C18.6835 3.90187 19.3165 3.90187 19.7075 4.29287C20.0975 4.68387 20.0975 5.31587 19.7075 5.70687L16.4155 8.99887Z"
      fill="currentColor"
    />
    <path
      d="M5.00449 13.0006L10.0015 13.0256C10.5515 13.0286 10.9965 13.4746 10.9965 14.0246L11.0005 18.9996C11.0005 19.5516 10.5525 20.0006 10.0005 20.0006C9.44749 20.0006 9.00049 19.5526 9.00049 19.0016L8.99849 16.4166L5.70749 19.7076C5.51149 19.9026 5.25549 20.0006 5.00049 20.0006C4.74449 20.0006 4.48849 19.9026 4.29349 19.7076C3.90249 19.3166 3.90249 18.6836 4.29349 18.2936L7.57249 15.0136L4.99549 15.0006C4.44249 14.9976 3.99749 14.5466 4.00049 13.9956C4.00249 13.4446 4.45049 13.0006 5.00049 13.0006H5.00449Z"
      fill="currentColor"
    />
  </svg>
);
