/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { underlineCss } from '@app/react/styles/utils';
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import { EllipsisContainer } from '../EllipsisContainer';
import { fullWidthContainers } from '@app/react/styles/ellipsisStyles';

export const MailTo = ({
	value,
	typographyType,
	TypographyColor,
}: {
	value: string;
	typographyType: ETypography;
	TypographyColor: ETypographyColor;
}) => {
	return (
		<a css={[underlineCss, fullWidthContainers]} href={`mailto:${value}`} target="_blank">
			<Typography type={typographyType} color={TypographyColor}>
				<EllipsisContainer>{value}</EllipsisContainer>
			</Typography>
		</a>
	);
};
