import React from 'react';

export const Upload = (props: any) => (
  <svg
    data-aid="svgUpload"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 17V18H18V17C18 16.45 18.45 16 19 16C19.55 16 20 16.45 20 17V19C20 19.55 19.55 20 19 20H5C4.45 20 4 19.55 4 19V17C4 16.45 4.45 16 5 16C5.55 16 6 16.45 6 17Z"
      fill="currentColor"
    />
    <path
      d="M6 17V18H18V17C18 16.45 18.45 16 19 16C19.55 16 20 16.45 20 17V19C20 19.55 19.55 20 19 20H5C4.45 20 4 19.55 4 19V17C4 16.45 4.45 16 5 16C5.55 16 6 16.45 6 17Z"
      fill="currentColor"
    />
    <path
      d="M12.9975 5.9241C12.9994 5.94916 13.0004 5.97447 13.0004 6L13.0004 14C13.0004 14.552 12.5534 15 12.0004 15C11.4474 15 11.0004 14.552 11.0004 14L11.0004 6.00018L8.60038 7.8C8.15838 8.133 7.53138 8.042 7.20038 7.6C6.86838 7.158 6.95838 6.531 7.40038 6.2L11.4004 3.2C11.5774 3.067 11.7894 3 12.0004 3C12.2014 3 12.4024 3.061 12.5754 3.182L16.5754 5.996C17.0274 6.314 17.1364 6.938 16.8184 7.389C16.5004 7.841 15.8774 7.95 15.4254 7.632L12.9975 5.9241Z"
      fill="currentColor"
    />
    <path
      d="M12.9975 5.9241C12.9994 5.94916 13.0004 5.97447 13.0004 6L13.0004 14C13.0004 14.552 12.5534 15 12.0004 15C11.4474 15 11.0004 14.552 11.0004 14L11.0004 6.00018L8.60038 7.8C8.15838 8.133 7.53138 8.042 7.20038 7.6C6.86838 7.158 6.95838 6.531 7.40038 6.2L11.4004 3.2C11.5774 3.067 11.7894 3 12.0004 3C12.2014 3 12.4024 3.061 12.5754 3.182L16.5754 5.996C17.0274 6.314 17.1364 6.938 16.8184 7.389C16.5004 7.841 15.8774 7.95 15.4254 7.632L12.9975 5.9241Z"
      fill="currentColor"
    />
  </svg>
);
