/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import { alignItemsCss, flexColCss, gapCss } from '../../styles/utils';
import { Loader as SpinningLoader } from '../../components/Loader';
import { Typography } from '../../atoms/Typography';
import { ETypography, ETypographyColor } from '../../types';

export const LoadingWithSpinner: React.FC<{ labels: { title?: string; description?: string } }> = ({ labels }) => {
	return (
		<div css={[flexColCss, alignItemsCss('center'), gapCss(8)]} data-aid="LoadingWithSpinner-Loading">
			<SpinningLoader width={20} />
			{labels?.title ? (
				<Typography type={ETypography.captionAccented} color={ETypographyColor.primary}>
					{labels?.title}
				</Typography>
			) : null}
			{labels?.description ? (
				<Typography type={ETypography.overline} color={ETypographyColor.secondary}>
					{labels?.description}
				</Typography>
			) : null}
		</div>
	);
};
