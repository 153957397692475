import React from 'react';


export const CheckmarkCircle = (props: any) => (
    <svg
    data-aid="SvgCheckmarkCircle"
    width="24"
    height="24"
    viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 10.9261C1.66602 6.32444 5.39685 2.59277 9.99935 2.59277C14.6018 2.59277 18.3327 6.32444 18.3327 10.9261C18.3327 15.5278 14.6018 19.2594 9.99935 19.2594C5.39685 19.2594 1.66602 15.5278 1.66602 10.9261ZM9.99935 17.5928C6.32352 17.5928 3.33268 14.6019 3.33268 10.9261C3.33268 7.25027 6.32352 4.25944 9.99935 4.25944C13.6752 4.25944 16.666 7.25027 16.666 10.9261C16.666 14.6019 13.6752 17.5928 9.99935 17.5928ZM12.2531 7.92151L9.0998 12.0623L7.73897 10.324C7.45563 9.96068 6.93063 9.89735 6.56897 10.1815C6.20647 10.4648 6.1423 10.989 6.42647 11.3507L8.4523 13.9398C8.61063 14.1415 8.8523 14.259 9.10897 14.259H9.1148C9.3723 14.2582 9.61563 14.1365 9.77147 13.9307L13.579 8.93068C13.8581 8.56401 13.7873 8.04235 13.4206 7.76318C13.0531 7.48401 12.5315 7.55568 12.2531 7.92151Z" fill="#24C875"/>
        <mask id="mask0_729_27046"  maskUnits="userSpaceOnUse" x="1" y="2" width="18" height="18">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 10.9261C1.66602 6.32444 5.39685 2.59277 9.99935 2.59277C14.6018 2.59277 18.3327 6.32444 18.3327 10.9261C18.3327 15.5278 14.6018 19.2594 9.99935 19.2594C5.39685 19.2594 1.66602 15.5278 1.66602 10.9261ZM9.99935 17.5928C6.32352 17.5928 3.33268 14.6019 3.33268 10.9261C3.33268 7.25027 6.32352 4.25944 9.99935 4.25944C13.6752 4.25944 16.666 7.25027 16.666 10.9261C16.666 14.6019 13.6752 17.5928 9.99935 17.5928ZM12.2531 7.92151L9.0998 12.0623L7.73897 10.324C7.45563 9.96068 6.93063 9.89735 6.56897 10.1815C6.20647 10.4648 6.1423 10.989 6.42647 11.3507L8.4523 13.9398C8.61063 14.1415 8.8523 14.259 9.10897 14.259H9.1148C9.3723 14.2582 9.61563 14.1365 9.77147 13.9307L13.579 8.93068C13.8581 8.56401 13.7873 8.04235 13.4206 7.76318C13.0531 7.48401 12.5315 7.55568 12.2531 7.92151Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_729_27046)">
        </g>
    </svg>
)