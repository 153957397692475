import React from 'react';

export const Expand = (props: any) => (
  <svg
    data-aid="svgExpand"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.0016 4.0256C19.5516 4.0286 19.9966 4.4746 19.9966 5.0246L19.9996 9.9996C20.0006 10.5516 19.5526 11.0006 19.0006 11.0006C18.4486 11.0006 18.0006 10.5526 17.9996 10.0016L17.9976 7.41659L14.7066 10.7076C14.5116 10.9026 14.2556 11.0006 13.9996 11.0006C13.7446 11.0006 13.4886 10.9026 13.2926 10.7076C12.9026 10.3166 12.9026 9.6836 13.2926 9.2936L16.5726 6.0136L13.9956 6.0006C13.4426 5.9976 12.9966 5.5466 12.9996 4.9956C13.0026 4.4446 13.4506 4.0006 13.9996 4.0006H14.0046L19.0016 4.0256Z"
      fill="currentColor"
    />
    <path
      d="M9.29295 13.2929C9.68395 12.9019 10.316 12.9019 10.707 13.2929C11.098 13.6839 11.098 14.3159 10.707 14.7069L7.41495 17.9989L9.99995 17.9999C10.553 17.9999 11 18.4479 11 19.0009C10.999 19.5529 10.552 19.9999 9.99995 19.9999L5.02395 19.9969C4.47395 19.9969 4.02795 19.5519 4.02495 19.0019L3.99995 14.0049C3.99695 13.4529 4.44295 13.0029 4.99495 12.9999H4.99995C5.54995 12.9999 5.99695 13.4439 5.99995 13.9949L6.01295 16.5729L9.29295 13.2929Z"
      fill="currentColor"
    />
  </svg>
);
