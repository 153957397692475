/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React from 'react';
import { IColors } from '../../types';

export const flexColCss = css`
	display: flex;
	flex-direction: column;
`;

export const flexRowCss = css`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const alignTopCss = css`
	align-items: start;
`;

export const wrapCss = css`
	flex-wrap: wrap-reverse;
`;

export const headerCss = css`
	width: 100%;
	margin-top: 16px;
`;

export const footerCss = css`
	width: 100%;
	padding-top: 16px;
`;

export const headerRow = css`
	${flexRowCss};
	width: 100%;
`;

export const absolute = css`
	position: absolute;
`;

export const layoutContainer = (isMax: boolean) => css`
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: ${isMax ? 'row' : 'column-reverse'};
`;

export const iconCss = (color: string, size: number) => css`
	width: ${size}px;
	height: ${size}px;
	margin-right: 12px;
	color: ${color};
	svg {
		width: 100%;
		height: auto;
	}
`;

export const dataListContainer = (isMaximized = false) => css`
	width: ${!isMaximized ? '100%' : '65%'};
	height: 100%;
	display: flex;
	flex-direction: column;
	//	The table needs to be flush with the bottom of the modal
	margin-bottom: -1rem;
`;

export const titleTextCss = css`
	margin-right: 12px;
`;

export const publicLinkCss = (colors: IColors) => css`
	${flexRowCss};
	${hyperlinkCss};
	color: ${colors.tx_text_primary};
	margin-left: 32px;
`;

export const hyperlinkCss = css`
	cursor: pointer;
`;

export const globeCss = css`
	margin-right: 4px;
	svg {
		height: 12px;
		width: 12px;
	}
`;

export const rightBorderCss = css`
	padding-right: 12px;
	border-right: 1px solid #e2e7eb;
`;

export const secondRowIndentCss = (topMargin = 4) => css`
	padding-left: 32px;
	margin-top: ${topMargin}px;
`;

export const containerCss = css`
	padding: 0 26px;
	height: 100%;
`;

export const rightMarginCss = (rightMargin = 4) => css`
	margin-right: ${rightMargin}px;
`;

export const leftMarginCss = (leftMargin = 4) => css`
	margin-right: ${leftMargin}px;
`;

export const bottomMarginCss = (bottomMargin = 4) => css`
	margin-bottom: ${bottomMargin}px;
`;

export const dividerCss = (colors: IColors) => css`
	height: 1px;
	margin: 0 -36px;
	border-top: 1px solid ${colors.bg_border_seperator};
`;

export const fullHeight = css`
	height: 100%;
`;

export const flexGrowCss = css`
	flex-grow: 1;
`;

export const detailsMarginCss = (addMargin: boolean) => css`
	${addMargin && 'margin-left: 58px;'};
`;
