/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, SerializedStyles } from '@emotion/react';
import React from 'react';
import { Content } from './modalContent';
import { Footer } from './modalFooter';
import { Header } from './modalHeader';
import { defaultModalLayoutCss } from './styles';

export const ModalLayout = ({
	alternativeCss,
	isLoading = false,
	setLoading = () => {},
	children,
}: React.PropsWithChildren<{
	alternativeCss?: SerializedStyles;
	isLoading?: boolean;
	setLoading?: (v: boolean) => void;
}>) => {

	return (
		<div css={alternativeCss ? alternativeCss : defaultModalLayoutCss} data-aid="modal-layout-container">
			{children}
		</div>
	);
};
ModalLayout.Header = Header;
ModalLayout.Content = Content;
ModalLayout.Footer = Footer;
