import React from 'react';

export const Horizontal = (props: any) => (
  <svg
    data-aid="svgHorizontal"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.68 3.3345H4.31999C3.25961 3.3345 2.39999 4.2194 2.39999 5.31097V9.26391C2.39999 10.3555 3.25961 11.2404 4.31999 11.2404H19.68C20.7404 11.2404 21.6 10.3555 21.6 9.26391V5.31097C21.6 4.2194 20.7404 3.3345 19.68 3.3345ZM4.31999 5.0845H19.68C19.7105 5.0845 19.7453 5.09462 19.7824 5.13277C19.8203 5.17182 19.85 5.23155 19.85 5.31097V9.26391C19.85 9.34334 19.8203 9.40307 19.7824 9.44212C19.7453 9.48027 19.7105 9.49039 19.68 9.49039H4.31999C4.2895 9.49039 4.25465 9.48027 4.21759 9.44212C4.17966 9.40307 4.14999 9.34334 4.14999 9.26391V5.31097C4.14999 5.23154 4.17966 5.17182 4.21759 5.13277C4.25465 5.09462 4.2895 5.0845 4.31999 5.0845Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.68 12.7478H4.31999C3.25961 12.7478 2.39999 13.6327 2.39999 14.7242V18.6772C2.39999 19.7687 3.25961 20.6536 4.31999 20.6536H19.68C20.7404 20.6536 21.6 19.7687 21.6 18.6772V14.7242C21.6 13.6327 20.7404 12.7478 19.68 12.7478ZM4.31999 14.4978H19.68C19.7105 14.4978 19.7453 14.5079 19.7824 14.546C19.8203 14.5851 19.85 14.6448 19.85 14.7242V18.6772C19.85 18.7566 19.8203 18.8163 19.7824 18.8554C19.7453 18.8935 19.7105 18.9036 19.68 18.9036H4.31999C4.2895 18.9036 4.25465 18.8935 4.21759 18.8554C4.17966 18.8163 4.14999 18.7566 4.14999 18.6772V14.7242C4.14999 14.6448 4.17966 14.5851 4.21759 14.546C4.25465 14.5079 4.2895 14.4978 4.31999 14.4978Z"
      fill="currentColor"
    />
  </svg>
);
