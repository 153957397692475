import React from 'react';

export const Conflict = (props: any) => (
	<svg
	    data-aid="svgConflict"
		width={`${props.width || 24}`}
		height={`${props.height || 24}`}
		viewBox="0 0 24 24"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12.0001 3.99989C7.58181 3.99989 4.00009 7.58162 4.00009 11.9999C4.00009 16.4182 7.58181 19.9999 12.0001 19.9999C16.4184 19.9999 20.0001 16.4182 20.0001 11.9999C20.0001 7.58162 16.4184 3.99989 12.0001 3.99989ZM12 15.9999C12.5523 15.9999 13 16.4476 13 16.9999C13 17.5522 12.5523 17.9999 12 17.9999C11.4477 17.9999 11 17.5522 11 16.9999C11 16.4476 11.4477 15.9999 12 15.9999ZM12 6.00011C12.5523 6.00011 13 6.44782 13 7.00011V13.0001C13 13.5524 12.5523 14.0001 12 14.0001C11.4477 14.0001 11 13.5524 11 13.0001V7.00011C11 6.44782 11.4477 6.00011 12 6.00011Z"
			fill="currentColor"
		/>
	</svg>
);
