import React from 'react';

export const SemiPro = (props: any) => (
  <svg
    data-aid="svgSemiPro"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.9162 2C17.9162 2 12.9947 2 12.0519 2C11.1091 2 6.08383 2 6.08383 2V3.85351H3C2.44772 3.85351 2 4.30123 2 4.85351V6.87803C2 9.02414 3.77976 10.7803 6.08383 10.7803H6.60746C7.34066 12.4386 8.91084 13.6096 10.7957 14.0001V16.4395H9.33001C8.91084 16.4395 8.49236 16.83 8.49236 17.2199V18.293H7.23553V21C7.23553 21.5523 7.68324 22 8.23553 22H15.7645C16.3168 22 16.7645 21.5523 16.7645 21V18.293H15.5076V17.2199C15.5076 16.8294 15.1936 16.4395 14.67 16.4395H13.2043V14.0001C15.0892 13.6096 16.5549 12.4392 17.3925 10.7803H17.9162C20.1151 10.7803 22 9.02414 22 6.87803V4.85351C22 4.30123 21.5523 3.85351 21 3.85351H17.9162V2ZM6.08383 8.73154C4.93214 8.73154 4.09449 7.85377 4.09449 6.87803V5.9023H6.08383V8.43895C6.08383 8.53627 6.08383 8.63423 6.08383 8.73154ZM13.6227 18.3903V20.3418H10.3766V18.3903H13.6227ZM14.67 7.17063L13.4132 8.14636L13.8323 9.60996C13.9368 9.90255 13.6227 10.0978 13.3087 10.0005L12.0519 9.12274L10.6906 10.0005C10.3766 10.0978 10.0625 9.90319 10.167 9.60996L10.5862 8.14636L9.32933 7.17063C9.11974 6.97535 9.22488 6.58544 9.53891 6.58544L11.1098 6.48812L11.7378 5.02452C11.8423 4.73193 12.2615 4.73193 12.3659 5.02452L12.994 6.48812L14.5649 6.58544C14.7744 6.58544 14.9847 6.97599 14.67 7.17063ZM20.0107 5.80498V6.78072C20.0107 7.85377 19.0685 8.73218 18.0213 8.73218C18.0213 8.63487 18.0213 8.43959 18.0213 8.34163V5.80498H20.0107Z"
      fill="currentColor"
    />
  </svg>
);
