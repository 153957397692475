/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FC, Fragment } from 'react';
import { DefaultNotesLineProps } from '../../types';
import { Row } from '../../../../atoms/layoutPrimitives';
import { BnIcon } from '../../../../components/icons_v2';
import { ETypography, ETypographyColor, Icons } from '../../../../types';
import { alignCenterRowCss, hyperlinkCss } from '../../styles';
import { Typography } from '../../../../atoms';
import { Tooltip } from '../../../../components/Tooltip';

export const DefaultNotesLine: FC<DefaultNotesLineProps> = ({ onOpenPublic, onOpenPrivate, labels }) => (
	<Row gap="0.5rem">
		<Row styles={alignCenterRowCss} gap="0.5rem">
			<div css={hyperlinkCss} onClick={onOpenPublic} data-aid="index-default-notes-line">
				<Typography color={ETypographyColor.primary} type={ETypography.captionLink}>
					{labels.initialPublic}
				</Typography>
			</div>
			<Tooltip isTopLayer content={labels.publicTooltip}>
				<BnIcon icon={Icons.info} />
			</Tooltip>
		</Row>
		<Row styles={alignCenterRowCss} gap="0.5rem">
			<div css={hyperlinkCss} onClick={onOpenPrivate}>
				<Typography color={ETypographyColor.primary} type={ETypography.captionLink}>
					{labels.initialPrivateNote}
				</Typography>
			</div>
			<Tooltip isTopLayer content={labels.privateTooltip}>
				<BnIcon icon={Icons.info} />
			</Tooltip>
		</Row>
	</Row>
);
