import React from 'react';

export const Rugby = (props: any) => (
  <svg
    data-aid="svgRugby"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.0882 3.10979C19.0882 3.10979 14.0196 3.76656 8.77409 9.01149C3.52854 14.2564 2.86965 19.3295 2.86965 19.3295C2.56681 20.5581 3.32904 21.3376 4.55269 21.0041C4.55269 21.0041 9.62124 20.3474 14.8668 15.1024C20.1123 9.85751 20.7712 4.78445 20.7712 4.78445C21.0741 3.55582 20.3118 2.7763 19.0882 3.10979ZM7.75915 7.99667C10.188 5.56807 12.7684 3.86784 15.3354 2.77323C16.1119 2.4428 16.8394 2.19012 17.5116 2.00086C14.2488 1.96506 9.5844 3.04125 6.31041 6.31486C3.21342 9.41148 2.0839 13.7521 2 16.9735C2.1504 16.5265 2.32638 16.061 2.53305 15.5761C3.62881 13.0073 5.32924 10.4263 7.75813 7.9977L7.75915 7.99667ZM21.9846 5.9875C21.9816 5.99773 21.9795 6.00694 21.9775 6.01716C21.78 6.78237 21.4956 7.62942 21.1078 8.53989C20.0121 11.1086 18.3116 13.6897 15.8827 16.1183C13.4539 18.5469 10.8735 20.2471 8.30652 21.3417C7.67833 21.6098 7.08287 21.8246 6.52118 21.9995C9.78187 22.0261 14.4227 20.9458 17.6845 17.6855C21.1283 14.2421 22.1412 9.25804 21.9846 5.9875Z"
      fill="currentColor"
    />
  </svg>
);
