import React from "react";

export const Drinking = (props: any) => (
  <svg
    data-aid="SvgDrinking"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 14C22 19.514 17.513 22 12 22C6.486 22 2 19.514 2 14C2 13.447 2.447 13 3 13L15.999 13L15.999 5C15.999 4.448 15.551 4 14.999 4C14.448 4 13.999 4.448 13.999 5C13.999 5.553 13.552 6 12.999 6C12.447 6 11.999 5.553 11.999 5C11.999 3.346 13.345 2 14.999 2C16.654 2 18 3.346 18 5V13L20.999 13C21.552 13 22 13.447 22 14ZM12 20C16.072 20 19.444 18.94 19.937 15L4.062 15C4.555 18.94 7.927 20 12 20ZM12.999 10C12.447 10 11.999 9.553 11.999 9C11.999 8.447 12.999 7 12.999 7C12.999 7 13.999 8.447 13.999 9C13.999 9.553 13.552 10 12.999 10Z"
      fill="currentColor"
    />
    <path
      d="M13.0038 11C12.2678 11 11.6705 10.404 11.6705 9.66667C11.6705 8.92933 13.0038 7 13.0038 7C13.0038 7 14.3372 8.92933 14.3372 9.66667C14.3372 10.404 13.7412 11 13.0038 11Z"
      fill="currentColor"
    />
  </svg>
);
