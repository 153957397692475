import React from "react";

export const Skating = (props: any) => (
  <svg
    data-aid="svgSkating"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.05781 15.625H19.8461C19.5629 12.8145 17.2473 10.5949 14.3957 10.4621V11.9823C14.3957 12.3186 14.1375 12.6081 13.8016 12.6243C13.4427 12.6415 13.1457 12.3555 13.1457 12V10.2754C12.8801 10.1742 12.6344 10.0328 12.4156 9.85859L10.9051 10.7652C10.6076 10.9428 10.2246 10.8462 10.0473 10.5508C9.86992 10.2547 9.96562 9.8707 10.2617 9.69336L11.634 8.86992C11.4482 8.47387 11.375 8.08645 11.375 7.6668H9.875C9.51945 7.6668 9.23344 7.36984 9.25074 7.0109C9.26691 6.675 9.55644 6.4168 9.89273 6.4168H11.375V4.8332H9.89273C9.55644 4.8332 9.26691 4.57496 9.25074 4.23906C9.23348 3.88016 9.51945 3.5832 9.875 3.5832H11.375V2.625C11.375 2.2798 11.0952 2 10.75 2H2.625C2.2798 2 2 2.2798 2 2.625V8.56719C5.75312 8.86953 8.75547 11.8719 9.05781 15.625Z"
      fill="currentColor"
    />
    <path
      d="M2 9.82227V15.625H7.80273C7.50781 12.5613 5.06367 10.1172 2 9.82227Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.75 18.25C20.75 17.9048 21.0298 17.625 21.375 17.625C21.7202 17.625 22 17.9048 22 18.25C22 20.3178 20.3178 22 18.25 22H2.625C2.27984 22 2 21.7202 2 21.375C2 21.0298 2.27984 20.75 2.625 20.75H3.875V18.4582H3.5C2.74863 18.4582 2 17.8877 2 16.875H19.7734C19.4878 17.7905 18.6328 18.4569 17.625 18.4581V20.75H18.25C19.6285 20.75 20.75 19.6285 20.75 18.25ZM16.375 18.4582H5.125V20.75H16.375V18.4582Z"
      fill="currentColor"
    />
  </svg>
);
