/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { BnIcon, ETypography, ETypographyColor, Icons, Pricify, Typography } from '@bondsports/utils';
import { Link } from 'react-router-dom';
import { colors } from '@app/react/styles/theme';
import { PaymentStatus } from '../PaymentStatus';
import { pointer } from '@app/react/styles/utils';
import { Fragment } from 'react';
import * as dayjs from 'dayjs';
import { EDateTimeFormats } from '@app/react/types/times';
import { AlertColumn } from '../../utils/AlertColumn';
import { TranslationEn } from '@assets/i18n/en';
import { PaymentAlert } from '../types';

const iconCss = (isclickable: boolean) => css`
	width: 20px;
	height: 20px;
	color: ${colors.brandPrimary};
	${isclickable && pointer};
`;

export const PaymentAlertRow = ({
	paymentAlertType,
	date,
	unpaidAmount,
	invoiceId,
	invoicePath,
	handleRedirectToNote,
}: PaymentAlert) => {
	const labels = TranslationEn.customers.overview.payments;
	const handleNoteOnClick = () => handleRedirectToNote?.();
	return (
		<Fragment>
			<AlertColumn>
				<PaymentStatus paymentAlertType={paymentAlertType} />
			</AlertColumn>
			<AlertColumn>
				<Typography type={ETypography.body2} color={ETypographyColor.primary}>
					{dayjs(date).format(EDateTimeFormats.DAY_FORMAT)}
				</Typography>
			</AlertColumn>
			<AlertColumn>
				<Typography type={ETypography.body2Accented} color={ETypographyColor.primary}>
					{Pricify(unpaidAmount)}
				</Typography>
			</AlertColumn>
			<AlertColumn>
				<Link to={invoicePath}>
					<Typography type={ETypography.body2Link} color={ETypographyColor.primary}>
						{`${labels.invoiceNumber} ${invoiceId}`}
					</Typography>
				</Link>
			</AlertColumn>
			<AlertColumn isFlexEnd>
				<BnIcon
					icon={handleRedirectToNote ? Icons.note_filled : Icons.note}
					css={iconCss(Boolean(handleRedirectToNote))}
					onClick={handleNoteOnClick}
				/>
			</AlertColumn>
		</Fragment>
	);
};
