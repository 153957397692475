/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import Linkify from 'linkify-react';
import { NoteDto } from '../../../types/entities/notes';
import { useColors } from '../../../hooks/useColors';
import {
	alignItemsCss,
	flexColCss,
	flexRowCss,
	gapCss,
	heightPxCss,
	justifyContentCss,
	marginBottomCss,
	maxHeightCss,
	maxWidthCss,
	textEllipsisOverflowCss,
	widthCss,
} from '../../../styles/utils';
import { ETypography, ETypographyColor, Icons, NotesPopupLabels } from '../../../types';
import { Typography } from '../../../atoms';
import { BnIcon } from '../../../components/icons_v2';
import { Button } from '../../../components/Button';

export type NoteProps = {
	note: NoteDto;
	onDelete: (note: NoteDto) => void;
	onEdit: (note: NoteDto) => void;
	drawSeparator: boolean;
	timezone: string;
	labels: NotesPopupLabels['list'];
};

type AuthorAndDateTimeProps = Pick<NoteProps, 'onEdit' | 'onDelete' | 'note' | 'timezone' | 'labels'>;

const contentCss = css`
	display: flow;
	line-break: anywhere;
	> a {
		text-decoration: underline;
	}
`;

const separatorCss = (color: string) => css`
	border-bottom: 1px solid ${color};
	padding-bottom: 20px;
`;

const editAndDeleteIconsCss = css`
	display: flex;
	flex-direction: row;
	height: 100%;
	align-items: start;
`;

export const Note: React.FC<NoteProps> = ({ note, onEdit, onDelete, drawSeparator, timezone, labels }) => {
	const { colors } = useColors();
	const drawSeparatorCss = drawSeparator ? separatorCss(colors.bg_border_seperator as string) : null;

	return (
		<div
			css={[marginBottomCss(20), maxHeightCss('fit-content'), maxWidthCss('100%'), drawSeparatorCss]}
			data-aid="Note-NotesList"
		>
			<AuthorAndDateTime note={note} timezone={timezone} onDelete={onDelete} onEdit={onEdit} labels={labels} />
			<div css={[flexColCss, gapCss(4), maxWidthCss('100%')]}>
				<NoteTitle note={note} />
				<NoteMainContent note={note} />
			</div>
		</div>
	);
};

const NoteMainContent: React.FC<{ note: NoteDto }> = ({ note }) => {
	return (
		<Typography color={ETypographyColor.primary} type={ETypography.body1}>
			<span css={contentCss}>
				<Linkify options={{ target: '_blank' }}>{note.content}</Linkify>
			</span>
		</Typography>
	);
};

const NoteTitle: React.FC<{ note: NoteDto }> = ({ note }) => {
	return (
		<div css={[textEllipsisOverflowCss, widthCss(100)]}>
			<Typography color={ETypographyColor.primary} type={ETypography.body1Accented}>
				{note.title.slice(0, 60)} {note.title.length > 50 ? '...' : ''}
			</Typography>
		</div>
	);
};

const AuthorAndDateTime: React.FC<AuthorAndDateTimeProps> = ({ note, timezone, onEdit, onDelete, labels }) => {
	const { colors } = useColors();

	const onEditHandler = () => onEdit(note);
	const onDeleteHandler = () => onDelete(note);

	return (
		<div data-aid="NotesList" css={[flexRowCss, justifyContentCss('space-between'), alignItemsCss('start')]}>
			<div css={marginBottomCss(12)}>
				<div css={[flexRowCss, gapCss(4)]}>
					<Typography color={ETypographyColor.secondary} type={ETypography.body1}>
						{labels.dateAndTime(note.createdAt, timezone)}
					</Typography>
					<div css={[flexRowCss, alignItemsCss('center')]}>
						{note.isPinned && (
							<div css={heightPxCss(16)}>
								<BnIcon color={colors.tx_text_primary} icon={Icons.pinned} />
							</div>
						)}
						{note.isAlert && (
							<div css={heightPxCss(18)}>
								<BnIcon color={colors.bg_system} icon={Icons.info} width={18} height={18} />
							</div>
						)}
					</div>
				</div>
				<div css={[flexRowCss, gapCss(4)]}>
					<Typography color={ETypographyColor.secondary} type={ETypography.body1}>
						{labels.notedBy}
					</Typography>
					<Typography color={ETypographyColor.primary} type={ETypography.body1}>
						{note.creatorName}
					</Typography>
				</div>
			</div>
			<div css={[editAndDeleteIconsCss, gapCss(12)]}>
				<Button data-aid="button-NotesList-edit" theme={'basic'} sizer={'S'} style={{ padding: 0 }} onClick={onEditHandler}>
					<BnIcon icon={Icons.edit_outline} width={20} height={20} color={colors.bg_text_secondary} />
				</Button>
				<Button data-aid="button-NotesList-delete" theme={'basic'} sizer={'S'} style={{ padding: 0 }} onClick={onDeleteHandler}>
					<BnIcon icon={Icons.delete_outline} width={20} height={20} color={colors.bg_text_secondary} />
				</Button>
			</div>
		</div>
	);
};
