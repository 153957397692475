import React from 'react';

export const QuestionMark = (props: any) => (
    <svg
        data-aid="svgQuestionMark"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <path
          d="M11.9375 2.5C15.2114 2.5 17.875 5.16356 17.875 8.4375C17.875 11.3041 15.8313 13.7041 13.125 14.2539V15.5625C13.125 16.2192 12.5942 16.75 11.9375 16.75C11.2808 16.75 10.75 16.2192 10.75 15.5625V13.1875C10.75 12.5308 11.2808 12 11.9375 12C13.9016 12 15.5 10.4016 15.5 8.4375C15.5 6.47338 13.9016 4.875 11.9375 4.875C9.97338 4.875 8.375 6.47338 8.375 8.4375C8.375 9.09419 7.84419 9.625 7.1875 9.625C6.53081 9.625 6 9.09419 6 8.4375C6 5.16356 8.66356 2.5 11.9375 2.5Z"
          fill="currentColor"
      />
      <path
          d="M10.75 20.3125C10.75 19.6558 11.2808 19.125 11.9375 19.125C12.5942 19.125 13.125 19.6558 13.125 20.3125C13.125 20.9692 12.5942 21.5 11.9375 21.5C11.2808 21.5 10.75 20.9692 10.75 20.3125Z"
          fill="currentColor"
      />
    </svg>
);
