import { User } from 'app/react/types/user';
export interface IStation {
	id: number;
	name: string;
	facilityId: number;
	organizationId?: number;
	processorTerminalId?: string;
	terminaLabel?: string;
	processorSerialNumber?: string;
	currentOpenShift?: IShift;
	updatedAt?: string;
}

export interface IShift {
	id: number;
	organizationId: number;
	stationId: number;
	status: ShiftStatusEnum;
	station: IStation;
	openingUserId: number;
	openingCashAmount: number;
	openingDate: string;
	openingTime: string;

	closingUserId?: number;
	closingCashAmount?: number;
	closingDate?: string;
	closingTime?: string;

	reconcilingUserId?: number;
	reconciliationAmount?: number;
	reconcilingDate?: string;
	reconcilingTime?: string;
	reconciliationInvoiceId?: number;

	managementClosingCashAmount?: number;

	deletedAt?: Date;

	sumsOnShift?: { [key: string]: number };
	currentCashDifference?: number;

	openingUser?: User;
	closingUser?: User;
	reconcilingUser?: User;
}

export enum ShiftStatusEnum {
	OPEN = 'open',
	CLOSED = 'closed',
	RECONCILED = 'reconciled',
	MANAGMENT_CLOSED = 'closed_by_manager',
}

/**
 * not to confuse with 'be_terminal' on EStorageKeys
 */
export const BnTERMINAL = 'bnTerminal';
