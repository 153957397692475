/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor } from '../../../types';
import { SkeletonWrapper } from '../../../components/skeletonWrapper';
import { useWindowSize } from '../../../hooks/useWindowSize';

const summaryLineCss = css`
	display: flex;
	justify-content: space-between;
	@media print and (max-width: 10cm) {
		display: none;
	}
`;

const printRowCss = css`
	display: none;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-bottom: black 1px dashed;
	padding: 1rem 0 1rem 0;
	@media print and (max-width: 10cm) {
		display: flex;
	}
`;

const bottomBorderCss = css`
	border-bottom: black 1px dashed;
`;

export const SummaryLine = ({
	label,
	value,
	isMain = false,
	isLoading = false,
}: {
	label?: string;
	value?: string;
	isMain?: boolean;
	isLoading?: boolean;
}) => {
	return (
		<Fragment>
			<div css={[printRowCss, bottomBorderCss]} data-aid="summaryLine-details">
				<Typography type={ETypography.body2} color={ETypographyColor.primary}>
					{label}
				</Typography>
				<Typography type={ETypography.body2} color={ETypographyColor.primary}>
					{value}
				</Typography>
			</div>
			<div css={summaryLineCss}>
				<Typography type={isMain ? ETypography.captionAccented : ETypography.caption} color={ETypographyColor.primary}>
					{label}
				</Typography>
				<SkeletonWrapper isLoading={isLoading} minHeight={10}>
					<Typography
						type={isMain ? ETypography.captionAccented : ETypography.caption}
						color={ETypographyColor.primary}
					>
						{value}
					</Typography>
				</SkeletonWrapper>
			</div>
		</Fragment>
	);
};
