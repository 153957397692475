import React from 'react';

export const LockFilled = (props: any) => (
  <svg
    data-aid="svgLockFilled"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.0004 10.9969H17.0004C18.105 10.9969 19.0004 11.8923 19.0004 12.9969V18.9969C19.0004 20.1015 18.105 20.9969 17.0004 20.9969H7.0004C5.89583 20.9969 5.0004 20.1015 5.0004 18.9969V12.9969C5.0004 11.8923 5.89583 10.9969 7.0004 10.9969ZM13.0004 16C13.0004 16.5523 12.5527 17 12.0004 17C11.4481 17 11.0004 16.5523 11.0004 16C11.0004 15.4477 11.4481 15 12.0004 15C12.5527 15 13.0004 15.4477 13.0004 16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 4.05001C11.2177 4.05001 10.4674 4.36081 9.91415 4.91405C9.36091 5.46728 9.05011 6.21762 9.05011 7.00001V11C9.05011 11.5799 8.58001 12.05 8.00011 12.05C7.42021 12.05 6.95011 11.5799 6.95011 11V7.00001C6.95011 5.66067 7.48216 4.37618 8.42922 3.42912C9.37628 2.48206 10.6608 1.95001 12.0001 1.95001C13.3395 1.95001 14.6239 2.48206 15.571 3.42912C16.5181 4.37618 17.0501 5.66067 17.0501 7.00001V11C17.0501 11.5799 16.58 12.05 16.0001 12.05C15.4202 12.05 14.9501 11.5799 14.9501 11V7.00001C14.9501 6.21762 14.6393 5.46728 14.0861 4.91405C13.5328 4.36081 12.7825 4.05001 12.0001 4.05001Z"
      fill="currentColor"
    />
  </svg>
);
