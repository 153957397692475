import React from 'react';

export const Pingpong = (props: any) => (
  <svg
    data-aid="svgPingpong"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.31673 2C3.06175 2 2.04641 2.97836 2.04641 4.18763C2.04641 5.3969 3.06175 6.37526 4.31673 6.37526C5.57171 6.37526 6.58705 5.3969 6.58705 4.18763C6.58705 2.97836 5.57171 2 4.31673 2ZM14.3566 2.72921C11.9917 2.75352 9.67721 3.88379 7.94924 5.54882C6.81093 6.64264 6.0983 7.97952 5.80505 9.38933L15.0629 18.3343C16.526 18.0517 17.9387 17.362 19.0738 16.2682C22.1451 13.3088 23.4599 8.55985 19.7045 4.94115C18.0616 3.35815 16.1949 2.71098 14.3566 2.72921ZM5.6537 11.0422C5.66946 11.9416 5.83974 12.8197 6.18344 13.6674C6.23074 13.8861 6.53345 15.4418 5.95641 16.0008C4.69512 17.2162 2.96084 18.3282 2.34912 18.9177C1.73739 19.5071 1.95497 20.3852 2.80318 21.2025C3.64825 22.0168 4.54376 22.272 5.17441 21.6644C5.80505 21.0567 6.96544 19.4038 8.22673 18.1885C8.77854 17.6567 10.2385 17.8786 10.5979 17.9454C11.4935 18.2887 12.4237 18.465 13.3728 18.4802L5.6537 11.0422Z"
      fill="currentColor"
    />
  </svg>
);
