import React from "react";

export const Linkedin = (props: any) => (
  <svg
    data-aid="SvgLikedin"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 5.03125C5.46497 5.03125 5.03125 5.46497 5.03125 6V18C5.03125 18.535 5.46497 18.9688 6 18.9688H18C18.535 18.9688 18.9688 18.535 18.9688 18V6C18.9688 5.46497 18.535 5.03125 18 5.03125H6ZM2.96875 6C2.96875 4.32589 4.32589 2.96875 6 2.96875H18C19.6741 2.96875 21.0313 4.32589 21.0313 6V18C21.0313 19.6741 19.6741 21.0313 18 21.0313H6C4.32589 21.0313 2.96875 19.6741 2.96875 18V6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9.96874C8.56955 9.96874 9.03125 10.4304 9.03125 11V16C9.03125 16.5695 8.56955 17.0312 8 17.0312C7.43046 17.0312 6.96875 16.5695 6.96875 16V11C6.96875 10.4304 7.43046 9.96874 8 9.96874Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6.96874C8.56955 6.96874 9.03125 7.43045 9.03125 7.99999V8.00999C9.03125 8.57954 8.56955 9.04124 8 9.04124C7.43046 9.04124 6.96875 8.57954 6.96875 8.00999V7.99999C6.96875 7.43045 7.43046 6.96874 8 6.96874Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 9.96874C12.5695 9.96874 13.0313 10.4304 13.0313 11V16C13.0313 16.5695 12.5695 17.0312 12 17.0312C11.4305 17.0312 10.9688 16.5695 10.9688 16V11C10.9688 10.4304 11.4305 9.96874 12 9.96874Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8566 10.8566C12.4251 10.2881 13.1961 9.96874 14 9.96874C14.8039 9.96874 15.575 10.2881 16.1434 10.8566C16.7119 11.425 17.0313 12.1961 17.0313 13V16C17.0313 16.5695 16.5695 17.0312 16 17.0312C15.4305 17.0312 14.9688 16.5695 14.9688 16V13C14.9688 12.7431 14.8667 12.4967 14.685 12.315C14.5033 12.1333 14.2569 12.0312 14 12.0312C13.7431 12.0312 13.4967 12.1333 13.315 12.315C13.1333 12.4967 13.0313 12.7431 13.0313 13C13.0313 13.5695 12.5695 14.0312 12 14.0312C11.4305 14.0312 10.9688 13.5695 10.9688 13C10.9688 12.1961 11.2881 11.425 11.8566 10.8566Z"
      fill="currentColor"
    />
  </svg>
);
