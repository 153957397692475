import React, { useRef, ComponentType } from 'react';
import { VariableSizeList } from 'react-window';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { createRow } from './createRow';

export interface InfiniteListProps<TRow> {
	component: ComponentType<TRow>;
	data: TRow[];
	defaultRowHeight?: number;
	defaultColumnWidth?: number;
}

export function InfiniteList<TRow>({ component, data, defaultRowHeight }: InfiniteListProps<TRow>) {
	const totalItemCount = data?.length;
	const listRef = useRef<VariableSizeList | null>(null);
	const rowHeights = useRef({});

	const handleSetHeight = (index: number, size: number) => {
		if (listRef) {
			listRef.current?.resetAfterIndex(index);
			rowHeights.current = { ...rowHeights.current, [index]: size };
		}
	};

	const getRowHeight = (index: number) => {
		return (rowHeights?.current as any)?.[index] || defaultRowHeight || 100;
	};

	return (
		<AutoSizer>
			{({ height, width }: Size) => (
				<VariableSizeList
					ref={listRef}
					height={height || '100%'}
					width={width || '100%'}
					itemCount={totalItemCount}
					itemSize={getRowHeight}
					itemData={data}
				>
					{createRow(component, handleSetHeight)}
				</VariableSizeList>
			)}
		</AutoSizer>
	);
}
