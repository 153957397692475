// DateRangePicker.tsx
import dayjs from 'dayjs';
import { useTheme } from '@emotion/react';
import Calendar from './Calender/Calender';
import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import {
	StyledDateRangeInput,
	StyledLeftArea,
	StyledPredefinedRanges,
	StyledMainArea,
	StyledMainBody,
	StyledCalendars,
	StyledMainFooter,
	Right,
	ErrorStyle,
} from './style';
import { Button } from '../Button';
import { DateRangeStartEnd, PredefinedRange } from '../../types';
import { capitalize } from '../../functions';
import { ErrorMessage } from '../ErrorMessage';
import { BnIcon } from '../../icons';

interface DateRangeProps {
	onSubmit: (startDate: Date, endDate?: Date, isToggle?: boolean, isSoftSubmit?: boolean) => void;
	onCancel: () => void;
	disabledDates?: (day: Date) => boolean;
	predefinedRanges: PredefinedRange[];
	labels?: {
		btnSubmit: string;
		btnCancel: string;
	};
	notAfter?: string;
	notBefore?: string;
	notAfterError?: string;
	notBeforeError?: string;
	openOnDateRange?: DateRangeStartEnd;
}

interface SelectedMonth {
	firstCalender: Date;
	secondCalender: Date;
}

const DateRange: React.FC<DateRangeProps> = ({
	onSubmit,
	onCancel,
	predefinedRanges,
	disabledDates,
	labels = undefined,
	notAfter,
	notBefore,
	notAfterError,
	notBeforeError,
	openOnDateRange,
}) => {
	const colors = useTheme();
	const [isCanceled, setIsCanceled] = useState<boolean>(false);
	const [{ startDate, endDate }, setDateRange] = useState<{ startDate: Date | undefined; endDate: Date | undefined }>({
		startDate: undefined,
		endDate: undefined,
	});
	const [selectedMonth, setSelectedMonthOfCalender] = useState<SelectedMonth>({
		firstCalender: dayjs().toDate(),
		secondCalender: dayjs().add(1, 'month').toDate(),
	});
	const [error, setError] = useState<string>('');
	const [predefinedRangesState, setPredefinedRangesState] = useState<PredefinedRange[]>(predefinedRanges);

	useEffect(() => {
		if (openOnDateRange) {
			handlePredefinedRangeClick(openOnDateRange);
		}
	}, [openOnDateRange]);

	useEffect(() => {
		handleSubmit(!isCanceled, true);
		setIsCanceled(false);
	}, [startDate, endDate]);

	const isRangeSelected = React.useMemo(() => {
		return (range: DateRangeStartEnd) => {
			const end = dayjs(range.end).startOf('day').toDate();
			const selectedEnd = endDate ? dayjs(endDate).startOf('day').toDate() : new Date();
			return (
				dayjs(range.start).isSame(startDate) &&
				((endDate && dayjs(end).isSame(selectedEnd)) || (!endDate && dayjs(end).isSame(startDate)))
			);
		};
	}, [startDate, endDate]);

	const handleSubmit = useCallback(
		(isSoftSubmit: boolean = false, isCanceled: boolean = false) => {
			if (startDate) {
				const end = endDate ? dayjs(endDate).endOf('day').toDate() : undefined;
				onSubmit(startDate, end, isSoftSubmit, isCanceled);
			}
		},
		[startDate, endDate, onSubmit]
	);

	const handleCalendarMonthChange = (newMonth: Date, calendarNumber: number) => {
		const firstCalender = calendarNumber === 1 ? newMonth : dayjs(newMonth).subtract(1, 'month').toDate();
		const secondCalender = calendarNumber === 1 ? dayjs(newMonth).add(1, 'month').toDate() : newMonth;
		setSelectedMonthOfCalender({
			firstCalender,
			secondCalender,
		});
	};

	const handlePredefinedRangeClick = useCallback(
		(range: DateRangeStartEnd) => {
			if (dayjs(range.start).diff(dayjs(startDate)) || dayjs(range.end).diff(dayjs(endDate))) {
				setError('');
				setDateRange({ startDate: range.start, endDate: range.end || undefined });
				handleCalendarMonthChange(range.start, 1);
			}
		},
		[handleCalendarMonthChange]
	);

	const handleCancel = useCallback(() => {
		setIsCanceled(true);
		if (openOnDateRange) {
			handlePredefinedRangeClick(openOnDateRange);
		}
		onCancel();
	}, [openOnDateRange, handlePredefinedRangeClick, onCancel]);

	const handleCalendarChange = useCallback(
		(newDate: Date | null) => {
			setError('');
			setPredefinedRangesState(predefinedRanges);
			try {
				if (!startDate) {
					setDateRange({ startDate: newDate || undefined, endDate: undefined });
				} else if (startDate && !endDate) {
					if (newDate && dayjs(newDate).isBefore(startDate)) {
					} else {
						setDateRange({ startDate, endDate: newDate || undefined });
					}
				} else if (startDate && endDate) {
					setDateRange({ startDate: newDate || undefined, endDate: undefined });
				}
			} catch (error) {
				console.error('Error handling calendar change:', error);
			}
		},
		[startDate, endDate]
	);

	const selectRange = (predefinedRange: PredefinedRange) => {
		const { name } = predefinedRange;
		const ranges = predefinedRangesState.map(range => {
			if (range.name === name) {
				return {
					...range,
					isSelected: true,
				};
			} else {
				return { ...range, isSelected: false };
			}
		});
		setPredefinedRangesState(ranges);
	};

	return (
		<StyledDateRangeInput theme={{ ...colors }}>
			{predefinedRanges?.length > 0 && (
				<StyledLeftArea theme={{ ...colors }}>
					<StyledPredefinedRanges theme={{ ...colors }}>
						<ul>
							{predefinedRangesState.map((predfinedRange, index) => (
								<li
									data-aid="date-selection-aid"
									data-aid-value={`${predfinedRange.name}`}
									key={index}
									onClick={() => {
										handlePredefinedRangeClick(predfinedRange.range);
										selectRange(predfinedRange);
									}}
								>
									<div className={cx('range-info', { selected: predfinedRange.isSelected })}>
										<span>{`${predfinedRange.name}`}</span>
									</div>
								</li>
							))}
						</ul>
					</StyledPredefinedRanges>
				</StyledLeftArea>
			)}
			<StyledMainArea theme={{ ...colors }}>
				<StyledMainBody theme={{ ...colors }}>
					<StyledCalendars theme={{ ...colors }}>
						<Calendar
							selectedMonth={dayjs(selectedMonth.firstCalender)}
							selectedDate={{ startDate, endDate }}
							onChangeMonth={(newMonth: Date) => handleCalendarMonthChange(newMonth, 1)}
							onSelectDay={(selectedDay: Date) => handleCalendarChange(selectedDay)}
							showBackButton={true}
							showForwardButton={false}
							disabledDates={disabledDates}
						/>
						<Calendar
							selectedMonth={dayjs(selectedMonth.secondCalender)}
							selectedDate={{ startDate, endDate }}
							onChangeMonth={(newMonth: Date) => handleCalendarMonthChange(newMonth, 2)}
							onSelectDay={(selectedDay: Date) => handleCalendarChange(selectedDay)}
							showBackButton={false}
							showForwardButton={true}
							disabledDates={disabledDates}
						/>
					</StyledCalendars>
				</StyledMainBody>
				<StyledMainFooter theme={{ ...colors }}>
					{
						<div>
							{error && (
								<ErrorStyle>
									{BnIcon({ icon: 'warning', props: {} })}
									<div style={{ marginRight: '8px', marginLeft: '8px' }}>
										<ErrorMessage error={error} />
									</div>
								</ErrorStyle>
							)}
						</div>
					}
					<Right>
						<Button data-aid="cancel-button" sizer="XS" theme="basic" onClick={handleCancel}>
							{capitalize(labels?.btnCancel || 'cancel')}
						</Button>
						<Button
							data-aid="apply-button"
							disabled={!(startDate && endDate)}
							sizer="XS"
							theme="primary"
							onClick={() => handleSubmit(false, false)}
						>
							{capitalize(labels?.btnSubmit || 'apply')}
						</Button>
					</Right>
				</StyledMainFooter>
			</StyledMainArea>
		</StyledDateRangeInput>
	);
};

export default DateRange;
