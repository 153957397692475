import * as  React from 'react';
export const BsTennis = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.84053 1.33519L10.5573 4.05079C12.348 5.77774 12.3501 8.76318 10.5573 10.5559C8.76458 12.3487 5.39136 11.9614 4.05159 10.5559L1.3352 7.84054C1.29372 9.59892 1.94391 11.3722 3.28583 12.7141C5.88909 15.3174 10.1114 15.3174 12.7142 12.7141C15.3175 10.11 15.3175 5.88912 12.7142 3.28581C11.3719 1.9435 9.59951 1.29287 7.84053 1.33519Z"
      fill="#F7B500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.61841 10.029C5.29388 10.7012 6.37002 10.9959 7.32376 10.9952C8.27669 10.9935 9.3553 10.7036 10.0291 10.029C10.7037 9.35518 11.1869 8.27702 11.1885 7.32365C11.1902 6.37031 10.7013 5.29373 10.0291 4.61831L9.99086 4.58005L7.13052 1.52649C5.93344 1.79097 4.34473 2.44477 3.41378 3.37655C2.48361 4.3075 1.79103 5.73969 1.52657 6.93717L4.5826 9.99399L4.61841 10.029Z"
      fill="#F7B500"
    />
  </svg>
);
