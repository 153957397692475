/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import { BnIcon } from '../icons_v2';
import { IColors } from '../../types';

type CustomCheckboxProps = {
	colors: IColors;
	indeterminateIcon?: React.ReactElement;
	checkedIcon?: React.ReactElement;
	isIndeterminate?: boolean;
	onChange: (val: boolean) => void;
	checked?: boolean;
	disabled?: boolean;
};

const customCheckboxCss = (colors: IColors, disabled?: boolean) => css`
	z-index: 0;
	min-width: 16px;
	min-height: 16px;
	margin-right: 12px;
	border-radius: 2px;
	border: 1px solid rgba(13, 71, 116, 0.04);
	background-color: ${colors.bg_background_fields};
	&:checked {
		color: ${disabled ? colors.bg_text_disabled : colors.bg_background_primary};
	}
	&:hover {
		${disabled ? '' : 'background-color: transparent'};
	}
}
`;

export const CustomCheckbox = ({
	colors,
	onChange,
	checked,
	disabled,
	checkedIcon = (
		<BnIcon icon="check" color={disabled ? colors.bg_text_disabled : colors.tx_text_primary} style={{ margin: 0 }} />
	),
	indeterminateIcon,
	isIndeterminate,
}: CustomCheckboxProps) => {
	const onClickHandler = () => {
		if (!disabled) {
			onChange(!checked);
		}
	};

	const icon: React.ReactNode = isIndeterminate ? indeterminateIcon : checked ? checkedIcon : null;
	return (
		<span onClick={onClickHandler} css={customCheckboxCss(colors, disabled)}>
			{icon}
		</span>
	);
};
