import { useEffect, useMemo, useState } from 'react';
import {
	cloneDeep,
	debounce,
	DebouncedFunc,
	filter,
	find,
	flatten,
	isEqual,
	isUndefined,
	sortBy,
	sumBy,
	uniq,
	uniqBy,
} from 'lodash';
import { PaginationResultDto } from '../../types/dtos';
import { TFetchFunc, TGroup, TOption, TSelectedEvent } from './types';

type PaginatedSelect<T, U> = {
	groups?: TGroup<T, U>[];
	isLoading: boolean;
	isFinalPage: boolean;
	searchValue: string;
	selectedValues: U[];
	selectedCount: number;
	hasError: boolean;
	selectedGroup?: TGroup<T, U>;
	setSearchValue: (val: string) => void;
	nextPage: () => void;
	onSelect: (values: U[]) => void;
	onRemove: (values: U[]) => void;
	onSelectGroup: (group?: TGroup<T, U>) => void;
	selectAll: () => void;
	removeAll: () => void;
	selectAllGroups: () => void;
	deslectAllGroups: () => void;
};

const DEBOUNCE_TIMEOUT_MS = 500;
const DEFAULT_PAGE = 1;

export const usePaginatedSelect = <T, U>(
	initialGroups: TGroup<T, U>[],
	usePagination: boolean,
	totalItemsCount: number = 0,
	onAllGroupsSelectionChange: (selected: boolean) => void,
	fetcher?: TFetchFunc<T, U>,
	onSelected?: (selected: TSelectedEvent<T, U>[]) => void
): PaginatedSelect<T, U> => {
	const [page, setPage] = useState(DEFAULT_PAGE);
	const [isLoading, setIsLoading] = useState(false);
	const [isFinalPage, setIsFinalPage] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [hasError, setHasError] = useState(false);

	const [groups, setGroups] = useState<TGroup<T, U>[]>();
	const [selectedGroup, setSelectedGroup] = useState<TGroup<T, U>>();

	const [allGroupsSelected, setAllGroupsSelected] = useState(false);

	useEffect(() => {
		if (!initialGroups) {
			return;
		}

		if (!groups) {
			setGroups(sortBy(cloneDeep(initialGroups), 'id'));
			return;
		}

		let isAll = allGroupsSelected;

		if (
			allGroupsSelected &&
			initialGroups.length &&
			initialGroups.every(group => !group.allSelected && !group.selected?.length)
		) {
			setAllGroupsSelected(false);
			onAllGroupsSelectionChange(false);
			isAll = false;
		}

		setGroups((groups: TGroup<T, U>[]) => {
			const newGroups: TGroup<T, U>[] = [];
			for (const group of initialGroups) {
				if (isAll) {
					group.allSelected = true;
					group.selected = group.options.map(option => option.value);
					group.excluded = [];
				}

				newGroups.push(group);
			}

			if (newGroups.length < groups.length) {
				onSelectedEvent(newGroups);
			}

			return newGroups;
		});
	}, [initialGroups, allGroupsSelected]);

	useEffect((): void => {
		if (!selectedGroup) {
			return;
		}

		const fetchFunc = selectedGroup.fetcher ?? fetcher;

		if (!fetchFunc) {
			setIsFinalPage(true);
			return;
		}

		setIsLoading(true);
		fetchFunc?.(page, searchValue, selectedGroup.id)
			.then((res: PaginationResultDto<TOption<U>>) => {
				setIsFinalPage(res.meta.currentPage >= res.meta.totalPages);
				setIsLoading(false);

				setGroups((current: TGroup<T, U>[]): TGroup<T, U>[] => {
					// If the search value is empty, set the current page to the new page.
					selectedGroup.currentPage = page;

					// Reset meta if search value is empty.
					// Because the result with no filter has the most possible items.
					if (!searchValue) {
						selectedGroup.meta = res.meta;
					}

					// If allSelected is true, add the new options to the selected array.
					// If excluded is not empty, remove the values from the excluded array.
					// This is to ensure that the selected and excluded arrays are updated when the search value is empty.
					if (selectedGroup.allSelected) {
						const selected: U[] = (selectedGroup.selected ?? [])
							.concat(res.data.map((option: TOption<U>) => option.value))
							.filter((value: U) => !find(selectedGroup.excluded, excluded => isEqual(excluded, value)));

						selectedGroup.selected = uniq(selected);
						onSelectedEvent(groups!);
					}

					// If there are no options or the page is the default page, set the options to the response data.
					// If there are options, add the new options to the existing options.
					// This is to ensure that the options are updated when the search value is empty.
					if (!selectedGroup.options.length || page === DEFAULT_PAGE) {
						selectedGroup.options = res.data;
						return Array.from(current);
					}

					// If the search value is not empty, add the new options to the existing options.
					selectedGroup.options = uniqBy(selectedGroup.options.concat(res.data), 'value');

					return Array.from(current);
				});
			})
			.catch(err => {
				setHasError(true);
				setIsFinalPage(true);
				setIsLoading(false);
			});
	}, [searchValue, page, selectedGroup]);

	const setOptionsOnMultiSelect = (allSelected?: boolean): void => {
		setGroups((groups: TGroup<T, U>[]) => {
			if (selectedGroup) {
				selectedGroup.allSelected = allSelected ?? !Boolean(selectedGroup.allSelected);
				selectedGroup.excluded = undefined;
				onSelectedEvent(groups!);
				return Array.from(groups);
			}

			return groups;
		});
	};

	const selectedValues: U[] = useMemo(
		() => flatten(groups?.map((group: TGroup<T, U>) => group.selected)).filter(value => !isUndefined(value)) as U[],
		[groups]
	);

	const selectedCount: number = useMemo(() => {
		if (allGroupsSelected) {
			return totalItemsCount - sumBy(groups, group => group.excluded?.length ?? group.meta?.totalItems ?? 0);
		}

		return sumBy(groups, 'selectedCount');
	}, [groups, allGroupsSelected, totalItemsCount]);

	const debounceSearchValue: DebouncedFunc<(val: string) => void> = useMemo(
		() =>
			debounce((val: string): void => {
				setSearchValue(val);
				setPage(DEFAULT_PAGE);
			}, DEBOUNCE_TIMEOUT_MS),
		[]
	);

	const onSelectedEvent = (groups: TGroup<T, U>[]): void => {
		onSelected?.(
			groups!.map(
				(group: TGroup<T, U>): TSelectedEvent<T, U> => ({
					id: group.id,
					allSelected: Boolean(group.allSelected),
					selectedValues: group.selected ?? [],
					excludedValues: group.excluded ?? [],
					metadata: group.metadata,
				})
			)
		);
	};

	return {
		groups,
		isLoading,
		isFinalPage,
		searchValue,
		selectedValues,
		selectedCount,
		selectedGroup,
		hasError,
		setSearchValue: (val: string) => debounceSearchValue(val),
		nextPage: () => setPage((selectedGroup?.currentPage ?? 0) + 1),
		onSelect: (values: U[]): void => {
			if (selectedGroup) {
				selectedGroup.selected ??= [];
				selectedGroup.selected = selectedGroup.selected.concat(values);
				selectedGroup.selectedCount = selectedGroup.selected.length;

				if (selectedGroup.excluded?.length) {
					selectedGroup.excluded = selectedGroup.excluded.filter(
						exclude => !find(values, val => isEqual(val, exclude))
					);
				}

				onSelectedEvent(groups!);
				setGroups((current: TGroup<T, U>[]): TGroup<T, U>[] => Array.from(current));
			}
		},
		onRemove: (values: U[]): void => {
			if (selectedGroup && selectedGroup.selected?.length) {
				selectedGroup.selectedCount = selectedGroup?.selectedCount! - values.length;
				selectedGroup.selected = filter(
					selectedGroup?.selected!,
					selected => !values.some(val => isEqual(val, selected))
				);

				if (selectedGroup.allSelected) {
					selectedGroup.excluded ??= [];
					selectedGroup.excluded = selectedGroup.excluded.concat(values);
				}
				//TODO: onSelectedEvent(groups?.every(group => group.selected?.length) ? groups! : []);
				onSelectedEvent(groups!);
				setGroups((current: TGroup<T, U>[]): TGroup<T, U>[] => Array.from(current));
			}
		},
		onSelectGroup: (group: TGroup<T, U>): void => {
			setSelectedGroup(group);
			setPage(group?.currentPage ?? DEFAULT_PAGE);
			setIsFinalPage(false);
			setHasError(false);
		},
		selectAll: (): void => {
			if (selectedGroup) {
				selectedGroup.selected = selectedGroup.options.map((option: TOption<U>) => option.value);
				selectedGroup.selectedCount =
					(usePagination ? selectedGroup.meta?.totalItems : selectedGroup.options.length) ?? 0;
				setOptionsOnMultiSelect();
			}
		},
		removeAll: (): void => {
			//TODO:setSelectedGroup([] as any);
			if (selectedGroup) {
				selectedGroup.selected = [];
				selectedGroup.selectedCount = 0;
				setOptionsOnMultiSelect(false);
			}
		},
		selectAllGroups: () => {
			setGroups(groups => {
				if (!groups) {
					return groups;
				}

				for (const group of groups) {
					group.allSelected = true;
					group.selected = group.options.map(option => option.value);
					group.excluded = [];
				}

				onSelectedEvent(groups!);
				onAllGroupsSelectionChange(true);

				return Array.from(groups);
			});

			setAllGroupsSelected(true);
		},
		deslectAllGroups: () => {
			setGroups(groups => {
				if (!groups) {
					return groups;
				}

				for (const group of groups) {
					group.allSelected = false;
					group.selected = [];
					group.excluded = [];
				}

				onSelectedEvent([]);
				onAllGroupsSelectionChange(false);

				return Array.from(groups);
			});

			setAllGroupsSelected(false);
		},
	};
};
