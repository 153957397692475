type PromiseFunc<TResult> = (...args: any[]) => Promise<TResult>;

export class PromiseCache<TKey> {
	private readonly promises = new Map<TKey, Promise<any>>();

	public async withCache<TResult>(func: PromiseFunc<TResult>, key: TKey, ...args: any[]): Promise<TResult> {
		if (this.promises.has(key)) {
			return this.promises.get(key);
		}

		const promise = func(...args).finally(() => {
			this.promises.delete(key);
		});

		this.promises.set(key, promise);

		return promise;
	}
}
