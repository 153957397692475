import React from 'react';

export const FavoriteFilled = (props: any) => (
	<svg data-aid="SvgFavoriteFilled" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M11.1026 3.32495C11.4689 2.57997 12.5311 2.57997 12.8974 3.32496L15.1669 7.94046C15.3123 8.23611 15.594 8.44118 15.92 8.48873L21.0009 9.22974C21.82 9.34919 22.1476 10.3552 21.5559 10.9341L17.8747 14.5356C17.64 14.7651 17.533 15.0952 17.5883 15.4188L18.4567 20.5005C18.5964 21.3179 17.7377 21.9403 17.0043 21.5533L12.4667 19.159C12.1746 19.0049 11.8254 19.0049 11.5333 19.159L6.99571 21.5533C6.26229 21.9403 5.40364 21.3179 5.54332 20.5005L6.41171 15.4188C6.467 15.0952 6.35996 14.7651 6.12532 14.5356L2.44408 10.9341C1.8524 10.3552 2.18 9.34919 2.99908 9.22974L8.08 8.48873C8.40601 8.44118 8.68768 8.23611 8.83306 7.94046L11.1026 3.32495Z"
			fill="#0D4774"
		/>
	</svg>
);
