/** @jsx jsx */

import React, { Fragment } from 'react';
import { jsx } from '@emotion/react';
import { SkeletonWrapper } from '@bondsports/utils';

interface IProps {
	isLoading: boolean;
	skeletonLength: number;
}

export const SkeletonList = ({ isLoading, skeletonLength }: IProps) => {
	const skeletonElements = new Array(skeletonLength).fill(null);
	return (
		<Fragment>
			{skeletonElements.map((_, idx) => (
				<SkeletonWrapper key={idx} isLoading={isLoading} breakToLines minWidth={120} minHeight={20}>
					<div>skeleton-place-holder</div>
				</SkeletonWrapper>
			))}
		</Fragment>
	);
};
