/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/react';
import { Charge } from './Charge';
import { ManageDiscounts } from './temporals/ManageDiscounts';
import { Discount } from './Discount';
import { RefundDeprecated } from '../customers/refund';

const PaymentRequest = () => {
	return <div>payment Request</div>;
};

export const usePayments = () => {
	return { Charge, Discount, PaymentRequest, Refund: RefundDeprecated, DiscountTemp: ManageDiscounts };
};
