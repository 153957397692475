import React from 'react';

export const MartialArts = (props: any) => (
  <svg
    data-aid="svgMartialArts"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6639 4.86423L9.05726 2.383H13.899L11.6639 4.86423ZM5.95191 7.45228C5.95191 7.13986 6.21766 6.8869 6.54588 6.8869C6.87409 6.8869 7.13984 7.13986 7.13984 7.45228V9.40743H7.43735L10.8762 5.56868L7.57394 2.53921L6.25472 3.16707C5.89474 3.34042 5.59935 3.59035 5.38336 3.89471L1.58029 9.3258C1.47653 9.47193 1.41406 9.65132 1.41406 9.84482C1.41406 10.1099 1.53053 10.3487 1.71581 10.519C2.87833 11.542 4.30236 12.313 5.87992 12.7322L5.95191 7.45228ZM21.2489 9.3258L17.4511 3.90277C17.2298 3.59035 16.9344 3.34042 16.5872 3.17312L15.1864 2.50796L12.241 5.80955C12.2028 5.88413 12.1467 5.94661 12.079 5.99196L9.02867 9.41046H15.701V7.52686C15.701 7.21444 15.9667 6.96148 16.295 6.96148C16.6232 6.96148 16.8889 7.21444 16.8889 7.52686V12.7433C18.5332 12.3079 19.9519 11.539 21.1187 10.514C21.2987 10.3497 21.4141 10.1109 21.4141 9.84583C21.4141 9.65233 21.3516 9.47193 21.2457 9.32278L21.2489 9.3258ZM13.3707 15.164V16.579C13.3707 16.8914 13.1049 17.1444 12.7767 17.1444C12.4485 17.1444 12.1827 16.8914 12.1827 16.579V15.164H11.2436V18.4374C11.2436 18.7498 10.9779 19.0028 10.6496 19.0028C10.3214 19.0028 10.0557 18.7498 10.0557 18.4374V15.164H7.24466V21.6705C7.24995 22.0645 7.58558 22.382 7.99956 22.383H15.1324C15.5506 22.383 15.8905 22.0605 15.8905 21.6614V15.155L13.3707 15.164ZM15.8344 14.3387V10.8365H7.29336V14.3387H15.8344Z"
      fill="currentColor"
    />
  </svg>
);
