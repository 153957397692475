import React from 'react';

export const PrivateLessons = (props: any) => (
  <svg
    data-aid="svgPrivateLessons"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9997 1C10.3873 1 9.08014 2.30091 9.08014 3.90566V4.32075C9.08014 5.92551 10.3314 7.64151 11.9997 7.64151C13.668 7.64151 14.9193 5.92551 14.9193 4.32075V3.90566C14.9193 2.30091 13.6121 1 11.9997 1ZM12.0013 8.88679C11.1284 8.88679 10.3041 8.97349 9.55831 9.09677C9.58292 9.25824 9.61128 9.41972 9.64548 9.58078C9.79688 10.2752 9.99322 10.9675 10.2581 11.6417C10.5204 12.3158 10.8263 12.9773 11.1721 13.6198C11.424 14.0852 11.6984 14.5504 11.9899 14.9908C12.279 14.5441 12.5579 14.0824 12.8094 13.6125C13.1527 12.9679 13.4586 12.3056 13.7234 11.6319C13.9862 10.957 14.1906 10.2671 14.3466 9.57348C14.3804 9.41367 14.4083 9.25334 14.4338 9.09353C13.6909 8.97191 12.8701 8.88679 12.0013 8.88679ZM15.5775 9.32215C15.5412 9.49483 15.5032 9.66638 15.4585 9.83616C15.2512 10.5904 14.9926 11.3232 14.679 12.0268C14.3628 12.7291 14.0083 13.4087 13.6167 14.0649C13.3723 14.4713 13.118 14.8657 12.8477 15.2543C13.2414 15.4871 13.5412 15.8737 13.6363 16.3561L13.8766 17.5705C13.8766 17.5705 13.5489 18.0655 12.3215 18.3059C11.0936 18.5462 10.6026 18.211 10.6026 18.211L10.3623 16.9965C10.223 16.2917 10.558 15.6087 11.1427 15.2583C10.8691 14.8723 10.6133 14.4806 10.3672 14.0755C9.97183 13.4192 9.61496 12.7373 9.3009 12.0324C8.98433 11.3284 8.73421 10.5904 8.53109 9.83454C8.48688 9.66559 8.44882 9.49521 8.41379 9.32378C7.84364 9.4591 7.35308 9.60578 6.97437 9.73238C6.4355 9.91254 6.03687 10.3631 5.9276 10.9185L4.55009 17.92C4.40537 18.6547 4.5775 19.4162 5.0242 20.0189L7.23342 23H16.7692L18.9768 20.0189C19.4223 19.4166 19.5944 18.6555 19.4501 17.9216L18.0742 10.9193C17.9662 10.3701 17.5752 9.91655 17.0421 9.73806C16.6584 9.60938 16.1597 9.45997 15.5775 9.32215ZM12.8176 16.5174L11.1802 16.8376L11.3415 17.6524L12.978 17.3322L12.8176 16.5174Z"
      fill="currentColor"
    />
  </svg>
);
