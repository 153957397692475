import { useState } from 'react';
import { IPaymentMethod } from '..';
import { roundWithDecimal } from '../../../lib/utils';
import { PaymentMethodTypeEnum } from '../../../types/entities/payment';

const OPTIONAL_METHODS_ORDER = [
	PaymentMethodTypeEnum.CARD,
	PaymentMethodTypeEnum.CARD_ON_TERMINAL,
	PaymentMethodTypeEnum.ACH,
	PaymentMethodTypeEnum.CHECK,
	PaymentMethodTypeEnum.GIFT_CARD,
];

const sortPaymentMethods = (() => {
	// reverse the actual order to make the unspecified types go at the end of the list (indexOf === -1)
	const reversedList = OPTIONAL_METHODS_ORDER.reverse();

	return ({ type: typeA }: IPaymentMethod, { type: typeB }: IPaymentMethod) =>
		reversedList.indexOf(typeA) < reversedList.indexOf(typeB) ? 1 : -1;
})();

export const usePaymentMethodPicker = () => {
	const [priceRemaining, setPriceRemaining] = useState<number>(0);
	const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
	const [height, setHeight] = useState<{ collapsed: number; expanded: number } | undefined>();

	const labels = {
		amountTitle: 'Amount',
		selectTitle: 'Which payment method?',
		showMore: 'show more',
		showLess: 'show less',
		totalLeft: 'Left: ',
		refundLarger: ' is available for refund on this payment method',
	};

	const recalcPriceRemaining = (totalPrice: number) => {
		if (totalPrice) {
			let total = paymentMethods.reduce((sum, pm) => sum + (pm.selected && pm.refundAmount ? pm.refundAmount : 0), 0);
			const priceRemaining = roundWithDecimal(totalPrice - total);
			setPriceRemaining(priceRemaining);
		}
	};

	const handlePaymentSelect = (id: number, totalPrice = 0, showAmount = false, amount?: number) => {
		let currPaymentMethods = [...paymentMethods];
		const currPayment = currPaymentMethods.find(pm => pm.id === id);
		if (currPayment) {
			if (amount) {
				currPayment.selected = true;
			} else {
				currPayment.selected = !currPayment.selected;
			}

			// Calculate the amounts
			if (totalPrice && showAmount) {
				// Check if previously selected
				if (!currPayment.selected) {
					currPayment.refundAmount = undefined;
				} else {
					if (amount) {
						currPayment.refundAmount = amount === 0 ? undefined : amount;
					} else {
						const refund =
							priceRemaining < 0
								? 0
								: (currPayment.refundAmount =
										currPayment.amount && currPayment.amount < priceRemaining ? currPayment.amount : priceRemaining);
						currPayment.refundAmount = refund;
					}
				}
			} else {
				// Remove all others selected (basic only allows 1 method selected at a time)
				currPaymentMethods = currPaymentMethods.map(cpm => ({
					...cpm,
					selected: cpm.id !== currPayment.id ? false : cpm.selected,
				}));
			}
		}
		calculateHeights(paymentMethods);
		setPaymentMethods(currPaymentMethods);
	};

	const buildPaymentMethods = (usedPaymentMethods: IPaymentMethod[]) => {
		// First 2 are always balance and cash
		let paymentMethods: IPaymentMethod[] = [
			{ id: 0, type: PaymentMethodTypeEnum.BALANCE },
			{ id: 1, type: PaymentMethodTypeEnum.CASH },
		];
		const numberOfFixedMethods = paymentMethods.length;

		// Previously used payment methods with funds left on this invoice
		usedPaymentMethods
			.filter(
				method =>
					![PaymentMethodTypeEnum.BALANCE, PaymentMethodTypeEnum.CASH, PaymentMethodTypeEnum.OTHER].includes(
						method.type
					)
			)
			.sort(sortPaymentMethods)
			.forEach((method, idx) => paymentMethods.push({ ...method, id: numberOfFixedMethods + idx }));

		// Last is always other
		paymentMethods.push({ id: paymentMethods.length, type: PaymentMethodTypeEnum.OTHER });

		calculateHeights(paymentMethods);
		setPaymentMethods(paymentMethods);
	};

	const calculateHeights = (paymentMethods: IPaymentMethod[]) => {
		let collapsed = 0;
		let expanded = 0;
		const ACH_INFO_HEIGHT = 40;

		// Calculate height
		for (let i = 0; i < paymentMethods.length; i++) {
			const rec = paymentMethods[i];
			if (rec.amount && rec.refundAmount && rec.amount < rec.refundAmount) {
				if (i < 3) collapsed += 65;
				expanded += 65;
			} else {
				if (i < 3) collapsed += 46;
				expanded += 46;
			}
		}

		if (
			paymentMethods.some(paymentMethod => paymentMethod.type == PaymentMethodTypeEnum.ACH && paymentMethod.selected)
		) {
			collapsed += ACH_INFO_HEIGHT;
			expanded += ACH_INFO_HEIGHT;
		}

		setHeight({ collapsed, expanded });
	};

	return {
		priceRemaining,
		paymentMethods,
		labels,
		height,
		setHeight,
		handlePaymentSelect,
		buildPaymentMethods,
		recalcPriceRemaining,
	};
};
