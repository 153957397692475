/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import { alignItemsCss, flexRowCss, fullWidthCss, justifyContentCss } from '../../../styles/utils';
import { ErrorModalBody } from '../../../molecules/ErrorModalBody';

type ErrorStepProps = {
	setError: (err: any) => void;
	error: { message?: string; err?: any };
	toggle: () => void;
};

export const ErrorStep: React.FC<ErrorStepProps> = ({ setError, error, toggle }) => {
	const onClose = () => {
		setError(null);
		toggle();
	};

	return (
		<div
			css={[flexRowCss, justifyContentCss('center'), alignItemsCss('center'), fullWidthCss]}
			data-aid="ErrorStep-Steps"
		>
			<ErrorModalBody onClose={onClose} errorMessage={error.err || error.message} />
		</div>
	);
};
