/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Collapse, Grid } from '@material-ui/core';
import { FormPart } from 'app/react/components/customers/utils/formPart';
import { FrameContainer } from 'app/react/components/customers/utils/FrameContainer';
import { getDatesRangeWithYears, getDaysAndTimesForActivityTimesDaysSeparately } from 'app/react/lib/dates';
import { ActivityTime } from 'app/react/types/season';
import { TranslationEn } from 'assets/i18n/en';
import { Button, getIcon } from '@bondsports/utils';
import React, { Fragment, useMemo } from 'react';

const DISPLAYED_DAYS_TIMES_NUMBER = 4;

const dayCss = css`
	margin-bottom: 10px;
`;

interface SeasonDateTimeProps {
	startDate: string;
	endDate: string;
	activityTimes: ActivityTime[];
}

interface DaysAndTimeComponentProps {
	displayedDaysAndTimes: string[];
	expandableOption?: boolean;
}

const SeasonDateTime = ({ startDate, endDate, activityTimes }: SeasonDateTimeProps) => {
	const [expanded, setExpanded] = React.useState(false);

	const daysAndTimes = useMemo(() => getDaysAndTimesForActivityTimesDaysSeparately(activityTimes), [activityTimes]);

	const isExpandedAvailable = daysAndTimes ? daysAndTimes.length > DISPLAYED_DAYS_TIMES_NUMBER : false;

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const DaysAndTimeComponent = ({ displayedDaysAndTimes, expandableOption }: DaysAndTimeComponentProps) => (
		<Fragment>
			{displayedDaysAndTimes &&
				displayedDaysAndTimes.map((activity, index) => {
					const dayAndTime = activity.split(',');

					return (
						<Grid key={index} container css={dayCss}>
							<Grid item lg={3}>
								{dayAndTime[0]},
							</Grid>
							<Grid container item lg={9} justify="space-between">
								{dayAndTime[1]}
								{expandableOption && index === DISPLAYED_DAYS_TIMES_NUMBER - 1 && (
									<Button
										data-aid="button-SeasonDateTime"
										sizer="S"
										theme="basic"
										onClick={handleExpandClick}
										aria-expanded={expanded}
										aria-label="show more"
									>
										{expanded ? getIcon('arrow_up') : getIcon('arrow_down')}
									</Button>
								)}
							</Grid>
						</Grid>
					);
				})}
		</Fragment>
	);

	return (
		<FrameContainer width={60} margin="0 1rem 2rem 1rem" title={TranslationEn.season.dashboard.SeasonDateTime.title}>
			<Grid container direction="row" justify="flex-start" alignItems="flex-start">
				<Grid item lg={6}>
					<FormPart
						label={TranslationEn.season.dashboard.SeasonDateTime.dates}
						value={getDatesRangeWithYears(startDate, endDate)}
						width={100}
						margin={0}
					/>
				</Grid>

				<Grid item lg={6}>
					<FormPart
						label={TranslationEn.season.dashboard.SeasonDateTime.days}
						value={
							<DaysAndTimeComponent
								displayedDaysAndTimes={daysAndTimes.slice(0, DISPLAYED_DAYS_TIMES_NUMBER)}
								expandableOption={isExpandedAvailable}
							/>
						}
						width={100}
						margin={0}
					/>

					<Collapse in={expanded} timeout="auto" unmountOnExit>
						<FormPart
							label={''}
							value={
								<DaysAndTimeComponent
									displayedDaysAndTimes={daysAndTimes.slice(DISPLAYED_DAYS_TIMES_NUMBER, daysAndTimes.length)}
								/>
							}
							width={100}
							margin={0}
						/>
					</Collapse>
				</Grid>
			</Grid>
		</FrameContainer>
	);
};

export default SeasonDateTime;
