/** @jsx jsx */
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import React from 'react';
import { jsx, css } from '@emotion/react';
import TableCell from '@material-ui/core/TableCell';
import { cellCss } from '../styles';

export const DateWithTimeRange = ({ row, index, column, isLast }) => {
	return (
		<TableCell css={cellCss({ isAction: column.action, isLast, svgWidth: 35 })} key={column.id}>
			<Typography color={ETypographyColor.primary} type={ETypography.body2}>
				{row.daysTimes?.dateRange}
			</Typography>
			<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
				{row.daysTimes?.timeRange}
			</Typography>
		</TableCell>
	);
};
