/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx } from '@emotion/react';
import { marginTopCss } from '../../styles/utils';
import { ResourceGlDto } from '../../types/entities/product';
import { i18n } from '../../lib/i18n';
import { ETypography, ETypographyColor } from '../../types';
import { Typography } from '../../atoms';
import { SelectedTags } from '../../components/Select_v2/SelectedTags';
import { convertGlCodesToOptions } from './utils';

const { accountingCodes, noAccountingCodes } = i18n.glCodes;

export const AccountingCodes = ({
	glCodes,
	isTooltip = false,
	isSmall = false,
	textWhenNoGl = noAccountingCodes,
}: {
	glCodes: ResourceGlDto[];
	isTooltip?: boolean;
	isSmall?: boolean;
	textWhenNoGl?: string; //In some places the card where use the gl codes is smaller so we have saller spaces and diferent typography for example- session product card.
}) => {
	const selectedValues = convertGlCodesToOptions(glCodes);

	return (
		<Fragment>
			<div css={marginTopCss(isSmall ? 12 : 8)} data-aid="index-accountingCodes">
				<Typography
					type={isSmall ? ETypography.captionAccented : ETypography.captionCTA}
					color={isSmall ? ETypographyColor.primary : ETypographyColor.secondary}
				>
					{accountingCodes}
				</Typography>
				<div css={marginTopCss(isSmall ? 4 : 8)}>
					{selectedValues.length > 0 ? (
						<SelectedTags selectedValues={selectedValues} isWithShowMoreButton={!isTooltip} />
					) : (
						<Typography type={ETypography.body2} color={ETypographyColor.secondary}>
							{textWhenNoGl}
						</Typography>
					)}
				</div>
			</div>
		</Fragment>
	);
};
