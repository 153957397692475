/** @jsx jsx */

import { useState } from 'react';
import { css, jsx } from '@emotion/react';
import { Editor } from '@tinymce/tinymce-react';
import { TranslationEn } from '@assets/i18n/en';
import { richTextWrapper, ContentCss } from './styles';
import { imageApi } from '@app/react/lib/api/imageApi';

export const UNLIMITED_LENGTH = -1;

export interface IProps {
	initialValue?: string;
	handleChange?: (val: string) => void;
	//set max to UNLIMITED_LENGTH to allow unlimited characters
	max?: number;
	setLength?: (val: number) => void;
	height?: number;
	value?: string;
	isAllowImages?: boolean;
	isAllowSourceCode?: boolean;
	hideImageHelperLabel?: boolean;
}

const handleApplyResponsiveCss = (val: string, _Editor: any) => {
	// New logic for manually modifying the HTML string
	let updatedHtml = val;

	// A regular expression pattern to find img tags with or without style attribute
	const imgTagWithStyleRegExp = /<img [^>]*style="([^"]*)"[^>]*>/g;
	const imgTagNoStyleRegExp = /(<img [^>]*)>/g;

	const replacementFuncWithStyle = (match: string, styleContent: string) => {
		if (styleContent.includes('max-width: 100%; height: auto;')) {
			return match;
		} else {
			return match.replace(styleContent, styleContent + ' max-width: 100%; height: auto;');
		}
	};

	const replacementFuncNoStyle = (_match: string, imgTagStart: string) => {
		return `${imgTagStart} style="max-width: 100%; height: auto;">`;
	};

	updatedHtml = updatedHtml.replace(imgTagWithStyleRegExp, replacementFuncWithStyle);
	updatedHtml = updatedHtml.replace(imgTagNoStyleRegExp, replacementFuncNoStyle);

	return updatedHtml;
};

export const RichText = ({
	initialValue = '',
	value,
	handleChange,
	max = 1000,
	setLength,
	height = 300,
	isAllowImages = true,
	isAllowSourceCode = true,
	hideImageHelperLabel = false,
	...rest
}: IProps) => {
	const [isMaxReached, setMaxReached] = useState(false);

	const label = TranslationEn.richTextEditor.mediaAllowedCaption;

	const handleUploadImage = (blobInfo, _progress): Promise<string> =>
		new Promise((resolve, reject) => {
			imageApi.uploadFileItemImage(blobInfo.blob()).then(async response => {
				if (response.secure_url && response.secure_url.length > 0) {
					resolve(response.secure_url);
				} else {
					reject('Error uploading image');
				}
			});
		});

	function unlimitedLength(): boolean {
		return max == UNLIMITED_LENGTH;
	}

	function contentExceedsMaxLength({ appending = 0 }: { appending?: number } = {}): boolean {
		return !unlimitedLength() && length + appending >= max;
	}

	return (
		<div css={richTextWrapper}>
			<Editor
				{...rest}
				initialValue={initialValue}
				init={{
					height: height,
					content_style: ContentCss,
					menubar: false,
					statusbar: true,
					link_title: false,
					link_context_toolbar: true,
					plugins: ['advlist', 'autolink', 'lists', 'link', 'paste', 'wordcount', 'image', 'hr', 'code'],
					toolbar: `${
						isAllowSourceCode ? 'code |' : ''
					} bold italic underline | fontfamily fontsize | alignleft aligncenter alignright | bullist numlist link ${
						isAllowImages ? 'image' : ''
					} hr`,
					paste_as_text: true,
					file_picker_types: 'image',
					images_file_types: 'jpg,jpeg,png,gif,bmp,webp,tiff',
					images_upload_handler: handleUploadImage,
					setup: function (editor) {
						editor.on('PastePreProcess', e => {
							const wordcount = editor.plugins.wordcount;
							const length = wordcount.body.getCharacterCount();
							const newContent = editor.getBody().innerText + e.content;

							if (!e.content.includes('<img src="blob:')) {
								if (contentExceedsMaxLength({ appending: e.content.length })) {
									if (editor.getBody().innerText.trim().length === 0) {
										editor.notificationManager.open({
											text: 'While pasting you reach the max amount of characters, and not all the content pasted',
											type: 'warning',
											closeButton: true,
											timeout: 5000,
										});
										editor.setContent(newContent.slice(0, max));
									} else {
										editor.notificationManager.open({
											text: 'While pasting you reach the max amount of characters',
											type: 'error',
											closeButton: true,
											timeout: 5000,
										});
									}
									e.preventDefault();
								}
							}
						});
					},
				}}
				onKeyDown={(e: KeyboardEvent) => {
					if (isMaxReached) {
						if (e.key !== 'Backspace') {
							e.preventDefault();
						}
					}
				}}
				onEditorChange={(val, Editor) => {
					handleApplyResponsiveCss(val, Editor);

					const wordcount = Editor.plugins.wordcount;
					const length = wordcount.body.getCharacterCount();
					if (setLength) {
						setLength(length);
					}
					if (!contentExceedsMaxLength()) {
						setMaxReached(false);
						if (handleChange) {
							handleChange(handleApplyResponsiveCss(val, Editor));
						}
					} else {
						setMaxReached(true);
					}
				}}
			/>
			{isAllowImages && !hideImageHelperLabel && <p className="caption">{label}</p>}
		</div>
	);
};
