import { FieldValidator } from 'final-form';
import sanitizeHtml from 'sanitize-html';
import * as React from 'react';

export const required: FieldValidator<unknown> = value => {
	return value ? undefined : 'Required';
};

export const requiredArray: FieldValidator<unknown> = (value: any[]) => {
	return value.length > 0 ? undefined : 'Required';
};

export const Capitalize = (s: string) => {
	if (typeof s !== 'string') {
		return '';
	}
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const PricifyWithOutSign = (price?: number) => {
	if (price) {
		if (price > 0) {
			return `${price.toFixed(2)}`;
		} else {
			return `-${(price * -1).toFixed(2)}`;
		}
	} else {
		return '-';
	}
};

export const toFixedDecimal = (number: number) => {
	return number % 1 !== 0 ? parseFloat(number.toFixed(2)) : number;
};

export const sanitize = (str: string, keepStyles = true) => {
	const allowedTags = ['p', 'span', 'li', 'ul', 'ol', 'strong', 'em', 'a', 'br', 'img'];
	const allowedAttributes = {
		p: ['style'],
		span: ['style'],
		li: ['style'],
		ul: ['style'],
		ol: ['style'],
		strong: ['style'],
		em: ['style'],
		a: ['style', 'href', 'target'],
		img: ['src', 'alt', 'width', 'height', 'style'],
	};
	const allowedStyles = !keepStyles
		? {
				'*': {
					'text-decoration': [/^underline$/],
				},
		  }
		: undefined;

	return sanitizeHtml(str, {
		allowedTags,
		allowedAttributes,
		allowedStyles,
	});
};

export const isValuesChanged = (values: any, initialValues: any) => {
	return JSON.stringify(values) !== JSON.stringify(initialValues);
};

export const isEventType = (object: unknown) => {
	return (object as React.MouseEvent<HTMLElement, MouseEvent>)?.target;
};
