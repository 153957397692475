/** @jsxRuntime classic */
/** @jsx jsx */

import { FC } from 'react';
import { jsx } from '@emotion/react';
import { useDroppable } from '@dnd-kit/core';
import classNames from 'classnames';
import { IDroppableProps } from '../types';
import { useColors } from '../../hooks/useColors';

export const Droppable: FC<IDroppableProps> = ({ children, id, weekly, height, maxWidth }) => {
	const { isOver, setNodeRef } = useDroppable({ id });
	const { colors } = useColors();

	return (
		<div
			ref={setNodeRef}
			css={{
				background: isOver ? colors.bg_background_fields : undefined,
				flexGrow: 1,
				height,
				maxWidth,
				width: maxWidth,
				minWidth: maxWidth,
			}}
			className={classNames(weekly ? 'WeeklyDroppable' : 'Droppable')}
			aria-label="Droppable region"
			data-aid="Droppable-atoms"
		>
			{children}
		</div>
	);
};
