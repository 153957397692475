import React from 'react';

export const Program_PrivateLessons = (props: any) => (
  <svg
    data-aid="svgProgram_PrivateLessons"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M57.7777 60.4445H55.9999V47.1112C55.9999 42.9957 53.2533 40.3201 46.8266 38.1779L46.7111 38.1334L39.5999 34.5779C39.1022 34.3468 36.4444 33.0579 36.4444 31.1112V27.5557H38.2222V31.1112C38.2222 31.689 39.4844 32.5779 40.3644 32.969L47.4577 36.5157C52.2755 38.1246 57.7777 40.7912 57.7777 47.1112V60.4445Z"
      fill="currentColor"
    />
    <path
      d="M7.99995 60.4445H6.22217V47.1112C6.22217 40.7912 11.7244 38.1245 16.5511 36.5068L23.6088 32.9779C24.6488 32.5068 25.7777 31.6357 25.7777 31.1112V27.5557H27.5555V31.1112C27.5555 33.0579 24.8977 34.3468 24.3644 34.5868L17.1733 38.1779C10.7466 40.3201 7.99995 42.9957 7.99995 47.1112V60.4445Z"
      fill="currentColor"
    />
    <path
      d="M16.8888 50.6668H15.1111V60.4445H16.8888V50.6668Z"
      fill="currentColor"
    />
    <path
      d="M48.8888 50.6668H47.1111V60.4445H48.8888V50.6668Z"
      fill="currentColor"
    />
    <path
      d="M31.9999 31.1112C26.6044 31.1112 23.1111 26.2223 23.1111 18.6668V16.0001C23.1111 14.3468 24.2488 12.9512 25.7777 12.5601V9.77789H28.7377L32.2933 12.4446H37.3333C39.2977 12.4446 40.8888 14.0357 40.8888 16.0001V18.6668C40.8888 26.2223 37.3955 31.1112 31.9999 31.1112ZM26.6666 14.2223C25.6888 14.2223 24.8888 15.0223 24.8888 16.0001V18.6668C24.8888 23.8223 26.7555 29.3334 31.9999 29.3334C37.2444 29.3334 39.1111 23.8223 39.1111 18.6668V16.0001C39.1111 15.0223 38.3111 14.2223 37.3333 14.2223H31.7066L28.1511 11.5557H27.5555V14.2223H26.6666Z"
      fill="currentColor"
    />
    <path
      d="M31.9999 43.6623L31.5999 43.4668C31.2888 43.3068 23.9999 39.5912 23.9999 33.7779H25.7777C25.7777 37.8223 30.5688 40.8446 31.9999 41.6534C33.4311 40.8357 38.2222 37.8046 38.2222 33.7779H39.9999C39.9999 39.5912 32.7111 43.3068 32.3999 43.4579L31.9999 43.6623Z"
      fill="currentColor"
    />
    <path
      d="M39.9999 23.1112V21.3334C40.9777 21.3334 41.7777 20.5334 41.7777 19.5557V17.7779C41.7777 17.289 41.3777 16.889 40.8888 16.889H39.9999V15.1112H40.8888C42.3555 15.1112 43.5555 16.3112 43.5555 17.7779V19.5557C43.5555 21.5201 41.9644 23.1112 39.9999 23.1112Z"
      fill="currentColor"
    />
    <path
      d="M23.9999 23.1112C22.0355 23.1112 20.4444 21.5201 20.4444 19.5557V17.7779C20.4444 16.3112 21.6444 15.1112 23.1111 15.1112H23.9999V16.889H23.1111C22.6222 16.889 22.2222 17.289 22.2222 17.7779V19.5557C22.2222 20.5334 23.0222 21.3334 23.9999 21.3334V23.1112Z"
      fill="currentColor"
    />
    <path
      d="M32.8888 47.1112H31.1111V48.889H32.8888V47.1112Z"
      fill="currentColor"
    />
    <path
      d="M32.8888 50.6668H31.1111V52.4445H32.8888V50.6668Z"
      fill="currentColor"
    />
    <path
      d="M45.3333 50.6668H34.6666V52.4445H45.3333V50.6668Z"
      fill="currentColor"
    />
    <path
      d="M11.5555 60.4445H9.77772V52.4445H7.11106V50.6668H11.5555V60.4445Z"
      fill="currentColor"
    />
    <path
      d="M38.7188 22.312L33.3842 24.9799L34.1794 26.5699L39.514 23.902L38.7188 22.312Z"
      fill="currentColor"
    />
    <path
      d="M42.6666 16.0001H40.8888V12.4446C40.8888 8.52455 37.6977 5.33344 33.7777 5.33344H30.2222C26.3022 5.33344 23.1111 8.52455 23.1111 12.4446H21.3333C21.3333 7.54677 25.3244 3.55566 30.2222 3.55566H33.7777C38.6755 3.55566 42.6666 7.54677 42.6666 12.4446V16.0001Z"
      fill="currentColor"
    />
  </svg>
);
