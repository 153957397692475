import React from 'react';

export const Advanced = (props: any) => (
  <svg
    data-aid="svgAdvanced"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.79008 9L3 1H4.70755L10.2516 8.73374L8.79008 9Z"
      fill="currentColor"
    />
    <path
      d="M8.19654 1L13.7814 8.73374C13 8.5 12 8.5 12 8.5L6.56369 1H8.19654Z"
      fill="currentColor"
    />
    <path
      d="M20 1H18.2924L13.9119 6.99283L14.7288 8.16086L20 1Z"
      fill="currentColor"
    />
    <path
      d="M16.3616 1L12.8726 5.70856L12.0558 4.59927L14.7288 1H16.3616Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 16C19 19.866 15.866 23 12 23C8.13401 23 5 19.866 5 16C5 12.134 8.13401 9 12 9C15.866 9 19 12.134 19 16ZM12 12L13.1756 14.382L15.8042 14.7639L13.9021 16.618L14.3511 19.2361L12 18L9.64886 19.2361L10.0979 16.618L8.19577 14.7639L10.8244 14.382L12 12Z"
      fill="currentColor"
    />
  </svg>
);
