/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { BnIcon } from '@bondsports/utils';
import Picture from '../Picture';
import { Icons } from 'app/react/styles/theme';
import { lightIconCss, setOpacity } from 'app/react/styles/utils';
import { colors } from 'app/react/styles/theme';

const imgCss = (width: number, height: number) => css`
	width: ${width}px;
	height: ${height}px;
	border-radius: 50%;
`;

const profileIconCss = (width: number, height: number) => css`
	box-shadow: 0px 12px 20px ${setOpacity(colors.brandPrimaryLight || '', 0.0749563)};
	background: ${setOpacity(colors.brandPrimary, 0.04)};
	${imgCss(width, height)};
	display: flex;
	justify-content: center;
	align-items: center;
`;

interface Props {
	name: string;
	profilePicture?: any;
	width?: number;
	height?: number;
}

export const Profile = ({ name, profilePicture, width = 24, height = 24 }: Props) => {
	return (
		<Fragment>
			{profilePicture ? (
				<div>
					<Picture path={profilePicture.url} alt={name} css={imgCss(width, height)} />
				</div>
			) : (
				<div css={profileIconCss(width, height)}>
					<BnIcon icon={Icons.profile_filled} css={lightIconCss} />
				</div>
			)}
		</Fragment>
	);
};
