import { atom } from 'recoil';
import { IRawNote, ERecoilFilteredNotes } from '../types/notes';

const publicSavedNotes = atom<IRawNote[]>({
	key: ERecoilFilteredNotes.PUBLIC_NOTES,
	default: [],
});

const privateSavedNotes = atom<IRawNote[]>({
	key: ERecoilFilteredNotes.PRIVATE_NOTES,
	default: [],
});

const allNotes = atom<IRawNote[]>({
	key: ERecoilFilteredNotes.ALL_NOTES,
	default: [],
});

export const notesStore = {
	publicSavedNotes,
	privateSavedNotes,
	allNotes,
};
