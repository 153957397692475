import React from 'react';

export const ExpandAll = (props: any) => (
  <svg
    data-aid="svgExpandAll"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 15.1492L12 21L6 15.1492L7.17851 14L12 18.7016L16.8215 14L18 15.1492Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 8.8508L12 3L18 8.8508L16.8215 10L12 5.29841L7.17851 10L6 8.8508Z"
      fill="currentColor"
    />
  </svg>
);
