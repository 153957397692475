/** @jsx jsx */

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { css, jsx } from '@emotion/react';
import { Breadcrumbs, Pricify } from '@bondsports/utils';

import { ISingleTab } from '../../../shared/Tabs';
import { HeaderContainer, HeaderTitle, SideContainer } from '../../../shared/Header';
import { AccountActivity } from '../../../shared/Header/ClockLabel';
import { CustomerTag } from '../../utils/CustomerTag';
import { Tabs } from '../Tabs';
import { setBreadCrumbsPaths } from '../../utils/setBreadcrumbs';
import { colors } from '../../../../styles/theme';
import { flex, topLineHeader, topLineHeaderBread } from '../../../../styles/utils';
import { ICustomer } from '../../../../types/customers';
import { MoreMenuWrapper } from './MoreMenuWrapper';
import { TranslationEn } from '../../../../../../assets/i18n/en';
import { generateMoreMenuProps } from '../../../../pages/customers/CustomerList/lib';
import { NotesPopupCustomerWrapper } from '../../NotesPopupCustomerWrapper';
import { NOTE_POPUP_LABELS } from '../../consts';

const leftPartContainer = css`
	display: flex;
	flex-direction: column;
`;

const balanceContainer = css`
	background: white;
	padding: 1rem;
	margin-right: 1rem;
	text-align: left;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 1.6rem;
	line-height: 20px;
	color: ${colors.brandPrimary};
	span {
		color: ${colors.statusGreen};
	}
`;

interface IProps {
	id: string;
	customerState: ICustomer;
	tabs: ISingleTab[];
}

export const CustomerHeader = ({ customerState, tabs, id }: IProps) => {
	const [breadCrumbSteps, setBreadCrumbSteps] = useState([]);
	const location = useLocation();
	const labels = TranslationEn.customers;

	const moreMenuProps = generateMoreMenuProps(customerState);

	useEffect(() => {
		setBreadCrumbsPaths(location as any, setBreadCrumbSteps);
	}, [location]);

	return (
		<HeaderContainer isColumn>
			<div data-aid="customerPageHeader-bread" css={topLineHeaderBread}>
				<Breadcrumbs steps={breadCrumbSteps} />
			</div>
			<div data-aid="customerPageHeader-top" css={topLineHeader}>
				<SideContainer>
					<div css={leftPartContainer}>
						<div data-aid="customerPageHeader-title" css={flex}>
							<HeaderTitle>
								{customerState.firstName} {customerState.lastName}
							</HeaderTitle>
							<CustomerTag entityType={customerState.entityType} familyStatus={customerState.familyStatus} />
						</div>
						<AccountActivity id={customerState.id} time={customerState.updatedAt} />
					</div>
				</SideContainer>
				<SideContainer>
					<div data-aid="customerPageHeader-side" css={flex}>
						<div data-aid="customerPageHeader-balance" css={balanceContainer}>
							{labels.listColumns.creditBalance}: <span>{Pricify(Number(customerState.storedCredit))}</span>
						</div>
						<NotesPopupCustomerWrapper labels={NOTE_POPUP_LABELS} customerState={customerState} />
						<MoreMenuWrapper {...moreMenuProps} />
					</div>
				</SideContainer>
			</div>
			<Tabs tabs={tabs} id={id} />
		</HeaderContainer>
	);
};
