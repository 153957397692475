import React from 'react';
import { Select_v2 } from '../../../../components/Select_v2';
import { PromoCodeSelectProps } from '../../types/promoCodes';
import { i18n } from '../../../../lib/i18n';
import { usePromoCodeContent } from '../../hooks/usePromoCodeContent';

export const PromoCodeSelect: React.FC<PromoCodeSelectProps> = ({ onChange, initialState, fetchData }) => {
	const { options, value, isLoading, handlePromoCodeChange, lastOptionElementRef, handleType } = usePromoCodeContent({
		onChange,
		initialState,
		fetchData,
	});
	const labels = i18n.addDiscount.content.promoCode.select;
	return (
		<Select_v2
			options={options}
			isGroups={false}
			value={value}
			onSelecte={handlePromoCodeChange}
			lastOptionElementRef={lastOptionElementRef}
			isLoading={isLoading}
			handleType={handleType}
			placeholder={labels.placeholder}
			label={labels.label}
		/>
	);
};
