import { FC, ReactNode, RefObject } from 'react';
import dayjs from 'dayjs';
import { ISlot } from '../organisms/bo_calendar_slot/types';

export interface ISpace {
	id: number;
	name: string;
	parentSpaceId?: number;
	organizationId: number;
	children: ISpace[];
}

export interface IReservations {
	creatorType: string;
	name: string;
}

export enum ECalendarMode {
	DAILY = 'daily',
	WEEKLY = 'weekly',
	MONTHLY = 'monthly',
}

export enum ECalendarView {
	VERTICAL = 'vertical',
	HORIZONTAL = 'horizontal',
	LIST = 'list',
}

export interface IOptions {
	dragAndDrop?: boolean;
	resize?: boolean;
	infiniteScrolling?: boolean;
	mode?: ECalendarMode;
	view?: ECalendarView;
	date?: string;
	partialRendering?: boolean;
	isSundayFirstDay?: boolean;
	dndStepLength?: number;
	hourSize?: {
		vertical?: number;
		horizontal?: number;
	};
	addNewSlotButton?: {
		hoverDelay?: string;
		duration?: number;
		step?: number;
	};
	scrollTo?: {
		startTime?: string;
		endTime?: string;
		resourceId?: string | number;
		trigger?: number;
	};
	monthlySpecificMonth?: boolean;
	isLoading?: boolean;
}

export interface ICalendarProps {
	spaces: ISpace[];
	widthChangeTrigger: boolean;
	slots: ISlotsEventsAndEventsWithSpaces;
	EventComponent: FC<IEventComponentProps>;
	DragOverlayComponent: FC<IDragOverlayComponentProps>;
	ResourceGroupHeader: FC<ISpaceProps>;
	onChange: TOnChangeInCalender;
	options: IOptions;
	resourceId?: string | number;
}

export type TOnChangeInCalender = <T>(data: T) => boolean | void;

export interface IEventComponentProps {
	event: ISlot;
}

export interface IDragOverlayComponentProps {
	event: ISlot;
	hours?: string;
}

export interface ISlotsEventsAndEventsWithSpaces {
	eventsToSpaces: ISlotEventsToSpaces;
	events: ISlotsEvents;
}

export interface ISlotsEvents {
	[eventId: number]: ISlot;
}

export interface ISlotEventsToSpaces {
	[eventId: number]: ISlot[];
}

export interface IDndProviderProps {
	children: ReactNode;
	DragOverlayComponent: FC<IDragOverlayComponentProps>;
	onChange?: TOnChangeInCalender;
	horizontal?: boolean;
}

export interface IDayToDropProps {
	id: string;
	EventComponent: FC<IEventComponentProps>;
	withoutChildren?: boolean;
	hiddenEvents?: boolean;
	onAddNewSlotClick?: TOnChangeInCalender;
	isModeChanged: boolean;
}

export interface IWeekToDropProps {
	spaceId: string;
	EventComponent: FC<IEventComponentProps>;
	isLoading?: boolean;
}

export interface ISpaceProps {
	state: ISpace;
	view?: any;
}

export interface IDraggableProps {
	item: ISlot | undefined;
	delta?: number;
	Component: FC<IDragOverlayComponentProps>;
}

export interface IDroppableProps {
	id: string;
	dragging?: boolean;
	weekly?: boolean;
	height?: number | string;
	maxWidth?: number | string;
}

export interface IDraggableDayToDropProps {
	id: number;
	state: ISlot;
	parentId: number | string;
	groupTop?: number;
	groupCount?: number;
	groupIndex?: number;
	isEditable?: boolean;
	isConnectedMaintenance?: boolean;
}

export interface ClientXAndClientY {
	clientX: number;
	clientY: number;
}

export interface IDailyViewProps {
	spaces: ISpace[];
	EventComponent: FC<IEventComponentProps>;
	onAddNewSlotClick?: TOnChangeInCalender;
	isModeChanged: boolean;
	hourSize?: number;
}

export interface IEventsGroup {
	items: ISlot[];
	item?: ISlot;
	groups?: ISlot[][];
	height?: number;
	top?: number;
}

export interface IPartialRange {
	from: number;
	to: number;
}

export interface IPartialRanges {
	vertical: IPartialRange;
	horizontal: IPartialRange;
}

export type ElementHTML = HTMLElement | null | undefined;

export interface IAsideContainerProps {
	width: number;
	bodyContainerHeight: number;
	verticalSpacesRef: RefObject<HTMLDivElement>;
	spaces: ISpace[];
	ResourceGroupHeader: FC<ISpaceProps>;
}

export interface IHeaderContainerProps {
	width: number;
	headerRef: RefObject<HTMLDivElement>;
	spaces: ISpace[];
	ResourceGroupHeader: FC<ISpaceProps>;
	resourceId?: string | number;
}

export interface ITimeLineProps {
	width: number;
}

export interface IAddNewSlotButtonsContainerProps {
	onClick?: TOnChangeInCalender;
	horizontal?: boolean;
	parentId: string;
}

export interface IAddNewSlotButtonProps {
	index: number;
	parentId: string;
	size: number;
	duration: number;
	horizontal?: boolean;
	onClick?: TOnChangeInCalender;
}

export interface IPlusButtonData {
	customLabel?: string;
	height: number;
	top: number;
	bottom: number;
	isEvent?: boolean;
	isGroup?: true;
}

export interface IHourBoxProps {
	date: dayjs.Dayjs;
	width: number;
}

export interface IMonthlyHeaderContainerProps {
	width: number;
	headerRef: RefObject<HTMLDivElement>;
	space: ISpace;
	ResourceGroupHeader: FC<ISpaceProps>;
}

export interface IWeekBoxProps {
	weekNumber: number;
	width: number;
	horizontal?: boolean;
}

export interface IWeeklyDraggableProps {
	id: number;
	state: ISlot;
	parentId: number | string;
}

export interface IWeeklyViewProps {
	spaces: ISpace[];
	EventComponent: FC<IEventComponentProps>;
	isLoading?: boolean;
}

export interface IUsePartialRenderProps {
	ref?: RefObject<HTMLDivElement>;
	elementSize: number;
	direction?: 'vertical' | 'horizontal';
	disable?: boolean;
}

export interface IResizeOptions {
	step?: number;
	axis?: 'vertical' | 'horizontal' | 'both';
}
