import React from "react";

export const Instagram = (props: any) => (
  <svg
    data-aid="SvgInstagram"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 5.03125C6.3604 5.03125 5.03125 6.3604 5.03125 8V16C5.03125 17.6396 6.3604 18.9688 8 18.9688H16C17.6396 18.9688 18.9688 17.6396 18.9688 16V8C18.9688 6.3604 17.6396 5.03125 16 5.03125H8ZM2.96875 8C2.96875 5.22132 5.22132 2.96875 8 2.96875H16C18.7787 2.96875 21.0312 5.22132 21.0312 8V16C21.0312 18.7787 18.7787 21.0312 16 21.0312H8C5.22132 21.0312 2.96875 18.7787 2.96875 16V8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10.0312C10.9127 10.0312 10.0312 10.9127 10.0312 12C10.0312 13.0873 10.9127 13.9687 12 13.9687C13.0873 13.9687 13.9687 13.0873 13.9687 12C13.9687 10.9127 13.0873 10.0312 12 10.0312ZM7.96874 12C7.96874 9.77359 9.77359 7.96874 12 7.96874C14.2264 7.96874 16.0312 9.77359 16.0312 12C16.0312 14.2264 14.2264 16.0312 12 16.0312C9.77359 16.0312 7.96874 14.2264 7.96874 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 6.46874C17.0695 6.46874 17.5312 6.93045 17.5312 7.49999C17.5312 8.06953 17.0695 8.53199 16.5 8.53199C15.9304 8.53199 15.4687 8.07028 15.4687 7.50074C15.4687 6.9312 15.9304 6.46874 16.5 6.46874Z"
      fill="currentColor"
    />
  </svg>
);
