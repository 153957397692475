import _ from 'lodash';
import React, { Fragment, ReactNode } from 'react';
import { Field as FinalField, useForm } from 'react-final-form';
import { Select, IOption, SelectRadix, ISelectOptions } from '@bondsports/utils';

export interface IFormProps {
	name: string;
	onClear?: (val: string) => void;
	required?: boolean;
	onSelecting: (val1: string, val2: string | number | (string | number)[]) => void;
	options?: IOption[];
	placeholder?: string;
	value?: string | number;
	onSelecte?: (val: string) => void;
	sizer?: 'M' | 'S';
	disabled?: boolean;
	isMultiple?: boolean;
	noLabel?: boolean;
	label?: string;
	labelAddition?: ReactNode;
	info?: string;
	error?: string;
	helper?: string;
	helperContent?: string;
	clear?: () => void;
	onChange?: (v: unknown) => void;
	hideError?: boolean;
	unsetOptionsWidth?: boolean;
}

export type TCustomerKeyProp = {
	CustomSelectOptionsComponent?: ({
		options,
		onSelect,
		value,
		autoFocus,
		isMultiple,
		enableSelectAll,
		selectAllLabel,
		handleSelectAll,
		handleRemoveAll,
	}: ISelectOptions) => ReactNode;
};

// TODO - newSelect is for the preview of the new select component. Needs to be swapped completely once ready
export const FormSelect = ({
	...props
}: IFormProps & React.SelectHTMLAttributes<HTMLSelectElement> & TCustomerKeyProp & { newSelect?: boolean }) => {
	const form = useForm();

	const onSelect = (val: string) => {
		props.onSelecting(props.name, val);
	};

	return (
		<FinalField
			name={props.name}
			subscription={{ touched: true }}
			validate={val => {
				let res = undefined;
				if (props.required) {
					if (val == null) {
						const errors = {};
						errors[props.name] = 'required';
						res = errors;
					}
				}
				return res;
			}}
			displayEmpty
		>
			{({ input }) => {
				const metadata = form.getFieldState(props.name);
				const value = _.get(form.getState().values, props.name);

				const newProps = { ...props };
				const touched = metadata?.touched ?? false;
				const err = metadata?.error?.[props?.name] ? metadata.error[props.name] : null;

				let clear;

				if (newProps.onClear) {
					clear = () => props.onClear(props.name);
					delete newProps.onClear;
				}

				return (
					<Fragment>
						{!props.newSelect && (
							<Select
								{...input}
								{...(newProps as any)}
								error={err}
								clear={clear}
								value={value != null ? value : input.value}
								onSelecte={onSelect}
							/>
						)}

						{props.newSelect && (
							<SelectRadix
								isTouched={touched}
								onBlur={input?.onBlur}
								options={newProps.options}
								size={newProps?.sizer?.toLowerCase() ?? ('s' as any)}
								onSelect={onSelect}
								value={value ? String(value) : undefined}
								label={newProps.label}
								labelAddition={newProps.labelAddition}
								controlLabel="form-control-label"
								errorMessage={err}
								required={newProps.required}
							/>
						)}
					</Fragment>
				);
			}}
		</FinalField>
	);
};
