import { useEffect, useMemo, useRef, useState } from 'react';

/**
 * Represents the options for fetching data.
 *
 * @template T - The type of the data returned from the fetch.
 * @property onSuccess - A callback that is called on every successful api response with its data
 * @property onComplete - A callback that is called every time a request finished executing
 * @property onAbort - A callback that is called when the request is aborted
 * @property onTimeout - A callback that is called when a timeout was reached
 * @property onError - A callback that is called when ever a request resulted in an error (error response/any other error)
 * @property timeout - setting a timeout limit on the request
 */
type TFetchOptions<T> = {
	onError?: (err) => void;
	onSuccess?: (data: T) => void;
	onComplete?: () => void;
	onAbort?: () => void;
	onTimeOut?: () => void;
	timeout?: number;
};

/**
 * Represents a utility function for fetching data of a certain type
 * @template T - The type of data being received
 * @property data - The fetched data
 * @property isLoading - Indicator that a fetch request is in progress
 * @property isFirstFetch - Indicates if this is the first fetch request
 * @property refetch - Function to re-initiate the fetch request
 * @property abort - Function to abort the fetch request
 * @property fetch - Function to execute a fetch request
 */
type TFetch<T> = {
	data: T;
	isLoading: boolean;
	isFirstFetch: boolean;
	refetch: () => void;
	abort: () => void;
	fetch?: () => void;
};

/**
 * Custom hook for handling data fetching with options and lifecycle callbacks. Inspired by react-query useQuery hook.
 *
 * @template T - The type of the data being fetched
 * @param {function} fetcher - The function that performs the actual fetching, accepting options containing an AbortSignal
 * @param {Array} deps - The dependencies for the hook, used to prevent unnecessary fetches
 * @param {Object} [options] - Optional options for the fetch, including timeout and callbacks
 * @returns {Object} - An object containing data, loading state, fetch and abort functions, and first fetch status
 */
export const useFetch = <T,>(
	fetcher: (options: { signal: AbortSignal }) => Promise<T>,
	deps: any[],
	options?: TFetchOptions<T>
): TFetch<T> => {
	const [data, setData] = useState<T>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFirstFetch, setIsFirstFetch] = useState(true);
	const controller = useRef<AbortController>();

	const { fetch, abort } = useMemo(() => {
		let timeoutId: number;

		return {
			abort: () => controller.current?.abort(),
			fetch: async () => {
				try {
					controller.current = new AbortController();

					setIsLoading(true);

					if (options?.timeout) {
						timeoutId = window.setTimeout(() => {
							setIsFirstFetch(false);
							setIsLoading(false);

							controller.current.abort();
							options?.onTimeOut?.();
						}, options.timeout);
					}

					const response: T = await fetcher({ signal: controller.current.signal });

					if (!response) {
						return;
					}

					if ((response as any).err) {
						throw response;
					}

					setData(response);
					options?.onSuccess?.(response);
				} catch (error) {
					if (error.name === 'AbortError') {
						options?.onAbort?.();
						return;
					}

					options?.onError?.(error);
				} finally {
					if (timeoutId) {
						clearTimeout(timeoutId);
					}
					setIsLoading(false);
					options?.onComplete?.();
				}
			},
		};
	}, deps);

	useEffect(() => {
		fetch().finally(() => setIsFirstFetch(false));
		return () => abort();
	}, [fetch]);

	return {
		data,
		isLoading,
		refetch: () => {
			controller.current?.abort();
			setIsFirstFetch(true);
			fetch().finally(() => setIsFirstFetch(false));
		},
		abort,
		isFirstFetch,
	};
};
