/** @jsx jsx */

import React, { useState } from 'react';
import { jsx, css } from '@emotion/react';
import { flexCol, svgIcon, flexRowCss } from '../../../styles/utils';
import { colors_v2 } from '../../../styles/theme';
import { TranslationEn } from 'assets/i18n/en';
import { Icons, Button, Typography, Textarea, BnIcon, ETypographyColor, ETypography } from '@bondsports/utils';
import { EDocumentType, IRawNote } from '../../../types/notes';

const savedNoteContainer = css`
	margin-bottom: 20px;
	break-inside: avoid !important;
	hr {
		margin-bottom: 20px !important;
		border-color: ${colors_v2.bg_border_primary};
	}
`;

const notesHeaderCss = css`
	display: flex;
	justify-content: space-between;
	gap: 0 6px;
	div {
		padding: 2px;
	}
	button {
		padding: 0px;
	}
	svg {
		${svgIcon(16)}
	}
`;

const noteContentCss = css`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 0px 24px;
	div:first-of-type {
		margin-right: 4px;
	}
`;
const noteDetails = css`
	margin: 0px;
	.S {
		margin-top: 0 !important;
	}
`;

const iconsButtonsContainer = css`
	${flexRowCss};
	gap: 2px;
	button {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
`;

const noteHeaderLeftGroup = css`
	${flexRowCss};
	gap: 4px;
	div:last-of-type {
		margin-left: 8px;
	}
	svg {
		color: ${colors_v2.tx_text_primary};
	}
`;

const createdTitleCss = css`
	display: flex;
	align-items: center;
	margin-bottom: 12px;
`;

const ActiveTextArea = css`
	${flexCol};
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: ${colors_v2.bg_text_secondary};
`;

interface Props {
	onSaveEdit: (note: IRawNote, newContent: string, documentType: EDocumentType, invoiceId: number) => void;
	note: IRawNote;
	invoiceId: number;
	documentType: EDocumentType;
	isPrinting?: boolean;
	toggleDeleteModal?: () => void;
	setNoteForDelete?: (noteId: number, documentType: EDocumentType, invoiceId: number) => void;
}

export const SavedNote = ({
	onSaveEdit,
	note: noteData,
	invoiceId,
	documentType,
	isPrinting = false,
	toggleDeleteModal,
	setNoteForDelete,
}: Props) => {
	const [isEditingNote, setIsEditingNote] = useState<boolean>(false);
	const [noteContent, setNoteContent] = useState<string>(noteData.content);

	const labels = TranslationEn.customers.paymentsInvoices.invoiceNotes;

	const editNoteContentHandler = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
		setNoteContent(ev.target.value);
	};

	const enableEdit = () => {
		setIsEditingNote(true);
	};

	const deleteClickHandler = async () => {
		setNoteForDelete(noteData.id, documentType, invoiceId);
		toggleDeleteModal();
	};

	const saveEditedNote = () => {
		onSaveEdit(noteData, noteContent, documentType, invoiceId);
		setIsEditingNote(false);
	};

	const cancelEdit = () => {
		setNoteContent(noteData.content);
		setIsEditingNote(false);
	};

	return (
		<div data-aid="SavedNote" css={savedNoteContainer}>
			{!isPrinting && <hr />}
			<div data-aid="SavedNote-header" css={notesHeaderCss}>
				<div css={noteHeaderLeftGroup}>
					<BnIcon icon={Icons.note_filled} />
					<Typography color={ETypographyColor.secondary} type={ETypography.captionAccented}>
						{TranslationEn.components.note}
					</Typography>
					<Typography color={ETypographyColor.teriaty} type={ETypography.caption}>
						{noteData.updatedAt}
					</Typography>
				</div>
				{!isPrinting &&
					(!isEditingNote ? (
						<div data-aid="SavedNote-cons" css={iconsButtonsContainer}>
							<Button data-aid="button-SavedNote-edit" sizer="XS" theme="basic" onClick={enableEdit}>
								<BnIcon icon={Icons.edit_outline} />
							</Button>

							<Button data-aid="button-SavedNote-delete" sizer="XS" theme="basic" onClick={deleteClickHandler}>
								<BnIcon icon={Icons.delete_outline} />
							</Button>
						</div>
					) : (
						<div css={flexRowCss}>
							<Button data-aid="button-SavedNote-cancel" sizer="XS" theme="basic" onClick={cancelEdit}>
								{TranslationEn.buttons.cancel}
							</Button>
							<Button data-aid="button-SavedNote-save" sizer="XS" theme="secondary" onClick={saveEditedNote}>
								{TranslationEn.buttons.save}
							</Button>
						</div>
					))}
			</div>
			<div data-aid="SavedNote-details" css={noteDetails}>
				{!isEditingNote ? (
					<div css={noteContentCss}>
						<div data-aid="SavedNote-created" css={createdTitleCss}>
							<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
								{labels.createdBy}
							</Typography>
							<Typography color={ETypographyColor.primary} type={ETypography.caption}>
								{noteData.createdBy}
							</Typography>
						</div>
						<Typography data-aid="SavedNote-content" color={ETypographyColor.primary} type={ETypography.body2}>
							{noteData.content}
						</Typography>
					</div>
				) : (
					<Textarea
						sizer="M"
						charCounter
						charLimit={120}
						css={ActiveTextArea}
						value={noteContent}
						onChange={editNoteContentHandler}
					/>
				)}
			</div>
		</div>
	);
};
