import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AdminAuthService } from '@app/shared/services/auth/admin-auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class AdminGuard implements CanActivate {
	constructor(private router: Router, private auth: AdminAuthService) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (this.auth.isLoggedIn()) {
			return true;
		} else {
			this.router.navigate(['/a-login']);
			return false;
		}
	}
}
