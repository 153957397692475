import { ENotificationType } from '@bondsports/utils';
import { useState } from 'react';

import { useNotification } from '../useNotification';
import { useToggle } from '../useToggle';
import { useErrorModal } from '../useErrorModal';

import { IMembershipRow } from '../../types/membership';
import { membershipApi } from '../../lib/api/membershipApi';
import { TranslationEn } from '@assets/i18n/en';

interface ICancelParams {
	isImmediate: boolean;
	organizationId: number;
	callback: () => unknown;
	fetchCustomer?: (organizationId: number, userId: number) => unknown;
}

export const useCancelMembership = () => {
	const labels = TranslationEn.memberships.members.cancelMembership;
	const [isProcessing, setIsProcessing] = useState(false);
	const [cancelledUser, setCancelledUser] = useState<IMembershipRow>();
	const [cancellationReason, setCancellationReason] = useState('');
	const { setPopupNotification } = useNotification();
	const [isCancelMembershipShowing, cancelToggle] = useToggle();
	const { setErrorModal } = useErrorModal();

	const handleCancelIconClick = (member: IMembershipRow) => {
		setCancelledUser(member);
		cancelToggle();
	};

	const handleCancel = async ({ isImmediate, organizationId, callback, fetchCustomer = null }: ICancelParams) => {
		if (cancelledUser) {
			setIsProcessing(true);
			fetchCustomer && fetchCustomer(organizationId, cancelledUser?.userId);
			const res = await membershipApi.cancelMembership(
				organizationId,
				cancelledUser.memberId,
				cancelledUser.userId,
				isImmediate,
				cancellationReason
			);
			setIsProcessing(false);
			cancelToggle();
			if (res.err || !res.data) {
				setErrorModal({ message: String(res.err ?? res) });
				setPopupNotification(labels.failedToCancel, ENotificationType.warning);
			}
			if (res.data) {
				await callback();
				setPopupNotification(labels.membershipCanceled, ENotificationType.action_confirmed);
			}
		}
	};

	return {
		isCancelMembershipShowing,
		isProcessing,
		cancelledUser,
		cancellationReason,
		setCancellationReason,
		cancelToggle,
		handleCancelIconClick,
		handleCancel,
		setCancelledUser,
	};
};
