/** @jsxRuntime classic*/
/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/react';
import React, { FC, CSSProperties } from 'react';
import { rowLayoutCss } from '../styles';

export interface RowProps {
	gap?: string;
	align?: CSSProperties['alignItems'];
	justify?: CSSProperties['justifyContent'];
	fullWidth?: boolean;
	styles?: SerializedStyles;
	children: React.ReactNode | React.ReactNode[];
}

export const Row: FC<RowProps> = ({ gap = '8px', align = 'center', justify, fullWidth = false, styles, children }) => {
	return (
		<div className="row-primitive" css={rowLayoutCss(gap, align, fullWidth, justify, styles)} data-aid="row-components">
			{children}
		</div>
	);
};
