import React from 'react';

export const Food = (props: any) => (
  <svg
    data-aid="svgFood"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.125 21.4142V13.6988C14.7894 13.4266 13.7812 12.2432 13.7812 10.8285V6.41739C13.7812 4.57777 14.8032 2.88506 16.4488 2.06226C16.6296 1.97183 16.8459 1.97984 17.0187 2.088C17.1915 2.19499 17.2968 2.38327 17.2968 2.58639V21.4142C17.2968 21.7381 17.0348 22.0002 16.7109 22.0002C16.387 22.0002 16.125 21.7381 16.125 21.4142ZM9.09371 21.4142V10.1833C7.75815 9.91103 6.75 8.72761 6.75 7.31291V2.58642C6.75 2.26255 7.01207 2.00049 7.33593 2.00049C7.65979 2.00049 7.92186 2.26255 7.92186 2.58642V6.72698H9.09371V2.58642C9.09371 2.26255 9.35578 2.00049 9.67964 2.00049C10.0035 2.00049 10.2656 2.26255 10.2656 2.58642V6.72698H11.4374V2.58642C11.4374 2.26255 11.6995 2.00049 12.0234 2.00049C12.3472 2.00049 12.6093 2.26255 12.6093 2.58642V7.31291C12.6093 8.72757 11.6011 9.91103 10.2656 10.1833V21.4142C10.2656 21.7381 10.0035 22.0002 9.67964 22.0002C9.35578 22.0002 9.09371 21.7381 9.09371 21.4142Z"
      fill="currentColor"
    />
  </svg>
);
