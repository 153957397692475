/** @jsx jsx */

import { MutableRefObject, useRef } from 'react';
import { jsx, css } from '@emotion/react';
import { Tab } from './Tab';
import { colors } from '../../../styles/theme';
import { EInvoicingTabs } from '@bondsports/utils';

interface ISingleTab {
	title: string;
	link: string;
	disabled?: boolean;
	isActive?: boolean;
	onAction?: (tab: EInvoicingTabs) => void;
}

interface ITabsProps {
	id?: string;
	tabs: ISingleTab[];
	disabled?: boolean;
	tabRefs?: MutableRefObject<MutableRefObject<HTMLAnchorElement>[]>;
}

const TabsCss = css`
	display: flex;
	margin-bottom: -1rem;
	margin-top: 1rem;
	.link {
		padding: 0.7rem 1rem;
		margin-right: 1rem;
		font-family: Montserrat;
		font-style: normal;
		font-weight: 500;
		font-size: 1.4rem;
		line-height: 17px;
		text-align: center;
		color: ${colors.formInputBg};
		border-bottom: 3px solid transparent;
		&:hover {
			color: ${colors.brandPrimary};
		}
	}
	.active {
		color: ${colors.brandPrimary};
		border-bottom: 3px solid ${colors.brandPrimary};
		padding: 0.7rem 1rem;
		margin-right: 1rem;
		border-radius: 2px;
	}
`;

export const Tabs = ({ tabs, tabRefs }: ITabsProps) => {
	return (
		<div css={TabsCss}>
			{tabs.map((tab, index) => {
				const ref = useRef<HTMLAnchorElement>(null);
				if (tabRefs) {
					tabRefs.current[index] = ref;
				}

				return (
					<Tab
						key={index}
						title={tab.title}
						link={`${tab.link}`}
						disabled={tab.disabled}
						onAction={tab.onAction}
						isActive={tab.isActive}
						ref={ref}
					/>
				);
			})}
		</div>
	);
};
