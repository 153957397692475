import React from "react";
import { Checkbox } from "../../components/Checkbox";

interface IDay {
  handleChange: (val: string) => void;
  value: string;
  isChecked: boolean;
  isTinyView: boolean;
}
export const DayCheck = ({
  handleChange,
  value,
  isChecked,
  isTinyView,
}: IDay) => {
  //   const isMobile = useMediaQuery(mobileOnly);
  return (
    <div style={{ marginRight: "12px" }}>
      <Checkbox
        label={false && isTinyView ? value[0] : value}
        width={isTinyView ? 17 : 20}
        checked={isChecked}
        setChecked={() => {
          handleChange(value);
        }}
      />
    </div>
  );
};
