/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { ETypography, ETypographyColor, IColors } from '../../types';
import { useColors } from '../../hooks/useColors';
import { Typography } from '../../atoms';

const additionalInfoRowCss = (colors: IColors) => css`
	padding: 20px;
	background: ${colors.bg_white};
	border-bottom: ${`1px solid ${colors.bg_border_primary}`};
	div {
		display: flex;
		justify-content: center;
	}
`;

export const AdditionalInfoRow = ({ numOfColumns, text }: { numOfColumns: number; text: string }) => {
	const { colors } = useColors();
	return (
		<tr key={-1} tabIndex={-1}>
			<td colSpan={5} css={additionalInfoRowCss(colors)}>
				<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
					{text}
				</Typography>
			</td>
		</tr>
	);
};
