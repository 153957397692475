/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { colors } from 'app/react/styles/theme';
import { BnIcon, ModalWindow, useModal } from '@bondsports/utils';
import React, { useEffect, useState } from 'react';
import DateAndTime from './DateAndTime';
import Header from './Header';
import Purchased from './Purchased';
import { Icons } from 'app/react/styles/theme';
import { RecoilRoot } from 'recoil';
import { BnProvider } from '@bondsports/utils';

const rowCss = css`
	margin-bottom: 34px;
	display: flex;
`;
const basicIconCss = css`
	color: ${colors.formInputBg};
`;

const textCss = css`
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 1.4rem;
	line-height: 130%;
	color: ${colors.brandPrimary};
	margin-left: 10px;
`;

const container = css`
	width: 100%;
	font-size: 2rem;
	font-family: 'Montserrat';
	color: ${colors.brandPrimary};
	font-weight: bold;
	padding: 4rem;
`;

const modalCss = css`
	width: 596px;
	height: 616px;
`;

interface EventRegistrationModalProps {
	seasonId: number;
	organizationId: number;
	eventId: number;
	startDate: string | Date;
	endDate: string | Date;
	spaceName: string;
	eventName: string;
	seasonName: string;
	programName: string;
	punchCardSeason?: boolean;
	//  send true to refresh data on event table, false just closes
	close?: (refresh: boolean) => void;
}

const EventRegistrationModal = ({
	seasonId,
	organizationId,
	eventId,
	close,
	startDate,
	endDate,
	spaceName,
	eventName,
	seasonName,
	programName,
	punchCardSeason,
}: EventRegistrationModalProps) => {
	const { isShowing, toggle } = useModal();
	const [refreshOnClose, setRefreshOnClose] = useState<boolean>(false);

	const handleOnClose = () => {
		toggle();
		close(refreshOnClose);
	};

	useEffect(() => {
		toggle();
	}, []);

	return (
		<RecoilRoot>
			<BnProvider>
				<ModalWindow toggle={handleOnClose} isShowing={isShowing} padding={0}>
					<div css={modalCss}>
						<Header
							handleOnClose={handleOnClose}
							eventName={eventName}
							programName={programName}
							seasonName={seasonName}
						/>

						<div css={container}>
							<DateAndTime startDate={startDate} endDate={endDate} />

							{/* Space name */}
							<div css={rowCss}>
								<div css={basicIconCss}>
									<BnIcon icon={Icons.location_filled} />
								</div>
								<div css={textCss}>{spaceName}</div>
							</div>

							<Purchased
								seasonId={seasonId}
								organizationId={organizationId}
								eventId={eventId}
								punchCardSeason={punchCardSeason}
								setRefreshOnClose={setRefreshOnClose}
							/>
						</div>
					</div>
				</ModalWindow>
			</BnProvider>
		</RecoilRoot>
	);
};

export default EventRegistrationModal;
