import { useState, useEffect, useRef, useCallback } from 'react';

export interface IMeta {
	totalItems: number;
	itemsPerPage: number;
	totalPages: number;
	currentPage: number;
}

type TFetchOptions<T> = {
	onError?: (err: any) => void;
	onSuccess?: (data: FetchDataResponse<T>) => void;
	onComplete?: () => void;
	onAbort?: () => void;
	onTimeOut?: () => void;
	timeout?: number;
};

export interface FetchDataResponse<T> {
	items: T[];
	meta: IMeta;
}

export interface FetchDataCallback<T, F> {
	(params: { page: number; itemsPerPage: number; filters?: F; signal?: AbortSignal }): Promise<FetchDataResponse<T>>;
}

const ABORT_ERROR = 'AbortError';

export const useInfiniteScroll = <T extends unknown, F>({
	fetchCallback,
	scrollElementRef,
	initialItemsPerPage,
	fetchOptions,
}: {
	fetchCallback: FetchDataCallback<T, F>;
	scrollElementRef: React.RefObject<HTMLElement>;
	initialItemsPerPage: number;
	fetchOptions?: TFetchOptions<T>;
}) => {
	const [items, setItems] = useState<T[]>([]);
	const [meta, setMeta] = useState<IMeta>({
		totalItems: 0,
		itemsPerPage: initialItemsPerPage,
		totalPages: 0,
		currentPage: 0,
	});

	const [hasMore, setHasMore] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const controller = useRef<AbortController>();

	const refetch = async (newFilters?: F) => {
		setItems([]);
		setMeta({
			totalItems: 0,
			itemsPerPage: initialItemsPerPage,
			totalPages: 0,
			currentPage: 0,
		});
		setHasMore(true);
		await loadItems(false, newFilters);
	};
	const loadItems = async (append = true, filters?: F) => {
		if (isLoading || !hasMore) return;

		let timeoutId: number | undefined;
		controller.current?.abort();
		controller.current = new AbortController();

		setIsLoading(true);

		try {
			if (fetchOptions?.timeout) {
				timeoutId = window.setTimeout(() => {
					controller.current?.abort();
					setIsLoading(false);
					setHasMore(false);
					fetchOptions?.onTimeOut?.();
				}, fetchOptions?.timeout);
			}

			const nextPage = append ? meta.currentPage + 1 : 1;
			const response = await fetchCallback({
				page: nextPage,
				itemsPerPage: meta.itemsPerPage,
				signal: controller.current.signal,
				filters,
			});

			if (!response) {
				return;
			}

			if ((response as any).err) {
				throw response;
			}

			fetchOptions?.onSuccess?.(response);
			setMeta({ ...response.meta, currentPage: nextPage });
			setHasMore(nextPage < response.meta.totalPages);
			setItems(append ? [...items, ...response.items] : response.items);
		} catch (error) {
			if (error.name === ABORT_ERROR) {
				fetchOptions?.onAbort?.();
				return;
			}
			setHasMore(false);
			fetchOptions?.onError?.(error);
		} finally {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			setIsLoading(false);
			fetchOptions?.onComplete?.();
		}
	};

	const checkAndLoadMoreItems = useCallback(async () => {
		const element = scrollElementRef.current;
		if (element && hasMore && !isLoading) {
			const nearBottom = element.scrollHeight - element.scrollTop <= element.clientHeight + 100;
			const needsMoreItems = element.clientHeight >= element.scrollHeight;

			if (nearBottom || needsMoreItems) {
				await loadItems();
			}
		}
	}, [hasMore, isLoading, loadItems, scrollElementRef]);

	useEffect(() => {
		loadItems(false);
		return () => {
			controller.current?.abort(); // Cancel any ongoing fetch requests
		};
	}, []);

	useEffect(() => {
		checkAndLoadMoreItems();

		const element = scrollElementRef.current;
		if (element) {
			element.addEventListener('scroll', checkAndLoadMoreItems);
			return () => element.removeEventListener('scroll', checkAndLoadMoreItems);
		}

		return () => controller.current?.abort();
	}, [checkAndLoadMoreItems]);

	return { items, refetch, meta, hasMore, isLoading };
};
