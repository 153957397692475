import React, { ComponentType } from 'react';
import { InfiniteList, InfiniteListProps } from '../list';
import { InfiniteGrid, InfiniteGridProps } from '../grid';

export interface VirtualizedListProps<T> {
	component: ComponentType<T>;
	data: T[];
	defaultRowHeight: number;
	defaultColumnWidth?: number;
	isGrid?: boolean;
}

export function VirtualizedList<T>({ isGrid = false, ...props }: VirtualizedListProps<T>) {
	switch (isGrid) {
		case true:
			return <InfiniteGrid {...(props as InfiniteGridProps<T>)} />;
		case false:
		default:
			return <InfiniteList {...(props as InfiniteListProps<T>)} />;
	}
}
