/** @jsx jsx */
import React, { Fragment, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { setOpacity } from 'app/react/styles/utils';
import { colors } from 'app/react/styles/theme';
import { Props, OptionTypeBase } from 'react-select';
import { environment } from 'environments/environment';
import { TranslationEn } from 'assets/i18n/en';

const googleAddressCss = css`
	.MuiFilledInput-input {
		height: 40px;
	}
	.css-yk16xz-control {
		background-color: ${colors.background_fields};
		border: unset;
		border-radius: 0px;
	}
	.css-1pahdxg-control {
		background-color: ${colors.background_fields};
		border: unset;
		border-radius: 0px;
	}

	.css-tlfecz-indicatorContainer {
		background-color: ${setOpacity(colors.background_fields || '', 0.37)};
		color: ${colors.white};
	}
	[class*='indicatorSeparator'] {
		display: none;
	}
	.css-26l3qy-menu {
		z-index: 999;
	}

	[class*='IndicatorsContainer'] {
		display: none;
	}
	.css-1uccc91-singleValue {
		color: ${colors.brandPrimary};
	}
	.css-1wa3eu0-placeholder {
		color: ${colors.lightText};
	}
	.css-yk16xz-control:active {
		border: 1px solid ${colors.borderPrimary};
	}
	.css-1wy0on6:focus {
		outline: 0 !important;
	}
`;

const GoogleAddress = (props: Props<OptionTypeBase>) => {
	return (
		<div css={googleAddressCss}>
			<GooglePlacesAutocomplete
				apiKey={environment.GOOGLE_API_KEY}
				selectProps={{
					isClearable: true,
					placeholder: TranslationEn.googleAddress.placeholder,
					...props,
					components: { DropdownIndicator: () => null, IndicatorSeparator: () => null },
				}}
			/>
		</div>
	);
};

export default GoogleAddress;
