import React from 'react';

export const Activity = (props: any) => (
  <svg
    data-aid="svgActivity"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9999 3H12.9999V7H13.9999V3ZM7.99996 14.3333C7.99996 13.8307 8.05884 13.335 8.17384 12.8546L1.55996 10.65C1.42663 10.6033 1.33329 10.4767 1.33329 10.3333V8.33333C1.33329 8.15 1.4833 8 1.66663 8H14.3333C17.4533 8 20.0522 10.2654 20.572 13.2384C20.7878 13.0881 21.0502 13 21.3333 13C22.07 13 22.6666 13.5967 22.6666 14.3333C22.6666 15.07 22.07 15.6667 21.3333 15.6667C21.0502 15.6667 20.7878 15.5786 20.572 15.4283C20.0522 18.4012 17.4533 20.6667 14.3333 20.6667C10.84 20.6667 7.99996 17.8267 7.99996 14.3333ZM21.4 13.8C21.125 13.8 20.9 14.025 20.9 14.3C20.9 14.575 21.125 14.8 21.4 14.8C21.675 14.8 21.9 14.575 21.9 14.3C21.9 14.025 21.675 13.8 21.4 13.8ZM8.7135 4.45728L7.87483 5.00191L9.2369 7.09931L10.0756 6.55468L8.7135 4.45728ZM18.971 5.04464L18.1323 4.5L16.7707 6.59668L17.6094 7.14132L18.971 5.04464ZM19.2999 14.3C19.2999 11.5727 17.0272 9.29999 14.2999 9.29999H13.8454H12.4817V10.3606H14.1484C16.3212 10.3606 18.0878 12.1273 18.0878 14.3C18.0878 16.6783 16.2999 18.3 14.1484 18.2394C11.9757 18.2394 10.2999 16.3 10.2999 14.3H9.29993C9.29993 16.806 11.2999 19.3 14.1484 19.3C16.6545 19.3 19.2999 17.4818 19.2999 14.3Z"
      fill="currentColor"
    />
  </svg>
);
