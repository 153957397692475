/** @jsx jsx */
import { flex } from 'app/react/styles/utils';
import { css, jsx } from '@emotion/react';
import { getDateString, getFixedDayName, time24ToAmPm } from 'app/react/lib/dates';
import { colors } from 'app/react/styles/theme';
import { TranslationEn } from 'assets/i18n/en';
import { BnIcon } from '@bondsports/utils';
import { Icons } from 'app/react/styles/theme';

const basicIconCss = css`
	color: ${colors.formInputBg};
`;

const timeTitleCss = css`
	color: ${colors.formInputBg};
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
`;

const timeCss = css`
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 1.4rem;
	line-height: 130%;
	color: ${colors.brandPrimary};
`;

const rowCss = css`
	margin-bottom: 20px;
	display: flex;
`;

const timeContainerCss = css`
	${flex};
	margin-left: 17px;
`;

interface Props {
	startDate: string | Date;
	endDate: string | Date;
}

const DateAndTime: React.FC<Props> = ({ startDate, endDate }) => {
	const startTime = time24ToAmPm(new Date(startDate).getUTCHours(), new Date(startDate).getUTCMinutes());
	const end = time24ToAmPm(new Date(endDate).getUTCHours(), new Date(endDate).getUTCMinutes());
	const start = `${getFixedDayName(new Date(startDate).getDay() + 1)}, ${getDateString(new Date(startDate))} 
  \u00A0 ${startTime}\u00A0\u00A0-\u00A0\u00A0 `;

	return (
		<div css={rowCss}>
			<div css={basicIconCss}>
				<BnIcon icon={Icons.clock} />
			</div>
			<div css={timeContainerCss}>
				<div>
					<div css={timeTitleCss}>{TranslationEn.eventRegistrationModal.start}</div>
					<div css={timeCss}>{start}</div>
				</div>

				<div>
					<div css={timeTitleCss}>{TranslationEn.eventRegistrationModal.end}</div>
					<div css={timeCss}>{end}</div>
				</div>
			</div>
		</div>
	);
};
export default DateAndTime;
