/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, useMemo } from 'react';
import { jsx, css } from '@emotion/react';
import { Table } from '../../table';
import { IColumn } from '../../table/types';
import { itemsColumns } from './lib';
import { SectionContainer } from '../sectionContainer';
import { useDetectPrint } from '../../../hooks/useIsPrint';
import { ResourceNameTypeEnum } from '../types';
import { EInvoiceVariant } from '../../../organisms/invoiceTemplate/types';
import { flexColCss } from '../../../organisms/refundPopup/style';
import { gapCss } from '../../../styles/utils';
import { ProgramParticipantInfo } from './ProgramParticipantDetails';

const containerCss = css`
	${flexColCss};
	${gapCss(24)};
	break-before: page;
`;

const tableContainerCss = css`
	${[flexColCss, gapCss(12)]};
	height: 100%;
`;

const PROGRAM_PURCHASED_RESOURCE_TYPE = [ResourceNameTypeEnum.EVENT, ResourceNameTypeEnum.PROGRAM_SEASON];

export interface AppendixItem {
	description: string;
	addonCount: any; //IAppendixMultiCell;
	startDate: Date;
	daysTimes: any; //AppendixAddonItemDaysTimes;
	addons: any; //AppendixAddonItem[];
	approvalStatus?: any; // EReservationStatus;
	space?: any; //IAppendixMultiCell;
	slots?: any[]; //SlotDto[];
}

export const Appendix = ({
	activeTab,
	additional,
	state,
	labels,
	variant,
}: {
	activeTab: string;
	additional: any;
	state: any;
	labels: any;
	variant: EInvoiceVariant;
}) => {
	const appendices = useMemo(() => {
		return additional?.appendix ?? [];
	}, [additional]);

	const isPrint = useDetectPrint();
	const finalVariant = isPrint ? EInvoiceVariant.PRINT : variant;
	const scheduleLabels = labels.appendix.scheduleTab;

	return (
		<div css={containerCss}>
			{appendices.map((lineItems: any[]) => {
				const isReservation = lineItems?.some((li: any) => li?.slots?.length > 0);
				const expandableKey = /*isReservation ? 'slots' : 'addons';*/ 'slots';
				const isProgramLineItem = lineItems?.filter(li =>
					PROGRAM_PURCHASED_RESOURCE_TYPE.includes(li?.lineItem?.purchasedResources?.[0]?.resourceType)
				);
				const columns = itemsColumns(isReservation, scheduleLabels, labels) as IColumn[];
				return (
					<SectionContainer title={labels.appendix.title}>
						<div data-aid="invoiceAppendix" css={tableContainerCss}>
							{isProgramLineItem?.length > 0 && (
								<ProgramParticipantInfo lineItem={isProgramLineItem[0].lineItem} labels={scheduleLabels} />
							)}
							<Table
								rows={lineItems}
								columns={columns}
								pagination={false}
								isHover={false}
								maxHeight={-1}
								isExpand={finalVariant === EInvoiceVariant.PRINT}
								expandable
								expandOn={['addonCount']}
								expandableKey={expandableKey}
							/>
						</div>
					</SectionContainer>
				);
			})}
		</div>
	);
};
