import * as  React from 'react';

export const BnArrowDown = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8.45767L10 13.3333L5 8.45767L5.98209 7.5L10 11.418L14.0179 7.5L15 8.45767Z"
      fill="currentColor"
    />
  </svg>
);
