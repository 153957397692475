import React from 'react';

export const DeleteFilled = (props: any) => (
  <svg
    data-aid="svgDeleteFilled"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.75C9.9337 3.75 9.87011 3.77634 9.82323 3.82322C9.77634 3.87011 9.75 3.9337 9.75 4V6.25002H14.25V4C14.25 3.9337 14.2237 3.87011 14.1768 3.82322C14.1299 3.77634 14.0663 3.75 14 3.75H10ZM8.25 4V6.25002H5.01084H4.99074H4.00009C3.58588 6.25002 3.25009 6.58581 3.25009 7.00002C3.25009 7.41424 3.58588 7.75002 4.00009 7.75002H4.30986L5.25016 19.0337C5.25893 19.7508 5.54763 20.4368 6.05541 20.9445C6.57114 21.4603 7.27061 21.75 7.99996 21.75H16C16.7293 21.75 17.4288 21.4603 17.9445 20.9445C18.4523 20.4368 18.741 19.7508 18.7497 19.0337L19.6901 7.75002H20.0001C20.4143 7.75002 20.7501 7.41424 20.7501 7.00002C20.7501 6.58581 20.4143 6.25002 20.0001 6.25002H19.0092H18.9891H15.75V4C15.75 3.53587 15.5656 3.09075 15.2374 2.76256C14.9093 2.43437 14.4641 2.25 14 2.25H10C9.53587 2.25 9.09075 2.43437 8.76257 2.76256C8.43438 3.09075 8.25 3.53587 8.25 4ZM10.7501 11C10.7501 10.5858 10.4143 10.25 10.0001 10.25C9.58588 10.25 9.25009 10.5858 9.25009 11V17C9.25009 17.4142 9.58588 17.75 10.0001 17.75C10.4143 17.75 10.7501 17.4142 10.7501 17V11ZM13.9999 10.25C14.4141 10.25 14.7499 10.5858 14.7499 11V17C14.7499 17.4142 14.4141 17.75 13.9999 17.75C13.5857 17.75 13.2499 17.4142 13.2499 17V11C13.2499 10.5858 13.5857 10.25 13.9999 10.25Z"
      fill="currentColor"
    />
  </svg>
);
