/** @jsx jsx */
import React, { Fragment, useEffect, useMemo } from 'react';
import { css, jsx } from '@emotion/react';
import { ETypography, ETypographyColor, Typography, BnIcon, Icons, SkeletonWrapper } from '@bondsports/utils';
import { TranslationEn } from 'assets/i18n/en';
import { Link } from 'react-router-dom';
import { AlignType } from '@app/react/components/shared/NewTable/types';
import { Table } from '@app/react/components/shared/NewTable';
import { FrameContainer } from '../../../../utils/FrameContainer';
import { useQuestionnaire } from '../../../hooks/useQuestionnaire';
import { colorCss } from '@app/react/styles/utils';
import { colors } from '@app/react/styles/theme';
import { getSubRows } from './utils';
import { NO_SUBJECT } from '../../../../../../types/helpers';

const errorMessageCss = css`
	display: flex;
	justify-content: center;
	div {
		${colorCss(colors.dangerRed)};
	}
`;
export const WaiverAndDocuments = ({
	organizationId,
	userId,
	orgQuestionnaireId,
}: {
	organizationId: number;
	userId: number;
	orgQuestionnaireId: number;
}) => {
	const { questionnaires, fetchQuestionnaires, isErrorInFetchingQuestionnaires, isLoadingQuestionnaires } =
		useQuestionnaire();
	const labels = TranslationEn.customers.customerPage.waiver;
	const columnsLabels = labels.columns;
	const columns = [
		{
			id: 'id',
			label: columnsLabels.id,
			type: 'string',
			styling: { align: AlignType.LEFT },
		},
		{
			id: 'name',
			label: columnsLabels.name,
			type: 'string',
			styling: { align: AlignType.LEFT },
		},
		{
			id: 'type',
			label: columnsLabels.type,
			type: 'string',
			styling: { align: AlignType.LEFT },
		},
		{
			id: 'dateCompleted',
			label: columnsLabels.dateCompleted,
			type: 'string',
			styling: { align: AlignType.LEFT },
		},
		{
			id: 'validUntil',
			label: columnsLabels.validUntil,
			type: 'string',
			styling: { align: AlignType.LEFT },
		},
		{
			id: 'download',
			label: '',
			type: 'custom',
			component: ({ formId, answerTitleId }: { formId: number; answerTitleId: number }) => (
				<Link
					to={`/user/${userId}/form/${formId}/answerTitle/${answerTitleId}/organization/${organizationId}`}
					target="_blank"
				>
					<BnIcon icon={Icons.download} />
				</Link>
			),
			styling: { align: AlignType.LEFT },
		},
	];

	useEffect(() => {
		fetchQuestionnaires(organizationId, userId);
	}, []);

	const [rows, isExistWaivers] = useMemo(() => {
		if (questionnaires.length > 0) {
			const rows = [];
			questionnaires.forEach(questionnaire => {
				const subRows = getSubRows(questionnaire, orgQuestionnaireId, labels);
				rows.push(...subRows);
			});

			return [rows, rows.length > 0];
		}
		return [];
	}, [questionnaires]);

	return (
		<FrameContainer title={labels.title}>
			<SkeletonWrapper isLoading={isLoadingQuestionnaires} minHeight={60}>
				{isErrorInFetchingQuestionnaires ? (
					<div css={errorMessageCss}>
						<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
							{labels.errorInFetchingQuestionnaires}
						</Typography>
					</div>
				) : (
					<Fragment>
						{isExistWaivers ? (
							<Table
								rows={rows}
								columns={columns}
								pagination={false}
								disablePaddingForPagination
								disableShadow
								subject={NO_SUBJECT}
							/>
						) : (
							<Typography color={ETypographyColor.primary} type={ETypography.body2}>
								{labels.noWaiver}
							</Typography>
						)}
					</Fragment>
				)}
			</SkeletonWrapper>
		</FrameContainer>
	);
};
