import { useCallback, useEffect, useRef, useState } from 'react';
import usePagination from '../../../hooks/usePaginatin';
import { IPromoCodeState } from '../types';
import { GetOrganizationPromoCodesDto, PaginationResultDto } from '@bondsports/types';
import { IOption } from '../../../types';

export const usePromoCodeContent = ({
	onChange,
	initialState,
	fetchData,
}: {
	onChange: (promoCodeState: Partial<IPromoCodeState>) => void;
	initialState: IPromoCodeState;
	fetchData: (queryData: GetOrganizationPromoCodesDto) => Promise<PaginationResultDto<IOption>>;
}) => {
	const [totalPages, setTotalPages] = useState(initialState.totalPages || 1);
	const [value, setValue] = useState(initialState.codeDiscountId ? String(initialState.codeDiscountId) : '');
	const [allOptions, setAllOptions] = useState(initialState.options || []);
	const [options, setOptions] = useState(initialState.options || []);
	const [isLoading, setIsLoading] = useState(false);
	const [search, setSearch] = useState('');
	const initialPage = initialState.page || 1;
	const { currentPage, nextPage, goToNextPage, isHasMore, resetPage } = usePagination(totalPages, initialPage);

	const handleLoadNextPage = useCallback(() => {
		setIsLoading(true);
		fetchData({ page: nextPage, itemsPerPage: initialState.itemsPerPage ?? 8, search }).then(res => {
			const newAllOptions = [...allOptions, ...res.data];
			setAllOptions(newAllOptions);
			setTotalPages(res.meta.totalPages);
			goToNextPage();
			onChange({ options: newAllOptions });
			setIsLoading(false);
		});
	}, [allOptions, fetchData, nextPage, onChange, search]);

	const observer = useRef<IntersectionObserver | null>(null);
	const lastOptionElementRef = useCallback(
		(node: Element): void => {
			if (isLoading) {
				return;
			}

			if (observer?.current?.disconnect) {
				observer?.current?.disconnect();
			}

			observer.current = new IntersectionObserver(entries => {
				const [entry] = entries;
				if (entry.isIntersecting && isHasMore) {
					handleLoadNextPage();
				}
			});

			if (node) {
				observer?.current?.observe(node);
			}
		},
		[isHasMore, isLoading, nextPage, currentPage, observer, options, totalPages, search]
	);

	const handlePromoCodeChange = useCallback(
		(val: string) => {
			setValue(val);
			onChange({ codeDiscountId: Number(val) });
		},
		[onChange]
	);

	const handleType = useCallback(
		(v: string) => {
			setSearch(v);
			setIsLoading(true);
			fetchData({ page: initialPage, itemsPerPage: initialState.itemsPerPage ?? 8, search: v }).then(res => {
				const newAllOptions = [...res.data];
				setAllOptions(newAllOptions);
				setOptions(newAllOptions);
				onChange({ options: newAllOptions });
				setIsLoading(false);
				resetPage();
				setTotalPages(res.meta.totalPages);
			});
		},
		[fetchData, onChange, resetPage, totalPages]
	);

	useEffect(() => {
		if (allOptions.length !== options.length) {
			setOptions([...allOptions]);
		}
	}, [allOptions, options]);

	return { options, value, isLoading, handlePromoCodeChange, lastOptionElementRef, handleType, search };
};
