import React from 'react';

export const Check = (props: any) => (
  <svg
    data-aid="svgCheck"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.516 6.46302C18.8706 5.84566 17.8243 5.84566 17.179 6.46302L9.24578 14.183L6.82095 11.8639C6.17567 11.2465 5.12929 11.2465 4.48402 11.8639C3.83866 12.4814 3.83866 13.4824 4.48402 14.0999L8.07739 17.5369C8.40003 17.8457 8.82302 18 9.24594 18C9.66885 18 10.0918 17.8457 10.4145 17.5369L19.516 8.69895C20.1613 8.08151 20.1613 7.08047 19.516 6.46302Z"
      fill="currentColor"
    />
  </svg>
);
