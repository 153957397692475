/** @jsx jsx */
import React, { useContext, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import { FormContext } from '../../../components/shared/form';
import { useToggle } from '../../../hooks/useToggle';
import { FormSection } from './utils/FormSection';
import { ICustomer } from '../../../types/customers';
import { AddMemberFormFieldsEnum as FieldsEnum, IToggleProps } from '../familyAccount/formBody/types';
import { TranslationEn } from '@assets/i18n/en';

interface IProps {
	isDisplayEmailNote?: boolean;
	isDependentCustomer?: boolean;
	customerState?: ICustomer;
	toggleSwitch?: IToggleProps;
}

export const ContactDetails = ({
	isDisplayEmailNote = false,
	isDependentCustomer = false,
	customerState,
	toggleSwitch = null,
}: IProps) => {
	const [isActive, setIsActive] = useToggle();
	const { form } = useContext(FormContext);
	const labels = TranslationEn.customers.editFamilyAccount.contactDetails;
	const { title, address, city, state, zipCode, phoneNo, email, emailNote } = labels;
	const isRequired = !isDependentCustomer;
	const contactDetailsInputs = [
		{ name: FieldsEnum.PHONE_NUMBER, label: phoneNo, type: 'number', isRequired },
		{
			name: FieldsEnum.EMAIL,
			label: email,
			isRequired,
			helperContent: isDisplayEmailNote && ' ',
			helper: isDisplayEmailNote && emailNote,
		},
		{ name: FieldsEnum.STREET, label: address, isRequired },
		{ name: FieldsEnum.CITY, label: city, isRequired },
		{ name: FieldsEnum.STATE, label: state, isRequired },
		{ name: FieldsEnum.ZIP, label: zipCode, isRequired },
	];
	useEffect(() => {
		const { onSelect } = form?.mutators ?? {};
		//temp solution until we refactor create/edit individual account
		if (!onSelect || !toggleSwitch) {
			return;
		}
		if (customerState && isActive) {
			onSelect(FieldsEnum.PHONE_NUMBER, customerState.phoneNumber);
			onSelect(FieldsEnum.STREET, customerState.address?.street);
			onSelect(FieldsEnum.CITY, customerState.address?.city);
			onSelect(FieldsEnum.STATE, customerState.address?.state);
			onSelect(FieldsEnum.ZIP, customerState.address?.zip);
		} else {
			onSelect(FieldsEnum.PHONE_NUMBER, '');
			onSelect(FieldsEnum.STREET, '');
			onSelect(FieldsEnum.CITY, '');
			onSelect(FieldsEnum.STATE, '');
			onSelect(FieldsEnum.ZIP, '');
		}
	}, [customerState, isActive]);

	return (
		<FormSection
			detailsInputs={contactDetailsInputs}
			title={title}
			isWithToggle={!!toggleSwitch}
			toggleLabel={toggleSwitch?.toggleLabel}
			isActive={isActive}
			setIsActive={setIsActive}
		/>
	);
};
