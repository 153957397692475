/* @jsx jsx */

import React, { useMemo } from 'react';
import { css, jsx } from '@emotion/react';
import { separatorCss } from '../../styles';
import {
	alignItemsCss,
	flexCol,
	flexGrowCss,
	flexRowCss,
	fullWidthCss,
	gapCss,
	justifyContentCss,
	paddingBottomCss,
} from '../../../../../styles/utils';
import { formatDate, isEarlier, setUTCTimezone } from '../../../../../lib/dates';
import { Checkbox, Dot, ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import { EDateTimeFormats } from '../../../../../types/times';
import { colors_v2 } from '../../../../../styles/theme';
import { PunchPassEventDto } from '@bondsports/types';
import { DynamicList, DynamicListProps } from '../utils/DynamicList';

const DISABLE_SELECTION = true;

export const PunchPassEventsDynamicList = ({
	fetcher,
	sessionStartDate,
	sessionEndDate,
	totalPasses,
	labels,
	NoDataComponent,
}: Omit<DynamicListProps<PunchPassEventDto>, 'children'> & { totalPasses: number }): React.ReactElement => {
	return (
		<DynamicList
			sessionStartDate={sessionStartDate}
			sessionEndDate={sessionEndDate}
			labels={labels}
			fetcher={fetcher}
			NoDataComponent={NoDataComponent}
		>
			{({ data, isLastItem, index }) => (
				<EventRow
					labels={labels}
					data={data}
					totalPunches={totalPasses}
					isSelected={!DISABLE_SELECTION}
					isLastItem={isLastItem}
					index={index}
				/>
			)}
		</DynamicList>
	);
};

type EventRowProps = {
	data: PunchPassEventDto;
	isSelected: boolean;
	totalPunches: number;
	labels: Record<string, any>;
	isLastItem: boolean;
	index: number;
};

const eventRowContainerCss = (isLast: boolean) => css`
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 12px;
	padding-top: 20px;
	${separatorCss('top')};
	${isLast ? paddingBottomCss(20) : paddingBottomCss(28)};
`;

const EventRow: React.FC<EventRowProps> = ({ data, totalPunches, labels, isLastItem, index }) => {
	const isDisabled = isEarlier(setUTCTimezone(data.endDate), setUTCTimezone(Date.now()));

	const textColor: ETypographyColor = useMemo(
		() => (isDisabled ? ETypographyColor.secondary : ETypographyColor.primary),
		[isDisabled]
	);

	const dotColor: string = useMemo(
		() => (isDisabled ? colors_v2.tx_text_secondary : colors_v2.bg_text_primary),
		[isDisabled]
	);

	return (
		<div datatype="row" data-testid="punch-pass-event" data-index={index} css={eventRowContainerCss(isLastItem)}>
			<Checkbox isDisabled={DISABLE_SELECTION || isDisabled} />
			<div datatype="content" css={[flexCol, flexGrowCss(1), gapCss(8), fullWidthCss]}>
				<div css={[flexRowCss, justifyContentCss('space-between'), fullWidthCss]}>
					<span css={[flexRowCss, gapCss(4), alignItemsCss('center')]}>
						<Typography type={ETypography.body2} color={textColor}>
							{formatDate(data.startDate, EDateTimeFormats.DAY, data.timezone)}
						</Typography>
						<Dot color={dotColor} />
						<Typography type={ETypography.body2} color={textColor}>
							{formatDate(data.startDate, EDateTimeFormats.DAY_FORMAT, data.timezone)}
						</Typography>
						<Dot color={dotColor} />
						<Typography type={ETypography.body2} color={textColor}>
							{`${formatDate(data.startDate, EDateTimeFormats.H12_ampm, data.timezone)} - ${formatDate(
								data.endDate,
								EDateTimeFormats.H12_ampm,
								data.timezone
							)}`}
						</Typography>
					</span>

					<Typography type={ETypography.body2} color={textColor}>
						({data.punch}/{totalPunches})
					</Typography>
				</div>
				<Typography type={ETypography.body2} color={textColor}>
					{labels.redeemedOn} {formatDate(data.redeemedOn, EDateTimeFormats.DAY_FORMAT, data.timezone)}
				</Typography>
			</div>
		</div>
	);
};
