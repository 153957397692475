import * as  React from 'react';

export const BnTickets = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0325 15.3385C15.428 15.3385 15.7486 15.0179 15.7486 14.6224C15.7486 14.2268 15.428 13.9062 15.0325 13.9062C14.637 13.9062 14.3163 14.2268 14.3163 14.6224C14.3163 15.0179 14.637 15.3385 15.0325 15.3385Z"
      fill="currentColor"
    />
    <path
      d="M18.5929 11.0624C18.9267 10.9947 19.1666 10.7012 19.1666 10.3606V8.53438C19.1666 6.95484 17.8815 5.66979 16.302 5.66979H3.69783C3.49058 5.66979 3.28859 5.69246 3.09373 5.73446C3.18533 5.69232 3.28258 5.65916 3.38466 5.63628L14.5826 3.12705C15.1642 3.00129 15.7602 3.30361 16.0001 3.84663C16.16 4.20843 16.5829 4.37196 16.9446 4.21219C17.3064 4.05234 17.4701 3.62946 17.3102 3.2677C16.7962 2.1046 15.5201 1.45703 14.2763 1.72791L14.2721 1.72884L3.07149 4.23865C2.43874 4.38044 1.86704 4.7373 1.46174 5.24347C1.05644 5.74964 0.833252 6.38551 0.833252 7.03394V10.3607C0.833252 10.7013 1.07313 10.9947 1.40692 11.0625C1.90443 11.1635 2.26554 11.6062 2.26554 12.1151C2.26554 12.6241 1.90443 13.0668 1.40692 13.1678C1.07313 13.2355 0.833252 13.529 0.833252 13.8696V15.6959C0.833252 17.2754 2.1183 18.5605 3.69783 18.5605H16.302C17.8815 18.5605 19.1666 17.2754 19.1666 15.6959V13.8696C19.1666 13.529 18.9267 13.2356 18.5929 13.1678C18.0954 13.0668 17.7343 12.6241 17.7343 12.1151C17.7343 11.6062 18.0954 11.1635 18.5929 11.0624ZM17.7343 9.85036C16.8782 10.2581 16.302 11.1339 16.302 12.1151C16.302 13.0964 16.8782 13.9722 17.7343 14.3799V15.6959C17.7343 16.4856 17.0918 17.1282 16.302 17.1282H15.7486C15.7486 16.7326 15.428 16.412 15.0325 16.412C14.6369 16.412 14.3163 16.7326 14.3163 17.1282H3.69783C2.90807 17.1282 2.26554 16.4856 2.26554 15.6959V14.3799C3.12159 13.9722 3.69783 13.0964 3.69783 12.1151C3.69783 11.1339 3.12159 10.2581 2.26554 9.85036V8.53441C2.26554 7.74465 2.90807 7.10212 3.69783 7.10212H14.3163C14.3163 7.49765 14.6369 7.81827 15.0325 7.81827C15.428 7.81827 15.7486 7.49765 15.7486 7.10212H16.302C17.0918 7.10212 17.7343 7.74465 17.7343 8.53441V9.85036Z"
      fill="currentColor"
    />
    <path
      d="M15.0325 10.3255C15.428 10.3255 15.7486 10.0048 15.7486 9.60934C15.7486 9.21382 15.428 8.8932 15.0325 8.8932C14.637 8.8932 14.3163 9.21382 14.3163 9.60934C14.3163 10.0048 14.637 10.3255 15.0325 10.3255Z"
      fill="currentColor"
    />
    <path
      d="M15.0325 12.832C15.428 12.832 15.7486 12.5114 15.7486 12.1159C15.7486 11.7203 15.428 11.3997 15.0325 11.3997C14.637 11.3997 14.3163 11.7203 14.3163 12.1159C14.3163 12.5114 14.637 12.832 15.0325 12.832Z"
      fill="currentColor"
    />
    <path
      d="M12.7056 10.6091L10.5854 10.2851L9.63184 8.25373C9.53541 8.04804 9.32865 7.91666 9.10144 7.91666C8.87424 7.91666 8.66764 8.04804 8.57105 8.25358L7.61753 10.2851L5.49731 10.6091C5.28018 10.6422 5.09982 10.794 5.03024 11.0023C4.96051 11.2106 5.01315 11.4402 5.1665 11.5975L6.71771 13.1875L6.35043 15.4395C6.31427 15.6609 6.40781 15.8836 6.59122 16.0128C6.77463 16.1421 7.01572 16.1552 7.2121 16.0467L9.10144 15.0022L10.9908 16.0467C11.1887 16.1561 11.4298 16.141 11.6117 16.0128C11.7951 15.8836 11.8886 15.6609 11.8525 15.4395L11.4852 13.1875L13.0364 11.5975C13.1897 11.4404 13.2424 11.2106 13.1726 11.0023C13.1031 10.794 12.9227 10.6423 12.7056 10.6091ZM10.4399 12.5808C10.3099 12.7139 10.251 12.9008 10.281 13.0842L10.5017 14.4373L9.38495 13.82C9.29676 13.7713 9.1991 13.7469 9.10144 13.7469C9.00379 13.7469 8.90613 13.7713 8.81794 13.82L7.70114 14.4373L7.92194 13.0842C7.95185 12.9008 7.89295 12.7139 7.76294 12.5808L6.80148 11.5954L8.10352 11.3964C8.29669 11.3668 8.46241 11.2431 8.54541 11.0662L9.10144 9.88169L9.65748 11.0661C9.74048 11.2429 9.90619 11.3668 10.0994 11.3963L11.4014 11.5952L10.4399 12.5808Z"
      fill="currentColor"
    />
  </svg>
);
