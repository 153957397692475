/** @jsxRuntime classic */
/** @jsx jsx */

import React, { Fragment, useCallback, useMemo, useRef } from 'react';
import { css, jsx, Theme, useTheme } from '@emotion/react';
import { TGroup } from './types';
import { Group } from './Group';
import { SizeEnum } from '../../lib/constants';
import { flexCol } from '../../molecules/invoicing/appendix/lib';
import { SelectAllOptions } from './SelectAllOptions';
import { useColors } from '../../hooks/useColors';
import { sumBy } from 'lodash';
import { Typography } from '../../atoms';
import { ETypography, ETypographyColor } from '../../types';

type GroupsOptionsProps<T, U> = {
	groups: TGroup<T, U>[];
	onSelect: (val: any) => void;
	isMultiple?: boolean;
	selectedValuesArray: U[];
	enableSelectAll?: boolean;
	size?: SizeEnum;
	value?: U[];
	handleRemoveAll?: () => void;
	handleSelectAll?: () => void;
	selectAllLabel?: string;
	handleLoadNextPage?: () => void;
	isLoading?: boolean;
	isFinalPage?: boolean;
	selectedGroup?: any;
	onSelectGroup: (val: TGroup<T, U>) => void;
	isGroups?: boolean;
	searchValue?: string;
	usePagination: boolean;
	LoadingComponent: React.ReactNode;
	EndOptionComponent: React.ReactNode;
	NoOptionsComponent: React.ReactNode;
	handleLoadMoreGroups?: () => void;
	selectAllGroups: () => void;
	deslectAllGroups: () => void;
	labels: any;
};

export const GroupsOptions = <T, U>({
	groups,
	onSelect,
	isMultiple = false,
	selectedValuesArray,
	enableSelectAll,
	size,
	value,
	handleRemoveAll,
	handleSelectAll,
	selectAllLabel,
	handleLoadNextPage,
	isLoading,
	isFinalPage,
	selectedGroup,
	onSelectGroup,
	isGroups,
	searchValue,
	usePagination,
	LoadingComponent,
	EndOptionComponent,
	NoOptionsComponent,
	handleLoadMoreGroups,
	selectAllGroups,
	deslectAllGroups,
	labels,
}: GroupsOptionsProps<T, U>) => {
	const theme: Theme = useTheme();

	const observer = useRef<IntersectionObserver | null>(null);
	const lastOptionElementRef = useCallback(
		(node: Element): void => {
			if (selectedGroup) {
				return;
			}

			if (observer?.current?.disconnect) {
				observer?.current?.disconnect();
			}

			observer.current = new IntersectionObserver(entries => {
				const [entry] = entries;
				if (entry.isIntersecting) {
					handleLoadMoreGroups?.();
				}
			});

			if (node) {
				observer?.current?.observe(node);
			}
		},
		[selectedGroup]
	);

	const allSelectedGroupsCount: number = Number(sumBy(groups, 'allSelected'));

	const isChecked: boolean = Boolean(
		groups.length && allSelectedGroupsCount === groups.length && !groups.some(group => !!group.excluded?.length)
	);

	const isIndeterminate: boolean =
		Boolean(
			groups?.some(groups => groups.allSelected) || (allSelectedGroupsCount && allSelectedGroupsCount !== groups.length)
		) && !isChecked;

	return (
		<Fragment>
			{isGroups ? (
				<div>
					<div css={flexCol}>
						<SelectAllOptions
							theme={theme}
							handleRemoveAll={() => deslectAllGroups()}
							handleSelectAll={() => selectAllGroups()}
							isIndeterminate={isIndeterminate}
							isChecked={isChecked}
						>
							<Fragment>
								<Typography style={{ margin: 0, padding: 0 }} type={ETypography.body2} color={ETypographyColor.primary}>
									{labels.allGroupsTextPrimary}
								</Typography>
								<Typography
									style={{ margin: 0, padding: 0, paddingLeft: 5 }}
									type={ETypography.body2}
									color={ETypographyColor.secondary}
								>
									{labels.allGroupsTextSecondary}
								</Typography>
							</Fragment>
						</SelectAllOptions>
					</div>
					{groups.map((group: TGroup<T, U>, index: number) => {
						//@ts-ignore
						const ref: (node: HTMLSpanElement) => void = index === groups.length - 1 ? lastOptionElementRef : null;
						return (
							<span ref={ref}>
								<Group
									key={index}
									onSelect={onSelect}
									isMultiple={isMultiple}
									label={group.label}
									options={group.options}
									group={group}
									selectedValuesArray={selectedValuesArray}
									handleLoadNextPage={handleLoadNextPage}
									isFinalPage={isFinalPage}
									isLoading={isLoading}
									enableSelectAll={enableSelectAll}
									handleSelectAll={handleSelectAll}
									handleRemoveAll={handleRemoveAll}
									selectAllLabel={selectAllLabel}
									size={size}
									value={value}
									selectedGroup={selectedGroup}
									onSelectGroup={onSelectGroup}
									isGroups={isGroups}
									searchValue={searchValue}
									usePagination={usePagination}
									LoadingComponent={LoadingComponent}
									EndOptionComponent={EndOptionComponent}
									isLastGroup={index === groups.length - 1}
									NoOptionsComponent={NoOptionsComponent}
								/>
							</span>
						);
					})}
				</div>
			) : (
				<Group
					onSelect={onSelect}
					isMultiple={isMultiple}
					label={groups[0].label}
					options={groups[0].options}
					group={groups[0]}
					selectedValuesArray={selectedValuesArray}
					handleLoadNextPage={handleLoadNextPage}
					isFinalPage={isFinalPage}
					isLoading={isLoading}
					enableSelectAll={enableSelectAll}
					handleSelectAll={handleSelectAll}
					handleRemoveAll={handleRemoveAll}
					selectAllLabel={selectAllLabel}
					size={size}
					value={value}
					selectedGroup={selectedGroup}
					onSelectGroup={onSelectGroup}
					isGroups={isGroups}
					searchValue={searchValue}
					usePagination={usePagination}
					LoadingComponent={LoadingComponent}
					EndOptionComponent={EndOptionComponent}
					NoOptionsComponent={NoOptionsComponent}
				/>
			)}
		</Fragment>
	);
};
