/** @jsxRuntime classic */
/** @jsx jsx */

import { FC, useMemo, useState } from 'react';
import { jsx } from '@emotion/react';
import dayjs from 'dayjs';
import { useTheme } from '@emotion/react';
import useInterval from '../../../hooks/useInterval';
import { hourBoxContainerHorizontal, hourBoxLineHorizontal } from '../../styles';
import { calculateMarginLeftFromMinutes } from '../../../utils/timeUtils';
import { useMiddlewareContext } from '../../../hooks/useMiddlewareContext';
import { IHourBoxProps } from '../../../types';

export const HorizontalHourBox: FC<IHourBoxProps> = ({ date, width }) => {
	const colors = useTheme();
	const { options } = useMiddlewareContext();
	const { date: optionDate } = options;
	const today = useMemo(() => dayjs(optionDate), [optionDate]);

	const isSelectedDate = useMemo(
		() =>
			date.format('DD/MM/YYYY') === today.format('DD/MM/YYYY') &&
			today.format('DD/MM/YYYY') === dayjs().format('DD/MM/YYYY'),
		[date, today]
	);

	const [marginLeft, setMarginLeft] = useState(calculateMarginLeftFromMinutes(Number(dayjs().format('mm'))));
	const [now, setNow] = useState(dayjs());

	useInterval(() => {
		setMarginLeft(calculateMarginLeftFromMinutes(Number(dayjs().format('mm'))));
		setNow(dayjs());
	}, 1000);

	return (
		<div css={hourBoxContainerHorizontal(colors)} data-aid="HorizontalHourBox-horizontal">
			{isSelectedDate && now.format('hh a') === dayjs(date).format('hh a') && (
				<div css={hourBoxLineHorizontal(width, marginLeft)} />
			)}
			<span className="small" />
			<span className="big" />
			<div className="time">{dayjs(date).format('hh a')}</div>
		</div>
	);
};
