/* @jsx jsx */

import React, { useCallback, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { BnIcon, Button, ETypography, ETypographyColor, Icons, ModalWindow, Typography } from '@bondsports/utils';
import { colors, colors_v2 } from '../../../../../styles/theme';
import { Table } from '@app/react/components/shared/NewTable';
import { useAddons } from '@app/react/hooks/useAddons';
import { paddingBottomCss } from '../../../../settings/styles';
import { AlignType } from '@app/react/components/shared/NewTable/types';
import { AddonTimePeriodEnum, UserAddonDto } from '@bondsports/types';
import { getTimePeriodLabel } from './lib';
import { AddonsModalProps } from './types';

const addonModalContainerCss = css`
	min-width: 500px;
	max-width: 500px;
	max-height: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	padding-top: 36px;
`;

const titleCss = css`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	gap: 4px;
`;

const timePeriodCss = css`
	width: inherit;
	display: flex;
	justify-content: flex-end;
	text-align: left;
	padding-left: 15px;
`;

const centerCss = css`
	padding-top: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	overflow: hidden;
	color: ${colors.formInputBg};
`;

const tableContainerCss = css`
	height: 50%;
	width: 100%;
`;

export const AddonsModal: React.FC<AddonsModalProps> = ({ isShowing, toggle, labels, data }) => {
	const INITIAL_PAGE_NUMBER = 1;
	const [page, setPage] = useState(INITIAL_PAGE_NUMBER);
	const { getUserProgramAddons, addons, addonsMeta, isLoading, error } = useAddons();

	const columns = [
		{
			id: 'name',
			label: 'name',
			type: 'string',
			styling: { align: AlignType.LEFT },
		},
		{
			id: 'quantity',
			label: 'quantity',
			type: 'integer',
			styling: { align: AlignType.LEFT },
		},
		{
			id: 'timePeriod',
			label: 'timePeriod',
			type: 'custom',
			component: (timePeriod: AddonTimePeriodEnum, addon: UserAddonDto) => {
				const label = getTimePeriodLabel(labels, timePeriod, String(addon.date), data.isSegment);
				return <div css={timePeriodCss}>{label}</div>;
			},
		},
	];

	useEffect(() => {
		getUserProgramAddons(data.isSegment, data.organizationId, data.userId, data.id, data.productUserId, page);
	}, [
		data.id,
		data.isSegment,
		data.organizationId,
		data.productUserId,
		data.userId,
		getUserProgramAddons,
		labels.error,
		page,
	]);

	const incrementPage = useCallback(() => {
		if (addonsMeta.totalPages > page) setPage(prevPage => prevPage + 1);
	}, [addonsMeta.totalPages, page]);

	return (
		<ModalWindow isShowing={isShowing} toggle={toggle}>
			<div datatype="title" data-testid="addon-modal-title" css={addonModalContainerCss}>
				<div css={titleCss}>
					<BnIcon icon={Icons.add_ons} color={colors_v2.tx_text_primary} />
					<Typography type={ETypography.h4} color={ETypographyColor.primary}>
						{labels.title}
					</Typography>
				</div>
				<div css={tableContainerCss}>
					<Table
						columns={columns}
						rows={addons}
						subject={labels.title}
						meta={{ itemsPerPage: addons.length }}
						bottomBorder={false}
						fetchNextPage={incrementPage}
						isHoverRow={false}
						removeRowSeparator
						removeHeader
						pagination
						infiniteScroll
						maxHeight={200}
						isLoading={isLoading}
						isRowsError={!!error}
						rowsErrorMessage={error}
					/>
				</div>
				<div css={paddingBottomCss(20)}>
					<Button data-aid="button-ResourcesModal-close" theme="primary" sizer="M" onClick={toggle}>
						{labels.close}
					</Button>
				</div>
			</div>
		</ModalWindow>
	);
};
