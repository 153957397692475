import React from 'react';

export const Rowing = (props: any) => (
  <svg
    data-aid="svgRowing"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.22202 2.0004C5.05368 2.00044 4.88917 2.0544 4.74954 2.15538C4.60991 2.25635 4.50149 2.39975 4.43814 2.56725C4.3748 2.73474 4.35941 2.91872 4.39393 3.09565C4.42846 3.27259 4.51134 3.43445 4.63198 3.56053L10.8034 10.188L7.95238 13.2497C6.99786 12.7334 5.75867 12.8786 4.98237 13.713L1.49504 17.458C0.834988 18.1659 0.834988 19.3202 1.49504 20.0281L2.83708 21.4693C3.1671 21.8237 3.60042 22 4.03368 22C4.46694 22 4.90026 21.8237 5.23028 21.4693L8.71761 17.7243C9.19742 17.2099 9.46136 16.5246 9.46136 15.7967C9.46136 15.3441 9.34937 14.9141 9.15725 14.5259L12.4776 10.9601C12.5752 10.8916 12.6584 10.8021 12.7223 10.6974L19.368 3.56053C19.4903 3.43289 19.5738 3.26855 19.6076 3.08905C19.6414 2.90956 19.624 2.72328 19.5575 2.55463C19.4911 2.38598 19.3787 2.24282 19.2352 2.14392C19.0916 2.04502 18.9236 1.995 18.7532 2.0004C18.5333 2.00743 18.3247 2.1061 18.1714 2.27551L12 8.90299L5.83023 2.27551C5.75118 2.18824 5.65661 2.11892 5.55213 2.07166C5.44765 2.0244 5.33539 2.00016 5.22202 2.0004ZM14.2907 11.363L13.0941 12.648L14.8444 14.5277C14.6527 14.9156 14.5403 15.3446 14.5403 15.7967C14.5403 16.5246 14.8051 17.2099 15.284 17.7243L18.7714 21.4693C19.1014 21.8237 19.5331 22 19.9663 22C20.3996 22 20.8329 21.8237 21.1629 21.4693L22.505 20.0281C23.165 19.3202 23.165 18.1659 22.505 17.458L19.0193 13.713C18.2428 12.8784 17.0022 12.733 16.0476 13.2497L14.2907 11.363Z"
      fill="currentColor"
    />
  </svg>
);
