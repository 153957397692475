import React from 'react';

export const Camps = (props: any) => (
  <svg
    data-aid="svgCamps"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.4975 13.9992H22.4162C21.9079 13.9992 21.8651 14.6048 21.8631 14.7627V15.8368C21.8631 16.1656 21.6161 15.8675 21.5955 15.8415L19.696 13.4822L12.3517 4.21057C11.979 3.74078 11.6153 4.20278 11.6098 4.21013L3.89001 13.8568L3.79667 13.9741C3.77497 14.0048 3.75052 14.045 3.72606 14.0865L2.42038 15.7092C2.14071 16.0561 2.13692 15.7083 2.13692 15.7083V14.6692C2.13692 14.0584 1.78596 14.0026 1.67575 14H1.55176C1.55176 14 1 13.9866 1 14.6896V15.9255V17.4737V19.1409C1 19.1409 1 19.9991 1.51112 19.9991H1.62615C1.73326 19.9953 2.13727 19.9286 2.13727 19.1318V18.3981C2.13727 17.716 2.39386 17.4079 2.39386 17.4079L3.31896 16.259C3.3627 16.2075 3.51769 16.0392 3.51769 16.2728V19.2387C3.5201 19.426 3.57452 19.9991 4.14487 19.9991H8.0144H8.2214C8.89921 19.9991 9.07555 19.3689 9.08313 19.3395L11.8099 13.5465C11.8484 13.4145 12.0663 13.3262 12.1578 13.5465L14.8811 19.2486C14.8811 19.2486 15.0677 19.9991 15.8444 19.9991H15.9043H19.7143C20.3394 19.9991 20.4003 19.4203 20.4038 19.2261V16.6254C20.4038 16.6254 20.4365 16.0695 20.8836 16.6245L21.6213 17.5416C21.6213 17.5416 21.8627 17.8479 21.8627 18.3268V19.3312C21.8648 19.4861 21.9054 19.9987 22.3673 19.9987H22.3997C23 20.0407 23 19.0501 23 19.0501V18.598V17.5888V14.8829C23 14.0788 22.6201 14.0031 22.4975 13.9992Z"
      fill="currentColor"
    />
  </svg>
);
