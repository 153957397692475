import React from 'react';
import * as queryString from 'querystring';
import { useHistory, useLocation } from 'react-router-dom';

export function useQuery() {
	const { search, pathname } = useLocation();
	const history = useHistory();

	const getQueryString = (object: { [key: string]: string }) => {
		let obj = queryString.parse(search.replace('?', ''));
		obj = { ...obj, ...object };
		return queryString.stringify(obj);
	};

	const getQueryParams = () => {
		const obj: { [key: string]: string } = queryString.parse(search.replace('?', '')) as { [key: string]: string };
		return obj;
	};

	const setMultipleQuery = (object: { [key: string]: string }) => {
		let obj = queryString.parse(search.replace('?', ''));
		obj = { ...obj, ...object };
		history.push({ pathname, search: queryString.stringify(obj) });
	};

	const setQuery = (key: string, value: string) => {
		const obj = queryString.parse(search.replace('?', ''));
		obj[key] = value;
		history.push({ pathname, search: queryString.stringify(obj) });
	};

	const getQueryValue = (key: string) => {
		const obj = queryString.parse(search.replace('?', ''));
		return obj[key];
	};

	const resetQuery = () => {
		history.push({ pathname, search: '' });
	};

	return { setQuery, getQueryValue, resetQuery, setMultipleQuery, getQueryString, getQueryParams };
}
