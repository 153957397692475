/** @jsxRuntime classic*/
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { capitalize } from '../../functions';
import { StyledRadio } from './style';
import { ErrorMessage } from '../ErrorMessage';
import { ETypography, ETypographyColor, IColors, Icons, TRadioType } from '../../types';
import { useColors } from '../../hooks/useColors';
import { Typography } from '../../atoms';
import { BnIcon } from '../icons_v2';
import { flex, gapCss, marginLeftCss } from '../../styles/utils';

const containerCss = (colors: IColors, isChecked: boolean) => css`
	display: flex;
	padding: 12px;
	background: ${colors.bg_background_secondary};
	border: 1px solid ${isChecked ? colors.tx_text_primary : colors.bg_border_primary};
	position: relative;
	cursor: pointer;
	border-radius: 3px;
	&:hover {
		background: ${colors.bg_background_primary};
	}
`;

const bodyContainerCss = (colors: IColors) => css`
	margin-left: 36px;

	.body-2 {
		margin-top: 4px;
	}
`;

export interface IRadioProps {
	value: string | number;
	checked?: boolean;
	setChecked?: (val: unknown) => void;
	label?: string;
	width?: number;
	error?: string;
	sizer?: 'M' | 'S';
	componentType?: TRadioType;
	title?: string;
	description?: string;
	disabled?: boolean;
	icon?: Icons;
}
export const Radio = ({
	value,
	checked,
	setChecked,
	label = '',
	sizer = 'S',
	width = 20,
	error,
	componentType = 'basic',
	icon,
	...props
}: IRadioProps) => {
	const { colors } = useColors();
	const [visited, setVisited] = useState<boolean>(false);
	const isIconHorizontal = componentType === 'icon-btn-horizontal';

	return (
		<Fragment>
			<StyledRadio
				active={checked as boolean}
				theme={{ ...colors }}
				isIconHorizontal={isIconHorizontal}
				width={width}
				isDisabled={props.disabled}
			>
				{isIconHorizontal && (
					<div
						css={containerCss(colors, checked || false)}
						onClick={() => {
							if (setChecked) {
								setChecked(value);
							}
						}}
						data-aid="index-Radio"
					>
						<div css={bodyContainerCss(colors)}>
							<Typography type={ETypography.body2Accented} color={ETypographyColor.primary}>
								{props.title}
							</Typography>
							<Typography type={ETypography.body2} color={ETypographyColor.secondary}>
								{props.description}
							</Typography>
						</div>
					</div>
				)}
				<div css={[flex, gapCss(4)]}>
					{icon && <BnIcon icon={icon} />}
					<div className={`${sizer}`}>{capitalize(label)}</div>
				</div>

				<input
					data-checked={checked}
					value={value}
					type="radio"
					onClick={e => {
						if (setChecked) {
							setChecked(value);
						}
					}}
					{...props}
					onBlur={() => setVisited(true)}
				/>
				<span className={`checkmark ${visited && error ? 'error' : ''}`}></span>
			</StyledRadio>
			{visited && <ErrorMessage error={error || ''} />}
		</Fragment>
	);
};
