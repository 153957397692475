import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';
import { css } from '@emotion/react';
import { fullWidthCss } from '../../../styles/utils';
import { CSSProperties } from 'react';

export const rowLayoutCss = (
	gap: string,
	align: string,
	fullWidth: boolean,
	justify?: string,
	styles?: SerializedStyles
) => css`
	${Boolean(justify) && `justify-content: ${justify}`};
	display: flex;
	flex-direction: row;
	align-items: ${align};
	gap: ${gap};
	text-align: center;
	${fullWidth && `width: 100%; flex-grow: 1; box-sizing: border-box;`};
	${styles && { ...styles }};
`;

export const colCss = (
	gap: CSSProperties['gap'],
	align: CSSProperties['alignItems'],
	fullWidth: boolean,
	style?: SerializedStyles
) => css`
	display: flex;
	flex-direction: column;
	align-items: ${align};
	gap: ${gap};
	${fullWidth && `width: 100%; flex-grow: 1; box-sizing: border-box;`};
	${style && { ...style }};
`;

export const centerCss = (style?: SerializedStyles) => css`
	display: flex;
	justify-content: center;
	align-items: center;
	${style && { ...style }};
`;
