/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactNode, useLayoutEffect, useMemo, useRef } from 'react';
import { css, jsx } from '@emotion/react';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor, IColors } from '../../../types';
import { PaymentMethodContainer } from './paymentMethodContainer';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { mobileOnly } from '../../../styles/utils';
import { PaymentMethod } from '../../../types/entities/payment';
import { useColors } from '../../../hooks/useColors';
import { oldThemeColors as brandColors } from '../../../styles/theme';

const containerCss = (colors: IColors, isMarginBottom: boolean) => css`
	max-width: 600px;
	> header {
		padding-bottom: 20px;
		border-bottom: 1px solid ${colors.bg_border_seperator};
		margin-bottom: ${isMarginBottom ? 20 : 0}px;
		.h4 {
			color: ${brandColors.brandPrimary} !important;
		}
		${mobileOnly} {
			.h6-accented {
				color: ${brandColors.brandPrimary} !important;
			}
		}
	}
	${mobileOnly} {
		padding: 20px;
	}
`;

export const PaymentForm = ({
	addPaymemtButtons,
	handlePay,
	paymentMethods,
	paymentPageToggle,
	labels,
	state,
	selectedPaymentMethod,
	handleSelectPaymentMethod,
	paymentAmountInput,
	isPayButtonDisabled,
	amountToPay,
	handleBack,
}: {
	paymentPageToggle: () => void;
	addPaymemtButtons: ReactNode;
	paymentMethods: any[];
	handlePay: (selectedPaymentMethod: any, onDone: () => void, amountToPay: number) => void;
	labels: any;
	state: any;
	selectedPaymentMethod?: PaymentMethod;
	handleSelectPaymentMethod: (v: PaymentMethod) => void;
	paymentAmountInput?: ReactNode;
	isPayButtonDisabled?: boolean;
	amountToPay: number;
	handleBack: () => void;
}) => {
	const { colors } = useColors();
	const { isMobile } = useWindowSize();
	const ref = useRef<HTMLDivElement>(null);
	const relevantLabels = labels.paymentForm;

	const totalToPay = useMemo(() => {
		return state?.amountWithFees > state.amountToPay ? state?.amountWithFees : state?.amountToPay;
	}, [state.amountToPay, state.amountWithFees]);

	useLayoutEffect(() => {
		setTimeout(() => {
			if (ref.current) {
				ref.current.scrollIntoView({
					behavior: 'smooth',
				});
			}
		}, 100);
	}, [ref]);

	return (
		<div data-aid="paymentForm" css={containerCss(colors, !paymentAmountInput)} ref={ref}>
			<header>
				<Typography type={isMobile ? ETypography.h6Accented : ETypography.h4} color={ETypographyColor.primary}>
					{relevantLabels.title}
				</Typography>
			</header>
			{paymentAmountInput}
			<PaymentMethodContainer
				paymentPageToggle={paymentPageToggle}
				paymentMethods={paymentMethods}
				addPaymemtButtons={addPaymemtButtons}
				handlePay={handlePay}
				state={state}
				labels={labels}
				selected={selectedPaymentMethod}
				handleSelect={handleSelectPaymentMethod}
				isPayButtonDisabled={isPayButtonDisabled}
				amountToPay={amountToPay}
				handleBack={handleBack}
			/>
		</div>
	);
};
