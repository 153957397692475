import React from 'react';

export const Dollar = (props: any) => (
  <svg
    data-aid="svgDollar"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00002 8.49991C7.00002 6.29575 8.73753 4.50133 11.0052 4.08911L11.0012 4.04537L11 2.99991C11 2.44762 11.4477 1.99991 12 1.99991C12.5523 1.99991 13 2.44762 13 2.99991V3.99991L12.9958 4.0893C15.263 4.50185 17 6.29608 17 8.49991C17 9.0522 16.5523 9.49991 16 9.49991C15.4872 9.49991 15.0645 9.11387 15.0067 8.61653L15 8.49991C15 7.1511 13.6844 5.99991 12 5.99991C10.3157 5.99991 9.00002 7.1511 9.00002 8.49991C9.00002 9.79876 10.22 10.9144 11.8144 10.9952L12 10.9999C14.7339 10.9999 17 12.9827 17 15.4999C17 17.7021 15.2656 19.4953 13.0008 19.9096L13 20.9999C13 21.5522 12.5523 21.9999 12 21.9999C11.4477 21.9999 11 21.5522 11 20.9999L11.0002 19.9098C8.73498 19.4959 7.00002 17.7025 7.00002 15.4999C7.00002 14.9476 7.44773 14.4999 8.00002 14.4999C8.5523 14.4999 9.00002 14.9476 9.00002 15.4999C9.00002 16.8487 10.3157 17.9999 12 17.9999C13.6844 17.9999 15 16.8487 15 15.4999C15 14.2011 13.78 13.0855 12.1856 13.0046L12 12.9999C9.26609 12.9999 7.00002 11.0171 7.00002 8.49991Z"
      fill="currentColor"
    />
  </svg>
);
