/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactNode } from 'react';
import { jsx, css } from '@emotion/react';

const container = css`
	display: flex;
	flex-direction: column;

	background: white;
	gap: 24px;

	@media print and (max-width: 10cm) {
		padding: 0 0 100px 0;
	}
`;
export const Body = ({ children }: { children: ReactNode }) => {
	return (
		<div css={container} data-aid="index-body">
			{children}
		</div>
	);
};
