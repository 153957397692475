/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import { i18n } from '../../../lib/i18n';
import { MembershipStatusEnum } from './types';
import { iconMapper, membershipStatusColorMapper } from './utils';
import { IconWithTooltip } from '../../IconWithTooltip';

export interface Props {
	membershipStatus: MembershipStatusEnum;
	size?: number;
}
export const MembershipStatusIndication = ({ membershipStatus, size = 16 }: Props) => {
	const membershipStatusMapper = i18n.membershipStatusMapper;

	return (
		<IconWithTooltip
			icon={iconMapper[membershipStatus]}
			text={membershipStatusMapper[membershipStatus]}
			color={membershipStatusColorMapper[membershipStatus]}
			size={size}
		/>
	);
};
