/** @jsx jsx */
import { MutableRefObject, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { jsx } from '@emotion/react';
import { EInvoicingTabs } from '@bondsports/utils';

interface Props {
	title: string;
	link: string;
	disabled?: boolean;
	isActive?: boolean;
	onAction?: (tab: EInvoicingTabs) => void;
}

export const Tab = forwardRef(
	({ title, link, disabled = false, onAction, isActive }: Props, ref: MutableRefObject<HTMLAnchorElement>) => {
		const className = `link${disabled ? ' disabled-tab' : ''}`;

		return (
			<NavLink
				className={className}
				ref={ref}
				id={link}
				isActive={(match, location) => {
					return isActive || location.pathname.includes(link);
				}}
				to={link}
				onClick={e => {
					if (onAction) {
						e.preventDefault();
						onAction(link as EInvoicingTabs);
					}
				}}
			>
				<div>{title}</div>
			</NavLink>
		);
	}
);
