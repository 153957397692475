import React from 'react';

export const Softball = (props: any) => (
  <svg
    data-aid="svgSoftball"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.6076 2C18.9608 2 18.3496 2.25806 17.8844 2.72324L13.3634 7.24261C12.4755 8.13053 11.1682 9.87919 9.78285 11.7314C8.29054 13.7297 7.61484 14.9249 6.45359 16.1082L4.14806 18.4137L5.62849 19.8941L7.93571 17.5869C9.13431 16.3645 10.4093 15.6311 12.4839 14.0811C14.2632 12.7501 15.9422 11.4937 16.8013 10.6364L21.2341 6.2019C22.1152 5.32077 22.2527 3.97786 21.56 3.00846C21.0932 2.36841 20.3801 2 19.6076 2ZM7.65049 2.00679C6.21251 2.00679 5.04277 3.17653 5.04277 4.61451C5.04277 4.68073 5.04786 4.74694 5.05295 4.81145C5.68791 4.65017 6.2923 4.29704 6.822 3.76734C7.3466 3.24274 7.69803 2.64514 7.86271 2.01698C7.7931 2.01188 7.7218 2.00679 7.65049 2.00679ZM8.49936 2.1528C8.30921 2.9032 7.89836 3.61115 7.28208 4.22743C6.65901 4.8505 5.94257 5.26305 5.18198 5.4515C5.43834 6.2036 6.02746 6.7995 6.77446 7.06605C6.98498 6.32074 7.41451 5.60599 8.04437 4.97613C8.63348 4.38702 9.33125 3.99144 10.1207 3.78602C9.86264 3.02204 9.26164 2.41595 8.49936 2.1528ZM10.2497 4.43116C9.58761 4.61112 9.00189 4.94388 8.50615 5.43792C7.96627 5.9778 7.59447 6.58389 7.41111 7.21035C7.48921 7.21714 7.569 7.22224 7.65049 7.22224C9.08847 7.22224 10.2582 6.0525 10.2582 4.61451C10.2582 4.5534 10.2531 4.49228 10.2497 4.43116ZM2.79327 18.6259C2.58614 18.6259 2.38072 18.7023 2.22792 18.8551C1.92403 19.159 1.92403 19.6802 2.22792 19.9841L4.01903 21.7752C4.32293 22.0791 4.84413 22.0791 5.14803 21.7752C5.45362 21.4696 5.45362 20.9484 5.14803 20.6445L3.35861 18.8551C3.20582 18.7023 3.00039 18.6259 2.79327 18.6259Z"
      fill="currentColor"
    />
  </svg>
);
