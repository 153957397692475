export const useNavigation = () => {
	const openInNewTab = (url: string): void => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
		if (newWindow) newWindow.opener = null;
	};

	return {
		openInNewTab,
	};
};
