import { getEmergencyContactDetails } from '@app/react/components/customers/lib';
import { REQUIRED } from '@app/react/lib/constants';
import { ICustomer } from '@app/react/types/customers';
import { CreateOrEditCustomerDto } from '@bondsports/types';
import { EMERGENCY_CONTACT_NAME, EMERGENCY_CONTACT_PHONE } from './formBody/types';

export const generateInitialvalues = ({
	customerState,
	isCreateAccount = false,
}: {
	customerState: ICustomer;
	isCreateAccount?: boolean;
}) => {
	const { address, name, phoneNumber, email, emergencyContacts } = customerState;
	const { street, streetNum, city, state, zip } = address || {};

	const emergencyContactDetails = getEmergencyContactDetails(emergencyContacts);

	const initialValues = {
		name: isCreateAccount ? '' : name,
		phoneNumber: phoneNumber,
		email: email,
		street: `${street || ''} ${streetNum || ''}`,
		city: city,
		state: state,
		zip: zip,
		emergencyContactName: emergencyContactDetails?.name,
		emergencyContactPhone: emergencyContactDetails?.phoneNumber,
	};
	return initialValues;
};

export const cantactDetailsValidation = (values: CreateOrEditCustomerDto) => {
	const errors = {};
	if (!values.emergencyContactName && values.emergencyContactPhone) {
		errors[EMERGENCY_CONTACT_NAME] = REQUIRED;
	}
	if (!values.emergencyContactPhone && values.emergencyContactName) {
		errors[EMERGENCY_CONTACT_PHONE] = REQUIRED;
	}
	return errors;
};
