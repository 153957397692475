/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactNode, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor, IColors, Icons } from '../../../types';
import { useColors } from '../../../hooks/useColors';
import { Button } from '../../../components/Button';
import { PaymentMethodRadio } from './paymentMethod';
import { flexBetweenCss, flexCss, fullWidthCss, gapCss, mobileOnly } from '../../../styles/utils';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { Pricify } from '../../../lib/price';
import { PaymentMethod, PaymentMethodTypeEnum } from '../../../types/entities/payment';
import { BackButton } from '../../BackButtton';
import { iconCss } from '../../../organisms/refundPopup/style';
import { BnIcon } from '../../../components/icons_v2';
import { i18n } from '../../../lib/i18n';

import { EStripeCardFundingType } from './types';
import { findFee } from '../../../organisms/invoiceTemplate/lib';
import { oldThemeColors as brandColors } from '../../../styles/theme';

const flex = css`
	display: flex;
	gap: 4px;
`;

const buttonContainerCss = css`
	${flex};
	.primary {
		:not(:disabled) {
			background: ${brandColors.brandPrimary} !important;
		}
	}
`;

const iconContainerCss = (colors: IColors) => css`
	${iconCss(String(colors.bg_error), 20, '0')};
	flex-shrink: 0;
`;

const paymentMethodContainerCss = css`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	> header {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}
`;

const alignCenterCss = css`
	align-items: center;
`;

const methodsWrapperCss = (colors: IColors) => css`
	padding-top: 18px;
	padding-bottom: 20px;
	border-bottom: 1px solid ${colors.bg_border_seperator};
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const footerCss = css`
	display: flex;
	justify-content: end;
	margin-top: 36px;
	button {
		padding: 14px 46px !important;
	}
	${mobileOnly} {
		background: white;
		z-index: 20;
		position: fixed;
		bottom: 0;
		align-items: center;
		justify-content: space-between;
		margin-top: 0 !important;

		left: 0;
		right: 0;
		box-shadow: 0px -4px 8px rgba(61, 64, 67, 0.1);
		padding: 20px 16px;
		button {
			padding: 10px 12px !important;
		}
	}
`;

const selectableContainerCss = (colors: IColors) => css`
	padding: 20px 0;
	border-bottom: 1px solid ${colors.bg_border_seperator};
	gap: 16px;
	display: flex;
	flex-direction: column;
	${mobileOnly} {
		padding-bottom: 100px;
	}
`;

const cardWrapperCss = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const backButtonCss = css`
	button {
		text-transform: capitalize !important;
		padding-left: 0 !important;
	}
`;

export const PaymentMethodContainer = ({
	addPaymemtButtons,
	paymentMethods,
	paymentPageToggle,
	handlePay,
	state,
	labels,
	selected,
	handleSelect,
	isPayButtonDisabled,
	amountToPay,
	handleBack,
}: {
	paymentPageToggle: () => void;
	addPaymemtButtons: ReactNode;
	paymentMethods: any[];
	handlePay: (selectedPaymentMethod: any, onDone: () => void, amountToPay: number) => void;
	state: any;
	labels: any;
	selected?: PaymentMethod;
	handleSelect: (v: PaymentMethod) => void;
	isPayButtonDisabled?: boolean;
	amountToPay: number;
	handleBack: () => void;
}) => {
	const { isMobile } = useWindowSize();
	const { colors } = useColors();
	const [isLoading, setLoading] = useState(false);

	const showGeneralFeeWarning = !!state.fees?.length;
	const selectedFee = findFee(state.fees ?? [], selected);
	const feePercentage = selectedFee?.percentageValue ? `${selectedFee.percentageValue}` : undefined;
	const feeFixed = `${selectedFee?.fixValue ?? ''}`;
	const isCreditCard =
		!selected?.card &&
		![EStripeCardFundingType.DEBIT, EStripeCardFundingType.PREPAID, EStripeCardFundingType.UNKNOWN].includes(
			selected?.card?.funding as EStripeCardFundingType
		);
	const showFeeWarning = selected && (feePercentage || feeFixed) && (!selected.card || !isCreditCard);
	const methodType = selected?.type as PaymentMethodTypeEnum.CARD | PaymentMethodTypeEnum.ACH;

	const paymentMethodsLabels = labels.paymentMethods;

	return (
		<div css={paymentMethodContainerCss} data-aid="paymentMethodContainer-invoicingCheckoutLayout">
			<header>
				<Typography type={ETypography.h5} color={ETypographyColor.primary}>
					{paymentMethodsLabels.title}
				</Typography>
				<Typography type={ETypography.body1} color={ETypographyColor.primary}>
					{paymentMethodsLabels.description}
				</Typography>
			</header>
			<div css={methodsWrapperCss(colors)}>
				<div css={[flexCss, gapCss(18)]}>{addPaymemtButtons}</div>
				{showGeneralFeeWarning && (
					<div css={[flexCss, alignCenterCss]}>
						<Typography type={ETypography.captionAccented} color={ETypographyColor.primary}>
							{i18n.invoice.card.defaultFeeNotice}
						</Typography>
					</div>
				)}
			</div>
			{paymentMethods?.length > 0 && (
				<div css={selectableContainerCss(colors)}>
					<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
						{paymentMethodsLabels.saved}
					</Typography>
					{showFeeWarning && (
						<div css={[flexCss, gapCss(10), alignCenterCss]}>
							<div css={iconContainerCss(colors)}>
								<BnIcon icon={Icons.conflict} />
							</div>
							<Typography type={ETypography.captionAccented} color={ETypographyColor.primary}>
								{i18n.invoice.card.feeNotice(feePercentage, feeFixed, i18n.invoice.card.methodTypes[methodType])}
							</Typography>
						</div>
					)}
					<div css={cardWrapperCss}>
						{paymentMethods?.map(paymentMethod => (
							<PaymentMethodRadio
								key={paymentMethod?.id}
								state={paymentMethod}
								isChecked={selected?.id === paymentMethod?.id}
								handleSelect={handleSelect}
							/>
						))}
					</div>
				</div>
			)}
			<div css={footerCss}>
				{isMobile && (
					<div css={flex}>
						<Typography type={ETypography.body1} color={ETypographyColor.primary}>
							{paymentMethodsLabels.leftToPay}
						</Typography>
						<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
							{Pricify(state?.paidAmount ? state?.price - state?.paidAmount : state?.price)}
						</Typography>
					</div>
				)}
				<div css={[flexBetweenCss, fullWidthCss]}>
					<div css={backButtonCss}>
						<BackButton handleBack={handleBack} sizer="M" />
					</div>
					<div css={buttonContainerCss}>
						{isMobile && (
							<Button data-aid="button-paymentMethodContainer-cancel" sizer="M" theme="basic" onClick={paymentPageToggle}>
								{paymentMethodsLabels.cancel}
							</Button>
						)}
						<Button
							data-aid="button-paymentMethodContainer-set"
							sizer="M"
							theme="primary"
							isProcessing={isLoading}
							disabled={isPayButtonDisabled}
							onClick={() => {
								setLoading(true);
								handlePay(selected, () => setLoading(false), amountToPay);
							}}
						>
							{`${paymentMethodsLabels.pay} ${Pricify(amountToPay)}`}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
