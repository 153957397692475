import { EDiscountUnit } from '../../../molecules/discount-amount-input';
import { SerializedStyles } from '@emotion/react';
import { StateEnum } from './promoCodes';
import { IOption } from '../../../types';
import { GetOrganizationPromoCodesDto, PaginationResultDto } from '@bondsports/types';

export interface IDiscountModalLabels {
	title: string;
	subtitles: string[];
	controls: {
		cancel: string;
		confirm: string;
		amount: {
			label: string;
			afterDiscount: string;
		};
	};
}

export interface IReason {
	id: number;
	organizationId: number;
	reason: string;
	type: string;
	ordinal: number;
}

export type TDiscountApplicant = 'invoice' | 'lineItem';

export interface DiscountModalProps {
	isOpen: boolean;
	totalAmount: number;
	discountType: TDiscountApplicant;
	reasons: IReason[];
	invoiceSubtotalBalance?: number;
	onClose: (state?: TDiscountModalState) => void;
	withTabs?: boolean;
	isAppliedPromoCode?: boolean;
	fetchData?: (queryData: GetOrganizationPromoCodesDto) => Promise<PaginationResultDto<IOption>>;
}

export interface ErrorRowProps {
	labels: {
		discountError: (val: string, percent: string) => string;
		notes: {
			private: { openNote: string; closedNote: string };
			initialPublic: string;
			public: { openNote: string; closedNote: string };
			initialPrivateNote: string;
			both: { private: string; public: string };
		};
		reasonSelect: { label: string; placeholder: string };
	};
	amount: number;
	percentage: number;
	styles: SerializedStyles;
}

export type TCustomState = {
	amount?: number;
	type?: EDiscountUnit;
	reason?: number;
	publicNote?: string;
	privateNote?: string;
};

export interface IPromoCodeState {
	codeDiscountId?: number;
	initialComponentState?: StateEnum;
	options?: IOption[];
	totalPages?: number;
	page?: number;
	itemsPerPage?: number;
	publicNote?: string;
	privateNote?: string;
}

export interface IPromoCodeDiscountInfo {
	publicNote?: string;
	privateNote?: string;
}

export enum EDiscountStateActionsType {
	SET_STATE = 'SET_STATE',
	RESET = 'RESET',
}

export type TCustomStateActions =
	| { type: EDiscountStateActionsType.SET_STATE; payload: TCustomState }
	| { type: EDiscountStateActionsType.RESET };

export type TPromoCodeStateActions =
	| { type: EDiscountStateActionsType.SET_STATE; payload: IPromoCodeState }
	| { type: EDiscountStateActionsType.RESET };

export interface DiscountFooterFooterProps {
	isValid: boolean;
	onSubmit: () => void;
	onCancel: () => void;
}

export enum DiscountModalTabEnum {
	PROMO_CODE = 'promoCode',
	CUSTOM = 'custom',
}

export type TabLabels = {
	[key in DiscountModalTabEnum]: string;
};

export type TDiscountModalState = {
	discountType: DiscountModalTabEnum;
	discountId?: number;
	discountState: TCustomState;
};

export interface DiscountModalHookReturn {
	handleOnChangeCustom: (data: {
		amount: number;
		type: EDiscountUnit;
		reason: number;
		publicNote: string;
		privateNote: string;
	}) => void;
	handleOnChangePromoCode: (newState: IPromoCodeState) => void;
	isValid: boolean;
	resetStates: () => void;
	currentTab?: DiscountModalTabEnum;
	customState: {
		amount?: number;
		type?: EDiscountUnit;
		reason?: number;
		publicNote?: string;
		privateNote?: string;
	};
	promoCodeState: IPromoCodeState;
	resetCurrentTab: () => void;
	remainingAmount: number;
	remainingPercentage: number;
	tabs: { title: string; link: DiscountModalTabEnum }[];
	handleTabChange: (tab: DiscountModalTabEnum) => void;
}
