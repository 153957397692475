/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	Elements,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe, StripeCardElement, StripeElementChangeEvent } from '@stripe/stripe-js';
import { useLayout } from 'app/react/hooks/useLayout';
import { organizationApi } from 'app/react/lib/api/organizationApi';
import React, { useEffect, useState } from 'react';
import { Form, useFormState } from 'react-final-form';
import { environment } from '../../../../../environments/environment';
import { paymentApi } from '../../../lib/api/paymentApi';
import { colors } from '../../../styles/theme';
import { flex, flexCol } from '../../../styles/utils';
import { InputLabel, withLabelContainer } from '../../shared/Input/InputLabel';
import { isNil } from 'lodash';
import { TranslationEn } from '@assets/i18n/en';
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import { gapCss } from '@app/react/styles/utils';
import { getRelevantErrorMessage } from '../../../lib/stripe.util';
import { FuturePaymentStatusEnum, PaymentMethodTypeEnum } from '@bondsports/types';
const container = css`
	${flexCol};
	padding: 2rem;
	max-width: 320px;
	margin: 0 2rem;
`;

const contentBetween = css`
	${flex};
	max-width: 320px;
	${gapCss(12)};
	justify-content: space-between;
`;

export interface INewCardProps {
	userId: any;
	trigger: any;
	handleError: any;
	organizationId: any;
	onIsValidated?: (validated: boolean) => void;
	onSubmitAction?: (pm: any, method: any) => void;
}

export const NewCard = ({
	userId,
	trigger,
	onSubmitAction,
	handleError,
	organizationId,
	onIsValidated,
}: INewCardProps) => {
	const { disabledScreenToggle } = useLayout();
	const [name, setName] = useState('');
	const [stripeValidated, setStripeValidated] = useState({
		cardNumber: false,
		expirationDate: false,
		securityCode: false,
	});
	const [address, setAddress] = useState({
		city: null,
		line1: null,
		postal_code: null,
		state: null,
	});
	const [anonymous, setAnonymous] = useState<string>('');
	const [stripe, setStripe] = useState<{
		stripe: unknown;
		// eslint-disable-next-line camelcase
		client_secret?: string;
		stripeCartElement: StripeCardElement;
	} | null>(null);
	const [stripePromise, setStripePromise] = useState<Promise<any>>(null);
	const stripeErrors = TranslationEn.payments.stripePaymentDeclineErrorCodes;
	let submit;

	useEffect(() => {
		const isAddressNotFilled = !isNil(Object.keys(address).find(key => !address[key]));
		onIsValidated(
			name &&
				!isAddressNotFilled &&
				stripeValidated.expirationDate &&
				stripeValidated.cardNumber &&
				stripeValidated.securityCode
		);
	}, [name, address, stripeValidated]);

	useEffect(() => {
		if (!userId) {
			organizationApi.getAnonymousCustomer(organizationId).then(({ data }) => {
				setAnonymous(data.entityId ? String(data.entityId) : null);
			});
		}
		const stripeInstance = loadStripe(environment.STRIPE_KEY || 'missing-stripe.key');
		if (stripeInstance) setStripePromise(stripeInstance);

		return () => {
			onIsValidated(false);
		};
	}, []);

	useEffect(() => {
		if (trigger) {
			submit();
		}
	}, [trigger]);

	const onClose = (obj, str) => {};

	// call stripe to confirm the entered
	async function confirmCardStripe(
		stripe: unknown,
		// eslint-disable-next-line camelcase
		client_secret: unknown,
		stripeCartElement: unknown,
		onSuccess: (setupIntent: unknown) => void,
		onError: (message: string) => void,
		name?: string
	) {
		if (!stripe || !stripeCartElement) {
			return;
		}

		try {
			// @ts-ignore
			const { error, setupIntent } = await stripe.confirmCardSetup(client_secret, {
				payment_method: {
					card: stripeCartElement,
					billing_details: { name, address },
				},
			});
			if (error) {
				const message = getRelevantErrorMessage(error);
				// Show error to your customer (e.g., insufficient funds)
				onError(message);
			} else {
				// The payment has been processed!
				if (setupIntent.status === FuturePaymentStatusEnum.SUCCEEDED) {
					onSuccess({ result: setupIntent });
					onSubmitAction(setupIntent.payment_method, PaymentMethodTypeEnum.CARD);
				}
			}
		} catch {
			onError(stripeErrors.unknown);
		}
	}

	const checkCreditCard = async () => {
		disabledScreenToggle(true);
		paymentApi
			.getPaymentSecret(organizationId, userId || anonymous)
			.then(clientSecret => {
				confirmCardStripe(
					stripe.stripe,
					clientSecret,
					stripe.stripeCartElement,
					intent => {
						if (onClose) {
							disabledScreenToggle(false);
							onClose({ intent }, 'backdropClick');
						}
					},
					err => {
						disabledScreenToggle(false);
						handleError(err);
					},
					name
				);
			})
			.catch(err => {
				disabledScreenToggle(false);
				handleError(err.message ? err.message : err);
			});
	};

	return (
		<div data-aid="newCreditCard-check" css={container}>
			<Elements stripe={stripePromise}>
				<Form
					onSubmit={() => {
						checkCreditCard();
					}}
					render={({ handleSubmit }) => {
						submit = handleSubmit;
						return (
							<form autoComplete="off" onSubmit={handleSubmit}>
								<StripeFormContent
									setAddress={setAddress}
									setName={setName}
									setStripe={setStripe}
									onStripeElementValueChanged={(elementName, isOk) =>
										setStripeValidated(prev => ({ ...prev, [elementName]: isOk }))
									}
								/>
							</form>
						);
					}}
				/>
			</Elements>
		</div>
	);
};

const withLabelContainerStripe = css`
	${withLabelContainer};
	max-width: 320px;
	.StripeElement {
		background: rgba(13, 71, 116, 0.04);
		position: relative;
		padding: 1rem;
		border: 1px solid transparent;
	}
	#cardNumber {
		&:before {
			display: block;
			content: ' ';
			background-image: url('assets/media/icons/payments/card.svg');
			background-size: 28px 28px;
			height: 28px;
			width: 28px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		.__PrivateStripeElement {
			left: 40px;
			width: 80%;
		}
	}
	.StripeElement--focus {
		border: 1px solid ${colors.borderPrimary};
	}
`;

const withLabelContainerHalf = css`
	${withLabelContainerStripe};
	width: 50%;
`;

const StripeFormContent = ({ setName, setStripe, setAddress, onStripeElementValueChanged }) => {
	const labels = TranslationEn.payments.newCard;

	const stripe = useStripe();
	const elements = useElements();
	const [val, setVal] = useState('');
	const { values } = useFormState();

	useEffect(() => {
		const setAddressFields = (prevAddress, newAddress) => {
			for (const key in prevAddress) {
				if (newAddress[key] === undefined) {
					newAddress[key] = null;
				}
			}

			return newAddress;
		};

		setName(values.name);
		const newValues = { ...values };
		delete newValues.name;
		setAddress(prev => setAddressFields(prev, newValues));
	}, [values]);

	useEffect(() => {
		setName(val);
	}, [val]);

	useEffect(() => {
		if (!stripe || !elements) {
			return;
		}

		const cardNumberElement: unknown = elements.getElement(CardNumberElement);

		if (!cardNumberElement) {
			throw new Error('should never happen - stripe failed to create the card element');
		}

		const stripeCartElement = cardNumberElement as StripeCardElement;

		setStripe({ stripe, stripeCartElement });
	}, [stripe, elements, setStripe]);

	const onStripeElementChanged = (elementName: string, e: StripeElementChangeEvent) => {
		if (e.complete) {
			onStripeElementValueChanged(elementName, true);
			return;
		}

		if (e.error || e.empty) {
			onStripeElementValueChanged(elementName, false);
			return;
		}
	};

	return (
		<div data-aid="newCreditCard">
			<React.Fragment>
				<InputLabel dataAid="selectMethod-name" label="Name on Card" name="name" />
				<div css={withLabelContainerStripe}>
					<label>
						<Typography color={ETypographyColor.secondary} type={ETypography.captionAccented}>
							{labels.cardNumber}
						</Typography>
					</label>
					<CardNumberElement
						id="cardNumber"
						onChange={e => onStripeElementChanged('cardNumber', e)}
						options={{
							style: {
								base: {
									'::placeholder': {
										color: 'transparent',
									},
								},
							},
						}}
					/>
				</div>
				<div css={contentBetween}>
					<div data-aid="newCard-input-expired" css={withLabelContainerHalf}>
						<label>
							<Typography color={ETypographyColor.secondary} type={ETypography.captionAccented}>
								{labels.expirationDate}
							</Typography>
						</label>
						<CardExpiryElement
							id="expirationDate"
							onChange={e => onStripeElementChanged('expirationDate', e)}
							options={{
								style: {
									base: {
										'::placeholder': {
											color: 'transparent',
										},
									},
								},
							}}
						/>
					</div>
					<div data-aid="newCard-input-code" css={withLabelContainerHalf}>
						<label>
							<Typography color={ETypographyColor.secondary} type={ETypography.captionAccented}>
								{labels.securityCode}
							</Typography>
						</label>
						<CardCvcElement
							id="securityCode"
							onChange={e => onStripeElementChanged('securityCode', e)}
							options={{
								style: {
									base: {
										'::placeholder': {
											color: 'transparent',
										},
									},
								},
							}}
						/>
					</div>
				</div>
				<InputLabel
					dataAid="selectMethod-address"
					location
					label="Billing Address"
					name="line1"
					type={ETypography.captionAccented}
				/>
				<div css={contentBetween}>
					<InputLabel dataAid="selectMethod-city" label="City" name="city" type={ETypography.captionAccented} />
					<InputLabel dataAid="selectMethod-state" label="State" name="state" type={ETypography.captionAccented} />
					<InputLabel
						dataAid="selectMethod-zip"
						label="Zip Code"
						name="postal_code"
						type={ETypography.captionAccented}
					/>
				</div>
			</React.Fragment>
		</div>
	);
};
