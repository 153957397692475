import * as  React from 'react';

export const BnBell = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5996 11.1701L17.1004 12.6734C17.4912 13.0643 17.6062 13.6476 17.3937 14.1593C17.1821 14.6701 16.6887 15.0001 16.1354 15.0001H13.3329V15.2843C13.3329 16.9659 11.8379 18.3334 9.99955 18.3334C8.16122 18.3334 6.66621 16.9659 6.66621 15.2843V15.0001H3.86371C3.31038 15.0001 2.81621 14.6701 2.60371 14.1584C2.39205 13.6476 2.50788 13.0651 2.89871 12.6734L4.39955 11.1701L4.40038 7.2726C4.40038 5.66094 5.09871 4.12427 6.31871 3.05594C7.53122 1.99261 9.14788 1.50344 10.7495 1.71677C13.5146 2.08261 15.5996 4.54677 15.5996 7.4476V11.1701ZM4.59538 13.3335L5.57871 12.3485C5.89371 12.0335 6.06704 11.6151 6.06704 11.1701V7.27264C6.06704 6.14181 6.55871 5.06098 7.41704 4.30931C8.28204 3.55098 9.38371 3.21765 10.5312 3.36848C12.4704 3.62598 13.9329 5.37931 13.9329 7.44764V11.1701C13.9329 11.6151 14.1062 12.0335 14.4204 12.3476L15.4045 13.3335H4.59538ZM11.6662 15.2842C11.6662 16.0334 10.9029 16.6667 9.99956 16.6667C9.09623 16.6667 8.33289 16.0334 8.33289 15.2842V15.0001H11.6662V15.2842Z"
        fill="currentColor"
      />
    </svg>
  );
};
