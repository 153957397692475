/** @jsx jsx */
import React, { useRef } from 'react';
import { jsx, css } from '@emotion/react';
import { IconButton, KeyboardArrowDownIcon, KeyboardArrowUpIcon } from '../IconButton';
import { cellCss, expandableButtonCss } from '../styles';
import { IDefaultCellProps } from './Default';
import { EllipsisContainer } from '../../EllipsisContainer';

//TEMP-COMPONENT should create new one with reusable code
interface Props extends IDefaultCellProps {
	isUnderlined?: boolean;
}

export const LongString = ({
	column,
	row,
	expandOn,
	isLast,
	expandableKey = '',
	isExpanded = false,
	setIsExpanded,
}: Props) => {
	const { isUnderlined } = column;

	const expandOnArr = Array.isArray(expandOn) ? expandOn : typeof expandOn === 'string' ? [expandOn] : [];
	const ref = useRef(null);

	const handleClick = e => {
		const btn = ref.current;
		if (btn && !btn.contains(e.target)) {
			if (column.action) {
				column.action(row);
			}
		}
		if (!btn) {
			if (column.action) {
				column.action(row);
			}
		}
	};
	const content = expandOnArr.includes(column.id) ? row[column.id] || row.name || '-' : row[column.id] || '-';
	return (
		<td
			className="TableCell-root TableCell-body"
			key={column.id}
			onClick={e => handleClick(e)}
			align={column?.styling?.align ? (column?.styling?.align === 'right' ? 'right' : 'left') : 'center'}
			css={cellCss({ isAction: column.action, isLast, isUnderlined })}
		>
			<EllipsisContainer>{content}</EllipsisContainer>
			{row[expandableKey] && row[expandableKey].length > 1 && expandOnArr.includes(column.id) && (
				<IconButton ref={ref} css={expandableButtonCss} onClick={() => setIsExpanded(!isExpanded)}>
					{isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				</IconButton>
			)}
		</td>
	);
};
