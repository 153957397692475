type ThrottleFn<TArgs> = (args: TArgs) => void;

export type Throttle<TArgs> = (args: TArgs) => void;

export const throttle = <TArgs>(fn: ThrottleFn<TArgs>, delay: number): Throttle<TArgs> => {
	let timeout: ReturnType<typeof setTimeout>;

	return (args: TArgs) => {
		if (timeout) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(() => {
			fn(args);
		}, delay);
	};
};
