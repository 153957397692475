/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { marginTopCss } from '../../styles/utils';
import { Typography } from '../../atoms';
import { ETypography, ETypographyColor, Icons } from '../../types';
import {} from '../../components/Modal';
import { ModalBody } from '../../components/Modal/ModalBody';

const DANGER_RED = '#e23401';

const containerCss = css`
	${marginTopCss(18)};
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
`;

const errorMessageCss = css`
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	text-align: center;
	color: ${DANGER_RED};
	white-space: pre-line;
`;

const labels = {
	title: 'Error',
	buttonText: 'close',
	somethingWentWrong: 'Something went wrong:',
	pleaseTryAgainLater: 'Please try again later.',
};

export const ErrorModalBody = ({
	customWidth = 340,
	onClose,
	errorMessage,
	tryAgainText,
}: {
	customWidth?: number;
	onClose: () => void;
	errorMessage: string;
	tryAgainText?: string;
}) => {
	return (
		<ModalBody
			title={labels.title}
			mainButton={labels.buttonText}
			mainAction={onClose}
			iconColor={DANGER_RED}
			icon={Icons.conflict}
			customWidth={customWidth}
		>
			<div css={containerCss} data-aid="index-ErrorModalBody">
				<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
					{labels.somethingWentWrong}
				</Typography>
				<div css={errorMessageCss}>{errorMessage}</div>
				<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
					{tryAgainText || labels.pleaseTryAgainLater}
				</Typography>
			</div>
		</ModalBody>
	);
};
