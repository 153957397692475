/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { Modal } from 'app/react/components/shared/Modal';
import { ModalBody } from 'app/react/components/shared/ModalBody';
import { ENotificationType, Icons } from '@bondsports/utils';
import { useNotification } from '@app/react/hooks/useNotification';
import { colors } from '@app/react/styles/theme';
import { IErrorArr, ISuccess } from '@app/react/types/errors';

interface ILabels {
	title: string;
	description: string;
	mainButton: string;
	subButton: string;
	successMessage: string;
	errorMessage: string;
}

interface Props {
	isShowing: boolean;
	toggle: () => void;
	entityId: number;
	organizationId: number;
	labels: ILabels;
	updateEndPoint: (
		customerId: number,
		organizationId: number,
		data: {
			futureOnly: boolean;
		}
	) => Promise<IErrorArr | ISuccess>;
}

export const EditColorModal = ({ isShowing, toggle, entityId, organizationId, labels, updateEndPoint }: Props) => {
	const { setPopupNotification } = useNotification();

	const updateProgramColor = async (futureOnly: boolean) => {
		toggle();
		const response = await updateEndPoint(entityId, organizationId, { futureOnly });
		const notificationDate = (response as IErrorArr).err
			? { val: String((response as IErrorArr).err), type: ENotificationType.warning }
			: { val: labels.successMessage, type: ENotificationType.success };

		setPopupNotification(notificationDate.val, notificationDate.type);
	};

	const changeAll = () => {
		updateProgramColor(false);
	};

	const changeOnlyFuture = () => {
		updateProgramColor(true);
	};

	return (
		<Modal toggle={toggle} isShowing={isShowing} isDismissable={false}>
			<ModalBody
				title={labels.title}
				description={labels.description}
				icon={Icons.conflict}
				iconColor={colors.dangerRed}
				mainButton={labels.mainButton}
				mainAction={changeAll}
				subButton={labels.subButton}
				subAction={changeOnlyFuture}
			/>
		</Modal>
	);
};
