import React from 'react';

export const Boxing = (props: any) => (
  <svg
    data-aid="svgBoxing"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.4574 2C10.6928 2 8.96378 2.36562 7.80682 3.15C6.64986 3.93438 6.27273 5.16875 6.27273 6.3C6.27273 6.52188 6.41655 6.9 6.4517 7.2C6.12571 7.04063 5.84126 6.8 5.45455 6.8C4.10263 6.8 3 7.8875 3 9.2C3 14.2406 5.97869 17.2281 7.90909 18.45V22H19.3636V18.175C19.5075 17.9156 19.8175 17.3125 20.1562 16.05C20.5845 14.4563 21 11.9781 21 8.4C21 6.45938 20.6516 4.80312 19.4148 3.65C18.1779 2.49688 16.2443 2 13.4574 2ZM13.4574 3.6C16.0462 3.6 17.4844 4.075 18.2642 4.8C19.044 5.525 19.3636 6.65 19.3636 8.4C19.3636 11.8563 18.9609 14.1781 18.571 15.625C18.2546 16.7969 18.0788 17.0344 17.983 17.2H8.98295C7.51278 16.3406 4.63636 13.7906 4.63636 9.2C4.63636 8.7625 4.99751 8.4 5.45455 8.4C5.91797 8.4 6.27273 8.74688 6.27273 9.2C6.27273 11.1375 7.14205 12.3375 8.1392 12.825C9.13636 13.3125 10.2134 13.2 10.3636 13.2V11.6C9.95135 11.6 9.37926 11.6188 8.88068 11.375C8.44602 11.1625 8.08487 10.7 7.9858 9.6C8.42685 9.81875 8.91903 10 9.51989 10H16.0909V8.4H9.51989C8.625 8.4 8.40447 8.14062 8.19034 7.75C7.97621 7.35938 7.90909 6.75312 7.90909 6.3C7.90909 5.43125 8.04332 4.91562 8.72727 4.45C9.41122 3.98438 10.8494 3.6 13.4574 3.6ZM9.54545 18.8H17.7273V20.4H9.54545V18.8Z"
      fill="currentColor"
    />
  </svg>
);
