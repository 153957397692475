/** @jsx jsx */
import { flex } from 'app/react/styles/utils';
import { css, jsx } from '@emotion/react';
import { Button, Divider, Grid, List } from '@material-ui/core';
import { colors } from 'app/react/styles/theme';
import { CreateRegisteredAttendeeData, Purchased, UpdateRegisteredAttendeeData } from 'app/react/types/registration';
import { TranslationEn } from 'assets/i18n/en';
import { BnIcon, EBrandingColorsOptions, SpinningLoader, SuspenseWrapper } from '@bondsports/utils';
import isEqual from 'lodash/isEqual';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import {
	createRegisteredAttendee,
	getCustomer,
	getEntitledCustomers,
	updateRegisteredAttendee,
} from '../../lib/api/registrationApi';
import CustomerItem from './CustomerItem';
import { Icons } from 'app/react/styles/theme';
import { IApiError } from 'app/react/lib/network';
import { EntryStatusEnum } from '@bondsports/types';

const basicIconCss = css`
	color: ${colors.formInputBg};
`;

const titleCss = css`
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 1.4rem;
	line-height: 130%;
	color: ${colors.brandPrimary};
	margin-bottom: 4px;
	cursor: pointer;
`;

const dividerCss = css`
	border-left: 1px solid ${colors.borderSeperator};
	border-right: 1px solid ${colors.borderSeperator};
`;

const filterButtonCss = (divider = false) => css`
	text-transform: none;
	color: ${colors.formInputBg};
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	padding: 0px 15px;
	border-left: ${divider ? `1px solid ${colors.borderSeperator}` : 'none'};
	border-right: ${divider ? `1px solid ${colors.borderSeperator}` : 'none'};
	cursor: pointer;
`;

const containerCss = css`
	display: flex;
`;

const spacingCss = css`
	margin-left: 10px;
`;

const customersCss = css`
	margin-top: 14px;
	width: 500px;
`;

const filterWrapperCss = css`
	margin-left: -15px;
	${flex};
`;

interface FilterButtonProps {
	filter: (customer: any) => boolean;
	title: string;
	divider?: boolean;
}

interface PurchasedProps {
	seasonId: number;
	organizationId: number;
	eventId: number;
	punchCardSeason?: boolean;
	setRefreshOnClose: Dispatch<SetStateAction<boolean>>;
}
const Purchased = ({
	seasonId,
	organizationId,
	eventId,
	setRefreshOnClose,
	punchCardSeason = false,
}: PurchasedProps) => {
	const [customers, setCustomers] = useState<Purchased[] | null>(null);
	const [customersFilter, setCustomersFilter] = useState<(customer) => boolean>(() => () => true);
	const [displayedCustomers, setDisplayedCustomers] = useState<Purchased[]>([]);
	const [title, setTitle] = useState('');

	const registeredCustomersFilter = customer => customer.attendee_entryStatus === null && customer.attendee_id !== null;

	const checkedInCustomersFilter = customer => customer.attendee_entryStatus === EntryStatusEnum.ENTERED;
	{
		/* TODO: add treatment for missing payments */
	}
	const missingPaymentsCustomers = customer => false;

	const getEntitledCustomersFromApi = async (): Promise<Purchased[]> => {
		return await getEntitledCustomers(seasonId, organizationId, eventId, Boolean(punchCardSeason));
	};

	const getCustomersPicturesFromApi = (customersForPictures: Purchased[]): Promise<any>[] => {
		return customersForPictures.map(customer => getCustomer(customer.customer_id, organizationId));
	};

	/*const handleRegister = async (customer_entityId: number) => {
    const newRegisteredAttendeeData: CreateRegisteredAttendeeData = {
      eventId,
      attendeeId: customer_entityId,
    };

    const newRegisteredAttendee = await createRegisteredAttendee(organizationId, newRegisteredAttendeeData);

    if (newRegisteredAttendee) {
      const updatedCustomers = customers.map((customer) => {
        if (customer.customer_entityId !== customer_entityId) {
          return customer;
        }
        return { ...customer, attendee_id: newRegisteredAttendee.id };
      });
      setCustomers(updatedCustomers);
      setRefreshOnClose(true);
    }

    return newRegisteredAttendee.id;
  };*/

	const handleCheckIn = async (
		attendee_id: number,
		attendee_entryStatus: EntryStatusEnum,
		customer_entityId?: number
	) => {
		let newAttendeeId;
		if (customer_entityId) {
			// newAttendeeId = await handleRegister(customer_entityId);
		}

		const newEntryStatus = attendee_entryStatus === null ? EntryStatusEnum.ENTERED : null;
		const updateRegisteredAttendeesData: any = {
			id: attendee_id || newAttendeeId,
			entryStatus: newEntryStatus,
			eventId,
		};

		const updatedAttendee = await updateRegisteredAttendee(organizationId, updateRegisteredAttendeesData);
		if ((updatedAttendee as IApiError).err) {
			// handle error
		} else {
			const updeatedCustomers = customers.map(customer => {
				if (customer_entityId) {
					return customer.customer_entityId !== customer_entityId
						? customer
						: { ...customer, attendee_entryStatus: updatedAttendee.entryStatus, attendee_id: newAttendeeId };
				}
				return customer.attendee_id !== updatedAttendee.id
					? customer
					: { ...customer, attendee_entryStatus: updatedAttendee.entryStatus };
			});
			setCustomers(updeatedCustomers);
		}
	};

	const handlePicturesFromApi = (results: Purchased[]) => {
		Promise.all(getCustomersPicturesFromApi(results)).then(customersPicturesFromApi => {
			const customerWithPictures = customersPicturesFromApi.filter(
				customerPicture => customerPicture.data.profilePicture
			);
			const updatedCustomers = results.map(customer => {
				const customerPicture = customerWithPictures.find(
					customerPicture => customerPicture.data.id === customer.customer_id
				);
				if (!customerPicture) return customer;
				return { ...customer, profilePicture: customerPicture.data.profilePicture };
			});
			setCustomers(updatedCustomers);
			setDisplayedCustomers(updatedCustomers);
		});
	};

	useEffect(() => {
		getEntitledCustomersFromApi().then(results => {
			handlePicturesFromApi(results);
			setTitle(`${results.length} ${TranslationEn.eventRegistrationModal.purchased}`);
		});
	}, []);

	useEffect(() => {
		if (customers !== null) {
			setDisplayedCustomers(customers.filter(customersFilter));
		}
	}, [customers, customersFilter]);

	const handleFilter = (customersFilter: (customer: any) => boolean) => {
		const isFilterd = isEqual(displayedCustomers, customers.filter(customersFilter));
		if (displayedCustomers.length == 0 || isFilterd) {
			setCustomersFilter(() => () => true);
		} else {
			setCustomersFilter(() => customersFilter);
		}
	};

	const FilterButton: React.FC<FilterButtonProps> = ({ filter, title, divider = false }: FilterButtonProps) => (
		<div css={filterButtonCss(divider)} onClick={() => handleFilter(filter)}>
			{`${title} ${customers.filter(filter).length}`}
		</div>
	);

	return (
		<Fragment>
			<SuspenseWrapper loading={!customers}>
				<div css={containerCss}>
					<div css={basicIconCss}>
						<BnIcon icon={Icons.profiles} />
					</div>

					<div css={spacingCss}>
						<div css={titleCss}>{title}</div>

						<div css={filterWrapperCss}>
							<FilterButton
								filter={registeredCustomersFilter}
								title={TranslationEn.eventRegistrationModal.registered}
							/>

							<FilterButton
								filter={checkedInCustomersFilter}
								title={TranslationEn.eventRegistrationModal.checkedIn}
								divider
							/>

							<FilterButton filter={() => true} title={TranslationEn.eventRegistrationModal.missingPayments} />
						</div>
						<div css={customersCss}>
							{/*{displayedCustomers.map((customer) => (
								<CustomerItem
								key={customer.customer_id}
								customer={customer}
								handleRegister={handleRegister}
								handleCheckIn={handleCheckIn}
								/>
							))}*/}
						</div>
					</div>
				</div>
			</SuspenseWrapper>
		</Fragment>
	);
};
export default Purchased;
