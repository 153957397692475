/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { ETypography, ETypographyColor, IconWithTooltip, Icons, TagColorsMapper, Typography } from '@bondsports/utils';
import { flex, gapCss } from '@app/react/styles/utils';
import { PaymentAlertEnum } from '@app/react/types/customers';
import { TranslationEn } from '@assets/i18n/en';

export const PaymentStatus = ({ paymentAlertType }: { paymentAlertType: PaymentAlertEnum }) => {
	const { paymentAlertMapper } = TranslationEn.customers.overview.payments;
	return (
		<div css={[flex, gapCss(12)]}>
			<IconWithTooltip
				icon={Icons.indications_payment}
				text={paymentAlertMapper[paymentAlertType]}
				color={TagColorsMapper.red.primary}
				size={16}
			/>
			<Typography type={ETypography.body2} color={ETypographyColor.primary}>
				{paymentAlertMapper[paymentAlertType]}
			</Typography>
		</div>
	);
};
