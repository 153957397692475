import React, { useEffect, useState } from 'react';
import { StateEnum } from '../../types/promoCodes';
import { Typography } from '../../../../atoms';
import { ETypography, ETypographyColor } from '../../../../types';
import { Loader } from '../../../../components/Loader';
import { PromoCodeContent } from './promo-code-content';
import { IPromoCodeState } from '../../types';
import { PaginationResultDto } from '../../../../types/dtos';
import { i18n } from '../../../../lib/i18n';
import { IOption } from '../../../../../build/src';
import { GetOrganizationPromoCodesDto } from '@bondsports/types';

interface PromoCodeTabProps {
	onChange: (promoCodeState: Partial<IPromoCodeState>) => void;
	initialState: IPromoCodeState;
	fetchData: (queryData: GetOrganizationPromoCodesDto) => Promise<PaginationResultDto<IOption>>;
}

export const PromoCodeTab: React.FC<PromoCodeTabProps> = ({ onChange, initialState, fetchData }: PromoCodeTabProps) => {
	const [componentState, setCurrentState] = useState<StateEnum>(
		initialState?.initialComponentState || StateEnum.LOADING
	);
	const [options, setOptions] = useState(initialState?.options || []);
	const labels = i18n.addDiscount.content.promoCode;

	useEffect(() => {
		if (componentState === StateEnum.LOADING) {
			fetchData({ page: 1, itemsPerPage: initialState.itemsPerPage || 8 }).then(response => {
				if (response.data?.length > 0) {
					setOptions(response.data);
					onChange({
						options: response.data,
						initialComponentState: StateEnum.CODES,
						totalPages: response.meta.totalPages,
					});
					setCurrentState(StateEnum.CODES);
				} else {
					setCurrentState(StateEnum.NO_CODES);
				}
			});
		}
	}, []);

	switch (componentState) {
		case StateEnum.APPLIED:
			return (
				<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
					{labels.alreadyApplied}
				</Typography>
			);
		case StateEnum.LOADING:
			return <Loader />;
		case StateEnum.ERROR:
			return (
				<Typography type={ETypography.caption} color={ETypographyColor.red}>
					{labels.error}
				</Typography>
			);
		case StateEnum.NO_CODES:
			return (
				<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
					{labels.noValidCodes}
				</Typography>
			);
		case StateEnum.CODES:
			return <PromoCodeContent onChange={onChange} initialState={{ ...initialState, options }} fetchData={fetchData} />;
		default:
			return <div>{labels.defaultStateMessage}</div>;
	}
};
