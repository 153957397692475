/** @jsxRuntime classic */
/** @jsx jsx */

import React, { FC } from 'react';
import { css, jsx } from '@emotion/react';
import { ISlot } from './types';

export interface IEventComponentProps {
	event: ISlot;
	inList?: boolean;
}

const modalSlotContainerCss = css`
	position: relative;
	margin: 2px;
	height: 70px;
`;

interface IProps {
	slot: ISlot;
	SlotWrapper: FC<IEventComponentProps>;
}

export const SlotInModal = ({ slot, SlotWrapper }: IProps) => {
	return (
		<div css={modalSlotContainerCss} data-aid="SlotInModal-bo_calendar_slot">
			<SlotWrapper event={slot} inList />
		</div>
	);
};
