/** @jsxRuntime classic */
/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/react';
import { AnswerDto, AnswerTemplateCustomerDto, QuestionDto } from '@bondsports/types';
import { RCOrganization } from '../../../types/entities/coreEntites/Organization';
import { TemplateHeader } from './TemplateHeader';
import { CustomerDetails } from './CustomerDetails';
import { TemplateForm } from './TemplateForm';
import { containerCss } from '../styles';

export interface ApplicationTemplateProps {
	organization: RCOrganization;
	customer: AnswerTemplateCustomerDto;
	answers: AnswerDto[];
	questions: QuestionDto[];
	dateSigned: string | Date;
}

export const ApplicationTemplate: FC<ApplicationTemplateProps> = props => {
	const { organization, customer, questions, answers, dateSigned } = props;

	return (
		<div css={containerCss} data-aid="ApplicationTemplate">
			<TemplateHeader organization={organization} address={organization?.address} />
			<CustomerDetails customer={customer} dateSigned={dateSigned} />
			<TemplateForm answers={answers} questions={questions} />
		</div>
	);
};
