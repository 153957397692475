import { getFullName } from '@app/react/lib/utils';
import { IPayingUser, TChargeSteps } from '../../../../../app/react/types/payment';
import { TranslationEn } from '../../../../../assets/i18n/en';
import { PaymentMethodTypeEnum } from '@bondsports/types';

import { takeRight } from 'lodash';

const CARD_NUMBER_MASK = '****-****-****';

export const getPaymentMethodLabelByValue = (method: PaymentMethodTypeEnum | TChargeSteps) =>
	TranslationEn.payments.methods[method];

export const getPaymentMethodText = (payment: {
	paymentType: PaymentMethodTypeEnum;
	paymentMethodId: string;
	ccLast4?: string;
	ccBrand?: string;
	isPublicInvoice?: boolean;
}) => {
	if (!payment) {
		return '';
	}
	switch (payment.paymentType) {
		case PaymentMethodTypeEnum.CARD:
		case PaymentMethodTypeEnum.CARD_ON_TERMINAL:
			return payment.isPublicInvoice ? payment.ccLast4 : `${CARD_NUMBER_MASK}-${payment.ccLast4}`;
		case PaymentMethodTypeEnum.CHECK:
			return `${TranslationEn.payments.methods.check} #${payment.paymentMethodId}`;
		case PaymentMethodTypeEnum.GIFT_CARD:
			return `${TranslationEn.payments.methods['gift-card']} #${takeRight(payment.paymentMethodId, 5).join('')}`;
		case PaymentMethodTypeEnum.ACH:
			return `${TranslationEn.payments.methods[PaymentMethodTypeEnum.ACH]} #${takeRight(payment.ccLast4, 4).join('')}`;
		default:
			return getPaymentMethodLabelByValue(payment.paymentType) || '';
	}
};

export const getPaymentMethodPayingUserName = (isPublic: boolean, payingUser: IPayingUser) => {
	return isPublic ? getFullName(payingUser) : '';
};
