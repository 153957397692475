import * as  React from 'react';

export const BnClock = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0003 5.83358C10.4603 5.83358 10.8337 6.20608 10.8337 6.66691V9.16691H13.3337C13.7945 9.16691 14.167 9.53941 14.167 10.0002C14.167 10.4611 13.7945 10.8336 13.3337 10.8336H10.0003C9.54033 10.8336 9.16699 10.4611 9.16699 10.0002V6.66691C9.16699 6.20608 9.54033 5.83358 10.0003 5.83358Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0003 1.66675C5.40533 1.66675 1.66699 5.40508 1.66699 10.0001C1.66699 14.5951 5.40533 18.3334 10.0003 18.3334C14.5953 18.3334 18.3337 14.5951 18.3337 10.0001C18.3337 5.40508 14.5953 1.66675 10.0003 1.66675ZM3.33358 10.0002C3.33358 13.6761 6.32441 16.6669 10.0002 16.6669C13.6761 16.6669 16.6669 13.6761 16.6669 10.0002C16.6669 6.32441 13.6761 3.33358 10.0002 3.33358C6.32441 3.33358 3.33358 6.32441 3.33358 10.0002Z"
      fill="currentColor"
    />
  </svg>
);
