// normal toFixed has bugs :)

import { IPayment } from '../types/payment';

// see https://stackoverflow.com/questions/10015027/javascript-tofixed-not-rounding/23204425#23204425
function toFixed(num: number, precision: number) {
	return +(+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}

/**
 * rounds the price to cents - making sure we don't have cents fractions
 * e.g. price 25.10232323 will be 25.10
 */
export function roundPriceCents(price: number): number {
	return toFixed(price, 2);
}

/**
 * round the price to cents, 2 digits after the dot - making sure we don't have cents fractions.
 * change the price on place
 * @param any object with a payments property, of type IPayment[]
 * @returns a shallow copy of the given object, with its original structure but with rounded prices
 */
export const roundPricesOfPayment = <T extends { payments: IPayment[] }>(obj: T): T => {
	const roundedPayment = obj.payments.map(payment => {
		return { ...payment, price: roundPriceCents(payment.price) };
	});
	return { ...obj, payments: roundedPayment };
};
