import * as  React from 'react';

export const BnArrowUp = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 12.3756L10 7.49992L15 12.3756L14.0179 13.3333L10 9.41526L5.98209 13.3333L5 12.3756Z"
      fill="currentColor"
    />
  </svg>
);
