import React, { Fragment } from 'react';
import { Field as FinalField } from 'react-final-form';
import { Textarea, InputProps, TTextareaProps } from './Textarea';

export interface IFormProps extends InputProps {
	name: string;
	onClear?: (val: string) => void;
	required?: boolean;
	type?: string;
	noCarriageReturn?: boolean;
}

export const FormTextarea = ({
	onClear = (val: string) => {},
	type = 'string',
	...props
}: IFormProps & React.TextareaHTMLAttributes<HTMLTextAreaElement> & TTextareaProps) => {
	const clear = () => onClear(props.name);
	return (
		<FinalField
			name={props.name}
			validate={val => {
				if (props.required) {
					if (!val) {
						let errors = {};
						errors[props.name] = 'required';
						return errors;
					}
				}
				return undefined;
			}}
			displayEmpty
		>
			{({ input, meta }) => {
				const newProps = { ...props };

				return (
					<Fragment>
						<Textarea {...input} {...newProps} error={meta.error && meta.error[props.name]} clear={clear} />
					</Fragment>
				);
			}}
		</FinalField>
	);
};
