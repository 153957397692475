import React, { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { BnBack, BnForward } from '../../../icons';
import {
	DAY_OF_MONTH_FORMAT,
	FULL_MONTH_NAME_FORMAT,
	YEAR_FORMAT,
	YEAR_MONTH_DAY_FORMAT,
	YEAR_MONTH_FORMAT,
	weekDays,
} from './calander.consts';
import { StyledCalendar } from './style';
import { WeekDay } from '../../DateInput/comp/Date';

export interface CalendarProps {
	selectedMonth?: dayjs.Dayjs;
	disabledDates?: (day: Date) => boolean;
	onChangeMonth: (newMonth: Date | undefined) => void;
	onSelectDay: (selectedDay: Date) => void;
	selectedDate?: { startDate?: Date; endDate?: Date };
	showBackButton?: boolean;
	showForwardButton?: boolean;
}

const Calendar = ({
	selectedMonth,
	disabledDates,
	onChangeMonth,
	onSelectDay,
	selectedDate = { startDate: undefined, endDate: undefined },
	showBackButton = true,
	showForwardButton = true,
}: CalendarProps) => {
	const colors = useTheme();
	const [weeksAndDays, setWeeksAndDays] = useState<WeekDay[][]>([]);

	const chunkArray = (array: any[], size: number): WeekDay[][] => {
		const results = [];
		while (array.length) {
			results.push(array.splice(0, size));
		}
		return results;
	};

	const getWeekday = (date: string) => {
		return dayjs(date).day();
	};

	const createCalendar = (): WeekDay[] => {
		if (selectedMonth) {
			let currentMonthDays = [...Array(dayjs(`${selectedMonth.format(YEAR_MONTH_FORMAT)}-01`).daysInMonth())].map(
				(_, index) => {
					const dayOfMonth = index + 1;
					const date = selectedMonth.set('date', dayOfMonth).format(YEAR_MONTH_DAY_FORMAT);
					return {
						date,
						dayOfMonth,
						isCurrentMonth: true,
						isDisabled: disabledDates && disabledDates(dayjs(date).toDate()),
					};
				}
			);

			const currentMonthFirstDay = getWeekday(currentMonthDays[0].date);

			const daysOfPreviousMonth = currentMonthFirstDay ? currentMonthFirstDay - 1 : 6;

			const lastMondayOfPreviousMonth = dayjs(currentMonthDays[0].date).subtract(daysOfPreviousMonth, 'day').date();

			let previousMonthDays = [...Array(daysOfPreviousMonth)].map((_, index) => {
				const dayOfMonth = lastMondayOfPreviousMonth + index;
				const date = '';
				return {
					date,
					dayOfMonth,
					isCurrentMonth: false,
					isDisabled: disabledDates && disabledDates(dayjs(date).toDate()),
				};
			});

			const currentMonthLastDay = getWeekday(`${selectedMonth.format(YEAR_MONTH_FORMAT)}-${currentMonthDays.length}`);

			const daysOfNextMonth = currentMonthLastDay ? 7 - currentMonthLastDay : currentMonthLastDay;

			let nextMonthDays = [...Array(daysOfNextMonth)].map((_, index) => {
				const dayOfMonth = index + 1;
				const date = '';
				return {
					date,
					dayOfMonth,
					isCurrentMonth: false,
					isDisabled: disabledDates && disabledDates(dayjs(date).toDate()),
				};
			});

			return [...previousMonthDays, ...currentMonthDays, ...nextMonthDays];
		}

		return [];
	};

	const updateCalendar = () => {
		const days = createCalendar();
		const chunkedDays = chunkArray(days, 7);
		setWeeksAndDays(chunkedDays);
	};

	const goBack = () => {
		if (selectedMonth) {
			const newMonth = selectedMonth.subtract(1, 'month');
			onChangeMonth(newMonth.toDate());
		}
	};

	const goForward = () => {
		if (selectedMonth) {
			const newMonth = selectedMonth.add(1, 'month');
			onChangeMonth(newMonth.toDate());
		}
	};

	useEffect(() => {
		updateCalendar();
	}, [selectedMonth, selectedDate]);

	const isSelected = (selectedDate: { startDate?: Date | undefined; endDate?: Date | undefined }, date: string) => {
		return (
			(selectedDate.startDate && dayjs(date).isSame(selectedDate.startDate, 'day')) ||
			(selectedDate.endDate && dayjs(date).isSame(selectedDate.endDate, 'day'))
		);
	};

	return (
		<StyledCalendar theme={{ ...colors }}>
			<div data-aid="calendar_header" className="calendar_header">
				<button
					data-aid={showBackButton ? 'button-previous-month' : undefined}
					style={{ visibility: showBackButton ? 'visible' : 'hidden' }}
					onClick={goBack}
				>
					<BnBack />
				</button>
				<span
					data-aid={showBackButton ? 'button-left-month-month-year' : 'button-right-month-month-year'}
					left-month-month-year
					style={{ paddingBottom: '4px' }}
				>{`${selectedMonth?.format(FULL_MONTH_NAME_FORMAT)} ${selectedMonth?.format(YEAR_FORMAT)}`}</span>
				<button
					data-aid={showBackButton ? undefined : 'next-month'}
					style={{ visibility: showForwardButton ? 'visible' : 'hidden' }}
					onClick={goForward}
				>
					<BnForward />
				</button>
			</div>
			<div className="calendar_body" data-aid={showBackButton ? 'calendar_body-left-month' : 'calendar_body-right-month'}>
				<table>
					<thead>
						<tr>
							{weekDays.map((weekDay: string, key: number) => (
								<th key={key}>{weekDay}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{weeksAndDays?.map((week: WeekDay[], i: number) => (
							<tr key={i}>
								{week.map((day: WeekDay, a: number) => (
									<td key={a}>
										{day.date !== '' ? (
											<div
												data-day-is-blocked={`${day.isDisabled ? true : false}`}
												data-day-calendar={`${day.date}`}
												className={cx({
													disabledDay: day.isDisabled,
													notCurrentMonth: !day.isCurrentMonth,
													selected: isSelected(selectedDate, day.date),
													selectedRange:
														selectedDate.startDate &&
														selectedDate.endDate &&
														dayjs(day.date).isAfter(dayjs(selectedDate.startDate), 'day') &&
														dayjs(day.date).isBefore(dayjs(selectedDate.endDate), 'day'),
												})}
												onClick={() => {
													if (!day.isDisabled) {
														onSelectDay(dayjs(day.date).startOf('day').toDate());
													}
												}}
											>
												{dayjs(day.date).format(DAY_OF_MONTH_FORMAT)}
											</div>
										) : (
											<div className="empty_day">&nbsp;</div>
										)}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</StyledCalendar>
	);
};

export default Calendar;
