/* @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import { BnIcon, Icons } from '@bondsports/utils';

const sizeCss = (size: number) => css`
	width: ${size}px;
	height: ${size}px;
`;

const wrapperCss = (size: number) => css`
	${sizeCss(size)};
	svg {
		${sizeCss(size)};
	}
`;

const colorCss = (color: string) => css`
	color: ${color} !important;
`;

export const IconWrapper = ({ icon, color, size }: { icon: Icons; color: string; size: number }) => {
	return (
		<div css={wrapperCss(size)}>
			<BnIcon icon={icon} css={color && colorCss(color)} />
		</div>
	);
};
