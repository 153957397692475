/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/react';
import { Avatar, BnIcon, ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import { Icons } from '@bondsports/utils';
import { Stripe } from 'stripe';
import { ICustomer } from '../../../types/customers';

import { CheckboxButton } from '../utils';
import { flexCol, flex, gapCss, svgCss, marginBottomCss, heightCss } from '../../../styles/utils';
import { colors_v2 } from '../../../styles/theme';
import { EChargeInnerStep, TChargeSteps } from '../../../types/payment';
import { SwipeButton } from '../utils/swipeButton';
import { Mixpanel, MixpanelEvents } from 'app/react/lib/mixpanel';
import { TranslationEn } from '@assets/i18n/en';

const containerCss = css`
	${flexCol};
	gap: 18px;
	padding: 3rem 4rem 2rem 4rem;
	justify-content: center;
	align-items: flex-start;
	max-width: 500px;
`;

const wrapContainerCss = css`
	${flex};
	max-width: 100%;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 8px;

	button {
		margin: 0;
	}
`;

const topPartCss = css`
	${flexCol};
	margin: 0 0 6px 0;
	justify-content: flex-start;

	button {
		justify-content: center;
	}
`;

const iconCss = css`
	margin-right: 0.5rem;

	div {
		display: flex;
		justify-content: center;
		aling-items: center;
	}
`;

const customerCardCss = css`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 6px;
`;

const pointerCss = css`
	cursor: pointer;
`;

const manualButtonCss = css`
	${flex};

	&:hover {
		color: ${colors_v2.tx_text_primary};

		.body-2-accented {
			color: ${colors_v2.tx_text_primary};
		}

		svg {
			fill: ${colors_v2.tx_text_primary};
		}

		path {
			fill: ${colors_v2.tx_text_primary};
		}
	}
`;

interface IProps {
	cards: Stripe.PaymentMethod[];
	userId: number;
	customer?: ICustomer;
	selectedCard: string;
	setSelectedCard: (val: string) => void;
	setMethod: (val: TChargeSteps) => void;
	setFullSelectedCard?: (val) => void;
	amount?: number;
	currency?: 'usd';
	isSwipeEnabled?: boolean;
	isAnonymous?: boolean;
	triggerPay?: boolean;
	paymentAction?: (val: string, val2: string) => void;
	handleError?: (message: string) => void;
}

export const CreditCard = ({
	userId,
	selectedCard,
	setSelectedCard,
	setMethod,
	setFullSelectedCard,
	amount,
	currency = 'usd',
	isSwipeEnabled = true,
	triggerPay = false,
	paymentAction,
	handleError,
	cards,
	customer,
}: IProps) => {
	const labels = TranslationEn.payments;
	const shouldDisplayCards = cards?.length > 0 && !customer.isAnonymous;

	const handleSwipeReady = (clientSecret: string) => {
		setMethod(EChargeInnerStep.SWIPE);
		setSelectedCard(clientSecret);
	};

	const swipeProps = { amount, currency, userId, handleSwipeReady, triggerPay, paymentAction, handleError };

	const handlePickCC = async card => {
		Mixpanel.track(MixpanelEvents.START_ADD_NEW_CARD, {
			cart_total_price: amount,
		});
		setSelectedCard(card.id);
		if (setFullSelectedCard) {
			setFullSelectedCard(card);
		}
	};

	return (
		<div data-aid="CreditCard" css={containerCss}>
			<div css={customerCardCss}>
				<Avatar img={customer?.profilePicture?.url} imgStyle={{ height: '20px', width: '20px' }} />
				<Typography data-aid="CreditCard-name" type={ETypography.body2} color={ETypographyColor.primary}>
					{customer?.name?.trim() ? customer.name.trim() : labels.isGuest}
				</Typography>
			</div>
			<div data-aid="CreditCard-top" css={topPartCss}>
				{shouldDisplayCards && (
					<div css={[flexCol, gapCss(8)]}>
						<Typography type={ETypography.captionAccented} color={ETypographyColor.secondary}>
							{labels.useExisting}
						</Typography>
						<div data-aid="CreditCard-checkbox" css={wrapContainerCss}>
							{cards.map(card => {
								return (
									<CheckboxButton
										key={`credit_Checkboxkey-${card.id}`}
										onClick={() => handlePickCC(card)}
										active={selectedCard === card.id}
									>
										<BnIcon icon={Icons[card.card.brand]} css={iconCss} />
										{card.card.last4}
									</CheckboxButton>
								);
							})}
						</div>
					</div>
				)}
			</div>
			{shouldDisplayCards && (
				<Typography data-aid="CreditCard-label" type={ETypography.caption} color={ETypographyColor.secondary}>
					{labels.or}
				</Typography>
			)}
			<div
				onClick={() => {
					setMethod(EChargeInnerStep.NEW_CARD);
				}}
				css={[manualButtonCss, customerCardCss, marginBottomCss(0), pointerCss]}
			>
				<Typography data-aid="CreditCard-enter" type={ETypography.captionAccented} color={ETypographyColor.secondary}>
					{labels.enterManually}
				</Typography>
				<div data-aid="CreditCard-edit" css={[heightCss(20), svgCss('20px', '20px', colors_v2.tx_text_secondary)]}>
					<BnIcon icon={Icons.edit_outline} />
				</div>
			</div>

			{isSwipeEnabled && <SwipeButton {...swipeProps} />}
		</div>
	);
};
