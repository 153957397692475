/** @jsx jsx */
import React, { Fragment, Dispatch, SetStateAction, useEffect } from 'react';
import { css, jsx } from '@emotion/react';
import { Collapse } from '@material-ui/core';
import { FormPart } from 'app/react/components/customers/utils/formPart';
import { FrameContainer } from 'app/react/components/customers/utils/FrameContainer';
import { CTAButton } from 'app/react/components/shared/Button/CTAButton';
import { colors } from 'app/react/styles/theme';

import { TranslationEn } from 'assets/i18n/en';
import { Button, getIcon } from '@bondsports/utils';

import { useHistory } from 'react-router-dom';

const basicIconCss = css`
	color: ${colors.formInputBg};
`;

interface SeasonDetailsProps {
	description: string;
	longDescription: string;
}

const SeasonDetails = ({ description, longDescription }: SeasonDetailsProps) => {
	const [expanded, setExpanded] = React.useState(false);

	const history = useHistory();

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const moveToEditDetails = () => {
		window.location.pathname = window.location.pathname.replace('dashboard', 'basic');
	};

	return (
		<Fragment>
			<FrameContainer margin="0 1rem 2rem 1rem" title={TranslationEn.season.dashboard.seasonDetails.title}>
				<FormPart label={''} value={description} width={100} margin={0} />

				{longDescription && (
					<Fragment>
						<Button
							data-aid="button-SeasonDetails"
							sizer="S"
							theme="basic"
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
							css={basicIconCss}
						>
							{TranslationEn.season.dashboard.seasonDetails.viewMore}
							{expanded ? getIcon('arrow_up') : getIcon('arrow_down')}
						</Button>

						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<FormPart label={''} value={longDescription} width={100} margin={0} />
						</Collapse>
					</Fragment>
				)}
			</FrameContainer>
		</Fragment>
	);
};

export default SeasonDetails;
