import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { EDateFormats, EHoursFormat } from '../../../lib/timeUtils';

dayjs.extend(utc);
dayjs.extend(timezone);

const UTC_TIMEZONE = 'UTC';

/*
 * This function returns the formatDate and formatTime functions
 * that can be used to format date and time in the given timezone.
 * If no timezone is provided, it defaults to UTC.
 *
 * @param timezone - The timezone to use for formatting the date and time.
 * @returns The formatDate and formatTime functions.
 */
export function formatDateTimeTz(timezone: string = UTC_TIMEZONE) {
	const formatDate = (date: string) => {
		return timezone.toUpperCase() === UTC_TIMEZONE
			? dayjs(date).format(EDateFormats.DAY_FORMAT)
			: dayjs(date).tz(timezone).format(EDateFormats.DAY_FORMAT);
	};

	const formatTime = (date: string) => {
		return timezone.toUpperCase() === UTC_TIMEZONE
			? dayjs(date).format(EHoursFormat.H12_AMPM_UPPERCASE)
			: dayjs(date).tz(timezone).format(EHoursFormat.H12_AMPM_UPPERCASE);
	};
	return { formatDate, formatTime };
}
