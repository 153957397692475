import React from 'react';

export const Cornhole = (props: any) => (
  <svg
    data-aid="svgCornhole"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.3019 12.5919C17.1594 13.1273 17.0452 13.6562 16.953 14.1787C16.9036 13.6452 16.8753 13.1008 16.8753 12.5481C16.8753 10.6279 17.1768 8.81191 17.745 7.28729C18.4383 7.32931 19.1051 7.32566 19.7336 7.27542C18.726 8.66849 17.8591 10.5028 17.3019 12.5929V12.5919ZM16.2706 18.0866C14.7505 17.5732 12.9281 17.2745 10.9687 17.2745C9.00923 17.2745 7.18681 17.5732 5.66676 18.0866C6.17557 16.5702 6.47154 14.7551 6.47154 12.8039C6.47154 10.6416 6.10797 8.64566 5.49319 7.03974C7.04704 7.58966 8.93341 7.91121 10.9678 7.91121C13.0021 7.91121 14.8894 7.58875 16.4423 7.03974C15.8284 8.64566 15.464 10.6416 15.464 12.8039C15.464 14.7551 15.7608 16.5702 16.2697 18.0866H16.2706ZM13.1811 6.39207C12.5454 6.46332 11.8904 6.50169 11.2254 6.50169C10.5402 6.50169 9.86517 6.46241 9.21202 6.3875C9.2376 5.71061 9.21933 5.0602 9.15538 4.45181C10.2689 5.21549 11.6419 5.886 13.1811 6.39298V6.39207ZM12 2C6.47703 2 2 6.47703 2 12C2 17.523 6.47703 22 12 22C17.523 22 22 17.523 22 12C22 6.47703 17.523 2 12 2Z"
      fill="currentColor"
    />
  </svg>
);
