/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { colors } from 'app/react/styles/theme';
import { Button } from '@bondsports/utils';

const container = css`
	padding: 16px 40px;
	border-top: 1px solid ${colors.borderSeperator};
	display: flex;
	justify-content: flex-end;
	button {
		margin-left: 16px;
	}
`;

interface IFooter {
	toggle: () => void;
	actionButtonText: string;
	actionButtonMethod: () => void;
	isActionButtonDisabled: boolean;
	isActionButtonProcessing: boolean;
}

export const Footer = ({
	toggle,
	actionButtonText,
	actionButtonMethod,
	isActionButtonDisabled,
	isActionButtonProcessing,
}: IFooter) => {
	return (
		<div css={container}>
			<Button data-aid="button-paymentsFooter-cancel" disabled={isActionButtonProcessing} theme="secondary" sizer="S" onClick={toggle}>
				Cancel
			</Button>
			<Button
				data-aid="button-paymentsFooter-action"
				theme="primary"
				sizer="S"
				disabled={isActionButtonDisabled}
				onClick={actionButtonMethod}
				isProcessing={isActionButtonProcessing}
			>
				{actionButtonText}
			</Button>
		</div>
	);
};
