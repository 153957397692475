/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { cellCss } from '../styles';

export const Description = ({ row, column, isLast }) => {
	return (
		<td
			className="TableCell-root TableCell-body"
			css={cellCss({ isAction: column.action, isLast })}
			align={column?.styling?.align ? (column?.styling?.align === 'right' ? 'right' : 'left') : 'center'}
			onClick={() => {
				if (column.action) {
					column.action(row);
				}
			}}
			key={column.id}
		>
			{row[column.id]} {column.label}
		</td>
	);
};
