import { Invoice } from '@bondsports/types';
import { EDiscountUnit, roundPriceCents, DiscountModalTabEnum, TCustomState } from '@bondsports/utils';
import { paymentApi } from '../../../../lib/api/paymentApi';
import { cleanObjectOfNulls } from '../../../../lib/utils';
import {
	IDiscountAmountData,
	IDiscountData,
	IDiscountNote,
	IPostDiscountData,
	IRemoveDiscount,
	IRemoveDiscountData,
} from '../../../../types/payment';

const getDiscountAmountData = (
	discountType: DiscountModalTabEnum,
	discountState: TCustomState,
	remainingAmount: number
): IDiscountAmountData => {
	if (discountType === DiscountModalTabEnum.CUSTOM && discountState.amount && discountState.type) {
		const { amount, type } = discountState;
		const calculatedAmount = type === EDiscountUnit.AMOUNT ? amount : roundPriceCents((amount / 100) * remainingAmount);
		const calculatedPercentage = type === EDiscountUnit.PERCENT ? amount : null;
		return {
			amount: calculatedAmount,
			percentage: calculatedPercentage,
		};
	} else {
		return {
			amount: undefined,
			percentage: undefined,
		};
	}
};
export const useInvoiceDiscounts = () => {
	const addDiscount = async (
		discountType: DiscountModalTabEnum,
		organizationId: number,
		invoiceId: number,
		remainingAmount: number,
		discountState: TCustomState,
		discountId: number,
		lineItemsIds?: number[],
		shiftId?: number
	): Promise<Invoice | { err: string[] }> => {
		const { reason, publicNote, privateNote } = discountState;
		const notes: IDiscountNote[] = [];
		const { amount, percentage } = getDiscountAmountData(discountType, discountState, remainingAmount);
		const discountData: IDiscountData = {
			discountId,
			amount: amount,
			percentage: percentage,
			lineItemsIds: lineItemsIds?.length ? lineItemsIds : null,
		};

		if (publicNote)
			notes.push({
				content: publicNote.trim(),
				isPublic: true,
			});

		if (privateNote)
			notes.push({
				content: privateNote.trim(),
				isPublic: false,
			});

		const data: IPostDiscountData = {
			discounts: [discountData],
			reasonId: reason,
			notes: notes.length ? notes : null,
			shiftId,
		};
		cleanObjectOfNulls(data);

		return await paymentApi.postDiscounts(organizationId, invoiceId, data);
	};

	const removeDiscount = async (organizationId: number, invoiceId: number, actionId: string, shiftId?: number) => {
		const discountData: IRemoveDiscount = {
			actionId,
		};

		const data: IRemoveDiscountData = {
			discounts: [discountData],
			shiftId,
		};
		cleanObjectOfNulls(data);

		return await paymentApi.removeDiscounts(organizationId, invoiceId, data);
	};

	return { addDiscount, removeDiscount };
};
