import React from 'react';

export const Clock = (props: any) => (
  <svg
    data-aid="svgClock"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0002 7.00039C12.5522 7.00039 13.0002 7.44739 13.0002 8.00039V11.0004H16.0002C16.5532 11.0004 17.0002 11.4474 17.0002 12.0004C17.0002 12.5534 16.5532 13.0004 16.0002 13.0004H12.0002C11.4482 13.0004 11.0002 12.5534 11.0002 12.0004V8.00039C11.0002 7.44739 11.4482 7.00039 12.0002 7.00039Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0002 2.0002C6.48624 2.0002 2.00024 6.4862 2.00024 12.0002C2.00024 17.5142 6.48624 22.0002 12.0002 22.0002C17.5142 22.0002 22.0002 17.5142 22.0002 12.0002C22.0002 6.4862 17.5142 2.0002 12.0002 2.0002ZM4.00015 12.0004C4.00015 16.4114 7.58915 20.0004 12.0001 20.0004C16.4111 20.0004 20.0001 16.4114 20.0001 12.0004C20.0001 7.58939 16.4111 4.00039 12.0001 4.00039C7.58915 4.00039 4.00015 7.58939 4.00015 12.0004Z"
      fill="currentColor"
    />
  </svg>
);
