/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import { paperCss } from '../../shared/NewTable';
import { DisabledCustomSelect } from '../customSelect';
import { Input } from '../../shared/Input';
import { flex } from '../../../styles/utils';
import { NoTableRecordsReasonEnum } from '@app/react/types/enums';
import { INoTableRecords } from '@app/react/types/helpers';
import { TableRowsErorr } from '../../shared/NewTable/TableRowError';

const containerCss = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	input {
		margin-bottom: 0 !important;
	}
`;

const filterContainerCss = css`
	${flex}
	justify-content: flex-start;
	gap: 16px;
	margin: 20px 2rem;
`;

const tableContainerCss = css`
	height: 500px;
	flex-grow: 1;
	margin: 1rem 2rem 2rem 2rem;
`;

const EmptyTableCSS = css`
	${paperCss(true, true)};
	align-items: center;
	justify-content: center;
`;

export const EmptyTable = ({ message, type }: { message: string; type: NoTableRecordsReasonEnum }) => {
	//TEMP SOLUTION, should add red color to ETypographyColor (in utils...)
	const isEmpty = type === NoTableRecordsReasonEnum.EMPTY;
	// const textColor = isEmpty ? ETypographyColor.secondary : ETypographyColor.red;
	return (
		<div css={EmptyTableCSS}>
			{isEmpty ? (
				<Typography type={ETypography.body1Accented} color={ETypographyColor.secondary}>
					{message}
				</Typography>
			) : (
				<TableRowsErorr message={message} />
			)}
		</div>
	);
};

interface Props {
	noRowsObj: INoTableRecords;
	labels: { searchPlaceholder: string; filter: string };
}

export const EmptyTab = ({ noRowsObj, labels }: Props) => {
	return (
		<div data-aid="EmptyTab" css={containerCss}>
			<div css={filterContainerCss}>
				<Input data-aid="EmptyTab-input-filter" disabled placeholder={labels.searchPlaceholder} />
				<DisabledCustomSelect title={labels.filter} />
			</div>

			<div css={tableContainerCss}>
				<EmptyTable message={noRowsObj.message} type={noRowsObj.type} />
			</div>
		</div>
	);
};
