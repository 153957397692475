/** @jsx jsx */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { jsx, css } from '@emotion/react';
import { Table, ItemsPerPageCalculator } from '../../../shared/NewTable';
import { AlignType } from '../../../shared/NewTable/types';
import { CustomSelect } from '../../customSelect';
import { Input } from '../../../shared/Input';
import { CustomersService } from '@app/shared/services/customers/customers.service';
import * as queryString from 'querystring';
import { flexCol, flex, filterContainer, checkboxCss, CheckedIconCss, iconCss } from '../../../../styles/utils';
import { CustomCheckbox } from '../../../shared/Checkbox';
import { PaymentChargeStatusEnum, EPaymentMethod } from '../../../../types/payment';
import { TranslationEn } from 'assets/i18n/en';
import { useComingSoonPopUp } from '../../../shared/ComingSoon';
import { Capitalize } from '../../../../lib/form';
import * as moment from 'moment';
import { useDebounce } from '@app/react/hooks/useDebouncedSearch';
import { getPaymentMethodLabelByValue } from '@app/react/components/payments/utils/paymentMethods';
import { filterPaymentMethods } from '@app/react/components/shared/ChooseInvoice/utils';
import { PageContext } from '@app/react/pages/customers/PageContext';
import { NO_SUBJECT } from '../../../../types/helpers';

const containerCss = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
`;
const filterContainerCss = css`
	display: flex;
	align-items: center;
	margin: 5px 2rem;
`;

const tableContainerCss = css`
	height: 500px;
	flex-grow: 1;
	margin: 1rem 2rem 2rem 2rem;
`;

interface Props {
	customerState: any;
	customersService: CustomersService;
}

//REFACTOR REQUIRED TBD: duplicate code with Invoices
export const Payments = ({ customerState, customersService }: Props) => {
	const [isLoading, setLoading] = useState(true);
	const [tablePage, setPage] = useState(0);
	const [payments, setPayments] = useState([]);
	const [meta, setMeta] = useState({});
	const [statusFilter, setStatusFilter] = useState([]);
	const [methodFilter, setMethodFilter] = useState([]);
	const [searchFilter, setSearchFilter] = useState('');
	const history = useHistory();
	const location = useLocation();
	const tableContainerRef = useRef(null);
	const { itemsPerPage } = ItemsPerPageCalculator(tableContainerRef);
	const { ComingSoonToggle, ComingSoonModal } = useComingSoonPopUp();
	const [skipFirstFetch, setSkipFirstFetch] = useState<boolean>(true);
	const debouncedValue = useDebounce(searchFilter, 300);
	const { isMemberAdded } = useContext(PageContext);

	useEffect(() => {
		const parsed = queryString.parse(location.search.replace('?', ''));
		if (parsed.page) {
			setPage(Number(parsed.page) - 1);
		}
		return () => setPage(0);
	}, [location]);

	useEffect(() => {
		if (!isMemberAdded) {
			history.push(`${location.pathname}?page=1`);
		}
	}, [methodFilter, debouncedValue, statusFilter]);

	useEffect(() => {
		if (!skipFirstFetch) {
			setLoading(true);
			customersService
				.getPaymentsByCustomer(
					Number(customerState.id),
					tablePage + 1,
					itemsPerPage,
					statusFilter,
					methodFilter,
					debouncedValue
				)
				.toPromise()
				.then(data => {
					const newPayments = data.data.map((pay, index) => {
						let desc = '';
						pay.invoices
							.sort((a, b) => {
								if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
									return -1;
								} else {
									return 1;
								}
							})
							.map((invoice, index) => {
								if (index === 0) {
									desc = `Invoice ${invoice}`;
								} else {
									desc += `, ${invoice}`;
								}
							});
						pay.description = desc;

						pay.paymentMethod = Capitalize(getPaymentMethodLabelByValue(pay.paymentMethod));

						return pay;
					});

					setPayments(newPayments);
					setMeta(data.meta);
					setLoading(false);
				});
		}
		setSkipFirstFetch(false);
	}, [statusFilter, methodFilter, debouncedValue, tablePage, itemsPerPage]);

	const handleRedirection = (id: number) => {
		history.push(`${location.pathname}/${id}`);
	};
	const tableLabels = TranslationEn.customers.paymentsInvoices;
	const columns = [
		{
			id: 'createdAt',
			label: tableLabels.createdOn,
			styling: { align: AlignType.LEFT },
			type: 'datetime',
			action: row => handleRedirection(row.id),
		},
		{
			id: 'id',
			label: tableLabels.receiptNum,
			type: 'number',
			action: row => handleRedirection(row.id),
		},
		{
			id: 'description',
			label: TranslationEn.memberships.dashboard.details,
			type: 'string',
			action: row => handleRedirection(row.id),
		},
		{
			id: 'total',
			label: tableLabels.total,
			type: 'currency',
			action: row => handleRedirection(row.id),
		},
		{
			id: 'paymentMethod',
			label: tableLabels.paymentMethod,
			type: 'string',
			action: row => handleRedirection(row.id),
		},
		{
			id: 'status',
			label: tableLabels.status,
			type: 'status',
			action: row => handleRedirection(row.id),
		},
		{
			id: 'forward',
			label: '',
			type: 'icon',
			action: row => handleRedirection(row.id),
		},
	];

	const PaymenteFilters = () => {
		// still in progress
		return (
			<div data-aid="customerPayments-filters" css={filterContainer}>
				<label>{TranslationEn.customers.listColumns.status}</label>
				{Object.keys(PaymentChargeStatusEnum).map((key, index) => {
					return (
						<div data-aid="customerPayments-checkbox-tags" css={flex} key={index}>
							<CustomCheckbox
								css={checkboxCss}
								checkedIcon={<span id="checked" css={CheckedIconCss} />}
								icon={<span css={iconCss} />}
								checked={statusFilter.includes(key)}
								onChange={e => {
									if (e.target.checked) {
										setStatusFilter([...statusFilter, e.target.name]);
									} else {
										setStatusFilter(statusFilter.filter(status => status != e.target.name));
									}
								}}
								name={key}
							/>
							<div>{TranslationEn.customers.alternativeTags[key]}</div>
						</div>
					);
				})}
				<label>{TranslationEn.customers.paymentsInvoices.paymentMethod}</label>
				{filterPaymentMethods.map((method, index) => {
					return (
						<div data-aid="customerPayments-checkbox-methods" css={flex} key={index}>
							<CustomCheckbox
								css={checkboxCss}
								checkedIcon={<span id="checked" css={CheckedIconCss} />}
								icon={<span css={iconCss} />}
								checked={methodFilter.includes(method.value)}
								onChange={e => {
									if (e.target.checked) {
										setMethodFilter([...methodFilter, method.value]);
									} else {
										setMethodFilter(methodFilter.filter(method => method != e.target.name));
									}
								}}
								name={method.value}
							/>
							<div>{method.label}</div>
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<React.Fragment>
			<div css={containerCss}>
				<div css={filterContainerCss}>
					{/* <CustomSelect title="for checked" /> */}
					<CustomSelect title={TranslationEn.filter} content={<PaymenteFilters />} />
					<Input
					    data-aid="customerPayments-input-search"
						search
						placeholder={TranslationEn.payments.searchByPayment}
						value={searchFilter}
						onChange={e => setSearchFilter(e.target.value)}
						// onFocus={() => ComingSoonToggle()}
					/>
				</div>
				<div data-aid="customerPayments-table" ref={tableContainerRef} css={tableContainerCss}>
					<Table
						page={tablePage}
						ssr
						meta={meta}
						rows={payments}
						columns={columns}
						isLoading={isLoading}
						subject={TranslationEn.payments.tables.subject}
					/>
				</div>
			</div>
			<ComingSoonModal />
		</React.Fragment>
	);
};
