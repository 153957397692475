import React from 'react';

export const Info = (props: any) => (
  <svg
    data-aid="svgInfo"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C7.02975 3 3 7.02975 3 12C3 16.9702 7.02975 21 12 21C16.9702 21 21 16.9702 21 12C21 7.02975 16.9702 3 12 3ZM13.1251 16.5C13.1251 17.121 12.6211 17.6249 12.0001 17.6249C11.3791 17.6249 10.8751 17.1209 10.8751 16.5V10.8749C10.8751 10.2539 11.3791 9.74996 12.0001 9.74996C12.6211 9.74996 13.1251 10.254 13.1251 10.8749V16.5ZM10.8751 7.51801C10.8751 8.13898 11.3791 8.643 12.0001 8.643C12.6211 8.643 13.1251 8.13898 13.1251 7.51801C13.1251 6.89705 12.6211 6.39303 12.0001 6.39303C11.3791 6.39303 10.8751 6.89705 10.8751 7.51801Z"
      fill="currentColor"
    />
  </svg>
);
