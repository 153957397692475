import React, { useRef, useEffect, ComponentType } from 'react';
import { ListChildComponentProps } from 'react-window';

export type DecoratedRowProps<T> = T & {
	rowIndex: number;
};

export function createRow<TInnerProps>(
	RowComponent: ComponentType<TInnerProps>,
	onSetHeight: (index: number, size: number) => void
): ComponentType<ListChildComponentProps<TInnerProps[]>> {
	return ({ data, index, style }) => {
		if (!data[index]) {
			return null;
		}

		const ref = useRef<HTMLDivElement | null>(null);

		useEffect(() => {
			if (ref?.current) {
				onSetHeight(index, getElementHeightWithMargins(ref.current) ?? 100);
			}
		}, [ref]);

		const props: DecoratedRowProps<TInnerProps> = { ...data[index], rowIndex: index };

		return (
			<div style={style} data-testid={`infinite-list-row-${index}`}>
				<div ref={ref} style={{ display: 'inline-block' }}>
					<RowComponent {...props} />
				</div>
			</div>
		);
	};
}

const getElementHeightWithMargins = (node: HTMLDivElement): number => {
	const { offsetHeight } = node;
	const styles = window.getComputedStyle(node);

	return Math.ceil(offsetHeight + parseFloat(styles['margin-top']) + parseFloat(styles['margin-bottom']));
};
