/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { jsx, Theme } from '@emotion/react';
import { moreItemsButton } from './styles';

interface IProps {
	moreItemsCount: number;
	handleToggleModal: () => void;
	colors: Theme;
	isWeekly?: boolean;
}

export const MoreSlotsButton = ({ colors, moreItemsCount, handleToggleModal, isWeekly }: IProps) => {
	return (
		<button data-aid="button-MoreSlotsButton-toggle" css={moreItemsButton(colors, isWeekly)} onClick={handleToggleModal}>
			<div>{`+${moreItemsCount} \n more`}</div>
		</button>
	);
};
