/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from '@emotion/react';
import { TranslationEn } from '@assets/i18n/en';
import React from 'react';
import { InfoModalBody } from '../../utils/modals/InfoModalBody ';
import { LinkedMemberTypeEnum } from './types';

export const LinkedMember = ({
	familyName,
	linkedMemberType,
	onClose,
	toggle,
}: {
	familyName?: string;
	linkedMemberType: LinkedMemberTypeEnum;
	onClose?: () => void;
	toggle?: () => void;
}) => {
	const labels = TranslationEn.customers.familyPage.addFamilyMemberFlow.error;

	const isSameFamily = linkedMemberType === LinkedMemberTypeEnum.SAME_FAMILY;

	return (
		<InfoModalBody
			morInfo={(isSameFamily && familyName) || ''}
			labels={{
				title: labels.title,
				description: isSameFamily ? labels.alreadyInFamily : labels.linkedMember,
				mainButton: labels.mainButton,
			}}
			mainAction={onClose || toggle}
			handleBack={toggle}
		/>
	);
};
