import React from 'react';
import { IColors } from '../../types';
import { css, jsx } from '@emotion/react';
import { ColorCode, colorCodeMap, TMaintAtEndHeight } from './types';

export const genericIconCss = (squere: number) => css`
	width: ${squere}px;
	height: ${squere}px;
`;

const maintenanceBeforeCss = css`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 14px;
`;

const maintenanceAfterCss = css`
	top: unset;
	bottom: 0;
	left: 0;
`;

const maintAtBeginOrEnd = ({
	isNestedEvent,
	maintAtEndHeight,
}: {
	maintAtEndHeight?: TMaintAtEndHeight;
	isNestedEvent?: boolean;
}) => css`
	${isNestedEvent &&
	`position: relative;
top: 0;
right: 5px;
border-radius: 0;
width: 100%;
margin-bottom: 4px;
min-height: 14px;
${
	maintAtEndHeight &&
	`position: absolute;
	top: unset;
	bottom: ${maintAtEndHeight.height}px;
	left: 4px;
	margin-bottom: 0;
	`
}
`}
`;

interface IGenericContainerProps {
	colors: IColors;
	isDiagonal?: boolean;
	colorCode?: ColorCode;
	isDaily: boolean;
	isShort: boolean;
	isDraft: boolean;
	top?: boolean;
	bottom?: boolean;
	isNestedEvent?: boolean;
	maintAtEndHeight?: TMaintAtEndHeight;
	isNesting?: boolean;
	isTrailing?: boolean;
	height?: number | undefined;
	isMaintenance?: boolean;
}

export const GenericContainerCss = ({
	colors,
	isMaintenance = false,
	colorCode = isMaintenance ? colorCodeMap.gray : colorCodeMap.blue,
	isDaily,
	isShort,
	isDraft,
	top,
	bottom,
	isNesting,
	isNestedEvent,
	maintAtEndHeight,
	isTrailing,
	height,
}: IGenericContainerProps) => css`
	background: ${isDraft
			? `
		repeating-linear-gradient(
		135deg,
		rgba(0, 0, 0, 0.45),
		rgba(0, 0, 0, 0.45) 3px,
		rgba(0, 0, 0, 0.5) 3px,
		rgba(0, 0, 0, 0.5) 16px
	);`
			: colorCode.lightValue}${isNestedEvent && '99'}; //opacity 60% for nested maintenance
	${isDraft && isNestedEvent && `background-color: #ffffff8c;`}
	border-left: 4px solid ${isDraft ? 'transparent' : colorCode.baseValue};
	height: ${height ?? 100}%;
	border-radius: 2px;
	${top && `border-top-left-radius: 0;`}
	${top && `border-top-right-radius: 0;`}
	${bottom && `border-bottom-left-radius: 0;`}
	${bottom && `border-bottom-right-radius: 0;`}
	padding: ${isDaily ? '8px' : '2px 8px'};
	${isShort &&
	`
    padding:0 8px;
  `}
	${isDraft &&
	`
		*{
			color:white;
		}
		`}

	padding-left: 5px;
	/* position: relative; */
	overflow: hidden;
	cursor: ${isDraft ? 'default' : 'pointer'};
	${isNesting &&
	`
	padding-top: 0;
	padding-right: 0;
	`}
	${isTrailing && maintenanceBeforeCss}
	${maintAtBeginOrEnd({ isNestedEvent, maintAtEndHeight })}
	${isTrailing && maintAtEndHeight && maintenanceAfterCss}
	.caption-accented {
		text-transform: capitalize;
	}
	div,
	label,
	p,
	h1,
	h2,
	h3,
	h4 {
		color: ${isDraft ? colors.bg_white : colorCode?.darkValue} !important;
	}
	.dot {
		background: ${isDraft ? colors.bg_white : colorCode?.baseValue} !important;
	}
	.circle-icon {
		background: ${isDraft ? colors.bg_white : colorCode?.baseValue};
		border-radius: 100px;
		width: 16px;
		height: 16px;
		display: block;
		svg {
			color: white !important;
			width: 16px;
			height: 16px;
		}
	}
	svg {
		&.main {
			color: ${isDraft ? colors.bg_white : colorCode?.darkValue} !important;
		}
		&.sub {
			color: ${isDraft ? colors.bg_white : colorCode?.baseValue} !important;
		}
	}
`;

export const iconsContainerCss = (heightModifier = 0) => css`
	display: flex;
	position: absolute;
	bottom: ${heightModifier + 4}px;
	right: 4px;
	z-index: 10;
`;

export const conflictIconCss = (colors: IColors) => css`
	display: flex;
	svg {
		width: 16px;
		height: 16px;
		color: ${colors.tx_error};
	}
`;

export const Container = (
	colors: IColors,
	isDiagonal: boolean,
	isMaintenance: boolean = false,
	isShort: boolean = false,
	colorCode: ColorCode
) => css`
	background: ${colorCode?.lightValue};
	border-left: 3px solid ${colorCode?.baseValue};
	height: 100%;
	border-radius: 2px 0 0 2px;
	padding: ${isShort ? '0' : '8px'};
	padding-left: 5px;
	/* position: relative; */
	overflow: hidden;
	cursor: pointer;
	.header {
		display: flex;
		align-items: ${isShort ? 'flex-start' : 'center'};
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	&:before {
		content: '';

		display: ${isDiagonal ? 'block' : 'none'};
		height: 100%;
		width: 200%;
		position: absolute;
		background-image: linear-gradient(#4a90e2 3px, transparent 0.1em);
		opacity: 0.2;
		background-size: 25px 25px;
		top: 0;
		left: 0;
		transform: rotate(-65deg) translate(-15%, -25%);
	}
`;

export const DotCss = (colors: IColors) => css`
	background: ${colors.bg_text_primary};
	height: 2px;
	width: 2px;
	min-width: 2px;
	margin: 0 4px;
	display: block;
	border-radius: 10px;
`;

export const gridCss = css`
	height: 100%;
	width: 200%;
	position: absolute;
	background-image: linear-gradient(#1e1e1e 2px, transparent 0.1em);
	background-size: 30px 30px;
	top: 0;
	left: 0;
	transform: rotate(-45deg) translate(-20%, -20%);
`;

export const iconCss = css`
	width: 15px;
	height: 15px;
	color: #9b9b9b !important;
`;

export const maintenanceIconCss = css`
	width: 15px;
	height: 15px;
`;

export const downIconCss = css`
	width: 12px;
	height: 12px;
	color: #4e4e4e;
`;

export const maintenanceTimeCss = (isShort: boolean) => css`
	display: flex;
	align-items: ${isShort ? 'flex-start' : 'center'};
	.caption-accented {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		${isShort && 'font-size:10px;'}
		${isShort && 'line-height:10px;'}
    width: 50%;
	}
	.caption-accented,
	.overline {
		color: #4e4e4e !important;
		margin-left: 8px;
	}
`;
export const maintenanceNameCCss = css`
	color: #4e4e4e;
	font-size: 10px;
	line-height: 1.2rem;
	font-family: Montserrat;
	line-height: 1.2rem;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-left: 5.3px;
	padding-right: 6.5px;
	font-weight: 600;
`;

export const DailyShortCss = css`
	display: flex;
	align-items: end;
	justify-content: space-between;
	overflow-x: hidden;
	width: 99%;
	padding: 4px 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
`;

export const flexRowCss = css`
	display: flex;
`;

export const flexStart = css`
	display: flex;
	justifycontent: flex-start;
`;
