import React from "react";

export const Court = (props: any) => (
  <svg
    data-aid="SvgCourt"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 10.3H6C5.73478 10.3 5.48043 10.4159 5.29289 10.6222C5.10536 10.8285 5 11.1083 5 11.4C5 11.6917 5.10536 11.9715 5.29289 12.1778C5.48043 12.3841 5.73478 12.5 6 12.5H7V22.4C7 22.6917 7.10536 22.9715 7.29289 23.1778C7.48043 23.3841 7.73478 23.5 8 23.5C8.26522 23.5 8.51957 23.3841 8.70711 23.1778C8.89464 22.9715 9 22.6917 9 22.4V21.3H11V22.4C11 22.6917 11.1054 22.9715 11.2929 23.1778C11.4804 23.3841 11.7348 23.5 12 23.5C12.2652 23.5 12.5196 23.3841 12.7071 23.1778C12.8946 22.9715 13 22.6917 13 22.4V21.3H15V22.4C15 22.6917 15.1054 22.9715 15.2929 23.1778C15.4804 23.3841 15.7348 23.5 16 23.5C16.2652 23.5 16.5196 23.3841 16.7071 23.1778C16.8946 22.9715 17 22.6917 17 22.4V12.5H18C18.2652 12.5 18.5196 12.3841 18.7071 12.1778C18.8946 11.9715 19 11.6917 19 11.4C19 11.1083 18.8946 10.8285 18.7071 10.6222C18.5196 10.4159 18.2652 10.3 18 10.3ZM11 19.1H9V16.9H11V19.1ZM11 14.7H9V12.5H11V14.7ZM15 19.1H13V16.9H15V19.1ZM15 14.7H13V12.5H15V14.7ZM20 1.5H4C3.20435 1.5 2.44129 1.84768 1.87868 2.46655C1.31607 3.08542 1 3.92479 1 4.8V13.6C1 14.4752 1.31607 15.3146 1.87868 15.9335C2.44129 16.5523 3.20435 16.9 4 16.9C4.26522 16.9 4.51957 16.7841 4.70711 16.5778C4.89464 16.3715 5 16.0917 5 15.8C5 15.5083 4.89464 15.2285 4.70711 15.0222C4.51957 14.8159 4.26522 14.7 4 14.7C3.73478 14.7 3.48043 14.5841 3.29289 14.3778C3.10536 14.1715 3 13.8917 3 13.6V4.8C3 4.50826 3.10536 4.22847 3.29289 4.02218C3.48043 3.81589 3.73478 3.7 4 3.7H20C20.2652 3.7 20.5196 3.81589 20.7071 4.02218C20.8946 4.22847 21 4.50826 21 4.8V13.6C21 13.8917 20.8946 14.1715 20.7071 14.3778C20.5196 14.5841 20.2652 14.7 20 14.7C19.7348 14.7 19.4804 14.8159 19.2929 15.0222C19.1054 15.2285 19 15.5083 19 15.8C19 16.0917 19.1054 16.3715 19.2929 16.5778C19.4804 16.7841 19.7348 16.9 20 16.9C20.7956 16.9 21.5587 16.5523 22.1213 15.9335C22.6839 15.3146 23 14.4752 23 13.6V4.8C23 3.92479 22.6839 3.08542 22.1213 2.46655C21.5587 1.84768 20.7956 1.5 20 1.5Z"
      fill="currentColor"
    />
  </svg>
);
