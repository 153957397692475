import React from 'react';

export const Send = (props: any) => (
  <svg
    data-aid="svgSend"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1453 2.00168C21.3595 2.01457 21.5699 2.10283 21.7335 2.26645C21.8928 2.42566 21.9806 2.6292 21.9971 2.83734C21.998 2.84792 21.9986 2.85852 21.9991 2.86914C22.0009 2.9096 22 2.95029 21.9964 2.99093C21.9957 2.99828 21.995 3.00563 21.9941 3.01297C21.9854 3.08948 21.967 3.16509 21.939 3.23782L15.4956 21.0809C15.4871 21.1046 15.4775 21.128 15.467 21.1509C15.3509 21.4043 15.1645 21.6189 14.93 21.7695C14.6954 21.92 14.4226 22 14.1439 22C13.8652 22 13.5924 21.92 13.3578 21.7695C13.1286 21.6224 12.9454 21.414 12.8288 21.1682L9.49649 14.5035L2.83175 11.1711C2.58594 11.0546 2.37761 10.8714 2.23053 10.6422C2.08001 10.4076 2 10.1348 2 9.85611C2 9.57742 2.08001 9.30458 2.23053 9.07003C2.38105 8.83549 2.59575 8.64908 2.84911 8.53298C2.87204 8.52247 2.89539 8.51293 2.91911 8.50436L20.762 2.06108C20.8377 2.03185 20.9165 2.01312 20.9962 2.00487C21.046 1.9997 21.0958 1.99867 21.1453 2.00168ZM17.563 5.15059L4.37986 9.91116L9.99488 12.7187L17.563 5.15059ZM11.2813 14.0051L18.8494 6.43703L14.0888 19.6201L11.2813 14.0051Z"
      fill="currentColor"
    />
  </svg>
);
