import { isArray, isEmpty, isNil } from 'lodash';

export const buildQueryString = (params: { [key: string]: any }): string => {
	const entries = Object.entries(params).filter(entry => !isNil(entry[1]));

	if (entries.length === 0) {
		return '';
	}

	return `?${entries.map(([key, value]) => `${key}=${value}`).join('&')}`;
};
