/** @jsxRuntime classic */
/** @jsx jsx */
import React, { FC, useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { jsx, Theme, useTheme } from '@emotion/react';
import { StyledInputContainer, DeleteButtonStyle, InputContainer } from './style';
import { getIcon, BnRemove } from '../../icons';
import { capitalize } from '../../functions';
import { Tooltip } from '../Tooltip';
import { Popup } from '../Popup';
import dayjs from 'dayjs';
import { DateRangeInputProps } from '../../types';
import DateRange from '../DateRangeInput/DateRange';
import { YEAR_MONTH_DAY_FORMAT } from '../DateRangeInput/Calender/calander.consts';
import { endOfDay } from 'date-fns';

export const DateRangeInput: FC<DateRangeInputProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
	predefinedRanges = [],
	rightIcon = '',
	labels = undefined,
	children,
	sizer = 'S',
	noLabel = false,
	label = '',
	info = '',
	notBefore = undefined,
	enablePast = false,
	error = undefined,
	disableTrailing = true,
	setChange = () => {},
	clear = () => {},
	visualFormat = YEAR_MONTH_DAY_FORMAT,
	disabled = false,
	// colors = ThemeColors,
	grayedOut = false,
	onSubmitDateRange,
	notAfter = undefined,
	notAfterError,
	notBeforeError,
	openOnDateRange,
	...rest
}) => {
	const inputRef = useRef(null);
	const colors = useTheme();
	const [isFocus, setFocus] = useState<boolean>(false);
	const [innerValue, setInnerValue] = useState<string>('');
	const [isHover, setHover] = useState<boolean>(false);
	const [visited, setVisited] = useState<boolean>(false);
	const [isDateOpen, setDateOpen] = useState<boolean>(false);

	const updateInnerValueToOpenDateRange = () => {
		let valueStr = '';
		if (openOnDateRange) {
			valueStr = visualInput(openOnDateRange.start, openOnDateRange.end);
		}

		setInnerValue(valueStr);
	};

	useEffect(() => {
		updateInnerValueToOpenDateRange();
	}, [openOnDateRange]);

	const handelDateRangeValue = (e: any, value: string) => {
		const isDateRange = value.includes(' - ');

		if (!value) {
			if (rest.onChange) {
				rest.onChange(e);
			}
		} else if (isDateRange) {
			// Handle date range
			const [startDateStr, endDateStr] = value.split(' - ');

			// Ensure both start and end dates are valid before proceeding
			const startDate = dayjs(startDateStr, YEAR_MONTH_DAY_FORMAT).isValid()
				? dayjs(startDateStr, YEAR_MONTH_DAY_FORMAT).toDate()
				: null;

			const endDate = dayjs(endDateStr, YEAR_MONTH_DAY_FORMAT).isValid()
				? dayjs(endDateStr, YEAR_MONTH_DAY_FORMAT).toDate()
				: null;

			if (startDate && endDate) {
				handleDateRangeSubmit(startDate, endDate);
			}
		} else {
			// Handle single date
			const selectedDateStartDay = dayjs(value, YEAR_MONTH_DAY_FORMAT).startOf('day').toDate();
			const selectedDateEndDay = dayjs(value, YEAR_MONTH_DAY_FORMAT).endOf('day').toDate();
			handleDateRangeSubmit(selectedDateStartDay, selectedDateEndDay);
		}
	};

	const disabledDates = (day: Date) => {
		const pastDate = notBefore ? dayjs(notBefore).format(YEAR_MONTH_DAY_FORMAT) : null;
		const futureDate = notAfter ? dayjs(notAfter).format(YEAR_MONTH_DAY_FORMAT) : null;
		const dayjsDay = dayjs(day);
		const isDayBeforeTime = notBefore ? dayjsDay.isBefore(pastDate) : false;
		const isDayAfterTime = notAfter ? dayjsDay.isAfter(futureDate) : false;
		return isDayAfterTime || isDayBeforeTime;
	};

	const visualInput = (startDate: Date, endDate: Date | null) => {
		let startDateFormated = dayjs(startDate).format(visualFormat);
		let endDateFormated = null;

		if (endDate) {
			endDateFormated = dayjs(endDate).format(visualFormat);
			if (dayjs(endDateFormated).isBefore(startDateFormated)) {
				const temp = endDateFormated;
				endDateFormated = startDateFormated;
				startDateFormated = temp;
			}
		}
		return endDateFormated ? `${startDateFormated} - ${endDateFormated}` : `${startDateFormated} -`;
	};

	const handleDateRangeSubmit = (
		startDate: Date,
		endDate: Date,
		isToggle: boolean = false,
		isSoftSubmit: boolean = false
	) => {
		const value = visualInput(startDate, endDate);
		setInnerValue(value);

		if (rest.onChange) {
			//@ts-ignore
			rest.onChange(value);
		}
		if (!isSoftSubmit) {
			onSubmitDateRange([startDate, endDate ? endOfDay(endDate) : null]);
		}
		setDateOpen(isToggle);
	};

	const handleDateRangeClear = () => {
		//on the right side of the input
		updateInnerValueToOpenDateRange();
		handleDateRangeCancel();
	};

	const handleDateRangeCancel = () => {
		updateInnerValueToOpenDateRange();
		if (inputRef && inputRef.current) {
			// @ts-ignore: Object is possibly 'null'.
			inputRef.current.focus();
		}
		setDateOpen(false);
	};

	return (
		<InputContainer
			className={cx({
				M: sizer === 'M',
				S: sizer === 'S',
			})}
			theme={{ ...colors }}
			style={{ ...rest.style }}
		>
			{(label || noLabel) && (
				<div className="label">
					<div>
						{capitalize(label)}
						{rest.required && ' *'}
					</div>
					{info && <Tooltip content={info}>{getIcon('info', 'l')}</Tooltip>}
				</div>
			)}
			<Popup
				placement="bottom-start"
				padding={0}
				disableAutoTrigger
				// fullWidth
				body={
					<DateRange
						openOnDateRange={openOnDateRange}
						onSubmit={handleDateRangeSubmit}
						onCancel={handleDateRangeCancel}
						predefinedRanges={predefinedRanges}
						labels={labels}
						disabledDates={disabledDates}
						notBefore={notBefore}
						notAfter={notAfter}
						notAfterError={notAfterError}
						notBeforeError={notBeforeError}
					/>
				}
				onOpenChange={setDateOpen}
				trigger={isDateOpen}
				onOutsideClick={() => updateInnerValueToOpenDateRange()}
			>
				<StyledInputContainer
					theme={{ ...colors }}
					className={cx({
						focus: isFocus,
						error: error && (visited || rest.displayErrorBeforeVisited),
						hover: isHover,
						M: sizer === 'M',
						S: sizer === 'S',
						disabled: grayedOut && disabled,
					})}
					grayedOut={grayedOut}
					onClick={() => {
						if (inputRef.current) {
							//@ts-ignore
							inputRef.current.focus();
						}
					}}
					onMouseEnter={() => {
						setHover(true);
					}}
					onMouseLeave={() => {
						setHover(false);
					}}
				>
					<input
						data-aid="date-range-input"
						ref={inputRef}
						{...rest}
						disabled={disabled}
						value={innerValue}
						onChange={e => {
							setInnerValue(e.target.value);

							// Check if the input value represents a valid date range or a single date
							handelDateRangeValue(e, e.target.value);
						}}
						onFocus={e => {
							setFocus(true);
							setDateOpen(true);
							if (rest.onFocus) {
								rest.onFocus(e);
							}
							updateInnerValueToOpenDateRange();
						}}
						onBlur={e => {
							setFocus(false);
							setVisited(true);
							if (rest.onBlur) {
								rest.onBlur(e);
							}
						}}
					/>
					{!disableTrailing && innerValue.length > 0 && isHover && (
						<DeleteButton onClick={handleDateRangeClear} theme={{ ...colors }} />
					)}
					{rightIcon && getIcon(rightIcon, 'l')}
				</StyledInputContainer>
			</Popup>
		</InputContainer>
	);
};

interface DeleteButtonProps {
	theme: Theme;
	onClick: () => void;
}
const DeleteButton = ({ theme, onClick }: DeleteButtonProps) => {
	return (
		<DeleteButtonStyle theme={theme} onClick={onClick} style={{ paddingLeft: '3px', paddingTop: '3px' }}>
			<BnRemove />
		</DeleteButtonStyle>
	);
};
