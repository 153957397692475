import React, { Fragment, useState } from 'react';
import { Field as FinalField } from 'react-final-form';
import { Checkbox, Props } from './index';

export interface IFormProps extends Props {
  name: string;
  onClear?: (val: string) => void;
  required?: boolean;
}

export const FormCheckbox = ({
  onClear = (val: string) => {},
  required = false,
  ...props
}: IFormProps) => {
  const clear = () => onClear(props.name);

  return (
    <FinalField
      type="checkbox"
      name={props.name}
      validate={(val) => {
        if (required) {
          if (!val) {
            let errors = {};
            errors[props.name] = 'required';
            return errors;
          }
        }
        return undefined;
      }}
      displayEmpty
    >
      {({ input, meta }) => {
        return (
          <Checkbox
            {...input}
            {...props}
            error={meta.error && meta.error[props.name]}
          />
        );
      }}
    </FinalField>
  );
};
