import { ByOrganizationIdDto, PaginationQuery, ResourceNameTypeEnum } from '@bondsports/types';

export interface INote {
	content: string;
	datetime: string;
}

export interface INotesProps {
	notes: INote[];
	handleAdd: (INote) => void;
	toggle: () => void;
}

export interface IFilterObject {
	isPublic: boolean;
}

interface FullName {
	firstName: string;
	lastName: string;
}

class UserObject implements FullName {
	firstName: string;
	lastName: string;
}

type DocumentId = 'invoiceId' | 'paymentId';

export interface IRawNote {
	id: number;
	organizationId: number;
	(key: DocumentId): number;
	creatingUserId: number;
	content: string;
	isPublic: boolean;
	createdAt: string; // '2022-02-17T11:50:04.634Z';
	updatedAt: string;
	deletedAt: null | string;
	user: UserObject;
	createdBy?: string;
}

export enum EDocumentType {
	INVOICE = 'INVOICE',
	PAYMENT = 'PAYMENT',
}

export enum ERecoilFilteredNotes {
	PUBLIC_NOTES = 'publicSavedNotes',
	PRIVATE_NOTES = 'privateSavedNotes',
	ALL_NOTES = 'publicAndPrivateNotes',
}
