import React from 'react';

export const CourtIndicator = (props: any) => (
	<svg data-aid="SvgCourtIndicator" width="28" height="31" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14 19.3C15.5464 19.3 16.8 18.0464 16.8 16.5C16.8 14.9536 15.5464 13.7 14 13.7C12.4536 13.7 11.2 14.9536 11.2 16.5C11.2 18.0464 12.4536 19.3 14 19.3ZM14 21.1667C16.5773 21.1667 18.6667 19.0773 18.6667 16.5C18.6667 13.9227 16.5773 11.8333 14 11.8333C11.4227 11.8333 9.33334 13.9227 9.33334 16.5C9.33334 19.0773 11.4227 21.1667 14 21.1667Z"
			fill="currentColor"
		/>
		<path
			d="M16.6793 29.2167C16.7578 28.9586 16.8 28.6847 16.8 28.4009C16.8 26.8545 15.5464 25.6009 14 25.6009C12.4536 25.6009 11.2 26.8545 11.2 28.4009C11.2 28.6847 11.2422 28.9586 11.3207 29.2167H9.40439C9.35769 28.9518 9.33334 28.6792 9.33334 28.4009C9.33334 25.8236 11.4227 23.7342 14 23.7342C16.5773 23.7342 18.6667 25.8236 18.6667 28.4009C18.6667 28.6792 18.6423 28.9518 18.5956 29.2167H16.6793Z"
			fill="currentColor"
		/>
		<path
			d="M11.3207 3.78333C11.2422 4.04143 11.2 4.31532 11.2 4.59908C11.2 6.14548 12.4536 7.39908 14 7.39908C15.5464 7.39908 16.8 6.14548 16.8 4.59908C16.8 4.31532 16.7578 4.04143 16.6793 3.78333H18.5956C18.6423 4.04821 18.6667 4.3208 18.6667 4.59908C18.6667 7.17641 16.5773 9.26575 14 9.26575C11.4227 9.26575 9.33334 7.17641 9.33334 4.59908C9.33334 4.3208 9.35769 4.04821 9.40439 3.78333H11.3207Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.61667 7.16666C3.61667 5.2981 5.13144 3.78333 7 3.78333H21C22.8686 3.78333 24.3833 5.2981 24.3833 7.16666V25.8333C24.3833 27.7019 22.8686 29.2167 21 29.2167H7C5.13144 29.2167 3.61667 27.7019 3.61667 25.8333V7.16666ZM7 5.88333C6.29124 5.88333 5.71667 6.45789 5.71667 7.16666V25.8333C5.71667 26.5421 6.29124 27.1167 7 27.1167H21C21.7088 27.1167 22.2833 26.5421 22.2833 25.8333V7.16666C22.2833 6.45789 21.7088 5.88333 21 5.88333H7Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.98334 16.5C1.98334 15.9845 2.4012 15.5667 2.91667 15.5667H25.0833C25.5988 15.5667 26.0167 15.9845 26.0167 16.5C26.0167 17.0155 25.5988 17.4333 25.0833 17.4333H2.91667C2.4012 17.4333 1.98334 17.0155 1.98334 16.5Z"
			fill="currentColor"
		/>
		<path
			d="M28 6.5C28 9.81371 25.3137 12.5 22 12.5C18.6863 12.5 16 9.81371 16 6.5C16 3.18629 18.6863 0.5 22 0.5C25.3137 0.5 28 3.18629 28 6.5Z"
			fill="#E02020"
		/>
		<path
			d="M22.6003 3.57493C22.6003 3.28498 22.3652 3.04993 22.0753 3.04993C21.7853 3.04993 21.5503 3.28498 21.5503 3.57493V7.925C21.5503 8.21495 21.7853 8.45 22.0753 8.45C22.3652 8.45 22.6003 8.21495 22.6003 7.925V3.57493Z"
			fill="white"
		/>
		<path
			d="M22.0759 10.1749C22.3658 10.1749 22.6009 9.9398 22.6009 9.64985C22.6009 9.3599 22.3658 9.12485 22.0759 9.12485C21.7859 9.12485 21.5509 9.3599 21.5509 9.64985C21.5509 9.9398 21.7859 10.1749 22.0759 10.1749Z"
			fill="white"
		/>
	</svg>
);
