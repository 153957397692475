import React from "react";

export const Room = (props: any) => (
  <svg
    data-aid="SvgRoom"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6571 11.367C12.2931 11.367 11.9981 11.6997 11.9981 12.1102V13.5966C11.9981 14.007 12.2931 14.3397 12.6571 14.3397C13.021 14.3397 13.316 14.007 13.316 13.5966V12.1102C13.316 11.6997 13.021 11.367 12.6571 11.367Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1004 1.76924C16.1004 1.41827 15.9715 1.08296 15.7444 0.843004C15.5174 0.603043 15.2123 0.479849 14.9018 0.502695L5.6648 1.18226C5.07297 1.2258 4.61268 1.7799 4.61268 2.44881V20.5024H1.12538C0.503852 20.5024 0 21.0706 0 21.7716C0 22.4726 0.503852 23.0408 1.12538 23.0408H5.65964L14.8189 24.4877C15.1415 24.5387 15.4678 24.4295 15.7135 24.1885C15.9593 23.9474 16.1004 23.5981 16.1004 23.2308V5.95114H17.6332V21.7793C17.6332 22.4803 18.1371 23.0486 18.7586 23.0486H22.8746C23.4961 23.0486 24 22.4803 24 21.7793C24 21.0783 23.4961 20.5101 22.8746 20.5101H19.884V4.6819C19.884 3.98092 19.3801 3.41267 18.7586 3.41267H16.1004V1.76924ZM13.8496 21.7714L6.86344 20.6678V3.63795L13.8496 3.12397V21.7714Z"
      fill="currentColor"
    />
  </svg>
);
