import { Icons } from '@bondsports/utils';
import { ActivitiesEnum } from './enums';
import { OrderByEnum, ProgramTypesEnum, SimpleProgramDto, SimpleSessionDto, SportsEnum } from '@bondsports/types';

export type TCustomerActivitiesLabels = {
	activitiesBar: {
		title: string;
		buttons: {
			punchPass: {
				name: string;
				icon: Icons;
			};
			class: {
				name: string;
				icon: Icons;
			};
			clinic: {
				name: string;
				icon: Icons;
			};
			camps: {
				name: string;
				icon: Icons;
			};
			privateLesson: {
				name: string;
				icon: Icons;
			};
			clubTeam: {
				name: string;
				icon: Icons;
			};
		};
	};
	util: {
		loading: {
			title: string;
			description: string;
		};
	};
	activities: {
		[key in ActivitiesEnum]: {
			title: string;
			error: string;
			noData: string;
			card: Record<string, any>;
		};
	};
};

export type SimpleProgramWithSportDto = SimpleProgramDto & { sport: SportsEnum; type: ProgramTypesEnum };

export type MustHaveStartAndEndDates = {
	startDate: Date;
	endDate: Date;
};

export type FetchFunc<T extends MustHaveStartAndEndDates> = (
	itemsPerPage: number,
	startDate: Date,
	endDate: Date,
	orderBy: OrderByEnum,
	options?: { signal: AbortSignal }
) => Promise<T[]>;

export type PagesState<T> = {
	current?: Page<T>;
};

export class Page<T> {
	previous?: Page<T>;
	next?: Page<T>;

	constructor(public page: T[]) {}
}

export type ActivityDetailsCardProps<T> = {
	data: T;
	displayUserDetails: boolean;
	onViewInvoice: (invoiceId: number) => void;
	onViewPayment: (paymentId: number) => void;
	labels: Record<string, any>;
	timezone: string;
	onNavigateToSession: (session: SimpleSessionDto, program: SimpleProgramWithSportDto) => void;
	index: number;
};
