/** @jsx jsx */
/** @jsxRuntime classic */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { TComponentSize } from '../types';

const containerCss = (colorId: string, size: TComponentSize) => css`
	min-width: ${size === 's' ? 20 : 24}px;
	min-height: ${size === 's' ? 20 : 24}px;
	border-radius: 50%;
	background-color: ${colorId};
	display: flex;
	align-items: center;
	justify-content: center;
`;

interface Props {
	colorId: string;
	children?: React.ReactNode;
	size: TComponentSize;
}
export const ColorEllipse = ({ colorId, children, size }: Props) => {
	return (
		<div css={containerCss(colorId, size)} data-aid="ColorEllipse-ColorPicker">
			{children}
		</div>
	);
};
