/** @jsxRuntime classic*/
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import 'lazysizes';
import * as React from 'react';

/**
 * Using the LQIP (Low Quality Image Placeholder) pattern.
 * The LQIP technique is enhanced with CSS transitions to unblur
 * the LQIP image when the image is loaded.
 */
const imgCss = css`
	filter: blur(5px);
	position: relative;
	transition: filter 400ms, -webkit-filter 400ms;
	object-fit: cover;
	/* class from lazysizes that indicates that the image was loaded */
	&.lazyloaded {
		filter: blur(0);
	}
`;

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
	path: string;
	alt: string;
	className?: string;
	isModule?: boolean;
}

/**
 * Image optimization component.
 * First will try to use webp format and fallback if the browser doesn't
 * support it.
 *
 * Using lazysizes to lazy load images with LQIP fallback.
 * @see
 * https://github.com/aFarkas/lazysizes
 */
function Picture({ className, path, alt, ...restProps }: Props) {
	return (
		<picture>
			<img css={imgCss} className={`${className} lazyload`} data-src={path} src={path} alt={alt} {...restProps} />
		</picture>
	);
}

export default Picture;
