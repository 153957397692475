import React from "react";

export const GoogleFill = (props: any) => (
  <svg
    data-aid="SvgGoogleFill"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.8234 7.68042C14.8458 6.88171 13.6016 6.3936 12.2241 6.3936C9.6597 6.3936 7.49877 8.04872 6.74069 10.3499L3.45064 7.8375C5.04056 4.60936 8.36083 2.40002 12.2241 2.40002C14.7125 2.40002 16.9343 3.33186 18.6673 4.84054L15.8234 7.68042Z"
      fill="currentColor"
    />
    <path
      d="M6.74069 10.3499C6.55272 10.9201 6.44725 11.5281 6.44725 12.1622C6.44725 12.7932 6.55123 13.3989 6.73742 13.9664L3.44914 16.4837C2.80882 15.1827 2.448 13.7166 2.448 12.1622C2.448 10.6065 2.80942 9.13941 3.45064 7.8375L6.74069 10.3499Z"
      fill="currentColor"
    />
    <path
      d="M12.2241 17.9305C9.6597 17.9305 7.49877 16.2754 6.74069 13.9742L3.45064 16.4862C5.04056 19.7148 8.36083 21.9241 12.2241 21.9241C14.6085 21.9241 16.885 21.0775 18.5936 19.4911C20.3882 17.8254 21.5554 15.344 21.5554 12.1621C21.5554 11.5853 21.4665 10.9641 21.3332 10.3872H12.2238V14.1589H17.4672C17.2051 15.4449 16.4914 16.4335 15.4703 17.0769C14.5891 17.6319 13.4798 17.9305 12.2241 17.9305Z"
      fill="currentColor"
    />
  </svg>
);
