import styled from "@emotion/styled";
import { IColors } from "../../types/theme";
import { setOpacity } from "../../styles/utils";

export const StyledDateInput = styled.div<any>(
  ({ theme: colors }: { theme: IColors }) => `
    display:flex;
    align-items:center;
    width:100%;
  `
);

export const StyledCalendar = styled.div<any>(
  ({ theme: colors }: { theme: IColors }) => `
    .calendar_header{
        display:flex;
        align-items:center;
        padding:16px;
        justify-content:space-between;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: ${colors.tx_text_primary};   
        border-bottom:1px solid ${colors.bg_border_seperator};
        button{
            border:none;
            padding:0;
            margin:0;
            background:none;
            svg{
                cursor:pointer;
                color:${colors.tx_text_secondary};
                &:hover{
                    color: ${colors.tx_text_primary};   
                }
            }
        }
        
    }
    .calendar_body{
        padding:16px;
        font-family: Montserrat;
        th{
            padding:5px;
            text-align:center;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: ${colors.tx_text_secondary};
        }
        td{
            div{
                padding:5px;
                text-align:center;
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                color: ${colors.tx_text_primary};
                cursor:pointer;
                border-radius: 2px;
                &:hover{
                    background: ${setOpacity(colors.bg_system || "black", 0.2)};
                }
                &.notCurrentMonth{
                    color: ${colors.tx_text_teriaty};
                }
                &.today{
                    background: ${colors.bg_background_primary};
                }
                &.selected{
                    background: linear-gradient(270deg, ${
                      colors.bg_system
                    } 0%, ${colors.bg_system} 100%);
                    color:${colors.tx_white};
                }
                &.disabledDay{
                    color: ${colors.tx_text_teriaty};
                    cursor:default;
                    &:hover{
                        background:transparent;
                    }
                }
            }
        }
    }
`
);
