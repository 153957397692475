import { TeamsService } from '@app/shared/services/teams/teams.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeaguesService } from '@app/shared/services/leagues/leagues.service';
import { RCLeagueSeason, RCSeasonTeam, RCTeam } from '@rcenter/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'rc-move-team-to-season-modal',
	templateUrl: './move-team-to-season-modal.component.html',
	styleUrls: ['./move-team-to-season-modal.component.scss'],
	exportAs: 'modal',
})
export class MoveTeamToSeasonModalComponent implements OnInit {
	@ViewChild('modal', { static: true }) public modal: ModalDirective;

	@Output() onSubmit = new EventEmitter();
	team: RCTeam;
	currentSeason: RCLeagueSeason;
	teamEditForm: FormGroup;
	loading: boolean;
	leaguesSelections;
	toLeagueId;
	seasonsSelections;
	toSeasonId;
	divisionsSelections;
	toDivisionId;
	subscribers = [];
	constructor(
		private toastr: ToastrService,
		private fb: FormBuilder,
		private leagueService: LeaguesService,
		private teamService: TeamsService
	) {}

	// getDivisionsBySeason

	ngOnInit() {
		this.teamEditForm = this.fb.group({
			teamId: ['', Validators.required],
			fromSeasonId: ['', Validators.required],
			toLeagueId: ['', Validators.required],
			toSeasonId: ['', Validators.required],
			toDivisionId: ['', Validators.required],
		});
	}

	openMoveTeamModal(season: RCLeagueSeason, team: RCSeasonTeam) {
		this.teamEditForm.reset();

		this.team = team.team;
		this.currentSeason = season;
		this.toLeagueId = this.leagueService.currentLeagueObject.id;

		this.teamEditForm.get('teamId').setValue(this.team.id);
		this.teamEditForm.get('fromSeasonId').setValue(this.currentSeason.id);

		this.leagueService.getLeagues().subscribe(leagues => {
			this.leaguesSelections = leagues.data;
			this.teamEditForm.get('toLeagueId').setValue(this.leagueService.currentLeagueObject.id);
			this.leagueSelected(this.leagueService.currentLeagueObject.id);
		});

		this.modal.show();
	}

	private getSeasonDivisions(seasonId) {
		this.leagueService.getSeasonById(this.toLeagueId, seasonId).subscribe(res => {
			this.divisionsSelections = res.data.seasonDivisions;
			console.log('this.divisionsSelections', this.divisionsSelections);
			if (this.divisionsSelections.length == 1) {
				this.toDivisionId = this.divisionsSelections[0].id;
			} else {
				this.toDivisionId = undefined;
			}
			this.teamEditForm.get('toDivisionId').setValue(this.toDivisionId);
		});
	}

	leagueSelected(leagueId) {
		this.divisionsSelections = undefined;
		this.seasonsSelections = undefined;
		setTimeout(() => {
			let league = this.leaguesSelections.find(l => l.id == leagueId);
			this.seasonsSelections = league.leagueSeasons.filter(s => s.id != this.currentSeason.id);
			if (this.seasonsSelections.length == 1) {
				this.toSeasonId = this.seasonsSelections[0].id;
				this.getSeasonDivisions(this.toSeasonId);
			} else {
				this.toSeasonId = undefined;
			}
			this.teamEditForm.get('toSeasonId').setValue(this.toSeasonId);
		}, 0);
	}

	seasonSelected(seasonId) {
		this.divisionsSelections = undefined;
		setTimeout(() => {
			this.getSeasonDivisions(seasonId);
		}, 0);
	}

	submit(data) {
		if (this.loading) return;
		this.loading = true;
		this.teamService.moveTeamOrMember(parseInt(localStorage.getItem('selected_org_id')), data).subscribe(
			res => {
				this.loading = false;
				this.toastr.success('Team moved Successfully');
				this.reset(true);
				this.onSubmit.emit();
			},
			err => {
				this.loading = false;
				this.toastr.error(err.message);
			}
		);
	}

	reset(closeModal?: boolean) {
		this.teamEditForm.reset();

		if (closeModal) {
			this.modal.hide();
		}
	}
}
