/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import {
	flexColCss,
	flexRowCss,
	globeCss,
	publicLinkCss,
	iconCss,
	titleTextCss,
	secondRowIndentCss,
	rightMarginCss,
	bottomMarginCss,
	hyperlinkCss,
} from './style';
import { BnIcon } from '../../components/icons_v2';
import { ETypography, ETypographyColor, Icons } from '../../types';
import { Typography } from '../../atoms';
import { useColors } from '../../hooks/useColors';
import { useNavigation } from '../../hooks/useNavigation';
import { HeaderProps } from './index';

export const AttendanceHeader = ({ sportIcon, title, subTitle, publicUrl, navigateToSeasonDashboard }: HeaderProps) => {
	const { colors } = useColors();
	const { openInNewTab } = useNavigation();

	const handlePublicLinkClick = () => {
		openInNewTab(publicUrl);
	};

	const labels = {
		publicLink: 'Public',
	};

	return (
		<div css={flexColCss} data-aid="Header-attendancePopup">
			{/* TBD - need to think about implenting <a> tags to links */}
			<div css={flexRowCss}>
				<div css={iconCss('#4a90e2', 20)}>
					<BnIcon icon={sportIcon} />
				</div>
				<div css={titleTextCss}>
					<Typography color={ETypographyColor.primary} type={ETypography.h4}>
						{title}
					</Typography>
				</div>
				<div css={publicLinkCss(colors)} onClick={handlePublicLinkClick}>
					<div css={globeCss}>
						<BnIcon icon={Icons.globe_filled} />
					</div>
					<Typography color={ETypographyColor.primary} type={ETypography.caption}>
						{labels.publicLink}
					</Typography>
				</div>
			</div>
			<div css={[flexRowCss, secondRowIndentCss(10), bottomMarginCss(24)]}>
				<div css={rightMarginCss(6)}>
					<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
						{subTitle?.firstText}
					</Typography>
				</div>
				<Typography
					css={hyperlinkCss}
					color={ETypographyColor.primary}
					type={ETypography.captionLink}
					onClick={navigateToSeasonDashboard}
				>
					{subTitle?.secondText}
				</Typography>
			</div>
		</div>
	);
};
