/** @jsx jsx */
import React, { useRef, useCallback, Fragment, useMemo, ReactElement } from 'react';
import { jsx, css } from '@emotion/react';
import { TranslationEn } from '../../../../../assets/i18n/en';
import { ItemsPerPageCalculator } from './utils';

import { checkboxCss, CheckedIconCss, iconCss, checkboxContainer } from '../../../styles/utils';

import { Pagination, PaginationItem } from './Pagination';
import { Link, useLocation } from 'react-router-dom';

import { ExpandableRow } from './ExpandableRow';
import { HeaderRow } from './HeaderRow';
import { colors } from '../../../styles/theme';
import {
	paginationContainer,
	showingStateCss,
	pageItem,
	rowCss,
	tableCss,
	deviderCss,
	headerCss,
	pageBreakCss,
} from './styles';
import { TableProps } from './types';
import { ETypography, ETypographyColor, SkeletonWrapper, Typography } from '@bondsports/utils';
import { CustomCheckbox } from '../Checkbox';
import { VirtualTable } from './VirtualTable';
import { StyledTableRowWrapper } from './ExpendableRow/DefaultRowWrapper';
import { TableRowsErorr } from './TableRowError';

const rootCss = css`
	width: 100%;
	height: 100%;
	display: flex;
	flex: 1 1 0;
	color: ${colors.black};
`;

export const paperCss = (pagination: boolean, removeShadows = false) => css`
	height: 100%;
	width: 100%;
	padding: ${pagination ? '2rem' : '0'};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: white;
	box-shadow: ${removeShadows ? 'none' : '0px 2px 22px rgb(21 93 148 / 7%)'};
`;

const tableContainerCss = (height: number, bottomBorder: boolean) => css`
	max-height: ${height}px;
	display: flex;
	flex-grow: 1;
	border-bottom: ${bottomBorder ? '1px solid rgba(224, 224, 224, 1)' : '0'};
	overflow: auto;
`;

const inifiniteScrollIndicator = css`
	height: 3px;
`;

const emptyTableCss = css`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
interface Data {
	calories: number;
	carbs: number;
	fat: number;
	name: string;
	protein: number;
}

const emptyCellCss = css`
	&.TableCell-root {
		border-bottom: none;
	}
`;

const backToTopCss = css`
	cursor: pointer;
	width: fit-content;
	height: 32px;
	padding: 10px 0;
`;

type Order = 'asc' | 'desc';

export { ItemsPerPageCalculator };

export const Table = (props: TableProps) => {
	const labels = TranslationEn.customers.pagination;
	const {
		isLoading = false,
		rows,
		columns,
		isSelectRow = false,
		isRadio = false,
		isHoverRow = true,
		page,
		pagination = true,
		expandableKey = '',
		expandable = false,
		expandOn = [],
		ssr = false,
		meta,
		isExpand = false,
		expandedRows = [],
		subBottomBorder = false,
		maxHeight = 700,
		onSelect = () => {},
		selectedArr = [],
		subject,
		isAllSelected = false,
		bottomBorder = true,
		source,
		maxSelected,
		customEmptySpace = false,
		specificDisabledRows = [],
		infiniteScroll,
		fetchNextPage,
		removeShadows = true,
		removeRowSeparator = false,
		removeHeader = false,
		footerOrder = ['labels', 'pagination'],
		footer = <Fragment />,
		isVirtualized = false,
		defaultSortColumn,
		emptyTableMessage,
		isRowsError,
		rowsErrorMessage,
		timezone,
	} = props;
	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<keyof Data>('calories');
	const [selected, setSelected] = React.useState<string[]>([]);
	const observer = useRef<IntersectionObserver | null>(null);
	const tableRef = useRef(null);
	const isEmptyTable = !rows?.length;

	const lastParticipantElementRef = useCallback(
		node => {
			if (isLoading) {
				return;
			}
			if (observer.current && observer.current?.disconnect) {
				observer?.current?.disconnect();
			}
			observer.current = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting && fetchNextPage) {
					fetchNextPage();
				}
			});
			if (node) {
				observer?.current?.observe(node);
			}
		},
		[isLoading, fetchNextPage]
	);

	const location = useLocation();

	function compare(a, b) {
		const key = orderBy;

		let bandA;
		let bandB;

		if (typeof a[key] === 'number') {
			bandA = a[key] ? a[key] : 0;
			bandB = b[key] ? b[key] : 0;
		} else {
			bandA = a[key] ? a[key].toUpperCase() : '';
			bandB = b[key] ? b[key].toUpperCase() : '';
		}
		// Use toUpperCase() to ignore character casing

		let comparison = 0;
		if (bandA > bandB) {
			comparison = 1;
		} else if (bandA < bandB) {
			comparison = -1;
		}
		if (order === 'asc') {
			return comparison;
		} else {
			return comparison * -1;
		}
	}

	const isSelected = useCallback((name: string | number) => selectedArr.includes(name), [selectedArr]);

	const emptyRows = meta
		? Math.min(meta.itemsPerPage - Math.min(meta.itemsPerPage, rows?.length), 10 - rows?.length)
		: 0;

	const isCheckBoxDisabled = (row: any) => {
		if (selectedArr) {
			if (specificDisabledRows?.length > 0) {
				return specificDisabledRows.includes(row?.id);
			} else {
				return selectedArr?.length >= maxSelected;
			}
		}
		return false;
	};

	const handleScrollToTop = () => {
		tableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const arr = [0, 1, 2, 3, 4, 5, 6, 7];

	const RowWrapper = useMemo(
		() =>
			({ children, key, isItemSelected, isDisabled }) =>
				(
					<StyledTableRowWrapper
						isChecked={isItemSelected}
						key={key}
						serializedCss={[pageBreakCss, rowCss(isHoverRow, false, false, isDisabled)]}
					>
						{children}
					</StyledTableRowWrapper>
				),
		[isHoverRow]
	);

	const ExpandedRowWrapper = useMemo(
		() =>
			({ children, key, isItemSelected }) =>
				(
					<StyledTableRowWrapper
						isChecked={isItemSelected}
						key={key}
						serializedCss={rowCss(isHoverRow, true, subBottomBorder)}
					>
						{children}
					</StyledTableRowWrapper>
				),
		[isHoverRow, subBottomBorder]
	);

	let table: ReactElement = null;

	if (isLoading) {
		table = (
			<table
				data-aid="table-loading"
				css={tableCss(removeRowSeparator)}
				aria-labelledby="tableTitle"
				aria-label="enhanced table"
			>
				{!removeHeader && (
					<HeaderRow
						isLoading
						isSelectRow={isSelectRow}
						isRadio={isRadio}
						columns={columns}
						numSelected={selected?.length}
						rowCount={rows?.length}
					/>
				)}
				<tbody>
					{arr.map(key => {
						return (
							<tr key={key} style={{ display: 'flex', justifyContent: 'space-between', margin: '30px 0' }}>
								{columns.map(col => {
									return (
										<SkeletonWrapper isLoading minWidth={120} minHeight={20}>
											<td data-aid="table-td-col">{col.id}</td>
										</SkeletonWrapper>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	} else if (isVirtualized) {
		table = <VirtualTable {...props} rows={rows?.sort(compare)} />;
	} else if (isEmptyTable) {
		const noRowsmessage = emptyTableMessage || TranslationEn.table.noRecords(subject);
		const errorMessage = rowsErrorMessage || TranslationEn.table.rowsErrorMessage;
		table = (
			<div css={emptyTableCss}>
				{isRowsError ? (
					<TableRowsErorr message={errorMessage} />
				) : (
					<Typography type={ETypography.body1Accented} color={ETypographyColor.secondary}>
						{noRowsmessage}
					</Typography>
				)}
			</div>
		);
	} else {
		table = (
			<table
				data-aid="table-main"
				css={tableCss(removeRowSeparator)}
				aria-labelledby="tableTitle"
				aria-label="enhanced table"
			>
				<thead data-aid="table-head" css={headerCss}>
					{!removeHeader && (
						<HeaderRow
							isSelectRow={isSelectRow}
							isRadio={isRadio}
							columns={columns}
							numSelected={selected?.length}
							rowCount={rows?.length}
							defaultSortColumn={defaultSortColumn}
						/>
					)}
				</thead>
				<tbody data-aid="table-body">
					{rows?.sort(compare).map((row, index) => {
						if (row.isDivider) {
							return (
								<tr data-aid="table-tr" css={rowCss(isHoverRow, false, false, row.isDisabled)} key={index}>
									{isSelectRow && (
										<td
											data-aid="checkbox-td"
											className="TableCell-root TableCell-body TableCell-paddingCheckbox"
											css={[deviderCss, checkboxContainer]}
										>
											{isRadio ? (
												<div>s</div>
											) : (
												<CustomCheckbox
													checked={false}
													css={checkboxCss(false)}
													checkedIcon={<span id="checked" css={CheckedIconCss} />}
													icon={<span css={iconCss} />}
												/>
											)}
										</td>
									)}
									<td
										data-aid="td-title"
										className="TableCell-root TableCell-body"
										css={deviderCss}
										colSpan={columns?.length}
									>
										{row.title}
									</td>
								</tr>
							);
						}
						const labelId = `enhanced-table-checkbox-${index}`;
						const isItemSelected =
							(isAllSelected && !selectedArr.includes(row.id)) || (!isAllSelected && selectedArr.includes(row.id));
						return (
							<ExpandableRow
								isExpand={isExpand || expandedRows.includes(row.id)}
								isRadio={isRadio}
								isHoverRow={isHoverRow}
								key={index}
								isLast={index + 1 === rows?.length}
								row={row}
								columns={columns}
								isItemSelected={isItemSelected}
								expandableKey={expandableKey}
								isSelected={isSelected}
								handleClick={onSelect}
								expandable={expandable}
								isSelectRow={isSelectRow}
								subBottomBorder={subBottomBorder}
								labelId={labelId}
								expandOn={expandOn}
								source={source}
								disableCheckbox={isCheckBoxDisabled(row)}
								isSubCheckboxDisabled={isCheckBoxDisabled}
								RowWrapper={RowWrapper}
								ExpandedRowWrapper={ExpandedRowWrapper}
								disable={row.isDisabled}
								timezone={timezone}
							/>
						);
					})}

					{emptyRows > 0 && (
						<tr css={{ height: customEmptySpace ? maxHeight - 16 - 60 * (rows?.length || 0) : 60 * emptyRows }}>
							<td data-aid="table-td-empty" className="TableCell-root TableCell-body" css={emptyCellCss} colSpan={6} />
						</tr>
					)}
					{infiniteScroll && meta.currentPage > 1 && meta.currentPage === meta.totalPages && (
						<tr>
							<td data-aid="table-td-backTop">
								<div data-aid="backToTop" css={backToTopCss} onClick={handleScrollToTop}>
									<Typography color={ETypographyColor.primary} type={ETypography.body2Link}>
										{labels.backToTop}
									</Typography>
								</div>
							</td>
						</tr>
					)}
					{infiniteScroll && <div css={inifiniteScrollIndicator} ref={lastParticipantElementRef} />}
				</tbody>
			</table>
		);
	}

	const calcSelected = () => {
		let templateSelected = (value: number) => `(${value} ${labels.selected})`;
		let numberSelected = undefined;
		if (meta?.totalSelected) {
			numberSelected = meta.totalSelected;
		} else {
			numberSelected = isAllSelected ? meta.totalItems - selectedArr?.length : selectedArr?.length;
		}
		return numberSelected ? templateSelected(numberSelected) : '';
	};

	return (
		<div css={rootCss}>
			<div css={paperCss(pagination, removeShadows)}>
				<div ref={tableRef} css={tableContainerCss(maxHeight, bottomBorder)}>
					{table}
				</div>

				{!isEmptyTable && pagination && (
					<div css={paginationContainer}>
						{footerOrder.map(component => {
							if (component === 'footer') {
								return footer;
							}
							if (component === 'labels' && typeof page === 'number' && !isNaN(page)) {
								return (
									<div key={component} css={showingStateCss}>
										{labels.showing} {page * (meta?.itemsPerPage || 0) + 1} {labels.to}{' '}
										{page + 1 === meta?.totalPages ? meta.totalItems : (page + 1) * meta?.itemsPerPage} {labels.of}{' '}
										{ssr ? meta?.totalItems : rows?.length} {subject} {`${calcSelected()}`}
									</div>
								);
							}
							if (component === 'pagination' && typeof page === 'number' && !isNaN(page) && meta?.totalPages > 1) {
								return (
									<Pagination
										page={Number(page + 1)}
										count={meta?.totalPages}
										shape="rounded"
										css={pageItem}
										renderItem={item => {
											return (
												<PaginationItem
													type={'start-ellipsis'}
													component={Link}
													to={`${location.pathname}?page=${item.page}`}
													{...item}
												/>
											);
										}}
									/>
								);
							}
						})}
					</div>
				)}
			</div>
		</div>
	);
};
