/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { SortButton } from '../SortButton';
import { flex } from '../../../styles/utils';

const columnTitleCss = (sortButtonId: string) => css`
  ${flex}
	#${sortButtonId} {
		padding: 2px 0 2px 6px; !important;
	}
`;

interface Props {
	columnTitle: string;
	isSortable: boolean;
	currentSortColumn: string;
	sortFunction: (isAsc: boolean) => void;
	isInitialAscending?: boolean;
}

export const TabelColumnTitle = ({
	columnTitle,
	isSortable,
	currentSortColumn,
	sortFunction,
	isInitialAscending,
}: Props) => {
	const uniqueColumnId = `column-sort-button-${columnTitle?.split(' ').join('-')}`;
	//if there is no columnTItle, don't add sorting
	return (
		<Fragment>
			{!isSortable
				? columnTitle
				: isSortable &&
				  columnTitle && (
						<div css={columnTitleCss(uniqueColumnId)}>
							{columnTitle}
							<SortButton
								idSelector={uniqueColumnId}
								isCurrentSort={currentSortColumn === columnTitle}
								sortFunction={sortFunction}
								isInitialAscending={isInitialAscending}
							/>
						</div>
				  )}
		</Fragment>
	);
};
