/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/react';
import { Modal } from '../Modal';
import { TranslationEn } from 'assets/i18n/en';
import { colors } from '../../../styles/theme';

const container = css`
  padding: 8rem;
  font-size: 2rem;
  font-family:"Montserrat";
  color:${colors.brandPrimary};
  font-weight:bold;
}
`;

export interface ComingSoonProps {
	toggle: () => void;
	isShowing: boolean;
}

export const ComingSoon = ({ isShowing, toggle }: ComingSoonProps) => {
	return (
		<Modal isShowing={isShowing} toggle={toggle}>
			<div css={container}>{TranslationEn.comingSoon}</div>
		</Modal>
	);
};
