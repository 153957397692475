import React from 'react';

export const Indications_question_mark = (props: any) => (
	<svg data-aid="SvgIndications_question_mark" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11 6.25C11 4.5958 9.6542 3.25 8 3.25C6.3458 3.25 5 4.5958 5 6.25C5 6.5818 5.2682 6.85 5.6 6.85C5.9318 6.85 6.2 6.5818 6.2 6.25C6.2 5.2576 7.0076 4.45 8 4.45C8.9924 4.45 9.8 5.2576 9.8 6.25C9.8 7.2424 8.9924 8.05 8 8.05C7.6682 8.05 7.4 8.3182 7.4 8.65V9.85C7.4 10.1818 7.6682 10.45 8 10.45C8.3318 10.45 8.6 10.1818 8.6 9.85V9.1888C9.9674 8.911 11 7.6984 11 6.25ZM8 11.65C7.6682 11.65 7.4 11.9182 7.4 12.25C7.4 12.5818 7.6682 12.85 8 12.85C8.3318 12.85 8.6 12.5818 8.6 12.25C8.6 11.9182 8.3318 11.65 8 11.65Z"
			fill="currentColor"
		/>
		<defs>
			<linearGradient id="paint0_linear_3109_212921" x1="16" y1="0" x2="0" y2="0" gradientUnits="userSpaceOnUse">
				<stop stop-color="#F7C500" />
				<stop offset="1" stop-color="#F7B500" />
			</linearGradient>
		</defs>
	</svg>
);
