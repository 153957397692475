import React from 'react';

export const Calendar = (props: any) => (
  <svg
    data-aid="svgCalendar"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 3C16.9971 3 17.4 3.40294 17.4 3.9V4.8H18.3C19.7912 4.8 21 6.00883 21 7.5V18.3C21 19.7912 19.7912 21 18.3 21H5.7C4.20883 21 3 19.7912 3 18.3V7.5C3 6.00883 4.20883 4.8 5.7 4.8H6.6V3.9C6.6 3.40294 7.00294 3 7.5 3C7.99706 3 8.4 3.40294 8.4 3.9V4.8H15.6V3.9C15.6 3.40294 16.0029 3 16.5 3ZM4.80006 12.0018V18.3018C4.80006 18.7988 5.203 19.2018 5.70006 19.2018H18.3001C18.7971 19.2018 19.2001 18.7988 19.2001 18.3018V12.0018H4.80006ZM4.80006 10.2018H19.2001V7.50176C19.2001 7.0047 18.7971 6.60176 18.3001 6.60176H17.4001V7.50176C17.4001 7.99881 16.9971 8.40176 16.5001 8.40176C16.003 8.40176 15.6001 7.99881 15.6001 7.50176V6.60176H8.40006V7.50176C8.40006 7.99881 7.99711 8.40176 7.50006 8.40176C7.003 8.40176 6.60006 7.99881 6.60006 7.50176V6.60176H5.70006C5.203 6.60176 4.80006 7.0047 4.80006 7.50176V10.2018Z"
      fill="currentColor"
    />
  </svg>
);
