/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import { ModalWindow, Icons } from '@bondsports/utils';
import { ModalBody } from '../../shared/ModalBody';
import { TranslationEn } from '@assets/i18n/en';

interface Props {
	fullName: string;
	mainButton?: string;
	mainAction: () => void;
	secondButton?: string;
	secondAction?: () => void;
	isRefundShowing: boolean;
	refundToggle: () => void;
}

export const RefundCustomerModal = ({
	isRefundShowing,
	refundToggle,
	fullName,
	mainButton,
	mainAction,
	secondButton,
	secondAction,
}: Props) => {
	const labels = TranslationEn.memberships.members.cancelMembership.refundModal;
	return (
		<ModalWindow toggle={refundToggle} isShowing={isRefundShowing}>
			<ModalBody
				title={`${fullName}${labels.title}`}
				icon={Icons.conflict}
				description={labels.description}
				mainButton={mainButton || labels.mainButton}
				mainAction={mainAction}
				secondButton={secondButton || labels.secondButton}
				secondAction={secondAction ?? refundToggle}
			/>
		</ModalWindow>
	);
};
