/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { colors } from 'app/react/styles/theme';

const dividerCss = (margin: string, color?: string) => css`
	background: ${color || colors.borderSeperator};
	height: 1px;
	width: 100%;
	margin: ${margin};
`;

interface Props {
	margin?: string;
	color?: string;
}
export const Divider = ({ margin = '0', color }: Props) => <div css={dividerCss(margin, color)} />;
