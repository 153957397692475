/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { IColors } from '../../../types';
import { useColors } from '../../../hooks/useColors';
import { PaymentForm } from './paymentForm';
import { CheckoutCard } from './checkoutCard';
import { mobileOnly, notMobile } from '../../../styles/utils';
import { PaymentMethod } from '../../../types/entities/payment';

const containerCss = (colors: IColors) => css`
	display: flex;
	flex-direction: column;
	height: 100%;
	scroll-behavior: smooth;

	> header {
		background: ${colors.bg_white};
		padding: 28px;
		box-shadow: 0px 2px 22px rgba(21, 93, 148, 0.0749563);
		z-index: 1;
		border-radius: 2px;
	}
`;
const backgroundCss = (colors: IColors) => css`
	background: ${colors.bg_background_secondary};
	display: flex;
	flex-grow: 1;
	height: 200px;
`;

const bodyCss = css`
	display: flex;
	flex-grow: 1;
	padding: 20px;
	max-width: 1200px;
	margin: 0 auto;
	gap: 20px;
	overflow-y: scroll;
	${mobileOnly} {
		flex-direction: column;
		padding: 0;
		gap: 0;
	}
`;

const invoiceWrapperCss = css`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 100%;
	scroll-behavior: smooth;
	${mobileOnly} {
		box-shadow: 0px 2px 22px rgba(61, 64, 67, 0.0749563);
	}
	${notMobile} {
		filter: drop-shadow(0px 2px 22px rgba(61, 64, 67, 0.0749563));
	}
`;

const checkoutCardContainerCss = css`
	max-width: 450px;
	min-width: 390px;
	position: sticky;
	top: 0;
	${mobileOnly} {
		position: relative;
	}
`;

export const InvoicingCheckoutLayout = ({
	memo,
	addPaymemtButtons,
	header,
	paymentMethods,
	handlePay,
	organizationName,
	handleDownload,
	state,
	labels,
	isLoading,
	selectedPaymentMethod,
	handleSelectPaymentMethod,
	paymentAmountInput,
	isPayButtonDisabled,
	amountToPay,
	resetFormValues,
	children = <Fragment />,
}: {
	memo: string;
	header: ReactNode;
	addPaymemtButtons: ReactNode;
	organizationName: string;
	paymentMethods: any[];
	handlePay: (selectedMethod: PaymentMethod, onDone: () => void, onSuccess: () => void, amountToPay: number) => void;
	handleDownload: (callback: () => void) => void;
	state: any;
	labels: any;
	isLoading: boolean;
	selectedPaymentMethod: any;
	handleSelectPaymentMethod: (v: PaymentMethod) => void;
	paymentAmountInput?: ReactNode;
	isPayButtonDisabled?: boolean;
	amountToPay: number;
	resetFormValues: () => void;
	children?: ReactNode;
}) => {
	const { colors } = useColors();
	const [isPaymentPage, setPaymentPage] = useState(false);

	const handlePayButton = () => {
		setPaymentPage(true);
	};

	const paymentPageToggle = () => {
		resetFormValues();
		setPaymentPage(prev => !prev);
	};

	const successCallback = () => {
		setPaymentPage(false);
		resetFormValues();
	};

	return (
		<div data-aid="invoiceCheckoutLayout" css={containerCss(colors)}>
			<header>{header}</header>
			<div css={backgroundCss(colors)}>
				<div css={bodyCss}>
					<div css={checkoutCardContainerCss}>
						<CheckoutCard
							handlePayButton={handlePayButton}
							memo={memo}
							organizationName={organizationName}
							handleDownload={handleDownload}
							labels={labels}
							state={state}
							isLoading={isLoading}
							isPaymentPage={isPaymentPage}
							isPaymentMethodSelected={!!selectedPaymentMethod}
						/>
					</div>
					<div css={invoiceWrapperCss}>
						{isPaymentPage ? (
							<PaymentForm
								paymentPageToggle={paymentPageToggle}
								addPaymemtButtons={addPaymemtButtons}
								paymentMethods={paymentMethods}
								handlePay={(v, onDone) => {
									handlePay(v, onDone, successCallback, amountToPay);
								}}
								labels={labels}
								state={state}
								selectedPaymentMethod={selectedPaymentMethod}
								handleSelectPaymentMethod={handleSelectPaymentMethod}
								paymentAmountInput={paymentAmountInput}
								isPayButtonDisabled={isPayButtonDisabled}
								amountToPay={amountToPay}
								handleBack={paymentPageToggle}
							/>
						) : (
							<Fragment>{children}</Fragment>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
