/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import { TGroup, TOption } from './types';
import { ETypography, ETypographyColor, IColors } from '../../types';
import { StyledSelectOption } from '../Options_v2/style';
import { Typography } from '../../atoms';
import { Checkbox } from '../Checkbox';
import { BnIcon } from '../icons_v2';
import { capitalize } from '../../functions';
import { SizeEnum } from '../../lib/constants';
import { find, isEqual } from 'lodash';

type SelectOptionsProps<U> = {
	onSelect: (val: U) => void;
	value?: U[];
	isMultiple?: boolean;
	enableSelectAll?: boolean;
	selectAllLabel?: string;
	size: SizeEnum;
	handleLoadNextPage?: () => void;
	isLoading?: boolean;
	isFinalPage?: boolean;
	originalOptions?: TOption<U>[];
	isGroups?: boolean;
	onSelectGroup?: (group: TGroup<any, any>) => void;
	lastOptionElementRef: ((node: HTMLDivElement) => void) | null;
	selectedValuesArray: U[];
	autoFocus?: boolean;
	option: TOption<U>;
	index: number;
	colors: IColors;
};

export const SelectOption = <T, U>({
	colors,
	index,
	size,
	onSelect,
	option,
	autoFocus,
	isMultiple,
	selectedValuesArray,
	value,
	lastOptionElementRef,
}: SelectOptionsProps<U>) => {
	// @ts-ignore
	return (
		<StyledSelectOption
			theme={{ ...colors }}
			key={index}
			onClick={() => onSelect(option.value)}
			tabIndex={0}
			size={size}
			autoFocus={autoFocus ? !index : false}
			isDisabled={option.isDisabled}
		>
			<Typography
				color={option.isDisabled ? ETypographyColor.secondary : ETypographyColor.primary}
				type={ETypography.body2}
			>
				<span data-aid="SelectOption-span" ref={lastOptionElementRef}>
					{isMultiple && (
						<Checkbox
							data-aid="SelectOption-checkbox"
							width={16}
							functionDisable
							setChecked={() => {}}
							checked={!!find(selectedValuesArray, val => isEqual(val, option.value))}
						/>
					)}
					{option.icon && <BnIcon icon={option.icon} />}
					{capitalize(option.label)}
				</span>

				{!isMultiple && String(value) === String(option.value) && <BnIcon icon="check" />}
			</Typography>
		</StyledSelectOption>
	);
};
