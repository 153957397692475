/* @jsx jsx */

import React, { useCallback, useState } from 'react';
import { css, jsx } from '@emotion/react';
import {
	alignItemsCss,
	flexCol,
	flexGrowCss,
	flexRowCss,
	fullHeightCss,
	fullWidthCss,
	gapCss,
	overflowBehaviorCss,
	paddingTopCss,
	positionCss,
	sidePaddingCss,
	widthCss,
	widthPxCss,
} from '../../../styles/utils';
import { ActivitiesEnum } from './types/enums';
import { ActivityCategoriesSection } from './sections/ActivityCategoriesSection';
import { ActivitySection } from './sections/ActivitySection';
import { ICustomer } from '../../../types/customers';
import { TranslationEn } from '../../../../../assets/i18n/en';
import { Activity } from './views/ActivityCategoriesView';
import { CustomerActivitiesContext } from './customerActivitiesContext';
import { useOrganizationTimezone } from '../../../hooks/useOrganizationTimezone';

type CustomerActivitiesProps = {
	customerState: ICustomer;
	displayUserDetails?: boolean;
};

const zeroTopPositionCss = css`
	top: 0px;
`;

const LABELS = TranslationEn.customers.activitiesTab;

const ACTIVITIES: Activity[] = [
	LABELS.activitiesBar.buttons.class,
	LABELS.activitiesBar.buttons.clinic,
	LABELS.activitiesBar.buttons.camps,
	LABELS.activitiesBar.buttons.privateLesson,
	LABELS.activitiesBar.buttons.clubTeam,
];

const PUNCH_PASS_ACTIVITY: Activity = LABELS.activitiesBar.buttons.punchPass;

export const CustomerActivities: React.FC<CustomerActivitiesProps> = ({
	customerState,
	displayUserDetails = false,
}) => {
	const { timezone } = useOrganizationTimezone();

	const [selectedActivity, setSelectedActivity] = useState<ActivitiesEnum>(ActivitiesEnum.CLASS);

	const onClick = useCallback((key: ActivitiesEnum) => {
		setSelectedActivity(key);
	}, []);

	return (
		<CustomerActivitiesContext.Provider
			value={{ labels: LABELS, customer: customerState, displayUserDetails, selectedActivity, timezone }}
		>
			<div
				data-aid="CustomerActivities"
				css={[
					flexRowCss,
					fullWidthCss,
					paddingTopCss(20),
					sidePaddingCss(16),
					alignItemsCss('start'),
					gapCss(16),
					overflowBehaviorCss('none', 'scroll'),
				]}
			>
				<section css={[widthPxCss(300), positionCss('sticky'), zeroTopPositionCss]}>
					<ActivityCategoriesSection
						onClick={onClick}
						selectedActivity={selectedActivity}
						activities={ACTIVITIES}
						mainActivity={PUNCH_PASS_ACTIVITY}
					/>
				</section>
				<section css={[flexCol, gapCss(16), widthCss(60), fullHeightCss]}>
					<ActivitySection />
				</section>
			</div>
		</CustomerActivitiesContext.Provider>
	);
};
