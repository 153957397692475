/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx, css } from '@emotion/react';
import { Button } from '../Button';
import { i18n } from '../../lib/i18n';

export const ShowMoreButton = ({ handleShowMore }: { handleShowMore: (isShowMore: boolean) => void }) => {
	const [expanded, setExpanded] = useState(false);
	const { showMore, showLess } = i18n.ShowMoreButton;
	const showMoreToggle = () => {
		setExpanded(state => !state);
	};

	const onClick = () => {
		showMoreToggle();
		handleShowMore(expanded);
	};

	return (
		<div data-aid="index-ShowMoreButton">
			<Button data-aid="button-ShowMoreButton-do" theme="basicLine" sizer="XS" onClick={onClick} type="button">
				{expanded ? showLess : showMore}
			</Button>
		</div>
	);
};
