import React, { Fragment } from 'react';
import { Field as FinalField } from 'react-final-form';
import { DateInputProps, InputProps } from '../../types';
import { DateInput } from '../Input/DateInput';

export interface IFormProps extends DateInputProps {
	name: string;
	onClear?: (val: string) => void;
	required?: boolean;
	isBirthday?: boolean;
	grayedOut?: boolean;
	initialValue?: any;
}

interface IUseYupValidation {
	useyup?: boolean;
}

const FormDateInputWithYup = ({ onClear = (val: string) => {}, ...props }: IFormProps) => {
	const clear = () => onClear(props.name);
	return (
		<FinalField name={props.name} displayEmpty initialValue={props.initialValue}>
			{({ input, meta }) => {
				const newProps = { ...props };

				return (
					<Fragment>
						<DateInput {...props} {...input} {...newProps} error={meta.error || props.error} clear={clear} />
					</Fragment>
				);
			}}
		</FinalField>
	);
};

const OldFormDateInput = ({ onClear = (val: string) => {}, ...props }: IFormProps) => {
	const clear = () => onClear(props.name);
	return (
		<FinalField
			name={props.name}
			validate={val => {
				if (props.required) {
					if (!val) {
						let errors = {};
						errors[props.name] = 'required';
						return errors;
					}
				}
				return undefined;
			}}
			displayEmpty
			initialValue={props.initialValue}
		>
			{({ input, meta }) => {
				const newProps = { ...props };

				return (
					<Fragment>
						<DateInput {...props} {...input} error={meta.error && meta.error[props.name]} clear={clear} />
					</Fragment>
				);
			}}
		</FinalField>
	);
};

export const FormDateInput = ({ onClear = (val: string) => {}, ...props }: IFormProps & IUseYupValidation) => {
	return props.useyup ? (
		<FormDateInputWithYup {...props} onClear={onClear} />
	) : (
		<OldFormDateInput {...props} onClear={onClear} />
	);
};
