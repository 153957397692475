import React from 'react';

export const Location = (props: any) => (
  <svg
    data-aid="svgLocation"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.53 9.46953C4.53 5.34054 7.87054 2 11.9995 2C16.1285 2 19.4691 5.34054 19.4691 9.46953C19.4691 11.7049 18.3014 14.2171 17.0499 16.3045C15.7726 18.435 14.2914 20.3096 13.4544 21.3141C12.6922 22.2286 11.3068 22.2286 10.5447 21.3141C9.70767 20.3096 8.22651 18.435 6.94918 16.3045C5.69768 14.2171 4.53 11.7049 4.53 9.46953ZM11.9995 4.06849C9.01293 4.06849 6.59848 6.48293 6.59848 9.46953C6.59848 11.1024 7.4976 13.1965 8.72325 15.2409C9.85883 17.1349 11.1794 18.8331 11.9995 19.8279C12.8196 18.8331 14.1402 17.1349 15.2758 15.2409C16.5015 13.1965 17.4006 11.1024 17.4006 9.46953C17.4006 6.48293 14.9861 4.06849 11.9995 4.06849ZM11.9995 8.20546C11.3021 8.20546 10.7355 8.77206 10.7355 9.46953C10.7355 10.167 11.3021 10.7336 11.9995 10.7336C12.697 10.7336 13.2636 10.167 13.2636 9.46953C13.2636 8.77206 12.697 8.20546 11.9995 8.20546ZM8.66697 9.46953C8.66697 7.62967 10.1597 6.13697 11.9995 6.13697C13.8394 6.13697 15.3321 7.62967 15.3321 9.46953C15.3321 11.3094 13.8394 12.8021 11.9995 12.8021C10.1597 12.8021 8.66697 11.3094 8.66697 9.46953Z"
      fill="currentColor"
    />
  </svg>
);
