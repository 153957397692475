import { BasicActivityTimesDto } from '@bondsports/types';
import { first, last, sortBy } from 'lodash';
import { DAY_END_TIME, DAY_START_TIME, dayOfWeekMapper } from '../../../../lib/dates';

export type FormattedActivityTimes = {
	openingDays: string;
	open: string;
	close: string;
};

function getSetsOfConsecutiveDays(sortedActivityTimes: BasicActivityTimesDto[]): BasicActivityTimesDto[][] {
	const daySets: BasicActivityTimesDto[][] = [];
	let currentSet: BasicActivityTimesDto[] = [sortedActivityTimes[0]];

	for (let i = 1; i < sortedActivityTimes.length; i++) {
		if (sortedActivityTimes[i].dayOfWeek === sortedActivityTimes[i - 1].dayOfWeek + 1) {
			currentSet.push(sortedActivityTimes[i]);
		} else {
			daySets.push(currentSet);
			currentSet = [sortedActivityTimes[i]];
		}
	}

	daySets.push(currentSet);

	return daySets;
}

export function formatActivityTimes(
	activityTimes: BasicActivityTimesDto[],
	dayMapper: { [day: number]: string } = dayOfWeekMapper
): FormattedActivityTimes[] {
	const activityTimeToOpeningHoursMap: { [key: string]: BasicActivityTimesDto[] } = activityTimes.reduce(
		(map, activityTime) => {
			const timeKey = `${activityTime.open}-${activityTime.close}`;
			if (!map[timeKey]) {
				map[timeKey] = [];
			}

			map[timeKey].push(activityTime);

			return map;
		},
		{}
	);

	const formattedActivityTimes: FormattedActivityTimes[] = [];

	for (const activityTimesToHours of Object.values(activityTimeToOpeningHoursMap)) {
		const sortedActivityTimes: BasicActivityTimesDto[] = sortBy(
			activityTimesToHours,
			activityTime => activityTime.dayOfWeek
		);

		let openingDays: string;
		if (sortedActivityTimes.length === 1) {
			openingDays = dayMapper[first(sortedActivityTimes).dayOfWeek];
		}

		const sets: BasicActivityTimesDto[][] = getSetsOfConsecutiveDays(sortedActivityTimes);
		openingDays = sets.reduce((openingDays, set) => {
			if (set.length === 1) {
				return openingDays + `${openingDays === '' ? '' : '/ '}${dayMapper[first(set).dayOfWeek]}`;
			}

			return (
				openingDays +
				`${openingDays === '' ? '' : '/ '}${dayMapper[first(set).dayOfWeek]} - ${dayMapper[last(set).dayOfWeek]}`
			);
		}, '');

		formattedActivityTimes.push({
			openingDays,
			open: sortedActivityTimes[0].open,
			close: sortedActivityTimes[0].close,
		});
	}

	return formattedActivityTimes;
}

export function isPastSegment(endDate: Date | string, activityTimes: BasicActivityTimesDto[]): boolean {
	const endDateAtMidnight = new Date(`${endDate}T${DAY_START_TIME}Z`);
	const endTime = activityTimes.filter(a => a.dayOfWeek === endDateAtMidnight.getDay())?.[0]?.close ?? DAY_END_TIME;
	return Date.parse(`${endDate}T${endTime}Z`) < Date.now();
}
