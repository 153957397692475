import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/shared/services/auth/authentication.service';
import { FileUploaderService } from '@app/shared/services/utils/file-uploader.service';
import { RCLeague, RCLeagueSeason } from '@rcenter/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from 'ngx-bootstrap/modal';

const ONE_MB = 1_048_576;

// MAX_FILE_SIZE is a bytes of 4mb
const MAX_FILE_SIZE = 4 * ONE_MB;

@Component({
	selector: 'rc-schedule-xls-upload-modal',
	templateUrl: './schedule-xls-upload-modal.component.html',
	styleUrls: ['./schedule-xls-upload-modal.component.scss'],
	exportAs: 'modal',
})
export class ScheduleXlsUploadModalComponent implements OnInit {
	loading = false;
	@ViewChild('modal', { static: true }) modal: ModalDirective;
	@ViewChild('fileUploader', { static: false }) fileUploadInput: ElementRef<HTMLInputElement>;
	@Output() onUploaded = new EventEmitter();
	@Input() league: RCLeague;
	@Input() season: RCLeagueSeason;
	uploader: FileUploader;

	// Support Only CSV File
	public allowedMimeTypes = ['.csv'];

	constructor(
		private toastr: ToastrService,
		private fileUploaderService: FileUploaderService,
		private authService: AuthenticationService
	) {}

	ngOnInit() {
		this.uploader = new FileUploader({
			isHTML5: true,
			authToken: `Bearer ${this.authService.getToken()}`,
		});

		this.uploader.onAfterAddingFile = file => {
			if (this.uploader.queue.length > 1) {
				this.uploader.removeFromQueue(this.uploader.queue[0]);
			}
			if (file._file.size > MAX_FILE_SIZE) {
				this.toastr.error('File size is too big, max file size is 4MB');
				this.uploader.removeFromQueue(file);
				this.fileUploadInput.nativeElement.value = '';
			}
		};

		this.uploader.onWhenAddingFileFailed = () => {
			this.toastr.error('This file type is not supported');
		};
	}

	showModal() {
		this.modal.show();
	}

	cancel() {
		this.modal.hide();
	}

	get selectedFile(): FileItem {
		const files = this.uploader.getNotUploadedItems();
		return files[0];
	}

	onSubmit() {
		if (!this.selectedFile) return this.toastr.warning('File was not selected');
		this.loading = true;

		const url = `/leagues/${this.season.leagueId}/organization/${this.season.seasonLeague['organizationId']}/season/${this.season.id}/upload-schedule`;
		this.fileUploaderService.uploadFile(url, this.selectedFile, true).subscribe(
			response => {
				if (response?.err) {
					this.errorInUpload(JSON.stringify(response));
					this.fileUploadInput.nativeElement.value = '';
				} else {
					this.loading = false;
					this.onUploaded.emit();
					this.toastr.success('Successfully uploaded excel file');
					this.modal.hide();
				}
			},
			response => {
				this.errorInUpload(response);
			}
		);
	}

	private errorInUpload(response: any) {
		const parsed = JSON.parse(response);
		this.loading = false;
		this.toastr.error(
			parsed?.error ??
				parsed?.err ??
				'Error occurred while uploading excel. Make sure the excel file matches the template'
		);
	}
}
