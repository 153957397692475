import React from "react";
import { IColors, ITypography } from "../../types/theme";
import { css, jsx } from "@emotion/react";

export const AddonContainerCss = (colors: IColors) => css`
  display: flex;
  flex-direction: column;
  background: ${colors.bg_background_secondary};
  border: 1px solid ${colors.bg_border_primary};
  box-sizing: border-box;
  border-radius: 4px;
  max-width: 500px;
  margin: 8px 0;
`;

export const headerCss = (colors: IColors, typography: ITypography) => css`
  display: flex;
  align-items: center;
  padding: 1.6rem 4rem;
  border-bottom: 1px solid ${colors.bg_border_seperator};

  .checkbox {
    position: relative;
    transform: translate(-20px, 5px);
  }
  .icon_container {
    height: 44px;
    width: 44px;
    background: ${colors.bg_system};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    color: ${colors.tx_white};
  }
  .titles {
    margin-left: 1rem;
  }
  h1 {
    font-family: ${typography.fontPrimary};
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: ${colors.tx_text_primary};
    margin: 0;
  }
  h2 {
    font-family: ${typography.fontPrimary};
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 130%;
    margin: 0;
    color: ${colors.tx_text_secondary};
  }

  @media (max-width: 767px) {
    padding: 1.6rem;
  }
`;

export const mainCss = css`
  padding: 0.7rem 4rem;
  @media (max-width: 767px) {
    padding: 0.7rem 1.6rem;
  }
`;

export const footerCss = (colors: IColors, typography: ITypography) => css`
  border-top: 1px solid ${colors.bg_border_seperator};
  padding: 1.6rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .price_container {
    font-family: ${typography.fontPrimary};
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: ${colors.tx_text_primary};
    div {
      margin-right: 4px;
      font-weight: 500;
      color: ${colors.tx_text_secondary};
    }
  }
  @media (max-width: 767px) {
    padding: 1.6rem;
  }
`;

export const emptySlot = (isTinyView: boolean) => css`
  height: 30px;
  width: ${isTinyView ? "52px" : "70px"};
  padding: 6px 4px;
  margin: 12px 6px;
  margin-left: 0;
  @media (max-width: 767px) {
    width: ${isTinyView ? "38px" : "50px"};
  }
`;

export const dateContainerCss = (
  colors: IColors,
  typography: ITypography
) => css`
  margin: 16px 0;
  .title {
    font-family: ${typography.fontPrimary};
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: ${colors.tx_text_secondary};
    margin-bottom: 16px;
  }
  .schedularContainer {
    display: flex;
    flex-direction: column;
    .schedularHeader {
      display: none; // temportal
      align-items: flex-start;
      margin: 12px 0 16px 0;
      div {
        display: flex;
        flex-direction: column;
        h3 {
          font-family: ${typography.fontPrimary};
          font-style: normal;
          font-weight: normal;
          font-size: 1.4rem;
          line-height: 130%;
          color: ${colors.tx_text_primary};
          margin: 0;
        }
      }
    }

    .toggleContainer {
      margin: 16px 0;
      label {
        font-family: ${typography.fontPrimary};
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 130%;
        color: ${colors.tx_text_secondary};
      }
    }
    .daysContainer {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;
