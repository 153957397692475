import { FuturePaymentStatusEnum } from '@bondsports/types';
import { TranslationEn } from '../../../../assets/i18n/en';
import { paymentApi } from '../../lib/api/paymentApi';
import { BookingDto } from '../../types/NewReservation';
import { IPurchaseRes } from '../../types/orders';
import { useErrorModal } from '../useErrorModal';
import { TChargeScheduled, IPayProps, TPurchaseData } from './types';

export const usePurchase = () => {
	const { setErrorModal } = useErrorModal();
	const generalErrorMessage = TranslationEn.errors.purchase.generalError;

	const handleResponseParse = (res: IPurchaseRes) => {
		if (res.status === FuturePaymentStatusEnum.FAILED) setFailedPaymentError();
		return res;
	};

	const setFailedPaymentError = () => {
		setErrorModal({ message: generalErrorMessage });
	};

	const handlePurchase = async (data: Partial<TPurchaseData>, organizationId: number): Promise<IPurchaseRes> => {
		const res = await paymentApi.purchase(data, organizationId);

		return handleResponseParse(res);
	};

	const handlePurchasePartial = async (data: IPayProps): Promise<IPurchaseRes> => {
		const res = await paymentApi.payPartialBalance(data);

		return handleResponseParse(res);
	};

	const handleChargeScheduled = async (data: TChargeScheduled): Promise<IPurchaseRes> => {
		const res = await paymentApi.chargeSelectedScheduled(data);

		return handleResponseParse(res);
	};

	const handlePurchaseRental = async (
		data: BookingDto,
		organizationId: number,
		facilityId: number
	): Promise<IPurchaseRes> => {
		const res = await paymentApi.rentalPurchase(data, organizationId, facilityId);

		return handleResponseParse(res);
	};

	return {
		handleChargeScheduled,
		handlePurchase,
		handlePurchasePartial,
		handlePurchaseRental,
	};
};
