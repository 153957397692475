/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { ReactNode, useState } from 'react';
import { PaymentContext } from './context';
import { BillingInfo } from './billingInfo';
import { Details, PrintableDetails } from './details';
import { Body } from './body';
import { Header } from './header';
import { Payments } from './payments';
import { Notes } from '../../molecules/invoicing';
import { containerCss } from '../invoiceTemplate/styles';

export const Payment = ({
	state,
	labels,
	isLoading = false,
	setLoading = () => {},
	children,
}: {
	state: any;
	labels: any;
	isLoading?: boolean;
	setLoading?: (v: boolean) => void;
	children: ReactNode;
}) => {
	return (
		<PaymentContext.Provider
			value={{
				state,
				labels,
				isLoading,
				setLoading,
			}}
		>
			<div css={containerCss(true)} data-aid="index-receipt">
				{children}
			</div>
		</PaymentContext.Provider>
	);
};

Payment.Header = Header;
Payment.Body = Body;
Payment.PrintableBillingInfo = BillingInfo;
Payment.PrintableDetails = PrintableDetails;
Payment.Payments = Payments;
Payment.Notes = Notes;
