import React from 'react';

export const Cart = (props: any) => (
  <svg
    data-aid="svgCart"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00016 2.08337C2.4939 2.08337 2.0835 2.49378 2.0835 3.00004C2.0835 3.5063 2.4939 3.91671 3.00016 3.91671H4.22363L4.88836 7.90507C4.88514 7.93629 4.8835 7.96797 4.8835 8.00004C4.8835 8.13798 4.91396 8.2688 4.96854 8.38615L6.09597 15.1507C6.10466 15.2029 6.11786 15.2542 6.1354 15.3041C6.33497 15.8716 6.66137 16.3823 7.08598 16.7993C6.47174 17.3341 6.0835 18.1217 6.0835 19C6.0835 20.6109 7.38933 21.9167 9.00016 21.9167C10.611 21.9167 11.9168 20.6109 11.9168 19C11.9168 18.6172 11.8431 18.2516 11.709 17.9167H14.2913C14.1572 18.2516 14.0835 18.6172 14.0835 19C14.0835 20.6109 15.3893 21.9167 17.0002 21.9167C18.611 21.9167 19.9168 20.6109 19.9168 19C19.9168 18.3493 19.7037 17.7483 19.3435 17.2631C20.0495 16.7922 20.5834 16.1047 20.8649 15.3041C20.8848 15.2475 20.8991 15.1891 20.9076 15.1297L21.9076 8.12968C21.9452 7.86661 21.8668 7.60019 21.6927 7.39944C21.5186 7.19869 21.2659 7.08337 21.0002 7.08337H6.61003L5.90436 2.84934C5.83069 2.40734 5.44827 2.08337 5.00016 2.08337H3.00016ZM16.9798 17.9169C17.0182 17.9188 17.0566 17.9201 17.095 17.9208C17.6489 17.9689 18.0835 18.4337 18.0835 19C18.0835 19.5984 17.5985 20.0834 17.0002 20.0834C16.4019 20.0834 15.9168 19.5984 15.9168 19C15.9168 18.4086 16.3909 17.9278 16.9798 17.9169ZM17.1692 16.0882C17.5799 16.0884 17.9825 15.9671 18.3257 15.7383C18.6786 15.503 18.9508 15.166 19.1066 14.7729L19.9432 8.91671H6.91558L7.89009 14.7638C8.04537 15.1608 8.319 15.5012 8.67466 15.7383C9.05041 15.9888 9.49738 16.1104 9.94826 16.0848C9.96554 16.0839 9.98285 16.0834 10.0002 16.0834H16.9978C16.9986 16.0834 16.9994 16.0834 17.0002 16.0834C17.0569 16.0834 17.1132 16.085 17.1692 16.0882ZM9.00016 17.9167C8.40185 17.9167 7.91683 18.4017 7.91683 19C7.91683 19.5984 8.40185 20.0834 9.00016 20.0834C9.59847 20.0834 10.0835 19.5984 10.0835 19C10.0835 18.4017 9.59847 17.9167 9.00016 17.9167Z"
      fill="currentColor"
    />
  </svg>
);
