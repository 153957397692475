/* @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import { alignItemsCss, flexCol, justifyContentCss, verticalPaddingCss } from '../../../../../styles/utils';
import { separatorCss } from '../../styles';
import { CHARACHERS, ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import { formatDate } from '../../../../../lib/dates';
import { EDateTimeFormats } from '../../../../../types/times';

type RemovedFromActivityPromptProps = {
	latestDeletedEventDate: Date;
	timezone: string;
	labels: any;
};

export const RemovedFromActivityPrompt: React.FC<RemovedFromActivityPromptProps> = ({
	latestDeletedEventDate,
	timezone,
	labels,
}) => {
	return (
		<div
			data-aid="removed-form-session-prompt"
			css={[
				flexCol,
				justifyContentCss('center'),
				alignItemsCss('center'),
				separatorCss('bottom'),
				verticalPaddingCss(28),
			]}
		>
			<Typography
				data-aid="removed-form-session-prompt-label"
				type={ETypography.body2}
				color={ETypographyColor.secondary}
			>
				{labels.removedFromSession}
			</Typography>
			<Typography
				data-aid="removed-form-session-prompt-date"
				type={ETypography.body2}
				color={ETypographyColor.secondary}
			>
				{`${formatDate(latestDeletedEventDate, EDateTimeFormats.DAY_FORMAT)} ${CHARACHERS.DASH} ${formatDate(
					latestDeletedEventDate,
					EDateTimeFormats.H12_ampm,
					timezone
				)}`}
			</Typography>
		</div>
	);
};
