/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, ReactNode, useContext, useRef } from 'react';
import { jsx, css } from '@emotion/react';
import { ETypography, ETypographyColor, IColors } from '../../types';
import { useColors } from '../../hooks/useColors';
import { Row, rowCss } from './row';
import { ITableProps } from './types';
import { Header } from './header';
import { Typography } from '../../atoms';
import { AdditionalInfoRow } from './AdditionalInfoRow';

const rootCss = (colors: IColors) => css`
	width: 100%;
	height: 100%;
	display: flex;
	color: ${colors.black};
`;

const paperCss = (pagination: boolean) => css`
	width: 100%;
	padding: ${pagination ? '2rem' : '0'};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: white;
`;

const tableContainerCss = (height: number, bottomBorder: boolean, autoOverflow = true) => css`
	${height > 0 && `max-height: ${height}px;`}
	display: flex;
	flex-grow: 1;
	overflow: ${autoOverflow ? 'auto' : 'unset'};
`;

const tableCss = (colors: IColors, removeRowSeparator = false, noHeight: boolean) => css`
	${!noHeight && 'height: 100%'};
	min-width: 150px;
	width: 100%;
	border: 1px solid ${colors.bg_border_seperator};
	border-radius: 5px;
	thead {
		position: sticky;
		top: 0;
		border-radius: 5px 5px 0 0;
		tr {
			th:first-child {
				border-radius: 5px 0 0 0;
				padding-left: 16px;
			}
			th:last-child {
				border-radius: 0 5px 0 0;
				padding-right: 16px;
			}
		}
	}
	tbody {
		tr {
			td:first-child {
				padding-left: 16px;
			}
			td:last-child {
				padding-right: 16px;
			}
		}
		td {
			color: ${colors.tx_text_primary};
			border-bottom: ${removeRowSeparator ? '0' : '1px solid rgba(224, 224, 224, 1)'};
			border-spacing: 0;
		}
	}
	tbody tr:last-child {
		border-radius: 0 0 5px 5px;
		td:first-child {
			border-radius: 0 0 0 5px;
		}
		td:last-child {
			border-radius: 0 0 5px 0;
		}
		td {
			border-bottom: 0px solid rgba(224, 224, 224, 1);
		}
	}

	tfoot {
		tr {
			background: ${colors.bg_background_primary};
			border-radius: 0 0 5px 5px;
			td {
				border-top: 1px solid ${colors.bg_border_seperator};
				border-radius: 0 0 5px 5px;
			}
		}
	}

	[align='right'] {
		* {
			display: flex;
			justify-content: end;
		}
	}
	[align='left'] {
		* {
			display: flex;
			justify-content: start;
		}
	}
	[align='center'] {
		* {
			display: flex;
			justify-content: center;
		}
		div {
			margin: 0 auto;
		}
	}
`;

export function Table<T>({
	columns,
	rows,
	pagination = true,
	maxHeight = 700,
	bottomBorder = true,
	removeRowSeparator = false,
	isLoading = false,
	footer,
	isHover = true,
	expandable = false,
	expandableKey = '',
	expandOn = [],
	isExpand = false,
	additionalInfoRow = '',
	subRowsColumns,
}: ITableProps<T>) {
	const { colors } = useColors();
	const tableRef = useRef(null);

	return (
		<div css={rootCss(colors)} data-aid="index-table">
			<div css={paperCss(pagination)}>
				<div ref={tableRef} css={tableContainerCss(maxHeight, bottomBorder, maxHeight !== -1)}>
					<table css={tableCss(colors, removeRowSeparator, maxHeight === -1)} cellSpacing={0}>
						<thead>
							<Header columns={columns} />
						</thead>
						<tbody>
							{isLoading ? (
								[0, 1, 2, 3].map(row => {
									return (
										<Row
											key={row}
											state={{ id: row }}
											columns={columns}
											isLoading={isLoading}
											isHoverRow={isHover}
											{...{ expandable, expandableKey, expandOn, isExpand }}
										/>
									);
								})
							) : (
								<Fragment>
									{additionalInfoRow && <AdditionalInfoRow numOfColumns={columns.length} text={additionalInfoRow} />}

									{rows?.map(row => {
										return (
											<Row
												key={row.id}
												state={row}
												columns={columns}
												isLoading={isLoading}
												isHoverRow={isHover}
												subRows={(row as any).subRows}
												subRowsColumns={subRowsColumns}
												{...{ expandable, expandableKey, expandOn, isExpand }}
											/>
										);
									})}
								</Fragment>
							)}
						</tbody>
						{footer && (
							<tfoot>
								<tr>
									<td colSpan={columns.length}>{footer}</td>
								</tr>
							</tfoot>
						)}
					</table>
				</div>
			</div>
		</div>
	);
}
