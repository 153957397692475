/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactNode } from 'react';
import { jsx, css } from '@emotion/react';
import { i18n } from '../../lib/i18n';
import { Typography } from '../../atoms/Typography';
import { ETypography, ETypographyColor } from '../../types';
import { Tooltip } from '../../components/Tooltip';
import { fitContentCss } from '../../styles/utils';

export const MoreTooltip = ({ content, moreItemsCounter }: { content: ReactNode; moreItemsCounter: number }) => {
	return (
		<div css={fitContentCss} data-aid="index-MoreTooltip">
			<Tooltip placement="top-start" content={content}>
				<Typography type={ETypography.overlineLink} color={ETypographyColor.secondary}>
					{`+${moreItemsCounter} ${i18n.more}`}
				</Typography>
			</Tooltip>
		</div>
	);
};
