import React from 'react';

export const Indications_alert = (props: any) => (
	<svg data-aid="SvgIndications_alert" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
			fill="currentColor"
		/>
		<path
			d="M13.2006 6.14985C13.2006 5.56995 12.7305 5.09985 12.1506 5.09985C11.5707 5.09985 11.1006 5.56995 11.1006 6.14985V14.85C11.1006 15.4299 11.5707 15.9 12.1506 15.9C12.7305 15.9 13.2006 15.4299 13.2006 14.85V6.14985Z"
			fill="white"
		/>
		<path
			d="M12.1518 19.3497C12.7317 19.3497 13.2018 18.8796 13.2018 18.2997C13.2018 17.7198 12.7317 17.2497 12.1518 17.2497C11.5719 17.2497 11.1018 17.7198 11.1018 18.2997C11.1018 18.8796 11.5719 19.3497 12.1518 19.3497Z"
			fill="white"
		/>
	</svg>
);
