import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomersService } from '@app/shared/services/customers/customers.service';
import { OrganizationsService } from '@app/shared/services/organization/organizations.service';
import { Throttle, throttle } from '@app/shared/services/utils/throttle.service';
import { RCUser } from '@rcenter/core';

@Component({
	selector: 'rc-team-player-customer-search',
	templateUrl: './customer-search.component.html',
	styleUrls: ['./customer-search.component.scss'],
})
export class CustomerSearchComponent implements OnInit {
	@Output() hide = new EventEmitter<void>();
	@Output() select = new EventEmitter<RCUser>();
	@Input() teamId: number;
	@Input() loading: boolean;

	searchText = '';
	customers: RCUser[] = [];

	throttledSearch: Throttle<void>;

	constructor(private customerService: CustomersService, private organizationService: OrganizationsService) {}

	ngOnInit(): void {
		this.throttledSearch = throttle(() => {
			this.search();
		}, 300);
	}

	async onSelectCustomer(customer: RCUser) {
		this.select.emit(customer);
	}

	onSearchChange(event: InputEvent) {
		this.searchText = (event.target as HTMLInputElement).value;

		this.customers = [];

		this.throttledSearch();
	}

	search() {
		const { id } = this.organizationService.getCurrentOrganization();
		this.customerService
			.searchCustomers(id, this.searchText)
			.pipe()
			.subscribe(response => {
				this.customers = response.data;
			});
	}
}
