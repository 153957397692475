import React from 'react';

export const DeleteOutline = (props: any) => (
  <svg
    data-aid="svgDeleteOutline"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.82313 3.82322C9.87002 3.77634 9.93361 3.75 9.99991 3.75H13.9999C14.0662 3.75 14.1298 3.77634 14.1767 3.82322C14.2236 3.87011 14.2499 3.9337 14.2499 4V6.25002H9.74991V4C9.74991 3.9337 9.77625 3.87011 9.82313 3.82322ZM8.24991 6.25002V4C8.24991 3.53587 8.43429 3.09075 8.76247 2.76256C9.09066 2.43437 9.53578 2.25 9.99991 2.25H13.9999C14.464 2.25 14.9092 2.43437 15.2373 2.76256C15.5655 3.09075 15.7499 3.53587 15.7499 4V6.25002H18.989H19.0091H20C20.4142 6.25002 20.75 6.58581 20.75 7.00002C20.75 7.41424 20.4142 7.75002 20 7.75002H19.69L18.7497 19.0337C18.7409 19.7508 18.4522 20.4368 17.9444 20.9445C17.4287 21.4603 16.7292 21.75 15.9999 21.75H7.99986C7.27052 21.75 6.57105 21.4603 6.05532 20.9445C5.54754 20.4368 5.25884 19.7508 5.25007 19.0337L4.30977 7.75002H4C3.58579 7.75002 3.25 7.41424 3.25 7.00002C3.25 6.58581 3.58579 6.25002 4 6.25002H4.99065H5.01075H8.24991ZM6.74727 18.9377L5.81497 7.75002H18.1848L17.2525 18.9377C17.2507 18.9584 17.2499 18.9792 17.2499 19C17.2499 19.3315 17.1182 19.6495 16.8837 19.8839C16.6493 20.1183 16.3314 20.25 15.9999 20.25H7.99986C7.66834 20.25 7.3504 20.1183 7.11598 19.8839C6.88156 19.6495 6.74986 19.3315 6.74986 19C6.74986 18.9792 6.749 18.9584 6.74727 18.9377ZM10 10.25C10.4142 10.25 10.75 10.5858 10.75 11V17C10.75 17.4142 10.4142 17.75 10 17.75C9.58579 17.75 9.25 17.4142 9.25 17V11C9.25 10.5858 9.58579 10.25 10 10.25ZM14.7498 11C14.7498 10.5858 14.414 10.25 13.9998 10.25C13.5856 10.25 13.2498 10.5858 13.2498 11V17C13.2498 17.4142 13.5856 17.75 13.9998 17.75C14.414 17.75 14.7498 17.4142 14.7498 17V11Z"
      fill="currentColor"
    />
  </svg>
);
