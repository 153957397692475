/** @jsx jsx */
/** @jsxRuntime classic */
import React, { ReactNode, useState } from 'react';
import { css, jsx } from '@emotion/react';
import {
	BnIcon,
	Button,
	Typography,
	ETypography,
	ETypographyColor,
	Icons,
	EBrandingColorsOptions,
} from '@bondsports/utils';
import { flex, flexCol, justifyCenterCss } from '../../../styles/utils';
import { BackButton } from './BackButton';
import { colors } from '@app/react/styles/theme';

const container = (customWidth?: number, isCompact = false) => css`
	display: flex;
	width: ${String(customWidth) ?? '500'}px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 3rem ${isCompact ? '0' : '5rem'};
	h4 {
		margin: 8px 0;
	}
`;

const buttonContainer = css`
	${flexCol};
	margin-top: 3rem;
	gap: 8px;
`;

const lineCss = css`
	button:first-child {
		margin-right: 8px;
	}
`;

const iconCss = (iconColor: string, height: number, width: number) => css`
	color: ${iconColor || colors.brandPrimary};
	height: ${height || 28}px;
	width: ${width || 28}px;
	display: block;
`;

interface Props {
	icon?: Icons;
	iconColor?: string;
	iconWidth?: number;
	iconHeight?: number;
	mainButton: string;
	title: string;
	description?: string;
	descriptionSecondLine?: string;
	subButton?: string;
	mainAction: () => void;
	secondButton?: string;
	secondAction?: () => void;
	subAction?: () => void;
	customIsProcessing?: boolean;
	overrideIsProcessing?: boolean; // for backwards compat, should probably be a default for customIsProcessing
	isSecondButtonProcessing?: boolean;
	isSubButtonProcessing?: boolean;
	children?: ReactNode;
	customWidth?: number;
	compact?: boolean; // Vertical button alignment and no horizontal margin on container
	handleBack?: () => void;
}
export const ModalBody = ({
	icon,
	iconColor,
	iconWidth,
	iconHeight,
	mainButton,
	title,
	description,
	descriptionSecondLine,
	subButton,
	mainAction,
	subAction,
	secondButton,
	secondAction,
	customIsProcessing,
	overrideIsProcessing,
	isSecondButtonProcessing,
	isSubButtonProcessing,
	children,
	customWidth = 500,
	compact,
	handleBack,
}: Props) => {
	const [isProcessing, setProcessing] = useState<boolean>(false);

	const handleOnClick = () => {
		if (!customIsProcessing || !isProcessing) {
			setProcessing(true);
		}
		mainAction();
	};

	return (
		<div data-aid="ModalBody" css={container(customWidth, compact)}>
			{handleBack && <BackButton handleBack={handleBack} />}
			{icon && (
				<div>
					<BnIcon icon={icon} css={iconCss(iconColor, iconWidth, iconHeight)} />
				</div>
			)}
			{title && (
				<Typography color={ETypographyColor.primary} type={ETypography.h4}>
					{title}
				</Typography>
			)}
			{description && (
				<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
					{description}
				</Typography>
			)}
			{descriptionSecondLine && (
				<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
					{descriptionSecondLine}
				</Typography>
			)}
			{children}
			<div css={buttonContainer}>
				<div css={!compact && [justifyCenterCss, secondButton && mainButton && lineCss]}>
					{secondButton && (
						<Button
							data-aid="button-ModalBody-second"
							isProcessing={isSecondButtonProcessing}
							loadingColor={EBrandingColorsOptions.tx_text_primary}
							sizer="S"
							theme="secondary"
							onClick={secondAction}
						>
							{secondButton}
						</Button>
					)}
					{mainButton && (
						<Button
							data-aid="button-ModalBody-primary"
							isProcessing={overrideIsProcessing ? customIsProcessing : isProcessing && customIsProcessing}
							sizer="S"
							theme="primary"
							onClick={handleOnClick}
						>
							{mainButton}
						</Button>
					)}
				</div>
				{subButton && (
					<Button
						data-aid="button-ModalBody-sub"
						sizer="S"
						theme="basic"
						onClick={subAction}
						isProcessing={isSubButtonProcessing}
						loadingColor={EBrandingColorsOptions.tx_text_primary}
					>
						{subButton}
					</Button>
				)}
			</div>
		</div>
	);
};
