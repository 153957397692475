/** @jsx jsx */

import { CARD_SUBTYPE_WITH_FEES } from '@app/react/components/payments/types/payment-method.const';
import { PaymentMethodOption, PaymentMethodTypeEnum, SubPaymentMethodTypeEnum } from '@bondsports/types';
import { BnIcon, ETypography, ETypographyColor, Icons, Input, Pricify, Typography } from '@bondsports/utils';
import { css, jsx } from '@emotion/react';
import { useStation } from 'app/react/hooks/useStation';
import { TChargeSteps } from 'app/react/types/payment';
import { TranslationEn } from 'assets/i18n/en';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { colors, colors_v2 } from '../../../styles/theme';
import { flexCol, flexRowCss, fullWidth, gapCss, svgCss } from '../../../styles/utils';
import { PaymentMethodBox } from '../utils';
import { PaymentMethodsContext } from './PaymentMethodsProvider';

const labelCss = css`
	font-size: 1.2rem;
	line-height: 1.5rem;
	margin-bottom: 8px;
	color: ${colors.formInputBg};
	text-align: left;
	width: 100%;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 1.4rem;
`;

const sectionCss = (topPadding: number, bottomPadding: number) => css`
	${flexCol};
	padding: ${topPadding}px 40px ${bottomPadding}px 40px;
	justify-content: center;
	align-items: center;
`;

const paymentContainerCss = ({ showCash = false, showBalance = false, columns = 2 }) => css`
	${columns != 4 && `max-width: 488px`};
	${columns == 4 && `max-width: 320px`};
	display: grid;
	grid-gap: 16px;
	grid-template-columns: repeat(${columns}, 1fr);
`;

const feeCss = css`
	${flexRowCss};
	${fullWidth};
	${gapCss(10)};
	${svgCss('auto', '20px', colors_v2.tx_error)};
	margin-top: 16px;
	margin-bottom: -8px;
`;

interface Props {
	totalAmount: number;
	amount: number;
	customerBalance?: number;
	isAmountEditable: boolean;
	method?: TChargeSteps;
	paymentMethods: PaymentMethodOption[];
	setAmount: (val?: number) => void;
	setMethod: (val: TChargeSteps) => void;
	disableBalance?: boolean;
}

export const SelectMethod = ({
	totalAmount,
	amount,
	customerBalance,
	isAmountEditable,
	method,
	setAmount,
	setMethod,
	paymentMethods,
	disableBalance = false
}: Props) => {
	const [error, setError] = useState<string>('');
	const { connectedStation } = useStation();
	const { ACHMethods } = useContext(PaymentMethodsContext);
	// Show cash only if we are connected to a station
	const showCash = Boolean(connectedStation?.currentOpenShift?.id);
	const showBalance = !isNaN(customerBalance) && customerBalance >= 0;
	const labels = TranslationEn.payments;
	const isShowACH = ACHMethods?.filter(Boolean).length > 0;

	const selectedFee = useMemo(() => {
		const foundFees = paymentMethods?.filter(pm => pm.paymentMethodType === method);
		if (foundFees?.length === 1) return foundFees[0].fee;

		return foundFees?.find(pm => pm.subPaymentMethodType && CARD_SUBTYPE_WITH_FEES.includes(pm.subPaymentMethodType))
			?.fee;
	}, [paymentMethods, method]);

	const isShowFeeWarning = selectedFee?.fixValue > 0 || selectedFee?.percentageValue > 0;

	const pmTypeList = paymentMethods?.filter(pm => {
		switch (pm.paymentMethodType) {
			case PaymentMethodTypeEnum.ACH:
				return true;
			case PaymentMethodTypeEnum.CASH:
				return showCash;
			case PaymentMethodTypeEnum.CARD:
				return !pm.subPaymentMethodType;
			case PaymentMethodTypeEnum.CHECK:
			case PaymentMethodTypeEnum.OTHER:
			case PaymentMethodTypeEnum.GIFT_CARD:
				return true;
			case PaymentMethodTypeEnum.BALANCE:
				return showBalance;
			case PaymentMethodTypeEnum.CARD_ON_TERMINAL:
			case PaymentMethodTypeEnum.MIGRATED:
			case PaymentMethodTypeEnum.LOST_DEBT:
			case PaymentMethodTypeEnum.VOID:
			default:
				return false;
		}
	});

	useEffect(() => {
		if (method === 'balance') {
			if (+amount > +customerBalance) setAmount(customerBalance);
		}
	}, [method, amount]);

	const handleAmountChange = (event: any) => {
		setError('');
		const amount = Number(event.target.value);
		setAmount(amount);
		if (+amount > +totalAmount) setError(labels.errors.paymentToLarge);
		if (method === 'balance' && +amount > +customerBalance) setError(labels.errors.notEnoughCreditBalance);
	};

	const handleAmountClear = () => {
		setError('');
		setAmount(null);
	};

	const numberOfColumns = useMemo(() => {
		// 4 items are always shown in a 2x2 layout.
		// If more are added it becomes 3x3
		return showBalance || showCash ? 3 : 2;
	}, [showBalance, showCash]);

	return (
		<div data-aid="selectMethod">
			<Fragment>
				{isAmountEditable && (
					<div css={sectionCss(24, 0)}>
						<Input
							data-aid="selectMethod-input-amount"
							value={amount}
							type="number"
							label={labels.howMuchToCharge}
							onChange={handleAmountChange}
							clear={handleAmountClear}
							leftIcon={Icons.dollar}
							placeholder={String(totalAmount)}
							decimalScale={2}
							step={0.01}
							sizer="M"
							error={error}
						/>
					</div>
				)}

				<div data-aid="SelectMethod-paymentMethods" css={sectionCss(16, 24)}>
					<label css={labelCss}>{labels.whichPaymentMethod}</label>
					<div css={paymentContainerCss({ showCash, showBalance, columns: numberOfColumns })}>
						{pmTypeList?.map(paymentMethod => (
							<PaymentMethodBox
								key={`${paymentMethod.paymentMethodType}`}
								isFirst={false}
								handleClick={setMethod}
								method={paymentMethod.paymentMethodType}
								active={method === paymentMethod.paymentMethodType}
								customerBalance={customerBalance}
								showAch={isShowACH}
								disableBalance={disableBalance ? +totalAmount > +customerBalance : false}
							/>
						))}
					</div>
					{isShowFeeWarning && (
						<div data-aid="selectMethod-button-conflict" css={feeCss}>
							<BnIcon icon={Icons.conflict} />
							<Typography type={ETypography.captionAccented} color={ETypographyColor.primary}>
								{labels.selectMethodFeeWarning(
									String(selectedFee.percentageValue),
									Pricify(selectedFee.fixValue),
									labels.methods[method]?.toLowerCase()
								)}
							</Typography>
						</div>
					)}
				</div>
			</Fragment>
		</div>
	);
};
