/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { ReactSVG } from 'react-svg';
import { cellCss, iconCell } from '../styles';
import { isEmpty } from 'lodash';

export const BooleanCell = ({ expandableKey, row, index, column, isLast }) => {
	let src = 'assets/media/icons/customers/minus.svg';
	if (row[column.id] && (!row[expandableKey] || isEmpty(row[expandableKey]))) {
		src = 'assets/media/icons/customers/check.svg';
	}
	return (
		<td
			key={column.id}
			align={column?.styling?.align ? (column?.styling?.align === 'right' ? 'right' : 'left') : 'center'}
			className="TableCell-root TableCell-body"
			onClick={() => {
				if (column.action) {
					column.action(row);
				}
			}}
			css={cellCss({ isAction: column.action, isLast })}
		>
			<ReactSVG css={iconCell} src={src} />
		</td>
	);
};
