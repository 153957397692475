/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx } from '@emotion/react';
import { IGroupsOptions } from '../types';
import { Group } from './Group';

export const GroupsOptions = ({
	groups,
	onSelect,
	autoFocus = true,
	isMultiple = false,
	selectedValuesArray,
}: IGroupsOptions) => {
	return (
		<Fragment>
			{groups.map((category, index) => {
				return (
					<Group
						key={index}
						onSelect={onSelect}
						autoFocus={autoFocus}
						isMultiple={isMultiple}
						label={category.label}
						options={category.options}
						selectedValuesArray={selectedValuesArray}
					/>
				);
			})}
		</Fragment>
	);
};
