import { Dispatch, useMemo, useReducer } from 'react';
import {
	DiscountModalHookReturn,
	EDiscountStateActionsType,
	IPromoCodeState,
	TPromoCodeStateActions,
	DiscountModalTabEnum,
} from '../types';
import { EDiscountUnit } from '../../../molecules/discount-amount-input';
import { roundPriceCents } from '../../../lib/price';
import { useMultiTabForm } from '../../../hooks/useMultiTabForm';
import { discountModalReducer, promoCodeReducer } from '../store';
import { i18n } from '../../../lib/i18n';
import { StateEnum } from '../types/promoCodes';

export const useDiscountModal = (
	withTabs: boolean,
	totalAmount: number,
	isAppliedPromoCode?: boolean,
	invoiceSubtotalBalance?: number
): DiscountModalHookReturn => {
	const [customState, dispatch] = useReducer(discountModalReducer, {});
	const [promoCodeState, promoCodeDispatch]: [IPromoCodeState, Dispatch<TPromoCodeStateActions>] = useReducer(
		promoCodeReducer,
		{ initialComponentState: isAppliedPromoCode ? StateEnum.APPLIED : StateEnum.LOADING }
	);
	const switchTotalAmount = invoiceSubtotalBalance && invoiceSubtotalBalance < totalAmount;
	const totalAfterValidation = switchTotalAmount ? invoiceSubtotalBalance : totalAmount;
	const remainingAmount = roundPriceCents(totalAfterValidation - (switchTotalAmount ? 0 : invoiceSubtotalBalance ?? 0));
	const remainingPercentage = roundPriceCents(totalAmount === 0 ? 0 : (remainingAmount / totalAmount) * 100);

	const tabsIds = [DiscountModalTabEnum.PROMO_CODE, DiscountModalTabEnum.CUSTOM];
	const { currentTab, handleTabChange, resetCurrentTab } = useMultiTabForm(withTabs ? tabsIds[0] : undefined);

	const labels = i18n.addDiscount.header.tabs;
	const tabs = tabsIds.map(id => {
		return {
			title: labels[id],
			link: id,
		};
	});

	const handleOnChangeCustom = ({
		amount,
		type,
		reason,
		publicNote,
		privateNote,
	}: {
		amount: number;
		type: EDiscountUnit;
		reason: number;
		publicNote: string;
		privateNote: string;
	}) => {
		let newState = { amount, type, reason, publicNote, privateNote };
		Object.keys(newState).forEach(key => {
			newState[key] == null && delete newState[key];
		});
		// When we chage the type we need to reset the amount
		if (type) {
			newState = { ...newState, amount };
		}

		dispatch({ type: EDiscountStateActionsType.SET_STATE, payload: { ...customState, ...newState } });
	};

	const handleOnChangePromoCode = (newState: IPromoCodeState) => {
		promoCodeDispatch({ type: EDiscountStateActionsType.SET_STATE, payload: { ...promoCodeState, ...newState } });
	};

	const isValidCustom = useMemo(() => {
		const { amount, type, reason } = customState;
		if (!amount || !type || !reason) return false;
		switch (type) {
			case EDiscountUnit.PERCENT:
				return amount <= remainingPercentage;
			case EDiscountUnit.AMOUNT:
				return amount <= remainingAmount;
			default:
				return false;
		}
	}, [customState, remainingPercentage, remainingAmount]);

	const isValidPromoCode = useMemo(() => {
		return Boolean(promoCodeState.codeDiscountId);
	}, [promoCodeState]);

	const isValid = useMemo(() => {
		switch (currentTab) {
			case DiscountModalTabEnum.CUSTOM:
				return isValidCustom;
			case DiscountModalTabEnum.PROMO_CODE:
				return isValidPromoCode;
			default:
				return isValidCustom;
		}
	}, [isValidPromoCode, isValidCustom, currentTab]);

	const resetStates = () => {
		dispatch({ type: EDiscountStateActionsType.RESET });
		promoCodeDispatch({ type: EDiscountStateActionsType.RESET });
	};

	return {
		handleOnChangeCustom,
		handleOnChangePromoCode,
		isValid,
		resetStates,
		currentTab,
		customState,
		promoCodeState,
		resetCurrentTab,
		remainingAmount,
		remainingPercentage,
		tabs,
		handleTabChange,
	};
};
