/** @jsx jsx */

import React from 'react';
import { Link } from 'react-router-dom';
import { jsx, css } from '@emotion/react';
import { BnIcon, ETypography, ETypographyColor, Icons, Typography } from '@bondsports/utils';
import { getCustomerPagePath, getFamilyPagePath } from '../../../../lib/paths';
import { flex, svgIcon } from '../../../../styles/utils';
import { colors_v2 } from '../../../../styles/theme';
import { ILinkedAccount } from '../../../../types/customers';
import { TranslationEn } from '@assets/i18n/en';
import { CustomerFamilyStatusEnum } from '@bondsports/types';

const primaryIconCss = css`
	color: ${colors_v2.bg_system};
	${svgIcon(20)}
`;

const linkWrapperCss = (isFamily: boolean) => css`
	${flex}
	gap: 8px;
	margin: ${isFamily ? 20 : 25}px 0;
`;

interface IProps {
	details: ILinkedAccount;
	typography?: ETypography;
}

const ProfileIcon = ({ isPrimary }: { isPrimary: boolean }) => {
	return (
		<BnIcon
			icon={isPrimary ? Icons.profile_filled : Icons.profile_outline}
			css={isPrimary ? primaryIconCss : svgIcon(20)}
		/>
	);
};

export const AccountLink = ({ details, typography = ETypography.captionLink }: IProps) => {
	const { customerId, customerName, isFamily, familyStatus } = details;
	const isPrimary = familyStatus === CustomerFamilyStatusEnum.PRIMARY;
	const path = isFamily ? getFamilyPagePath({ familyId: customerId }) : getCustomerPagePath(customerId);
	const color = isFamily ? ETypographyColor.primary : ETypographyColor.secondary;
	const familyAcount = `${customerName} ${isFamily ? TranslationEn.customers.account : ''}`;
	return (
		<div css={linkWrapperCss(isFamily)}>
			{!isFamily && <ProfileIcon isPrimary={isPrimary} />}
			<Link to={path}>
				<Typography type={typography} color={color}>
					{familyAcount}
				</Typography>
			</Link>
		</div>
	);
};
