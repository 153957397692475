/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useEffect } from 'react';

export const SearchInput = ({
	handleOpenPopup,
	onType,
	...rest
}: React.InputHTMLAttributes<HTMLInputElement> & {
	handleOpenPopup: (val: boolean) => void;
	onType: (val: string) => void;
}) => {
	return (
		<input
			data-aid="Select_v2-searchInput"
			{...rest}
			onChange={e => {
				onType(e.target.value);
				handleOpenPopup(true);
			}}
		/>
	);
};
