import { colors } from '@app/react/styles/theme';
import { flexCol, flex } from '@app/react/styles/utils';
import { css } from '@emotion/react';

export const ContainerCss = (checkContainer = false) => css`
	${flexCol};
	padding: ${checkContainer ? '1.5rem 3.5rem' : '2rem 10rem'};
	justify-content: center;
	align-items: center;
`;

export const wrapContainerCss = (checkContainer = false) => css`
	${flex};
	${checkContainer ? 'width: 100%;' : 'max-width: 300px;'};
	flex-wrap: wrap;
	justify-content: flex-start;
`;

export const topPartCss = css`
	${flexCol};
	margin: 0;
	width: 100%;
`;

export const middleCss = css`
	color: ${colors.formInputBg};
	font-size: 1.2rem;
	line-height: 1.5rem;
	text-align: center;
	margin-top: 1rem;
`;

export const cashContainerCss = css`
	${flex};
	flex-wrap: wrap;
	justify-content: center;
`;

export const changeCss = css`
	margin-top: 16px;
	text-align: center;
`;

export const fullWidthCss = css`
	width: 100%;
`;
