/** @jsxRuntime classic */
/** @jsx jsx */
import React, { FC, PropsWithChildren } from 'react';
import { jsx, css } from '@emotion/react';

export interface IModalContentProps {
	children: React.ReactNode;
}

export const Content: FC<PropsWithChildren<IModalContentProps>> = ({children}) => {

	return (
		<div className="modal-layout-content" data-aid="index-modal-content">
			{children}
		</div>
	);
};
