import { css } from '@emotion/react';
import { IColors } from '../../types';
import { ECalendarMode } from '../types';
import { oneHourHeight, oneHourWidth } from '../utils/timeUtils';

export const horizontalHourHeight = 38;
export const verticalSpaceHeight = 88;
export const spaceHeight = 110;
export const spaceWidth = 154;
export const weekdayHeight = 69;
export const timeBoxWidth = 52;

export const collisionGroupContainer = (top = 0, height = 0) => css`
	display: inline-flex;
	align-items: flex-start;
	width: 100%;
	position: absolute;
	top: ${top}px;
	height: ${height}px;
`;

export const collisionGroupContainerHorizontal = (left = 0, width = 0) => css`
	display: inline-flex;
	align-items: flex-start;
	height: 100%;
	position: absolute;
	left: ${left}px;
	width: ${width}px;
`;

export const draggableContainer = (top: number = 0, collisionCount: number = 1, shift: number = 0) => {
	let width = 100 / collisionCount;
	if (collisionCount >= 4) {
		width = 100 / (collisionCount + 1);
	}

	// if (width < 25) width = 25;

	let left = width * shift;
	if (left > 75) left = 75;

	return css`
		width: ${width}%;
		left: ${left}%;
		top: ${top}px;
		z-index: 20;
		position: absolute;
		box-sizing: border-box;
		overflow: hidden;

		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&:hover {
			svg {
				opacity: 1;
			}
		}
	`;
};

export const draggableContainerHorizontal = (left: number = 0, collisionCount: number = 1, shift: number = 0) => {
	let height = 100 / collisionCount;
	if (height < 25) height = 25;

	let top = height * shift;
	if (top > 75) top = 75;

	return css`
		height: ${height}%;
		top: ${top}%;
		left: ${left}px;
		position: absolute;
		box-sizing: border-box;
		overflow: hidden;

		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	`;
};

export const draggableContent = (height: number = 1) => css`
	height: ${height - 1}px;
	display: grid;
	box-sizing: border-box;
`;

export const draggableContentHorizontal = (width: number = 1) => css`
	width: ${width - 1}px;
	box-sizing: border-box;
	height: 100%;
	&:hover {
		svg {
			opacity: 1;
		}
	}
`;

export const draggableHandler = css`
	position: absolute;
	top: 4px;
	right: 0px;
	width: 20px;
	cursor: grab;
	overflow: hidden;

	svg {
		opacity: 0;
		transition: opacity 200ms;
	}
`;

// TODO: change to new resize icon
export const draggableResizeHandler = (cursor: string = 'ns-resize') => css`
	position: absolute;
	cursor: ${cursor} !important;
	display: inline-block;
	bottom: 0;
	right: 0;
	margin: 0;
	height: 14px;

	svg {
		height: 14px;
		width: 14px;
		opacity: 0;
		transition: opacity 200ms;
	}
`;

export const hourBoxContainer = (colors: IColors) => css`
	display: flex;
	position: relative;
	width: ${timeBoxWidth}px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	/* margin-top: 0.5px; */
	margin-top: 1px;

	.span {
		position: absolute;
		width: 100%;
		height: 1px;
		top: calc(50% + 6px);
		right: 0;
		transform: translate(0, -50%);
		display: block;
		background: ${colors.bg_border_seperator};
	}
	span {
		position: absolute;
		width: 30%;
		height: 1px;
		top: 6px;
		right: 0;
		transform: translate(0, -50%);
		display: block;
		background: ${colors.bg_border_seperator};
	}
	div:last-of-type {
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		line-height: 12px;
		text-align: center;
		color: ${colors.tx_text_primary};
		padding-bottom: ${oneHourHeight - 13}px;
	}
`;

export const hourBoxLine = (width: number = 1000, marginTop: number = 0) => css`
	background: #0e4874;
	height: 1px;
	width: ${width}px;
	position: absolute;
	margin: 0;
	padding: 0;
	margin-top: ${marginTop}px;
	z-index: 1;

	&::after {
		display: block;
		content: '';
		width: 5px;
		height: 5px;
		border-radius: 100px;
		left: 0;
		top: 0;
		transform: translate(-50%, -40%);
		background: #0e4874;
	}
`;

export const hourBoxLineHorizontal = (width = 1000, marginLeft = 0) => css`
	background: #0e4874;
	width: 1px;
	height: ${width}px;
	position: absolute;
	margin: 0;
	padding: 0;
	margin-left: ${marginLeft}px;
	z-index: 1;
	top: 26px;

	&::after {
		display: block;
		content: '';
		width: 5px;
		height: 5px;
		border-radius: 100px;
		left: 0;
		top: 0;
		transform: translate(-40%, -40%);
		background: #0e4874;
	}
`;
export const hourBoxLineHorizontal2 = (marginLeft = 0) => css`
	background: #0e4874;
	width: 1px;
	height: calc(100% + 8px);
	position: absolute;
	margin: 0;
	padding: 0;
	left: ${marginLeft}px;
	z-index: 20;
	top: -2px;
`;

export const hourBoxLine2 = (width = 0, marginTop = 0) => css`
	background: #0e4874;
	height: 1px;
	width: max(100%, ${width}px);
	position: absolute;
	margin: 0;
	padding: 0;
	top: ${marginTop + 1}px;
	z-index: 20;
	left: 0;
`;

export const hourBoxContainerHorizontal = (colors: IColors) => css`
	position: relative;
	box-sizing: border-box;
	height: ${horizontalHourHeight}px;
	min-width: ${oneHourWidth}px;

	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	color: ${colors.tx_text_primary};

	.time {
		position: absolute;
		width: fit-content;
		left: -15px;
	}

	&:first-of-type {
		.time {
			left: 0;
		}
	}

	.small,
	.big {
		position: absolute;
		bottom: 0;
		left: 0;
		display: inline-block;
		width: 1px;
		height: 12px;
		background: ${colors.bg_border_seperator};
	}
	.big {
		left: 50%;
		height: 100%;
	}
`;

export const horizontalDayToDropContainer = (daysCount = 1, colors: IColors) => css`
	border-bottom: 1px solid ${colors.bg_border_seperator};
	height: 100%;
	min-height: ${spaceHeight}px;
	&:last-of-type {
		min-height: ${spaceHeight + 1}px;
	}
	&:first-of-type {
		min-height: ${spaceHeight}px;
	}
	box-sizing: border-box;
	min-width: ${oneHourWidth * 24 * daysCount}px;
	position: relative;
`;

export const weekBoxContainer = (colors: IColors, width: number, horizontal?: boolean) => css`
	position: relative;
	width: ${horizontal ? '100%' : `${timeBoxWidth}px`};
	box-sizing: border-box;

	display: flex;
	flex-direction: ${horizontal ? 'row' : 'column'};
	/* min-height: 1000px; */
	flex: 1 1 1px;

	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14.63px;
	text-align: center;

	&:last-of-type {
		&::after {
			height: 1px;
			width: ${width}px;
			position: absolute;
			content: '';
			bottom: -1px;
			left: 0;
			background-color: ${colors.bg_border_seperator};
			z-index: 1;
		}
	}

	.weekday {
		position: relative;
		display: flex;
		flex-direction: ${horizontal ? 'row' : 'column'};
		align-items: ${horizontal ? 'center' : 'flex-start'};
		justify-content: space-between;

		${horizontal
			? `border-right: 1px solid ${colors.bg_border_seperator};`
			: `border-top: 1px solid ${colors.bg_border_seperator};`}

		flex: 1 1 ${weekdayHeight}px;
		padding: ${horizontal ? '0 12px 0 10px' : '1.5vh 0'};
		height: ${horizontal ? '40px' : `100%`};
		${!horizontal && `min-height: max(14.2857%, ${weekdayHeight}px);`}
		box-sizing: border-box;

		${horizontal && `min-width: max(100% / 7, ${spaceWidth - 1}px);`}

		&::after {
			position: absolute;
			content: '';
			background-color: ${colors.bg_border_seperator};
			${horizontal
				? `
            width: 1px;
            top: 100%;
            right: -1px;  
        `
				: `
            height: 1px;
            width: ${width}px;
            top: -1px;
            left: 0;
            z-index: 1;
        `}
		}

		.name {
			width: 30px;
			text-transform: uppercase;
			color: ${colors.bg_text_secondary};
		}
		.date {
			color: ${colors.bg_text_primary};
			display: flex;
			align-items: center;
			justify-content: center;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			/* margin-top: ${horizontal ? '0' : '10'}px; */
			&.today {
				color: white;
				background: linear-gradient(270deg, ${colors.bg_system_gradient} 0%, ${colors.bg_system_gradient} 100%);
			}
		}
	}
`;

export const weekDayToDropContainer = (colors: IColors, withoutChildren?: boolean) => css`
	box-sizing: border-box;
	flex-grow: 1;
	position: relative;
	border-right: 1px solid ${colors.bg_border_seperator};
	min-width: ${spaceWidth}px;

	&:last-of-type {
		width: ${spaceWidth + 1}px;
	}

	& {
		width: ${spaceWidth}px;
	}

	height: calc(100% - 2px);
	.WeeklyDroppable {
		position: relative;
		&::after {
			content: '';
			display: block;
			height: 1px;
			background-color: ${colors.bg_border_seperator};
		}
	}
`;

export const weekDayToDropHorizontalContainer = (colors: IColors) => css`
	display: flex;
	flex-grow: 1;
	min-width: ${spaceWidth - 1}px;
	position: relative;
	height: 100%;
	min-height: ${spaceHeight}px;
	&:last-of-type {
		min-height: ${spaceHeight + 1}px;
	}
	&:first-of-type {
		min-height: ${spaceHeight}px;
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		height: 1px;
		min-width: 100%;
		width: ${(spaceWidth - 1) * 7}px;
		background-color: ${colors.bg_border_seperator};
	}
`;

export const monthlyViewContainer = css`
	display: flex;
	flex-wrap: wrap;
	min-width: max(100%, ${spaceWidth * 7}px);
	position: relative;
	margin-bottom: 6px;
`;

export const weekDayContainer = (colors: IColors, horizontal?: boolean, height?: string | number) => css`
	height: ${height || '100%'};
	position: relative;
	padding-top: 1px;
	box-sizing: border-box;
	flex-grow: 1;
	flex-basis: 100%;
	${horizontal && `border-right: 1px solid ${colors.bg_border_seperator};`}
`;

export const weekDayEventContainer = css`
	margin: 2px 0;
	height: 16px;
	overflow: hidden;
	max-width: 100%;

	&:first-of-type {
		margin-top: 1px;
	}
`;

export const moreItemsButton = (colors: IColors, isWeekly?: boolean) => css`
	position: absolute;
	right: 0;
	width: 25%;
	z-index: 20;
	cursor: pointer;
	height: 100%;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid ${colors.bg_border_seperator};
	border-radius: 2px;

	${isWeekly &&
	`
    position: static;
    height: 18px;
    width: 100%;
    flex-direction: row;
    div {
      padding: 0 2px;
    }
  `}

	font-family: Montserrat;
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	text-align: center;
	color: ${colors.tx_text_primary};
`;

export const moreItemsButtonHorizontal = (colors: IColors, isWeekly?: boolean) => css`
	${moreItemsButton(colors, isWeekly)}
	width: 100%;
	height: 25%;
	bottom: 0;
`;

export const moreModal = (colors: IColors, position = { y: 'top: 0;', x: 'left: 50%;' }) => css`
	width: 50%;
	min-width: 232px;
	position: absolute;
	${position.y}
	${position.x}
  padding: 14px;
	background-color: white;
	border: 1px solid ${colors.bg_border_seperator};
	box-shadow: 0px 2px 22px rgba(21, 93, 148, 0.0749563);
	z-index: 100;

	.more-modal-header {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 8px;
		div {
			svg {
				position: static;
				width: 20px;
				transform: none;
			}
			&:hover {
				cursor: pointer;
			}
		}
	}

	.events-list {
		max-height: 300px;
		overflow-y: auto;
		overflow-x: hidden;
	}
`;

export const monthlyDatesHeaderContainer = (colors: IColors) => css`
	display: flex;
	background-color: ${colors.bg_background_primary};
	color: ${colors.bg_text_secondary};
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	text-transform: uppercase;
	position: absolute;
	bottom: 0;
	min-width: 100%;

	div {
		padding: 5px 0;
		flex: 1 1 1px;
		min-width: max(100% / 7, ${spaceWidth}px);
		box-sizing: border-box;
		border-left: 1px solid transparent;
		border-right: 1px solid ${colors.bg_border_seperator};
		border-bottom: 1px solid ${colors.bg_border_seperator};
		&:last-of-type {
			border-right: 1px solid transparent;
		}
	}
`;

export const monthlyDateContainer = (
	colors: IColors,
	isCurrentMonth?: boolean,
	isToday?: boolean,
	isSeventh?: boolean
) => css`
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	border: 1px solid ${colors.bg_border_seperator};
	border-top-color: transparent;
	border-left-color: transparent;
	${isSeventh && 'border-right-color: transparent;'}

	.date {
		font-family: Montserrat;
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 9px;
		color: ${isCurrentMonth ? colors.bg_text_primary : colors.bg_text_teriaty};

		margin: 0 auto;
		width: 16px;
		height: 16px;
		border-radius: 50%;

		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		${isToday &&
		` color: white; 
      background: linear-gradient( 270deg, ${colors.bg_system_gradient} 0%, ${colors.bg_system_gradient} 100% ); 
    `}
	}
`;

export const asideContainer = (mode = ECalendarMode.DAILY, horizontal = false) => {
	const isVerticalView = mode === ECalendarMode.DAILY && !horizontal;

	return css`
		overflow: hidden;
		margin-bottom: ${isVerticalView ? 12 : 0}px;
	`;
};

export const loadingContainerCss = css`
	.skeleton {
		margin-bottom: 2px;
	}
`;
