import React, { useEffect, useState } from "react";
import { Input } from "../Input";
import { SelectOptions } from "../Options";
import { Popup } from "../Popup";

export interface IOption {
  value: number;
  label: string;
}

export interface IAutoComplete {
  checked?: IOption[];
  value?: string;
  typed?: (val: string) => void;
  clear?: () => void;
  list: IOption[];
  onSelect?: (value: IOption[]) => void;
  placeholder?: string;
  label?: string;
  isMultiple?: boolean;
}

export function AutoComplete({
  checked=[],
  typed,
  list,
  onSelect,
  clear,
  placeholder = "",
  label = "",
  value="",
  isMultiple=false,
}: IAutoComplete) {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [searchOptions, setSearchOptions] = useState<IOption[]>([...list]);

  const handleClear = () => {
    if (clear) {
      clear();
      setSearchOptions([...list]);
      if (onSelect) {
        onSelect([]);
      }
    }
  };

  const updateCheckedElements = (searchedStrings: string[]) => {
    const updatedChecked = [...checked];
    const deletedElement = updatedChecked.filter(checkedLabel => !searchedStrings.includes(checkedLabel.label));
    const deletedCheckedElement = updatedChecked.findIndex(checkedElement => checkedElement.label === deletedElement[0].label);
    updatedChecked.splice(deletedCheckedElement, 1);
    if (onSelect)
      onSelect(updatedChecked);
  }

  const handleChange = (v: string) => {
    // If user deleted all values, clear is fired
    if (v) {
      if (typed) {
        if (isMultiple) {
          // Updating displayed value in the text box
          typed(v);
          // Getting all values searched, splitting them by , and getting all available results that include what user typed
          const allSearchResults = v.split(",")
            .map(serchedString => [...list].filter(item => item.label.includes(serchedString)));
          const combinedSearchedResults: IOption[] = [];
          // Mapping all the results
          for (const result of allSearchResults) {
            for (const singleResult of result) {
              // If the result is not marked pushing it to combined
              if (combinedSearchedResults.findIndex(option => option.value === singleResult.value) === -1) {
                combinedSearchedResults.push(singleResult);
              }
            }
          }
          if (v.split(",").length < checked.length) {
            // Updating the checked elements in the list
            updateCheckedElements(v.split(","));
          }
          setSearchOptions(combinedSearchedResults);
        } else {
          typed(v);
          !isOpen &&
            setOpen(true);
          if (v !== "") {
            const updatedSearchOptions = [...list];
            setSearchOptions(updatedSearchOptions.filter((item) => item.label.includes(v)));
          }
        }
      }
    } else {
      handleClear();
    }
  }

  const handleSelect = (val: number) => {
    if (onSelect && searchOptions) {
      const elem = searchOptions.find((item) => item.value === val);
      if (elem) {
        const checkedIndex = checked.findIndex((checkedElement) => checkedElement.value === elem.value);
        if (checkedIndex !== -1)  {
          if (isMultiple) {
            const updatedCheckedItems = [...checked];
            updatedCheckedItems.splice(checkedIndex, 1);
            onSelect([...updatedCheckedItems]);
          } else {
            onSelect([]);
          }
        } else {
          isMultiple ? onSelect([...checked, elem]) : onSelect([elem]);
        }
      }
    }
  };

  return (
    <div data-aid="autoComplete">
      <Popup
        placement="bottom-end"
        fullWidth
        disableAutoTrigger
        trigger={isOpen}
        onOpenChange={setOpen}
        body={
          <SelectOptions
            options={searchOptions}
            value={checked.map(item => item.value)}
            onSelect={handleSelect}
            autoFocus={false}
            isMultiple={isMultiple}
          />
        }
      >
        <Input
          label={label}
          placeholder={placeholder}
          onFocus={(e) => {
            setOpen(true);
          }}
          sizer="S"
          clear={handleClear}
          value={value}
          hideError
          onChange={(e) => handleChange(e.target.value)}
        />
      </Popup>
    </div>
  );
};
