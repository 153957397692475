import { environment } from '../../../../environments/environment';

const GENERAL_UPLOAD_URL = `${environment.CLOUDINARY_API_ROOT}?upload_preset=${environment.CLOUDINARY_UPLOAD_PRESET}`;

const uploadFileItemImage = async (file, filePublicId?: string) => {
	let url;
	if (filePublicId) {
		const formattedFileName = filePublicId.replace(/[\\~#%&*{ }/:<>?!@#$%^&*()+=|"'`]/g, '-');
		url = GENERAL_UPLOAD_URL + `&public_id=${formattedFileName}`;
	} else {
		url = GENERAL_UPLOAD_URL;
	}
	const formData = new FormData();

	formData.append('file', file);
	formData.append('upload_preset', 'avatars');
	const response = await fetch(url, {
		method: 'POST',
		body: formData,
	});
	const data = await response.text();

	const uploadFileResponse = JSON.parse(data);

	if (uploadFileResponse) {
		return uploadFileResponse;
	}
	return null;
};

export const imageApi = {
	uploadFileItemImage,
};
