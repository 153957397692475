/** @jsxRuntime classic */
/** @jsx jsx */
import { useMemo } from 'react';
import { jsx } from '@emotion/react';
import { PaymentDetailAndIcon } from './PaymentDetailAndIcon';

import { getXLastChars } from '../../../lib/utils';
import { PaymentMethodTypeEnum } from '../../../types/entities/payment';
import { i18n } from '../../../lib/i18n';
import { EPaymentIcons } from '../../../atoms/PaymentMethod';

interface IPaymentMethod {
	paymentType: PaymentMethodTypeEnum;
	ccBrand?: string;
	ccLast4?: string;
	paymentMethodId?: string;
}

export const PaymentMethodMapper = ({ state, noIcons = false }: { state: IPaymentMethod; noIcons?: boolean }) => {
	const paymentType = state?.paymentType ?? (state as any).method;
	const labels = i18n.PaymentMethodTypeEnum;
	const GIFT_CARD_LAST_CHARS = 5;
	const CC_BRAND_SIZE = 35;

	const { icon, text, iconSize } = useMemo(() => {
		const icon = [PaymentMethodTypeEnum.CARD_ON_TERMINAL, PaymentMethodTypeEnum.CARD].includes(paymentType)
			? state.ccBrand
			: !noIcons
			? EPaymentIcons[paymentType]
			: '';
		let text = paymentType as string;
		let iconSize = 24;
		let methodId = '';
		switch (paymentType.replace(' ', '-').toLowerCase()) {
			case PaymentMethodTypeEnum.CARD:
			case PaymentMethodTypeEnum.CARD_ON_TERMINAL:
				text = `${noIcons ? `${state.ccBrand} ` : ''}${state.ccLast4}`;
				iconSize = CC_BRAND_SIZE;
				break;
			case PaymentMethodTypeEnum.CHECK:
				methodId = state.paymentMethodId
					? `#${state.paymentMethodId.substring(state.paymentMethodId.length - 4, state.paymentMethodId.length)}`
					: '';
				text = `${labels[PaymentMethodTypeEnum.CHECK]} ${methodId}`;
				break;
			case PaymentMethodTypeEnum.GIFT_CARD:
				methodId = state.paymentMethodId
					? `#${getXLastChars(String(state.paymentMethodId), GIFT_CARD_LAST_CHARS)}`
					: '';
				text = `${labels[PaymentMethodTypeEnum.GIFT_CARD]} ${methodId}`;
				break;
			case PaymentMethodTypeEnum.ACH:
				text = `${labels[PaymentMethodTypeEnum.ACH]} ${state.ccLast4}`;
				break;
		}
		return { icon, text, iconSize };
	}, [state]);

	return <PaymentDetailAndIcon text={text} icon={!noIcons && icon} iconSize={iconSize} />;
};
export { PaymentMethodTypeEnum };
