import { auth, Prop } from '@app/react/lib/auth';
import { useMemo } from 'react';
import jwt_decode from 'jwt-decode';

export const useJWTPayload = (): Prop => {
	const { getToken } = auth;

	const token = getToken();

	return useMemo(() => {
		if (token) {
			return jwt_decode(token) as Prop;
		}

		return {} as Prop;
	}, [token]);
};
