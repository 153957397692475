/** @jsx jsx */

import React, { Fragment } from 'react';
import { jsx } from '@emotion/react';
import { BnIcon, Icons, PopupHeader } from '@bondsports/utils';
import { colors_v2 } from '../../../styles/theme';
import { flexRowCss, justifyContentCss, marginTopCss } from '../../../styles/utils';

export const ArchiveNoteHeader: React.FC<{ title: string }> = ({ title }) => {
	return (
		<Fragment>
			<div css={[flexRowCss, justifyContentCss('center'), marginTopCss(32)]}>
				<BnIcon icon={Icons.conflict} color={colors_v2.tx_error} />
			</div>
			<PopupHeader title={title} marginTop={8} />
		</Fragment>
	);
};
