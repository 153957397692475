/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from '@emotion/react';
import { ThemeColors, oldThemeColors as colors } from '../../../styles/theme';
import { printFont } from '../../../styles/utils';

export const containerCss = (payment?: boolean) => css`
	width: 100%;
	height: 100%;

	.more {
		width: 40px;
	}

	.no-print,
	.no-print > * {
		display: flex;
	}

	.print,
	.print > * {
		display: none;
	}

	@media print {
		body {
			margin: 0;
			padding: 0;
		}

		table {
			break-inside: auto;
		}

		tr {
			break-inside: avoid;
			break-before: auto;
		}

		thead {
			display: table-row-group;
		}

		tfoot {
			display: table-row-group;
		}
	}
	@media print and (max-width: 10cm) {
		${printFont}
		.body-section {
			padding: 0 !important;
		}

		.no-print,
		.no-print > * {
			display: none;
		}
		.print,
		.print > * {
			display: flex;
		}
	}
`;

export const inlineIconCss = css`
	color: ${ThemeColors.tx_text_primary};
	svg {
		height: 16px;
		width: auto;
	}
`;

export const textColCss = (inlineStartPadding = '80px') => css`
	padding-inline-start: ${inlineStartPadding};
	align-items: center;
	gap: 4px;
`;

export const sublineCss = css`
	svg {
		height: 14px;
		width: auto;
		color: ${ThemeColors.tx_text_teriaty};
		transition: color 0.2s, filter 0.2s;

		&:hover {
			color: ${ThemeColors.tx_text_secondary};
			filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.2));
		}
	}
`;

export const tooltipWithButtonCss = css`
	position: relative;
	display: flex;
	align-items: center;
	div {
		display: flex;
	}
	svg {
		margin-left: 8px;
		width: 16px;
		height: 16px;
		color: ${colors.formInputBg};
	}
`;

export const hrCss = css`
	border: 1px dashed ${ThemeColors.black};
	margin: 0;
	width: 100%;
`;
