/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useContext } from 'react';
import { Typography } from '../../../atoms';
import { ETypography, ETypographyColor, IColors } from '../../../types';
import { PaymentContext } from '../context';
import { useColors } from '../../../hooks/useColors';
import { SkeletonWrapper } from '../../../components/skeletonWrapper';
import { printFont, printOnly } from '../../../styles/utils';
import { useWindowSize } from '../../../hooks/useWindowSize';

const headerContainerCss = (colors: IColors) => css`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: 24px;
	padding: 13px 23px 0 23px;
	${printOnly} {
		padding: 0;
	}

	@media print and (max-width: 10cm) {
		${printFont}
	}
`;

const centerCss = css`
	display: flex;
	width: 100%;
	gap: 16px;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const leftSide = css`
	display: flex;
	gap: 16px;
	align-items: center;
	width: 100%;
	@media print and (max-width: 10cm) {
		justify-content: center;
	}
	@media print and (min-width: 11cm) {
		justify-content: flex-start;
	}
`;

export const Header = () => {
	const { isMobile } = useWindowSize();
	const { colors } = useColors();
	const { state, labels, isLoading } = useContext(PaymentContext);

	const color = isMobile ? ETypographyColor.black : ETypographyColor.primary;

	return (
		<div css={headerContainerCss(colors)} data-aid="index-header">
			<div css={centerCss}>
				<SkeletonWrapper isLoading={isLoading}>
					<Typography type={ETypography.body1Accented} color={color}>
						{state?.organizationName}
					</Typography>
				</SkeletonWrapper>
				<SkeletonWrapper isLoading={isLoading}>
					<Typography type={ETypography.body2Link} color={color}>
						{state?.organizationEmail}
					</Typography>
				</SkeletonWrapper>
			</div>
			<div css={leftSide}>
				<SkeletonWrapper isLoading={isLoading}>
					<Typography type={ETypography.body2Accented} color={color}>
						{labels.header.title} {state?.id}
					</Typography>
				</SkeletonWrapper>
			</div>
		</div>
	);
};
