/** @jsx jsx */

import { useCustomers } from 'app/react/components/customers/hooks/useCustomers';
import { ModalBody } from 'app/react/components/shared/ModalBody';
import { useErrorModalDepricated } from '@app/react/components/shared/ErrorDepricated';
import { VoidMethodBox } from 'app/react/components/customers/singleInvoice/voidModal/VoidMethodBox';
import { TranslationEn } from '@assets/i18n/en';
import { BnIcon, ETypography, ETypographyColor, Icons, Typography, Pricify } from '@bondsports/utils';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { useModal } from '@bondsports/utils';
import { Modal } from 'app/react/components/shared/Modal';
import { flex, flexCol } from 'app/react/styles/utils';
import { Footer } from 'app/react/components/payments/Footer';
import { colors, colors_v2 } from 'app/react/styles/theme';
import { ILineItem, VoidActionType } from 'app/react/types/orders';

const containerCss = css`
	${flexCol};
	justify-content: space-between;
	width: 560px;
	height: 345px;
	padding-bottom: -1rem;
`;

const noVoidContainerCss = css`
	${flexCol};
	justify-content: space-between;
	width: 560px;
`;

const headerCss = css`
	${flexCol};
	margin-top: 24px;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const modalBodyCss = css`
	${flexCol};
	border-top: 1px solid ${colors.borderSeperator};
	justify-content: end;
	align-items: center;
	width: 100%;
	min-height: 160px;
`;

const methodRowCss = css`
	${flex};
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 8px;
`;

const rowCss = css`
	${flex};
	justify-content: center;
	align-items: center;
	margin-top: 16px;
`;

const methodSubtitleCss = css`
	${flex};
	color: ${colors_v2.tx_error};
	align-items: center;
	justify-content: center;
	max-width: 400px;

	svg {
		margin-right: 4px;
	}
`;

interface VoidModalProps {
	organizationId: number;
	invoiceId: number;
	totalDue: number;
	partialPayment: boolean;
	noVoid: boolean;
	isVoidedLineItemExist?: boolean;
	toggle: () => void;
	onFinish: (voidType: VoidActionType) => void;
	onRefund: () => void;
	lineItems: ILineItem[];
}

export const useVoidInvoiceModal = () => {
	const { toggle: VoidInvoiceToggle, isShowing: isVoidInvoiceShowing } = useModal();

	const VoidInvoiceModal = useCallback(
		({
			organizationId,
			invoiceId,
			totalDue,
			partialPayment = false,
			noVoid,
			toggle,
			onFinish,
			onRefund,
			isVoidedLineItemExist,
			lineItems,
		}: VoidModalProps) => {
			const { ErrorModalDepricated, ErrorToggle } = useErrorModalDepricated();
			const { postVoidItems } = useCustomers();

			const [voidType, setVoidType] = useState<VoidActionType | undefined>(undefined);
			const [loading, setLoading] = useState<boolean>(false);
			const [warningMsg, setWarningMsg] = useState<string>('');
			const [errorMsg, setErrorMsg] = useState<string>('');

			const voidInvoiceLabels = TranslationEn.customers.paymentsInvoices.voidModal;

			const resetValues = () => {
				setErrorMsg('');
				setVoidType(undefined);
			};

			const handleSubmit = async () => {
				setLoading(true);
				if (voidType === 'all') {
					if (partialPayment) {
						onRefund();
						return;
					} else {
						const voidResponse = await postVoidItems(
							organizationId,
							invoiceId,
							lineItems.map(lineItem => ({ id: lineItem.id }))
						);
						if ((voidResponse as any).err) {
							//  Error in response from server
							ErrorToggle();
							setLoading(false);
							setVoidType(undefined);
							setErrorMsg((voidResponse as any).err);
							return;
						}
					}
				}
				//  Void successful
				onFinish(voidType);
				toggle();
				setLoading(false);
				resetValues();
			};

			const onTypeSelected = (type: VoidActionType) => {
				setVoidType(type);
				if (type === 'all') {
					if (partialPayment) {
						setWarningMsg(voidInvoiceLabels.voidPartialPaymentWarning);
					} else {
						setWarningMsg(voidInvoiceLabels.voidAllWarning);
					}
				} else {
					setWarningMsg('');
				}
			};

			const disableButtons = useMemo(() => {
				// if there is voided line item already -> we disable the void all option.
				return !voidType || (isVoidedLineItemExist && voidType === 'all');
			}, [voidType]);

			const voidShowChange = useCallback(() => {
				if (!isVoidInvoiceShowing) {
					resetValues();
				}
			}, [isVoidInvoiceShowing]);

			useEffect(() => {
				voidShowChange();
			}, [isVoidInvoiceShowing]);

			return (
				<Fragment>
					<Modal isShowing={isVoidInvoiceShowing} toggle={VoidInvoiceToggle}>
						{!noVoid && (
							<div css={containerCss}>
								<div css={headerCss}>
									<Typography type={ETypography.h4} color={ETypographyColor.primary}>
										{voidInvoiceLabels.title}
									</Typography>
									<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
										{`${voidInvoiceLabels.subTitle}${Pricify(totalDue)}`}
									</Typography>
								</div>
								<div css={modalBodyCss}>
									{warningMsg && (
										<div css={rowCss}>
											<div className="caption" css={methodSubtitleCss}>
												<BnIcon icon={Icons.conflict} />
												{warningMsg}
											</div>
										</div>
									)}
									{!warningMsg && (
										<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
											{voidInvoiceLabels.optionLabel}
										</Typography>
									)}
									<div css={methodRowCss}>
										<VoidMethodBox voidType={'all'} active={voidType === 'all'} handleClick={onTypeSelected} isFirst />
										<VoidMethodBox voidType={'items'} active={voidType === 'items'} handleClick={onTypeSelected} />
									</div>
								</div>
								<Footer
									actionButtonText={voidInvoiceLabels.buttons.submit}
									actionButtonMethod={handleSubmit}
									toggle={VoidInvoiceToggle}
									isActionButtonDisabled={disableButtons}
									isActionButtonProcessing={loading}
								/>
							</div>
						)}
						{noVoid && (
							<div css={noVoidContainerCss}>
								<ModalBody
									icon={Icons.conflict}
									iconColor={colors_v2.tx_error}
									title={voidInvoiceLabels.noVoidAvailable.title}
									description={voidInvoiceLabels.noVoidAvailable.description}
									mainButton={voidInvoiceLabels.noVoidAvailable.mainButton}
									subButton={voidInvoiceLabels.noVoidAvailable.subButton}
									mainAction={onRefund}
									subAction={VoidInvoiceToggle}
								/>
							</div>
						)}
					</Modal>
					<ErrorModalDepricated message={errorMsg} />
				</Fragment>
			);
		},
		[isVoidInvoiceShowing]
	);

	return { VoidInvoiceToggle, isVoidInvoiceShowing, VoidInvoiceModal };
};
