/** @jsxRuntime classic */
/** @jsx jsx */

import React, { createContext, forwardRef, useState } from 'react';
import { jsx, css, useTheme } from '@emotion/react';
import { useModal } from '../../hooks/useModal';
import { IColors, Icons } from '../../types';
import { BnIcon } from '../icons_v2';
import { animated, useSpring } from '@react-spring/web';
import { useWindowSize } from '../../hooks/useWindowSize';
import { mobileOnly } from '../../styles/utils';

/*
 * Non-maximizable modal css only
 * */
const containerCss = (colors: IColors, isFullSize: boolean, isFullWidth: boolean, isFullHeight: boolean) => css`
	${isFullSize && 'width:100%;height:100%;'};
	${isFullHeight && 'height:100%;'};
	${isFullWidth && 'width:100%;'};
	max-height: 90%;
	max-width: 90%;
`;

const popupWindowBodyContainerCss = (colors: IColors, padding: string, isMaximizable = false) => css`
	padding: ${`1rem ${padding ? padding + 'rem' : '1rem'} ${padding ? padding + 'rem' : '1rem'} ${
		padding ? padding + 'rem' : isMaximizable ? '0' : '1rem'
	}`};
	background: ${colors.bg_white};
	display: flex;
	flex-direction: column;
	height: 97%;
	/*	${mobileOnly} {
		width: 90%;
	}*/
`;

const modalContainerCss = (
	colors: IColors,
	minWidth?: string,
	minHeight?: string,
	maxWidth?: string,
	maxHeight?: string,
	isMaximized?: boolean,
	width?: number,
	height?: number,
	zIndex?: number
) => css`
	position: fixed;
	box-shadow: 0 2px 22px rgba(21, 93, 148, 0.0749563);
	background: ${colors.bg_white};
	z-index: ${zIndex};
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: auto;

	${minWidth && `min-width: ${minWidth};`};
	${minHeight && `min-height: ${minHeight};`};
	${maxWidth && `max-width: ${maxWidth}; width: 100%`};
	${maxHeight && `max-height: ${maxHeight}; `};
	${isMaximized && `width: ${width}px !important; height: ${height}px !important;`};
`;

const headerRowCss = css`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	position: absolute;
`;

const headerButtonCss = (colors: IColors, rightMargin: string) => css`
	margin: 1rem ${rightMargin} 0 0;
	width: 20px;
	height: 20px;
	z-index: 2;
	border-radius: 200px;
	display: flex;
	padding: 0.25rem;
	outline: none;
	border: 0;
	background: transparent;
	cursor: pointer;

	svg {
		color: ${colors.tx_text_secondary};
		height: 20px;
		width: 20px;
	}

	&:focus {
		outline: none;
	}

	&:hover {
		outline: none;

		svg {
			color: ${colors.tx_text_primary};
		}
	}
`;

interface Props {
	children?: React.ReactNode;
	toggle: () => void;
	background?: string;
	padding?: number;
	minWidth?: string;
	minHeight?: string;
	maxWidth?: string;
	maxHeight?: string;
	isDismissable: boolean;
	isFullSize: boolean;
	isFullWidth: boolean;
	isFullHeight: boolean;
	isMaximizable?: boolean;
}

export const ModalContext = createContext({ isMaximized: false, isInTransition: false });

export const Container = forwardRef<HTMLDivElement, Props>(
	(
		{
			children,
			toggle,
			background,
			padding,
			minWidth,
			minHeight,
			maxWidth,
			maxHeight,
			isDismissable,
			isFullSize,
			isFullWidth,
			isFullHeight,
			isMaximizable = false,
		},
		ref
	) => {
		const { zIndex } = useModal();
		const { width, height } = useWindowSize();
		const colors = useTheme();
		const [isMaximized, setIsMaximized] = useState<boolean>(false);
		const [isInTransition, setIsInTransition] = useState<boolean>(false);
		const [styles, api] = useSpring(() => ({ width: minWidth || ``, height: minHeight || `` }));

		const toggleMaximize = () => {
			//	Because setState is async, we are going to use !isMaximized to base our movement on
			if (!isMaximized) {
				//	Maximizing the popup;
				api.start({
					to: { width: maxWidth || String(width), height: maxHeight || String(height) },
					onStart: () => setIsInTransition(true),
					onRest: () => {
						setIsInTransition(false);
						setIsMaximized(true);
					},
				});
			} else {
				//	Collapsing a maximized popup
				api.start({
					to: { width: minWidth, height: minHeight },
					onStart: () => setIsInTransition(true),
					onRest: () => {
						setIsInTransition(false);
						setIsMaximized(false);
					},
				});
			}
		};

		return (
			<div data-aid="container">
				<ModalContext.Provider value={{ isMaximized, isInTransition }}>
					<animated.div
						ref={ref}
						style={styles}
						css={
							!isMaximizable
								? [
										containerCss(colors, isFullSize, isFullWidth, isFullHeight),
										modalContainerCss(
											colors,
											minWidth,
											minHeight,
											maxWidth,
											maxHeight,
											undefined,
											undefined,
											undefined,
											zIndex
										),
								  ]
								: modalContainerCss(
										colors,
										minWidth,
										minHeight,
										maxWidth,
										maxHeight,
										isMaximized && !isInTransition,
										width,
										height,
										zIndex
								  )
						}
					>
						<div css={headerRowCss} data-aid="container-Modal">
							{isMaximizable && (
								<button
									data-aid="button-ModalContainer-max"
									onClick={toggleMaximize}
									css={headerButtonCss(colors, '5px')}
								>
									<BnIcon icon={isMaximized ? Icons.collaps : Icons.expand} />
								</button>
							)}
							{isDismissable && (
								<button data-aid="button-ModalContainer-close" onClick={toggle} css={headerButtonCss(colors, '1rem')}>
									<BnIcon icon={Icons.close} />
								</button>
							)}
						</div>
						<div data-aid="container-main" css={popupWindowBodyContainerCss(colors, String(padding), isMaximizable)}>
							{children}
						</div>
					</animated.div>
				</ModalContext.Provider>
			</div>
		);
	}
);
