import React from 'react';

export const Drag = (props: any) => (
  <svg
    data-aid="svgDrag"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 4.5C7.5 3.67157 8.17157 3 9 3C9.82843 3 10.5 3.67157 10.5 4.5C10.5 5.32843 9.82843 6 9 6C8.17157 6 7.5 5.32843 7.5 4.5ZM13.5 4.5C13.5 3.67157 14.1716 3 15 3C15.8284 3 16.5 3.67157 16.5 4.5C16.5 5.32843 15.8284 6 15 6C14.1716 6 13.5 5.32843 13.5 4.5ZM7.5 12C7.5 11.1716 8.17157 10.5 9 10.5C9.82843 10.5 10.5 11.1716 10.5 12C10.5 12.8284 9.82843 13.5 9 13.5C8.17157 13.5 7.5 12.8284 7.5 12ZM13.5 12C13.5 11.1716 14.1716 10.5 15 10.5C15.8284 10.5 16.5 11.1716 16.5 12C16.5 12.8284 15.8284 13.5 15 13.5C14.1716 13.5 13.5 12.8284 13.5 12ZM7.5 19.5C7.5 18.6716 8.17157 18 9 18C9.82843 18 10.5 18.6716 10.5 19.5C10.5 20.3284 9.82843 21 9 21C8.17157 21 7.5 20.3284 7.5 19.5ZM13.5 19.5C13.5 18.6716 14.1716 18 15 18C15.8284 18 16.5 18.6716 16.5 19.5C16.5 20.3284 15.8284 21 15 21C14.1716 21 13.5 20.3284 13.5 19.5Z"
      fill="currentColor"
    />
  </svg>
);
