import { PaymentMethodTypeEnum, PlatformsEnum, RefundTypeEnum } from '@bondsports/types';
import { Icons } from '@bondsports/utils';

export interface GetPaymentsResponse {
	meta: {
		totalItems: number;
		itemsPerPage: number;
		totalPages: number;
		currentPage: number;
	};
	data: Payment[];
}

export interface Payment {
	id: number;
	total: number;
	paymentMethod: EPaymentMethod;
	status: PaymentChargeStatusEnum;
	createdAt: Date;
	invoices: number[];
}

//  Matches response from api
export interface IPayment {
	bondFee: number;
	createdAt: string;
	creatingUserId: number;
	currency: string;
	fundLeft: number;
	id: number;
	isRefunded: boolean;
	organizationId: number;
	payingUserId: number;
	paymentMethodId?: string;
	paymentProcessorId?: string;
	paymentProcessorTransactionId?: string;
	paymentStatus: PaymentChargeStatusEnum;
	paymentType: EPaymentMethod;
	platform: string;
	price: number;
	receiptUrl?: string;
	shiftId?: number;
	stripeFee: number;
	updatedAt: string;
	totalPrice?: number;
	splitsNumber?: number;
}

export enum EPaymentMethod {
	ACH = 'us_bank_account',
	BALANCE = 'balance',
	CARD = 'card',
	CARD_ON_TERMINAL = 'card-on-terminal',
	CASH = 'cash',
	CHECK = 'check',
	MIGRATED = 'migrated',
	OTHER = 'other',
	SCHEDULED_CHARGE = 'scheduled-charge',
	GIFT_CARD = 'gift-card',
}

export enum EChargeInnerStep {
	SWIPE = 'swipe',
	NEW_CARD = 'newCard',
	SCHEDULED_CHARGE = 'scheduled-charge',
	SCHEDULED = 'scheduled',
	RESCHEDULE = 'reschedule',
}

export enum EChargeStatus {
	COMPLETED = 'completed',
	ERROR = 'error',
}

export type TChargeSteps = EChargeStatus | EChargeInnerStep | PaymentMethodTypeEnum | '';

export enum EPaymentStatus {
	NOT_PAID = 'not_paid',
	FULLY_PAID = 'paid',
	PARTIAL_PAYMENT = 'partial',
	PURCHASE_ORDER = 'purchase_order',
	REFUNDED = 'refunded',
	VOID = 'void',
	QUOTE = 'quote',
}

export enum EPaymentStatusInvoiceFilter {
	NOT_PAID = 'not_paid',
	PAID = 'paid',
	PARTIAL = 'partial',
	VOID = 'void',
}

export enum EInvoicePaymentStatusFilter {
	NOT_PAID = 'not_paid',
	PAID = 'paid',
	PARTIAL = 'partial',
}

export enum PaymentChargeStatusEnum {
	paid = 'Paid',
	refunded = 'Refunded',
}

export interface IPayment {
	plannedDate: string;
	price: number;
}

export interface PaymentMethod {
	id: string;
	billing_details: {
		address: {
			city: string | null;
			country: string | null;
			line1: string | null;
			line2: string | null;
			postalCode: string | null;
			state: string | null;
		};
		email: string;
		name: string;
		phone: string;
		account_number: string;
	};
	card: {
		brand: string;
		country?: string;
		exp_month: string;
		exp_year: string;
		fingerprint?: string;
		funding?: string;
		generated_from?: string | null;
		last4: string;
	};
	type: PaymentMethodTypeEnum;
}

export interface ICustomerFunds {
	// fundsLeft?: number;
	fundLeft?: number;
	paymentMethodId: string;
	paymentType: PaymentMethodTypeEnum;
	ccBrand?: string;
	ccLast4?: string;
	payingUser?: IPayingUser;
}
export interface IPaymentMethod {
	type: EPaymentMethod | PaymentMethodTypeEnum | any;
	selected?: boolean;
	id?: number;
	paymentMethodId?: string;
	icon?: Icons;
	primaryText?: string;
	secondaryText?: string;
	amount?: number; // Amount on a single card or ach payment
	refundAmount?: number; // amount to refund on this payment method
	refundSuccess?: boolean;
	receiptId?: number;
}

export interface IRefundRequest {
	invoiceId: number;
	lineItems: {
		id: number;
		refundAmount: number;
	}[];
	refunds: {
		paymentMethodId: string;
		paymentMethodType: PaymentMethodTypeEnum;
		amount: number;
	}[];
	shiftId: string | number;
	reasonId: number;
	note: string;
	refundType: RefundTypeEnum;
}

export enum PaymentProcessorEnum {
	STRIPE = 'stripe',
	OTHER = 'other',
}
export interface IOrganizationPaymentMethod {
	paymentMethodType: PaymentMethodTypeEnum;
	paymentMethodProcessor: PaymentProcessorEnum;
	minimumAmount: number;
	daysToRefunds?: number;
	platform: PlatformsEnum;
	id: number;
	organizationId: number;
}

export interface IPayingUser {
	firstName?: string;
	lastName?: string;
	id: number;
}
export interface IInvoiceData {
	isPublic?: boolean;
	owner?: string;
}

export interface IDiscountAmountData {
	amount?: number;
	percentage?: number;
}

export interface IDiscountNote {
	content: string;
	isPublic: boolean;
}

export interface IDiscountData extends IDiscountAmountData {
	discountId?: number;
	lineItemsIds: number[];
}

export interface IPostDiscountData {
	discounts: IDiscountData[];
	reasonId: number;
	notes: IDiscountNote[];
	shiftId?: number;
}

export interface IRemoveDiscount {
	actionId: string;
	lineItemIds?: number[];
}

export interface IRemoveDiscountData {
	discounts: IRemoveDiscount[];
	shiftId?: number;
}
