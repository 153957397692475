import React, { Fragment } from 'react';
import { Field as FinalField } from 'react-final-form';
import { Input, InputProps } from '@bondsports/utils';
import { isEmpty } from 'lodash';

export interface IFormProps extends InputProps {
	name: string;
	onClear?: (val: string) => void;
	required?: boolean;
	type?: string;
	step?: number;
	decimalScale?: number;
	validators?: ((val) => { error: string })[];
}

export const FormInput = ({
	onClear = (val: string) => {},
	type = 'string',
	step,
	error,
	...props
}: IFormProps & React.InputHTMLAttributes<HTMLInputElement>) => {
	const clear = () => onClear(props.name);
	return (
		<FinalField
			name={props.name}
			validate={val => {
				const errors = {};
				if (props.required) {
					if (!val) {
						errors[props.name] = 'required';
						return errors;
					}
				}
			}}
			displayEmpty
		>
			{({ input, meta }) => {
				const newProps = { ...props };

				return (
					<Fragment>
						<Input
							data-aid="formInput"
							type={type}
							step={step}
							error={meta.error && meta.error[props.name]}
							clear={clear}
							{...input as any}
							{...newProps as any}
						/>
					</Fragment>
				);
			}}
		</FinalField>
	);
};
