import React from 'react';

export const Back = (props: any) => (
  <svg
    data-aid="svgBack"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.01645 12.0091L7.0128 12.0128L8.13585 13.1358L8.1395 13.1322L14.7818 19.7745L15.9049 18.6515L9.26255 12.0091L15.914 5.35767L14.791 4.23462L8.1395 10.8861L8.12671 10.8733L7.00366 11.9963L7.01645 12.0091Z"
      fill="currentColor"
    />
  </svg>
);
