import React from 'react';

export const CreditCard = (props: any) => (
	<svg 
		data-aid="svgCreditCard"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.91668 8C3.91668 6.84941 4.84942 5.91667 6.00001 5.91667H18C19.1506 5.91667 20.0833 6.84941 20.0833 8V9.08333H3.91668V8ZM2.08334 10V8C2.08334 5.83688 3.83689 4.08333 6.00001 4.08333H18C20.1631 4.08333 21.9167 5.83688 21.9167 8V10V16C21.9167 18.1631 20.1631 19.9167 18 19.9167H6.00001C3.83689 19.9167 2.08334 18.1631 2.08334 16V10ZM20.0833 10.9167V16C20.0833 17.1506 19.1506 18.0833 18 18.0833H6.00001C4.84942 18.0833 3.91668 17.1506 3.91668 16V10.9167H20.0833ZM6.08334 15C6.08334 14.4937 6.49375 14.0833 7.00001 14.0833H7.01001C7.51627 14.0833 7.92668 14.4937 7.92668 15C7.92668 15.5063 7.51627 15.9167 7.01001 15.9167H7.00001C6.49375 15.9167 6.08334 15.5063 6.08334 15ZM11 14.0833C10.4937 14.0833 10.0833 14.4937 10.0833 15C10.0833 15.5063 10.4937 15.9167 11 15.9167H13C13.5063 15.9167 13.9167 15.5063 13.9167 15C13.9167 14.4937 13.5063 14.0833 13 14.0833H11Z"
			fill="currentColor"
		/>
	</svg>
);
