import { OrderByEnum, PunchPassEventDto } from '@bondsports/types';
import { network } from '../network';
import { environment } from '../../../../environments/environment';
import { getEpochTime } from '@app/react/lib/dates';

const V4_ROOT_URL: string = environment.CS_URLS.API_ROOT_V4;

async function getPunchPassEvents(
	organizationId: number,
	userId: number,
	passId: number,
	itemsPerPage: number,
	startDate: Date,
	endDate: Date,
	orderBy?: OrderByEnum,
	includeResources = true,
	includeDeleted = true,
	options?: RequestInit
): Promise<PunchPassEventDto[]> {
	const startDateEpoch = getEpochTime(startDate);
	const endDateEpoch = getEpochTime(endDate);

	const query = `itemsPerPage=${itemsPerPage}&startDate=${startDateEpoch}&endDate=${endDateEpoch}&orderBy=${orderBy}&includeResources=${
		includeResources || ''
	}&includeDeleted=${includeDeleted || ''}`;

	return await network.get(
		`${V4_ROOT_URL}/punch-pass/organization/${organizationId}/user/${userId}/passes/${passId}/events?${query}`,
		options
	);
}

export const punchPassApi = {
	getPunchPassEvents,
};
