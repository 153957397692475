/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { FC, PropsWithChildren } from 'react';

export interface IModalHeaderProps {
	children: React.ReactNode;
}

export const Header: FC<PropsWithChildren<IModalHeaderProps>> = ({ children }: IModalHeaderProps) => {

	return (
		<div
			className='modal-layout-header'
			data-aid="modal-layout-header"
		>
			{children}
		</div>
	);
};
