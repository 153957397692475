/** @jsx jsx */
/** @jsxRuntime classic */

import { BnIcon, ETypography, ETypographyColor, Icons, Typography } from '@bondsports/utils';
import { css, jsx } from '@emotion/react';
import React, { ReactNode } from 'react';
import { colors } from '../../../../../styles/theme';

const containerCss = css`
	background: ${colors.lightGray};
	border: 1px solid ${colors.borderPrimary};
	box-sizing: border-box;
	border-radius: 5px;
	padding: 25px;
	margin: 8px 6px;
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: column;
	svg {
		height: 100%;
		width: auto;
		color: ${colors.lightText};
	}
	.children {
		margin-top: 12px;
	}
	div {
		text-align: center;
	}
`;

const iconCss = css`
	height: 28px;
	width: 28px;
	margin-bottom: 8px;
`;

interface IProps {
	icon: Icons;
	title: string;
	children: ReactNode;
}

export const WidgetBody = ({ icon, title, children }: IProps) => {
	return (
		<div data-aid="WidgetBody" css={containerCss}>
			<div data-aid="WidgetBody-button" css={iconCss}>
				<BnIcon icon={icon} />
			</div>
			<Typography data-aid="WidgetBody-title" color={ETypographyColor.primary} type={ETypography.body2Accented}>
				{title}
			</Typography>
			<div data-aid="WidgetBody-children" className="children">{children}</div>
		</div>
	);
};
