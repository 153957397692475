import * as  React from 'react';

export const BnRemove = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 8C2 4.69147 4.69147 2 8 2C11.3085 2 14 4.69147 14 8C14 11.3085 11.3085 14 8 14C4.69147 14 2 11.3085 2 8ZM10.2096 10.2095C10.4051 10.014 10.4051 9.69803 10.2096 9.50248L8.70705 8L10.2096 6.49753C10.4051 6.30198 10.4051 5.98603 10.2096 5.79047C10.0141 5.59501 9.69811 5.59501 9.50255 5.79047L8.00008 7.29303L6.49761 5.79047C6.30205 5.59501 5.9861 5.59501 5.79054 5.79047C5.59508 5.98603 5.59508 6.30198 5.79054 6.49753L7.29311 8L5.79054 9.50248C5.59508 9.69803 5.59508 10.014 5.79054 10.2095C5.88805 10.307 6.01604 10.356 6.14412 10.356C6.27211 10.356 6.4001 10.307 6.49761 10.2095L8.00008 8.70697L9.50255 10.2095C9.60005 10.307 9.72804 10.356 9.85603 10.356C9.98412 10.356 10.1121 10.307 10.2096 10.2095Z"
      fill="currentColor"
    />
  </svg>
);
