import {
	AfterViewInit,
	Component,
	ElementRef,
	OnDestroy,
	ViewChild,
	ViewEncapsulation,
	Output,
	Input,
	EventEmitter,
} from '@angular/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastrService } from 'ngx-toastr';
import UpdateBankDetailsModal from '@app/react/pages/bank/UpdateBankDetailsModal';
import { currencies } from '@app/shared/constants/currencies';
import { accountNumberRegex, nameRegex } from '@app/shared/constants/regex';
@Component({
	selector: 'stripe-bank-modal-modal',
	template: `<div #reactRoot></div>`,
	encapsulation: ViewEncapsulation.Emulated,
})
export class StripeBankModal implements OnDestroy, AfterViewInit {
	@ViewChild('reactRoot') containerRef: ElementRef;
	@Output() onSubmit = new EventEmitter();
	@Input() org: string;

	currencies = currencies;

	constructor(private toastr: ToastrService) {}

	ngOnChanges(): void {
		this.render();
	}

	ngAfterViewInit() {
		this.render();
	}

	ngOnDestroy() {
		ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
	}

	open() {
		this.render(true);
	}

	private render(visible = false) {
		if (!this.containerRef) return;

		ReactDOM.render(
			React.createElement(UpdateBankDetailsModal, {
				currencies: this.currencies,
				visible,
				onSubmit: obj => this.submit(obj),
			}),
			this.containerRef.nativeElement
		);
	}

	submit(formData) {
		if (!formData.owner || !nameRegex.test(formData.owner)) {
			this.toastr.warning('Please provide valid account owner');
			return false;
		}
		if (!formData.routing_number || !accountNumberRegex.test(formData.routing_number)) {
			this.toastr.warning('Please provide valid routing number');
			return false;
		}
		if (!formData.address) {
			this.toastr.warning('Please provide valid address');
			return false;
		}
		if (!formData.account_number || !accountNumberRegex.test(formData.account_number)) {
			this.toastr.warning('Please provide valid account number');
			return false;
		}
		if (!formData.currency) {
			this.toastr.warning('Please provide valid currency');
			return false;
		}

		this.onSubmit.emit(formData);
		return true;
	}
}
