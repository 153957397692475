export interface IFormValues {
	name: string;
	phoneNumber: string;
	email: string;
	street?: string;
	city?: string;
	state?: string;
	zip?: string;
	emergencyContactName?: string;
	emergencyContactPhone?: string;
}
export interface IFormInputWraper {
	label: string;
	name: string;
	isRequired?: boolean;
	type?: string;
	isFullWidth?: boolean;
	helper?: string;
	helperContent?: string;
}

export const EMERGENCY_CONTACT_NAME = 'emergencyContactName';
export const EMERGENCY_CONTACT_PHONE = 'emergencyContactPhone';

export enum AddMemberFormFieldsEnum {
	PHONE_NUMBER = 'phoneNumber',
	EMAIL = 'email',
	STREET = 'street',
	CITY = 'city',
	STATE = 'state',
	ZIP = 'zip',
}

export interface IToggleProps {
	toggleLabel: string;
}
