/** @jsx jsx */
import React, { Fragment, forwardRef, ComponentType } from 'react';
import { jsx, SerializedStyles } from '@emotion/react';
import { TableVirtuoso } from 'react-virtuoso';

import { checkboxCss, CheckedIconCss, iconCss, checkboxContainer } from '../../../../styles/utils';

import { ExpandableRow } from '../ExpandableRow';
import { HeaderRow } from '../HeaderRow';
import { rowCss, headerCss, pageBreakCss, deviderCss, tableCss } from '../styles';
import { CustomCheckbox } from '../../Checkbox';
import { TableProps } from '../types';

interface VirtualTableContext {
	tableCss: SerializedStyles | SerializedStyles[];
	rowCss: SerializedStyles | SerializedStyles[];
}

export const VirtualTable = ({
	rows,
	columns,
	isSelectRow = false,
	isRadio = false,
	isHoverRow = true,
	expandableKey = '',
	expandable = false,
	expandOn = [],
	isExpand = false,
	subBottomBorder = false,
	onSelect = () => {},
	selectedArr = [],
	source,
	maxSelected,
	specificDisabledRows = [],
	removeRowSeparator = false,
	isAllSelected = false,
	onSelectAll = () => {},
	emptyTableMessage = '', //TableVirtuoso not support it
	isSelectIndeterminate = false,
	timezone,
}: TableProps) => {
	const isSelected = (name: string | number) => selectedArr.includes(name);

	const isCheckBoxDisabled = (row: any) => {
		if (selectedArr) {
			if (specificDisabledRows.length > 0) {
				return specificDisabledRows.includes(row?.id);
			} else {
				return selectedArr.length >= maxSelected;
			}
		}
		return false;
	};
	// passing rows.length as selectedNum to display checked if all rows are selected
	const selecedNum = isAllSelected ? rows?.length : selectedArr?.length;
	return (
		<TableVirtuoso
			style={{
				width: '100%',
				height: '100%',
			}}
			context={
				{
					tableCss: tableCss(removeRowSeparator, false),
					rowCss: [pageBreakCss, rowCss(isHoverRow, false, subBottomBorder)],
				} as VirtualTableContext
			}
			components={{
				Table,
				TableHead,
				TableRow,
			}}
			data={rows}
			fixedHeaderContent={() => (
				<HeaderRow
					isSelectRow={isSelectRow}
					isRadio={isRadio}
					columns={columns}
					numSelected={selecedNum}
					rowCount={rows?.length}
					onSelectAllClick={onSelectAll}
					isSelectIndeterminate={isSelectIndeterminate}
				/>
			)}
			itemContent={(index, row) => {
				if (row.isDivider) {
					return (
						<Fragment key={index}>
							{isSelectRow && (
								<td
									className="TableCell-root TableCell-body TableCell-paddingCheckbox"
									css={[deviderCss, checkboxContainer]}
								>
									{isRadio ? (
										<div>s</div>
									) : (
										<CustomCheckbox
											checked={false}
											css={checkboxCss(false)}
											checkedIcon={<span id="checked" css={CheckedIconCss} />}
											icon={<span css={iconCss} />}
										/>
									)}
								</td>
							)}
							<td className="TableCell-root TableCell-body" css={deviderCss} colSpan={columns.length}>
								{row.title}
							</td>
						</Fragment>
					);
				}
				const labelId = `enhanced-table-checkbox-${index}`;
				const isItemSelected =
					(isAllSelected && !selectedArr.includes(row?.id)) || (!isAllSelected && selectedArr.includes(row?.id));

				return (
					<ExpandableRow
						isExpand={isExpand}
						isRadio={isRadio}
						isHoverRow={isHoverRow}
						key={index}
						isLast={index + 1 === rows.length}
						row={row}
						columns={columns}
						isItemSelected={isItemSelected}
						expandableKey={expandableKey}
						isSelected={isSelected}
						handleClick={onSelect}
						expandable={expandable}
						isSelectRow={isSelectRow}
						subBottomBorder={subBottomBorder}
						labelId={labelId}
						expandOn={expandOn}
						source={source ? source : null}
						disableCheckbox={isCheckBoxDisabled(row)}
						isSubCheckboxDisabled={isCheckBoxDisabled}
						timezone={timezone}
					/>
				);
			}}
		/>
	);
};

const Table = ({ children, context: { tableCss }, ...props }) => (
	<table css={tableCss} aria-labelledby="tableTitle" aria-label="enhanced table" {...props}>
		{children}
	</table>
);

const TableHead: ComponentType<any> = forwardRef(({ children, ...props }, ref: any) => (
	<thead {...props} css={headerCss} ref={ref}>
		{children}
	</thead>
));

const TableRow: ComponentType<any> = ({ children, context: { rowCss }, ...props }) => (
	<tr css={rowCss} {...props}>
		{children}
	</tr>
);
