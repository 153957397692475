import {
	EventAttendee,
	FindEventAttendeeOptionsDto,
	IEventOfSessionDetails,
	IGetEventAttendees,
} from 'app/react/types/events';
import { CreateRegisteredAttendeeData, Purchased, UpdateRegisteredAttendeeData } from 'app/react/types/registration';
import { environment } from '../../../../environments/environment';
import { network, IApiError } from '../network';

const v1Url = `${environment.CS_URLS.API_ROOT}`;
const v4Url = `${environment.CS_URLS.API_ROOT_V4}`;

const getEntitledCustomers = (
	programSeasonId: number,
	organizationId: number,
	eventId: number,
	punchCardSeason: boolean
): Promise<Purchased[]> => {
	return network.get(
		`${v4Url}/registration/entitledCustomers/programSeason/${programSeasonId}/organization/${organizationId}/event/${eventId}/${punchCardSeason}`
	);
};

const getRegisteredAttendees = (eventId: number, organizationId: number): Promise<EventAttendee[]> => {
	return network.get(`${v4Url}/registration/registeredAttendees/event/${eventId}/organization/${organizationId}`);
};

const getCustomer = (customerId: number, organizationId: number): Promise<any> => {
	return network.get(`${v1Url}/organizations/${organizationId}/customers/${customerId}`);
};

const updateRegisteredAttendee = (
	organizationId: number,
	updateRegisteredAttendeeData: UpdateRegisteredAttendeeData
): Promise<EventAttendee> => {
	return network.put(`${v4Url}/registration/attendee/organization/${organizationId}`, updateRegisteredAttendeeData);
};

const createRegisteredAttendee = (
	organizationId: number,
	createRegisteredAttendeeData: CreateRegisteredAttendeeData
): Promise<EventAttendee[]> => {
	return network.post(
		`${v4Url}/registration/organization/${organizationId}/attendee/register`,
		createRegisteredAttendeeData
	);
};

async function updateSessionRegistration(organizationId: number, sessionId: number, data: any): Promise<any> {
	const response = await network.put(
		`${v4Url}/programs-seasons/organization/${organizationId}/season/${sessionId}/registration`,
		data
	);
	return response;
}

/**
 * Get details of event for the programs schedule attendance popup window
 * @param {number} sessionId Session id of the parent session of the event
 * @param {number} eventId Event id to be requested
 * @returns {Promise<IEventOfSessionDetails>} Object with event details only
 */
const getEventDetails = async (sessionId: number, eventId: number): Promise<IEventOfSessionDetails> => {
	const { data } = await network.get(`${v4Url}/programs-seasons/${sessionId}/events/${eventId}`);
	return data;
};

const getEventAttendees = async (
	sessionId: number,
	eventId: number,
	filters?: FindEventAttendeeOptionsDto
): Promise<IGetEventAttendees> => {
	const query = [];
	for (const [key, value] of Object.entries(filters)) {
		query.push(`${key}=${value}`);
	}
	return await network.get(`${v4Url}/programs-seasons/${sessionId}/events/${eventId}/attendees?${query.join('&')}`);
};

export {
	getEntitledCustomers,
	getRegisteredAttendees,
	updateRegisteredAttendee,
	createRegisteredAttendee,
	getCustomer,
	updateSessionRegistration,
	getEventDetails,
	getEventAttendees,
};
