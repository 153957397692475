import { useEffect, useRef, useState } from 'react';
import { IOption } from '.';
import { getLabels } from './utils';
import { IGroup } from '../Options_v2/types';

export const useSelect = ({
	value,
	maxSelections,
	onSelecte,
	isWithTags,
	options,
	isGroups,
}: {
	value: string | (string & readonly string[]);
	maxSelections?: number;
	onSelecte: (val: string | string[] | number[] | (string | number)[]) => void;
	isWithTags?: boolean;
	options: IOption[] | IGroup[];
	isGroups: boolean;
}) => {
	const [isOpen, setOpen] = useState(false);
	const [visited, setVisited] = useState(false);
	const [toEnter, setToEnter] = useState(false);
	const [selectedValues, setSelectedValues] = useState<IOption[]>([]);
	const [isMaxError, setIsMaxError] = useState(false);
	const [isOrderBySelection, setIsOrderBySelection] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	const toggleIsOpen = () => setOpen(!isOpen);

	const handleSetOpen = (val: boolean) => setOpen(val);

	const handleRemoveOption = (val: string) => {
		onSelecte((value as unknown as any[]).map(v => String(v)).filter(v => v !== String(val)));
		enableSelection();
	};

	const isDisableSelection = maxSelections && value.length >= maxSelections;
	const disaleSelection = () => {
		setOpen(false);
		setIsMaxError(true);
	};
	const enableSelection = () => {
		setIsMaxError(false);
	};

	const isValueSelected = (val: string) => {
		return value && (value as unknown as any[])?.map(v => String(v))?.includes(val);
	};

	const singleSelectOnChange = (val: string) => {
		onSelecte(val);
		setOpen(false);
		if (ref.current) {
			ref.current.focus();
		}
	};

	const multipleSelectOnChange = (val: string) => {
		if (isValueSelected(String(val))) {
			handleRemoveOption(val);
		} else {
			if (isDisableSelection) {
				disaleSelection();
				return;
			} else {
				enableSelection();
			}
			onSelecte([...(value as unknown as string[]), val]);
			if (!isOrderBySelection) {
				setIsOrderBySelection(true);
			}
		}
	};

	const handleOnChange = (val: string, isMultiple?: boolean) => {
		if (isMultiple) {
			multipleSelectOnChange(val);
		} else {
			singleSelectOnChange(val);
		}
	};

	useEffect(() => {
		if (toEnter) {
			if (!isOpen) {
				setVisited(true);
			}
		}
		setToEnter(true);
	}, [isOpen]);

	/**The logic of this `useEffect` is only applicable when the values and `isOrderBySelection` change.
	 * When adding/removing a value, it should be displayed under the select,
	 * But it should not render every time the options change. */
	useEffect(() => {
		setSelectedValues(prevSelectedValues => {
			return isWithTags && Array.isArray(value)
				? (getLabels({ prevSelectedValues, options, values: value, isGroups, isOrderBySelection }) as IOption[])
				: [];
		});
	}, [value, isOrderBySelection]);

	return {
		handleOnChange,
		isOpen,
		visited,
		toEnter,
		selectedValues,
		isMaxError,
		handleSetOpen,
		ref,
		handleRemoveOption,
		isGroups,
		toggleIsOpen,
	};
};
