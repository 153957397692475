import * as  React from 'react';

export const BnNoteFilled = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0002 21.7504L8.25024 18.0004H5.75024C4.75568 18.0004 3.80185 17.6053 3.09859 16.902C2.39533 16.1988 2.00024 15.245 2.00024 14.2504V6.75039C2.00024 5.75583 2.39533 4.802 3.09859 4.09874C3.80185 3.39548 4.75568 3.00039 5.75024 3.00039H18.2502C19.2448 3.00039 20.1986 3.39548 20.9019 4.09874C21.6052 4.802 22.0002 5.75583 22.0002 6.75039V14.2504C22.0002 15.245 21.6052 16.1988 20.9019 16.902C20.1986 17.6053 19.2448 18.0004 18.2502 18.0004H15.7502L12.0002 21.7504ZM5.85499 7.99926C5.85499 7.36644 6.368 6.85343 7.00082 6.85343H17.0008C17.6336 6.85343 18.1467 7.36644 18.1467 7.99926C18.1467 8.63209 17.6336 9.1451 17.0008 9.1451H7.00082C6.368 9.1451 5.85499 8.63209 5.85499 7.99926ZM6.99998 11.855C6.36715 11.855 5.85414 12.368 5.85414 13.0008C5.85414 13.6336 6.36715 14.1466 6.99998 14.1466H14.5C15.1328 14.1466 15.6458 13.6336 15.6458 13.0008C15.6458 12.368 15.1328 11.855 14.5 11.855H6.99998Z"
      fill="currentColor"
    />
  </svg>
);
