/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, useState } from 'react';
import { jsx } from '@emotion/react';
import { NotesPopupLabels, NotesPopupStepEnum, TNotesPopupActions } from '../../../types/notes';
import { Stepper } from '../Steps';
import { Schema } from 'yup';
import { NotesPopupContext } from '../../../store/notes';
import { NoteFormProps } from '../../../organisms/notes/NoteForm';
import { NoteProps } from '../../../molecules/notes/NotesList/Note';
import { ArchiveNoteProps } from '../../../molecules/notes/ArchiveNote';
import { PopupHeader } from '../../../molecules/notes/Headers/PopupHeader';
import { AddNoteDto, EditNoteDto, NoteDto } from '../../../types/entities/notes';
import { ModalWindow } from '../../../index';
import { ResourceNameTypeEnum } from '../../../types/enums';

export type NotesPopupProps = {
	labels: NotesPopupLabels;
	step: NotesPopupStepEnum;
	isShowing: boolean;
	toggle: () => void;
	entityId: number;
	entityType: ResourceNameTypeEnum;
	setStep: (step: NotesPopupStepEnum) => void;
	timezone: string;
	actions: TNotesPopupActions;
	creatorId: number;
	creatorName: string;
	onNoteCreated?: (note: NoteDto) => void;
	onNoteEdited?: (note: NoteDto) => void;
	onNoteArchived?: (note: NoteDto) => void;
	schema: Schema;
	FormComponent?: React.FC<NoteFormProps>;
	NoteComponent?: React.FC<NoteProps>;
	ArchiveComponent?: React.FC<ArchiveNoteProps>;
	HeaderComponent?: React.FC<{ title: string }>;
	isFormValid: (
		values: Partial<EditNoteDto> | AddNoteDto,
		initialValues: Partial<EditNoteDto | AddNoteDto>,
		errors: any
	) => boolean;
	notesToDisplay: number;
	maxAlerts: number;
};

export const NotesPopup: React.FC<NotesPopupProps> = ({
	step,
	isShowing,
	toggle,
	entityId,
	entityType,
	creatorId,
	creatorName,
	setStep,
	labels,
	timezone,
	actions,
	onNoteArchived,
	onNoteCreated,
	onNoteEdited,
	schema,
	FormComponent,
	NoteComponent,
	ArchiveComponent,
	HeaderComponent,
	notesToDisplay,
	maxAlerts,
	isFormValid,
}) => {
	const [title, setTitle] = useState('');

	return (
		<ModalWindow isShowing={isShowing} toggle={toggle} padding={0} maxWidth={'600px'} minWidth={'600px'}>
			<NotesPopupContext.Provider
				value={{
					timezone,
					entityType,
					entityId,
					creatorName,
					creatorId,
					schema,
					FormComponent,
					NoteComponent,
					ArchiveComponent,
					actions,
					labels,
					isFormValid,
					notesToDisplay,
					maxAlerts,
				}}
			>
				<Fragment>
					{HeaderComponent ? <HeaderComponent title={title} /> : <PopupHeader title={title} />}
					<Stepper
						toggle={toggle}
						step={step}
						setStep={setStep}
						setTitle={setTitle}
						onNoteCreated={onNoteCreated}
						onNoteEdited={onNoteEdited}
						onNoteArchived={onNoteArchived}
					/>
				</Fragment>
			</NotesPopupContext.Provider>
		</ModalWindow>
	);
};
