/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactElement } from 'react';
import { css, jsx, useTheme } from '@emotion/react';
import { IColors, Icons } from '../../types';
import { BnIcon } from '../icons_v2';
import { Tooltip } from '../Tooltip';

const frameContainerCss = (height: number | string, width: number, margin: string) => css`
	background: white;
	box-shadow: 0px 2px 22px rgba(21, 93, 148, 0.0749563);
	box-sizing: border-box;
	padding: 2rem;
	width: ${width}%;
	margin: ${margin};
	height: ${typeof height === 'number' ? `${height}px` : height};
	border-radius: 4px;
`;

const titleCss = (colors: IColors) => css`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 1.6rem;
	line-height: 20px;
	margin: 0;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: ${colors.bg_text_primary};
`;

const headerCss = (withoutHeaderBorder: boolean, headerPadding: string, colors: IColors) => css`
	display: flex;
	margin: 0;
	margin-bottom: ${withoutHeaderBorder ? '2px' : '1rem'};
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding-bottom: ${headerPadding};
	border-bottom: ${withoutHeaderBorder ? 'none' : `1px solid ${colors.bg_border_primary}`};
`;

const infoCss = (colors: IColors) => css`
	position: relative;
	display: flex;
	align-items: center;
	div {
		display: flex;
	}
	svg {
		margin-left: 8px;
		width: 16px;
		height: 16px;
		color: ${colors.bg_text_secondary};
	}
`;

export interface IFrameContainerProps {
	title: string | ReactElement;
	button?: ReactElement;
	height?: number;
	info?: string;
	width?: number;
	margin?: string;
	headerPadding?: string;
	withoutHeaderBorder?: boolean;
    colors?: IColors
}

export const FrameContainer: React.FC<IFrameContainerProps> = ({
	children,
	title,
	info,
	button,
    colors: colorsOverride = {},
	height = 'auto',
	width = 100,
	margin = '0 0 2rem 0',
	withoutHeaderBorder = false,
	headerPadding = '2rem',

}) => {
    const colors = { ...useTheme(), ...colorsOverride };

	return (
		<div data-aid="frameContainer" css={frameContainerCss(height, width, margin)}>
			{title && (
				<div data-aid="frameContainerTitle" css={headerCss(withoutHeaderBorder, headerPadding, colors)}>
					<h1 title={typeof(title)==='string' ? title : ""} css={titleCss(colors)}>
						{title}
						{info && (
							<div data-aid="frameContainer-info" css={infoCss(colors)}>
								<Tooltip content={info}>
									<BnIcon icon={Icons.info} />
								</Tooltip>
							</div>
						)}
					</h1>
					<div data-aid="cont-button">
					{button}
					</div>
				</div>
			)}
			<div data-aid="frameContainerChildren">{children}</div>
		</div>
	);
};