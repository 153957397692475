import * as React from 'react';
import { useRecoilState } from 'recoil';
import { programsApi } from '../lib/api/programsApi';
import { programStore } from '../stores/programStore';
import { environment } from '../../../environments/environment';
import { PublishingStatusEnum } from '../types/season';
import { GenderEnum, LevelOfPlayEnum, SportsEnum } from '@bondsports/types';
import { useNotification } from './useNotification';
import { ENotificationType } from '@bondsports/utils';

export const useProgram = () => {
	const [programState, setProgramState] = useRecoilState(programStore.programState);
	const [allSessions, setAllSessions] = useRecoilState(programStore.allSessions);
	const [isFetchingProgram, setFetchingProgramState] = useRecoilState(programStore.isProgramFetching);
	const [isFetchingSessions, setIsFetchingSessions] = useRecoilState(programStore.isFetchingSessions);
	const [isColorEdited, setIsColorEdited] = useRecoilState(programStore.isColorEdited);
	const { setPopupNotification } = useNotification();

	const initiateProgramData = (data: any) => {
		setProgramState(data);
		setFetchingProgramState(false);
	};

	const initiateOtherSessions = (data: any) => {
		setAllSessions(data);
		setIsFetchingSessions(false);
	};

	const fetchSessionsByProgram = (organizationId: number, programId: number) => {
		programsApi.getSessionsByProgram(programId, organizationId).then(response => {
			if (response.data) {
				initiateOtherSessions(response.data);
			}
		});
	};

	const fetchProgramData = (programId: number, organizationId: number, includeSeasons = false) => {
		programsApi.getProgram(programId, organizationId, includeSeasons).then(res => {
			if (res.err) {
				setPopupNotification(String(res.err), ENotificationType.warning);
			} else {
				initiateProgramData(res);
			}
		});
	};

	const getLevelOfPlayText = (ids: number[]): string => {
		let tempString = '';
		for (const level of ids) {
			tempString = tempString + '-' + LevelOfPlayEnum[level];
		}
		return tempString;
	};

	const generatedPublicLink = () => {
		let publicSiteUrl =
			environment.CONSUMER_SITE_URL +
			'/activity/programs/' +
			(programState.gender === GenderEnum.OTHER ? 'coed' : GenderEnum[programState.gender]) +
			getLevelOfPlayText(programState.level) +
			'-' +
			SportsEnum[programState.sport] +
			'/' +
			programState.id;
		publicSiteUrl = publicSiteUrl.replace(/ /g, '-').toLowerCase();

		return publicSiteUrl;
	};

	const updateProgramStatus = async (
		programId: number,
		organizationId: number,
		status: PublishingStatusEnum,
		onError: (message: string) => void,
		onSuccess: () => void
	): Promise<void> => {
		const { data } = await programsApi.updateProgramStatus(programId, organizationId, status);
		if (data.err) {
			onError(data.err.message);
		} else {
			onSuccess();
			setProgramState(data);
		}
	};

	return {
		fetchProgramData,
		generatedPublicLink,
		fetchSessionsByProgram,
		programState,
		allSessions,
		isFetchingSessions,
		isFetchingProgram,
		updateProgramStatus,
		isColorEdited,
		setIsColorEdited,
	};
};
