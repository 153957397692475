/** @jsxRuntime classic */
/** @jsx jsx */
import React, { FC, useEffect, useState, useRef } from 'react';
import { TSize, TButton, EBrandingColorsOptions } from '../../types/theme';
import cx from 'classnames';
import { Loader } from '../Loader';
import { jsx, useTheme } from '@emotion/react';
import { StyledButton } from './style';

export type ButtonP = ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface ButtonProps {
	theme: TButton;
	sizer: TSize;
	icon?: string;
	isProcessing?: boolean;
	loadingColor?: EBrandingColorsOptions;
	isActive?: boolean;
}

export const Button: FC<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
	children,
	theme = 'primary',
	sizer = 'L',
	isProcessing = false,
	icon = '',
	loadingColor = EBrandingColorsOptions.tx_white,
	isActive = false,
	...rest
}) => {
	const ref = useRef<HTMLElement>(null);
	const [width, setWidth] = useState(0);
	const colors = useTheme();
	const newClassName = rest.className;
	delete rest.className;

	useEffect(() => {
		const { offsetWidth } = ref.current as HTMLElement;
		setWidth(offsetWidth);
	}, []);

	const newOnClick = isProcessing ? () => {} : rest?.onClick;

	return (
		<StyledButton
			data-aid='button'
			theme={{ ...colors }}
			{...rest}
			onClick={newOnClick}
			className={cx(
				{
					primary: theme === 'primary',
					secondary: theme === 'secondary',
					invert: theme === 'invert',
					basic: theme === 'basic',
					basicLine: theme === 'basicLine',
					isProcessing: isProcessing,
					isActive: isActive,
				},
				sizer,
				newClassName
			)}
			ref={ref}
		>
			{isProcessing ? (
				<div style={{ minWidth: width }}>
					<Loader color={loadingColor} />
				</div>
			) : (
				children
			)}
		</StyledButton>
	);
};
