/* @jsx jsx */

import React, { useState } from 'react';
import { css, jsx } from '@emotion/react';
import { colors_v2 } from '../../../../../styles/theme';
import { useModal } from '@bondsports/utils';
import { PunchPassInfoDto, SimpleResourceDto, SimpleSessionDto } from '@bondsports/types';
import { isEarlier } from '../../../../../lib/dates';
import { useSports } from '../../../../../hooks/useSports';
import { SimpleProgramWithSportDto } from '../../types/types';
import { ResourcesModal } from '../utils/ResourcesModal';
import { PunchPassExpendableSection } from './PunchPassExpendableSection';
import { PunchPassValidityInfo } from './PunchPassValidityInfo';
import { PunchPassBasicInfo } from './PunchPassBasicInfo';

type PunchPassDetailsCardProps = {
	data: PunchPassInfoDto;
	displayUserDetails: boolean;
	onViewInvoice: (invoiceId: number) => void;
	onViewPayment: (paymentId: number) => void;
	labels: Record<string, any>;
	timezone: string;
	onNavigateToSession: (session: SimpleSessionDto, program: SimpleProgramWithSportDto) => void;
	index: number;
};

const cardCss = (backgroundColor: string) => css`
	border: 1px solid ${colors_v2.bg_border_primary};
	border-radius: 4px;
	padding: 16px;
	margin: 0;
	background: ${backgroundColor};
`;

export const PunchPassDetailsCard: React.FC<PunchPassDetailsCardProps> = ({
	data,
	onViewInvoice,
	onViewPayment,
	displayUserDetails,
	labels,
	timezone,
	onNavigateToSession,
	index,
}) => {
	const [resourcesToView, setResourceToView] = useState<SimpleResourceDto[]>();

	const { getSportById } = useSports();

	const { session, passesLeft, product, program } = data;

	const expired: boolean = isEarlier(session.endDate, new Date());
	const noPassesLeft: boolean = passesLeft <= 0;
	const isValidPass: boolean = !noPassesLeft && !expired;

	const { toggle: toggleResourcesModal, isShowing: isShowingResourcesModal } = useModal();

	return (
		<div
			datatype="card"
			data-testid="punch-pass-details"
			data-index={index}
			css={cardCss(isValidPass ? colors_v2.bg_white : colors_v2.tx_background_primary)}
		>
			<PunchPassBasicInfo
				program={program as SimpleProgramWithSportDto}
				product={product}
				session={session}
				onNavigateToSession={onNavigateToSession}
			/>
			<PunchPassValidityInfo
				labels={labels}
				timezone={timezone}
				expired={expired}
				isValidPass={isValidPass}
				session={session}
				passesLeft={data.passesLeft}
				totalPasses={data.totalPasses}
			/>
			<PunchPassExpendableSection
				data={data}
				labels={labels}
				getSportById={getSportById}
				displayUserDetails={displayUserDetails}
				onViewInvoice={onViewInvoice}
				onViewPayment={onViewPayment}
				timezone={timezone}
				setResourcesView={setResourceToView}
				toggle={toggleResourcesModal}
			/>
			<ResourcesModal
				labels={labels}
				resources={resourcesToView}
				isShowing={isShowingResourcesModal}
				toggle={toggleResourcesModal}
			/>
		</div>
	);
};
