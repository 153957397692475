import React from 'react';

export const Logout = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.12501 19.875H11.4374C11.7479 19.875 11.9999 20.127 11.9999 20.4375C11.9999 20.748 11.7479 21 11.4374 21H3.56248C3.25202 21 3 20.748 3 20.4375V3.56249C3 3.25197 3.25202 3 3.56248 3H11.4374C11.7479 3 11.9999 3.25202 11.9999 3.56249C11.9999 3.87295 11.7479 4.12497 11.4374 4.12497H4.12501V19.875Z"
      fill="currentColor"
    />
    <path
      d="M16.9556 7.67042L20.8368 11.6074C21.0556 11.8256 21.0534 12.1918 20.8362 12.4106L16.9556 16.3476C16.7362 16.5698 16.3802 16.5698 16.1602 16.3476C15.9403 16.126 15.9403 15.7665 16.1602 15.5443L19.0852 12.5771H7.50178C7.19131 12.5771 6.9393 12.3229 6.9393 12.009C6.9393 11.6951 7.19127 11.4409 7.50178 11.4409H19.0852L16.1602 8.47366C15.9403 8.25203 15.9403 7.89205 16.1602 7.67042C16.3796 7.44879 16.7357 7.44823 16.9556 7.67042Z"
      fill="currentColor"
    />
  </svg>
);
