/** @jsxRuntime classic */
/** @jsx jsx */
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { jsx, css } from '@emotion/react';

export interface IModalFooterProps {
	children: React.ReactNode;
}
export const Footer: FC<PropsWithChildren<IModalFooterProps>> = ({ children }: IModalFooterProps) => {
	return (
		<div className="modal-layout-footer" data-aid="modal-layout-footer">
			{children}
		</div>
	);
};
