import React, { useRef, ComponentType, useEffect } from 'react';
import { VariableSizeGrid } from 'react-window';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { useGridDimensions } from '../../hooks/useGridResize';
import { createCell } from './createCell';
import { arrayTo2d } from '../../utils/virtualized-grid.util';

export interface InfiniteGridProps<TCell> {
	component: ComponentType<TCell>;
	data: TCell[];
	defaultRowHeight: number;
	defaultColumnWidth: number;
}

export function InfiniteGrid<TCell>({
	component,
	data,
	defaultRowHeight,
	defaultColumnWidth,
}: InfiniteGridProps<TCell>) {
	const gridRef = useRef<VariableSizeGrid | null>(null);
	const { dimensions, setNewParentDimensions } = useGridDimensions(defaultColumnWidth);
	const cellHeights = useRef({});
	const cellWidths = useRef({});

	const handleSetSize = (rowIndex: number, columnIndex: number, size: { height: number; width: number }) => {
		if (gridRef) {
			gridRef.current?.resetAfterIndices({ columnIndex, rowIndex });
			cellHeights.current = { ...cellHeights.current, [`${rowIndex}-${columnIndex}`]: size.height };
			cellWidths.current = { ...cellWidths.current, [`${rowIndex}-${columnIndex}`]: size.width };
		}
	};

	const getRowHeight = (index: number) => {
		return (cellHeights?.current as any)?.[index] || defaultRowHeight || 100;
	};

	const getColumnWidth = (index: number) => {
		return (cellWidths?.current as any)?.[index] || defaultColumnWidth || 100;
	};

	return (
		<AutoSizer>
			{({ height, width }: Size) => {
				const rowCount = Math.ceil(data.length / dimensions.columns);
				const colCount = data.length < dimensions.columns ? data.length : dimensions.columns;
				if (width !== dimensions.width - 15 || height !== dimensions.height) setNewParentDimensions(width, height);

				const data2d = arrayTo2d(rowCount, colCount, data);

				return (
					<VariableSizeGrid
						ref={gridRef}
						height={height}
						width={width}
						rowCount={rowCount}
						columnCount={colCount}
						rowHeight={getRowHeight}
						columnWidth={getColumnWidth}
						overscanRowCount={20}
						itemData={data2d}
					>
						{createCell(component, handleSetSize)}
					</VariableSizeGrid>
				);
			}}
		</AutoSizer>
	);
}
