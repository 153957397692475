/** @jsx jsx */
import { flex } from 'app/react/styles/utils';
import { css, jsx } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { colors } from 'app/react/styles/theme';
import React from 'react';

const TITLE_SIZE = 40;

const headerCss = css`
	margin: 1rem 0;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 38px 0px 24px 42px;
	border-bottom: 1px solid ${colors.borderPrimary};
`;

const eventNameCss = css`
	font-size: 2rem;
	font-family: 'Montserrat';
	color: ${colors.brandPrimary};
	font-weight: bold;
	${flex};
`;

const subtitleCss = (color: string) => css`
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 1.2rem;
	line-height: 130%;
	color: ${color};
`;

interface HeaderProps {
	eventName: string;
	programName: string;
	seasonName: string;

	handleOnClose: () => void;
}

const Header: React.FC<HeaderProps> = ({ handleOnClose, eventName, seasonName, programName }) => {
	const truncate = source => {
		return source.length > TITLE_SIZE ? source.slice(0, TITLE_SIZE - 1) + '…' : source;
	};

	return (
		<div css={headerCss}>
			<div css={eventNameCss}>{truncate(eventName)}</div>
			<div css={flex}>
				<div css={subtitleCss(colors.formInputBg)}>{`${programName},\u00A0`}</div>
				<div css={subtitleCss(colors.brandPrimary)}>{` ${seasonName}`}</div>
			</div>
		</div>
	);
};

export default Header;
