/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { jsx, css } from '@emotion/react';
import { Schema } from 'yup';
import { FormNoteFooter } from '../../../molecules/notes/NoteForm/Footer';
import { CheckBoxContainer } from '../../../molecules/notes/NoteForm/CheckBoxContainer';
import { AddNoteDto, EditNoteDto } from '../../../types/entities/notes';
import {
	alignItemsCss,
	flexColCss,
	flexRowCss,
	gapCss,
	justifyContentCss,
	marginBottomCss,
	marginTopCss,
	paddingLeftCss,
	paddingRightCss,
	widthCss,
} from '../../../styles/utils';
import { FormInput } from '../../../components/Input/FormInput';
import { FormTextarea } from '../../../components/Input/FormTextarea';
import { ETypography, ETypographyColor, Icons } from '../../../types';
import { Typography } from '../../../atoms';
import { NotesPopupLabels } from '../../../types/notes';
import { useSchemaValidation } from '../../../hooks/useSchemaValidation';
import { BnIcon } from '../../../components/icons_v2';
import { useColors } from '../../../hooks/useColors';

const MAX_CONTENT_LENGTH = 120;
const MAX_TITLE_LENGTH = 60;

type KeysOfValues = keyof EditNoteDto & keyof AddNoteDto;

export type NoteFormProps = {
	note: Partial<AddNoteDto> | Partial<EditNoteDto>;
	onCancel: () => void;
	onSubmit: (dto: AddNoteDto | EditNoteDto) => void | Promise<void>;
	isAlertDisabled: boolean;
	disableAll?: boolean;
	labels: NotesPopupLabels['form'];
	schema: Schema;
	isValid: (
		values: Partial<EditNoteDto> | AddNoteDto,
		initialValues: Partial<EditNoteDto | AddNoteDto>,
		errors: any
	) => boolean;
};

export const NoteForm: React.FC<NoteFormProps> = ({
	note,
	onCancel,
	onSubmit,
	isAlertDisabled,
	disableAll,
	labels,
	schema,
	isValid,
}) => {
	const [, validate] = useSchemaValidation(schema);
	const onSubmitHandler = (values: AddNoteDto | EditNoteDto) => {
		onSubmit(values);
	};

	const { colors } = useColors();

	return (
		<Fragment>
			<Form
				validate={validate}
				initialValues={note}
				render={({ values, form, handleSubmit }) => {
					const charactersLeft: number = MAX_CONTENT_LENGTH - (values.content?.length || 0);

					const setValue = function <T>(key: KeysOfValues, val: T): void {
						form.change(key, val as any);
					};

					return (
						<form
							autoComplete="off"
							encType="multipart/form-data"
							onSubmit={handleSubmit}
							css={[
								flexColCss,
								alignItemsCss('center'),
								paddingLeftCss(40),
								paddingRightCss(40),
								marginTopCss(24),
								marginBottomCss(24),
							]}
						>
							<div css={[flexColCss, widthCss(100)]} data-aid="index-NoteForm">
								<FormInput
									name="title"
									sizer="S"
									hideError
									placeholder={labels.titleInputPlaceHolder}
									maxLength={MAX_TITLE_LENGTH}
									disabled={disableAll}
									onClear={() => setValue('title', '')}
								/>
								<FormTextarea
									name="content"
									sizer="S"
									hideError
									placeholder={labels.contentInputPlaceHolder}
									maxLength={MAX_CONTENT_LENGTH}
									disabled={disableAll}
									noCarriageReturn
								/>
								<div css={[flexRowCss, marginTopCss(4), justifyContentCss('end')]}>
									<Typography type={ETypography.body1} color={ETypographyColor.secondary}>
										{charactersLeft} / {MAX_CONTENT_LENGTH}
									</Typography>
								</div>
							</div>
							<div css={[flexColCss, widthCss(100), gapCss(12)]}>
								<CheckBoxContainer
									onChecked={val => setValue('isPinned', val)}
									label={labels.pinToTop}
									isChecked={values.isPinned!}
									disabled={disableAll}
								/>
								<CheckBoxContainer
									onChecked={val => setValue('isAlert', val)}
									label={labels.pinToTopAndDisplay}
									withToolTip
									toolTipContent={
										<div css={flexRowCss}>
											<BnIcon
												icon={Icons.conflict}
												color={colors.bg_text_secondary}
												height={60}
												width={100}
												viewBox={'0 20 35 30'}
											/>
											<Typography type={ETypography.body1} color={ETypographyColor.secondary}>
												{!isAlertDisabled ? labels.pinToTopAndDisplayTooltip : labels.pinToTopAndDisplayTooltipMaxLimit}
											</Typography>
										</div>
									}
									isChecked={values.isAlert!}
									disabled={disableAll || isAlertDisabled}
								/>
							</div>
							<FormSpy
								subscription={{ errors: true, values: true }}
								render={({ errors, values }) => {
									const isFormValid: boolean = isValid(values, note, errors);
									return <FormNoteFooter disabled={disableAll || !isFormValid} onCancel={onCancel} labels={labels} />;
								}}
							/>
						</form>
					);
				}}
				onSubmit={onSubmitHandler}
			/>
		</Fragment>
	);
};
