import React from 'react';

export const Previous: React.FC<any> = props => {
	return (
		<svg data-aid="SvgPrevious" xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M9.17749 10.3306L9.17444 10.3337L10.1103 11.2696L10.1134 11.2665L15.6486 16.8018L16.5845 15.8659L11.0492 10.3306L16.5921 4.78775L15.6563 3.85187L10.1134 9.39476L10.1027 9.38409L9.16682 10.32L9.17749 10.3306Z"
				fill="currentColor"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M3.34416 10.3306L3.34111 10.3337L4.27699 11.2696L4.28004 11.2665L9.81531 16.8018L10.7512 15.8659L5.21591 10.3306L10.7588 4.78774L9.82292 3.85187L4.28004 9.39476L4.26937 9.38409L3.3335 10.32L3.34416 10.3306Z"
				fill="currentColor"
			/>
		</svg>
	);
};
