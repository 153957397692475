/** @jsxRuntime classic*/
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { ReactNode, useRef, useState } from "react";
import { Typography } from "../../atoms";
import { useColors } from "../../hooks/useColors";
import { ETypography, ETypographyColor, IColors, Icons } from "../../types";
import { BnIcon } from "../icons_v2";

export interface ICollapsableProps {
  title: string;
  children: ReactNode;
}

const buttonLineCss = (colors: IColors, isExpanded: boolean) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    color: ${colors.bg_text_primary};
    transform: rotate(${isExpanded ? "90deg" : "0"});
    transition: transform 200ms;
  }
`;

const bodyContentCss = (isExpanded: boolean, height: number) => css`
  max-height: ${isExpanded ? `${height}px` : "0"};
  overflow-y: hidden;
  transition: max-height 300ms;
`;

export const Collapsable = ({ title, children }: ICollapsableProps) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);
  const { colors } = useColors();

  const expandToggle = () => setExpanded(!isExpanded);

  return (
    <div data-aid="collapsable">
      <div data-aid="collapsable-expand" css={buttonLineCss(colors, isExpanded)} onClick={expandToggle}>
        <Typography
          data-aid="collapsable-title"
          color={ETypographyColor.primary}
          type={ETypography.body2Accented}
        >
          {title}
        </Typography>
        <BnIcon icon={Icons.forward} />
      </div>
      <div
        data-aid="collapsable-body"
        ref={divRef}
        css={bodyContentCss(isExpanded, divRef?.current?.scrollHeight || 0)}
      >
        {children}
      </div>
    </div>
  );
};
